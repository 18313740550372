import { Component, OnInit } from '@angular/core';
import { BaseCellComponent } from '@datagalaxy/core-ui';
import { TranslateModule } from '@ngx-translate/core';
import { IHasHddData } from '@datagalaxy/dg-object-model';
import { LineagePathTrackerService } from '../lineage-path-tracker.service';
import { LineageTrackerColor } from '../lineage-path-tracker.types';
import { map, Observable } from 'rxjs';
import { AsyncPipe } from '@angular/common';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';
import { EntityIdentifier } from '@datagalaxy/webclient/entity/utils';
import { FunctionalLogService } from '@datagalaxy/webclient/monitoring/data-access';

@Component({
    standalone: true,
    selector: 'app-lineage-path-tracker-option',
    templateUrl: './lineage-path-tracker.component.html',
    styleUrls: ['./lineage-path-tracker.component.scss'],
    imports: [TranslateModule, AsyncPipe, MatLegacyTooltipModule],
})
export class LineagePathTrackerComponent
    extends BaseCellComponent<unknown, IHasHddData>
    implements OnInit
{
    protected readonly LineageTrackerColor = LineageTrackerColor;

    protected isRedActive$: Observable<boolean>;
    protected isBlueActive$: Observable<boolean>;

    protected redTooltip$: Observable<string>;
    protected blueTooltip$: Observable<string>;

    constructor(
        private lineagePathTracker: LineagePathTrackerService,
        private functionalLogService: FunctionalLogService
    ) {
        super();
    }

    ngOnInit() {
        const entityIdr = EntityIdentifier.fromIHierarchicalData(
            this.value.HddData
        );
        this.isRedActive$ =
            this.lineagePathTracker.selectEntityActivePathTracker(
                entityIdr,
                LineageTrackerColor.Red
            );
        this.isBlueActive$ =
            this.lineagePathTracker.selectEntityActivePathTracker(
                entityIdr,
                LineageTrackerColor.Blue
            );

        this.redTooltip$ = this.isRedActive$.pipe(
            map((isActive) => this.getTooltip(isActive))
        );
        this.blueTooltip$ = this.isBlueActive$.pipe(
            map((isActive) => this.getTooltip(isActive))
        );
    }

    protected togglePathTracker(color: LineageTrackerColor) {
        const entityIdr = EntityIdentifier.fromIHierarchicalData(
            this.value.HddData
        );
        const suffix = this.lineagePathTracker.state[color] ? 'UNPIN' : 'PIN';

        this.lineagePathTracker.togglePathTracker(entityIdr, color);
        this.functionalLogService.parseAndLog(
            `LINEAGE,A,${suffix}_TRACK_${color.toUpperCase()}`
        );
    }

    private getTooltip(isActive: boolean) {
        return isActive
            ? 'UI.ImpactAnalysis.lineage.pathTracker.unpinTrack'
            : 'UI.ImpactAnalysis.lineage.pathTracker.pinTrack';
    }
}

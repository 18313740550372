<div class="button-wrapper">
    <a
        class="handle"
        mat-icon-button
        (mousedown)="onMouseDown()"
        (mouseup)="onMouseUp()"
        [dxyRichTooltip]="tooltipContent"
        [richTooltipDisabled]="disabled"
        richTooltipPosition="above"
        [richTooltipPositionOptions]="{ offsetY: -2 }"
    >
        <i class="glyph-splitter"></i>
        <i class="glyph-splitter"></i>
    </a>
    <button
        class="trigger-button"
        #triggerButton="matMenuTrigger"
        [matMenuTriggerFor]="menu"
    ></button>
</div>

<mat-menu #menu="matMenu" class="dg5-mat-menu no-max-width" xPosition="after">
    <ng-container *ngIf="triggerButton.menuOpen">
        <dxy-burger-menu [options]="options"></dxy-burger-menu>
    </ng-container>
</mat-menu>

<ng-template #tooltipContent>
    <div class="dg5-custom-tooltip" [innerHTML]="tooltipHtml"></div>
</ng-template>

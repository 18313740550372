import { Subscription } from 'rxjs';
import {
    Component,
    ElementRef,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DxyBaseWidgetComponent } from '../DxyBaseWidgetComponent';
import {
    IWidgetCommonSettingTranslateKeys,
    IWidgetTypeDefinition,
    WidgetCategory,
    WidgetFormFieldType,
} from '../../WidgetUtil';
import { EntityType, ServerType } from '@datagalaxy/dg-object-model';
import { AppSpaceService } from '../../../services/AppSpace.service';
import { DashboardService, IWidgetFilter } from '../../dashboard.service';
import { EntitySecurityService } from '../../../shared/entity/services/entity-security.service';
import { SelectionItem } from '../../../shared/util/app-types/SelectionItem';
import { DataUtil } from '../../../shared/util/DataUtil';
import { NavigationService } from '../../../services/navigation.service';
import { EntityEventService } from '../../../shared/entity/services/entity-event.service';
import { AttributeDataService } from '../../../shared/attribute/attribute-data.service';
import { Space } from '@datagalaxy/webclient/workspace/data-access';
import {
    AggregationStatisticsDataResultItem,
    WidgetType,
} from '@datagalaxy/webclient/dashboard/data-access';
import { EntityTypeUtils } from '@datagalaxy/webclient/entity/utils';
import { SpaceIdentifier } from '@datagalaxy/webclient/workspace/utils';
import { ISpaceIdentifier } from '@datagalaxy/webclient/workspace/domain';
import { DgModule } from '@datagalaxy/shared/dg-module/domain';

@Component({
    //eslint-disable-next-line @angular-eslint/component-selector
    selector: 'dxy-space-module-widget',
    templateUrl: 'dxy-space-module-widget.component.html',
    styleUrls: ['dxy-space-module-widget.component.scss'],
})
export class DxySpaceModuleWidgetComponent
    extends DxyBaseWidgetComponent
    implements OnChanges, OnInit
{
    //#region typeDef
    public static typeDef: IWidgetTypeDefinition = {
        widgetType: WidgetType.spaceModule,
        category: WidgetCategory.general,
        component: 'spaceModuleWidget',
        params: [
            {
                name: 'dgModule',
                formFieldType: WidgetFormFieldType.dgModuleLegacy,
            },
            {
                name: 'spaceIdr',
                formFieldType: WidgetFormFieldType.spaceVersion,
            },
        ],
        commonFieldTranslateKeys: {
            needsDgModuleSuffix: true,
            titleText: 'UI.spaceModuleWidget.titleByDgModule',
            description: 'UI.spaceModuleWidget.descriptionByDgModule',
        },
        spaceVersionSelectorProjectsOnly: true,
    };
    //#endregion

    // Do Not rename those properties, as they may be used in json stored in DB
    @Input() spaceIdr: ISpaceIdentifier;
    @Input() dgModule: DgModule;
    @Input() titleText: string;
    @Input() description: string;

    public serverType: ServerType;
    public elementList: SelectionItem<EntityType>[];
    public totalCount: number;
    public isLoading: boolean;
    public glyphClass: string;
    public firstImportTranslateKey: string;
    public filters: IWidgetFilter[];
    public get hasCount() {
        return !!this.countData;
    }
    public get canImport() {
        return this.isReady && this.moduleSecurityData?.HasImportAccess;
    }
    public get moduleSecurityData() {
        return (
            this.spaceData &&
            this.entitySecurityService.getModuleImportSecurityData(
                this.serverType,
                this.spaceData
            )
        );
    }

    public get importTranslateKey() {
        return `${this.translateKeyPrefix}.import.import`;
    }
    public get entitySecurityData() {
        return this.spaceData?.SecurityData;
    }
    public get placeholderVisible() {
        return this.hasCount && this.totalCount === 0 && !this.canImport;
    }

    private spaceData: Space;
    private countData: { [key: string]: number };
    private translateKeyPrefix: string;
    private entityDeleteSubscription: Subscription;
    private get isReady() {
        return this.dgModule && !!this.spaceIdr?.spaceId;
    }

    constructor(
        private elementRef: ElementRef<HTMLElement>,
        private translate: TranslateService,
        private dashboardService: DashboardService,
        private entityEventService: EntityEventService,
        private navigationService: NavigationService,
        private entitySecurityService: EntitySecurityService,
        private attributeDataService: AttributeDataService,
        private appSpaceService: AppSpaceService
    ) {
        super();
    }

    ngOnInit() {
        this.init();
    }

    ngOnChanges(changes: SimpleChanges) {
        super.onChanges(changes, ['spaceIdr', 'dgModule'], () => this.init());
    }

    public onImportData() {
        this.isReady && this.navigationService.goToClientDashboard(true);
    }

    public onClickTitle() {
        this.isReady &&
            this.navigationService.goToEntityListAll(
                this.dgModule,
                this.spaceIdr
            );
    }

    public onClickEntityTypeContent(entityType: EntityType) {
        this.isReady &&
            this.navigationService.goToEntityTypeFilteredGridView(
                entityType,
                this.spaceIdr
            );
    }

    private getOrderedEntityTypes(serverType: ServerType) {
        if (!serverType) {
            return [];
        }
        const orderedSubTypes =
            AttributeDataService.getOrderedAdminSubTypes(serverType);
        return orderedSubTypes.map(
            (st) =>
                new SelectionItem(
                    st,
                    EntityType[st],
                    this.attributeDataService.getEntityTypeTranslation(st)
                )
        );
    }

    public getCount(propertyTypeName: string): number {
        return this.countData?.[propertyTypeName] || 0;
    }

    public getElementTypeClass(entityType: EntityType) {
        return this.isReady && EntityTypeUtils.getColoredGlyphClass(entityType);
    }

    public getTranslateEntitySubTypeKey(typeName: string) {
        return this.isReady && `${this.translateKeyPrefix}.label.${typeName}`;
    }
    public getEntitySubTypeKeyPluralizationParameters(elementKey: string) {
        return {
            count: (this.isReady && this.getCount(elementKey)) || undefined,
        };
    }

    public getFeatureCode() {
        if (!this.isReady) {
            return;
        }
        const isProject = SpaceIdentifier.isProject(this.spaceIdr);
        return (
            (isProject ? 'PROJECT_DASHBORAD_' : 'ORGA_DASHBORAD_') +
            ServerType[this.serverType]?.toUpperCase() +
            ',R'
        );
    }

    public getEmptyModulePlaceholderUrl() {
        let fileName = '';
        switch (this.dgModule) {
            case DgModule.Glossary:
                fileName = 'glossary';
                break;
            case DgModule.Catalog:
                fileName = 'catalog';
                break;
            case DgModule.Processing:
                fileName = 'processing';
                break;
            case DgModule.Usage:
                fileName = 'usage';
                break;
        }
        return `images/placeholders/dashboards/${fileName}.svg`;
    }

    private async init(): Promise<void> {
        this.unsubscribeEvents();
        this.spaceData = null;
        if (!this.isReady) {
            return;
        }

        this.serverType = DataUtil.getDefaultServerTypeFromModule(
            this.dgModule
        );

        this.totalCount = 0;
        this.countData = null;

        this.glyphClass = 'glyph typeIcon glyph-';
        this.translateKeyPrefix = 'UI.ProjectDashboardComponent.';
        this.firstImportTranslateKey = 'UI.Global.import.first';

        switch (this.dgModule) {
            case DgModule.Glossary: {
                this.glyphClass += 'glossary';
                this.translateKeyPrefix += 'glossary';
                this.firstImportTranslateKey += 'Glossary';
                break;
            }
            case DgModule.Catalog: {
                this.glyphClass += 'catalog';
                this.translateKeyPrefix += 'modeler';
                this.firstImportTranslateKey += 'Catalog';
                break;
            }
            case DgModule.Processing: {
                this.glyphClass += 'dataprocessing';
                this.translateKeyPrefix += 'dataProcessing';
                this.firstImportTranslateKey += 'DataProcessing';
                break;
            }
            case DgModule.Usage: {
                this.glyphClass += 'software';
                this.translateKeyPrefix += 'usage';
                this.firstImportTranslateKey += 'Usage';
                break;
            }
        }

        this.elementRef.nativeElement.classList.add(
            `project-dashboard-${DgModule[this.dgModule].toLowerCase()}`
        );

        const getTranslated = (key: keyof IWidgetCommonSettingTranslateKeys) =>
            this.translate.instant(
                DxySpaceModuleWidgetComponent.typeDef.commonFieldTranslateKeys[
                    key
                ] +
                    '.' +
                    DgModule[this.dgModule]
            );
        this.titleText = this.titleText ?? getTranslated('titleText');
        this.description = this.description ?? getTranslated('description');

        this.elementList = this.getOrderedEntityTypes(this.serverType);

        this.isLoading = true;
        try {
            await this.updateFiltersAsync();
            this.spaceData = await this.appSpaceService.getSpace(this.spaceIdr);
            await this.loadData();
        } finally {
            this.isLoading = false;
            this.subscribeEvents();
            this.log('init-done');

            if (this.debug) {
                this.log({
                    serverType: ServerType[this.serverType],
                    dgModule: DgModule[this.dgModule],
                    spaceIdr: this.spaceIdr,
                    spaceData: this.spaceData,
                    hasCount: this.hasCount,
                    totalCount: this.totalCount,
                    isReady: this.isReady,
                    canImport: this.canImport,
                    moduleSecurityData: this.moduleSecurityData,
                    hasModuleImportAccess:
                        this.moduleSecurityData?.hasModuleImportAccess,
                });
            }
        }
    }

    private async updateFiltersAsync() {
        this.filters = await this.dashboardService.getWidgetFilters({
            spaceIdr: this.spaceIdr,
        });
    }
    private unsubscribeEvents() {
        super.unsubscribe(this.entityDeleteSubscription);
    }
    private subscribeEvents() {
        this.unsubscribeEvents();
        this.entityDeleteSubscription = super.registerSubscription(
            this.entityEventService.subscribeEntityDelete(this.serverType, () =>
                this.init()
            )
        );
    }
    private async loadData() {
        const targetType = DashboardService.getProjectDashBoardTargetType(
            this.dgModule
        );
        const result = await this.dashboardService.loadAggregationData(
            this.spaceIdr,
            targetType,
            this.destroyNotifier
        );
        if (!result) {
            return;
        }

        this.countData = {};
        (result.Items as AggregationStatisticsDataResultItem[]).forEach(
            (item) => {
                this.countData[item.EntityType] = item.Count;
                this.totalCount = item.TotalCount;
            }
        );
    }
}
DashboardService.registerWidgetType(DxySpaceModuleWidgetComponent.typeDef);

import {
    Directive,
    InjectionToken,
    Input,
    Output,
    EventEmitter,
} from '@angular/core';
import {
    CdkDragDrop,
    CdkDropList,
    moveItemInArray,
} from '@angular/cdk/drag-drop';
import { DxyBaseDirective } from '@datagalaxy/ui/core';

export const DXY_SORT_LIST = new InjectionToken<DxySortListDirective<any>>(
    'DxySortListDirective'
);

/**
 * This directive allows to sort a data list on horizontal and vertical level.
 * It needs to be paired with dxySortListItem directive to work correctly like in this example:
 ```
 <div [dxySortList]="data">
    <whatever-you-want *ngFor="let d of data; let i = index" [dxySortListItem]="i">
    </whatever-you-want>
 </div>
 ```
 * Internally it connects cdkDropList between each others, this is the trick that allows
 * horizontal and vertical sort, this way we can move cdkDrag elements from on cdkDropList
 * to another one.
 */
@Directive({
    selector: '[dxySortList]',
    providers: [{ provide: DXY_SORT_LIST, useExisting: DxySortListDirective }],
})
export class DxySortListDirective<T> extends DxyBaseDirective {
    @Input('dxySortList') data: T[];
    @Output() readonly dxySortChange = new EventEmitter<void>();

    private readonly dropListItems: CdkDropList<T>[] = [];
    private pickedItem: number;

    public addDropList(dropList: CdkDropList<T>) {
        this.dropListItems.push(dropList);
        this.dropListItems.forEach(
            (drop) => (drop.connectedTo = this.dropListItems)
        );
    }

    constructor() {
        super();
    }

    drop(event: CdkDragDrop<T>, targetIndex: number) {
        if (event.previousContainer === event.container) {
            return;
        }

        this.debug &&
            this.log('drop', {
                from: this.pickedItem,
                to: targetIndex,
            });

        moveItemInArray(this.data, this.pickedItem, targetIndex);
        this.dxySortChange.emit();
    }

    picked(index: number) {
        this.pickedItem = index;
    }
}

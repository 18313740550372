import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    inject,
    Input,
    OnInit,
} from '@angular/core';
import { ProgressBarComponent } from '@datagalaxy/ui/progress-bar';
import { DxyRichTooltipDirective } from '@datagalaxy/ui/tooltip';
import { MultilingualApiService } from '@datagalaxy/webclient/multilingual/data-access';
import { TranslationBatch } from '@datagalaxy/webclient/multilingual/domain';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { map, Observable } from 'rxjs';

@Component({
    selector: 'dxy-translation-progress',
    templateUrl: './translation-progress.component.html',
    styleUrls: ['./translation-progress.component.scss'],
    standalone: true,
    imports: [
        ProgressBarComponent,
        NgIf,
        NgFor,
        AsyncPipe,
        DxyRichTooltipDirective,
        TranslateModule,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TranslationProgressComponent implements OnInit {
    @Input() languageCode!: string;
    protected batches$?: Observable<string[] | undefined>;
    private multilingualApiService = inject(MultilingualApiService);
    private translate = inject(TranslateService);

    ngOnInit() {
        this.batches$ = this.multilingualApiService
            .getLanguagePendingBatches(this.languageCode)
            .pipe(
                map((batches) => {
                    if (!batches || batches.length === 0) {
                        return undefined;
                    }
                    return batches.map((batch) => this.getBatchMessage(batch));
                })
            );
    }

    private getBatchMessage(batch: TranslationBatch): string {
        let messageKey = 'addLanguageMessage';
        switch (batch.origin) {
            case 'languageAdded':
                messageKey = 'addLanguageMessage';
                break;
            case 'attributeAdded':
                messageKey = 'addAttributesMessage';
                break;
            case 'import':
                messageKey = 'importMessage';
        }

        return this.translate.instant(
            `Multilingual.Administration.LanguagesTab.TranslationProgressTooltip.${messageKey}`
        );
    }
}

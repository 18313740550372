import { Injectable } from '@angular/core';
import { BaseService } from '@datagalaxy/core-ui';
import { CoreUtil, StringUtil } from '@datagalaxy/core-util';
import { environment } from '../../environments/environment';

const isDevEnv = location.href.startsWith('https://dev-');

/* Notes:
  - Properties format is:   myProp: <IOptionState>{},
  - To find usages of properties, search for their names instead of their reference */
const devPreviewState = {
    _: <IOptionState>{ readonly: true },
    canAutoLogin: <IOptionState>{},
    lineageLazyLoadItemsCount: <IOptionState>{
        isInput: true,
        inputDefaultValue: '100',
    },
};

/**
 * ## Role
 * Options repository of features being in development, or options that facilitate testing.
 *
 * Allows for options listing and toggling.
 * Option states:
 * - are stored in local storage,
 * - are visible: in development build, and in production build only on dev-clm and dev-uat envs.
 * - may store a user-provided value
 *
 * ## Notes
 * As this service may be used by any service or component, it must not reference any other service or component.
 */
@Injectable({ providedIn: 'root' })
export class DevPreviewService extends BaseService {
    public options: IDevPreviewOption[] = [];
    public get showOptions() {
        return this.useDevPreview && this.options.length > 0;
    }
    public get canClearCache() {
        return this.hasStoredState();
    }

    private readonly useDevPreview = !environment.production || isDevEnv;
    private readonly storageKey = 'dev-preview-state';
    private currentState: TDevPreviewState;

    constructor() {
        super();
        this.initState();
    }

    public toggle(k: IOptionKey, force?: boolean) {
        const state = this.getOptionState(k);
        if (!state || state.readonly) {
            return;
        }
        state.active = !!(force ?? !state.active);
        this.storeState();
        this.updateOptions();
        this.doLog(`${k} is now ${this.isActive(k) ? 'on' : 'off'}`);
    }

    public isActive(k: IOptionKey) {
        return this.getOptionState(k)?.active;
    }

    public clearOptionsCache() {
        this.clearStoredState();
        this.doLog('Cache cleared');
        this.initState();
    }

    public getInputValue(k: IOptionKey) {
        const os = this.getActiveInputOptionState(k);
        if (!os) {
            return;
        }
        return os.inputValue ?? os.inputDefaultValue;
    }
    public inputChange(k: IOptionKey, newValue: string) {
        const os = this.getActiveInputOptionState(k);
        if (!os) {
            return;
        }
        os.inputValue = newValue;
        this.storeState();
        this.updateOptions();
        const currentValue = this.getInputValue(k);
        this.doLog(`${k} value is now ${currentValue}`);
        return currentValue;
    }
    public resetInput(k: IOptionKey) {
        const os = this.getActiveInputOptionState(k);
        if (!os) {
            return;
        }
        return this.inputChange(k, os.inputDefaultValue);
    }

    private initState() {
        this.currentState =
            this.getStoredState() ?? CoreUtil.cloneDeep(devPreviewState);

        // cleanup state, because previous stored option state was a boolean
        Object.keys(devPreviewState)
            .filter((k) => typeof this.currentState[k] != 'object')
            .forEach((k) => (this.currentState[k] = devPreviewState[k] ?? {}));

        this.updateOptions();
    }
    private storeState() {
        try {
            window.localStorage.setItem(
                this.storageKey,
                JSON.stringify(this.currentState)
            );
        } catch (e) {
            CoreUtil.warn(e);
        }
    }
    private getStoredState(): TDevPreviewState {
        try {
            return JSON.parse(window.localStorage.getItem(this.storageKey));
        } catch (e) {
            CoreUtil.warn(e);
        }
    }
    private clearStoredState() {
        window.localStorage.removeItem(this.storageKey);
    }
    private hasStoredState() {
        return !!window.localStorage.getItem(this.storageKey);
    }
    private getOptionState(k: IOptionKey) {
        return this.currentState[k];
    }
    private getActiveInputOptionState(k: IOptionKey) {
        const os = this.getOptionState(k);
        return os && os.isInput && os.active ? os : undefined;
    }
    private updateOptions() {
        this.options = (Object.keys(devPreviewState) as IOptionKey[])
            .filter((k) => k != '_')
            .map((k) => {
                const os = this.getOptionState(k);
                return {
                    key: k,
                    text: StringUtil.toHumanCase(k),
                    ...os,
                    inputValue: os.inputValue ?? os.inputDefaultValue,
                };
            });
    }

    /** logs to console even in production mode */
    private doLog(...args: any[]) {
        // this console.log is on purpose
        console.log(this.constructor.name, ...args);
    }
}

type TDevPreviewState = typeof devPreviewState;
type IOptionKey = keyof TDevPreviewState;

interface IOptionState {
    active?: boolean;
    readonly?: boolean;
    isInput?: boolean;
    inputDefaultValue?: string;
    inputValue?: string;
}

export interface IDevPreviewOption extends IOptionState {
    key: IOptionKey;
    text: string;
}

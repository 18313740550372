import { DashboardSection, WidgetInstance } from '../../../domain';
import { MAX_WIDGETS_PER_SECTION } from '../../../domain/dashboard-constants';
import { DashboardState } from '../dashboard-state';
import { getNextPosition } from '../get-next-position';
import { sectionReducer } from './section-reducer';
import {
    distanceToOrigin,
    widgetsByDistance,
} from '../../../common/dashboard-grid-utils';
import { generateGuid } from '@datagalaxy/utils';

export function duplicateWidgetInstanceReducer(
    state: DashboardState,
    section: DashboardSection,
    wi: WidgetInstance
) {
    if (section.widgetInstances.length >= MAX_WIDGETS_PER_SECTION) {
        return;
    }
    const position = getNextPosition(wi.position);
    if (!position) {
        return;
    }

    const newWidgetInstance = {
        guid: generateGuid(),
        widgetName: wi.widgetName,
        position,
    } as WidgetInstance;

    // update next cards positions
    const widgetDistance = distanceToOrigin(wi.position);

    const newWidgetInstances = [
        ...section.widgetInstances.map((w) => {
            const d = distanceToOrigin(w.position);
            if (d > widgetDistance) {
                return {
                    ...w,
                    position: getNextPosition(w.position),
                };
            }
            return w;
        }),
        newWidgetInstance,
    ].sort(widgetsByDistance);

    return sectionReducer(state, section, {
        widgetInstances: newWidgetInstances,
    });
}

import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
} from '@angular/core';
import { withLoading } from '@datagalaxy/core-ui';
import { SpaceApiService } from '../../services/space-api.service';
import { AppDataService } from '../../../services/app-data.service';
import { SpaceUiService } from '../../services/space-ui.service';
import { CollectionsHelper } from '@datagalaxy/core-util';
import { SpaceArticleDto } from '@datagalaxy/webclient/workspace/data-access';
import {
    BaseCustomTitleWidgetComponent,
    ICustomTitleWidgetComponent,
    registerWidget,
} from '@datagalaxy/webclient/dashboard/ui';
import { CurrentSpaceService } from '../../../services/currentSpace.service';
import { LicenseLevel } from '@datagalaxy/webclient/license/domain';

/**
 * ## Role
 * Display a list of news for a workspace
 *
 * ## Features
 * - Show a create button that open a modal to create a news
 * - Open the news details in a modal on news click
 */
@registerWidget({
    name: 'space-news-widget',
    titleKey: 'UI.SpaceHome.Widgets.News.Title',
    previewImgUrl: '/images/space-widgets/space-widget-news.svg',
})
@Component({
    selector: 'app-space-news-widget',
    templateUrl: './space-news-widget.component.html',
    styleUrls: [
        '../space-widget.component.scss',
        './space-news-widget.component.scss',
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpaceNewsWidgetComponent
    extends BaseCustomTitleWidgetComponent
    implements AfterViewInit, ICustomTitleWidgetComponent
{
    protected get space() {
        return this.currentSpaceService.currentSpace;
    }
    protected articles: SpaceArticleDto[];
    protected get showPlaceholder() {
        return !this.loadingValue && !this.articles?.length;
    }
    protected get showCreateButton() {
        return (
            this.appDataService.currentUserLicenseLevel === LicenseLevel.Steward
        );
    }

    constructor(
        private spaceApiService: SpaceApiService,
        private spaceUiService: SpaceUiService,
        private appDataService: AppDataService,
        private currentSpaceService: CurrentSpaceService
    ) {
        super();
    }

    ngAfterViewInit() {
        this.initAsync();
    }

    protected async onCreateArticleClick() {
        const res = await this.spaceUiService.openNewsModal(this.space);

        if (!res?.article) {
            return;
        }

        this.articles.unshift(res.article);
        this.cd.detectChanges();
    }

    protected async onArticleClick(article: SpaceArticleDto) {
        const res = await this.spaceUiService.openNewsModal(
            this.space,
            article
        );

        if (res?.article) {
            CollectionsHelper.replaceOne(
                this.articles,
                (data) => data === article,
                res.article
            );
        }

        if (res?.deleted) {
            CollectionsHelper.removeElement(this.articles, article);
        }

        this.cd.detectChanges();
    }

    @withLoading()
    private async initAsync() {
        this.articles = await this.spaceApiService.getArticles(this.space);
    }
}

<ng-container *ngIf="hasDataQuality">
    <dxy-icon-cell
        [glyphClass]="glyph"
        [dxyRichTooltip]="dataQualityTooltip"
    ></dxy-icon-cell>
    <ng-template #dataQualityTooltip>
        <div class="dg5-custom-tooltip data-quality">
            <h3 translate>DgServerTypes.BaseData.fields.QualityStatus</h3>
            <div class="data-quality-status">
                <i [ngClass]="glyph"></i>
                <span>{{ dataQualityKey | translate }}</span>
            </div>
        </div>
    </ng-template>
</ng-container>

import { select as d3select } from 'd3-selection';
import { CoreEventsService } from '@datagalaxy/core-ui';
import { IGraphicalToolbarOption } from '@datagalaxy/core-ui/graphical';
import { D3Helper, SD3SvgDefs } from '@datagalaxy/core-d3-util';
import { CoreUtil, DomUtil } from '@datagalaxy/core-util';
import { Component, ElementRef, Input, NgZone, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IPopoverHelper, PopoverHelper } from './popover-helper';
import { EntityUiService } from '../../shared/entity/services/entity-ui.service';
import {
    DxyBaseForceGraphComponent,
    IForceGraphConfig,
    SD3,
} from '../BaseForceGraphComponent';
import { ExplorationGraphData } from '../data/ExplorationGraphData';
import { ImpactAnalysisService } from '../impact-analysis.service';
import { AttributeDataService } from '../../shared/attribute/attribute-data.service';
import { NavigationService } from '../../services/navigation.service';
import { AppEventsService } from '../../services/AppEvents.service';
import { ExplorationGraphLink } from '../data/ExplorationGraphLink';
import { GlyphUtil } from '../../shared/util/GlyphUtil';
import { EntityType } from '@datagalaxy/dg-object-model';
import { ImpactAnalysisTool } from '../../shared/util/app-types/impact-analysis.types';
import { ExplorationGraphNode } from '../data/ExplorationGraphNode';
import {
    EntityIdentifier,
    EntityTypeUtils,
} from '@datagalaxy/webclient/entity/utils';

@Component({
    selector: 'app-exploratory-graph',
    templateUrl: 'exploratory-graph.component.html',
    styleUrls: ['exploratory-graph.component.scss'],
})
export class ExploratoryGraphComponent
    extends DxyBaseForceGraphComponent<Item, Link>
    implements OnInit
{
    //#region for debug
    protected verbose = false;
    private debugNoPopover = false;
    //#endregion

    //#region static

    private static readonly config: IForceGraphConfig = {
        forces: {
            gravity: 0.02,
            link: { distance: 200, strength: 2.0 },
            charge: { distance: 1500, strength: -5000 },
            friction: 0.8,
        },
        alpha: 0.8,
        draggedItem: {
            setSelected: true,
        },
    };

    private static readonly behaviour = {
        centerViewOnItem: {
            onLoadLinkedNodes: false,
            onClusterNode: false,
            onUnclusterNode: false,
        },
        centerAndNavigateAtOnce: true,
        minChildrenClusterCountForWarning: 20,
        childrenSpacingRadius: 200,
    };

    private static readonly itemTemplateCustomContent =
        '<circle class="dg_dataVizObjectGraph-disque-select" cx="100" cy="47" r="30" style="fill: #555;stroke: #000;stroke-miterlimit: 10;stroke-width: 2px"/>' +
        '<circle class="dg_dataVizObjectGraph-disque" cx="100" cy="47" r="30" style="fill: #555;stroke: #000;stroke-miterlimit: 10;stroke-width: 2px"/>' +
        '<text class="item-icon"/>';

    //#endregion - static

    @Input() graphData: ExplorationGraphData;
    @Input() enableNavigation: boolean;

    public actionButtons: IGraphicalToolbarOption[];

    private popoverHelper: IPopoverHelper<Item>;
    private goldenText: string;
    private get isNavigationSmoothAppearance() {
        return (
            this.isFirstSimulation && this.enableNavigation && !!this.selected
        );
    }

    constructor(
        translate: TranslateService,
        private impactAnalysisService: ImpactAnalysisService,
        private attributeDataService: AttributeDataService,
        private navigationService: NavigationService,
        entityUiService: EntityUiService,
        ngZone: NgZone,
        elementRef: ElementRef,
        coreEventsService: CoreEventsService,
        appEventsService: AppEventsService
    ) {
        super(
            ngZone,
            elementRef,
            coreEventsService,
            appEventsService,
            ExploratoryGraphComponent.config,
            ExploratoryGraphComponent.itemTemplateCustomContent
        );
        this.popoverHelper = new PopoverHelper(
            entityUiService,
            (it) =>
                new EntityIdentifier(
                    it.nodeObjectReferenceId,
                    this.graphData.rootNodeVersionId,
                    it.entityType
                ),
            (it) => it.id,
            (it) => this.isPopoverDisabled(it),
            this.debug && this.verbose
        );
        this.goldenText = translate.instant(
            'DgServerTypes.ObjectLinkType.Golden'
        );
    }

    ngOnInit() {
        this.log('ngOnInit', this.graphData, this.enableNavigation);
        this.actionButtons = this.getActionButtons(this.enableNavigation);
        if (this.enableNavigation) {
            this.config.noAnimationOnStart = true;
        }
    }

    protected isDataSourceReady() {
        return !!this.graphData;
    }

    protected createDefs(defs: SD3SvgDefs) {
        defs.append('marker')
            .attr('id', 'marker')
            .attr('viewBox', '0 -5 10 10')
            .attr('refX', 40)
            .attr('refY', 0)
            .attr('markerWidth', 5)
            .attr('markerHeight', 5)
            .attr('orient', 'auto')
            .append('path')
            .attr('d', 'M0,-5 L10,0 L0,5');
    }

    protected getSelectItemDisplayName(): string {
        const currentItem = this.graphData
            ?.getDisplayableNodes()
            .find((elem) => elem.isRoot == true);
        return this.impactAnalysisService.getNodeName(
            this.graphData,
            currentItem
        );
    }

    protected async takeScreenShot() {
        const element = DomUtil.getElement(this.elementRef, '.d3div');
        if (!element) {
            CoreUtil.warn('element not found');
        }
        const fileName = `Explorer_${this.getSelectItemDisplayName()}`;
        await DomUtil.screenshot(element, fileName);
    }

    protected getItemsData() {
        const items = this.graphData?.getDisplayableNodes() || [];
        const root = this.graphData?.rootNode;
        if (this.isFirstSimulation) {
            this.initPositions(items, root);
        }
        this.setSelectedItem(root, true);
        return items;
    }
    private initPositions(items: Item[], root: Item) {
        if (!root) {
            return;
        }
        const neighbors = items.filter((o) => o != root);
        root.x = this.center.x;
        root.y = this.center.y;
        this.positionItemsOnCircle(
            neighbors,
            ExploratoryGraphComponent.behaviour.childrenSpacingRadius
        );
    }

    protected getLinksData() {
        return this.graphData?.getDisplayableLinks() ?? [];
    }

    protected setupNewDrawnItems(d3newItems: SD3Items) {
        d3newItems.append((d) => this.getItemSvg(d).node());
        if (this.isNavigationSmoothAppearance) {
            this.makeItemAppearSmoothly(this.selected, d3newItems, true);
            this.makeLinkedItemsAppearSmoothly(d3newItems, this.selected);
        }
    }

    protected bindNewDrawnItems(d3items: SD3Items) {
        super.bindNewDrawnItems(d3items);
        if (!(this.debug && this.debugNoPopover)) {
            this.popoverHelper?.setup(d3items);
        }
    }

    protected updateDrawnItems(d3items: SD3Items) {
        const d3ItemSvgs = this.redrawItemsPosition(d3items).attr(
            'visibility',
            (item) => (item.isDisplayed ? '' : 'hidden')
        );

        d3ItemSvgs
            .select('.dg_dataVizObjectGraph-element')
            .attr('cluster', (item) => item.isCluster)
            .attr('selected', (item) => !item.isLoading && item.isSelected)
            .attr('root', (item) => item.isRoot);

        d3ItemSvgs
            .select('.dg_dataVizObjectGraph-name')
            .text((item) =>
                this.impactAnalysisService.getNodeName(this.graphData, item)
            );

        d3ItemSvgs
            .select('.dg_dataVizObjectGraph-spinner')
            .attr('visibility', (item) => (item.isLoading ? '' : 'hidden'));
    }

    protected redrawItemsPosition(d3items: SD3Items) {
        return DxyBaseForceGraphComponent.redrawItemsPosition(d3items);
    }
    protected redrawLinks(d3links: SD3Links) {
        return DxyBaseForceGraphComponent.redrawLinks(d3links);
    }

    protected setupNewDrawnLinks(d3newLinks: SD3Links) {
        d3newLinks
            .append('line')
            .attr(
                'class',
                (link) =>
                    `dg_dataVizGraphObject-graphicalLink--${link.objectLinkTypeName}`
            )
            .attr('marker-end', (link) =>
                link.hasMarker ? 'url(#marker)' : null
            );

        // link primary text
        this.setupLinkText(d3newLinks, 'txt1', (link) =>
            this.impactAnalysisService.getLinkName(link)
        );

        // link secondary text - golden
        const linksWithText2Golden = d3newLinks.filter((l) => l.isGoldenLink);
        this.setupLinkText(
            linksWithText2Golden,
            'txt2 golden',
            () => this.goldenText
        );

        // link secondary text - parallel
        const linksWithText2 = d3newLinks.filter(
            (l) => !!l.parallel && !l.isGoldenLink
        );
        this.setupLinkText(linksWithText2, 'txt2', (l) =>
            this.impactAnalysisService.getLinkName(l.parallel)
        );

        if (this.isNavigationSmoothAppearance) {
            this.makeLinksAppearSmoothly(d3newLinks, this.selected);
        }
    }
    private setupLinkText(
        d3Links: SD3Links,
        textClass: string,
        getText: (link: ExplorationGraphLink) => string
    ) {
        d3Links
            .append('text')
            .attr(
                'class',
                `${textClass} dg_dataVizGraphObject-graphicalLinkText`
            )
            .attr('text-anchor', 'middle')
            .attr('alignment-baseline', 'central')
            .text(getText);
    }

    protected updateDrawnLinks(d3links: SD3Links) {
        this.redrawLinks(d3links);
        this.updateLinkText(d3links, '.txt1', -10);
        this.updateLinkText(d3links, '.txt2', 10);
    }
    private updateLinkText(d3links: SD3Links, selector = '', offsetY = -10) {
        const linkTexts = d3links.select<SVGTextElement>(`text${selector}`);
        DxyBaseForceGraphComponent.redrawLinksTextPosition(linkTexts, offsetY);
        DxyBaseForceGraphComponent.redrawLinksTextVisibility(linkTexts);
    }

    protected onSimulationEnd() {
        this.log('onSimulationEnd');
        if (this.isFirstSimulation) {
            this.centerView(this.selected, false);
        }
    }

    protected onItemClick(item: Item, event: MouseEvent) {
        this.log('onItemClick', item);
        if (event.ctrlKey || event.metaKey) {
            // metaKey is for mac, since ctrl-click opens the contextual menu
            if (item.isCluster) {
                return;
            }
            this.loadLinkedNodes(item);
        } else if (event.shiftKey) {
            if (item.isCluster) {
                return;
            }
            this.navigateToNode(item);
        } else if (event.altKey) {
            if (item.isCluster) {
                this.unclusterNode(item);
            } else {
                this.clusterNode(item);
            }
        } else {
            this.selectNode(item);
        }
    }

    private getItemSvg(item: Item) {
        const entityType = item.entityType;

        const colorClass = EntityTypeUtils.getGlyphColorClass(entityType);
        const iconGlyphChar = GlyphUtil.getEntityTypeGlyphChar(entityType);

        const d3svg = d3select(
            this.itemTemplateNode.cloneNode(true) as SVGElement
        );

        d3svg
            .select('.dg_dataVizObjectGraph-type')
            .text(() =>
                this.attributeDataService.getEntityTypeTranslation(entityType)
            );

        const d3g = d3svg.select('g').classed(colorClass, true);

        d3g.select('.item-icon').text(iconGlyphChar);

        return d3svg;
    }

    private isPopoverDisabled(it: Item) {
        return (
            this.isDragging ||
            !it.isVisible ||
            (it.isCluster && it.childrenClusterNodesId.length > 0) ||
            it.entityType == EntityType.DataProcessingItem
        );
    }

    private selectNode(item: Item) {
        this.log('selectNode', item);
        this.setSelectedItem(item);
    }

    private async loadLinkedNodes(item: Item) {
        this.log('loadLinkedNodes', item);
        if (!this.graphData) {
            return;
        }

        const hdd = this.graphData?.getObjectHdd(item);
        if (!hdd) {
            return;
        }

        item.isLoading = true;

        const center: () => Promise<void> = async () => {
            if (
                ExploratoryGraphComponent.behaviour.centerViewOnItem
                    .onLoadLinkedNodes
            ) {
                await this.centerView(item, true, true);
            }
        };

        const load = async () => {
            await this.impactAnalysisService.loadExploratoryAnalyticsData(
                EntityIdentifier.fromHdd(hdd),
                this.graphData
            );
            item.isLoading = false;
        };

        if (ExploratoryGraphComponent.behaviour.centerAndNavigateAtOnce) {
            await Promise.all([center(), load()]);
        } else {
            await center();
            await load();
        }

        const newNeighbours = this.graphData
            .getLinkedNodes(item)
            .filter((it) => isNaN(it.x ?? NaN) && isNaN(it.y ?? NaN));
        if (!newNeighbours?.length) {
            return;
        }

        const parent = this.graphData.getLinkedNodes(item)?.[0];
        this.positionChildrenOnCircle(item, newNeighbours, parent);
        this.updateAll(true);
        this.setSelectedItem(item);
    }

    private clusterNode(item: Item) {
        this.log('clusterNode', item?.isCluster, item);
        if (!this.graphData) {
            return;
        }
        this.graphData.clusterNode(item);
        if (item?.isCluster) {
            const updateAndSelect = () => {
                this.updateAll(true);
                this.setSelectedItem(item);
            };
            if (
                ExploratoryGraphComponent.behaviour.centerViewOnItem
                    .onClusterNode
            ) {
                const centerOn =
                    this.graphData.getLinkedNodes(item)?.[0] || item;
                this.centerView(centerOn, true).then(updateAndSelect);
            } else {
                updateAndSelect();
            }
        } else {
            this.setSelectedItem(item);
        }
    }

    private async unclusterNode(item: Item) {
        this.log('unclusterNode', item);
        if (!this.graphData) {
            return;
        }

        if (
            item.childrenClusterNodesId.length >=
            ExploratoryGraphComponent.behaviour
                .minChildrenClusterCountForWarning
        ) {
            const confirmed =
                await this.impactAnalysisService.confirmWarningModal();
            if (!confirmed) {
                return;
            }
        }

        const parent = this.graphData.getLinkedNodes(item)?.[0];
        const siblings = this.graphData.getClusterChildren(item);
        this.graphData.unclusterNode(item);
        this.positionSiblingsOnArc(item, siblings, parent, Math.PI / 4);
        if (
            ExploratoryGraphComponent.behaviour.centerViewOnItem.onUnclusterNode
        ) {
            await this.centerView(parent, true);
        }
        this.updateAll(true);
        this.setSelectedItem(item);
    }

    private navigateToNode(node: Item) {
        this.log('navigateToNode', node);
        if (!this.graphData) {
            return;
        }
        if (!this.enableNavigation) {
            this.setSelectedItem(node);
            return;
        }
        this.centerView(node, true, true).then(() => this.navigateToItem(node));
    }
    private navigateToItem(item: Item) {
        const hdd = this.graphData.getObjectHdd(item);
        this.navigationService.gotoCurrentStateWithHierarchyDataDescriptor(hdd);
    }

    private getActionButtons(enableNavigation = false) {
        const options: IGraphicalToolbarOption[] = [
            this.impactAnalysisService.getToolSelectorOption(
                ImpactAnalysisTool.Explorer
            ),
            {
                isSeparator: true,
            },
            {
                glyphClass: 'glyph-glyph_dggg',
                callback: () => this.loadLinkedNodes(this.selected),
                hidden: () =>
                    !this.selected ||
                    this.selected.isRoot ||
                    this.selected.isClustered,
                tooltipTranslateKey: 'UI.ImpactAnalysis.exploratory.expandNode',
            },
            {
                glyphClass: 'glyph-glyph_ddg2',
                callback: () => this.clusterNode(this.selected),
                hidden: () => this.selected?.isCluster,
                tooltipTranslateKey:
                    'UI.ImpactAnalysis.exploratory.clusterNode',
            },
            {
                glyphClass: 'glyph-glyph_dgg2',
                callback: () => this.unclusterNode(this.selected),
                hidden: () => !this.selected?.isCluster,
                tooltipTranslateKey:
                    'UI.ImpactAnalysis.exploratory.unclusterNode',
            },
        ];

        if (enableNavigation) {
            options.push({
                glyphClass: 'glyph-visual-analysis',
                callback: () => this.navigateToNode(this.selected),
                disabled: () =>
                    !this.selected ||
                    this.selected.isRoot ||
                    this.selected.isClustered,
                tooltipTranslateKey:
                    'UI.ImpactAnalysis.exploratory.startAnalysis',
            });
        }

        return options;
    }

    private makeItemAppearSmoothly(
        item: Item,
        d3newItems: SD3Items,
        fast = true
    ) {
        const d3item = d3newItems.filter((d) => d == item);
        D3Helper.transitionToVisibility(d3item, true, fast);
    }
    private makeLinkedItemsAppearSmoothly(
        d3newItems: SD3Items,
        parentItem: Item,
        fast = false
    ) {
        const linkedItems = this.graphData.getLinkedNodes(parentItem);
        const d3linkedItems = d3newItems.filter((d) => linkedItems.includes(d));
        D3Helper.transitionToVisibility(d3linkedItems, true, fast);
    }
    private makeLinksAppearSmoothly(
        d3newLinks: SD3Links,
        parentItem: Item,
        fast = false
    ) {
        const links = this.graphData.getLinks(parentItem);
        const d3links = d3newLinks.filter((d) => links.includes(d));
        D3Helper.transitionToVisibility(d3links, true, fast);
    }
}

declare type Item = ExplorationGraphNode;
declare type Link = ExplorationGraphLink;
declare type SD3Items = SD3<Item>;
declare type SD3Links = SD3<Link>;

import { UiSpinnerService } from '@datagalaxy/core-ui';
import { saveAs } from 'file-saver';
import { NextObserver, Subject } from 'rxjs';
import { formConfigs } from './connection-form/form-configs/connection-form-configs';
import { ConnectivityService } from './connectivity.service';
import { ConnectorCredentials, ISaveConnectionParams } from './connector.types';
import { DxyConnectorImportHistoryModalComponent } from './dxy-connector-import-history-modal/dxy-connector-import-history-modal.component';
import { IImportHistoryModalResolve } from './dxy-connector-import-history-modal/connector-import-history-modal.types';
import { ModalSize } from '@datagalaxy/ui/dialog';
import { DxyConnectorSchedulerModalComponent } from './dxy-connector-scheduler-modal/dxy-connector-scheduler-modal.component';
import { IConnectorSchedulerModalData } from './dxy-connector-scheduler-modal/dxy-connector-scheduler-modal.types';
import { ISavedConnectionRow } from './saved-connections.types';
import { Injectable } from '@angular/core';
import { ImportEntityTarget } from './dxy-target-selection/target-entity-selector.types';
import { SecurityService } from '../services/security.service';
import { DxyModalService } from '../shared/dialogs/DxyModalService';
import { CurrentSpaceService } from '../services/currentSpace.service';
import { ToasterService } from '../services/toaster.service';
import { EntityService } from '../shared/entity/services/entity.service';
import { ImportWizardService } from '../import/services/import-wizard.service';
import { ConnectorUtil } from './ConnectorUtil';
import { ImportContext, ImportTarget } from '../import/shared/ImportContext';
import {
    EntityType,
    EntityTypeUtil,
    ServerType,
} from '@datagalaxy/dg-object-model';
import { TranslateService } from '@ngx-translate/core';
import {
    Connection,
    Connector,
    ConnectorDownloadApiService,
    ConnectorModule,
    IConnectorPlugin,
    ImportHistory,
} from '@datagalaxy/webclient/connectivity/data-access';
import {
    CrudActionType,
    CrudOperation,
    FunctionalLogService,
} from '@datagalaxy/webclient/monitoring/data-access';
import {
    DEFAULT_ORPHANED_OBJECTS_HANDLING,
    OrphanedObjectsHandling,
} from './connection-form/dxy-connection-form-target/dxy-connection-form-target.types';
import { SpaceIdentifier } from '@datagalaxy/webclient/workspace/utils';
import { ISpaceIdentifier } from '@datagalaxy/webclient/workspace/domain';
import { Filter, FilterOperator } from '@datagalaxy/webclient/filter/domain';
import { IConfigFieldDef } from './connection-form/form-configs/types/interfaces/config-field-def.interface';
import { ConnectionFields } from './connection-form/form-configs/types/models/connection-fields.model';
import { EntityCreator } from '@datagalaxy/webclient/entity/feature';

export const IS_OTHER_USER_PAT = 'otherUserPat';

@Injectable({ providedIn: 'root' })
export class ConnectorService {
    //#region connector-wizard properties
    public pathTransformation: string;
    public connectionCredentials: object;
    public connectionName: string;
    public connectionId: string;
    public connectionVersion?: string;
    public targetSourceName: string;
    public targetModule: string;
    public initialTargetId: string;
    public connectionToken: string;
    public tokenUid: string;
    public targetPlugin: IConnectorPlugin;
    public isConnectorFormValidated: boolean;
    public fields: ConnectionFields;
    public importEntityTargets: ImportEntityTarget[];
    public isEntityTargetCreated: boolean;
    public connectionObjectsLoading = true;
    public selectedObjectNames: string[] = [];
    public orphanedObjectsHandling: OrphanedObjectsHandling =
        DEFAULT_ORPHANED_OBJECTS_HANDLING;

    public get connectorCredentials() {
        return new ConnectorCredentials(this.connectionCredentials);
    }

    public get onlineConnectionSelectedEntitiesText() {
        const selectedCount = this.isAllObjectsSelected
            ? this.onlineConnectionSelectedEntitiesTotal
            : this.selectedObjectNames.length;
        return this.onlineConnectionSelectedEntitiesTotal >= selectedCount
            ? this.translate.instant(
                  'Import.GenericImportWizard.ConnectionForm.ObjectsSelection.selection',
                  {
                      selection: selectedCount,
                      total: this.onlineConnectionSelectedEntitiesTotal,
                  }
              )
            : '';
    }
    public isAllObjectsSelected: boolean;
    private onlineConnectionSelectedEntitiesTotal = 0;
    //#endregion

    //#region connection-form
    public get listFieldUpdateCalled$() {
        return this.listFieldUpdateCallSource.asObservable();
    }
    public readonly listFieldUpdateCallSource = new Subject<void>();
    //#endregion

    //#region connection-list properties
    public get savedConnectionsRefreshCalled$() {
        return this.savedConnectionsRefreshCallSource.asObservable();
    }
    private readonly savedConnectionsRefreshCallSource = new Subject<void>();
    //#endregion

    //#region import-history properties
    public get importUpdateCalled$() {
        return this.importUpdateCallSource.asObservable();
    }
    private readonly importUpdateCallSource = new Subject<ImportHistory>();
    //#endregion

    //#endregion

    private readonly OUTPUT_ORPHANED_OBJECTS_HANDLING: string =
        'orphaned-objects-handling';

    constructor(
        private connectorDownloadApiService: ConnectorDownloadApiService,
        private connectivityService: ConnectivityService,
        private securityService: SecurityService,
        private dxyModalService: DxyModalService,
        private currentSpaceService: CurrentSpaceService,
        private toasterService: ToasterService,
        private entityService: EntityService,
        private entityCreator: EntityCreator,
        private importWizardService: ImportWizardService,
        private functionalLogService: FunctionalLogService,
        private uiSpinnerService: UiSpinnerService,
        private translate: TranslateService
    ) {
        this.fields = new ConnectionFields();
    }

    //#region Plugin List

    public async downloadConnector(name: string) {
        this.functionalLogService.logFunctionalAction(
            `DOWNLOAD_PLUGIN_${name.toUpperCase()}`,
            CrudOperation.A,
            CrudActionType.Download
        );
        const apiResult =
            await this.connectorDownloadApiService.downloadConnectorPackage(
                name
            );
        return saveAs(
            new Blob([apiResult.contents], {
                type: 'application/java-archive',
            }),
            apiResult.filename
        );
    }

    public async downloadConnectorDesktop(arch: '64' | '32') {
        this.functionalLogService.logFunctionalAction(
            'DOWNLOAD_CONNECTOR_DESTK',
            CrudOperation.A,
            CrudActionType.Download
        );
        const apiResult =
            await this.connectorDownloadApiService.downloadConnectorDesktop(
                arch
            );
        return saveAs(
            new Blob([apiResult.contents], {
                type: 'application/x-zip-compressed',
            }),
            apiResult.filename
        );
    }

    public getOnlineConnectorImageUrl(connectorName: string) {
        return this.connectivityService.getConnectorImageUrl(connectorName);
    }

    public listenExecutionStatus(
        operationId: string,
        observer: NextObserver<ImportHistory>
    ) {
        const executionStatusWebSocket =
            this.connectivityService.getExecutionStatusWebSocket(operationId);
        executionStatusWebSocket.subscribe({
            ...observer,
            error: () => {
                /* won't be able to update execution status, not critical, ignoring... */
                /* QUESTION: we should probably log to be able to troubleshoot, but I don't know how :( */
                executionStatusWebSocket.unsubscribe();
            },
            complete: () => {
                executionStatusWebSocket.unsubscribe();
            },
        });
    }

    //#endregion

    //#region used by (routed)saved-connections/omni-grid burger menu
    public async registerConnectionData(
        metadata: ISavedConnectionRow
    ): Promise<void> {
        const {
            credentials,
            name,
            targetModule,
            pluginName,
            versionId,
            selectedObjectNames,
        } = metadata;
        const { entityId, data, tokenUid, connectionId } = credentials ?? {};
        const { connection, transform, output } = data ?? {};
        if (credentials.entityId) {
            this.initialTargetId = entityId;
        }
        this.connectionCredentials = connection;
        this.tokenUid = tokenUid;
        this.connectionId = connectionId;
        this.connectionName = name;
        this.targetModule = targetModule;
        this.pathTransformation = transform?.['structure-path'];
        this.connectionVersion = versionId;
        this.selectedObjectNames = selectedObjectNames ?? [];
        this.fields.dataStructure = connection['data-structure'] || 'TREE';
        if (output) {
            this.orphanedObjectsHandling =
                OrphanedObjectsHandling[
                    output[this.OUTPUT_ORPHANED_OBJECTS_HANDLING]
                ];
        }
        if (!this.orphanedObjectsHandling) {
            this.orphanedObjectsHandling = DEFAULT_ORPHANED_OBJECTS_HANDLING;
        }

        const connector = await this.getConnector(pluginName);
        this.targetPlugin = {
            ...connector,
            iconUrl: this.getOnlineConnectorImageUrl(pluginName),
        };
    }
    //#endregion

    //#region import-wizard

    public buildCredentials() {
        const formConfig = formConfigs[this.targetPlugin.name];
        const credentials = ConnectorUtil.buildPayload(this.fields, formConfig);

        this.fields.maskList?.forEach(
            (mask, i) => (credentials[`structure-masks.${i}`] = mask)
        );

        credentials[`data-structure`] = this.fields.dataStructure.toString();

        this.connectionCredentials = credentials;
    }

    //#endregion

    //#region Apis

    private async saveConnection({
        spaceId,
        versionId,
        entityId,
        credentials,
    }: ISaveConnectionParams) {
        const token =
            this.connectionToken === IS_OTHER_USER_PAT
                ? undefined
                : this.connectionToken;

        credentials.output = {
            token,
            'project-id': spaceId,
            'version-id': versionId,
            'model-id': entityId,
            [this.OUTPUT_ORPHANED_OBJECTS_HANDLING]:
                this.orphanedObjectsHandling,
        };
        credentials.transform = {
            'structure-path': this.fields.pathTransformation,
        };

        const commonPayload = {
            credentials: JSON.stringify(credentials),
            name: this.connectionName,
            entityId,
            integrationToken: token,
            pluginName: this.targetPlugin.name,
            selectedObjectNames:
                this.selectedObjectNames.length ===
                    this.onlineConnectionSelectedEntitiesTotal ||
                this.isAllObjectsSelected
                    ? []
                    : this.selectedObjectNames,
        };

        let connection: Connection;
        if (this.connectionId) {
            connection = await this.connectivityService.updateConnection(
                commonPayload,
                spaceId,
                this.connectionId
            );
        } else {
            connection = await this.connectivityService.createConnection(
                {
                    ...commonPayload,
                    versionId,
                    integrationToken: token,
                    targetModule: this.targetModule,
                },
                spaceId
            );
        }
        this.connectionId = connection.id;

        return connection;
    }

    public async testConnection(spaceId: string, connectionId: string) {
        const { reachable } = await this.connectivityService.testConnection(
            spaceId,
            connectionId
        );
        return {
            reachable,
        };
    }

    public async executeWithCurrentConnectionId(spaceId: string) {
        if (!this.connectionId) {
            return;
        }
        try {
            const featureCode = `IMPORT_CONNECTION_${this.targetPlugin.name.toUpperCase()}`;
            this.functionalLogService.logFunctionalAction(
                featureCode,
                CrudOperation.A,
                CrudActionType.Import
            );
            const operationId = await this.execute(spaceId, this.connectionId);
            this.listenExecutionStatus(operationId, {
                next: (value: ImportHistory) => {
                    this.notifyImportUpdate(value);
                },
            });
            const executeMsg = `Operation id: ${operationId}`;
            this.toasterService.successToast({
                titleKey: 'UI.Connector.Wizard.Step4.Execute.Success.Title',
                messageKey: executeMsg,
            });
            this.savedConnectionsRefresh();
        } catch (error) {
            this.toasterService.errorToast({
                titleKey: 'UI.Connector.Wizard.Step4.Execute.Failure.Title',
                messageKey: error.data.error.message,
            });
            this.savedConnectionsRefresh();
        }
    }

    private async execute(
        spaceId: string,
        connectionId: string
    ): Promise<string> {
        const result = await this.connectivityService.executeConnection(
            spaceId,
            connectionId
        );
        return result.operationId;
    }

    public async getConnectionsCount(): Promise<number> {
        const result = await this.getConnections();
        return result.length;
    }

    public async getConnections(): Promise<Connection[]> {
        const result = await this.connectivityService.getConnections();
        return result.connections;
    }

    public async getConnector(pluginName: string): Promise<Connector> {
        return (await this.connectivityService.getConnector(pluginName))
            .connector;
    }

    /**
     * Requests Connectivity API to fetch the list of existing Online Connector Plugins.
     * @returns List of Online Connector Plugins that have a registered form in `connection-form-configs.ts`
     * or all Plugins if Client Access Flag EnableOnlineConnectionTextEditor is true
     */
    public async getConnectorsOnline(): Promise<Connector[]> {
        const result = await this.connectivityService.getConnectors();
        const registeredConnectorForms = Object.keys(formConfigs);
        return result.connectors.filter(
            (c) =>
                registeredConnectorForms.includes(c.name) ||
                this.securityService.isOnlineConnectorTextEditorEnabled()
        );
    }

    public async deleteConnection(connectionId: string): Promise<void> {
        return this.connectivityService.deleteConnection(connectionId);
    }

    public async duplicateConnection(
        connection: ISavedConnectionRow
    ): Promise<Connection> {
        const featureCode = `DUPLICATE_CONNECTION_${connection.pluginName.toUpperCase()}`;
        this.functionalLogService.logFunctionalAction(
            featureCode,
            CrudOperation.C
        );
        return this.connectivityService.duplicateConnection(
            connection.credentials.connectionId
        );
    }

    public async renameConnection(connectionId: string, name: string) {
        const featureCode = `RENAME_CONNECTION_${this.targetPlugin.name.toUpperCase()}`;
        this.functionalLogService.logFunctionalAction(
            featureCode,
            CrudOperation.U
        );
        return this.connectivityService.updateConnection(
            { name },
            this.currentSpaceService.getCurrentSpace().ReferenceId,
            connectionId
        );
    }

    public async getImportHistory(
        connectionId: string
    ): Promise<ImportHistory[]> {
        const result = await this.connectivityService.getImportsHistory(
            connectionId
        );
        return result.importsHistory;
    }

    //#endregion

    //#region scheduler

    public async getScheduling(connectionId: string) {
        return await this.connectivityService.getScheduling(connectionId);
    }

    public async createScheduling(
        connectionId: string,
        cron: string,
        suspend?: boolean,
        timezone?: string
    ) {
        return await this.connectivityService.createScheduling(
            connectionId,
            cron,
            suspend,
            timezone
        );
    }

    public async updateScheduling(
        connectionId: string,
        cron?: string,
        suspend?: boolean,
        timezone?: string
    ) {
        return await this.connectivityService.updateScheduling(
            connectionId,
            cron,
            suspend,
            timezone
        );
    }

    public async openSchedulerModal(metadata: ISavedConnectionRow) {
        await this.dxyModalService.open<
            DxyConnectorSchedulerModalComponent,
            IConnectorSchedulerModalData,
            void
        >({
            componentType: DxyConnectorSchedulerModalComponent,
            disableCloseOnBackdropClick: true,
            width: 'auto',
            data: {
                connection: {
                    id: metadata.credentials.connectionId,
                    hasScheduling: Boolean(metadata.nextExecution),
                },
                pluginName: metadata.pluginName,
            },
        });
    }

    //#endregion

    //#region saved-connections

    public savedConnectionsRefresh() {
        this.savedConnectionsRefreshCallSource.next();
    }

    public async openSavedConnection(metadata: ISavedConnectionRow) {
        this.resetStoredData();
        await this.uiSpinnerService.executeWithSpinner(
            () => this.registerConnectionData(metadata),
            undefined,
            undefined,
            0
        );
        const space = this.currentSpaceService.getCurrentSpace();
        await this.importWizardService.openImportWizardModal(
            new ImportContext(
                new SpaceIdentifier(space.spaceId, this.connectionVersion),
                ImportTarget.Entities,
                null,
                null,
                null,
                true
            )
        );
    }

    public async confirmDeleteModal(pluginName: string) {
        const featureCode = `DELETE_CONNECTION_${pluginName.toUpperCase()},D`;
        return this.dxyModalService.confirmDeleteObject(null, { featureCode });
    }

    public async loadEntities() {
        const searchValues = [
            EntityType.RelationalModel,
            EntityType.NonRelationalModel,
            EntityType.NoSqlModel,
            EntityType.TagBase,
            EntityType.Application,
        ].map((value) => EntityType[value]);
        const typeFilter = new Filter(
            '_EntityType',
            FilterOperator.ListContains,
            searchValues
        );
        const response = await this.entityService.getEntities(
            this.currentSpaceService.getCurrentSpace(),
            [ServerType.Model, ServerType.SoftwareElement],
            true,
            0,
            5000,
            [typeFilter],
            ['DisplayName']
        );
        return response.Entities;
    }

    //#endregion

    //#region connection-form

    public resetStoredData() {
        this.connectionCredentials = null;
        this.connectionName = '';
        this.connectionId = null;
        this.connectionToken = '';
        this.selectedObjectNames = [];
        this.orphanedObjectsHandling = DEFAULT_ORPHANED_OBJECTS_HANDLING;
        this.resetSourceData();
    }

    public resetSourceData() {
        this.importEntityTargets = null;
        this.initialTargetId = null;
        this.isEntityTargetCreated = false;
    }

    public async initImportEntityTargets(
        spaceIdr: SpaceIdentifier,
        getDefaultTargetEntityName: (target: ImportEntityTarget) => string
    ) {
        const sourceTypeName =
            this.targetSourceName ?? this.targetPlugin.sourceType;

        this.importEntityTargets = await Promise.all(
            this.targetPlugin.modules.map(async (module: ConnectorModule) => {
                const importEntityTarget = new ImportEntityTarget(
                    module,
                    sourceTypeName
                );
                await this.initTargetAvailableEntities(
                    spaceIdr,
                    importEntityTarget
                );
                importEntityTarget.newEntityName =
                    getDefaultTargetEntityName(importEntityTarget);

                this.initTargetDefaultEntity(importEntityTarget);
                return importEntityTarget;
            })
        );
    }

    public async initTargetAvailableEntities(
        spaceIdr: ISpaceIdentifier,
        entityTarget: ImportEntityTarget
    ) {
        const searchValue = EntityTypeUtil.getEntityType(
            ServerType[entityTarget.serverType],
            entityTarget.subTypeName
        );
        const typeFilter = new Filter(
            '_EntityType',
            FilterOperator.ListContains,
            [EntityType[searchValue]]
        );
        const { Entities } =
            await this.entityService.getTargetEntitiesFromServerType(
                null,
                spaceIdr,
                entityTarget.serverType,
                1000,
                [typeFilter]
            );
        entityTarget.availableEntities = Entities.filter(
            (entity) =>
                (entityTarget.subTypeName
                    ? entity.SubTypeName === entityTarget.subTypeName
                    : true) && !entity.HddData.Parents.length
        );
    }

    public presetAttributesFromPlugin(pluginName: string) {
        const savedCredentials = this.connectionCredentials as {
            [key: string]: string;
        };
        this.setInitialValues(formConfigs[pluginName], savedCredentials);
        this.fields.maskList = ConnectorUtil.buildMaskList(savedCredentials);
        this.fields.pathTransformation = this.pathTransformation;
        this.listFieldUpdateCallSource.next();
    }

    public setFieldsPortFromPlugin(pluginName: string) {
        const port = ConnectorUtil.getDefaultPort(pluginName);
        this.fields.port = port?.toString() ?? '';
    }

    private setInitialValues(
        confs: IConfigFieldDef[],
        savedConnection: object
    ) {
        confs.forEach((conf) => {
            if (savedConnection[conf.payloadField] != undefined) {
                this.fields[conf.formField] =
                    savedConnection[conf.payloadField];
            }
            if (!conf.dependencies) {
                return;
            }
            conf.dependencies.forEach((dep) => {
                if (
                    savedConnection[dep.field.payloadField] != undefined &&
                    dep.show(savedConnection[conf.payloadField])
                ) {
                    this.fields[dep.field.formField] =
                        savedConnection[dep.field.payloadField];
                }
            });
        });
    }

    private initTargetDefaultEntity(target: ImportEntityTarget) {
        if (target.isCatalogModule) {
            target.selectedEntityId = this.initialTargetId;
            target.isUpdate = !!target.selectedEntityId;
            return;
        }
        const entityName = target.isUsageModule
            ? this.connectorCredentials.connection?.['root-application-name']
            : this.connectorCredentials.connection?.['root-dataflow-name'];

        target.selectedEntityId = target.availableEntities.find(
            (entity) =>
                entity.SubTypeName == target.subTypeName &&
                entity.DisplayName == entityName
        )?.DataReferenceId;
        target.isUpdate = !!target.selectedEntityId;
    }

    //#endregion

    //#region import-history

    public async openImportHistoryModal(metadata: ISavedConnectionRow) {
        await this.dxyModalService.open<
            DxyConnectorImportHistoryModalComponent,
            IImportHistoryModalResolve,
            void
        >({
            componentType: DxyConnectorImportHistoryModalComponent,
            size: ModalSize.Large,
            data: {
                connectionId: metadata.credentials.connectionId,
            },
        });
    }

    private notifyImportUpdate(importUpdate: ImportHistory) {
        this.importUpdateCallSource.next(importUpdate);
    }

    //#endregion

    //#region general

    public async openRenameModal() {
        const result = await this.dxyModalService.prompt({
            titleKey: 'UI.Connector.SavedConnections.renameModalTitle',
            userInputLabelKey: 'UI.Connector.Wizard.Step3.displayName',
            userInputValue: this.connectionName,
            confirmButtonKey: 'UI.Global.btnRename',
        });

        if (result?.trim()) {
            await this.renameConnection(this.connectionId, result);
            this.savedConnectionsRefresh();
        }
    }

    public async getCurrentConnectionFoundObjects(spaceId: string) {
        const result = await this.connectivityService.getConnectionObjects(
            spaceId,
            this.connectionId
        );
        this.onlineConnectionSelectedEntitiesTotal = result.objects.length;
        return result.objects;
    }

    //#endregion

    public async preSaveConnection(
        spaceIdr: SpaceIdentifier,
        isConnectionUpdate: boolean
    ) {
        const featureCode = `TEST_SAVE_CONNECTION_${this.targetPlugin.name.toUpperCase()}`;
        const crudOperation = isConnectionUpdate
            ? CrudOperation.U
            : CrudOperation.C;
        this.functionalLogService.logFunctionalAction(
            featureCode,
            crudOperation
        );

        await Promise.all(
            this.importEntityTargets.map(async (target) => {
                if (!target.isUpdate && !this.isEntityTargetCreated) {
                    target.selectedEntityId = await this.createEntity(
                        target,
                        spaceIdr
                    );
                }
            })
        );

        const sourceId = this.importEntityTargets.find(
            (target) => target.isCatalogModule
        )?.selectedEntityId;

        const credentials = this.connectorCredentials;

        const usageTarget = this.importEntityTargets.find(
            (target) => target.isUsageModule
        );
        if (usageTarget) {
            credentials.connection['root-application-name'] =
                usageTarget.isUpdate
                    ? usageTarget.availableEntities.find(
                          (entity) =>
                              entity.DataReferenceId ==
                              usageTarget.selectedEntityId
                      )?.DisplayName
                    : usageTarget.newEntityName;
        }

        const processingTarget = this.importEntityTargets.find(
            (target) => target.isProcessingModule
        );
        if (processingTarget) {
            credentials.connection['root-dataflow-name'] =
                processingTarget.isUpdate
                    ? processingTarget.availableEntities.find(
                          (entity) =>
                              entity.DataReferenceId ==
                              processingTarget.selectedEntityId
                      )?.DisplayName
                    : processingTarget.newEntityName;
        }

        const connection = await this.saveConnection({
            credentials,
            entityId: sourceId,
            spaceId: spaceIdr.spaceId,
            versionId: spaceIdr.versionId,
        });
        this.connectionCredentials = credentials.connection;
        this.savedConnectionsRefresh();
        return connection;
    }

    private async createEntity(
        importEntityTarget: ImportEntityTarget,
        spaceIdr: SpaceIdentifier
    ): Promise<string> {
        const source = await this.entityCreator.createEntity(
            spaceIdr.spaceId,
            spaceIdr.versionId,
            EntityTypeUtil.getEntityType(
                ServerType[importEntityTarget.serverType],
                importEntityTarget.subTypeName
            ),
            importEntityTarget.newEntityName
        );
        this.isEntityTargetCreated = !!source;
        return source.ReferenceId;
    }
}

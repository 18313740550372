import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ClientSpacesListComponent } from './client-spaces-list/client-spaces-list.component';
import { DxyOmnigridModule } from '@datagalaxy/core-ui/omnigrid';
import { NavbarModule } from '../navbar/navbar.module';
import { SharedModule } from '../shared/shared.module';
import { DxySpotlightModule } from '../spotlight/DxySpotlightModule';
import { NotificationModule } from '../notifications/notification.module';
import { DxyEntityPanelsModule } from '../entity-panels/DxyEntityPanelsModule';
import { FieldsModule } from '../shared/fields/fields.module';
import { DxySharedUiModule } from '../shared/shared-ui/DxySharedUiModule';
import { SuggestionModule } from '../suggestions/suggestion.module';
import { ClientMainComponent } from './client-main/client-main.component';
import { DxySearchModule } from '../search/DxySearchModule';
import { DataQualityModule } from '../data-quality/data-quality.module';
import { DxyInsightsModule } from '../insights/DxyInsightsModule';
import { DxyEntityDashboardModule } from '../entity-dashboard/DxyEntityDashboardModule';
import { DxyScreensModule } from '../screens/DxyScreensModule';
import { ExportModule } from '../export/ExportModule';
import { DxyConnectorModule } from '../connector/DxyConnectorModule';
import { DxyFilterModule } from '../shared/filter/DxyFilterModule';
import { DxyActivityLogModule } from '../activityLog/DxyActivityLogModule';
import { DxyImportModule } from '../import/DxyImportModule';
import { DxyTaskModule } from '../tasks/DxyTaskModule';
import { DxyCommentaryModule } from '../commentary/DxyCommentaryModule';
import { DxyModuleMainModule } from '../moduleMain/DxyModuleMainModule';
import { GlossaryModule } from '../glossary/glossary-module';
import { DxyModelerModule } from '../modeler/DxyModelerModule';
import { DxySoftwareModule } from '../software/DxySoftwareModule';
import { DataProcessingModule } from '../data-processing/DataProcessingModule';
import { DxyAboutInfoModule } from '../about-info/DxyAboutInfoModule';
import { DxyApiDocModule } from '../api-doc/DxyApiDocModule';
import { DxyExtensionModule } from '../dxy-extension-modal/DxyExtensionModule';
import { DxyVersioningModule } from '../versioning/DxyVersioningModule';
import { SpaceModule } from '../space/space.module';
import { DxyOrganizationDashboardModule } from '../organization-dashboard/DxyOrganizationDashboardModule';
import { DxySupportModule } from '../shared/support/DxySupportModule';
import { DxySharedEntityModule } from '../shared/entity/DxySharedEntityModule';
import { DxyFileOptionsModule } from '../shared/file-options/DxyFileOptionsModule';
import { DxyPublicEditModalModule } from '../shared/public-edit-modal/DxyPublicEditModalModule';
import { EntityCardModule } from '../shared/entityCard/entity-card.module';
import { DxySpaceSelectorModule } from '../space-selector/DxySpaceSelectorModule';
import { DxyAttributesModule } from '../shared/attribute/DxyAttributesModule';
import { DxyScreensLayoutModule } from '../shared/screens-layout/DxyScreensLayoutModule';
import { DxyNavigationModule } from '../navigation/DxyNavigationModule';
import { DxySharedDialogsModule } from '../shared/dialogs/DxySharedDialogsModule';
import { DxyMatBurgerMenuModule } from '@datagalaxy/core-ui';
import { ClientFirstAccessComponent } from './first-access/client-first-access.component';
import { DxyImageInputModule } from '@datagalaxy/core-ui/image-input';
import { CheckboxComponent } from '@datagalaxy/ui/forms';
import { SplashScreenComponent } from '@datagalaxy/ui/illustration';
import { LanguageSelectorButtonComponent } from '@datagalaxy/webclient/multilingual/feature';
import { DxyButtonsModule } from '@datagalaxy/ui/buttons';
import { GridComponent } from '@datagalaxy/ui/grid';

const clientSharedImports = [
    SharedModule,
    FieldsModule,
    DxySharedDialogsModule,
    NavbarModule,
    DxySpotlightModule,
    NotificationModule,
    DxyEntityPanelsModule,
    DxySharedUiModule,
    DxyOmnigridModule,
    SuggestionModule,
    DxySearchModule,
    DataQualityModule,
    NotificationModule,
    DxyInsightsModule,
    DxyEntityPanelsModule,
    DxyEntityDashboardModule,
    DxyScreensModule,
    ExportModule,
    DxyConnectorModule,
    DxySpotlightModule,
    DxyFilterModule,
    DxyActivityLogModule,
    SuggestionModule,
    DxyImportModule,
    DxyTaskModule,
    DxyCommentaryModule,
    DxyModuleMainModule,
    GlossaryModule,
    DxyModelerModule,
    DxySoftwareModule,
    DataProcessingModule,
    DxyAboutInfoModule,
    DxyApiDocModule,
    DxyExtensionModule,
    DxyVersioningModule,
    SpaceModule,
    DxyOrganizationDashboardModule,
    DxySupportModule,
    NavbarModule,
    //#region shared component modules
    DxySharedEntityModule,
    DxyFileOptionsModule,
    DxyPublicEditModalModule,
    EntityCardModule,
    DxySpaceSelectorModule,
    DxyAttributesModule,
    DxyScreensLayoutModule,
    DxyNavigationModule,
    DxyMatBurgerMenuModule,
    DxyImageInputModule,
    //#endregion
    // #region Standalone components
    CheckboxComponent,
    SplashScreenComponent,
    LanguageSelectorButtonComponent,
    // #endregion
    DxyButtonsModule,
    GridComponent,
];

@NgModule({
    imports: clientSharedImports,
    exports: clientSharedImports,
    declarations: [
        ClientSpacesListComponent,
        ClientFirstAccessComponent,
        ClientMainComponent,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ClientSharedModule {
    constructor() {}
}

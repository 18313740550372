import { select as d3select, Selection } from 'd3-selection';
import { D3Helper, SD3SvgDefs } from '@datagalaxy/core-d3-util';
import { CoreUtil } from '@datagalaxy/core-util';
import { ViewType } from '../../../shared/util/app-types/ViewType';
import { GlyphUtil } from '../../../shared/util/GlyphUtil';
import { LineageItem } from '../data/LineageItem';
import { LineageLink, VirtualType } from '../data/LineageLink';
import { LineageUiElementType, SD3Items, SD3Links } from '../lineage.utils';
import { LineageConstants } from '../data/LineageConstants';
import { IMarkerSpec } from '@datagalaxy/core-2d-util';
import { LineageLinkOrientationType } from '@datagalaxy/webclient/explorer/data-access';

/**
 * Lineage graph sub-component for creating and updating SVG dom elements.
 * Modifies only the dom, except the calls to LineageLink.computeVisibility().
 */
export class LineageRenderer {
    public viewType: ViewType;
    private frontItem: LineageItem;

    constructor(
        private itemSpec: LineageConstants,
        /** get the nodes and data of every hierarchical group */
        private d3HGroups: () => SD3Items,
        /** get the nodes and data of all (or some) items */
        private d3Items: (filter?: string, selector?: string) => SD3Items,
        /** get the nodes and data of all (or some) links */
        private d3Links: (filter?: string) => SD3Links,
        private onElementCreated: (
            d3item: SD3Items,
            elementType: LineageUiElementType
        ) => void,
        /** returns true if any item is hierarchically ellipsed */
        private isAnyHEllipsed: () => boolean,
        private getHEllipsisText: (d: LineageItem) => string,
        private hEllipsedClickDisabled: boolean,
        private onItemTextDrawn: (
            item: LineageItem,
            wholeTextWidth: number,
            node?: SVGTextElement,
            wholeText?: string
        ) => void,
        private debug: { showBlueprints: boolean },
        private getItemColor: (d: LineageItem) => string,
        private setTooltip: (el: Element, message: string) => void,
        private removeTooltip: (el) => void
    ) {}

    //#region public

    //#region create

    /** append svg 'marker' and 'use' definitions to the defs element */
    public createDefs(defs: SD3SvgDefs) {
        this.log('createDefs', defs);

        //#region link markers

        const tsf = LineageConstants.linkEnd.trackSizeFactor;

        const appendLinkMarker = (isDot: boolean, isTrack = false) => {
            const getSpec = <T extends IMarkerSpec>(spec: T) => {
                if (isTrack && tsf) {
                    // for not to double link ends size when doubling links stroke width
                    spec = CoreUtil.clone(spec);
                    (spec.markerHeight as number) *= tsf;
                    (spec.markerWidth as number) *= tsf;
                }
                return spec;
            };

            const markerId = this.getLinkMarkerId(isDot, isTrack);

            if (isDot) {
                const spec = getSpec(LineageConstants.linkDot);
                D3Helper.appendMarker(defs, { ...spec, id: markerId })
                    .append('circle')
                    .attr('cx', spec.cx)
                    .attr('cy', spec.cy)
                    .attr('r', spec.r);
            } else {
                const spec = getSpec(LineageConstants.linkArrow);
                D3Helper.appendMarker(defs, {
                    ...spec,
                    id: markerId,
                    orient: 'auto',
                })
                    .append('path')
                    .attr('d', spec.path);
            }
        };

        // one marker def for each type/style
        const tf = [true, false];
        tf.forEach((isDot) =>
            tf.forEach((isTrack) => appendLinkMarker(isDot, isTrack))
        );

        //#endregion

        //#region expander caret

        const caretCircle = LineageConstants.expanderCaretCircle;
        defs.append('circle')
            .attr('id', 'caret-circle')
            .attr('cx', caretCircle.cx)
            .attr('cy', caretCircle.cy)
            .attr('r', caretCircle.r);
        defs.append('circle')
            .attr('id', 'caret-circle-lzy')
            .attr('cx', caretCircle.cx)
            .attr('cy', caretCircle.cy)
            .attr('r', caretCircle.r);

        if (!LineageConstants.behaviour.lazyItems.enableLoadChildren) {
            const lzyCaret = defs.append('g').attr('id', 'lzy-caret-circle');
            lzyCaret
                .append('circle')
                .attr('class', 'outer')
                .attr('cx', caretCircle.cx)
                .attr('cy', caretCircle.cy)
                .attr('r', caretCircle.r);
            lzyCaret
                .append('circle')
                .attr('class', 'inner')
                .attr('cx', caretCircle.cx)
                .attr('cy', caretCircle.cy)
                .attr('r', caretCircle.r / 2);
        }

        //#endregion

        //#region hover icons
        const createHoverIcon = (id: string, glyphText: string) => {
            const d3Icon = defs.append('g').attr('id', id).attr('class', id),
                r = this.itemSpec.hoverIcon.width / 2,
                cx = r,
                cy = this.itemSpec.minHeight(0) / 2;

            d3Icon.append('rect').attr('class', 'hover-icon-background');

            d3Icon
                .append('circle')
                .attr('r', r)
                .attr('transform', `translate(${cx},${cy})`);

            d3Icon
                .append('text')
                .text(glyphText)
                .attr('transform', `translate(${cx},${cy + 2})`)
                // for firefox ESR68
                .attr(
                    'style',
                    `font-size: 1.2rem; dominant-baseline: middle; text-anchor: middle; fill: ${LineageConstants.textColor}`
                );

            return d3Icon;
        };

        createHoverIcon('burger-icon', GlyphUtil.glyphCharSplitter);
        createHoverIcon('preview-icon', GlyphUtil.glyphCharPreview);

        //#endregion

        //#region link gradient
        const createLinkLineardGradient = (
            className: string,
            startColor: string,
            endColor: string
        ) => {
            const gradient = defs
                .append('linearGradient')
                .attr('id', className)
                .attr('x1', '0%')
                .attr('x2', '100%');

            gradient
                .append('stop')
                .attr('class', 'start')
                .attr('offset', '0%')
                .attr('stop-color', startColor)
                .attr('stop-opacity', 1);

            gradient
                .append('stop')
                .attr('class', 'end')
                .attr('offset', '100%')
                .attr('stop-color', endColor)
                .attr('stop-opacity', 1);

            return gradient;
        };
        createLinkLineardGradient(
            'link-path-gradient',
            LineageConstants.pathLinkGradientStartColor,
            LineageConstants.pathLinkGradientEndColor
        );
        createLinkLineardGradient(
            'link-path-gradient-swapped',
            LineageConstants.pathLinkGradientEndColor,
            LineageConstants.pathLinkGradientStartColor
        );
    }

    public createElements(
        itemsByRoot: Map<LineageItem, LineageItem[]>,
        links: LineageLink[]
    ) {
        const d3newItems = this.createHGroups(itemsByRoot);
        this.debug &&
            this.log(
                'createElements',
                'd3newItems',
                d3newItems.size(),
                d3newItems.data()
            );
        const { d3heads, d3icons, d3names } =
            this.setupNewItemElements(d3newItems);
        const d3newLinks = this.createLinkElements(links);

        this.redrawLinkMarkers(d3newLinks);

        return { d3newItems, d3heads, d3icons, d3names, d3newLinks };
    }

    //#endregion

    //#region update

    public showHideBurgerIcon(show: boolean, item: LineageItem) {
        this.log('showHideBurgerIcon', show, item);
        this.showHideHoverIcon(
            show,
            item,
            'burger-icon',
            LineageUiElementType.BurgerIcon,
            0,
            'UI.ImpactAnalysis.lineage.ttMoreOptions'
        );
    }
    public showHidePreviewIcon(show: boolean, item: LineageItem) {
        this.log('showHidePreviewIcon', show, item);
        this.showHideHoverIcon(
            show,
            item,
            'preview-icon',
            LineageUiElementType.PreviewIcon,
            1,
            'UI.ImpactAnalysis.lineage.ttOpenPane'
        );
    }
    private showHideHoverIcon(
        show: boolean,
        item: LineageItem,
        iconId: string,
        elementType: LineageUiElementType,
        iconIndex: number,
        tooltipTranslateText: string
    ) {
        if (!item) {
            return;
        }
        const d3itemHead = this.d3Items(' .ghead', item.selector);
        const d3icon = d3itemHead.select(`g.${iconId}-container`);
        if (show && d3icon.empty()) {
            const d3icon = d3itemHead
                .append('g')
                .attr('class', `${iconId}-container hover-icon-container`);
            const x = (it: LineageItem) =>
                it.width -
                this.itemSpec.itemHeadMargin -
                (this.itemSpec.hoverIcon.width +
                    this.itemSpec.hoverIcon.margin) *
                    (1 + iconIndex);

            d3icon.attr('transform', (d) => `translate(${x(d)},0)`);
            d3icon
                .append('use')
                .attr('href', `#${iconId}`)
                .attr('class', 'hover-icon-content');
            const el = document.getElementsByClassName(
                `${iconId}-container`
            )[0];
            this.setTooltip(el, tooltipTranslateText);
            this.onElementCreated(d3icon, elementType);
        } else if (!show && !d3icon.empty()) {
            const el = document.getElementsByClassName(
                `${iconId}-container`
            )[0];
            this.removeTooltip(el);
            d3icon.remove();
        }
    }

    public showHideGoldenLinksAndItems(show: boolean) {
        const links = this.d3Links().filter(
            (d) => d.isGoldenLink || d.isVirtualGoldenLink
        );
        const items = this.d3Items().filter((d) => d.isGoldenItem);

        links.selectAll('.golden-link-path').classed('hidden', !show);
        items.selectAll('.golden-item-icon').classed('hidden', !show);
    }

    public bringToFront(root: LineageItem) {
        if (this.frontItem == root) {
            return;
        }
        this.frontItem = root;
        this.log('bringToFront', root);
        // move the root's hierarchical group to last position in the dom (and preserve events)
        this.d3HGroups().sort((a, b) => (a == root ? 1 : b == root ? -1 : 0));
    }

    public onViewTypeChanged(viewType: ViewType) {
        this.log('onViewTypeChanged');
        this.viewType = viewType;
        this.redrawText(this.d3Items(':not(.hidden)'));
    }

    public onMoved(root: LineageItem) {
        //this.log('onMoved')
        this.withItemAndDescendants(root, this.redrawPosition);
        this.redrawHGroupLinks(root, false);
    }

    public onHorizontallyFlipped() {
        this.log('onHorizontallyFlipped');
        this.redraw({ includeHEllipsed: true, position: true, links: true });
    }

    public onExpandedOrCollapsed(
        item: LineageItem,
        updatedHGroups: LineageItem[]
    ) {
        this.log('onExpandedOrCollapsed', item, updatedHGroups);

        updatedHGroups.forEach((r) =>
            this.redrawHeightAndPositionItemAndDescendants(r)
        );

        const clearOnly = !item.isExpanded && !this.isAnyHEllipsed();
        this.redrawHEllipsisDescendants(item, clearOnly);

        this.redrawCaretAndDescendantsVisibilityCaretAndText(item);

        const root = item.root;
        this.redrawHGroupsLinks(updatedHGroups, (r) => r == root);
    }

    public onHEllipsisToggled(
        item: LineageItem,
        updatedHGroups: LineageItem[]
    ) {
        this.log('onHEllipsisToggled', item, updatedHGroups);

        updatedHGroups.forEach((r) =>
            this.redrawHeightAndPositionItemAndDescendants(r)
        );

        const clearOnly = !this.isAnyHEllipsed();
        this.redrawHEllipsisDescendants(item.root, clearOnly);

        this.withItemAndDescendants(item, (d3items) => {
            const d3visibleItems = d3items.filter((d) =>
                d.isVisibleOrHEllipsed()
            );
            this.redrawWidth(d3visibleItems);
        });

        this.redrawCaretAndDescendantsVisibilityCaretAndText(item);

        const root = item.root;
        this.redrawHGroupsLinks(updatedHGroups, (r) => r == root);
    }

    public onGlobalExpandedOrCollapsed(dirty: {
        toggledItems: Set<LineageItem>;
        toggledRoots: LineageItem[];
        updatedHGroups: LineageItem[];
    }) {
        this.log('onGlobalExpandedOrCollapsed', dirty);

        const { toggledItems, toggledRoots, updatedHGroups } = dirty;

        const noHEllipsis = !this.isAnyHEllipsed();
        updatedHGroups.forEach((r) => {
            this.redrawHeightAndPositionItemAndDescendants(r);
            this.redrawHEllipsisDescendants(r, !r.isExpanded && noHEllipsis);
        });

        toggledItems.forEach((it) =>
            this.redrawCaretAndDescendantsVisibilityCaretAndText(it)
        );

        // redraw links, compute visibility for toggled roots
        this.redrawHGroupsLinks(
            updatedHGroups,
            (r) => toggledRoots.indexOf(r) != -1
        );
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public onGlobalHierarchicalEllipsisToggled(updatedHGroups: LineageItem[]) {
        this.log('onGlobalHierarchicalEllipsisToggled');
        this.redraw({
            hEllipsis: true,
            visibility: true,
            includeHEllipsed: true,
            position: true,
            height: true,
            width: true,
            text: true,
            linksVisibility: true,
        });
    }

    public onLayoutReset(applyHEllipsis: boolean) {
        this.log('onLayoutReset');
        this.redraw({
            hEllipsis: true,
            visibility: true,
            includeHEllipsed: applyHEllipsis,
            position: true,
            height: true,
            width: true,
            caret: true,
            text: true,
            linksVisibility: true,
        });
    }

    public redrawLinkMarkers(d3links: SD3Links, isTrack = false) {
        //this.log('redrawLinkMarkers')
        d3links
            .select('path.link-path')
            .attr(
                'marker-start',
                (d) => `url(#${this.getMarkerId(true, d, isTrack)})`
            )
            .attr(
                'marker-end',
                (d) => `url(#${this.getMarkerId(false, d, isTrack)})`
            );
    }

    //#endregion public-update

    //#endregion public

    //#region private

    //#region create

    /** wraps a root and the (flat) list of its descendants in a container.
     * Returns the added items as a d3 selection */
    private createHGroups(itemsByRoot: Map<LineageItem, LineageItem[]>) {
        const allRoots = Array.from(itemsByRoot.keys());
        this.log('createHGroups', allRoots);

        this.d3HGroups()
            .data(allRoots, (d) => d.id)
            .enter()
            .append('g')
            .attr('class', (d) => `hgroup hg${d.id}`);

        return this.d3HGroups()
            .selectAll<SVGGElement, LineageItem>('g')
            .data(
                (d) => itemsByRoot.get(d),
                (d) => d.id
            )
            .enter()
            .append('g');
    }

    private setupNewItemElements(d3newItems: SD3Items) {
        const self = this;
        const d3newRoots = d3newItems.filter((d) => d.isRoot);

        // setup the top container
        d3newItems
            .attr('data-id', (d) => d.id)
            .attr('class', (d) => d.getClass());

        // add the main visible box to each item
        d3newItems
            .append('rect')
            .attr('class', 'databox')
            .attr('width', (d) => d.width);

        // set the left colored border to the root items

        d3newRoots
            .append('rect')
            .attr('class', (d) => `left-border ${this.getItemColor(d)}`);

        // the item's head: caret, icon, text
        const d3heads = d3newItems.append('g').attr('class', 'ghead');

        d3heads
            .append('rect')
            .attr('class', 'item-head')
            .attr('transform', (d) => d.headTransform)
            .attr('width', (d) => d.headWidth)
            .attr('height', (d) => d.headHeight);

        let filterExpander = (d: LineageItem) =>
            d.hasChildren || d.hasLazyChildren;
        if (!LineageConstants.behaviour.lazyItems.enableLoadChildren) {
            filterExpander = (d: LineageItem) => d.hasChildren;

            const d3lzyCarets = d3newItems
                .filter((d) => d.hasLazyChildren)
                .select('g.ghead')
                .append('g')
                .attr('class', 'lzy-caret');
            d3lzyCarets.append('use').attr('href', '#lzy-caret-circle');
            d3lzyCarets.each(function () {
                self.setTooltip(
                    this,
                    'UI.ImpactAnalysis.lineage.ttLazyItemsParent'
                );
            });
        }

        const expanderCaret = d3newItems
            .filter(filterExpander)
            .select('g.ghead')
            .append('g')
            .attr('class', 'expander-caret');
        expanderCaret
            .append('use')
            .attr('href', (d) =>
                d.hasLazyChildren ? '#caret-circle-lzy' : '#caret-circle'
            );
        expanderCaret.append('text').text(GlyphUtil.glyphCharExpanded);

        // add the icon
        const d3icons = d3heads
            .append('text')
            .attr('class', (d) => `item-icon ${this.getItemColor(d)}`)
            .text((d) => GlyphUtil.getEntityTypeGlyphChar(d.entityType));

        // add the text placeholder
        const d3names = d3heads.append('text').attr('class', 'item-name');

        // add golden item icon
        const d3goldenItemIcon = this.createGoldenItemIcon(d3heads);
        d3goldenItemIcon.each(function () {
            self.setTooltip(this, 'UI.ImpactAnalysis.lineage.ttGoldenItemIcon');
        });

        if (this.debug) {
            if (this.debug.showBlueprints) {
                d3newItems
                    .append('rect')
                    .attr('class', 'item-head-debug')
                    .attr('width', (d) => d.width)
                    .attr('height', (d) => d.minHeight);
            }
            d3newItems
                .append('rect')
                .attr('class', 'item-name item-name-debug');
        }

        return { d3heads, d3icons, d3names, d3goldenItemIcon };
    }

    private createGoldenItemIcon(d3heads: SD3Items) {
        const d3goldenItemIcon = d3heads
                .filter((d) => d.isGoldenItem)
                .append('g')
                .attr('class', 'golden-item-icon'),
            r = this.itemSpec.goldenItemIcon.width / 2,
            cx = r,
            cy = this.itemSpec.minHeight(0) / 2;
        d3goldenItemIcon
            .append('circle')
            .attr('r', r)
            .attr('transform', `translate(${cx},${cy})`)
            .attr('class', 'golden-item-icon-bg');
        d3goldenItemIcon
            .append('text')
            .text(GlyphUtil.glyphCharGoldenLink)
            .attr('transform', `translate(${cx},${cy + 2})`)
            // for firefox ESR68
            .attr(
                'style',
                `font-size: 12px; dominant-baseline: middle; text-anchor: middle; fill: ${LineageConstants.textColor}`
            )
            .attr('class', 'golden-item-icon-text');

        return d3goldenItemIcon;
    }

    private createLinkElements(links: LineageLink[]) {
        const d3links = this.d3Links().data(links);

        const d3newlinks = d3links
            .enter()
            .append('g')
            .attr('class', (d) => d.class);

        d3newlinks
            .filter(
                (d) =>
                    d.isGoldenLink ||
                    (d.isVirtualGoldenLink &&
                        (d.virtual != VirtualType.No ||
                            !d.src.isExpanded ||
                            !d.tgt.isExpanded))
            )
            .append('path')
            .attr('class', 'golden-link-path');

        d3newlinks.append('path').attr('class', 'link-path');

        if (this.debug) {
            d3newlinks
                .append('text')
                .attr('height', 20)
                .attr('width', (l, i, n) =>
                    (n[i] as SVGTextElement).getComputedTextLength()
                );
        }

        return d3newlinks;
    }

    private setupNewHEllipsisElements(d3newHEllipsisElements: SD3Items) {
        this.log('setupNewHEllipsisElements');

        const { height, marginWidth, textLeft, cornerRadius } =
                this.itemSpec.hierarchicalEllipsis,
            centerHoriz = textLeft == undefined,
            cy = height / 2;

        // add the text
        const d3text = d3newHEllipsisElements
            .append('text')
            .text((d) => this.getHEllipsisText(d));

        // centered vertically
        d3text.attr('y', cy).attr('alignment-baseline', 'middle');

        if (centerHoriz) {
            d3text
                .attr('text-anchor', 'middle')
                .attr('x', (d) => (d.width / 2) | 0);
        } else {
            d3text.attr('text-anchor', 'left').attr('x', textLeft | 0);
        }

        d3text.each((d, i, n) => {
            // add the 2 dashed horizontal segments around the text
            const node = n[i],
                textWidth = node.getComputedTextLength(),
                lengthLeft = centerHoriz
                    ? (d.width - textWidth) / 2 - marginWidth
                    : textLeft - marginWidth,
                lengthRight = centerHoriz
                    ? lengthLeft
                    : d.width - textLeft - textWidth - marginWidth,
                path = `M0,${cy | 0} h${lengthLeft | 0} M${d.width | 0},${
                    cy | 0
                } h-${lengthRight | 0}`,
                d3parent = d3select<SVGGElement, LineageItem>(
                    node.parentNode as SVGGElement
                );

            d3parent.append('path').attr('d', path);

            if (!this.hEllipsedClickDisabled) {
                // add the background rect with round corners
                const k = { x: 2, y: 0, w: -2, h: -2 } /* adjustments */,
                    w = k.w + textWidth + 2 * marginWidth,
                    h = k.h + height,
                    x = k.x + (centerHoriz ? (d.width - w) / 2 : lengthLeft),
                    y = k.y,
                    rx = cornerRadius,
                    ry = cornerRadius,
                    rectNode = D3Helper.createRectOrUpdate(
                        d3parent,
                        'bg',
                        { x, y, width: w, height: h, rx, ry },
                        true
                    ).node();

                // move the rect node before the text node so it appears behind
                node.parentNode.insertBefore(rectNode, node);
            }
        });

        if (this.debug) {
            // show the ellipsis rectangle
            d3newHEllipsisElements.each((d, i, n) =>
                D3Helper.createRectOrUpdate(
                    d3select<SVGGElement, LineageItem>(n[i]),
                    'debug',
                    { width: d.width, height }
                )
            );
        }

        //send it to the controller for event binding
        this.onElementCreated(
            d3newHEllipsisElements,
            LineageUiElementType.HEllipsis
        );
    }

    //#endregion

    //#region update

    public setLoadedExpanderCaret(d3items: SD3Items) {
        d3items
            .select('use[href="#caret-circle-lzy"]')
            .attr('href', '#caret-circle');
        this.redrawCaret(d3items);
    }
    public removeExpanderCaret(d3items: SD3Items) {
        d3items.select('.expander-caret').remove();
    }

    private redraw(
        opt: {
            hEllipsis?: boolean;
            visibility?: boolean;
            includeHEllipsed?: boolean;
            position?: boolean;
            height?: boolean;
            width?: boolean;
            caret?: boolean;
            text?: boolean;
            linksVisibility?: boolean;
            links?: boolean;
        } = {}
    ) {
        const d3items = this.d3Items();

        if (opt.hEllipsis) {
            const clearOnly = !this.isAnyHEllipsed();
            d3items
                .filter((d) => d.isRoot)
                .each((r) => this.redrawHEllipsisDescendants(r, clearOnly));
        }

        if (opt.visibility) {
            // only root children because roots are always visible
            this.redrawVisibility(d3items.filter((d) => !d.isRoot));
        }

        const d3visibleItems = opt.includeHEllipsed
            ? d3items.filter((d) => d.isVisibleOrHEllipsed())
            : d3items.filter((d) => d.isVisible());

        if (opt.position) {
            this.redrawPosition(d3visibleItems);
        }
        if (opt.height) {
            this.redrawHeight(d3visibleItems);
        }
        if (opt.width) {
            this.redrawWidth(d3visibleItems);
        }
        if (opt.caret) {
            this.redrawCaret(d3visibleItems.filter((d) => d.hasChildren));
        }
        if (opt.text) {
            this.redrawText(d3visibleItems);
        }

        if (opt.links || opt.linksVisibility) {
            this.redrawLinks(this.d3Links(), opt.linksVisibility);
        }
    }

    private redrawHEllipsisDescendants(item: LineageItem, clearOnly = false) {
        this.withDescendants(item, (d3descendants) => {
            // remove

            d3descendants
                .filter((d) => !d.isVisibleHEllipsisRoot)
                .classed('hell', false)
                .selectAll('g.inhell')
                .remove();

            if (clearOnly) {
                return;
            }

            // create

            const d3HEllipsisRoots = d3descendants
                .filter((d) => d.isVisibleHEllipsisRoot)
                .classed('hell', true)
                .append('g')
                .attr('class', 'inhell');

            if (d3HEllipsisRoots.empty()) {
                return;
            }

            this.setupNewHEllipsisElements(d3HEllipsisRoots);
        });
    }

    private redrawCaretAndDescendantsVisibilityCaretAndText(item: LineageItem) {
        this.withItemOrDescendants(item, this.redrawCaret, (d3descendants) => {
            this.redrawVisibility(d3descendants);
            const d3visibleDescendants = d3descendants.filter(':not(.hidden)');
            this.redrawCaret(d3visibleDescendants);
            this.redrawText(d3visibleDescendants);
        });
    }

    private redrawHeightAndPositionItemAndDescendants(item: LineageItem) {
        this.withItemAndDescendants(item, (d3items) => {
            const d3visibleItems = d3items.filter((d) =>
                d.isVisibleOrHEllipsed()
            );
            //const d3visibleItems = d3items.filter(':not(.hidden)')

            this.redrawPosition(d3visibleItems);
            this.redrawHeight(d3visibleItems);
        });
    }

    //#region links

    private redrawHGroupsLinks(
        updatedHGroups: LineageItem[],
        computeVisibility: (r: LineageItem) => boolean
    ) {
        updatedHGroups.forEach((r) =>
            this.redrawHGroupLinks(r, computeVisibility(r))
        );
    }

    private redrawHGroupLinks(item: LineageItem, computeVisibility: boolean) {
        const d3hgroupLinks = this.d3Links('.hg' + item.root.id);
        this.redrawLinks(d3hgroupLinks, computeVisibility);
    }

    private redrawLinks(d3links: SD3Links, computeVisibility: boolean) {
        if (computeVisibility) {
            d3links.classed('hidden', (d) => !d.computeVisibility());
            d3links
                .selectAll('.golden-link-path')
                .classed(
                    'hidden',
                    (d: LineageLink) =>
                        d.isVirtualGoldenLink &&
                        !d.isGoldenLink &&
                        d.tgt.isExpanded
                );
        }

        // in debug mode we keep hidden links
        if (!this.debug) {
            d3links = d3links.filter(':not(.hidden)');
        }

        d3links
            .selectAll('path')
            .attr('d', (d: LineageLink) => d.getPath())
            .attr('stroke', (d: LineageLink) => {
                if (d.orient === LineageLinkOrientationType.Unoriented) {
                    return LineageConstants.pathLinkColor;
                } else if (d.isLeftToRight) {
                    return 'url(#link-path-gradient)';
                } else {
                    return 'url(#link-path-gradient-swapped)';
                }
            });

        if (this.debug) {
            // and we display a label on the link
            d3links
                .select('text')
                .text(
                    (l) =>
                        l.toDebugString() +
                        ':' +
                        this.getMarkerId(true, l, false) +
                        ',' +
                        this.getMarkerId(false, l, false)
                )
                .attr('transform', (l, i, n) => {
                    const labelWidth = +(n[i] as SVGElement).getAttribute(
                        'width'
                    );
                    return l.getCenterTransform(labelWidth);
                });
        }
    }

    //#endregion links

    private redrawText(d3Items: SD3Items) {
        const texts = d3Items.select<SVGTextElement>('.item-name'),
            viewType = this.viewType,
            debug = !!this.debug;

        D3Helper.setTextWithEllipsis(
            texts,
            (d) => d.getDisplayedName(viewType, debug),
            (d) => d.textMaxWidth,
            this.onItemTextDrawn
        );

        if (this.debug) {
            // show the whole placeholder for text
            this.d3Items()
                .select('.item-name-debug')
                .attr('width', (d) => d.textMaxWidth);
        }

        const goldenItemIcons =
            d3Items.select<SVGGElement>('.golden-item-icon ');

        goldenItemIcons.each(
            (
                d: LineageItem,
                i: number,
                n: SVGGElement[] | ArrayLike<SVGGElement>
            ) => {
                const x =
                    texts.nodes()[i].getComputedTextLength() +
                    this.itemSpec.itemText.left +
                    this.itemSpec.goldenItemIcon.margin;
                n[i].setAttribute('transform', `translate(${x}, 0)`);
            }
        );
    }

    private redrawHeight(d3items: SD3Items) {
        d3items.select('.databox').attr('height', (d) => d.height);
        const leftBorderWidth = this.itemSpec.itemLeftBorderWidth(0);
        d3items
            .filter((d) => d.isRoot)
            .select('.left-border')
            .attr('height', (d) => d.height)
            .attr('width', leftBorderWidth);
    }
    private redrawWidth(d3items: SD3Items) {
        d3items.select('.databox').attr('width', (d) => d.width);
        d3items.select('.item-head').attr('width', (d) => d.headWidth);
    }
    private redrawCaret(d3items: SD3Items) {
        d3items
            .select('.expander-caret')
            .classed('expanded', (d) => d.isExpanded);
    }
    private redrawVisibility(d3items: SD3Items) {
        d3items.classed('hidden', (d) => !d.isVisible());
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private redrawPosition(
        d3sel: Selection<any, { x: number; y: number }, any, unknown>
    ) {
        // we truncate values. need no more precision, lightens dom and ease reading/debugging
        return d3sel.attr(
            'transform',
            (o) => `translate(${o.x | 0},${o.y | 0})`
        );
    }

    private withDescendants(
        item: LineageItem,
        action: (sel: SD3Items) => void
    ) {
        this.withItemOrDescendants(item, undefined, action);
    }
    private withItemAndDescendants(
        item: LineageItem,
        action: (sel: SD3Items) => void
    ) {
        this.withItemOrDescendants(item, action, action);
    }
    private withItemOrDescendants(
        item: LineageItem,
        withItem?: (sel: SD3Items) => void,
        withDescendants?: (sel: SD3Items) => void
    ) {
        if (withItem) {
            withItem(this.d3Items('', item.selector));
        }
        if (withDescendants) {
            withDescendants(this.d3Items(`.pid${item.id}`));
        }
    }

    //#endregion

    private getMarkerId(isStart: boolean, d: LineageLink, isTrack: boolean) {
        const isDot =
            isStart || d.orient == LineageLinkOrientationType.Unoriented;
        return this.getLinkMarkerId(isDot, isTrack);
    }
    private getLinkMarkerId(isDot: boolean, isTrack: boolean) {
        return `link-${isDot ? 'dot' : 'arr'}${isTrack ? '-track' : ''}`;
    }

    private log(...args: unknown[]) {
        if (this.debug) {
            console.log(this.constructor.name, ...args);
        }
    }

    //#endregion private
}

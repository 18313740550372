import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    HostListener,
    Input,
    NgZone,
    OnChanges,
    OnDestroy,
    Output,
    SimpleChanges,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { DashboardGridService } from './dashboard-grid.service';
import { DashboardStateService } from '../dashboard/state/dashboard-state.service';
import { DashboardSection, WidgetInstance } from '../domain';
import { BehaviorSubject, map } from 'rxjs';
import { MAX_WIDGETS_PER_SECTION } from '../domain/dashboard-constants';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { generateGuid } from '@datagalaxy/utils';

@Component({
    selector: 'dxy-dashboard-grid',
    templateUrl: './dashboard-grid.component.html',
    styleUrls: [
        './dashboard-grid.component.scss',
        './dashboard-grid.component.responsive.scss',
    ],
    providers: [DashboardGridService],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class DashboardGridComponent
    extends DxyBaseComponent
    implements AfterViewInit, OnChanges, OnDestroy
{
    @Input() section: DashboardSection;
    @Input() @HostBinding('class.editing') enableEdition = false;
    @Input() widgetGalleryOpened = false;

    @Output() openWidgetGallery = new EventEmitter<void>();

    @ViewChild('gridContainer') gridContainer: ElementRef<HTMLElement>;

    protected get widgetInstances() {
        return this.section.widgetInstances;
    }

    protected get placeholderVisible() {
        return this.widgetInstances.length === 0 && this.enableEdition;
    }

    public get fullOverlayVisible$() {
        return this.dragging$.pipe(map((dragging) => dragging && this.full));
    }

    protected get dragging() {
        return this.dragging$.getValue();
    }

    protected gridId = generateGuid();
    protected get full() {
        return this.section?.widgetInstances?.length >= MAX_WIDGETS_PER_SECTION;
    }
    private dragging$ = new BehaviorSubject(false);

    @HostListener('mouseenter', ['$event']) onMouseEnter(event: MouseEvent) {
        if (event.buttons == 1) {
            this.dragging$.next(true);
        }
    }
    @HostListener('mouseleave') onMouseLeave() {
        this.resetDragging();
    }
    @HostListener('mouseup') onMouseUp() {
        this.resetDragging();
    }

    constructor(
        private zone: NgZone,
        private cd: ChangeDetectorRef,
        private dashboardGridService: DashboardGridService,
        private dashboardStateService: DashboardStateService
    ) {
        super();
        this.subscribe(this.dashboardGridService.widgetDropped, (e) => {
            this.dashboardStateService.addWidgetToSection(this.section, e);
        });
        this.subscribe(this.dashboardGridService.widgetInstanceDropped, (e) => {
            this.dashboardStateService.addWidgetInstanceToSection(
                this.section,
                e
            );
        });
        this.subscribe(this.dashboardGridService.widgetInstancesMoved, (e) => {
            this.dashboardStateService.moveWidgetsInsideSection(
                this.section,
                e
            );
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        super.onChange(
            changes,
            'section',
            () => this.dashboardGridService.changing$.next(false),
            false
        );
        const enabled = changes.enableEdition?.currentValue;
        if (enabled === undefined) {
            return;
        }
        if (enabled) {
            this.zone.runOutsideAngular(() => {
                this.dashboardGridService.activateDrag();
            });
        } else {
            this.zone.runOutsideAngular(() => {
                this.dashboardGridService.deactivateDrag();
            });
        }
    }

    ngAfterViewInit() {
        this.zone.runOutsideAngular(() => {
            setTimeout(() => {
                this.dashboardGridService.initGs(
                    this.gridContainer.nativeElement,
                    this.enableEdition
                );
            });
            this.cd.detectChanges();
        });
    }

    ngOnDestroy() {
        this.dashboardGridService.destroy();
    }

    protected removeWidgetInstance(wi: WidgetInstance) {
        this.dashboardStateService.removeWidgetInstance(this.section, wi);
        this.cd.detectChanges();
    }

    protected duplicateWidgetInstance(wi: WidgetInstance) {
        this.dashboardStateService.duplicateWidgetInstance(this.section, wi);
    }

    protected updateWidgetConfiguration(
        wi: WidgetInstance,
        configuration: unknown
    ) {
        this.dashboardStateService.updateWidgetConfiguration(
            this.section,
            wi,
            configuration
        );
    }

    protected widgetInstanceTrackBy(_, wi: WidgetInstance) {
        return wi.guid;
    }

    private resetDragging() {
        if (!this.dragging$.value) {
            return;
        }
        this.dragging$.next(false);
    }
}

import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';
import { GridStack } from 'gridstack';
import { Widget } from './domain';

@Directive({ selector: '[dxyDraggableWidget]' })
export class DraggableWidgetDirective implements AfterViewInit {
    @Input('dxyDraggableWidget') widget: Widget;

    constructor(private el: ElementRef<HTMLElement>) {}

    ngAfterViewInit() {
        this.makeDraggable();
    }

    private makeDraggable() {
        this.el.nativeElement.setAttribute('widget-name', this.widget.name);
        GridStack.setupDragIn([this.el.nativeElement], {
            appendTo: 'body',
            helper: () => this.createDraggableCard(),
        });
    }

    private createDraggableCard() {
        const dragCardCopy = this.el.nativeElement.cloneNode(
            true
        ) as HTMLElement;
        dragCardCopy.classList.add('dragging');
        return dragCardCopy;
    }
}

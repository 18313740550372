import { Injectable } from '@angular/core';
import { IActionOption } from '@datagalaxy/core-ui';
import { StateName } from '@datagalaxy/webclient/routing';
import { NavigationService } from '../../services/navigation.service';
import { LineageGraphService } from '../lineage-graph/lineage-graph.service';
import { EntityType, IHasHddData } from '@datagalaxy/dg-object-model';

@Injectable()
export class LineageAssetsMenuService {
    constructor(
        private navigationService: NavigationService,
        private lineageGraph: LineageGraphService
    ) {}

    public getMenu(): IActionOption<IHasHddData>[] {
        return [this.getLineageOption()];
    }

    private getLineageOption(): IActionOption<IHasHddData> {
        return {
            labelKey: 'UI.EntityImpactAnalysis.lineageBurgerMenu.goToLineage',
            callback: (entity: IHasHddData) =>
                this.navigationService.goToEntityDetailsByIdentifier(
                    entity.HddData,
                    {
                        tabName: StateName.RelativeTabLineage,
                    }
                ),
            hidden: (entity: IHasHddData) => {
                if (!entity.HddData.Data.HasReadAccess) {
                    return true;
                }

                const rootNode = this.lineageGraph.getRoot();

                if (!rootNode) {
                    return false;
                }

                const isRoot = rootNode.id === entity.HddData.ReferenceId;
                const isDataProcessingItem =
                    entity.HddData.entityType === EntityType.DataProcessingItem;

                return isRoot || isDataProcessingItem;
            },
            glyphClass: 'glyph-analysis',
        };
    }
}

import {
    Component,
    ElementRef,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { DxyFieldSelectComponent } from '@datagalaxy/core-ui/fields';
import { IOptionAdapter } from '@datagalaxy/core-ui';
import { DxyAttributeBaseInput } from '../../DxyAttributeBaseInput';
import { TranslateService } from '@ngx-translate/core';
import { AttributeMetaValue } from '@datagalaxy/webclient/attribute/domain';

@Component({
    selector: 'dxy-attribute-reference-list-input',
    templateUrl: './dxy-attribute-reference-list-input.component.html',
})
export class DxyAttributeReferenceListInputComponent
    extends DxyAttributeBaseInput<string>
    implements OnInit, OnChanges
{
    /** Adds an *undefined* option as the first available value */
    @Input() isClearable: boolean;
    /** Displayed text for the *undefined* option. Defaults to empty string */
    @Input() clearOptionText = '';

    @ViewChild('field') field: DxyFieldSelectComponent<AttributeMetaValue>;

    public readonly adapter: IOptionAdapter<AttributeMetaValue> = {
        getText: (amv) => amv?.Value ?? '',
    };
    public availableOptions: AttributeMetaValue[];

    public get currentOption() {
        return this._currentOption;
    }
    public set currentOption(value: AttributeMetaValue) {
        this.setData(value?.Key).then();
        this._currentOption = value;
    }

    private _currentOption: AttributeMetaValue;

    constructor(
        elementRef: ElementRef<HTMLElement>,
        translate: TranslateService
    ) {
        super(elementRef, translate);
    }

    ngOnChanges(changes: SimpleChanges): void {
        super.onChange(changes, 'fieldInfo', () => this.init());
    }

    ngOnInit() {
        this.init().then();
        super.ngOnInit();
    }

    //#region IAttributeBaseInputOverride
    public onAfterUndo() {
        this.setCurrentOption();
    }
    public focusField() {
        this.field.doFocus();
    }
    public blurField() {
        this.field.doBlur();
    }
    //#endregion

    private async init() {
        if (!this.hasLoadReferenceOptions) {
            return;
        }

        const result = await this.loadReferenceOptions();
        this.availableOptions = result
            ? this.isClearable
                ? [
                      new AttributeMetaValue(
                          this.attributeMeta,
                          undefined,
                          this.clearOptionText
                      ),
                      ...result,
                  ]
                : result.slice()
            : [];

        this.setCurrentOption();
    }

    private setCurrentOption() {
        const valueKey = this.getData();
        this._currentOption = this.availableOptions?.find(
            (amv) => amv?.Key === valueKey
        );
    }
}

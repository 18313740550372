import { IActionOption } from '@datagalaxy/core-ui';
import {
    DKSEntityMenuDefaultOption,
    DksEntityMenuOption,
    TDksEntityMenu,
    TDksEntityMenuOption,
} from './dks-entity-actions.types';
import {
    EntityType,
    HierarchicalData,
    IHasHddData,
} from '@datagalaxy/dg-object-model';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CollectionsHelper } from '@datagalaxy/core-util';
import { DataProcessingUiService } from '../../../data-processing/services/data-processing-ui.service';
import { EntityPreviewPanelService } from '../../../shared/entity/services/entity-preview-panel.service';
import { EntityIdentifier } from '@datagalaxy/webclient/entity/utils';

@Injectable()
export class DksEntityMenuService {
    private readonly menu = new BehaviorSubject<
        IActionOption<HierarchicalData>[]
    >([]);

    public get menu$() {
        return this.menu.asObservable();
    }

    constructor(
        private dataProcessingUiService: DataProcessingUiService,
        private previewPanel: EntityPreviewPanelService
    ) {}

    public setupMenu(options?: TDksEntityMenu): void {
        const menu = CollectionsHelper.flatten(
            options?.map((opt) => {
                const res = this.getOption(opt);
                return Array.isArray(res) ? res : [res];
            })
        );
        this.menu.next(menu);
    }

    public refresh() {
        this.menu.next([...this.menu.value]);
    }

    private getOption(
        option: TDksEntityMenuOption
    ): IActionOption<IHasHddData> {
        switch (option.type) {
            case 'default':
                return {
                    ...this.getDefaultOption(option),
                    hidden: option.hidden,
                };
            case 'option':
                return option.option;
            default:
                return;
        }
    }

    private getDefaultOption(
        option: DKSEntityMenuDefaultOption
    ): IActionOption<IHasHddData> {
        switch (option.option) {
            case DksEntityMenuOption.Preview:
                return this.getPreviewAction();
            default:
                return;
        }
    }

    private getPreviewAction(): IActionOption<IHasHddData> {
        return {
            callback: (entity: IHasHddData) => this.openPreviewPane(entity),
            glyphClass: 'glyph-object-preview',
            tooltipTranslateKey: 'UI.Search.resultActionTooltip.openPane',
        };
    }

    private async openPreviewPane(entity: IHasHddData) {
        const entityIdr = EntityIdentifier.fromIHierarchicalData(
            entity.HddData
        );
        if (entityIdr.entityType === EntityType.DataProcessingItem) {
            return this.dataProcessingUiService.showDpiEditModalFromEntityIdr(
                entityIdr
            );
        } else {
            return this.previewPanel.setupPanel({ entityIdr });
        }
    }
}

import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    Input,
    OnChanges,
    OnDestroy,
    Output,
    QueryList,
    SimpleChanges,
    ViewChild,
    ViewChildren,
} from '@angular/core';
import { Dashboard, DashboardSection } from '../domain';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { IListOption } from '@datagalaxy/core-ui';
import { widgetTypeMappings } from '../widgets-registry';
import { DashboardStateService } from './state/dashboard-state.service';
import { map } from 'rxjs';
import { SectionWithDropDownOptions } from './section-with-dropdown-option';
import { WidgetsGalleryComponent } from '../widgets-gallery/widgets-gallery.component';
import { MAX_SECTIONS_LENGTH } from '../domain/dashboard-constants';
import { DraggingObserver } from './dragging-observer';
import { TranslateService } from '@ngx-translate/core';
import { DashboardHistoryService } from './state/dashboard-history-state.service';
import { DashboardSectionComponent } from '../dashboard-section/dashboard-section.component';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { generateGuid } from '@datagalaxy/utils';

@Component({
    selector: 'dxy-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss', '../is-drag-over.scss'],
    providers: [
        DashboardStateService,
        DashboardHistoryService,
        DraggingObserver,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardComponent
    extends DxyBaseComponent
    implements OnChanges, AfterViewInit, OnDestroy
{
    @Input() dashboard: Dashboard;
    @Input() @HostBinding('class.editing') enableEdition = false;

    @Output() dashboardChange = new EventEmitter();

    @ViewChild(WidgetsGalleryComponent) widgetGallery: WidgetsGalleryComponent;
    @ViewChildren(DashboardSectionComponent)
    sections: QueryList<DashboardSectionComponent>;

    @HostBinding('class.is-drag-over') dragging = false;

    protected sections$ = this.dashboardStateService.selectSections().pipe(
        map((s) => {
            return s.map(
                (section, index) =>
                    ({
                        ...section,
                        widgetInstances: [...section.widgetInstances],
                        options: this.getDropDownOptions(section, index),
                    } as SectionWithDropDownOptions)
            );
        })
    );

    protected availableWidgets = Array.from(widgetTypeMappings?.values());

    protected get addSectionButtonVisible$() {
        return this.dashboardStateService.selectSections().pipe(
            map((sections) => {
                return (
                    this.enableEdition && sections.length < MAX_SECTIONS_LENGTH
                );
            })
        );
    }

    protected sectionTrackBy(_, section: SectionWithDropDownOptions) {
        return section.id;
    }

    private getDropDownOptions(
        section: DashboardSection,
        index: number
    ): IListOption[] {
        return [
            {
                glyphClass: 'glyph-edit',
                labelKey: 'Dashboard.Handle.DropDown.Section.rename',
                callback: () => {
                    this.focusTitleInput(index);
                },
            },
            {
                glyphClass: 'glyph-arrow-up',
                labelKey: 'Dashboard.Handle.DropDown.Section.moveUp',
                hidden: index === 0,
                callback: () => {
                    this.dashboardStateService.moveSectionAtIndex(
                        section,
                        index - 1
                    );
                    this.cd.detectChanges();
                },
            },
            {
                glyphClass: 'glyph-arrow-down',
                labelKey: 'Dashboard.Handle.DropDown.Section.moveDown',
                hidden:
                    index ===
                    this.dashboardStateService.getSectionsLength() - 1,
                callback: () => {
                    this.dashboardStateService.moveSectionAtIndex(
                        section,
                        index + 1
                    );
                    this.cd.detectChanges();
                },
            },
            {
                glyphClass: 'glyph-delete',
                labelKey: 'Dashboard.Handle.DropDown.Section.remove',
                callback: () => {
                    this.dashboardStateService.removeSectionAtIndex(index);
                    this.cd.detectChanges();
                },
            },
            {
                glyphClass: 'glyph-hide',
                labelKey: 'Dashboard.Handle.DropDown.Section.hideTitle',
                hidden: !section.titleVisible,
                callback: () => {
                    this.dashboardStateService.changeSectionTitleVisibility(
                        section.id,
                        false
                    );
                },
            },
            {
                glyphClass: 'glyph-show',
                labelKey: 'Dashboard.Handle.DropDown.Section.showTitle',
                hidden: section.titleVisible,
                callback: () => {
                    this.dashboardStateService.changeSectionTitleVisibility(
                        section.id,
                        true
                    );
                },
            },
        ];
    }

    constructor(
        private dashboardStateService: DashboardStateService,
        private dashboardHistoryService: DashboardHistoryService,
        private cd: ChangeDetectorRef,
        private hostElement: ElementRef,
        private draggingObserver: DraggingObserver,
        private translate: TranslateService
    ) {
        super();
        this.subscribe(dashboardStateService.selectSections(), () => {
            this.dashboardChange.emit(
                this.dashboardStateService.getDashboard()
            );
        });
    }

    ngAfterViewInit() {
        this.subscribe(this.sections$, () => {
            setTimeout(() => {
                this.cd.detectChanges();
            }, 20);
        });

        this.subscribe(this.draggingObserver.dragging$, (dragging: boolean) => {
            this.dragging = dragging;
            this.cd.detectChanges();
        });

        this.draggingObserver.observe(this.hostElement.nativeElement);
    }

    ngOnDestroy(): void {
        this.draggingObserver.disconnect();
        this.dashboardHistoryService.dispose();
    }

    ngOnChanges(changes: SimpleChanges): void {
        super.onChange(
            changes,
            'dashboard',
            () => {
                if (changes.dashboard.firstChange) {
                    this.dashboardStateService.init(this.dashboard);
                }
            },
            true
        );
    }

    protected dragStarted() {
        this.dragging = true;
    }

    protected dragEnded() {
        this.dragging = false;
    }

    protected drop(event: CdkDragDrop<SectionWithDropDownOptions[]>) {
        const section = event.item?.data as DashboardSection;
        if (!section) {
            return;
        }
        this.dashboardStateService.moveSectionAtIndex(
            section,
            event.currentIndex
        );
        this.cd.detectChanges();
    }

    protected addSection() {
        this.dashboardStateService.addSection({
            id: generateGuid(),
            title: this.translate.instant('Dashboard.Section.defaultTitle'),
            titleVisible: true,
            widgetInstances: [],
        });
        this.widgetGallery.openPanel();
    }

    private focusTitleInput(index: number) {
        const section = this.sections.toArray()[index];
        if (section) {
            setTimeout(() => section.focusTitleInput(), 100);
        }
    }
}

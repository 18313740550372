import {
    IClientIntegrationEditModalResult,
    IClientIntegrationEditModalResolve,
} from './client-admin-integration-edit-modal.types';
import { DxyBaseModalComponent } from '@datagalaxy/ui/dialog';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ClientIntegrationDTO } from '@datagalaxy/webclient/integration/data-access';
import { IntegrationService } from '../../../services/integration.service';

@Component({
    selector: 'app-client-admin-integration-edit-modal',
    templateUrl: './client-admin-integration-edit-modal.component.html',
})
export class ClientAdminIntegrationEditModalComponent
    extends DxyBaseModalComponent<
        IClientIntegrationEditModalResolve,
        IClientIntegrationEditModalResult
    >
    implements OnInit
{
    public displayName: string;
    public checkClientAdmin: boolean;
    public canCreate: boolean;

    public get featureCode() {
        return `INTEGRATION_TOKEN,${this.isCreation ? 'C' : 'U'}`;
    }
    public get actionBtnLblKey() {
        return `UI.Components.IntegrationAdmin.${
            this.isCreation ? 'btnCreate' : 'btnUpdate'
        }`;
    }
    public get modalTitleKey() {
        return `UI.Components.IntegrationAdmin.${
            this.isCreation ? 'AddToken' : 'EditToken'
        }`;
    }

    private get isCreation() {
        return this.data.isCreation;
    }

    constructor(
        private integrationService: IntegrationService,
        dialogRef: MatDialogRef<
            ClientAdminIntegrationEditModalComponent,
            IClientIntegrationEditModalResult
        >,
        @Inject(MAT_DIALOG_DATA) data: IClientIntegrationEditModalResolve
    ) {
        super(dialogRef, data);
    }

    ngOnInit() {
        const ci = this.data.clientIntegration;
        this.result = {
            clientIntegration: ci,
            displayName: '',
        };

        if (!this.data.isCreation) {
            this.displayName = ci.DisplayName;
            this.checkClientAdmin = ci.IsClientAdmin;
        }
    }

    public onCloseSubmit() {
        this.onCreate().then(() => super.onCloseSubmit());
    }

    public updateCanCreate() {
        if (this.isCreation) {
            this.canCreate = !!this.displayName;
        } else {
            const ci = this.data.clientIntegration;
            this.canCreate =
                !!this.displayName &&
                (ci.IsClientAdmin != this.checkClientAdmin ||
                    ci.DisplayName != this.displayName);
        }
    }

    private async onCreate() {
        let res: ClientIntegrationDTO;
        if (this.data.isCreation) {
            res = await this.integrationService.createClientIntegration(
                this.displayName,
                this.checkClientAdmin
            );
        } else {
            const ci = this.data.clientIntegration;
            res = await this.integrationService.updateClientIntegration(
                this.displayName,
                ci.ClientIntegrationId,
                this.checkClientAdmin
            );
        }
        this.result.clientIntegration = res;
    }
}

<app-data-knowledge-studio
    [config]="dksConfig"
    [leftBarMenu]="dksLeftMenu$ | async"
    [panelOpen]="!!editedNode"
    [externalPanel]="!!editedNode"
    (onReady)="onGraphSurfaceReady($event)"
    (panelClose)="onPanelClose()"
>
    <app-lineage-item-picker
        *ngIf="editedNode"
        [node]="editedNode"
        side-panel-content
    ></app-lineage-item-picker>
</app-data-knowledge-studio>
<dxy-spinner
    *ngIf="loading$ | async"
    class="xl absolute-centered"
></dxy-spinner>

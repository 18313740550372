<div class="title" translate>UI.ViewIdentifier.spaceHome</div>
<ng-container *ngIf="canEditHomePage">
    <ng-container *ngIf="editionEnabled$ | async; else editButton">
        <button mat-button (click)="save()" [disabled]="loading$ | async">
            <i class="glyph-save"></i>
            <span translate>UI.DashboardGrid.Buttons.save</span>
        </button>
        <button mat-button (click)="cancel()" [disabled]="loading$ | async">
            <i class="glyph-cancelsearch"></i>
            <span translate>UI.DashboardGrid.Buttons.cancel</span>
        </button>
    </ng-container>
    <ng-template #editButton>
        <button mat-button (click)="enableEdition()">
            <i class="glyph-content-view-module-2"></i>
            <span translate>UI.SpaceHome.editHomePage</span>
        </button>
    </ng-template>

    <dxy-spinner
        *ngIf="loading$ | async"
        class="xs no-padding no-margin"
    ></dxy-spinner>
</ng-container>

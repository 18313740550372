import { Injectable } from '@angular/core';
import {
    BackendApiService,
    BaseServiceParameter,
    BaseServiceResult,
} from '@datagalaxy/data-access';
import {
    GetRecentEntitiesParameter,
    GetRecentEntitiesResult,
    GetRecentSearchesParameter,
    GetRecentSearchesResult,
    SaveRecentSearchFiltersParameter,
} from './recent';
import {
    GetFilteredEntitiesParameter,
    GetFilteredEntitiesResult,
} from './search';

@Injectable({ providedIn: 'root' })
export class SearchApiService {
    constructor(private backendApiService: BackendApiService) {}

    public async getLastSearches(param: GetRecentSearchesParameter) {
        return await this.backendApiService.postPromise(
            'SearchHistory/GetRecentSearches',
            param,
            GetRecentSearchesResult
        );
    }

    public async saveLastSearch(param: SaveRecentSearchFiltersParameter) {
        return await this.backendApiService.postPromise(
            'SearchHistory/SaveRecentSearch',
            param,
            BaseServiceResult
        );
    }

    public async clearLastSearches(param: BaseServiceParameter) {
        return await this.backendApiService.postPromise(
            'SearchHistory/DeleteRecentSearches',
            param,
            BaseServiceResult
        );
    }

    public async getRecentEntities(param: GetRecentEntitiesParameter) {
        return await this.backendApiService.postPromise(
            'SearchHistory/GetRecentEntities',
            param,
            GetRecentEntitiesResult
        );
    }

    public async getFilteredEntities(param: GetFilteredEntitiesParameter) {
        return await this.backendApiService.postPromise(
            'Entity/GetFilteredEntities',
            param,
            GetFilteredEntitiesResult
        );
    }
}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BaseCellComponent } from '@datagalaxy/core-ui/cell-components';
import { GlyphUtil } from '../../../util/GlyphUtil';
import { EntityLifecycleStatus } from '@datagalaxy/webclient/attribute/domain';

/**
 * ## Role
 * Display entity status with a colorized glyph in an omnigrid
 */
@Component({
    selector: 'app-dg-status-cell',
    templateUrl: 'dg-status-cell.component.html',
    styleUrls: ['dg-status-cell.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DgStatusCellComponent extends BaseCellComponent<
    any,
    EntityLifecycleStatus
> {
    @Input() status: EntityLifecycleStatus;

    public get textKey() {
        const status = this.status;
        return status != null
            ? `DgServerTypes.EntityLifecycleStatus.${EntityLifecycleStatus[status]}`
            : '';
    }
    public get glyphClass() {
        return GlyphUtil.getStatusGlyphClass(this.status);
    }

    protected override updateBindings() {
        this.status = this.value;
    }
}

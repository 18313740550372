import { Graph } from '@datagalaxy/core-d3-util';
import {
    LineageEdge,
    LineageEdgeSpec,
    LineageNodeSpec,
} from '../lineage.types';
import { EntityLinkUtils } from '../../entity-links/entity-link.utils';
import { FlowType } from '../../shared/entity/linked-object.types';
import { EntityLinkService } from '../../entity-links/entity-link.service';
import { CollectionsHelper } from '@datagalaxy/core-util';
import { LineageTreeNode } from '../lineage-tree/lineage-tree-node';
import { getLocalId } from '@datagalaxy/webclient/utils';
import { EntityType } from '@datagalaxy/dg-object-model';

export class LineageTreeGraph {
    private trees = new Map<string, LineageTreeNode>();
    /**
     * Map of parent reference id to child reference ids
     * For performance reasons, we store the parent reference ids
     * to avoid iterating the whole nodes array to find the parent
     */
    private parentRefs = new Map<string, string[]>();
    private graph = new Graph<LineageTreeNode, LineageEdge>();

    public add(nodes: LineageNodeSpec[]) {
        const treeNodes = nodes
            .filter((node) => !this.trees.has(node.entity.ReferenceId))
            .map((node) => this.addTreeNode(node));

        this.linkNodesToTheirChildAndParents(nodes);
        this.cleanupDataProcessingDuplicatedLinks(treeNodes);

        const edges = this.extractEdgesFromLineageNodesLinks(nodes);

        const nodeEdges = CollectionsHelper.distinctByProperty(
            edges,
            (edge) => edge.id
        ).filter((edge) => {
            const source = this.trees.get(edge.source);
            const target = this.trees.get(edge.target);

            return source && target && !this.graph.getEdge(edge.id);
        });

        this.graph.add(
            treeNodes.map((treeNode) => ({
                id: treeNode.id,
                data: treeNode,
            })),
            nodeEdges.map((edge) => ({
                id: edge.id,
                source: edge.source,
                target: edge.target,
                data: this.makeLineageEdge(edge),
            }))
        );

        return {
            nodes: treeNodes,
            edges: nodeEdges,
        };
    }

    public removeNodes(ids: string[]): LineageTreeNode[] {
        const clearNode = (treeNode: LineageTreeNode) => {
            treeNode.children?.forEach(clearNode);

            if (!treeNode) return treeNode;
            treeNode.parent?.removeChild(treeNode);
            treeNode.setHidden();
            this.trees.delete(treeNode.id);
            this.parentRefs.delete(treeNode.id);
            return treeNode;
        };
        const nodes = ids?.map((id) => this.trees.get(id));

        const removedNodes = nodes
            .flatMap((rootNode) => rootNode.getAllChildren())
            .concat(nodes);

        CollectionsHelper.distinct(nodes).forEach((node) => {
            clearNode(node);
        });

        this.graph.removeNodes(...removedNodes.map((node) => node.id));
        return removedNodes;
    }

    public getNode(id: string) {
        return this.trees.get(id);
    }

    public getNodeEdges(...ids: string[]) {
        return this.graph.getNodesEdges(...ids);
    }

    public getRelatedNodeEdges(...ids: string[]) {
        return this.graph.getRelatedNodesEdges(ids);
    }

    public getEdge(id: string) {
        return this.graph.getEdge(id);
    }

    public toggleTreeNode(id: string): LineageTreeNode | null {
        const treeNode = this.getNode(id);

        treeNode?.toggleExpansion();

        return treeNode;
    }

    public toggleTreeNodeVisibility(id: string): LineageTreeNode | null {
        const treeNode = this.getNode(id);

        treeNode?.toggleVisibility();

        return treeNode;
    }

    public getRecursiveSuccessors(nodeId: string) {
        return this.graph.getRecursiveSuccessors(nodeId);
    }

    public getRecursivePredecessors(nodeId: string) {
        return this.graph.getRecursiveNodePredecessors(nodeId);
    }

    public getRecursiveSuccessorsAndPredecessors(
        nodeId: string,
        includeChildren: boolean
    ) {
        const node = this.trees.get(nodeId);

        if (!node) {
            return null;
        }

        const nodes = includeChildren
            ? [node, ...node.getAllChildren()]
            : [node];

        return nodes
            .map((node) =>
                this.graph.getRecursiveSuccessorsAndPredecessors(node.id)
            )
            .reduce(
                (acc, val) =>
                    CollectionsHelper.distinctByProperty(
                        [...acc, ...val],
                        (node) => node.id
                    ),
                nodes.map((node) => ({
                    id: node.id,
                    data: node,
                }))
            );
    }

    private makeLineageEdge(edgeSpec: LineageEdgeSpec): LineageEdge {
        return {
            id: edgeSpec.id,
            source: this.trees.get(edgeSpec.source),
            target: this.trees.get(edgeSpec.target),
            linkType: edgeSpec.linkType,
        };
    }

    private extractEdgesFromLineageNodesLinks(
        nodes: LineageNodeSpec[]
    ): LineageEdgeSpec[] {
        const entityAttributeLinks = nodes.flatMap((node) => ({
            entity: node.entity,
            groups: node.links,
        }));

        const links = entityAttributeLinks.flatMap((node) =>
            node.groups.flatMap((group) => {
                const flow = EntityLinkUtils.getLinkTypeFlow(group.linkType);
                const inverted = flow === FlowType.upstream;
                const linkType = inverted
                    ? EntityLinkService.reverseObjectLinkType(group.linkType)
                    : group.linkType;

                return group.entityIds.map((item) => {
                    const source = inverted ? item : node.entity.ReferenceId;
                    let target = inverted ? node.entity.ReferenceId : item;

                    return {
                        id: `${getLocalId(source)}:${getLocalId(
                            target
                        )}:${linkType}`,
                        source,
                        target,
                        linkType: linkType,
                    };
                });
            })
        );

        return CollectionsHelper.distinctByProperty(links, (link) => link.id);
    }

    private addTreeNode(node: LineageNodeSpec): LineageTreeNode {
        const treeNode = new LineageTreeNode(
            node.entity,
            node.links,
            node.hidden,
            node.noAccessData
        );

        this.trees.set(treeNode.id, treeNode);

        const parentRefs = this.parentRefs.get(treeNode.parentId) || [];

        this.parentRefs.set(treeNode.parentId, parentRefs.concat(treeNode.id));

        return treeNode;
    }

    private linkNodesToTheirChildAndParents(nodes: LineageNodeSpec[]) {
        nodes.forEach((node) => {
            const parent = this.trees.get(node.parent);
            const addedTreeNode = this.trees.get(node.entity.ReferenceId);

            if (parent) {
                addedTreeNode.setParent(parent);
                parent.addChild(addedTreeNode);
            }

            const childIds = this.parentRefs.get(addedTreeNode.id);

            if (childIds?.length) {
                childIds.forEach((childId) => {
                    const child = this.trees.get(childId);

                    if (child) {
                        child.setParent(addedTreeNode);
                    }
                });
            }
        });
    }

    private cleanupDataProcessingDuplicatedLinks(treeNodes: LineageTreeNode[]) {
        this.addMissingDpiLinks(treeNodes);
        this.removeDataProcessingDuplicatedLinks(treeNodes);
    }

    /**
     * Entities outside of data processing items won't have links to DPIs
     * This method adds the missing links to the DPIs if DPIs are already loaded
     */
    private addMissingDpiLinks(treeNodes: LineageTreeNode[]) {
        treeNodes.forEach((node) => {
            node.links.forEach((group) => {
                group.entityIds.forEach((entityId) => {
                    const targetNode = this.trees.get(entityId);

                    if (
                        targetNode?.entityIdentifier.entityType ===
                        EntityType.DataProcessing
                    ) {
                        const dpiTarget = targetNode.children.find((child) => {
                            return child.links.some((group) =>
                                group.entityIds.includes(node.id)
                            );
                        });

                        if (dpiTarget) {
                            group.entityIds.push(dpiTarget.id);
                            group.entityIds = group.entityIds.filter(
                                (id) => id !== targetNode.id
                            );

                            targetNode.links.forEach((targetGroup) => {
                                targetGroup.entityIds =
                                    targetGroup.entityIds.filter(
                                        (id) => id !== node.id
                                    );
                            });
                        }
                    }
                });
            });
        });
    }

    /**
     * Data Processing & Data Processing Item share the same links
     * So we need to remove the duplicated links from the Data Processing to have a precise lineage
     * and also remove the inverted links from the target nodes
     */
    private removeDataProcessingDuplicatedLinks(treeNodes: LineageTreeNode[]) {
        const dataProcessingNodes = treeNodes.filter(
            (node) =>
                node.entityIdentifier.entityType === EntityType.DataProcessing
        );

        dataProcessingNodes.forEach((node) => {
            node.links.forEach((group) => {
                group.entityIds.forEach((entityId) => {
                    const isDuplicated = node.children.some((child) => {
                        return child.links.some(
                            (childGroup) =>
                                childGroup.linkType === group.linkType &&
                                childGroup.entityIds.includes(entityId)
                        );
                    });

                    if (isDuplicated) {
                        group.entityIds = group.entityIds.filter(
                            (id) => id !== entityId
                        );

                        const targetNode = this.trees.get(entityId);
                        targetNode?.links.forEach((targetGroup) => {
                            targetGroup.entityIds =
                                targetGroup.entityIds.filter(
                                    (id) => id !== node.id
                                );
                        });
                    }
                });
            });
        });
    }
}

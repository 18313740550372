import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnChanges,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import {
    BreadcrumbData,
    BreadcrumbService,
} from '../services/breadcrumb.service';
import { CoreUtil } from '@datagalaxy/core-util';
import { DxySpaceIconComponent } from '../../shared/shared-ui/dxy-space-icon/dxy-space-icon.component';
import { HierarchicalData } from '@datagalaxy/dg-object-model';
import {
    BreadcrumbUtil,
    IBreadcrumbItemSettings,
    ISpaceBreadcrumbInfo,
} from '../../shared/util/BreadcrumbUtil';
import { DxyBaseComponent } from '@datagalaxy/ui/core';

@Component({
    selector: 'dxy-nav-breadcrumb',
    templateUrl: './dxy-nav-breadcrumb.component.html',
    styleUrls: ['dxy-nav-breadcrumb.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DxyNavBreadcrumbComponent
    extends DxyBaseComponent
    implements AfterViewInit, OnChanges
{
    @Input() hierarchicalData: HierarchicalData;
    @Input() readOnly: boolean;
    @Input() openPreview: boolean;
    @Input() noIcons: boolean;
    @Input() isTooltipDisabled: boolean;
    /* if true, space is included in the breadcrumbs; otherwise space is included only if no global current space */
    @Input() forceIncludeSpace: boolean;
    /* if true, space is excluded of the breadcrumbs; otherwise space is included only if no global current space */
    @Input() forceExcludeSpace: boolean;
    /* if true, the given *hierarchicalData*, will be included in the breadcrumb, else not */
    @Input() includeSelf: boolean;
    /* if not empty set a data-dtname attribute to "a" tag prefixed by dtContext value */
    @Input() dtContext?: string;

    @ViewChild('breadcrumbContainer')
    breadcrumbContainer: ElementRef<HTMLElement>;
    @ViewChild(DxySpaceIconComponent, { read: ElementRef })
    spaceIconRef: ElementRef<HTMLElement>;

    public renderedItems: IBreadcrumbItemSettings[];
    public isReadyToDisplay: boolean;
    public spaceInfo: ISpaceBreadcrumbInfo;
    public breadcrumbTooltip: string;
    public get firstItemHierarchicalData() {
        return this.breadcrumbData?.items?.[0]?.hierarchicalData;
    }
    public get hasRenderedItems() {
        return !!this.initialRenderedItems?.length;
    }

    private breadcrumbData: BreadcrumbData;
    private initialRenderedItems: IBreadcrumbItemSettings[];

    constructor(
        private breadcrumbService: BreadcrumbService,
        private cdRef: ChangeDetectorRef
    ) {
        super();
    }

    ngOnChanges(changes: SimpleChanges) {
        super.onChanges(
            changes,
            [
                'hierarchicalData',
                'forceIncludeSpace',
                'forceExcludeSpace',
                'includeSelf',
            ],
            () => this.initAsync()
        );
    }

    ngAfterViewInit() {
        this.initAsync();
    }

    //#region API
    public refresh() {
        this.render();
    }
    //#endregion API

    private async initAsync() {
        this.breadcrumbData = this.breadcrumbService.getBreadcrumb(
            this.hierarchicalData,
            {
                forceIncludeSpace: this.forceIncludeSpace,
                forceExcludeSpace: this.forceExcludeSpace,
                includeSelf: this.includeSelf,
            }
        );
        this.isReadyToDisplay = false;
        setTimeout(() => {
            this.initRenderedItems();
        }, 5);
    }

    private async initRenderedItems() {
        this.isReadyToDisplay = false;
        const renderedItems =
            BreadcrumbUtil.getBreadcrumbItemsSettingsFromIDisplayedHData(
                this.breadcrumbData?.items,
                this.dtContext
            );
        if (renderedItems.length) {
            const firstItemHDD =
                renderedItems[0].itemData.ancestorData.HddData.Data;
            if (firstItemHDD.isSpace()) {
                this.spaceInfo =
                    await this.breadcrumbService.getSpaceBreadcrumbInfo(
                        this.breadcrumbData
                    );
                renderedItems.splice(0, 1);
            }
        }

        this.renderedItems = this.initialRenderedItems = renderedItems;

        this.cdRef.detectChanges();

        const breadcrumbElement = this.breadcrumbContainer?.nativeElement;
        if (!breadcrumbElement) {
            return;
        }

        const itemsData = this.initialRenderedItems.map(
            (value) => value.itemData
        );

        Array.from(breadcrumbElement.children).forEach(
            (ancestorElement: HTMLElement, index: number) => {
                if (!itemsData?.[index]) {
                    return;
                }
                itemsData[index].itemWidth = ancestorElement.offsetWidth;
            }
        );

        this.render();
    }

    private render() {
        if (!this.hasRenderedItems) {
            return;
        }
        const breadcrumbElement = this.breadcrumbContainer.nativeElement;
        const itemsData = CoreUtil.cloneDeep(
            this.initialRenderedItems.map((value) => value.itemData)
        );
        const availableWidth = breadcrumbElement.offsetWidth;
        const renderedItems = (this.renderedItems =
            BreadcrumbUtil.getBreadcrumbItemsSettingsFromAvailableWidth(
                availableWidth,
                itemsData,
                4,
                16
            ));
        const hasHiddenOrEllipsedItems = renderedItems.some(
            (item) => !item.isDisplayed || !!item.maxWidth
        );

        this.breadcrumbTooltip = hasHiddenOrEllipsedItems
            ? itemsData?.map((it) => it.ancestorData.displayName).join(' > ') ??
              ''
            : '';

        this.isReadyToDisplay = true;
        // Detect changes to ensure ui refresh in entity-card
        this.cdRef.detectChanges();
    }
}

import { NgModule } from '@angular/core';
import { AngularSplitPersistDirective } from './angular-split-persist.directive';
import { DxyLogFunctionalDirective } from './dxy-log-functional.directive';
import { FeatureFlagDirective } from '@datagalaxy/webclient/feature-flag';
import { DxyRichTooltipDirective } from '@datagalaxy/ui/tooltip';

@NgModule({
    imports: [FeatureFlagDirective, DxyRichTooltipDirective],
    declarations: [DxyLogFunctionalDirective, AngularSplitPersistDirective],
    exports: [
        DxyLogFunctionalDirective,
        AngularSplitPersistDirective,
        FeatureFlagDirective,
        DxyRichTooltipDirective,
    ],
})
export class DirectivesModule {}

import {
    forceCenter,
    forceLink,
    ForceLink as D3ForceLink,
    forceManyBody,
    forceSimulation,
    forceX,
    forceY,
    Simulation,
    SimulationLinkDatum,
    SimulationNodeDatum,
} from 'd3-force';
import { create as d3create, selectAll, Selection } from 'd3-selection';
import { D3DragEvent } from 'd3-drag';
import {
    AfterViewInit,
    Directive,
    ElementRef,
    NgZone,
    ViewChild,
} from '@angular/core';
import {
    D3Helper,
    GraphSurface,
    GraphSurfaceOptions,
    SD3SvgDefs,
    SurfaceLayer,
} from '@datagalaxy/core-d3-util';
import { TDomElement, Vect2 } from '@datagalaxy/core-2d-util';
import { CoreUtil, DomUtil, wait } from '@datagalaxy/core-util';
import { CoreEventsService } from '@datagalaxy/core-ui';
import {
    DxyGraphicalControlsComponent,
    IGraphicalControlEvents,
} from '@datagalaxy/core-ui/graphical';
import { AppEventsService } from '../services/AppEvents.service';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { ZoneUtils } from '@datagalaxy/utils';

@Directive()
export abstract class DxyBaseForceGraphComponent<
        TItem extends Item,
        TLink extends Link
    >
    extends DxyBaseComponent
    implements AfterViewInit
{
    //#region static

    protected static buildItemTemplateNode(customContent: string) {
        const svg = d3create('svg');
        svg.append('g')
            .attr('class', 'dg_dataVizObjectGraph-element')
            .html(
                '<foreignObject height="18" width="100" transform="translate(50 98)">' +
                    '<div xmlns="http://www.w3.org/1999/xhtml" class="dg_dataVizObjectGraph-type"></div>' +
                    '</foreignObject>' +
                    '<foreignObject height="20" width="200" transform="translate(0 83)">' +
                    '<div xmlns="http://www.w3.org/1999/xhtml" class="dg_dataVizObjectGraph-name"></div>' +
                    '</foreignObject>' +
                    customContent
            );
        return svg.node();
    }

    protected static redrawItemsPosition<TItem extends Item>(
        d3items: SD3<TItem>,
        offsetX = -100,
        offsetY = -47
    ) {
        const d3ItemSvgs = d3items.select<SVGSVGElement>('svg');
        d3ItemSvgs
            .attr('x', (d) => (d.x + offsetX) | 0)
            .attr('y', (d) => (d.y + offsetY) | 0);
        return d3ItemSvgs;
    }

    protected static redrawLinks<TN extends Item, TL extends Link>(
        d3links: SFLinks<TN, TL>
    ) {
        const d3lines = d3links.select<SVGLineElement>('line');
        DxyBaseForceGraphComponent.redrawLinksPosition(d3lines);
        DxyBaseForceGraphComponent.redrawLinksVisibility(d3lines);
        return d3lines;
    }
    protected static redrawLinksPosition<TN extends Item, TL extends Link>(
        d3lines: SFLinks<TN, TL>
    ) {
        d3lines
            .attr('x1', (link) => link.source.x | 0)
            .attr('y1', (link) => link.source.y | 0)
            .attr('x2', (link) => link.target.x | 0)
            .attr('y2', (link) => link.target.y | 0);
    }
    protected static redrawLinksVisibility<TN extends Item, TL extends Link>(
        d3lines: SFLinks<TN, TL>
    ) {
        d3lines.attr('visibility', (link) =>
            link.isDisplayed ? '' : 'hidden'
        );
    }
    protected static redrawLinksTextPosition<TN extends Item, TL extends Link>(
        d3texts: SFLinks<TN, TL>,
        offsetY = -10
    ) {
        d3texts
            .attr('x', (link) => link.centerX | 0)
            .attr('y', (link) => (link.centerY + offsetY) | 0)
            .attr('transform', (link) =>
                D3Helper.getSVGRotationTransformLTR(link.source, link.target)
            );
    }
    protected static redrawLinksTextVisibility<
        TN extends Item,
        TL extends Link
    >(d3texts: SFLinks<TN, TL>) {
        d3texts.attr('visibility', (link) => (link.isVisible ? '' : 'hidden'));
    }

    //#endregion

    public readonly toolBarEvents: IGraphicalControlEvents = {
        onScreenshot: () => this.takeScreenShot(),
        onZoomIn: () => this.d3ZoomSurface?.zoom.stepIn(),
        onZoomOut: () => this.d3ZoomSurface?.zoom.stepOut(),
        onZoomReset: () => this.zoomBestFit(true),
        onFullScreenChanging: (toFullScreen) =>
            this.onFullScreenChanging(toFullScreen),
        onFullScreenChanged: (isFullScreen) =>
            this.d3ZoomSurface?.viewport
                .updateViewportAsync(true)
                .then(() => this.onFullScreenChanged(isFullScreen)),
    };
    public get isFullScreen() {
        return this.graphicalControls?.isFullScreen;
    }
    public get zoom() {
        return this.d3ZoomSurface?.zoom.scale;
    }

    protected itemTemplateNode: SVGElement;
    protected verbose = false;
    protected isDragging: boolean;
    protected selectedItem: TItem;
    protected get selected() {
        return this.selectedItem;
    }
    protected get center() {
        return this.d3ZoomSurface?.viewport.center;
    }
    protected get isFirstSimulation() {
        return this.isFirstStart;
    }

    @ViewChild(DxyGraphicalControlsComponent)
    private graphicalControls: DxyGraphicalControlsComponent;
    private d3ZoomSurface: GraphSurface<
        TDomElement,
        TItem,
        TItem & SimulationNodeDatum
    >;
    private readonly surfaceCfg: GraphSurfaceOptions<
        TDomElement,
        TItem,
        TItem & SimulationNodeDatum
    > = {
        debug: this.debug && this.verbose,
        nodeDrag: {
            callbacks: {
                onDragStart: (d, e) => this.onDragStart(d, e),
                onDragMove: (d, e) => this.onDragMove(d, e),
                onDragEnd: (d, e) => this.onDragEnd(d, e),
            },
        },
    };
    private itemsContainer: SD3<void>;
    private linksContainer: SD3<void>;
    private simulation: ForceSimulation<TItem, TLink>;
    private frontItem: TItem;
    private isFirstStart = true;
    private get forceLink() {
        return this.simulation?.force('link') as ForceLink<TItem, TLink>;
    }
    /** constrained to [.O2, 1], defaults to 1 */
    private get alpha() {
        return Math.min(Math.max(0.02, this.config.alpha || 1), 1);
    }

    protected constructor(
        protected ngZone: NgZone,
        public elementRef: ElementRef,
        protected coreEventsService: CoreEventsService,
        protected appEventsService: AppEventsService,
        protected config: IForceGraphConfig,
        itemTemplateCustomContent: string,
        protected itemClassName = 'dg_dataVizGraphObject-graphicalNode',
        protected linkClassName = 'dg_dataVizGraphObject-graphicalLinkObject',
        private readonly useForce = true
    ) {
        super();
        this.itemTemplateNode =
            DxyBaseForceGraphComponent.buildItemTemplateNode(
                itemTemplateCustomContent
            );
    }

    ngAfterViewInit() {
        this.log('super-ngAfterViewInit');
        this.initAsync(); // unawaited on purpose
    }

    //#region to be overridden

    protected abstract isDataSourceReady(): boolean;

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    protected createDefs(defs: SD3SvgDefs) {}

    protected setupSimulation(simulation: ForceSimulation<TItem, TLink>) {
        this.log('setupSimulation', this.config, !!simulation);
        const cfg = this.config.forces;
        if (cfg.center) {
            simulation.force(
                'center',
                forceCenter<TItem>(this.center.x, this.center.y)
            );
        }
        if (cfg.gravity) {
            simulation
                .force('x', forceX(() => this.center.x).strength(cfg.gravity))
                .force('y', forceY(() => this.center.y).strength(cfg.gravity));
        }
        if (cfg.link) {
            const link = forceLink<Item, FLink<TItem, TLink>>();
            if (cfg.link.distance) {
                link.distance(cfg.link.distance);
            }
            if (cfg.link.strength) {
                link.strength(cfg.link.strength);
            }
            simulation.force('link', link);
        }
        if (cfg.charge) {
            const charge = forceManyBody();
            if (cfg.charge.distance) {
                charge.distanceMax(cfg.charge.distance);
            }
            if (cfg.charge.strength) {
                charge.strength(cfg.charge.strength);
            }
            simulation.force('charge', charge);
        }
        if (cfg.friction) {
            simulation.velocityDecay(cfg.friction);
        }
        if (this.config.alphaDecay) {
            simulation.alphaDecay(this.config.alphaDecay);
        }
    }

    protected confirmStart(): Promise<boolean> {
        return Promise.resolve(true);
    }

    protected abstract takeScreenShot(): Promise<void>;
    protected abstract getItemsData(): TItem[];
    protected abstract getLinksData(): TLink[];

    protected abstract setupNewDrawnItems(newD3items: SD3<TItem>): void;
    protected bindNewDrawnItems(d3items: SD3<TItem>) {
        const elements = d3items
            .nodes()
            .map((n) => n.children[0]) as SVGElement[];

        this.d3ZoomSurface.graph.addNodesFromElements(elements, {
            data: this.d3Items().data(),
            layer: SurfaceLayer.none,
        });

        D3Helper.bindClick(selectAll(elements), (it, el, event) => {
            event.preventDefault();
            this.onItemClick(it as any, event);
        });
    }
    protected abstract updateDrawnItems(d3items: SD3<TItem>): void;

    protected abstract setupNewDrawnLinks(newD3links: SD3<TLink>): void;
    protected abstract updateDrawnLinks(d3links: SD3<TLink>): void;

    protected abstract redrawItemsPosition(d3items: SD3<TItem>): void;
    protected abstract redrawLinks(d3links: SD3<TLink>): void;

    protected onSimulationEnd() {}
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    protected onItemClick(item: TItem, event: MouseEvent) {}
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    protected onFullScreenChanging(toFullScreen: boolean) {}
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    protected onFullScreenChanged(isFullScreen: boolean) {}

    //#endregion

    protected d3Items() {
        return this.itemsContainer.selectAll<SVGElement, TItem>(
            '.' + this.itemClassName
        );
    }
    protected d3Links() {
        return this.linksContainer.selectAll<SVGElement, TLink>(
            '.' + this.linkClassName
        );
    }

    private d3ForceItems() {
        return this.d3Items().data(
            this.useForce ? this.simulation.nodes() : this.getItemsData(),
            (item) => item.id
        );
    }
    private d3ForceLinks() {
        return this.d3Links().data(
            this.useForce ? this.forceLink?.links() : this.getLinksData(),
            (link) => link.id
        );
    }

    protected setNodeFixed(item: TItem & SimulationNodeDatum, fixed: boolean) {
        item.fixed = fixed;
        item.fx = fixed ? item.x : undefined;
        item.fy = fixed ? item.y : undefined;
    }

    protected updateAll(animated = true) {
        const getSimulationState = () =>
            this.debug && D3Helper.getSimulationState(this.simulation);
        this.log(
            'updateSimulation',
            animated,
            !!this.simulation,
            getSimulationState()
        );
        if (this.useForce) {
            if (!this.simulation) {
                return;
            }
            this.simulation.nodes(this.getItemsData());
            this.forceLink?.links(this.getLinksData() as FLink<TItem, TLink>[]);
            this.simulation.alpha(this.alpha);
            if (this.config.alphaTarget) {
                this.simulation.alphaTarget(this.config.alphaTarget);
            }
            this.log('updateSimulation-restart', getSimulationState());
            this.simulation.restart();
            if (animated) {
                this.refreshAll();
            } else {
                this.simulation.tick(D3Helper.getFullTicks(this.simulation));
                this.refreshAll();
                this.onSimulationEnd();
            }
        } else {
            this.refreshAll();
            this.onSimulationEnd();
            this.refreshAll();
        }
        this.isFirstStart = false;
        this.log('updateSimulation-out', getSimulationState());
    }

    protected refreshAll() {
        //this.log('refreshAll')
        this.refreshItems();
        this.refreshLinks();
    }

    protected setSelectedItem(item: TItem, noRefresh = false) {
        const prevItem = this.selectedItem;
        const change =
            item != prevItem || item?.isSelected != prevItem?.isSelected;
        if (prevItem) {
            prevItem.isSelected = false;
        }
        this.log('setSelectedItem', change, item);
        if (item) {
            item.isSelected = true;
        }
        this.selectedItem = item;
        if (noRefresh || !this.itemsContainer) {
            return;
        }
        this.updateDrawnItems(
            this.d3Items().filter((d) => d == prevItem || d == item)
        );
    }

    protected centerView(
        item?: TItem,
        smooth = false,
        resetZoom = false
    ): Promise<void> {
        this.log('centerView', item, smooth, resetZoom);
        return new Promise<void>((resolve) =>
            this.d3ZoomSurface.zoom.centerView(
                item,
                smooth ? 222 : 0,
                resetZoom,
                resolve
            )
        );
    }

    protected zoomBestFit(smooth: boolean) {
        this.log('zoomBestFit');
        const d3items = this.d3Items();
        if (d3items.empty()) {
            this.log('zoomBestFit-empty');
        } else {
            ZoneUtils.zoneTimeout(
                () => {
                    const rects = D3Helper.getRects(d3items);
                    const bb = D3Helper.getBoundingBox(rects);
                    this.d3ZoomSurface.zoom.zoomToRect(bb, {
                        zoomMax: 1,
                        margin: 10,
                        durationMs: smooth ? 333 : 0,
                    });
                },
                111,
                this.ngZone,
                true
            );
        }
    }

    //#region items positionning

    /** sets the given items coordinates
     * in a sunflower-like arrangement
     * around the (optionally given) origin */
    protected positionItemsAsPhyllotaxis(
        items: TItem[],
        origin = this.center,
        radius0 = 10
    ) {
        this.log('positionItemsAsPhyllotaxis');
        return Vect2.positionItemsAsPhyllotaxis(items, origin, radius0);
    }

    /** sets the given items coordinates
     * so they are evenly spaced on a circle centered on the (optionally given) origin */
    protected positionItemsOnCircle(
        items: TItem[],
        radius: number,
        origin = this.center,
        startAngle = 0
    ) {
        this.log('positionItemsOnCircle');
        return Vect2.positionItemsOnCircle(items, origin, radius, startAngle);
    }

    /** sets the given siblings coordinates
     * so they are evenly spaced, alternatively, on both sides of the given item,
     * on a circle centered on the given parent */
    protected positionSiblingsOnArc(
        item: Item,
        siblings: Item[],
        parent: Item,
        maxArc: number
    ) {
        this.log('positionSiblingsOnArc');
        return Vect2.positionSiblingsOnArc(item, siblings, parent, maxArc);
    }

    /** sets the given children coordinates
     * so they are evenly spaced, alternatively,
     * on a circle centered on the given parent,
     * on the opposite side of the given great-parent */
    protected positionChildrenOnCircle(
        parent: Item,
        children: Item[],
        greatParent: Item
    ) {
        this.log('positionChildrenOnCircle');
        return Vect2.positionChildrenOnCircle(parent, children, greatParent);
    }

    //#endregion

    private subscribeEvents() {
        this.log('subscribeEvents');
        super.subscribe(this.coreEventsService.mainViewResize$, () =>
            this.d3ZoomSurface?.viewport.updateViewport({ debounce: true })
        );
        super.subscribe(this.appEventsService.viewTypeChange$, () =>
            this.refreshAll()
        );
    }

    private async initAsync() {
        await wait(undefined, this.ngZone); // ensures dom is ready
        let isDataSourceReady: boolean;
        try {
            await CoreUtil.waitFor(
                () => this.isDataSourceReady() || undefined,
                { timeoutMs: 5000, pollDelayMs: 100 }
            );
            isDataSourceReady = true;
        } catch (err) {
            isDataSourceReady = false;
        }

        this.log('initAsync-dataSourceReady', isDataSourceReady);
        if (!isDataSourceReady) {
            return;
        }

        this.subscribeEvents();
        const container = DomUtil.getElement(this.elementRef, '.d3div');
        const isSurfaceready = (this.d3ZoomSurface = new GraphSurface<
            TDomElement,
            TItem,
            TItem & SimulationNodeDatum
        >(container, this.surfaceCfg));
        this.log('initAsync-surfaceReady', isSurfaceready);
        if (!isSurfaceready) {
            return;
        }

        this.initWithContainer();

        const isConfirmed = await this.confirmStart();
        this.log('initAsync-confirmStart', isConfirmed);
        if (!isConfirmed) {
            return;
        }

        this.updateAll(!this.config.noAnimationOnStart);
        setTimeout(() => this.zoomBestFit(false));
    }

    private initWithContainer() {
        this.log('initWithContainer');
        this.createDefs(this.d3ZoomSurface.svgL.defs.d3);

        // container for all of the svg elements
        const mainContainer = this.d3ZoomSurface.svgL.d3;
        // container for links - must be before the items container in the dom to be behind the items
        this.linksContainer = mainContainer
            .append('g')
            .attr('class', 'graphical-linkArea');
        // container for items
        this.itemsContainer = mainContainer
            .append('g')
            .attr('class', 'graphical-nodeArea');

        if (!this.useForce) {
            return;
        }

        this.simulation = forceSimulation<TItem, FLink<TItem, TLink>>();
        this.setupSimulation(this.simulation);
        this.simulation
            .stop()
            .on('tick', () => this.refreshAll())
            .on('end', () => this.onSimulationEnd());
    }

    //#region event handlers

    private onDragStart(
        item: TItem & SimulationNodeDatum,
        event: ForceDragEvent<TDomElement, TItem>
    ) {
        this.log('onDragStart', item);
        this.bringToFront(item);
        if (!this.useForce) {
            return;
        }

        if (!event.active) {
            this.heatSimulation();
        }
        item.fx = item.x;
        item.fy = item.y;
    }
    private onDragMove(
        item: TItem & SimulationNodeDatum,
        event: ForceDragEvent<TDomElement, TItem>
    ) {
        this.verbose && this.log('onDragMove', item, event);
        this.isDragging = true;
        if (this.useForce) {
            item.fx = event.x;
            item.fy = event.y;
        } else {
            item.x = event.x;
            item.y = event.y;
            this.refreshAll();
        }
    }
    private onDragEnd(
        item: TItem & SimulationNodeDatum,
        event: ForceDragEvent<TDomElement, TItem>
    ) {
        this.log('onDragEnd');
        if (!event.active) {
            this.simulation?.alphaTarget(0);
        }
        const cfg = this.config.draggedItem;
        this.setNodeFixed(item, cfg?.setFixed || item.fixed);
        if (cfg?.setSelected) {
            this.setSelectedItem(item);
        }
        ZoneUtils.zoneTimeout(
            () => (this.isDragging = false),
            111,
            this.ngZone,
            true
        );
    }
    //#endregion

    private heatSimulation() {
        const alphaTarget =
            this.config.dragAlphaTarget || Math.min(0.3, this.alpha - 0.01);
        this.log('heatSimulation', alphaTarget);
        this.simulation?.alphaTarget(alphaTarget).restart();
    }

    private bringToFront(item: TItem) {
        if (this.frontItem == item) {
            return;
        }
        this.frontItem = item;
        this.log('bringToFront', item);
        D3Helper.bringToFront(item, this.d3Items());
    }

    private refreshItems(d3items = this.d3ForceItems()) {
        //this.log('refreshItems')

        const d3oldItems = d3items.exit();
        d3oldItems.remove();

        const newItems = d3items
            .enter()
            .append('g')
            .attr('class', this.itemClassName);
        this.setupNewDrawnItems(newItems);
        this.bindNewDrawnItems(newItems);

        this.updateDrawnItems(d3items);

        this.frontItem = undefined;
    }
    private refreshLinks(d3links = this.d3ForceLinks()) {
        //this.log('refreshLinks')

        d3links.exit().remove();

        const newLinks = d3links
            .enter()
            .append('g')
            .attr('class', this.linkClassName);
        this.setupNewDrawnLinks(newLinks);

        this.updateDrawnLinks(d3links);
    }
}

export interface IForceGraphConfig {
    forces: {
        center?: boolean;
        gravity?: number;
        link?: {
            strength?: number;
            distance?: number;
        };
        charge?: {
            strength: number;
            distance?: number;
        };
        friction?: number;
    };
    /** defaults to 1 and will be constrained in [.2, 1] */
    alpha?: number;
    alphaTarget?: number;
    alphaDecay?: number;
    dragAlphaTarget?: number;
    draggedItem?: {
        setFixed?: boolean;
        setSelected?: boolean;
    };
    noAnimationOnStart?: boolean;
    smoothRemove?: boolean;
}

export type ForceSimulation<TN, TL> = Simulation<
    TN,
    TL & SimulationLinkDatum<TN>
>;
export type ForceLink<TN, TL> = D3ForceLink<TN, TL & SimulationLinkDatum<TN>>;
export type ForceDragEvent<T extends Element, D> = D3DragEvent<
    T,
    D,
    D & SimulationNodeDatum
>;
export type FLink<TN extends Item, TL extends Link> = TL &
    SimulationLinkDatum<TN>;
export type SD3<T> = Selection<SVGElement, T, any, any>;

declare interface Item {
    x: number;
    y: number;
    id: string;
    isSelected: boolean;
    fixed: boolean;
}
declare type Link = ILink<Item>;

declare interface ILink<TNode> {
    source: TNode;
    target: TNode;
    id: string;
    isDisplayed: boolean;
    isVisible: boolean;
    centerX: number;
    centerY: number;
}

declare type SFLinks<TItem extends Item, TLink extends Link> = SD3<
    FLink<TItem, TLink>
>;

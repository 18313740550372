import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DxyButtonsModule } from '@datagalaxy/ui/buttons';
import { TranslateModule } from '@ngx-translate/core';
import { MatLegacyMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';
import { NgForOf } from '@angular/common';
import { CheckboxComponent } from '@datagalaxy/ui/forms';
import { GridComponent } from '../grid/grid.component';
import { TColDef } from '../grid-column/grid-column.types';

@Component({
    standalone: true,
    selector: 'dxy-grid-column-selector',
    templateUrl: './grid-column-selector.component.html',
    styleUrls: ['./grid-column-selector.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        DxyButtonsModule,
        TranslateModule,
        MatLegacyMenuModule,
        MatLegacyTooltipModule,
        NgForOf,
        CheckboxComponent,
    ],
})
export class DxyGridColumnSelectorComponent<T> {
    @Input() forGrid?: GridComponent<T>;

    protected get columns() {
        return this.forGrid?.columns.slice(1) || [];
    }

    protected isColumnVisible(column: TColDef<T>) {
        return this.forGrid?.isColumnVisible(column.id);
    }

    protected onColumnToggleVisibility(column: TColDef<T>) {
        this.forGrid?.toggleColumnVisibility(column.id);
    }

    protected onResetClick() {
        this.forGrid?.resetColumns();
    }
}

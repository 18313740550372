import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    HostBinding,
    HostListener,
    Inject,
    Input,
    OnInit,
    ViewChild,
} from '@angular/core';
import { withLoading } from '@datagalaxy/core-ui';
import { AttributeFilterModel } from '../../shared/attribute/attribute-filter/attribute-filter/attributeFilterModel';
import { BehaviorSubject, from, map, Subject, Subscription } from 'rxjs';
import {
    debounceTime,
    distinctUntilChanged,
    finalize,
    tap,
} from 'rxjs/operators';
import {
    CollectionsHelper,
    CoreUtil,
    DomUtil,
    StringUtil,
} from '@datagalaxy/core-util';
import { IHasAttributeFilterModels } from '../../shared/attribute/attribute-filter/attribute-filter-form/IFilterFormModel';
import { DOCUMENT } from '@angular/common';
import { AttributeFilterService } from '../../shared/attribute/attribute-filter/attribute-filter.service';
import { IAttributeFilterCarouselFilterChange } from '../../shared/attribute/attribute-filter/attribute-filter-carousel/attribute-filter-carousel.types';
import { AttributeFilterCarouselComponent } from '../../shared/attribute/attribute-filter/attribute-filter-carousel/attribute-filter-carousel.component';
import { AttributeFilterAction } from '../../shared/attribute/attribute-filter/attribute-filter.types';
import { EntityPreviewPanelService } from '../../shared/entity/services/entity-preview-panel.service';
import { CurrentSearch } from '../../search/models/CurrentSearch';
import { IHasHddData } from '@datagalaxy/dg-object-model';
import { SearchResult, SearchService } from '../../search/search.service';
import { SearchUsage } from '../../shared/util/DgServerTypesApi';
import { EntityService } from '../../shared/entity/services/entity.service';
import { AppSpaceService } from '../../services/AppSpace.service';
import { AppEventsService } from '../../services/AppEvents.service';
import { DataUtil } from '../../shared/util/DataUtil';
import { spotlightTokens } from '../../search/search.utils';
import { NavigationService } from '../../services/navigation.service';
import { DxyModalService } from '../../shared/dialogs/DxyModalService';
import { CurrentSpaceService } from '../../services/currentSpace.service';
import { AttributeDataService } from '../../shared/attribute/attribute-data.service';
import { Space } from '@datagalaxy/webclient/workspace/data-access';
import { RecentSearchDto } from '@datagalaxy/webclient/search/data-access';
import {
    CrudOperation,
    FunctionalLogService,
} from '@datagalaxy/webclient/monitoring/data-access';
import { FilterCarouselDisplayMode } from '@datagalaxy/webclient/filter/ui';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { SpaceIdentifier } from '@datagalaxy/webclient/workspace/utils';
import { ISpaceIdentifier } from '@datagalaxy/webclient/workspace/domain';
import { Filter } from '@datagalaxy/webclient/filter/domain';
import { AttributeMetaValue } from '@datagalaxy/webclient/attribute/domain';
import { DgModule } from '@datagalaxy/shared/dg-module/domain';
import { ServerConstants } from '@datagalaxy/shared/server/domain';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'dxy-spotlight-input',
    templateUrl: './dxy-spotlight-input.component.html',
    styleUrls: [
        './dxy-spotlight-input.component.scss',
        './dxy-spotlight-input.component.responsive.scss',
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DxySpotlightInputComponent
    extends DxyBaseComponent
    implements OnInit
{
    @Input() placeholder: string;
    @Input() focusInputOnInit: boolean;
    /**
     * If true the last search or last viewed entities will be shown
     */
    @Input() enableLastSearch: boolean;
    @Input() currentSearch: CurrentSearch;
    /**
     * DisplayMode is used to determine the result container display
     * Dropdown: the container will be showed like a dropdown, under the spotlight-input
     * SlidePanel: the container will be showed like a panel on the right of the screen
     */
    @Input() displayMode: 'slide-panel' | 'dropdown' = 'slide-panel';

    @Input() @HostBinding('class.disabled') disabled = false;

    @ViewChild(AttributeFilterCarouselComponent)
    filterItemsCarousel: AttributeFilterCarouselComponent;
    @ViewChild(AttributeFilterCarouselComponent, { read: ElementRef })
    filterItemsCarouselRef: ElementRef<HTMLElement>;

    public searching$ = new BehaviorSubject<boolean>(false);
    public quickFilters$ = new BehaviorSubject<Filter[]>([]);
    public filteredUsers: AttributeMetaValue[] = [];
    public filteredTags: AttributeMetaValue[] = [];
    public filteredDomains: AttributeMetaValue[] = [];
    public previewResult: SearchResult;
    public isResultPaneVisible: boolean;
    public lastViewedEntities: IHasHddData[];
    public lastSearchesViews: CurrentSearch[];
    /** when true, the filters will take the spotlight's full width */
    public isFilterCarouselFullWidth: boolean;
    public get displayedFilters$() {
        return this.currentSearch?.displayedFilters$;
    }
    public get displayedFiltersEmpty$() {
        return this.displayedFilters$.pipe(map((filters) => !filters?.length));
    }
    public get selectedSpaceIdr() {
        return this.currentSearch?.spaceIdr;
    }
    public get searchTerm(): string {
        return this.currentSearch?.searchTerm;
    }
    public set searchTerm(value: string) {
        if (this.currentSearch) {
            this.currentSearch.searchTerm = value;
            this.searchTermChanged.next(value);
        }
    }
    public get searchUsageValue() {
        return this.navigationService.isInSpaceHomeView
            ? SearchUsage.SpaceHome
            : SearchUsage.Spotlight;
    }
    public get hasResultItems() {
        return !!this.previewResult?.count;
    }

    protected get carouselDisplayMode(): FilterCarouselDisplayMode {
        return this.displayMode == 'slide-panel' ? 'default' : 'active-filters';
    }

    private searchSubscription: Subscription;
    private searchTermChanged = new Subject<string>();
    private isPanelOpen: boolean;
    private readonly userToken = spotlightTokens.user;
    private readonly tagToken = spotlightTokens.tag;
    private readonly domainToken = spotlightTokens.domain;
    private readonly allTokens = spotlightTokens._all;
    private readonly userAttributeTokens = {
        DataOwners: ['proprietaire', 'owner'],
        DataStewards: ['steward'],
        CdoUsers: ['cdo'],
        DpoUsers: ['dpo'],
        CisoUsers: ['ciso'],
        ExpertUsers: ['expert'],
    };

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private elementRef: ElementRef<HTMLElement>,
        private attributeFilterService: AttributeFilterService,
        private searchService: SearchService,
        private navigationService: NavigationService,
        private functionalLogService: FunctionalLogService,
        private appEventsService: AppEventsService,
        private appSpaceService: AppSpaceService,
        private currentSpaceService: CurrentSpaceService,
        private attributeDataService: AttributeDataService,
        private entityService: EntityService,
        private entityPreviewPanelService: EntityPreviewPanelService,
        private dxyModalService: DxyModalService
    ) {
        super();
    }

    ngOnInit() {
        this.init().then(() => this.subscribeEvents());
        this.searchTermChanged
            .pipe(debounceTime(444), distinctUntilChanged())
            .subscribe(() => {
                this.onSearch('spotlight-input').then();
            });
    }

    //#region  event handlers

    public async onFiltersChange() {
        await this.onSearch('onFiltersChange');

        if (this.isFilterCarouselFullWidth) {
            setTimeout(() => this.updateFilterCarouselWidth());
        } else {
            this.filterItemsCarousel.refresh();
        }
        this.focusInput();
    }

    public async onSearch(logIdFrom: string): Promise<void> {
        this.log('onSearch', logIdFrom);
        this.searchSubscription?.unsubscribe();

        if (this.currentSearch.isSearchEmpty) {
            this.clearAutocompleteResults();
            this.previewResult = null;
            this.loadRecentSearchesAndEntities().then();
            this.searchSubscription = from(
                this.searchService.searchPreview(this.currentSearch, {
                    searchUsage: this.searchUsageValue,
                    initQuickFilters: true,
                })
            ).subscribe((res) => {
                this.quickFilters$.next(res.quickFilters);
            });
            return;
        }

        this.searching$.next(true);
        const hasAutoComplete = await this.checkAutoComplete();

        this.log('onSearch-checkAutoComplete', hasAutoComplete);
        if (hasAutoComplete) {
            this.searching$.next(false);
            this.openResultPane();
            setTimeout(() => this.focusInput());
        } else {
            this.searchSubscription = from(
                this.searchService.searchPreview(this.currentSearch, {
                    searchUsage: this.searchUsageValue,
                })
            )
                .pipe(
                    tap(() => this.functionalLog(FuncLogType.autocomplete)),
                    finalize(() => this.searching$.next(false))
                )
                .subscribe((res) => {
                    this.log('onSearch-preview-result');
                    this.previewResult = res;
                    this.quickFilters$.next(res.quickFilters);
                    this.openResultPane();
                });
        }
    }

    public async onSubmit() {
        const searching = this.searching$.value;
        const canRedirectToResults =
            this.currentSearch.isSearchEmpty ||
            searching ||
            this.hasResultItems;
        this.log('onSubmit', canRedirectToResults);

        if (searching) {
            this.searchSubscription?.unsubscribe();
        }

        this.functionalLog(FuncLogType.fullResults);

        if (canRedirectToResults) {
            await this.redirectToResults();
        } else {
            this.closeResultPane();
        }
    }

    public async onClickResultItem() {
        await this.searchService.saveLastSearch(this.currentSearch);
    }

    public async onShowAllResult() {
        this.log('onShowAllResult');
        this.functionalLog(FuncLogType.viewAll);

        await this.redirectToResults();
    }

    public async onFocusSearchInput() {
        this.isFilterCarouselFullWidth = false;
        this.filterItemsCarousel?.refresh();
        DomUtil.getElement<HTMLInputElement>(this.elementRef, 'input').select();

        if (
            (this.currentSearch.isSearchEmpty && !this.enableLastSearch) ||
            this.isResultPaneVisible
        ) {
            return;
        }

        this.openResultPane();
        await this.onSearch('onFocusSearchInput');
    }

    public onCloseResult() {
        this.log('onCloseResult');
        this.closeResultPane();
    }

    public async onSelectFilterItem(item: AttributeMetaValue) {
        this.log('onSelectFilterItem', item);
        this.removeCurrentAutocompleteSearchTerm();
        this.searchService.updateFilterItemsFromSpotLightItem(
            item,
            this.currentSearch
        );
        this.functionalLogKeyboardShortcut(item);
        this.clearAutocompleteResults();
        await this.onSearch('onSelectFilterItem');
        this.focusInput();
    }

    public async onSelectQuickFilter(filter: AttributeFilterModel) {
        this.log('onSelectQuickFilter');
        this.currentSearch.addFilterItem(filter);
        if (!filter.isValid()) {
            return;
        }
        await this.onSearch('onSelectQuickFilter');
        this.focusInput();
    }

    public async onSelectRecentSearch(event: IHasAttributeFilterModels) {
        const recentSearch = event as CurrentSearch;
        this.log('onClickFilteredSearch', recentSearch);
        recentSearch.setSpaceAndVersion(this.selectedSpaceIdr);
        this.currentSearch = recentSearch;
        await this.onSearch('onSelectRecentSearch');
        this.focusInput();
    }

    public async onClearLastSearches() {
        this.log('onClearLastSearches');
        await this.searchService.clearLastSearches();
        this.lastSearchesViews = null;
        await this.setLastViewedEntities();
    }

    public async onSpaceVersionSelected(spaceIdr: ISpaceIdentifier) {
        const isChanged = !SpaceIdentifier.areSame(
            spaceIdr,
            this.selectedSpaceIdr
        );
        this.log(
            'onSpaceVersionSelected',
            spaceIdr,
            { isChanged },
            !!this.filteredUsers
        );
        if (!isChanged) {
            return;
        }

        this.currentSearch.setSpaceAndVersion(spaceIdr);

        if (this.filteredUsers) {
            await this.checkAutoComplete();
            await this.onSearch('onSpaceVersionSelected-with-filteredUsers');
        } else {
            await this.onSearch('onSpaceVersionSelected-without-filteredUsers');
        }
    }

    public async onFilterItemAction(
        event: IAttributeFilterCarouselFilterChange
    ) {
        const { filterItem, action } = event;
        this.log('onFilterItemAction', filterItem, action);
        switch (action) {
            case AttributeFilterAction.itemChanged:
                await this.onFilterItemChanged(filterItem);
                break;
            case AttributeFilterAction.itemRemoved:
                await this.onFilterItemRemoving(filterItem);
                break;
            case AttributeFilterAction.formClosed:
                this.onFilterItemFormClosed(filterItem);
                break;
            default:
                CoreUtil.warn('action not implemented');
                break;
        }
    }

    public onFilterScroll() {
        if (!this.isFilterCarouselFullWidth) {
            this.isFilterCarouselFullWidth = true;
            this.filterItemsCarousel.refresh();
        }
    }

    public onEscapeKey() {
        if (!this.isPanelOpen) {
            return;
        }
        this.closeResultPane();
        this.blurInput();
    }

    //#endregion  event handlers

    //#region subscribed event handlers

    private onStateChanged(stateName: string) {
        this.log('onStateChanged', stateName);
        this.closeResultPane();
    }

    private async onModuleChanged(dgModule: DgModule) {
        this.log('onModuleChanged', DgModule[dgModule]);
        await this.clearCurrentSearch();
    }

    private onSpotlightPanelToggle(isVisible: boolean) {
        this.log('onSpotlightPanelToggle', isVisible);
        this.isResultPaneVisible = isVisible;
    }

    private async onCurrentSpaceChanged(currentSpace: Space) {
        this.log('onCurrentSpaceChanged', currentSpace);
        await this.clearCurrentSearch();
    }

    private onMainSearchSpaceChange(spaceIdr: SpaceIdentifier) {
        this.log('onMainSearchSpaceChange', spaceIdr);
        this.currentSearch.setSpaceAndVersion(spaceIdr);
        this.searchTerm = '';
    }

    //#endregion subscribed event handlers

    private async init() {
        this.currentSearch = this.currentSearch ?? this.getNewSearch();
        if (this.focusInputOnInit) {
            this.focusInput();
        }
        /**
         * Since attributes loading is bugged at this time, we need to ensure that attributes are correctly
         * loaded to be able to build filters
         * TODO: to be removed once attributes loading is fixed
         */
        this.attributeDataService
            .loadAttributes(DataUtil.getFirstClassEntityServerTypeNames())
            .then();
        await this.initSpaceAndVersion();
    }

    private subscribeEvents() {
        super.registerSubscriptions(
            this.appEventsService.stateChanged$.subscribe((stateName) =>
                this.onStateChanged(stateName)
            ),
            this.appSpaceService.subscribe({
                onChangeCurrent: (currentSpace) =>
                    this.onCurrentSpaceChanged(currentSpace),
            }),
            this.appEventsService.moduleChanged$.subscribe((dgModule) =>
                this.onModuleChanged(dgModule)
            ),
            this.searchService.isSpotlightPanelOpen$.subscribe((isVisible) =>
                this.onSpotlightPanelToggle(isVisible)
            ),
            this.currentSpaceService.mainSearchSpaceChange$.subscribe(
                (spaceIdr: ISpaceIdentifier) =>
                    this.onMainSearchSpaceChange(spaceIdr)
            )
        );
    }

    private async onFilterItemRemoving(afm: AttributeFilterModel) {
        this.log('onFilterItemRemoving', afm);
        this.currentSearch.removeFilterItem(afm);
        await this.onFiltersChange();
    }
    private async onFilterItemChanged(afm: AttributeFilterModel) {
        this.log('onFilterItemChanged', afm);
        await this.onSearch('onFilterItemChanged');
    }
    private onFilterItemFormClosed(afm: AttributeFilterModel) {
        this.log('onFilterItemFormClosed', afm);
        if (!afm.isValid()) {
            this.currentSearch.removeFilterItem(afm);
        }
        this.focusInput();
    }

    private async clearCurrentSearch() {
        this.log('clearCurrentSearch');
        this.currentSearch.clearSearchTerm();
        this.currentSearch.clearFilters();
        this.clearAutocompleteResults();
        await this.initSpaceAndVersion();
    }

    @HostListener('document:click', ['$event'])
    private onClickOutside(event: PointerEvent) {
        if (!this.isResultPaneVisible) {
            return;
        }
        const clickedOutside = !event
            .composedPath()
            .includes(this.elementRef.nativeElement);
        if (!clickedOutside) {
            return;
        }

        if (this.entityPreviewPanelService.isPanelVisible) {
            this.log('onClickOutside-prevented-isEntityPreviewPanelVisible');
            return;
        }

        if (this.dxyModalService.isActiveModal) {
            this.log('onClickOutside-prevented-isActiveModal');
            return;
        }

        const entityPreviewElement = DomUtil.getElement(
            this.document,
            'dxy-entity-preview-panel-content'
        );
        const entityPreviewClicked = event
            .composedPath()
            .includes(entityPreviewElement);

        if (entityPreviewClicked) {
            this.log('onClickOutside-prevented-entityPreviewClicked');
            return;
        }

        const cdkOverlayElement = DomUtil.getElement(
            this.document,
            '.cdk-overlay-container'
        );
        const cdkOverlayClicked = event
            .composedPath()
            .includes(cdkOverlayElement);

        if (cdkOverlayClicked) {
            this.log('onClickOutside-prevented-cdkOverlayClicked');
            return;
        }

        this.closeResultPane();
    }

    /**
     * If the filter carousel is small enough to fit with spotlight input
     * we force isFilterCarouselFullWidth to false
     */
    private updateFilterCarouselWidth() {
        const filterCarouselEl = this.filterItemsCarouselRef.nativeElement;

        // To be aligned with .filter-carousel-wrapper max-width css property
        if (
            filterCarouselEl.offsetWidth <=
            filterCarouselEl.parentElement.offsetWidth / 2
        ) {
            this.isFilterCarouselFullWidth = false;
            this.filterItemsCarousel.refresh();
        }
    }

    @withLoading()
    private async loadRecentSearchesAndEntities() {
        return await Promise.all([
            this.setRecentSearches(),
            this.setLastViewedEntities(),
        ]);
    }

    private async setRecentSearches() {
        const lastSearchesResult = await this.searchService.getLastSearches(4);
        this.lastSearchesViews = await this.getFilterItemsFromFilters(
            lastSearchesResult.RecentSearches
        );
    }

    private async setLastViewedEntities() {
        const lastEntities = await this.entityService.getLastViewedEntities(
            4,
            []
        );
        this.lastViewedEntities = lastEntities.map((hData) => ({
            HddData: hData,
        }));
    }

    private async getFilterItemsFromFilters(
        searches: RecentSearchDto[]
    ): Promise<CurrentSearch[]> {
        const currentSearches: CurrentSearch[] = [];
        for (const search of searches) {
            const currentSearch =
                await this.searchService.getCurrentSearchFromFilters(
                    search.filters,
                    search.SpaceUid,
                    search.DefaultVersionId
                );
            currentSearches.push(currentSearch);
        }
        return currentSearches;
    }

    private getNewSearch() {
        return this.searchService.getNewSearch(this.getLogger('currentSearch'));
    }

    private async initSpaceAndVersion() {
        this.log('initSpaceAndVersion');
        const spaceIdr = await this.appSpaceService.getASpaceIdentifier({
            includeCurrent: true,
            includeLast: true,
        });
        this.log('initSpaceAndVersion-getASpaceIdentifier-result', spaceIdr);
        this.currentSearch.setSpaceAndVersion(spaceIdr);
    }

    private closeResultPane() {
        this.log('closeResultPane');
        if (this.currentSearch.isSearchEmpty) {
            this.previewResult = null;
        }
        this.lastSearchesViews = null;
        this.lastViewedEntities = null;
        this.searchService.notifySpotlightPanelOpen((this.isPanelOpen = false));
    }
    private openResultPane() {
        this.log('openResultPane');
        this.searchService.notifySpotlightPanelOpen((this.isPanelOpen = true));
    }

    private async redirectToResults() {
        this.log('redirectToResults');
        const currentSearch = this.currentSearch.clone();
        this.closeResultPane();
        this.blurInput();
        this.currentSearch.clearFilters();

        const hasAutocomplete = await this.checkAutoComplete();
        if (hasAutocomplete) {
            this.removeCurrentAutocompleteSearchTerm();
        }
        await this.searchService.goToMainSearchResultsFromSpotlight(
            currentSearch
        );
    }

    private focusInput() {
        DomUtil.getElement(this.elementRef, 'input').focus();
    }

    private blurInput() {
        DomUtil.getElement(this.elementRef, 'input').blur();
    }

    //#region autocomplete

    private removeCurrentAutocompleteSearchTerm() {
        const currentToken = this.getCurrentAutoCompleteToken();
        this.searchTerm = this.searchTerm.split(currentToken)[0];
    }

    private getCurrentAutoCompleteToken() {
        if (this.currentSearch.isSearchEmpty) {
            return;
        }

        let currentAutocompleteToken: string;
        let tokenIndex: number;
        this.allTokens.forEach((token) => {
            const index = this.searchTerm.indexOf(token);
            if (
                index != -1 &&
                (index < tokenIndex || !currentAutocompleteToken)
            ) {
                currentAutocompleteToken = token;
                tokenIndex = index;
            }
        });

        return currentAutocompleteToken;
    }

    private async checkAutoComplete() {
        this.clearAutocompleteResults();
        const currentAutocompleteToken = this.getCurrentAutoCompleteToken();
        this.log('checkAutoComplete', currentAutocompleteToken);

        let result = true;
        switch (currentAutocompleteToken) {
            case this.userToken:
                await this.updateFilteredUsers().then();
                break;
            case this.tagToken:
                await this.updateFilteredTags();
                break;
            case this.domainToken:
                await this.updateFilteredDomains();
                break;
            default:
                result = false;
                break;
        }

        this.log('checkAutoComplete-result', result);
        return result;
    }

    private clearAutocompleteResults() {
        this.filteredTags = [];
        this.filteredUsers = [];
        this.filteredDomains = [];
    }

    @withLoading()
    private async updateFilteredUsers() {
        const { token, attributeKey } = this.getUserTokenAndAttributeKey();
        const query = this.searchTerm.split(token)[1];
        let values =
            await this.attributeFilterService.getSpaceGovUserFilterValues(
                this.currentSearch,
                query,
                attributeKey
            );
        values = values.filter((amv) => !this.hasFilterItem(amv));
        const result = CollectionsHelper.orderByText(
            values,
            (amv) => amv.spaceGovernanceUserDto.FirstName
        );
        this.filteredUsers = result;
        this.log('getFilteredUserValues-result', result);
    }
    private getUserTokenAndAttributeKey() {
        let attributeKey: string;
        let token = this.userToken;
        let found = false;

        for (const k in this.userAttributeTokens) {
            const uats: string[] = this.userAttributeTokens[k];
            CollectionsHelper.withFirstFound(
                uats,
                (uat) =>
                    StringUtil.contains(
                        this.searchTerm,
                        `${this.userToken}${uat}:`,
                        true,
                        true
                    ),
                (uat) => {
                    attributeKey = k;
                    token = `${this.userToken}${uat}:`;
                    found = true;
                }
            );

            if (found) {
                break;
            }
        }
        this.log('getUserTypeTokenAndAttributeKey', token, attributeKey);
        return { token, attributeKey };
    }

    @withLoading()
    private async updateFilteredTags() {
        const values =
            await this.attributeFilterService.getClientTagFilterValues(
                this.currentSearch
            );
        const candidates = values.filter((amv) => !this.hasFilterItem(amv));
        const searchTerm = this.searchTerm.split(this.tagToken)[1];
        const filteredTags = StringUtil.filterSearched(
            searchTerm,
            candidates,
            (tag) => tag.Value
        );
        this.filteredTags = CollectionsHelper.alphaSort(filteredTags, 'Value');
        this.log('updateFilteredTags-result', this.filteredTags);
    }

    @withLoading()
    private async updateFilteredDomains() {
        const searchTerm = this.searchTerm.split(this.domainToken)[1];
        const ami = AttributeFilterService.getAttributeMeta(
            ServerConstants.AttributeConstants.BusinessDomainsAttributeKey,
            this.currentSearch
        );
        const entities = await this.searchService.searchBusinessDomains(
            this.selectedSpaceIdr,
            searchTerm
        );
        const domainValues = entities.map((fei) =>
            AttributeMetaValue.fromHierarchicalData(fei.HddData, ami)
        );
        this.filteredDomains = domainValues.filter(
            (amv) => !this.hasFilterItem(amv)
        );
        this.log('updateFilteredDomains-result', this.filteredDomains);
    }

    private hasFilterItem(amv: AttributeMetaValue) {
        return this.searchService.hasCurrentFilterItem(amv, this.currentSearch);
    }

    //#endregion

    //#region functional log

    private functionalLogKeyboardShortcut(amv: AttributeMetaValue) {
        const suffix = this.getFuncLogKeyboardShortcutSuffix(amv);
        if (!suffix) {
            return;
        }
        const prefix = this.navigationService.isInSpaceHomeView
            ? 'MAIN_SEARCH'
            : 'SPOTLIGHT';
        this.functionalLogR(`${prefix}_KEYBOARDSHORTCUT_${suffix}`);
    }
    private getFuncLogKeyboardShortcutSuffix(amv: AttributeMetaValue) {
        switch (amv.attributeInfo.AttributeKey) {
            case 'DataOwners':
                return 'OWNER';
            case 'DataStewards':
                return 'STEWARD';
            case 'CdoUsers':
                return 'CDO';
            case 'DpoUsers':
                return 'DPO';
            case 'CisoUsers':
                return 'CISO';
            case 'ExpertUsers':
                return 'EXPERT';
            case ServerConstants.AttributeConstants.SystemTagsAttributeKey:
                return 'TAG';
            case ServerConstants.AttributeConstants.BusinessDomainsAttributeKey:
                return 'DOMAIN';
        }
    }

    private functionalLog(type: FuncLogType) {
        let main = 'MAIN_SEARCH',
            feature: string;
        switch (type) {
            case FuncLogType.autocomplete:
                feature = 'AUTOCOMPLETE';
                break;
            case FuncLogType.fullResults:
                feature = 'FULL';
                break;
            case FuncLogType.viewAll:
                feature = 'VIEW_ALL';
                break;
            default:
                return;
        }
        const featureCode = this.navigationService.isInSpaceHomeView
            ? `${main}_${feature}`
            : `SPOTLIGHT_${feature}_${this.navigationService.getCurrentZoneFeatureCode()}`;
        this.functionalLogR(featureCode);
    }

    private functionalLogR(featureCode: string) {
        this.functionalLogService.logFunctionalAction(
            featureCode,
            CrudOperation.R
        );
    }
}

export enum FuncLogType {
    none = 0,
    autocomplete,
    fullResults,
    viewAll,
}

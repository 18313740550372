<dxy-space-version-selector
    *ngIf="showSpaceSelector"
    [showSpaceDropdown]="showSpaceSelector"
    (onSpaceVersionSelected)="onSpaceVersionSelectedInternal($event.spaceIdr)"
    [spaceIdr]="spaceIdr"
    [allowAll]="true"
    [defaultAll]="true"
    [hasLabel]="true"
    [isSmallCaretBtn]="true"
    [showSelectedSpaceIcon]="true"
    logId="search-filters-list"
></dxy-space-version-selector>

<!-- searched text -->
<div class="dg5-filter-item searchterm-filter">
    <span class="glyph-search"></span>
    <span *ngIf="showSearchTermOperator" class="operator-name light-operator">{{
        searchTermFilterOperator
    }}</span>
    <div class="filter-group">
        <input
            matInput
            id="searchTerm"
            class="filter-value"
            type="text"
            autocomplete="off"
            [placeholder]="
                'UI.Spotlight.filters.searchTermPlaceholder' | translate
            "
            [(ngModel)]="searchTerm"
            (ngModelChange)="onSearchTermChange()"
        />
    </div>
    <button
        *ngIf="searchTerm"
        mat-icon-button
        class="dg5-small-icon-button"
        (click)="onClearSearchTerm()"
    >
        <i class="glyph-cancelsearch"></i>
    </button>
    <button
        *ngIf="searchTermOpEnabled"
        mat-icon-button
        [matMenuTriggerFor]="searchTermOpMenu"
        class="dg5-small-icon-button"
    >
        <i class="glyph-arrow-drop-down"></i>
    </button>
    <mat-menu #searchTermOpMenu="matMenu" class="dg5-mat-menu">
        <ul>
            <li
                *ngFor="let op of searchTermOps"
                (click)="onSearchTermOperatorChange(op)"
            >
                {{ getSearchOpDisplayName(op) }}
            </li>
        </ul>
    </mat-menu>
</div>

<!-- filter items -->
<div class="filter-items-outer">
    <div class="filter-items">
        <app-attribute-filter
            *ngFor="let fi of filterItems$ | async"
            [filterItemData]="fi"
            (onAction)="onFilterItemAction(fi, $event)"
        ></app-attribute-filter>
    </div>
</div>

<!-- add filter button -->
<app-attribute-filter-add-dropdown
    [attributes]="availableAttributes"
    (onAddAttribute)="onAddAttribute($event)"
></app-attribute-filter-add-dropdown>

import { Selection, BaseType } from 'd3-selection';
import { EntityUiService } from '../../shared/entity/services/entity-ui.service';
import { IEntityIdentifier } from '@datagalaxy/dg-object-model';

export interface IPopoverHelper<TData> {
    setup(...selections: Selection<BaseType, TData, any, any>[]): void;
}

export class PopoverHelper<TData> implements IPopoverHelper<TData> {
    private static debug = false;

    constructor(
        private entityUiService: EntityUiService,
        private getEntity: (data: TData) => IEntityIdentifier,
        private getId?: (data: TData) => string,
        private noPopover?: (data: TData) => boolean,
        public debug = PopoverHelper.debug
    ) {}

    public setup(...selections: Selection<BaseType, TData, any, any>[]) {
        const self = this;
        selections.forEach((sel) =>
            sel.on('mouseenter', (event, d) => self.showPopover(d, event))
        );
    }

    private log(...data: any[]) {
        if (!this.debug) {
            return;
        }
        console.log('PopoverHelper', ...data);
    }

    private async showPopover(data: any, event: MouseEvent) {
        this.log('showPopover', event.currentTarget);
        const target = event.target as HTMLElement;
        const popoverStillNeedForId = this.getId?.(data);

        if (
            this.noPopover?.(data) ||
            !popoverStillNeedForId ||
            (this.getId && popoverStillNeedForId != this.getId(data))
        ) {
            return;
        }

        const entity = this.getEntity(data);
        await this.entityUiService.openEntityPopover(target, entity);
    }
}

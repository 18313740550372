import { Injectable } from '@angular/core';
import { IEntityIdentifier } from '@datagalaxy/dg-object-model';
import { LineageGraphState } from './lineage-graph.types';
import {
    LineageEdge,
    LineageEdgeSpec,
    LineageNodeSpec,
} from '../lineage.types';
import { EntityEventService } from '../../shared/entity/services/entity-event.service';
import { LineageApiService } from '../lineage-api.service';
import { GraphNode } from '@datagalaxy/core-d3-util';
import { ReplaySubject } from 'rxjs';
import { LineageTreeGraph } from './lineage-tree-graph';
import { LineageTreeNode } from '../lineage-tree/lineage-tree-node';
import { LineageGraphRootTracker } from './lineage-graph-root-tracker';
import { LineageGraphVisibilityHandler } from './lineage-graph-visibility-handler';
import { SpaceIdentifier } from '@datagalaxy/webclient/workspace/utils';
import { BaseStateService } from '@datagalaxy/utils';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';
import { LineageNodeStreamDirection } from '../lineage-entity-stream-buttons/lineage-entity-stream.types';
import { LineageEntityStreamUtils } from '../lineage-entity-stream-buttons/lineage-entity-stream.utils';

export interface LineageGraphEvent {
    nodes: LineageTreeNode[];
    edges: LineageEdgeSpec[];
}

@Injectable()
export class LineageGraphService extends BaseStateService<LineageGraphState> {
    /** The root entity of the lineage graph currently loaded */
    private rootEntity?: EntityItem;
    private treeGraph = new LineageTreeGraph();
    private rootTracker: LineageGraphRootTracker;
    private visibilityHandler: LineageGraphVisibilityHandler;

    public added = new ReplaySubject<LineageGraphEvent>();
    public updated = new ReplaySubject<LineageGraphEvent>();
    public removed = new ReplaySubject<LineageTreeNode[]>();

    public get added$() {
        return this.added.asObservable();
    }

    public get updated$() {
        return this.updated.asObservable();
    }

    public get removed$() {
        return this.removed.asObservable();
    }

    private get spaceIdr() {
        return SpaceIdentifier.fromEntity(this.rootEntity);
    }

    constructor(
        private entityEventService: EntityEventService,
        private lineageApiService: LineageApiService
    ) {
        super({ loading: false });

        this.subscribeEvents();
    }

    public selectLoading() {
        return this.select((state) => state.loading);
    }

    public loadLineage(entity: EntityItem) {
        this.rootEntity = entity;
        this.rootTracker = new LineageGraphRootTracker(
            this.treeGraph,
            entity.ReferenceId
        );
        this.visibilityHandler = new LineageGraphVisibilityHandler(
            entity.ReferenceId
        );

        this.setState({ loading: true });

        void this.initLoadLineage(entity);
    }

    public async loadLineageNodes(
        entities: string[],
        direction: LineageNodeStreamDirection,
        level = 1,
        isPrincipal?: boolean
    ) {
        this.setState({ loading: true });

        const res = await this.lineageApiService.getLineageNodes(
            entities,
            this.spaceIdr
        );
        const added = this.addLineage(res);

        if (level > 1) {
            const nodes = added.nodes.filter((node) => node.isVisibleRoot());

            const linkedEntities = nodes
                .flatMap((node) => {
                    const nodes = LineageEntityStreamUtils.getStreamNodes(
                        node
                    ).filter((node) => {
                        if (isPrincipal == undefined) {
                            return true;
                        }

                        return this.isPrimaryNode(node.id) == isPrincipal;
                    });

                    const links = nodes.flatMap((node) => node.links);
                    return LineageEntityStreamUtils.makeStreamLinkGroup(
                        links,
                        direction
                    );
                })
                .flatMap((group) => group.entityIds);

            if (linkedEntities.length) {
                const res = await this.loadLineageNodes(
                    linkedEntities,
                    direction,
                    level - 1,
                    isPrincipal
                );

                added.nodes.push(...res.nodes);
                added.edges.push(...res.edges);
            }
        }

        this.setState({ loading: false });
        return added;
    }

    public toggleNodeVisibility(id: string, toggleExpansion?: boolean) {
        const treeNode = this.treeGraph.toggleTreeNodeVisibility(id);

        if (toggleExpansion) {
            treeNode.visible ? treeNode.open() : treeNode.close();
        }

        this.updated.next({
            nodes: [treeNode],
            edges: [],
        });
    }

    public toggleNode(id: string) {
        const treeNode = this.treeGraph.toggleTreeNode(id);
        this.updated.next({
            nodes: [treeNode, ...treeNode.getAllVisibleExpandedChildren()],
            edges: [],
        });
    }

    // #region Graph methods
    public getNode(nodeId: string): LineageTreeNode | null {
        return this.treeGraph.getNode(nodeId);
    }

    public getNodesEdges(nodeIds: string[]): LineageEdge[] {
        return this.treeGraph.getNodeEdges(...nodeIds).map((edge) => edge.data);
    }

    public getRelatedNodeEdges(nodeIds: string[]): LineageEdge[] {
        return this.treeGraph
            .getRelatedNodeEdges(...nodeIds)
            .map((edge) => edge.data);
    }

    public getRecursiveSuccessorsAndPredecessors(
        nodeId: string,
        includeChildren: boolean
    ): GraphNode<LineageTreeNode>[] {
        return this.treeGraph.getRecursiveSuccessorsAndPredecessors(
            nodeId,
            includeChildren
        );
    }

    public getConnectedNodesAndEdges(nodeId: string, includeChildren: boolean) {
        const nodes = this.getRecursiveSuccessorsAndPredecessors(
            nodeId,
            includeChildren
        );
        const nodeIds = nodes.map((node) => node.id);
        const edges = this.getRelatedNodeEdges(nodeIds);

        return {
            nodes,
            edges,
        };
    }

    public hasEdges(edgeIds: string[]) {
        return edgeIds.every((edgeId) => this.treeGraph.getEdge(edgeId));
    }

    public getPrimaryNodeAndEdges() {
        return this.getRecursiveSuccessorsAndPredecessors(
            this.rootEntity.ReferenceId,
            true
        );
    }

    public areNodeSuccessors(entityIdr: IEntityIdentifier[]) {
        return entityIdr.some((entity) =>
            this.rootTracker.areNodeSuccessors(entity.ReferenceId)
        );
    }

    public areNodePredecessors(entityIdr: IEntityIdentifier[]) {
        return entityIdr.some((entity) =>
            this.rootTracker.areNodePredecessors(entity.ReferenceId)
        );
    }

    public isPrimaryNode(nodeId: string) {
        return this.rootTracker.isPrimaryNode(nodeId);
    }

    public getRoot(): LineageTreeNode {
        return this.rootTracker.getRootTreeNode();
    }
    // #endregion

    private async initLoadLineage(entity: EntityItem) {
        const res = await this.lineageApiService.loadRoot(entity);

        this.addLineage(res);

        this.setState({ loading: false });
    }

    public removeNodes(ids: string[]) {
        const removedNodes = this.treeGraph.removeNodes(ids);
        this.rootTracker.update();
        this.removed.next(removedNodes);
    }

    private addLineage(nodes: LineageNodeSpec[]) {
        const res = this.treeGraph.add(nodes);
        this.rootTracker.update();
        this.visibilityHandler.update(res.nodes);
        this.added.next(res);

        return res;
    }

    private subscribeEvents() {
        this.entityEventService.subscribeEntityLinkAdd(null, (entity) =>
            this.onEntityLinkAdd(entity)
        );

        this.entityEventService.subscribeEntityLinkDelete(null, () =>
            this.onEntityLinkDelete()
        );
    }

    private async onEntityLinkAdd(_entity: EntityItem) {}

    private async onEntityLinkDelete() {
        // TODO: Implement when we are able to remove nodes & edges disconnected from the root node
    }
}

import { Injectable } from '@angular/core';
import { NavigationService } from '../../services/navigation.service';
import { IActionOption, IListOption } from '@datagalaxy/core-ui';
import { StateName } from '@datagalaxy/webclient/routing';
import { EntityType, IHasHddData } from '@datagalaxy/dg-object-model';
import {
    DKSEntityMenuListOption,
    DksEntityMenuOption,
    TDksEntityMenu,
} from '../../data-knowledge-studio/menu/dks-entity-menu/dks-entity-actions.types';
import { LineageGraphService } from '../lineage-graph/lineage-graph.service';
import { LineagePathTrackerComponent } from '../lineage-path-tracker/lineage-path-tracker-option/lineage-path-tracker.component';
import { EntityIdentifier } from '@datagalaxy/webclient/entity/utils';
import { LineageEntityStreamService } from '../lineage-entity-stream-buttons/lineage-entity-stream.service';
import {
    LineageNodeStreamDirection,
    LineageNodeStreamStatus,
} from '../lineage-entity-stream-buttons/lineage-entity-stream.types';
import { LineageDksApi } from '../lineage.types';

@Injectable()
export class LineageEntityMenuService {
    private dksApi: LineageDksApi;

    constructor(
        private navigationService: NavigationService,
        private lineageGraph: LineageGraphService,
        private entityStreamService: LineageEntityStreamService
    ) {}

    public getMenu(): TDksEntityMenu {
        return [
            {
                type: 'default',
                option: DksEntityMenuOption.Preview,
                hidden: (entity: IHasHddData) =>
                    !entity.HddData.Data.HasReadAccess,
            },
            { type: 'option', option: this.getBurgerMenu() },
            ...this.getStreamOptions(),
        ];
    }

    public setupMenu(dksApi: LineageDksApi) {
        this.dksApi = dksApi;
    }

    private getBurgerMenu(): IListOption<IHasHddData> {
        return {
            tooltipTranslateKey: 'UI.DiagramNode.moreOptions',
            options: [this.getLineageOption(), this.getTrackerOption()].filter(
                (opt) => !!opt
            ),
        };
    }

    private getLineageOption(): IActionOption<IHasHddData> {
        return {
            labelKey: 'UI.EntityImpactAnalysis.lineageBurgerMenu.goToLineage',
            callback: (entity: IHasHddData) =>
                this.navigationService.goToEntityDetailsByIdentifier(
                    EntityIdentifier.fromIHierarchicalData(entity.HddData),
                    { tabName: StateName.RelativeTabImpactAnalysis }
                ),
            hidden: (entity: IHasHddData) => {
                if (!entity.HddData.Data.HasReadAccess) {
                    return true;
                }

                const rootNode = this.lineageGraph.getRoot();

                if (!rootNode) {
                    return false;
                }

                const isRoot = rootNode.id === entity.HddData.ReferenceId;
                const isDataProcessingItem =
                    entity.HddData.entityType === EntityType.DataProcessingItem;

                return isRoot || isDataProcessingItem;
            },
            glyphClass: 'glyph-analysis',
        };
    }

    private getTrackerOption(): IActionOption<IHasHddData> {
        return {
            labelKey: 'PathTracker',
            renderData: {
                renderer: LineagePathTrackerComponent,
            },
        };
    }

    private getStreamOptions(): DKSEntityMenuListOption[] {
        const options = [
            {
                alwaysVisible: true,
                glyphClass: (entity: IHasHddData) => {
                    const hasGoldenLinks =
                        this.entityStreamService.hasGoldenLinks(
                            EntityIdentifier.fromIHierarchicalData(
                                entity.HddData
                            )
                        );
                    const glyph = hasGoldenLinks
                        ? 'glyph-golden-link golden'
                        : 'glyph-glossary';
                    return `${glyph} glossary-upstream collapsed`;
                },
                tooltipTranslateKey: (entity: IHasHddData) => {
                    const hasGoldenLinks =
                        this.entityStreamService.hasGoldenLinks(
                            EntityIdentifier.fromIHierarchicalData(
                                entity.HddData
                            )
                        );

                    return hasGoldenLinks
                        ? 'UI.ImpactAnalysis.lineage.ttGoldenItemIcon'
                        : 'UI.ImpactAnalysis.lineage.entityMenu.glossaryLinks';
                },
                hidden: (entity: IHasHddData) => {
                    const res = this.entityStreamService.getEntityStream(
                        EntityIdentifier.fromIHierarchicalData(entity.HddData),
                        LineageNodeStreamDirection.TopStream
                    );

                    return res?.status !== LineageNodeStreamStatus.Collapsed;
                },
                callback: async (entity: IHasHddData) => {
                    await this.entityStreamService.expandEntityStream(
                        EntityIdentifier.fromIHierarchicalData(entity.HddData),
                        LineageNodeStreamDirection.TopStream
                    );

                    this.dksApi.refreshEntitiesMenu();
                },
            },
            {
                alwaysVisible: true,
                glyphClass: 'glyph-minus glossary-upstream expanded',
                hidden: (entity: IHasHddData) => {
                    const res = this.entityStreamService.getEntityStream(
                        EntityIdentifier.fromIHierarchicalData(entity.HddData),
                        LineageNodeStreamDirection.TopStream
                    );

                    return res?.status !== LineageNodeStreamStatus.Expanded;
                },
                callback: async (entity: IHasHddData) => {
                    this.entityStreamService.collapseEntityStream(
                        EntityIdentifier.fromIHierarchicalData(entity.HddData),
                        LineageNodeStreamDirection.TopStream
                    );

                    this.dksApi.refreshEntitiesMenu();
                },
            },
            {
                alwaysVisible: true,
                glyphClass: (entity: IHasHddData) => {
                    const hasGoldenLinks =
                        this.entityStreamService.hasGoldenLinks(
                            EntityIdentifier.fromIHierarchicalData(
                                entity.HddData
                            )
                        );
                    const glyph = hasGoldenLinks
                        ? 'glyph-golden-link golden'
                        : 'glyph-catalog';
                    return `${glyph} glossary-bottomstream collapsed`;
                },
                tooltipTranslateKey: (entity: IHasHddData) => {
                    const hasGoldenLinks =
                        this.entityStreamService.hasGoldenLinks(
                            EntityIdentifier.fromIHierarchicalData(
                                entity.HddData
                            )
                        );

                    return hasGoldenLinks
                        ? 'UI.ImpactAnalysis.lineage.ttGoldenItemIcon'
                        : 'UI.ImpactAnalysis.lineage.entityMenu.catalogLinks';
                },
                hidden: (entity: IHasHddData) => {
                    const res = this.entityStreamService.getEntityStream(
                        EntityIdentifier.fromIHierarchicalData(entity.HddData),
                        LineageNodeStreamDirection.BottomStream
                    );

                    return res?.status !== LineageNodeStreamStatus.Collapsed;
                },
                callback: async (entity: IHasHddData) => {
                    await this.entityStreamService.expandEntityStream(
                        EntityIdentifier.fromIHierarchicalData(entity.HddData),
                        LineageNodeStreamDirection.BottomStream
                    );

                    this.dksApi.refreshEntitiesMenu();
                },
            },
            {
                alwaysVisible: true,
                glyphClass: 'glyph-minus glossary-bottomstream expanded',
                hidden: (entity: IHasHddData) => {
                    const res = this.entityStreamService.getEntityStream(
                        EntityIdentifier.fromIHierarchicalData(entity.HddData),
                        LineageNodeStreamDirection.BottomStream
                    );

                    return res?.status !== LineageNodeStreamStatus.Expanded;
                },
                callback: async (entity: IHasHddData) => {
                    this.entityStreamService.collapseEntityStream(
                        EntityIdentifier.fromIHierarchicalData(entity.HddData),
                        LineageNodeStreamDirection.BottomStream
                    );

                    this.dksApi.refreshEntitiesMenu();
                },
            },
        ];

        return options.map((opt) => ({
            type: 'option',
            option: opt,
        }));
    }
}

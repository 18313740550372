<header>
    <dxy-widget-title-editor
        class="header-title"
        [editionEnabled]="editionEnabled"
        [customTitle]="widgetConfiguration?.customTitle"
        defaultTitleKey="UI.SpaceHome.Widgets.SpaceFilteredViews.title"
        (customTitleChange)="onCustomTitleChange($event)"
        dxyEllipsisTooltip
    ></dxy-widget-title-editor>
    <dxy-value-list-filter
        labelKey="DgServerTypes.BaseData.fields.Module"
        xMenuPosition="before"
        [(filter)]="modulesFilter"
        [items]="moduleFilterOptions"
        [config]="moduleFilterConfig"
        [noOperator]="true"
        [adapter]="moduleFilterAdapter"
        (filterChange)="onModuleFilterChange()"
    ></dxy-value-list-filter>
</header>
<section class="body" [class.disabled]="editionEnabled">
    <dxy-tabs-header [tabs]="tabs" (tabChange)="onTabChange($event)">
        <div class="tab-content">
            <div
                class="fv-item"
                *ngFor="let fv of filteredViews"
                (click)="onFilteredViewClick(fv)"
            >
                <dxy-icon-card
                    [icon]="fvClass(fv)"
                    [iconTooltip]="getTooltipLabelKey(fv) | translate"
                    [text]="
                        'Entity.EntityType.ShortName.FilteredView' | translate
                    "
                ></dxy-icon-card>
                <span
                    class="fv-item-name"
                    [dxyEllipsisTooltip]="fv.Description"
                    dxyEllipsisMode="alwaysVisible"
                    >{{ fv.DisplayName }}</span
                >
                <span *ngIf="hasCount(fv); else spinner" class="dg5-badge">{{
                    fv.entitiesCount | numberFormat
                }}</span>
                <ng-template #spinner>
                    <dxy-spinner class="no-margin"></dxy-spinner>
                </ng-template>
            </div>
            <dxy-spinner
                *ngIf="loading$ | async"
                class="absolute-centered"
            ></dxy-spinner>
            <div *ngIf="showPlaceholder" class="dxy-placeholder">
                <img class="dxy-placeholder-img" src="{{ placeholderSrc }}" />
                <span
                    class="dxy-placeholder-text"
                    [translate]="placeholderKey"
                ></span>
            </div>
        </div>
    </dxy-tabs-header>
</section>

import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    HostBinding,
    Input,
    Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'dxy-slide-toggle',
    standalone: true,
    imports: [CommonModule, FormsModule],
    templateUrl: './slide-toggle.component.html',
    styleUrls: ['./slide-toggle.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SlideToggleComponent {
    @Input() value = false;
    @Input() @HostBinding('class.disabled') disabled? = false;
    @Output() valueChange = new EventEmitter<boolean>();

    protected uniqueId = `dxy-slide-toggle-${Math.random()
        .toString(36)
        .substring(2)}`;
}

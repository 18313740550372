import { Component } from '@angular/core';
import { DxyBaseComponent } from '@datagalaxy/ui/core';

@Component({
    selector: 'dxy-space-main',
    templateUrl: 'dxy-space-main.component.html',
    styleUrls: ['dxy-space-main.component.scss'],
})
export class DxySpaceMainComponent extends DxyBaseComponent {
    constructor() {
        super();
    }
}

import {
    AfterViewInit,
    Component,
    ContentChildren,
    ElementRef,
    Input,
    NgZone,
    OnChanges,
    Optional,
    QueryList,
    Self,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { IRadioOption } from '..';
import { DxyRadioFieldControlComponent } from '../controls/radio-field-control/radio-field-control.component';
import { DxyFieldRadioItemComponent } from '../field-radio-item/field-radio-item.component';
import { DxyBaseFocusableFieldComponent } from '@datagalaxy/ui/fields';

@Component({
    selector: 'dxy-field-radio',
    templateUrl: 'field-radio.component.html',
})
export class DxyFieldRadioComponent<T>
    extends DxyBaseFocusableFieldComponent<T>
    implements AfterViewInit, OnChanges
{
    @Input() options?: IRadioOption<T>[];

    public get label() {
        return this.getLabel(this.translate);
    }
    public get labelTooltip() {
        return this.getLabelTooltip(this.translate);
    }
    public get errorMessage() {
        return this.getErrorMessage(this.translate);
    }

    public get readOnlyOption() {
        return this.options?.find((o) => o.value == this.value);
    }

    @ContentChildren(DxyFieldRadioItemComponent, { descendants: true })
    public radioItems!: QueryList<DxyFieldRadioItemComponent<T>>;
    @ViewChild('fieldControl')
    protected fieldControl: DxyRadioFieldControlComponent<T>;

    protected get empty() {
        return this.fieldControl?.empty;
    }

    constructor(
        private translate: TranslateService,
        elementRef: ElementRef<HTMLElement>,
        ngZone: NgZone,
        @Optional() @Self() ngControl: NgControl
    ) {
        super(elementRef, ngZone, ngControl);
    }

    ngAfterViewInit() {
        super.ngAfterViewInit();
        this.createRadioButtons();
    }

    ngOnChanges(changes: SimpleChanges) {
        super.onChange(changes, 'radioItems', () => this.createRadioButtons());
    }

    private createRadioButtons() {
        if (!this.radioItems?.length) {
            return;
        }
        setTimeout(() => {
            this.options = this.radioItems.map(
                (ri) =>
                    ({
                        value: ri.value,
                        templateRefLabel: ri.itemTemplate,
                        disabled: ri.disabled,
                    } as IRadioOption<T>)
            );
        }, 10);
    }
}

import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { IUserGridCellBadgeData, IUserGridCellData } from './user-cell.types';
import {
    BaseCellComponent,
    IUserBadgeCellData,
} from '@datagalaxy/core-ui/cell-components';
import { CoreUiModule, IActionOption } from '@datagalaxy/core-ui';
import { UserPublicService } from './user-public-service';
import { IUserCommonDto } from '@datagalaxy/webclient/user/domain';
import { LicenseLevel } from '@datagalaxy/webclient/license/domain';

/**
 * ## Role
 * User badge with user name, lock and licence level informations
 */
@Component({
    selector: 'dxy-user-cell',
    standalone: true,
    styleUrls: ['user-cell.component.scss'],
    templateUrl: 'user-cell.component.html',
    imports: [CoreUiModule],
})
export class UserCellComponent<TEntity>
    extends BaseCellComponent<TEntity, IUserGridCellData>
    implements OnChanges
{
    @Input() public userId: string;
    @Input() public user: IUserCommonDto;
    @Input() public badgeData: IUserGridCellBadgeData;
    @Input() public isDefault: boolean;
    @Input() public actionData: TEntity;
    @Input() public actions: IActionOption<TEntity, unknown>[];
    @Input() public tooltipClass: string;
    @Input() public disableTooltip: boolean;
    @Input() public hideUserName: boolean;
    @Input() public mini: boolean;

    protected userName?: string;
    protected tagData?: IUserBadgeCellData;
    protected licenseLevel?: string;
    protected displayUserBadge?: boolean;
    protected locked?: boolean;
    protected lockTooltipKey?: string;

    protected userBadgeGlyphClass?: string;
    protected userBadgeTooltip?: string;

    public get showUserName() {
        return !!this.userName && !this.hideUserName;
    }
    public override get isMini() {
        return super.isMini || this.mini;
    }

    constructor(private userService: UserPublicService) {
        super();
    }

    override ngOnChanges(changes: SimpleChanges) {
        super.ngOnChanges(changes);
        super.onChanges(
            changes,
            ['userId', 'badgeData', 'isDefault'],
            () => this.init(),
            true
        );
    }

    protected override updateBindings() {
        this.actionData = this.data;
        this.actions = this.params?.actions;

        this.userId = this.value?.userId;
        this.isDefault = super.value?.isDefault;
        this.badgeData = this.value?.badgeData;
        this.user = this.value?.user;

        this.init();
    }

    private init() {
        const userId = this.userId;
        if (!userId) {
            return;
        }
        const user = this.user || this.userService.getPublicUserData(userId);
        if (!user) {
            return;
        }
        this.userName = user.FullName;
        this.tagData = this.userService.getUserBadgeData(user);
        this.initUserBadgeData(user);
    }

    private initUserBadgeData(user: IUserCommonDto) {
        const badgeData = this.badgeData;
        if (!badgeData?.enableBadgeDisplay) {
            return;
        }

        if (
            badgeData.enableLicenseLevelDisplay &&
            user.LicenseLevel !== undefined
        ) {
            this.licenseLevel = LicenseLevel[user.LicenseLevel];
        }
        this.displayUserBadge = badgeData.isBadgeVisible?.(user);
        this.userBadgeGlyphClass = badgeData.glyphClass;
        this.userBadgeTooltip = badgeData.tooltipText;
        this.locked = badgeData.locked;
        this.lockTooltipKey = badgeData.lockTooltipKey;
    }
}

import { INgZone, ZoneUtils } from './zone.utils';

export class DomUtils {
    /** Adds an event listener and returns a function to remove it.
     * If *ngZone* is provided and *outsideAngular* is *true* or *undefined*, *runOutsideAngular()* will be used. */
    public static addListener<K extends keyof HTMLElementEventMap>(
        el: Element | Document | Window,
        eventName: K | K[],
        listener: (ev: HTMLElementEventMap[K]) => unknown,
        ngZone?: INgZone,
        outsideAngular = true
    ) {
        return this.addCustomListener(
            el,
            eventName,
            listener,
            ngZone,
            outsideAngular
        );
    }

    /**
     * Adds an event listener and returns a function to remove it.
     * If *ngZone* is provided and *outsideAngular* is *true* or *undefined*, *runOutsideAngular()* will be used. */
    public static addCustomListener<K extends keyof HTMLElementEventMap>(
        el: Element | Document | Window,
        eventName: string | string[],
        listener: (ev: HTMLElementEventMap[K]) => unknown,
        ngZone?: INgZone,
        outsideAngular = true
    ) {
        if (!el || !listener || !eventName) {
            return () => {};
        }
        const eventNames = Array.isArray(eventName) ? eventName : [eventName];
        ZoneUtils.zoneExecute(
            () =>
                eventNames.forEach((evtName) =>
                    el.addEventListener(evtName, (e: Event) =>
                        listener(e as HTMLElementEventMap[K])
                    )
                ),
            ngZone,
            outsideAngular
        );
        return () =>
            eventNames.forEach((evtName) =>
                el.removeEventListener(evtName, (e: Event) =>
                    listener(e as HTMLElementEventMap[K])
                )
            );
    }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EntityCardModule } from '../../../shared/entityCard/entity-card.module';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { NgForOf, NgIf } from '@angular/common';
import { LineageTreeNode } from '../../lineage-tree/lineage-tree-node';
import { HierarchicalData, IHasHddData } from '@datagalaxy/dg-object-model';
import { IActionOption } from '@datagalaxy/core-ui';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';

@Component({
    standalone: true,
    selector: 'app-lineage-item-picker-item',
    templateUrl: './lineage-item-picker-item.component.html',
    styleUrls: ['./lineage-item-picker-item.component.scss'],
    imports: [EntityCardModule, MatLegacyButtonModule, NgIf, NgForOf],
})
export class LineageItemPickerItemComponent {
    @Input() node: LineageTreeNode;
    @Input() baseDepth: number;
    @Input() open: boolean;
    @Input() actions: IActionOption<Partial<IHasHddData>>[];

    @Output() toggle = new EventEmitter<void>();

    protected get paddingLeft() {
        return (
            (this.node.getDepth() - this.baseDepth) * 16 +
            (this.node.hasChildren ? 0 : 40)
        );
    }

    protected onToggle() {
        this.toggle.emit();
    }

    protected getChildHddData(node: LineageTreeNode): HierarchicalData {
        return (node.entityIdentifier as EntityItem).HddData;
    }
}

import { NgModule } from '@angular/core';
import { DxyUsersGlobalActivityWidgetComponent } from './widgets/dxy-users-global-activity-widget/dxy-users-global-activity-widget.component';
import { CoreUiModule } from '@datagalaxy/core-ui';
import { DxyWidgetHeaderComponent } from './dxy-widget-header/dxy-widget-header.component';
import { DxySharedUiModule } from '../shared/shared-ui/DxySharedUiModule';
import { DxySpaceSelectorModule } from '../space-selector/DxySpaceSelectorModule';
import { DxySpaceModuleWidgetComponent } from './widgets';
import { DxySharedEntityModule } from '../shared/entity/DxySharedEntityModule';
import { DirectivesModule } from '../directives/directives.module';
import { GlossaryModule } from '../glossary/glossary-module';

@NgModule({
    imports: [
        CoreUiModule,
        DxySharedUiModule,
        DxySpaceSelectorModule,
        DxySharedEntityModule,
        GlossaryModule,
        DirectivesModule,
    ],
    declarations: [
        DxyWidgetHeaderComponent,
        DxyUsersGlobalActivityWidgetComponent,
        DxySpaceModuleWidgetComponent,
    ],
    exports: [
        DxyWidgetHeaderComponent,
        DxyUsersGlobalActivityWidgetComponent,
        DxySpaceModuleWidgetComponent,
    ],
})
export class SharedWidgetModule {}

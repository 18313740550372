import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnInit,
} from '@angular/core';
import { DxyBaseFilterComponent } from '../base-filter/base-filter.component';
import { TranslateService } from '@ngx-translate/core';
import { IBooleanFilterData } from './boolean-filter.types';

/**
 * ## Role
 * Display a boolean filter button
 */
@Component({
    selector: 'dxy-boolean-filter',
    templateUrl: './boolean-filter.component.html',
    styleUrls: ['./boolean-filter.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DxyBooleanFilterComponent
    extends DxyBaseFilterComponent<IBooleanFilterData, void>
    implements OnInit
{
    @Input() iconClass = 'glyph-checkbox';

    public get valueDisplay() {
        return (
            this.isResolved &&
            this.translate.instant(
                this.value ? 'CoreUI.Global.True' : 'CoreUI.Global.False'
            )
        );
    }

    public get isResolved() {
        return this.value != null;
    }
    public get value() {
        return this.filter?.value;
    }

    constructor(private translate: TranslateService) {
        super();
    }

    ngOnInit() {
        if (!this.filter) {
            this.filter = {};
        }
    }

    public setBooleanValue(newValue: boolean) {
        this.filter.value = newValue;
        this.filterChange.emit(this.filter);
    }
}

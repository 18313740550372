<div
    *ngIf="activeState$ | async as state"
    [class.hidden]="state.hidden"
    class="entity-docking-pane vcontainerDiv"
    [class.collapsed]="state.collapsed"
>
    <dxy-entity-panel-body
        [data]="panelBodyData"
        [activeState]="state"
        logId="docking-pane"
        [noTableColumns]="true"
        [noPrimaryKey]="true"
        [collapsed]="state.collapsed"
        (toolClick)="onToolClick($event)"
        (toggleClick)="toggleCollapse()"
    ></dxy-entity-panel-body>
</div>

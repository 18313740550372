import { FocusMonitor } from '@angular/cdk/a11y';
import {
    Component,
    ElementRef,
    Input,
    NgZone,
    Optional,
    Self,
    ViewChild,
} from '@angular/core';
import { FormGroupDirective, NgControl, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatLegacyFormFieldControl as MatFormFieldControl } from '@angular/material/legacy-form-field';
import { CollectionsHelper, DomUtil } from '@datagalaxy/core-util';
import { DxyBaseMatFormFieldControl } from '../../../base/DxyBaseMatFormFieldControl';

/*
    NOTE: Proper change detection is ensured by
    replacing the value array instead of mutating it
*/

/**
 * ## Role
 * File upload Input
 */
@Component({
    selector: 'dxy-file-upload-field-control',
    templateUrl: './file-upload-field-control.component.html',
    styleUrls: ['./file-upload-field-control.component.scss'],
    providers: [
        {
            provide: MatFormFieldControl,
            useExisting: DxyFileUploadFieldControlComponent,
        },
    ],
})
export class DxyFileUploadFieldControlComponent extends DxyBaseMatFormFieldControl<
    File[]
> {
    @Input() maxFileSize: number;
    @Input() allowsMultiple: boolean;
    @Input() acceptedFormat: string;
    /** Optional. Must return true when at least one file is not valid */
    @Input() checkFiles: (files: File[]) => boolean;

    @ViewChild('inputRef') inputRef: ElementRef<HTMLInputElement>;

    //#region API
    public get hasFiles(): boolean {
        return !!this.value?.length;
    }
    //#endregion

    constructor(
        @Optional() @Self() ngControl: NgControl,
        @Optional() parentForm: NgForm,
        @Optional() parentFormGroup: FormGroupDirective,
        defaultErrorStateMatcher: ErrorStateMatcher,
        focusMonitor: FocusMonitor,
        elementRef: ElementRef,
        ngZone: NgZone
    ) {
        super(
            'dxy-file-upload-field-control',
            ngControl,
            parentForm,
            parentFormGroup,
            defaultErrorStateMatcher,
            focusMonitor,
            elementRef,
            ngZone
        );
    }

    //#region API
    public focus() {
        this.inputRef.nativeElement.focus();
    }
    public blur() {
        this.inputRef.nativeElement.blur();
    }
    public click() {
        this.inputRef.nativeElement.click();
    }
    //#endregion

    public onFilesChange(files: File[]) {
        if (files?.length) {
            const hasError = this.checkFiles?.(files);
            if (!hasError) {
                this.addUniqueFiles(files);
            }
        }
    }

    public onRemoveFileClick($event: Event, file: File) {
        $event.stopPropagation();
        this.value = CollectionsHelper.removeElement(this.value, file).slice();
    }

    private addUniqueFiles(files: File[]) {
        if (!files.length) {
            return;
        }

        if (!this.allowsMultiple) {
            this.value = [files[0]];
        } else if (this.value.length) {
            const newFiles = CollectionsHelper.filterOutOccurences(
                files,
                DomUtil.areSameFile,
                ...this.value
            );
            this.value = [...this.value, ...newFiles];
        } else {
            this.value = files;
        }
    }
}

<ng-container *ngIf="lastEntryValue != undefined">
    <dxy-trend-pill
        [trend]="trend"
        [colorRule]="colorRule"
        [matTooltip]="'UI.TimeSeriesTrendRenderer.tooltip' | translate"
    ></dxy-trend-pill>

    <div class="value-container">
        {{ lastEntryValue }}
    </div>
</ng-container>

import { NgModule } from '@angular/core';
import { DxyEntitySelectorFieldComponent } from './dxy-entity-selector-field/dxy-entity-selector-field.component';
import { AttributeFilterModule } from '../attribute/attribute-filter/AttributeFilterModule';
import { DxyNavigationModule } from '../../navigation/DxyNavigationModule';
import { DxyFilterModule } from '../filter/DxyFilterModule';
import { DxyFieldsModule } from '@datagalaxy/core-ui/fields';
import { SharedModule } from '../shared.module';
import { EntityCardModule } from '../entityCard/entity-card.module';

@NgModule({
    imports: [
        SharedModule,
        DxyNavigationModule,
        DxyFieldsModule,
        EntityCardModule,
        DxyFilterModule,
        AttributeFilterModule,
    ],
    declarations: [DxyEntitySelectorFieldComponent],
    exports: [DxyEntitySelectorFieldComponent],
})
export class DxyEntitySelectorModule {}

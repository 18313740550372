<ng-container *ngIf="filteredView$ | async as fv; else placeholder">
    <header>
        <h3 class="header-title" dxyEllipsisTooltip>
            {{ fv.DisplayName }}
            <span class="dg5-badge">{{ count | numberFormat }}</span>
        </h3>

        <button *ngIf="!editionEnabled" mat-button (click)="showEverything(fv)">
            <span translate>UI.Global.filter.seeAll</span>
        </button>
    </header>

    <section class="body" [class.disabled]="editionEnabled">
        <app-space-filtered-view-entities
            [entities]="filteredViewObjects$ | async"
            (showEverythingClick)="showEverything(fv)"
        ></app-space-filtered-view-entities>
    </section>
</ng-container>

<ng-template #placeholder>
    <header>
        <h3 class="header-title" translate>
            UI.SpaceHome.Widgets.SpaceFilteredViewObjects.title
        </h3>
    </header>
    <section class="body" [class.disabled]="editionEnabled">
        <img
            *ngIf="!noAccess"
            class="preview"
            src="/images/space-widgets/space-filtered-view-objects.svg"
        />
        <div *ngIf="noAccess" class="dxy-placeholder">
            <img
                class="dxy-placeholder-img"
                src="/images/placeholders/common/deleted.svg"
            />
            <span class="dxy-placeholder-text" translate>
                UI.SpaceHome.Widgets.SpaceFilteredViewObjects.filteredViewDeletedPlaceholder
            </span>
        </div>
    </section>
</ng-template>

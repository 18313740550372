<div
    style="visibility: hidden; position: fixed"
    [style.left.px]="menuPosition.x"
    [style.top.px]="menuPosition.y"
    [matMenuTriggerFor]="menu"
    (menuOpened)="onMenuOpenClose(true)"
    (menuClosed)="onMenuOpenClose(false)"
></div>
<mat-menu #menu="matMenu" class="dg5-mat-menu">
    <ul>
        <li (click)="deleteConnection()" translate>
            UI.DataProcessingItem.deleteLink
        </li>
    </ul>
</mat-menu>

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BaseCellComponent } from '@datagalaxy/core-ui/cell-components';
import {
    ISuggestionCellInputs,
    ISuggestionCellParams,
    ISuggestionCellValue,
} from './suggestion-cell.types';
import { CoreUtil } from '@datagalaxy/core-util';
import { IListOptionItem } from '@datagalaxy/core-ui';
import { ISuggestion } from '../suggestion.types';

/**
 * ## Role
 * Suggestion value with score and user action buttons (accept/decline)
 */
@Component({
    selector: 'app-suggestion-cell',
    templateUrl: 'suggestion-cell.component.html',
    styleUrls: ['suggestion-cell.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SuggestionCellComponent
    extends BaseCellComponent<any, ISuggestionCellValue, ISuggestionCellParams>
    implements ISuggestionCellInputs
{
    @Input() suggestion: ISuggestion;
    @Input() showGroupDisplayName?: boolean;
    @Input() featureCodePrefix: string;
    @Input() accepted?: boolean;

    protected option?: IListOptionItem;

    protected get acceptedIndicatorVisible() {
        return this.accepted !== undefined;
    }
    protected get actionsVisible() {
        return !this.acceptedIndicatorVisible;
    }
    protected get score() {
        return this.suggestion.score || 0;
    }

    constructor() {
        super();
    }

    protected override updateBindings() {
        CoreUtil.assign(this, this.params.inputs);
        CoreUtil.assign(this, this.value);
    }
}

<ng-container *ngIf="translationResult$ | async as result">
    <span
        class="display-name-value"
        [dxyEllipsisTooltip]="displayNameTooltip"
        dxyEllipsisMode="alwaysVisible"
        dxyEllipsisTooltipPosition="below"
        (click)="titleClicked.emit()"
    >
        {{ result.value }}
    </span>
    <dxy-attribute-translation-button
        *ngIf="result.translation?.origin === aiOrigin"
        [entityIdentifier]="entity"
        [translation]="result.translation"
        [attributeName]="
            'DgServerTypes.BaseData.fields.DisplayName' | translate
        "
        [metalModelValue]="entity?.DisplayName"
    ></dxy-attribute-translation-button>
</ng-container>

import { DksGraphOptions } from './dks-config.types';
import { DiagramConstants } from '../../diagrams/diagram/diagram.constants';
import { GridMode } from '@datagalaxy/core-d3-util';
import {
    DksGraphSurfaceOptions,
    EntityDksNode,
    TDksNode,
} from '../data-knowledge-studio.types';
import { DksEntitiesService } from '../entities/dks-entities.service';
import { NodeUtils } from '../nodes/node.utils';

export class ConfigBuilder {
    public static buildGraphSurfaceConfig<NodeData, EdgeData>(
        config: DksGraphOptions<NodeData, EdgeData>,
        dksEntitiesService: DksEntitiesService
    ): DksGraphSurfaceOptions<NodeData, EdgeData> {
        return {
            initialZoomToFit: config.initialZoomToFit,
            layout: {
                mode: config.layout,
                nodeSortingValue: (node: NodeData) =>
                    NodeUtils.isEntityNode(node as TDksNode<NodeData>)
                        ? dksEntitiesService.getEntityById(
                              (node as EntityDksNode<NodeData>).entityIdr
                          )?.DisplayName
                        : null,
            },
            zoomBestFit: {
                durationMs: 0,
                zoomMax: 1,
                margin: [20, 20, 20, 90],
            },
            nodeDrag: config.nodeDrag,
            hover: config.hover,
            minimapContainer: '[mini-map-content]',
            grid: {
                cellSize: DiagramConstants.bgGrid.cellSize,
                dotRadius: DiagramConstants.bgGrid.dotRadius,
                visible: config.gridMode === GridMode.on,
                magnetic: [GridMode.on, GridMode.hidden].includes(
                    config.gridMode
                ),
            },
            selection: {
                disabled: config.selectionDisabled,
                selectedClass: 'selected',
                area: {
                    areaClass: 'selection-area',
                    selectingClass: 'selected',
                    getEnclosedOptions: {
                        useClientRect: true,
                        subtractSurfaceOffset: true,
                    },
                },
            },
            resize: {
                disabled: !config.resize,
                resizingItemClass: 'resizing',
                min: { width: 60, height: 60 },
                zoomIndependent: false,
                handles: {
                    class: 'resize-handle',
                    frameClass: 'resize-frame',
                    size: 16,
                    cornersOnly: false,
                    margin: 5,
                },
            },
            linking: config.linking,
            endpoint: config.endpoint,
            graph: config.graph,
        };
    }
}

<div class="card-content">
    <dxy-widget-handle
        class="widget-handle"
        [class.visible]="enableEdition"
        [options]="dropDownOptions"
        [disabled]="dragging$ | async"
    ></dxy-widget-handle>
    <ng-template #container> </ng-template>
    <div class="skeleton-container" *ngIf="widgetLoading$ | async">
        <dxy-skeleton-list></dxy-skeleton-list>
    </div>
</div>

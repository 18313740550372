import {
    baseIconCollectionCellInputs,
    ICollectionCellOption,
    ICollectionCellParams,
} from '@datagalaxy/core-ui/cell-components';
import { DxyIconCellComponent } from '@datagalaxy/core-ui/omnigrid';
import { IAttributeCollectionCellParamsBuilder } from './attribute-collection-cell.types';
import {
    AttributeMetaValue,
    AttributeObjectValue,
} from '@datagalaxy/webclient/attribute/domain';

export class IconAttributeCollectionCellParamsBuilder
    implements IAttributeCollectionCellParamsBuilder
{
    constructor(
        private values: (AttributeObjectValue | AttributeMetaValue)[]
    ) {}

    public buildParams(): ICollectionCellParams {
        return {
            inputs: {
                ...baseIconCollectionCellInputs,
                options: this.buildOptions(),
            },
            isMini: true,
        };
    }

    private buildOptions(): ICollectionCellOption[] {
        return this.values?.map((v) => this.buildOption(v));
    }

    private buildOption(
        value: AttributeObjectValue | AttributeMetaValue
    ): ICollectionCellOption {
        const { glyphClass, imgUrl, labelText } =
            value instanceof AttributeMetaValue
                ? this.buildOptionFromMeta(value)
                : this.buildOptionFromObject(value);
        return {
            renderData: {
                renderer: DxyIconCellComponent,
                param: { value: { glyphClass, imgUrl } },
            },
            labelText,
        };
    }

    private buildOptionFromObject(value: AttributeObjectValue) {
        return {
            glyphClass: null,
            imgUrl: null,
            labelText: value.DisplayName,
        };
    }

    private buildOptionFromMeta(value: AttributeMetaValue) {
        return {
            glyphClass: value.glyphClass,
            imgUrl: value.iconUrl,
            labelText: value.translatedDisplayName,
        };
    }
}

import {
    Component,
    HostListener,
    Inject,
    OnInit,
    ViewChild,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DxyBaseModalComponent } from '@datagalaxy/ui/dialog';
import {
    IScriptMessage,
    IGenerationResultModalInput,
    IScriptResult,
} from './script-generation-result.types';
import { ModelerService } from '../../services/modeler.service';
import { KeyboardUtil } from '@datagalaxy/utils';
import { DxyFieldTextComponent } from '@datagalaxy/ui/fields';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'dxy-script-generation-result-modal',
    templateUrl: './dxy-script-generation-result-modal.component.html',
    styleUrls: ['./dxy-script-generation-result-modal.component.scss'],
})
export class DxyScriptGenerationResultModalComponent
    extends DxyBaseModalComponent<IGenerationResultModalInput, void>
    implements OnInit
{
    public hasMessages: boolean;

    public get script() {
        return this.result0.script;
    }
    public set script(value: string) {
        this.result0.script = value;
    }
    public get messageTypes() {
        return Object.keys(this.result0.messageGroups);
    }

    @ViewChild('scriptRef') private fieldTextRef: DxyFieldTextComponent;

    private results: IScriptResult[];
    private get result0() {
        return this.results[0];
    }

    constructor(
        private modelerService: ModelerService,
        dialogRef: MatDialogRef<DxyScriptGenerationResultModalComponent, void>,
        @Inject(MAT_DIALOG_DATA) data: IGenerationResultModalInput
    ) {
        super(dialogRef, data);
    }

    ngOnInit() {
        const { results, hasMessages } =
            this.modelerService.translateDdlScriptResultItems(
                this.data.result.Items
            );
        this.results = results;
        this.hasMessages = hasMessages;
    }

    public getMessageGroups(type: string): IScriptMessage[][] {
        const groups = this.result0.messageGroups[type];
        return Object.keys(groups).map((k) => groups[k]);
    }

    public onClickScript() {
        this.fieldTextRef.inputRef.nativeElement.select();
    }

    @HostListener('keydown', ['$event'])
    private keyDownModal(event: KeyboardEvent) {
        if (KeyboardUtil.isEnterKey(event)) {
            this.onCloseSubmit();
        }
    }
}

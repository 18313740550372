import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { DxyBaseModalComponent } from '@datagalaxy/ui/dialog';
import { IOptionAdapter } from '@datagalaxy/core-ui';
import { UiOptionSelectDataType } from '@datagalaxy/core-ui';
import * as cronParser from 'cron-parser';
import {
    CronFields,
    DayOfTheMonthRange,
    DayOfTheWeekRange,
    HourRange,
    MonthRange,
    SixtyRange,
} from 'cron-parser';
import * as moment from 'moment';
import timezones, { Timezone } from 'timezones.json';
import { ConnectorService } from '../connector.service';
import {
    ConnectorSchedulerFrequencyType,
    IConnectorSchedulerModalData,
} from './dxy-connector-scheduler-modal.types';
import { IMultiSelectData } from '@datagalaxy/core-ui';
import {
    CrudOperation,
    FunctionalLogService,
} from '@datagalaxy/webclient/monitoring/data-access';

const buildNumberArray = (length: number) => Array.from(Array(length).keys());

const defaultCron = '0 0 * * *';
const LOCAL_TIMEZONE = Intl.DateTimeFormat().resolvedOptions().timeZone;
const TIME_FORMAT = 'LT';

// for better timezone understanding go to: https://github.com/dmfilipenko/timezones.json/blob/master/timezones.json
@Component({
    selector: 'dxy-connector-scheduler-modal',
    templateUrl: 'dxy-connector-scheduler-modal.component.html',
    styleUrls: ['./dxy-connector-scheduler-modal.component.scss'],
})
export class DxyConnectorSchedulerModalComponent
    extends DxyBaseModalComponent<IConnectorSchedulerModalData, void>
    implements OnInit
{
    //#region html bindings
    public frequencyType: ConnectorSchedulerFrequencyType;
    public schedulerFrequencyType = ConnectorSchedulerFrequencyType;
    public isLoading = false;
    public enabled = true;
    public frequencyDailyMultiSelectData: IMultiSelectData<string>;
    public weekdayOptions: string[];
    public weekdayValue: string;
    public frequencyMonthlyOptions: number[];
    public frequencyMonthlyAdapter: IOptionAdapter<number> = {
        getText: (frequency: number) =>
            this.translate.instant(`UI.Connector.Scheduler.frequencyMonths`, {
                count: frequency,
            }),
    };
    public frequencyMonthlyValue: number;
    public monthDayOptions: number[];
    public monthDayValue: number;
    public timezoneSearch: string;
    public timezoneOptions: Timezone[] = timezones;
    public timezoneAdapter: IOptionAdapter<Timezone> = {
        getText: (timezone) => timezone.text,
    };
    public timezoneValue: Timezone;
    public timeInputValue: string;
    public actionBtnLbl: string;
    public enabledLabel: string = this.translate.instant(
        'UI.Connector.Scheduler.enabled'
    );
    public get formIsValid() {
        return this.isValidForm();
    }
    public get dailyValue() {
        return this.frequencyType == ConnectorSchedulerFrequencyType.Daily;
    }
    public get weeklyValue() {
        return this.frequencyType == ConnectorSchedulerFrequencyType.Weekly;
    }
    public get monthlyValue() {
        return this.frequencyType == ConnectorSchedulerFrequencyType.Monthly;
    }
    //#endregion

    private cronExpression = cronParser.parseExpression(defaultCron, {
        tz: LOCAL_TIMEZONE,
    });

    constructor(
        @Inject(MAT_DIALOG_DATA) data: IConnectorSchedulerModalData,
        dialogRef: MatDialogRef<DxyConnectorSchedulerModalComponent>,
        private translate: TranslateService,
        private connectorService: ConnectorService,
        private functionalLogService: FunctionalLogService
    ) {
        super(dialogRef, data);
    }

    ngOnInit() {
        this.init().then();
    }

    public getTimezomeTooltip() {
        return this.translate.instant(
            'UI.Connector.Scheduler.timezoneTooltip',
            { timezone: this.timezoneValue?.text }
        );
    }

    public getTimePlaceholder() {
        return moment('00', 'Hm').format(TIME_FORMAT);
    }

    public onChangeFrequency(
        selectedFrequency: ConnectorSchedulerFrequencyType
    ) {
        const defaultCronExpression = cronParser.parseExpression(defaultCron);
        this.frequencyType = selectedFrequency;

        const fields = { ...this.cronExpression.fields };
        let dayOfMonth = defaultCronExpression.fields.dayOfMonth as number[];
        let month = defaultCronExpression.fields.month as number[];
        let dayOfWeek = defaultCronExpression.fields.dayOfWeek;
        if (this.frequencyType === ConnectorSchedulerFrequencyType.Monthly) {
            dayOfMonth = [this.monthDayValue];
            month = buildNumberArray(12)
                .filter((v) => v % this.frequencyMonthlyValue === 0)
                .map((v) => v + 1);
        } else if (
            this.frequencyType === ConnectorSchedulerFrequencyType.Weekly
        ) {
            dayOfWeek = [
                moment().day(this.weekdayValue).day(),
            ] as DayOfTheWeekRange[];
        } else {
            dayOfWeek = [
                ...this.frequencyDailyMultiSelectData.selectedItems.map(
                    (item) =>
                        moment()
                            .day(item as string)
                            .day()
                ),
            ] as DayOfTheWeekRange[];
        }

        this.cronExpression = cronParser.fieldsToExpression(
            {
                second: [0],
                hour: fields.hour,
                minute: fields.minute,
                dayOfMonth: dayOfMonth as DayOfTheMonthRange[],
                month: month as MonthRange[],
                dayOfWeek,
            },
            { tz: this.timezoneValue.utc[0] }
        );
    }

    public onSelectWeekday(selectedValue: string) {
        this.weekdayValue = this.weekdayOptions.find(
            (option) => option === selectedValue
        );
        this.cronExpressionSet('dayOfWeek', [
            moment().day(this.weekdayValue).day(),
        ] as DayOfTheWeekRange[]);
    }

    public onSelectFrequencyMonthly(selectedValue: number) {
        this.frequencyMonthlyValue = selectedValue;
        this.cronExpressionSet(
            'month',
            buildNumberArray(12)
                .filter((v) => v % this.frequencyMonthlyValue === 0)
                .map((v) => v + 1) as MonthRange[]
        );
    }

    public onSelectMonthDay(selectedValue: number) {
        this.monthDayValue = this.monthDayOptions.find(
            (option) => option === selectedValue
        );
        this.cronExpressionSet('dayOfMonth', [
            this.monthDayValue,
        ] as DayOfTheMonthRange[]);
    }

    public onChangeTime() {
        const momentTime = moment(this.timeInputValue, TIME_FORMAT);
        const hour = momentTime.hour();
        const minute = momentTime.minute();
        this.cronExpressionSet('hour', [
            Number.isNaN(hour ?? NaN) ? 0 : hour,
        ] as HourRange[]);
        this.cronExpressionSet('minute', [
            Number.isNaN(minute ?? NaN) ? 0 : minute,
        ] as SixtyRange[]);
    }

    public onSelectTimezone(selectedTimezone: Timezone) {
        this.timezoneValue = selectedTimezone;
    }

    public onChangeEnabled() {
        this.enabled = !this.enabled;
    }

    public async submit() {
        const scheduling = this.inputValuesToScheduling();
        const featureCode = `SCHEDULE_CONNECTION_${this.data.pluginName.toUpperCase()}`;
        this.functionalLogService.logFunctionalAction(
            featureCode,
            CrudOperation.U
        );
        this.isLoading = true;
        try {
            if (!this.data.connection.hasScheduling) {
                await this.connectorService.createScheduling(
                    this.data.connection.id,
                    scheduling.cron,
                    scheduling.suspend,
                    this.timezoneValue.utc[0]
                );
            } else {
                await this.connectorService.updateScheduling(
                    this.data.connection.id,
                    scheduling.cron,
                    scheduling.suspend,
                    this.timezoneValue.utc[0]
                );
            }
            this.connectorService.savedConnectionsRefresh();
            this.onCloseSubmit();
        } finally {
            this.isLoading = false;
        }
    }

    private async init() {
        this.actionBtnLbl = this.translate.instant('UI.Global.btnSave');
        this.initInputs();
        if (this.data.connection.hasScheduling) {
            await this.loadScheduling();
        }
        this.initFrequencyType();
        this.initInputsValues();
    }

    private async loadScheduling() {
        this.isLoading = true;
        try {
            const { cron, suspend, timezone } =
                await this.connectorService.getScheduling(
                    this.data.connection.id
                );

            this.timezoneValue = this.utcToTimezone(timezone ?? 'Etc/GMT');
            this.cronExpression = cronParser.parseExpression(cron, {
                tz: timezone,
            });
            this.enabled = !suspend;
        } finally {
            this.isLoading = false;
        }
    }

    private cronExpressionSet<K extends keyof CronFields>(
        key: K,
        value: CronFields[K]
    ) {
        const fields = { ...this.cronExpression.fields };
        fields[key] = value;
        this.cronExpression = cronParser.fieldsToExpression(fields, {
            utc: true,
        });
    }

    private onFrequencyDailyChange = (selectedItems: string[]) => {
        if (!selectedItems?.length) {
            return;
        }
        this.cronExpressionSet('dayOfWeek', [
            ...selectedItems?.map((item) => moment().day(item).day()),
        ] as DayOfTheWeekRange[]);
    };

    private dayToLocaleDay = (day: number | string) =>
        moment().day(day).weekday();

    private initInputs() {
        const weekdays = moment.weekdays(true);
        this.frequencyDailyMultiSelectData = {
            hasSelectAll: true,
            dataType: UiOptionSelectDataType.other,
            onSelectionChange: (selectedItems) =>
                this.onFrequencyDailyChange(selectedItems),
            items: weekdays,
            selectedItems: weekdays.filter(
                (day) =>
                    this.cronExpression.fields.dayOfWeek.find(
                        (d) => d === moment().day(day).day()
                    ) !== undefined
            ),
        };
        this.weekdayOptions = weekdays;
        this.frequencyMonthlyOptions = [1, 2, 3, 4, 6];
        this.monthDayOptions = buildNumberArray(31).map((day) => day + 1);
    }

    private initInputsValues() {
        if (!this.timezoneValue) {
            this.timezoneValue = this.utcToTimezone(LOCAL_TIMEZONE);
        }
        this.timezoneSearch = this.timezoneValue.text;
        const weekdays = moment.weekdays(true);
        this.frequencyDailyMultiSelectData.selectedItems =
            this.cronExpression.fields.dayOfWeek.map(
                (day) => weekdays[this.dayToLocaleDay(day)]
            );
        this.timeInputValue = moment()
            .hour(this.cronExpression.fields.hour[0])
            .minute(this.cronExpression.fields.minute[0])
            .format(TIME_FORMAT);
        this.weekdayValue =
            weekdays[
                this.dayToLocaleDay(this.cronExpression.fields.dayOfWeek[0])
            ];
        this.frequencyMonthlyValue =
            12 / this.cronExpression.fields.month.length;
        this.monthDayValue = this.cronExpression.fields.dayOfMonth[0] as number;
    }

    private initFrequencyType() {
        let frequencyType = ConnectorSchedulerFrequencyType.Daily;
        if (this.cronExpression.fields.dayOfMonth.length === 1) {
            frequencyType = ConnectorSchedulerFrequencyType.Monthly;
        } else if (this.cronExpression.fields.dayOfWeek.length === 1) {
            frequencyType = ConnectorSchedulerFrequencyType.Weekly;
        }

        this.frequencyType = frequencyType;
    }

    private inputValuesToScheduling() {
        return {
            suspend: !this.enabled,
            cron: this.cronExpression.stringify(),
        };
    }

    private isValidForm() {
        if (!moment(this.timeInputValue, TIME_FORMAT, true).isValid()) {
            return false;
        }
        if (
            this.frequencyType === ConnectorSchedulerFrequencyType.Daily &&
            this.frequencyDailyMultiSelectData?.selectedItems?.length === 0
        ) {
            return false;
        }
        return true;
    }

    private utcToTimezone(utcTimezone?: string) {
        for (const timezone of timezones) {
            for (const utc of timezone.utc) {
                if (utc === utcTimezone) {
                    return timezone;
                }
            }
        }

        return timezones.find((timezone) => timezone.abbr === 'UTC');
    }
}

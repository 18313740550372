<div
    *ngIf="sections$ | async as sections"
    class="sections"
    cdkDropList
    [cdkDropListDisabled]="!enableEdition"
    (cdkDropListDropped)="drop($event)"
>
    <dxy-dashboard-section
        *ngFor="let section of sections; trackBy: sectionTrackBy"
        cdkDrag
        [cdkDragData]="section"
        cdkDragLockAxis="y"
        (cdkDragStarted)="dragStarted()"
        (cdkDragEnded)="dragEnded()"
        [section]="section"
        [enableEdition]="enableEdition"
        [dragging]="dragging"
        (openWidgetGallery)="widgetGallery?.openPanel()"
        [widgetGalleryOpened]="widgetGallery?.opened"
    >
        <dxy-widget-handle
            *ngIf="enableEdition"
            cdkDragHandle
            [options]="section.options"
            [disabled]="dragging"
        ></dxy-widget-handle>
    </dxy-dashboard-section>
</div>

<button
    *ngIf="addSectionButtonVisible$ | async"
    class="add-section-btn"
    (click)="addSection()"
>
    <span translate>Dashboard.Section.add</span>
    <i class="glyph-add"></i>
</button>

<dxy-widgets-gallery
    *ngIf="enableEdition"
    [title]="'Dashboard.WidgetsGallery.title' | translate"
    [description]="'Dashboard.WidgetsGallery.description' | translate"
    [widgets]="availableWidgets"
    [opened]="true"
    [dragging]="dragging"
></dxy-widgets-gallery>

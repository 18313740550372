import { LineageTreeNode } from '../lineage-tree/lineage-tree-node';
import { CollectionsHelper } from '@datagalaxy/core-util';
import { EntityType } from '@datagalaxy/dg-object-model';

/**
 * This list represent the entity types that should not be displayed as
 * standalone entities in the lineage view. They should only be displayed
 * below their parent entity.
 */
export const forbiddenStandaloneEntityTypes = [
    EntityType.Column,
    EntityType.Document,
    EntityType.Field,
    EntityType.File,
    EntityType.SubStructure,
    EntityType.TagBase,
    EntityType.DataProcessingItem,
    EntityType.UsageField,
    EntityType.UsageComponent,
];

export interface GraphVisibilityHandlerOptions {
    /**
     * The maximum number of children that a node can have before it is hidden
     * Beyond this limit, the node will be hidden and only the first `childrenLimit`
     * will remain visible.
     * Default value is 10.
     */
    childrenLimit: number;
}

export class LineageGraphVisibilityHandler {
    constructor(
        private rootId: string,
        private options?: GraphVisibilityHandlerOptions
    ) {}

    public update(nodes: LineageTreeNode[]) {
        const rootNodes = CollectionsHelper.distinctByProperty(
            nodes.map((treeNode) => treeNode.getRoot()),
            (node) => node.id
        );

        rootNodes.forEach((node) => this.updateNodeVisibility(node));
    }

    private updateNodeVisibility(node: LineageTreeNode) {
        node.children?.forEach((child) => this.updateNodeVisibility(child));

        if (this.hasForbiddenStandaloneEntityChild(node)) {
            if (
                !node.visible &&
                node.children.some((child) => child.id === this.rootId)
            ) {
                node.open();
            }

            node.setVisible();
        }

        if (
            !node.links.length &&
            !node.getAllVisibleChildren()?.length &&
            node.id !== this.rootId
        ) {
            node.setHidden();
        }

        const childrenLimit = this.options?.childrenLimit || 10;

        if (node.visibleChildren.length >= childrenLimit) {
            node.setVisible();

            node.visibleChildren
                ?.filter((child) => child.id !== this.rootId)
                ?.slice(childrenLimit)
                ?.forEach((child) => child.setHidden());
        }
    }

    private hasForbiddenStandaloneEntityChild(node: LineageTreeNode): boolean {
        return node.children.some((child) =>
            forbiddenStandaloneEntityTypes.includes(
                child.entityIdentifier.entityType
            )
        );
    }
}

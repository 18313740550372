import {
    ChangeDetectionStrategy,
    Component,
    HostBinding,
    Input,
} from '@angular/core';
import { BaseCellComponent } from '@datagalaxy/core-ui/cell-components';
import { ITranslatedAttributeUpdate } from './attributes-updates.types';
import {
    AttributeUpdatesTranslation,
    IAttributeTranslationValue,
    IAttributeUpdateTranslationInputs,
} from '@datagalaxy/translation-common';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../../../services/user.service';

/**
 * ## Role
 * Button to display a list of attributes updates
 */
@Component({
    selector: 'app-attributes-updates',
    templateUrl: './attributes-updates.component.html',
    styleUrls: ['./attributes-updates.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttributesUpdatesComponent extends BaseCellComponent<
    unknown,
    AttributeUpdatesTranslation
> {
    constructor(
        private translate: TranslateService,
        private userService: UserService
    ) {
        super();
    }

    @Input() updatesTranslation: AttributeUpdatesTranslation;

    protected get translatedUpdates(): ITranslatedAttributeUpdate[] {
        return this.updatesTranslation.updates?.map((u) =>
            this.getTranslatedAttributeUpdate(u)
        );
    }
    @HostBinding('class.opened')
    protected opened = false;
    protected get visible() {
        return !!this.translatedUpdates?.length;
    }

    public toggleList(e: MouseEvent) {
        e.stopPropagation();
        this.opened = !this.opened;
    }

    protected override updateBindings() {
        this.updatesTranslation = this.value;
    }

    private getTranslatedAttributeUpdate(
        u: IAttributeUpdateTranslationInputs
    ): ITranslatedAttributeUpdate {
        return {
            attributeName: this.translate.instant(u.attributeNameKey),
            previousValue: this.getTranslatedValue(u.previousValue),
            value: this.getTranslatedValue(u.value),
        };
    }
    private getTranslatedValue(value: IAttributeTranslationValue): string {
        return (
            value.text ??
            value.richText ??
            (value.translateKey?.length
                ? this.translate.instant(value.translateKey)
                : value.userGuid
                ? this.userService.getUserName(value.userGuid)
                : '')
        );
    }
}

import { CollectionsHelper } from '@datagalaxy/core-util';
import { BaseService } from '@datagalaxy/core-ui';
import { Injectable } from '@angular/core';
import { EntityService } from './entity.service';
import { ServerType } from '@datagalaxy/dg-object-model';
import { ILoadMultiResult } from '@datagalaxy/webclient/entity/data-access';
import { EntityUtils } from '@datagalaxy/webclient/entity/utils';
import { ISpaceIdentifier } from '@datagalaxy/webclient/workspace/domain';
import { Filter } from '@datagalaxy/webclient/filter/domain';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';
import { ServerConstants } from '@datagalaxy/shared/server/domain';

@Injectable({ providedIn: 'root' })
export class EntityCacheService
    extends BaseService
    implements ICurrentEntityData
{
    private currentEntityData: EntityItem;
    private itemsByClient: Map<TClient, EntityCacheItem[]>;

    constructor(private entityApiService: EntityService) {
        super();
        this.itemsByClient = new Map<TClient, EntityCacheItem[]>();
    }

    //#region ICurrentEntityData

    public getCurrentEntityData() {
        return this.currentEntityData;
    }
    public setCurrentEntityData(entityData: EntityItem) {
        this.currentEntityData = entityData;
    }

    // #endregion

    public registerClient(client: TClient) {
        this.itemsByClient.set(client, []);
    }
    public unregister(client: TClient) {
        this.itemsByClient.delete(client);
    }
    public clear(client: TClient) {
        this.itemsByClient.set(client, []);
    }

    public addEntityToCache(
        client: TClient,
        entityItem: EntityItem,
        isFlatCache = false
    ) {
        const cachedItem = this.getCachedItemIfExist(
            client,
            entityItem.LogicalParentId,
            isFlatCache
        );
        // If the parent is already cached : update parent's cacheItem
        if (cachedItem) {
            const childrenResult = cachedItem.childrenResult;
            childrenResult.TotalCount++;
            childrenResult.Size++;
            // add the entity to the parent's result and sort
            childrenResult.Entities.push(entityItem);
            childrenResult.Entities = CollectionsHelper.alphaSort(
                childrenResult.Entities,
                'DisplayName'
            );
        }

        // Update all ancestors and cacheItems ancestors besides parent's cacheItem
        entityItem.HddData.Parents.slice(1).forEach((ancestor, index) => {
            // If it is the last parent, get base cacheItem
            const ancestorCacheItemId =
                index == entityItem.HddData.Parents.length - 2
                    ? null
                    : ancestor.DataReferenceId;
            const ancestorCacheItem = this.getCachedItemIfExist(
                client,
                ancestorCacheItemId
            );
            if (ancestorCacheItem) {
                this.updateCachedItemOnAdd(
                    ancestorCacheItem,
                    entityItem,
                    index
                );
            }
        });
    }

    public updateCachedEntity(
        client: TClient,
        updatedEntity: EntityItem,
        isFlat: boolean
    ) {
        const cachedItem = this.getCachedItemIfExist(
            client,
            isFlat ? null : updatedEntity.entityParentId,
            isFlat
        );
        // If the parent is cached : update parent's cacheItem data
        if (!cachedItem) {
            return;
        }

        const childrenResult = cachedItem.childrenResult;
        // Replace oldEntity by the new one
        CollectionsHelper.replace(childrenResult.Entities, (cachedEntity) => {
            if (cachedEntity.ReferenceId == updatedEntity.ReferenceId) {
                EntityUtils.mergeEntity(cachedEntity, updatedEntity);
            }
            return cachedEntity;
        });

        // Sort entities
        childrenResult.Entities = CollectionsHelper.alphaSort(
            childrenResult.Entities,
            'DisplayName'
        );

        // return the merged entity
        return childrenResult.Entities.find(
            (entity) => entity.ReferenceId == updatedEntity.ReferenceId
        );
    }

    public updateCachedEntitiesTechnology(
        client: TClient,
        parentId: string,
        childId: string,
        isFlat: boolean,
        newTechnologyCode: string
    ) {
        const updatedEntities = [];
        const cachedItem = this.getCachedItemIfExist(
            client,
            isFlat ? null : parentId,
            isFlat
        );
        if (!cachedItem) {
            return updatedEntities;
        }
        cachedItem.childrenResult.Entities.forEach((entityItem) => {
            if (!childId || entityItem.ReferenceId == childId) {
                entityItem.HddData.TechnologyCode = newTechnologyCode;
                updatedEntities.push(entityItem);
                const updatedChildren = this.updateCachedEntitiesTechnology(
                    client,
                    entityItem.ReferenceId,
                    '',
                    isFlat,
                    newTechnologyCode
                );
                updatedEntities.push(...updatedChildren);
            }
        });
        return updatedEntities;
    }

    public updateCachedEntityParent(
        client: TClient,
        updatedEntity: EntityItem,
        isFlatCache = false
    ) {
        this.log('updateCachedEntityParent', updatedEntity);
        //updatedEntity.ContextualChildrenCount = updatedEntity.getAttributeValue(ServerConstants.PropertyName.LogicalChildrenCount);
        updatedEntity.ContextualAllLevelChildrenCount =
            updatedEntity.getAttributeValue<number>(
                ServerConstants.PropertyName.LogicalAllLevelChildrenCount
            );
        // 1 - delete entity from old parent hierarchy, update it and update all ancestors count
        this.deleteEntitiesFromCache(
            client,
            [updatedEntity.ReferenceId],
            isFlatCache,
            false
        );
        // 2 - add entity to new parent's hierarchy if exist and update all ancestors count (depending on children number)
        this.addEntityToCache(client, updatedEntity, isFlatCache);
        // 3 - update children's HddData
        const cacheItem = this.getCachedItemIfExist(
            client,
            updatedEntity.ReferenceId,
            isFlatCache
        );
        if (!cacheItem) {
            return;
        }
        cacheItem.childrenResult.Entities.forEach((child) => {
            // Update child HddData by removing ancestors and pushing new ones
            const childAncestors = child.HddData.Parents;
            childAncestors.splice(1);
            childAncestors.push(...updatedEntity.HddData.Parents);
            child.HddData.Parents = childAncestors;
        });
    }

    public deleteEntitiesFromCache(
        client: TClient,
        deletedEntityIds: string[],
        isFlatCache = false,
        cleanHierarchy = true
    ) {
        const cacheItems = isFlatCache
            ? [this.getCachedItemIfExist(client, null)]
            : this.itemsByClient.get(client);
        let deletedEntities: EntityItem[] = [];
        // Delete cached cacheItems for deleted nodes
        if (cleanHierarchy) {
            CollectionsHelper.remove(cacheItems, (cacheItem) =>
                deletedEntityIds.some(
                    (deletedEntityId) => deletedEntityId == cacheItem.parentId
                )
            );
        }

        // Delete entity on cached parent's cacheItem
        cacheItems.forEach((cacheItem) => {
            CollectionsHelper.remove(
                cacheItem.childrenResult.Entities,
                (entity) =>
                    deletedEntityIds.some(
                        (deletedEntityId) =>
                            deletedEntityId == entity.ReferenceId
                    )
            ).forEach((deletedEntity) => {
                cacheItem.childrenResult.Size--;
                cacheItem.childrenResult.TotalCount--;
                deletedEntities.push(deletedEntity);
            });
        });

        // Update ancestors children count
        if (deletedEntities && deletedEntities.length) {
            deletedEntities.forEach((deletedEntity) => {
                deletedEntity.HddData.Parents.slice(1).forEach(
                    (ancestor, index) => {
                        // If it is the last parent, get base cacheItem
                        const ancestorCacheItemId =
                            index == deletedEntity.HddData.Parents.length - 2
                                ? null
                                : ancestor.DataReferenceId;
                        const ancestorCacheItem = cacheItems.find(
                            (value) => value.parentId == ancestorCacheItemId
                        );

                        if (!ancestorCacheItem) {
                            return;
                        }

                        const numberOfChildrenDeleted =
                            deletedEntity.ContextualAllLevelChildrenCount + 1;
                        ancestorCacheItem.childrenResult.TotalCount -=
                            numberOfChildrenDeleted;

                        const cachedAncestor =
                            ancestorCacheItem.childrenResult.Entities.find(
                                (cachedEntity) =>
                                    cachedEntity.DataReferenceId ==
                                    deletedEntity.HddData.Parents[index]
                                        .DataReferenceId
                            );
                        if (!cachedAncestor) {
                            return;
                        }

                        cachedAncestor.ContextualAllLevelChildrenCount -=
                            numberOfChildrenDeleted;
                        cachedAncestor.setAttributeValue(
                            ServerConstants.PropertyName
                                .LogicalAllLevelChildrenCount,
                            (
                                cachedAncestor.getAttributeValue<number>(
                                    ServerConstants.PropertyName
                                        .LogicalAllLevelChildrenCount
                                ) - numberOfChildrenDeleted
                            ).toString()
                        );

                        // If the cachedAncestor is direct parent of the deleted, also update children count
                        if (
                            deletedEntity.entityParentId ==
                            cachedAncestor.DataReferenceId
                        ) {
                            cachedAncestor.setAttributeValue(
                                ServerConstants.PropertyName
                                    .LogicalChildrenCount,
                                (
                                    cachedAncestor.getAttributeValue<number>(
                                        ServerConstants.PropertyName
                                            .LogicalChildrenCount
                                    ) - 1
                                ).toString()
                            );
                        }
                    }
                );
            });
        }
    }

    public getTotalCount(client: TClient, isFlatCache = false) {
        const baseCacheItem = this.getCachedItemIfExist(client, undefined);
        if (!baseCacheItem) {
            return null;
        }
        if (isFlatCache) {
            return baseCacheItem.childrenResult.TotalCount;
        }
        let total = 0;
        total += baseCacheItem.childrenResult.Size;
        total += baseCacheItem.childrenResult.Entities.reduce(
            (p, c) => p + c.ContextualAllLevelChildrenCount,
            0
        );
        return total;
    }

    public async getEntitiesForHierarchical(
        client: object,
        spaceIdr: ISpaceIdentifier,
        serverType: ServerType | ServerType[],
        filters: Filter[],
        neededAttributes: string[],
        rootEntityReferenceId: string,
        maxSize: number,
        sortKey?: string
    ): Promise<ILoadMultiResult<EntityItem>> {
        /* If we find data for that parent's Id, no need to request server*/
        const cachedItem = this.getCachedItemIfExist(
            client,
            rootEntityReferenceId
        );
        if (cachedItem) {
            this.log(
                'getEntitiesForHierarchical',
                'cache-hit',
                rootEntityReferenceId,
                cachedItem
            );
            return cachedItem.childrenResult;
        }

        /* If no data is cached for this parentId, request server and store result*/
        const result = await this.entityApiService.getEntitiesForHierarchical(
            spaceIdr,
            serverType,
            filters,
            neededAttributes,
            rootEntityReferenceId,
            maxSize,
            sortKey
        );
        const newCachedItem = this.cacheNewItem(
            result,
            client,
            false,
            rootEntityReferenceId
        );
        this.log(
            'getEntitiesForHierarchical',
            'cache-miss',
            rootEntityReferenceId,
            newCachedItem
        );
        return newCachedItem;
    }

    public async getEntitiesForFlat(
        client: object,
        spaceIdr: ISpaceIdentifier,
        serverType: ServerType | ServerType[],
        startIndex: number,
        size: number,
        withCounts: boolean,
        neededAttributes: string[],
        filters: Filter[],
        sortKey?: string
    ) {
        const result = await this.entityApiService.getEntitiesForFlat(
            spaceIdr,
            serverType,
            startIndex,
            size,
            withCounts,
            neededAttributes,
            filters,
            sortKey
        );
        return this.cacheNewItem(result, client, true, null);
    }

    private updateCachedItemOnAdd(
        ancestorCacheItem: EntityCacheItem,
        addedEntity: EntityItem,
        index: number
    ) {
        ancestorCacheItem.childrenResult.TotalCount++;
        // update parent's entity data
        const cachedAncestor = ancestorCacheItem.childrenResult.Entities.find(
            (ancestorChildren) =>
                ancestorChildren.ReferenceId ==
                addedEntity.HddData.Parents[index].DataReferenceId
        );

        if (!cachedAncestor) {
            return;
        }

        cachedAncestor.ContextualAllLevelChildrenCount++;
        cachedAncestor.setAttributeValue(
            ServerConstants.PropertyName.LogicalAllLevelChildrenCount,
            (cachedAncestor.getAttributeValue(
                ServerConstants.PropertyName.LogicalAllLevelChildrenCount
            ) as number) + 1
        );

        // If cached entity is the direct parent of the added entity, update it
        if (addedEntity.entityParentId == cachedAncestor.DataReferenceId) {
            cachedAncestor.setAttributeValue(
                ServerConstants.PropertyName.LogicalChildrenCount,
                (cachedAncestor.getAttributeValue(
                    ServerConstants.PropertyName.LogicalChildrenCount
                ) as number) + 1
            );
        }
    }

    private getCachedItemIfExist(
        client: TClient,
        parentId: string,
        isFlatCache = false
    ) {
        const cacheItems = this.itemsByClient.get(client);
        if (!cacheItems) {
            return null;
        }

        // If we are looking for a flat cache hierarchy, use null as parentId
        parentId = isFlatCache ? null : parentId;
        return cacheItems.find((value) => value.parentId == parentId);
    }

    private cacheNewItem(
        result: ILoadMultiResult<EntityItem>,
        client: TClient,
        isFlatCache: boolean,
        parentId: string
    ) {
        const cacheItems = this.itemsByClient.get(client);

        // If Item Already Exists, we are loading paged entries
        if (isFlatCache) {
            const item = cacheItems.length && cacheItems[0];
            if (item) {
                item.appendResultEntities(result);
                return result;
            }
        }

        cacheItems.push(new EntityCacheItem(parentId, result));
        return result;
    }
}

type TClient = object;

class EntityCacheItem {
    public childrenResult: ILoadMultiResult<EntityItem>;
    public parentId: string;

    public constructor(
        parentId: string,
        childrenResult?: ILoadMultiResult<EntityItem>
    ) {
        this.parentId = parentId;
        this.childrenResult = childrenResult;
    }

    appendResultEntities(result: ILoadMultiResult<EntityItem>) {
        result?.Entities?.length &&
            this.childrenResult?.Entities.push(...result.Entities);
    }
}

// TODO: move this feature to a new application state service, and maintain current entity by zone (dashboard, docking pane)
export interface ICurrentEntityData {
    getCurrentEntityData: () => EntityItem;
    setCurrentEntityData: (entityData: EntityItem) => void;
}

import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    Inject,
} from '@angular/core';
import { executeOnce, withLoading } from '@datagalaxy/core-ui';
import {
    MultilingualApiService,
    MultilingualAttributeSetting,
} from '@datagalaxy/webclient/multilingual/data-access';
import { BaseComponent } from '@datagalaxy/utils';
import { MultilingualStateService } from '../../multilingual-state.service';
import { BehaviorSubject, combineLatest, map, mergeMap } from 'rxjs';
import { DIALOG_SERVICE_TOKEN, IDialogService } from '@datagalaxy/ui/dialog';
import {
    ATTRIBUTES_FINDER_TOKEN,
    AttributesFinder,
} from '@datagalaxy/webclient/attribute/feature';
import { AddAttributesModalComponent } from '../add-attributes-modal/add-attributes-modal.component';

@Component({
    selector: 'dxy-multilingual-attributes',
    templateUrl: './multilingual-attributes.component.html',
    styleUrls: ['./multilingual-attributes.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultilingualAttributesComponent
    extends BaseComponent
    implements AfterViewInit
{
    protected searchTerm$ = new BehaviorSubject<string>('');

    protected attributeSettings$ = combineLatest([
        this.multilingualStateService
            .selectAttributeSettings()
            .pipe(mergeMap((settings) => this.augmentSettings(settings))),
        this.searchTerm$,
    ]).pipe(
        map(([augmentedSettings, searchTerm]) => {
            return augmentedSettings.filter((s) => {
                const name =
                    s.attribute?.translatedDisplayName ??
                    s.attribute?.DisplayName ??
                    '';
                return name.toLowerCase().includes(searchTerm?.toLowerCase());
            });
        })
    );

    protected hasPendingBatches$ = new BehaviorSubject(true);

    constructor(
        private multilingualApiService: MultilingualApiService,
        private multilingualStateService: MultilingualStateService,
        @Inject(ATTRIBUTES_FINDER_TOKEN)
        private attributesFinder: AttributesFinder,
        @Inject(DIALOG_SERVICE_TOKEN) private dialogService: IDialogService
    ) {
        super();
    }
    ngAfterViewInit() {
        this.loadAttributeSettings();
        this.loadPendingBatches();
    }

    protected onSearchStringChange(searchString: string | undefined) {
        this.searchTerm$.next(searchString ?? '');
    }

    protected async openAddAttributesModal() {
        await this.dialogService.open<AddAttributesModalComponent, void, void>({
            componentType: AddAttributesModalComponent,
        });
        await this.loadPendingBatches();
    }

    @executeOnce()
    protected async toggleAttribute(
        attributeSetting: MultilingualAttributeSetting
    ) {
        const updatedSetting: MultilingualAttributeSetting = {
            id: attributeSetting.id,
            attributePath: attributeSetting.attributePath,
            enabled: !attributeSetting.enabled,
        };
        this.multilingualStateService.updateAttributeSetting(updatedSetting);
        try {
            await this.multilingualApiService.udpdateAttributeSetting(
                updatedSetting
            );
        } catch (e) {
            // Revert the change
            this.multilingualStateService.updateAttributeSetting({
                ...updatedSetting,
                enabled: !updatedSetting.enabled,
            });
            setTimeout(() => {
                throw e;
            }, 0);
        }
    }

    @withLoading()
    private async loadAttributeSettings() {
        const attributeSettings =
            await this.multilingualApiService.getAttributeSettings();
        this.multilingualStateService.updateAttributeSettings(
            attributeSettings
        );
    }

    private async augmentSettings(settings: MultilingualAttributeSetting[]) {
        const attributes = await this.attributesFinder.searchAttributes((a) =>
            settings.some((s) => s.attributePath === a.AttributePath)
        );
        return settings
            .map((setting) => ({
                ...setting,
                attribute: attributes.find(
                    (a) => a.AttributePath === setting.attributePath
                ),
            }))
            .filter((s) => !!s.attribute);
    }

    private async loadPendingBatches() {
        const batches = await this.multilingualApiService.getPendingBatches();
        this.hasPendingBatches$.next(batches.length > 0);
    }
}

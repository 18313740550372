import { Component, Inject, OnInit } from '@angular/core';
import { DxyBaseModalComponent } from '@datagalaxy/ui/dialog';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { IFieldSelectAdapter } from '@datagalaxy/core-ui';
import { CollectionsHelper } from '@datagalaxy/core-util';
import { IUserProfileModalResolve } from '../models/IUserProfileModalResolve';
import { AppDataService } from '../../services/app-data.service';
import { SecurityService } from '../../services/security.service';
import { IUserProfileModalResult } from '../models/IUserProfileModalResult';
import { UserAdminData } from '@datagalaxy/webclient/user/data-access';
import { UserService } from '../../services/user.service';
import { LicenseDTO, LicenseLevel } from '@datagalaxy/webclient/license/domain';

@Component({
    selector: 'dxy-user-profile-edit-modal',
    templateUrl: './dxy-user-profile-edit-modal.component.html',
})
export class DxyUserProfileEditModalComponent
    extends DxyBaseModalComponent<
        IUserProfileModalResolve,
        IUserProfileModalResult
    >
    implements OnInit
{
    public availableDataReader: LicenseDTO[] = [];
    public availableDataStewards: LicenseDTO[] = [];
    public availableExplorer: LicenseDTO[] = [];
    public isAddExistingUser = false;
    public isTrialInvite: boolean;
    public email: string;
    public firstName: string = null;
    public lastName: string = null;
    public title: string = null;
    public service: string = null;
    public role: string = null;
    public lastNameErrorKey: string;
    public firstNameErrorKey: string;
    public emailErrorKey: string;
    public readonly licenseTypes: IFieldSelectAdapter<ILicenseType> = {
        isModel: true,
        getText: (o) => o.text,
    };

    public get isUpdateUser() {
        return this.currentUser && !this.isUserCreation;
    }
    public get canSubmitCreation() {
        return this.canCreate && !this.isAddExistingUser;
    }
    public get modalTitleKey() {
        return `AdminUI.User.userInfoModal.${
            this.isUpdateUser
                ? 'updateTitle'
                : this.isTrialInvite
                ? 'inviteTitle'
                : 'createTitle'
        }`;
    }
    public get actionBtnLblKey() {
        if (this.isTrialInvite) {
            return 'UI.Global.btnInvite';
        }
        return `UI.Dialog.NewItem.${
            this.isUpdateUser ? 'lblSave' : 'lblCreate'
        }`;
    }
    public get featureCode() {
        return this.currentUser ? 'USER_LICENSE,U' : 'USER,C';
    }
    public get availableDataReaderCount() {
        return this.getLicenseCountText(
            this.getTotalAvailableLicenses(this.availableDataReader)
        );
    }
    public get hasExplorerLicensesAvailable() {
        return (
            this.getTotalAvailableLicenses(this.availableExplorer) ||
            this.hasExplorers
        );
    }
    public get availableExplorerCount() {
        return this.getLicenseCountText(
            this.getTotalAvailableLicenses(this.availableExplorer)
        );
    }
    public get availableStewardsCount() {
        return this.getLicenseCountText(
            this.getTotalAvailableLicenses(this.availableDataStewards)
        );
    }

    private currentUser: UserAdminData;
    private isUserCreation: boolean;
    private hasExplorers: boolean;
    private get canCreate() {
        return !!(
            (this.isUpdateUser && !!this.licenseTypes.current) ||
            (this.email &&
                !this.emailErrorKey &&
                this.firstName &&
                this.lastName)
        );
    }

    constructor(
        dialogRef: MatDialogRef<DxyUserProfileEditModalComponent>,
        @Inject(MAT_DIALOG_DATA) data: IUserProfileModalResolve,
        private translate: TranslateService,
        private userService: UserService,
        private securityService: SecurityService,
        private appDataService: AppDataService
    ) {
        super(dialogRef, data);
    }

    ngOnInit() {
        this.currentUser = this.data.adminUserData;
        this.isUserCreation = this.data.isUserCreation;
        this.isTrialInvite = this.data.isTrialInvite;
        this.initLicenses().then();
    }

    public async onChangeEmail() {
        if (!this.email) {
            this.emailErrorKey = 'UI.Attribute.msgRequired';
            return;
        }
        this.emailErrorKey = this.securityService.checkEmailFormat(this.email);
        if (this.emailErrorKey) {
            return;
        }
        const result = await this.userService.getUsersForCreation(this.email);
        const userDTO = result?.[0];

        this.updateUserField(userDTO);
        if (this.isAddExistingUser) {
            this.emailErrorKey = 'AdminUI.User.userInfoModal.msgExistentUser';
            return;
        }

        if (this.isTrialInvite) {
            const expectedSuffix =
                this.appDataService.currentUserEmail.split('@')[1] || '';
            this.emailErrorKey = this.email.endsWith(expectedSuffix)
                ? ''
                : 'AdminUI.User.userInfoModal.unexpectedDomain';
            return;
        }

        this.emailErrorKey = '';
    }

    public onChangeFirstName() {
        const hasError = this.firstName === '' || !this.firstName;
        this.firstNameErrorKey = hasError ? 'UI.Attribute.msgRequired' : '';
    }

    public onChangeLastName() {
        const hasError = this.lastName === '' || !this.lastName;
        this.lastNameErrorKey = hasError ? 'UI.Attribute.msgRequired' : '';
    }

    public onCloseSubmit() {
        let currentLicenseId = this.licenseTypes.current?.id;
        if (this.isTrialInvite && !currentLicenseId) {
            currentLicenseId =
                this.availableDataStewards?.[0]?.LicenseId ??
                this.availableDataReader?.[0].LicenseId;
        }
        this.result = {
            email: this.email,
            firstName: this.firstName,
            lastName: this.lastName,
            title: this.title,
            licenseId: currentLicenseId,
            service: this.service,
            role: this.role,
        };
        super.onCloseSubmit();
    }

    private async initLicenses() {
        const result = await this.userService.getLicenses();
        const licenses = result.Licenses;

        const { LicenseLevel: level, LicenseId: id } = this.currentUser ?? {};
        const licenseTypes: ILicenseType[] = [];

        if (this.isUpdateUser && level) {
            licenseTypes.push({
                id,
                text: `${LicenseLevel[level]} ${this.translate.instant(
                    'AdminUI.User.userInfoModal.licenseAlreadySet'
                )}`,
            });
        }

        const licenseTypesByLevel = (licenseLevel: LicenseLevel) =>
            licenses.filter(
                (dto) =>
                    dto.LicenseLevel == licenseLevel &&
                    dto.IsLicensePool &&
                    !dto.IsExpired &&
                    dto.AvailableLicenses > 0
            );

        const stewards = (this.availableDataStewards = licenseTypesByLevel(
            LicenseLevel.Steward
        ));
        if (stewards.length && level != LicenseLevel.Steward) {
            licenseTypes.push({ id: stewards[0].LicenseId, text: 'Steward' });
        }

        const explorers = (this.availableExplorer = licenseTypesByLevel(
            LicenseLevel.Explorer
        ));
        if (explorers.length && level != LicenseLevel.Explorer) {
            licenseTypes.push({ id: explorers[0].LicenseId, text: 'Explorer' });
        }
        this.hasExplorers = licenses.some(
            (license) =>
                license.LicenseLevel == LicenseLevel.Explorer &&
                !license.IsLicensePool
        );

        const readers = (this.availableDataReader = licenseTypesByLevel(
            LicenseLevel.Reader
        ));
        if (readers.length && level != LicenseLevel.Reader) {
            licenseTypes.push({ id: readers[0].LicenseId, text: 'Reader' });
        }

        this.licenseTypes.options = licenseTypes;
    }

    private getLicenseCountText(count: number) {
        const countString = count.toString();
        return countString == 'Infinity' ? '∞' : countString;
    }

    private getTotalAvailableLicenses(licenses: LicenseDTO[]): number {
        return CollectionsHelper.sum(licenses, (license) =>
            license.IsInfinite
                ? Number.POSITIVE_INFINITY
                : license.AvailableLicenses
        );
    }

    private updateUserField(userDTO: UserAdminData) {
        if (userDTO) {
            this.isAddExistingUser = true;
            this.firstName = userDTO.FirstName;
            this.lastName = userDTO.LastName;
            this.title = userDTO.Title;
            this.service = userDTO.Service;
            this.role = userDTO.Role;
        } else if (this.isAddExistingUser) {
            this.isAddExistingUser = false;
            this.firstName =
                this.lastName =
                this.title =
                this.service =
                this.role =
                    null;
        }
    }
}

interface ILicenseType {
    id: number;
    text: string;
}

<dxy-field-select
    *ngIf="filteredViews$ | async as filteredViews"
    [options]="filteredViews"
    [adapter]="filteredViewAdapter"
    [(ngModel)]="value"
    (ngModelChange)="onModelChange($event)"
    (openClose)="onOpenClose($event)"
    [disabled]="disabled"
    [search]="true"
    [mandatory]="true"
    [untouchedNoSearchPlaceholderVisible]="true"
    panelClass="dxy-field-select larger"
    [labelKey]="labelKey"
>
    <dxy-filter-carousel
        *ngIf="hasActiveModuleFilters$ | async"
        displayMode="active-filters"
        headerBeforeSearch
    >
        <dxy-module-filter-button
            dxyFilterCarouselItem
            [removable]="true"
            [withDropdown]="true"
            [availableModules]="(availableModuleNames$ | async) ?? []"
            [selectedModules]="(activeModuleFilters$ | async) ?? []"
            (selectedModulesChange)="onSelectedModulesChange($event)"
            (removeClick)="removeModuleFilters()"
        ></dxy-module-filter-button>
    </dxy-filter-carousel>
    <dxy-filter-carousel
        *ngIf="hasQuickFilters$ | async"
        displayMode="quick-filters"
        headerAfterSearch
    >
        <dxy-module-filter-button
            *ngFor="let moduleName of availableModuleNames$ | async"
            dxyFilterCarouselItem
            [moduleName]="moduleName"
            (buttonClick)="addModuleFilter(moduleName)"
        ></dxy-module-filter-button>
    </dxy-filter-carousel>
</dxy-field-select>

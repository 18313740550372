<span
    *ngIf="isClientNameVisible"
    data-tracker-id="Breadcrumb-Clientspace-Landing"
    dxyEllipsisTooltip
    >{{ clientName }}</span
>
<div *ngIf="showSpaceSelectorSeparator" class="breadcrumb-separator"></div>
<dxy-space-version-selector
    logId="navigation-breadcrumb"
    [class.is-full-dropdown]="!showModuleSeparator"
    [isSmallCaretBtn]="true"
    [showSpaceDropdown]="showSpaceDropdown"
    [enableFavorite]="true"
    [showSelectedSpaceIcon]="true"
    [showVersionDropdown]="showVersionDropdown"
    [useCurrentSpace]="true"
    [isSpaceSelectable]="isSpaceSelectable"
    [isVersionSelectable]="isVersionSelectable"
    [showAllSpaceRedirection]="true"
    [openOnSpaceOrVersionClick]="!showModuleSeparator"
    [trackerIds]="spaceSelectorTrackerIds"
    dxyDataTestId="workspace-selector-button"
    (onSpaceVersionSelected)="onSpaceVersionSelected($event.spaceIdr)"
    (onSpaceNameClick)="onSpaceVersionClick()"
    (onVersionNameClick)="onSpaceVersionClick()"
></dxy-space-version-selector>
<div *ngIf="showModuleSeparator" class="breadcrumb-separator"></div>

<!-- Module selector -->
<dxy-option-list
    *ngIf="isModuleVisible"
    [class.is-full-dropdown]="!showFilteredViewSelectorSeparator"
    [isDropdownMenu]="true"
    [options]="moduleOptions$ | async"
    [dropdownMenuSelected]="selectedModuleOption"
    [dropdownMenuSelectedTextOnly]="true"
    [categories]="moduleCategories"
    selectedTextTrackerId="Breadcrumb-Module-Landing"
    selectedDropdownTrackerId="Breadcrumb-Module-DropDrown"
    (onDropdownMenuSelected)="onModuleChange($event)"
    [dropdownToggleOnGlyphOnly]="showFilteredViewSelectorSeparator"
    (onSelectedTextClick)="onSelectedModuleOptionClick()"
    logId="modules"
></dxy-option-list>

<div
    *ngIf="showFilteredViewSelectorSeparator"
    class="breadcrumb-separator"
></div>

<!-- FilteredView selector -->
<dxy-option-list
    *ngIf="isFilteredViewSelectorVisible"
    class="is-full-dropdown"
    [isDropdownMenu]="true"
    [options]="filteredViewOptions"
    [categories]="filteredViewCategories"
    [dropdownMenuSelected]="selectedFilteredViewOption"
    [dropdownMenuSelectedTextOnly]="true"
    selectedTextTrackerId="Breadcrumb-FilteredView-Landing"
    selectedDropdownTrackerId="Breadcrumb-FilteredView-DropDrown"
    (onDropdownMenuSelected)="onFilteredViewSelected($event)"
    logId="filteredViews"
></dxy-option-list>

<div *ngIf="showTitleSeparator" class="breadcrumb-separator"></div>
<span>{{ currentViewTitle }}</span>

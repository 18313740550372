<input
    #input
    type="text"
    [maxLength]="maxLength"
    [placeholder]="placeholder"
    [readonly]="readonly"
    [value]="value"
    (input)="onInput($event)"
    (blur)="blurInput.emit()"
/>

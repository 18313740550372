import {
    createTable,
    getCoreRowModel,
    getExpandedRowModel,
    getGroupedRowModel,
    getSortedRowModel,
    Header,
    Row,
    Table,
    TableOptionsResolved,
    TableState,
    Updater,
} from '@tanstack/table-core';
import { IColDef, TColDef } from '../grid-column/grid-column.types';
import { TanStackGridAdapter } from './grid.adapter';
import { BehaviorSubject } from 'rxjs';
import { GridConfig } from '../grid/grid.types';
import { GridColumnSizeUtils } from '../grid-column-size/grid-column-size.utils';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { PersistedGridState } from '../grid-persisted-state/grid-persisted-state.types';
import { ArrayUtils } from '../array.utils';

export class GridTable<TRow> {
    private defaultOptions: TableOptionsResolved<TRow> = {
        state: {
            columnPinning: {},
            rowSelection: {},
            columnSizing: {},
            pagination: {
                pageSize: Number.MAX_SAFE_INTEGER,
                pageIndex: 0,
            },
        },
        columns: [],
        data: [],
        getCoreRowModel: getCoreRowModel(),
        getGroupedRowModel: getGroupedRowModel(),
        getSortedRowModel: getSortedRowModel(),
        columnResizeMode: 'onChange',
        onStateChange: (updater: Updater<TableState>) => {
            this.table.setOptions((prev) => {
                const state =
                    typeof updater === 'function'
                        ? updater(this.table.getState())
                        : updater;

                return {
                    ...prev,
                    state: {
                        ...prev.state,
                        ...state,
                    },
                };
            });
            this.tableBehavior.next(this.table);
        },
        renderFallbackValue: null,
    };

    private tableBehavior = new BehaviorSubject<Table<TRow>>(
        createTable(this.defaultOptions)
    );

    public get table(): Table<TRow> {
        return this.tableBehavior.value;
    }

    public get table$() {
        return this.tableBehavior.asObservable();
    }

    public get selection(): Row<TRow>[] {
        return this.table.getSelectedRowModel().rows;
    }

    constructor() {}

    public init(
        columns: IColDef<TRow>[],
        items: TRow[],
        config?: GridConfig<TRow>
    ): void {
        const columnDefs = TanStackGridAdapter.convertColumns(columns);
        const initialState = TanStackGridAdapter.getColumnsInitialState(
            columns,
            config
        );

        this.table.setOptions((prev) => {
            this.table.initialState = {
                ...prev.initialState,
                ...initialState,
            } as TableState;

            return {
                ...prev,
                columns: columnDefs,
                data: items || [],
                state: {
                    ...prev.state,
                    ...initialState,
                    expanded: true,
                },
                getGroupedRowModel: config?.groupBy
                    ? getGroupedRowModel()
                    : undefined,
                getExpandedRowModel:
                    config?.treeConfig || config?.groupBy
                        ? getExpandedRowModel()
                        : undefined,
                getSubRows: (row: TRow) => config?.treeConfig?.getSubRows(row),
                getRowId: (row) => config?.getItemId(row) || '',
            };
        });
    }

    public isColumnVisible(colId: string) {
        return this.table.getColumn(colId)?.getIsVisible();
    }

    public updateItems(items: TRow[]) {
        this.table.setOptions((prev) => ({
            ...prev,
            data: items || [],
        }));

        this.table.resetRowSelection();
    }

    public updateColumns(columns: TColDef<TRow>[]) {
        this.table.setOptions((prev) => ({
            ...prev,
            columns: TanStackGridAdapter.convertColumns(columns),
        }));
    }

    public toggleColumnVisibility(colId: string, availableWidth: number) {
        const column = this.table?.getColumn(colId);

        if (!column || !this.table) {
            return;
        }

        const isVisible = column.getIsVisible();

        if (isVisible) {
            column.toggleVisibility();
            this.fitColumnsToWidth(availableWidth);
        } else {
            const remainingWidth = availableWidth - column.getSize();
            this.fitColumnsToWidth(remainingWidth);
            column.toggleVisibility();
        }
    }

    public reorderHeader(previousIndex: number, currentIndex: number) {
        const columns = this.table.getAllColumns();
        if (!columns) {
            return;
        }
        const columnIds = columns.map((col) => col.id);
        moveItemInArray(columnIds, previousIndex, currentIndex);
        this.table.setColumnOrder(columnIds);
    }

    public fitColumnsToWidth(availableWidth: number) {
        const columns = this.table.getVisibleLeafColumns();
        const columnSizing = columns.map((col) => ({
            id: col.id,
            width: col.columnDef.size ?? col.getSize(),
            minWidth: col.columnDef.minSize,
        }));

        this.table.setColumnSizing((prev) => ({
            ...prev,
            ...GridColumnSizeUtils.fitColumnSizingToWidth(
                columnSizing,
                availableWidth
            ),
        }));
    }

    public resetColumns() {
        this.table.resetColumnOrder();
        this.table.resetColumnSizing();
        this.table.resetColumnVisibility();
    }

    public restorePersistedState(state: PersistedGridState) {
        const columnOrder = state.columns.map((col) => col.id);
        const columnSizing = ArrayUtils.toRecord(state.columns, (col) => [
            col.id,
            col.width,
        ]);
        const columnVisibility = ArrayUtils.toRecord(state.columns, (col) => [
            col.id,
            !col.hidden,
        ]);

        this.table.setColumnOrder(columnOrder);
        this.table.setColumnSizing(columnSizing);
        this.table.setColumnVisibility(columnVisibility);
    }

    public getPersistedState(): PersistedGridState {
        const columOrder = this.table.getState().columnOrder;
        const columns = this.table
            .getAllColumns()
            .sort(ArrayUtils.sortByIndexOf(columOrder || [], (col) => col.id));

        return {
            columns: columns.map((col) => {
                const columnWidth = col.getSize();
                const columnVisible = col.getIsVisible();

                return {
                    id: col.id,
                    hidden: !columnVisible,
                    width: columnWidth,
                };
            }),
        };
    }

    public onResizeHeader(event: MouseEvent, header: Header<TRow, unknown>) {
        event.stopPropagation();
        const resizeHandler = header.getResizeHandler();

        resizeHandler(event);
    }

    public setSelection(rows: string[]) {
        this.table.setRowSelection({
            ...rows.reduce((acc, row) => ({ ...acc, [row]: true }), {}),
        });
    }
}

<div class="header">
    <i class="title-icon glyph-person"></i>
    <h1
        class="title"
        translate
        [translateParams]="{spaceName}"
        dxyEllipsisTooltip
    >
        UI.SpaceHome.Widgets.SpaceMembers.Title
    </h1>
    <div class="title-action">
        <button
            *ngIf="hasRights"
            [matTooltip]="
                'UI.SpaceHome.Widgets.SpaceMembers.Preview.GoToSpaceRoleSettingsTT'
                    | translate
            "
            mat-icon-button
            (click)="onGoToAdminRoles()"
        >
            <i class="glyph-settings"></i>
        </button>
        <button
            mat-icon-button
            (click)="onCloseClick()"
            class="entity-pane-close-btn"
        >
            <i class="glyph-cancelsearch"></i>
        </button>
    </div>
</div>
<div class="body">
    <dxy-tabs-header
        *ngIf="tabItems"
        [tabs]="tabItems"
        [activeTabItem]="selectedTab"
        (tabChange)="onTabChange($event)"
    >
        <dxy-search-input
            *ngIf="showSearchInput"
            class="no-border-top"
            (onSearchString)="onSearchTerm($event.searchString)"
        ></dxy-search-input>
        <dxy-grid
            [config]="gridConfig"
            [columns]="cols"
            [items]="rows"
        ></dxy-grid>
    </dxy-tabs-header>
</div>

import * as moment from 'moment';
import { OmniGridUtil, TRowNode } from '@datagalaxy/core-ui/omnigrid';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';
import {
    AttributeMetaInfo,
    AttributeObjectValue,
} from '@datagalaxy/webclient/attribute/domain';

export class EntityGridUtil {
    public static makeTextComparator(
        getValueAsString: (value: any, node: EntityRowNode) => string
    ) {
        return OmniGridUtil.makeTextComparator<EntityItem>(getValueAsString);
    }
    public static makeMomentComparator(
        getValueAsMoment: (value: any, node: EntityRowNode) => moment.Moment
    ) {
        return OmniGridUtil.makeMomentComparator<EntityItem>(getValueAsMoment);
    }

    public static makeStringDateComparator(
        getValueAsString: (value: any, node: EntityRowNode) => string
    ) {
        return OmniGridUtil.makeStringDateComparator<EntityItem>(
            getValueAsString
        );
    }

    public static makeAttributeMultiValueComparator(
        attributeMeta: AttributeMetaInfo
    ) {
        const getAttributeMultiValueAsString = (
            value: any,
            node: EntityRowNode
        ) =>
            (
                node.data?.getTypedAttributeValue(
                    attributeMeta
                ) as AttributeObjectValue[]
            )
                ?.map((v) => v.DisplayName)
                .join(', ');
        return EntityGridUtil.makeTextComparator(
            getAttributeMultiValueAsString
        );
    }
}

export type EntityRowNode = TRowNode<EntityItem>;

import { ChangeDetectionStrategy, Component } from '@angular/core';
import {
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
} from '@angular/forms';
import {
    FunnelAction,
    ValidatedFunnelStepComponent,
} from '@datagalaxy/ui/funnel';
import { Observable, map, startWith } from 'rxjs';
import { MultilingualFunnelStateService } from '../multilingual-funnel-state.service';
import {
    LanguageForm,
    languageAdapter,
} from '../../../languages/language-selection-helpers';
import { MultilingualApiService } from '@datagalaxy/webclient/multilingual/data-access';
import { MultilingualFunnelStepComponent } from '../multilingual-funnel-step.component';

@Component({
    selector: 'dxy-source-language-step',
    templateUrl: './source-language-step.component.html',
    styleUrls: ['../funnel-step.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SourceLanguageStepComponent
    extends MultilingualFunnelStepComponent
    implements ValidatedFunnelStepComponent
{
    public valid$: Observable<boolean>;

    override actions: FunnelAction[] = [
        this.previousAction,
        {
            ...this.nextAction,
            callback: () => this.saveSourceLanguage(),
        },
    ];

    protected form: FormGroup<LanguageForm>;
    protected languages = this.stateService.getAvailableSourceLanguages();
    protected languageAdapter = languageAdapter;

    constructor(
        private formBuilder: FormBuilder,
        private stateService: MultilingualFunnelStateService,
        private multilingualApiService: MultilingualApiService
    ) {
        super();
        const defaultValue = this.stateService.getSourceLanguage() ?? null;
        this.form = this.formBuilder.group<LanguageForm>({
            language: new FormControl(defaultValue, [Validators.required]),
        });
        this.valid$ = this.form.statusChanges.pipe(
            startWith(this.form.status),
            map((status) => status === 'VALID')
        );
        this.form.controls.language.valueChanges.subscribe((value) => {
            if (!value) {
                return;
            }
            this.stateService.setSourceLanguage(value);
        });
    }

    public async saveSourceLanguage() {
        await this.multilingualApiService.updateSettings({
            sourceLanguageCode:
                this.form.value?.language?.languageCode ?? undefined,
            enabled: false,
        });
    }
}

<header>
    <ng-content></ng-content>
    <form *ngIf="editableTitle; else titleTemplate" [formGroup]="titleForm">
        <dxy-title-input
            #titleInput
            name="sectionTitle"
            formControlName="title"
            [class.visible]="section.titleVisible"
            [readonly]="dragging"
            [placeholder]="'Dashboard.Section.emptyTitle' | translate"
            [dxyRichTooltip]="titleTooltipTemplate"
            richTooltipPosition="top-left"
            [richTooltipPositionOptions]="{
                offsetY: -5
            }"
            [richTooltipDisabled]="titleTooltipDisabled"
            [maxLength]="titleMaxLength"
        >
        </dxy-title-input>
    </form>
    <ng-template #titleTooltipTemplate>
        <div class="dg5-custom-tooltip" translate>
            Dashboard.Section.hiddenTitle
        </div>
    </ng-template>
    <ng-template #titleTemplate>
        <h3 *ngIf="titleVisible" dxyEllipsisTooltip>{{ title }}</h3>
    </ng-template>
</header>
<section *ngIf="!hidden">
    <dxy-dashboard-grid
        [enableEdition]="enableEdition"
        [section]="section"
        (openWidgetGallery)="openWidgetGallery.emit()"
        [widgetGalleryOpened]="widgetGalleryOpened"
    ></dxy-dashboard-grid>
</section>

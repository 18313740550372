import { Component, Input } from '@angular/core';
import { IActionOption } from '@datagalaxy/core-ui';
import { BaseCellComponent } from '@datagalaxy/core-ui/cell-components';

/**
 * ## Role
 * Display a text and action buttons in an omnigrid
 */
@Component({
    selector: 'app-text-and-tools-cell',
    templateUrl: 'text-and-tools-cell.component.html',
    styleUrls: ['text-and-tools-cell.component.scss'],
})
export class TextAndToolsCellComponent<
    TEntity = unknown
> extends BaseCellComponent<TEntity, string> {
    @Input() public text: string;
    @Input() public actionsData?: TEntity;
    @Input() public actions?: IActionOption<TEntity>[];
    @Input() public size: 'default' | 'medium' = 'default';

    constructor() {
        super();
    }

    protected override updateBindings() {
        this.text = this.value;
        this.actionsData = this.data;
        this.actions = this.params?.actions;
    }
}

import { Component, Inject, OnInit } from '@angular/core';
import { DxyBaseModalComponent } from '@datagalaxy/ui/dialog';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
    ISpaceNewsForm,
    ISpaceNewsWidgetModalInput,
    ISpaceNewsWidgetModalOutput,
} from './space-news-widget-modal.types';
import {
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
} from '@angular/forms';
import { SpaceApiService } from '../../services/space-api.service';
import { RichTextMentionService } from '../../../shared/richText/mentions/rich-text-mention.service';
import { AppDataService } from '../../../services/app-data.service';
import { DxyModalService } from '../../../shared/dialogs/DxyModalService';
import { TranslateService } from '@ngx-translate/core';
import { SecurityService } from '../../../services/security.service';
import { CurrentSpaceService } from '../../../services/currentSpace.service';
import { SpaceArticleDto } from '@datagalaxy/webclient/workspace/data-access';
import {
    IUiImageInputParams,
    UiImageInputMode,
} from '@datagalaxy/core-ui/image-input';

/**
 * ## Role
 * Display a news details in readonly mode or display a form to
 * edit/create a news
 */
@Component({
    selector: 'app-space-news-widget-modal',
    templateUrl: './space-news-widget-modal.component.html',
    styleUrls: ['./space-news-widget-modal.component.scss'],
})
export class SpaceNewsWidgetModalComponent
    extends DxyBaseModalComponent<
        ISpaceNewsWidgetModalInput,
        ISpaceNewsWidgetModalOutput
    >
    implements OnInit
{
    protected loading: boolean;
    protected isEditing: boolean;
    protected formGroup: FormGroup<ISpaceNewsForm>;
    protected searchImageInputParams: IUiImageInputParams = {
        mode: UiImageInputMode.modalEdit,
        editSize: 500,
        imageSize: 450,
        finalSize: 450,
        upscale: true,
        forcedRatio: 5 / 3,
        placeHolderClass: 'space-news-image-placeholder',
        getImageUrl: () => this.spaceApiService.getArticleImage(this.article),
        storeImage: (file) => this.onStoreImage(file),
        deleteImage: () => this.onDeleteImage(),
    };

    protected get isReading() {
        return !this.isEditing && !this.isCreating;
    }
    protected get article() {
        return this.data.article;
    }
    protected get title() {
        return `UI.SpaceHome.Widgets.News.Modal.Title.${
            this.isCreating ? 'Create' : 'Update'
        }`;
    }
    protected get submitButtonKey() {
        return this.isCreating ? 'UI.Global.btnCreate' : 'UI.Global.btnUpdate';
    }
    protected get cancelButtonKey() {
        return this.isEditing ? 'UI.Global.btnCancel' : 'UI.Global.btnClose';
    }
    protected get isCreating() {
        return !this.data?.article;
    }
    protected get descriptionLabelKey() {
        return this.isReading
            ? ''
            : 'UI.SpaceHome.Widgets.News.Modal.Form.Description';
    }
    protected get mentionResolvers() {
        return this.richTextMentionService.getDefaultMentionResolvers();
    }
    protected get showEditButton() {
        return (
            this.isReading &&
            (this.article.CreationUserId ===
                this.appDataService.currentUserId ||
                this.isCurrentUserWorkspaceAdmin())
        );
    }

    private imageFile: File;
    private imageDeleted: boolean;

    constructor(
        dialogRef: MatDialogRef<SpaceNewsWidgetModalComponent>,
        @Inject(MAT_DIALOG_DATA) data: ISpaceNewsWidgetModalInput,
        private spaceApiService: SpaceApiService,
        private appDataService: AppDataService,
        private richTextMentionService: RichTextMentionService,
        private dxyModalService: DxyModalService,
        private translate: TranslateService,
        private fb: FormBuilder,
        private securityService: SecurityService,
        private currentSpaceService: CurrentSpaceService
    ) {
        super(dialogRef, data);
    }

    ngOnInit() {
        this.init();
    }

    protected async onSubmit() {
        if (!this.formGroup.valid) {
            return;
        }

        this.loading = true;

        try {
            const article = await this.createOrUpdate();
            this.result = { article };
            this.onCloseSubmit();
        } finally {
            this.loading = false;
        }
    }

    protected onEditClick() {
        this.isEditing = true;
    }

    protected onCancelClick() {
        if (this.isCreating || this.isReading) {
            this.onCloseCancel();
        } else {
            this.isEditing = false;
            const { Title: title, Description: description } = this.article;
            this.formGroup.reset({ title, description });
        }
    }

    protected async onDeleteClick() {
        const title = this.article.Title;
        const res = await this.dxyModalService.prompt({
            titleKey: 'UI.SpaceHome.Widgets.News.Modal.Delete.Title',
            message: this.translate.instant(
                'UI.SpaceHome.Widgets.News.Modal.Delete.Message',
                { title }
            ),
            userInputLabelKey: 'UI.SpaceHome.Widgets.News.Modal.Form.Title',
            userInputValidationMethod: (input) => input === title,
            confirmButtonKey: 'UI.Global.btnDelete',
            isFromActiveModal: true,
        });

        if (!res) {
            return;
        }

        this.loading = true;
        try {
            await this.spaceApiService.deleteArticle(this.article.ArticleUid);
            this.result = { deleted: true };
            this.onCloseSubmit();
        } finally {
            this.loading = false;
        }
    }

    private init() {
        this.formGroup = this.fb.group({
            title: new FormControl(this.article?.Title, Validators.required),
            description: new FormControl(
                this.article?.Description,
                Validators.required
            ),
        });
    }

    private async createOrUpdate(): Promise<SpaceArticleDto> {
        const { title, description } = this.formGroup.value;

        if (this.isCreating) {
            return await this.spaceApiService.createArticle(
                this.data.spaceIdr,
                title,
                description,
                this.imageFile
            );
        }
        return await this.spaceApiService.updateArticle(
            this.article.ArticleUid,
            this.data.spaceIdr,
            title,
            description,
            this.imageFile,
            this.imageDeleted
        );
    }

    private async onStoreImage(file: File) {
        this.log('onStoreImage');
        this.imageDeleted = false;
        this.imageFile = file;
        return true;
    }

    private async onDeleteImage() {
        this.log('onDeleteImage');
        this.imageFile = null;
        if (this.article?.ImageHash) {
            this.imageDeleted = true;
        }
        return true;
    }

    private isCurrentUserWorkspaceAdmin() {
        return this.securityService.hasManagementAccess(
            this.currentSpaceService.currentSpace
        );
    }
}

<dxy-spinner
    *ngIf="loading$ | async; else tableTemplate"
    class="absolute-centered xl"
></dxy-spinner>

<ng-template #tableTemplate>
    <table
        *ngIf="table$ | async as table"
        cdkDropList
        cdkDropListOrientation="horizontal"
        [cdkDropListDisabled]="!orderingEnabled"
        (cdkDropListDropped)="onHeaderCellDrop($event)"
    >
        <thead>
            <tr class="header-row">
                <ng-container
                    *ngFor="let headerGroup of table.getHeaderGroups()"
                >
                    <th
                        *ngIf="selectionEnabled"
                        class="header-cell column-select"
                    >
                        <dxy-checkbox
                            (checkedChange)="table.toggleAllRowsSelected()"
                            [checked]="table.getIsAllRowsSelected()"
                            [indeterminate]="table.getIsSomeRowsSelected()"
                        >
                        </dxy-checkbox>
                    </th>

                    <th
                        *ngFor="
                            let header of headerGroup.headers;
                            trackBy: trackByHeader
                        "
                        class="header-cell header-cell-{{ header.id }}"
                        [style.width.px]="header.getSize()"
                        [class.sortable]="header.column.getCanSort()"
                        (click)="header.column.getToggleSortingHandler?.()?.($event)"
                        cdkDrag
                        cdkDragLockAxis="x"
                        [cdkDragDisabled]="header.column.getIsPinned()"
                        cdkDragBoundary="cdk-header-row"
                        [cdkDragData]="header"
                    >
                        <span class="header-text" dxyEllipsisTooltip>
                            {{ header.column.columnDef.header }}
                        </span>
                        <dxy-icon
                            *ngIf="header.column.getCanSort()"
                            [icon]="getSortIcon(header)"
                            size="xs"
                        ></dxy-icon>
                        <div
                            *ngIf="header.column.getCanResize()"
                            class="resize-handle"
                            (mousedown)="onResizeHeader($event, header)"
                            (click)="$event.stopPropagation()"
                        >
                            <span class="resize-bar"></span>
                        </div>
                    </th>
                </ng-container>
            </tr>
        </thead>
        <tbody>
            <cdk-virtual-scroll-viewport [itemSize]="rowHeightInPx">
                <tr
                    *cdkVirtualFor="let row of tableRows; trackBy: trackByRow"
                    class="row"
                    [ngClass]="config?.rowCssClass?.(row.original)"
                    [class.group-row]="row.getIsGrouped()"
                    [class.active]="isActive(row)"
                    (click)="onRowClick(row)"
                    [id]="row.id"
                >
                    <td
                        *ngIf="row.getIsGrouped()"
                        class="group-cell"
                        [attr.colspan]="table.getVisibleLeafColumns().length"
                        (click)="row.toggleExpanded()"
                    >
                        <button dxyIconButton color="secondary">
                            <dxy-icon
                                [icon]="
                                    row.getIsExpanded()
                                        ? 'glyph-arrow-drop-down'
                                        : 'glyph-arrow-drop-right'
                                "
                            ></dxy-icon>
                        </button>
                        <dxy-grid-cell
                            [col]="config?.groupBy?.colDef"
                            [item]="row.original"
                        ></dxy-grid-cell>
                    </td>
                    <ng-container *ngIf="!row.getIsGrouped()">
                        <td *ngIf="selectionEnabled" class="cell column-select">
                            <dxy-checkbox
                                (click)="$event.stopPropagation()"
                                (change)="row.toggleSelected()"
                                [checked]="row.getIsSelected()"
                            >
                            </dxy-checkbox>
                        </td>
                        <td
                            *ngFor="
                                let cell of row.getVisibleCells();
                                let cellIndex = index
                            "
                            class="cell"
                            [class.expand-cell]="row.getCanExpand()"
                            [style.width.px]="cell.column.getSize()"
                        >
                            <ng-container
                                *ngIf="cellIndex === 0 && config?.treeConfig"
                            >
                                <div
                                    class="spacer"
                                    [style.padding-left.px]="
                                        row.depth * 30 +
                                        (row.getCanExpand() ? 0 : 32)
                                    "
                                ></div>
                                <button
                                    *ngIf="row.getCanExpand()"
                                    (click)="row.toggleExpanded()"
                                    dxyIconButton
                                    color="secondary"
                                >
                                    <dxy-icon
                                        [icon]="
                                            row.getIsExpanded()
                                                ? 'glyph-arrow-drop-down'
                                                : 'glyph-arrow-drop-right'
                                        "
                                    ></dxy-icon>
                                </button>
                            </ng-container>
                            <dxy-grid-cell
                                [col]="getCellColumn(cell)"
                                [item]="row.original"
                            ></dxy-grid-cell>
                        </td>
                    </ng-container>
                </tr>
            </cdk-virtual-scroll-viewport>
        </tbody>
    </table>
</ng-template>

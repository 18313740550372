import { Injectable } from '@angular/core';
import {
    DksGraphSurface,
    EntityDksNode,
    TDksNode,
} from '../data-knowledge-studio.types';
import { map } from 'rxjs';
import { NodeUtils } from '../nodes/node.utils';
import { switchMap } from 'rxjs/operators';
import {
    IOnStageAsset,
    IOnStageAssetChild,
} from './asset-onstage/asset-onstage.types';
import { DksEntitiesService } from '../entities/dks-entities.service';
import { Rect } from '@datagalaxy/core-2d-util';
import { EntityPanelTool } from '../../entity-panels/entity-panels.types';
import { EntityPreviewPanelService } from '../../shared/entity/services/entity-preview-panel.service';
import { IActionOption } from '@datagalaxy/core-ui';
import { DksAssetsOptions } from '../config/dks-config.types';
import { EntityNodeTree } from '../nodes/entity/entity-node.types';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';
import { HddUtil } from '../../shared/util/HddUtil';
import { IEntityIdentifier, IHasHddData } from '@datagalaxy/dg-object-model';

@Injectable()
export class DksAssetPanelService<NodeData, EdgeData> {
    constructor(
        private dksEntitiesService: DksEntitiesService,
        private entityPreviewPanelService: EntityPreviewPanelService
    ) {}

    public getOnStageAssets(
        graphSurface: DksGraphSurface<TDksNode<NodeData>, EdgeData>,
        options?: DksAssetsOptions
    ) {
        const entities$ = this.dksEntitiesService.selectEntities();
        const node$ = graphSurface.graph.nodes$.pipe(
            map((nodes) =>
                nodes
                    .map((n) => n.data.data as TDksNode<NodeData>)
                    .filter(NodeUtils.isEntityNode)
            )
        );

        return node$.pipe(
            switchMap((nodes) =>
                entities$.pipe(
                    switchMap((entities) =>
                        graphSurface.selection.selectedNodes$.pipe(
                            map((selectedNodes) =>
                                this.makeAssets(
                                    nodes,
                                    selectedNodes.map((n) => n.id),
                                    entities,
                                    graphSurface,
                                    options
                                )
                            )
                        )
                    )
                )
            )
        );
    }

    private makeAssets(
        nodes: EntityDksNode<NodeData>[],
        selectedIds: string[],
        entities: EntityItem[],
        graphSurface: DksGraphSurface<TDksNode<NodeData>, EdgeData>,
        options?: DksAssetsOptions
    ): IOnStageAsset<NodeData>[] {
        if (!entities?.length) {
            return [];
        }

        return nodes
            .map((node) => {
                const entity = entities.find(
                    (entity) =>
                        entity.ReferenceId === node.entityIdr.ReferenceId
                );

                if (!entity && !node.noAccessData) {
                    return;
                }

                return {
                    node,
                    selected: selectedIds.includes(node.id),
                    entity,
                    children: node.children?.map((child) =>
                        this.makeAssetChild(child, graphSurface, options)
                    ),
                    opened: true,
                    params: {
                        data: {
                            ...node,
                            HddData:
                                entity?.HddData ||
                                HddUtil.createNoAccessHData(node.noAccessData),
                        },
                        actions: this.getAssetActions(
                            graphSurface,
                            options?.menu
                        ),
                        inputs: {
                            noLabelNavLink: true,
                            noBreadcrumbNavLink: true,
                        },
                    },
                };
            })
            .filter((asset) => !!asset);
    }

    private makeAssetChild(
        child: EntityNodeTree,
        graphSurface: DksGraphSurface<TDksNode<NodeData>, EdgeData>,
        options?: DksAssetsOptions
    ): IOnStageAssetChild {
        return {
            entity: child.entity,
            params: {
                data: {
                    ...child,
                    HddData: child.entity.HddData,
                },
                actions: this.getAssetActions(graphSurface, options?.menu),
                inputs: {
                    noLabelNavLink: true,
                    noBreadcrumbNavLink: true,
                },
            },
            opened: true,
            selected: false,
            children: child.children?.map((child) =>
                this.makeAssetChild(child, graphSurface, options)
            ),
        };
    }

    private getAssetActions(
        graphSurface: DksGraphSurface<TDksNode<NodeData>, EdgeData>,
        menuOptions?: IActionOption<IHasHddData>[]
    ): IActionOption<IHasHddData>[] {
        return [
            {
                glyphClass: 'glyph-marker',
                tooltipTranslateKey: 'UI.Diagrams.Assets.centerOn',
                callback: (entity: IHasHddData) =>
                    this.centerOnAsset(entity, graphSurface),
            },
            {
                glyphClass: 'glyph-splitter',
                tooltipTranslateKey: 'UI.DiagramNode.moreOptions',
                options: [
                    {
                        glyphClass: 'glyph-object-preview',
                        labelKey: 'UI.Search.resultActionTooltip.openPane',
                        callback: (entity: IHasHddData) =>
                            this.openPreviewPanel(
                                entity.HddData,
                                EntityPanelTool.Details
                            ),
                        hidden: (entity: IHasHddData) =>
                            !entity.HddData.Data.HasReadAccess,
                    },
                    {
                        glyphClass: 'glyph-diagram',
                        labelKey: 'UI.DiagramNode.showLinkedObjects',
                        callback: (entity: IHasHddData) =>
                            this.openPreviewPanel(
                                entity.HddData,
                                EntityPanelTool.LinkedObject
                            ),
                        hidden: (entity: IHasHddData) =>
                            !entity.HddData.Data.HasReadAccess,
                    },
                    ...(menuOptions || []),
                ],
            },
        ];
    }

    private async centerOnAsset(
        entity: IHasHddData,
        graphSurface: DksGraphSurface<TDksNode<NodeData>, EdgeData>
    ) {
        const id = entity.HddData.ReferenceId;
        const n = graphSurface.graph.getNodeById(id);
        graphSurface.zoom.centerView(Rect.center(n.data.rect), 500, true);
        this.highlightNode(n.data.data, graphSurface);
    }

    private highlightNode(
        node: TDksNode<NodeData>,
        graphSurface: DksGraphSurface<TDksNode<NodeData>, EdgeData>
    ) {
        const nodes = graphSurface.graph.getNodes();
        graphSurface.graph.updateNodes(
            nodes.map((n) => n.id),
            { highlighted: false }
        );
        graphSurface.graph.updateNode(node.id, { highlighted: true });
    }

    private async openPreviewPanel(
        entityIdr: IEntityIdentifier,
        activeTool?: EntityPanelTool
    ) {
        await this.entityPreviewPanelService.setupPanel({
            entityIdr,
            forcedTool: EntityPanelTool[activeTool],
        });
    }
}

<div
    class="dg_omnigrid-container dg_omnigrid-theme-default"
    cdkDropList
    [cdkDropListConnectedTo]="dragDrop?.dropTo"
    [cdkDropListDisabled]="!dragDrop || dragDrop.disabled"
    cdkDropListSortingDisabled
    [cdkDropListData]="dragDrop"
>
    <ag-grid-angular
        *ngIf="isReady"
        class="ag-theme-balham"
        [ngClass]="{
            'dg_omnigrid-row-select-single': isRowSelectSingle,
            'dg_omnigrid-normal': !isAutoHeight,
            'dg_omnigrid-tree': isTree,
            'dg_omnigrid-single-column': isSingleColumn
        }"
        [gridOptions]="gridOptions"
    >
    </ag-grid-angular>
</div>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MatCommonModule } from '@angular/material/core';
import { DxyInputFileChangeDirective } from './input-file-change.directive';
import { DxyAutoFocusInputDirective } from './auto-focus-input.directive';
import { DxyResizeColumnDirective } from './resize-column.directive';
import { DxySortListItemDirective } from './sort-list-item.directive';
import { DxySortListDirective } from './sort-list.directive';
import { FileDropDirective } from '@datagalaxy/ui/forms';
import { EllipsisTooltipDirective } from '@datagalaxy/ui/tooltip';
import { DxyDataTestIdDirective } from '@datagalaxy/ui/testing';

@NgModule({
    imports: [
        CommonModule,
        MatCommonModule,
        MatTooltipModule,
        FileDropDirective,
        EllipsisTooltipDirective,
        DxyDataTestIdDirective,
    ],
    declarations: [
        DxyInputFileChangeDirective,
        DxyAutoFocusInputDirective,
        DxyResizeColumnDirective,
        DxySortListItemDirective,
        DxySortListDirective,
    ],
    exports: [
        DxyDataTestIdDirective,
        DxyInputFileChangeDirective,
        FileDropDirective,
        DxyAutoFocusInputDirective,
        DxyResizeColumnDirective,
        DxySortListItemDirective,
        DxySortListDirective,
        EllipsisTooltipDirective,
    ],
})
export class DxyCoreDirectivesModule {}

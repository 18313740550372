import { Component } from '@angular/core';
import { DxyBaseModalComponent } from '@datagalaxy/ui/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { IForeignKeyDeleteModalResult } from '../pkfk.types';

@Component({
    selector: 'dxy-foreign-key-delete-modal',
    templateUrl: './dxy-foreign-key-delete-modal.component.html',
})
export class DxyForeignKeyDeleteModalComponent extends DxyBaseModalComponent<
    void,
    IForeignKeyDeleteModalResult
> {
    public isKeepFunctionalForeignKey: boolean;

    constructor(
        dialogRef: MatDialogRef<
            DxyForeignKeyDeleteModalComponent,
            IForeignKeyDeleteModalResult
        >
    ) {
        super(dialogRef);
    }

    public onCloseSubmit() {
        this.result = {
            isKeepFunctionalForeignKey: this.isKeepFunctionalForeignKey,
        };
        super.onCloseSubmit();
    }
}

import {
    AfterViewInit,
    Component,
    ElementRef,
    HostListener,
    Input,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { IActionOption, IFunctionalEvent } from '@datagalaxy/core-ui';
import { DpMappingUiService } from '../dpMappingUi.service';
import { BaseDpMappingItemComponent } from '../helpers/BaseDpMappingItemComponent';
import { GraphicalEntityData } from '../models/GraphicalEntityData';
import { TNodeType } from '../dp-mapping.types';
import { ViewTypeService } from '../../../services/viewType.service';
import { DataProcessingItemDto } from '@datagalaxy/webclient/data-processing/data-access';
import { FunctionalLogService } from '@datagalaxy/webclient/monitoring/data-access';

/**
 * ## Role
 * UI representation of a Data-Processing Item in the Data-Processing Mapping UI
 *
 * (i.e., the boxes in the 'Process' layout column)
 */
@Component({
    selector: 'app-dp-item-element',
    templateUrl: 'dp-item-element.component.html',
})
export class DpItemElementComponent
    extends BaseDpMappingItemComponent
    implements OnInit, AfterViewInit, OnDestroy
{
    @Input() linkedItem: DataProcessingItemDto;

    public menuOptions: IActionOption[];
    public get richTooltipDisabled() {
        return !this.dpMappingUiService.tooltipsEnabled;
    }

    protected itemId: string;
    protected data: GraphicalEntityData;
    protected type: TNodeType = 'Process';

    private get hasWriteAccess() {
        return this.dpMappingUiService.hasWriteAccess;
    }

    constructor(
        elementRef: ElementRef<HTMLElement>,
        dpMappingUiService: DpMappingUiService,
        private viewTypeService: ViewTypeService,
        private functionalLogService: FunctionalLogService
    ) {
        super(elementRef, dpMappingUiService);
    }

    ngOnInit() {
        this.data = this.dpMappingUiService.onDpItemElementPreInit(
            this.linkedItem
        );
        this.itemId = this.linkedItem.ReferenceId;
        this.initMenu();
    }
    ngAfterViewInit() {
        this.dpMappingUiService.onDpItemElementPostInit(this.linkedItem);
        super.ngAfterViewInit();
    }
    ngOnDestroy() {
        this.dpMappingUiService.onItemElementDestroy(this.linkedItem);
        super.ngOnDestroy();
    }

    public editDataProcessingItem() {
        this.dpMappingUiService.showDpiEditModal(this.linkedItem);
    }

    public deleteDataProcessingItem() {
        this.dpMappingUiService.deleteDpItem(this.linkedItem);
    }

    public toggleDpiConnection() {
        this.dpMappingUiService.toggleDpItemElementConnection(this.linkedItem);
    }

    public onLogFunctional(event: IFunctionalEvent) {
        this.functionalLogService.parseAndLog(event.text, event.origin);
    }

    public getName() {
        return this.viewTypeService.getTechnicalOrDisplayName(this.linkedItem);
    }

    @HostListener('mouseenter') private mouseenter() {
        this.dpMappingUiService.toggleDpItemElementHover(this.linkedItem, true);
    }

    @HostListener('mouseleave') private mouseleave() {
        this.dpMappingUiService.toggleDpItemElementHover(
            this.linkedItem,
            false
        );
    }

    private initMenu() {
        this.menuOptions = [
            {
                glyphClass: 'glyph-edit',
                labelKey: this.hasWriteAccess
                    ? 'UI.DataProcessingItem.ttEdit'
                    : 'UI.DataProcessingItem.ttEditReadOnly',
                callback: () => this.editDataProcessingItem(),
            },
            {
                glyphClass: 'glyph-hide',
                labelKey: 'UI.DataProcessingItem.ttToggleConnections',
                callback: () => this.toggleDpiConnection(),
                logFunctional: 'HIDE_DATA_PROESSING_ITEM,U',
            },
            {
                glyphClass: 'glyph-delete',
                labelKey: 'UI.DataProcessingItem.ttDelete',
                callback: () => this.deleteDataProcessingItem(),
                hidden: () => !this.hasWriteAccess,
            },
        ];
    }
}

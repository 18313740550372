import {
    AfterViewInit,
    ChangeDetectorRef,
    Directive,
    ElementRef,
    Input,
    NgZone,
    OnChanges,
    OnDestroy,
    SimpleChanges,
} from '@angular/core';
import { GridPosition } from './domain/grid-position';
import { DashboardGridService } from './dashboard-grid/dashboard-grid.service';
import { DxyBaseDirective } from '@datagalaxy/ui/core';

@Directive({ selector: '[dxyCardFromGrid]' })
export class CardFromGridDirective
    extends DxyBaseDirective
    implements OnDestroy, AfterViewInit, OnChanges
{
    @Input('dxyCardFromGrid') position: GridPosition;
    @Input() enableEdition = false;

    private created = false;

    constructor(
        private el: ElementRef<HTMLElement>,
        private dashboardGridService: DashboardGridService,
        private zone: NgZone,
        private cd: ChangeDetectorRef
    ) {
        super();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.positionHasChanged(changes)) {
            this.moveWidget();
        }
    }

    ngAfterViewInit() {
        this.subscribe(this.dashboardGridService.gridReady$, (ready) => {
            if (!ready) {
                return;
            }
            this.createWidget();
        });
    }

    ngOnDestroy() {
        this.destroyWidget();
    }

    private positionHasChanged(changes: SimpleChanges) {
        if (!this.created || !this.enableEdition) {
            return false;
        }
        return !!changes.position;
    }

    private createWidget() {
        this.zone.runOutsideAngular(() => {
            this.dashboardGridService.addWidget(
                this.el.nativeElement,
                this.position
            );
            this.created = true;
            this.cd.detectChanges();
        });
    }

    private moveWidget() {
        this.zone.runOutsideAngular(() => {
            this.dashboardGridService.moveWidget(this.el.nativeElement, {
                x: this.position.x,
                y: this.position.y,
            });
        });
    }

    private destroyWidget() {
        this.zone.runOutsideAngular(() => {
            this.dashboardGridService.removeWidget(this.el.nativeElement);
        });
    }
}

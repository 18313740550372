import { HierarchicalData, ModelType } from '@datagalaxy/dg-object-model';
import {
    DataProcessingLinkDirection,
    DataProcessingLinkEntityType,
} from '../../data-processing.types';
import { DataProcessingLinkColumnDto } from './DataProcessingLinkColumnDto';
import { generateGuid } from '@datagalaxy/utils';

/** NOTE: This object is now only used by the client. */
export class DataProcessingLinkDto {
    public Columns: DataProcessingLinkColumnDto[];
    public Direction: DataProcessingLinkDirection;
    public EntityType: DataProcessingLinkEntityType;
    public LinkHierarchyData: HierarchicalData;
    public ModelDisplayName: string;
    public TableDisplayName: string;
    public TableTechnicalName: string;
    public Type: ModelType;
    public ReferenceId: string;

    public get LinkedDataReferenceId(): string {
        return this.LinkHierarchyData.DataReferenceId;
    }

    constructor(
        columns: DataProcessingLinkColumnDto[],
        direction: DataProcessingLinkDirection,
        entityType: DataProcessingLinkEntityType,
        linkHierarchyData: HierarchicalData
    ) {
        this.Columns = columns;
        this.Direction = direction;
        this.EntityType = entityType;
        this.LinkHierarchyData = linkHierarchyData;
        this.ReferenceId = generateGuid(); // note: this ReferenceId is not is the format "guid:guid"
    }
}

import { Injectable } from '@angular/core';
import { ModuleSecurityService } from './module-security.service';
import {
    allModules,
    DgModuleDefinition,
} from '@datagalaxy/shared/dg-module/domain';
import { ObjectAccessType } from '@datagalaxy/webclient/security/data-access';
import { UserService } from '../services/user.service';

@Injectable({ providedIn: 'root' })
export class ModuleService {
    constructor(
        private moduleSecurityService: ModuleSecurityService,
        private userService: UserService
    ) {}

    public async loadCurrentUserAccessibleModules(
        spaceId: string,
        versionId: string
    ) {
        const currentUser = this.userService.getCurrentUser();
        return this.loadModules(currentUser.UserId, spaceId, versionId);
    }

    public async loadModules(
        userId: string,
        spaceId: string,
        versionId: string
    ) {
        const accessibleModules: DgModuleDefinition[] = [];
        const modulesWithSecurityData =
            await this.moduleSecurityService.getUserModulesSecurityData(
                userId,
                spaceId,
                versionId
            );
        const userAccessibleModules = modulesWithSecurityData.Items.filter(
            (module) =>
                module.ObjectAccessType !== ObjectAccessType.NoAccess &&
                module.Module !== 'Catalog'
        );

        userAccessibleModules.forEach((module) => {
            const dgModule = allModules.find((m) => m.name === module.Module);
            accessibleModules.push(dgModule);
        });

        const dgModule = allModules.find((m) => m.name === 'Catalog');
        accessibleModules.push(dgModule);

        return accessibleModules.sort((a, b) => a.position - b.position);
    }
}

import {
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import {
    IPrimaryKeySettingsInput,
    IPrimaryKeySettingsOutput,
} from '../pkfk.types';
import { ModelerService } from '../../services/modeler.service';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';

@Component({
    selector: 'app-primary-key-preview-pane',
    templateUrl: 'primary-key-preview-pane.component.html',
    styleUrls: ['./primary-key-preview-pane.component.scss'],
})
export class PrimaryKeyPreviewPaneComponent
    extends DxyBaseComponent
    implements OnInit, OnChanges
{
    @Input() entityData: EntityItem;
    @Input() showHeader: boolean;
    @Input() readOnly: boolean;

    public settingsData: IPrimaryKeySettingsInput;
    public get hasWriteAccess() {
        return (
            !this.readOnly && !!this.entityData?.SecurityData?.HasWriteAccess
        );
    }
    private isInitialOutput: boolean;

    constructor(private modelerService: ModelerService) {
        super();
    }

    ngOnChanges(changes: SimpleChanges): void {
        super.onChange(changes, 'entityData', () => this.initAsync());
    }
    ngOnInit() {
        this.initAsync();
    }

    public async onOutputChange(output: IPrimaryKeySettingsOutput) {
        const changed = !this.isInitialOutput;
        this.isInitialOutput = false;
        if (changed) {
            await this.modelerService.updatePrimaryKey(output);
        }
    }

    private async initAsync() {
        this.log('initAsync', this.entityData);
        this.isInitialOutput = true;
        this.settingsData =
            await this.modelerService.getPrimaryKeySettingsInputForTableEntity(
                this.entityData
            );
    }
}

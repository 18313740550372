import { CollectionsHelper } from '@datagalaxy/core-util';
import { EntityType } from '@datagalaxy/dg-object-model';
import { LineageConstants } from './LineageConstants';
import { ViewType } from '../../../shared/util/app-types/ViewType';

export class LineageItem {
    //#region static helpers

    public static getDescendants(
        item: LineageItem,
        filter?: (it: LineageItem) => boolean,
        includeSelf = false,
        descendants = new Array<LineageItem>()
    ) {
        if (item) {
            const visit = (it: LineageItem) => {
                if (!filter || filter(it)) {
                    descendants.push(it);
                }
                it.children?.forEach(visit);
            };
            if (includeSelf) {
                visit(item);
            } else {
                item.children?.forEach(visit);
            }
        }
        return descendants;
    }

    private static withDescendants(
        item: LineageItem,
        action: (it: LineageItem) => void,
        includeSelf = false
    ) {
        if (!item) {
            return;
        }
        if (includeSelf) {
            action(item);
        }
        item.children?.forEach((it) =>
            LineageItem.withDescendants(it, action, true)
        );
    }

    public static getFirstLinkedDescendant(
        it: LineageItem,
        links: { src: LineageItem; tgt: LineageItem }[],
        includeSelf = false,
        filter?: (linked: LineageItem, upstream: boolean) => boolean
    ) {
        let result: { upstream: boolean; linked: LineageItem };
        LineageItem.findFirstDescendant(
            it,
            (d) => {
                const found = LineageItem.findLinked(d, links, filter);
                if (found) {
                    result = found;
                    return true;
                }
                return false;
            },
            includeSelf
        );
        return result;
    }

    public static findLinked(
        d: LineageItem,
        links: { src: LineageItem; tgt: LineageItem }[],
        filter?: (linked: LineageItem, upstream: boolean) => boolean
    ) {
        const src = links.find(
            (l) => l.tgt == d && (!filter || filter(l.src, true))
        )?.src;
        if (src) {
            return { upstream: true, linked: src };
        }
        const tgt = links.find(
            (l) => l.src == d && (!filter || filter(l.tgt, false))
        )?.tgt;
        if (tgt) {
            return { upstream: false, linked: tgt };
        }
    }

    private static findFirstDescendant(
        item: LineageItem,
        predicate: (it: LineageItem) => boolean,
        includeSelf = false
    ): LineageItem {
        if (!item || (includeSelf && predicate(item))) {
            return item;
        }
        if (!item.children) {
            return;
        }
        for (let i = 0, l = item.children.length; i < l; i++) {
            const found = LineageItem.findFirstDescendant(
                item.children[i],
                predicate,
                true
            );
            if (found) {
                return found;
            }
        }
    }

    public static getParents(item: LineageItem) {
        if (!item) {
            return;
        }
        const parents = new Array<LineageItem>();
        let parent = item.parent;
        while (parent) {
            parents.push(parent);
            parent = parent.parent;
        }
        return parents;
    }

    //#endregion

    /** undefined if item is root. Don't set directly, use setParent */
    public parent: LineageItem;

    /** root of the parenting tree. Self if no parent */
    public root: LineageItem;

    /** can be undefined but not empty */
    public children: LineageItem[];

    /** id in the graph */
    public id: string;

    /** the css selector for this item's node in the dom */
    public selector: string;

    /** depth level in the parenting tree (0 if the item is a root) */
    public level: number;

    /** deepest level of the parenting tree */
    public maxLevel: number;

    public isSearchedItem: boolean;
    /** is a parent of the searched item */
    public isSearchedItemParent: boolean;

    public isExpanded = false;
    public x = 0;
    public y = 0;
    public width: number;
    public height: number;

    /** height when collapsed */
    public minHeight: number;

    /** width of the text when not ellipsed */
    public wholeTextWidth: number;

    /** true if the item is the root of the hierarchical group */
    public get isRoot() {
        return !this.parent;
    }

    public get hasChildren() {
        return this.children != undefined;
    }

    public get hasLazyChildren() {
        return this.lazyChildrenCount > 0;
    }

    public get xmax() {
        return this.x + this.width;
    }

    public get ymax() {
        return this.y + this.height;
    }

    public get ymidmin() {
        return this.y + this.minHeight / 2;
    }

    public get textMaxWidth() {
        const { itemText, goldenItemIcon } = this.layout;
        return (
            this.width -
            itemText.left -
            goldenItemIcon.margin -
            goldenItemIcon.width
        );
    }

    public get headTransform() {
        const hm = this.layout.itemHeadMargin;
        return `translate(${this.headLeft + hm},${hm})`;
    }

    public get headWidth() {
        return this.width - this.headLeft - 2 * this.layout.itemHeadMargin;
    }

    public get headHeight() {
        return this.minHeight - 2 * this.layout.itemHeadMargin;
    }

    /** true if the item is hierarchically ellipsed */
    public get isHEllipsed() {
        return this.hEllipsisRoot != undefined;
    }

    /** true if the item is the root of a hierarchical ellipsis branch */
    public get isHEllipsisRoot() {
        return this.hEllipsisRoot == this;
    }

    public get isVisibleHEllipsisRoot() {
        return this.hEllipsisRoot == this && !!this.parent?.isExpanded;
    }

    /** Normalized depth level for computing drawing parameters. Can be 0 (outermost), 1 (intermediate), 2 (innermost). */
    private uiLevel: number;
    private childrenUiLevel: number;
    private headLeft: number;
    /** root of the hierarchical ellipsis branch when the item is hierarchically ellipsed */
    private hEllipsisRoot: LineageItem;

    private layout: LineageConstants;

    constructor(
        public readonly entityType: EntityType,
        public readonly displayName: string,
        public readonly technicalName: string,
        public readonly dataId: string,
        parent: LineageItem,
        public readonly isGoldenItem: boolean,
        public lazyChildrenCount: number,
        /** leaf item of the branch for which this one has been cloned (by LineageData.splitRoots),
         * for to make a global id, so we can store & restore the position of this cloned item */
        public readonly clonedFor?: LineageItem
    ) {
        this.setParent(parent);
    }

    //#region for preparation

    /** Must be called before init */
    public reParent(
        parent: LineageItem,
        removeEmptyParents: (emptyParent: LineageItem) => boolean
    ) {
        // console.log('reParent', this, parent)
        this.parent?.removeChild(this, removeEmptyParents);
        const prevRoot = this.root;
        this.setParent(parent);
        const root = this.root;
        if (root != prevRoot) {
            LineageItem.withDescendants(this, (it) => (it.root = root));
        }
    }

    private removeChild(
        child: LineageItem,
        canRemoveSelfWhenNoChildren: (it: LineageItem) => boolean
    ) {
        const children = this.children;
        if (!children) {
            return;
        }
        const childIndex = children.indexOf(child);
        if (childIndex == -1) {
            return;
        }
        //console.log('removeChild', this, child, children.length)
        if (children.length < 2) {
            this.children = undefined;
            if (this.parent && canRemoveSelfWhenNoChildren(this)) {
                this.parent.removeChild(this, canRemoveSelfWhenNoChildren);
            }
        } else {
            children.splice(childIndex, 1);
        }
    }

    private setParent(parent: LineageItem) {
        this.parent = parent;
        if (parent) {
            if (parent.children) {
                parent.children.push(this);
            } else {
                parent.children = [this];
            }
            this.level = parent.level + 1;
            this.root = parent.root;
        } else {
            this.level = 0;
            this.root = this;
        }
    }

    /** sets id, and precomputes maxLevel, uiLevel, childrenUiLevel, minHeight, selector, headLeft */
    public init(id: number, layoutSpec: LineageConstants) {
        this.layout = layoutSpec;
        this.id = id.toString();
        const maxLevel =
            this.maxLevel == undefined ? this.computeMaxLevel() : this.maxLevel;
        const level = this.level;
        const uiLevel =
            level == 0 ? 0 : level == maxLevel && maxLevel > 1 ? 2 : 1;
        const childrenUiLevel = level == maxLevel - 1 && maxLevel > 1 ? 2 : 1;
        this.minHeight = layoutSpec.minHeight(uiLevel);
        this.height =
            this.parent && !this.parent.isExpanded ? 0 : this.minHeight;
        this.uiLevel = uiLevel;
        this.childrenUiLevel = childrenUiLevel;
        this.selector = `g[data-id="${id}"]`;
        this.headLeft =
            layoutSpec.itemLeftBorderWidth(uiLevel) +
            layoutSpec.itemLeftPadding(uiLevel);
    }

    public computeMaxLevel() {
        return (this.maxLevel = this.children
            ? CollectionsHelper.maxValue(this.children, (it) =>
                  it.computeMaxLevel()
              )
            : this.level);
    }

    //#endregion

    //#region for items drawing

    public isVisible() {
        return !this.isHEllipsed && this.isVisibleOrHEllipsed();
    }

    /** is visible or hierarchicaly ellipsed */
    public isVisibleOrHEllipsed(): boolean {
        return (
            !this.parent ||
            (this.parent.isExpanded && this.parent.isVisibleOrHEllipsed())
        );
    }

    public getClass() {
        const classes = ['uil' + this.uiLevel];
        classes.push(...this.getAncestorIds('pid'));
        if (this.isSearchedItem) {
            classes.push('searched-item');
        }
        if (this.lazyChildrenCount) {
            classes.push('lzy');
        }
        return classes.join(' ');
    }

    public getDisplayedName(viewType: ViewType, forDebug = false) {
        return (
            (forDebug ? this.id + '-' : '') +
            (viewType == ViewType.Functional
                ? this.displayName || this.technicalName
                : this.technicalName || this.displayName)
        );
    }

    public getHEllipsisDepth(_depth = 0) {
        return this.hEllipsisRoot == undefined
            ? _depth
            : this.children[0].getHEllipsisDepth(_depth + 1);
    }

    public containsAnyHEllipsis() {
        return (
            this.hEllipsisRoot != undefined ||
            (this.children != undefined &&
                this.children.some((c) => c.containsAnyHEllipsis()))
        );
    }

    //#endregion

    //#region for collapse/expand/hierarchical-ellipsis

    public updateHEllipsisSelfAndDescendants(
        toBeEllipsed: boolean,
        removeAll = false,
        _hEllipsRoot?: LineageItem
    ) {
        let anyEllipsed = false;
        if (!this.children) {
            _hEllipsRoot = undefined;
        } else {
            const parent = this.parent,
                children = this.children;

            if (toBeEllipsed) {
                // don't ellipsis if:
                if (
                    !parent || // is root
                    !this.isExpanded || // is collapsed
                    //|| !parent.isExpanded       // parent is collapsed
                    children.length > 1 || // many children
                    !children[0].children // child is leaf
                    //|| !children[0].isExpanded // child is collapsed
                ) {
                    _hEllipsRoot = undefined;
                } else if (!_hEllipsRoot) {
                    // if should ellipsis and no root yet then i am groot
                    _hEllipsRoot = this;
                }
            } else {
                _hEllipsRoot = undefined;
            }

            if (
                !toBeEllipsed &&
                !removeAll &&
                this.hEllipsisRoot &&
                this.children[0].hEllipsisRoot != this.hEllipsisRoot
            ) {
                // don't remove an inner h.ellipsis
            } else {
                children.forEach((it) => {
                    if (
                        it.updateHEllipsisSelfAndDescendants(
                            toBeEllipsed,
                            removeAll,
                            _hEllipsRoot
                        )
                    ) {
                        anyEllipsed = true;
                    }
                });
            }
        }
        this.hEllipsisRoot = _hEllipsRoot;
        return anyEllipsed || _hEllipsRoot != undefined;
    }

    public collectToggleExpandedSelfAndDescendants(
        newLevel: number,
        searchedItemLevel: number,
        collect: Set<LineageItem>
    ) {
        if (!this.children) {
            return;
        }

        const itemNewLevel =
            newLevel < searchedItemLevel &&
            (this.isSearchedItem || this.isSearchedItemParent)
                ? searchedItemLevel
                : newLevel;

        const needsCollapse = this.isExpanded && this.level >= itemNewLevel;
        const needsExpand = !this.isExpanded && this.level < itemNewLevel;

        if (needsCollapse || needsExpand) {
            // this will be dirty on expand/collapse
            collect.add(this);
            if (needsCollapse) {
                //children will be hidden when this is collapsed
                return;
            }
        }

        this.children.forEach((it) =>
            it.collectToggleExpandedSelfAndDescendants(
                newLevel,
                searchedItemLevel,
                collect
            )
        );
    }

    public collapseDescendants(collectCollapsed: Set<LineageItem>) {
        if (this.children) {
            this.children.forEach((it) =>
                it.setIsExpandedSelfAndDescendants(false, collectCollapsed)
            );
        }
    }

    private setIsExpandedSelfAndDescendants(
        expanded: boolean,
        collectChanged: Set<LineageItem>
    ) {
        if (!this.children) {
            return;
        }
        if (this.isExpanded != expanded) {
            collectChanged.add(this);
        }
        this.isExpanded = expanded;
        this.children.forEach((it) =>
            it.setIsExpandedSelfAndDescendants(expanded, collectChanged)
        );
    }

    public updateHGroupAfterExpandedOrCollapsed(
        translateFollowingItems: boolean
    ) {
        const h0 = this.height;
        this.computeSizeSelfAndDescendants();
        const dy = this.height - h0;

        // size ancestors
        if (this.parent) {
            this.parent.sizeSelfAndAncestors(0, dy);
        }

        this.positionSelfAndDescendants(undefined, this.y);

        if (translateFollowingItems) {
            this.getFollowingItemsInHGroup().forEach((it) =>
                it.translateSelfAndDescendants(0, dy)
            );
        }

        return dy;
    }

    public updateHGroupAfterHEllipsisToggled(translateFollowingItems: boolean) {
        const h0 = this.height;
        this.computeSizeSelfAndDescendants(this.width, true);
        const dy = this.height - h0;

        // size ancestors
        if (this.parent) {
            this.parent.sizeSelfAndAncestors(0, dy);
        }

        this.positionSelfAndDescendants(this.x, this.y, true);

        if (translateFollowingItems) {
            this.getFollowingItemsInHGroup().forEach((it) =>
                it.translateSelfAndDescendants(0, dy)
            );
        }

        return dy;
    }

    //#endregion

    //#region for update

    public updatePositionSelfAndDescendants() {
        this.positionSelfAndDescendants(this.x, this.y);
    }

    public positionSelfAndDescendants(
        x?: number,
        y?: number,
        _isPostHEllipsisToggle = false
    ) {
        let xChild: number;
        if (x != undefined) {
            const mw2 =
                this.layout.marginLeft(this.uiLevel) -
                this.layout.itemHeadMargin +
                1;
            if (this.isHEllipsed) {
                xChild = x;
                x += mw2;
            } else {
                if (_isPostHEllipsisToggle) {
                    x -= mw2;
                }
                xChild = x + this.layout.marginLeft(this.childrenUiLevel);
            }
            this.x = x;

            if (y == undefined && this.children) {
                this.children.forEach((it) =>
                    it.positionSelfAndDescendants(xChild)
                );
                return;
            }
        }

        if (y != undefined) {
            this.y = y;
            if (!this.children) {
                return;
            }

            if (this.isExpanded) {
                switch (this.hEllipsisRoot) {
                    case undefined:
                        y += this.minHeight;
                        break;
                    case this:
                        y += this.layout.hierarchicalEllipsis.height;
                        break;
                    default:
                        break;
                }
                const mbc = this.layout.marginBetweenChildren(
                    this.childrenUiLevel
                );
                this.children.forEach((it) => {
                    it.positionSelfAndDescendants(xChild, y);
                    y += it.height + mbc;
                });
            } else if (xChild != undefined) {
                this.children.forEach((it) =>
                    it.positionSelfAndDescendants(xChild)
                );
            }
        }
    }

    /** computes the height, returns the difference between new height and previous height */
    public updateWidthHeightSelfAndDescendants() {
        const h0 = this.height,
            h = this.computeSizeSelfAndDescendants(this.width);
        return h - h0;
    }

    /** Computes and returns height.
     * Descendants width is recomputed only if provided */
    public computeSizeSelfAndDescendants(
        width?: number,
        _isPostHEllipsisToggle = false
    ) {
        const parent = this.parent,
            children = this.children,
            uiLevel = this.uiLevel,
            isHEllipsed = this.hEllipsisRoot != undefined,
            isHEllipsisRoot = this.hEllipsisRoot == this;

        let childWidth: number;
        if (width > 0) {
            const mw =
                    uiLevel == 0
                        ? 0
                        : this.layout.marginLeft(uiLevel) +
                          this.layout.marginRight(uiLevel),
                mw2 =
                    isHEllipsed || _isPostHEllipsisToggle
                        ? this.layout.itemLeftPadding(uiLevel) +
                          2 * this.layout.itemHeadMargin
                        : undefined;
            if (isHEllipsed) {
                childWidth = width;
                if (isHEllipsisRoot) {
                    width -= mw + mw2;
                }
            } else {
                if (_isPostHEllipsisToggle) {
                    width += mw2;
                } else {
                    width -= mw;
                }
                childWidth = width;
            }
            this.width = width;
        }

        let h = isHEllipsed
            ? isHEllipsisRoot
                ? this.layout.hierarchicalEllipsis.height
                : 0
            : parent && !parent.isExpanded
            ? 0
            : this.minHeight;
        if (children) {
            if (this.isExpanded) {
                h +=
                    CollectionsHelper.sum(children, (c) =>
                        c.computeSizeSelfAndDescendants(childWidth)
                    ) +
                    (children.length - 1) *
                        this.layout.marginBetweenChildren(
                            this.childrenUiLevel
                        ) +
                    this.layout.marginAfterLastChild();
            } else if (childWidth) {
                children.forEach((it) =>
                    it.computeSizeSelfAndDescendants(childWidth)
                );
            }
        }
        this.height = h;
        return h;
    }

    public translateSelfAndDescendants(dx: number, dy: number) {
        this.x += dx;
        this.y += dy;
        if (this.children) {
            this.children.forEach((c) => c.translateSelfAndDescendants(dx, dy));
        }
    }

    private sizeSelfAndAncestors(dx: number, dy: number) {
        if (dx) {
            const mw = 0;
            if (this.width + dx < mw) {
                this.width = mw;
                dx = 0;
            } else {
                this.width += dx;
            }
        }
        if (dy) {
            const mh = this.isHEllipsed
                ? this.layout.hierarchicalEllipsis.height
                : this.minHeight;
            if (this.height + dy < mh) {
                this.height = mh;
                dy = 0;
            } else {
                this.height += dy;
            }
        }
        if ((dx || dy) && this.parent) {
            this.parent.sizeSelfAndAncestors(dx, dy);
        }
    }

    //#endregion

    //#region getter helpers

    public isAnyHEllipsisInBranch() {
        if (this.children == undefined) {
            return false;
        }
        if (this.hEllipsisRoot != undefined) {
            return true;
        }
        return this.children.some((it) => it.isAnyHEllipsisInBranch());
    }

    private getFollowingItemsInHGroup() {
        const items = new Array<LineageItem>();
        let start = this as LineageItem;
        while (start.parent) {
            const siblings = start.parent.children,
                iNext = siblings.indexOf(start) + 1,
                iLast = siblings.length - 1;
            if (iNext == iLast) {
                items.push(siblings[iNext]);
            } else if (iNext < iLast) {
                items.push(...siblings.slice(iNext));
            }
            start = start.parent;
        }
        return items;
    }

    private getAncestorIds(prefix = '') {
        const ids = new Array<string>();
        let parent = this.parent;
        while (parent) {
            ids.push(prefix + parent.id);
            parent = parent.parent;
        }
        return ids;
    }

    public isOverLapping(that: LineageItem) {
        return (
            this.isIntersecting(that) ||
            (that.isBelow(this) && this.isIntersectingX(that))
        );
    }

    public isIntersecting(that: LineageItem) {
        return (
            this.xmax > that.x &&
            that.xmax > this.x &&
            that.ymax > this.y &&
            this.ymax > that.y
        );
    }

    private isBelow(that: LineageItem) {
        return this.y > that.ymax;
    }

    private isIntersectingX(that: LineageItem) {
        return this.xmax > that.x && that.xmax > this.x;
    }

    //#region for debug

    public toDebugString(short = false) {
        const p = (v: string) => (v == undefined ? v : '(' + v + ')');
        const getClonedFromId = (it: LineageItem) => {
            let c = it.clonedFor;
            while (c.parent && c.parent.dataId != it.dataId) {
                c = c.parent;
            }
            return c?.id ?? '?';
        };
        const li = (it: LineageItem) =>
            it
                ? it.id +
                  p(it.displayName) +
                  (it.clonedFor ? p(getClonedFromId(it)) : '')
                : it;
        if (short) {
            return li(this);
        }
        return JSON.stringify(
            this,
            (k, v) => {
                switch (k) {
                    case 'parent':
                        return li(v as LineageItem);
                    case 'children':
                        return v && (v as Array<LineageItem>).map(li);
                    case 'root':
                    case 'hEllipsisRoot':
                        return v == this
                            ? '(self)'
                            : v == this.parent
                            ? '(parent)'
                            : li(v as LineageItem);
                    case 'entityType':
                        return v + p(EntityType[v]);
                    default:
                        return v;
                }
            },
            1
        ).replace(/\n|"/gm, '');
    }

    //#endregion

    //#endregion
}

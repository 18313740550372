export enum ZoomType {
    none = 0,
    bestFit,
    reset,
}

export class LineageConstants {
    //#region static

    //#region behaviour

    static readonly behaviour = {
        zoom: {
            onLoadData: ZoomType.bestFit,
            onGlobalExpandLevelChange: ZoomType.none,
            /** applied zoom on: window size changed, mainview-resize, docking pane show/hide */
            onSurfaceSizeChange: ZoomType.bestFit,
            bestFit: {
                /** the [top, right, bottom, left] margins around the bounding box when zooming to best fit */
                margin: [
                    10,
                    10,
                    10,
                    20 + 42 + 20, // toolbar-margin + toolbar-width + toolbar-margin
                ] as [number, number, number, number],
                /** set to 1 for items not to be bigger than their initial size, undefined otherwise */
                zoomMax: 1,
            },
        },

        resetLayout: {
            onGlobalExpandLevelChange: true,
        },

        preventOverlapping: true,

        linkRoutes: {
            allowSameSide: true,
            horizontalTension: 25,
            /** if vertical distance is inferior to this value then horizontal tension is applied proportionally,
             * preventing short links to zig-zag */
            horizontalTensionVerticalDistanceThreshold: 150,
        },

        hierarchicalEllipsis: {
            globalDisabled: false,
            clickDisabled: false,
            applyOnStart: false,
        },

        /** 0 = roots only, 1 = intermediate (global_max_level/2), 2 = all expanded */
        expandLevel: {
            max: 2,
            initial: 1,
        },

        lazyItems: {
            /** maximum number of items to be initially loaded. Can be overridden by adding &maxItems=xxx to the url */
            maxItemsCount: 100,
            maxItemsCountUrlWord: 'maxItems',
            /** maximum number of subsequent items to load, above which a confirmation modal is displayed **/
            warningThreshold: 50,
            /** if true, the user can load an item's children that were not initially loaded by clicking on the parent's caret */
            enableLoadChildren: true,
            /** When enableLoadChildren is true, the server should return children when the item was identified as having lazy children.
             * Setting this to true will remove the caret when no children returned - masking a possible error. */
            removeExpanderCaretWhenNoChildren: false,
        },

        pathTracker: {
            nbTracks: 2,
            clearTracks: {
                onPin: {
                    collapsedItemChildren: true,
                    descendantsIsPartOf: true,
                },
                onCollapseExpand: {
                    descendants: true,
                    self: true,
                    descendantsIsPartOf: true,
                },
            },
            highlightMaxDepth: 1, // for full depth, set to undefined
        },

        splitRoots: {
            globalDisabled: false,
            activatedAtInit: true,
            removeEmptyRootsHavingNoLinks: true,
        },
    };

    //#endregion - behaviour

    //#region drawing constants

    static readonly trackClass = 'trk';

    static readonly textColor = '#10244C';
    static readonly pathLinkColor = '#3D5CFE'; // $dg5-reflex
    static readonly pathLinkGradientStartColor = '#3D5CFE'; // $dg5-reflex
    static readonly pathLinkGradientEndColor = '#11d4b7';

    // used by: link(verticalOffset), renderer
    static readonly linkEnd = {
        verticalOffset: 8,
        horizontalOffset: 2,
        trackSizeFactor: 0.5,
    };

    // used by: renderer
    /** link ends definition */
    static readonly linkArrow = {
        viewBox: '0 -10 20 20',
        refX: 15,
        refY: 0,
        markerWidth: 10,
        markerHeight: 10,
        path: 'M0,-10 L20,0 L0,10 z',
    };

    // used by: renderer
    static readonly linkDot = {
        viewBox: '0 0 10 10',
        refX: 5,
        refY: 5,
        markerWidth: 5,
        markerHeight: 5,
        cx: 5,
        cy: 5,
        r: 5,
    };

    // used by: renderer
    /** expand caret size and offset  */
    static readonly expanderCaretCircle = {
        cx: 5,
        cy: -4.4,
        r: 5,
    };

    //#endregion

    //#endregion - static

    // used by: renderer
    public readonly hoverIcon: IIconSpec;

    // used by item(textMaxWidth), renderer(createGoldenItemIcon)
    public readonly goldenItemIcon: IIconSpec;

    // used by item(...), renderer(showHideHoverIcon)
    public readonly itemHeadMargin: number;

    //used by: item (textMaxWidth), renderer(redrawText)
    public readonly itemText: IItemTextSpec;

    // used by: item(positionSelfAndDescendants, computeSizeSelfAndDescendants, sizeSelfAndAncestors), renderer(setupNewHEllipsisElements)
    public readonly hierarchicalEllipsis: IHEllipsisSpec;

    constructor(private spec: ILineageItemLayoutSpec) {
        this.hoverIcon = spec.hoverIcon;
        this.goldenItemIcon = spec.goldenItemIcon;
        this.itemHeadMargin = spec.itemHeadMargin;
        this.hierarchicalEllipsis = spec.hierarchicalEllipsis;
        this.itemText = spec.itemText;
    }

    // used by: item, renderer, graph
    public minHeight(itemUiLevel: number) {
        return itemUiLevel == 0
            ? this.spec.minHeight.outer
            : this.spec.minHeight.default;
    }

    // used by: layout(computeColumnsLayout)
    /** minimum width for each root item.
     * min width is only defined for outermost(topmost) items */
    public minWidth(itemUiLevel: number) {
        return itemUiLevel == 0
            ? this.spec.minWidth.outer
            : this.spec.minWidth.default;
    }
    // used by: item(positionSelfAndDescendants, computeSizeSelfAndDescendants), layout(computeColumnsLayout)
    public marginLeft(itemUiLevel: number) {
        //items with uiLevel 0 are the column's items
        return itemUiLevel == 0
            ? this.spec.marginLeft.outer
            : this.spec.marginLeft.default;
    }
    // used by: item(computeSizeSelfAndDescendants)
    public marginRight(itemUiLevel: number) {
        return itemUiLevel == 0
            ? this.spec.marginRight.outer
            : this.spec.marginRight.default;
    }
    // used by: item(computeSizeSelfAndDescendants)
    public marginAfterLastChild() {
        return this.spec.marginAfterLastChild.default;
    }

    // used by: item(positionSelfAndDescendants, computeSizeSelfAndDescendants), layout(computeInitialPositions)
    /** note: argument is the children's uiLevel, not the item's one */
    public marginBetweenChildren(childrenUiLevel: number) {
        //children with uiLevel 0 are the column's items
        return childrenUiLevel == 0
            ? this.spec.marginBetweenChildren.outer
            : this.spec.marginBetweenChildren.default;
    }

    // used by: item(init:headLeft), renderer(redrawHeight)
    public itemLeftBorderWidth(itemUiLevel: number) {
        return itemUiLevel == 0
            ? this.spec.itemLeftBorderWidth.outer
            : this.spec.itemLeftBorderWidth.default;
    }

    // used by: item (init:headLeft, computeSizeSelfAndDescendants)
    public itemLeftPadding(itemUiLevel: number) {
        return itemUiLevel == 0
            ? this.spec.itemLeftPadding.outer
            : this.spec.itemLeftPadding.default;
    }
}
export interface ILineageItemLayoutSpec {
    minHeight: IOuterOrDefault;
    minWidth: IOuterOrDefault;
    marginLeft: IOuterOrDefault;
    marginRight: IOuterOrDefault;
    marginAfterLastChild: IOuterOrDefault;
    marginBetweenChildren: IOuterOrDefault;
    itemText: IItemTextSpec;
    itemLeftBorderWidth: IOuterOrDefault;
    itemLeftPadding: IOuterOrDefault;
    itemHeadMargin: number;
    hoverIcon: IIconSpec;
    goldenItemIcon: IIconSpec;
    hierarchicalEllipsis: IHEllipsisSpec;
}
interface IOuterOrDefault {
    readonly outer?: number;
    readonly default: number;
}
interface IIconSpec {
    readonly width: number;
    readonly margin: number;
}
interface IHEllipsisSpec {
    readonly height: number;
    readonly marginWidth: number;
    readonly textLeft: number;
    readonly cornerRadius: number;
}
interface IItemTextSpec {
    /** must match css .item-name.translate.x */
    readonly left: number;
}

export const svgLineageItemLayout: ILineageItemLayoutSpec = {
    minHeight: { outer: 30, default: 30 },
    itemHeadMargin: 3,
    itemText: { left: 50 },
    itemLeftPadding: { outer: 0, default: 5 },
    itemLeftBorderWidth: { outer: 10, default: 0 },
    /** outer is for column's items */
    marginBetweenChildren: { outer: 20, default: 0 },
    marginAfterLastChild: { default: 0 },
    /** marginRight.outer must equal marginLeft.outer */
    marginRight: { outer: 20, default: 0 },
    /** outer is for column's items */
    marginLeft: { outer: 20, default: 10 },
    /** min width is only defined for outermost(topmost) items */
    minWidth: { outer: 300, default: undefined },
    hierarchicalEllipsis: {
        height: 22,
        marginWidth: 10,
        textLeft: 20,
        cornerRadius: 3,
    },
    hoverIcon: { width: 24, margin: 0 },
    goldenItemIcon: { width: 18, margin: 5 },
};

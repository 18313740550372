import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DxyBaseModalComponent } from '@datagalaxy/ui/dialog';
import {
    ILocalSynonymModalResolve,
    ILocalSynonymModalResult,
} from './local-synonym-modal.types';

@Component({
    selector: 'dxy-local-synonym-modal',
    templateUrl: './dxy-local-synonym-modal.component.html',
})
export class DxyLocalSynonymModalComponent
    extends DxyBaseModalComponent<
        ILocalSynonymModalResolve,
        ILocalSynonymModalResult
    >
    implements OnInit
{
    public override result: ILocalSynonymModalResult;
    public canCreate = false;

    public get actionBtnLblKey() {
        return `UI.Dialog.NewItem.Project.${
            this.data.isCreation ? 'ttCreateButton' : 'ttUpdateButton'
        }`;
    }

    constructor(
        dialogRef: MatDialogRef<
            DxyLocalSynonymModalComponent,
            ILocalSynonymModalResult
        >,
        @Inject(MAT_DIALOG_DATA) data: ILocalSynonymModalResolve
    ) {
        super(dialogRef, data);
    }

    ngOnInit() {
        this.result = {
            displayName: this.data.displayName ?? '',
            description: this.data.description ?? '',
        };
    }

    public updateCanCreate() {
        const {
            isCreation,
            displayName: oldName,
            description: oldDesc,
        } = this.data;
        const { displayName: newName, description: newDesc } = this.result;
        this.canCreate =
            (isCreation && !!newName) ||
            (!isCreation &&
                !!newName &&
                (newName != oldName || newDesc != oldDesc));
    }
}

import { IOmniGridColumnDef } from '../OmniGridTypes';

export class OmniGridColumnInfo {
    colDef: IOmniGridColumnDef;
    order?: number;
    isDefaultSort?: boolean;

    get visible() {
        return !this.colDef.hide;
    }
    set visible(value: boolean) {
        this.colDef.hide = !value;
    }
    get name() {
        return this.colDef.colId;
    }
    get displayName() {
        return this.colDef.headerName;
    }
    set displayName(displayName: string) {
        this.colDef.headerName = displayName;
    }
    get isSystem() {
        return false;
    }

    constructor(colDef: IOmniGridColumnDef) {
        this.colDef = colDef;
    }
}

import {
    ChangeDetectionStrategy,
    Component,
    OnChanges,
    OnInit,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { BaseAttributeFilter } from '../base-attribute-filter.component';
import { AttributeBooleanFilterModel } from './AttributeBooleanFilterModel';
import {
    IBooleanFilterData,
    DxyBooleanFilterComponent,
} from '@datagalaxy/core-ui/filters';
import { AttributeFilterService } from '../attribute-filter.service';
import { AttributeFilterAction } from '../attribute-filter.types';
import { AttributeDataService } from '../../attribute-data.service';

/**
 * ## Role
 * Display a boolean attribute filter
 */
@Component({
    selector: 'app-attribute-boolean-filter',
    templateUrl: './attribute-boolean-filter.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttributeBooleanFilterComponent
    extends BaseAttributeFilter<AttributeBooleanFilterModel>
    implements OnInit, OnChanges
{
    @ViewChild(DxyBooleanFilterComponent)
    filterComponent: DxyBooleanFilterComponent;

    public filter: IBooleanFilterData;

    protected get iconClass() {
        return this.attributeDataService.getAttributeGlyphClass(
            this.attributeMeta
        );
    }

    constructor(
        attributeFilterService: AttributeFilterService,
        private attributeDataService: AttributeDataService
    ) {
        super(attributeFilterService);
    }

    ngOnInit() {
        super.ngOnInit();
        this.init();
    }

    ngOnChanges(changes: SimpleChanges) {
        super.onChange(changes, 'filterItemData', () => this.init());
    }

    public onFilterChange() {
        this.filterItemData.value = this.filter.value;
        this.onAction.emit(AttributeFilterAction.itemChanged);
    }

    private init() {
        this.filter = {
            value: this.filterItemData.value,
        };
    }
}

const sharedInstanceOrigins = [
    'https://dkw.datagalaxy.com',
    'https://app.datagalaxy.com',
];

const sharedInstanceApiUrl = 'https://api.datagalaxy.com/';
export const v3ApiUrl = getV3ApiUrlFromDomain(window.location.origin);

export function getV3ApiUrlFromDomain(origin: string): string {
    try {
        if (sharedInstanceOrigins.includes(origin)) {
            return sharedInstanceApiUrl;
        }
        if (origin.includes('.app')) {
            const url = new URL(origin.replace('.app', '.api'));
            return url.toString();
        }
        const url = new URL(origin);

        const parts = url.hostname.split('.');

        if (parts.length > 2 && !url.hostname.startsWith('localhost')) {
            const domain = parts.slice(-2).join('.');
            const subdomains = parts.slice(0, -2).join('.');

            const newHostname = `${subdomains}.api.${domain}`;

            url.hostname = newHostname;
        }

        return url.toString();
    } catch (error) {
        throw new Error(`Invalid URL: ${error}`);
    }
}

import { BaseService } from '@datagalaxy/core-ui';
import { UAParser } from 'ua-parser-js';
import { Injectable, Injector } from '@angular/core';
import { DxySupportTicketModalComponent } from './dxy-support-ticket-modal/dxy-support-ticket-modal.component';
import { PendoService } from '../../../services/pendo.service';
import { DxyModalService } from '../../dialogs/DxyModalService';
import { ClientContextUtil } from '../../util/ClientContextUtil';
import { AppDataService } from '../../../services/app-data.service';
import { SecurityService } from '../../../services/security.service';
import { ClientContext } from '@datagalaxy/webclient/monitoring/data-access';
import { AppConfigService } from '@datagalaxy/webclient/config';

const feedbackGuideId =
    'Qm8SjN-RFhPZk4KE-EDmKCcG62E@hhTwjvJZweyYQ9YufjHnOb1OjPw';

/** Service for User Feedback */

@Injectable({ providedIn: 'root' })
export class SupportTicketService extends BaseService {
    private uaParser = new UAParser();

    public get isFeedbackEnabled() {
        return (
            !this.appConfigService.DISABLE_FEEDBACK &&
            this.securityService.isClientFeedbackEnabled()
        );
    }

    constructor(
        private injector: Injector,
        private window: Window,
        private dxyModalService: DxyModalService,
        private appConfigService: AppConfigService,
        private securityService: SecurityService,
        private appDataService: AppDataService,
        private pendoService: PendoService
    ) {
        super();
    }

    public async openSupportTicketModal() {
        await this.dxyModalService.open<
            DxySupportTicketModalComponent,
            void,
            void
        >({
            componentType: DxySupportTicketModalComponent,
        });
    }

    public openFeedbackGuide() {
        this.pendoService.showGuideById(feedbackGuideId);
    }

    public addDetailsToDesc(description: string): string {
        const formatLine = (key: string, obj: ClientContext | IUserDetails) =>
            `<b>${key}:</b> ${obj[key]}</br>`;

        const obj2MultiLine = (object: ClientContext | IUserDetails) =>
            Object.keys(object).reduce(
                (acc, cur) => acc + formatLine(cur, object),
                ''
            );

        const browser = this.uaParser.getBrowser();
        const appInfo = this.appConfigService.getAppVersionInfo();
        const userContext = obj2MultiLine(
            ClientContextUtil.getClientContext(this.injector) ?? ({} as any)
        );
        const userDetails = obj2MultiLine({
            url: this.window.location,
            os: navigator.platform,
            browser: `${browser.name} - ${browser.version}`,
            cookies: navigator.cookieEnabled,
            email: this.appDataService.currentUserEmail,
            fullname: this.appDataService.currentUserFullName,
            browserLanguage: navigator.language,
            userLanguage: this.appDataService.currentUserLanguageCode,
            ticketCreationDate: new Date(),
            clientId: this.appDataService.clientId,
            clientName: this.appDataService.clientName,
            env: appInfo.versionName,
            platformVersion: appInfo.platformVersionNumber,
            version: appInfo.versionNumber,
        });

        return `${description}<p><b>=== User Details ===</b></p>${userDetails}${userContext}`;
    }
}

interface IUserDetails {
    url: Location;
    os: string;
    browser: string;
    cookies: boolean;
    email: string;
    fullname: string;
    browserLanguage: string;
    userLanguage: string;
    ticketCreationDate: Date;
    clientId: string;
    clientName: string;
    env: string;
    platformVersion: string;
    version: string;
}

import { ChangeDetectorRef, Directive, ViewChild, inject } from '@angular/core';
import { Subject } from 'rxjs';
import {
    ICustomTitleWidgetComponent,
    IEditionWidgetComponnent,
    WidgetTitleConfiguration,
} from './widget-interfaces';
import { WidgetTitleEditorComponent } from './widget-title-editor/widget-title-editor.component';
import { DxyBaseComponent } from '@datagalaxy/ui/core';

export abstract class BaseEditionWidgetComponent
    extends DxyBaseComponent
    implements IEditionWidgetComponnent
{
    public editionEnabled = false;

    protected cd = inject(ChangeDetectorRef);

    public toggleEditionMode(enabled: boolean) {
        this.editionEnabled = enabled;
        this.cd.detectChanges();
    }
}

@Directive()
export abstract class BaseCustomTitleWidgetComponent<
        TWidgetConfiguration extends WidgetTitleConfiguration = WidgetTitleConfiguration
    >
    extends BaseEditionWidgetComponent
    implements ICustomTitleWidgetComponent
{
    protected configuration = new Subject<TWidgetConfiguration>();
    public configuration$ = this.configuration.asObservable();

    @ViewChild(WidgetTitleEditorComponent)
    private widgetTitleEditor?: WidgetTitleEditorComponent;

    protected widgetConfiguration?: TWidgetConfiguration;

    public applyConfiguration(configuration?: TWidgetConfiguration) {
        this.widgetConfiguration = configuration;
        this.cd.detectChanges();
    }

    public onCustomTitleChange(customTitle: string) {
        this.widgetConfiguration = {
            ...this.widgetConfiguration,
            customTitle,
        };
        this.configuration.next(this.widgetConfiguration);
        this.cd.detectChanges();
    }

    public focusWidgetTitleEditor() {
        this.widgetTitleEditor.focusTitleInput();
    }
}

import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
} from '@angular/core';
import {
    IOptionAdapter,
    IValueListFilterConfig,
    IValueListFilterData,
} from '@datagalaxy/core-ui';
import { CollectionsHelper } from '@datagalaxy/core-util';
import {
    DgModuleDefinition,
    DgModuleName,
    allModules,
    getModuleDefinition,
} from '@datagalaxy/shared/dg-module/domain';
import { DxyBaseComponent } from '@datagalaxy/ui/core';

/**
 * ## Role Display a filter button to filter by module
 */
@Component({
    selector: 'dxy-module-filter-button',
    templateUrl: './module-filter-button.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModuleFilterButtonComponent
    extends DxyBaseComponent
    implements OnChanges
{
    @Input() moduleName?: DgModuleName = 'Glossary';
    @Input() availableModules?: DgModuleName[];
    @Input() selectedModules?: DgModuleName[];
    @Input() removable = false;
    @Input() readonly = false;
    @Input() withDropdown = false;
    @Output() buttonClick = new EventEmitter();
    @Output() removeClick = new EventEmitter();
    @Output() selectedModulesChange = new EventEmitter<DgModuleName[]>();

    protected filter: IValueListFilterData<DgModuleDefinition> = {
        values: [],
    };
    protected filterConfig: IValueListFilterConfig = {
        hasSearch: true,
        hasSelectAll: true,
    };
    protected filterItems: DgModuleDefinition[] = [];

    protected filterAdapter: IOptionAdapter<DgModuleDefinition> = {
        getId: (m) => m?.name,
        getTextKey: (m) => m?.translateKey,
        getGlyphClass: (m) => m?.coloredGlyphClass,
    };

    protected get module() {
        return allModules.find((m) => m.name == this.moduleName);
    }

    constructor() {
        super();
    }

    ngOnChanges(changes: SimpleChanges) {
        this.onChange(
            changes,
            'availableModules',
            (currentValue: DgModuleName[], previousValue: DgModuleName[]) => {
                if (
                    CollectionsHelper.contentEquals(previousValue, currentValue)
                ) {
                    return;
                }
                this.updateFilterItems();
            },
            true
        );
        this.onChange(
            changes,
            'selectedModules',
            () => {
                this.updateFilter();
            },
            true
        );
    }

    protected onFilterChange() {
        const moduleNames = this.filter.values?.map((v) => v.name) ?? [];
        this.selectedModulesChange.emit(moduleNames);
    }
    protected onRemove() {
        this.removeClick.emit();
    }

    private updateFilterItems() {
        this.filterItems =
            this.availableModules?.map((m) => getModuleDefinition(m)) ?? [];
    }

    private updateFilter() {
        const values = this.filterItems.filter((f) =>
            this.selectedModules?.some((m) => m == f.name)
        );
        this.filter.values = values;
    }
}

import { CollectionsHelper } from '@datagalaxy/core-util';
import { LineageGraphParams } from '../../impactAnalysis/lineage-graph/data/LineageGraphParams';
import {
    IEntityIdentifier,
    ObjectLinkType,
    ServerType,
} from '@datagalaxy/dg-object-model';
import { HierarchyDataDescriptor } from '@datagalaxy/dg-object-model';
import { HddUtil } from '../../shared/util/HddUtil';
import {
    GetDataLineageResult,
    LineageOrientation,
} from '@datagalaxy/webclient/explorer/data-access';
import {
    DataLineageDataLink,
    DataLineageGenerationData,
    DataLineageItem,
    LineageLinkOrientationType,
} from '@datagalaxy/webclient/explorer/data-access';
import { getLocalId } from '@datagalaxy/webclient/utils';
import { GetLinkedDataResult } from '@datagalaxy/webclient/entity/data-access';
import {
    EntityItem,
    EntityLinkItem,
    LinkedDataGroup,
} from '@datagalaxy/webclient/entity/domain';

export class ImplementationLineageDataSource extends LineageGraphParams {
    //#region static

    private static makeDataLineageResult(
        entityHdd: HierarchyDataDescriptor,
        linkedDataGroups: LinkedDataGroup[]
    ) {
        const getHds = (linkType: ObjectLinkType) =>
            linkedDataGroups
                .find((g) => g.UniversalObjectLinkType == linkType)
                ?.Items?.map((it) => it.LinkedData) ?? [];
        const implementations = getHds(ObjectLinkType.IsImplementedBy);
        const recordingSystems = getHds(ObjectLinkType.HasForRecordingSystem);
        const allHds = [...implementations, ...recordingSystems];

        // 2 columns, containing the graph's root objects' ids :
        const generations = [
            // left column: only the entity
            new DataLineageGenerationData(0, [entityHdd.DataLocalId]),
            // right column: the databases (distinct list of recording systems and implementing entities' database)
            new DataLineageGenerationData(
                1,
                CollectionsHelper.distinct(
                    allHds
                        .map((hd) =>
                            HddUtil.getParentDataDescriptorByType(hd, [
                                ServerType.Model,
                            ])
                        )
                        .filter((o) => o)
                        .map((hdd) => hdd.DataLocalId)
                )
            ),
        ];

        const makeItem = (hdd: HierarchyDataDescriptor, isRoot: boolean) => {
            const dli = new DataLineageItem();
            dli.DataLocalId = hdd.DataLocalId;
            dli.DisplayName = hdd.DisplayName;
            dli.TechnicalName = hdd.TechnicalName;
            dli.EntityType = hdd.EntityType;
            dli.ParentLocalId = isRoot ? undefined : hdd.ParentList[0];
            return dli;
        };
        const items = new Map<string, DataLineageItem>();
        items.set(entityHdd.DataLocalId, makeItem(entityHdd, true));
        allHds.forEach((hd) => {
            [
                hd.Data,
                ...HddUtil.getHierarchyDataDescriptorList(hd).filter(
                    (o) => o.EntityType
                ),
            ].forEach((hdd) => {
                if (!hdd?.DataLocalId || items.has(hdd.DataLocalId)) {
                    return;
                }
                items.set(
                    hdd.DataLocalId,
                    makeItem(hdd, hdd.DataServerType == ServerType.Model)
                );
            });
        });

        const typedLinkItems = CollectionsHelper.flattenGroups(
            linkedDataGroups,
            (group) =>
                group.Items.map((i) => ({
                    type: group.UniversalObjectLinkType,
                    item: i,
                }))
        );
        const linkItemsMap = new Map<string, EntityLinkItem>(
            typedLinkItems.map((linkItem) => {
                const linkSourceId =
                    getLocalId(
                        linkItem.item.LinkEntityData.Source.DataReferenceId
                    ) ?? '';
                const linkTargetId =
                    getLocalId(
                        linkItem.item.LinkEntityData.Target.DataReferenceId
                    ) ?? '';
                return [
                    `${linkSourceId}-${linkTargetId}-${linkItem.type}`,
                    linkItem.item.LinkEntityData,
                ];
            })
        );

        const makeLink = (hdd: HierarchyDataDescriptor, lt: ObjectLinkType) => {
            const link = new DataLineageDataLink(
                lt,
                undefined,
                undefined,
                undefined
            );
            link.SourceId = entityHdd.DataLocalId;
            link.TargetId = hdd.DataLocalId;
            const baseLinkData = linkItemsMap.get(
                `${link.SourceId}-${link.TargetId}-${lt}`
            );
            link.IsGoldenLink = baseLinkData.IsGoldenLink;
            link.EntityLinkReferenceId = baseLinkData.DataReferenceId;
            link.UniversalObjectLinkType = lt;
            link.OrientationType = LineageLinkOrientationType.Unoriented;
            return link;
        };

        // The order is important: Since the final DataLineage logic will only keep one link per src->dst,
        // we need to make sure the IsImplementedBy is set the last, for the Golden Link information to be used properly
        const links = [
            ...recordingSystems.map((hd) =>
                makeLink(hd.Data, ObjectLinkType.HasForRecordingSystem)
            ),
            ...implementations.map((hd) =>
                makeLink(hd.Data, ObjectLinkType.IsImplementedBy)
            ),
        ];

        const result = new GetDataLineageResult();
        result.SourceDataLocalId = entityHdd.DataLocalId;
        result.SourceDataTypeName = entityHdd.DataTypeName;
        result.generations = generations;
        result.Items = Array.from(items.values());
        result.DataLinks = links;
        result.IsSuccess = true;

        //console.log('makeDataLineageResult', { implementations, recordingSystems, result })

        return result;
    }

    //#endregion

    public linkedData: GetLinkedDataResult;

    constructor(
        private getLinkedData: (
            identifier: IEntityIdentifier,
            ...linkTypes: ObjectLinkType[]
        ) => Promise<GetLinkedDataResult>,
        public readonly entityItem: EntityItem,
        private log?: (...args: any[]) => void
    ) {
        super(
            entityItem,
            LineageOrientation.Both,
            (forceReload) => this.getDataInternal(forceReload),
            true
        );
    }

    private async getDataInternal(forceReload: boolean) {
        if (forceReload) {
            this.linkedData = undefined;
        }
        this.linkedData ??= await this.getLinkedData(
            this.entityItem,
            ObjectLinkType.IsImplementedBy,
            ObjectLinkType.HasForRecordingSystem
        );
        const result = ImplementationLineageDataSource.makeDataLineageResult(
            this.entityItem.HddData.Data,
            this.linkedData.Groups
        );
        this.log &&
            this.log(
                'ImplementationLineageDataSource-getDataInternal',
                forceReload,
                this.linkedData,
                result
            );
        return result;
    }
}

import { deserializeAs } from 'cerialize';
import { UserInsightItem } from './user-insights';
import { EntityInsightItem } from './entity-insight-item';

export class GetInsightsResult {
    @deserializeAs(UserInsightItem) public UserItems?: UserInsightItem[];
    @deserializeAs(EntityInsightItem) public EntityItems?: EntityInsightItem[];

    propagateEntityVersionId() {
        if (this.EntityItems) {
            this.EntityItems.forEach((c) =>
                c.HddData?.setVersionId(c.HddData.VersionId)
            );
        }
    }
}

import { TransitionService } from '@uirouter/core';
import { Ng2StateDeclaration } from '@uirouter/angular';
import { CommonStateDefinitions as CSD } from '../shared/util/states/CommonStateDefinitions';
import { Constants } from '../shared/util/Constants';
import { StateName } from '@datagalaxy/webclient/routing';
import { EntityType, ServerType } from '@datagalaxy/dg-object-model';
import Nav = Constants.Nav;
import UrlWord = Nav.UrlWord;
import { DgModule } from '@datagalaxy/shared/dg-module/domain';

export function getStatesForUsages(): Ng2StateDeclaration[] {
    return [
        // common routes
        ...CSD.ModuleStates(DgModule.Usage),

        // specific routes

        CSD.LinkedObjects(StateName.SoftwareLinkedObjects),
        CSD.EntityImpactAnalysis(StateName.SoftwareImpactAnalysis),
        CSD.EntityDiagrams(StateName.SoftwareDiagramsTab, {
            persistenceGridId: 'diagram-list-software',
        }),
        CSD.DataMap(StateName.SoftwareDataMap),
        CSD.ModuleEntityTabGrid(
            StateName.SoftwareFields,
            `/${UrlWord.usageFields}`,
            ServerType.SoftwareElement,
            ['Description', 'Type'],
            ServerType.SoftwareElement,
            false,
            true,
            true,
            [EntityType.UsageField],
            false
        ),
        CSD.ModuleEntityTabGrid(
            StateName.SoftwareComponents,
            `/${UrlWord.usageComponents}`,
            ServerType.SoftwareElement,
            ['DisplayName', 'Description', 'ComponentType'],
            ServerType.SoftwareElement,
            false,
            true,
            true,
            [EntityType.UsageComponent],
            false
        ),
    ];
}

export function softwareRouterInit(transitionService: TransitionService) {
    CSD.dgModuleRouterInit(StateName.SoftwareDashboard, transitionService);
}

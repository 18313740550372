import { NgModule } from '@angular/core';
import { DxyActivityLogItemComponent } from './dxy-activity-log-item/dxy-activity-log-item.component';
import { DxyActivityLogContainerComponent } from './dxy-activity-log-container/dxy-activity-log-container.component';
import { DxyActivityLogElementComponent } from './dxy-activity-log-element/dxy-activity-log-element.component';
import { EntityCardModule } from '../shared/entityCard/entity-card.module';
import { DxyNavigationModule } from '../navigation/DxyNavigationModule';
import { FieldsModule } from '../shared/fields/fields.module';
import { SharedModule } from '../shared/shared.module';
import { DxySharedUiModule } from '../shared/shared-ui/DxySharedUiModule';
import { DxyFiltersModule } from '@datagalaxy/core-ui';

@NgModule({
    imports: [
        SharedModule,
        FieldsModule,
        DxyNavigationModule,
        EntityCardModule,
        DxySharedUiModule,
        DxyFiltersModule,
    ],
    declarations: [
        DxyActivityLogItemComponent,
        DxyActivityLogElementComponent,
        DxyActivityLogContainerComponent,
    ],
    exports: [
        DxyActivityLogItemComponent, // used by notifications
        DxyActivityLogElementComponent,
        DxyActivityLogContainerComponent,
    ],
})
export class DxyActivityLogModule {}

import { Dashboard } from '@datagalaxy/webclient/dashboard/ui';

export function deserializeDashboard(serializedDashboard: string) {
    try {
        const dashboard = JSON.parse(serializedDashboard) as Dashboard;
        if (!Array.isArray(dashboard?.sections)) {
            return undefined;
        }
        return dashboard;
    } catch {
        return undefined;
    }
}

<span class="title" translate
    >UI.ImpactAnalysis.lineage.pathTracker.pathTracker</span
>
<div
    class="path-tracker red"
    [class.active]="isRedActive$ | async"
    [matTooltip]="redTooltip$ | async | translate"
    (click)="togglePathTracker(LineageTrackerColor.Red)"
></div>
<div
    class="path-tracker blue"
    [class.active]="isBlueActive$ | async"
    [matTooltip]="blueTooltip$ | async | translate"
    (click)="togglePathTracker(LineageTrackerColor.Blue)"
></div>

import { DxyBaseModalComponent } from '@datagalaxy/ui/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { AppConfigService } from '@datagalaxy/webclient/config';

@Component({
    selector: 'dxy-about-info-modal',
    templateUrl: './dxy-about-info-modal.component.html',
    styleUrls: ['./dxy-about-info-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DxyAboutInfoModalComponent
    extends DxyBaseModalComponent<void, void>
    implements OnInit
{
    public platformVersion: string;
    public currentReleaseNoteHref: string;
    public technicalInfo: string;
    public showReleaseNote: boolean;
    public currentYear: string;

    constructor(
        public dialogRef: MatDialogRef<DxyAboutInfoModalComponent>,
        private appConfigService: AppConfigService
    ) {
        super(dialogRef);
    }

    ngOnInit() {
        this.showReleaseNote = !this.appConfigService.DISABLE_RELEASE_NOTE;
        this.currentReleaseNoteHref = this.appConfigService.releaseNoteUrl;
        this.platformVersion = this.appConfigService.PLATFORM_VERSION_NUMBER;
        this.technicalInfo = this.appConfigService.APP_VERSION_NUMBER;
        this.currentYear = `${new Date().getFullYear()}`;
    }
}

import {
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { EditCellType, IEditGridData, IValueChange } from '@datagalaxy/core-ui';
import { DataTypeMappingGridItem } from '../../grid-items/DataTypeMappingGridItem';
import { ModelerService } from '../../services/modeler.service';
import { ModelSettings } from '@datagalaxy/webclient/modeler/data-access';
import { DxyBaseComponent } from '@datagalaxy/ui/core';

@Component({
    selector: 'dxy-data-type-mapping-grid',
    templateUrl: 'dxy-data-type-mapping-grid.component.html',
})
export class DxyDataTypeMappingGridComponent
    extends DxyBaseComponent
    implements OnChanges, OnInit
{
    @Input() modelSettings: ModelSettings;

    public gridData: IEditGridData<DataTypeMappingGridItem>;

    private readonly dataSource =
        new MatTableDataSource<DataTypeMappingGridItem>();

    constructor(private modelerService: ModelerService) {
        super();
    }

    ngOnChanges(changes: SimpleChanges) {
        super.onChange(changes, 'modelSettings', () =>
            this.updateRows('changes-settings')
        );
    }

    ngOnInit() {
        this.updateRows('ngOnInit');
        this.gridData = {
            options: {
                rowHeight: 27,
                headerHeight: 27,
            },
            dataSource: this.dataSource,
            colDefs: [
                {
                    field: 'dataTypeName',
                    headerKey:
                        'UI.DataType.DataTypeMappingGrid.lblDataTypeName',
                    type: EditCellType.text,
                    readonly: true,
                },
                {
                    field: 'dataTypeMappingName',
                    headerKey: 'UI.DataType.DataTypeMappingGrid.lblMappingName',
                    type: EditCellType.text,
                    isReadOnly: (it) => it.isReadOnly,
                    debounceTimeMs: 444,
                },
            ],
        };

        super.registerSubscription(
            this.modelerService.subscribeDataTypeEvents({
                onAdd: () => this.updateRows('onAdd-type'),
                onDelete: () => this.updateRows('onDelete-type'),
            })
        );
        super.registerSubscription(
            this.modelerService.subscribeDataTypeMappingEvents({
                onChange: () => this.updateRows('onChange-mapping'),
            })
        );
    }

    public async onGridValueChanged(
        event: IValueChange<DataTypeMappingGridItem>
    ) {
        const item = event.item.dataTypeMappingItem;
        const propertyName = this.getUpdatedProperty(event.colDef.field);
        if (!propertyName) {
            return;
        }
        await this.modelerService.updateDataTypeMappingItem(
            item,
            propertyName,
            event.currentValue
        );
    }
    private getUpdatedProperty(field: string) {
        switch (field) {
            case 'dataTypeMappingName':
                return 'DisplayName';
        }
    }

    private updateRows(origin?: string) {
        this.log('updateRows', origin);
        this.dataSource.data = this.modelerService.getDataTypeMappingGridData(
            this.modelSettings
        );
    }
}

import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ViewChild,
} from '@angular/core';
import {
    DxyTabsHeaderComponent,
    IOptionAdapter,
    ITabItem,
    withLoading,
} from '@datagalaxy/core-ui';
import { FilteredViewService } from '../../../shared/filter/services/filteredView.service';
import { EntityUiService } from '../../../shared/entity/services/entity-ui.service';
import { SearchService } from '../../../search/search.service';
import { GlyphUtil } from '../../../shared/util/GlyphUtil';
import {
    IValueListFilterConfig,
    IValueListFilterData,
} from '@datagalaxy/core-ui/filters';
import { NavigationService } from '../../../services/navigation.service';
import { CollectionsHelper } from '@datagalaxy/core-util';
import { FilteredViewsUtil } from '@datagalaxy/webclient/filter/data-access';
import {
    BaseCustomTitleWidgetComponent,
    ICustomTitleWidgetComponent,
    registerWidget,
} from '@datagalaxy/webclient/dashboard/ui';
import { CurrentSpaceService } from '../../../services/currentSpace.service';
import { SpaceFilteredViewConfiguration } from './space-filtered-views-configuration';
import { EntityType } from '@datagalaxy/dg-object-model';
import {
    FilteredViewDto,
    FilterModuleName,
} from '@datagalaxy/webclient/filter/domain';
import { DgZone } from '@datagalaxy/webclient/domain';

/**
 * ## Role
 * Display space's filtered views grouped in public/private tabs
 *
 * ## Features
 * - Click on filtered view redirect on the filtered view
 * - A filter allows to filter filtered views by moduleName
 */
@registerWidget({
    name: 'space-filtered-views-widget',
    titleKey: 'UI.SpaceHome.Widgets.SpaceFilteredViews.title',
    previewImgUrl: '/images/space-widgets/space-widget-filtered-views.svg',
})
@Component({
    selector: 'app-space-filtered-views-widget',
    templateUrl: './space-filtered-views-widget.component.html',
    styleUrls: [
        '../space-widget.component.scss',
        './space-filtered-views-widget.component.scss',
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpaceFilteredViewsWidgetComponent
    extends BaseCustomTitleWidgetComponent<SpaceFilteredViewConfiguration>
    implements AfterViewInit, ICustomTitleWidgetComponent
{
    @ViewChild(DxyTabsHeaderComponent) tabsHeader: DxyTabsHeaderComponent;

    protected get space() {
        return this.currentSpaceService.currentSpace;
    }
    protected get placeholderSrc() {
        return `/images/space-widgets/${this.selectedTab.tabId}-space-filtered-views-placeholder.svg`;
    }

    protected tabs: ITabItem<FilteredViewDto[]>[] = [
        {
            tabId: 'public',
            tabTranslateKey: 'UI.Filter.sharedFilters',
            showEmptyDataCount: true,
        },
        {
            tabId: 'private',
            tabTranslateKey: 'UI.Filter.myFilters',
            showEmptyDataCount: true,
        },
    ];
    protected readonly EntityType = EntityType;

    //#region module filter

    protected modulesFilter: IValueListFilterData<FilterModuleName> = {};
    protected moduleFilterConfig: IValueListFilterConfig = {
        sortOptions: (moduleNames) =>
            CollectionsHelper.orderBy(moduleNames, (value) =>
                FilteredViewsUtil.moduleNameOrderIndex(value)
            ),
    };
    protected moduleFilterOptions =
        CollectionsHelper.getEnumValues<FilterModuleName>(FilterModuleName);
    protected moduleFilterAdapter: IOptionAdapter<FilterModuleName> = {
        getTextKey: (moduleName) =>
            `DgServerTypes.DataGalaxyModule.${FilterModuleName[moduleName]}`,
        getGlyphClass: (moduleName) =>
            moduleName === FilterModuleName.MainSearch
                ? 'glyph-filter-empty'
                : GlyphUtil.getModuleColoredGlyphClass(
                      FilteredViewsUtil.dgModuleFromModuleName(moduleName)
                  ),
    };
    //#endregion module filter

    protected get filteredViews() {
        return this.selectedTab.data;
    }
    protected get showPlaceholder() {
        return !this.loadingValue && !this.filteredViews?.length;
    }
    protected get placeholderKey() {
        const suffix =
            this.selectedTab === this.publicTab
                ? 'PlaceholderPublic'
                : 'PlaceholderPrivate';
        return `UI.SpaceHome.Widgets.SpaceFilteredViews.${suffix}`;
    }

    protected selectedTab: ITabItem<FilteredViewDto[]> = this.tabs[0];
    private _filteredViews?: FilteredViewDto[];

    private get publicTab() {
        return this.tabs[0];
    }
    private set publicViews(value: FilteredViewDto[]) {
        this.publicTab.data = value;
        this.tabsHeader.refresh();
    }

    private get privateTab() {
        return this.tabs[1];
    }
    private set privateViews(value: FilteredViewDto[]) {
        this.privateTab.data = value;
        this.tabsHeader.refresh();
    }

    constructor(
        private filteredViewService: FilteredViewService,
        private entityUiService: EntityUiService,
        private searchService: SearchService,
        private navigationService: NavigationService,
        private currentSpaceService: CurrentSpaceService
    ) {
        super();
    }

    ngAfterViewInit() {
        this.initAsync();
    }

    public async applyConfiguration(
        configuration: SpaceFilteredViewConfiguration
    ) {
        super.applyConfiguration(configuration);
        this.modulesFilter = {
            ...this.modulesFilter,
            values: configuration?.modules ?? [],
        };
        if (!this.loadingValue) {
            await this.loadFilteredViews();
        }
        this.setPrivateAndPublicViews();
        await this.updateFilteredViewsCount();
        this.cd.detectChanges();
    }

    protected onModuleFilterChange() {
        this.setPrivateAndPublicViews();
        if (this.editionEnabled) {
            this.widgetConfiguration = {
                ...this.widgetConfiguration,
                modules: this.modulesFilter.values,
            };
            this.configuration.next(this.widgetConfiguration);
        }
    }

    protected async onFilteredViewClick(fv: FilteredViewDto) {
        await this.navigationService.goToFilteredView(fv, this.space);
    }

    protected hasCount(fv: FilteredViewDto) {
        return fv.entitiesCount != null;
    }

    protected fvClass(fv: FilteredViewDto) {
        if (fv.DgZone === DgZone.Search) {
            return 'glyph-filter-empty';
        }
        return GlyphUtil.getModuleColoredGlyphClass(
            FilteredViewsUtil.dgModuleFromModuleName(fv.ModuleName)
        );
    }

    protected getTooltipLabelKey(fv: FilteredViewDto) {
        const tooltip = 'UI.Global.filteredViewName.';
        if (fv.DgZone === DgZone.Search) {
            return `${tooltip}crossModule`;
        }
        return `${tooltip}${FilteredViewsUtil.getFilteredViewNameKeyFromModuleName(
            fv.ModuleName
        )}`;
    }

    protected async onTabChange(tab: ITabItem<FilteredViewDto[]>) {
        this.selectedTab = tab;

        if (tab === this.privateTab) {
            await this.updateFilteredViewsCount();
        }
    }

    private async initAsync() {
        if (!this.loadingValue) {
            await this.loadFilteredViews();
            await this.updateFilteredViewsCount();
        }
    }

    @withLoading()
    private async loadFilteredViews() {
        const res = await this.filteredViewService.getSpaceFilteredViews(
            this.space
        );
        this._filteredViews = CollectionsHelper.orderBy(res, [
            (fv) => FilteredViewsUtil.moduleNameOrderIndex(fv.ModuleName),
            (fv) => fv.DisplayName,
        ]);
        this.setPrivateAndPublicViews();
    }

    private setPrivateAndPublicViews() {
        const includedModules = this.modulesFilter.values;
        const views = includedModules?.length
            ? this._filteredViews?.filter((fv) =>
                  includedModules.includes(fv.ModuleName)
              )
            : this._filteredViews;
        this.publicViews = views?.filter((fv) => !fv.IsPrivate);
        this.privateViews = views?.filter((fv) => fv.IsPrivate);
        this.cd.detectChanges();
    }

    private async updateFilteredViewsCount() {
        if (!this.filteredViews) {
            return;
        }
        await Promise.all(
            this.filteredViews.map((fv) => this.updateEntitiesCount(fv))
        );
        this.cd.detectChanges();
    }

    private async updateEntitiesCount(fv: FilteredViewDto) {
        if (!fv) {
            return;
        }
        if (fv.entitiesCount) {
            return fv.entitiesCount;
        }

        let count: number;
        switch (fv.DgZone) {
            case DgZone.Module: {
                const dgModule = FilteredViewsUtil.dgModuleFromModuleName(
                    fv.ModuleName
                );
                count = await this.entityUiService.getModuleEntitiesCount(
                    dgModule,
                    this.space,
                    fv
                );
                break;
            }
            case DgZone.Search: {
                count =
                    await this.searchService.getFilteredViewSearchEntitiesCount(
                        fv
                    );
                break;
            }
            default: {
                count = null;
                break;
            }
        }
        fv.entitiesCount = count;
    }
}

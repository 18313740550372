import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Output,
} from '@angular/core';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import {
    IDropdownSection,
    IListOption,
    IValueListFilterConfig,
    IValueListFilterData,
} from '@datagalaxy/core-ui';
import { CollectionsHelper } from '@datagalaxy/core-util';
import { TranslateService } from '@ngx-translate/core';
import { ObjectLinkType } from '@datagalaxy/dg-object-model';
import { LinkSuggestionOrder } from '@datagalaxy/webclient/suggestion/domain';

/**
 * ## Role
 * Filter for suggestion links in the suggestion center panel
 */
@Component({
    selector: 'dxy-suggestion-link-filter',
    templateUrl: './suggestion-link-filter.component.html',
    styleUrls: ['./suggestion-link-filter.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SuggestionLinkFilterComponent extends DxyBaseComponent {
    @Output() readonly parameterChange =
        new EventEmitter<ISuggestionLinkSearchParameters>();

    protected typeFilter: IValueListFilterData<ObjectLinkType>;
    protected typeFilterOptions = [
        ObjectLinkType.IsImplementedBy,
        ObjectLinkType.IsUsedBy,
    ];
    protected typeFilterAdapter = {
        getTextKey: (objectLinkType: ObjectLinkType) =>
            `DgServerTypes.ObjectLinkType.${ObjectLinkType[objectLinkType]}`,
        getGlyphClass: () => 'glyph-diagram',
    };
    protected filterConfig: IValueListFilterConfig = {
        hasSearch: false,
    };

    protected get hasSelectedTypes() {
        return !!this.typeFilter?.values?.length;
    }

    protected get sortTitle(): string {
        const sortTitle = this.translate.instant(
            'UI.Suggestions.Panel.Sort.title'
        );
        const sortValue = this.translate.instant(
            `UI.Suggestions.Panel.Sort.${
                LinkSuggestionOrder[this.currentLinkSearchParameters.sort]
            }`
        );
        return `${sortTitle}${sortValue}`;
    }

    protected sortSections: IDropdownSection[] = [];

    private currentLinkSearchParameters: ISuggestionLinkSearchParameters = {
        sort: LinkSuggestionOrder.Date,
        linkType: null,
    };

    constructor(private translate: TranslateService) {
        super();
        this.init();
    }

    protected resetTypesFilter() {
        this.typeFilter = {
            ...this.typeFilter,
            values: [],
        };
        this.emitLinkTypeChange(null);
    }

    public onFilterTypeChange() {
        const selectedValue = this.typeFilter.values[0];
        this.emitLinkTypeChange(selectedValue);
    }

    private init() {
        this.initSortingSection();
    }

    private initSortingSection() {
        const sortingValues =
            CollectionsHelper.getEnumValues<LinkSuggestionOrder>(
                LinkSuggestionOrder
            ).map((value) => {
                return {
                    labelKey: `UI.Suggestions.Panel.Sort.${LinkSuggestionOrder[value]}`,
                    data: value,
                    callback: () => {
                        this.emitSortChangeChange(value);
                    },
                } as IListOption;
            });

        this.sortSections = [
            {
                filteredOptions: sortingValues,
            },
        ];
    }

    private emitSortChangeChange(value: LinkSuggestionOrder) {
        this.currentLinkSearchParameters.sort = value;
        this.emitParameterChange();
    }

    private emitLinkTypeChange(value: ObjectLinkType) {
        this.currentLinkSearchParameters.linkType = value;
        this.emitParameterChange();
    }

    private emitParameterChange() {
        this.parameterChange.emit(this.currentLinkSearchParameters);
    }
}

export interface ISuggestionLinkSearchParameters {
    sort: LinkSuggestionOrder;
    linkType?: ObjectLinkType;
}

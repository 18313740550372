import { DataStructure } from '@datagalaxy/webclient/connectivity/data-access';

export class ConnectionFields {
    public dataStructure = DataStructure.Tree;
    public instance: string;
    public server: string;
    public port: string;
    public user: string;
    public password: string;
    public storageAccount: string;
    public tenantId: string;
    public clientId: string;
    public clientSecret: string;
    public containerName: string;
    public path: string;
    public accountName: string;
    public schema: string;
    public warehouse: string;
    public serverInstance: string;
    public database: string;
    public maskList: string[];
    public selectedToken: string;
    public maskGroup = true;
    public getAutomaticLineage = true;
    public maskFilter: boolean;
    public pathTransformation: string;
    public region: string;
    public bucketName: string;
    public filesPath: string;
    public accessKey: string;
    public secretKey: string;
    public personalAccessToken = '';
    public privateKey: string;
    public dataset: string;
    public projectId: string;
    public authMechanism: string;
    public username: string;
    public passwordOrSecret: string;
    public protocol: string;
    public siteContentUrl: string;
    public apiVersion: string;
    public clientGuid: string;
    public azureSubscriptionId: string;
    public azureAccountName: string;
    public azureResourceGroupName: string;
    public azureClientId: string;
    public azureTenantId: string;
    public azureClientSecret: string;
    public sampleSize: number;
    public url: string;
    public workspaceNames: string[];
    public advancedMode = false;
    public host: string;
    public accessToken: string;
}

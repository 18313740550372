import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { IUserBadgeCellData } from '@datagalaxy/core-ui/cell-components';
import { UserService } from '../../../services/user.service';
import { getLocalId } from '@datagalaxy/webclient/utils';
import { DxyBaseComponent } from '@datagalaxy/ui/core';

@Component({
    selector: 'dxy-mini-avatars',
    templateUrl: './dxy-mini-avatars.component.html',
    styleUrls: ['./dxy-mini-avatars.component.scss'],
})
export class DxyMiniAvatarsComponent
    extends DxyBaseComponent
    implements OnChanges, IMiniAvatarsComponentInputs
{
    @Input() fieldKey: string;
    @Input() usersIds: string | string[];
    @Input() maxUsers: number;
    /** small image size. defaults to true */
    @Input() mini = true;

    public usersData: IMiniUserAvatar[];
    public fieldName: string;
    public get usersCount() {
        return this.usersData?.length;
    }
    public get isUniqueUser() {
        return this.usersData?.length == 1;
    }
    public get isTooManyUsers() {
        return this.usersData?.length > this.maxUsers;
    }
    public get uniqueUserName() {
        return this.usersData?.[0]?.name;
    }

    constructor(private userService: UserService) {
        super();
    }

    ngOnChanges(changes: SimpleChanges) {
        super.onChange(changes, 'usersIds', () => this.onUsersChanged(), true);
    }

    private onUsersChanged() {
        this.log('onUsersChanged', this.usersIds);
        const userIds = Array.isArray(this.usersIds)
            ? this.usersIds
            : this.usersIds
            ? [this.usersIds]
            : [];
        this.usersData = userIds.map((userId) => this.makeUserData(userId));
    }

    private makeUserData(userId: string): IMiniUserAvatar {
        const userTag = this.userService.getUserBadgeDataFromId(userId);
        const userData = this.userService.getPublicUserData(getLocalId(userId));
        return {
            userTag,
            name: userData?.FullName ?? '',
            disabled: userData?.isDisabled,
        };
    }
}

interface IMiniUserAvatar {
    userTag: IUserBadgeCellData;
    name: string;
    disabled?: boolean;
}

export interface IMiniAvatarsComponentInputs {
    usersIds: string | string[];
    fieldKey: string;
    maxUsers?: number;
}

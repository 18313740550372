import {
    ChangeDetectionStrategy,
    Component,
    ViewEncapsulation,
} from '@angular/core';
import { AttributeTranslationBaseInput } from '../attribute-translation-base-input';
import { ServerConstants } from '@datagalaxy/shared/server/domain';

@Component({
    selector: 'dxy-attribute-text-translation-input',
    templateUrl: './attribute-text-translation-input.component.html',
    styleUrls: ['./attribute-text-translation-input.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttributeTextTranslationInputComponent extends AttributeTranslationBaseInput {
    protected textMaxLength =
        ServerConstants.AttributeConstants.AttributeTextLimit;

    constructor() {
        super();
    }
}

import { UserSessionInterceptor } from './user-session.interceptor';
import { Provider } from '@angular/core';
import { SequenceNumberInterceptor } from './sequence-number.interceptor';
import { RequestTimeInterceptor } from './request-time.interceptor';
import { XidInterceptor } from './xid.interceptor';
import { RealtimeIdInterceptor } from './realtime-id.interceptor';
import { ErrorInterceptor } from './error.interceptor';
import { BACKEND_HTTP_INTERCEPTORS } from '../backend';
import { ClientVersionInterceptor } from './client-version.interceptor';
import { JwtInterceptor } from './jwt.interceptor';

export const httpInterceptors: Provider[] = [
    {
        provide: BACKEND_HTTP_INTERCEPTORS,
        useClass: ClientVersionInterceptor,
        multi: true,
    },
    {
        provide: BACKEND_HTTP_INTERCEPTORS,
        useClass: UserSessionInterceptor,
        multi: true,
    },
    {
        provide: BACKEND_HTTP_INTERCEPTORS,
        useClass: JwtInterceptor,
        multi: true,
    },
    {
        provide: BACKEND_HTTP_INTERCEPTORS,
        useClass: SequenceNumberInterceptor,
        multi: true,
    },
    {
        provide: BACKEND_HTTP_INTERCEPTORS,
        useClass: RequestTimeInterceptor,
        multi: true,
    },
    {
        provide: BACKEND_HTTP_INTERCEPTORS,
        useClass: XidInterceptor,
        multi: true,
    },
    {
        provide: BACKEND_HTTP_INTERCEPTORS,
        useClass: RealtimeIdInterceptor,
        multi: true,
    },
    {
        provide: BACKEND_HTTP_INTERCEPTORS,
        useClass: ErrorInterceptor,
        multi: true,
    },
];

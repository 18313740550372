import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnInit,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NavigationService } from '../../services/navigation.service';
import { FilterBarService } from '../../shared/filter/services/filterBar.service';
import { AppDataService } from '../../services/app-data.service';
import { FilteredViewService } from '../../shared/filter/services/filteredView.service';
import { ViewIdentifier } from '../../shared/util/ViewIdentifier';
import {
    ModuleSecurityData,
    Space,
} from '@datagalaxy/webclient/workspace/data-access';
import { DashboardDto } from '@datagalaxy/webclient/dashboard/data-access';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { FilteredViewDto } from '@datagalaxy/webclient/filter/domain';
import { DgZone } from '@datagalaxy/webclient/domain';
import { DgModule } from '@datagalaxy/shared/dg-module/domain';

@Component({
    //eslint-disable-next-line @angular-eslint/component-selector
    selector: 'dxy-navbar-title',
    templateUrl: './dxy-navbar-title.component.html',
    styleUrls: ['./dxy-navbar-title.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DxyNavbarTitleComponent
    extends DxyBaseComponent
    implements OnInit
{
    @Input() currentViewIdentifier: string;
    @Input() dgZone?: DgZone;
    @Input() dgModule?: DgModule;
    @Input() moduleSecurityData?: ModuleSecurityData;
    @Input() spaceData?: Space;
    @Input() dashboardList?: DashboardDto[];
    @Input() dashboardId?: string;
    @Input() currentFilteredView: FilteredViewDto;

    public headerTitle?: string;
    public showSpaceDropdown = false;
    public showVersionDropdown = false;
    public showCurrentClientName = true;

    public isSpaceSelectable = false;
    public isVersionSelectable = false;

    public hideViewName?: boolean;

    public get showFiltersAndActions() {
        return (
            !this.navigationService.isInDiagramsListView &&
            (this.showModuleActions || this.showSearchActions)
        );
    }
    public get showModuleActions() {
        return this.navigationService.isInModuleView;
    }
    public get showSearchActions() {
        return this.isSearchResults;
    }
    public get isSearchResults() {
        return this.navigationService.isInSearchResultsView;
    }
    public get showTitle() {
        return !this.showFiltersAndActions && !this.hideTitle;
    }
    public get showSubTitleContainer() {
        return this.showTitle || this.showFiltersAndActions;
    }

    public get title() {
        return (this._title ??= this.translate.instant(
            `UI.ViewIdentifier.${
                this.headerTitle || this.currentViewIdentifier
            }`
        ));
    }
    public get viewTitle() {
        return this.hideViewName
            ? null
            : this.translate.instant(
                  `UI.ViewIdentifier.${this.currentViewIdentifier}`
              );
    }

    // #region filter
    public filtersCount: number;
    public get hasFilters() {
        return !!this.filtersCount;
    }
    public get isFilterButtonVisible() {
        switch (this.dgZone) {
            case DgZone.Search:
                return this.isSearchResults;
            case DgZone.Module:
                return !this.navigationService.isEntityFullPage;
        }
    }
    public filterBtnTooltip: string;
    // #endregion filter

    private _title?: string;
    private hideTitle?: boolean;

    constructor(
        private translate: TranslateService,
        private navigationService: NavigationService,
        private filterBarService: FilterBarService,
        private appDataService: AppDataService,
        private filteredViewService: FilteredViewService,
        private cd: ChangeDetectorRef
    ) {
        super();
    }

    ngOnInit() {
        this.init();
        this.debug &&
            this.logData('ngOnInit', {
                dgZone: DgZone[this.dgZone],
                dgModule: DgModule[this.dgModule],
                currentViewIdentifier: this.currentViewIdentifier,
                showTitle: this.showTitle,
                title: this.title,
                viewTitle: this.viewTitle,
                spaceData: this.spaceData,
            });

        this.setFilterCount();
        super.subscribe(this.filteredViewService.currentViewChanged$, (e) =>
            this.onCurrentViewChanged(e.dgZone, e.filteredView)
        );
        super.subscribe(this.filterBarService.showHideFilterBar$, () =>
            this.onFilterBarShowHide()
        );
    }

    // #region filter

    public onFilterClick() {
        this.filterBarService.toggleFilterBar(this.dgZone);
    }

    private onCurrentViewChanged(dgZone: DgZone, fv: FilteredViewDto) {
        if (dgZone != this.dgZone) {
            return;
        }
        this.log('onCurrentViewChanged', DgZone[dgZone], fv);
        this.currentFilteredView = fv;
        this.setFilterCount();
    }

    private onFilterBarShowHide() {
        this.setFilterBtnTooltip();
    }

    private setFilterBtnTooltip() {
        const isOpen = this.filterBarService.isFilterBarVisible(this.dgZone);
        const key = isOpen
            ? '.closeFiltersBarTooltip'
            : '.openFiltersBarTooltip';
        this.filterBtnTooltip = this.translate.instant(
            `UI.NavBar.filteredViews${key}`
        );
        this.preventNg0100Error(this.cd);
    }

    private setFilterCount() {
        this.filtersCount = this.filteredViewService.getCurrentFiltersCount(
            this.dgZone
        );
    }

    // #archi-navbar (revi) move this logic in another file (use an interface, e.g. INavBarTitleInput)
    private init() {
        switch (this.currentViewIdentifier) {
            case ViewIdentifier.ClientSpacesList:
            case ViewIdentifier.ClientTasks:
            case ViewIdentifier.ClientWidgetsDashboard:
            case ViewIdentifier.ClientConnectors:
            case ViewIdentifier.ClientAdmin:
            case ViewIdentifier.SpaceDashboard:
                this.hideViewName = true;
                this.hideTitle = true;
                break;

            case ViewIdentifier.CampaignDetails:
                this.hideViewName = true;
                this.hideTitle = true;
                break;

            case ViewIdentifier.ClientSearchResults:
                this.dgZone = DgZone.Search;
                this.hideViewName = true;
                break;

            case ViewIdentifier.Modeler:
                this.headerTitle = ViewIdentifier.ModelerDiagrams;
            // eslint-disable-next-line no-fallthrough
            case ViewIdentifier.Glossary:
            case ViewIdentifier.DataProcessing:
            case ViewIdentifier.Software:
                this.showSpaceDropdown = true;
                this.showVersionDropdown = true;
                this.isVersionSelectable = true;
                this.isSpaceSelectable = true;
                this.hideViewName = true;
                break;

            case ViewIdentifier.Diagram:
                this.showSpaceDropdown = true;
                this.showVersionDropdown = true;
                this.isVersionSelectable = true;
                this.hideViewName = true;
                this.isSpaceSelectable = true;
                this.hideTitle = true;
                break;

            case ViewIdentifier.Diagrams:
            case ViewIdentifier.Campaigns:
            case ViewIdentifier.ImpactAnalysis:
                this.showSpaceDropdown = true;
                this.showVersionDropdown = true;
                this.isVersionSelectable = true;
                this.hideViewName = true;
                this.isSpaceSelectable = true;
                break;
            case ViewIdentifier.SpaceHome:
                this.showSpaceDropdown = true;
                this.isSpaceSelectable = true;
                this.showVersionDropdown = true;
                this.isVersionSelectable = true;
                this.showCurrentClientName = true;
                this.hideViewName = true;
                this.hideTitle = true;
                break;

            case ViewIdentifier.SpaceSettings:
                this.showSpaceDropdown = true;
                this.showVersionDropdown = true;
                this.isVersionSelectable = true;
                this.isSpaceSelectable = true;
                this.hideViewName = true;
                break;

            case ViewIdentifier.UserProfile:
                this.showCurrentClientName = false;
                this._title = this.appDataService.currentUserFullName;
                break;
        }
    }
    // #endregion filter
}

import { NgModule } from '@angular/core';
import { CoreUiModule } from '../core-ui.module';
import { DxyCaptionPositionModule } from './caption-position';
import { DxyGraphicalSidePanelComponent } from './graphical-side-panel/graphical-side-panel.component';
import { DxyGraphicalContextMenuComponent } from './graphical-context-menu/graphical-context-menu.component';
import { DxyGraphicalControlsComponent } from './graphical-controls/graphical-controls.component';
import { DxyGraphicalTextInputComponent } from './graphical-text-input/graphical-text-input.component';
import { DxyGraphicalTextAreaComponent } from './graphical-textarea/graphical-textarea.component';
import { DxyGraphicalToolbarComponent } from './graphical-toolbar/graphical-toolbar.component';

@NgModule({
    imports: [CoreUiModule, DxyCaptionPositionModule],
    declarations: [
        DxyGraphicalControlsComponent,
        DxyGraphicalToolbarComponent,
        DxyGraphicalSidePanelComponent,
        DxyGraphicalContextMenuComponent,
        DxyGraphicalTextInputComponent,
        DxyGraphicalTextAreaComponent,
    ],
    exports: [
        DxyGraphicalControlsComponent,
        DxyGraphicalToolbarComponent,
        DxyGraphicalSidePanelComponent,
        DxyGraphicalContextMenuComponent,
        DxyGraphicalTextInputComponent,
        DxyGraphicalTextAreaComponent,
        DxyCaptionPositionModule,
    ],
})
export class DxyGraphicalModule {}

import {
    Component,
    EventEmitter,
    HostBinding,
    Input,
    Output,
} from '@angular/core';
import { ButtonColor, DxyButtonsModule } from '@datagalaxy/ui/buttons';
import { TranslateModule } from '@ngx-translate/core';
import { NgClass, NgForOf } from '@angular/common';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';
import { StreamExplorerAction } from './lineage-stream-explorer-button.types';

@Component({
    standalone: true,
    selector: 'app-lineage-stream-explorer-button',
    templateUrl: './lineage-stream-explorer-button.component.html',
    styleUrls: ['./lineage-stream-explorer-button.component.scss'],
    imports: [
        DxyButtonsModule,
        TranslateModule,
        NgForOf,
        NgClass,
        MatLegacyTooltipModule,
    ],
})
export class LineageStreamExplorerButtonComponent<T> {
    @Input() actions: StreamExplorerAction<T>[];
    @Input() reverse: boolean;
    @Input() color: ButtonColor;
    @Input() virtual: boolean;

    @Output() select = new EventEmitter<StreamExplorerAction<T>>();

    @HostBinding('class.reversed')
    get reversedClass() {
        return this.reverse;
    }

    @HostBinding('class.secondary')
    get secondaryClass() {
        return this.color === 'secondary';
    }

    @HostBinding('class.virtual')
    get virtualClass() {
        return this.virtual;
    }

    @HostBinding('style.width.px')
    get hostMaxWidth() {
        return this.maxWidth;
    }

    protected activeId: string;
    protected maxWidth: number;

    constructor() {}

    protected getColor(action: StreamExplorerAction<T>): ButtonColor {
        if (!this.activeId && this.actions.indexOf(action) === 0)
            return this.color;
        return action.id === this.activeId ? this.color : 'transparent';
    }

    protected onMouseEnter(action: StreamExplorerAction<T>) {
        this.activeId = action.id;
        this.maxWidth = this.actions.length * 20;
    }

    protected onMouseLeave() {
        this.activeId = null;
        this.maxWidth = 20;
    }

    protected onClick(action: StreamExplorerAction<T>) {
        this.select.emit(action);
    }
}

import { Component, Input, EventEmitter, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IListOption } from '../../IListOption';
import { DxyBaseComponent } from '@datagalaxy/ui/core';

@Component({
    selector: 'dxy-split-button',
    templateUrl: './split-button.component.html',
    styleUrls: ['./split-button.component.scss'],
})
export class DxySplitButtonComponent extends DxyBaseComponent {
    /** Specifies the mat button color to use */
    @Input() color: string;
    /** When false, we hide the caret and the split bar */
    @Input() showSplitButton: boolean;
    @Input() disabled: boolean;
    @Input() text: string;
    @Input() textKey: string;
    /** Options used for the dropdown actions */
    @Input() options: IListOption[];
    @Input() isLoading: boolean;
    @Output() openClose = new EventEmitter<boolean>();

    public get btnText() {
        return (
            this.text ||
            (this.textKey && this.translate.instant(this.textKey)) ||
            ''
        );
    }

    constructor(private translate: TranslateService) {
        super();
    }
}

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { CollectionsHelper } from '@datagalaxy/core-util';
import { CoreUtil } from '@datagalaxy/core-util';
import { DxyAttributeBaseInput } from '../../DxyAttributeBaseInput';
import { EntityLinksChanges } from '../EntityLinksChanges';
import { TranslateService } from '@ngx-translate/core';
import { DxyEntitySelectorFieldComponent } from '../../../../entitySelector/dxy-entity-selector-field/dxy-entity-selector-field.component';
import { EntitySelectorData } from '../../../../entitySelector/entity-selector.types';
import { EntityLinkService } from '../../../../../entity-links/entity-link.service';
import { EntityEventService } from '../../../../entity/services/entity-event.service';
import { AttributeDataService } from '../../../attribute-data.service';
import { AddLinkedEntitiesResult } from '@datagalaxy/webclient/entity/data-access';
import {
    EntityItem,
    LinkedDataItem,
} from '@datagalaxy/webclient/entity/domain';
import { AttributeMetaInfo } from '@datagalaxy/webclient/attribute/domain';

/*
    used by entity-attribute-input in the Shortcuts section (Usage, Universe, ...) of the details page of an entity
 */

@Component({
    selector: 'dxy-attribute-link-shortcut-input',
    templateUrl: './dxy-attribute-link-shortcut-input.component.html',
})
export class DxyAttributeLinkShortcutInputComponent
    extends DxyAttributeBaseInput<LinkedDataItem[]>
    implements OnInit
{
    @ViewChild('field') field: DxyEntitySelectorFieldComponent<LinkedDataItem>;

    //#region html bindings
    public items: LinkedDataItem[];
    public get options() {
        return this.selectorData.instance;
    }
    //#endregion

    private readonly linksChange = new EntityLinksChanges();
    private readonly selectorData = new EntitySelectorData();
    private isDeserialized: boolean;

    constructor(
        private entityEventService: EntityEventService,
        private linkedObjectService: EntityLinkService,
        elementref: ElementRef<HTMLElement>,
        translate: TranslateService
    ) {
        super(elementref, translate);
    }

    ngOnInit() {
        this.subscribeEntityUpdate();
        this.setDataInitialDeserialized().then(() => {
            this.setItems();
            this.updateSelectorData(true, true);
            super.ngOnInit();
        });
    }

    //#region html bindings

    public async onSelectionChange(selection: EntityItem[] | EntityItem) {
        const entityItems =
            selection instanceof EntityItem ? [selection] : selection;
        this.isDeserialized = false;
        if (this.hasInternalError) {
            // TODO: Reset correct initial state
            this.resetMessages();
        }

        this.linksChange.clearLinksToAdd();
        const linkedData = this.getDataAsLinkedDataItems() ?? [];
        const linkedDataIds = linkedData?.map(
            (item) => item.LinkedData.DataReferenceId
        );
        const dataClone: LinkedDataItem[] = entityItems.map((item) => {
            const linkedDataItem = new LinkedDataItem(null, null);
            linkedDataItem.LinkedData = item.HddData;

            if (!linkedDataIds.includes(item.DataReferenceId)) {
                this.linksChange.addLinkToAdd(linkedDataItem);
            }

            return linkedDataItem;
        });

        const dataCloneIds = dataClone.map(
            (data) => data.LinkedData.DataReferenceId
        );
        linkedData.forEach((item) => {
            if (!dataCloneIds.includes(item.LinkedData.DataReferenceId)) {
                this.linksChange.addLinkToDelete(item);
            }
        });

        const excludedIds = entityItems.map(
            (entity) => entity.HddData.Data.DataReferenceId
        );
        this.selectorData.setExcludedIds(excludedIds);
    }

    //#region IAttributeBaseInputOverride

    public onServerError() {
        this.resetInitialValue();
    }

    public async onServerSuccess(result: any) {
        this.log('onServerSuccess');
        await this.doReplacements(result as AddLinkedEntitiesResult);
        await this.setDataInitialDeserialized();
        this.updateSelectorData();
    }

    public onAfterUndo() {
        this.linksChange
            .getLinksToAddLinkedDataIds()
            .forEach((linkedDataId) =>
                this.selectorData.removeExcludedId(linkedDataId)
            );
        this.linksChange.clearAll();
        this.isDeserialized = true;
        this.setItems();
    }

    public async onBeforeValidate() {
        return !this.isShortcutAttribute() || this.linksChange.hasAny();
    }
    public onAfterValidate() {
        this.isDeserialized = true;
        this.linksChange.clearAll();
    }

    public async onAttributeValueChange(attributeMeta: AttributeMetaInfo) {
        const linksChangeInfo = this.isShortcutAttribute()
            ? this.linksChange.asChangeInfo()
            : undefined;
        return super.onAttributeValueChange(attributeMeta, linksChangeInfo);
    }

    public isDirty(): boolean {
        return !this.isDeserialized && this.linksChange.hasAny();
    }

    public onBeforeSetActive() {
        this.setMinChars();
    }
    public focusField() {
        this.field.doFocus();
    }
    public blurField() {
        this.field.doBlur();
    }

    //#endregion

    //#region private

    private subscribeEntityUpdate() {
        const entity = this.getEntityData();
        if (!entity) {
            return;
        }

        super.registerSubscription(
            this.entityEventService.subscribeEntityUpdate(
                entity.ServerType,
                (data) => {
                    if (data.DataReferenceId == entity.DataReferenceId) {
                        this.onLocalEntityUpdated(data).then();
                    }
                }
            )
        );
    }

    private isShortcutAttribute() {
        return AttributeDataService.isShortcutAttribute(this.attributeType);
    }

    private getActualEntityData() {
        return this.getEntityDataList()?.[0] ?? this.getEntityData();
    }

    private getDataAsLinkedDataItems(deserialize = false) {
        const items = this.getData() as LinkedDataItem[];
        return deserialize
            ? EntityItem.getDeserializedLinkedDataItems(items)
            : items;
    }
    private getDataAsReferenceIds() {
        return this.getDataAsLinkedDataItems()?.map(
            (hd) => hd.Data.DataReferenceId
        );
    }

    private async onLocalEntityUpdated(entityItem: EntityItem) {
        const entityData = this.getEntityData();
        if (
            !entityData ||
            entityItem.DataReferenceId != entityData.DataReferenceId ||
            !entityItem.getAttributeValue(this.attributeKey)
        ) {
            return;
        }

        this.clearItems();
        await this.setDataClone(
            CoreUtil.cloneDeep(entityItem.getLinkedDataItems(this.attributeKey))
        );
        await this.setDataInitialDeserialized();
        this.setItems();
        this.updateSelectorData();
    }

    private async doReplacements(addLinkResult: AddLinkedEntitiesResult) {
        if (!addLinkResult.Links?.length) {
            return;
        }
        const dataClone = CoreUtil.cloneDeep(this.items);

        for (const link of addLinkResult.Links) {
            const hd = link.LinkedEntities[0].HddData;
            const linkedItem = new LinkedDataItem(
                hd,
                link.LinkEntity
                    .HddData as any /* #Archi-typing (fbo): cast added here to match added type on LinkedDataItem. Seems doubtfull to me. CBO ? */
            );
            CollectionsHelper.replaceOrAppend(
                dataClone,
                (a) => a.LinkedData.Data.DataReferenceId == hd.DataReferenceId,
                linkedItem
            );
        }

        await this.setDataClone(dataClone);
    }
    private async setDataClone(
        dataClone: LinkedDataItem[],
        deserialize = false
    ) {
        if (deserialize && dataClone?.length) {
            await this.setData(
                EntityItem.getDeserializedLinkedDataItems(dataClone)
            );
        } else {
            await this.setData(dataClone);
        }
    }

    private async setDataInitialDeserialized() {
        const data = this.getDataAsLinkedDataItems(true);
        this.log('setDataInitialDeserialized', data);
        if (data?.length) {
            await this.setData(data);
        }
        this.setInitialValue(data, true);
        this.isDeserialized = true;
    }

    private updateSelectorData(
        setSpaceIdr = false,
        setServerTypesAndIncludedEntityTypes = false
    ) {
        if (setSpaceIdr) {
            this.selectorData.setSpaceIdr(this.getSpaceIdr());
        }

        if (setServerTypesAndIncludedEntityTypes) {
            this.setServerTypesAndIncludedEntityTypes();
        }

        this.setMinChars();

        const excludedIds =
            this.attributeMeta.excludedEntitiesIds.slice() ?? [];
        const includedIds = this.selectorData.getIncludedIds();
        const isSpotlightEnabled = this.selectorData.isEnabled;

        const refIds = this.getDataAsReferenceIds();
        if (refIds?.length && this.isGenericEntityForm) {
            excludedIds.push(...refIds);
        }

        const actualDataLength = this.getEntityDataList()?.length ?? 0;
        if (actualDataLength == 1) {
            excludedIds.push(this.getActualEntityData().ReferenceId);
        }

        this.selectorData.setExcludedIds(excludedIds);
        this.selectorData.setIncludedIds(includedIds);
        this.selectorData.setEnabled(isSpotlightEnabled);
        this.selectorData.updateInstance();
    }
    private setServerTypesAndIncludedEntityTypes() {
        const entityItem = this.getActualEntityData();

        const res = this.linkedObjectService.getEntityTypesAndLinkTypeKind(
            this.attributeMeta,
            entityItem
        );
        if (res.kind != undefined) {
            this.linksChange.setKind(res.kind);
        }
        const includedEntityTypes = res.entityTypes;

        this.selectorData.setIncludedEntityTypes(includedEntityTypes);
    }

    private setMinChars() {
        this.selectorData.setMinChars(this.getData()?.length ? 1 : 0);
    }

    private setItems() {
        this.items = this.getDataAsLinkedDataItems()?.slice() ?? [];
    }
    private clearItems() {
        this.items = [];
    }

    //#endregion - private
}

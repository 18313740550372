import { CollectionsHelper } from '@datagalaxy/core-util';
import { Component, Inject, OnInit } from '@angular/core';
import { EntityPreviewPanelService } from '../services/entity-preview-panel.service';
import { DXY_POPOVER_DATA, DxyBasePopoverComponent } from '@datagalaxy/core-ui';
import { OverlayRef } from '@angular/cdk/overlay';
import { EntityService } from '../services/entity.service';
import { AppSpaceService } from '../../../services/AppSpace.service';
import {
    HierarchicalData,
    IEntityIdentifier,
} from '@datagalaxy/dg-object-model';
import { ViewTypeService } from '../../../services/viewType.service';
import { CommentaryService } from '../../../commentary/commentary.service';
import { HddUtil } from '../../util/HddUtil';
import { TaskService } from '../../../tasks/task.service';
import { NavigationEventService } from '../../../navigation/services/navigation-event.service';
import { NavigationService } from '../../../services/navigation.service';
import { Space } from '@datagalaxy/webclient/workspace/data-access';
import {
    CrudOperation,
    FunctionalLogService,
} from '@datagalaxy/webclient/monitoring/data-access';
import { EntityTypeUtils } from '@datagalaxy/webclient/entity/utils';
import { SpaceIdentifier } from '@datagalaxy/webclient/workspace/utils';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';
import { AttributeObjectValue } from '@datagalaxy/webclient/attribute/domain';

@Component({
    selector: 'dxy-entity-popover-tooltip-rich',
    templateUrl: 'dxy-entity-popover-tooltip-rich.component.html',
    styleUrls: ['dxy-entity-popover-tooltip-rich.component.scss'],
})
export class DxyEntityPopoverTooltipRichComponent
    extends DxyBasePopoverComponent<IEntityPopoverTooltipResolve>
    implements OnInit
{
    public hierarchicalData: HierarchicalData;
    public entityData: EntityItem;
    public currentSpace: Space;
    public owners: AttributeObjectValue[];
    public moreOwnersThan3 = 0;
    public stewards: AttributeObjectValue[];
    public moreStewardsThan3 = 0;
    public isGdpr: boolean;
    public gdprTagColor: string;
    public gdprTagText: string;
    public allOwnersTooltip: string;
    public allStewardsTooltip: string;
    public entityName: string;
    public typeClyphClass: string;
    public entityDescription: string;
    public hasOwners: boolean;
    public hasStewards: boolean;
    public loading: boolean;

    constructor(
        @Inject(DXY_POPOVER_DATA) data: IEntityPopoverTooltipResolve,
        overlayRef: OverlayRef,
        private viewTypeService: ViewTypeService,
        private navigationService: NavigationService,
        private functionalLogService: FunctionalLogService,
        private navigationEventService: NavigationEventService,
        private taskService: TaskService,
        private commentaryService: CommentaryService,
        private entityPreviewPanelService: EntityPreviewPanelService,
        private entityService: EntityService,
        private appSpaceService: AppSpaceService
    ) {
        super(overlayRef, data);
    }

    ngOnInit() {
        this.log('$onInit');
        super.subscribe(this.navigationEventService.openDeleteModal$, () =>
            this.close()
        );

        this.initAsync();
    }

    public getFullName(user: { PersonFullName?: string }) {
        return user?.PersonFullName;
    }

    //#region actions
    public goToDetail() {
        this.log('goToDetail');
        this.close();
        this.navigationService.goToWithHierarchicalData(
            this.entityData.HddData
        );
    }
    public openDetailPane() {
        this.log('openDetailPane');
        this.close();
        this.entityPreviewPanelService.setupPanel({
            entityIdr: this.entityData,
        });
    }
    public openTaskNewModal() {
        this.log('openTaskNewModal');
        this.close();
        this.taskService.openTaskFormModal(this.entityData);
    }
    public openCommentaryNewModal() {
        this.log('openCommentaryNewModal');
        this.close();
        this.commentaryService.openCommentaryNewModal({
            entityData: this.entityData,
        });
    }
    //#endregion

    public close() {
        this.onClose();
    }

    private async initAsync() {
        this.functionalLogService.logFunctionalAction(
            'POPOVER_DISPLAY',
            CrudOperation.R
        );
        this.loading = true;
        this.entityData = await this.entityService.getEntityForDetails(
            this.data.entityIdr
        );
        this.currentSpace = await this.appSpaceService.getSpace(
            SpaceIdentifier.fromEntity(this.data.entityIdr)
        );
        this.loading = false;
        this.owners = this.entityData.getAttributeValue<AttributeObjectValue[]>(
            'DataOwnersObjectValues'
        );
        this.hasOwners = !!this.owners;
        if (this.owners?.length > 3) {
            this.moreOwnersThan3 = this.owners.length - 2;
            this.owners = this.owners.slice(0, 2);
        }

        this.stewards = this.entityData.getAttributeValue<
            AttributeObjectValue[]
        >('DataStewardsObjectValues');
        this.hasStewards = !!this.stewards;
        if (this.stewards?.length > 3) {
            this.moreStewardsThan3 = this.stewards.length - 2;
            this.stewards = this.stewards.slice(0, 2);
        }

        this.hierarchicalData = HddUtil.createHDataForSpace(this.currentSpace);

        this.isGdpr = this.entityData.isGdpr;

        const tagGdpr = CollectionsHelper.findUnique(
            this.entityData.tags,
            (t) => t.TagInternalName == '_dg_rgpd'
        );
        this.gdprTagColor = tagGdpr?.TagColor || '';
        this.gdprTagText = tagGdpr?.DisplayName || '';

        const ownersStr =
            this.entityData.getAttributeValue<string>('DataOwnersString');
        this.allOwnersTooltip = ownersStr.split(',').slice(2).join(', ');

        const stewardsStr =
            this.entityData.getAttributeValue<string>('DataStewardsString');
        this.allStewardsTooltip = stewardsStr.split(',').slice(2).join(', ');

        this.entityName = this.viewTypeService.getTechnicalOrDisplayName(
            this.entityData
        );
        this.typeClyphClass = EntityTypeUtils.getGlyphClass(
            this.entityData?.EntityType
        );
        this.entityDescription = this.entityData.Description;
    }
}

export interface IEntityPopoverTooltipResolve {
    entityIdr: IEntityIdentifier;
}

import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogType, DxyBaseModalComponent } from '@datagalaxy/ui/dialog';
import { IMentionResolver } from '@datagalaxy/core-ui/rich-text';
import { SpaceApiService } from '../services/space-api.service';
import {
    ISpaceEditModalForm,
    ISpaceEditModalResolve,
    ISpaceEditModalResult,
} from './space-edit-modal.types';
import { EnumNumberFieldSelectAdapter } from '@datagalaxy/core-ui';
import { DxyModalService } from '../../shared/dialogs/DxyModalService';
import {
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
} from '@angular/forms';
import { SpaceTrigramValidationUtils } from '../space-trigram-validation.utils';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { map } from 'rxjs';
import { CollectionsHelper, CoreUtil } from '@datagalaxy/core-util';
import { UserFieldSelectAdapter } from '@datagalaxy/webclient/user/ui';
import { ServerType } from '@datagalaxy/dg-object-model';
import { AttributeDataService } from '../../shared/attribute/attribute-data.service';
import { UserService } from '../../services/user.service';
import { RichTextMentionService } from '../../shared/richText/mentions/rich-text-mention.service';
import { GlyphUtil } from '../../shared/util/GlyphUtil';
import { SpaceSecurityProfileType } from '@datagalaxy/webclient/workspace/data-access';

/**
 * ## Role
 * Space creation/edition modal
 */
@Component({
    selector: 'dxy-space-edit-modal',
    templateUrl: './dxy-space-edit-modal.component.html',
})
export class DxySpaceEditModalComponent
    extends DxyBaseModalComponent<ISpaceEditModalResolve, ISpaceEditModalResult>
    implements OnInit
{
    public readonly mentionResolvers: IMentionResolver[];
    public readonly defaultOwnerAdapter = new UserFieldSelectAdapter();
    public readonly defaultStewardAdapter = new UserFieldSelectAdapter();
    public readonly securityProfileAdapter = new EnumNumberFieldSelectAdapter(
        SpaceSecurityProfileType,
        {
            getTextKey: (t) =>
                `DgServerTypes.SpaceSecurityProfileType.${SpaceSecurityProfileType[t]}`,
            getSubTextKey: (t) =>
                `DgServerTypes.SpaceSecurityProfileTypeText.${SpaceSecurityProfileType[t]}`,
            getGlyphClass: (t) =>
                GlyphUtil.getSpaceSecurityProfileGlyphClass(t),
            orderedValues:
                AttributeDataService.getOrderedSpaceSecurityProfileType(),
        }
    );

    public formGroup: FormGroup<ISpaceEditModalForm>;
    public loading: boolean;

    public get isCreation() {
        return !this.data.navSpace;
    }
    public get trigramErrorMessageKey() {
        return SpaceTrigramValidationUtils.trigramErrorMessageKey(
            this.formGroup
        );
    }
    public get modalTitleKey() {
        const suffix = this.isCreation
            ? 'Project.titleCreate'
            : `${this.navSpace.TypeName}.titleUpdate`;
        return `UI.Dialog.NewItem.${suffix}`;
    }

    public get securityProfileTypeTextKey() {
        const { securityProfile } = this.formGroup.value;
        const suffix = SpaceSecurityProfileType[securityProfile];
        return `DgServerTypes.SpaceSecurityProfileTypeText.${suffix}`;
    }

    public get actionBtnLblKey() {
        const suffix = this.isCreation ? 'ttCreateButton' : 'ttUpdateButton';
        return `UI.Dialog.NewItem.Project.${suffix}`;
    }
    public get featureCode() {
        const type = this.isCreation ? this.data.type : this.navSpace.Type;
        const obj =
            type == ServerType.Organization
                ? 'ORGA'
                : type == ServerType.Project
                ? 'PROJECT'
                : undefined;
        return obj && `${obj},${this.isCreation ? 'C' : 'U'}`;
    }

    private get navSpace() {
        return this.data.navSpace;
    }

    constructor(
        private spaceApiService: SpaceApiService,
        private userService: UserService,
        private dxyModalService: DxyModalService,
        private fb: FormBuilder,
        richTextMentionService: RichTextMentionService,
        dialogRef: MatDialogRef<
            DxySpaceEditModalComponent,
            ISpaceEditModalResult
        >,
        @Inject(MAT_DIALOG_DATA) data: ISpaceEditModalResolve
    ) {
        super(dialogRef, data);
        this.mentionResolvers =
            richTextMentionService.getDefaultMentionResolvers(
                this.data.navSpace?.spaceId
            );
        this.initForm();
    }

    ngOnInit() {
        this.initAsync();
        this.subscribeEvents();
    }

    public override async onCloseSubmit() {
        if (this.formGroup.invalid) {
            return;
        }

        if (!this.isCreation) {
            const { securityProfile } = this.formGroup.value;
            const oldSecurityProfile = this.navSpace.SecurityProfileType;
            if (securityProfile != oldSecurityProfile) {
                const confirmed = await this.confirmChangeSecurityModal(
                    securityProfile,
                    oldSecurityProfile
                );
                if (!confirmed) {
                    return;
                }
            }
        }

        this.loading = true;
        const navSpace = await this.createOrUpdateNavSpace(this.formGroup);
        this.loading = false;

        this.result = { navSpace };

        super.onCloseSubmit();
    }

    private async createOrUpdateNavSpace(
        formGroup: FormGroup<ISpaceEditModalForm>
    ) {
        if (this.isCreation) {
            return await this.createSpace(formGroup);
        } else {
            return await this.updateSpace(formGroup);
        }
    }

    private async updateSpace(form: FormGroup<ISpaceEditModalForm>) {
        const space = this.navSpace;
        const { trigram, displayName, description, securityProfile } =
            form.value;

        const updatedSpace = await this.spaceApiService.updateNavSpace(
            space.Type,
            space.ReferenceId,
            space.IsDefaultSpace,
            displayName,
            trigram,
            description,
            securityProfile
        );

        return CoreUtil.merge(this.navSpace, updatedSpace);
    }

    private async createSpace(form: FormGroup<ISpaceEditModalForm>) {
        const parentSpaceId = this.data.currentOrganizationId;
        const type = this.data.type;
        const {
            trigram,
            displayName,
            description,
            securityProfile,
            defaultOwner,
            defaultSteward,
        } = form.value;

        return this.spaceApiService.createNavSpace(
            type,
            parentSpaceId,
            displayName,
            trigram,
            description,
            defaultOwner.UserId,
            defaultSteward.UserId,
            securityProfile
        );
    }

    private initForm() {
        const navSpace = this.data.navSpace;
        const securityProfileType = (this.securityProfileAdapter.current = this
            .isCreation
            ? SpaceSecurityProfileType.Limited
            : navSpace.SecurityProfileType);
        const trigramValidator = SpaceTrigramValidationUtils.trigram(
            this.spaceApiService,
            navSpace?.spaceId || this.data.currentOrganizationId
        );

        this.formGroup = this.fb.group<ISpaceEditModalForm>({
            displayName: new FormControl(
                navSpace?.DisplayName,
                Validators.required
            ),
            description: new FormControl(navSpace?.Description),
            trigram: new FormControl(
                navSpace?.Trigram,
                Validators.required,
                trigramValidator
            ),
            securityProfile: new FormControl(securityProfileType),
            defaultOwner: new FormControl(null, Validators.required),
            defaultSteward: new FormControl(null, Validators.required),
        });
    }

    private async initAsync() {
        const userOptions = CollectionsHelper.orderBy(
            await this.userService.getNonDeletedUsers(),
            (user) => user.FullName
        );
        this.defaultStewardAdapter.init(
            userOptions,
            this.navSpace?.DefaultStewardUser.UserId
        );
        this.defaultOwnerAdapter.init(
            userOptions,
            this.navSpace?.DefaultOwnerUser.UserId
        );
        this.formGroup.patchValue({
            defaultOwner: this.defaultOwnerAdapter.current,
            defaultSteward: this.defaultStewardAdapter.current,
        });
    }

    private subscribeEvents() {
        super.subscribe(
            this.formGroup.valueChanges.pipe(
                map((values) => values.displayName),
                debounceTime(500),
                distinctUntilChanged()
            ),
            () => this.updateTrigramOnWorkspaceNameChange()
        );
    }

    private async updateTrigramOnWorkspaceNameChange() {
        const {
            displayName,
            trigram,
            description,
            defaultOwner,
            defaultSteward,
        } = this.formGroup.value;
        if (!this.isCreation || !displayName) {
            return;
        }

        const result = await this.spaceApiService.preCreateSpace(
            this.data.currentOrganizationId,
            displayName,
            trigram,
            description,
            defaultOwner?.UserId,
            defaultSteward?.UserId
        );
        if (!result) {
            return;
        }
        this.formGroup.patchValue({ trigram: result.Trigram });
    }

    private async confirmChangeSecurityModal(
        currentSecurityType: SpaceSecurityProfileType,
        oldSecurityType: SpaceSecurityProfileType
    ) {
        const fromProfileTypeString = SpaceSecurityProfileType[oldSecurityType];
        const toProfileTypeString =
            SpaceSecurityProfileType[currentSecurityType];
        return this.dxyModalService.confirm({
            titleKey: 'UI.Dialog.NewItem.Project.updateSecurityProfile.title',
            messageKey: `UI.Dialog.NewItem.Project.updateSecurityProfile.${fromProfileTypeString}To${toProfileTypeString}`,
            type: DialogType.Action,
            confirmButtonKey: 'UI.Dialog.btnUpdate',
        });
    }
}

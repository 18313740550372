import { WidgetInstance } from '../domain';
import { GRID_COLUMNS } from '../domain/dashboard-constants';
import { GridPosition } from '../domain/grid-position';

export function distanceToOrigin(position: GridPosition) {
    return position.y * GRID_COLUMNS + position.x;
}
export function invalidPosition(length: number, position: GridPosition) {
    const maxY = Math.floor(length / 3);
    return (
        position.y > maxY || (position.y == maxY && position.x >= length % 3)
    );
}

export const widgetsByDistance = (a: WidgetInstance, b: WidgetInstance) => {
    return distanceToOrigin(a.position) - distanceToOrigin(b.position);
};

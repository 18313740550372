import { Injectable } from '@angular/core';
import { BaseService, IListOption } from '@datagalaxy/core-ui';
import {
    EntityType,
    EntityTypeUtil,
    IHierarchicalData,
    ServerType,
} from '@datagalaxy/dg-object-model';
import { DataUtil } from '../../util/DataUtil';
import { ModelerDataUtil } from '../../util/ModelerDataUtil';
import { EntityUiService } from '../services/entity-ui.service';
import { EntityLinkService } from '../../../entity-links/entity-link.service';
import { GlossaryService } from '../../../glossary/glossary.service';
import { CurrentSpaceService } from '../../../services/currentSpace.service';
import { CampaignSecurityService } from '../../../campaign/campaign-security.service';
import { CampaignUiService } from '../../../campaign/campaign-ui.service';
import { EntityIdentifier } from '@datagalaxy/webclient/entity/utils';
import { SuggestionService } from '../../../suggestions/suggestion.service';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';
import { DgModule } from '@datagalaxy/shared/dg-module/domain';
import { ModuleStore } from '../../../module/module.store';
import { EntityCreationOrigin } from '@datagalaxy/webclient/entity/feature';

@Injectable({
    providedIn: 'root',
})
export class EntityGridBurgerMenuService extends BaseService {
    private readonly linkSeparator: IListOption<EntityItem> = {
        isSeparator: true,
        hidden: (data) =>
            this.areBtnLinksHidden(data) || this.areBtnCreationHidden(data),
    };

    private areBtnLinksHidden(data: EntityItem) {
        return (
            !data?.SecurityData?.HasWriteAccess &&
            this.isBtnGlossaryAutoGenerateHidden(data) &&
            this.isBtnGlossaryLinksGenerateHidden(data)
        );
    }

    private readonly creationSeparator: IListOption<EntityItem> = {
        isSeparator: true,
        hidden: (data) =>
            this.areBtnCreationHidden(data) || this.areOtherActionsHidden(data),
    };

    private areBtnCreationHidden(data: EntityItem) {
        return (
            this.isBtnCreateChildHidden(data) &&
            this.isBtnCreateColumnHidden(data) &&
            this.isBtnCreateContainerHidden(data) &&
            this.isBtnCreateStructureHidden(data) &&
            this.isBtnFusionHidden(data) &&
            this.isBtnChangeParentHidden(data)
        );
    }

    private areOtherActionsHidden(data: EntityItem) {
        return (
            !data?.SecurityData?.HasCreateAccess &&
            !data?.SecurityData?.HasExportAccess &&
            !this.campaignSecurityService.canCreateOrModifyCampaignSync(
                this.currentSpaceService.currentSpace
            ) &&
            !data?.SecurityData?.HasDeleteAccess
        );
    }

    private readonly btnCreateChild: (
        origin: EntityCreationOrigin
    ) => IListOption<EntityItem> = (origin) => ({
        labelKey: (data) =>
            this.getTranslateKey(EntityBurgerMenuAction.btnCreateChild, data),
        hidden: (data) => this.isBtnCreateChildHidden(data),
        glyphClass: 'glyph-add',
        callback: (data) => this.executeCreateChild(data, null, origin),
    });

    private isBtnCreateChildHidden(data: EntityItem) {
        return (
            !data?.SecurityData?.HasCreateAccess ||
            !this.isCreateChildAvailable(data)
        );
    }

    private readonly btnGlossaryLinksGenerate: IListOption<EntityItem> = {
        labelKey: (data) =>
            this.getTranslateKey(
                EntityBurgerMenuAction.btnGlossaryLinksGenerate,
                data
            ),
        hidden: (data) => this.isBtnGlossaryLinksGenerateHidden(data),
        glyphClass: 'glyph-metabot-links',
        callback: (data) =>
            this.glossaryService.openLinksGenerationModalForEntities(
                DataUtil.getModuleFromEntityType(data.EntityType),
                data,
                this.currentSpaceService.currentSpace
            ),
        logFunctional: (data) =>
            SuggestionService.getAutoGenerationLinksActionNameFromModule(
                DataUtil.getModuleFromEntityType(data.EntityType)
            ),
    };

    private isBtnGlossaryLinksGenerateHidden(data: EntityItem) {
        return (
            !this.moduleStore.hasAccess(DgModule.Glossary) ||
            !data?.SecurityData?.HasWriteAccess ||
            data.EntityType === EntityType.DataProcessing
        );
    }

    private readonly btnCreateContainer: (
        origin: EntityCreationOrigin
    ) => IListOption<EntityItem> = (origin) => ({
        labelKey: (data) =>
            this.getTranslateKey(
                EntityBurgerMenuAction.btnCreateContainer,
                data
            ),
        hidden: (data) => this.isBtnCreateContainerHidden(data),
        glyphClass: 'glyph-add',
        callback: (data) =>
            this.executeCreateChild(data, ServerType.Container, origin),
    });

    private isBtnCreateContainerHidden(data: EntityItem) {
        return (
            !data?.SecurityData?.HasWriteAccess ||
            !this.isCreateStructureOrContainerAvailable(data.HddData.ServerType)
        );
    }

    private readonly btnCreateStructure: (
        origin: EntityCreationOrigin
    ) => IListOption<EntityItem> = (origin) => ({
        labelKey: (data) =>
            this.getTranslateKey(
                EntityBurgerMenuAction.btnCreateStructure,
                data
            ),
        hidden: (data) => this.isBtnCreateStructureHidden(data),
        glyphClass: 'glyph-add',
        callback: (data) =>
            this.executeCreateChild(data, ServerType.Table, origin),
    });

    private isBtnCreateStructureHidden(data: EntityItem) {
        return (
            !data?.SecurityData?.HasWriteAccess ||
            !this.isCreateStructureAvailable(data.HddData)
        );
    }

    private readonly btnCreateColumn: (
        origin: EntityCreationOrigin
    ) => IListOption<EntityItem> = (origin) => ({
        labelKey: (data) =>
            this.getTranslateKey(EntityBurgerMenuAction.btnCreateColumn, data),
        hidden: (data) => this.isBtnCreateColumnHidden(data),
        glyphClass: 'glyph-add',
        callback: (data) =>
            this.executeCreateChild(data, ServerType.Column, origin),
    });

    private isBtnCreateColumnHidden(data: EntityItem) {
        return (
            !data?.SecurityData?.HasWriteAccess ||
            !this.isCreateColumnAvailable(data)
        );
    }

    private readonly btnClone: (
        origin: EntityCreationOrigin
    ) => IListOption<EntityItem> = (origin) => ({
        labelKey: (data) =>
            this.getTranslateKey(EntityBurgerMenuAction.btnClone, data),
        hidden: (data) => !data?.SecurityData?.HasCreateAccess,
        glyphClass: 'glyph-file-copy',
        callback: (data) =>
            this.entityUiService.onCloneEntity(
                data,
                origin ?? EntityCreationOrigin.burgerMenu
            ),
    });

    private readonly btnChangeParent: IListOption<EntityItem> = {
        labelKey: (data) =>
            this.getTranslateKey(EntityBurgerMenuAction.btnChangeParent, data),
        hidden: (data) => this.isBtnChangeParentHidden(data),
        glyphClass: 'glyph-move-child',
        callback: (data) => this.entityUiService.onChangeParent(data),
    };

    private isBtnChangeParentHidden(data: EntityItem) {
        return (
            !data?.SecurityData?.HasWriteAccess ||
            !this.isChangeParentAvailable(data)
        );
    }

    private readonly btnFusion: IListOption<EntityItem> = {
        labelKey: (data) =>
            this.getTranslateKey(EntityBurgerMenuAction.btnFusion, data),
        hidden: (data) => this.isBtnFusionHidden(data),
        glyphClass: 'glyph-fusion',
        callback: (data) => this.entityUiService.onFusionEntity(data),
    };

    private isBtnFusionHidden(data: EntityItem) {
        return (
            !data?.SecurityData?.HasWriteAccess || !this.isFusionAvailable(data)
        );
    }

    private readonly btnExport: IListOption<EntityItem> = {
        labelKey: (data) =>
            this.getTranslateKey(EntityBurgerMenuAction.btnExport, data),
        hidden: (data) => !data?.SecurityData?.HasExportAccess,
        glyphClass: 'glyph-download',
        callback: (data) => this.entityUiService.onExportEntity(data),
    };

    private readonly btnCreateLink: IListOption<EntityItem> = {
        labelKey: (data) =>
            this.getTranslateKey(EntityBurgerMenuAction.btnCreateLink, data),
        hidden: (data) => !data?.SecurityData?.HasWriteAccess,
        glyphClass: 'glyph-relation',
        callback: (data) =>
            this.linkedObjectService.openLinkCreationModal(data, undefined, {
                includeEntityLinks: true,
            }),
    };

    private readonly btnGlossaryAutoGenerate: IListOption<EntityItem> = {
        labelKey: (data) =>
            this.getTranslateKey(
                EntityBurgerMenuAction.btnGlossaryAutoGenerate,
                data
            ),
        hidden: (data) => this.isBtnGlossaryAutoGenerateHidden(data),
        glyphClass: 'glyph-metabot-glossary',
        logFunctional: (data) =>
            DataUtil.getModuleFromServerType(data.ServerType) === DgModule.Usage
                ? 'GLOSSARY_GENERATION_FROM_USAGE,R'
                : 'GLOSSARY_GENERATION_FROM_DICTIONARY,R',
        callback: (data) =>
            this.glossaryService.openGenerateFromSourceModal(
                this.currentSpaceService.currentSpace,
                DataUtil.getModuleFromServerType(data.ServerType),
                [data]
            ),
    };

    private isBtnGlossaryAutoGenerateHidden(data: EntityItem) {
        return (
            !this.moduleStore.hasAccess(DgModule.Glossary) ||
            !data?.SecurityData?.HasCreateAccess ||
            (data.EntityType != EntityType.Application &&
                !ModelerDataUtil.isModelSource(data.EntityType))
        );
    }

    private readonly btnAddToCampaign: IListOption<EntityItem> = {
        labelKey: (data) =>
            this.getTranslateKey(EntityBurgerMenuAction.btnAddToCampaign, data),
        hidden: () =>
            !this.campaignSecurityService.canCreateOrModifyCampaignSync(
                this.currentSpaceService.currentSpace
            ),
        glyphClass: 'glyph-campaign',
        callback: (data) =>
            this.campaignUiService.openCampaignSelectFormModal(
                this.currentSpaceService.currentSpace,
                [data.ReferenceId]
            ),
    };

    private readonly btnDelete: IListOption<EntityItem> = {
        labelKey: (data) =>
            this.getTranslateKey(EntityBurgerMenuAction.btnDelete, data),
        hidden: (data) => !data?.SecurityData?.HasDeleteAccess,
        glyphClass: 'glyph-delete',
        callback: (data) =>
            this.entityUiService.onDeleteEntity(
                EntityIdentifier.fromIHierarchicalData(data.HddData)
            ),
    };

    constructor(
        private entityUiService: EntityUiService,
        private glossaryService: GlossaryService,
        private currentSpaceService: CurrentSpaceService,
        private linkedObjectService: EntityLinkService,
        private campaignSecurityService: CampaignSecurityService,
        private campaignUiService: CampaignUiService,
        private moduleStore: ModuleStore
    ) {
        super();
    }

    public getBurgerMenu(
        opt: IEntityGridBurgerMenuOptions,
        additionalActions?: IListOption<EntityItem>[]
    ): IListOption<EntityItem> {
        const options: IListOption<EntityItem>[] = [];

        if (!opt.readonly) {
            options.push(...this.getActionOptions(opt.entityCreationOrigin));
        }

        if (opt.readonly && !opt.hideReadonlyActions) {
            options.push(...this.getActionsForReadOnlyCase());
        }

        if (additionalActions) {
            additionalActions.forEach(
                (opt) =>
                    (opt.categoryKey = 'UI.EntityBurgerMenu.Categories.Actions')
            );
            options.push(...additionalActions);
        }

        if (!opt.hideNavigationOptions) {
            return this.entityUiService.getEntityCellNavigationMenu(
                opt.logId,
                options
            );
        }

        return {
            tooltipTranslateKey: 'UI.Global.ttMoreOptions',
            options,
        };
    }

    private getActionOptions(origin: EntityCreationOrigin) {
        const actionCategoryKey = 'UI.EntityBurgerMenu.Categories.Actions';
        const options = [
            this.btnGlossaryAutoGenerate,
            this.btnGlossaryLinksGenerate,
            this.btnCreateLink,
            this.linkSeparator,
            this.btnCreateChild(origin),
            this.btnCreateColumn(origin),
            this.btnCreateContainer(origin),
            this.btnCreateStructure(origin),
            this.btnFusion,
            this.btnChangeParent,
            this.creationSeparator,
            this.btnClone(origin),
            this.btnExport,
            this.btnAddToCampaign,
            this.btnDelete,
        ];
        options.forEach((opt) => (opt.categoryKey = actionCategoryKey));
        return options;
    }

    private getActionsForReadOnlyCase() {
        const actionCategoryKey = 'UI.EntityBurgerMenu.Categories.Actions';
        const options = [this.btnAddToCampaign];
        options.forEach((opt) => (opt.categoryKey = actionCategoryKey));
        return options;
    }

    private getTranslateKey(
        actionId: EntityBurgerMenuAction,
        item: EntityItem
    ) {
        if (!item) {
            return;
        }
        switch (actionId) {
            case EntityBurgerMenuAction.btnExport:
                return this.entityUiService.getEntityTypeTranslationWithArticle(
                    item.HddData.EntityType,
                    'UI.Global.btnExportEntity'
                );
            default:
                return `UI.Global.${EntityBurgerMenuAction[actionId]}`;
        }
    }

    private isCreateChildAvailable(data: EntityItem) {
        return (
            EntityTypeUtil.canHaveChildren(data.HddData.EntityType) &&
            !ModelerDataUtil.isModelerServerType(data.HddData.ServerType)
        );
    }

    private isChangeParentAvailable(data: EntityItem) {
        return EntityTypeUtil.isParentUpdatable(data.HddData.EntityType);
    }

    private isCreateColumnAvailable(data: EntityItem) {
        return (
            EntityTypeUtil.canHaveChildren(data.HddData.EntityType) &&
            ModelerDataUtil.isModelerServerType(data.HddData.ServerType) &&
            !this.isCreateStructureOrContainerAvailable(data.HddData.ServerType)
        );
    }

    private isCreateStructureOrContainerAvailable(
        entityServerType: ServerType
    ) {
        return (
            entityServerType &&
            (entityServerType == ServerType.Model ||
                entityServerType == ServerType.Container)
        );
    }

    private isCreateStructureAvailable(hddData: IHierarchicalData) {
        return DataUtil.isCreateStructureAvailable(hddData);
    }

    private isFusionAvailable(data: EntityItem) {
        return (
            DataUtil.getModuleFromServerType(data.HddData.ServerType) ==
            DgModule.Glossary
        );
    }

    private async executeCreateChild(
        entity: EntityItem,
        serverType?: ServerType,
        origin?: EntityCreationOrigin
    ) {
        const entityCreationOrigin =
            origin ?? EntityCreationOrigin.burgerMenuCreateChild;
        await this.entityUiService.onCreateChild(
            entity,
            entityCreationOrigin,
            serverType
        );
    }
}

enum EntityBurgerMenuAction {
    btnCreateChild,
    btnCreateContainer,
    btnCreateStructure,
    btnCreateColumn,
    btnChangeParent,
    btnClone,
    btnFusion,
    btnExport,
    btnDelete,
    btnCreateLink,
    btnGlossaryAutoGenerate,
    btnGlossaryLinksGenerate,
    btnAddToCampaign,
}

export interface IEntityGridBurgerMenuOptions {
    logId: string;
    readonly?: boolean;
    entityCreationOrigin?: EntityCreationOrigin;
    hideNavigationOptions?: boolean;
    hideReadonlyActions?: boolean;
}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IActionOption } from '@datagalaxy/core-ui';
import { BaseCellComponent } from '@datagalaxy/core-ui/cell-components';
import { NavSpace, Space } from '@datagalaxy/webclient/workspace/data-access';

/**
 * ## Role
 * Display a workspace icon + its displayName and optional action buttons/menus
 */
@Component({
    selector: 'app-space-cell',
    templateUrl: './space-cell.component.html',
    styleUrls: ['./space-cell.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpaceCellComponent<TEntity = unknown> extends BaseCellComponent<
    TEntity,
    Space | NavSpace
> {
    @Input() space: Space | NavSpace;
    @Input() actions: IActionOption<Space | NavSpace>[];
}

import { BaseService } from '@datagalaxy/core-ui';
import { DialogPosition } from '@angular/material/dialog';
import { IModalPosition } from '@datagalaxy/ui/dialog';
import { Injectable } from '@angular/core';
import { DxyTimeSeriesModalComponent } from '../time-series/dxy-time-series-modal/dxy-time-series-modal.component';
import {
    ITimeSeriesEditModalInput,
    ITimeSeriesEditModalOutput,
    ITimeSeriesModalInput,
} from '../time-series/time-series.types';
import { DxyTimeSeriesEditModalComponent } from '../time-series/dxy-time-series-edit-modal/dxy-time-series-edit-modal.component';
import { DxyModalService } from '../shared/dialogs/DxyModalService';
import { EntityDockingPaneService } from '../shared/entity/services/entity-docking-pane.service';
import {
    CrudOperation,
    FunctionalLogService,
} from '@datagalaxy/webclient/monitoring/data-access';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';
import {
    AttributeMetaInfo,
    TimeSeriesEntry,
    TimeSeriesObject,
} from '@datagalaxy/webclient/attribute/domain';

@Injectable({ providedIn: 'root' })
export class TimeSeriesUiService extends BaseService {
    constructor(
        private dxyModalService: DxyModalService,
        private functionalLogService: FunctionalLogService,
        private entityDockingPaneService: EntityDockingPaneService
    ) {
        super();
    }

    public async openTimeSeriesModal(
        data: TimeSeriesObject,
        attributeMeta: AttributeMetaInfo,
        entity: EntityItem,
        readonly: boolean
    ) {
        this.functionalLogService.logFunctionalAction(
            'TIMESERIES_VALUES',
            CrudOperation.R
        );
        return await this.dxyModalService.open<
            DxyTimeSeriesModalComponent,
            ITimeSeriesModalInput,
            TimeSeriesObject
        >({
            componentType: DxyTimeSeriesModalComponent,
            data: {
                data,
                attributeMeta,
                entity,
                readonly,
            },
        });
    }

    public async openTimeSeriesEditModal(
        entity: EntityItem,
        entries: TimeSeriesEntry[],
        entry: TimeSeriesEntry,
        attributeMeta: AttributeMetaInfo,
        isCreate: boolean,
        position: DialogPosition | IModalPosition
    ) {
        return await this.dxyModalService.open<
            DxyTimeSeriesEditModalComponent,
            ITimeSeriesEditModalInput,
            ITimeSeriesEditModalOutput
        >({
            componentType: DxyTimeSeriesEditModalComponent,
            isFromActiveModal: true,
            position: isCreate ? null : position,
            data: {
                entry,
                entries,
                attributeMeta,
                entity,
                isCreate,
                mustIncludeQualityOnUpdate:
                    this.entityDockingPaneService.isInsightsPaneVisible(),
            },
        });
    }
}

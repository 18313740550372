import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
} from '@angular/core';
import { withLoading } from '@datagalaxy/core-ui';
import { CollectionsHelper, StringUtil } from '@datagalaxy/core-util';
import { SpaceUiService } from '../../services/space-ui.service';
import { ISpaceMemberGroup } from './space-members.types';
import { SpaceApiService } from '../../services/space-api.service';
import {
    SpaceMemberDto,
    SpaceRole,
} from '@datagalaxy/webclient/workspace/data-access';
import {
    BaseCustomTitleWidgetComponent,
    ICustomTitleWidgetComponent,
    registerWidget,
} from '@datagalaxy/webclient/dashboard/ui';
import { CurrentSpaceService } from '../../../services/currentSpace.service';
import { UserDto } from '@datagalaxy/webclient/user/domain';

/**
 * ## Role
 * Display a list of user roles, for each role, it shows
 * a user count or user collection cell
 *
 * ## Features
 * - Clicking on the header will open the space member preview
 * - Click on individual role will open the space member preview
 * focused on this role
 */
@registerWidget({
    name: 'space-members-widget',
    titleKey: 'UI.SpaceHome.Widgets.SpaceMembers.Title',
    previewImgUrl: '/images/space-widgets/space-widget-members.svg',
})
@Component({
    selector: 'app-space-members-widget',
    templateUrl: './space-members-widget.component.html',
    styleUrls: [
        '../space-widget.component.scss',
        './space-members-widget.component.scss',
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpaceMembersWidgetComponent
    extends BaseCustomTitleWidgetComponent
    implements AfterViewInit, ICustomTitleWidgetComponent
{
    protected spaceMemberGroups: ISpaceMemberGroup[];
    protected spaceMembers: UserDto[];
    protected maxUserItems = 5;

    protected get showRoles() {
        return !this.loadingValue;
    }
    protected get spaceMembersCount() {
        return this.spaceMembers?.length;
    }

    constructor(
        private spaceUiService: SpaceUiService,
        private spaceApiService: SpaceApiService,
        private currentSpaceService: CurrentSpaceService
    ) {
        super();
    }

    ngAfterViewInit() {
        this.initAsync();
    }

    protected getHideCollectionCellUserName(group: ISpaceMemberGroup) {
        return group.users.length > 1;
    }

    protected async onOpenSpaceMembersPreview() {
        await this.openSpaceMembersPreview();
    }

    protected async onSpaceMemberGroupClick(
        spaceMemberGroup: ISpaceMemberGroup
    ) {
        await this.openSpaceMembersPreview(spaceMemberGroup);
    }

    private async openSpaceMembersPreview(
        selectedSpaceMemberGroup?: ISpaceMemberGroup
    ) {
        await this.spaceUiService.openTeamMembersWidgetPreview(
            this.currentSpaceService.currentSpace,
            this.spaceMembers,
            this.spaceMemberGroups,
            selectedSpaceMemberGroup
        );
    }

    @withLoading()
    private async initAsync() {
        const currentSpace = this.currentSpaceService.currentSpace;
        const res = await this.spaceApiService.getSpaceUsers(
            currentSpace,
            true
        );

        this.spaceMembers = CollectionsHelper.orderByText(
            res.SpaceMembers,
            (o) => o.FullName
        );
        this.spaceMemberGroups = [
            SpaceRole.Administrator,
            SpaceRole.DataOwners,
            SpaceRole.DataStewards,
            SpaceRole.DpoUsers,
            SpaceRole.CdoUsers,
            SpaceRole.ExpertUsers,
            SpaceRole.CisoUsers,
        ]
            .map((role) => this.makeSpaceMemberGroup(role, res.SpaceMembers))
            .filter((group) => !!group.userCount);
    }

    private makeSpaceMemberGroup(
        role: SpaceRole,
        users: SpaceMemberDto[]
    ): ISpaceMemberGroup {
        const filteredUsers = users.filter((user) =>
            user.SpaceRoleDataList.find(
                (roleData) => roleData.SpaceRole === role
            )
        );
        const userCount = filteredUsers?.length;
        const isDefaultUser = (user: SpaceMemberDto) =>
            user.SpaceRoleDataList.find(
                (roleData) => roleData.SpaceRole === role
            )?.IsDefault;
        const orderedUsers = CollectionsHelper.orderBy(filteredUsers, [
            (user) => !isDefaultUser(user),
            (user) => StringUtil.normalizeForSearch(user.FullName),
        ]);
        const defaultUserIds = orderedUsers
            .filter((user) => isDefaultUser(user))
            .map((user) => user.UserId);
        return {
            role,
            roleKey: `DgServerTypes.BaseData.fields.${SpaceRole[role]}`,
            users: orderedUsers,
            defaultUserIds,
            userCollectionIds: orderedUsers.map((user) => user.UserId),
            userCount,
        };
    }
}

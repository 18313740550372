import { EntityDksNode } from '../../data-knowledge-studio/data-knowledge-studio.types';
import { DiagramNodeKind } from '@datagalaxy/webclient/diagram/data-access';
import { IRectPortSpec, PortKind, PortUsage } from '@datagalaxy/core-d3-util';
import { RectSide } from '@datagalaxy/core-2d-util';
import { LineageEntityStreamButtonsComponent } from '../lineage-entity-stream-buttons/lineage-entity-stream-buttons.component';
import { EntityType } from '@datagalaxy/dg-object-model';
import { DataUtil } from '../../shared/util/DataUtil';
import {
    DgModule,
    DgModuleName,
    getModuleDefinition,
} from '@datagalaxy/shared/dg-module/domain';
import { EntityNodeTree } from '../../data-knowledge-studio/nodes/entity/entity-node.types';
import { LineageGraphService } from '../lineage-graph/lineage-graph.service';
import { LineageNodeSpec } from '../lineage.types';
import { CollectionsHelper } from '@datagalaxy/core-util';
import { LineageTreeNode } from '../lineage-tree/lineage-tree-node';
import { LineageEdgeGraphBuilder } from './lineage-graph-surface-edge.builder';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';

export class LineageGraphSurfaceBuilder {
    private edgeBuilder = new LineageEdgeGraphBuilder();

    constructor(private lineageGraphService: LineageGraphService) {}

    public makeGraphNodeSpecs(treeNodes: LineageTreeNode[]) {
        const rootNodes = CollectionsHelper.distinctByProperty(
            treeNodes
                .map((treeNode) => treeNode.getVisibleRoot())
                .filter((treeNodes) => treeNodes?.visible),
            (node) => node.id
        );
        const res = this.lineageGraphService.getPrimaryNodeAndEdges();
        const primaryNodeIds = res.map((node) => node.id);

        const ids = rootNodes.flatMap((node) => [
            node.id,
            ...node.getAllChildren().map((node) => node.id),
        ]);
        const edges = this.lineageGraphService.getNodesEdges(ids);

        const visibleNodes = new Map<string, string[]>();

        edges.forEach((edge) => {
            if (
                !edge.source.getVisibleRoot() ||
                !edge.target.getVisibleRoot()
            ) {
                return;
            }
            const sourceId = edge.source.getVisibleRoot().id;
            const sourceLinks = visibleNodes.get(sourceId) || [];
            sourceLinks.push(edge.id);
            visibleNodes.set(sourceId, sourceLinks);

            const targetId = edge.target.getVisibleRoot().id;
            const targetLinks = visibleNodes.get(targetId) || [];
            targetLinks.push(edge.id);
            visibleNodes.set(targetId, targetLinks);
        });

        const filteredRootNodes = rootNodes.filter(
            (node) =>
                visibleNodes.get(node.id)?.length > 0 ||
                node.find(this.lineageGraphService.getRoot().id)
        );
        const nodeSpecs = CollectionsHelper.distinctByProperty(
            filteredRootNodes,
            (node) => node.id
        ).map((node) => this.makeGraphNodeSpec(node, primaryNodeIds));

        const edgeSpecs = this.edgeBuilder.makeGraphEdges(edges);

        const deletedNodes = rootNodes.filter((node) =>
            this.lineageGraphService.getNode(node.id)
        );

        const deletedNodes2 = rootNodes.flatMap((node) => {
            return node
                .getAllChildren()
                .filter((node) => this.lineageGraphService.getNode(node.id));
        });

        return {
            added: {
                nodes: nodeSpecs,
                edges: edgeSpecs,
            },
            deleted: {
                nodes: {
                    nodes: [...deletedNodes, ...deletedNodes2],
                },
            },
        };
    }

    private makeGraphNodeSpec(
        node: LineageTreeNode,
        primaryNodeIds: string[]
    ): EntityDksNode<LineageNodeSpec> {
        const item = node.entityIdentifier;

        return {
            id: item.ReferenceId,
            type: DiagramNodeKind.Entity,
            entityIdr: item,
            locked: false,
            color: this.getColor(item.entityType),
            sizeMode: 'medium',
            width: 300,
            cssClass: this.getClassName(node),
            children: node.opened
                ? this.makeEntityNodeTree(node, primaryNodeIds)
                : [],
            hasChildren: node.hasChildren,
            childrenCount: node.children.length,
            opened: node.opened,
            ports: this.getNodePorts(node),
            customChild: {
                component: LineageEntityStreamButtonsComponent,
                inputs: {
                    entityIdr: item,
                },
            },
            noAccessData: node.noAccessData,
        };
    }

    private getClassName(node: LineageTreeNode) {
        const item = node.entityIdentifier;
        const rootNode = this.lineageGraphService.getRoot()?.getClosestOpened();
        const isRoot = rootNode?.id === item.ReferenceId;
        const isPrimary = this.isTreeNodePrimary(node);
        const classNames = [
            isRoot ? `root ${this.getModuleColor(item.entityType)}` : '',
            isPrimary ? 'primary' : '',
        ];

        return classNames.join(' ').trim();
    }

    private makeEntityNodeTree(
        treeNode: LineageTreeNode,
        primaryNodeIds: string[]
    ): EntityNodeTree[] {
        const treeNodes = treeNode.visibleChildren;
        return treeNodes.map((treeNode) => {
            const entity = treeNode.entityIdentifier as EntityItem;

            return {
                entity,
                parent: treeNode.parentId,
                opened: treeNode.opened,
                className: this.getClassName(treeNode),
                children: treeNode.opened
                    ? this.makeEntityNodeTree(treeNode, primaryNodeIds)
                    : [],
                hasChildren: treeNode.hasChildren,
                childrenCount: treeNode.children.length,
                customChild: {
                    component: LineageEntityStreamButtonsComponent,
                    inputs: {
                        entityIdr: entity,
                    },
                },
                noAccess: !entity.HddData.Data.HasReadAccess,
            };
        });
    }

    private getColor(entityType: EntityType) {
        const module = DataUtil.getModuleFromEntityType(entityType);

        const moduleDefinition = getModuleDefinition(
            DgModule[module] as DgModuleName
        );
        return moduleDefinition.graphicalColor;
    }

    private getModuleColor(entityType: EntityType) {
        const module = DataUtil.getModuleFromEntityType(entityType);
        const moduleDefinition = getModuleDefinition(
            DgModule[module] as DgModuleName
        );
        return moduleDefinition.colorGlyphClass;
    }

    private isTreeNodePrimary(treeNode: LineageTreeNode) {
        const isPrimary = this.lineageGraphService.isPrimaryNode(
            treeNode.entityIdentifier.ReferenceId
        );

        if (isPrimary) {
            return true;
        }
        if (treeNode.closed) {
            return treeNode
                .getAllChildren()
                .some((child) =>
                    this.lineageGraphService.isPrimaryNode(
                        child.entityIdentifier.ReferenceId
                    )
                );
        }
        return false;
    }

    private getNodePorts(node: LineageTreeNode): IRectPortSpec[] {
        const childrenPorts = node
            .getAllVisibleExpandedChildren()
            .map((nodeTree) => ({
                kind: PortKind.custom,
                usage: PortUsage.none,
                sides: [RectSide.right, RectSide.left],
                id: nodeTree.id,
                margin: { left: 6 },
            }));

        const showMorePorts = [node, ...node.getAllExpandedChildren()]
            .filter(
                (nodeTree) =>
                    nodeTree.visibleChildren?.length <
                        nodeTree.children.length && nodeTree.opened
            )
            .map((nodeTree) => ({
                kind: PortKind.custom,
                usage: PortUsage.none,
                sides: [RectSide.right, RectSide.left],
                id: `show-more-${nodeTree.id}`,
                margin: { left: 6 },
            }));

        return [
            {
                kind: PortKind.custom,
                usage: PortUsage.none,
                sides: [RectSide.right, RectSide.left],
                id: node.id,
                margin: { left: 6 },
            },
            ...childrenPorts,
            ...showMorePorts,
        ];
    }
}

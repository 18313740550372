import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import {
    IUiImageInputParams,
    UiImageInputMode,
} from '@datagalaxy/core-ui/image-input';
import { CropMode } from '@datagalaxy/core-doka-util';
import {
    MatLegacyTabChangeEvent as MatTabChangeEvent,
    MatLegacyTabGroup as MatTabGroup,
} from '@angular/material/legacy-tabs';
import { CollectionsHelper } from '@datagalaxy/core-util';
import { IDxyPreviewPaneContent } from '../../shared/shared-ui/dxy-preview-panel-slider/dxy-preview-panel-slider.types';
import {
    ITeamMembersCountUpdate,
    ITeamRequestsCountUpdate,
    TeamService,
} from '../team.service';
import { AppDataService } from '../../services/app-data.service';
import { PreviewPanelService } from '../../shared/shared-ui/preview-panel.service';
import {
    TeamAccessType,
    TeamDto,
    TeamMemberDto,
    TeamMembershipRequest,
} from '@datagalaxy/webclient/team/data-access';
import {
    CrudOperation,
    FunctionalLogService,
} from '@datagalaxy/webclient/monitoring/data-access';
import { DxyBaseComponent } from '@datagalaxy/ui/core';

@Component({
    selector: 'app-team-preview',
    templateUrl: 'team-preview.component.html',
    styleUrls: ['team-preview.component.scss'],
})
export class TeamPreviewComponent
    extends DxyBaseComponent
    implements IDxyPreviewPaneContent<TeamDto>, OnInit, AfterViewInit
{
    @Input() teamData: TeamDto;
    @Input() defaultTabIndex: number;
    @Input() showJoinBtn: boolean;
    @Input() isAdmin: boolean;

    @Output() readonly onReady = new EventEmitter<TeamDto>();

    @ViewChild(MatTabGroup) matTabGroup: MatTabGroup;

    public teamMembers: TeamMemberDto[];
    public teamRequests: TeamMembershipRequest[];
    public imageParams: IUiImageInputParams;
    public isMembershipRequestsTabVisible = false;

    public hasEditRights: boolean;

    constructor(
        private teamService: TeamService,
        private appDataService: AppDataService,
        private previewPanelService: PreviewPanelService,
        private functionalLogService: FunctionalLogService
    ) {
        super();
    }

    ngOnInit() {
        this.initMembersData().then();

        const currentUserId = this.appDataService.currentUserId;
        this.hasEditRights =
            this.isAdmin ||
            this.teamData.TeamOwners.some(
                (owner) => owner.UserId == currentUserId
            );
        this.isMembershipRequestsTabVisible =
            this.teamData.AccessType == TeamAccessType.Limited &&
            this.hasEditRights;

        this.imageParams = {
            mode: this.hasEditRights
                ? UiImageInputMode.modalEdit
                : UiImageInputMode.noEdit,
            crop: CropMode.round,
            onDone: (file) =>
                this.teamService.setTeamImage(this.teamData, file),
            deleteImage: () =>
                this.teamService.setTeamImage(this.teamData, null, true),
            placeHolderClass: () =>
                this.teamService.getTeamGlyphClass(this.teamData, true),
            getImageUrl: () =>
                this.teamService.getTeamImageUrl(this.teamData) ?? '',
            editSize: 90,
        };

        this.onReady.emit(this.teamData);
        super.subscribe(this.teamService.membersCountChanged$, async (result) =>
            this.onMembersCountUpdate(result)
        );
        super.subscribe(this.teamService.requestsCountChanged$, (result) =>
            this.onRequestCountUpdate(result)
        );
        super.subscribe(this.teamService.teamChange$, (team) =>
            this.onTeamDetailsUpdate(team)
        );
    }

    ngAfterViewInit() {
        if (this.defaultTabIndex && this.matTabGroup) {
            this.matTabGroup.selectedIndex = this.defaultTabIndex;
        }
    }

    public onCloseClick() {
        this.previewPanelService.hidePanel();
    }

    public onChangeTab($event: MatTabChangeEvent) {
        let featureCode = '';
        switch ($event.index) {
            case 0:
                featureCode = 'TEAM_PROFILE';
                break;
            case 1:
                featureCode = 'TEAM_MEMBERS';
                break;
            case 2:
                featureCode = 'TEAM_ACCESS_REQUEST';
                break;
        }
        this.functionalLogService.logFunctionalAction(
            featureCode,
            CrudOperation.R
        );
    }

    private async onMembersCountUpdate(
        membersCountUpdate: ITeamMembersCountUpdate
    ) {
        if (this.teamData.TeamUid != membersCountUpdate.teamId) {
            return;
        }
        await this.initMembersData();
        this.teamData.MembersCount = membersCountUpdate.updatedCount;
        this.teamData.TeamOwners = this.teamData.TeamOwners.filter(
            (owner) =>
                !membersCountUpdate.deletedMembersId?.some(
                    (deletedMemberId) => owner.UserId == deletedMemberId
                )
        );
    }

    private onRequestCountUpdate(
        requestsCountUpdate: ITeamRequestsCountUpdate
    ) {
        if (this.teamData.TeamUid == requestsCountUpdate.teamId) {
            this.teamData.MembersCount =
                requestsCountUpdate.updatedMembers.length;
            this.teamMembers = requestsCountUpdate.updatedMembers;
            this.teamRequests = requestsCountUpdate.updatedRequests;
        }
    }

    private async initMembersData() {
        const result = await this.teamService.getTeamMembers(
            this.teamData.TeamUid
        );
        this.teamMembers = result.TeamMembers;
        this.teamRequests = result.MembershipRequests;
    }

    private async onTeamDetailsUpdate(updatedTeam: TeamDto) {
        this.imageParams.placeHolderClass = this.teamService.getTeamGlyphClass(
            this.teamData,
            true
        );

        const hasSameOwners = CollectionsHelper.contentEquals(
            updatedTeam.TeamOwners,
            this.teamData.TeamOwners,
            false,
            true,
            (m1, m2) => m1.UserId == m2.UserId
        );
        this.teamData = updatedTeam;

        if (hasSameOwners) {
            return;
        }
        this.teamMembers = this.teamMembers.map((member) => {
            member.IsTeamOwner = updatedTeam.TeamOwners.some(
                (owner) => owner.UserId == member.UserId
            );
            return member;
        });
    }
}

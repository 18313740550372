import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnChanges,
} from '@angular/core';
import { GlyphUtil } from '../../../../../shared/util/GlyphUtil';
import { NodeExtraDataComponent } from '../entity-node-extra-data.component';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';
import { EntityLifecycleStatus } from '@datagalaxy/webclient/attribute/domain';

@Component({
    selector: 'app-entity-node-status',
    templateUrl: './entity-node-status.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EntityNodeStatusComponent
    extends DxyBaseComponent
    implements NodeExtraDataComponent, OnChanges
{
    @Input() entity: EntityItem;
    @Input() attributeKey: string;

    protected status: EntityLifecycleStatus;

    protected get glyph() {
        return GlyphUtil.getStatusGlyphClass(this.status);
    }
    protected get statusKey() {
        const status = EntityLifecycleStatus[this.status];
        return `DgServerTypes.EntityLifecycleStatus.${status}`;
    }

    constructor() {
        super();
    }

    ngOnChanges() {
        const statusStr = this.entity.getAttributeValue<string>(
            this.attributeKey
        );
        this.status = EntityLifecycleStatus[statusStr];
    }
}

import { Injectable } from '@angular/core';
import {
    FilteredViewApiService,
    FilteredViewsUtil,
} from '@datagalaxy/webclient/filter/data-access';
import { DataUtil } from '../../util/DataUtil';
import { CurrentSpaceService } from '../../../services/currentSpace.service';
import { EntityService } from '../../entity/services/entity.service';
import { SearchService } from '../../../search/search.service';
import { getLocalId } from '@datagalaxy/webclient/utils';
import { NavigationService } from '../../../services/navigation.service';
import { DxyModalService } from '../../dialogs/DxyModalService';
import { ModelerDataUtil } from '../../util/ModelerDataUtil';
import { FilteredViewDto } from '@datagalaxy/webclient/filter/domain';
import { DgZone } from '@datagalaxy/webclient/domain';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';
import { DgModule } from '@datagalaxy/shared/dg-module/domain';

@Injectable({ providedIn: 'root' })
export class FilteredViewUiService {
    private get space() {
        return this.currentSpaceService.currentSpace;
    }

    constructor(
        private currentSpaceService: CurrentSpaceService,
        private entityService: EntityService,
        private searchService: SearchService,
        private navigationService: NavigationService,
        private dxyModalService: DxyModalService,
        private filteredViewApiService: FilteredViewApiService
    ) {}

    public async loadCurrentVersionFilteredViewObjects(
        fv: FilteredViewDto
    ): Promise<{ TotalCount: number; Entities: EntityItem[] }> {
        if (!fv) {
            return;
        }
        fv.SpaceUid = getLocalId(this.space.DataReferenceId);
        fv.DefaultVersionId = this.space.versionId;
        switch (fv.DgZone) {
            case DgZone.Module: {
                const dgModule = FilteredViewsUtil.dgModuleFromModuleName(
                    fv.ModuleName
                );
                const serverTypes =
                    dgModule == DgModule.Catalog
                        ? ModelerDataUtil.modelerServerTypes
                        : [DataUtil.getDefaultServerTypeFromModule(dgModule)];
                const filters = fv?.ListFilter;
                const result = await this.entityService.getEntitiesForFlat(
                    this.space,
                    serverTypes,
                    0,
                    45,
                    true,
                    undefined,
                    filters
                );
                return result;
            }
            case DgZone.Search: {
                const result =
                    await this.searchService.getFilteredViewSearchEntities(fv);
                return result;
            }
        }
        return { TotalCount: 0, Entities: [] };
    }

    public async navigateTo(fv: FilteredViewDto) {
        await this.navigationService.goToFilteredView(fv, this.space);
    }

    public async openPublishModal(fv: FilteredViewDto) {
        const confirm = await this.dxyModalService.confirm({
            titleKey: 'UI.NavBar.filteredViews.publishModalTitle',
            messageKey: 'UI.NavBar.filteredViews.publishModalMessage',
            confirmButtonKey: 'UI.Global.btnPublish',
        });
        if (confirm) {
            await this.filteredViewApiService.publish(fv);
        }
        return confirm;
    }
}

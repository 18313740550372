import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnChanges,
} from '@angular/core';
import { GlyphUtil } from '../../../../../shared/util/GlyphUtil';
import { NodeExtraDataComponent } from '../entity-node-extra-data.component';
import { DataQualityResult } from '@datagalaxy/webclient/data-quality/data-access';
import { StringUtil } from '@datagalaxy/core-util';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';

@Component({
    selector: 'app-entity-node-data-quality',
    templateUrl: './entity-node-data-quality.component.html',
    styleUrls: ['./entity-node-data-quality.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EntityNodeDataQualityComponent
    extends DxyBaseComponent
    implements NodeExtraDataComponent, OnChanges
{
    @Input() entity: EntityItem;
    @Input() attributeKey: string;

    protected dataQualityStatus: DataQualityResult;

    protected get glyph() {
        return GlyphUtil.getDataQualityGlyphClass(this.dataQualityStatus);
    }
    protected get dataQualityKey() {
        return `DgServerTypes.DataQualityStatusEnum.${StringUtil.capitalize(
            this.dataQualityStatus
        )}`;
    }
    protected get hasDataQuality() {
        return this.dataQualityStatus !== undefined;
    }

    constructor() {
        super();
    }

    ngOnChanges() {
        const dataQualityStr = this.entity.getAttributeValue<string>(
            this.attributeKey
        );
        this.dataQualityStatus =
            dataQualityStr?.toLowerCase() as DataQualityResult;
    }
}

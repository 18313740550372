<dxy-navigation-panel
    *ngIf="navPanelParams"
    [params]="navPanelParams"
    [(panelOpened)]="panelOpened"
    (panelOpenedChange)="onPanelToggle()"
    (itemClick)="onViewClick($event)"
>
    <ng-template let-data="data" #extraInfos>
        <ng-container *ngIf="asIPanelFilteredView(data) as fv">
            <div
                *ngIf="fv.entitiesCount != null; else spinner"
                class="dg5-badge entities-count"
            >
                {{ fv.entitiesCount | numberFormat }}
            </div>
            <ng-template #spinner>
                <dxy-spinner *ngIf="!fv.IsDefault"></dxy-spinner>
            </ng-template>
        </ng-container>
    </ng-template>
</dxy-navigation-panel>

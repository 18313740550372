import { IEntityIdentifier } from '@datagalaxy/dg-object-model';
import { LineageTreeNode } from '../lineage-tree/lineage-tree-node';

export enum LineageTrackerColor {
    Red = 'red',
    Blue = 'blue',
}

export interface LineageTrackerColorState {
    entityIdr: IEntityIdentifier;
    virtualEntityIdr: IEntityIdentifier;
    nodes: LineageTreeNode[];
    edgesIds: string[];
}

export interface LineageTrackerState {
    red?: LineageTrackerColorState;
    blue?: LineageTrackerColorState;
}

import { NgModule } from '@angular/core';
import { DevPreviewOptionListComponent } from './dev-preview-options-list/dev-preview-options-list.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
    imports: [SharedModule],
    declarations: [DevPreviewOptionListComponent],
    exports: [DevPreviewOptionListComponent],
})
export class DevPreviewOptionsModule {}

import { NgModule } from '@angular/core';
import { DxyOrganizationDashboardComponent } from './dxy-organization-dashboard/dxy-organization-dashboard.component';
import { SharedModule } from '../shared/shared.module';
import { SharedWidgetModule } from '../widgets-dashboard/shared-widget.module';
import { SpaceModule } from '../space/space.module';

@NgModule({
    imports: [SharedModule, SharedWidgetModule, SpaceModule],
    declarations: [DxyOrganizationDashboardComponent],
    exports: [DxyOrganizationDashboardComponent],
})
export class DxyOrganizationDashboardModule {}

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { ISuggestionRow, ISuggestionUserChoice } from '../suggestion.types';
import { map } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { SuggestionService } from '../suggestion.service';
import { EntityCardCellComponent } from '../../shared/entityCard/entity-card/entity-card-cell.component';
import { IActionOption, withLoading } from '@datagalaxy/core-ui';
import { EntityUiService } from '../../shared/entity/services/entity-ui.service';
import { SuggestionCellComponent } from '../suggestion-cell/suggestion-cell.component';
import { SuggestionType } from '@datagalaxy/webclient/suggestion/types';
import {
    CrudOperation,
    FunctionalLogService,
} from '@datagalaxy/webclient/monitoring/data-access';
import { ISuggestionLinkSearchParameters } from '@datagalaxy/webclient/suggestion/ui';
import { SpaceIdentifier } from '@datagalaxy/webclient/workspace/utils';
import { LinkSuggestionOrder } from '@datagalaxy/webclient/suggestion/domain';
import {
    GridCellType,
    GridComponent,
    GridConfig,
    TColDef,
} from '@datagalaxy/ui/grid';

/**
 * ## Role
 * Display a list of link suggestions
 */
@Component({
    selector: 'app-suggestion-panel-links',
    templateUrl: './suggestion-panel-links.component.html',
    styleUrls: ['./suggestion-panel-links.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SuggestionPanelLinksComponent
    extends DxyBaseComponent
    implements OnInit, OnChanges
{
    @Input() spaceIdr: SpaceIdentifier;

    @ViewChild(GridComponent) gridComponent: GridComponent;

    protected rows: ISuggestionRow[] = [];
    protected readonly SuggestionType = SuggestionType;
    protected showMoreButton = true;
    protected gridConfig: GridConfig<ISuggestionRow> = {
        getItemId: (row) => row.suggestion?.id,
    };
    protected columns: TColDef<ISuggestionRow>[] = [
        {
            id: 'hierarchicalData',
            headerLabel: this.translate.instant(
                'UI.Suggestions.Panel.ColumnTitle.Objects'
            ),
            sortable: false,
            customCellComponent: EntityCardCellComponent,
            getInputs: (row: ISuggestionRow) => {
                return {
                    breadcrumbOpenPreview: true,
                    forceIncludeSpace: true,
                    hierarchicalData: row.suggestion.entity,
                    actions: this.getEntityActions(),
                };
            },
        },
        {
            id: 'LinkType',
            headerLabel: this.translate.instant(
                'UI.Suggestions.Panel.ColumnTitle.linkType'
            ),
            sortable: false,
            type: GridCellType.text,
            getValue: (row) => {
                return this.translate.instant(row.suggestion.title);
            },
            width: 140,
        },
        {
            id: 'Target',
            headerLabel: this.translate.instant(
                'UI.Suggestions.Panel.ColumnTitle.linkedObject'
            ),
            sortable: false,
            customCellComponent: SuggestionCellComponent,
            getInputs: (row: ISuggestionRow) => ({
                showGroupDisplayName: false,
                featureCodePrefix: 'SUGGESTIONS_CENTER_',
                suggestion: row.suggestion,
                accepted: row.accepted,
            }),
        },
    ];

    private static loadingSize = 7;
    private suggestionLoadParameters: ISuggestionLinkSearchParameters;

    protected get placeholderVisible$() {
        return this.gridHidden$;
    }

    protected get gridHidden$() {
        return this.loading$.pipe(
            map((loading) => {
                return !this.hasSuggestions && !loading;
            })
        );
    }

    protected get isMainSpinnerVisible$() {
        return this.loading$.pipe(
            map((loading) => {
                return !this.hasSuggestions && loading;
            })
        );
    }

    protected get allSuggestionsBeingApplied$() {
        return this.suggestionService.applyAllLoading$;
    }

    protected get hasSuggestions() {
        return this.rows?.length > 0;
    }

    constructor(
        private entityUiService: EntityUiService,
        private translate: TranslateService,
        private suggestionService: SuggestionService,
        private functionalLogService: FunctionalLogService,
        private cd: ChangeDetectorRef
    ) {
        super();
    }

    ngOnChanges(changes: SimpleChanges) {
        super.onChange(changes, 'spaceIdr', () => this.initLoadData());
    }

    ngOnInit() {
        void this.initAsync();
    }

    @withLoading()
    public async loadMore() {
        this.functionalLogService.logFunctionalAction(
            'SUGGESTIONS_CENTER_SHOW_MORE_LINKS',
            CrudOperation.R
        );
        await this.loadData();
    }

    protected async onParameterChange(params: ISuggestionLinkSearchParameters) {
        let featureCode = `SUGGESTIONS_CENTER_SELECT_LINK_TYPE`;
        if (this.suggestionLoadParameters.sort !== params.sort) {
            if (params.sort === LinkSuggestionOrder.Score)
                featureCode = `SUGGESTIONS_CENTER_SORT_BY_CONFIDENCE_SCORE`;
            else featureCode = `SUGGESTIONS_CENTER_SORT_BY_RECENT_DATE`;
        }

        this.functionalLogService.logFunctionalAction(
            featureCode,
            CrudOperation.R
        );

        this.suggestionLoadParameters.sort = params.sort;
        this.suggestionLoadParameters.linkType = params.linkType;
        this.rows = [];
        await this.loadData();
    }

    private async initAsync() {
        this.functionalLogService.logFunctionalAction(
            `SUGGESTIONS_CENTER_LINKS`,
            CrudOperation.R
        );

        this.suggestionService.userChoice$.subscribe((choice) => {
            this.updateSuggestion(choice);
        });

        await this.initLoadData();
    }

    private async initLoadData() {
        this.suggestionLoadParameters = {
            sort: LinkSuggestionOrder.Date,
            linkType: null,
        };
        this.rows = [];
        await this.loadData();
    }

    @withLoading()
    private async loadData() {
        const loadedSuggestions =
            await this.suggestionService.getAllLinkSuggestions(
                this.spaceIdr?.spaceId,
                this.spaceIdr?.versionId,
                this.defineOffset(),
                SuggestionPanelLinksComponent.loadingSize,
                this.suggestionLoadParameters.sort,
                this.suggestionLoadParameters.linkType
            );
        this.rows.push(...loadedSuggestions);

        this.showMoreButton =
            loadedSuggestions.length >=
            SuggestionPanelLinksComponent.loadingSize;
        this.refreshGrid();
        this.scrollToBottom();
    }

    private defineOffset() {
        return this.rows.filter(
            (o) => o.accepted === null || o.accepted === undefined
        ).length;
    }

    private getEntityActions(): IActionOption<ISuggestionRow>[] {
        const option: IActionOption<ISuggestionRow> =
            this.entityUiService.getEntityCellNavigationMenu(
                'SUGGESTIONS_CENTER'
            );

        return [option];
    }

    private updateSuggestion(choice: ISuggestionUserChoice) {
        for (const id of choice.ids) {
            const row = this.rows.find((r) => r.suggestion.id == id);
            if (!row) {
                continue;
            }
            row.accepted = choice.accepted;
        }
        this.refreshGrid();
    }

    private refreshGrid() {
        this.rows = [...this.rows];
        this.cd.detectChanges();
    }

    private scrollToBottom() {
        const lastRow = this.rows?.slice(-1)[0];
        if (!lastRow) {
            return;
        }
        this.gridComponent?.scrollToIndex(lastRow.suggestion.id);
        this.cd.detectChanges();
    }
}

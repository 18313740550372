import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    NgZone,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ColDef } from 'ag-grid-community';
import { OmniGridCore } from '../OmniGridCore';
import {
    IOmniGridApi,
    IOmniGridColumnDef,
    IOmniGridDataInfo,
    IOmniGridExportOptions,
    IOmniGridState,
    OmniGridSortModel,
} from '../OmniGridTypes';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { DxyTooltipService } from '@datagalaxy/ui/tooltip';

/**
 * ## Role
 * Grid with advanced capabilities
 */
@Component({
    selector: 'dxy-omni-grid',
    templateUrl: './omni-grid.component.html',
    styleUrls: ['./omni-grid.component.scss'],
})
export class DxyOmniGridComponent<TEntity>
    extends DxyBaseComponent
    implements IOmniGridApi<TEntity>, OnChanges, OnInit, OnDestroy
{
    @Input() data: IOmniGridDataInfo<TEntity>;
    @Output() onReady = new EventEmitter<IOmniGridApi<TEntity>>();
    @Output() onRowClick = new EventEmitter<{
        data: TEntity;
        refreshRow?: (newData: TEntity) => void;
    }>();
    @Output() onSortChange = new EventEmitter<OmniGridSortModel>();
    @Output() onSelectionChange = new EventEmitter<void>();
    @Output() onColumnsChange = new EventEmitter<void>();

    public get isReady() {
        return this.core.isReady;
    }
    public get gridOptions() {
        return this.core.gridOptions;
    }
    public get isRowSelectSingle() {
        return this.core.isRowSelectSingle;
    }
    public get isAutoHeight() {
        return this.core.isAutoHeight;
    }
    public get isTree() {
        return this.core.isTree;
    }
    public get isSingleColumn() {
        return this.core.isSingleColumn;
    }
    public get dragDrop() {
        return this.data?.dragDrop;
    }

    private core: OmniGridCore<TEntity>;

    constructor(
        private elementRef: ElementRef<HTMLElement>,
        private tooltipService: DxyTooltipService,
        translate: TranslateService,
        ngZone: NgZone
    ) {
        super();
        this.core = new OmniGridCore<TEntity>(
            translate,
            ngZone,
            (gridApi) => this.onReady.emit(gridApi),
            (data, refreshRow) => this.onRowClick.emit({ data, refreshRow }),
            (sortModel: OmniGridSortModel) => this.onSortChange.emit(sortModel),
            () => this.onSelectionChange.emit(),
            () => this.onColumnsChange.emit(),
            (el, message) => this.tooltipService.setTooltip(el, message),
            (el) => this.tooltipService.removeTooltip(el),
            (els) => this.tooltipService.removeTooltips(els),
            (...args: any[]) => this.log(...args)
        );
    }

    ngOnChanges(changes: SimpleChanges) {
        super.onChange(changes, 'data', () => this.asyncInit());
    }

    ngOnInit() {
        this.asyncInit();
    }

    ngOnDestroy() {
        this.core.destroy();
    }

    private async asyncInit() {
        if (this.data?.debug) {
            this.debug = true;
        }
        if (this.data?.logId) {
            this.logId = this.data.logId;
        }
        await this.core.init(this.elementRef.nativeElement, this.data);
    }

    //#region IOmniGridApi
    /** refresh displayed cells content and layout */
    refreshView() {
        return this.core.refreshView();
    }
    expandCollapseAll(isExpanded: boolean) {
        return this.core.expandCollapseAll(isExpanded);
    }
    setRowSelected(rowId: string, isSelected = true, clearSelection = false) {
        return this.core.setRowSelected(rowId, isSelected, clearSelection);
    }
    setRowsSelected(
        rowIds: string[],
        isSelected = true,
        clearSelection = false
    ) {
        return this.core.setRowsSelected(rowIds, isSelected, clearSelection);
    }
    clearSelection() {
        return this.core.clearSelection();
    }
    clearFocusedCell() {
        return this.core.clearFocusedCell();
    }
    forceNodeRefresh(
        rowId: string,
        hasContextualChildren?: (entity: TEntity) => boolean,
        getContextualAllLevelChildrenCount?: (entity: TEntity) => number
    ) {
        return this.core.forceNodeRefresh(
            rowId,
            hasContextualChildren,
            getContextualAllLevelChildrenCount
        );
    }
    getRowData(rowId: string) {
        return this.core.getRowData(rowId);
    }
    setRowData(rowId: string, newData: TEntity) {
        return this.core.setRowData(rowId, newData);
    }
    setRowsData(rowsIdAndData: Map<string, TEntity>) {
        return this.core.setRowsData(rowsIdAndData);
    }
    removeRows(predicate: (rowData: any) => boolean) {
        return this.core.removeRows(predicate);
    }
    updateRows(predicate: (rowData: any) => boolean) {
        return this.core.updateRows(predicate);
    }
    refreshAllRows(preserveExpandedRows = false) {
        return this.core.refreshAllRows(preserveExpandedRows);
    }
    refreshAllCells(columns: string[]) {
        return this.core.refreshAllCells(columns);
    }
    refreshNodeOnAction(parentId: string, preserveExpandedRows = false) {
        return this.core.refreshNodeOnAction(parentId, preserveExpandedRows);
    }
    updateNodeData(nodeId: string, newNodeData: TEntity) {
        return this.core.updateNodeData(nodeId, newNodeData);
    }
    setRowsExpanded(
        rowIds: string | string[],
        isExpanded = true,
        chained = false
    ) {
        return this.core.setRowsExpanded(rowIds, isExpanded, chained);
    }
    refreshForParentChanged(
        rowId: string,
        newParentId: string,
        preserveExpandedRows = false
    ) {
        return this.core.refreshForParentChanged(
            rowId,
            newParentId,
            preserveExpandedRows
        );
    }
    refreshForParentsChanged(
        rowsIdAndNewParentId: Map<string, string>,
        preserveExpandedRows?: boolean
    ) {
        return this.core.refreshForParentsChanged(
            rowsIdAndNewParentId,
            preserveExpandedRows
        );
    }
    refreshForRemoved(
        rowIds: string[],
        areChildrenReParentedToRoot = false,
        preserveExpandedRows = false
    ) {
        return this.core.refreshForRemoved(
            rowIds,
            areChildrenReParentedToRoot,
            preserveExpandedRows
        );
    }
    scrollToNode(rowId: string) {
        return this.core.scrollToNode(rowId);
    }
    sizeColumnsToFit() {
        return this.core.sizeColumnsToFit();
    }
    getSelectedRows() {
        return this.core.getSelectedRows();
    }
    setColumnVisible(colIds: string | string[], visible: boolean) {
        return this.core.setColumnVisible(colIds, visible);
    }
    setColumnDefs(
        colDefs: (IOmniGridColumnDef | ColDef)[],
        deltaColumnMode?: boolean
    ) {
        return this.core.setColumnDefs(colDefs, deltaColumnMode);
    }
    getState() {
        return this.core.getState();
    }
    setState(gridState: IOmniGridState) {
        return this.core.setState(gridState);
    }
    resetState() {
        return this.core.resetState();
    }
    refreshCellsLayout() {
        return this.core.refreshCellsLayout();
    }
    getFirstData() {
        return this.core.getFirstData();
    }
    getExpandedRowIds() {
        return this.core.getExpandedRowIds();
    }
    updateGridData() {
        return this.core.updateGridData();
    }
    export(options: IOmniGridExportOptions) {
        return this.core.export(options);
    }
    getRowPosition(rowId: string) {
        return this.core.getRowPosition(rowId);
    }
    //#endregion IOmniGridApi
}

import { Component } from '@angular/core';
import { MultilingualFunnelService } from '../funnel/multilingual-funnel.service';

@Component({
    selector: 'dxy-multilingual-activator',
    templateUrl: './multilingual-activator.component.html',
})
export class MultilingualActivatorComponent {
    constructor(private funnelService: MultilingualFunnelService) {}
    protected activate() {
        this.funnelService.startFunnel();
    }
}

import { Component } from '@angular/core';
import { IHyperlinkModel } from '@datagalaxy/core-ui/fields';
import { BaseCellComponent } from '@datagalaxy/core-ui/cell-components';
import { fromSerializedServerHyperlinkModel } from '../../../fields/field-hyperlink.utils';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';

/**
 * ## Role
 * Display an html link from an entity attribute in an omnigrid
 */
@Component({
    selector: 'app-dg-attribute-html-link-model-cell',
    templateUrl: 'dg-attribute-html-link-model-cell.component.html',
})
export class DgAttributeHtmlLinkModelCellComponent extends BaseCellComponent<
    EntityItem,
    string
> {
    public href?: string;
    public text?: string;

    constructor() {
        super();
    }

    protected override updateBindings() {
        const data: IHyperlinkModel = fromSerializedServerHyperlinkModel(
            this.value
        );
        const url = data?.url;
        this.text = data?.name || url || '';
        this.href = url;
    }
}

import { DashboardSection, WidgetInstance } from '../../../domain';
import { DashboardState } from '../dashboard-state';
import { sectionReducer } from './section-reducer';

export function updateWidgetConfigurationReducer(
    state: DashboardState,
    section: DashboardSection,
    wi: WidgetInstance,
    configuration: unknown
) {
    const newWidgetInstances = [
        ...section.widgetInstances.map((w) => {
            if (w.guid == wi.guid) {
                return {
                    ...w,
                    configuration,
                };
            }
            return w;
        }),
    ];
    return sectionReducer(state, section, {
        widgetInstances: newWidgetInstances,
    });
}

import { DksGraphSurface } from '../data-knowledge-studio.types';
import { RichTooltipContentHelper } from '@datagalaxy/core-ui';
import { DksTooltipsOptions } from '../config/dks-config.types';
import { IGraphEdgeHoverEvent } from '@datagalaxy/core-d3-util';
import { ITooltipContentShowParams } from '@datagalaxy/ui/tooltip';

export class DksTooltip<NodeData, EdgeData> {
    public get showHide$() {
        return this.richTextContentHelper.showHide$;
    }

    private richTextContentHelper =
        new RichTooltipContentHelper<ITooltipContentShowParams>(250);

    constructor(
        graphSurface: DksGraphSurface<NodeData, EdgeData>,
        private config?: DksTooltipsOptions<NodeData, EdgeData>
    ) {
        graphSurface.events.edgeHovered$.subscribe((event) =>
            this.onEdgeHovered(event)
        );
    }

    private onEdgeHovered(event: IGraphEdgeHoverEvent<NodeData, EdgeData>) {
        if (event.show) {
            const text = this.config?.showEdgeTooltip(event.edge);
            if (!text) {
                return;
            }
            this.richTextContentHelper.show(event.event, text);
        } else {
            this.richTextContentHelper.hide();
        }
    }
}

import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnChanges,
} from '@angular/core';
import {
    baseMiniTagCollectionCellInputs,
    DxyColorPointCellComponent,
    ICollectionCellInputs,
    ICollectionCellOption,
    ICollectionCellParams,
    IColorPointCellData,
} from '@datagalaxy/core-ui';
import { TranslateService } from '@ngx-translate/core';
import { AttributeDataService } from '../../../../../shared/attribute/attribute-data.service';
import { NodeExtraDataComponent } from '../entity-node-extra-data.component';
import { from, map, Observable } from 'rxjs';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';
import { AttributeTagDTO } from '@datagalaxy/webclient/attribute/domain';

@Component({
    selector: 'app-entity-node-tags',
    templateUrl: './entity-node-tags.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EntityNodeTagsComponent
    extends DxyBaseComponent
    implements NodeExtraDataComponent, OnChanges
{
    @Input() entity: EntityItem;
    @Input() attributeKey: string;

    protected extraDataTagsParam$: Observable<ICollectionCellParams>;

    constructor(
        private translate: TranslateService,
        private attributeDataService: AttributeDataService
    ) {
        super();
    }

    ngOnChanges() {
        this.extraDataTagsParam$ = from(
            this.attributeDataService.getClientTags()
        ).pipe(
            map((tags) => {
                const tagsIds = this.entity.getAttributeValue<string[]>(
                    this.attributeKey
                );
                return this.makeTagsCollectionParam(
                    tags.filter((tag) => tagsIds.includes(tag.id))
                );
            })
        );
    }

    private makeTagsCollectionParam(tags: AttributeTagDTO[]) {
        if (!tags?.length) {
            return;
        }
        const options: ICollectionCellOption<IColorPointCellData>[] = tags.map(
            (t) => ({
                renderData: {
                    renderer: DxyColorPointCellComponent,
                    param: {
                        value: { color: t.Color },
                    },
                },
                labelText: t.DisplayName,
            })
        );
        const baseCellInputs: ICollectionCellInputs = {
            ...baseMiniTagCollectionCellInputs,
            maxItems: 3,
        };
        return this.makeCollectionParam(options, baseCellInputs, 'Domains');
    }

    private makeCollectionParam(
        options: ICollectionCellOption[],
        inputs: ICollectionCellInputs,
        attKey: string
    ): ICollectionCellParams {
        if (!options?.length) {
            return;
        }
        return {
            inputs: {
                ...inputs,
                options,
                tooltipTitle: this.translate.instant(
                    `DgServerTypes.BaseData.fields.${attKey}`
                ),
                uniqueTooltip: true,
                hideLabel: true,
            },
            isMini: true,
        };
    }
}

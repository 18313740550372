import { NgModule } from '@angular/core';
import { CoreUiModule } from '..';
import { DxyRichTextFieldControlComponent } from './editor/rich-text-field-control/rich-text-field-control.component';
import { DxyRichTextFieldComponent } from './editor/rich-text-field/rich-text-field.component';
import { RichTextPipe } from './rich-text.pipe';

@NgModule({
    imports: [CoreUiModule],
    declarations: [
        DxyRichTextFieldControlComponent,
        DxyRichTextFieldComponent,
        RichTextPipe,
    ],
    exports: [
        DxyRichTextFieldControlComponent,
        DxyRichTextFieldComponent,
        RichTextPipe,
    ],
})
export class DxyRichTextModule {}

<span *ngIf="!isMenuEmpty">
    <button
        mat-icon-button
        class="dg5-dropdown-icon-button"
        [matMenuTriggerFor]="menu"
        [attr.data-tracker-id]="btnDataTrackerId"
        [matTooltip]="'UI.NavBar.moreOptionsTooltip' | translate"
    >
        <i class="glyph-splitter"></i>
    </button>

    <mat-menu #menu="matMenu" class="dg5-mat-menu">
        <ul>
            <li
                *ngIf="showSearchPreferencesRedirection"
                role="menuitem"
                (click)="onClickSearchPreferences()"
            >
                <span class="glyph-settings"></span>
                <span class="text" translate
                    >UI.NavBar.redirectOnSearchPreferences</span
                >
            </li>

            <li
                *ngIf="showModuleExportButton"
                role="menuitem"
                (click)="onExportModule()"
            >
                <span class="glyph-download"></span>
                <span
                    class="text"
                    [translate]="moduleExportButtonTranslateKey"
                ></span>
            </li>

            <li
                *ngIf="showFilteredViewExportButton"
                role="menuitem"
                (click)="onExportFilteredView()"
            >
                <span class="glyph-download"></span>
                <span class="text" translate
                    >UI.NavBar.filteredViews.export</span
                >
            </li>

            <li *ngIf="showSeparatorMenu" role="separator"></li>

            <li
                *ngIf="hasImportAccess"
                role="menuitem"
                (click)="openImportFromCSVModal()"
            >
                <span class="glyph glyph-upload"></span>
                <span class="text" translate
                    >UI.Global.import.menuFromFile</span
                >
            </li>
            <li
                *ngIf="showGenerateGlossary"
                role="menuitem"
                (click)="openGlossaryGenerationModal()"
                [dxyLogFunctional]="glossaryAutoGenerationFuncLog"
            >
                <span class="glyph glyph-metabot-glossary"></span>
                <span class="text" translate
                    >UI.Glossary.import.generateFromSource</span
                >
            </li>
            <li
                *ngIf="showLinksGeneration"
                role="menuitem"
                (click)="openLinksGenerationModal()"
                [dxyLogFunctional]="linkAutoGenerationFuncLog"
            >
                <span class="glyph glyph-metabot-links"></span>
                <span class="text" translate
                    >UI.Glossary.import.linksGeneration</span
                >
            </li>
        </ul>
    </mat-menu>
</span>

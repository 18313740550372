import { NgModule } from '@angular/core';
import { FunnelModalComponent } from './funnel-modal/funnel-modal.component';
import { FunnelStepperComponent } from './funnel-stepper/funnel-stepper.component';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
    imports: [FunnelModalComponent, FunnelStepperComponent, MatDialogModule],
    exports: [FunnelModalComponent, MatDialogModule],
})
export class FunnelModule {}

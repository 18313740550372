import {
    AfterViewChecked,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { CollectionsHelper, StringUtil } from '@datagalaxy/core-util';
import { DxyModalService } from '../../../shared/dialogs/DxyModalService';
import { ConnectorService } from '../../connector.service';
import { AppDataService } from '../../../services/app-data.service';
import { SecurityService } from '../../../services/security.service';
import { ImportContext } from '../../../import/shared/ImportContext';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { IConfigFieldDef } from '../form-configs/types/interfaces/config-field-def.interface';
import { IInputFieldSettings } from '../form-configs/types/interfaces/input-field-settings.interface';
import { IBaseFieldDef } from '../form-configs/types/interfaces/base-field-def.interface';
import { IFieldDef } from '../form-configs/types/interfaces/field-def.interface';
import { FieldType } from '../form-configs/types/enums/field-type.enum';
import { formConfigs } from '../form-configs/connection-form-configs';
import { connectorFieldsInfos } from '../form-configs/connector-fields-infos';

@Component({
    selector: 'dxy-connection-form-settings',
    templateUrl: './dxy-connection-form-settings.component.html',
    styleUrls: ['./dxy-connection-form-settings.component.scss'],
})
export class DxyConnectionFormSettingsComponent
    extends DxyBaseComponent
    implements OnInit, AfterViewChecked
{
    @Input() importContext: ImportContext;

    /** Emitted when a menu is opened or closed. The argument is true on open. */
    @Output() readonly onMenuOpenClose = new EventEmitter<boolean>();

    //#region html bindings
    public hasCustomForm = true;
    /** If true, disable all fields display and shows a json field */
    protected isAdvancedMode = false;
    public get isAdvancedModeVisible() {
        return this.securityService.isOnlineConnectorTextEditorEnabled();
    }

    public advancedModeText: string;
    public advancedFieldError: string;
    public fieldDefs: IFieldDef[];
    public passwordFileFieldKey: string;
    public get isPasswordFile() {
        return this.fieldDefs.some(
            (def) => def.conf.isPassword && def.conf.isDownloadField
        );
    }

    public get passwordFiles() {
        return this._passwordFiles;
    }
    public set passwordFiles(files: File[]) {
        this.onChangePasswordFile(files);
    }

    public get hasMaskList() {
        return this.inputSettingsByFormField?.has('maskList');
    }

    public hasFilterSection = false;

    /** Note: this is connectorService.fields */
    public get fields() {
        return this.connectorService.fields;
    }

    //#endregion - html bindings

    private _passwordFiles: File[];
    private inputSettingsByFormField: Map<string, IInputFieldSettings>;
    private get pluginName() {
        return this.targetPlugin.name ?? '';
    }
    private get targetPlugin() {
        return this.connectorService.targetPlugin;
    }
    private get pluginFormConfig() {
        return formConfigs[this.pluginName];
    }

    constructor(
        private dxyModalService: DxyModalService,
        private connectorService: ConnectorService,
        private appDataService: AppDataService,
        private changeDetector: ChangeDetectorRef,
        private securityService: SecurityService
    ) {
        super();
    }

    ngOnInit() {
        if (!this.pluginFormConfig) {
            this.hasCustomForm = false;
            this.isAdvancedMode = true;
        }
        this.initFields();
    }

    ngAfterViewChecked() {
        this.preventNg0100Error(this.changeDetector);
    }

    //#region html bindings

    public isText(def: IBaseFieldDef) {
        return def.conf.type == FieldType.text;
    }
    public isBool(def: IBaseFieldDef) {
        return def.conf.type == FieldType.boolean;
    }
    public isToggle(def: IBaseFieldDef) {
        return def.conf.type == FieldType.toggle;
    }
    public isSelect(def: IBaseFieldDef) {
        return def.conf.type == FieldType.select;
    }
    public isList(def: IBaseFieldDef) {
        return def.conf.type == FieldType.list;
    }

    public isOptionSectionField(def: IBaseFieldDef) {
        return def.conf.isOptionInfo;
    }
    public isFilterSectionField(def: IBaseFieldDef) {
        return def.conf.isFilter;
    }
    public isBaseField(def: IBaseFieldDef) {
        return !def.conf.isOptionInfo && !def.conf.isFilter;
    }

    public onChangePasswordClick(def: IBaseFieldDef) {
        def.conf.isUpdatingPassword = true;
    }

    public getPasswordFieldPlaceholder(def: IBaseFieldDef) {
        return def.conf.isUpdatingPassword ? '' : '•••••';
    }

    public onValueChange(fieldKey: string) {
        this.handleFieldDependencies(fieldKey);
        this.validForm();
    }

    //#region list-field

    public async openListFieldModal(fieldDef: IFieldDef) {
        const result = await this.dxyModalService.prompt({
            titleKey: fieldDef.conf.listFieldTradKeys.modalTitle,
            userInputLabelKey: fieldDef.conf.listFieldTradKeys.modalLabel,
            userInputInfoKey: fieldDef.conf.listFieldTradKeys.description,
            confirmButtonKey: 'common.add',
        });
        this.log('openListFieldModal', result);

        if (result?.trim()) {
            fieldDef.data?.push(result);
            this.connectorService.listFieldUpdateCallSource.next();
        }
    }

    public checkAdvancedModeTextFormat() {
        try {
            const object = JSON.parse(this.advancedModeText);
            this.advancedFieldError = '';
            this.connectorService.connectionCredentials = object;
            // Force text formatting to have a nice rendering
            this.advancedModeText = JSON.stringify(
                this.connectorService.connectionCredentials,
                null,
                4
            );
        } catch (e) {
            this.advancedFieldError = e;
        }
        this.connectorService.isConnectorFormValidated =
            !this.advancedFieldError;
    }

    public onToggleAdvancedMode() {
        this.isAdvancedMode = !this.isAdvancedMode;

        if (this.isAdvancedMode) {
            this.advancedModeText = JSON.stringify(
                this.connectorService.connectionCredentials,
                null,
                4
            );
            this.advancedFieldError = '';
        } else {
            this.initFields();
        }
    }

    //#endregion

    private initFields() {
        // init with connection saved values
        if (this.connectorService.connectionCredentials) {
            if (this.isAdvancedMode) {
                this.advancedModeText = JSON.stringify(
                    this.connectorService.connectionCredentials,
                    null,
                    4
                );
            } else {
                this.connectorService.presetAttributesFromPlugin(
                    this.pluginName
                );
            }
        } else {
            this.connectorService.setFieldsPortFromPlugin(this.pluginName);
        }

        this.initFieldDefs();
        this.validForm();
    }

    private validForm() {
        if (this.isAdvancedMode) {
            return;
        }
        const passwordFields = this.fieldDefs
            .filter((def) => def.conf.isPassword)
            .map((fieldDef) => fieldDef?.conf.formField);
        const isExistingConnection =
            !!this.connectorService.connectionCredentials;
        const fields = this.fields;
        const isMissingMandatoryValues = Array.from(
            this.inputSettingsByFormField.keys()
        ).some((k) => {
            if (
                isExistingConnection &&
                passwordFields.some((field) => field == k)
            ) {
                return false;
            }
            return (
                this.isNullOrEmptyOrUndefined(fields[k]) &&
                !this.inputSettingsByFormField.get(k).isDisabled?.(fields) &&
                this.inputSettingsByFormField.get(k).isMandatory
            );
        });
        this.connectorService.isConnectorFormValidated =
            !isMissingMandatoryValues;

        // QUESTION: why we don't want to build credentials if we are in advanced mode?
        if (!this.isAdvancedMode) {
            this.connectorService.buildCredentials();
        }
    }

    public setPassword(_def: IFieldDef) {
        this.validForm();
    }

    private async onChangePasswordFile(files: File[]) {
        this._passwordFiles = files;
        const newFile = files[0];
        let newPrivateKey: string;
        if (newFile) {
            newPrivateKey = await newFile.text();
        }
        this.fields[this.passwordFileFieldKey] = newPrivateKey;
        this.validForm();
    }

    private initFieldDef(conf: IConfigFieldDef) {
        const { labelKey, fieldKey, getTextKey, description, hint } =
            this.initField(conf);
        const cfd: IFieldDef = { conf, labelKey, fieldKey, description, hint };
        const self = this;
        if (conf.type === FieldType.select) {
            cfd.adapter = {
                getTextKey,
                options: conf.selectValues,
                isModel: true,
                onSelectionChange: (_v) => self.onValueChange(fieldKey),
                get current() {
                    return self.fields[fieldKey];
                },
                set current(v: unknown) {
                    self.fields[fieldKey] = v;
                },
            };
        } else {
            Object.defineProperty(cfd, 'data', {
                get: () => self.fields[fieldKey],
                set: (v: unknown) => (self.fields[fieldKey] = v),
            });
        }
        return cfd;
    }

    private initFieldDefs() {
        if (!this.hasCustomForm) {
            return;
        }
        this.isAdvancedMode = false;
        const self = this;
        const confs: IConfigFieldDef[] = [];
        this.pluginFormConfig.forEach((c) => {
            if (c.isFilter) {
                this.hasFilterSection = true;
            }
            if (c.formField) {
                confs.push(c);
                c.dependencies?.forEach((dep) => {
                    if (dep.show(self.fields[c.formField] ?? c.payloadValue)) {
                        confs.push(dep.field);
                    }
                });
            }
        });
        this.inputSettingsByFormField = CollectionsHelper.objArrayToMap(
            confs,
            (c) => c.formField,
            (c) => ({ isMandatory: c.formMandatory, isDisabled: c?.isDisabled })
        );

        this.fieldDefs = confs.map((c) => self.initFieldDef(c));
    }

    private initField(conf: IConfigFieldDef) {
        const fieldKey = conf.formField;
        const labelKey = `UI.Connector.Wizard.Step3.${
            conf.tradKey ?? fieldKey
        }`;
        const getTextKey = conf.translate
            ? (v: string) =>
                  `UI.Connector.Wizard.Step3.${StringUtil.capitalize(
                      fieldKey
                  )}.${
                      conf.getDisplayValueTradKey
                          ? conf.getDisplayValueTradKey(v)
                          : v
                  }`
            : undefined;

        this.fields[fieldKey] = this.isNullOrEmptyOrUndefined(
            this.fields[fieldKey]
        )
            ? conf.payloadValue
            : this.fields[fieldKey];
        const infos = connectorFieldsInfos[this.pluginName]?.[conf.formField];
        const currentUserLanguageCode =
            this.appDataService.currentUserLanguageCode;
        const description = infos?.description?.[currentUserLanguageCode];
        const hint = infos?.hint?.[currentUserLanguageCode];
        if (conf.isDownloadField) {
            this.passwordFileFieldKey = conf.formField;
        }
        // In creation mode => display password field
        if (conf.isPassword) {
            conf.isUpdatingPassword =
                !this.connectorService.connectionCredentials;
        }
        return { fieldKey, labelKey, getTextKey, description, hint };
    }

    private handleFieldDependencies(fieldKey: string) {
        const config = this.pluginFormConfig.find(
            (el) => el.formField === fieldKey && el.dependencies
        );
        if (config?.dependencies) {
            this.initFieldDefs();
        }
    }

    private isNullOrEmptyOrUndefined(value: unknown) {
        return typeof value == 'string'
            ? StringUtil.isNullOrEmpty(value)
            : value == undefined;
    }
}

import { LineageItem } from '../data/LineageItem';
import { LineagePathTracker } from './LineagePathTracker';
import { BurgerMenuActionProviderBase, IActionDef } from '@datagalaxy/core-ui';
import { CommentaryService } from '../../../commentary/commentary.service';
import { EntityType, IEntityIdentifier } from '@datagalaxy/dg-object-model';
import { EntityService } from '../../../shared/entity/services/entity.service';
import { TaskService } from '../../../tasks/task.service';
import { StateName } from '@datagalaxy/webclient/routing';
import { LineageLink } from '../data/LineageLink';
import {
    IGotoDetailsOptions,
    NavigationService,
} from '../../../services/navigation.service';
import { UpdateLinkAction } from '@datagalaxy/webclient/entity/data-access';
import {
    EntityIdentifier,
    EntityTypeUtils,
} from '@datagalaxy/webclient/entity/utils';
import { IMiniEntityContent } from '@datagalaxy/webclient/entity/domain';

/** Lineage sub-component providing actions for the context-menu of a LineageItem */
export class LineageBurgerMenuActionProvider extends BurgerMenuActionProviderBase<
    LineageBurgerMenuAction,
    LineageItem,
    ILineageBurgerMenuContext
> {
    static readonly debug = false;

    private readonly pathTrackerActionDef: ILineageActionDef = {
        actionId: LineageBurgerMenuAction.pathTracker,
        isAvailable: (item, ctx) => item && ctx?.canBePinned,
        execute: () => {
            /* nothing here on purpose. see getHtmlContent */
        },
        optionClass: 'path-tracker-option',
    };

    private readonly addTaskActionDef: ILineageActionDef = {
        actionId: LineageBurgerMenuAction.addTask,
        isAvailable: (item, ctx) => this.isEntityWithIdentifier(item, ctx),
        execute: (item, ctx) =>
            this.getMiniEntityContent(item, ctx).then((entity) => {
                this.taskService.openTaskFormModal(entity);
            }),
        glyphClass: 'glyph-file-tasks-check',
    };

    private readonly addCommentActionDef: ILineageActionDef = {
        actionId: LineageBurgerMenuAction.addComment,
        isAvailable: (item, ctx) => this.isEntityWithIdentifier(item, ctx),
        execute: (item, ctx) =>
            this.getMiniEntityContent(item, ctx).then((entity) => {
                this.commentaryService.openCommentaryNewModal({
                    entityData: entity,
                });
            }),
        glyphClass: 'glyph-comment-add',
    };

    private readonly goToDetailsActionDef: ILineageActionDef = {
        actionId: LineageBurgerMenuAction.gotoDetails,
        isAvailable: (item, ctx) => this.isEntityWithIdentifier(item, ctx),
        execute: (_, ctx) =>
            this.gotoEntityDetails(
                ctx,
                this.isOriginItem(ctx)
                    ? { tabName: StateName.RelativeTabDetails, reload: true }
                    : undefined
            ),
        glyphClass: (item) => EntityTypeUtils.getGlyphClass(item.entityType),
    };

    private readonly goToLineageActionDef: ILineageActionDef = {
        actionId: LineageBurgerMenuAction.goToLineage,
        isAvailable: (item, ctx) =>
            this.isEntityWithIdentifier(item, ctx) && !this.isOriginItem(ctx),
        execute: (_, ctx) =>
            this.gotoEntityDetails(ctx, {
                tabName: StateName.RelativeTabImpactAnalysis,
            }),
        glyphClass: 'glyph-analysis',
    };
    private async gotoEntityDetails(
        ctx: ILineageBurgerMenuContext,
        options?: IGotoDetailsOptions
    ) {
        await this.navigationService.goToEntityDetailsByIdentifier(
            this.getEntityIdentifier(ctx),
            options
        );
    }

    private readonly unlinkActionDef: ILineageActionDef = {
        actionId: LineageBurgerMenuAction.unlink,
        isAvailable: (item, ctx) => {
            this.getMiniEntityContent(item, ctx);
            return !!(
                this.onActionUnlink &&
                ctx?.canBeUnlinked &&
                ctx?.entityData?.SecurityData.HasWriteAccess
            );
        },
        execute: (item, ctx) => this.onActionUnlink(item, ctx),
        glyphClass: 'glyph-link-broken',
    };

    private readonly setGoldenLinkActionDef: ILineageActionDef = {
        actionId: LineageBurgerMenuAction.setGoldenLink,
        isAvailable: (item, ctx) =>
            !!(
                ctx &&
                ctx.canBeGolden &&
                ctx.entityData?.SecurityData.HasWriteAccess &&
                !ctx.dataLink?.isGoldenLink
            ),
        execute: (item, ctx) =>
            this.onActionSetGoldenLink(
                UpdateLinkAction.SetGoldenLink,
                ctx?.dataLink
            ),
        glyphClass: 'glyph-golden-link',
    };

    private readonly deleteGoldenLinkActionDef: ILineageActionDef = {
        actionId: LineageBurgerMenuAction.deleteGoldenLink,
        isAvailable: (item, ctx) =>
            !!(
                ctx &&
                ctx.canBeGolden &&
                ctx.entityData?.SecurityData.HasWriteAccess &&
                ctx.dataLink?.isGoldenLink
            ),
        execute: (item, ctx) =>
            this.onActionSetGoldenLink(
                UpdateLinkAction.RemoveGoldenLink,
                ctx?.dataLink
            ),
        glyphClass: 'glyph-golden-link-off',
    };

    constructor(
        private navigationService: NavigationService,
        private taskService: TaskService,
        private commentaryService: CommentaryService,
        private entityService: EntityService,
        private pathTracker: LineagePathTracker,
        private toCache: (
            item: LineageItem,
            entity: IMiniEntityContent
        ) => void,
        private originEntityIdr: IEntityIdentifier,
        private onActionUnlink?: (
            item: LineageItem,
            ctx: ILineageBurgerMenuContext
        ) => void,
        private onActionSetGoldenLink?: (
            action: UpdateLinkAction,
            lineageLink: LineageLink
        ) => void,
        debug = false
    ) {
        super(debug);
        this.actionDefs = [
            this.pathTrackerActionDef,
            this.setGoldenLinkActionDef,
            this.deleteGoldenLinkActionDef,
            this.unlinkActionDef,
            this.goToDetailsActionDef,
            this.goToLineageActionDef,
            this.addTaskActionDef,
            this.addCommentActionDef,
        ];
        if (LineageBurgerMenuActionProvider.debug) {
            this.debug = true;
        }
    }

    protected getTranslateKey(
        actionId: LineageBurgerMenuAction,
        ctx: ILineageBurgerMenuContext
    ) {
        const actionNameBase = LineageBurgerMenuAction[actionId];
        const actionName =
            ctx && actionId == LineageBurgerMenuAction.unlink && ctx.isMultiLink
                ? `${actionNameBase}Multi`
                : actionNameBase;
        return 'UI.EntityImpactAnalysis.lineageBurgerMenu.' + actionName;
    }

    protected getHtmlContent(
        actionId: LineageBurgerMenuAction
    ): string | HTMLElement | ((data: LineageItem) => HTMLElement) {
        return (item: LineageItem) => {
            if (actionId == LineageBurgerMenuAction.pathTracker) {
                return this.pathTracker.getPinOptionElement(item);
            }
        };
    }

    private isEntity(it: LineageItem) {
        return (
            !!it?.entityType && it.entityType != EntityType.DataProcessingItem
        );
    }
    private getEntityIdentifier(ctx: ILineageBurgerMenuContext) {
        return ctx?.entityData ?? ctx?.entityIdentifier;
    }
    private isEntityWithIdentifier(
        item: LineageItem,
        ctx: ILineageBurgerMenuContext
    ) {
        return this.isEntity(item) && !!this.getEntityIdentifier(ctx);
    }
    private isOriginItem(ctx: ILineageBurgerMenuContext) {
        return (
            this.originEntityIdr &&
            EntityIdentifier.areSame(
                this.originEntityIdr,
                this.getEntityIdentifier(ctx)
            )
        );
    }

    /** retrieves the minimal entity data from the server, adds it to the cache and the item's menu context */
    private async getMiniEntityContent(
        item: LineageItem,
        ctx: ILineageBurgerMenuContext
    ): Promise<IMiniEntityContent> {
        if (ctx.entityData) {
            return ctx.entityData;
        }

        const entityData = await this.entityService.getEntityMini(
            ctx.entityIdentifier
        );
        this.toCache(item, entityData);
        ctx.entityData = entityData;
        return entityData;
    }
}

export interface ILineageBurgerMenuContext {
    /** true when the item can be used for the path-tracker */
    canBePinned: boolean;
    /** entity for which the context menu is displayed.
     * - can be undefined, if entityIdentifier is provided */
    entityData?: IMiniEntityContent;
    /** item's entity identifier */
    entityIdentifier?: IEntityIdentifier;
    dataLink?: LineageLink;
    canBeGolden?: boolean;
    canBeUnlinked?: boolean;
    isMultiLink: boolean;
}

enum LineageBurgerMenuAction {
    unknown,
    pathTracker,
    gotoDetails,
    addTask,
    addComment,
    unlink,
    setGoldenLink,
    deleteGoldenLink,
    goToLineage,
}

interface ILineageActionDef
    extends IActionDef<
        LineageBurgerMenuAction,
        LineageItem,
        ILineageBurgerMenuContext
    > {}

<div class="save-filters-actions">
    <span *ngIf="hasChanged" class="edited-filters-text" translate
        >UI.Global.filter.edited</span
    >
    <button
        *ngIf="areActionsVisible"
        mat-icon-button
        class="dg5-dropdown-icon-button"
        [matMenuTriggerFor]="menu"
        [matTooltip]="'UI.Global.filter.save' | translate"
    >
        <span class="glyph glyph-save1"></span>
    </button>
</div>

<mat-menu #menu="matMenu" class="dg5-mat-menu">
    <ul>
        <li
            *ngIf="canSaveCurrent"
            (click)="onSaveCurrentClick()"
            role="menuitem"
        >
            <span translate>UI.Global.filter.save</span>
        </li>
        <li *ngIf="canSaveAs" (click)="onSaveAsClick()" role="menuitem">
            <span translate>UI.Global.filter.saveAs</span>
        </li>
        <li *ngIf="hasChanged" (click)="onCancelChangesClick()" role="menuitem">
            <span translate>UI.Global.filter.cancelChanges</span>
        </li>
    </ul>
</mat-menu>

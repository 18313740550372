import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import { CollectionsHelper } from '@datagalaxy/core-util';
import { DateFilterOperator, IDateFilterData } from './date-filter.types';
import { DxyBaseFilterComponent } from '../base-filter/base-filter.component';
import { DateFilterUtils } from './date-filter.utils';
import * as moment from 'moment/moment';

/**
 * ## Role
 * Date filter button with period dropdown and range date inputs
 *
 * ## Notes
 * Does not depend on entity attributes.
 */
@Component({
    selector: 'dxy-date-filter',
    templateUrl: './date-filter.component.html',
    styleUrls: ['./date-filter.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DxyDateFilterComponent
    extends DxyBaseFilterComponent<IDateFilterData, DateFilterOperator>
    implements OnInit, OnChanges
{
    @Input() minDate: moment.Moment;
    @Input() maxDate: moment.Moment;

    public get isRangeDate() {
        return this.operator === DateFilterOperator.RangeContains;
    }
    public get hasOnlyStartDate() {
        return this.filter.startDate && !this.filter.endDate;
    }
    public get hasOnlyEndDate() {
        return !this.filter.startDate && this.filter.endDate;
    }
    public get hasStartAndEndDate() {
        return this.filter.startDate && this.filter.endDate;
    }
    public get isResolved() {
        return DateFilterUtils.isResolved(this.filter);
    }
    public get operatorKey() {
        return this.getOperatorTranslateKey(this.operator);
    }

    constructor(private cd: ChangeDetectorRef) {
        super();
    }

    public ngOnInit() {
        this.init();
    }

    public ngOnChanges(changes: SimpleChanges) {
        super.onChange(changes, 'filter', () => this.init());
    }

    public onStartDateChange() {
        this.onFilterChange();
    }

    public onEndDateChange() {
        if (this.filter.endDate) {
            this.filter.endDate.add(1, 'day');
        }
        this.onFilterChange();
    }

    public onOperatorChange(operator: DateFilterOperator) {
        this.log('onOperatorChange', operator);
        this.filter.operator = operator;
        this.onFilterChange();
    }

    public getOperatorTranslateKey(operator: DateFilterOperator) {
        return DateFilterUtils.getOperatorTranslateKey(operator);
    }

    private init() {
        if (!this.filter) {
            this.filter = {};
        }
        if (!this.operator) {
            this.operator = DateFilterOperator.RangeContains;
        }
        this.operators ??= CollectionsHelper.getEnumValues<DateFilterOperator>(
            DateFilterOperator
        ).filter(
            (o) =>
                !this.excludedOperators?.includes(o) &&
                o !== DateFilterOperator.Unknown
        );
    }

    private onFilterChange() {
        this.cd.detectChanges();
        this.filterChange.emit(this.filter);
    }
}

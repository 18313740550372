<as-split
    direction="horizontal"
    unit="pixel"
    (dragEnd)="onGraphicalAreaResized()"
    [appAsSplitPersistenceId]="splitterPersistenceId"
>
    <as-split-area>
        <div
            class="graphical-surface diagramming"
            [class.debug]="debug || serviceDebug"
        >
            <div
                class="mapping-zone mapping-zone--in"
                dxyDataTestId="data-processing-container"
                (scroll)="onLayoutColumnScrolled()"
            >
                <app-dp-implem-element
                    *ngFor="
                        let container of data.inputLinks
                            | orderBy : 'displayName';
                        trackBy: getContainerId
                    "
                    class="mapping-container mapping-container--processes"
                    [container]="container"
                    type="In"
                ></app-dp-implem-element>
            </div>
            <div
                class="mapping-zone mapping-zone--processItems"
                dxyDataTestId="data-processing-items"
                (scroll)="onLayoutColumnScrolled()"
            >
                <app-dp-item-element
                    *ngFor="let item of data.items"
                    class="dataProcessing-item connection-target"
                    [linkedItem]="item"
                    [class.item-highlighted]="item.highlighted"
                ></app-dp-item-element>
                <div *ngIf="isEditEnabled" class="dataProcessing-item">
                    <div
                        class="dataProcessing-item--ghost"
                        (click)="onDPICreateButtonClick()"
                        dxyLogFunctional="DATA_PROESSING_ITEM,C"
                        [matTooltip]="
                            'UI.DataProcessingItem.lblNew' | translate
                        "
                        [matTooltipDisabled]="!isTooltipEnabled"
                        dxyDataTestId="data-processing-item-create-button"
                    >
                        <span class="icon default-icon glyph-add"></span>
                        <span translate>UI.DataProcessingItem.lblNew</span>
                    </div>
                </div>
            </div>
            <div
                class="mapping-zone mapping-zone--out"
                dxyDataTestId="data-processing-output-container"
                (scroll)="onLayoutColumnScrolled()"
            >
                <app-dp-implem-element
                    *ngFor="
                        let container of data.outputLinks
                            | orderBy : 'parentData.DisplayName';
                        trackBy: getContainerId
                    "
                    class="mapping-container mapping-container--processes"
                    [container]="container"
                    type="Out"
                ></app-dp-implem-element>
            </div>
        </div>
    </as-split-area>
    <as-split-area
        *ngIf="isEditEnabled"
        [size]="getAreaSize(1, 260)"
        [minSize]="260"
        [maxSize]="500"
    >
        <div class="right-list">
            <dxy-entity-grid
                [egOptions]="egOptions"
                [serverType]="gridServerType"
                [spaceIdr]="spaceIdr"
                logId="dp-implem"
                dtContext="Data Processing Mapping"
            ></dxy-entity-grid>
        </div>
    </as-split-area>
</as-split>
<app-dp-item-link-menu></app-dp-item-link-menu>

import { TransitionService } from '@uirouter/core';
import { Ng2StateDeclaration } from '@uirouter/angular';
import { CommonStateDefinitions as CSD } from '../shared/util/states/CommonStateDefinitions';
import { DxyPrimaryKeyGridComponent } from './pkfk/dxy-primary-key-grid/dxy-primary-key-grid.component';
import { DxyForeignKeyGridComponent } from './pkfk/foreign-key-grid/dxy-foreign-key-grid.component';
import { DxyDenodoTableSampleComponent } from './denodo/dxy-denodo-table-sample/dxy-denodo-table-sample.component';
import { ModelerService } from './services/modeler.service';
import { Constants } from '../shared/util/Constants';
import { StateName } from '@datagalaxy/webclient/routing';
import { ServerType } from '@datagalaxy/dg-object-model';
import { DxyDataTypeModelSettingsComponent } from './data-types/dxy-data-type-model-settings/dxy-data-type-model-settings.component';
import { HddUtil } from '../shared/util/HddUtil';
import { DataQualityTabComponent } from '../data-quality/data-quality-tab-component/data-quality-tab.component';
import { Project } from '@datagalaxy/webclient/workspace/data-access';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';
import { DgModule } from '@datagalaxy/shared/dg-module/domain';
import { ServerConstants } from '@datagalaxy/shared/server/domain';
import PropertyName = ServerConstants.PropertyName;
import Nav = Constants.Nav;
import UrlWord = Nav.UrlWord;

export function getStatesForCatalog(): Ng2StateDeclaration[] {
    return [
        // modeler module routes
        ...CSD.ModuleStates(DgModule.Catalog),

        // modeler specific routes

        CSD.LinkedObjects(StateName.ModelerLinkedObjects),
        CSD.EntityImpactAnalysis(StateName.ModelerImpactAnalysisTab),
        CSD.EntityDiagrams(StateName.ModelerDiagramsTab, {
            persistenceGridId: 'diagram-list-modeler',
            canCreateDiagram: true,
        }),
        CSD.ModuleEntityTabGrid(
            StateName.ModelerTableColumnsTab,
            `/${UrlWord.columns}`,
            ServerType.Column,
            [
                'Type',
                'DataTypeDisplayName',
                'Order',
                'TechnicalName',
                'DisplayName',
                'Description',
                'SizeString',
                'IsMandatory',
                'IsTechnicalData',
                'IsForeignKey',
                'IsPrimaryKey',
            ],
            ServerType.Table,
            false,
            false,
            true
        ),
        {
            name: StateName.ModelerTableSampleTab,
            url: `/${UrlWord.sample}`,
            component: DxyDenodoTableSampleComponent,
            resolve: [
                {
                    token: 'entityData',
                    deps: ['entityData'],
                    resolveFn: (entityData: EntityItem) => entityData,
                },
            ],
        },
        CSD.ModuleEntityTabGrid(
            StateName.ModelerContainerTablesTab,
            `/${UrlWord.tables}`,
            ServerType.Table,
            [
                'Type',
                'TechnicalName',
                'DisplayName',
                'Description',
                'TechnicalComments',
            ],
            ServerType.Model,
            false,
            false,
            true
        ),
        CSD.ModuleEntityTabGrid(
            StateName.ModelerModelContainersTab,
            `/${UrlWord.containers}`,
            ServerType.Container,
            [
                PropertyName.TechnicalName,
                PropertyName.LegacySubTypeAttributeKey,
                PropertyName.Description,
                PropertyName.LogicalChildrenCount,
                PropertyName.LogicalAllLevelChildrenCount,
            ],
            ServerType.Model,
            true,
            true,
            true
        ),
        {
            name: StateName.ModelerModelSettingsTab,
            url: `/${UrlWord.settings}`,
            component: DxyDataTypeModelSettingsComponent,
            resolve: [
                {
                    token: 'entityData',
                    deps: ['entityData'],
                    resolveFn: (entityData: EntityItem) => entityData,
                },
                {
                    token: 'modelData',
                    deps: ['entityData', ModelerService],
                    resolveFn: async (
                        entityData: EntityItem,
                        modelerService: ModelerService
                    ) =>
                        modelerService.getModelForDataTypeModelSettings(
                            entityData
                        ),
                },
            ],
        },
        {
            name: StateName.ModelerModelPrimaryKeysTab,
            url: `/${UrlWord.primaryKeys}`,
            component: DxyPrimaryKeyGridComponent,
            resolve: [
                {
                    token: 'currentRoot',
                    deps: ['currentRoot'],
                    resolveFn: (currentRoot: Project) => currentRoot,
                },
                {
                    token: 'entityData',
                    deps: ['entityData'],
                    resolveFn: (entityData: EntityItem) => entityData,
                },
                {
                    token: 'modelId',
                    deps: ['entityData', ModelerService],
                    resolveFn: async (
                        entityData: EntityItem,
                        modelerService: ModelerService
                    ) => {
                        const modelId = HddUtil.getModelId(entityData?.HddData);
                        await modelerService.loadModelForPrimaryKeyGrid(
                            modelId
                        );
                        return modelId;
                    },
                },
            ],
        },
        {
            name: StateName.ModelerModelForeignKeysTab,
            url: `/${UrlWord.foreignKeys}`,
            component: DxyForeignKeyGridComponent,
            resolve: [
                {
                    token: 'currentRoot',
                    deps: ['currentRoot'],
                    resolveFn: (currentRoot: Project) => currentRoot,
                },
                {
                    token: 'entityData',
                    deps: ['entityData'],
                    resolveFn: (entityData: EntityItem) => entityData,
                },
                {
                    token: 'modelData',
                    deps: ['entityData', ModelerService],
                    resolveFn: async (
                        entityData: EntityItem,
                        modelerService: ModelerService
                    ) => modelerService.getModelForForeignKeyGrid(entityData),
                },
            ],
        },
        {
            name: StateName.ModelerDataQuality,
            url: `/${UrlWord.dataQuality}`,
            component: DataQualityTabComponent,
            resolve: [
                {
                    token: 'entityData',
                    deps: ['entityData'],
                    resolveFn: (entityData: EntityItem) => entityData,
                },
            ],
        },
    ];
}

export function modelerRouterInit(transitionService: TransitionService) {
    CSD.dgModuleRouterInit(StateName.ModelerDashboard, transitionService);
}

import { Component, ViewChild } from '@angular/core';
import { BaseCellComponent } from '@datagalaxy/core-ui/cell-components';
import { IDgEntityPathCellParams } from './dg-entity-path-cell.types';
import { DxyNavBreadcrumbComponent } from '../../../../navigation/dxy-nav-breadcrumb/dxy-nav-breadcrumb.component';
import { HierarchicalData } from '@datagalaxy/dg-object-model';

/**
 * ## Role
 * Display entity path as a breadcrumb with clickable links
 */
@Component({
    selector: 'app-dg-entity-path-cell',
    templateUrl: 'dg-entity-path-cell.component.html',
    styleUrls: ['./dg-entity-path-cell.component.scss'],
})
export class DgEntityPathCellComponent extends BaseCellComponent<
    unknown,
    HierarchicalData,
    IDgEntityPathCellParams
> {
    public hddData?: HierarchicalData;
    public dtContext?: string;

    @ViewChild(DxyNavBreadcrumbComponent)
    navBreadcrumb: DxyNavBreadcrumbComponent;

    constructor() {
        super();
    }

    public override updateLayout() {
        this.navBreadcrumb?.refresh();
    }

    protected override updateBindings() {
        this.hddData = this.value;
        this.dtContext = this.params.dtContext;
    }
}

import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { CollectionsHelper } from '@datagalaxy/core-util';
import { FacetGroup } from '../models/FacetGroup';
import { FacetItem } from '../models/FacetItem';
import { SearchService } from '../../search.service';
import { DxyBaseComponent } from '@datagalaxy/ui/core';

@Component({
    selector: 'dxy-search-facet-filter-group',
    templateUrl: './dxy-search-facet-filter-group.component.html',
    styleUrls: ['./dxy-search-facet-filter-group.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DxySearchFacetFilterGroupComponent
    extends DxyBaseComponent
    implements OnInit
{
    @Input() groupData: FacetGroup;
    @Output() onFilterChange = new EventEmitter<FacetItem>();

    public isCollapsible: boolean;
    public displayName: string;
    public glyphClassName: string;
    public subGroups: FacetGroup[];
    public filteredItems: FacetItem[];
    public get isCollapsed() {
        return this.groupData?.isCollapsed;
    }
    public get isShowMoreVisible() {
        return this.groupData?.displayedItems?.length > this.itemsCountLimit;
    }
    public get itemsCountLimit() {
        return this.groupData.itemsCountLimit;
    }

    constructor() {
        super();
    }

    ngOnInit() {
        const g = this.groupData;
        if (!g) {
            return;
        }

        this.subGroups = g.subGroups;
        this.isCollapsible = g.isCollapsible;
        this.displayName = g.displayName;
        this.setupFilteredItems();
    }

    public toggleCollapsed() {
        if (!this.isCollapsible) {
            return;
        }
        this.groupData.isCollapsed = !this.groupData.isCollapsed;
    }

    public onShowMore() {
        this.groupData.itemsCountLimit += 5;
        this.setupFilteredItems();
    }

    public isUserItem(item: FacetItem) {
        return SearchService.isSpaceGovUserFilterElement(item.attributeKey);
    }
    public isTagItem(item: FacetItem) {
        return SearchService.isTagFilterElement(item.attributeKey);
    }

    public hasIconUrl(item: FacetItem) {
        return !!item.iconUrl;
    }

    private setupFilteredItems() {
        this.filteredItems = CollectionsHelper.orderBy(
            this.groupData.displayedItems,
            (d) => (d.isChecked ? -1 : 1)
        ).slice(0, this.itemsCountLimit);
    }
}

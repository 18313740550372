import { DpMappingUiService } from '../dpMappingUi.service';
import { AfterViewInit, Directive, ElementRef, OnDestroy } from '@angular/core';
import { GraphicalEntityData } from '../models/GraphicalEntityData';
import { TNodeType } from '../dp-mapping.types';
import { DxyBaseComponent } from '@datagalaxy/ui/core';

/**
 * ## Role
 * Base class for a DPI or a sub-item of a mapped DPI in the Data-Processing Mapping UI
 */
@Directive()
export abstract class BaseDpMappingItemComponent
    extends DxyBaseComponent
    implements AfterViewInit, OnDestroy
{
    protected abstract itemId: string;
    protected abstract data: GraphicalEntityData;
    protected abstract type: TNodeType;
    protected virtual = false;

    constructor(
        protected elementRef: ElementRef<HTMLElement>,
        protected dpMappingUiService: DpMappingUiService
    ) {
        super();
    }

    ngAfterViewInit() {
        this.dpMappingUiService.onBaseMappingItemPostInit(
            this.elementRef.nativeElement,
            this.itemId,
            this.data,
            this.type,
            this.virtual
        );
    }
    ngOnDestroy() {
        this.dpMappingUiService.onBaseMappingItemDestroy(
            this.elementRef.nativeElement
        );
        super.ngOnDestroy();
    }
}

import { Component, Input } from '@angular/core';
import { IActionOption } from '@datagalaxy/core-ui';
import { ServerType } from '@datagalaxy/dg-object-model';
import { EntityPreviewPanelService } from '../../shared/entity/services/entity-preview-panel.service';
import {
    CompareOperation,
    ComparisonItem,
    ProjectVersion,
} from '@datagalaxy/webclient/versioning/data-access';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { EntityIdentifier } from '@datagalaxy/webclient/entity/utils';

@Component({
    selector: 'dxy-versioning-comparator-item',
    templateUrl: 'dxy-versioning-comparator-item.component.html',
    styleUrls: ['dxy-versioning-comparator-item.component.scss'],
})
export class DxyVersioningComparatorItemComponent extends DxyBaseComponent {
    @Input() comparisonItem: ComparisonItem;
    @Input() selectedVersion: ProjectVersion;

    public actions: IActionOption[] = [
        {
            hidden: () => !this.isMagnifyingGlassEnabled(),
            callback: () => this.openEntityPane(),
            tooltipTranslateKey: 'UI.Versioning.Comparator.previewTooltip',
            glyphClass: 'glyph-object-preview',
        },
    ];
    public get hierarchicalData() {
        return this.comparisonItem.Item;
    }

    constructor(private entityPreviewPanelService: EntityPreviewPanelService) {
        super();
    }

    private isMagnifyingGlassEnabled() {
        if (this.comparisonItem.Operation == CompareOperation.Deleted) {
            return false;
        }
        const dtn = this.comparisonItem.Item.Data.DataTypeName,
            ST = ServerType;
        return ![ST.PrimaryKey, ST.ForeignKey, ST.Diagram].includes(ST[dtn]);
    }

    private openEntityPane() {
        const item = this.comparisonItem;
        this.entityPreviewPanelService.setupPanel({
            entityIdr: EntityIdentifier.fromIHierarchicalDataAndEntityType(
                item.HddData,
                item.Item.Data.EntityType
            ),
        });
    }
}

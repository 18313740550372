import { WidgetInstanceDroppedEvent } from '../../../common/dashboard-grid-events';
import { DashboardSection, WidgetInstance } from '../../../domain';
import { DashboardState } from '../dashboard-state';
import { insertWidgetInstanceReducer } from './insert-widget-instance-reducer';
import { removeWidgetInstanceReducer } from './remove-widget-instance-reducer';
import { generateGuid } from '@datagalaxy/utils';

export function moveWidgetInstanceReducer(
    state: DashboardState,
    section: DashboardSection,
    event: WidgetInstanceDroppedEvent
) {
    const widgetInstanceId = event.widgetInstanceId;
    const sourceSection = state.dashboard.sections.find((s) =>
        s.widgetInstances.some((w) => w.guid == widgetInstanceId)
    );
    if (!sourceSection) {
        return;
    }
    const widgetInstance = sourceSection.widgetInstances.find(
        (wi) => wi.guid == widgetInstanceId
    );
    const newWidgetInstance = {
        ...widgetInstance,
        guid: generateGuid(),
        position: event.position,
    } as WidgetInstance;
    let newState = insertWidgetInstanceReducer(
        state,
        section,
        newWidgetInstance
    );
    return removeWidgetInstanceReducer(newState, sourceSection, widgetInstance);
}

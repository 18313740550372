import { NgModule } from '@angular/core';
import { DxyDropdownButtonComponent } from './dxy-dropdown-button/dxy-dropdown-button.component';
import { DxyMiniAvatarsComponent } from './dxy-mini-avatars/dxy-mini-avatars.component';
import { DxyProfileAvatarComponent } from './dxy-profile-avatar/dxy-profile-avatar.component';
import { DxyTabViewComponent } from './dxy-tab-view/dxy-tab-view.component';
import { DxyMenuBarViewComponent } from './dxy-menu-bar-view/dxy-menu-bar-view.component';
import { cellComponentsList } from './cells/cell-components-list';
import { DxyOmnigridModule } from '@datagalaxy/core-ui/omnigrid';
import { DxyPreviewPanelContentComponent } from './dxy-preview-panel-content/dxy-preview-panel-content.component';
import { DxyPreviewPanelSliderComponent } from './dxy-preview-panel-slider/dxy-preview-panel-slider.component';
import { SharedModule } from '../shared.module';
import { DxySpaceIconComponent } from './dxy-space-icon/dxy-space-icon.component';
import { UserCellComponent } from '@datagalaxy/webclient/user/ui';

/*
    Important: Do not include components that use dxy-field-*.
    Instead, create a new module in app/ and add it to dxyAppModulesList.ts
*/
@NgModule({
    imports: [SharedModule, DxyOmnigridModule, UserCellComponent],
    declarations: [
        DxyProfileAvatarComponent,
        DxyMiniAvatarsComponent,
        DxyTabViewComponent,
        DxyMenuBarViewComponent,
        DxyDropdownButtonComponent,
        DxyPreviewPanelSliderComponent,
        DxyPreviewPanelContentComponent,
        DxySpaceIconComponent,
        ...cellComponentsList,
    ],
    exports: [
        DxyProfileAvatarComponent,
        DxyMiniAvatarsComponent,
        DxyTabViewComponent,
        DxyMenuBarViewComponent,
        DxyDropdownButtonComponent,
        DxyPreviewPanelSliderComponent,
        DxySpaceIconComponent,
        ...cellComponentsList,
        UserCellComponent,
    ],
})
export class DxySharedUiModule {}

<div class="modal-header">
    <h3 mat-dialog-title class="modal-title" translate>
        DgServerTypes.ServerTypeName.DataProcessingItem
    </h3>
    <button
        mat-icon-button
        (click)="onCloseCancel()"
        class="modal-close-btn"
    ></button>
</div>
<div mat-dialog-content>
    <dxy-field-text
        [labelText]="getLabelText('DisplayName')"
        [(ngModel)]="edited.DisplayName"
        (ngModelChange)="updateSaveEnabled()"
        [required]="true"
    ></dxy-field-text>
    <dxy-field-text
        [labelText]="getLabelText('TechnicalName')"
        [(ngModel)]="edited.TechnicalName"
        (ngModelChange)="updateSaveEnabled()"
        [required]="true"
    ></dxy-field-text>
    <dxy-field-text
        [labelText]="getLabelText('Description')"
        [(ngModel)]="edited.Description"
    ></dxy-field-text>
    <dxy-field-select
        [labelText]="getLabelText('ItemType')"
        [adapter]="itemTypeAdapter"
        (openClose)="onPanelOpenClose($event)"
    ></dxy-field-select>
    <dxy-rich-text-field
        [labelText]="getLabelText('LongDescription')"
        [(ngModel)]="edited.LongDescription"
        [mentionResolvers]="mentionResolvers"
        toolbarDisplayMode="focus"
        [noTabCapture]="true"
        (onAcquireMentionData)="onPanelOpenClose($event)"
    ></dxy-rich-text-field>
</div>
<div class="modal-footer">
    <button mat-flat-button (click)="onCloseCancel()" type="button">
        <span translate>UI.Dialog.btnCancel</span>
    </button>
    <button
        mat-flat-button
        color="primary"
        (click)="onSubmit()"
        type="button"
        [disabled]="!isSaveEnabled"
    >
        <span translate>UI.Dialog.btnSave</span>
    </button>
</div>

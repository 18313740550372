import { IActionOption, IListOption } from '@datagalaxy/core-ui';
import { IHasHddData } from '@datagalaxy/dg-object-model';

export enum DksEntityMenuOption {
    Preview,
}

export interface DKSEntityMenuDefaultOption {
    type: 'default';
    option: DksEntityMenuOption;
    hidden?: (entity: IHasHddData) => boolean;
}

export interface DKSEntityMenuListOption {
    type: 'option';
    option: IListOption<IHasHddData> | IActionOption<IHasHddData>;
}

export type TDksEntityMenuOption =
    | DKSEntityMenuDefaultOption
    | DKSEntityMenuListOption;

export type TDksEntityMenu = TDksEntityMenuOption[];

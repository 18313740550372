import { Injectable, Injector, NgZone } from '@angular/core';
import { BaseService, CoreEventsService } from '@datagalaxy/core-ui';
import { ClientErrorService } from '../clientError.service';
import { ExceptionNotificationBuilder } from './exception-notification-builder';
import { AppDataService } from '../app-data.service';
import { AnalyticService } from '../analytic.service';
import { environment } from '../../../environments/environment';
import { ClientContextUtil } from '../../shared/util/ClientContextUtil';
import { BrowserInfoUtil } from '../../shared/util/BrowserInfoUtil';
import { NavigationApiService } from '../../navigation/services/navigation-api.service';
import { Constants } from '../../shared/util/Constants';
import { LoggingHttpService } from '../../core/backend-http-interceptors/logging-http.service';
import { getContextId } from '@datagalaxy/webclient/utils';
import {
    ClientErrorParameter,
    CrudActionType,
    CrudOperation,
    FunctionalLogService,
    LogFunctionalActionParameter,
    MonitoringApiService,
} from '@datagalaxy/webclient/monitoring/data-access';
import { FeatureFlagService } from '@datagalaxy/webclient/feature-flag';
import { CurrentUserService } from '@datagalaxy/webclient/user/feature/current-user';
import { ZoneUtils } from '@datagalaxy/utils';

/**
 * #Archi-service-top: This service cannot be referenced by any service
 */
@Injectable({ providedIn: 'root' })
export class MonitoringService extends BaseService {
    private readonly monitorActionsOnlyIfDebug = true;

    //#region locals
    private currentNavigationPath: string;
    private appStart: number;
    private lastActionStart: number;
    private debouncedMonitorActionTimer: any;
    private firstMutationTime: number;
    private lastMutationTime: number;
    private lastEventTime: number;
    private lastActionName: string;
    private totalMutationCount: number;
    private isServerCallInProgress: boolean;
    private lastActionParams: any;
    private apiTime: number;
    private apiStart: number;
    private lastActionType: string;
    private stateChangeLevel: number;
    private stateChangeStart: number;
    private lastApiEnd: number;
    private isInStateChange: boolean;

    //#endregion

    private get userSessionId(): string {
        return this.appDataService.userSessionId;
    }

    constructor(
        private ngZone: NgZone,
        private injector: Injector,
        private coreEventsService: CoreEventsService,
        private clientErrorService: ClientErrorService,
        private functionalLogService: FunctionalLogService,
        private navigationApiService: NavigationApiService,
        private appDataService: AppDataService,
        private analyticService: AnalyticService,
        private loggingHttpService: LoggingHttpService,
        private monitoringApiService: MonitoringApiService,
        private featureFlagService: FeatureFlagService,
        private currentUserService: CurrentUserService
    ) {
        super();
        this.appStart = Date.now();

        this.clearLastEvent();

        this.observeApiService();
        this.observeCoreEventsService();
        this.observeClientErrorService();
        this.observeFunctionalLogService();
        this.observeNavigationApiService();
    }

    //#region public

    public onStateTransitionStart(nameTo: string, paramsTo: any) {
        try {
            this.trackStateChangeStart(nameTo);
            this.tryStartAction(Constants.ActionType.State, nameTo, paramsTo);
        } catch (e) {
            this.consoleWarn(e);
        }
    }
    public onStateTransitionError(
        errorData: Error,
        toStateName: string,
        errorMessage: string
    ) {
        try {
            const isError = this.notifyClientException(
                errorData,
                'Transition Change',
                'transition'
            );
            if (isError) {
                this.endStateChange();
                this.trackStateChangeErrorInConsole(toStateName, errorMessage);
            }
        } catch (e) {
            this.consoleWarn(e);
        }
    }

    //#region from index.route.ts

    public onStateTransitionSuccess(stateName: string, url: string) {
        this.log('onStateTransitionSuccess', stateName);

        this.endStateChange();

        this.trackStateChangeSuccessInConsole(stateName);

        this.currentNavigationPath = url;

        const elapsed = Date.now() - this.stateChangeStart;
        this.logWithTime(
            `State Change End - ${stateName} - Elapsed: ${elapsed} ms`
        );
        const timeoutStart = Date.now();
        ZoneUtils.zoneTimeout(
            () => {
                const timeElapsedTimeout = Date.now() - timeoutStart;
                this.logWithTime(
                    `State Change End Timeout! ${timeElapsedTimeout}`
                );
            },
            0,
            this.ngZone,
            true
        );
    }

    //#endregion

    //#endregion - public

    private trackStateChangeStart(stateName: string) {
        this.log('trackStateChangeStart', stateName);
        this.startStateChange();
        this.trackStateChangeStartInConsole(stateName);
    }

    private trackStateChangeStartInConsole(stateName: string) {
        this.logWithTime(`State Change Start - ${stateName}`);
    }
    private trackStateChangeSuccessInConsole(stateName: string) {
        this.log(`State Change Success : ${stateName}`);
    }

    private trackStateChangeErrorInConsole(stateName: string, error: any) {
        this.consoleError(
            `State Change Error : ${stateName}\nError : ${error}`
        );
    }

    private logWithTime(logString: string, nowTimeToUse?: number) {
        if (!this.debug) {
            return;
        }

        nowTimeToUse ??= Date.now();
        const timeStart = nowTimeToUse - this.appStart;
        const timeString = ('       ' + timeStart).slice(-7);
        let secondaryString = '';

        if (this.lastActionStart > 0) {
            const secondaryTime = nowTimeToUse - this.lastActionStart;
            secondaryString = `, Action: ${('       ' + secondaryTime).slice(
                -7
            )} ms`;
        }

        // Example of Loggin' with Style(tm)
        //this.log(`State Change End - %c${toState.name} - Elapsed: ${elapsed} ms`, 'font-weight:bold;')
        this.log(`${timeString} ms ${secondaryString} - ${logString}`);
    }

    private clearLastEvent() {
        this.lastActionStart = 0;
        this.lastEventTime = 0;
        this.lastMutationTime = 0;
        this.lastActionName = '';
        this.firstMutationTime = 0;
        this.totalMutationCount = 0;
        this.isServerCallInProgress = false;
        this.lastActionParams = null;
        this.apiTime = 0;
        this.apiStart = 0;
        this.lastActionType = '';
        this.lastApiEnd = 0;
    }

    private monitorAction(debounced = false) {
        this.log('monitorAction', debounced);
        const now = Date.now();
        const timeSinceLastStateChangeStart = now - this.stateChangeStart;

        if (
            this.isServerCallInProgress ||
            (this.stateChangeLevel > 0 && timeSinceLastStateChangeStart < 500)
        ) {
            this.debouncedMonitorAction();
            return;
        }

        if (!this.lastActionName) {
            return;
        }

        if (this.lastMutationTime === 0) {
            this.lastMutationTime = this.lastActionStart;
        }

        const endTime = Math.max(this.lastApiEnd, this.lastMutationTime);

        this.lastEventTime = endTime - this.lastActionStart;
        let mutationsTime = this.lastMutationTime - this.firstMutationTime;

        let apiString = '';
        if (this.apiTime > 0) {
            apiString = `, API: ${this.apiTime} ms`;
            mutationsTime -= this.apiTime;
        }
        mutationsTime = Math.max(0, mutationsTime);

        const paramsString = this.lastActionParams
            ? `, Params: ${this.lastActionParams}`
            : '';
        const logString =
            `PERF: [${this.lastActionType}].${this.lastActionName}: Total: ${this.lastEventTime} ms` +
            `${apiString}, Client: ${mutationsTime} ms, Mutations Count: ${this.totalMutationCount}` +
            ` ${paramsString}`;
        this.logWithTime(logString, endTime);

        this.clearLastEvent();
    }

    private debouncedMonitorAction() {
        if (this.debouncedMonitorActionTimer) {
            clearTimeout(this.debouncedMonitorActionTimer);
        }
        this.debouncedMonitorActionTimer = setTimeout(
            () => this.monitorAction(true),
            500
        );
    }

    private startStateChange() {
        this.stateChangeLevel++;
        this.stateChangeStart = Date.now();
        this.applyStateChange();
    }
    private endStateChange() {
        this.stateChangeLevel--;
        this.applyStateChange();
    }

    private applyStateChange() {
        this.isInStateChange = this.stateChangeLevel > 0;
    }

    private observeClientErrorService() {
        this.clientErrorService.onClientError$.subscribe((data) =>
            this.notifyClientException(data.error, data.cause, data.type)
        );
    }

    /**
     * #Archi-Error-Cleanup (cbo) Clean up this method and all it's callers to really master how it is used
     * and we correctly type all the arguments (JIRA: DG-2689)
     * */
    private notifyClientException(
        exception: Error,
        cause: string,
        errorType: string
    ): boolean {
        this.log('notifyClientException');
        const browserInfo = BrowserInfoUtil.getBrowserInfo();
        const clientContext = ClientContextUtil.getClientContext(this.injector);
        const exceptionNotificationBuilder = new ExceptionNotificationBuilder({
            exception,
            cause,
            errorType,
            browserInfo: browserInfo,
        });

        if (!exceptionNotificationBuilder.shouldSend) {
            return;
        }

        const clientError = new ClientErrorParameter();
        clientError.ErrorData = exceptionNotificationBuilder.data;
        clientError.ClientContext = clientContext;
        clientError.UserSessionId = this.userSessionId;
        this.monitoringApiService.logError(clientError);

        return true;
    }

    private observeApiService() {
        this.loggingHttpService.onStart$.subscribe(() => {
            this.log('apiService-onStart$');
            this.onApiStart();
        });
        this.loggingHttpService.onStop$.subscribe(() => {
            this.log('apiService-onStop$');
            this.onApiStop();
        });
        this.loggingHttpService.onLog$.subscribe((logString) => {
            this.log('apiService-onLog$');
            this.logWithTime(logString);
        });
    }

    private observeCoreEventsService() {
        if (this.featureFlagService.isFeatureEnabled('ENABLE_AUTH_V2')) {
            this.currentUserService.selectLanguage().subscribe((lang) => {
                this.analyticService.addUserMetaData({ language: lang });
            });
        } else {
            this.coreEventsService.uiLanguageChanged$.subscribe((lang) => {
                this.analyticService.addUserMetaData({ language: lang });
            });
        }
    }

    private observeFunctionalLogService() {
        this.functionalLogService.onDispatch$.subscribe((data) =>
            this.dispatchFunctionalLog(
                data.featureCode,
                data.crudOperation,
                data.source,
                data.crudActionType
            )
        );
    }
    private async dispatchFunctionalLog(
        featureCode: string,
        crudOperation: CrudOperation,
        source: string,
        crudActionType?: CrudActionType
    ) {
        this.log(
            'dispatchFunctionalLog',
            featureCode,
            CrudOperation[crudOperation],
            source,
            crudActionType
        );
        if (crudOperation == undefined) {
            this.consoleWarn('undefined crudOperation');
            return;
        }

        const clientContext =
            ClientContextUtil.getClientContext(this.injector) ?? {};
        const userActionParam = new LogFunctionalActionParameter(
            featureCode,
            crudOperation,
            clientContext,
            source,
            crudActionType
        );
        await this.monitoringApiService.logFunctionalAction(userActionParam);

        await this.analyticService.track(
            `${featureCode}-${CrudOperation[crudOperation]}`,
            userActionParam,
            { path: this.currentNavigationPath }
        );
    }

    private observeNavigationApiService() {
        this.navigationApiService.onGetNavSpaces$.subscribe((firstNavProject) =>
            this.segmentSpaceUpdate(
                getContextId(firstNavProject.spaceId),
                firstNavProject.versionId
            )
        );
    }
    private async segmentSpaceUpdate(
        spaceid: string,
        defaultVersionid: string
    ) {
        this.log('segmentSpaceUpdate', spaceid, defaultVersionid);
        await this.analyticService.addUserMetaData({
            spaceid,
            defaultVersionid,
        });
    }

    private onApiStart() {
        this.apiStart = Date.now();
        this.isServerCallInProgress = true;
    }
    private onApiStop() {
        this.apiTime += Date.now() - this.apiStart;
        this.apiStart = 0;
        this.lastApiEnd = Date.now();
        this.isServerCallInProgress = false;
    }

    private tryStartAction(
        actionType: string,
        actionName: string,
        params?: any
    ) {
        if (this.lastActionName) {
            return;
        }
        this.startAction(actionType, actionName, params);
    }
    private startAction(actionType: string, actionName: string, params?: any) {
        if (!this.debug && this.monitorActionsOnlyIfDebug) {
            return;
        }
        this.log('startAction', actionType, actionName, params);

        if (
            this.lastActionStart > 0 &&
            this.totalMutationCount > 0 &&
            !this.isServerCallInProgress &&
            this.stateChangeLevel === 0
        ) {
            this.monitorAction();
        }

        this.clearLastEvent();
        this.lastActionStart = Date.now();
        this.lastActionType = actionType;
        this.lastActionName = actionName;
        this.lastActionParams = params ?? null;

        this.debouncedMonitorAction();
    }

    private consoleWarn(...args: any[]) {
        if (environment.production) {
            return;
        }
        console.warn(...args);
    }
    private consoleError(...args: any[]) {
        if (environment.production) {
            return;
        }
        console.error(...args);
    }
}

import { EventEmitter, Injectable } from '@angular/core';
import {
    GridItemHTMLElement,
    GridStack,
    GridStackDroppedHandler,
    GridStackNode,
    GridStackNodesHandler,
    GridStackOptions,
} from 'gridstack';
import {
    WidgetDroppedEvent,
    WidgetInstanceDroppedEvent,
    WidgetInstanceMovedEvent,
} from '../common/dashboard-grid-events';
import {
    BehaviorSubject,
    catchError,
    filter,
    firstValueFrom,
    of,
    timeout,
} from 'rxjs';
import { GridPosition } from '../domain/grid-position';
import {
    CollectionsHelper,
    CoreUtil,
    ICancellableTimeout,
} from '@datagalaxy/core-util';
import { GRID_COLUMNS, GRID_ROWS } from '../domain/dashboard-constants';
import {
    distanceToOrigin,
    invalidPosition,
} from '../common/dashboard-grid-utils';
import {
    AddWidget,
    GridAction,
    MoveWidget,
    RemoveWidget,
} from './dashboard-grid-actions';

@Injectable()
export class DashboardGridService {
    public grid!: GridStack;
    public widgetDropped = new EventEmitter<WidgetDroppedEvent>();
    public widgetInstanceDropped =
        new EventEmitter<WidgetInstanceDroppedEvent>();
    public widgetInstancesMoved = new EventEmitter<
        WidgetInstanceMovedEvent[]
    >();
    private gridReady = new BehaviorSubject(false);
    public gridReady$ = this.gridReady.asObservable();
    public changing$ = new BehaviorSubject(false);

    private actionsBuffer: GridAction[] = [];
    private bufferTimeout?: ICancellableTimeout<void>;
    private batchExecuting$ = new BehaviorSubject(false);

    public initGs(el: HTMLElement, enableDrag: boolean) {
        if (this.grid) {
            this.destroy();
        }
        const margin = 20;
        const options: GridStackOptions = {
            margin,
            column: GRID_COLUMNS,
            maxRow: GRID_ROWS,
            minRow: 1,
            cellHeight: 380 + margin,
            disableOneColumnMode: true,
            acceptWidgets: (el) => {
                return (
                    el.tagName == 'DXY-DASHBOARD-CARD' ||
                    el.classList.contains('preview-card')
                );
            },
            float: false,
            removable: false,
            handleClass: 'widget-handle',
            resizable: {
                handles: 'none',
            },
        };

        this.grid = GridStack.init(options, el);

        if (!enableDrag) {
            this.grid.disable();
        }

        this.grid.on('dropped', (_event, previousNode, newNode) =>
            this.onDrop(_event, previousNode, newNode)
        );
        this.grid.on('change', ((_event, nodes) => {
            this.changing$.next(true);
            if (this.ensureCorrectNodesPosition()) {
                return;
            }
            const events =
                nodes
                    ?.filter((n) => n.el.id)
                    .map(
                        (n) =>
                            ({
                                position: {
                                    x: n.x,
                                    y: n.y,
                                },
                                widgetInstanceId: n.el.id,
                            } as WidgetInstanceMovedEvent)
                    ) ?? [];
            this.widgetInstancesMoved.emit(events);
        }) as GridStackNodesHandler);

        this.grid.on('added', (async (_event, nodes) => {
            await this.waitNotChanging();
            const addedElTagName = nodes[0].el.tagName;
            if (addedElTagName != 'DXY-DASHBOARD-CARD') {
                return;
            }
            this.ensureCorrectNodesPosition();
        }) as GridStackNodesHandler);

        this.grid.on('removed', (async (_event, nodes) => {
            await this.waitNotChanging();
            this.ensureCorrectNodesPosition(nodes);
        }) as GridStackNodesHandler);

        this.gridReady.next(true);
    }

    public deactivateDrag() {
        if (!this.grid) {
            return;
        }
        this.grid.disable();
    }
    public activateDrag() {
        if (!this.grid) {
            return;
        }
        this.grid.enable();
    }

    public addWidget(el: HTMLElement, position: GridPosition) {
        this.actionsBuffer.push(new AddWidget(el, position));
        this.batchExec();
    }

    public moveWidget(el: GridItemHTMLElement, position: GridPosition) {
        const node = el.gridstackNode;
        if (node.x == position.x && node.y == position.y) {
            return;
        }
        this.actionsBuffer.push(new MoveWidget(el, position));
        this.batchExec();
    }

    public removeWidget(el: HTMLElement) {
        this.actionsBuffer.push(new RemoveWidget(el));
        this.batchExec();
    }

    public destroy() {
        if (this.grid) {
            this.grid.destroy(true);
            delete this.grid;
        }
    }

    private async waitNotChanging() {
        return await firstValueFrom(
            this.changing$.pipe(
                filter((value) => value === false),
                timeout(100),
                catchError(() => of(false))
            )
        );
    }

    private async waitNotBatchExecuting() {
        return await firstValueFrom(
            this.batchExecuting$.pipe(
                filter((value) => value === false),
                timeout(100),
                catchError(() => of(false))
            )
        );
    }

    private batchExec() {
        this.bufferTimeout?.cancel();
        this.bufferTimeout = CoreUtil.startCancellableTimeout(() => {
            const actions = [...this.actionsBuffer];
            this.actionsBuffer = [];
            this.executeAllActions(actions);
        }, 50);
    }

    private async executeAllActions(actions: GridAction[]) {
        if (!this.grid) {
            return;
        }
        await this.waitNotBatchExecuting();
        this.batchExecuting$.next(true);
        this.grid.float(true);
        this.grid.batchUpdate();
        actions
            .sort((a, b) => {
                if (a instanceof RemoveWidget) {
                    return -1;
                }
                if (b instanceof RemoveWidget) {
                    return 1;
                }
                return a.distanceToOrigin - b.distanceToOrigin;
            })
            .forEach((action) => {
                const el = action.el;
                if (action instanceof RemoveWidget) {
                    this.grid.removeWidget(el);
                } else if (action instanceof AddWidget) {
                    this.grid.addWidget(el, {
                        ...action.position,
                        w: 1,
                        h: 1,
                        noResize: true,
                    });
                } else if (action instanceof MoveWidget) {
                    this.grid.update(el, action.position);
                }
            });
        this.grid.commit();
        this.grid.float(false);
        this.batchExecuting$.next(false);
    }

    private onDrop: GridStackDroppedHandler = async (_event, _, newNode) => {
        const droppedEl = newNode.el;
        droppedEl.style.visibility = 'hidden';
        await this.waitNotChanging();
        this.actionsBuffer.push(new RemoveWidget(droppedEl));
        const widgetName = droppedEl.getAttribute('widget-name');
        if (widgetName) {
            this.widgetDropped.emit({
                widgetName,
                position: {
                    x: newNode.x,
                    y: newNode.y,
                },
            });
            return;
        }
        const widgetInstanceId = droppedEl.id;
        if (widgetInstanceId) {
            this.widgetInstanceDropped.emit({
                widgetInstanceId,
                position: {
                    x: newNode.x,
                    y: newNode.y,
                },
            });
        }
    };

    private ensureCorrectNodesPosition = (
        deletedNodes: GridStackNode[] = []
    ): boolean => {
        const allNodes = this.grid
            .getGridItems()
            .filter((n) => !deletedNodes.some((dn) => dn.el.id === n.id))
            .map((i) => i.gridstackNode);
        const distinctPositions = CollectionsHelper.distinctValues(
            allNodes,
            (n) => distanceToOrigin(n as GridPosition)
        ).length;
        if (
            allNodes.some((n) =>
                invalidPosition(allNodes.length, n as GridPosition)
            ) ||
            allNodes.length != distinctPositions
        ) {
            const widgetsToMove = recalculateNodesPositions(allNodes);
            this.widgetInstancesMoved.emit(widgetsToMove);
            return true;
        }
        return false;
    };
}

export function recalculateNodesPositions(nodes: GridStackNode[]) {
    const sortedNodes = nodes.sort((a, b) => {
        const distance =
            distanceToOrigin(a as GridPosition) -
            distanceToOrigin(b as GridPosition);
        if (distance === 0) {
            return nodes.indexOf(a) - nodes.indexOf(b);
        }
        return distance;
    });
    const events = sortedNodes.map((node, index) => {
        const validPosition: GridPosition = {
            x: index % GRID_COLUMNS,
            y: Math.floor(index / GRID_COLUMNS),
        };
        return {
            position: validPosition,
            widgetInstanceId: node.el.id,
        } as WidgetInstanceMovedEvent;
    });
    return events.filter((e) => e);
}

import { NgModule } from '@angular/core';
import { DxyPrimaryKeyGridComponent } from './dxy-primary-key-grid/dxy-primary-key-grid.component';
import { DxyForeignKeyGridComponent } from './foreign-key-grid/dxy-foreign-key-grid.component';
import { DxyOmnigridModule } from '@datagalaxy/core-ui/omnigrid';
import { PrimaryKeyPreviewPaneComponent } from './primary-key-preview-pane/primary-key-preview-pane.component';
import { PrimaryKeySettingsContentComponent } from './primary-key-settings-content/primary-key-settings-content.component';
import { SharedModule } from '../../shared/shared.module';
import { FieldsModule } from '../../shared/fields/fields.module';
import { DxySharedUiModule } from '../../shared/shared-ui/DxySharedUiModule';
import { DxyForeignKeyColumnsTableComponent } from './dxy-foreign-key-columns-table/dxy-foreign-key-columns-table.component';
import { DxyForeignKeySettingsModalComponent } from './dxy-foreign-key-settings-modal/dxy-foreign-key-settings-modal.component';
import { DxyPrimaryKeySettingsModalComponent } from './dxy-primary-key-settings-modal/dxy-primary-key-settings-modal.component';
import { DxyForeignKeyDeleteModalComponent } from './dxy-foreign-key-delete-modal/dxy-foreign-key-delete-modal.component';

@NgModule({
    imports: [
        SharedModule,
        FieldsModule,
        DxySharedUiModule,
        DxyOmnigridModule,
        DxyPrimaryKeyGridComponent,
        DxyForeignKeyGridComponent,
    ],
    exports: [
        DxyForeignKeyColumnsTableComponent,
        DxyForeignKeySettingsModalComponent,
        DxyPrimaryKeySettingsModalComponent,
        DxyForeignKeyDeleteModalComponent,
        PrimaryKeySettingsContentComponent,
        PrimaryKeyPreviewPaneComponent,
    ],
    declarations: [
        DxyForeignKeyColumnsTableComponent,
        DxyForeignKeySettingsModalComponent,
        DxyPrimaryKeySettingsModalComponent,
        DxyForeignKeyDeleteModalComponent,
        PrimaryKeySettingsContentComponent,
        PrimaryKeyPreviewPaneComponent,
    ],
})
export class DxyModelerPkFkModule {}

import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnInit,
} from '@angular/core';
import { ImportContext } from '../../../shared/ImportContext';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { GridCellType, GridConfig, TColDef } from '@datagalaxy/ui/grid';

@Component({
    selector: 'dxy-csv-preimport-preview',
    templateUrl: './dxy-csv-preimport-preview.component.html',
    styleUrls: ['dxy-csv-preimport-preview.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DxyCsvPreImportPreviewComponent
    extends DxyBaseComponent
    implements OnInit
{
    @Input() importContext: ImportContext;

    protected gridConfig: GridConfig<any> = {
        getItemId: (item) => this.rows.indexOf(item).toString(),
        horizontalScroll: true,
    };
    protected cols: TColDef<any>[];
    protected rows: any[];
    constructor() {
        super();
    }

    ngOnInit() {
        const csvParsedData = this.importContext.csvParsedData;
        this.rows =
            csvParsedData?.slice(0, Math.min(csvParsedData.length || 0, 10)) ??
            [];
        this.cols = this.getColumns();
    }

    private getColumns(): TColDef<any>[] {
        return (
            this.importContext.attributeMatches
                ?.filter((attr) => attr.isMatched)
                .map((field) => ({
                    id: field.csvFieldName,
                    headerLabel: field.attributeName,
                    type: GridCellType.text,
                    minWidth: 100,
                    resizable: true,
                    fixed: true,
                    sortable: false,
                })) ?? []
        );
    }
}

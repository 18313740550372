<dxy-navbar-breadcrumb
    [showSpaceDropdown]="showSpaceDropdown"
    [showVersionDropdown]="showVersionDropdown"
    [isSpaceSelectable]="isSpaceSelectable"
    [isVersionSelectable]="isVersionSelectable"
    [currentViewTitle]="viewTitle"
    [showCurrentClientName]="showCurrentClientName"
    [dgZone]="dgZone"
></dxy-navbar-breadcrumb>

<div *ngIf="showSubTitleContainer" class="bottom-container">
    <div *ngIf="showFiltersAndActions" class="filter-container">
        <app-filtered-views-nav-header
            [dgZone]="dgZone"
            [dgModule]="dgModule"
            [spaceIdr]="spaceData"
            [moduleSecurityData]="moduleSecurityData"
            [currentFilteredView]="currentFilteredView"
        ></app-filtered-views-nav-header>
        <div class="actions-container">
            <dxy-entity-filters-save-actions
                [dgModule]="dgModule"
                [filteredView]="currentFilteredView"
            ></dxy-entity-filters-save-actions>
            <button
                mat-icon-button
                *ngIf="isFilterButtonVisible"
                [matTooltip]="filterBtnTooltip"
                (click)="onFilterClick()"
            >
                <i class="glyph-filter-empty"></i>
                <span *ngIf="hasFilters" class="dg5-notif-badge">{{
                    filtersCount
                }}</span>
            </button>
            <dxy-entity-view-toggle-button
                *ngIf="showModuleActions"
                [dgModule]="dgModule"
            >
            </dxy-entity-view-toggle-button>
            <app-navbar-title-more-actions
                [dgZone]="dgZone"
                [dgModule]="dgModule"
                [spaceData]="spaceData"
                [moduleSecurityData]="moduleSecurityData"
                [showSearchPreferencesRedirection]="showSearchActions"
            >
            </app-navbar-title-more-actions>
        </div>
    </div>

    <div *ngIf="showTitle" class="title">{{ title }}</div>
</div>

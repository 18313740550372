import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import { EntityNodeTree } from '../entity-node.types';
import { IActionOption } from '@datagalaxy/core-ui';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';

@Component({
    selector: 'app-entity-node-tree-item',
    templateUrl: './entity-node-tree-item.component.html',
    styleUrls: ['./entity-node-tree-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EntityNodeTreeItemComponent implements AfterViewInit {
    @Input() nodeTree: EntityNodeTree;
    @Input() level = 0;
    @Input() flat = false;
    @Input() actions?: IActionOption<Partial<EntityItem>>[];

    @Output() collapse = new EventEmitter<EntityNodeTree>();
    @Output() showMore = new EventEmitter<string>();

    @ViewChild('childComponent', { read: ViewContainerRef })
    private viewContainerRef?: ViewContainerRef;

    protected get isLeaf() {
        return !this.nodeTree.hasChildren && !this.nodeTree.children?.length;
    }

    protected get hasNoAccess() {
        return !this.nodeTree.entity.HddData.Data.HasReadAccess;
    }

    protected get showMoreOption() {
        return (
            this.nodeTree.childrenCount &&
            this.nodeTree.children?.length < this.nodeTree.childrenCount
        );
    }

    protected get paddingLeft() {
        const isLeaf = this.isLeaf && !this.flat;
        return 12 + this.level * 10 + (isLeaf ? 20 : 0);
    }

    protected get showMorePaddingLeft() {
        return 32 + (this.level + 1) * 10;
    }

    ngAfterViewInit() {
        this.initInnerComponent();
    }

    protected toggleCollapse(event: MouseEvent) {
        event.stopPropagation();
        this.collapse.emit(this.nodeTree);
    }

    protected onShowMoreClick() {
        this.showMore.emit(this.nodeTree.entity.ReferenceId);
    }

    private initInnerComponent() {
        const componentType = this.nodeTree.customChild?.component;
        if (!componentType) {
            return;
        }
        const componentRef =
            this.viewContainerRef?.createComponent(componentType);

        if (!componentRef || !this.nodeTree.customChild?.inputs) {
            return;
        }

        Object.entries(this.nodeTree.customChild.inputs).forEach(
            ([key, value]) => componentRef.setInput(key, value)
        );

        componentRef.changeDetectorRef.detectChanges();
    }
}

import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnInit,
} from '@angular/core';
import { UserService } from '../../../services/user.service';
import { DenodoApiService } from '@datagalaxy/webclient/modeler/data-access';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';
import { SpinnerComponent, withLoading } from '@datagalaxy/core-ui';
import {
    GridCellType,
    GridComponent,
    GridConfig,
    TColDef,
} from '@datagalaxy/ui/grid';
import { AsyncPipe, NgIf } from '@angular/common';

@Component({
    selector: 'dxy-denodo-table-sample',
    templateUrl: 'dxy-denodo-table-sample.component.html',
    styleUrls: ['dxy-denodo-table-sample.component.scss'],
    standalone: true,
    imports: [NgIf, GridComponent, SpinnerComponent, AsyncPipe],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DxyDenodoTableSampleComponent
    extends DxyBaseComponent
    implements OnInit
{
    @Input() entityData: EntityItem;

    public hasSamplingFailed = false;
    public sampledData: any[];
    protected cols: TColDef<any>[] = [];
    protected gridConfig: GridConfig<any> = {
        getItemId: (item: any) => this.sampledData?.indexOf(item)?.toString(),
    };

    public get isGridReadyToDisplay() {
        return !this.loadingSubject.value && !this.hasSamplingFailed;
    }

    constructor(
        private userService: UserService,
        private denodoApiService: DenodoApiService
    ) {
        super();
    }

    ngOnInit() {
        this.init().then();
    }

    @withLoading()
    private async init() {
        const denodoParameters = this.userService.clientDenodoSampleParameters;
        this.sampledData = await this.denodoApiService.getDenodoSampleData(
            this.entityData.TechnicalName,
            denodoParameters
        );
        this.cols = this.getColumns();

        if (!this.sampledData) {
            this.hasSamplingFailed = true;
            return;
        }
    }

    private getColumns(): TColDef<any>[] {
        const dataSample = this.sampledData[0];
        if (!dataSample) {
            return;
        }
        return Object.keys(dataSample).map((fieldName: string) => {
            return {
                id: fieldName,
                headerLabel: fieldName,
                type: GridCellType.text,
                getValue: (row: any) => row[fieldName],
                sortable: true,
            };
        });
    }
}

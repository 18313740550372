import { DxyBaseModalComponent } from '@datagalaxy/ui/dialog';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MsTeamsIntegrationService } from '../ms-teams-integration.service';
import { withLoading } from '@datagalaxy/core-ui';

/**
 * ## Role
 * MsTeams Integration deactivation modal
 */
@Component({
    selector: 'app-ms-teams-integration-deactivation-modal',
    templateUrl: './ms-teams-integration-deactivation-modal.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MsTeamsIntegrationDeactivationModalComponent extends DxyBaseModalComponent<
    void,
    boolean
> {
    constructor(
        dialogRef: MatDialogRef<
            MsTeamsIntegrationDeactivationModalComponent,
            boolean
        >,
        private msTeamsIntegrationService: MsTeamsIntegrationService
    ) {
        super(dialogRef, undefined);
    }

    @withLoading()
    protected async deactivateTeamsIntegration() {
        await this.msTeamsIntegrationService.deactivate();
        this.result = true;
        this.onCloseSubmit();
    }
}

import { Directive, OnChanges, SimpleChanges } from '@angular/core';
import { IWidgetEvents, IWidgetTypeDefinition } from '../WidgetUtil';
import { DxyBaseComponent } from '@datagalaxy/ui/core';

@Directive()
export abstract class DxyBaseWidgetComponent
    extends DxyBaseComponent
    implements OnChanges
{
    protected static typeDef: IWidgetTypeDefinition;
    /** Events triggered by external components */
    public widgetEvents: IWidgetEvents;
    abstract ngOnChanges(changes: SimpleChanges);
}

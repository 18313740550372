import { NgModule } from '@angular/core';
import { SharedModule } from '../shared.module';
import { FilteredEntityCardCellComponent } from './filtered-entity-card-cell/filtered-entity-card-cell.component';
import { DxyNavigationModule } from '../../navigation/DxyNavigationModule';
import { EntityCardCellComponent } from './entity-card/entity-card-cell.component';
import {
    EntityTypeCardComponent,
    EntityTypeIconComponent,
} from '@datagalaxy/webclient/entity/ui';

@NgModule({
    imports: [
        SharedModule,
        DxyNavigationModule,
        EntityTypeIconComponent,
        EntityTypeCardComponent,
    ],
    declarations: [EntityCardCellComponent, FilteredEntityCardCellComponent],
    exports: [EntityCardCellComponent, FilteredEntityCardCellComponent],
})
export class EntityCardModule {}

import {
    ChangeDetectionStrategy,
    Component,
    Inject,
    OnInit,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogType, DxyBaseModalComponent } from '@datagalaxy/ui/dialog';
import { IRuleCheckListModalOptions } from './rule-check-list-modal.types';
import { DxyDgDateTimeCellComponent } from '@datagalaxy/core-ui/omnigrid';
import { DataQualityCheck } from '@datagalaxy/webclient/data-quality/data-access';
import { TextAndToolsCellComponent } from '../../shared/shared-ui/cells/text-and-tools-cell/text-and-tools-cell.component';
import { UserCollectionCellComponent } from '../../shared/shared-ui/cells/user-collection-cell/user-collection-cell.component';
import { TranslateService } from '@ngx-translate/core';
import { GlyphUtil } from '../../shared/util/GlyphUtil';
import { DataQualityService } from '../data-quality.service';
import { DxyModalService } from '../../shared/dialogs/DxyModalService';
import { withLoading } from '@datagalaxy/core-ui';
import { DomUtil } from '@datagalaxy/core-util';
import { GridCellType, GridConfig, TColDef } from '@datagalaxy/ui/grid';
import { IconAndToolsCellComponent } from '../../shared/shared-ui/cells/icon-and-tools-cell/icon-and-tools-cell.component';

@Component({
    selector: 'app-rule-check-list-modal',
    templateUrl: './rule-check-list-modal.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RuleCheckListModalComponent
    extends DxyBaseModalComponent<IRuleCheckListModalOptions, any>
    implements OnInit
{
    protected get ruleStatement() {
        return this.data.qualityRule.statement;
    }

    protected gridConfig: GridConfig<DataQualityCheck> = {
        getItemId: (item) => item.id,
        autoHeight: true,
    };

    protected cols: TColDef<DataQualityCheck>[] = [
        {
            id: 'Result',
            headerLabel: this.getLabel('result'),
            type: GridCellType.custom,
            customCellComponent: IconAndToolsCellComponent,
            getInputs: (check) =>
                ({
                    iconCellData: {
                        glyphClass: GlyphUtil.getDataQualityGlyphClass(
                            check.status
                        ),
                        text: this.translate.instant(
                            DataQualityService.getDataQualityResultTranslateKey(
                                check.status
                            )
                        ),
                        glyphTooltip: this.translate.instant(
                            DataQualityService.getDataQualityResultTranslateKey(
                                check.status
                            )
                        ),
                    },
                    actions: [
                        {
                            glyphClass: 'glyph-delete',
                            tooltipTranslateKey: this.getLabel('deleteTitle'),
                            callback: async (data) => this.onDeleteCheck(data),
                        },
                    ],
                    actionsData: check,
                } as Partial<IconAndToolsCellComponent<DataQualityCheck>>),
            width: 140,
        },
        {
            id: 'creationTime',
            headerLabel: this.getLabel('checkDate'),
            type: GridCellType.custom,
            customCellComponent: DxyDgDateTimeCellComponent,
            getInputs: (check) =>
                ({
                    dgDate: check.creationTime,
                } as Partial<DxyDgDateTimeCellComponent<DataQualityCheck>>),
            width: 160,
            maxWidth: 160,
        },
        {
            id: 'userId',
            headerLabel: this.getLabel('checkUser'),
            type: GridCellType.custom,
            customCellComponent: UserCollectionCellComponent,
            getInputs: (check) =>
                ({
                    userIds: [check.userId],
                } as Partial<UserCollectionCellComponent>),
            maxWidth: 160,
        },
        {
            id: 'message',
            headerLabel: this.getLabel('resultSummary'),
            type: GridCellType.text,
        },
        {
            id: 'detail',
            headerLabel: this.getLabel('checkDetails'),
            type: GridCellType.custom,
            customCellComponent: TextAndToolsCellComponent,
            getInputs: (check) =>
                ({
                    text: check.detail,
                    actions: [
                        {
                            glyphClass: 'glyph-file-copy',
                            callback: (value: DataQualityCheck) =>
                                DomUtil.copyToClipboard(value.detail),
                        },
                    ],
                    actionsData: check,
                } as Partial<TextAndToolsCellComponent>),
        },
    ];

    protected qualityChecks: DataQualityCheck[];

    constructor(
        private translate: TranslateService,
        private dataQualityService: DataQualityService,
        private dxyModalService: DxyModalService,
        dialogRef: MatDialogRef<RuleCheckListModalComponent, any>,
        @Inject(MAT_DIALOG_DATA) data: IRuleCheckListModalOptions
    ) {
        super(dialogRef, data);
    }

    ngOnInit() {
        this.initAsync();
    }

    @withLoading()
    private async initAsync() {
        await this.loadData();
    }

    private async loadData() {
        const dataQualityRule = await this.dataQualityService.getRuleDetails(
            this.data.qualityRule.id
        );
        this.qualityChecks = dataQualityRule.lastChecks;
    }

    public onCloseSubmit() {
        this.dialogRef.close();
    }

    private async onDeleteCheck(check: DataQualityCheck) {
        const confirmed = await this.confirmDeletion();
        if (!confirmed) {
            return;
        }

        await this.deleteRuleCheckAndRefresh(check);
    }

    @withLoading()
    private async deleteRuleCheckAndRefresh(check: DataQualityCheck) {
        await this.dataQualityService.deleteRuleCheck(check.ruleId, check.id);
        await this.loadData();
    }

    private getLabel(suffix: string) {
        return this.translate.instant(`UI.Modeler.DataQualityGrid.${suffix}`);
    }

    private async confirmDeletion() {
        return await this.dxyModalService.confirm({
            titleKey: 'UI.Modeler.DataQualityGrid.deleteTitle',
            messageKey: 'UI.Modeler.DataQualityGrid.deleteMsg',
            type: DialogType.Action,
            confirmButtonKey: 'UI.Dialog.btnDelete',
        });
    }
}

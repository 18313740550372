import { Injectable } from '@angular/core';
import {
    DgModuleName,
    allModuleNames,
} from '@datagalaxy/shared/dg-module/domain';
import { BaseStateService } from '@datagalaxy/utils';
import {
    FilteredViewDto,
    FilterModuleName,
} from '@datagalaxy/webclient/filter/domain';

interface FilteredViewSelectorState {
    availableFilteredViews: FilteredViewDto[];
    allModuleNames: DgModuleName[];
    activeModuleFilters: DgModuleName[];
}

@Injectable()
export class FilteredViewSelectorStateService extends BaseStateService<FilteredViewSelectorState> {
    constructor() {
        super({
            availableFilteredViews: [],
            allModuleNames,
            activeModuleFilters: [],
        });
    }

    public selectHasQuickFilters() {
        return this.select(
            (s) =>
                !s.activeModuleFilters.length && s.availableFilteredViews.length
        );
    }

    public selectAvailableModuleNames() {
        return this.select((s) =>
            s.allModuleNames.filter((m) =>
                s.availableFilteredViews.some(
                    (fv) =>
                        (FilterModuleName[
                            fv.ModuleName ?? 0
                        ] as DgModuleName) == m
                )
            )
        );
    }

    public selectHasActiveModuleFilters() {
        return this.select((s) => s.activeModuleFilters.length > 0);
    }

    public selectActiveModuleFilters() {
        return this.select((s) => s.activeModuleFilters ?? []);
    }

    public selectFilteredViews() {
        return this.select((s) =>
            s.availableFilteredViews.filter((fv) =>
                filteredViewMatchModules(s.activeModuleFilters, fv)
            )
        );
    }

    public removeAllFilters() {
        this.setState({ activeModuleFilters: [] });
    }

    public updateFilters(moduleNames: DgModuleName[]) {
        this.setState({
            activeModuleFilters: [...moduleNames],
        });
    }

    public updateAvailableFilteredViews(filteredViews: FilteredViewDto[]) {
        this.setState({
            availableFilteredViews: filteredViews,
        });
    }
}

function filteredViewMatchModules(
    modules: DgModuleName[],
    fv: FilteredViewDto
) {
    if (fv.ModuleName == undefined) {
        return;
    }
    if (modules.length == 0) {
        return true;
    }
    return modules
        .map((m) => FilterModuleName[m as any] as any)
        .includes(fv.ModuleName);
}

<ul *ngIf="showDevPreviewOptions">
    <li
        *ngFor="let opt of devPreviewOptions"
        [class.disabled]="opt.readonly"
        (click)="!opt.readonly && toggleOption(opt, $event)"
        [class.input]="opt.isInput"
    >
        <div class="column">
            <div>
                <i
                    class="item glyph"
                    [class.glyph-live]="opt.active"
                    [class.glyph-view-more]="!opt.active && !opt.readonly"
                    [class.glyph-hide]="!opt.active && opt.readonly"
                ></i>
                {{ opt.text }}: {{ opt.active ? 'on' : 'off' }}
            </div>
            <div
                *ngIf="opt.active && opt.isInput"
                class="value"
                (click)="$event.stopPropagation()"
            >
                value:
                <input
                    #input
                    [value]="opt.inputValue"
                    (change)="inputChange(opt, input.value, $event)"
                />
                <i
                    *ngIf="opt.inputValue != opt.inputDefaultValue"
                    class="glyph-cancelsearch reset"
                    (click)="resetInput(opt, $event)"
                ></i>
            </div>
        </div>
    </li>
    <li *ngIf="canClearDevPreviewOptionsCache" (click)="clearCache($event)">
        <i class="glyph glyph-cancelsearch"></i>
        Clear preview options cache
    </li>
</ul>

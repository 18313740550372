<ng-container *ngIf="isOrganization">
    <div class="space-module-widget-wrapper">
        <dxy-space-module-widget
            [spaceIdr]="currentOrganization"
            [dgModule]="dgModule"
        >
        </dxy-space-module-widget>
    </div>

    <div class="orga-group">
        <div *ngIf="isSubOrgaEnabled" id="box-organization" class="box">
            <h4>
                <span translate>UI.OrganizationDashboard.titleSub</span>
                <button
                    *ngIf="
                        hasAdminAccess(
                            currentOrganization.OrganizationSecurityData
                        )
                    "
                    mat-flat-button
                    color="primary"
                    (click)="onCreateOrganization()"
                >
                    <span class="glyph-add"></span>
                    <span translate>UI.OrganizationDashboard.btnNewSub</span>
                </button>
            </h4>
            <div class="box-content">
                <div
                    *ngFor="
                        let subOrganization of currentOrganization.ChildOrganizations
                            | orderBy : 'DisplayName'
                    "
                    class="organization-line"
                >
                    <button
                        mat-button
                        (click)="goToOrganizationDashboard(subOrganization)"
                        dxyLogFunctional="ORGA,R"
                    >
                        <span class="glyph-organization"></span>
                        <span> {{ subOrganization.DisplayName }} </span>
                    </button>
                    <div class="actions">
                        <button
                            *ngIf="hasAdminAccess(subOrganization.SecurityData)"
                            mat-icon-button
                            (click)="onEditSubOrganization(subOrganization)"
                        >
                            <i class="glyph-edit"></i>
                        </button>
                        <button
                            *ngIf="
                                hasAdminAccess(
                                    currentOrganization.OrganizationSecurityData
                                )
                            "
                            mat-icon-button
                            (click)="onDeleteSubOrganization(subOrganization)"
                        >
                            <i class="glyph-delete"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="box">
            <h4>
                <span translate> UI.OrganizationDashboard.titleProjects </span>
                <button
                    *ngIf="
                        hasAdminAccess(
                            currentOrganization.OrganizationSecurityData
                        )
                    "
                    mat-flat-button
                    color="primary"
                    (click)="onCreateProject()"
                >
                    <span class="glyph-add"></span>
                    <span translate>
                        UI.OrganizationDashboard.btnNewProject
                    </span>
                </button>
            </h4>
            <div class="box-content">
                <div
                    *ngFor="
                        let project of currentOrganization.Projects
                            | orderBy : 'DisplayName'
                    "
                    class="organization-line"
                >
                    <button
                        mat-button
                        (click)="goToProjectDashboard(project)"
                        dxyLogFunctional="PROJECT,R"
                    >
                        <span class="glyph-project"></span>
                        <span> {{ project.DisplayName }}</span>
                    </button>
                    <div class="actions">
                        <button
                            *ngIf="hasAdminAccess(project.SecurityData)"
                            mat-icon-button
                            (click)="onEditProject(project)"
                        >
                            <i class="glyph-edit"></i>
                        </button>
                        <button
                            *ngIf="
                                hasAdminAccess(
                                    currentOrganization.OrganizationSecurityData
                                )
                            "
                            mat-icon-button
                            (click)="onDeleteProject(project)"
                        >
                            <i class="glyph-delete"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>

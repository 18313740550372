import { Injectable } from '@angular/core';
import {
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { BaseServiceParameter } from '../backend';
import { SignalRService } from '../signalr';
import { ObjectUtils } from '@datagalaxy/utils';

@Injectable()
export class RealtimeIdInterceptor implements HttpInterceptor {
    constructor(private signalR: SignalRService) {}

    intercept(req: HttpRequest<BaseServiceParameter>, next: HttpHandler) {
        const realtimeId = this.signalR.connectionId;

        if (!realtimeId) {
            return next.handle(req);
        }

        const reqClone: HttpRequest<BaseServiceParameter> = req.clone({
            body: ObjectUtils.assign(req.body, {
                RealTimeConnectionId: realtimeId,
            }),
        });

        return next.handle(reqClone);
    }
}

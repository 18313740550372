import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import { AppDataService } from '../../../services/app-data.service';
import { SecurityService } from '../../../services/security.service';
import { FilteredViewService } from '../services/filteredView.service';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { FilteredViewDto } from '@datagalaxy/webclient/filter/domain';
import { DgModule } from '@datagalaxy/shared/dg-module/domain';

@Component({
    //eslint-disable-next-line @angular-eslint/component-selector
    selector: 'dxy-entity-filters-save-actions',
    templateUrl: './dxy-entity-filters-save-actions.component.html',
    styleUrls: ['./dxy-entity-filters-save-actions.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DxyEntityFiltersSaveActionsComponent
    extends DxyBaseComponent
    implements OnInit, OnChanges
{
    @Input() dgModule: DgModule;
    @Input() filteredView: FilteredViewDto;

    public get canSaveCurrent() {
        return (
            this.canSaveAs &&
            this.hasWriteAccess &&
            this.hasChanged &&
            this.filteredView?.FilteredViewId > 0
        );
    }
    public get canSaveAs() {
        return FilteredViewService.hasCompletedFilters(this.filteredView);
    }
    public get hasChanged() {
        return FilteredViewService.hasChanged(this.filteredView);
    }
    public get areActionsVisible() {
        return this.canSaveAs || this.hasChanged;
    }

    private get hasWriteAccess() {
        return (
            this.filteredView?.CreationUserId ==
                this.appDataService.currentUserId ||
            this.securityService.isSteward()
        );
    }

    constructor(
        private filteredViewService: FilteredViewService,
        private appDataService: AppDataService,
        private securityService: SecurityService,
        private cd: ChangeDetectorRef
    ) {
        super();
    }

    ngOnInit() {
        this.log('ngOnInit', {
            filteredView: this.filteredView,
            dgModule: this.dgModule,
        });
        this.subscribeEvents();
    }

    ngOnChanges(changes: SimpleChanges) {
        super.onChange(changes, 'filteredView', () => this.subscribeEvents());
    }

    private subscribeEvents() {
        super.registerSubscriptions(
            this.filteredViewService.currentViewChanged$.subscribe((event) => {
                this.filteredView = event.filteredView;
                this.cd.detectChanges();
            }),
            this.filteredViewService.viewCRUD$.subscribe((e) => {
                if (e.filteredView !== this.filteredView) {
                    return;
                }
                this.cd.detectChanges();
            })
        );
    }

    public async onSaveAsClick() {
        await this.filteredViewService.saveFilteredViewAs(
            this.dgModule,
            this.filteredView
        );
    }
    public async onSaveCurrentClick() {
        await this.filteredViewService.updateFilteredView(this.filteredView);
    }
    public onCancelChangesClick() {
        const fv = this.filteredView;
        this.filteredView.reset();
        this.filteredViewService.notifyCurrentViewChange({
            dgZone: fv.DgZone,
            filteredView: fv,
        });
    }
}

import { EntityTaskDTO } from '@datagalaxy/dg-object-model';
import { BaseStateService } from '@datagalaxy/utils';
import { TaskService } from './task.service';
import {
    CrudOperation,
    FunctionalLogService,
} from '@datagalaxy/webclient/monitoring/data-access';
import { ISpaceIdentifier } from '@datagalaxy/webclient/workspace/domain';
import { AppSpaceService } from '../services/AppSpace.service';
import { Injectable } from '@angular/core';
import {
    TaskSearchFilterGroup,
    TaskSearchFilterItem,
} from './dxy-task-facet-filters/dxy-task-facet-filters.component';
import { CollectionsHelper } from '@datagalaxy/core-util';
import { Filter } from '@datagalaxy/webclient/filter/domain';
import { AttributeFilter } from '@datagalaxy/webclient/attribute/data-access';

interface TaskSearchState {
    tasks: EntityTaskDTO[];
    spaceIdr?: ISpaceIdentifier;
    loading?: boolean;
    searchText?: string;
    filtersTrack: TaskSearchFilterItem[];
    attributeFilters?: AttributeFilter[];
}

@Injectable()
export class TaskSearchStateService extends BaseStateService<TaskSearchState> {
    constructor(
        private taskService: TaskService,
        private functionalLogService: FunctionalLogService,
        private appSpaceService: AppSpaceService
    ) {
        super({
            tasks: [],
            filtersTrack: [],
        });
    }

    selectLoading() {
        return this.select((state) => state.loading);
    }

    selectFilteredTasks() {
        return this.select((state) => state.tasks);
    }

    selectAttributeFilters() {
        return this.select((state) => state.attributeFilters);
    }

    selectUserSearched() {
        return this.select(
            (state) => !!state.searchText || !!state.filtersTrack?.length
        );
    }

    setSearchString(searchText: string) {
        this.setState({ searchText, loading: true });
        // Effect
        this.search(!searchText);
    }

    setSpaceIdr(spaceIdr: ISpaceIdentifier) {
        this.setState({ spaceIdr, loading: true });
        // Effect
        this.search();
    }

    applyFilters(
        clickedFilterItem: TaskSearchFilterItem,
        clickedFilterGroup: TaskSearchFilterGroup
    ) {
        const filtersTrack = [...this.state.filtersTrack];
        if (clickedFilterItem) {
            if (clickedFilterItem.filterType == 'DueTime') {
                // remove other datetime filters because they are not always self-exclusive (ie today and currentWeek)
                CollectionsHelper.remove(
                    filtersTrack,
                    (ti) =>
                        ti.filterType == clickedFilterItem.filterType &&
                        ti.id != clickedFilterItem.id
                );
            }
            CollectionsHelper.toggle(
                filtersTrack,
                clickedFilterItem,
                (a, b) => a.id == b.id
            );
        } else if (clickedFilterGroup) {
            clickedFilterGroup.items.forEach((it) =>
                CollectionsHelper.remove(filtersTrack, (ti) => ti.id == it.id)
            );
        }
        this.setState({ filtersTrack, loading: true });
        // Effect
        this.search(false, true);
    }

    removeTasks(TaskIds: string[]) {
        this.setState({
            tasks: this.state.tasks.filter(
                (t) => !TaskIds.includes(t.ReferenceId)
            ),
        });
    }

    updateTask(updatedTask: EntityTaskDTO) {
        this.setState({
            tasks: this.state.tasks.map((t) => {
                return t.ReferenceId === updatedTask.ReferenceId
                    ? updatedTask
                    : t;
            }),
        });
    }

    async initAsync() {
        if (this.appSpaceService.isSingleWorkspace()) {
            const selectedSpaceIdr =
                await this.appSpaceService.getASpaceIdentifier({
                    includeCurrent: true,
                    includeLast: true,
                });
            this.setState({ spaceIdr: selectedSpaceIdr });
        }
    }

    private async search(isAuto = false, keepFilters = false) {
        const newState = { ...this.state };
        if (!isAuto) {
            this.functionalLogService.logFunctionalAction(
                'MAIN_TASK_SEARCH',
                CrudOperation.R
            );
        }
        if (!keepFilters) {
            newState.filtersTrack = [];
        }

        const searchResult = await this.taskService.getUserTasks(
            newState.spaceIdr,
            newState.searchText,
            serverFilterSelector(newState)
        );

        const tasks = searchResult?.Tasks ?? [];
        const attributeFilters = searchResult?.AttributeFilters ?? [];

        this.setState({ tasks, attributeFilters, loading: false });
    }
}

function serverFilterSelector(state: TaskSearchState): Filter[] {
    return state.filtersTrack.map((o) => o.serverFilter);
}

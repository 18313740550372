import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DxyBaseModalComponent } from '@datagalaxy/ui/dialog';
import { CollectionsHelper, CoreUtil } from '@datagalaxy/core-util';
import { AttributeFieldInfo } from '../../attribute/attribute.types';
import { DataUtil } from '../../util/DataUtil';
import {
    EntityType,
    EntityTypeUtil,
    HierarchyDataDescriptor,
    ServerType,
} from '@datagalaxy/dg-object-model';
import { HddUtil } from '../../util/HddUtil';
import { ModelerDataUtil } from '../../util/ModelerDataUtil';
import {
    CreationContext,
    IEntityCreateModalInput,
    IEntityCreateModalOutput,
    TValue,
} from '../interfaces/entity-create-modal.types';
import { IEntityForm } from '../interfaces/entity-form.interface';
import { Subject } from 'rxjs';
import { EntityService } from '../services/entity.service';
import { CreateEntityCheckParentMode } from '../entity.types';
import { ToasterService } from '../../../services/toaster.service';
import { SpaceApiService } from '../../../space/services/space-api.service';
import { EntitySecurityService } from '../services/entity-security.service';
import { VersionStore } from '../../../versioning/helpers/versionStore';
import {
    IGotoDetailsOptions,
    NavigationService,
} from '../../../services/navigation.service';
import { GlyphService } from '../../../services/glyph.service';
import { AttributeDataService } from '../../attribute/attribute-data.service';
import { VersioningUiService } from '../../../versioning/services/versioning-ui.service';
import { NavigationApiService } from '../../../navigation/services/navigation-api.service';
import {
    NavProject,
    NavSpace,
    Project,
    Space,
} from '@datagalaxy/webclient/workspace/data-access';
import { PreCreateEntityStatus } from '@datagalaxy/webclient/attribute/data-access';
import {
    AttributeValueExtendedInfo,
    AttributeValueInfo,
    CreateEntityOperation,
    UpdateAttributeAction,
} from '@datagalaxy/webclient/entity/data-access';
import {
    CrudOperation,
    FunctionalLogService,
} from '@datagalaxy/webclient/monitoring/data-access';
import { SpaceIdentifier } from '@datagalaxy/webclient/workspace/utils';
import { ISpaceIdentifier } from '@datagalaxy/webclient/workspace/domain';
import {
    AttributeMetaInfo,
    AttributeMetaType,
    AttributeMetaValue,
} from '@datagalaxy/webclient/attribute/domain';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';
import { DgModule } from '@datagalaxy/shared/dg-module/domain';
import { ServerConstants } from '@datagalaxy/shared/server/domain';
import {
    EntityCreationOrigin,
    EntityCreator,
} from '@datagalaxy/webclient/entity/feature';

export interface AttributeMetaValueOf<T> extends AttributeMetaValue {
    Value: T;
}

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'dxy-entity-create-modal',
    templateUrl: './dxy-entity-create-modal.component.html',
})
export class DxyEntityCreateModalComponent
    extends DxyBaseModalComponent<
        IEntityCreateModalInput,
        IEntityCreateModalOutput
    >
    implements OnInit
{
    public fieldInfos: AttributeFieldInfo[];
    public canCreate = false;
    public isCheckedCreateAnother: boolean;
    public creationLoading = false;
    public hideCreateAnother = false;

    public get modalTitleKey() {
        switch (this.serverType) {
            case ServerType.Property:
                return 'UI.EntityCreateModal.lblPropTitle';
            case ServerType.Model:
                return 'UI.EntityCreateModal.lblModelTitle';
            case ServerType.DataProcessing:
                return 'UI.EntityCreateModal.lblDPTitle';
            case ServerType.SoftwareElement:
                return 'UI.EntityCreateModal.lblUsageTitle';
            default:
                return 'UI.EntityCreateModal.lblDefaultTitle';
        }
    }

    public get actionBtnLbl() {
        const isCopy =
            this.existingEntity &&
            this.existingEntityMsgKey ==
                'UI.EntityCreateModal.preCreateStatus.ExistsInParentSpace';
        return this.translate.instant(
            `UI.Dialog.NewItem.${isCopy ? 'lblCopy' : 'lblCreate'}`
        );
    }

    public get featureCode() {
        if (!this.canCreate) {
            return;
        }
        const isProject = SpaceIdentifier.isProject(this.spaceIdr);
        if (isProject == undefined) {
            return;
        }
        return FunctionalLogService.getEntityActionFeatureCodeCrud(
            ServerType[this.serverType],
            this.entitySubType,
            CrudOperation.C,
            isProject
        );
    }

    private isFromAddButton: boolean;
    private redirectAfterCreation: boolean;
    /** this is selected NavSpace, or the currentSpace  */
    private spaceIdr: ISpaceIdentifier;
    private readonly navSpaceById = new Map<string, NavSpace>();
    private currentSpace: Space;
    private serverType: ServerType;
    private parentHdd: HierarchyDataDescriptor;

    private moduleName: string;
    private displayName: string;
    private technicalName: string;
    private description: string;
    private localEntityType: EntityType;
    private referenceDataValueCode: string;

    private attributes: Map<string, AttributeMetaInfo>;
    private entityForm: IEntityForm<TValue>;
    private actionOrigin: EntityCreationOrigin;
    private selectedModule: AttributeMetaValueOf<string>;
    private selectedTechnology: AttributeMetaValueOf<string>;
    private selectedVersion: AttributeMetaValueOf<string>;
    private selectedWorkspace: AttributeMetaValueOf<string>;
    private selectedSubType: AttributeMetaValueOf<string>;
    private ownersAttributeValueInfo: AttributeValueExtendedInfo;
    private stewardsAttributeValueInfo: AttributeValueExtendedInfo;

    private fiDisplayName: AttributeFieldInfo;
    private fiDescription: AttributeFieldInfo;
    private fiTechnicalName: AttributeFieldInfo;
    private fiLogicalParent: AttributeFieldInfo;
    private fiTechnology: AttributeFieldInfo;
    private fiRefDataValueCode: AttributeFieldInfo;
    private fiCheckParent: AttributeFieldInfo;
    private fiWorkspace: AttributeFieldInfo;
    private fiModule: AttributeFieldInfo;
    private fiType: AttributeFieldInfo;
    private fiOwners: AttributeFieldInfo;
    private fiStewards: AttributeFieldInfo;
    private fiVersions: AttributeFieldInfo;
    private entityFieldInfos: Map<string, AttributeFieldInfo>;

    private existingEntity: EntityItem;
    private existingEntityMsgKey: string;
    private existingNameError: string;

    private showVersionsField: boolean;
    private isDisplayNameUpdated = false;
    private isTechnicalNameUpdated = false;
    private isNewNavSpaceSelected = false;
    private hasManuallySetReferenceDataValueCode = false;
    private ownersEdited = false;
    private stewardsEdited = false;

    private timerRefreshPreCreateEntity: any;
    private timerRefreshDefaultValues: any;
    private timerRefreshUI: any;

    private isAttributesReady = false;
    private isInitDone = false;

    private readonly entityTypeChange = new Subject<void>();
    private readonly attributeValueLocalChange = new Subject<string>();

    private versionStore: VersionStore;
    private checkParent: boolean;

    private get hasTechnicalName() {
        return this.serverType != ServerType.Property && this.isAttributesReady;
    }

    private get hasCodeAttribute() {
        return this.localEntityType == EntityType.ReferenceDataValue;
    }

    private get hasTechnologyAttribute() {
        if (this.selectedTechnology.Value) {
            return true;
        }
        return (
            this.selectedModule?.Value == 'Model' ||
            this.isTechnoEditable(this.localEntityType) ||
            this.isTechnoEditable(this.parentHdd?.EntityType)
        );
    }

    private get entitySubType() {
        return this.selectedSubType?.Value;
    }

    private get zoneFeatureCode() {
        return this.navigationService.getCurrentZoneFeatureCode();
    }

    private get isParentValueFixed() {
        switch (this.actionOrigin) {
            case EntityCreationOrigin.dockingPane:
            case EntityCreationOrigin.burgerMenu:
            case EntityCreationOrigin.burgerMenuCreateChild:
            case EntityCreationOrigin.gridAddButton:
                return true;
            case EntityCreationOrigin.diagramAddEntityButton:
                return this.isForPhysicalDiagramTableOrView;
            default:
                return false;
        }
    }

    private get parentId() {
        return (
            this.parentHdd?.DataReferenceId ??
            (this.isFromAddButton
                ? undefined
                : this.data.parentData?.ReferenceId) ??
            this.spaceIdr.spaceId
        );
    }

    private get isForPhysicalDiagramTableOrView() {
        return (
            this.data.actionOrigin ==
                EntityCreationOrigin.diagramAddEntityButton &&
            !!this.data.modelHdd &&
            this.data.filterEntityTypes?.length == 1 &&
            (this.data.filterEntityTypes[0] == EntityType.Table ||
                this.data.filterEntityTypes[0] == EntityType.View)
        );
    }

    constructor(
        private changeDetector: ChangeDetectorRef,
        private translate: TranslateService,
        private attributeDataService: AttributeDataService,
        private navigationService: NavigationService,
        private navigationApiService: NavigationApiService,
        private toasterService: ToasterService,
        private versioningUiService: VersioningUiService,
        private entityService: EntityService,
        private entitySecurityService: EntitySecurityService,
        private entityCreator: EntityCreator,
        private functionalLogService: FunctionalLogService,
        private spaceApiService: SpaceApiService,
        private glyphService: GlyphService,
        dialogRef: MatDialogRef<
            DxyEntityCreateModalComponent,
            IEntityCreateModalOutput
        >,
        @Inject(MAT_DIALOG_DATA) data: IEntityCreateModalInput
    ) {
        super(dialogRef, data);
    }

    ngOnInit() {
        this.init().then();
    }

    //#region html bindings
    public onChangeCreateAnother() {
        this.isCheckedCreateAnother = !this.isCheckedCreateAnother;
        this.refreshPreCreateEntity();
    }

    public onCloseCancel(): void {
        this.functionalLogService.logFunctionalAction(
            `CANCEL_CREATE_OBJECT_${this.zoneFeatureCode}`,
            CrudOperation.C
        );
        super.onCloseCancel();
    }

    public onCloseSubmit() {
        if (!this.canCreate) {
            return;
        }
        this.creationLoading = true;
        this.onCreate().then(() => {
            this.refreshPreCreateEntity();
            // Without waiting for the End of the creation, we proceeed with the following steps:
            if (this.isCheckedCreateAnother) {
                setTimeout(() => {
                    this.creationLoading = false;
                    this.changeDetector.detectChanges();
                }, 500);
            } else {
                super.onCloseSubmit();
            }
        });
    }

    //#endregion

    public async init() {
        this.log('init', this.data);

        this.functionalLogService.logFunctionalAction(
            `START_CREATE_OBJECT_${this.zoneFeatureCode}`,
            CrudOperation.C
        );
        this.entityFieldInfos ??= new Map<string, AttributeFieldInfo>();
        this.actionOrigin = this.data.actionOrigin;
        this.isFromAddButton = [
            EntityCreationOrigin.globalAddButton,
            EntityCreationOrigin.diagramAddEntityButton,
        ].includes(this.actionOrigin);
        this.redirectAfterCreation =
            this.actionOrigin == EntityCreationOrigin.globalAddButton;
        this.hideCreateAnother =
            this.actionOrigin == EntityCreationOrigin.diagramAddEntityButton;
        this.isCheckedCreateAnother = false;
        this.currentSpace = this.data.currentSpace;
        this.versionStore = new VersionStore();
        this.fiCheckParent = this.makeGenericFieldInfo();
        this.serverType =
            this.data.serverType || this.data.parentData?.ServerType;

        const entityForm = (this.entityForm = this.buildEntityForm());

        // Creation of Module attribute
        this.fiModule = this.attributeDataService.createFieldInfo(
            entityForm,
            'Module',
            'UI.EntityCreateModal.lblModules',
            AttributeMetaType.ValueList,
            {
                isMandatory: true,
                isReadOnly: this.isForPhysicalDiagramTableOrView,
            }
        );

        // Creation of Version attribute
        this.fiVersions = this.attributeDataService.createFieldInfo(
            entityForm,
            'Version',
            'UI.EntityCreateModal.lblVersion',
            AttributeMetaType.ValueList
        );

        this.fiCheckParent = null;

        this.fiTechnicalName = this.makeGenericFieldInfo(true);
        this.fiDisplayName = this.makeGenericFieldInfo(true);
        this.fiStewards = this.makeGenericFieldInfo(true);
        this.fiOwners = this.makeGenericFieldInfo(true);
        this.fiDescription = this.makeGenericFieldInfo();
        this.fiTechnology = this.makeGenericFieldInfo();

        if (
            this.data.consolidationMode !==
            CreateEntityCheckParentMode.NoCheckBox
        ) {
            this.fiCheckParent = this.attributeDataService.createFieldInfo(
                entityForm,
                'CheckParent',
                'UI.EntityCreateModal.lblCheckParent',
                AttributeMetaType.Boolean
            );
        }

        // NOTE: As per request (DG-431), checkBox is forcefully disabled temporarily
        this.checkParent = false; //  this.resolve.consolidationMode === CreateEntityCheckParentMode.CheckBoxChecked

        this.fiWorkspace = this.attributeDataService.createFieldInfo(
            entityForm,
            'Workspace',
            'UI.EntityCreateModal.lblSpaces',
            AttributeMetaType.ValueList
        );

        this.result = {
            operation: CreateEntityOperation.Create,
            existingEntity: null,
            displayName: this.data.defaultName,
        };

        await this.initSpaces();
        this.isInitDone = true;
        this.refreshUI(true);
    }

    private async initSpaces() {
        this.navSpaceById.clear();
        if (this.currentSpace) {
            this.spaceIdr = SpaceIdentifier.from(this.currentSpace);
            await this.updateModulesListFromSpaceIdr();
            const moduleServerType =
                this.getCurrentModuleServerTypeFromNavigationState();
            const moduleServerTypeName = ServerType[moduleServerType];
            const moduleAMI = this.fiModule.attributeMeta;
            if (
                moduleServerType &&
                moduleAMI.ListValues.some((c) => c.Key == moduleServerTypeName)
            ) {
                this.serverType ||= moduleServerType;
                const translatedDisplayName =
                    this.getModuleName(moduleServerType);
                const amv = new AttributeMetaValue(
                    moduleAMI,
                    moduleServerTypeName,
                    moduleServerTypeName,
                    { translatedDisplayName }
                );
                this.selectedModule = amv;
                this.moduleName = amv.Value;
                await this.setupAttributes();
            }
        } else {
            this.spaceIdr = new SpaceIdentifier(null);
            await this.loadNavSpaces();
        }
    }

    private async onCreate() {
        const creationContext = this.getCreationContext();
        this.log(
            'onCreate',
            CreateEntityOperation[this.result?.operation],
            EntityCreationOrigin[this.actionOrigin],
            creationContext
        );
        let entity: EntityItem;

        if (this.result.operation === CreateEntityOperation.Create) {
            const aviList: AttributeValueInfo[] = [
                this.ownersAttributeValueInfo,
                this.stewardsAttributeValueInfo,
            ];
            this.addTechnoIfSelected(aviList);

            entity = await this.entityCreator.createEntity(
                creationContext.parentId,
                creationContext.versionId,
                EntityTypeUtil.getEntityType(
                    ServerType[creationContext.serverType],
                    creationContext.subTypeName
                ),
                this.displayName,
                {
                    description: this.description,
                    actionOrigin: this.actionOrigin,
                    isMultiCreation: this.isCheckedCreateAnother,
                    checkParent: this.checkParent,
                    referenceDataValueCode: this.referenceDataValueCode,
                    technicalName: this.technicalName,
                    attributeValues: aviList,
                }
            );
        } else {
            entity = await this.entityCreator.createEntityFromExisting(
                creationContext.parentId,
                creationContext.versionId,
                this.existingEntity,
                {
                    description: this.description,
                    actionOrigin: this.actionOrigin,
                    isMultiCreation: this.isCheckedCreateAnother,
                }
            );
        }

        this.result.createdEntity = entity;

        this.functionalLogService.logFunctionalAction(
            `CREATE_OBJECT_${this.zoneFeatureCode}`,
            CrudOperation.C
        );
        this.displayName = null;
        this.technicalName = null;
        this.isDisplayNameUpdated = false;
        this.isTechnicalNameUpdated = false;
        this.description = null;
        this.referenceDataValueCode = null;
        this.hasManuallySetReferenceDataValueCode = true;
        if (this.redirectAfterCreation && !this.isCheckedCreateAnother) {
            const dgModule = DataUtil.getModuleFromServerType(
                creationContext.serverType
            );
            const options: IGotoDetailsOptions = {
                modelId: HddUtil.getModelId(entity.HddData),
                entityObject: entity,
                spaceIdr: new SpaceIdentifier(
                    creationContext.spaceId,
                    creationContext.versionId
                ),
                tabName: 'details',
            };
            this.log('onCreate-goToEntityDetails');
            // not awaited to let the modal close
            this.navigationService.goToEntityDetails(dgModule, entity, options);
        }

        this.refreshPreCreateEntity();

        if (this.fiRefDataValueCode) {
            this.hasManuallySetReferenceDataValueCode = false;
            this.referenceDataValueCode = this.hasCodeAttribute
                ? this.fiRefDataValueCode.attributeMeta.DefaultValue
                : null;
        }
    }

    private getModuleName(moduleServerType: ServerType) {
        return this.translate.instant(
            `UI.EntityCreateModal.${ServerType[moduleServerType]}`
        );
    }

    private getCurrentModuleServerTypeFromNavigationState(): ServerType {
        let currentModule = this.navigationService.getCurrentModule();
        if (currentModule == DgModule.Diagram && this.data.modelHdd) {
            currentModule = DgModule.Catalog;
        }
        const moduleServerType =
            DataUtil.getDefaultServerTypeFromModule(currentModule);
        if (!moduleServerType && this.currentSpace) {
            const parentData = this.data?.parentData;
            if (this.isFromAddButton) {
                return ServerType[
                    this.fiModule.attributeMeta.ListValues[0].Key
                ];
            }
            if (
                parentData &&
                ModelerDataUtil.isModelerServerType(parentData.ServerType)
            ) {
                return ServerType.Model;
            }
            if (parentData) {
                return parentData.ServerType;
            }
        }
        return moduleServerType;
    }

    private canBeChildOf() {
        if (!this.localEntityType || !this.fiLogicalParent) {
            return false;
        }
        const entityTypeMapping = EntityTypeUtil.getMapping(
            this.localEntityType
        );
        return (
            entityTypeMapping.serverType != ServerType.Model &&
            !this.fiLogicalParent.attributeMeta.ExcludedEntityTypes.includes(
                entityTypeMapping.EntityType
            )
        );
    }

    /** sets all fields but module, space and version */
    private async setupAttributes() {
        this.isAttributesReady = false;
        this.attributes = await this.attributeDataService.loadAttributes([
            ServerType[this.serverType],
        ]);
        this.log('setupAttributes', this.attributes.size);

        this.entityFieldInfos ??= new Map<string, AttributeFieldInfo>();
        this.entityFieldInfos.clear();

        this.initTypeFieldInfo();

        this.fiOwners = this.getEntityFieldInfo(
            ServerConstants.PropertyName.DataOwners
        );
        this.fiStewards = this.getEntityFieldInfo(
            ServerConstants.PropertyName.DataStewards
        );
        this.fiDisplayName = this.getEntityFieldInfo(
            ServerConstants.PropertyName.DisplayName
        );
        this.fiTechnicalName = this.getEntityFieldInfo(
            ServerConstants.PropertyName.TechnicalName
        );
        this.fiDescription = this.getEntityFieldInfo(
            ServerConstants.PropertyName.Description
        );
        this.initTechno();

        // Reference Data Value has Code attribute
        if (this.serverType == ServerType.Property) {
            const codeAttributeSource = this.getEntityFieldInfo(
                ServerConstants.PropertyName.Code
            )?.attributeMeta;
            if (codeAttributeSource) {
                codeAttributeSource.DefaultValue = this.translate.instant(
                    'UI.EntityCreateModal.defaultReferenceDataValueCode'
                ); // Set Default Value
                this.fiRefDataValueCode =
                    this.makeFieldInfo(codeAttributeSource);
                this.referenceDataValueCode = codeAttributeSource.DefaultValue;
            } else {
                this.fiRefDataValueCode = null;
            }
        }

        const logicalParentFieldInfo = this.getEntityFieldInfo(
            ServerConstants.PropertyName.LogicalParentData
        );
        const logicalParentAttributeSource =
            logicalParentFieldInfo?.attributeMeta;
        if (logicalParentAttributeSource) {
            logicalParentAttributeSource.IsReadOnly = false;
            this.fiLogicalParent = this.makeFieldInfo(
                logicalParentAttributeSource
            );
        } else {
            this.fiLogicalParent = null;
        }

        if (this.isParentValueFixed) {
            this.fiLogicalParent.attributeMeta.IsReadOnly = true;
            this.parentHdd = this.data.parentData.HddData.Data;
        }
        this.initSubTypeValues();
        await this.doRefreshDefaultValues();
        this.isAttributesReady = true;
    }

    private initSubTypeValues() {
        this.selectedSubType = undefined;
        if (!this.fiType) {
            return;
        }

        const orderedSubTypes = AttributeDataService.getOrderedAdminSubTypes(
            this.serverType
        );

        let filteredSubTypes =
            (this.isParentValueFixed
                ? AttributeDataService.getChildAvailableSubTypes(
                      this.data.parentData.EntityType
                  )
                : orderedSubTypes) ?? [];

        const onlySubTypes = this.data.filterEntityTypes;
        if (onlySubTypes?.length) {
            filteredSubTypes = filteredSubTypes.filter((et) =>
                onlySubTypes.includes(et)
            );
        }

        const typeAMI = this.fiType.attributeMeta,
            stn = ServerType[this.serverType];
        const subTypeByValue = CollectionsHelper.objArrayToMap(
            typeAMI.ListValues,
            (v) => v,
            (v) => EntityTypeUtil.getEntityType(stn, v.Key)
        );
        const filteredValues = typeAMI.ListValues.filter((v) =>
            filteredSubTypes.includes(subTypeByValue.get(v))
        );
        typeAMI.ListValues = CollectionsHelper.orderBy(filteredValues, (v) =>
            orderedSubTypes.indexOf(subTypeByValue.get(v))
        );

        this.setTypeIfOnlyOneAvailable();
    }

    private setTypeIfOnlyOneAvailable() {
        const typeAMI = this.fiType.attributeMeta;
        if (typeAMI.ListValues?.length == 1) {
            const amv = typeAMI.ListValues[0];
            this.selectedSubType = amv;
            const selectedEntityType = EntityTypeUtil.getEntityType(
                ServerType[this.serverType],
                amv.Key
            );
            const etm = EntityTypeUtil.getMapping(selectedEntityType);
            this.setTechnoReadonly(!etm.HasWritableTechnologyCode);
            this.serverType = etm.serverType;
            typeAMI.IsReadOnly = true;
        } else {
            typeAMI.IsReadOnly = false;
        }
    }

    private buildEntityForm(): IEntityForm<TValue> {
        return {
            isEditEnabled: true,
            isLabelDisplayed: true,
            asterisk: true,
            getSpaceIdr: () => this.spaceIdr,
            getModule: () => {
                return DataUtil.getModuleFromServerType(this.serverType);
            },
            getAttributeValue: (attributeKey) =>
                this.getAttributeValue(attributeKey),
            setAttributeValue: async (attributeKey, newValue) =>
                await this.setAttributeValue(attributeKey, newValue),
            loadReferenceOptions: async (attributeKey: AttributeMetaInfo) =>
                await this.attributeDataService.loadReferenceOptionsSpace(
                    attributeKey,
                    this.spaceIdr
                ),
            getLocalEntityType: () => this.localEntityType,
            entityTypeChange$: this.entityTypeChange.asObservable(),
            attributeValueLocalChange$:
                this.attributeValueLocalChange.asObservable(),
            getExternalError: (attributeKey) => {
                switch (attributeKey) {
                    case ServerConstants.PropertyName.TechnicalName:
                        return this.existingNameError;
                    case ServerConstants.PropertyName.DisplayName:
                        return this.hasTechnicalName
                            ? null
                            : this.existingNameError;
                }
            },
        };
    }

    private getAttributeValue(attributeKey: string): TValue {
        switch (attributeKey) {
            case ServerConstants.PropertyName.DisplayName:
                return this.displayName;
            case ServerConstants.PropertyName.TechnicalName:
                return this.technicalName;
            case ServerConstants.PropertyName.DataOwners:
                return (
                    this.ownersAttributeValueInfo.Value as AttributeMetaValue[]
                ).map((attr) => attr.Key);
            case `${ServerConstants.PropertyName.DataOwners}ObjectValues`:
                return this.ownersAttributeValueInfo.ObjectValues;
            case ServerConstants.PropertyName.DataStewards:
                return (
                    this.stewardsAttributeValueInfo
                        .Value as AttributeMetaValue[]
                ).map((attr) => attr.Key);
            case `${ServerConstants.PropertyName.DataStewards}ObjectValues`:
                return this.stewardsAttributeValueInfo.ObjectValues;

            case ServerConstants.PropertyName.Technology:
                return this.selectedTechnology?.Value;

            case 'LongName':
            case 'Description':
                return this.description;

            case ServerConstants.PropertyName.LegacySubTypeAttributeKey:
                return this.isNewNavSpaceSelected || !this.selectedSubType
                    ? null
                    : this.selectedSubType.Key;

            case 'Module':
                return this.isNewNavSpaceSelected || !this.selectedModule
                    ? null
                    : this.selectedModule.Value;

            case 'Version':
                return this.selectedVersion ? this.selectedVersion.Value : null;

            case 'Workspace':
                return this.selectedWorkspace
                    ? this.selectedWorkspace.Value
                    : null;

            case 'CheckParent':
                return this.checkParent;

            case ServerConstants.PropertyName.LogicalParentData:
                return this.parentHdd;

            case 'Code':
                return this.referenceDataValueCode;
        }
    }

    private async setAttributeValue(attributeKey: string, newValue: TValue) {
        switch (attributeKey) {
            case 'Workspace': {
                const selectedSpaceId = (newValue as string) ?? null;
                await this.updateSpaceVersionAndModules(
                    selectedSpaceId,
                    undefined
                );
                this.refreshPreCreateEntity();
                this.refreshDefaultValues();
                break;
            }

            case 'Version': {
                const selectedVersionId = (newValue as string) ?? null;
                await this.updateSpaceVersionAndModules(
                    undefined,
                    selectedVersionId
                );
                this.refreshPreCreateEntity();
                this.refreshDefaultValues();
                break;
            }

            case 'Module': {
                const serverTypeString = (this.moduleName = newValue as string);
                this.serverType = ServerType[serverTypeString];
                const moduleAMI = this.fiModule.attributeMeta;
                this.selectedModule = moduleAMI.ListValues?.find(
                    (amv) => amv.Key === serverTypeString
                );
                this.isNewNavSpaceSelected = false;
                await this.setupAttributes();
                this.refreshPreCreateEntity();
                break;
            }

            case 'TechnologyCode':
                this.selectedTechnology.Value = newValue as string;
                break;

            case 'DisplayName':
                this.result.displayName = this.displayName = newValue as string;
                if (this.hasTechnicalName) {
                    this.namesSynchronisation(true, false);
                }
                this.refreshPreCreateEntity();
                break;

            case 'TechnicalName':
                this.technicalName = newValue as string;
                if (this.hasTechnicalName) {
                    this.namesSynchronisation(false, true);
                }
                this.refreshPreCreateEntity();
                break;

            case 'LongName':
            case 'Description':
                this.result.description = this.description = newValue as string;
                break;

            case ServerConstants.PropertyName.LegacySubTypeAttributeKey:
                this.onChangeSelectedEntityType(newValue as string);
                break;

            case 'CheckParent':
                this.checkParent = newValue as boolean;
                this.refreshPreCreateEntity();
                break;
            case ServerConstants.PropertyName.LogicalParentData:
                this.parentHdd = newValue as HierarchyDataDescriptor;
                this.refreshPreCreateEntity();
                this.refreshDefaultValues();
                break;

            case 'Code':
                this.referenceDataValueCode = newValue as string;
                this.hasManuallySetReferenceDataValueCode = true;
                this.refreshPreCreateEntity();
                break;

            case ServerConstants.PropertyName.DataStewards:
                this.stewardsAttributeValueInfo.Value = this.toMetaValues(
                    newValue,
                    this.fiStewards
                );
                this.stewardsEdited = true;
                break;
            case ServerConstants.PropertyName.DataOwners:
                this.ownersAttributeValueInfo.Value = this.toMetaValues(
                    newValue,
                    this.fiOwners
                );
                this.ownersEdited = true;
                break;
        }
        this.refreshUI();
    }

    private toMetaValues(newValue: TValue, fieldInfo: AttributeFieldInfo) {
        return (newValue as Array<string | AttributeMetaValue>).map((value) =>
            value instanceof AttributeMetaValue
                ? value
                : new AttributeMetaValue(fieldInfo.attributeMeta, value, value)
        );
    }

    /** updates spaceIdr, available modules, and either spaces or versions (available and selected) */
    private async updateSpaceVersionAndModules(
        selectedSpaceId: string,
        selectedVersionId: string
    ) {
        this.log(
            'updateSpaceVersionAndModules',
            selectedSpaceId,
            selectedVersionId
        );

        let spaceId: string, versionId: string;

        if (selectedSpaceId !== undefined) {
            spaceId = selectedSpaceId;

            const workspaceAMI = this.fiWorkspace.attributeMeta;
            this.selectedWorkspace = workspaceAMI.ListValues?.find(
                (amv) => amv.Value == spaceId
            );
            this.isNewNavSpaceSelected = true;
            this.selectedVersion = undefined;
            this.showVersionsField = false;
            this.serverType = null;
            this.selectedSubType = undefined;
            this.isAttributesReady = false;

            const navSpace = this.navSpaceById.get(spaceId);
            this.debug &&
                this.log('updateSpaceVersionAndModules-navSpace', {
                    spaceId,
                    navSpace,
                    isVersioningEnabled: (navSpace as unknown as NavProject)
                        .IsVersioningEnabled,
                });
            if (navSpace instanceof NavProject) {
                if (navSpace.IsVersioningEnabled) {
                    const projectVersions =
                        await this.versioningUiService.getProjectVersions(
                            spaceId
                        );
                    this.versionStore.init(projectVersions);
                    this.attributeDataService.setFieldInfoValues(
                        this.fiVersions,
                        this.versionStore.getActives(),
                        {
                            getKey: (a) => a.VersionName,
                            getValue: (a) => a.ProjectVersionId,
                            getTranslatedValue: (a) => a.VersionName,
                        }
                    );
                    const versionAMI = this.fiVersions.attributeMeta;
                    let initialAMV: AttributeMetaValue;
                    if (versionAMI.ListValues.length == 1) {
                        initialAMV = versionAMI.ListValues[0];
                    } else {
                        const initialVersionId =
                            navSpace.UserDefaultVersionId ??
                            navSpace.DefaultVersionId;
                        initialAMV = versionAMI.ListValues?.find(
                            (v) => v.Value == initialVersionId
                        );
                    }
                    this.showVersionsField = true;
                    this.selectedVersion = initialAMV;
                    versionId = initialAMV?.Value;
                } else {
                    versionId = navSpace.DefaultVersionId;
                }
            } else {
                versionId = null;
            }
        } else if (selectedVersionId !== undefined) {
            const versionAMI = this.fiVersions.attributeMeta;
            const versionAMV = versionAMI.ListValues?.find(
                (c) => c.Value == selectedVersionId
            );
            this.selectedVersion = versionAMV;
            versionId = versionAMV?.Value;
            spaceId = this.spaceIdr.spaceId;
        }

        this.spaceIdr = new SpaceIdentifier(spaceId, versionId);
        await this.updateModulesListFromSpaceIdr();
    }

    /** updates moduleFieldInfo.attributeMeta.Values */
    private async updateModulesListFromSpaceIdr() {
        const space = await this.spaceApiService.getSpace(this.spaceIdr);

        const baseModules: ServerType[] =
            space instanceof Project
                ? [
                      ServerType.Property,
                      ServerType.Model,
                      ServerType.DataProcessing,
                      ServerType.SoftwareElement,
                  ]
                : [ServerType.Property];

        const modules = space
            ? this.filterAvailableEntityTypesOnSecurity(space, baseModules)
            : baseModules;

        this.attributeDataService.setFieldInfoValues(this.fiModule, modules, {
            getKey: (st) => ServerType[st],
            getValue: (st) => ServerType[st],
            getTranslatedValue: (st) => this.getModuleName(st),
            getGlyphClass: (st) =>
                this.glyphService.getModuleColoredGlyphClass(
                    DataUtil.getModuleFromServerType(st)
                ),
        });
    }

    private onChangeSelectedEntityType(subTypeKey: string) {
        const typeAMI = this.fiType.attributeMeta;
        this.isNewNavSpaceSelected = false;
        this.selectedSubType = typeAMI.ListValues.find(
            (c) => c.Key === subTypeKey
        );
        this.localEntityType = EntityTypeUtil.getEntityType(
            this.moduleName,
            this.entitySubType
        );

        if (
            this.localEntityType &&
            EntityTypeUtil.isParentMandatory(this.localEntityType)
        ) {
            this.fiLogicalParent.attributeMeta.IsMandatory = true;
        }

        // If Parent is not fixed, reset parent on type update
        if (!this.isParentValueFixed) {
            this.entityTypeChange.next();
            this.parentHdd = null;
        }

        this.setTechnoReadonly(
            !EntityTypeUtil.getMapping(this.localEntityType)
                ?.HasWritableTechnologyCode
        );

        if (this.fiRefDataValueCode) {
            this.hasManuallySetReferenceDataValueCode = false;
            this.referenceDataValueCode = this.hasCodeAttribute
                ? this.fiRefDataValueCode.attributeMeta.DefaultValue
                : null;
        }

        this.refreshPreCreateEntity();
        this.refreshDefaultValues();
    }

    private async loadNavSpaces() {
        const result = await this.navigationApiService.getNavSpaces();
        this.navSpaceById.clear();
        result
            .allSpaces()
            .forEach((navSpace) =>
                this.navSpaceById.set(navSpace.ReferenceId, navSpace)
            );

        // Lost access to current space: Return to current home
        if (
            this.currentSpace &&
            !this.navSpaceById.has(this.currentSpace.ReferenceId)
        ) {
            this.toasterService.warningToast({
                titleKey: 'UI.Notification.CurrentSpaceAccessLost.title',
                messageKey: 'UI.Notification.CurrentSpaceAccessLost.msg',
            });
            await this.navigationService.goToClientSpacesList();
        } else {
            const availableNavSpaces = CollectionsHelper.filterMap(
                this.navSpaceById,
                (e) => e.CanCreateEntities
            );
            this.attributeDataService.setFieldInfoValues(
                this.fiWorkspace,
                availableNavSpaces,
                {
                    getKey: (ns) => ns.DisplayName,
                    getValue: (ns) => ns.ReferenceId,
                    getTranslatedValue: (ns) => ns.DisplayName,
                }
            );
        }
    }

    private getCreationContext() {
        const context = new CreationContext();
        context.spaceServerType = SpaceIdentifier.getServerType(this.spaceIdr);
        context.spaceId = this.spaceIdr.spaceId;
        context.versionId = this.spaceIdr.versionId;
        context.parentId = this.parentId;
        context.serverType = this.serverType;
        context.subTypeName = this.entitySubType;
        context.code = this.hasCodeAttribute
            ? this.referenceDataValueCode
            : undefined;
        return context;
    }

    /** debounced */
    private refreshPreCreateEntity() {
        this.log('refreshPreCreateEntity');
        clearTimeout(this.timerRefreshPreCreateEntity);
        this.timerRefreshPreCreateEntity = setTimeout(async () => {
            await this.doRefreshPreCreateEntity();
            this.refreshUI();
        }, 444);
    }

    private async doRefreshPreCreateEntity() {
        const ctx = this.getCreationContext();
        if (
            !ctx.parentId ||
            !ctx.serverType ||
            !ctx.subTypeName ||
            !this.displayName
        ) {
            this.canCreate = false;
            this.log('doRefreshPreCreateEntity-incomplete-ctx', ctx);
            return;
        }

        const aviList: AttributeValueInfo[] = [];
        this.addTechnoIfSelected(aviList);
        const entityType = EntityTypeUtil.getEntityType(
            ServerType[ctx.serverType],
            ctx.subTypeName
        );
        const result = await this.entityService.preCreateEntity(
            ctx.parentId,
            entityType,
            this.displayName,
            this.checkParent,
            ctx.versionId,
            ctx.code,
            this.technicalName,
            aviList
        );
        this.log(
            'doRefreshPreCreateEntity-status',
            PreCreateEntityStatus[result?.Status]
        );
        const existing = (this.existingEntity = result.ExistingItem);
        const msgKey = (this.existingEntityMsgKey = this.getMsgKey(
            result.Status,
            ctx
        ));
        const { operation, canCreate } = this.getOperation(result.Status);
        this.canCreate = canCreate;
        this.result.operation = operation;
        this.result.existingEntity = existing;
        this.result.displayName = this.displayName;
        this.result.description =
            existing && !this.description
                ? existing.Description
                : this.description;
        if (
            result.ReferenceDataValueCode &&
            !this.referenceDataValueCode &&
            !this.hasManuallySetReferenceDataValueCode
        ) {
            this.referenceDataValueCode = result.ReferenceDataValueCode;
        }
        this.existingNameError = existing
            ? `${
                  existing.DisplayName || existing.TechnicalName
              } ${this.translate.instant(msgKey)}`
            : undefined;
    }

    private getMsgKey(status: PreCreateEntityStatus, ctx: CreationContext) {
        let subKey = PreCreateEntityStatus[status];
        if (
            status == PreCreateEntityStatus.Exists &&
            this.hasCodeAttribute &&
            ctx.parentId != ctx.spaceId
        ) {
            subKey = `${subKey}Code`;
        }
        return `UI.EntityCreateModal.preCreateStatus.${subKey}`;
    }

    private getOperation(status: PreCreateEntityStatus) {
        let operation = CreateEntityOperation.Create;
        let canCreate = true;
        switch (status) {
            case PreCreateEntityStatus.Exists:
                canCreate = false;
                break;
            case PreCreateEntityStatus.ParentIsRequired:
                canCreate = false;
                break;
            case PreCreateEntityStatus.ExistsInChildSpace:
                break;
            case PreCreateEntityStatus.ExistsInParentSpace:
                operation = CreateEntityOperation.CopyFromExisting;
                break;
            case PreCreateEntityStatus.Ok:
                break;
        }
        return { operation, canCreate };
    }

    /** debounced */
    private refreshDefaultValues() {
        this.log('refreshDefaultValues');
        clearTimeout(this.timerRefreshDefaultValues);
        this.timerRefreshDefaultValues = setTimeout(
            async () => await this.doRefreshDefaultValues()
        );
    }

    private async doRefreshDefaultValues() {
        const stewardsKey = ServerConstants.PropertyName.DataStewards;
        const ownersKey = ServerConstants.PropertyName.DataOwners;
        const technologyKey = ServerConstants.PropertyName.Technology;

        const includedAttributes = [technologyKey];
        if (!this.stewardsEdited) {
            includedAttributes.push(stewardsKey);
        }
        if (!this.ownersEdited) {
            includedAttributes.push(ownersKey);
        }

        const results = await this.entityService.getDefaultValuesOnCreate(
            includedAttributes,
            this.parentId,
            this.spaceIdr.versionId
        );
        const valuesExtendedInfos = results.AttributeValues;
        if (!this.stewardsEdited) {
            this.stewardsAttributeValueInfo = valuesExtendedInfos.find(
                (v) => v.AttributePath == stewardsKey
            );
        }
        if (!this.ownersEdited) {
            this.ownersAttributeValueInfo = valuesExtendedInfos.find(
                (v) => v.AttributePath == ownersKey
            );
        }
        this.refreshTechno(valuesExtendedInfos);

        this.refreshUI();
    }

    private filterAvailableEntityTypesOnSecurity(
        space: Space,
        entityTypes: ServerType[]
    ) {
        const availableTypes = entityTypes.filter((et) => {
            switch (et) {
                case ServerType.Property:
                case ServerType.DataProcessing:
                case ServerType.SoftwareElement:
                    return this.entitySecurityService.getSecurityData(
                        space as Project,
                        et
                    )?.HasCreateAccess;
                case ServerType.Model: {
                    const securityData = this.data.parentData?.SecurityData;
                    if (securityData && this.isParentValueFixed) {
                        return securityData.HasWriteAccess;
                    } else {
                        return this.entitySecurityService.getSecurityData(
                            space as Project,
                            et
                        )?.HasCreateAccess;
                    }
                }
                default:
                    return false;
            }
        });
        return availableTypes;
    }

    private initTypeFieldInfo() {
        const typeFieldInfoSource = this.getEntityFieldInfo(
            ServerConstants.PropertyName.LegacySubTypeAttributeKey
        );
        const translatedDisplayName =
            this.fiType?.attributeMeta.translatedDisplayName;

        const typeAMI = typeFieldInfoSource?.attributeMeta;
        if (typeAMI) {
            typeAMI.IsMandatory = true;
            typeAMI.IsReadOnly = false;
            this.fiType = this.makeFieldInfo(typeAMI);
        } else {
            this.fiType = null;
        }

        if (
            translatedDisplayName !=
            this.fiType?.attributeMeta.translatedDisplayName
        ) {
            this.refreshUI();
        }
    }

    private namesSynchronisation(
        isDisplayNameUpdate: boolean,
        isTechnicalNameUpdate: boolean
    ) {
        if (isDisplayNameUpdate) {
            this.isDisplayNameUpdated = true;
            if (!this.isTechnicalNameUpdated && this.isDisplayNameUpdated) {
                this.technicalName = this.displayName;
            }
        } else if (isTechnicalNameUpdate) {
            this.isTechnicalNameUpdated = true;
            if (!this.isDisplayNameUpdated && this.isTechnicalNameUpdated) {
                this.displayName = this.technicalName;
            }
        }
    }

    //#region technology

    private isTechnoEditable(type: EntityType) {
        return [EntityType.Application, EntityType.DataFlow].includes(type);
    }

    private initTechno() {
        this.fiTechnology = this.getEntityFieldInfo(
            ServerConstants.PropertyName.Technology
        );
        if (this.fiTechnology) {
            this.updateteSelectedTechno('');
        }
    }

    private refreshTechno(valuesExtendedInfos: AttributeValueExtendedInfo[]) {
        if (!this.fiTechnology || !this.selectedTechnology) {
            return;
        }
        const technologyKey = ServerConstants.PropertyName.Technology;
        const defaultParentTechnology = valuesExtendedInfos.find(
            (v) => v.AttributePath == technologyKey
        )?.Value as unknown as string;
        this.log('refreshTechno', defaultParentTechnology, this.parentHdd);
        this.updateteSelectedTechno(defaultParentTechnology || '');
        this.setTechnoReadonly(!!(defaultParentTechnology || this.parentHdd));
        this.attributeValueLocalChange.next(technologyKey);
    }

    private updateteSelectedTechno(value: string) {
        this.selectedTechnology ??= new AttributeMetaValue(
            this.fiTechnology.attributeMeta,
            this.fiTechnology.attributeMeta.AttributeKey,
            ''
        );
        this.selectedTechnology.Value = value;
    }

    private setTechnoReadonly(isReadOnly: boolean) {
        if (!this.fiTechnology) {
            return;
        }
        this.fiTechnology.attributeMeta.IsReadOnly = isReadOnly;
    }

    private addTechnoIfSelected(aviList: AttributeValueInfo[]) {
        const value = this.selectedTechnology?.Value;
        if (!value) {
            return;
        }
        aviList.push(
            new AttributeValueInfo(
                this.fiTechnology.attributeMeta.AttributePath,
                value,
                UpdateAttributeAction[UpdateAttributeAction.SetValue]
            )
        );
    }

    //#endregion

    private getEntityFieldInfo(attributeKey: string) {
        if (this.entityFieldInfos.has(attributeKey)) {
            return this.entityFieldInfos.get(attributeKey);
        }
        // Create clones to make sure we do not modify the originals (IsReadOnly, for instance)
        const ami = CoreUtil.cloneDeep(this.attributes.get(attributeKey));
        const fi = this.makeFieldInfo(ami);
        this.entityFieldInfos.set(attributeKey, fi);
        return fi;
    }

    private makeGenericFieldInfo(isMandatory = false): AttributeFieldInfo {
        const ami = new AttributeMetaInfo();
        if (isMandatory) {
            ami.IsMandatory = true;
        }
        return new AttributeFieldInfo(ami, this.entityForm);
    }

    private makeFieldInfo(ami: AttributeMetaInfo): AttributeFieldInfo {
        return ami && new AttributeFieldInfo(ami, this.entityForm);
    }

    /** debounced unless rightAway is true*/
    private refreshUI(rightAway = false) {
        if (!rightAway) {
            this.log('refreshUI-debounced');
        }
        const action = () => {
            this.log('refreshUI');
            this.updateFieldsList();
            this.changeDetector.detectChanges();
        };

        clearTimeout(this.timerRefreshUI);
        if (rightAway) {
            action();
        } else {
            this.timerRefreshUI = setTimeout(action);
        }
    }

    /** recreates the whole fieldInfos array, so every field is redisplayed, and only if visible */
    private updateFieldsList() {
        this.fieldInfos = [
            // Note: Order is important
            this.fiWorkspace,
            this.fiVersions,
            this.fiModule,
            ...(this.fiType
                ? [
                      this.fiType,
                      this.fiTechnology,
                      this.fiDisplayName,
                      this.fiTechnicalName,
                      this.fiLogicalParent,
                      this.fiOwners,
                      this.fiStewards,
                      this.fiRefDataValueCode,
                      this.fiDescription,
                  ]
                : []),
            this.fiCheckParent,
        ].filter((fi) => this.isVisible(fi));

        this.debug &&
            this.log(
                'updateFieldsList',
                this.fieldInfos.length,
                this.fieldInfos.map((fi) => fi.attributeMeta.AttributeKey)
            );
    }

    private isVisible(fi: AttributeFieldInfo) {
        if (!fi) {
            return false;
        }
        switch (fi) {
            case this.fiWorkspace:
                return this.isFromAddButton && !this.currentSpace;
            case this.fiVersions:
                return this.showVersionsField;
            case this.fiModule:
                return this.isFromAddButton && this.isInitDone;
            case this.fiTechnicalName:
                return this.hasTechnicalName;
            case this.fiLogicalParent:
                return (
                    this.isAttributesReady &&
                    !!this.entitySubType &&
                    (this.isParentValueFixed || this.canBeChildOf())
                );
            case this.fiTechnology:
                return this.hasTechnologyAttribute;
            case this.fiOwners:
                return !!this.ownersAttributeValueInfo;
            case this.fiStewards:
                return !!this.stewardsAttributeValueInfo;
            case this.fiRefDataValueCode:
                return this.hasCodeAttribute;
            case this.fiCheckParent:
                // AS PER REQUEST, we disable this functionality for now (DG-431)
                return false; //return this.serverType == ServerType.Property
            default:
                return true;
        }
    }
}

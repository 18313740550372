<dxy-tabs-header
    class="stretch-tabs"
    [tabs]="headerTabs"
    [activeTabItem]="activeTab"
    (tabChange)="onTabChange($event)"
>
    <app-dks-asset-onstage
        *ngIf="isTabOnStage"
        [assets]="onStageAssets"
        [entityActions]="entityActions"
        [disabledSelection]="disabledSelection"
        (select)="select.emit($event)"
        (unselect)="unselect.emit($event)"
    ></app-dks-asset-onstage>
    <ng-container *ngIf="isTabCatalog">
        <ng-content select="[second-tab]"></ng-content>
    </ng-container>
</dxy-tabs-header>

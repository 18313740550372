import { NgModule } from '@angular/core';
import { DxyButtonsModule } from '@datagalaxy/ui/buttons';
import { InactivityLogoutModalComponent } from './inactivity-logout-modal/inactivity-logout-modal.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatDialogModule } from '@angular/material/dialog';
import { LogoComponent } from '@datagalaxy/ui/illustration';
import { SeeYouSoonComponent } from './see-you-soon/see-you-soon.component';

@NgModule({
    imports: [
        DxyButtonsModule,
        TranslateModule,
        MatDialogModule,
        LogoComponent,
    ],
    declarations: [InactivityLogoutModalComponent, SeeYouSoonComponent],
    exports: [InactivityLogoutModalComponent, SeeYouSoonComponent],
})
export class WebclientAuthUiModule {}

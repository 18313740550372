import { Injectable } from '@angular/core';
import { LineageGraphService } from '../lineage-graph/lineage-graph.service';
import { IEntityIdentifier } from '@datagalaxy/dg-object-model';
import { LineageDksApi } from '../lineage.types';
import {
    LineageTrackerColor,
    LineageTrackerState,
} from './lineage-path-tracker.types';
import { LineageTreeNode } from '../lineage-tree/lineage-tree-node';
import { LineageEdgeGraphBuilder } from '../lineage-graph-surface/lineage-graph-surface-edge.builder';
import { BaseStateService } from '@datagalaxy/utils';

@Injectable()
export class LineagePathTrackerService extends BaseStateService<LineageTrackerState> {
    private api: LineageDksApi;

    constructor(private lineageGraphService: LineageGraphService) {
        super({
            red: null,
            blue: null,
        });
    }

    public setApi(api: LineageDksApi) {
        this.api = api;
    }

    public togglePathTracker(
        entityIdr: IEntityIdentifier,
        color: LineageTrackerColor
    ) {
        if (!this.api) {
            return;
        }

        const colorEntityIdr = this.state?.[color]?.entityIdr;

        this.removePathTracker(color);

        if (colorEntityIdr?.ReferenceId === entityIdr.ReferenceId) {
            return;
        }

        this.addPathTracker(entityIdr, color);
    }

    public selectState() {
        return this.select((state) => state);
    }

    public selectEntityActivePathTracker(
        entityIdr: IEntityIdentifier,
        color: LineageTrackerColor
    ) {
        return this.select(
            (state) =>
                state[color]?.entityIdr?.ReferenceId === entityIdr.ReferenceId
        );
    }

    public removePathTracker(color: LineageTrackerColor) {
        const previousState = this.state?.[color];

        if (!previousState) {
            return;
        }
        const node = this.lineageGraphService.getNode(
            previousState.virtualEntityIdr.ReferenceId
        );

        this.removeClassToNodeOrChild(
            node,
            `path-tracker-root-${color} ${color} virtual`
        );

        previousState.nodes.forEach((node) => {
            this.removeClassToNodeOrChild(node, color);
        });

        previousState.edgesIds.forEach((edgeId) => {
            this.api.removeClassFromEdge(edgeId, `${color}`);
        });

        this.setState({ [color]: null });
    }

    private addPathTracker(
        entityIdr: IEntityIdentifier,
        color: LineageTrackerColor
    ) {
        const res = this.lineageGraphService.getConnectedNodesAndEdges(
            entityIdr.ReferenceId,
            true
        );

        const trackedNode = res.nodes.find(
            (node) => node.id === entityIdr.ReferenceId
        )?.data;

        const nodes = res.nodes
            .filter((node) => node.data.links.length > 0)
            .map((node) =>
                LineageEdgeGraphBuilder.getNodeEdgeTarget(node.data)
            );

        const edgeIds = res.edges.map((edge) => {
            const sourceId = LineageEdgeGraphBuilder.getNodeEdgeTargetId(
                edge.source
            );
            const targetId = LineageEdgeGraphBuilder.getNodeEdgeTargetId(
                edge.target
            );
            return `${sourceId}:${targetId}`;
        });

        const isRootVirtual = trackedNode.id !== entityIdr.ReferenceId;
        const rootClassNames = [
            `path-tracker-root-${color}`,
            'path-tracker',
            color,
            isRootVirtual ? 'virtual' : '',
        ]
            .filter((className) => !!className)
            .join(' ');

        this.addClassToNodeOrChild(trackedNode, rootClassNames);

        nodes.forEach((node) => {
            const id = LineageEdgeGraphBuilder.getNodeEdgeTargetId(node);

            if (id.includes('show-more')) {
                this.api.addClassToPort(id, `path-tracker ${color}`);
            } else {
                this.addClassToNodeOrChild(node, `path-tracker ${color}`);
            }
        });

        edgeIds.forEach((edgeId) => {
            this.api.addClassToEdge(edgeId, `path-tracker ${color}`);
        });

        this.setState({
            [color]: {
                entityIdr,
                virtualEntityIdr: trackedNode.entityIdentifier,
                nodes: nodes,
                edgesIds: edgeIds,
            },
        });
    }

    public refreshPathTrackers() {
        if (this.state.red) {
            const entity = this.state.red.entityIdr;

            this.removePathTracker(LineageTrackerColor.Red);
            this.addPathTracker(entity, LineageTrackerColor.Red);
        }

        if (this.state.blue) {
            const entity = this.state.blue.entityIdr;
            this.removePathTracker(LineageTrackerColor.Blue);
            this.addPathTracker(entity, LineageTrackerColor.Blue);
        }
    }

    private addClassToNodeOrChild(
        treeNode: LineageTreeNode,
        className: string
    ) {
        this.api.addClassToNodeChild(
            treeNode?.getVisibleRoot()?.id,
            treeNode.id,
            className
        );
    }

    private removeClassToNodeOrChild(
        treeNode: LineageTreeNode,
        className: string
    ) {
        this.api.removeClassToNodeChild(
            treeNode?.getVisibleRoot()?.id,
            treeNode.id,
            className
        );
    }
}

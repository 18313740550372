import { NgModule } from '@angular/core';
import { DxyCommentaryItemComponent } from './dxy-commentary-item/dxy-commentary-item.component';
import { DxyCommentaryContainerComponent } from './dxy-commentary-container/dxy-commentary-container.component';
import { DxyNewCommentaryModalComponent } from './dxy-new-commentary-modal/dxy-new-commentary-modal.component';
import { DxySharedDialogsModule } from '../shared/dialogs/DxySharedDialogsModule';
import { SharedModule } from '../shared/shared.module';
import { DxySharedUiModule } from '../shared/shared-ui/DxySharedUiModule';
import { FieldsModule } from '../shared/fields/fields.module';
import { COMMENTARY_MODAL_SERVICE_TOKEN } from '@datagalaxy/webclient/comment/feature';
import { CommentaryService } from './commentary.service';

@NgModule({
    imports: [
        SharedModule,
        DxySharedDialogsModule,
        FieldsModule,
        DxySharedUiModule, // for dxy-profile-avatar
    ],
    declarations: [
        DxyNewCommentaryModalComponent,
        DxyCommentaryItemComponent,
        DxyCommentaryContainerComponent,
    ],
    providers: [
        {
            provide: COMMENTARY_MODAL_SERVICE_TOKEN,
            useClass: CommentaryService,
        },
    ],
    exports: [DxyNewCommentaryModalComponent, DxyCommentaryContainerComponent],
})
export class DxyCommentaryModule {}

import { GRID_COLUMNS, GRID_ROWS } from '../../domain/dashboard-constants';
import { GridPosition } from '../../domain/grid-position';

export function getNextPosition(position: GridPosition): GridPosition {
    const newX = (position.x + 1) % GRID_COLUMNS;
    const newY = position.y + (position.x == GRID_COLUMNS - 1 ? 1 : 0);

    if (newY > GRID_ROWS - 1) {
        return undefined;
    }

    return {
        x: newX,
        y: newY,
    };
}

<div #gridContainer [id]="gridId" [class.editing]="enableEdition">
    <dxy-dashboard-card
        *ngFor="
            let widgetInstance of widgetInstances;
            trackBy: widgetInstanceTrackBy
        "
        [dxyCardFromGrid]="widgetInstance.position"
        [enableEdition]="enableEdition"
        [disableDuplication]="full"
        [widgetName]="widgetInstance.widgetName"
        [id]="widgetInstance.guid"
        [configuration]="widgetInstance.configuration"
        (remove)="removeWidgetInstance(widgetInstance)"
        (duplicate)="duplicateWidgetInstance(widgetInstance)"
        (configurationChange)="
            updateWidgetConfiguration(widgetInstance, $event)
        "
    ></dxy-dashboard-card>
</div>
<dxy-empty-grid-placeholder
    *ngIf="placeholderVisible"
    [class.dragging]="dragging"
    [class.widget-gallery-opened]="widgetGalleryOpened"
    (click)="openWidgetGallery.emit()"
></dxy-empty-grid-placeholder>
<div *ngIf="fullOverlayVisible$ | async" class="full-grid-message" translate>
    <dxy-alert-message
        [message]="
            'Dashboard.Section.fullOverlayMessage'
                | translate : { sectionTitle: section.title }
        "
    >
    </dxy-alert-message>
</div>

import { BaseWidgetComponent } from './widget-interfaces';
import { Widget } from './domain';
import { registerWidgetType } from './widgets-registry';

/**
 * decorator factory to register a widget type.
 * @param widget
 * @example
 * -@registerWidget({
 *  name: 'my-widget',
 *  previewImgUrl: 'img-url.svg'
 *  titleKey: 'PreviewTitleKey'
 * })
    class MyWidgetComponent extends BaseWidgetComponent implements OnInit {
        ...
    }
 */
export function registerWidget(widget: Partial<Widget>) {
    return function <TConstructor extends WidgetConstructor>(
        constructor: TConstructor
    ) {
        registerWidgetType(constructor, widget);
        return constructor;
    };
}
type WidgetConstructor = new (...args: any[]) => BaseWidgetComponent;

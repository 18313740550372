import { NgModule } from '@angular/core';
import { DxyFileOptionsModule } from '../shared/file-options/DxyFileOptionsModule';
import { DxySharedDialogsModule } from '../shared/dialogs/DxySharedDialogsModule';
import { SharedModule } from '../shared/shared.module';
import { DxySharedUiModule } from '../shared/shared-ui/DxySharedUiModule';
import { EntityExportModalComponent } from './entity-export-modal/entity-export-modal.component';
import { FieldsModule } from '../shared/fields/fields.module';

@NgModule({
    imports: [
        SharedModule,
        DxySharedDialogsModule,
        DxySharedUiModule,
        DxyFileOptionsModule,
        FieldsModule,
    ],
    declarations: [EntityExportModalComponent],
    exports: [EntityExportModalComponent],
})
export class ExportModule {}

import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { IListOption } from '@datagalaxy/core-ui';
import {
    IOmniGridRowHeightParams,
    OmniGridSortModel,
    OmniGridUtil,
} from '@datagalaxy/core-ui/omnigrid';
import { TranslateService } from '@ngx-translate/core';
import { CollectionsHelper, DomUtil } from '@datagalaxy/core-util';
import {
    EntityGridRowClickAction,
    IEntityGridOptions,
} from '../../shared/entity/entity-grid/EntityGridOptions';
import { FacetGroup } from '../facets/models/FacetGroup';
import { searchResultMaxCountOptions } from '../search.utils';
import { CurrentSearch } from '../models/CurrentSearch';
import { SearchResult, SearchService } from '../search.service';
import { EntityDockingPaneService } from '../../shared/entity/services/entity-docking-pane.service';
import { SearchFacetService } from '../facets/search-facet.service';
import { CurrentSpaceService } from '../../services/currentSpace.service';
import { AppEventsService } from '../../services/AppEvents.service';
import { FilteredViewService } from '../../shared/filter/services/filteredView.service';
import { FilterBarService } from '../../shared/filter/services/filterBar.service';
import { AttributeDataService } from '../../shared/attribute/attribute-data.service';
import { AppSpaceService } from '../../services/AppSpace.service';
import { EntityEventService } from '../../shared/entity/services/entity-event.service';
import { ViewTypeService } from '../../services/viewType.service';
import { FacetItem } from '../facets/models/FacetItem';
import { EntityGridDefaultRowHeight } from '../../shared/entity/entity-grid/EntityGridTypes';
import { getLocalId, getReferenceId } from '@datagalaxy/webclient/utils';
import { FilteredEntityItem } from '@datagalaxy/webclient/search/data-access';
import {
    CrudOperation,
    FunctionalLogService,
} from '@datagalaxy/webclient/monitoring/data-access';
import { FilteredEntityCardCellComponent } from '../../shared/entityCard/filtered-entity-card-cell/filtered-entity-card-cell.component';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { EntityServerTypeUtils } from '@datagalaxy/webclient/entity/utils';
import { SpaceIdentifier } from '@datagalaxy/webclient/workspace/utils';
import { ISpaceIdentifier } from '@datagalaxy/webclient/workspace/domain';
import { Filter, FilteredViewDto } from '@datagalaxy/webclient/filter/domain';
import { userSettingsValues } from '@datagalaxy/webclient/user/domain';
import { DgZone } from '@datagalaxy/webclient/domain';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';
import { ServerConstants } from '@datagalaxy/shared/server/domain';
import PropertyName = ServerConstants.PropertyName;

@Component({
    selector: 'dxy-main-search',
    templateUrl: './dxy-main-search.component.html',
    styleUrls: ['./dxy-main-search.component.scss'],
})
export class DxyMainSearchComponent extends DxyBaseComponent implements OnInit {
    @Input() currentFilteredView: FilteredViewDto;

    /** for now, till we fix entity-grid's grouping lines updating on view type change */
    private static readonly selfReloadOnViewTypeChange = true;

    public egOptions: IEntityGridOptions;
    public isLoading = false;
    public facetSections: FacetGroup[] = [];
    public isFacetsVisible = true;
    public resultMaxCountOptions: IListOption<number>[] =
        searchResultMaxCountOptions.map((maxCount) => ({
            labelText: maxCount.toString(),
            data: maxCount,
            callback: () => this.onMaxCountChange(maxCount),
        }));
    public objectCountMessage: string;
    public totalCount: number;
    public maxCount = this.resultMaxCountOptions[0];
    public showRefreshAction: boolean;

    public get currentSearch() {
        return this._currentSearch;
    }
    public set currentSearch(value: CurrentSearch) {
        this._currentSearch = value;
        this.searchService.setCurrentSearch(value);
    }
    public get spaceIdr() {
        return this.currentSearch.spaceIdr;
    }
    public set spaceIdr(spaceIdr: ISpaceIdentifier) {
        this.currentSearch.setSpaceAndVersion(spaceIdr);
    }
    public get showResultsGrid() {
        return !!this.egOptions;
    }
    public get showNoResultsMessage() {
        return this.isSearched && !this.isAnyResults;
    }
    public get isFiltersBarVisible() {
        return this.filterBarService.isFilterBarVisible(DgZone.Search);
    }
    public get isResultSpinnerVisible() {
        return (
            this.isLoading || (!this.isAnyResults && !this.showNoResultsMessage)
        );
    }
    public get showMaxCountDropdown() {
        return (
            !!this.totalCount ||
            this.maxCount.data !== searchResultMaxCountOptions[0]
        );
    }

    private _currentSearch: CurrentSearch;
    private readonly searchResultsHeaderActionButton: IListOption = {
        glyphClass: 'glyph-slider-2',
        tooltipTranslateKey: 'UI.Search.showFilters',
        callback: () => this.onToggleFacets(true),
    };
    private isSearched = false;
    private isAnyResults: boolean;
    private searchResults: SearchResult;

    constructor(
        private elementRef: ElementRef<HTMLElement>,
        private translate: TranslateService,
        private searchService: SearchService,
        private entityDockingPaneService: EntityDockingPaneService,
        private functionalLogService: FunctionalLogService,
        private searchFacetService: SearchFacetService,
        private appEventsService: AppEventsService,
        private currentSpaceService: CurrentSpaceService,
        private filteredViewService: FilteredViewService,
        private filterBarService: FilterBarService,
        private attributeDataService: AttributeDataService,
        private appSpaceService: AppSpaceService,
        private entityEventService: EntityEventService,
        private viewTypeService: ViewTypeService
    ) {
        super();
        this.logId = 'MainSearch';
    }

    ngOnInit() {
        this.log('ngOnInit', { currentFilteredView: this.currentFilteredView });
        this.appSpaceService.clearCurrentSpace();
        this.initAsync();
        this.subscribeEvents();
    }

    //#region event handlers
    public async onFiltersListChanged() {
        this.updateCurrentFilteredView();
    }
    public async onFacetSettingsChanged() {
        await this.search('onFacetSettingsChanged');
    }
    public async onFacetCheckChanged(item: FacetItem) {
        this.log('onFacetCheckChanged', item);
        this.searchService.updateFilterItemsFromChangedFacet(
            item,
            this.currentSearch
        );
        this.updateCurrentFilteredView();
    }
    public onToggleFacets(isFacetsVisible: boolean) {
        this.isFacetsVisible = isFacetsVisible;
        const featureCode = `MAIN_SEARCH_FACETS_${
            isFacetsVisible ? 'UNHIDE' : 'HIDE'
        }`;
        this.functionalLogService.logFunctionalAction(
            featureCode,
            CrudOperation.R
        );
    }
    public onCloseFiltersBarClick() {
        this.filterBarService.hideFilterBar(DgZone.Search);
    }
    public async onSpaceVersionSelected(spaceIdr: ISpaceIdentifier) {
        this.log('onSpaceVersionSelected', spaceIdr);
        const change = !SpaceIdentifier.areSame(this.spaceIdr, spaceIdr);
        if (!change) {
            return;
        }
        this.spaceIdr = spaceIdr;
        this.currentSpaceService.notifyMainSearchSpaceChange(spaceIdr);
        if (this.isSearched) {
            this.updateCurrentFilteredView();
        }
    }

    public async onMaxCountChange(maxCount: number) {
        this.log('onMaxCountChange', maxCount);
        this.maxCount = this.resultMaxCountOptions.find(
            (d) => d.data === maxCount
        );
        await this.search('onMaxCountChange');
    }

    public async onClickRefresh() {
        this.showRefreshAction = false;
        await this.search('refreshActionButton');
    }
    //#endregion event handlers

    private async initAsync() {
        this.clear();
        await this.initCurrentSearch();
        this.entityDockingPaneService.resetPanel();
        this.filterBarService.showFilterBar(DgZone.Search);
        await this.search('$onInit-isResultsView');
    }

    private subscribeEvents() {
        const serverTypes = EntityServerTypeUtils.firstClassEntityServerTypes;
        serverTypes.forEach((serverType) =>
            super.registerSubscription(
                this.entityEventService.subscribeEntityUpdate(
                    serverType,
                    (entity, isExternal) => {
                        this.onEntityUpdate(entity, isExternal);
                    }
                )
            )
        );
        super.subscribe(this.filteredViewService.currentViewChanged$, (e) =>
            this.onFilteredViewChanged(e.dgZone, e.filteredView)
        );

        if (DxyMainSearchComponent.selfReloadOnViewTypeChange) {
            super.subscribe(this.appEventsService.viewTypeChange$, () =>
                this.search('viewTypeChanged')
            );
        }
    }

    private onEntityUpdate(entity: EntityItem, isExternal: boolean) {
        if (isExternal) {
            return;
        }

        const gridContainsEntity = this.searchResults.allItems.some(
            (item) => item.ReferenceId === entity.ReferenceId
        );

        if (gridContainsEntity) {
            this.showRefreshAction = true;
        }
    }

    private async search(logIdFrom: string, fromFacetChanged?: FacetItem) {
        const currentSearch = this.currentSearch;
        this.log('search', logIdFrom, fromFacetChanged, currentSearch);

        this.isLoading = true;
        this.isSearched = false;
        this.showRefreshAction = false;
        const searchResult = (this.searchResults =
            await this.searchService.mainSearch(
                currentSearch,
                this.maxCount.data
            ));
        this.log('search-result', searchResult);

        const isAnyResults = searchResult.groups?.some((g) => g.count > 0);
        this.objectCountMessage = this.makeTotalCountMessage(searchResult);
        this.totalCount = searchResult.moreResultsTotalCount;
        const isAllSpaces = !currentSearch.spaceIdr?.spaceId;
        this.setupResultGrid(searchResult, isAllSpaces);

        const wasAnyResults = this.isAnyResults;
        this.isAnyResults = isAnyResults;
        this.isSearched = true;
        this.isLoading = false;

        const lastFacetSections = this.facetSections;
        this.facetSections = this.searchFacetService.buildRoots(
            searchResult,
            currentSearch
        );
        this.updateFacetsFromFilterItems(currentSearch, lastFacetSections);
        if (!isAnyResults) {
            this.focusSearchField();
        }
        if (wasAnyResults) {
            return this.egOptions.directData.update?.();
        }
    }

    private clear() {
        this.clearFacets();
        this.focusSearchField();
        this.isAnyResults = false;
        this.egOptions = undefined;
        this.isSearched = false;
    }

    private setupResultGrid(searchResult: SearchResult, isAllSpaces: boolean) {
        const go: IEntityGridOptions = this.egOptions ?? {
            primaryName: this.viewTypeService.isTechnicalView
                ? PropertyName.TechnicalName
                : PropertyName.DisplayName,
            primaryDisplayName: this.translate.instant(
                'UI.Search.primaryDisplayName'
            ),
            hasSecondaryName: false,
            headerActionButton: () =>
                this.isFacetsVisible
                    ? null
                    : this.searchResultsHeaderActionButton,
            showColumnsSelector: true,
            canSaveGridState: true,
            canMoveColumns: true,
            rowClickAction: EntityGridRowClickAction.openEntityPreviewPanel,
            isMultiSelect: false,
            burgerMenuConfiguration: { navigationButton: true },
            forceRefreshForUpdatedEntity: true,
            onShowEntityDetailsCallBack: () => this.funcLogShowEntityDetails(),
            entityDetailsNoFullPage: true,
            wantedColumns: SearchService.mainSearchDefaultDisplayedColumns,
            gridStatePersistenceId:
                userSettingsValues.omniGrid.routes.mainSearchResults,
            entityAttributeGroups:
                this.attributeDataService.getUiAttributesSections(
                    searchResult.entityAttributes,
                    {
                        useObjectValues: true,
                        getLogFunctional: () => 'FILTER,R',
                    }
                ),
            directData: {
                groupColumnMinWidth: 200,
                onColumnsChanged: () => this.search('columnsChanged'),
                onSortChanged: (sortModel) => this.onSortChanged(sortModel),
                updateDataOnColumnsChanged: true,
            },
            getRowHeight: (params: IOmniGridRowHeightParams) =>
                this.getRowHeight(params),
            firstCellRendererFramework: FilteredEntityCardCellComponent,
            entityAttributes: searchResult.entityAttributes,
            isServerSideSorting: true,
        };

        const dd = go.directData;

        if (searchResult.groups?.length > 1) {
            dd.groups = searchResult.groups;
            dd.items = null;
        } else {
            dd.items = searchResult.allItems;
            dd.groups = null;
        }
        dd.update?.();
        dd.isAllSpaces = isAllSpaces;

        this.egOptions = go;
    }

    private getRowHeight(params: IOmniGridRowHeightParams) {
        const entity = params.data as FilteredEntityItem;
        const defaultHeight = EntityGridDefaultRowHeight;
        if (!entity) {
            return defaultHeight;
        }
        const matchedAttributeLineHeight = 14;
        const attributesMatches =
            this.attributeDataService.extractExactMatchesAttributes(entity);
        const matchedAttribute =
            this.attributeDataService.getFirstOrNullExactMatch(
                attributesMatches,
                entity
            );
        return matchedAttribute
            ? defaultHeight + matchedAttributeLineHeight
            : defaultHeight;
    }

    private async onSortChanged(sortModel: OmniGridSortModel) {
        this.currentSearch.sortKey = OmniGridUtil.sortModelToString(sortModel);
        await this.search('onSortChanged');
    }

    private funcLogShowEntityDetails() {
        this.functionalLogService.logFunctionalAction(
            `OBJECT_DETAILS_MAIN_SEARCH`,
            CrudOperation.R
        );
    }

    private clearFacets() {
        this.log('clearFacets');
        this.facetSections.forEach((g) =>
            g.items.forEach((ff) => (ff.isChecked = false))
        );
    }

    private updateFacetsFromFilterItems(
        currentSearch: CurrentSearch,
        previousSections: FacetGroup[]
    ) {
        this.facetSections.forEach((fg) => {
            const prevGroup = previousSections.find(
                (pg) => pg.groupType === fg.groupType
            );
            if (prevGroup) {
                fg.isCollapsed = prevGroup.isCollapsed;
                fg.itemsCountLimit = prevGroup.itemsCountLimit;
            }
            fg.isHidden = false;
            fg.getAllItems().forEach((facet) => {
                const afm = SearchService.getFilterItemMatchingFacet(
                    currentSearch,
                    facet
                );
                facet.isChecked = !!afm;
                if (afm && !fg.isHidden) {
                    fg.isHidden = SearchFacetService.isSectionToBeHidden(
                        fg,
                        afm.operator
                    );
                }
            });
        });
    }

    private focusSearchField() {
        setTimeout(() => {
            const inputField = DomUtil.getElement(
                this.elementRef,
                '.search-filters-container input[type="text"]'
            ) as HTMLInputElement;
            if (!inputField) {
                return;
            }
            inputField.focus();
        });
    }

    private makeTotalCountMessage(searchResult: SearchResult) {
        return this.translate.instant('UI.Search.resultElements', {
            count: searchResult?.count || 0,
        });
    }

    private async initCurrentSearch() {
        const fv = this.currentFilteredView;
        this.log('initCurrentSearch', fv);
        this.currentSearch =
            await this.searchService.getCurrentSearchFromFilteredView(fv);
    }

    private async onFilteredViewChanged(
        dgZone: DgZone,
        filteredView: FilteredViewDto,
        forceReload?: boolean
    ) {
        if (dgZone != DgZone.Search && !forceReload) {
            return;
        }
        this.log('onFilteredViewChanged', filteredView);

        if (filteredView === this.currentFilteredView) {
            const areFiltersEquals = CollectionsHelper.contentEquals(
                filteredView.filters,
                this.currentSearch.computeFilters(),
                true,
                true,
                (a, b) => Filter.equals(a, b, true)
            );
            if (!areFiltersEquals) {
                this.updateCurrentSearchFromCurrentFilteredView();
            }
        } else {
            const previousSortKey = this.currentSearch.sortKey;
            this.currentSearch =
                await this.searchService.getCurrentSearchFromFilteredView(
                    filteredView
                );
            this.currentSearch.sortKey = previousSortKey;
            this.currentFilteredView = filteredView;
        }
        await this.search('filteredViewChange');
    }

    private updateCurrentFilteredView() {
        const fv = this.currentFilteredView;
        const currentSearch = this.currentSearch;
        const spaceIdr = currentSearch.spaceIdr;

        fv.SpaceUid = getLocalId(spaceIdr.spaceId);
        fv.DefaultVersionId = spaceIdr.versionId;
        fv.ListFilter = currentSearch.computeFilters();
        this.filteredViewService.notifyCurrentViewChange({
            dgZone: DgZone.Search,
            filteredView: fv,
        });
    }

    private updateCurrentSearchFromCurrentFilteredView() {
        this.log('updateCurrentSearchFromCurrentFilteredView');
        const fv = this.currentFilteredView;
        const currentSearch = this.currentSearch;
        const spaceId = fv.SpaceUid;
        const spaceReferenceId = spaceId
            ? getReferenceId(spaceId, spaceId)
            : null;
        const spaceIdr = new SpaceIdentifier(
            spaceReferenceId,
            fv.DefaultVersionId
        );

        currentSearch.setSpaceAndVersion(spaceIdr);
        currentSearch.setupFilterItems(fv.filters);
    }
}

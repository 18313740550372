import {
    ChangeDetectionStrategy,
    Component,
    HostBinding,
    Input,
} from '@angular/core';
import { DxyBaseComponent } from '@datagalaxy/ui/core';

/**
 * Togglable Show more/Show less button
 */
@Component({
    selector: 'dxy-show-more-button',
    templateUrl: './show-more-button.component.html',
    styleUrls: ['./show-more-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DxyShowMoreButtonComponent extends DxyBaseComponent {
    /** If true, button text changes to "Show less"*/
    @Input() isLess = false;
    /** If true, button style changes to mat-flat-button style*/
    @Input() @HostBinding('class.flat') isFlat = false;
    @Input() labelKey?: string;
    @Input() loading = false;
    @Input() disabled = false;

    protected get showMoreLabelKey() {
        return this.labelKey ?? 'CoreUI.Global.btnMore';
    }

    constructor() {
        super();
    }
}

import { ApiServiceError, ApiServiceErrorType } from './types';
import { BaseServiceParameter, BaseServiceResult } from './backend';

export function isUnsuccessfulApiError<
    TResult extends BaseServiceResult = BaseServiceResult,
    TParam extends BaseServiceParameter = BaseServiceParameter
>(error: unknown): error is ApiServiceError<TParam, TResult> {
    return isApiError(error, ApiServiceErrorType.UnsuccessfulRequest);
}

export function isUnmodifiedApiError<
    TResult extends BaseServiceResult = BaseServiceResult,
    TParam extends BaseServiceParameter = BaseServiceParameter
>(error: unknown): error is ApiServiceError<TParam, TResult> {
    return isApiError(error, ApiServiceErrorType.UnmodifiedContent);
}

export function isApiError<
    TResult extends BaseServiceResult = BaseServiceResult,
    TParam extends BaseServiceParameter = BaseServiceParameter
>(
    error: unknown,
    errorType?: ApiServiceErrorType
): error is ApiServiceError<TParam, TResult> {
    return (
        error instanceof ApiServiceError &&
        (error.type === errorType || errorType == undefined)
    );
}

export function catchUnsuccessfulApiErrorOrThrow<
    TResult extends BaseServiceResult = BaseServiceResult,
    TParam extends BaseServiceParameter = BaseServiceParameter
>(error: unknown, fn: (error: ApiServiceError<TParam, TResult>) => void) {
    return catchApiErrorOrThrow(
        error,
        ApiServiceErrorType.UnsuccessfulRequest,
        fn
    );
}

export function catchApiErrorOrThrow<
    TResult extends BaseServiceResult = BaseServiceResult,
    TParam extends BaseServiceParameter = BaseServiceParameter
>(
    error: unknown,
    errorType: ApiServiceErrorType,
    fn: (error: ApiServiceError<TParam, TResult>) => void
) {
    if (catchApiError(error, errorType, fn)) {
        return;
    }
    throw error;
}

export function catchApiError<
    TResult extends BaseServiceResult = BaseServiceResult,
    TParam extends BaseServiceParameter = BaseServiceParameter
>(
    error: unknown,
    errorType: ApiServiceErrorType,
    fn: (error: ApiServiceError<TParam, TResult>) => void
): boolean {
    if (error instanceof ApiServiceError && error.type === errorType) {
        fn(error);
        return true;
    }
    return false;
}

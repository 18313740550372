import { NgModule } from '@angular/core';
import { CoreUiModule } from '..';
import { DxyFieldsModule } from '../fields';
import { DxyDateFilterComponent } from './date-filter/date-filter.component';
import { DxyFilterButtonComponent } from './filter-button/filter-button.component';
import { TranslateModule } from '@ngx-translate/core';
import { DxyBooleanFilterComponent } from './boolean-filter/boolean-filter.component';
import { DxyTextFilterComponent } from './text-filter/text-filter.component';
import { DxyNumberFilterComponent } from './number-filter/number-filter.component';
import { DxyValueListFilterComponent } from './value-list-filter/value-list-filter.component';

@NgModule({
    imports: [CoreUiModule, DxyFieldsModule, TranslateModule],
    declarations: [
        DxyFilterButtonComponent,
        DxyDateFilterComponent,
        DxyBooleanFilterComponent,
        DxyTextFilterComponent,
        DxyNumberFilterComponent,
        DxyValueListFilterComponent,
    ],
    exports: [
        DxyFilterButtonComponent,
        DxyDateFilterComponent,
        DxyBooleanFilterComponent,
        DxyTextFilterComponent,
        DxyNumberFilterComponent,
        DxyValueListFilterComponent,
    ],
})
export class DxyFiltersModule {}

<!-- Sidebar for app site / Client Workspace zone -->
<div
    class="home-button-container sidebar-item"
    [matTooltip]="'UI.NavSideBar.spaceHome' | translate"
    matTooltipPosition="right"
>
    <a
        (click)="goToHomePage()"
        dxyDataTestId="navbar-home-button"
        dxyLogFunctional="MAIN_CLIENTSPACE_HOME,R"
        class="home-logo-container open-spaces-list-btn"
    >
        <span
            class="title-logo-glyph glyph-logo-dg open-spaces-list-btn"
        ></span>
        <img
            class="caption"
            src="/images/logo/logo-white-txt.svg"
            alt="Datagalaxy logo"
        />
    </a>
</div>

<!-- Dashboard item -->
<dxy-nav-sidebar-item
    glyphClass="glyph-dashboard"
    [showTooltip]="showItemsTooltip"
    dtName="Dashboard (Global)"
    [itemIdentifier]="ViewIdentifier.ClientDashboard"
    [stateId]="StateName.ClientDashboard"
    (onNavigation)="navigateTo($event)"
>
</dxy-nav-sidebar-item>
<!-- Tasks item -->
<dxy-nav-sidebar-item
    glyphClass="glyph-file-tasks-check"
    [showTooltip]="showItemsTooltip"
    dtName="Task Management"
    [itemIdentifier]="ViewIdentifier.ClientTasks"
    [stateId]="StateName.ClientTasks"
    (onNavigation)="navigateTo($event)"
>
</dxy-nav-sidebar-item>

<!-- Add item -->
<dxy-nav-sidebar-item
    *ngIf="showAddBurgerMenu || showAddEntityAction"
    [menuOptions]="showAddBurgerMenu ? addBurgerMenuOptions : null"
    menuClass="sidebar-item--mat-menu"
    glyphClass="glyph-add"
    dtName="Create Entity Button (Global)"
    [showTooltip]="showItemsTooltip"
    [itemIdentifier]="ViewIdentifier.Create"
    dxyDataTestId="navbar-add-button"
    (onMatMenuTrigger)="updateMatMenuPosition()"
></dxy-nav-sidebar-item>

<div
    class="dg_navSideBarSep workspace-list-section"
    [class.workspace-list-hidden]="shouldHideWorkspaceList"
    [class.workspace-list-populated]="!shouldHideWorkspaceList"
></div>
<dxy-navigation-space-list
    logId="navigation-breadcrumb"
    dxyDataTestId="workspace-selector-sidebar-button"
    menuClass="sidebar-item--mat-menu"
    [sidebarLayout]="true"
    [class.is-full-dropdown]="true"
    [class.hover-caret]="true"
    [isSmallCaretBtn]="true"
    [enableFavorite]="true"
    [showSelectedSpaceIcon]="true"
    [isSpaceSelectable]="true"
    [showAllSpaceRedirection]="true"
    [enableMenuTriggerTooltip]="true"
    (onNavSpaceSelected)="onNavSpaceSelected($event)"
    (openClose)="updateMatMenuPosition()"
></dxy-navigation-space-list>

<div class="dg_navSideBarSep"></div>

<!-- Glossary item -->
<app-nav-sidebar-module
    [showNonOrgaModule]="showNonOrgaModule"
    [showItemsTooltip]="showItemsTooltip"
    [spaceIdr]="currentSpaceOrLatest"
>
</app-nav-sidebar-module>

<ng-container *ngIf="showNonOrgaModule">
    <div class="dg_navSideBarSep"></div>

    <!-- Global diagrams list -->
    <dxy-nav-sidebar-item
        glyphClass="glyph-diagram1"
        [showTooltip]="showItemsTooltip"
        dtName="Global Diagrams List"
        [itemIdentifier]="ViewIdentifier.Diagrams"
        [stateId]="StateName.Diagrams"
        (onNavigation)="navigateTo($event)"
    >
    </dxy-nav-sidebar-item>
    <!-- Campaign module -->
    <dxy-nav-sidebar-item
        *ngIf="showCampaignModule"
        glyphClass="glyph-campaign dg5-beta-badge"
        [showTooltip]="showItemsTooltip"
        dtName="Campaign module"
        [itemIdentifier]="ViewIdentifier.Campaigns"
        [stateId]="StateName.Campaigns"
        (onNavigation)="navigateTo($event)"
    >
    </dxy-nav-sidebar-item>
</ng-container>

<!-- Impact analysis item -->
<!-- Archi-cleanup(revi) remove all code associated to this button  -->
<!-- Disabled for 3.3 version due to lineage new UX/UI | Need an entity selector to work  -->
<!-- <dxy-nav-sidebar-item *ngIf="showSpaceModules" glyphClass="glyph-analysis"
    dtName="Analytics Module" [itemIdentifier]="ViewIdentifier.ImpactAnalysis">
</dxy-nav-sidebar-item> -->

<ng-container *ngIf="hasManagementAccess">
    <dxy-nav-sidebar-item
        class="nav-item-offset"
        glyphClass="glyph-settings"
        [showTooltip]="showItemsTooltip"
        [itemIdentifier]="ViewIdentifier.SpaceSettings"
        dtName="Space Settings"
        [stateId]="StateName.SpaceSettings"
        (onNavigation)="navigateTo($event)"
    >
    </dxy-nav-sidebar-item>

    <div class="dg_navSideBarSep"></div>
</ng-container>
<!-- Expand/Collapse button -->
<dxy-nav-sidebar-item
    class="nav-item-offset"
    [glyphClass]="expandGlyphClass"
    [showTooltip]="showItemsTooltip"
    (click)="updateExpandState()"
    [itemIdentifier]="
        isCollapsed
            ? ViewIdentifier.GUIMenuExpand
            : ViewIdentifier.GUIMenuCollapse
    "
    dtName="{{ isCollapsed ? 'Expand Action' : 'Collapse Action' }}"
>
</dxy-nav-sidebar-item>

<app-entity-node-tree-item
    *ngFor="let tree of node.children"
    [nodeTree]="tree"
    [flat]="isFlat"
    [actions]="actions"
    (collapse)="collapse.emit($event)"
    (showMore)="showMore.emit($event)"
></app-entity-node-tree-item>
<div
    *ngIf="showMoreOption"
    class="show-more"
    [attr.gs-port-id]="'show-more-' + node.entityIdr.ReferenceId"
    (click)="onShowMoreClick()"
>
    <button mat-icon-button class="dg5-small-icon-button">
        <i class="glyph-burger"></i>
    </button>
    <span
        translate
        [translateParams]="{
            count: node.children.length,
            total: node.childrenCount
        }"
        >UI.DKS.Node.Children.ShowMore</span
    >
</div>

import { Component } from '@angular/core';
import { BaseCellComponent } from '@datagalaxy/core-ui/cell-components';
import { INavLinkCellParams } from '../nav-link.types';
import { INotificationButtonParams } from './INotificationButtonParams';

/**
 * ## Role
 * Button inside a notification to execute any action
 */
@Component({
    selector: 'app-notification-button',
    templateUrl: './notification-button.component.html',
    styleUrls: ['./notification-button.component.scss', '../nav-link.scss'],
})
export class NotificationButtonComponent extends BaseCellComponent<
    unknown,
    INotificationButtonParams,
    INavLinkCellParams<INotificationButtonParams>
> {
    constructor() {
        super();
    }

    protected iconGlyphClass?: string;
    protected buttonTextKey?: string;
    protected flat?: boolean;
    protected flatButtonColor?: string;

    protected get hasIcon() {
        return !!this.iconGlyphClass;
    }

    protected override updateBindings() {
        this.iconGlyphClass = this.value.iconGlyphClass;
        this.buttonTextKey = this.value.buttonTextKey;
        this.flat = this.value.flat;
        this.flatButtonColor = this.value.flatButtonColor;
    }

    public onClick(event: MouseEvent) {
        event.stopPropagation();
        this.value?.callback?.();
    }
}

import { NgModule } from '@angular/core';
import { DxyNavDataLinkDirective } from './directives/DxyNavDataLinkDirective';
import { DxyNavBreadcrumbComponent } from './dxy-nav-breadcrumb/dxy-nav-breadcrumb.component';
import { DxySharedUiModule } from '../shared/shared-ui/DxySharedUiModule';
import { SharedModule } from '../shared/shared.module';
import { DxyNavBackToSpaceLinkComponent } from './dxy-nav-back-to-space-link/dxy-nav-back-to-space-link.component';

@NgModule({
    imports: [SharedModule, DxySharedUiModule],
    declarations: [
        DxyNavDataLinkDirective,
        DxyNavBreadcrumbComponent,
        DxyNavBackToSpaceLinkComponent,
    ],
    exports: [
        DxyNavDataLinkDirective,
        DxyNavBreadcrumbComponent,
        DxyNavBackToSpaceLinkComponent,
    ],
})
export class DxyNavigationModule {}

import { DashboardSection } from '../../../domain';
import { DashboardState } from '../dashboard-state';

export function sectionReducer(
    state: DashboardState,
    section: DashboardSection,
    sectionUpdate: Partial<DashboardSection>
) {
    return {
        dashboard: {
            ...state.dashboard,
            sections: [
                ...state.dashboard.sections.map((s) => {
                    if (s.id != section.id) {
                        return s;
                    }
                    return {
                        ...s,
                        ...sectionUpdate,
                    };
                }),
            ],
        },
    };
}

import { DeserializeInto } from 'cerialize';
import { BaseService } from '@datagalaxy/core-ui';
import { Injectable } from '@angular/core';
import { AppDataService } from '../../services/app-data.service';
import { AccountApiService } from './account-api.service';
import { AccountSecurityTokenModel } from '../models/account-security-token.model';
import { JwtUtil } from '../../shared/util/Jwt.util';
import {
    AccountSetPasswordMode,
    AccountSetPasswordModel,
} from '../models/account-set-password.model';
import { catchUnsuccessfulApiErrorOrThrow } from '@datagalaxy/data-access';
import { CheckSecurityTokenResult } from '@datagalaxy/webclient/auth/data-access';

@Injectable({ providedIn: 'root' })
export class AccountModelService extends BaseService {
    constructor(
        private appDataService: AppDataService,
        private accountApiService: AccountApiService
    ) {
        super();
    }

    public createSecurityTokenModel(securityToken: string) {
        const securityTokenModel = new AccountSecurityTokenModel();
        const parsedToken = securityToken.split('.');
        if (parsedToken.length === 3) {
            const jsonToken = JwtUtil.parseJwt(securityToken);
            DeserializeInto(
                jsonToken,
                AccountSecurityTokenModel,
                securityTokenModel
            );
        }
        return securityTokenModel;
    }

    public async createAccountSetPasswordModel(
        securityToken: string,
        mode: AccountSetPasswordMode
    ): Promise<AccountSetPasswordModel> {
        const accountSetPasswordModel = new AccountSetPasswordModel(mode);
        if (securityToken) {
            accountSetPasswordModel.token = securityToken;
            let securityTokenModel =
                this.createSecurityTokenModel(securityToken);
            accountSetPasswordModel.setInformationsWithAccountSecurityToken(
                securityTokenModel
            );
            try {
                await this.accountApiService.checkSecurityToken(
                    accountSetPasswordModel.token,
                    accountSetPasswordModel.mail
                );
                accountSetPasswordModel.isValidToken = true;
            } catch (e) {
                catchUnsuccessfulApiErrorOrThrow<CheckSecurityTokenResult>(
                    e,
                    (error) => {
                        accountSetPasswordModel.isExpiredToken =
                            error.result.IsExpired;
                        accountSetPasswordModel.isValidToken = false;
                    }
                );
            }
            return accountSetPasswordModel;
        }
        accountSetPasswordModel.mail = this.appDataService.currentUserLogin;
        return Promise.resolve(accountSetPasswordModel);
    }
}

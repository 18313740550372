export const entityNodeConstants = {
    entityColorBorderWidth: 6,
    extraDataHeight: 38,
    sizeMode: {
        nano: { width: 10, height: 10 },
        mini: { width: 60, height: 60 },
        medium: {
            min: { width: 150, height: 60 },
            max: { width: null, height: 60 },
        },
        maxi: { width: 340, height: 300 },
    },
};

import {
    Component,
    EventEmitter,
    Input,
    Output,
    ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IFunctionalEvent } from '../../IFunctionalEvent';
import { IListOption, ListOptionUtil } from '../../IListOption';
import {
    IDropdownSection,
    ISectionOption,
} from '../../ui-dropdown-section.types';
import { DxySearchInputComponent } from '../search-input/search-input.component';
import { DxyBaseComponent } from '@datagalaxy/ui/core';

@Component({
    selector: 'dxy-dropdown-section',
    templateUrl: 'dropdown-section.component.html',
    styleUrls: ['dropdown-section.component.scss'],
})
export class DxyDropdownSectionComponent extends DxyBaseComponent {
    @Input() sections: IDropdownSection[];
    @Input() enableSearch: boolean;
    @Input() btnTitle: string;
    @Input() btnGlyphClass: string;
    @Input() btnClass = 'mat-button-base mat-button';
    @Input() showDropdownCaret: boolean;
    @Input() flattenSections = false;
    @Output() onLogFunctional = new EventEmitter<IFunctionalEvent>();
    /** Emitted when a menu is opened or closed. The argument is true on open. */
    @Output() readonly openClose = new EventEmitter<boolean>();

    /** Reserved for internal usage */
    @Input() isInnerSection?: boolean;

    public searchString = '';

    public get sectionTitleVisible() {
        return !this.flattenSections;
    }

    @ViewChild('searchInputRef') searchInputRef: DxySearchInputComponent;

    constructor(private translate: TranslateService) {
        super();
    }

    public onLogFunctionalInternal(event: IFunctionalEvent) {
        this.onLogFunctional.emit(event);
    }
    public onOpenClose(isOpen: boolean) {
        this.openClose.emit(isOpen);
    }

    public onClickSection($event: Event, section: IDropdownSection) {
        $event.stopPropagation();
        return (section.isExpanded = !section.isExpanded);
    }

    public onClickOption(option: ISectionOption, event: Event) {
        ListOptionUtil.onClick(option, this.onLogFunctional, event);
    }

    public getLabel(option: IListOption) {
        return ListOptionUtil.getText(option, this.translate);
    }

    public onSearchString(onSearchParam: { searchString: string }) {
        this.onSearchInSections(onSearchParam.searchString, this.sections);
    }

    public onToggle() {
        if (this.enableSearch) {
            this.focusSearchInput();
        }
    }

    public areOptionsVisible(section: IDropdownSection) {
        return (
            this.flattenSections ||
            (section.isExpanded && section.filteredOptions?.length > 0)
        );
    }

    public areSubSectionsVisible(section: IDropdownSection) {
        return section.isExpanded && section.subSections?.length > 0;
    }

    public isSectionVisible(section: IDropdownSection) {
        return (
            section.filteredOptions?.length > 0 ||
            !!section.subSections?.some(
                (subsec) => subsec.filteredOptions?.length
            )
        );
    }

    public getOptionText(textTranslateKey: string) {
        return this.translate.instant(textTranslateKey);
    }
    public getGlyphClass(option: IListOption) {
        return ListOptionUtil.getGlyphClass(option);
    }

    private focusSearchInput() {
        this.searchInputRef.focusInput();
    }

    private onSearchInSections(
        searchedString: string,
        sections: IDropdownSection[]
    ) {
        sections?.forEach((section) => {
            if (section.subSections?.length) {
                this.onSearchInSections(searchedString, section.subSections);
            } else if (section.onSearchString) {
                section.onSearchString(section, searchedString);
            }
        });
    }
}

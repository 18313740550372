import { Inject, Injectable } from '@angular/core';
import {
    HubConnection,
    HubConnectionBuilder,
    HubConnectionState,
    LogLevel,
} from '@microsoft/signalr';
import { fromEventPattern, Observable, tap } from 'rxjs';
import { GenericDeserialize, INewable } from 'cerialize';
import {
    ILoginDataForRealTime,
    RealTimeUserDto,
    SignalData,
    SignalrConnectionId,
    SignalrUserEvent,
} from './signalr.types';
import { BACKEND_API_CONFIG, IApiConfig } from '../types';
import { Location } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class SignalRService {
    private readonly _hubConnection: HubConnection;
    private _realTimeConnectionId: string | null = null;

    public readonly logged$: Observable<SignalrConnectionId>;

    public get hubConnection() {
        return this._hubConnection;
    }
    public get connectionId() {
        return this._realTimeConnectionId;
    }

    constructor(@Inject(BACKEND_API_CONFIG) config: IApiConfig) {
        const url = Location.joinWithSlash(config.baseUrl, 'signalr');
        const connection = (this._hubConnection = new HubConnectionBuilder()
            .withUrl(url)
            .configureLogging(LogLevel.Error)
            .withAutomaticReconnect()
            .build());

        connection.serverTimeoutInMilliseconds = 60 * 1000;

        this.logged$ = this.fromEvent(
            'welcome',
            (connectionId: string) => connectionId
        ).pipe(
            tap((connectionId) => (this._realTimeConnectionId = connectionId))
        );
    }

    public async login(data: ILoginDataForRealTime) {
        const connection = this._hubConnection;

        if (connection.state !== HubConnectionState.Connected) {
            await connection.start();
        }

        if (connection.state === HubConnectionState.Connected) {
            await connection.invoke(
                'connectUser',
                data.userFullName,
                data.userId,
                data.currentClientName,
                data.currentClientId,
                data.userSessionId
            );
        }
    }

    public async logout() {
        await this.hubConnection.stop();
    }

    /**
     * Create an observable from a SignalR event
     *
     * Note: This is mostly used by legacy events, new events should use
     * `fromUserEvent` instead, if they have the appropriate format.
     *
     * @param {string} eventName - The name of the event.
     * @param {Function} transformer - A function to transform the event data
     * @returns {Observable<T>} An Observable that emits when the event is
     * received. If `transformer` is provided, the emitted value will be the
     * result of the transformation. Otherwise, the emitted value will be the
     * raw event data.
     */
    public fromEvent<T>(
        eventName: string,
        transformer: (...args: any[]) => T
    ): Observable<T> {
        return fromEventPattern(
            (handler) => this.hubConnection.on(eventName, handler),
            (handler) => this.hubConnection.off(eventName, handler),
            (...args: string[]) => {
                const parsedArgs = args?.map((arg) =>
                    this.isJSONString(arg) ? JSON.parse(arg) : arg
                );
                return transformer(...parsedArgs);
            }
        );
    }

    public fromUserEvent<T>(
        eventName: string,
        type?: INewable<SignalData<T>>
    ): Observable<SignalrUserEvent<T>> {
        return this.fromEvent(eventName, (user: RealTimeUserDto, data: T) => ({
            eventName: eventName,
            user,
            data: type ? (GenericDeserialize(data, type) as T) : data,
        }));
    }

    public emit(methodName: string, ...args: any[]) {
        return this.hubConnection.invoke(methodName, ...args);
    }

    private isJSONString(str: string) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }
}

import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { FacetGroup } from '../models/FacetGroup';
import { FacetItem } from '../models/FacetItem';
import {
    CrudOperation,
    FunctionalLogService,
} from '@datagalaxy/webclient/monitoring/data-access';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { ServerConstants } from '@datagalaxy/shared/server/domain';
import PropertyName = ServerConstants.PropertyName;

@Component({
    selector: 'dxy-search-facet-filters',
    templateUrl: './dxy-search-facet-filters.component.html',
    styleUrls: ['./dxy-search-facet-filters.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DxySearchFacetFiltersComponent
    extends DxyBaseComponent
    implements OnInit, OnChanges
{
    private static featureCodes = {
        [ServerConstants.Search.EntityTypeFilterKey]: 'TYPE',
        [ServerConstants.AttributeConstants.SystemTagsAttributeKey]: 'TAG',
        [PropertyName.Technology]: 'TECHNOLOGY',
        [PropertyName.EntityStatus]: 'STATUS',
        [PropertyName.DataOwners]: 'DATA_OWNER',
        [PropertyName.DataStewards]: 'DATA_STEWARD',
        [PropertyName.CdoUsers]: 'CDO',
        [PropertyName.CisoUsers]: 'CISO',
        [PropertyName.DpoUsers]: 'DPO',
        [PropertyName.ExpertUsers]: 'EXPERT',
        [PropertyName.CreationTime]: 'CREATION_DATE',
        [PropertyName.LastModificationTime]: 'UPDATE_DATE',
        [PropertyName.GdprRiskLevel]: 'RISK_LEVEL',
        [PropertyName.GdprPersonalDataType]: 'PERSONAL_DATA_TYPE',
        [PropertyName.GdprPhysicalPersonType]: 'PHYSICAL_PERSON_TYPE',
        [PropertyName.GdprSensitiveDataType]: 'SENSITIVE_DATA',
        [PropertyName.GdprContainsPersonalData]: 'CONTAINS_PERSONAL_DATA',
        [PropertyName.GdprContainsSensitiveData]: 'CONTAINS_SENSITIVE_DATA',
        [PropertyName.GdprIsPersonalData]: 'IS_PERSONAL_DATA',
        [PropertyName.GdprIsSensitiveData]: 'IS_SENSITIVE_DATA',
    };

    @Input() groups: FacetGroup[];
    @Output() onFilterChange = new EventEmitter<FacetItem>();
    @Output() onSettingsChange = new EventEmitter();
    @Output() onClose = new EventEmitter();

    public searchTerm = '';
    public displayedGroups: FacetGroup[];

    constructor(private functionalLogService: FunctionalLogService) {
        super();
    }

    ngOnInit() {
        this.log('ngOnInit', this.groups);
        this.displayedGroups = this.groups;
    }

    ngOnChanges(changes: SimpleChanges) {
        super.onChanges(changes, ['groups'], () => this.onSearch(''));
    }

    public itemCheckChange(item: FacetItem) {
        const featureCode =
            DxySearchFacetFiltersComponent.featureCodes[item.attributeKey] ??
            item.attributeKey.toUpperCase();
        this.functionalLogService.logFunctionalAction(
            `MAIN_RESULTS_FACETS_${featureCode}`,
            CrudOperation.R
        );
        this.onFilterChange.emit(item);
    }

    public onOpenSettings() {
        this.functionalLogService.logFunctionalAction(
            'MAIN_RESULTS_FACETS_CONFIG',
            CrudOperation.R
        );
    }

    public onSearch(searchTerm: string) {
        this.searchTerm = searchTerm;
        const allGroups = this.groups.slice();
        allGroups.forEach((group) => group.onSearchTermChange(searchTerm));
        this.displayedGroups = allGroups.filter((group) => group.isVisible());
    }

    public onSearchFacetSettingsClick(event: MouseEvent) {
        event.stopPropagation();
    }
}

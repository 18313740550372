import {
    AbstractControl,
    AsyncValidatorFn,
    FormGroup,
    ValidationErrors,
} from '@angular/forms';
import { SpaceApiService } from './services/space-api.service';
import {
    debounceTime,
    distinctUntilChanged,
    first,
    startWith,
    switchMap,
} from 'rxjs/operators';
import { from, map, Observable, of } from 'rxjs';
import { PreSetupSpaceTrigramStatus } from '@datagalaxy/webclient/workspace/data-access';

export class SpaceTrigramValidationUtils {
    public static trigram(
        spaceApiService: SpaceApiService,
        spaceId: string
    ): AsyncValidatorFn {
        return (
            control: AbstractControl
        ): Observable<ValidationErrors | null> => {
            const trigram = control.value;

            if (trigram?.length > 3) {
                return of({ tooLong: { value: trigram } });
            }

            return control.valueChanges.pipe(
                startWith(control.value),
                debounceTime(500),
                distinctUntilChanged(),
                switchMap(() =>
                    from(
                        spaceApiService.checkTrigramValidity(
                            spaceId,
                            control.value
                        )
                    )
                ),
                map((res) =>
                    res &&
                    res.PreSetupSpaceTrigramStatus !==
                        PreSetupSpaceTrigramStatus.Ok
                        ? { status: res.PreSetupSpaceTrigramStatus }
                        : null
                ),
                first()
            );
        };
    }

    public static trigramErrorMessageKey(formGroup: FormGroup) {
        let key: string;
        if (formGroup.get('trigram').hasError('tooLong')) {
            key = 'UI.Dialog.NewItem.Project.lblTrigramTooLongError';
        } else if (formGroup.get('trigram').hasError('status')) {
            const status = formGroup.get('trigram').getError('status');
            key =
                status === PreSetupSpaceTrigramStatus.Exists
                    ? 'UI.Dialog.NewItem.Project.lblTrigramAlreadyExisting'
                    : '(invalid)';
        }
        return key;
    }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ITranslatedNotification } from '../notification.types';
import { DxyBaseComponent } from '@datagalaxy/ui/core';

/**
 * ## Role
 * Toaster to inform user of a new notification
 */
@Component({
    selector: 'app-notification-toaster',
    templateUrl: './notification-toaster.component.html',
    styleUrls: ['./notification-toaster.component.scss'],
})
export class NotificationToasterComponent
    extends DxyBaseComponent
    implements OnInit
{
    @Input() public readonly notification: ITranslatedNotification;
    @Input() public readonly toasterDelay = 5000;

    @Output() public readonly close = new EventEmitter();
    @Output() public readonly isAcknowledgedChanged = new EventEmitter();
    @Output() public readonly timeout = new EventEmitter();

    constructor() {
        super();
    }

    ngOnInit() {
        setTimeout(() => this.timeout.emit(), this.toasterDelay);
    }

    public onIsAcknowledgedChanged() {
        this.isAcknowledgedChanged.emit();
    }

    public onClose() {
        this.close.emit();
    }
}

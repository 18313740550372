import { Component } from '@angular/core';
import { ICellParams, BaseCellComponent } from '../../../cell-components';

/**
 * ## Role
 * Cell component for display spinner at omnigrid loading
 */
@Component({
    selector: 'dxy-loading-cell',
    templateUrl: 'loading-cell.component.html',
})
export class DxyLoadingCellComponent extends BaseCellComponent<
    any,
    any,
    ILoadingCellParams
> {
    public get loadingMessage() {
        return this.params.loadingMessage;
    }
    constructor() {
        super();
    }
}

export interface ILoadingCellParams extends ICellParams {
    loadingMessage: string;
}

import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    NgZone,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
    IFunctionalEvent,
    IListOption,
    ListOptionUtil,
} from '@datagalaxy/core-ui';
import { CoreUtil } from '@datagalaxy/core-util';
import {
    EntityGridColumnInfo,
    EntityItemOmniGridApi,
} from '../EntityGridTypes';
import { EntityGridCore } from '../EntityGridCore';
import { IEntityGridOptions } from '../EntityGridOptions';
import {
    OmniGridColumnInfo,
    OmniGridSortModel,
} from '@datagalaxy/core-ui/omnigrid';
import { EntityPreviewPanelService } from '../../services/entity-preview-panel.service';
import { EntityGridBurgerMenuService } from '../entity-grid-burger-menu.service';
import { ServerType } from '@datagalaxy/dg-object-model';
import { ViewTypeService } from '../../../../services/viewType.service';
import { EntityService } from '../../services/entity.service';
import { BreadcrumbService } from '../../../../navigation/services/breadcrumb.service';
import { UserService } from '../../../../services/user.service';
import { EntityUiService } from '../../services/entity-ui.service';
import { AppEventsService } from '../../../../services/AppEvents.service';
import { FilteredViewService } from '../../../filter/services/filteredView.service';
import { EntitySecurityService } from '../../services/entity-security.service';
import { NavigationService } from '../../../../services/navigation.service';
import { EntityCacheService } from '../../services/entity-cache.service';
import { ServerTimeService } from '../../../../services/serverTime.service';
import { AttributeDataService } from '../../../attribute/attribute-data.service';
import { EntityEventService } from '../../services/entity-event.service';
import { ExportService } from '../../../../services/export.service';
import { CampaignSecurityService } from '../../../../campaign/campaign-security.service';
import { CampaignUiService } from '../../../../campaign/campaign-ui.service';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { FunctionalLogService } from '@datagalaxy/webclient/monitoring/data-access';
import { ISpaceIdentifier } from '@datagalaxy/webclient/workspace/domain';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';
import { AttributeMetaInfo } from '@datagalaxy/webclient/attribute/domain';
import { EntitySecurityData } from '@datagalaxy/webclient/security/domain';

@Component({
    selector: 'dxy-entity-grid',
    templateUrl: './dxy-entity-grid.component.html',
    styleUrls: ['./dxy-entity-grid.component.scss'],
})
export class DxyEntityGridComponent
    extends DxyBaseComponent
    implements OnInit, OnChanges, OnDestroy
{
    //#region component bindings
    // Note: none is used in html

    // resolved from state
    @Input() securityData: EntitySecurityData;

    // used by: dpimplem, main-search, entity-tree, CSD.ModuleEntityList, CSD.ModuleEntityGrid, CSD.ModuleEntityTabGrid
    // $onChanges
    @Input() spaceIdr: ISpaceIdentifier;

    // used by: dpimplem, main-search, entity-tree, CSD.ModuleEntityList, CSD.ModuleEntityGrid, CSD.ModuleEntityTabGrid
    @Input() egOptions: IEntityGridOptions;

    // used by: dpimplem, main-search, entity-tree, CSD.ModuleEntityList, CSD.ModuleEntityGrid, CSD.ModuleEntityTabGrid
    /** dynatrace context for the Entity Inside */
    @Input() dtContext: string;

    // used by: dpimplem, entity-tree, CSD.ModuleEntityTabGrid
    // $onChanges
    @Input() serverType: ServerType;

    // used by: entity-tree, CSD.ModuleEntityGrid, CSD.ModuleEntityTabGrid
    // $onChanges
    @Input() entityAttributes: AttributeMetaInfo[];

    // used by: entity-tree, CSD.ModuleEntityList, CSD.ModuleEntityTabGrid
    @Input() parentDataId: string;

    // used by: entity-tree, CSD.ModuleEntityTabGrid
    @Input() parentData: EntityItem;

    // used by: entity-tree, CSD.ModuleEntityList
    @Input() currentEntityId: string;

    // CSD.ModuleEntityGrid, CSD.ModuleEntityTabGrid
    /** important: the tupple ('omni-grid', gridStatePersistenceId) identifies the stored grid state for the user.
     * See UserSettingValue and table [UserSetting] for details */
    @Input() gridStatePersistenceId: string;
    //#endregion

    /** Emitted when the grid init is done and the ag grid is ready */
    @Output() gridReady = new EventEmitter();

    //#region html bindings
    public searchBoxTerm: string;

    public get gridData() {
        return this.core.gridData;
    }
    public get noResult() {
        return this.core.noResult;
    }
    public get tooManyResults() {
        return this.core.tooManyResults;
    }
    public get totalCount() {
        return this.core.totalCount;
    }
    public get columnsList() {
        return this.core.columnsList;
    }
    public get filteredColumnListGroups() {
        return this.core.filteredColumnListGroups;
    }
    public get bulkEditDropdownOptions() {
        return this.core.bulkActionDropdownOptions;
    }
    public get bulkEditDropdownButton() {
        return this.core.bulkActionDropdownButton;
    }
    public get globalCollapseExpandDropdownOptions() {
        return this.core.globalCollapseExpandDropdownOptions;
    }
    public get globalCollapseExpandDropdownButton() {
        return this.core.globalCollapseExpandDropdownButton;
    }

    public get isListOptionEmpty() {
        return this.core.bulkActionDropdownOptions.every((opt) =>
            ListOptionUtil.isHidden(opt, opt?.data)
        );
    }

    public get isList() {
        return this.core.isList;
    }
    public get isGrid() {
        return this.core.isGrid;
    }
    public get isFlat() {
        return this.core.isFlat;
    }
    public get isTree() {
        return this.core.isTree;
    }
    public get resettingGrid() {
        return this.core.resettingGrid;
    }
    public get isHierarchical() {
        return this.core.isHierarchical;
    }
    public get isAutoHeight() {
        return this.core.isAutoHeight;
    }
    public get isBreadcrumbs() {
        return this.core.isBreadcrumbs;
    }
    public get rowHeight() {
        return this.core.rowHeight;
    }
    public get headerHeight() {
        return this.core.headerHeight;
    }
    public get showMessagesInGrid() {
        return this.core.showMessagesInGrid;
    }
    public get isAnyFilter() {
        return this.core.isAnyFilter;
    }
    public get selectedCount() {
        return this.core.selectedCount;
    }
    public get selectedEntities() {
        return this.core.selectedEntities;
    }
    public get showTitleBar() {
        return this.core.showTitleBar;
    }
    public get isAnyTitleMessage() {
        return this.core.isAnyTitleMessage;
    }
    public get selectionMessage() {
        return this.core.selectionMessage;
    }
    public get showTotalCountMessage() {
        return this.core.showTotalCountMessage;
    }
    public get totalCountMessage() {
        return this.core.totalCountMessage;
    }
    public get isAnyBulkAction() {
        return this.core.isAnyBulkAction;
    }
    public get showColumnsSelector() {
        return this.core.showColumnsSelector;
    }
    public get showGrid() {
        return this.core.showGrid;
    }
    public get isMultiSelect() {
        return this.core.isMultiSelect;
    }
    public get rowSelection() {
        return this.core.rowSelection;
    }
    public get showHierarchicalToggleButton() {
        return this.core.showHierarchicalToggleButton;
    }
    public get showHierarchicalToggleButtonInGrid() {
        return this.core.showHierarchicalToggleButtonInGrid;
    }
    public get showGlobalCollapseExpand() {
        return this.core.showGlobalCollapseExpand;
    }
    public get externalTitleMessage() {
        return this.core.externalTitleMessage;
    }
    public get headerActionButton() {
        return this.core.headerActionButton;
    }
    public get headerActionButtonTooltipKey() {
        return CoreUtil.fromFnOrValue(
            this.core.headerActionButton.tooltipTranslateKey
        );
    }
    public get showSearchBox() {
        return this.core.showSearchBox;
    }
    public get isCreateButtonVisible() {
        return this.core.isCreateButtonVisible;
    }
    public get hideColumnSelectorCollapse() {
        return this.core.hideColumnSelectorCollapse;
    }
    //#endregion

    //#region locals
    private core: EntityGridCore;
    private searchTermChanged = new Subject<string>();
    private agGridReady = new BehaviorSubject<boolean>(false);
    //#endregion

    constructor(
        private cd: ChangeDetectorRef,
        translate: TranslateService,
        attributeDataService: AttributeDataService,
        entityService: EntityService,
        viewTypeService: ViewTypeService,
        entityUiService: EntityUiService,
        entityPreviewPanelService: EntityPreviewPanelService,
        entityEventService: EntityEventService,
        navigationService: NavigationService,
        breadcrumbService: BreadcrumbService,
        entitySecurityService: EntitySecurityService,
        serverTimeService: ServerTimeService,
        userService: UserService,
        entityCacheService: EntityCacheService,
        functionalLogService: FunctionalLogService,
        appEventsService: AppEventsService,
        filteredViewService: FilteredViewService,
        exportService: ExportService,
        entityGridBurgerMenuService: EntityGridBurgerMenuService,
        campaignSecurityService: CampaignSecurityService,
        campaignUiService: CampaignUiService,
        ngZone: NgZone
    ) {
        super();
        this.core = new EntityGridCore(
            translate,
            attributeDataService,
            entityService,
            viewTypeService,
            entityUiService,
            entityPreviewPanelService,
            entityEventService,
            navigationService,
            breadcrumbService,
            entitySecurityService,
            serverTimeService,
            userService,
            entityCacheService,
            functionalLogService,
            appEventsService,
            filteredViewService,
            exportService,
            entityGridBurgerMenuService,
            campaignSecurityService,
            campaignUiService,
            ngZone,
            this.debug
        );
        this.searchTermChanged
            .pipe(debounceTime(500), distinctUntilChanged())
            .subscribe((value) => {
                this.core.setSearchBoxTerm(value);
                this.core.onSubmitSearch();
            });
    }

    ngOnChanges(changes: SimpleChanges): void {
        super.onChange(changes, 'egOptions', () => this.init());
        super.onChange(changes, 'serverType', () =>
            this.core?.setServerType(this.serverType)
        );
        super.onChange(changes, 'entityAttributes', () =>
            this.core?.setEntityAttributes(this.entityAttributes)
        );
        super.onChange(changes, 'spaceIdr', () =>
            this.core?.setSpaceIdr(this.spaceIdr)
        );
    }

    ngOnInit() {
        this.init();
    }

    ngOnDestroy() {
        super.ngOnDestroy();
        this.core?.destroy();
    }

    private async init() {
        this.log('init', this.egOptions);
        if (!this.egOptions) {
            return;
        }
        await this.core?.init(
            this.securityData,
            this.spaceIdr,
            this.egOptions,
            this.dtContext,
            this.serverType,
            this.entityAttributes,
            this.parentDataId,
            this.parentData,
            this.currentEntityId,
            this.gridStatePersistenceId,
            this.logId
        );
        this.cd.detectChanges();
        this.agGridReady.subscribe((ready) => {
            if (ready) {
                this.gridReady.emit();
            }
        });
    }

    //#region event handlers
    public onSearchBoxTermChanged(searchString: string) {
        this.searchTermChanged.next(searchString);
    }
    public onGridReady(gridApi: EntityItemOmniGridApi) {
        this.core.onGridReady(gridApi);
        this.agGridReady.next(true);
    }
    public onRowClick(
        entity: EntityItem,
        refreshRow?: (newRowData: EntityItem) => void
    ) {
        this.core.onRowClick(entity, refreshRow);
    }
    public onSortChange(sortModel: OmniGridSortModel) {
        this.core.onSortChange(sortModel);
    }
    public onGridSelectionChanged() {
        this.core.onGridSelectionChanged();
    }
    public onGridColumnsChanged() {
        this.core.onGridColumnsChanged();
    }
    public onCreateButtonClick() {
        this.core.onCreateButtonClick();
    }
    public onFunctional(event: IFunctionalEvent) {
        this.core.onFunctional(event);
    }
    public onHeaderActionButtonClick() {
        this.headerActionButton.callback?.();
    }
    //#endregion event handlers

    //#region columns actions
    public resetColumnStates() {
        return this.core.resetColumnStates();
    }
    public setColumnVisibility(column: OmniGridColumnInfo) {
        return this.core.setColumnVisibility(column);
    }
    public filterColumns(searchString: string) {
        return this.core.filterColumns(searchString);
    }
    public getColumnGlyphClass = (column: EntityGridColumnInfo) => {
        return this.core.getColumnGlyphClass(column);
    };
    //#endregion columns actions

    public getFeatureCodeHierarchy() {
        return this.core.getFeatureCodeHierarchy();
    }
    public async toggleHierarchicalView() {
        await this.core.toggleHierarchicalView();
        this.cd.detectChanges();
    }
    public getHierarchicalTooltip() {
        return this.core.getHierarchicalTooltip();
    }

    public getGlyphClass(option: IListOption) {
        return ListOptionUtil.getGlyphClass(option);
    }

    public async resetGrid() {
        await this.core.resetCoreGrid();
    }
}

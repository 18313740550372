import { NgModule } from '@angular/core';
import { DxyDialogModule } from '@datagalaxy/core-ui/dialog';
import { DxyModalFooterComponent } from './dxy-modal-footer/dxy-modal-footer.component';
import { DxyModalService } from './DxyModalService';
import { SharedModule } from '../shared.module';

@NgModule({
    providers: [DxyModalService],
    imports: [SharedModule, DxyDialogModule],
    declarations: [DxyModalFooterComponent],
    exports: [DxyModalFooterComponent, DxyDialogModule],
})
export class DxySharedDialogsModule {}

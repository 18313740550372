import { NgModule } from '@angular/core';
import { DxyDraggableFieldDirective } from './directives/dxyDraggableField.directive';
import { DxyDroppableAttributeDirective } from './directives/dxyDroppableAttribute.directive';
import { DxyCsvFileDepositCardComponent } from './components/dxy-import-wizard/dxy-csv-file-deposit-card/dxy-csv-file-deposit-card.component';
import { DxyAttributeMatchComponent } from './components/dxy-import-wizard/dxy-attribute-match/dxy-attribute-match.component';
import { DxySolutionSelectionComponent } from './components/dxy-import-wizard/dxy-solution-selection/dxy-solution-selection.component';
import { DxySolutionDetailsComponent } from './components/dxy-import-wizard/dxy-solution-details/dxy-solution-details.component';
import { DxyCsvPreImportMappingsComponent } from './components/dxy-import-wizard/dxy-csv-preimport-mappings/dxy-csv-pre-import-mappings.component';
import { DxyCsvPreImportPreviewComponent } from './components/dxy-import-wizard/dxy-csv-preimport-preview/dxy-csv-preimport-preview.component';
import { DxyCsvPreImportCheckComponent } from './components/dxy-import-wizard/dxy-csv-preimport-check/dxy-csv-preimport-check.component';
import { DxyCsvPostImportComponent } from './components/dxy-import-wizard/dxy-csv-post-import/dxy-csv-post-import.component';
import { DxyCsvPreImportWrapperComponent } from './components/dxy-import-wizard/dxy-csv-preimport-wrapper/dxy-csv-preimport-wrapper.component';
import { DxyCsvImportSettingsComponent } from './components/dxy-import-wizard/dxy-csv-import-settings/dxy-csv-import-settings.component';
import { DxyCsvCheckMessageComponent } from './components/dxy-import-wizard/dxy-csv-check-message/dxy-csv-check-message.component';
import { DxyWizardLayoutComponent } from './components/dxy-import-wizard/dxy-wizard-layout/dxy-wizard-layout.component';
import { DxyImportWizardModalComponent } from './components/dxy-import-wizard/dxy-import-wizard-modal/dxy-import-wizard-modal.component';
import { DxyTestErrorModalComponent } from './components/dxy-import-wizard/dxy-test-error-modal/dxy-test-error-modal.component';
import { DxyTestErrorMessageComponent } from './components/dxy-import-wizard/dxy-test-error-message/dxy-test-error-message.component';
import { DxyOmnigridModule } from '@datagalaxy/core-ui/omnigrid';
import { SharedModule } from '../shared/shared.module';
import { FieldsModule } from '../shared/fields/fields.module';
import { DxySpaceSelectorModule } from '../space-selector/DxySpaceSelectorModule';
import { DxyFileOptionsModule } from '../shared/file-options/DxyFileOptionsModule';
import { DxySharedUiModule } from '../shared/shared-ui/DxySharedUiModule';
import { DxyAttributesModule } from '../shared/attribute/DxyAttributesModule';
import { DxyConnectorModule } from '../connector/DxyConnectorModule';
import { DxyFiltersModule } from '@datagalaxy/core-ui/filters';
import { GridComponent } from '@datagalaxy/ui/grid';
import { DxyButtonsModule } from '@datagalaxy/ui/buttons';

@NgModule({
    imports: [
        SharedModule,
        FieldsModule,
        DxySpaceSelectorModule,
        DxyFileOptionsModule,
        DxySharedUiModule, // for dxy-dropdown-section used in dxy-csv-import-settings
        DxyAttributesModule,
        DxyConnectorModule, // for dxy-online-connection-form
        DxyOmnigridModule,
        DxyFiltersModule,
        GridComponent,
        DxyButtonsModule,
    ],
    declarations: [
        //#region directives
        DxyDraggableFieldDirective,
        DxyDroppableAttributeDirective,
        //#endregion
        DxyAttributeMatchComponent,
        DxySolutionSelectionComponent,
        DxySolutionDetailsComponent,
        DxyCsvFileDepositCardComponent,
        DxyCsvPreImportMappingsComponent,
        DxyCsvPreImportPreviewComponent,
        DxyCsvPreImportCheckComponent,
        DxyCsvImportSettingsComponent,
        DxyCsvPostImportComponent,
        DxyCsvPreImportWrapperComponent,
        DxyCsvCheckMessageComponent,
        DxyWizardLayoutComponent,
        DxyImportWizardModalComponent,
        DxyTestErrorModalComponent,
        DxyTestErrorMessageComponent,
    ],
    exports: [
        DxyImportWizardModalComponent,
        DxyTestErrorMessageComponent,
        DxyTestErrorModalComponent,
        //#region exported only for dxy-test-import
        DxyDraggableFieldDirective,
        DxyDroppableAttributeDirective,
        DxySolutionSelectionComponent,
        DxySolutionDetailsComponent,
        DxyCsvImportSettingsComponent,
        DxyCsvPreImportWrapperComponent,
        DxyCsvPostImportComponent,
        DxyCsvFileDepositCardComponent,
        //#endregion
    ],
})
export class DxyImportModule {}

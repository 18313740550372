<dxy-filter-button
    *ngIf="module && !withDropdown"
    [iconClass]="module.coloredGlyphClass"
    labelKey="common.module"
    [text]="module.translateKey | translate"
    [isResolved]="true"
    [noMenu]="!withDropdown"
    [hasRemove]="removable"
    [readonly]="readonly"
    (removeClick)="removeClick.emit()"
    (click)="buttonClick.emit()"
></dxy-filter-button>

<dxy-value-list-filter
    *ngIf="withDropdown"
    [(filter)]="filter"
    [isMini]="false"
    [items]="filterItems"
    [adapter]="filterAdapter"
    [config]="filterConfig"
    [labelText]="'Module'"
    [readonly]="false"
    [noOperator]="true"
    [hasRemove]="true"
    (filterChange)="onFilterChange()"
    (remove)="onRemove()"
></dxy-value-list-filter>

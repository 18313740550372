<dxy-filter-button
    [xMenuPosition]="xMenuPosition"
    [labelKey]="labelKey"
    [labelText]="labelText"
    [isResolved]="isResolved"
    [isMini]="isMini"
    [hasRemove]="hasRemove"
    [operators]="operators"
    [operator]="operator"
    [orOperator]="orOperator"
    [andOperator]="andOperator"
    [noOperator]="noOperator"
    [removeDisabled]="removeDisabled"
    [removeDisabledTooltipKey]="removeDisabledTooltipKey"
    [readonly]="readonly"
    [hasSmallPanel]="showOptionSelect"
    [getOperatorTranslateKey]="getOperatorTranslateKey"
    [iconClass]="iconClass"
    [iconUrl]="iconUrl"
    [hideIcon]="hideIcon"
    [menuHasDynamicHeight]="menuHasDynamicHeight"
    (operatorChange)="onOperatorChange($event)"
    (removeClick)="onRemove($event)"
    (onOpenClose)="onOpenCloseMenu($event)"
>
    <div *ngIf="!isValueLessOperator" ngProjectAs="[customIconTemplate]">
        <ng-content select="[customIconTemplate]"></ng-content>
    </div>
    <div ngProjectAs="[customTextTemplate]" #customTextTemplate>
        <ng-content select="[customTextTemplate]"></ng-content>
    </div>
    <div
        *ngIf="!hasCustomTemplate"
        customTextTemplate
        class="filter-value-wrapper"
    >
        <div class="text-value-wrapper">
            <ng-container *ngIf="!isValueLessOperator">
                <ng-container *ngIf="hasOneValue">
                    <span
                        class="filter-value"
                        [class.struck-out-text]="isStruckOutText"
                        dxyEllipsisTooltip
                        >{{ firstValueText }}</span
                    >
                </ng-container>
                <ng-container *ngIf="hasTwoValues">
                    <span
                        *ngIf="firstValueGlyph"
                        class="duo-glyph"
                        [ngClass]="firstValueGlyph"
                    ></span>
                    <span
                        class="filter-value"
                        [class.struck-out-text]="isStruckOutText"
                        dxyEllipsisTooltip
                        >{{ firstValueText }}</span
                    >
                    <span
                        class="text-separator"
                        [translate]="valuesSeparatorKey"
                    ></span>
                    <span
                        *ngIf="secondValueGlyph"
                        class="duo-glyph"
                        [ngClass]="secondValueGlyph"
                    ></span>
                    <span
                        class="filter-value"
                        [class.struck-out-text]="isStruckOutText"
                        dxyEllipsisTooltip
                        >{{ secondValueText }}</span
                    >
                </ng-container>
                <ng-container *ngIf="hasMultipleValues">
                    <span
                        class="filter-value"
                        [class.struck-out-text]="isStruckOutText"
                        >{{ summaryValue }}</span
                    >
                    <span
                        class="text-separator"
                        [translate]="valuesSeparatorKey"
                    ></span>
                </ng-container>
            </ng-container>
            <span
                *ngIf="isValueLessOperator"
                class="text-separator"
                [class.struck-out-text]="!isEmptyOperator"
                translate
            >
                CoreUI.Filter.Operators.Global.EmptyField
            </span>
        </div>
    </div>

    <!-- Filter Menu content -->
    <ng-container *ngIf="menuOpen">
        <dxy-multiselect-list
            *ngIf="isMultipleValues"
            class="inside-dropdown"
            [data]="multiSelectData"
        >
            <ng-content
                select="[headerBeforeSearch]"
                headerBeforeSearch
            ></ng-content>
            <ng-content
                select="[headerAfterSearch]"
                headerAfterSearch
            ></ng-content>
        </dxy-multiselect-list>

        <div *ngIf="showSingleSelect" class="select-wrapper">
            <dxy-field-select
                [ngModel]="singleValue"
                [adapter]="adapter"
                (ngModelChange)="onValueChange($event)"
                [options]="items"
                [search]="config?.hasSearch"
            >
                <ng-content
                    select="[headerBeforeSearch]"
                    headerBeforeSearch
                ></ng-content>
                <ng-content
                    select="[headerAfterSearch]"
                    headerAfterSearch
                ></ng-content>
            </dxy-field-select>
        </div>
        <div
            *ngIf="showOptionSelect"
            class="value-list-filter-option-select-menu"
        >
            <dxy-search-input
                *ngIf="isSearchEnabled"
                (onSearchString)="onOptionSearch($event.searchString)"
            ></dxy-search-input>
            <cdk-virtual-scroll-viewport
                [style.height.px]="scrollItemHeight"
                [itemSize]="rowHeightInPx"
            >
                <dxy-option-item
                    *cdkVirtualFor="let option of options"
                    [option]="option"
                    (click)="onOptionSelected(option)"
                ></dxy-option-item>
            </cdk-virtual-scroll-viewport>
            <dxy-spinner
                *ngIf="loading$ | async"
                class="spinner xl"
            ></dxy-spinner>
        </div>
    </ng-container>
    <!-- End Filter Menu content -->
</dxy-filter-button>

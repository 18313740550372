import { environment } from '../../../../environments/environment';
import { ObjectLinkType } from '@datagalaxy/dg-object-model';
import { LineageConstants } from './LineageConstants';
import { LineageItem } from './LineageItem';
import { LineageLinkOrientationType } from '@datagalaxy/webclient/explorer/data-access';

export class LineageLink {
    //#region static

    private static readonly allowSameSide =
        LineageConstants.behaviour.linkRoutes.allowSameSide;
    private static readonly hTension =
        LineageConstants.behaviour.linkRoutes.horizontalTension;
    private static readonly hTensionVDistThreshold =
        LineageConstants.behaviour.linkRoutes
            .horizontalTensionVerticalDistanceThreshold;
    private static readonly verticalOffset =
        LineageConstants.linkEnd.verticalOffset;

    //#endregion

    public isVisible: boolean;
    public readonly class: string;
    public isVirtualGoldenLink: boolean;
    public isLeftToRight: boolean;

    private cx: number;
    private cy: number;

    constructor(
        /** can't be null. Start of the link. */
        public readonly src: LineageItem,
        /** can't be null. End of the link. */
        public readonly tgt: LineageItem,

        public readonly orient: LineageLinkOrientationType,
        public virtual: VirtualType,
        public readonly linkType: ObjectLinkType,
        public isGoldenLink?: boolean,
        public readonly entityLinkReferenceId?: string,
        public isLazyLink?: boolean
    ) {
        this.class = this.getClasses();
    }

    public computeVisibility() {
        return (this.isVisible = this.getIsVisible());
    }

    /** computes and return the path.
     * note: this is called very often:
     *  for every visible link of a hierarchical group while it's being dragged */
    public getPath() {
        /* path goes from src(x1,y1) to tgt(x2,y2) */

        const a = this.src,
            b = this.tgt;

        let x1: number, x2: number, route: number;

        // compute the shortest of the (4 or 2) possible routes
        const nr = LineageLink.allowSameSide ? 4 : 2,
            al = a.x,
            ar = al + a.width,
            bl = b.x,
            br = bl + b.width,
            dy = a.y - b.y,
            dy2 = dy * dy;
        for (let ir = 0, minsd = Infinity; ir < nr; ir++) {
            let ax: number, bx: number;
            switch (ir) {
                // opposite sides
                case 0:
                    ax = ar;
                    bx = bl;
                    break;
                case 1:
                    ax = al;
                    bx = br;
                    break;
                // same sides
                case 2:
                    ax = ar;
                    bx = br;
                    break;
                case 3:
                    ax = al;
                    bx = bl;
                    break;
            }
            const dx = ax - bx,
                sd = dx * dx + dy2; // squared distance. Ok for comparison
            if (sd < minsd) {
                minsd = sd;
                x1 = ax;
                x2 = bx;
                route = ir;
            }
        }

        let y2: number;
        // add up vertical offset so ends do not overlap
        if (this.orient != LineageLinkOrientationType.Unoriented) {
            if (x2 < x1) {
                y2 = b.ymidmin + LineageLink.verticalOffset;
            } else {
                y2 = b.ymidmin - LineageLink.verticalOffset;
            }
        } else {
            y2 = b.ymidmin;
        }

        const y1 = a.ymidmin,
            mx = (x1 + x2) / 2;

        // store the center of the path, for displaying a label
        this.cx = mx;
        this.cy = (y1 + y2) / 2;

        // store the x start and the end of the path
        this.isLeftToRight = x1 < x2;

        // compute the intermediate points
        const cy1 = y1,
            cy2 = y2;
        let cx1: number, cx2: number;
        const ht = route == undefined ? 0 : LineageLink.hTension; // horizontal tension
        if (ht != 0) {
            // vertical distance under which we apply the horizontal tension proportionnaly
            // preventing short links to zig-zag
            const vd0 = LineageLink.hTensionVDistThreshold;
            if (vd0 > 0) {
                let t1: number, t2: number;
                switch (route) {
                    case 0:
                        /* r->l */ t1 = ht;
                        t2 = -ht;
                        break;
                    case 1:
                        /* l->r */ t1 = -ht;
                        t2 = ht;
                        break;
                    case 2:
                        /* r->r */ t1 = ht;
                        t2 = ht;
                        break;
                    case 3:
                        /* l->l */ t1 = -ht;
                        t2 = -ht;
                        break;
                }
                const vd = Math.abs(y1 - y2);
                if (vd >= vd0) {
                    cx1 = mx + t1;
                    cx2 = mx + t2;
                } else {
                    const k = vd / vd0;
                    cx1 = mx + t1 * k;
                    cx2 = mx + t2 * k;
                }
            } else {
                cx1 = cx2 = mx;
            }
        } else {
            cx1 = cx2 = mx;
        }

        return `M${x1 | 0},${y1 | 0} C${cx1 | 0},${cy1 | 0} ${cx2 | 0},${
            cy2 | 0
        } ${x2 | 0},${y2 | 0}`;
    }

    public getCenterTransform(textWidth = 0, randomOffset = 0) {
        let x = this.cx - textWidth / 2,
            y = this.cy;

        if (randomOffset > 0) {
            const r = Math.random;
            x += r() * randomOffset * (r() < 0.5 ? 1 : -1);
            y += r() * randomOffset * (r() < 0.5 ? 1 : -1);
        }

        return `translate(${x | 0},${y | 0})`;
    }

    private getIsVisible() {
        const s = this.src,
            t = this.tgt,
            v = this.virtual;
        if (v != VirtualType.No) {
            if (v == VirtualType.Both && (s.isExpanded || t.isExpanded)) {
                return false;
            }
            if (
                (s.isExpanded || !s.hasChildren) &&
                (t.isExpanded || !t.hasChildren)
            ) {
                return false;
            }
        }
        return s.isVisible() && t.isVisible();
    }

    private getClasses(): string {
        try {
            const s = this.src,
                t = this.tgt,
                v = this.virtual == VirtualType.No ? '' : ' vir',
                l = this.isLazyLink ? ' lzy' : '';
            return `link hg${s.root.id} hg${t.root.id} s${s.id} t${t.id}${v}${l}`;
        } catch (e) {
            if (!environment.production) {
                console.warn('link', this, e);
            }
            return 'link';
        }
    }

    //#region for debug

    public toDebugString(withDisplayNames = false) {
        const lid =
            this.src.id +
            (this.orient == LineageLinkOrientationType.Unoriented ? '-' : '>') +
            this.tgt.id;
        const typ = this.isLazyLink
            ? 'lz'
            : this.virtual == VirtualType.Both
            ? 'vv'
            : this.virtual == VirtualType.Src
            ? 'vs'
            : this.virtual == VirtualType.Tgt
            ? 'vt'
            : 'nv';
        const parts = [lid, typ];
        if (withDisplayNames) {
            parts.push(this.src.displayName, this.tgt.displayName);
        }
        return parts.join(' ');
    }

    //#endregion
}
export enum VirtualType {
    No,
    Src,
    Tgt,
    Both,
}

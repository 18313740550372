import { CollectionsHelper } from '@datagalaxy/core-util';
import { LineageConstants } from '../data/LineageConstants';
import { LineageData } from '../data/LineageData';
import { LineageItem } from '../data/LineageItem';
import { EntityType } from '@datagalaxy/dg-object-model';

export class LineageLayout {
    private lineageData: LineageData;
    private columnsHLayout: IColumnsHorizontalLayout;
    private availHeight: number;

    constructor(private itemSpec: LineageConstants, private debug: boolean) {}

    public compute(lineageData: LineageData, surfaceWidth: number) {
        this.lineageData = lineageData;
        this.columnsHLayout = this.computeColumnsLayout(surfaceWidth);
        this.computeNewColumns(this.lineageData.getAllRoots());
    }
    public computeNewColumns(newRoots: LineageItem[]) {
        if (!newRoots?.length) {
            return;
        }
        const rootWidth = this.columnsHLayout.rootWidth;
        newRoots.forEach((r) => r.computeSizeSelfAndDescendants(rootWidth));
    }

    public computeInitialPositions(surfaceHeight: number) {
        this.availHeight = surfaceHeight;
        return this.computeNewRootsInitialPositions();
    }
    public computeNewRootsInitialPositions(newRoots?: LineageItem[]) {
        const columns = this.lineageData.columns;
        const columnsHLayout = this.columnsHLayout;

        this.log(
            'computeNewRootsInitialPositions',
            this.availHeight,
            columnsHLayout,
            columns,
            newRoots
        );

        const updatedRoots: LineageItem[] = [],
            cy = this.availHeight / 2,
            mbc = this.itemSpec.marginBetweenChildren(0),
            { colMargin, rootMargin, rootWidth } = columnsHLayout,
            mx = colMargin + rootMargin,
            w = rootWidth + 2 * mx;

        newRoots?.forEach((r) => r.computeSizeSelfAndDescendants(rootWidth));

        columns.forEach((column, i) => {
            let colItems = column.items,
                colNewItems = colItems;
            if (newRoots) {
                const split = CollectionsHelper.split(column.items, (it) =>
                    newRoots.includes(it)
                );
                colNewItems = split.matches;
                colItems = split.others;
            }
            if (!colNewItems.length) {
                return;
            }

            const h =
                CollectionsHelper.sum(colItems, (it) => it.height) +
                (colItems.length - 1) * mbc;
            // add new roots below existing ones
            const y0 = newRoots
                ? CollectionsHelper.sum(colItems, (it) => it.height + mbc)
                : 0;
            // column's content is centered vertically
            let y = cy - h / 2 + y0;
            const x = mx + i * w;
            colNewItems.forEach((root) => {
                if (root.x != x || root.y != y) {
                    root.positionSelfAndDescendants(x, y);
                    updatedRoots.push(root);
                }
                y += root.height + mbc;
            });
        });

        if (this.debug) {
            const {
                items: allItems,
                links: allLinks,
                columns,
            } = this.lineageData;
            const items = allItems.filter((it) => !it.x && !it.y);
            this.log('items-without-coordinates:', items.length);
            items.forEach((it) =>
                this.log(
                    it.toDebugString(true),
                    `(${EntityType[it.entityType]})`,
                    it,
                    {
                        column: columns.find((c) => c.items.includes(it.root)),
                        links: allLinks.filter(
                            (l) => l.src == it || l.tgt == it
                        ),
                    }
                )
            );
        }

        return updatedRoots;
    }

    /** computes the columns width and margins */
    private computeColumnsLayout(surfaceWidth: number) {
        const colAvg = surfaceWidth / this.lineageData.columns.length,
            rootMargin = this.itemSpec.marginLeft(0),
            rootWidth = this.itemSpec.minWidth(0),
            rootBlockWidth = rootWidth + 2 * rootMargin,
            colWidth = Math.max(rootBlockWidth, colAvg),
            colMargin = (colWidth - rootBlockWidth) / 2,
            columnsHLayout = { colWidth, colMargin, rootWidth, rootMargin };
        this.log('computeColumnsLayout', columnsHLayout);
        return columnsHLayout;
    }

    private log(...args: any[]) {
        this.debug && console.log(this.constructor.name, ...args);
    }
}

interface IColumnsHorizontalLayout {
    colWidth: number;
    colMargin: number;
    rootWidth: number;
    rootMargin: number;
}

import { NgModule } from '@angular/core';
import { FieldsModule } from '../fields/fields.module';
import { DxyAttributeDateInputComponent } from './inputs/dxy-attribute-date-input/dxy-attribute-date-input.component';
import { DxyAttributeBooleanInputComponent } from './inputs/dxy-attribute-boolean-input/dxy-attribute-boolean-input.component';
import { DxyAttributeFormattedTextInputComponent } from './inputs/text/dxy-attribute-formatted-text-input/dxy-attribute-formatted-text-input.component';
import { DxyAttributeHyperlinkInputComponent } from './inputs/text/dxy-attribute-hyperlink-input/dxy-attribute-hyperlink-input.component';
import { DxyAttributeMultilineTextInputComponent } from './inputs/text/dxy-attribute-multiline-text-input/dxy-attribute-multiline-text-input.component';
import { DxyAttributeTextInputComponent } from './inputs/text/dxy-attribute-text-input/dxy-attribute-text-input.component';
import { DxyAttributeLinkShortcutInputComponent } from './inputs/entityselector/dxy-attribute-link-shortcut-input/dxy-attribute-link-shortcut-input.component';
import { DxyEntitySelectorModule } from '../entitySelector/DxyEntitySelectorModule';
import { DxyAttributeLogicalParentInputComponent } from './inputs/entityselector/dxy-attribute-logical-parent-input/dxy-attribute-logical-parent-input.component';
import { DxyAttributeLogicalPathInputComponent } from './inputs/dxy-attribute-logical-path-input/dxy-attribute-logical-path-input.component';
import { DxyAttributeDataTypeSizeInputComponent } from './inputs/dxy-attribute-data-type-size-input/dxy-attribute-data-type-size-input.component';
import { DxyAttributeReferenceListInputComponent } from './inputs/select/dxy-attribute-reference-list-input/dxy-attribute-reference-list-input.component';
import { DxyAttributeMultiselectInputComponent } from './inputs/dxy-attribute-multi-select-input/dxy-attribute-multi-select-input.component';
import { DxyAttributeRichValueListInputComponent } from './inputs/select/dxy-attribute-rich-value-list-input/dxy-attribute-rich-value-list-input.component';
import { DxyEntityAttributeInputComponent } from './dxy-entity-attribute-input/dxy-entity-attribute-input.component';
import { RichTextModule } from '../richText/RichTextModule';
import { DxyAttributeCompareItemComponent } from './dxy-attribute-compare-item/dxy-attribute-compare-item.component';
import { SuggestionModule } from '../../suggestions/suggestion.module';
import { DxyTimeSeriesModule } from '../../time-series/DxyTimeSeriesModule';
import { DxyAttributeNumberInputComponent } from './inputs/text/dxy-attribute-number-input/dxy-attribute-number-input.component';
import { DxyAttributeEmailInputComponent } from './inputs/text/dxy-attribute-email-input/dxy-attribute-email-input.component';
import { DxyNavigationModule } from '../../navigation/DxyNavigationModule';
import { DxyAttributeValueListInputComponent } from './inputs/select/dxy-attribute-value-list-input/dxy-attribute-value-list-input.component';
import { SharedModule } from '../shared.module';
import { DxyAttributeQualityScoreDropdownComponent } from './dxy-attribute-quality-score-dropdown/dxy-attribute-quality-score-dropdown.component';
import { AttributeSuggestionListComponent } from './attribute-suggestion-list/attribute-suggestion-list.component';
import { DxyAttributeTechnologyInputComponent } from './inputs/select/dxy-attribute-technology-input/dxy-attribute-technology-input.component';
import { DxySharedUiModule } from '../shared-ui/DxySharedUiModule';
import { DxyAttributeTimeSeriesInputComponent } from './inputs/dxy-attribute-time-series-input/dxy-attribute-time-series-input.component';
import { DxyAttributePasswordInputComponent } from './inputs/text/dxy-attribute-password-input/dxy-attribute-password-input.component';
import { AttributeFinderService } from './attribute-finder.service';
import { ATTRIBUTES_FINDER_TOKEN } from '@datagalaxy/webclient/attribute/feature';
import { DxyAttributeEntityTypeInputComponent } from './inputs/text/dxy-attribute-entity-type-input/dxy-attribute-entity-type-input.component';

@NgModule({
    imports: [
        SharedModule,
        DxySharedUiModule,
        FieldsModule,
        RichTextModule,
        DxyEntitySelectorModule,
        DxyNavigationModule,
        DxyTimeSeriesModule,
        SuggestionModule,
    ],
    declarations: [
        AttributeSuggestionListComponent,
        DxyAttributeQualityScoreDropdownComponent,
        DxyAttributeTextInputComponent,
        DxyAttributeEntityTypeInputComponent,
        DxyAttributeMultilineTextInputComponent,
        DxyAttributeFormattedTextInputComponent,
        DxyAttributeDateInputComponent,
        DxyAttributeBooleanInputComponent,
        DxyAttributeHyperlinkInputComponent,
        DxyAttributeEmailInputComponent,
        DxyAttributePasswordInputComponent,
        DxyAttributeNumberInputComponent,
        DxyAttributeLinkShortcutInputComponent,
        DxyAttributeLogicalParentInputComponent,
        DxyAttributeLogicalPathInputComponent,
        DxyAttributeDataTypeSizeInputComponent,
        DxyAttributeTechnologyInputComponent,
        DxyAttributeReferenceListInputComponent,
        DxyAttributeMultiselectInputComponent,
        DxyAttributeValueListInputComponent,
        DxyAttributeTimeSeriesInputComponent,
        DxyAttributeRichValueListInputComponent,
        DxyEntityAttributeInputComponent,
        DxyAttributeCompareItemComponent,
    ],
    exports: [
        DxyAttributeTextInputComponent,
        DxyAttributeEntityTypeInputComponent,
        DxyAttributeMultilineTextInputComponent,
        DxyAttributeFormattedTextInputComponent,
        DxyAttributeDateInputComponent,
        DxyAttributeBooleanInputComponent,
        DxyAttributeHyperlinkInputComponent,
        DxyAttributeEmailInputComponent,
        DxyAttributePasswordInputComponent,
        DxyAttributeNumberInputComponent,
        DxyAttributeLinkShortcutInputComponent,
        DxyAttributeLogicalParentInputComponent,
        DxyAttributeLogicalPathInputComponent,
        DxyAttributeDataTypeSizeInputComponent,
        DxyAttributeTechnologyInputComponent,
        DxyAttributeReferenceListInputComponent,
        DxyAttributeMultiselectInputComponent,
        DxyAttributeValueListInputComponent,
        DxyAttributeTimeSeriesInputComponent,
        DxyAttributeRichValueListInputComponent,
        DxyEntityAttributeInputComponent,
        DxyAttributeCompareItemComponent,
    ],
    providers: [
        {
            provide: ATTRIBUTES_FINDER_TOKEN,
            useClass: AttributeFinderService,
        },
    ],
})
export class DxyAttributesModule {}

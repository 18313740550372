import {
    DksGraphSurface,
    DksNodeComponent,
    EntityDksNode,
    TDksNode,
} from '../data-knowledge-studio.types';
import {
    EdgeSpec,
    IDiagNode,
    NodeSpec,
    SurfaceNodeDragEvent,
} from '@datagalaxy/core-d3-util';
import { ComponentRef, ViewContainerRef } from '@angular/core';
import { MovePhase, Rect } from '@datagalaxy/core-2d-util';
import { DiagramNodeKind } from '@datagalaxy/webclient/diagram/data-access';
import { EntityNodeComponent } from '../nodes/entity/entity-node.component';
import { NodeUtils } from '../nodes/node.utils';
import { DksEntitiesService } from '../entities/dks-entities.service';
import { EntityNodeUtils } from '../nodes/entity/entity-node.utils';
import { DksNotifierService } from '../notifier/dks-notifier';
import { CollectionsHelper } from '@datagalaxy/core-util';
import { DksEntityMenuService } from '../menu/dks-entity-menu/dks-entity-menu.service';

export class DksApi<NodeData = unknown, EdgeData = unknown> {
    public get event$() {
        return this.dksNotifier.events$;
    }

    constructor(
        private graphSurface: DksGraphSurface<NodeData, EdgeData>,
        private viewContainerRef: ViewContainerRef,
        private dksEntitiesService: DksEntitiesService,
        private dksNotifier: DksNotifierService<NodeData>,
        private dksEntityMenuService: DksEntityMenuService
    ) {
        dksEntitiesService
            .selectExtraDataKeys()
            .subscribe((keys) => this.onExtraDataToggle(keys));
        dksEntitiesService
            .selectEntities()
            .subscribe(() =>
                this.onExtraDataToggle(
                    dksEntitiesService.state.extraDataAttributesKeys
                )
            );

        graphSurface.graph.nodes$.subscribe((nodes) =>
            this.onGraphNodesChange(nodes as IDiagNode<TDksNode<NodeData>>[])
        );
        graphSurface.events.nodeDragged$.subscribe((event) =>
            this.onNodeDragged(event)
        );

        graphSurface.events.nodePortHovered$.subscribe((event) => {
            this.dksNotifier.notifyNodePortHover(event);
        });

        graphSurface.events.edgeHovered$.subscribe((event) => {
            this.dksNotifier.notifyEdgeHover(event);
        });
    }

    public add<N>(
        nodes: TDksNode<N>[],
        edgeSpecs: EdgeSpec<NodeData, EdgeData>[]
    ) {
        const specs: NodeSpec<NodeData>[] = nodes.map((node) => ({
            id: node.id,
            rect: Rect.from(node),
            el: this.createComponent(node).location.nativeElement,
            data: node as NodeData,
            minSize: NodeUtils.getMinSize(node),
            maxSize: NodeUtils.getMaxSize(node),
            ports: NodeUtils.isEntityNode(node) ? node.ports : null,
            cssClass: node.cssClass,
        }));

        this.graphSurface.graph.add(specs, edgeSpecs);

        const ids = nodes.filter(NodeUtils.isEntityNode)?.flatMap((n) => {
            const childrenRefs = EntityNodeUtils.flattenNodeTreeChildren(
                n.children
            ).map((n) => n.entity.ReferenceId);
            return [n.entityIdr.ReferenceId, ...childrenRefs];
        });

        void this.dksEntitiesService.addEntities(ids);
    }

    public getNode(nodeId: string) {
        return this.graphSurface.graph.getNodeById(nodeId);
    }

    public getNodeEdges(nodeId: string) {
        return this.getNodesEdges([nodeId]);
    }

    public getNodesEdges(nodeIds: string[]) {
        return this.graphSurface.graph.getNodesEdges(...nodeIds);
    }

    public addClassToNode(id: string, className: string) {
        const node = this.graphSurface.graph.getNodeById(id);
        if (!node) {
            return;
        }
        const cssClass = node.data.cssClass;

        const classesToAdd = className
            .split(' ')
            .filter((c) => !cssClass?.includes(c))
            .join(' ');
        this.graphSurface.graph.updateNode(id, {
            cssClass: `${cssClass} ${classesToAdd}`,
        });
    }

    public addClassToNodeChild(id: string, childId: string, className: string) {
        const node = this.graphSurface.graph.getNodeById(id) as IDiagNode<
            TDksNode<NodeData>
        >;
        if (!node || !NodeUtils.isEntityNode(node.data.data)) {
            return;
        }

        const child =
            node.data.data.id === childId
                ? node.data.data
                : EntityNodeUtils.findChild(node.data.data, childId);

        if (!child) {
            return;
        }

        this.addClassToPort(childId, className);
    }

    public addClassToPort(portId: string, className: string) {
        const el = document.querySelector(`[gs-port-id="${portId}"]`);

        if (!el) {
            return;
        }
        const classNames = className.split(' ');
        el.classList.add(...classNames);
    }

    public removeClassFromPort(portId: string, className: string) {
        const el = document.querySelector(`[gs-port-id="${portId}"]`);

        if (!el) {
            return;
        }
        const classNames = className.split(' ');
        el.classList.remove(...classNames);
    }

    public removeClassFromNode(id: string, className: string) {
        const node = this.graphSurface.graph.getNodeById(id);
        if (!node) {
            return;
        }
        const cssClass = node.data.cssClass;

        const updatedCssClass = CollectionsHelper.removeElements(
            cssClass.split(' '),
            className.split(' ')
        ).join(' ');

        this.graphSurface.graph.updateNode(id, { cssClass: updatedCssClass });
    }

    public removeClassToNodeChild(
        id: string,
        childId: string,
        className: string
    ) {
        const node = this.graphSurface.graph.getNodeById(id) as IDiagNode<
            TDksNode<NodeData>
        >;
        if (!node || !NodeUtils.isEntityNode(node.data.data)) {
            return;
        }

        const child =
            node.data.data.id === childId
                ? node.data.data
                : EntityNodeUtils.findChild(node.data.data, childId);

        if (!child) {
            return;
        }

        const el = document.querySelector(`[gs-port-id="${childId}"]`);

        if (!el) {
            return;
        }
        const classNames = className.split(' ');
        el.classList.remove(...classNames);
    }

    public addClassToEdge(id: string, className: string) {
        const edge = this.graphSurface.graph.getEdgeById(id);
        if (!edge) {
            return;
        }
        const cssClass = edge.connector.cssClass;
        const classesToAdd = className
            .split(' ')
            .filter((c) => !cssClass?.includes(c))
            .join(' ');

        edge.connector.el.classList.add(...classesToAdd.split(' '));
    }

    public removeClassFromEdge(id: string, className: string) {
        const edge = this.graphSurface.graph.getEdgeById(id);
        if (!edge) {
            return;
        }

        edge.connector.el.classList.remove(...className.split(' '));
    }

    public updateNode(id: string, data: Partial<NodeSpec<NodeData>>) {
        this.graphSurface.graph.updateNode(id, data);
    }

    public remove(nodeIds: string[], edgeIds: string[]) {
        this.graphSurface.graph.remove(nodeIds, edgeIds);
    }

    public getState() {
        const surface = this.graphSurface;
        return {
            nodes: surface.graph.getNodes().map((n) => n.data.data),
            edges: surface.graph.getEdges(),
            gridMode: surface.grid.mode,
            extraDataKeys: this.dksEntitiesService.getExtraDataKeys(),
        };
    }

    public centerNode(id: string) {
        const n = this.graphSurface.graph.getNodeById(id);
        this.graphSurface.zoom.centerView(Rect.center(n.data.rect), 500);
        const nodes = this.graphSurface.graph.getNodes();
        this.graphSurface.graph.updateNodes(
            nodes.map((n) => n.id),
            { highlighted: false }
        );
        this.graphSurface.graph.updateNode(id, { highlighted: true });
    }

    public refreshEntitiesMenu() {
        this.dksEntityMenuService.refresh();
    }

    private createComponent<N>(
        node: TDksNode<N>
    ): ComponentRef<DksNodeComponent<N>> {
        switch (node.type) {
            case DiagramNodeKind.Entity: {
                const componentRef = this.viewContainerRef.createComponent(
                    EntityNodeComponent<N>
                );

                componentRef.instance.node = node;
                componentRef.instance.ngOnInit();
                componentRef.instance.nodeCollapse.subscribe((event) =>
                    this.dksNotifier.notifyEntityNodeTreeToggle(
                        event.node as unknown as EntityDksNode<NodeData>,
                        event.nodeTree
                    )
                );
                componentRef.instance.showMore.subscribe((event) =>
                    this.dksNotifier.notifyEntityShowMore(event)
                );
                componentRef.changeDetectorRef.detectChanges();
                return componentRef;
            }
        }
    }

    private onExtraDataToggle(keys: string[]) {
        const nodes = this.graphSurface.graph.getNodes();
        const mediumEntityNodes = nodes
            .map((n) => n.data.data as TDksNode<NodeData>)
            .filter(NodeUtils.isEntityNode)
            .filter((node) => node.sizeMode === 'medium');

        const entityNodesWithExtraData = mediumEntityNodes.filter((node) =>
            this.hasExtraData(node, keys)
        );

        entityNodesWithExtraData.forEach((node) => {
            this.graphSurface.graph.updateNode(node.id, {
                minSize: EntityNodeUtils.getSizeModeMinSize('medium', {
                    extraDataVisible: true,
                    childrenCount: EntityNodeUtils.countChildren(node, true),
                    showMoreItems: EntityNodeUtils.countShowMoreChildren(
                        node,
                        true
                    ),
                }),
                maxSize: EntityNodeUtils.getSizeModeMaxSize('medium', {
                    extraDataVisible: true,
                    childrenCount: EntityNodeUtils.countChildren(node),
                    showMoreItems: EntityNodeUtils.countShowMoreChildren(node),
                }),
            });
        });

        const entityNodesWithoutExtraData = mediumEntityNodes.filter(
            (node) => !this.hasExtraData(node, keys)
        );

        entityNodesWithoutExtraData.forEach((node) => {
            this.graphSurface.graph.updateNode(node.id, {
                minSize: EntityNodeUtils.getSizeModeMinSize('medium', {
                    extraDataVisible: false,
                    childrenCount: EntityNodeUtils.countChildren(node, true),
                    showMoreItems: EntityNodeUtils.countShowMoreChildren(
                        node,
                        true
                    ),
                }),
                maxSize: EntityNodeUtils.getSizeModeMaxSize('medium', {
                    extraDataVisible: false,
                    childrenCount: EntityNodeUtils.countChildren(node),
                    showMoreItems: EntityNodeUtils.countShowMoreChildren(
                        node,
                        true
                    ),
                }),
            });
        });

        this.graphSurface.layout.refresh();
        this.onGraphNodesChange(
            this.graphSurface.graph.getNodes() as IDiagNode<
                TDksNode<NodeData>
            >[]
        );
    }

    private hasExtraData(node: EntityDksNode<NodeData>, keys: string[]) {
        const entity = this.dksEntitiesService.getEntityById(node.entityIdr);
        if (!entity) {
            return;
        }

        return keys?.some((key) => {
            const attributeValue = entity.getAttributeValue(key);
            return (
                (Array.isArray(attributeValue) && !!attributeValue?.length) ||
                (!Array.isArray(attributeValue) && !!attributeValue)
            );
        });
    }

    private onGraphNodesChange(nodes: IDiagNode<TDksNode<NodeData>>[]) {
        nodes.forEach((node) => {
            const dksNode = node.data.data;
            Rect.copy(dksNode, node.data.rect);
        });
    }

    private onNodeDragged(event: SurfaceNodeDragEvent) {
        if (event.phase !== MovePhase.end) {
            return;
        }
        event.nodes.forEach((node) => {
            const dksNode = node.data.data;
            Rect.copy(dksNode, node.data.rect);
        });
    }
}

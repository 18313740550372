import { LineageConstants } from '../data/LineageConstants';
import { LineageItem } from '../data/LineageItem';
import { LineageTrack } from '../data/LineageTrack';

/** Lineage graph sub-component for in-memory storage of path-tracker tracks */
export class LineageTrackStore {
    private static checkId(trackId: number) {
        if (trackId == undefined || trackId < 0) {
            throw Error(`bad trackId (${trackId})`);
        }
    }

    private store = new Array<LineageTrack>(
        LineageConstants.behaviour.pathTracker.nbTracks
    ).fill(undefined);

    constructor(private onChanged?: () => void) {}

    public find(item: LineageItem) {
        return !!item && this.store.find((lt) => lt && lt.item == item);
    }

    public getById(trackId: number) {
        LineageTrackStore.checkId(trackId);
        return this.store[trackId];
    }
    public set(trackId: number, lt: LineageTrack) {
        LineageTrackStore.checkId(trackId);
        if (!lt) {
            throw new Error('data must exist');
        }
        this.store[trackId] = lt;
        this.onChanged?.();
    }
    public clear(trackId: number) {
        LineageTrackStore.checkId(trackId);
        this.store[trackId] = undefined;
        this.onChanged?.();
    }
    public withAll(
        trackId: number | undefined,
        action: (lt: LineageTrack, tid: number) => void
    ) {
        this.store.forEach((lt, idx) => {
            if (!lt || (trackId != undefined && idx != trackId)) {
                return;
            }
            action(lt, idx);
        });
    }
    public hasItem(item: LineageItem, withTrackId?: number) {
        return (
            item &&
            this.store.some(
                (lt, tid) =>
                    lt &&
                    lt.item == item &&
                    (withTrackId == undefined || tid == withTrackId)
            )
        );
    }
    public getTrackId(item: LineageItem) {
        const trackId = !item
            ? -1
            : this.store.findIndex((lt) => lt && lt.item == item);
        return trackId == -1 ? undefined : trackId;
    }

    public any(predicate: (lt: LineageTrack, tid: number) => boolean) {
        return (
            !!this.store.length &&
            this.store.some((lt, tid) => lt && predicate(lt, tid))
        );
    }
    public all(predicate: (lt: LineageTrack, tid: number) => boolean) {
        return (
            !!this.store.length &&
            this.store.every((lt, tid) => lt && predicate(lt, tid))
        );
    }

    public isEmpty() {
        return !this.store.length || this.store.every((lt) => !lt);
    }
}

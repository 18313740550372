import { NgModule } from '@angular/core';
import { DxyScreenAdminListComponent } from './dxy-screen-admin-list/dxy-screen-admin-list.component';
import { DxyScreenAdminComposerComponent } from './dxy-screen-admin-composer/dxy-screen-admin-composer.component';
import { DxyScreenAdminComponent } from './dxy-screen-admin/dxy-screen-admin.component';
import { AngularSplitModule } from 'angular-split';
import { DxyScreenAdminTemplateComponent } from './dxy-screen-admin-template/dxy-screen-admin-template.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
    imports: [SharedModule, AngularSplitModule],
    declarations: [
        DxyScreenAdminListComponent,
        DxyScreenAdminComposerComponent,
        DxyScreenAdminComponent,
        DxyScreenAdminTemplateComponent,
    ],
    exports: [DxyScreenAdminComponent],
})
export class DxyScreensModule {}

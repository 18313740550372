<ng-container *ngIf="hasEntities; else placeholder">
    <div
        *ngFor="let entityItem of diplayedEntities"
        class="row"
        (click)="onRowClick(entityItem)"
    >
        <app-entity-card-cell
            [hierarchicalData]="entityItem.HddData"
            [actions]="entityActions"
        ></app-entity-card-cell>
    </div>
    <dxy-show-more-button
        *ngIf="showMoreVisible"
        (click)="showMore()"
    ></dxy-show-more-button>
    <dxy-show-more-button
        *ngIf="showEverythingVisible"
        labelKey="UI.Global.filter.seeAll"
        (click)="showEverything()"
    ></dxy-show-more-button>
</ng-container>

<ng-template #placeholder>
    <div class="dxy-placeholder">
        <img
            class="dxy-placeholder-img"
            src="/images/placeholders/common/no-objects.svg"
        />
        <span class="dxy-placeholder-text" translate>
            UI.SpaceHome.Widgets.SpaceFilteredViewObjects.noObjectsPlaceholder
        </span>
    </div>
</ng-template>

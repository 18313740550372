import { Component } from '@angular/core';
import { BaseCellComponent } from '@datagalaxy/core-ui/cell-components';
import {
    IGotoWithHierarchicalData,
    NavigationService,
} from '../../../../services/navigation.service';
import { IEntitySimpleLinkCell } from './entity-simple-link-cell.types';
import { ViewTypeService } from '../../../../services/viewType.service';
import { IHasHddData, IHierarchicalData } from '@datagalaxy/dg-object-model';
import { HierarchyDataDescriptor } from '@datagalaxy/dg-object-model';

@Component({
    selector: 'app-entity-simple-link-cell',
    templateUrl: './entity-simple-link-cell.component.html',
})
export class EntitySimpleLinkCellComponent extends BaseCellComponent<
    IHasHddData,
    unknown,
    IEntitySimpleLinkCell
> {
    public text: string;
    public get hierarchicalData(): IHierarchicalData {
        return this.data.HddData;
    }

    constructor(
        private navigationService: NavigationService,
        private viewTypeService: ViewTypeService
    ) {
        super();
    }

    protected override updateBindings() {
        this.initData();
    }

    public async onClickLink() {
        const opt: IGotoWithHierarchicalData = {
            isFromHierarchicalView: this.params.isFromHierarchical,
            withEntityFullPage: this.params.withEntityFullPage,
            broadcastStateChangeSuccess: true,
        };
        await this.navigationService.goToWithHierarchicalData(
            this.hierarchicalData,
            opt
        );
    }

    private initData() {
        const propertyKey = this.params.propertyKey;
        const hdData = this.hierarchicalData.Data;
        this.text =
            propertyKey && hdData
                ? hdData[propertyKey]
                : this.getTechnicalOrDisplayName(hdData);
    }

    private getTechnicalOrDisplayName(hdData: HierarchyDataDescriptor) {
        return this.viewTypeService.getTechnicalOrDisplayName(hdData);
    }
}

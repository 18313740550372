<ng-container *ngIf="module">
    <div *ngIf="thumbnailVisible" class="dxy-thumbnail">
        <i [ngClass]="module.coloredGlyphClass"></i>
        <span translate>Entity.EntityType.ShortName.FilteredView</span>
    </div>
    <i *ngIf="iconVisible" [ngClass]="module.coloredGlyphClass"></i>
</ng-container>
<div class="display-name" dxyEllipsisTooltip>
    {{ filteredView?.DisplayName }}
</div>

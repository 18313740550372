<dxy-tabs-header class="stretch-tabs" [tabs]="headerTabs">
    <div class="header">
        <div class="link-filter">
            <mat-slide-toggle
                color="primary"
                [checked]="hideNoLinkItems"
                [disableRipple]="true"
                (change)="onHideNoLinkItemsChange($event.checked)"
            >
                <span translate>
                    UI.ImpactAnalysis.lineage.itemPicker.linkedFilter
                </span>
            </mat-slide-toggle>
        </div>
        <div class="search">
            <dxy-search-input
                class="no-border-top"
                (onSearchString)="onSearchTermChange($event.searchString)"
            ></dxy-search-input>
        </div>
    </div>
    <cdk-virtual-scroll-viewport itemSize="52">
        <ng-container *cdkVirtualFor="let node of nodes">
            <app-lineage-item-picker-item
                [node]="node"
                [baseDepth]="baseDepth"
                [actions]="actions"
                [open]="isOpen(node)"
                (toggle)="onToggle(node)"
            ></app-lineage-item-picker-item>
        </ng-container>
    </cdk-virtual-scroll-viewport>
</dxy-tabs-header>

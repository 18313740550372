import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
} from '@angular/core';
import {
    LineageNodeStream,
    LineageNodeStreamDirection,
    LineageNodeStreamStatus,
} from '../lineage-entity-stream-buttons/lineage-entity-stream.types';
import { LineageStreamExplorerButtonComponent } from './lineage-stream-explorer-button/lineage-stream-explorer-button.component';
import { NgClass } from '@angular/common';
import { LineageStreamAction } from './lineage-stream-explorer.types';

@Component({
    standalone: true,
    selector: 'app-lineage-stream-explorer',
    templateUrl: './lineage-stream-explorer.component.html',
    imports: [LineageStreamExplorerButtonComponent, NgClass],
})
export class LineageStreamExplorerComponent implements OnChanges {
    @Input() stream: LineageNodeStream;

    @Output() select = new EventEmitter<LineageStreamAction>();

    protected actions: LineageStreamAction[];

    protected get isReversed() {
        return this.stream.type === LineageNodeStreamDirection.Upstream;
    }

    constructor() {}

    ngOnChanges() {
        this.actions = this.buildActions(this.stream);
    }

    protected buildActions(stream: LineageNodeStream): LineageStreamAction[] {
        const isAdd = stream.status === LineageNodeStreamStatus.Collapsed;
        const isUpstream = stream.type === LineageNodeStreamDirection.Upstream;

        const actions = [
            {
                glyphClass: isAdd ? 'glyph-add' : 'glyph-minus',
                id: '1',
                tooltipKey: this.streamTooltipKey(stream),
                tooltipData: {
                    level: 1,
                },
                data: {
                    level: 1,
                },
            },
        ];

        if (isAdd) {
            actions.push({
                glyphClass: isUpstream
                    ? 'glyph-caret-single-left'
                    : 'glyph-caret-single-right',
                tooltipKey: this.streamTooltipKey(stream),
                tooltipData: {
                    level: 3,
                },
                id: '2',
                data: {
                    level: 3,
                },
            });

            actions.push({
                glyphClass: isUpstream
                    ? 'glyph-caret-double-left'
                    : 'glyph-caret-double-right',
                tooltipKey: this.streamTooltipKey(stream),
                tooltipData: {
                    level: 5,
                },
                id: '3',
                data: {
                    level: 5,
                },
            });
        }

        return actions;
    }

    protected streamTooltipKey(stream: LineageNodeStream) {
        const virtualPrefix = stream.virtual ? 'virtual' : '';
        let statusSuffix = 'secondary';

        if (stream.status === LineageNodeStreamStatus.Expanded) {
            statusSuffix = 'removable';
        } else if (stream.isPrincipal) {
            statusSuffix = 'principal';
        }
        return `UI.ImpactAnalysis.lineage.streamButton.${virtualPrefix}${statusSuffix}`;
    }
}

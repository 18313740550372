import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreUiModule } from '@datagalaxy/core-ui';
import { ModuleFilterButtonComponent } from './module-filter-button/module-filter-button.component';
import { DxyFiltersModule } from '@datagalaxy/core-ui';
import { FilterCarouselComponent } from './filter-carousel/filter-carousel.component';
import { DxyButtonsModule } from '@datagalaxy/ui/buttons';
import { FilterCarouselItemDirective } from './filter-carousel/filter-carousel-item.directive';
import { DxyFieldsModule } from '@datagalaxy/core-ui/fields';
import { FilteredViewCardCellComponent } from './filtered-view-card-cell/filtered-view-card-cell.component';
import { UserFilterButtonComponent } from './user-filter-button/user-filter-button.component';
import { UserCellComponent } from '@datagalaxy/webclient/user/ui';
import { ActivityTypeFilterButtonComponent } from './activity-type-filter-button/activity-type-filter-button.component';

@NgModule({
    imports: [
        CommonModule,
        DxyButtonsModule,
        DxyFiltersModule,
        CoreUiModule,
        DxyFieldsModule,
        UserCellComponent,
    ],
    declarations: [
        ModuleFilterButtonComponent,
        FilterCarouselComponent,
        FilterCarouselItemDirective,
        FilteredViewCardCellComponent,
        UserFilterButtonComponent,
        ActivityTypeFilterButtonComponent,
    ],
    exports: [
        FilterCarouselComponent,
        FilterCarouselItemDirective,
        FilteredViewCardCellComponent,
        UserFilterButtonComponent,
        ActivityTypeFilterButtonComponent,
        ModuleFilterButtonComponent,
    ],
})
export class FilterModule {}

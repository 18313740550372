<div
    class="wrapper connection-source"
    [dxyRichTooltip]="dpiTooltipRef"
    [richTooltipDisabled]="richTooltipDisabled"
    richTooltipPosition="above"
>
    <div class="container-title">
        <div class="item-mini-obj">
            <div class="technical-dynamic-color glyph-processItem"></div>
            <div class="item-name">
                <div class="dg_dataProcessingItem-label">{{ getName() }}</div>
            </div>
        </div>
        <dxy-burger-menu-button
            class="connection-nodrag"
            [options]="menuOptions"
            [small]="true"
            (onLogFunctional)="onLogFunctional($event)"
        ></dxy-burger-menu-button>
    </div>
</div>
<ng-template #dpiTooltipRef>
    <div class="dp-item-element-rich-tooltip dg5-custom-tooltip">
        <div class="tooltip-title">
            <strong>{{ getName() }}</strong
            >&nbsp;-&nbsp;{{ linkedItem.dpiTypeTranslateKey | translate }}
        </div>
        <div *ngIf="linkedItem.Description" class="tooltip-description">
            {{ linkedItem.Description }}
        </div>
    </div>
</ng-template>

import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    NgZone,
    OnChanges,
    Optional,
    Output,
    QueryList,
    Self,
    SimpleChanges,
    ViewChildren,
} from '@angular/core';
import { IFieldSelectAdapter } from '@datagalaxy/core-ui';
import { DgModuleName } from '@datagalaxy/shared/dg-module/domain';
import { FilteredViewApiService } from '@datagalaxy/webclient/filter/data-access';
import { FilteredViewSelectorStateService } from './filtered-view-selector-state.service';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { DxyBaseValueAccessorComponent } from '@datagalaxy/ui/core';
import {
    FilteredViewDto,
    FilterModuleName,
} from '@datagalaxy/webclient/filter/domain';
import {
    FilterCarouselComponent,
    FilteredViewCardCellComponent,
} from '@datagalaxy/webclient/filter/ui';

/**
 * ## Role
 * Select a filtered view with filter and search capabilities
 */
@Component({
    selector: 'dxy-filtered-view-selector',
    templateUrl: './filtered-view-selector.component.html',
    providers: [FilteredViewSelectorStateService],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilteredViewSelectorComponent
    extends DxyBaseValueAccessorComponent<FilteredViewDto>
    implements OnChanges, ControlValueAccessor
{
    @Input() spaceId?: string;
    @Input() disabled = false;
    @Input() labelKey = 'DgServerTypes.ServerTypeName.FilteredView';
    @Output() valueChange = new EventEmitter<FilteredViewDto>();

    @ViewChildren(FilterCarouselComponent)
    carousels?: QueryList<FilterCarouselComponent>;

    protected filteredViews: FilteredViewDto[] = [];
    protected filteredViewAdapter: IFieldSelectAdapter<FilteredViewDto> = {
        getText: (fv) => fv?.DisplayName,
        getId: (fv) => fv?.FilteredViewId.toString(),
        getRenderData: (fv) => ({
            renderer: FilteredViewCardCellComponent,
            param: {
                value: fv,
            },
        }),
    };

    protected filteredViews$ = this.stateService.selectFilteredViews();
    protected hasQuickFilters$ = this.stateService.selectHasQuickFilters();
    protected availableModuleNames$ =
        this.stateService.selectAvailableModuleNames();
    protected hasActiveModuleFilters$ =
        this.stateService.selectHasActiveModuleFilters();
    protected activeModuleFilters$ =
        this.stateService.selectActiveModuleFilters();

    constructor(
        private filteredViewApiService: FilteredViewApiService,
        private stateService: FilteredViewSelectorStateService,
        ngZone: NgZone,
        @Optional() @Self() ngControl: NgControl
    ) {
        super(ngControl, ngZone);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (!changes['spaceId']) {
            return;
        }
        this.getFilteredViews();
    }

    protected addModuleFilter(moduleName: DgModuleName) {
        this.stateService.updateFilters([moduleName]);
    }

    protected removeModuleFilters() {
        this.stateService.removeAllFilters();
    }

    protected onSelectionChange(fv: FilteredViewDto) {
        this.writeValue(fv);
    }

    protected onModelChange(value: FilteredViewDto) {
        this.valueChange.emit(value);
    }

    protected onOpenClose(opened: boolean) {
        if (opened) {
            this.refreshCarousels();
            return;
        }
        this.stateService.removeAllFilters();
    }

    protected onSelectedModulesChange(dgModuleNames: DgModuleName[]) {
        this.stateService.updateFilters(dgModuleNames);
    }

    private async getFilteredViews() {
        if (!this.spaceId) {
            return;
        }
        const filterModuleValues = Object.values(FilterModuleName).filter(
            (value) => typeof value === 'number'
        ) as FilterModuleName[];

        const spaceId = this.spaceId;
        const filteredViews =
            (await this.filteredViewApiService.getFilteredViews(
                filterModuleValues,
                { spaceId }
            )) ?? [];
        this.stateService.updateAvailableFilteredViews(
            filteredViews.filter((fv) => !fv.IsPrivate)
        );
    }

    private refreshCarousels() {
        this.carousels?.forEach((c) => c.ajustScrollAfterContentUpdate());
    }
}

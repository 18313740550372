import { EntityType } from '@datagalaxy/dg-object-model';

export class ExplorationGraphNode {
    public x: number;
    public y: number;
    public fixed: boolean;
    public isVisible: boolean;
    public isCluster: boolean;
    public isSelected: boolean;
    public isRoot: boolean;
    public isLoading: boolean;
    public nodeObjectReferenceId: string;
    public parentClusterNodeId: string;
    public childrenClusterNodesId: string[];

    public entityType: EntityType;

    public get id() {
        return this.nodeObjectReferenceId;
    }
    public get isDisplayed() {
        return !this.parentClusterNodeId && this.isVisible;
    }
    public get isClustered() {
        return this.isCluster || !!this.parentClusterNodeId;
    }

    constructor(referenceId: string) {
        this.nodeObjectReferenceId = referenceId;
        this.isCluster = false;
        this.isVisible = true;
        this.isRoot = false;
        this.isLoading = false;
        this.parentClusterNodeId = null;
        this.childrenClusterNodesId = [];
    }
}

import { Injectable } from '@angular/core';
import {
    EntityAttributeLinkGroup,
    EntityAttributeLinks,
} from '../../entity-links/entity-links.types';
import { IEntityIdentifier } from '@datagalaxy/dg-object-model';
import { BehaviorSubject, map, merge, Observable, Subject } from 'rxjs';
import { LineageGraphService } from '../lineage-graph/lineage-graph.service';
import {
    LineageNodeStream,
    LineageNodeStreamDirection,
    LineageNodeStreamStatus,
} from './lineage-entity-stream.types';
import { LineageTreeNode } from '../lineage-tree/lineage-tree-node';
import { CollectionsHelper } from '@datagalaxy/core-util';
import { LineageEntityStreamUtils } from './lineage-entity-stream.utils';
import { filter } from 'rxjs/operators';
import { getContextId } from '@datagalaxy/webclient/utils';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';

export interface ExpandHistory {
    entityId: string;
    nodes: string[];
    direction?: LineageNodeStreamDirection;
}

@Injectable()
export class LineageEntityStreamService {
    private addedMap = new Map<
        string,
        Map<LineageNodeStreamDirection, string[]>
    >();

    private nodeExpanded = new Subject<LineageTreeNode>();

    private expandHistory$ = new BehaviorSubject<ExpandHistory[]>([]);

    public get nodeExpanded$() {
        return this.nodeExpanded.asObservable();
    }

    private get expandHistory() {
        return this.expandHistory$.value;
    }

    constructor(private lineageGraphService: LineageGraphService) {}

    public selectEntityStreamInfos(
        entityIdr: IEntityIdentifier
    ): Observable<LineageNodeStream[]> {
        return this.selectNodeLinkChange(entityIdr).pipe(
            map(() => this.getEntityStreams(entityIdr))
        );
    }

    /**
     * Returns the summup stream information for the hidden children of the entity
     * @param entityIdr
     */
    public selectEntityHiddenChildrenStreamInfos(
        entityIdr: IEntityIdentifier
    ): Observable<LineageNodeStream[]> {
        return this.selectNodeLinkChange(entityIdr).pipe(
            map(() => this.getEntityHiddenStreams(entityIdr))
        );
    }

    public selectNodeLinkChange(
        entityIdr: IEntityIdentifier
    ): Observable<LineageTreeNode> {
        const { added$, updated$, removed$ } = this.lineageGraphService;
        const added = added$.pipe(
            filter((event) => {
                const node = this.lineageGraphService.getNode(
                    entityIdr.ReferenceId
                );
                const ids = node.opened
                    ? [node.id]
                    : [node.id, ...node.getAllChildren().map((n) => n.id)];

                return event?.edges.some(
                    (edge) =>
                        ids.includes(edge.source) || ids.includes(edge.target)
                );
            })
        );
        const updated = updated$.pipe(
            filter((event) =>
                event.nodes.some((node) => node.id === entityIdr.ReferenceId)
            )
        );

        const removed = removed$.pipe(
            map((nodes) =>
                nodes.flatMap((node) =>
                    node.links.flatMap((links) => links.entityIds)
                )
            )
        );

        const historyChange = this.expandHistory$.pipe(
            filter((history) =>
                history.some((item) => item.entityId === entityIdr.ReferenceId)
            )
        );

        return merge(added, updated, removed, historyChange).pipe(
            map(() => this.lineageGraphService.getNode(entityIdr.ReferenceId)),
            filter((node) => !!node)
        );
    }

    public hasGoldenLinks(entityIdr: IEntityIdentifier) {
        const res = this.lineageGraphService.getNode(entityIdr.ReferenceId);
        const spaceId = getContextId(entityIdr.ReferenceId);

        const fn = (node: LineageTreeNode): boolean => {
            const goldenIds = node.links
                .flatMap((group) => group.goldenIds)
                .filter((id) => !!id);
            const res = goldenIds.some(
                (id) => !this.lineageGraphService.getNode(`${spaceId}:${id}`)
            );

            if (node.opened) {
                return res;
            }

            return res || node.children.some((child) => fn(child));
        };
        return fn(res);
    }

    public getEntityStream(
        entityIdr: IEntityIdentifier,
        direction: LineageNodeStreamDirection
    ) {
        const node = this.lineageGraphService.getNode(entityIdr.ReferenceId);

        if (!node) {
            return null;
        }
        return {
            type: direction,
            isChild: node.hasVisibleParent,
            status: this.getStatus(node, direction),
            isPrincipal: this.getIsPrincipal(node, direction),
            virtual: this.nodeHasVirtualStreams(node, direction),
        };
    }

    public async expandEntityStream(
        entityIdr: IEntityIdentifier,
        streamType: LineageNodeStreamDirection,
        level = 1,
        hidden?: boolean,
        isPrincipal?: boolean
    ) {
        const node = this.lineageGraphService.getNode(entityIdr.ReferenceId);

        const nodes = LineageEntityStreamUtils.getStreamNodes(
            node,
            hidden
        ).filter((node) => {
            if (isPrincipal == undefined || node.id === entityIdr.ReferenceId) {
                return true;
            }

            return (
                this.lineageGraphService.isPrimaryNode(node.id) === isPrincipal
            );
        });

        const fn = (node: LineageTreeNode): string[] => {
            const res = this.makeStreamLinkGroup(node, streamType);
            const entities = res
                .flatMap((group) => group.entityIds)
                .filter((item) => !this.lineageGraphService.getNode(item));

            const streamMap =
                this.addedMap.get(node.id) ||
                new Map<LineageNodeStreamDirection, string[]>();

            streamMap.set(streamType, entities);

            this.addedMap.set(node.id, streamMap);

            return entities;
        };

        const entities: string[] = nodes.flatMap((node) => fn(node));

        const res = await this.lineageGraphService.loadLineageNodes(
            CollectionsHelper.distinct(entities),
            streamType,
            level,
            isPrincipal
        );

        this.expandHistory$.next([
            ...this.expandHistory$.value,
            {
                entityId: entityIdr.ReferenceId,
                nodes: res.nodes.map((node) => node.id),
                direction: streamType,
            },
        ]);

        if (level === 1) {
            this.nodeExpanded.next(node);
        }
    }

    public collapseEntityStream(
        entityIdr: IEntityIdentifier,
        streamType: LineageNodeStreamDirection
    ) {
        const res = this.expandHistory[this.expandHistory.length - 1];

        if (
            !res ||
            res.entityId !== entityIdr.ReferenceId ||
            res.direction !== streamType
        ) {
            return;
        }

        this.expandHistory.pop();

        this.lineageGraphService.removeNodes(res.nodes);

        const node = this.lineageGraphService.getNode(entityIdr.ReferenceId);
        this.nodeExpanded.next(node);
    }

    private nodeHasVirtualStreams(
        node: LineageTreeNode,
        direction: LineageNodeStreamDirection
    ) {
        if (!node.closed) {
            return false;
        }

        return node
            .getAllChildren()
            .some(
                (child) =>
                    LineageEntityStreamUtils.makeStreamLinkGroup(
                        child.links,
                        direction
                    )?.length &&
                    this.getStatus(child, direction) ===
                        LineageNodeStreamStatus.Collapsed
            );
    }

    private getEntityStreams(
        entityIdr: IEntityIdentifier
    ): LineageNodeStream[] {
        const streamDirections = [
            LineageNodeStreamDirection.Upstream,
            LineageNodeStreamDirection.Downstream,
        ];

        return streamDirections
            .map((direction) => this.getEntityStream(entityIdr, direction))
            .filter(
                (stream) =>
                    !!stream && stream.status !== LineageNodeStreamStatus.Empty
            );
    }

    private getEntityHiddenStreams(entityIdr: IEntityIdentifier) {
        const streamDirections = [
            LineageNodeStreamDirection.Upstream,
            LineageNodeStreamDirection.Downstream,
        ];
        return streamDirections
            .map((direction) =>
                this.getEntityHiddenStream(entityIdr, direction)
            )
            .filter(
                (stream) =>
                    !!stream && stream.status !== LineageNodeStreamStatus.Empty
            );
    }

    private getEntityHiddenStream(
        entityIdr: IEntityIdentifier,
        direction: LineageNodeStreamDirection
    ) {
        const node = this.lineageGraphService.getNode(entityIdr.ReferenceId);

        if (!node || node.closed) {
            return null;
        }

        return {
            type: direction,
            isChild: false,
            status: this.getStatus(node, direction, true),
            isPrincipal: this.getIsPrincipal(node, direction, true),
            virtual: true,
            isRoot: node.isVisibleRoot(),
        };
    }

    private makeStreamLinkGroup(
        node: LineageTreeNode,
        direction: LineageNodeStreamDirection
    ): EntityAttributeLinkGroup[] {
        return LineageEntityStreamUtils.makeStreamLinkGroup(
            node.links,
            direction
        );
    }

    private getIsPrincipal(
        node: LineageTreeNode,
        direction: LineageNodeStreamDirection,
        hidden?: boolean
    ) {
        const nodes = LineageEntityStreamUtils.getStreamNodes(node, hidden);
        const linkedNodes = nodes.filter((n) => {
            const groups = this.makeStreamLinkGroup(n, direction);

            return (
                groups.length &&
                groups.some((link) => link.entityIds?.length) &&
                (this.getStatus(n, direction) ===
                    LineageNodeStreamStatus.Collapsed ||
                    n.id === node.id)
            );
        });
        const ids = linkedNodes.map((stream) => stream.entityIdentifier);

        if (
            direction === LineageNodeStreamDirection.Upstream ||
            direction === LineageNodeStreamDirection.TopStream
        ) {
            return this.lineageGraphService.areNodePredecessors(ids);
        } else if (
            direction === LineageNodeStreamDirection.Downstream ||
            direction === LineageNodeStreamDirection.BottomStream
        ) {
            return this.lineageGraphService.areNodeSuccessors(ids);
        }

        return false;
    }

    private getStatus(
        node: LineageTreeNode,
        direction: LineageNodeStreamDirection,
        hidden?: boolean
    ) {
        const nodes = LineageEntityStreamUtils.getStreamNodes(node, hidden);
        const entityAttributeLinks: EntityAttributeLinks[] = nodes
            .flatMap((node) => ({
                entity: node.entityIdentifier as EntityItem,
                groups: this.makeStreamLinkGroup(node, direction),
            }))
            .filter((item) => item.groups?.length);

        if (!entityAttributeLinks?.length) {
            return LineageNodeStreamStatus.Empty;
        }
        const edgeIds =
            LineageEntityStreamUtils.getEntityAttributeLinksListEdgeId(
                entityAttributeLinks
            );

        if (!edgeIds.length) {
            return null;
        }

        const hasEdges = this.lineageGraphService.hasEdges(edgeIds);

        if (!hasEdges) {
            return LineageNodeStreamStatus.Collapsed;
        }

        const isExpanded = this.isLastExpanded(node.id, direction);

        return isExpanded
            ? LineageNodeStreamStatus.Expanded
            : LineageNodeStreamStatus.Empty;
    }

    private isLastExpanded(
        entityId: string,
        direction: LineageNodeStreamDirection
    ) {
        const lastHistory = this.expandHistory[this.expandHistory.length - 1];
        return (
            lastHistory?.entityId === entityId &&
            lastHistory?.direction === direction
        );
    }
}

import { NgModule } from '@angular/core';
import { DxyFieldsModule } from '@datagalaxy/core-ui/fields';
import { DxySharedDialogsModule } from '../dialogs/DxySharedDialogsModule';
import { DxyPublicEditModalComponent } from './dxy-public-edit-modal/dxy-public-edit-modal.component';
import { SharedModule } from '../shared.module';
import { DxySharedUiModule } from '../shared-ui/DxySharedUiModule';

@NgModule({
    imports: [
        SharedModule,
        DxySharedDialogsModule,
        DxyFieldsModule,
        DxySharedUiModule,
    ],
    declarations: [DxyPublicEditModalComponent],
})
export class DxyPublicEditModalModule {}

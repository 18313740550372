import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { ITabItem } from '@datagalaxy/core-ui';
import { IDxyPreviewPaneContent } from '../../../shared/shared-ui/dxy-preview-panel-slider/dxy-preview-panel-slider.types';
import { PreviewPanelService } from '../../../shared/shared-ui/preview-panel.service';
import { UserCellComponent } from '@datagalaxy/webclient/user/ui';
import { TranslateService } from '@ngx-translate/core';
import { NavigationService } from '../../../services/navigation.service';
import { EmailUtil, StringUtil } from '@datagalaxy/core-util';
import { ISpaceMemberGroup } from '../space-members-widget/space-members.types';
import { TextAndToolsCellComponent } from '../../../shared/shared-ui/cells/text-and-tools-cell/text-and-tools-cell.component';
import { Space } from '@datagalaxy/webclient/workspace/data-access';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { UserDto } from '@datagalaxy/webclient/user/domain';
import { GridCellType, GridConfig, TColDef } from '@datagalaxy/ui/grid';

/**
 * ## Role
 * Display for a space a tab list for each user role.
 * Each tab contains a grid with its users
 */
@Component({
    selector: 'app-space-members-widget-preview',
    templateUrl: './space-members-widget-preview.component.html',
    styleUrls: ['./space-members-widget-preview.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpaceMembersWidgetPreviewComponent
    extends DxyBaseComponent
    implements IDxyPreviewPaneContent<void>, OnInit
{
    @Input() space: Space;
    @Input() spaceMembers: UserDto[];
    @Input() spaceMemberGroups: ISpaceMemberGroup[];
    @Input() selectedMemberGroup: ISpaceMemberGroup;

    @Output() readonly onReady = new EventEmitter();

    protected tabItems: ITabItem<UserDto[]>[];
    protected selectedTab: ITabItem<UserDto[]>;
    protected gridConfig: GridConfig<UserDto> = {
        getItemId: (item) => item.UserId,
    };
    protected cols: TColDef<UserDto>[] = [
        {
            id: 'UserId',
            headerLabel: this.translate.instant(
                'UI.SpaceHome.Widgets.SpaceMembers.Preview.UserName'
            ),
            type: GridCellType.custom,
            customCellComponent: UserCellComponent,
            getInputs: (user: UserDto) =>
                ({
                    userId: user.UserId,
                    actionData: user,
                    actions: [
                        {
                            glyphClass: 'glyph-star-fav',
                            hidden: (user: UserDto) =>
                                !this.selectedMemberGroup?.defaultUserIds.includes(
                                    user.UserId
                                ),
                            alwaysVisible: true,
                        },
                    ],
                } as Partial<UserCellComponent<UserDto>>),
            sortable: true,
            getSortableValue: (user: UserDto) => user.FullName,
        },
        {
            id: 'Email',
            headerLabel: this.translate.instant(
                'UI.SpaceHome.Widgets.SpaceMembers.Preview.Email'
            ),
            type: GridCellType.custom,
            customCellComponent: TextAndToolsCellComponent,
            getInputs: (user: UserDto) =>
                ({
                    text: user.Email,
                    actions: [
                        {
                            glyphClass: 'glyph-email',
                            tooltipTranslateKey:
                                'UI.SpaceHome.Widgets.SpaceMembers.Preview.SendEmailTT',
                            callback: (user: UserDto) =>
                                EmailUtil.mailTo(user.Email),
                        },
                    ],
                    actionsData: user,
                } as Partial<TextAndToolsCellComponent>),
            sortable: true,
            getSortableValue: (user: UserDto) => user.Email,
        },
    ];
    protected rows: UserDto[] = [];

    protected get spaceName() {
        return this.space.DisplayName;
    }
    protected get hasRights() {
        return this.space.SecurityData?.HasManagementAccess;
    }
    protected get showSearchInput() {
        return this.selectedTab?.data?.length > 7;
    }

    constructor(
        private previewPanelService: PreviewPanelService,
        private translate: TranslateService,
        private navigationService: NavigationService
    ) {
        super();
    }

    ngOnInit() {
        this.init();
        this.onReady.emit();
    }

    protected onCloseClick() {
        this.close();
    }

    protected async onGoToAdminRoles() {
        await this.navigationService.goToSpaceRolesAdmin(this.space);
        this.close();
    }

    protected onTabChange(tab: ITabItem<UserDto[]>) {
        this.selectedTab = tab;
        this.selectedMemberGroup = this.spaceMemberGroups?.find(
            (group) => group.roleKey === tab.tabId
        );
        this.refreshGrid();
    }

    protected onSearchTerm(searchTerm: string) {
        const filteredUsers = StringUtil.filterSearched(
            searchTerm,
            this.selectedTab.data,
            (o) => o.FullName
        );
        this.refreshGrid(filteredUsers);
    }

    private init() {
        const tabItems = this.spaceMemberGroups.map((group) =>
            this.makeGroupTab(group)
        );
        tabItems.unshift({
            tabId: 'all',
            tabTranslateKey: 'UI.Global.members',
            data: this.spaceMembers,
        });
        this.tabItems = tabItems;
        this.selectedTab =
            tabItems.find(
                (tab) => tab.tabId === this.selectedMemberGroup?.roleKey
            ) || tabItems[0];
        this.refreshGrid();
    }

    private refreshGrid(filteredUsers?: UserDto[]) {
        this.rows = filteredUsers ?? this.selectedTab.data;
    }

    private makeGroupTab(group: ISpaceMemberGroup): ITabItem<UserDto[]> {
        return {
            tabId: group.roleKey,
            tabTranslateKey: group.roleKey,
            data: group.users,
        };
    }

    private close() {
        this.previewPanelService.hidePanel();
    }
}

import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DxyBaseModalComponent } from '@datagalaxy/ui/dialog';
import { DomUtil } from '@datagalaxy/core-util';
import { ToasterService } from '../services/toaster.service';
import { UserService } from '../services/user.service';
import { PersonalAccessTokenResult } from '@datagalaxy/webclient/user/data-access';

@Component({
    selector: 'dxy-extension-modal',
    templateUrl: 'dxy-extension-modal.component.html',
    styleUrls: ['dxy-extension-modal.component.scss'],
})
export class DxyExtensionModalComponent
    extends DxyBaseModalComponent<void, void>
    implements OnInit
{
    protected extensionUrl =
        'https://chrome.google.com/webstore/detail/datagalaxy/bpppldnipnppkmongnkihlioaojpmine';
    protected personalAccessToken?: PersonalAccessTokenResult;
    protected get isPatGenerated() {
        return (
            !this.personalAccessToken?.RevocationTime &&
            !!(
                this.personalAccessToken?.PersonalAccessToken ||
                this.personalAccessToken?.CreationTime
            )
        );
    }

    constructor(
        private toasterService: ToasterService,
        private userService: UserService,
        dialogRef: MatDialogRef<DxyExtensionModalComponent>
    ) {
        super(dialogRef);
    }

    ngOnInit() {
        this.asyncInit();
    }

    private async asyncInit() {
        this.personalAccessToken =
            await this.userService.getUserPersonalAccessToken(
                this.userService.getCurrentUser().UserId
            );
    }

    public onClickDownload() {
        window.open(this.extensionUrl, '_blank');
    }

    public copyPat() {
        DomUtil.copyToClipboard(this.personalAccessToken.PersonalAccessToken);
        this.toasterService.successToast({
            messageKey: 'UI.extensionModal.taoster',
        });
    }

    public async generatePat() {
        this.personalAccessToken =
            await this.userService.regeneratePersonalAccessToken(
                this.isPatGenerated
            );
        this.copyPat();
    }
}

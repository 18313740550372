import { NgModule } from '@angular/core';
import { DxyAboutInfoModalComponent } from './dxy-about-info-modal/dxy-about-info-modal.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
    imports: [SharedModule],
    declarations: [DxyAboutInfoModalComponent],
    exports: [DxyAboutInfoModalComponent],
})
export class DxyAboutInfoModule {}

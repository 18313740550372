<div class="task-facet-filters">
    <div *ngIf="isLoading" class="dg5-spinner-container">
        <span class="dg5-spinner"></span>
    </div>
    <ng-container *ngIf="!isLoading">
        <ng-container *ngFor="let g of groups">
            <div *ngIf="g.count" class="filters-root-group">
                <div class="group-header">
                    <button
                        mat-icon-button
                        class="dg5-collapsible-caret"
                        [class.collapsed]="g.collapsed"
                        (click)="toggleGroupCollapsed(g)"
                    ></button>
                    <span
                        [class.active-filter-group]="g.hasAnyItemChecked"
                        (click)="toggleGroupCollapsed(g)"
                    >
                        {{ g.displayName }}
                    </span>
                    <button
                        *ngIf="g.hasAnyItemChecked"
                        mat-icon-button
                        (click)="groupClicked(g)"
                        dxyLogFunctional="MAIN_TASK_SEARCH,R"
                    >
                        <i class="glyph-filter-filled"></i>
                    </button>
                </div>
                <div class="group-body" [class.group--collapsed]="g.collapsed">
                    <div *ngFor="let sg of g.subGroups" class="sub-group">
                        <div
                            *ngIf="sg.isSubGroupHeaderVisible"
                            class="sub-group-header"
                        >
                            <label> {{ sg.displayName }} </label>
                            <span
                                *ngIf="sg.isSubGroupCountVisible"
                                class="search-count"
                            >
                                {{ sg.subGroupCount }}
                            </span>
                        </div>
                        <ol [class.subgrouped]="sg.isSubGroupHeaderVisible">
                            <ng-container *ngFor="let it of sg.items">
                                <li
                                    *ngIf="!it.isHidden"
                                    [class.active-filter]="it.isChecked"
                                >
                                    <button
                                        mat-button
                                        (click)="itemCheckChanged(it, sg)"
                                        dxyLogFunctional="MAIN_TASK_SEARCH,R"
                                    >
                                        {{ it.displayName }}
                                    </button>
                                    <span
                                        *ngIf="it.dataCount"
                                        class="search-count"
                                    >
                                        {{ it.dataCount }}
                                    </span>
                                </li>
                            </ng-container>
                        </ol>
                    </div>
                </div>
            </div>
        </ng-container>
    </ng-container>
</div>

<ng-container *ngIf="state$ | async as state; else spinner">
    <div>
        <div class="header" *ngIf="showHeader">
            <mat-checkbox
                name="cbSelectAll"
                [ngModel]="state.allSelected"
                (ngModelChange)="toggleSelection(state.assets, $event)"
                [indeterminate]="state.indeterminateSelection"
            >
                {{ 'UI.Diagrams.Assets.selectAll' | translate }}
            </mat-checkbox>
            <dxy-burger-menu-button
                [options]="state.burgerMenu"
            ></dxy-burger-menu-button>
        </div>
        <dxy-search-input
            *ngIf="state.showSearchBar"
            class="no-border height-50"
            placeholderKey="UI.Global.filter.filterByName"
            (onSearchString)="searchBoxTerm$.next($event.searchString)"
        ></dxy-search-input>
    </div>
    <div class="assets">
        <ng-container *ngFor="let asset of state.assets; trackBy: assetTrackBy">
            <app-asset-onstage-item
                [disabledSelection]="disabledSelection"
                [asset]="asset"
                (select)="select.emit($event)"
                (unselect)="unselect.emit($event)"
            ></app-asset-onstage-item>
        </ng-container>
    </div>
</ng-container>
<ng-template #spinner>
    <dxy-spinner class="xl absolute-centered"></dxy-spinner>
</ng-template>

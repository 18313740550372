export enum DataProcessingLinkEntityType {
    Table = 1,
    Column = 2,
    Model = 3,
    Container = 4,
}
export enum DataProcessingLinkDirection {
    In = 1,
    Out = 2,
    InOut = 3,
}

<div class="hcontainerDiv dg_u-pos-rel">
    <div class="vcontainerDiv flex-grow">
        <app-lineage-graph
            *ngIf="isActiveToolLineage"
            [graphParams]="lineageGraphParams"
        ></app-lineage-graph>

        <app-exploratory-graph
            *ngIf="isActiveToolExplorer"
            [graphData]="explorerGraphData"
            [enableNavigation]="true"
        ></app-exploratory-graph>
    </div>
</div>

<dxy-graphical-controls
    [events]="toolBarEvents"
    [noZoom]="!hasLineageData"
    [fullScreenElement]="elementRef"
    (functional)="onLogFunctional($event)"
    [zoom]="zoom"
    [class.loading]="isLoadingSourceData"
    [actionButtons]="actionButtons"
    [class.debug]="!!debug"
>
    <dxy-burger-menu
        [options]="burgerMenuOptions"
        [data]="optionData"
        [isStandalone]="true"
        logId="lineage-graph"
    ></dxy-burger-menu>
    <div class="d3div">
        <div class="debug-container"></div>
    </div>
</dxy-graphical-controls>
<dxy-spinner
    *ngIf="isLoadingSourceData"
    class="xl absolute-centered"
></dxy-spinner>

import { BaseLinkDataInfo, ObjectLinkType } from '@datagalaxy/dg-object-model';
import { ExplorationGraphNode } from './ExplorationGraphNode';

export class ExplorationGraphLink {
    /** other link that has same source and target */
    public parallel?: ExplorationGraphLink;

    public get id() {
        const src = this.source.id,
            tgt = this.target.id;
        return src < tgt ? `${src}_${tgt}` : `${tgt}_${src}`;
    }
    public get isVisible() {
        return this.source.isVisible && this.target.isVisible;
    }
    public get isDisplayed() {
        return this.source.isDisplayed && this.target.isDisplayed;
    }
    public get centerX() {
        return (this.target.x + this.source.x) / 2;
    }
    public get centerY() {
        return (this.target.y + this.source.y) / 2;
    }

    public get hasMarker() {
        return (
            !!this.isEntityLink ||
            this.sourceLinkInfo?.ObjectLinkType ==
                ObjectLinkType.IsChildOfTableColumnCatalogException ||
            this.sourceLinkInfo?.ObjectLinkType ==
                ObjectLinkType.IsParentOfTableColumnCatalogException
        );
    }

    constructor(
        public sourceLinkInfo: BaseLinkDataInfo,
        public source: ExplorationGraphNode,
        public target: ExplorationGraphNode,
        public linkTypeName: string,
        public linkObjectReferenceId: string,
        public isReverse: boolean,
        public isEntityLink: boolean,
        public objectLinkTypeName: string,
        public isGoldenLink: boolean
    ) {}
}

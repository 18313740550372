import { Location } from '@angular/common';
import {
    HttpContext,
    HttpContextToken,
    HttpHeaders,
    HttpResponse,
} from '@angular/common/http';
import { lastValueFrom, Observable } from 'rxjs';
import { RestHttpClient } from './rest-http-client.service';
import { buildUrlSearchParams } from './rest-url-search-params-builder';

export const IS_REST_API = new HttpContextToken<boolean>(() => false);

type QueryParamsRecord = Record<string, string | string[]>;
type RequestOptions = Partial<{
    headers: HttpHeaders;
    context: HttpContext;
}>;

export class RestApiService {
    constructor(
        private httpClient: RestHttpClient,
        public baseUrl: string,
        public apiPrefix: string
    ) {}

    private get apiUrl() {
        return Location.joinWithSlash(this.baseUrl, this.apiPrefix);
    }

    private get options(): RequestOptions {
        return {
            context: new HttpContext().set(IS_REST_API, true),
        };
    }

    public url(path: string, params?: QueryParamsRecord) {
        const urlParams =
            params === undefined ? '' : `?${buildUrlSearchParams(params)}`;
        return `${Location.joinWithSlash(this.apiUrl, path)}${urlParams}`;
    }

    public get<TResult>(path: string, params?: QueryParamsRecord) {
        return this.httpClient.get<TResult>(
            this.url(path, params),
            this.options
        );
    }

    public download(
        path: string,
        params?: QueryParamsRecord
    ): Observable<HttpResponse<ArrayBuffer>> {
        return this.httpClient.get(this.url(path, params), {
            ...this.options,
            observe: 'response',
            responseType: 'arraybuffer',
        });
    }

    public getPromise<TResult>(path: string, params?: QueryParamsRecord) {
        return lastValueFrom(this.get<TResult>(path, params));
    }

    public downloadPromise(
        path: string,
        params?: QueryParamsRecord
    ): Promise<HttpResponse<ArrayBuffer>> {
        return lastValueFrom(this.download(path, params));
    }

    public post<TResult = void, TBody = unknown>(
        path: string,
        body?: TBody,
        params?: QueryParamsRecord
    ) {
        return this.httpClient.post<TResult>(
            this.url(path, params),
            body,
            this.options
        );
    }

    public postPromise<TResult = void, TBody = unknown>(
        path: string,
        body?: TBody,
        params?: QueryParamsRecord
    ) {
        return lastValueFrom(this.post<TResult, TBody>(path, body, params));
    }

    public put<TResult, TBody = unknown>(
        path: string,
        body?: TBody,
        params?: QueryParamsRecord,
        options?: RequestOptions
    ) {
        const mergedOptions = {
            ...this.options,
            ...(options ?? {}),
        };
        return this.httpClient.put<TResult>(
            this.url(path, params),
            body,
            mergedOptions
        );
    }

    public putPromise<TResult = void, TBody = unknown>(
        path: string,
        body?: TBody,
        params?: QueryParamsRecord,
        options?: RequestOptions
    ) {
        return lastValueFrom(
            this.put<TResult, TBody>(path, body, params, options)
        );
    }

    public delete(path: string, params?: QueryParamsRecord) {
        return this.httpClient.delete(this.url(path, params), this.options);
    }

    public deletePromise(path: string, params?: QueryParamsRecord) {
        return lastValueFrom(this.delete(path, params));
    }

    public patch<TResult = void, TBody = unknown>(
        path: string,
        body?: TBody,
        params?: QueryParamsRecord
    ) {
        return this.httpClient.patch<TResult>(
            this.url(path, params),
            body,
            this.options
        );
    }

    public patchPromise<TResult = void, TBody = unknown>(
        path: string,
        body?: TBody,
        params?: QueryParamsRecord
    ) {
        return lastValueFrom(this.patch<TResult>(path, body, params));
    }
}

import { Directive, HostBinding, Input } from '@angular/core';
import { ButtonColor } from '../button-color';
import { ButtonSize } from '../button-size';

/**
 * ## Role
 * Add DataGalaxy CSS classes to an icon button based on inputs values
 */
@Directive({
    selector: '[dxyIconButton]',
})
export class DxyIconButtonDirective {
    /**
     * The size that the button takes in height
     */
    @Input() size: ButtonSize = 'large';

    /**
     * Color of the button
     */
    @Input() color: ButtonColor = 'primary';

    @HostBinding('class') get classes() {
        return `dxy-icon-button dxy-icon-button--${this.color} dxy-icon-button--${this.size}`;
    }
}

<div class="group-header">
    <span
        class="group-header-item"
        [ngClass]="groupHeaderItemClass"
        [class.reversed]="!isCaretBeforeText"
        (click)="toggleExpanded()"
    >
        <button
            mat-icon-button
            type="button"
            class="dg5-collapsible-caret"
            [class.collapsed]="!expanded"
        ></button>
        <span class="name" role="button">
            {{ text }}
        </span>
    </span>
</div>

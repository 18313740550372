import { Component, Inject } from '@angular/core';
import {
    IGlossaryLinksGenerationForm,
    IGlossaryLinksGenerationInput,
    IGlossaryLinksGenerationOutput,
} from './glossary-links-generation-modal.types';
import { UserService } from '../../services/user.service';
import {
    FormBuilder,
    FormControl,
    FormGroup,
    FormsModule,
    ReactiveFormsModule,
    Validators,
} from '@angular/forms';
import { GlyphUtil } from '../../shared/util/GlyphUtil';
import { DataUtil } from '../../shared/util/DataUtil';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { IEntitySelectorData } from '../../shared/entitySelector/entity-selector.types';
import { EntityType } from '@datagalaxy/dg-object-model';
import { withLoading } from '@datagalaxy/core-ui';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { CommonModule } from '@angular/common';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { DxyEntitySelectorModule } from '../../shared/entitySelector/DxyEntitySelectorModule';
import { ToasterService } from '../../services/toaster.service';
import {
    MAT_DIALOG_DATA,
    MatDialogModule,
    MatDialogRef,
} from '@angular/material/dialog';
import { SuggestionService } from '../../suggestions/suggestion.service';
import { DxySupportModule } from '../../shared/support/DxySupportModule';
import { DxyButtonsModule } from '@datagalaxy/ui/buttons';
import { DxyBaseModalComponent } from '@datagalaxy/ui/dialog';
import {
    CrudOperation,
    FunctionalLogService,
} from '@datagalaxy/webclient/monitoring/data-access';
import { DgModule } from '@datagalaxy/shared/dg-module/domain';

/**
 * ## Role
 * Display a guide explaining how link suggestions works and what it is. This
 * guide can be skipped next time.
 *
 * Then display a form to generate link suggestions for a source coming
 * from the modal context. The user can choose one or many targets to generate
 * links between the source and the target
 *
 * Note:
 * - The source can be Glossary, Catalog or Usage
 * - The source can be a specific entity from one of the previous modules
 */
@Component({
    standalone: true,
    selector: 'app-glossary-links-generation-modal',
    templateUrl: 'glossary-links-generation-modal.component.html',
    styleUrls: ['glossary-links-generation-modal.component.scss'],
    imports: [
        MatCheckboxModule,
        DxyEntitySelectorModule,
        MatButtonModule,
        ReactiveFormsModule,
        FormsModule,
        TranslateModule,
        CommonModule,
        MatDialogModule,
        DxySupportModule,
        DxyButtonsModule,
    ],
})
export class GlossaryLinksGenerationModalComponent extends DxyBaseModalComponent<
    IGlossaryLinksGenerationInput,
    IGlossaryLinksGenerationOutput
> {
    protected readonly LinkSuggestionHelpdeskArticle = '35000244464';
    protected showHelp: boolean;
    protected skipThisStep: boolean;
    protected formGroup: FormGroup<IGlossaryLinksGenerationForm>;
    protected targetsSelectOptions: IEntitySelectorData;

    protected get helpImage() {
        const moduleName = DgModule[this.data.sourceModule]?.toLowerCase();
        return `/images/glossary-links-generation/glossary-links-generation-help-${moduleName}.svg`;
    }

    protected get sourceGlyphClass() {
        return this.sourceEntity
            ? GlyphUtil.getEntityTypeColoredGlyphClass(
                  this.sourceEntity.EntityType
              )
            : GlyphUtil.getModuleColoredGlyphClass(this.data.sourceModule);
    }

    protected get sourceName() {
        return this.sourceEntity
            ? this.sourceEntity.DisplayName
            : this.translate.instant(
                  DataUtil.getModuleTranslateKey(this.data.sourceModule)
              );
    }

    private get sourceEntity() {
        return this.data.sourceEntity;
    }

    constructor(
        @Inject(MAT_DIALOG_DATA) data: IGlossaryLinksGenerationInput,
        dialogRef: MatDialogRef<GlossaryLinksGenerationModalComponent>,
        private userService: UserService,
        private fb: FormBuilder,
        private translate: TranslateService,
        private suggestionService: SuggestionService,
        private toasterService: ToasterService,
        private functionalLogService: FunctionalLogService
    ) {
        super(dialogRef, data);
        this.skipThisStep = this.data.skipHelp;
        this.showHelp = !this.data.skipHelp;
        this.formGroup = this.buildForm();
        this.targetsSelectOptions = this.getTargetsSelectOptions();
    }

    protected async goNextStep() {
        this.functionalLogService.logFunctionalAction(
            'START_SUGGESTION_LINKS',
            CrudOperation.R
        );

        this.showHelp = false;
        if (this.skipThisStep === this.data.skipHelp) {
            return;
        }
        await this.userService.setUserSettingValue(
            'glossary-links-generation',
            'skip-help',
            this.skipThisStep ? 'true' : 'false'
        );
    }

    protected goPreviousStep() {
        this.showHelp = true;
    }

    @withLoading()
    protected async onSubmit() {
        const { targets } = this.formGroup.value;
        const targetsIds = targets?.map((target) => target.ReferenceId);

        await this.generateLinks(targetsIds);

        this.toasterService.infoToast({
            titleKey:
                'UI.Glossary.components.glossaryLinksGenerationModal.successToaster.title',
            messageKey:
                'UI.Glossary.components.glossaryLinksGenerationModal.successToaster.message',
        });

        this.onCloseSubmit();
    }

    private async generateLinks(targetIds: string[]) {
        this.functionalLogService.logFunctionalAction(
            'SUGGEST_ALL_LINKS',
            CrudOperation.R
        );
        if (this.data.isGenerationForModule) {
            return this.suggestionService.generateSuggestionLinksFromModule(
                this.data.spaceIdr.spaceId,
                this.data.spaceIdr.versionId,
                this.data.sourceModule,
                targetIds
            );
        }
        return this.suggestionService.generateSuggestionLinksFromEntity(
            this.data.spaceIdr.spaceId,
            this.data.spaceIdr.versionId,
            this.data.sourceEntity.ReferenceId,
            targetIds
        );
    }

    private buildForm() {
        return this.fb.group({
            targets: new FormControl(null, Validators.required),
        });
    }

    private getTargetsSelectOptions() {
        const spaceIdr = this.data.spaceIdr;
        const sourceModule = this.data.sourceModule;
        const glossaryLinkTargets = [
            EntityType.RelationalModel,
            EntityType.NonRelationalModel,
            EntityType.NoSqlModel,
            EntityType.TagBase,
            EntityType.Application,
        ];

        return {
            spaceIdr,
            includedEntityTypes:
                sourceModule === DgModule.Glossary
                    ? glossaryLinkTargets
                    : [EntityType.Universe],
        };
    }
}

import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { EntityDksNode } from '../../../data-knowledge-studio.types';
import { IActionOption } from '@datagalaxy/core-ui';
import { EntityNodeTree } from '../entity-node.types';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';

@Component({
    selector: 'app-entity-node-tree',
    styleUrls: ['./entity-node-tree.component.scss'],
    templateUrl: './entity-node-tree.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EntityNodeTreeComponent {
    @Input() node: EntityDksNode;
    @Input() actions?: IActionOption<Partial<EntityItem>>[];

    @Output() collapse = new EventEmitter<EntityNodeTree>();
    @Output() showMore = new EventEmitter<string>();

    protected get isFlat() {
        return !this.node.children.some((child) => child.children.length);
    }

    protected get showMoreOption() {
        return (
            this.node.childrenCount &&
            this.node.children?.length < this.node.childrenCount
        );
    }

    protected onShowMoreClick() {
        this.showMore.emit(this.node.id);
    }
}

<div *ngIf="displayMode === 'default'" class="widget-title">
    {{ widget.titleKey | translate }}
</div>

<div class="preview-card-wrapper" [ngClass]="displayMode">
    <div class="preview-card" [dxyDraggableWidget]="widget">
        <h3>{{ previewHeaderKey | translate }}</h3>
        <img alt="widget preview" [src]="widget.previewImgUrl" />
    </div>
</div>

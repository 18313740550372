import { NgModule } from '@angular/core';
import { DpItemElementComponent } from './mapping/dp-item-element/dp-item-element.component';
import { DpiEditModalComponent } from './dpi-edit-modal/dpi-edit-modal.component';
import { DpMappingItemComponent } from './mapping/dp-mapping-item/dp-mapping-item.component';
import { DpImplemElementComponent } from './mapping/dp-implem-element/dp-implem-element.component';
import { DpMappingComponent } from './mapping/dp-mapping/dp-mapping.component';
import { UIRouterModule } from '@uirouter/angular';
import {
    dataProcessingRouterInit,
    getStatesForProcessing,
} from './data-processing.routes';
import { DpItemLinkMenuComponent } from './mapping/dp-item-link-menu/dp-item-link-menu.component';
import { AngularSplitModule } from 'angular-split';
import { SharedModule } from '../shared/shared.module';
import { FieldsModule } from '../shared/fields/fields.module';
import { DxySharedUiModule } from '../shared/shared-ui/DxySharedUiModule';
import { EntityCardModule } from '../shared/entityCard/entity-card.module';
import { DxySharedEntityModule } from '../shared/entity/DxySharedEntityModule';
import { DirectivesModule } from '../directives/directives.module';

@NgModule({
    imports: [
        UIRouterModule.forChild({
            states: getStatesForProcessing(),
            config: (uiRouter) =>
                dataProcessingRouterInit(uiRouter.transitionService),
        }),
        SharedModule,
        AngularSplitModule,
        FieldsModule,
        DxySharedUiModule,
        EntityCardModule,
        DxySharedEntityModule,
        DirectivesModule,
    ],
    declarations: [
        DpiEditModalComponent,
        DpMappingComponent,
        DpItemElementComponent,
        DpImplemElementComponent,
        DpMappingItemComponent,
        DpItemLinkMenuComponent,
    ],
})
export class DataProcessingModule {}

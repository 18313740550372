<div
    [class.animate-hidden]="!isFiltersBarVisible"
    class="search-filters-container"
>
    <dxy-search-filters-list
        *ngIf="currentSearch"
        [currentSearch]="currentSearch"
        (onChangeSearch)="onFiltersListChanged()"
        (onSpaceVersionSelected)="onSpaceVersionSelected($event)"
    ></dxy-search-filters-list>
    <button
        mat-icon-button
        (click)="onCloseFiltersBarClick()"
        [matTooltip]="
            'UI.NavBar.filteredViews.closeFiltersBarTooltip' | translate
        "
    >
        <i class="glyph-cancelsearch"></i>
    </button>
</div>
<as-split
    *ngIf="!showNoResultsMessage"
    class="search-results-container"
    direction="horizontal"
    unit="pixel"
    [gutterStep]="10"
>
    <as-split-area
        class="search-facet-filters-wrapper"
        [maxSize]="700"
        [minSize]="230"
        [size]="300"
        [order]="1"
        [visible]="isFacetsVisible"
        [class.is-loading]="isLoading"
    >
        <dxy-search-facet-filters
            class="search-facet-filters"
            [groups]="facetSections"
            (onFilterChange)="onFacetCheckChanged($event)"
            (onSettingsChange)="onFacetSettingsChanged()"
            (onClose)="onToggleFacets(false)"
        ></dxy-search-facet-filters>
        <dxy-spinner
            *ngIf="isResultSpinnerVisible"
            class="xl absolute-centered"
        ></dxy-spinner>
    </as-split-area>
    <as-split-area
        class="search-results-grid-container"
        [ngClass]="{ 'is-loading': isLoading }"
        [order]="2"
    >
        <dxy-entity-grid
            *ngIf="showResultsGrid"
            class="entity-grid"
            [egOptions]="egOptions"
            [spaceIdr]="spaceIdr"
            [logId]="logId"
            dtContext="Search Results"
        >
            <div class="entity-grid-header" header>
                <div class="max-count-container">
                    <div
                        *ngIf="showMaxCountDropdown"
                        class="max-count-dropdown"
                    >
                        <div [matMenuTriggerFor]="menu" class="max-count-btn">
                            {{ objectCountMessage }}
                            <button
                                mat-icon-button
                                class="dg5-small-icon-button"
                            >
                                <i class="glyph-arrow-drop-down"></i>
                            </button>
                        </div>
                        <span *ngIf="totalCount">/ {{ totalCount }}</span>
                        <mat-menu
                            #menu="matMenu"
                            class="dg5-mat-menu no-padding"
                        >
                            <dxy-option-list
                                class="menu-items"
                                [options]="resultMaxCountOptions"
                                [dropdownMenuSelected]="maxCount"
                            ></dxy-option-list>
                        </mat-menu>
                    </div>
                    <div *ngIf="!showMaxCountDropdown">
                        {{ objectCountMessage }}
                    </div>
                </div>
                <button
                    *ngIf="showRefreshAction"
                    mat-button
                    (click)="onClickRefresh()"
                >
                    <i class="glyph-reload"></i>
                    <span translate>UI.Search.refresh</span>
                </button>
            </div>
        </dxy-entity-grid>
        <dxy-spinner
            *ngIf="isResultSpinnerVisible"
            class="xl absolute-centered"
        ></dxy-spinner>
    </as-split-area>
</as-split>
<!-- no results message -->
<div *ngIf="showNoResultsMessage" class="dg_welcome-search">
    <div class="dg_label-search">
        <div>
            <img
                src="/images/placeholders/search/empty-search.svg"
                class="dg_search-image"
            />
        </div>
        <div class="dg_label-search-firstrow" translate>
            UI.Search.noResults
        </div>
    </div>
</div>

import { NgModule } from '@angular/core';
import { DxyVersioningComparatorComponent } from './dxy-version-comparator/dxy-version-comparator.component';
import { DxyVersioningComparatorItemComponent } from './dxy-versioning-comparator-item/dxy-versioning-comparator-item.component';
import { DxyVersioningComparatorSectionComponent } from './dxy-versioning-comparator-section/dxy-versioning-comparator-section.component';
import { DxyVersioningComparatorSingleObjectComponent } from './dxy-versioning-comparator-single-object/dxy-versioning-comparator-single-object.component';
import { DxyVersioningCreateModalComponent } from './dxy-versioning-create-modal/dxy-versioning-create-modal.component';
import { DxyVersioningNavigatorModalComponent } from './dxy-versioning-navigator/dxy-versioning-navigator-modal.component';
import { DxyVersioningSelectorComponent } from './dxy-versioning-selector/dxy-versioning-selector.component';
import { DxyVersioningUpdateModalComponent } from './dxy-versioning-update-modal/dxy-versioning-update-modal.component';
import { DxyVersioningStatusGraphComponent } from './dxy-versioning-status-graph/dxy-versioning-status-graph.component';
import { DxyVersioningGeneralInfoComponent } from './dxy-versioning-general-info/dxy-versioning-general-info.component';
import { DxySpaceVersionsComponent } from './dxy-space-versions/dxy-space-versions.component';
import { DxyFieldsModule } from '@datagalaxy/core-ui/fields';
import { SharedModule } from '../shared/shared.module';
import { DxySharedDialogsModule } from '../shared/dialogs/DxySharedDialogsModule';
import { EntityCardModule } from '../shared/entityCard/entity-card.module';
import { DxySharedUiModule } from '../shared/shared-ui/DxySharedUiModule';
import { DxyScreensLayoutModule } from '../shared/screens-layout/DxyScreensLayoutModule';

@NgModule({
    imports: [
        SharedModule,
        DxyFieldsModule,
        DxySharedDialogsModule,
        EntityCardModule,
        DxySharedUiModule, // for dxy-tab-view
        DxyScreensLayoutModule,
    ],
    declarations: [
        DxyVersioningSelectorComponent,
        DxyVersioningNavigatorModalComponent,
        DxyVersioningCreateModalComponent,
        DxyVersioningUpdateModalComponent,
        DxyVersioningComparatorItemComponent,
        DxyVersioningComparatorComponent,
        DxyVersioningComparatorSingleObjectComponent,
        DxyVersioningComparatorSectionComponent,
        DxyVersioningStatusGraphComponent,
        DxyVersioningGeneralInfoComponent,
        DxySpaceVersionsComponent,
    ],
    exports: [
        DxyVersioningSelectorComponent,
        DxyVersioningNavigatorModalComponent,
        DxyVersioningCreateModalComponent,
        DxyVersioningUpdateModalComponent,
        DxyVersioningComparatorComponent,
        DxyVersioningComparatorSingleObjectComponent,
        DxyVersioningComparatorSectionComponent,
        DxySpaceVersionsComponent,
    ],
})
export class DxyVersioningModule {}

import { Component, OnInit } from '@angular/core';
import {
    DgModule,
    DgModuleDefinition,
    getModuleDefinition,
} from '@datagalaxy/shared/dg-module/domain';
import { BaseCellComponent } from '@datagalaxy/core-ui';
import { INavLinkCellParams } from '../nav-link.types';
import { NavigationService } from '../../../services/navigation.service';
import { getReferenceId } from '@datagalaxy/webclient/utils';
import { getViewIdentifierFromModuleDefinition } from '../../../shared/util/ViewIdentifier';
import { SpaceIdentifier } from '@datagalaxy/webclient/workspace/utils';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'dxy-nav-link-module',
    templateUrl: './nav-link-module.component.html',
    styleUrls: ['../nav-link.scss'],
})
export class NavLinkModuleComponent
    extends BaseCellComponent<
        any,
        INavLinkModuleParameter,
        INavLinkCellParams<INavLinkModuleParameter>
    >
    implements OnInit
{
    protected moduleDefinition!: DgModuleDefinition;

    constructor(private navigationService: NavigationService) {
        super();
    }

    protected get glyphClass() {
        return this.moduleDefinition.glyphClass;
    }

    protected get translateKey() {
        return this.moduleDefinition?.translateKey;
    }

    protected async gotoModule() {
        const spaceIdr = new SpaceIdentifier(
            getReferenceId(this.value.spaceId, this.value.spaceId),
            this.value.versionId
        );
        const viewIdentifier = getViewIdentifierFromModuleDefinition(
            this.moduleDefinition
        );
        await this.navigationService.goTo(viewIdentifier, spaceIdr);
    }

    ngOnInit() {
        this.moduleDefinition = getModuleDefinition(
            DgModule[this.value.module]
        );
    }
}

export interface INavLinkModuleParameter {
    module: DgModule;
    spaceId: string;
    versionId: string;
}

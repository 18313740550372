import { SpaceHomePageDto } from '@datagalaxy/webclient/workspace/data-access';
import { SpaceHomeState } from '../space-home-state.service';
import { UpdateSpaceHomeHeaderAction } from './update-space-home-header-action';

export function updateSpaceHomeHeaderReducer(
    state: SpaceHomeState,
    action: UpdateSpaceHomeHeaderAction
): Partial<SpaceHomeState> {
    const homePage = state.spaceHomePage;
    const newHomePage: SpaceHomePageDto = {
        ...homePage,
        Title: action.title,
        IsTitleDisabled: action.hideTitle,
    };
    const image = action.image;
    if (image.FileContent) {
        newHomePage.SpaceHomePageImageDto = image;
    } else if (image.IsDelete) {
        newHomePage.SpaceHomePageImageDto = {
            IsDelete: true,
        };
        newHomePage.ImageHash = null;
    }
    return {
        spaceHomePage: newHomePage,
    };
}

import { NgModule } from '@angular/core';
import { DxyClientErrorComponent } from './dxy-client-error/dxy-client-error.component';
import { DxyErrorMainComponent } from './dxy-error-main/dxy-error-main.component';
import { DxyErrorPageComponent } from './dxy-error-page/dxy-error-page.component';
import { AccountSharedModule } from '../account/account-shared.module';
import { CommonModule } from '@angular/common';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    imports: [
        CommonModule,
        MatButtonModule,
        TranslateModule,
        AccountSharedModule,
    ],
    declarations: [
        DxyErrorPageComponent,
        DxyErrorMainComponent,
        DxyClientErrorComponent,
    ],
})
export class DxyErrorPagesModule {}

import { Dashboard } from '@datagalaxy/webclient/dashboard/ui';
import { SpaceHomeState } from '../space-home-state.service';

export function updateSpaceHomeDashboardReducer(
    state: SpaceHomeState,
    dashboard: Dashboard
): SpaceHomeState {
    const valid = isValidDashboard(dashboard);
    return {
        ...state,
        dashboard: valid ? dashboard : undefined,
        spaceHomePage: {
            ...state.spaceHomePage,
            Config: valid ? JSON.stringify(dashboard) : undefined,
        },
    };
}

function isValidDashboard(dashboard: Dashboard) {
    return Array.isArray(dashboard?.sections);
}

import { Component, Input } from '@angular/core';
import { ActivityLogEntryElement } from '../activityLog.types';
import { ServerTimeService } from '../../services/serverTime.service';
import { DxyBaseComponent } from '@datagalaxy/ui/core';

@Component({
    selector: 'dxy-activity-log-element',
    templateUrl: 'dxy-activity-log-element.component.html',
    styleUrls: ['./dxy-activity-log-element.component.scss'],
})
export class DxyActivityLogElementComponent extends DxyBaseComponent {
    @Input() activityLogEntryElement: ActivityLogEntryElement;
    @Input() disableNavigation?: boolean;

    public get isGroupHeader() {
        return this.activityLogEntryElement.isGroupHeader;
    }
    public get activityLogEntry() {
        return this.activityLogEntryElement.entry;
    }

    constructor(private serverTimeService: ServerTimeService) {
        super();
    }

    public getCreationTimeFromNow() {
        const dateString = this.activityLogEntryElement.entry.CreationTime;
        return this.serverTimeService.getServerTimeFromNowString(
            dateString,
            true
        );
    }
}

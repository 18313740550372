import { NgModule } from '@angular/core';
import { DxyFieldsModule } from '@datagalaxy/core-ui/fields';
import { AttributeFilterModule } from '../attribute/attribute-filter/AttributeFilterModule';
import { DxyEntityFiltersSaveActionsComponent } from './dxy-entity-filters-save-actions/dxy-entity-filters-save-actions.component';
import { SharedModule } from '../shared.module';
import { FilteredViewsNavHeaderComponent } from './filtered-views-nav-header/filtered-views-nav-header.component';
import { DxySharedUiModule } from '../shared-ui/DxySharedUiModule';
import { EntityFilterButtonComponent } from './entity-filter-button/entity-filter-button.component';
import { DxyFiltersModule } from '@datagalaxy/core-ui';

@NgModule({
    imports: [
        SharedModule,
        DxySharedUiModule,
        DxyFieldsModule,
        AttributeFilterModule,
        DxyFiltersModule,
    ],
    declarations: [
        FilteredViewsNavHeaderComponent,
        DxyEntityFiltersSaveActionsComponent,
        EntityFilterButtonComponent,
    ],
    exports: [
        FilteredViewsNavHeaderComponent,
        DxyEntityFiltersSaveActionsComponent,
        EntityFilterButtonComponent,
    ],
})
export class DxyFilterModule {}

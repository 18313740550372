import { Component, Input, OnInit } from '@angular/core';
import { EntityEventService } from '../services/entity-event.service';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { EntityUtils } from '@datagalaxy/webclient/entity/utils';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';
import { AttributeMetaInfo } from '@datagalaxy/webclient/attribute/domain';

@Component({
    selector: 'dxy-entity-details',
    templateUrl: 'dxy-entity-details.component.html',
    styleUrls: ['./dxy-entity-details.component.scss'],
})
export class DxyEntityDetailsComponent
    extends DxyBaseComponent
    implements OnInit
{
    //#region IScreenLayout
    @Input() spaceId: string;
    @Input() entityData: EntityItem;
    @Input() entityAttributes: AttributeMetaInfo[];
    @Input() readOnly?: boolean;
    @Input() noNavLink?: boolean;
    //#endregion
    @Input() reloadOnSourceChange: boolean;
    @Input() showHeader: boolean;
    /** If Entity Owner, responsible for updating the local instance of entityData on an Update event */
    @Input() isEntityOwner: boolean;

    constructor(private entityEventService: EntityEventService) {
        super();
    }

    ngOnInit() {
        this.log(
            'ngOnInit',
            this.spaceId,
            this.entityData,
            this.entityAttributes?.length
        );
        if (this.isEntityOwner) {
            super.registerSubscription(
                this.entityEventService.subscribeEntityUpdate(
                    this.entityData.ServerType,
                    (entity) => {
                        if (
                            entity.DataReferenceId ==
                            this.entityData.DataReferenceId
                        ) {
                            EntityUtils.mergeEntity(this.entityData, entity);
                        }
                    }
                )
            );
        }
    }
}

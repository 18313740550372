<label>
    <input
        type="checkbox"
        [name]="name"
        [checked]="checked"
        [value]="value"
        (change)="onValueChange($event)"
    />
    <i
        *ngIf="indeterminate; else checkIcon"
        class="checked glyph-indeterminate_check_box"
    ></i>
    <ng-template #checkIcon>
        <i *ngIf="!checked" class="unchecked glyph-checkbox-unchecked"></i>
        <i *ngIf="checked" class="checked glyph-checkbox-checked"></i>
    </ng-template>
    <span [innerHTML]="label"></span>
</label>

import { Component, OnDestroy, ViewChild } from '@angular/core';
import { MatLegacyInput as MatInput } from '@angular/material/legacy-input';
import { CollectionsHelper } from '@datagalaxy/core-util';
import { TranslateService } from '@ngx-translate/core';
import { BaseCellComponent } from '../../cell-components/BaseCellComponent';
import {
    IGraphicalTextInputParams,
    UpdateOn,
} from './graphical-text-input.types';

/** ## Role
 * Text input user control to be used in a graphical toolbar
 */
@Component({
    selector: 'dxy-graphical-text-input',
    templateUrl: 'graphical-text-input.component.html',
})
export class DxyGraphicalTextInputComponent
    extends BaseCellComponent<unknown, string, IGraphicalTextInputParams>
    implements OnDestroy
{
    public text: string;
    public placeholderText: string;
    public tooltipText: string;

    @ViewChild(MatInput) private matInput: MatInput;
    private prevText: string;
    private updateOn: UpdateOn[];

    constructor(private translate: TranslateService) {
        super();
    }

    ngOnDestroy() {
        this.text = this.matInput.value;
        this.onValueChange(UpdateOn.destroy);
        super.ngOnDestroy();
    }

    public onChanged() {
        this.onValueChange(UpdateOn.change, UpdateOn.blur);
    }

    public onEnterKey() {
        this.text = this.matInput.value;
        this.onValueChange(UpdateOn.enter);
    }

    protected override updateBindings() {
        const p = this.params ?? {},
            u = p.updateOn;
        this.updateOn = Array.isArray(u) ? u : u ? [u] : [UpdateOn.change];
        this.prevText = this.text = p.value;
        this.tooltipText =
            p.tooltipText ||
            (p.tooltipKey && this.translate.instant(p.tooltipKey)) ||
            '';
        this.placeholderText =
            p.placeholderText ||
            (p.placeholderKey && this.translate.instant(p.placeholderKey)) ||
            '';
        if (p.takeFocus) {
            setTimeout(() => this.matInput.focus());
        }
    }

    private onValueChange(...modes: UpdateOn[]) {
        const changed = this.text != this.prevText;
        this.prevText = this.text;
        if (this.debug) {
            this.log(
                'onValueChange',
                ...modes.map((u) => UpdateOn[u]),
                changed,
                this.text
            );
        }
        if (
            !changed ||
            !CollectionsHelper.includesAny(this.updateOn, ...modes)
        ) {
            return;
        }
        this.params.onValueChange?.(this.text);
    }
}

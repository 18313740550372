import {
    AfterViewInit,
    Directive,
    ElementRef,
    HostListener,
    Input,
} from '@angular/core';
import { DxyBaseDirective } from '@datagalaxy/ui/core';

@Directive({
    selector: '[dxyDraggableField]',
})
export class DxyDraggableFieldDirective
    extends DxyBaseDirective
    implements AfterViewInit
{
    @Input() isDisabled: boolean;
    @Input() dragData: string;

    private get element() {
        return this.elementRef?.nativeElement;
    }

    constructor(private elementRef: ElementRef<Element>) {
        super();
    }

    ngAfterViewInit() {
        const el = this.element as HTMLElement;
        if (!el) {
            return;
        }
        el.draggable = true;
        el.classList.add('draggable');
    }

    @HostListener('dragstart', ['$event']) onDragStart(e: DragEvent) {
        if (this.isDisabled) {
            return;
        }
        const el = this.element;
        if (!el) {
            return;
        }
        this.log('onDragStart', e.dataTransfer);
        el.classList.add('dragging');
        if (!e.dataTransfer) {
            return;
        }
        e.dataTransfer.effectAllowed = 'move';
        e.dataTransfer.setData('Text', this.dragData);
    }

    @HostListener('dragend', ['$event']) onDragEnd(_e: DragEvent) {
        this.log('onDragEnd');
        this.element?.classList.remove('dragging');
    }
}

<button
    #panelToggleButton
    dxyFabButton
    color="secondary"
    [matTooltip]="'Dashboard.WidgetsGallery.toogleButtonTooltip' | translate"
    [matTooltipDisabled]="opened$ | async"
    matTooltipPosition="left"
    (click)="togglePanel()"
>
    <i class="glyph-content-view-module-2"></i>
</button>

<dxy-panel-host
    class="small"
    [panelOpened]="opened$ | async"
    (panelOpenedChange)="togglePanel()"
    [disableAutoClosing]="true"
>
    <div class="panel-content" [class.drag-is-over]="dragging">
        <head>
            <i class="glyph-content-view-module-2"></i>
            <h3>
                {{ title }}
            </h3>
            <span class="dg5-badge">
                {{ widgets.length }}
            </span>
            <div class="actions">
                <button mat-icon-button (click)="togglePanel()">
                    <i class="glyph-uncollapse"></i>
                </button>
            </div>
        </head>
        <p class="description">{{ description }}</p>
        <div class="widget-previews">
            <dxy-widget-preview
                *ngFor="let widget of widgets"
                [widget]="widget"
            ></dxy-widget-preview>
        </div>
    </div>
</dxy-panel-host>

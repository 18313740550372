import { ChangeDetectionStrategy, Component } from '@angular/core';
import {
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
} from '@angular/forms';
import { DxyBaseModalComponent } from '@datagalaxy/ui/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { LanguageForm } from '../../languages/language-selection-helpers';
import { MultilingualStateService } from '../../multilingual-state.service';
import { MultilingualApiService } from '@datagalaxy/webclient/multilingual/data-access';
import { executeOnce, withLoading } from '@datagalaxy/core-ui';

@Component({
    selector: 'dxy-add-language-modal',
    templateUrl: './add-language-modal.component.html',
    styleUrls: ['./add-language-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddLanguageModalComponent extends DxyBaseModalComponent<
    void,
    void
> {
    protected form: FormGroup<LanguageForm>;

    protected exludedLanguageCodes: string[] = [
        this.stateService.state.settings.sourceLanguageCode ?? '',
        ...(this.stateService.state.settings.targetLanguages?.map(
            (l) => l.languageCode
        ) ?? []),
    ];

    constructor(
        dialogRef: MatDialogRef<AddLanguageModalComponent>,
        private stateService: MultilingualStateService,
        private formBuilder: FormBuilder,
        private multilingualApiService: MultilingualApiService
    ) {
        super(dialogRef);
        this.form = this.formBuilder.group<LanguageForm>({
            language: new FormControl(null, [Validators.required]),
        });
    }

    @withLoading()
    @executeOnce()
    protected async onSubmit() {
        if (!this.form.valid) {
            return;
        }
        const languageCode = this.form.controls.language.value?.languageCode;
        if (!languageCode) {
            return;
        }
        await this.multilingualApiService.addTargetLanguage({
            languageCode,
        });
        this.stateService.addLanguage(languageCode);
        this.onCloseSubmit();
    }
}

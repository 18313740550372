import { LoadMultiEntityResult } from '@datagalaxy/webclient/entity/data-access';
import {
    EntityAttributeLinkGroup,
    EntityAttributeLinks,
} from './entity-links.types';
import { ObjectLinkType } from '@datagalaxy/dg-object-model';
import { CollectionsHelper } from '@datagalaxy/core-util';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';

export class EntityLinkLoadMultiEntityAdapter {
    public static convert(
        loadMultiEntityResult: LoadMultiEntityResult<EntityItem>
    ): EntityAttributeLinks[] {
        const allLinkTypes =
            CollectionsHelper.getEnumValues<ObjectLinkType>(ObjectLinkType);

        return this.makeEntityAttributeLinks(
            loadMultiEntityResult,
            allLinkTypes
        );
    }

    private static makeEntityAttributeLinks(
        loadMultiEntityResult: LoadMultiEntityResult<EntityItem>,
        linkTypes: ObjectLinkType[]
    ) {
        return loadMultiEntityResult?.Entities?.flatMap((entity) => ({
            entity,
            groups: this.makeEntityAttributeLinksGroups(
                entity,
                linkTypes
            ).filter((g) => g.entityIds?.length),
        }));
    }

    private static makeEntityAttributeLinksGroups(
        entity: EntityItem,
        linkTypes: ObjectLinkType[]
    ): EntityAttributeLinkGroup[] {
        return linkTypes?.map((lt) => {
            const entities = entity.getAttributeLinkList(lt);
            return {
                linkType: lt,
                entityIds: entities,
            };
        });
    }
}

import { BaseStateService } from '@datagalaxy/utils';
import { ClientSpacesListState } from './client-spaces-list-state';
import { Injectable } from '@angular/core';
import { CollectionsHelper, CoreUtil, StringUtil } from '@datagalaxy/core-util';
import { NavSpace } from '@datagalaxy/webclient/workspace/data-access';
import { ServerType } from '@datagalaxy/dg-object-model';

@Injectable()
export class ClientSpacesListStateService extends BaseStateService<ClientSpacesListState> {
    constructor() {
        super({
            navSpaces: [],
            searchString: '',
        });
    }
    updateNavSpaces(navSpaces: NavSpace[]) {
        this.setState({
            navSpaces: navSpaces,
        });
    }
    updateNavSpace(navSpace: NavSpace) {
        this.setState({
            navSpaces: this.state.navSpaces.map((space) => {
                if (space.ReferenceId === navSpace.ReferenceId) {
                    space.DisplayName = navSpace.DisplayName;
                    space.Description = navSpace.Description;
                    if (navSpace.SecurityProfileType !== undefined) {
                        space.SecurityProfileType =
                            navSpace.SecurityProfileType;
                    }
                    return CoreUtil.cloneDeep(space);
                }
                return space;
            }),
        });
    }

    updateSearchTerm(searchString: string) {
        this.setState({
            searchString,
        });
    }

    updateDefaultNavSpace(updatedNavspace: NavSpace) {
        this.setState({
            navSpaces: this.state.navSpaces.map((space) => {
                if (space.ReferenceId === updatedNavspace.ReferenceId) {
                    space.IsDefaultSpace = updatedNavspace.IsDefaultSpace;
                    return CoreUtil.cloneDeep(space);
                }
                if (
                    updatedNavspace.IsDefaultSpace !== undefined &&
                    space.IsDefaultSpace
                ) {
                    space.IsDefaultSpace = false;
                    return CoreUtil.cloneDeep(space);
                }
                return space;
            }),
        });
    }

    addNavSpace(navSpace: NavSpace) {
        this.setState({
            navSpaces: [...this.state.navSpaces, navSpace],
        });
    }

    selectFilteredSpaces() {
        return this.select((state) => {
            const filteredSpaces = StringUtil.filterSearched(
                state.searchString,
                state.navSpaces,
                (nav) => nav.DisplayName
            );
            const orderedSpaces = CollectionsHelper.orderByText(
                filteredSpaces,
                (workspace) => workspace.DisplayName
            );
            return orderedSpaces;
        });
    }
    selectSpaceCount() {
        return this.select((state) => state.navSpaces.length);
    }

    getOrganizations() {
        return this.state.navSpaces.filter(
            (navspace) => navspace.Type == ServerType.Organization
        );
    }
}

import { Component } from '@angular/core';
import { BaseCellComponent } from '@datagalaxy/core-ui/cell-components';
import { EntityPanelTool } from '../../../entity-panels/entity-panels.types';
import { EntityPreviewPanelService } from '../../../shared/entity/services/entity-preview-panel.service';
import { EntityTaskDTO } from '@datagalaxy/dg-object-model';
import { HierarchicalData } from '@datagalaxy/dg-object-model';
import { INavLinkCellParams } from '../nav-link.types';
import { EntityIdentifier } from '@datagalaxy/webclient/entity/utils';

/**
 * ## Role
 * Link inside to open an entity preview panel on task tab
 */
@Component({
    selector: 'app-task-nav-link',
    templateUrl: './task-nav-link.component.html',
    styleUrls: ['../nav-link.scss'],
})
export class TaskNavLinkComponent extends BaseCellComponent<
    unknown,
    EntityTaskDTO,
    INavLinkCellParams<EntityTaskDTO>
> {
    constructor(private entityPreviewPanelService: EntityPreviewPanelService) {
        super();
    }

    protected iconGlyphClass?: string;
    protected iconTooltip?: string;
    protected technoImgUrl?: string;
    protected technoTooltip?: string;

    protected get title() {
        return this.value.Title;
    }

    public async openEntityPreview() {
        if (this.params?.disableNavigation) {
            return;
        }
        const entityIdr = this.getLinkedEntityIdentifier(this.value.HddData);
        return await this.entityPreviewPanelService.setupPanel({
            entityIdr,
            activeTool: EntityPanelTool[EntityPanelTool.Tasks],
        });
    }

    private getLinkedEntityIdentifier(hdata: HierarchicalData) {
        const hdd = hdata.EntityType
            ? hdata
            : hdata.Parents.find((parent) => parent.EntityType);
        return new EntityIdentifier(
            hdd.DataReferenceId,
            hdd.VersionId,
            hdd.EntityType
        );
    }
}

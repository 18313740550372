import {
    ISpaceEditModalResolve,
    ISpaceEditModalResult,
} from '../dxy-space-edit-modal/space-edit-modal.types';
import { BaseService } from '@datagalaxy/core-ui';
import { DxySpaceEditModalComponent } from '../dxy-space-edit-modal/dxy-space-edit-modal.component';
import { Injectable } from '@angular/core';
import { ServerType } from '@datagalaxy/dg-object-model';
import { NavigationService } from '../../services/navigation.service';
import { DxyModalService } from '../../shared/dialogs/DxyModalService';
import { SecurityService } from '../../services/security.service';
import { PreviewPanelService } from '../../shared/shared-ui/preview-panel.service';
import { SpaceMembersWidgetPreviewComponent } from '../space-home-widgets/space-members-widget-preview/space-members-widget-preview.component';
import { ISpaceMemberGroup } from '../space-home-widgets/space-members-widget/space-members.types';
import { SpaceActivityLogsWidgetPreviewComponent } from '../space-home-widgets/space-activity-logs-widget-preview/space-activity-logs-widget-preview.component';
import { SpaceNewsWidgetModalComponent } from '../space-home-widgets/space-news-widget-modal/space-news-widget-modal.component';
import {
    ISpaceNewsWidgetModalInput,
    ISpaceNewsWidgetModalOutput,
} from '../space-home-widgets/space-news-widget-modal/space-news-widget-modal.types';
import { ModalSize } from '@datagalaxy/ui/dialog';
import {
    NavSpace,
    Space,
    SpaceArticleDto,
} from '@datagalaxy/webclient/workspace/data-access';
import { ISpaceIdentifier } from '@datagalaxy/webclient/workspace/domain';
import { ActivityLogEntry } from '@datagalaxy/webclient/activity-log/domain';
import { UserDto } from '@datagalaxy/webclient/user/domain';

@Injectable({ providedIn: 'root' })
export class SpaceUiService extends BaseService {
    public get canAccessSubOrga() {
        return this.securityService.isClientSubOrganizationEnabled();
    }

    constructor(
        private securityService: SecurityService,
        private navigationService: NavigationService,
        private dxyModalService: DxyModalService,
        private previewPanelService: PreviewPanelService
    ) {
        super();
    }

    public async openCreateSpaceModal(
        parentSpaceId: string,
        type: ServerType
    ): Promise<NavSpace> {
        const modalResult = await this.dxyModalService.open<
            DxySpaceEditModalComponent,
            ISpaceEditModalResolve,
            ISpaceEditModalResult
        >({
            componentType: DxySpaceEditModalComponent,
            data: {
                currentOrganizationId: parentSpaceId,
                type,
            },
        });
        if (!modalResult) {
            return;
        }
        await this.navigationService.goToSpaceHome(modalResult.navSpace);
    }

    public async openUpdateSpaceModal(navSpace: NavSpace): Promise<NavSpace> {
        const modalResult = await this.dxyModalService.open<
            DxySpaceEditModalComponent,
            ISpaceEditModalResolve,
            ISpaceEditModalResult
        >({
            componentType: DxySpaceEditModalComponent,
            data: {
                navSpace: navSpace,
            },
        });

        return modalResult?.navSpace;
    }

    public async openNewsModal(
        spaceIdr: ISpaceIdentifier,
        article?: SpaceArticleDto
    ): Promise<ISpaceNewsWidgetModalOutput> {
        return await this.dxyModalService.open<
            SpaceNewsWidgetModalComponent,
            ISpaceNewsWidgetModalInput,
            ISpaceNewsWidgetModalOutput
        >({
            componentType: SpaceNewsWidgetModalComponent,
            size: ModalSize.Large,
            data: {
                spaceIdr,
                article,
            },
        });
    }

    public async openTeamMembersWidgetPreview(
        space: Space,
        spaceMembers: UserDto[],
        spaceMemberGroups: ISpaceMemberGroup[],
        selectedMemberGroup?: ISpaceMemberGroup
    ) {
        await this.previewPanelService.setupPanel({
            component: SpaceMembersWidgetPreviewComponent,
            inputs: {
                space,
                spaceMembers,
                spaceMemberGroups,
                selectedMemberGroup,
            },
        });
    }

    public async openActivityLogWidgetPreview(
        space: Space,
        logEntries: ActivityLogEntry[]
    ) {
        await this.previewPanelService.setupPanel({
            component: SpaceActivityLogsWidgetPreviewComponent,
            inputs: {
                space,
                logEntries,
            },
        });
    }
}

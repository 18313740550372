import { Injectable } from '@angular/core';
import {
    EntityType,
    HierarchicalData,
    IEntityIdentifier,
    IHasHddData,
    ServerType,
} from '@datagalaxy/dg-object-model';
import { CrudActionType, CrudOperation } from './log-functional';
import { SpaceIdentifier } from '@datagalaxy/webclient/workspace/utils';
import { EntityHierarchicalDataUtils } from '@datagalaxy/webclient/entity/utils';
import { CoreUtil, DomUtil, THTMLElement } from '@datagalaxy/core-util';
import { Subject } from 'rxjs';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';

/**
 #Archi-service-leaf (Does not reference any other app service)
 */
@Injectable({ providedIn: 'root' })
export class FunctionalLogService {
    private readonly onDispatch = new Subject<{
        featureCode: string;
        crudOperation?: CrudOperation;
        source?: string;
        crudActionType?: CrudActionType;
    }>();

    //#region static

    public static getEntityIdrActionFeatureCodeCrud(
        entityIdr: IEntityIdentifier,
        crudOperation: CrudOperation
    ) {
        const isProject = SpaceIdentifier.isProject(
            SpaceIdentifier.fromEntity(entityIdr)
        );
        return FunctionalLogService.getEntityActionFeatureCodeCrud(
            ServerType[entityIdr.ServerType],
            entityIdr.entityType ? EntityType[entityIdr.entityType] : undefined,
            crudOperation,
            isProject
        );
    }

    public static getEntityActionFeatureCodeCrud(
        serverTypeName: string,
        subTypeName: string | undefined,
        crudOperation: CrudOperation,
        isProject?: boolean
    ) {
        const featureCode = FunctionalLogService.getEntityActionFeatureCode(
            serverTypeName,
            subTypeName,
            isProject
        );
        return `${featureCode},${CrudOperation[crudOperation]}`;
    }

    public static getHddDataActionFeatureCode(hdContainer: IHasHddData) {
        const hdd = hdContainer?.HddData?.Data;
        if (!hdd) {
            return;
        }
        const isProject = FunctionalLogService.isProject(hdContainer);
        return FunctionalLogService.getEntityActionFeatureCode(
            hdd.DataTypeName,
            hdd.SubTypeName,
            isProject
        );
    }

    public static getEntityItemActionFeatureCode(entityData: EntityItem) {
        if (!entityData) {
            return;
        }
        const isProject = FunctionalLogService.isProject(entityData);
        return FunctionalLogService.getEntityActionFeatureCode(
            entityData.DataTypeName,
            entityData.SubTypeName,
            isProject
        );
    }

    public static getEntityActionFeatureCode(
        serverTypeName: string,
        subTypeName?: string,
        isProject?: boolean
    ) {
        let actionKey: string;
        if (subTypeName) {
            const prefix = FunctionalLogService.getTypeNameCode(
                serverTypeName?.toUpperCase(),
                true
            );
            const suffix = FunctionalLogService.getSubTypeNameCode(
                subTypeName?.toUpperCase()
            );
            actionKey = `${prefix}_${suffix}`;
        } else {
            actionKey = FunctionalLogService.getTypeNameCode(
                serverTypeName.toUpperCase(),
                false
            );
        }
        return isProject ? actionKey : `ORGA_${actionKey}`;
    }

    public static isProject(data: IHasHddData) {
        return EntityHierarchicalDataUtils.isProjectHddData(data.HddData);
    }

    public static getFeatureCode(
        entityData: {
            HddData?: HierarchicalData;
            ServerTypeName?: string;
            ServerType?: ServerType;
        },
        featureCode: string,
        codeCrud: string
    ) {
        if (!entityData) {
            return null;
        }

        const hd = entityData.HddData;
        let dataTypeName = '';
        let subType = '';
        let isProject = false;
        if (hd) {
            isProject = EntityHierarchicalDataUtils.isProjectHddData(hd);
            dataTypeName = hd.Data.DataTypeName;
            subType = hd.Data.SubTypeName;
        } else if (entityData.ServerType) {
            isProject = entityData.ServerType != ServerType.Organization;
            dataTypeName = ServerType[entityData.ServerType];
        } else if (entityData.ServerTypeName) {
            isProject = entityData.ServerTypeName != 'Organization';
            dataTypeName = entityData.ServerTypeName;
        }

        const entityFeatureCode =
            FunctionalLogService.getEntityActionFeatureCode(
                dataTypeName,
                subType,
                isProject
            );
        return `${entityFeatureCode}_${featureCode},${codeCrud}`;
    }

    public static getTypeNameCode(name: string, hasSubType: boolean) {
        switch (name) {
            case 'SOFTWAREELEMENT':
                return 'USAGE';
            case 'COLUMN':
                return hasSubType ? 'FIELD' : 'FIELD_COLUMN';
            case 'MODEL':
                return 'SOURCE';
            case 'TABLE':
                return hasSubType ? 'STRUCTURE' : 'STRUCTURE_TABLE';
            default:
                return name;
        }
    }

    private static getNamesFeatureCode(name: string) {
        switch (name) {
            case 'COLUMNS':
                return 'FIELDS';
            case 'MODELS':
                return 'SOURCES';
            case 'TABLES':
                return 'STRUCTURES';
            default:
                return name;
        }
    }

    private static getSubTypeNameCode(name: string) {
        switch (name) {
            case 'NONRELATIONAL':
                return 'FILESTORE';
            case 'RELATIONAL':
                return 'DATABASE';
            default:
                return name;
        }
    }

    private static parseData(logFunctionalData: string, origin?: THTMLElement) {
        //FEATURECODE,R
        //FEATURECODE,A,ADD
        const parts = logFunctionalData?.trim().split(',');
        if (!parts?.length) {
            return {};
        }

        const [featureCode, crudLetter, actionTypeString] = parts;
        const crudOp: CrudOperation =
            CrudOperation[crudLetter as keyof typeof CrudOperation];
        const actionType = actionTypeString as CrudActionType;

        //console.log({ featureCode, crudLetter, actionTypeString, actionType  })

        const parents =
            origin &&
            DomUtil.getParentComponents(
                origin,
                'ui-view',
                'as-split',
                'as-split-area'
            );
        const source =
            parents && `\\${parents.map((p) => p.localName).join('\\')}`;

        return { featureCode, crudOp, source, actionType };
    }

    //#endregion

    public get onDispatch$() {
        return this.onDispatch.asObservable();
    }

    public logEntityItemAction(
        entity: EntityItem,
        crudOperation: CrudOperation,
        crudActionType?: CrudActionType,
        suffix?: string
    ) {
        if (!entity) {
            return;
        }
        const isProject = FunctionalLogService.isProject(entity);
        return this.logEntityAction(
            entity.TypeName,
            crudOperation,
            entity.SubTypeName,
            isProject,
            crudActionType,
            suffix
        );
    }

    public logEntityAction(
        entityTypeName: string,
        crudOperation: CrudOperation,
        subtype?: string,
        isProject?: boolean,
        crudActionType?: CrudActionType,
        suffix?: string
    ) {
        const featureCode = `${FunctionalLogService.getEntityActionFeatureCode(
            entityTypeName,
            subtype,
            isProject
        )}${suffix ?? ''}`;
        this.logFunctionalAction(featureCode, crudOperation, crudActionType);
    }

    public logFunctionalAction(
        featureCode: string,
        crudOperation: CrudOperation,
        crudActionType?: CrudActionType
    ) {
        this.dispatchLog(featureCode, crudOperation, undefined, crudActionType);
    }

    /**
     * *text* in the form: "*featureCode*,*action*"
     * or "*featureCode*,*action*,*actionType*", where:
     * - *action* is one letter: C, R, U, D, A (see *CrudOperation* enum)
     * - *actionType* is a text matching one of the *CrudActionType* enum values (to be used when *action* is A) */
    public parseAndLog(text: string, origin?: THTMLElement) {
        if (!text) {
            return;
        }
        const { featureCode, crudOp, source, actionType } =
            FunctionalLogService.parseData(text, origin);
        this.dispatchLog(featureCode, crudOp, source, actionType);
    }

    private dispatchLog(
        featureCode?: string,
        crudOperation?: CrudOperation,
        source?: string,
        crudActionType?: CrudActionType
    ) {
        if (!featureCode) {
            CoreUtil.warn('undefined featureCode, server will fail', {
                crudOperation,
                source,
                crudActionType,
            });
            return;
        }

        if (crudOperation == undefined) {
            CoreUtil.warn('undefined crudOperation, server will fail', {
                featureCode,
                source,
                crudActionType,
            });
        }

        try {
            this.onDispatch.next({
                featureCode,
                crudOperation,
                source,
                crudActionType,
            });
        } catch (e) {
            CoreUtil.warn(e);
        }
    }
}

import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ITabItem, ITabsHeaderData } from './tabs-header.types';
import { MatLegacyTabGroup as MatTabGroup } from '@angular/material/legacy-tabs';
import { DxyBaseComponent } from '@datagalaxy/ui/core';

/**
 * ## Role
 * Horizontal tabs bar with optional content below it
 */
@Component({
    selector: 'dxy-tabs-header',
    templateUrl: 'tabs-header.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DxyTabsHeaderComponent<T = unknown>
    extends DxyBaseComponent
    implements OnChanges, OnInit, AfterViewInit
{
    @Input() tabs: ITabItem<T>[] | ITabsHeaderData<T>;
    @Input() activeTabItem: ITabItem<T>;
    @Input() customClass?: string;
    @Input() disabled?: boolean;

    /** Emitted when selected tab changes */
    @Output() readonly tabChange = new EventEmitter<ITabItem<T>>();

    @ViewChild(MatTabGroup, { static: true }) tabGroup: MatTabGroup;

    protected tabItems: ITabItem<T>[];

    protected get activeIndex() {
        return this._activeIndex;
    }
    protected set activeIndex(index: number) {
        this.activeSelectedTabItem(index);
    }
    private _activeIndex = 0;

    constructor(
        private translate: TranslateService,
        private cd: ChangeDetectorRef
    ) {
        super();
    }

    ngOnChanges(changes: SimpleChanges) {
        super.onChange(changes, 'tabs', () => this.init());
        super.onChange(changes, 'activeTabItem', () =>
            this.onActiveSelectedTabItemChange()
        );
    }

    ngOnInit() {
        this.log('ngOnInit', this.tabs);
        this.init();
    }

    ngAfterViewInit() {
        /**
         * Active tab item needs to be set after view init so mat-tab-group is
         * instanciated with activeIndex set to 0. This way when we update its internal
         * activeIndex the active tab transition will be correctly done.
         * Otherwise, the active index will be correctly focused, but not integrally
         * visible if there is a scroll
         */
        this.onActiveSelectedTabItemChange();
        this.tabGroup.realignInkBar();
    }

    //#region API
    public realignInkBar() {
        this.tabGroup.realignInkBar();
    }
    public setActiveTabItem(tabItem: ITabItem<T>) {
        if (!this.tabItems.includes(tabItem)) {
            return false;
        }
        this.activeTabItem = tabItem;
        return true;
    }
    public setActiveTabByIndex(tabIndex: number) {
        return this.setActiveTabItem(this.tabItems[tabIndex]);
    }
    public refresh() {
        this.cd.detectChanges();
    }
    //#endregion

    protected showDataCount(tabItem: ITabItem<T>) {
        const dataCount = this.getDataCount(tabItem);
        return dataCount > 0 || (dataCount == 0 && tabItem.showEmptyDataCount);
    }

    protected getDataCount(tabItem: ITabItem<T>) {
        return (
            tabItem.contentDataCount ??
            (Array.isArray(tabItem.data) ? tabItem.data.length : undefined)
        );
    }

    protected getTabItemText(tabItem: ITabItem<T>) {
        return (
            tabItem.tabText ??
            (tabItem.tabTranslateKey
                ? this.translate.instant(tabItem.tabTranslateKey)
                : !Array.isArray(this.tabs) && this.tabs.getTabItemTranslateKey
                ? this.translate.instant(
                      this.tabs.getTabItemTranslateKey(tabItem)
                  )
                : tabItem.tabId ?? '')
        );
    }

    protected getTabItemTooltip(tabItem: ITabItem<T>) {
        return tabItem.tabTooltipKey
            ? this.translate.instant(tabItem.tabTooltipKey)
            : '';
    }

    protected isCurrentTabItem(tabItem: ITabItem<T>) {
        return !this.tabs
            ? false
            : !Array.isArray(this.tabs) && this.tabs.isTabItemActive
            ? this.tabs.isTabItemActive(tabItem)
            : tabItem == this.activeTabItem;
    }

    private init() {
        const items = Array.isArray(this.tabs)
            ? this.tabs
            : this.tabs?.tabItems ?? [];
        this.tabItems = items.filter(
            (ti) =>
                !ti.disabled &&
                !(ti.hiddenIfNoDataCount && !ti.contentDataCount)
        );
        const activeTabItem = (this.activeTabItem =
            this.activeTabItem ||
            this.tabItems.find((ti) => this.isCurrentTabItem(ti)));
        if (activeTabItem) {
            return;
        }
        this.activeSelectedTabItem(0, true);
    }

    private activeSelectedTabItem(index: number, skipEmit?: boolean) {
        const tabItem = this.tabItems[index];
        this.log('activeSelectedTabItem', tabItem, index);
        if (!tabItem) {
            return;
        }
        this.activeTabItem = tabItem;
        this._activeIndex = this.tabItems?.findIndex(
            (ti) => ti === this.activeTabItem
        );
        if (!skipEmit) {
            this.tabChange.emit(tabItem);
        }
    }

    private onActiveSelectedTabItemChange() {
        this._activeIndex = this.tabItems?.findIndex(
            (ti) => ti === this.activeTabItem
        );
    }
}

import { Injectable } from '@angular/core';
import {
    BackendApiService,
    BaseServiceParameter,
} from '@datagalaxy/data-access';
import { IServerTimeData } from './server-time';
import { IServerHealthCheck } from './health-check';
import { ClientErrorParameter } from './log-error';
import { LogFunctionalActionParameter } from './log-functional';

@Injectable({ providedIn: 'root' })
export class MonitoringApiService {
    constructor(private backendApiService: BackendApiService) {}

    public async getServerTime(): Promise<IServerTimeData> {
        return await this.backendApiService.postPromise<IServerTimeData>(
            'Monitoring/GetTime',
            new BaseServiceParameter()
        );
    }

    public async healthCheck(): Promise<IServerHealthCheck> {
        return await this.backendApiService.postPromise<IServerHealthCheck>(
            'Monitoring/HealthCheck',
            new BaseServiceParameter()
        );
    }

    public async logFunctionalAction(param: LogFunctionalActionParameter) {
        return await this.backendApiService.postPromise(
            'Monitoring/LogFunctionalAction',
            param
        );
    }

    public async logError(param: ClientErrorParameter) {
        return await this.backendApiService.postPromise(
            'Monitoring/LogError',
            param
        );
    }
}

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
} from '@angular/core';
import {
    IConfigurableWidgetComponent,
    IEditionWidgetComponnent,
    IUndoWidgetComponent,
    IWidgetComponnent,
    registerWidget,
} from '@datagalaxy/webclient/dashboard/ui';
import { mergeMap, ReplaySubject, Subject } from 'rxjs';
import { SpaceFilteredViewObjectsWidgetConfiguration } from './space-filtered-view-objects-widget-configuration';
import { DxyModalService } from '../../../shared/dialogs/DxyModalService';
import { SpaceFilteredViewObjectsModalComponent } from '../space-filtered-view-objects-modal/space-filtered-view-objects-modal.component';
import { FilteredViewApiService } from '@datagalaxy/webclient/filter/data-access';
import { FilteredViewUiService } from '../../../shared/filter/services/filtered-view-ui.service';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { CoreUtil } from '@datagalaxy/core-util';
import { FilteredViewDto } from '@datagalaxy/webclient/filter/domain';

/**
 * ## Role
 * Display a list of objects returned by a configured filtered view
 */
@registerWidget({
    name: 'space-filtered-view-objects-widget',
    titleKey: 'UI.SpaceHome.Widgets.SpaceFilteredViewObjects.title',
    previewImgUrl: '/images/space-widgets/space-filtered-view-objects.svg',
})
@Component({
    selector: 'app-space-filtered-view-objects-widget',
    templateUrl: './space-filtered-view-objects-widget.component.html',
    styleUrls: [
        '../space-widget.component.scss',
        './space-filtered-view-objects-widget.component.scss',
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpaceFilteredViewObjectsWidgetComponent
    extends DxyBaseComponent
    implements
        IEditionWidgetComponnent,
        IWidgetComponnent,
        IUndoWidgetComponent,
        IConfigurableWidgetComponent<SpaceFilteredViewObjectsWidgetConfiguration>
{
    private configuration =
        new Subject<SpaceFilteredViewObjectsWidgetConfiguration>();
    public configuration$ = this.configuration.asObservable();

    public editionEnabled = false;
    public undo = new EventEmitter<void>();

    protected filteredView$ = new ReplaySubject<FilteredViewDto>();

    protected filteredViewObjects$ = this.filteredView$.pipe(
        mergeMap((fv) => this.loadFilterdViewObjects(fv))
    );

    protected count = 0;
    protected noAccess = false;

    constructor(
        private cd: ChangeDetectorRef,
        private filteredViewApiService: FilteredViewApiService,
        private modalService: DxyModalService,
        private filteredViewUiService: FilteredViewUiService
    ) {
        super();
    }

    public toggleEditionMode(enabled: boolean) {
        this.editionEnabled = enabled;
        this.cd.detectChanges();
    }

    public async applyConfiguration(
        configuration: SpaceFilteredViewObjectsWidgetConfiguration
    ) {
        const fvGuid = configuration?.filteredViewGuid;
        if (!fvGuid && this.editionEnabled) {
            this.openInitModal();
        }
        const fv = await this.loadFilterdView(fvGuid);
        this.filteredView$.next(fv);
    }

    protected async showEverything(fv: FilteredViewDto) {
        await this.filteredViewUiService.navigateTo(fv);
    }

    private async loadFilterdView(guid: string) {
        if (!guid) {
            return;
        }
        this.loadingSubject.next(true);
        try {
            return await this.filteredViewApiService.getFilteredView(guid);
        } catch (error) {
            this.noAccess = true;
            CoreUtil.warn(error);
            this.loadingSubject.next(false);
            return undefined;
        }
    }

    private async openInitModal() {
        const dto = await this.modalService.open<
            SpaceFilteredViewObjectsModalComponent,
            void,
            FilteredViewDto
        >({
            componentType: SpaceFilteredViewObjectsModalComponent,
        });
        if (!dto) {
            this.revertInsertion();
            return;
        }
        this.configuration.next({
            filteredViewGuid: dto.FilteredViewUid,
        });
        this.filteredView$.next(dto);
    }

    private revertInsertion() {
        this.undo.emit();
    }

    protected async loadFilterdViewObjects(fv: FilteredViewDto) {
        if (!fv) {
            return;
        }
        try {
            const result =
                await this.filteredViewUiService.loadCurrentVersionFilteredViewObjects(
                    fv
                );
            this.count = result.TotalCount;
            return result.Entities;
        } finally {
            this.loadingSubject.next(false);
        }
    }
}

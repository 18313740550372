import { ExplorationGraphNode } from './ExplorationGraphNode';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';

export class DatamapGraphNode extends ExplorationGraphNode {
    public displayName: string;
    public contextualAllLevelChildrenCount: number;
    public clicked: boolean;
    public level: number;

    constructor(el: EntityItem, level = 1) {
        super(el.ReferenceId);
        this.contextualAllLevelChildrenCount =
            el.ContextualAllLevelChildrenCount;
        this.displayName = el.DisplayName;
        this.entityType = el.EntityType;
        this.level = level;
        this.clicked = false;
    }
}

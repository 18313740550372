import { Component, EventEmitter, Input, Output } from '@angular/core';

/**
 * ## Role
 * Side panel displayed over graphical surface
 */
@Component({
    selector: 'dxy-graphical-side-panel',
    templateUrl: 'graphical-side-panel.component.html',
    styleUrls: ['graphical-side-panel.component.scss'],
})
export class DxyGraphicalSidePanelComponent {
    @Input() noHeader?: boolean;
    @Input() buttonTextKey?: string;
    @Output() readonly close = new EventEmitter<void>();
}

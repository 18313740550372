<div class="facets-header">
    <button
        mat-icon-button
        class="facets-header-hide"
        (click)="onClose.emit()"
        [matTooltip]="'UI.Search.hideFilters' | translate"
    >
        <i class="glyph-collapse"></i>
    </button>
    <span class="text" translate>UI.Search.filters</span>
    <span>
        <button
            mat-icon-button
            [matTooltip]="'UI.Search.facets.ttSettingsButton' | translate"
            [matMenuTriggerFor]="settingsMenu"
            (click)="onOpenSettings()"
        >
            <i class="glyph-settings"></i>
        </button>
        <mat-menu
            #settingsMenu="matMenu"
            class="dg5-mat-menu search-facet-filters-settings--mat-menu"
        >
            <dxy-search-facet-settings
                (onChangeSettings)="onSettingsChange.emit()"
                (click)="onSearchFacetSettingsClick($event)"
            ></dxy-search-facet-settings>
        </mat-menu>
    </span>
</div>
<div class="facets-body">
    <dxy-search-input
        class="search-input no-border"
        [noFocus]="true"
        [showCancelSearch]="true"
        [searchString]="searchTerm"
        (onSearchString)="onSearch($event.searchString)"
    ></dxy-search-input>
    <div class="facets-filters">
        <ng-container *ngFor="let group of displayedGroups; let last = last">
            <dxy-search-facet-filter-group
                [groupData]="group"
                (onFilterChange)="itemCheckChange($event)"
                class="facets-root-group"
                [class.separator]="!last"
            ></dxy-search-facet-filter-group>
        </ng-container>
    </div>
</div>

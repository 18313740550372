import { IHyperlinkModel } from '@datagalaxy/core-ui/fields';

export function fromSerializedServerHyperlinkModel(
    data: string
): IHyperlinkModel {
    return data && fromServerHyperlinkModel(JSON.parse(data));
}
export function toSerializedServerHyperlinkModel(value: IHyperlinkModel) {
    const serverModel: IServerHyperlinkModel = value && {
        Name: value.name,
        Url: value.url,
    };
    return serverModel && JSON.stringify(serverModel);
}

interface IServerHyperlinkModel {
    Name: string;
    Url: string;
}

function fromServerHyperlinkModel(
    value: IServerHyperlinkModel
): IHyperlinkModel {
    return value && { name: value.Name, url: value.Url };
}

import {
    IInnerRenderer,
    ILoadMultiResult,
    IOmniGridApi,
    IOmniGridTreeOptions,
    OmniGridSortModel,
    TRowNode,
    TRowNodeComparator,
} from './OmniGridTypes';
import * as moment from 'moment';
import { DateTimeUtil } from '@datagalaxy/core-util';
import { Type } from '@angular/core';
import { ICellComponent } from '../cell-components';

export class OmniGridUtil {
    public static makeTextComparator<TEntity>(
        getValueAsString: (value: any, node: TRowNode<TEntity>) => string
    ) {
        const comparator: TRowNodeComparator<TEntity> = (
            valueA,
            valueB,
            nodeA,
            nodeB
        ) => {
            const stringA = getValueAsString(valueA, nodeA) ?? '';
            const stringB = getValueAsString(valueB, nodeB) ?? '';
            return stringA.localeCompare(stringB);
        };
        return comparator;
    }

    public static makeNumberComparator<TEntity>(
        getValueAsNumber: (value: any, node: TRowNode<TEntity>) => number
    ) {
        const comparator: TRowNodeComparator<TEntity> = (
            valueA,
            valueB,
            nodeA,
            nodeB
        ) => {
            const numberA = getValueAsNumber(valueA, nodeA) ?? 0;
            const numberB = getValueAsNumber(valueB, nodeB) ?? 0;
            return numberA - numberB;
        };
        return comparator;
    }

    public static makeMomentComparator<TEntity>(
        getValueAsMoment: (value: any, node: TRowNode<TEntity>) => moment.Moment
    ) {
        const comparator: TRowNodeComparator<TEntity> = (
            valueA,
            valueB,
            nodeA,
            nodeB
        ) => {
            const mA = getValueAsMoment(valueA, nodeA);
            const mB = getValueAsMoment(valueB, nodeB);
            return DateTimeUtil.compareMoments(mA, mB);
        };
        return comparator;
    }

    public static makeStringDateComparator<TEntity>(
        getValueAsString: (value: any, node: TRowNode<TEntity>) => string
    ) {
        const comparator: TRowNodeComparator<TEntity> = (
            valueA,
            valueB,
            nodeA,
            nodeB
        ) => {
            const A = getValueAsString(valueA, nodeA);
            const B = getValueAsString(valueB, nodeB);
            return DateTimeUtil.compareIsoStrings(A, B);
        };
        return comparator;
    }

    public static simpleTreeDataSource<TEntity extends { id: number | string }>(
        getChildren: (id?: number | string) => TEntity[],
        opt?: {
            headerName?: string;
            getChildrenCount?: (e: TEntity) => number;
            groupRenderer?: IInnerRenderer<TEntity>;
            innerRendererFramework?: Type<ICellComponent<TEntity>>;
            onGroupItemClick?: (e: TEntity) => void;
            onGroupRowClick?: (e: TEntity) => void;
            log?: (...args: any[]) => void;
            maxChildrenCountToLoad?: number;
            canSortByHeaderClick?: boolean;
        }
    ): IOmniGridTreeOptions<TEntity> {
        const log = (...args: any[]) => {
            if (opt?.log) {
                opt.log('OmniGridUtil simpleTreeDataSource', ...args);
            }
        };
        return {
            getEntityId: (nod) => {
                log('getEntityId', nod, nod?.id);
                return '' + nod?.id;
            },
            getEntityChildren: (id) => {
                // id is undefined when getting the root children
                const children = getChildren(id);
                log('getEntityChildren', id, children);
                return Promise.resolve(
                    OmniGridUtil.makeGetChildrenResult(children)
                );
            },
            getEntityChildrenCount: opt?.getChildrenCount ?? (() => 1),
            controlCellRendererParams: {
                innerRenderer: opt?.innerRendererFramework
                    ? null
                    : opt?.groupRenderer ?? ((p) => `${p.data.id}`),
                innerRendererFramework: opt?.innerRendererFramework,
                onGroupItemClick: opt?.onGroupItemClick ?? (() => {}),
            },
            onGroupRowClick: opt?.onGroupRowClick,
            controlHeaderName: opt?.headerName,
            maxChildrenCountToLoad: opt?.maxChildrenCountToLoad,
            canSortByHeaderClick: opt?.canSortByHeaderClick,
            /** this one be better be true when using collapseExpandAll */
            purgeClosedRowNodes: true,
            //purgeClosedRowNodesChildrenSelection: true
        };
    }

    public static makeGetChildrenResult<TEntity>(
        children: TEntity[]
    ): ILoadMultiResult<TEntity> {
        return {
            Entities: children,
            Size: children?.length ?? 0,
            TotalCount: children?.length,
            StartIndex: -1,
            IsSuccess: !!children,
        };
    }

    public static collapseExpandAll<T extends { id: number | string }>(
        expand: boolean,
        gridApi: IOmniGridApi<T>,
        getChildren: (id?: number | string) => T[],
        sequential?: boolean
    ): Promise<void> {
        // setRowsExpanded's promise may be canceled,
        // so we create another to ensure the caller's .then() execution
        return new Promise<void>((resolve) => {
            if (expand) {
                const visit = async (id: string) => {
                    const ids = getChildren(id).map((n) => '' + n.id);
                    await gridApi?.setRowsExpanded(ids, expand, sequential);
                    expand && ids.forEach(visit);
                };
                visit(undefined).finally(() => resolve());
            } else {
                gridApi
                    .getExpandedRowIds()
                    .then((ids) => gridApi.setRowsExpanded(ids, false))
                    .finally(() => resolve());
            }
        });
    }

    public static sortModelToString(
        sortModel: OmniGridSortModel,
        replace?: (colId: string) => string
    ) {
        return sortModel
            ?.map((sortItem) => {
                const columnId = replace?.(sortItem.colId) ?? sortItem.colId;
                const sortSign = sortItem.sort == 'asc' ? '' : '-';
                return `${sortSign}${columnId}`;
            })
            .join(',');
    }
}

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DxyBaseComponent } from '@datagalaxy/ui/core';

@Component({
    selector: 'dxy-navbar',
    templateUrl: './dxy-navbar.component.html',
    styleUrls: [
        './dxy-navbar.component.scss',
        './dxy-navbar.component.responsive.scss',
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DxyNavbarComponent extends DxyBaseComponent {
    constructor() {
        super();
    }
}

import { Directive, Input, OnInit, SimpleChange } from '@angular/core';
import { UserService } from '../../services/user.service';
import { GridComponent } from '@datagalaxy/ui/grid';
import { debounceTime } from 'rxjs/operators';
import { PersistedGridState } from '@datagalaxy/ui/grid';
import { DxyBaseDirective } from '@datagalaxy/ui/core';
import { userSettingsValues } from '@datagalaxy/webclient/user/domain';

@Directive({
    standalone: true,
    selector: 'dxy-grid[appGridUserSettings]',
})
export class GridUserSettingsDirective
    extends DxyBaseDirective
    implements OnInit
{
    @Input() appGridUserSettings: string;

    constructor(
        private host: GridComponent<any>,
        private userService: UserService
    ) {
        super();
    }

    ngOnInit() {
        void this.initAsync();

        const gridState$ = this.host.gridStateChange.pipe(debounceTime(500));

        super.subscribe(gridState$, (gridState) =>
            this.onGridStateChange(gridState)
        );
    }

    private async initAsync() {
        const gridId = this.appGridUserSettings;
        this.host.startLoading();
        const res = await this.userService.getUserSettingValue(
            userSettingsValues.omniGrid.category,
            gridId
        );

        const gridState: PersistedGridState =
            res?.Value && JSON.parse(res.Value);
        if (!gridState) {
            this.host.endLoading();
            return;
        }
        this.host.endLoading();
        this.host.gridState = gridState;
        this.host.ngOnChanges({
            gridState: new SimpleChange(null, gridState, false),
        });
    }

    private async onGridStateChange(gridState: PersistedGridState) {
        await this.userService.setUserSettingValue(
            userSettingsValues.omniGrid.category,
            this.appGridUserSettings,
            JSON.stringify(gridState)
        );
    }
}

import { Pipe, PipeTransform } from '@angular/core';
import { StringUtil } from '@datagalaxy/core-util';

@Pipe({
    name: 'numberFormat',
})
export class NumberFormatPipe implements PipeTransform {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    transform(value: any, ...args: any[]): any {
        return StringUtil.formatNumber(value);
    }
}

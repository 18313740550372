import { IDpMappingItemOptions } from '../dp-mapping.types';
import { GraphicalContainer } from './GraphicalContainer';
import { GraphicalEntityData } from './GraphicalEntityData';
import { IEntityIdentifier } from '@datagalaxy/dg-object-model';

export class GraphicalItem<
    TData extends GraphicalEntityData = GraphicalEntityData
> {
    public container: GraphicalContainer;
    public highlighted: boolean;
    public id: string;
    public readonly entityIdentifier: IEntityIdentifier;

    constructor(public readonly data: TData, opt: IDpMappingItemOptions = {}) {
        this.id = opt.id ?? data.GraphicalUniqueId;
        this.entityIdentifier = data.toEntityIdentifier();
    }
}

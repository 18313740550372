<div class="client-spaces-list-container">
    <div class="table-header">
        <div class="title-container">
            <span> {{ spaceCount$ | async }} </span>
            <span translate> UI.SpacesList.spacesCount </span>
        </div>

        <button
            *ngIf="isCreateAvailable()"
            dxyButton
            color="primary"
            class="create-workspace-button"
            (click)="onCreateProject()"
            dxyDataTestId="create-workspace-button"
        >
            <span class="glyph-add"></span>
            <span translate> UI.OrganizationDashboard.btnNewProject </span>
        </button>
    </div>
    <dxy-search-input
        class="no-border-top"
        (onSearchString)="onSearchTermChange($event)"
    ></dxy-search-input>

    <dxy-spinner
        *ngIf="loading$ | async; else grid"
        class="xl absolute-centered"
    >
    </dxy-spinner>

    <ng-template #grid>
        <dxy-grid
            [columns]="cols"
            [config]="gridConfig"
            [items]="filteredNavSpaces$ | async"
            (rowClick)="onNavSpaceClick($event)"
        >
        </dxy-grid>
    </ng-template>
</div>

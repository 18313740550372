import { TranslateService } from '@ngx-translate/core';
import { AsyncValidatorFn, FormControl } from '@angular/forms';
import { from, map, of, timer } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { DataQualityApiService } from '@datagalaxy/webclient/data-quality/data-access';
import { CurrentSpaceService } from '../../services/currentSpace.service';

/**
 * Service to create validators for code of data quality rules
 */
@Injectable({ providedIn: 'root' })
export class RuleCodeValidatorService {
    constructor(
        private dataQualityApiService: DataQualityApiService,
        private translate: TranslateService,
        private currentSpaceService: CurrentSpaceService
    ) {}

    /**
     * Create an async validator function to check if a rule code is unique.
     * If the provided code is null, undefined, an empty space or
     * just white spaces, the code will be considered as unique.
     *
     * @returns an async validator that takes a FormControl<string> as a parameter
     */
    public uniqueCodeRuleValidator(
        actualCode: string | null = null
    ): AsyncValidatorFn {
        return (codeControl: FormControl<string>) => {
            return timer(500).pipe(
                switchMap(() =>
                    from(this.ruleCodeUniqueness(actualCode, codeControl.value))
                ),
                map((result) => {
                    return result
                        ? null
                        : {
                              ruleCodeAlreadyExists: this.translate.instant(
                                  'UI.DataQualityRules.ruleCodeValidator.codeAlreadyExists',
                                  { code: codeControl.value }
                              ),
                          };
                })
            );
        };
    }

    /**
     * Check if the rule code is unique for the current space.
     * If the provided code is null, undefined, an empty space or
     * just white spaces, the function will return true.
     *
     * @param code code to check
     *
     * @returns true if the code is unique, false otherwise
     */
    public async ruleCodeUniqueness(
        actualCode: string,
        newCode: string
    ): Promise<boolean> {
        // update: return true also if the code is equal to itself
        if (!newCode?.trim?.() || actualCode === newCode) {
            return true;
        }
        try {
            const result = await this.dataQualityApiService.findRuleByCode(
                newCode,
                this.currentSpaceService.currentSpace.ReferenceId.split(':')[0]
            );
            return result === undefined;
        } catch (err) {
            // unable to find a rule by code!
            return true;
        }
    }
}

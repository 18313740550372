import { LineageTreeNode } from '../lineage-tree/lineage-tree-node';
import { LineageNodeStreamDirection } from './lineage-entity-stream.types';
import {
    EntityAttributeLinkGroup,
    EntityAttributeLinks,
} from '../../entity-links/entity-links.types';
import { EntityLinkUtils } from '../../entity-links/entity-link.utils';
import { FlowType } from '../../shared/entity/linked-object.types';
import { EntityLinkService } from '../../entity-links/entity-link.service';
import { getLocalId } from '@datagalaxy/webclient/utils';

export class LineageEntityStreamUtils {
    public static getStreamNodes(node: LineageTreeNode, onlyHidden?: boolean) {
        if (onlyHidden) {
            return node.opened
                ? node.hiddenChildren
                : node.getAllHiddenChildren();
        }

        return node.opened ? [node] : [node, ...node.getAllChildren()];
    }

    public static makeStreamLinkGroup(
        linkGroups: EntityAttributeLinkGroup[],
        direction: LineageNodeStreamDirection
    ): EntityAttributeLinkGroup[] {
        switch (direction) {
            case LineageNodeStreamDirection.Upstream:
                return this.getNodeUpStreamLinks(linkGroups);
            case LineageNodeStreamDirection.Downstream:
                return this.getNodeDownStreamLinks(linkGroups);
            case LineageNodeStreamDirection.TopStream:
                return this.getNodeTopStreamLinks(linkGroups);
            case LineageNodeStreamDirection.BottomStream:
                return this.getNodeBottomStreamLinks(linkGroups);
        }
    }

    public static getEntityAttributeLinksListEdgeId(
        entityAttributeLinks: EntityAttributeLinks[]
    ) {
        return entityAttributeLinks
            .flatMap((entityAttributeLink) =>
                this.getEntityAttributeLinksEdgeId(entityAttributeLink)
            )
            .filter((item) => !!item);
    }

    private static getEntityAttributeLinksEdgeId(
        entityAttributeLink: EntityAttributeLinks
    ): string[] {
        return entityAttributeLink.groups
            ?.filter((group) => group.entityIds?.length)
            .flatMap((group) => {
                const flow = EntityLinkUtils.getLinkTypeFlow(group.linkType);
                const inverted = flow === FlowType.upstream;
                const linkType = inverted
                    ? EntityLinkService.reverseObjectLinkType(group.linkType)
                    : group.linkType;

                return group.entityIds.flatMap((item) => {
                    const source = inverted
                        ? item
                        : entityAttributeLink.entity.ReferenceId;
                    const target = inverted
                        ? entityAttributeLink.entity.ReferenceId
                        : item;

                    return `${getLocalId(source)}:${getLocalId(
                        target
                    )}:${linkType}`;
                });
            })
            .filter((item) => !!item);
    }

    private static getNodeUpStreamLinks(
        linkGroups: EntityAttributeLinkGroup[]
    ) {
        return linkGroups?.filter((group) => {
            const flow = EntityLinkUtils.getLinkTypeFlow(group.linkType);

            return (
                flow === FlowType.upstream &&
                !EntityLinkUtils.isGlossaryLinkType(group.linkType) &&
                group.entityIds?.length
            );
        });
    }

    private static getNodeDownStreamLinks(
        linkGroups: EntityAttributeLinkGroup[]
    ) {
        return linkGroups?.filter((group) => {
            const flow = EntityLinkUtils.getLinkTypeFlow(group.linkType);
            return (
                flow === FlowType.downstream &&
                !EntityLinkUtils.isGlossaryLinkType(group.linkType) &&
                group.entityIds?.length
            );
        });
    }

    private static getNodeTopStreamLinks(
        linkGroups: EntityAttributeLinkGroup[]
    ) {
        return linkGroups?.filter((group) => {
            const flow = EntityLinkUtils.getLinkTypeFlow(group.linkType);
            return (
                flow === FlowType.upstream &&
                EntityLinkUtils.isGlossaryLinkType(group.linkType) &&
                group.entityIds?.length
            );
        });
    }

    private static getNodeBottomStreamLinks(
        linkGroups: EntityAttributeLinkGroup[]
    ) {
        return linkGroups?.filter((group) => {
            const flow = EntityLinkUtils.getLinkTypeFlow(group.linkType);
            return (
                flow === FlowType.downstream &&
                EntityLinkUtils.isGlossaryLinkType(group.linkType) &&
                group.entityIds?.length
            );
        });
    }
}

import { NgModule } from '@angular/core';
import { DxyOmnigridModule } from '@datagalaxy/core-ui/omnigrid';
import { SuggestionCellComponent } from './suggestion-cell/suggestion-cell.component';
import { SuggestionChoiceActionsComponent } from './suggestion-choice-actions/suggestion-choice-actions.component';
import { SuggestionContainerComponent } from './suggestion-container/suggestion-container.component';
import { SuggestionPanelComponent } from './suggestion-panel/suggestion-panel.component';
import { SuggestionTriggerComponent } from './suggestion-trigger/suggestion-trigger.component';
import { SuggestionAttributeFilterManagerComponent } from './suggestion-attribute-filter-manager/suggestion-attribute-filter-manager.component';
import { SuggestionPanelGridComponent } from './suggestion-panel-grid/suggestion-panel-grid.component';
import { SharedModule } from '../shared/shared.module';
import { DxySpaceSelectorModule } from '../space-selector/DxySpaceSelectorModule';
import { AttributeFilterModule } from '../shared/attribute/attribute-filter/AttributeFilterModule';
import { SuggestionPanelGlossaryImportComponent } from './suggestion-panel-glossary-import/suggestion-panel-glossary-import.component';
import { EntityCardModule } from '../shared/entityCard/entity-card.module';
import { SuggestionPanelLinksComponent } from './suggestion-panel-links/suggestion-panel-links.component';
import { DxyFieldsModule } from '@datagalaxy/core-ui/fields';
import { DxyFiltersModule } from '@datagalaxy/core-ui';
import { SuggestionApplyAllButtonComponent } from './suggestion-apply-all-button/suggestion-apply-all-button.component';
import { DxyButtonsModule } from '@datagalaxy/ui/buttons';
import { DxySuggestionModule } from '@datagalaxy/webclient/suggestion/ui';
import { GridComponent } from '@datagalaxy/ui/grid';

@NgModule({
    imports: [
        SharedModule,
        DxyOmnigridModule,
        DxySpaceSelectorModule,
        AttributeFilterModule,
        EntityCardModule,
        DxyFieldsModule,
        DxyFiltersModule,
        DxyButtonsModule,
        DxySuggestionModule,
        GridComponent,
    ],
    declarations: [
        SuggestionContainerComponent,
        SuggestionTriggerComponent,
        SuggestionPanelComponent,
        SuggestionAttributeFilterManagerComponent,
        SuggestionAttributeFilterManagerComponent,
        SuggestionCellComponent,
        SuggestionChoiceActionsComponent,
        SuggestionPanelGridComponent,
        SuggestionPanelGlossaryImportComponent,
        SuggestionPanelLinksComponent,
        SuggestionApplyAllButtonComponent,
    ],
    exports: [
        SuggestionContainerComponent,
        SuggestionTriggerComponent,
        SuggestionCellComponent,
    ],
})
export class SuggestionModule {}

import { IColDef } from '../grid-column/grid-column.types';
import {
    ColumnDef,
    ColumnOrderTableState,
    ColumnPinningState,
    ColumnPinningTableState,
    GroupingTableState,
    SortingTableState,
    VisibilityState,
    VisibilityTableState,
} from '@tanstack/table-core';
import { GridColumnUtils } from '../grid-column/grid-column.utils';
import { GridConfig } from '../grid/grid.types';
import { ArrayUtils } from '../array.utils';

export class TanStackGridAdapter {
    public static convertColumns<TRow>(
        columns: IColDef<TRow>[]
    ): ColumnDef<TRow>[] {
        const avgSize = GridColumnUtils.getAverageColumnSize(columns);
        return columns.map((col) => ({
            id: col.id,
            header: col.headerLabel,
            accessorFn: (row) =>
                GridColumnUtils.getColumnSortableValue(col, row),
            minSize: col.minWidth,
            maxSize: col.maxWidth,
            size: col.width || avgSize,
            meta: col,
            enableSorting: !!col.sortable,
            enableResizing: col.resizable,
        }));
    }

    public static getColumnsInitialVisibility<TRow>(
        columns: IColDef<TRow>[]
    ): VisibilityState {
        return ArrayUtils.toRecord(columns, (col) => [col.id, !col.hidden]);
    }

    public static getColumnsInitialState<TRow>(
        columns: IColDef<TRow>[],
        config?: GridConfig<TRow>
    ): VisibilityTableState &
        ColumnOrderTableState &
        SortingTableState &
        ColumnPinningTableState &
        GroupingTableState {
        const sorting = config?.sortActive
            ? [
                  {
                      id: config?.sortActive,
                      desc: false,
                  },
              ]
            : [];
        const columnPinning: ColumnPinningState = {
            left: columns.filter((c) => c.fixed).map((col) => col.id),
        };

        return {
            columnVisibility: this.getColumnsInitialVisibility(columns),
            columnOrder: columns.map((col) => col.id),
            grouping: config?.groupBy?.columnId
                ? [config.groupBy.columnId]
                : [],
            columnPinning,
            sorting,
        };
    }
}

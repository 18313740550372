import { ISizeRO } from '@datagalaxy/core-2d-util';
import { DiagramConstants } from '../../../diagrams/diagram/diagram.constants';
import { EntityDksNode } from '../../data-knowledge-studio.types';
import { TSizeMode } from '../../../diagrams/diagram/diagram.types';
import { entityNodeConstants } from './entity-node.constants';
import { EntityNodeTree } from './entity-node.types';

export class EntityNodeUtils {
    public static flattenNodeTreeChildren(
        children: EntityNodeTree[]
    ): EntityNodeTree[] {
        const fn = (nodeTrees: EntityNodeTree[]) => {
            const result: EntityNodeTree[] = [];

            if (!nodeTrees) {
                return [];
            }

            for (const nodeTree of nodeTrees) {
                result.push(nodeTree);

                if (nodeTree.children) {
                    result.push(...fn(nodeTree.children));
                }
            }

            return result;
        };
        return fn(children);
    }

    public static findChild(
        node: EntityDksNode,
        nodeId: string
    ): EntityNodeTree | null {
        const children = this.flattenNodeTreeChildren(node.children);

        return children.find((n) => n.entity.ReferenceId === nodeId);
    }

    public static closeNodeTree(nodeTree: EntityNodeTree): void {
        nodeTree.opened = false;

        nodeTree.children?.forEach((n) => {
            this.closeNodeTree(n);
        });
    }

    public static findClosestOpenedNodeId<NodeData>(
        node: EntityDksNode<NodeData>,
        nodeId: string
    ): string | null {
        const flatChildren = this.flattenNodeTreeChildren(node.children);
        const nodeTree = flatChildren.find(
            (n) => n.entity.ReferenceId === nodeId
        );

        if (nodeId === node.entityIdr.ReferenceId) {
            return node.entityIdr.ReferenceId;
        } else if (!nodeTree) {
            return null;
        } else if (!node.opened) {
            return node.entityIdr.ReferenceId;
        }

        const fn = (
            nodeTree: EntityNodeTree,
            nodeTrees: EntityNodeTree[]
        ): string | null => {
            const parent = nodeTrees.find(
                (n) => nodeTree.parent === n.entity.ReferenceId
            );

            if (!parent || parent.opened) {
                return nodeTree.entity.ReferenceId;
            }

            return fn(parent, nodeTrees);
        };

        return fn(nodeTree, flatChildren);
    }

    public static countShowMoreChildren<NodeData>(
        node: EntityDksNode<NodeData>,
        onlyOpened?: boolean
    ): number {
        const fn = (nodeTree: EntityNodeTree | EntityDksNode<NodeData>) => {
            if (!nodeTree.opened && onlyOpened) {
                return 0;
            }
            const nodeShowMoreCount =
                nodeTree.children.length < nodeTree.childrenCount ? 1 : 0;
            return (
                nodeShowMoreCount +
                nodeTree.children.reduce((acc, child) => acc + fn(child), 0)
            );
        };

        return fn(node);
    }

    public static countChildren<NodeData>(
        node: EntityDksNode<NodeData>,
        onlyOpened?: boolean
    ): number {
        if (!node.opened && onlyOpened) {
            return 0;
        }

        const fn = (nodeTrees: EntityNodeTree[]) => {
            let count = 0;

            if (!nodeTrees) {
                return 0;
            }

            for (const nodeTree of nodeTrees) {
                count += 1;

                if (nodeTree.children && (!onlyOpened || nodeTree.opened)) {
                    count += fn(nodeTree.children);
                }
            }

            return count;
        };

        return fn(node.children);
    }

    public static getSizeModeMinSize(
        sizeMode: TSizeMode,
        opt?: {
            extraDataVisible?: boolean;
            hasColor?: boolean;
            childrenCount?: number;
            showMoreItems?: number;
        }
    ): ISizeRO {
        const hasColor = !!opt?.hasColor;
        const childrenHeight = opt?.childrenCount * 30;
        const showMoreHeight = opt?.showMoreItems * 30;
        const extraHeight = opt?.childrenCount || opt?.showMoreItems ? 16 : 0;

        switch (sizeMode) {
            case 'nano':
                return entityNodeConstants.sizeMode.nano;
            case 'mini': {
                if (hasColor) {
                    return {
                        height: entityNodeConstants.sizeMode.mini.height,
                        width:
                            entityNodeConstants.sizeMode.mini.width +
                            entityNodeConstants.entityColorBorderWidth,
                    };
                }
                return entityNodeConstants.sizeMode.mini;
            }
            case 'medium': {
                if (opt?.extraDataVisible) {
                    return {
                        width: entityNodeConstants.sizeMode.medium.min.width,
                        height:
                            entityNodeConstants.sizeMode.medium.min.height +
                            entityNodeConstants.extraDataHeight +
                            childrenHeight +
                            showMoreHeight +
                            extraHeight,
                    };
                }
                return {
                    width: entityNodeConstants.sizeMode.medium.min.width,
                    height:
                        entityNodeConstants.sizeMode.medium.min.height +
                        childrenHeight +
                        showMoreHeight +
                        extraHeight,
                };
            }
            default:
                return entityNodeConstants.sizeMode.maxi;
        }
    }

    public static getSizeModeMaxSize(
        sizeMode: TSizeMode,
        opt?: {
            extraDataVisible?: boolean;
            hasColor?: boolean;
            childrenCount?: number;
            showMoreItems?: number;
        }
    ): ISizeRO {
        const childrenHeight = opt?.childrenCount * 30;
        const showMoreHeight = opt?.showMoreItems * 30;
        const extraHeight = opt?.childrenCount || opt?.showMoreItems ? 16 : 0;

        switch (sizeMode) {
            case 'nano':
                return entityNodeConstants.sizeMode.nano;
            case 'mini': {
                if (opt?.hasColor) {
                    return {
                        height: entityNodeConstants.sizeMode.mini.height,
                        width:
                            entityNodeConstants.sizeMode.mini.width +
                            DiagramConstants.entityColorBorderWidth,
                    };
                }
                return entityNodeConstants.sizeMode.mini;
            }
            case 'medium': {
                if (opt?.extraDataVisible) {
                    return {
                        width: entityNodeConstants.sizeMode.medium.max.width,
                        height:
                            entityNodeConstants.sizeMode.medium.max.height +
                            entityNodeConstants.extraDataHeight +
                            childrenHeight +
                            showMoreHeight +
                            extraHeight,
                    };
                }
                return {
                    width: entityNodeConstants.sizeMode.medium.max.width,
                    height:
                        entityNodeConstants.sizeMode.medium.max.height +
                        childrenHeight +
                        showMoreHeight +
                        extraHeight,
                };
            }
            default:
                return null;
        }
    }
}

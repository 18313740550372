import { CollectionsHelper, DomUtil, StringUtil } from '@datagalaxy/core-util';
import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { OmniGridColumnInfo } from './OmniGridColumnInfo';
import { IDropdownSection } from '../../ui-dropdown-section.types';
import { ISearchTermEvent } from '../../components/search-input/search-input.component';
import { DxyBaseComponent } from '@datagalaxy/ui/core';

@Component({
    selector: 'dxy-omni-grid-column-selector',
    templateUrl: './omni-grid-column-selector.component.html',
    styleUrls: ['./omni-grid-column-selector.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DxyOmniGridColumnSelectorComponent<
        T extends OmniGridColumnInfo = OmniGridColumnInfo
    >
    extends DxyBaseComponent
    implements OnInit
{
    @Input() columnGroups: IDropdownSection[];
    @Input() hideCollapse: boolean;
    @Input() hideSearch: boolean;
    @Input() getColumnGlyphClass: (columnInfo: T) => string;

    @Output() onSearch = new EventEmitter<string>();
    @Output() onReset = new EventEmitter<void>();
    @Output() onColumnToggle = new EventEmitter<T>();

    public searchString = '';
    public menuOpened: boolean;
    public get showDropdown() {
        return !!this.columnGroups?.length;
    }

    constructor(private elementRef: ElementRef<HTMLElement>) {
        super();
    }

    ngOnInit() {
        this.log('columnGroups', this.columnGroups);
    }

    public isSectionOrSubSectionVisible(section: IDropdownSection) {
        return (
            this.isSectionVisible(section) || this.isSubSectionVisible(section)
        );
    }

    public isSectionVisible(section: IDropdownSection) {
        return section?.options?.length;
    }

    public isSubSectionVisible(section: IDropdownSection) {
        return section?.subSections?.length;
    }

    public toggleMenu(open: boolean) {
        this.menuOpened = open;
    }

    public onSearched(e: ISearchTermEvent) {
        this.log('onSearched', e);
        if (e.isFirstSearch) {
            DomUtil.fixMinWidth(this.elementRef, '.columns-selector');
        }
        this.onSearch.emit(e.searchString);
    }
    public onResetClick(event: MouseEvent) {
        event.stopPropagation();
        this.onReset.emit();
    }
    public onColumnToggled(column: T) {
        this.log('onColumnToggled', column);
        this.onColumnToggle.emit(column);
    }

    public getSectionColumns(section: IDropdownSection): T[] {
        const columns = section.options.map((opt) => opt.data as T);
        return CollectionsHelper.orderBy(columns, [
            (c) => (c.visible ? 0 : 1),
            (c) => c.displayName,
        ]);
    }

    public getFoundColumnText(column: T) {
        return StringUtil.boldifyFoundText(
            column.displayName,
            this.searchString,
            true,
            true
        );
    }
    public getColumnGlyphClassInternal(column: T) {
        return this.getColumnGlyphClass?.(column);
    }
}

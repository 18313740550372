import { NgModule } from '@angular/core';
import { DxyFiltersModule } from '@datagalaxy/core-ui/filters';
import { AttributeFilterComponent } from './attribute-filter/attribute-filter.component';
import { AttributeBooleanFilterComponent } from './attribute-boolean-filter/attribute-boolean-filter.component';
import { AttributeTextFilterComponent } from './attribute-text-filter/attribute-text-filter.component';
import { AttributeDateFilterComponent } from './attribute-date-filter/attribute-date-filter.component';
import { AttributeNumberFilterComponent } from './attribute-number-filter/attribute-number-filter.component';
import { AttributeValueListFilterComponent } from './attribute-value-list-filter/attribute-value-list-filter.component';
import { DxySharedUiModule } from '../../shared-ui/DxySharedUiModule';
import { CoreUiModule } from '@datagalaxy/core-ui';
import { AttributeEntityFilterComponent } from './attribute-entity-filter/attribute-entity-filter.component';
import { AttributeFilterFormComponent } from './attribute-filter-form/attribute-filter-form.component';
import { AttributeFilterCarouselComponent } from './attribute-filter-carousel/attribute-filter-carousel.component';
import { AttributeQuickFiltersComponent } from './attribute-quick-filters/attribute-quick-filters.component';
import { AttributeFilterAddDropdownComponent } from './attribute-filter-add-dropdown/attribute-filter-add-dropdown.component';
import { FilterModule } from '@datagalaxy/webclient/filter/ui';

@NgModule({
    imports: [CoreUiModule, DxyFiltersModule, FilterModule, DxySharedUiModule],
    declarations: [
        AttributeFilterComponent,
        AttributeFilterFormComponent,
        AttributeFilterCarouselComponent,
        AttributeQuickFiltersComponent,
        AttributeFilterAddDropdownComponent,
        //#region internal attribute filters
        AttributeBooleanFilterComponent,
        AttributeTextFilterComponent,
        AttributeDateFilterComponent,
        AttributeNumberFilterComponent,
        AttributeValueListFilterComponent,
        AttributeEntityFilterComponent,
        //#endregion internal attribute filters
    ],
    exports: [
        AttributeFilterComponent,
        AttributeFilterFormComponent,
        AttributeFilterCarouselComponent,
        AttributeQuickFiltersComponent,
        AttributeFilterAddDropdownComponent,
    ],
})
export class AttributeFilterModule {}

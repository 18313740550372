<div
    class="extra-data-item"
    [ngClass]="glyph"
    [dxyRichTooltip]="statusTooltipRef"
    [richTooltipPositionOptions]="{ offsetY: 4 }"
    richTooltipPosition="below"
></div>

<ng-template #statusTooltipRef>
    <div class="dg5-custom-tooltip status-tooltip">
        <h3 translate>UI.Diagrams.ExtraData.Values.status</h3>
        <div class="status">
            <span [ngClass]="glyph"></span>
            {{ statusKey | translate }}
        </div>
    </div>
</ng-template>

import { SelectionModel } from '@angular/cdk/collections';
import {
    CdkDragDrop,
    CdkDragStart,
    DragAxis,
    moveItemInArray,
} from '@angular/cdk/drag-drop';
import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { TranslateService } from '@ngx-translate/core';
import { CollectionsHelper, CoreUtil, DomUtil } from '@datagalaxy/core-util';
import {
    EditCellType,
    ICell,
    IColDef,
    IEditGridAPI,
    IEditGridData,
    IHierarchicalDataSource,
    IItemSelectedChange,
    ILocalDataSource,
    ISelectionChange,
    ISelectorOption,
    ISortableDataSource,
    IValueChange,
    TWidthSpec,
} from './edit-grid.types';
import { ICustomCellParams } from '../edit-grid-custom-cell/edit-grid-custom-cell.types';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { DxyBaseComponent } from '@datagalaxy/ui/core';

/** ## Role
 * In-place edit grid
 *
 * ## Features
 * - in place editable cells
 * - text-only cell editor
 * - boolean checkbox cell editor
 * - column sorting
 * - single-select cell editor with optional glyph
 * - readonly (global, by column, by cell)
 * - row multi-select
 * - expandable children-rows
 * - action cell
 * - row ordering by drag
 */
@Component({
    selector: 'dxy-edit-grid',
    templateUrl: './edit-grid.component.html',
})
export class DxyEditGridComponent<TItem>
    extends DxyBaseComponent
    implements IEditGridAPI, OnChanges, OnInit, AfterViewInit, OnDestroy
{
    @Input() data: IEditGridData<TItem>;

    /** any selection change */
    @Output() readonly onSelectionChange = new EventEmitter<
        ISelectionChange<TItem>
    >();
    /** all-items selection change by user */
    @Output() readonly onMasterSelectionChange = new EventEmitter<TItem[]>();
    /** single-item selection change by user */
    @Output() readonly onItemSelectionChange = new EventEmitter<
        IItemSelectedChange<TItem>
    >();
    /** When a cell is being edited (changed or focused depending on options) */
    @Output() readonly onBeginCellEdit = new EventEmitter<ICell<TItem>>();
    /** When a cell value has changed */
    @Output() readonly onValueChanged = new EventEmitter<IValueChange<TItem>>();

    public readonly selectionColId = '__multiSelect';
    public readonly chidrenColId = '__children';
    public readonly orderByDragColId = '__dragOrder';
    public colIds: string[];
    public get options() {
        return this.data?.options;
    }
    public get dataSource() {
        return this.data?.dataSource;
    }
    public get colDefs() {
        return this.data?.colDefs;
    }
    public get sortBy() {
        return this.options?.sortBy ?? this.hasOrderByDrag
            ? this.orderByDragColId
            : undefined;
    }
    public get sortDir() {
        return this.options?.sortDesc ? 'desc' : 'asc';
    }
    public get isAnySelected() {
        return this.selection.hasValue();
    }
    public get isAllSelected() {
        return this.selection.selected.length == this.countSelectables();
    }
    public get orderByDragHeaderText() {
        return this.orderByDragOpt?.headerText ?? '';
    }
    public get orderByDragDisabled() {
        return !this.hasOrderByDrag || this.isGlobalReadonly;
    }
    public get orderByDragLockAxis() {
        return this.orderByDragOpt?.noAxisLock ? undefined : ('y' as DragAxis);
    }
    public get orderByDragPreviewClasses() {
        return [
            'dxy-edit-grid-order-by-drag-preview',
            `instance-${this.instanceKey}`,
            ...(this.classes ? this.classes.split(' ') : []),
        ];
    }
    public get orderByDragShowValue() {
        return this.orderByDragOpt?.showValue;
    }

    private readonly selection = new SelectionModel<TItem>(true, [], true);
    private readonly editedCell: ICell<TItem> = { item: null, colDef: null };
    private readonly focusedCell: ICell<TItem> = { item: null, colDef: null };
    private readonly debouncing = new Map<ColDef, number>();
    private readonly instanceKey = CoreUtil.getRandomString(5);
    @ViewChild(MatSort) private sort: MatSort;
    private get sds() {
        return this.dataSource as ISortableDataSource<TItem>;
    }
    private get hds() {
        return this.dataSource as IHierarchicalDataSource<TItem>;
    }
    private get lds() {
        return this.dataSource as ILocalDataSource<TItem>;
    }
    private get isGlobalReadonly() {
        return CoreUtil.fromFnOrValue(this.options?.readonly);
    }
    @HostBinding('class') private get classes() {
        return this.options?.compact
            ? 'compact no-global-left-padding no-input-padding ellipsis'
            : '';
    }
    private get rowHeight() {
        return (
            this.options?.rowHeight ?? (this.options?.compact ? 25 : undefined)
        );
    }
    private get headerHeight() {
        return (
            this.options?.headerHeight ??
            (this.options?.compact ? 25 : undefined)
        );
    }
    private get hasOrderByDrag() {
        return !!this.options?.orderByDrag;
    }
    private get orderByDragOpt() {
        const opt = this.options?.orderByDrag;
        return typeof opt == 'object' ? opt : undefined;
    }

    constructor(
        private elementRef: ElementRef<HTMLElement>,
        private translate: TranslateService
    ) {
        super();
        this.selection.changed.subscribe((changes) =>
            this.onSelectionChange.emit({
                selection: this.selection.selected,
                changes,
            })
        );
    }

    ngOnChanges(changes: SimpleChanges) {
        super.onChange(changes, 'data', () => this.onInputDataChange());
    }
    ngOnInit() {
        this.initColIds();
        const rowHeight = this.rowHeight,
            headerHeight = this.headerHeight;
        if (rowHeight) {
            DomUtil.setStyleProperty(
                this.elementRef,
                '--row-height',
                rowHeight,
                'px'
            );
        }
        if (headerHeight) {
            DomUtil.setStyleProperty(
                this.elementRef,
                '--header-height',
                headerHeight,
                'px'
            );
        }
    }
    ngAfterViewInit() {
        this.initSort();
    }
    ngOnDestroy() {
        super.ngOnDestroy();
        this.debouncing.forEach(clearTimeout);
        this.debouncing.clear();
    }

    //#region IEditGridAPI
    public getSelected() {
        return this.selection.selected.slice();
    }
    public setSelected(selected: boolean, ...items: TItem[]) {
        if (selected) {
            this.selection.select(...items);
        } else {
            this.selection.deselect(...items);
        }
    }
    public updateColumns(columns: ColDef[]) {
        this.log('updateColumns');
        this.data.colDefs = columns;
        this.initColIds();
    }
    //#endregion

    public getColId(cd: ColDef) {
        return cd.id ?? cd.field;
    }
    public getHeaderText(cd: ColDef) {
        if (!cd) {
            return '';
        }
        return cd.headerText != undefined
            ? cd.headerText
            : (cd.headerKey && this.translate.instant(cd.headerKey)) ||
                  this.getColId(cd);
    }
    public getHeaderTextById(colId: string) {
        let text: string, key: string;
        switch (colId) {
            case this.orderByDragColId:
                text = this.orderByDragOpt?.headerText;
                key = this.orderByDragOpt?.headerKey;
                break;
            default:
                return '';
        }
        return text != undefined
            ? text
            : (key && this.translate.instant(key)) || '';
    }

    //#region Sort
    public isSortDisabled(cd: ColDef) {
        return cd.noSort || this.options?.sortFixed;
    }
    public getSortStart(cd: ColDef) {
        if (!this.options?.sortBy) {
            return;
        }
        return this.options.sortBy == this.getColId(cd) && this.options.sortDesc
            ? 'desc'
            : 'asc';
    }
    public onSortChange() {
        this.sds?.applySort?.();
    }
    private initSort() {
        if (!this.dataSource || !this.sort) {
            return;
        }
        this.sds.sort = this.sort;
    }
    //#endregion

    //#region order by drag column
    public getOrderByDragValue(it: TItem) {
        const getValue = this.orderByDragOpt?.getValue;
        return getValue ? getValue(it) : this.lds.data?.indexOf?.(it);
    }
    public orderByDragOnDrop(event: CdkDragDrop<TItem, TItem, TItem>) {
        if (this.lds.data && !this.orderByDragOpt?.noDataSourceUpdate) {
            const data = this.lds.data.slice();
            moveItemInArray(data, event.previousIndex, event.currentIndex);
            this.lds.data = data;
        }
        this.orderByDragOpt?.onDrop?.(event);
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public orderByDragOnDragStart(event: CdkDragStart<TItem>) {
        const previewRow = document.querySelector<HTMLElement>(
            `.mat-row.dxy-edit-grid-order-by-drag-preview.instance-${this.instanceKey}`
        );
        const rowHeight = this.rowHeight;
        if (rowHeight) {
            DomUtil.setStyleProperty(
                previewRow,
                '--row-height',
                rowHeight,
                'px'
            );
        }
        //console.log('orderByDragOnDragStart', this.rowHeight, previewRow, event)
    }
    //#endregion

    //#region columns width & resize
    public getStyleObj(o: TWidthSpec) {
        const getWidth = (width: string | number) =>
            typeof width == 'number' ? `${width}px` : width;
        return o == undefined
            ? undefined
            : typeof o == 'object'
            ? {
                  'flex-basis': getWidth(o.width),
                  'min-width': getWidth(o.minWidth),
                  'max-width': getWidth(o.maxWidth),
              }
            : { 'flex-basis': getWidth(o) };
    }
    public getStyleObjById(colId: string) {
        return this.getStyleObj(this.getWidthSpecById(colId));
    }
    private getWidthSpecById(colId: string): TWidthSpec {
        switch (colId) {
            case this.chidrenColId:
                return this.options?.toggleChildrenColumnWidth;
            case this.selectionColId:
                return this.options?.multiSelectColumnWidth;
            case this.orderByDragColId:
                return this.options?.orderByDragColumnWidth;
        }
    }
    //#endregion

    //#region rows selection
    /** Selects all rows if they are not all selected; otherwise clear selection. */
    public selectMasterToggle() {
        if (this.isAllSelected) {
            this.selection.clear();
            this.onMasterSelectionChange.emit([]);
            return;
        }
        const data = this.lds.data.slice();
        const items = this.options?.isSelectable
            ? data.filter((it) => this.options.isSelectable(it))
            : data;
        this.selection.select(...items);
        this.onMasterSelectionChange.emit(items);
    }
    public isSelectable(item: TItem) {
        return !this.options?.isSelectable || this.options?.isSelectable(item);
    }
    public isSelected(item: TItem) {
        return this.selection.isSelected(item);
    }
    public selectToggle(item: TItem) {
        this.selection.toggle(item);
        this.onItemSelectionChange.emit({
            item,
            selected: this.selection.isSelected(item),
        });
    }
    //#endregion

    //#region expand/collapse
    public showHideChildrenMasterClick(event: Event) {
        event.stopPropagation();
        this.hds?.setAllExpanded?.(this.hds?.isAnyCollapsed());
    }
    public showHideChildrenClick(it: TItem, event: Event) {
        event.stopPropagation();
        this.hds?.toggleExpanded?.(it);
    }
    public isChildrenMasterVisible() {
        return this.hds?.isAnyCollapsed() || this.hds?.isAnyExpanded();
    }
    public hasChildren(it: TItem) {
        return this.hds?.hasChildren(it);
    }
    public getMasterParentClass() {
        return this.hds.isAnyCollapsed()
            ? 'glyph-arrow-drop-down--collapsed'
            : '';
    }
    public getParentClass(it: TItem) {
        return this.hds.isExpandable(it)
            ? 'glyph-arrow-drop-down--collapsed'
            : '';
    }
    //#endregion

    //#region cell
    public isReadonly(it: TItem, cd: ColDef) {
        if (this.isGlobalReadonly) {
            return true;
        }
        if (cd.isReadOnly) {
            try {
                return cd.isReadOnly(it);
            } catch (e) {
                CoreUtil.warn(e, it, cd);
            }
        } else {
            return cd.readonly;
        }
    }
    public isDisabled(it: TItem, cd: ColDef) {
        return cd.isDisabled?.(it);
    }
    public getValue<T>(it: TItem, cd: ColDef): T {
        if (!it) {
            return;
        }
        if (cd.getValue) {
            try {
                return cd.getValue(it);
            } catch (e) {
                CoreUtil.warn(e, it, cd);
            }
        } else {
            return it[cd.field];
        }
    }
    public getText(it: TItem, cd: ColDef) {
        switch (cd.type) {
            case EditCellType.booleanYesNo:
            case EditCellType.booleanSlider:
                return this.getCellText_boolean(this.getValue<boolean>(it, cd));
            default:
                if (!it) {
                    return '';
                }
                if (cd.getText) {
                    try {
                        return cd.getText(it) ?? '';
                    } catch (e) {
                        CoreUtil.warn(e, it, cd);
                        return '';
                    }
                }
                return String(this.getValue<string>(it, cd) ?? '');
        }
    }

    public getCustomCellParam(it: TItem, cd: ColDef): ICustomCellParams<TItem> {
        return {
            component: cd.customCellComponent,
            params: {
                data: it,
                value: cd.getValue(it),
                inputs: cd.inputs,
            },
        };
    }
    //#endregion

    //#region in-cell selector
    public getOptions(it: TItem, cd: ColDef) {
        return cd.getOptions?.(it);
    }
    public getOptionText(o: ISelectorOption) {
        return o ? o.text ?? o.value : '';
    }
    public getCurrentOption(it: TItem, cd: ColDef) {
        const value = this.getValue(it, cd);
        return this.getOptions(it, cd)?.find((o) => o.value === value);
    }
    //#endregion

    //#region action-cell
    public onActionClick(event: FocusEvent, it: TItem, cd: ColDef) {
        cd.actionCallback?.(it);
    }
    public getActionTooltip(it: TItem, cd: ColDef) {
        if (cd.actionTooltipText) {
            return CoreUtil.fromFnOrValue(cd.actionTooltipText, it);
        }
        if (cd.actionTooltipKey) {
            return this.translate.instant(
                CoreUtil.fromFnOrValue(cd.actionTooltipKey, it)
            );
        }
    }
    public isActionDisabled(it: TItem, cd: ColDef) {
        return (
            this.isType_action(cd) &&
            (this.isGlobalReadonly || cd.actionDisabled?.(it))
        );
    }

    public getActionGlyphClass(it: TItem, cd: ColDef) {
        return CoreUtil.fromFnOrValue(cd.actionGlyphClass, it);
    }

    //#endregion

    public getRowClass(it: TItem) {
        return CoreUtil.fromFnOrValue(this.options?.customRowClass, it);
    }

    public async onValueChange(value: any, it: TItem, cd: ColDef) {
        this.log('onValueChange', value, it, cd);

        if (
            this.isNewCell(it, cd, this.editedCell) &&
            !this.options?.beginCellEditOnFocus
        ) {
            this.onBeginCellEdit.emit({ item: it, colDef: cd });
        }

        if (!it) {
            return;
        }

        if (cd.type === EditCellType.booleanSlider) {
            const editAllowed = await cd.editAllowed(it, value);
            if (!editAllowed) {
                const event = value as MatSlideToggleChange;
                event.source.checked = !event.checked;
                return;
            }
        }

        const previousValue = this.getValue(it, cd);

        const action = () => {
            if (cd.onValueChange) {
                cd.onValueChange(it, value);
            } else if (cd.field) {
                it[cd.field] = value;
            }

            const currentValue = this.getValue(it, cd);
            this.onValueChanged.emit({
                item: it,
                colDef: cd,
                previousValue,
                currentValue,
            });
        };
        if (cd.debounceTimeMs != undefined) {
            clearTimeout(this.debouncing.get(cd));
            this.debouncing.set(
                cd,
                window.setTimeout(() => {
                    this.debouncing.delete(cd);
                    action();
                }, cd.debounceTimeMs)
            );
        } else {
            action();
        }
    }

    public onFocusIn(event: FocusEvent, it: TItem, cd: ColDef) {
        this.log('onFocusIn', event, it, cd);
        if (
            this.isNewCell(it, cd, this.focusedCell) &&
            this.options?.beginCellEditOnFocus
        ) {
            this.onBeginCellEdit.emit({ item: it, colDef: cd });
        }
    }

    public isType_textOrDefault(cd: ColDef) {
        return !cd.type;
    }
    public isType_booleanYesNo(cd: ColDef) {
        return cd.type == EditCellType.booleanYesNo;
    }
    public isType_booleanSlider(cd: ColDef) {
        return cd.type == EditCellType.booleanSlider;
    }
    public isType_booleanRadio(cd: ColDef) {
        return cd.type == EditCellType.booleanRadio;
    }
    public isType_selector(cd: ColDef) {
        return cd.type == EditCellType.selector;
    }
    public isType_action(cd: ColDef) {
        return cd.type == EditCellType.action;
    }
    public isType_custom(cd: ColDef) {
        return cd.type == EditCellType.custom;
    }

    private getCellText_boolean(value: boolean) {
        return this.translate.instant(
            `UI.Global.${value ? 'btnYes' : 'btnNo'}`
        );
    }

    private initColIds() {
        this.colIds =
            this.colDefs
                ?.filter((cd) => !cd.hidden)
                .map((cd) => this.getColId(cd)) ?? [];
        if (this.options?.orderByDrag) {
            this.colIds.unshift(this.orderByDragColId);
        }
        if (this.options?.multiSelect) {
            this.colIds.unshift(this.selectionColId);
        }
        if (this.options?.toggleChildren) {
            this.colIds.unshift(this.chidrenColId);
        }
        this.debouncing.clear();
    }

    private onInputDataChange() {
        this.log('onInputDataChange');
        this.initColIds();
        this.selection.clear();
        this.initSort();
    }

    private countSelectables() {
        const data = this.lds?.data;
        if (!data) {
            return 0;
        }
        if (!this.options?.isSelectable) {
            return data.length;
        }
        return CollectionsHelper.sum(data, (it) =>
            this.options.isSelectable(it) ? 1 : 0
        );
    }

    private isNewCell(it: TItem, cd: ColDef, cell: ICell<TItem>) {
        if (this.isSameCell(it, cd, cell)) {
            return false;
        }
        cell.item = it;
        cell.colDef = cd;
        return true;
    }
    private isSameCell(it: TItem, cd: ColDef, cell: ICell<TItem>) {
        return cell.item == it && cell.colDef == cd;
    }
}

type ColDef = IColDef<unknown>;

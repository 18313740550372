import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    Output,
} from '@angular/core';
import { DomUtil } from '@datagalaxy/core-util';
import { OverlayContainer } from 'ngx-toastr';
import { fadeInFadeOutAnimation } from '../../animations/fade-in-fade-out.animation';
import { DxyBaseComponent } from '@datagalaxy/ui/core';

/**
 * ## Role
 * Display a panel container
 *
 * ## Features
 * - Display any transcluded content
 * - Manage click outside event to close panel
 */
@Component({
    selector: 'dxy-panel-host',
    templateUrl: './panel-host.component.html',
    styleUrls: ['./panel-host.component.scss'],
    animations: [fadeInFadeOutAnimation()],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DxyPanelHostComponent extends DxyBaseComponent {
    @Input() panelOpened: boolean | null = false;
    @Input() disableAutoClosing = false;
    @Input() toggleButton?: HTMLElement;
    @Output() panelOpenedChange = new EventEmitter<boolean>();

    /**
     * When true panel is closed on document:click event
     * Set by onMouseDown method
     */
    private closeOnClick?: boolean;

    constructor(
        private elementRef: ElementRef<HTMLElement>,
        private overlayContainer: OverlayContainer
    ) {
        super();
    }

    /**
     * Detect if target is outside the panel and set closeOnClick value
     * @param target
     * @returns
     */
    @HostListener('document:mousedown', ['$event.target'])
    private onMouseDown(target: EventTarget) {
        if (!this.panelOpened) {
            return;
        }
        if (!(target instanceof HTMLElement)) {
            return;
        }

        // Close on click when target is outside the panel except when it's in overlay
        this.closeOnClick = this.isOutside(target);
    }

    /**
     * Close panel on click outside
     * @param target clicked element
     * @returns
     */
    @HostListener('document:click', ['$event.target'])
    private onClickOutside(target: EventTarget) {
        let shouldClose = this.closeOnClick;
        this.closeOnClick = undefined;
        if (!this.panelOpened) {
            return;
        }
        if (this.disableAutoClosing) {
            return;
        }
        if (!(target instanceof HTMLElement)) {
            return;
        }
        if (this.toggleButton?.contains(target)) {
            return;
        }

        // Close panel even if target element stop mousedown event propagation
        shouldClose =
            shouldClose != undefined ? shouldClose : this.isOutside(target);

        if (shouldClose) {
            this.togglePanel();
        }
    }

    public togglePanel() {
        this.panelOpened = !this.panelOpened;
        this.panelOpenedChange.emit(this.panelOpened);
    }

    private isOutside(target: HTMLElement) {
        return (
            !this.elementRef.nativeElement.contains(target) &&
            !this.isFromOverlay(target)
        );
    }

    private isFromOverlay(target: HTMLElement) {
        const overlayEl = this.overlayContainer.getContainerElement();
        if (overlayEl.contains(target)) {
            return true;
        }
        const parents = DomUtil.getParents(target);
        if (
            parents.some((e) => e.className.includes('cdk-overlay-container'))
        ) {
            return true;
        }
        return false;
    }
}

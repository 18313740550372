import { NgModule } from '@angular/core';
import { AccountLoginComponent } from './account-login/account-login.component';
import { AccountSetPasswordComponent } from './account-set-password/account-set-password.component';
import { AccountExpiredTrialPageComponent } from './account-expired-trial-page/account-expired-trial-page.component';
import { AccountSharedModule } from './account-shared.module';
import { UIRouterModule } from '@uirouter/angular';
import { accountRoutes } from './account.routes';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule } from '@angular/forms';
import { DxyCoreDirectivesModule } from '@datagalaxy/core-ui';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { CommonModule } from '@angular/common';
import { DxyFieldsModule } from '@datagalaxy/core-ui/fields';
import { SplashScreenComponent } from '@datagalaxy/ui/illustration';

@NgModule({
    imports: [
        UIRouterModule.forChild({ states: accountRoutes }),
        DxyFieldsModule,
        MatButtonModule,
        CommonModule,
        MatCheckboxModule,
        DxyCoreDirectivesModule,
        TranslateModule,
        ReactiveFormsModule,
        AccountSharedModule,
        SplashScreenComponent,
    ],
    declarations: [
        AccountExpiredTrialPageComponent,
        AccountLoginComponent,
        AccountSetPasswordComponent,
    ],
})
export class AccountModule {}

import { BurgerMenuActionProviderBase, IActionDef } from '@datagalaxy/core-ui';
import { IDpMappingBurgerMenuActionProviderHost } from '../dp-mapping.types';
import { ObjectLinkType } from '@datagalaxy/dg-object-model';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';

/** ## Role
 * OmniGrid sub-component providing actions for the context-menu of a renderer
 * in the Data-processing Mapping UI
 *
 * (ie, for the available items in the list to the right)
 */
export class DpMappingBurgerMenuActionProvider extends BurgerMenuActionProviderBase<
    DpMappingBurgerMenuAction,
    EntityItem,
    ObjectLinkType
> {
    private readonly btnAddStructure: IDpMappingActionDef = {
        actionId: DpMappingBurgerMenuAction.btnAddStructureOnly,
        isAvailable: () => true,
        tooltipKey: (item, objectLinkType) => {
            const isInput = objectLinkType === ObjectLinkType.HasInput;
            return this.isUsedInList(item, isInput)
                ? `UI.DataProcessing.mapping.${
                      isInput ? 'btnAddToInput' : 'btnAddToOutput'
                  }Disabled`
                : '';
        },
        disabled: (item, objectLinkType) =>
            this.isUsedInList(item, objectLinkType === ObjectLinkType.HasInput),
        glyphClass: 'glyph-table',
        execute: (item, context) => this.host.addItem(item, context),
    };

    private readonly btnAddStructureChildren: IDpMappingActionDef = {
        actionId: DpMappingBurgerMenuAction.btnAddStructureChildren,
        isAvailable: () => true,
        disabled: () => false,
        glyphClass: 'glyph-field',
        execute: (item, context) => this.host.addItem(item, context, true),
    };

    constructor(
        private host: IDpMappingBurgerMenuActionProviderHost,
        private isUsedInList: (entity: EntityItem, isInput?: boolean) => boolean
    ) {
        super();
        this.actionDefs = [this.btnAddStructure, this.btnAddStructureChildren];
    }

    protected getTranslateKey(actionId: DpMappingBurgerMenuAction) {
        return `UI.DataProcessing.mapping.${DpMappingBurgerMenuAction[actionId]}`;
    }
}

enum DpMappingBurgerMenuAction {
    btnAddStructureOnly,
    btnAddStructureChildren,
}

interface IDpMappingActionDef
    extends IActionDef<DpMappingBurgerMenuAction, EntityItem, ObjectLinkType> {}

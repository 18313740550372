import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
    materialCdkModules,
    materialComponentModules,
} from './material-modules';
import { DxyCollapsibleComponent } from './components/collapsible/collapsible.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_LEGACY_TOOLTIP_DEFAULT_OPTIONS as MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/legacy-tooltip';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import { CDK_DRAG_CONFIG } from '@angular/cdk/drag-drop';
import { CorePipesModule } from './pipes/core-pipes.module';
import { dxyCustomTooltipDefaults } from './DxyMaterialOptionsConstants';
import { DxySearchInputComponent } from './components/search-input/search-input.component';
import { DxyTabsHeaderComponent } from './components/tabs-header/tabs-header.component';
import { DxyCoreDirectivesModule } from './directives/core-directives.module';
import { DxyRendererComponent } from './components/renderer/renderer.component';
import { DxyTrendPillComponent } from './components/trend-pill/trend-pill.component';
import { DxyPercentageDonutComponent } from './components/percentage-donut/percentage-donut.component';
import { DxySplitButtonComponent } from './components/split-button/split-button.component';
import { DxyOptionListComponent } from './components/option-list/option-list.component';
import { DxyBurgerMenuButtonComponent } from './components/burger-menu-button/burger-menu-button.component';
import { DxyDropdownSectionComponent } from './components/dropdown-section/dropdown-section.component';
import { DxyEditGridComponent } from './components/edit-grid/edit-grid.component';
import { DxyMultiselectListComponent } from './components/multiselect-list/multiselect-list.component';
import { DxyActionListComponent } from './components/action-list/action-list.component';
import { cellComponentsList } from './cell-components/cell-components-list';
import { DxyNavigationPanelComponent } from './components/navigation-panel/navigation-panel.component';
import { DxyRichTooltipContentComponent } from './components/rich-tooltip-content/rich-tooltip-content.component';
import { DxyActionListItemComponent } from './components/action-list-item/action-list-item.component';
import { DxyEditGridCustomCellComponent } from './components/edit-grid-custom-cell/edit-grid-custom-cell.component';
import { DxyPanelHostComponent } from './components/panel-host/panel-host.component';
import { DxyTranslateModule } from './translate/translate.module';
import { DxyShowMoreButtonComponent } from './components/show-more-button/show-more-button.component';
import { DxyMatBurgerMenuModule } from './components/mat-burger-menu/mat-burger-menu.module';
import { DxyOptionItemModule } from './components/option-item/option-item.module';
import { DxyColorPointCellComponent } from './cell-components/color-point-cell/color-point-cell.component';
import { DxyBurgerMenuComponent } from './components/burger-menu/burger-menu.component';
import { DxySkeletonListComponent } from './skeleton/skeleton-list/skeleton-list.component';
import { SpinnerComponent } from '@datagalaxy/ui/spinner';
import { ToasterModule } from '@datagalaxy/ui/toaster';
import { DxyRichTooltipDirective } from '@datagalaxy/ui/tooltip';
import { DxyButtonsModule } from '@datagalaxy/ui/buttons';

@NgModule({
    providers: [
        {
            provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
            useValue: dxyCustomTooltipDefaults,
        },
        { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
        // the default locale
        { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },
        { provide: CDK_DRAG_CONFIG, useValue: { zIndex: 10000 } },
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        DxyTranslateModule.forChild({ extend: true }),
        ...materialComponentModules,
        ...materialCdkModules,
        CorePipesModule,
        DxyCoreDirectivesModule,
        DxyMatBurgerMenuModule,
        DxyOptionItemModule,
        DxyRendererComponent,
        DxyColorPointCellComponent,
        SpinnerComponent,
        ToasterModule,
        DxyRichTooltipDirective,
        DxyButtonsModule,
    ],
    declarations: [
        DxyCollapsibleComponent,
        DxySearchInputComponent,
        DxyTabsHeaderComponent,
        DxyTrendPillComponent,
        DxyPercentageDonutComponent,
        DxySplitButtonComponent,
        DxyOptionListComponent,
        DxyBurgerMenuButtonComponent,
        DxyDropdownSectionComponent,
        DxyEditGridComponent,
        ...cellComponentsList,
        DxyMultiselectListComponent,
        DxyActionListComponent,
        DxyActionListItemComponent,
        DxyNavigationPanelComponent,
        DxyRichTooltipContentComponent,
        DxyEditGridCustomCellComponent,
        DxyPanelHostComponent,
        DxyShowMoreButtonComponent,
        DxySkeletonListComponent,
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        DxyTranslateModule,
        DxyRendererComponent,
        DxyColorPointCellComponent,
        ...materialComponentModules,
        ...materialCdkModules,
        DxyCoreDirectivesModule,
        CorePipesModule,
        SpinnerComponent,
        DxyCollapsibleComponent,
        DxySearchInputComponent,
        DxyTabsHeaderComponent,
        DxyTrendPillComponent,
        DxyPercentageDonutComponent,
        DxySplitButtonComponent,
        DxyOptionListComponent,
        DxyBurgerMenuButtonComponent,
        DxyDropdownSectionComponent,
        DxyEditGridComponent,
        ...cellComponentsList,
        DxyMultiselectListComponent,
        DxyActionListComponent,
        DxyNavigationPanelComponent,
        DxyRichTooltipContentComponent,
        DxyPanelHostComponent,
        DxyShowMoreButtonComponent,
        DxyOptionItemModule,
        DxyBurgerMenuComponent,
        DxySkeletonListComponent,
        ToasterModule,
    ],
})
export class CoreUiModule {
    public static forRoot(): ModuleWithProviders<CoreUiModule> {
        return {
            ngModule: CoreUiModule,
            providers: [
                // Put here all custom providers (for example: mock services)
            ],
        };
    }
}

import { Directive, Input, OnInit } from '@angular/core';
import { SplitComponent } from 'angular-split';
import { AngularSplitService } from '../services/angular-split.service';
import { DxyBaseDirective } from '@datagalaxy/ui/core';

/**
 * ## Role
 * Synchronize *angular-split* component's area sizes and persisted user settings
 * */
@Directive({ selector: '[appAsSplitPersistenceId]' })
export class AngularSplitPersistDirective
    extends DxyBaseDirective
    implements OnInit
{
    /** Id under wich the splitter layout is to be persisted in the user settings */
    @Input('appAsSplitPersistenceId') persistenceId: string;

    constructor(
        private host: SplitComponent,
        private angularSplitService: AngularSplitService
    ) {
        super();
    }

    ngOnInit() {
        super.registerSubscription(
            this.angularSplitService.register(this.host, this.persistenceId)
        );
    }
}

import { DomUtil } from '@datagalaxy/core-util';
import {
    AfterViewInit,
    Component,
    ElementRef,
    Input,
    OnInit,
} from '@angular/core';
import { IEntityIdentifier } from '@datagalaxy/dg-object-model';
import { ImpactAnalysisTool } from '../../shared/util/app-types/impact-analysis.types';
import { ExplorationGraphData } from '../data/ExplorationGraphData';
import { ImpactAnalysisService } from '../impact-analysis.service';
import { LineageGraphParams } from '../lineage-graph/data/LineageGraphParams';
import { NavigationService } from '../../services/navigation.service';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { SpaceIdentifier } from '@datagalaxy/webclient/workspace/utils';

@Component({
    selector: 'app-impact-analysis-main',
    templateUrl: 'impact-analysis-main.component.html',
})
export class ImpactAnalysisMainComponent
    extends DxyBaseComponent
    implements OnInit, AfterViewInit
{
    @Input() entityIdr: IEntityIdentifier;
    @Input() selectedTool: ImpactAnalysisTool;

    public readonly defaultTool = ImpactAnalysisTool.LineageBoth;
    public explorerGraphData: ExplorationGraphData;
    public lineageGraphParams: LineageGraphParams;
    public get isActiveToolLineage() {
        return ImpactAnalysisService.isLineageLegacy(this.selectedTool);
    }
    public get isActiveToolExplorer() {
        return ImpactAnalysisService.isExplorer(this.selectedTool);
    }

    constructor(
        private element: ElementRef<HTMLElement>,
        private impactAnalysisService: ImpactAnalysisService,
        private navigationService: NavigationService
    ) {
        super();
    }

    ngOnInit() {
        this.init().then();
    }

    ngAfterViewInit() {
        setTimeout(
            () =>
                DomUtil.focusElement(this.element, 'dxy-entity-selector input'),
            333
        );
    }

    private async init() {
        if (this.selectedTool == undefined) {
            this.selectedTool = this.defaultTool;
        }
        this.log('init', this.entityIdr, this.selectedTool);

        if (this.isActiveToolLineage) {
            this.entityIdr ??=
                this.impactAnalysisService.getCachedLineageOriginIdr();
            this.lineageGraphParams =
                this.impactAnalysisService.getLineageGraphParams(
                    this.entityIdr,
                    this.selectedTool
                );
        } else if (this.isActiveToolExplorer) {
            this.explorerGraphData =
                await this.impactAnalysisService.initExploratoryData(
                    this.entityIdr
                );
        }

        super.subscribe(
            this.impactAnalysisService.onSelectedToolChange$,
            (tool) => this.onSelectedToolChange(tool)
        );
    }

    private async onSelectedToolChange(newTool: ImpactAnalysisTool) {
        this.log('onSelectedToolChange', newTool);
        const currentSpace = SpaceIdentifier.fromEntity(this.entityIdr);
        return this.navigationService.goToImpactAnalysis(
            currentSpace,
            this.entityIdr,
            newTool
        );
    }
}

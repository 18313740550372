import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    NgZone,
    Output,
    ViewChild,
} from '@angular/core';
import {
    DxyGraphicalContextMenuComponent,
    IFunctionalEvent,
} from '@datagalaxy/core-ui';
import { MovePhase } from '@datagalaxy/core-2d-util';
import { DksContextualMenuOptions } from '../../config/dks-config.types';
import { DksGraphSurface } from '../../data-knowledge-studio.types';
import { DxyBaseComponent } from '@datagalaxy/ui/core';

@Component({
    selector: 'app-dks-contextual-menu',
    templateUrl: './dks-contextual-menu.component.html',
})
export class DksContextualMenuComponent<NodeData, EdgeData>
    extends DxyBaseComponent
    implements AfterViewInit
{
    @Input() graphSurface: DksGraphSurface<NodeData, EdgeData>;
    @Input() config: DksContextualMenuOptions<NodeData, EdgeData>;

    @Output() readonly functional = new EventEmitter<IFunctionalEvent>();

    @ViewChild(DxyGraphicalContextMenuComponent)
    private graphicalContextMenu: DxyGraphicalContextMenuComponent;

    constructor(private ngZone: NgZone) {
        super();
    }

    ngAfterViewInit() {
        const events = this.graphSurface.events;
        super.subscribe(events.selectionUpdated$, () =>
            this.showGraphicalContextMenu()
        );
        super.subscribe(events.nodeDragged$, (event) =>
            this.updateContextMenuWithMovePhase(event.phase)
        );
        super.subscribe(events.resized$, (event) =>
            this.updateContextMenuWithMovePhase(event.phase)
        );
        super.subscribe(events.selectionChanged$, () =>
            this.showGraphicalContextMenu()
        );
        super.subscribe(events.zoomed$, (event) =>
            this.updateContextMenuWithMovePhase(event.phase)
        );
        super.subscribe(events.viewportChanged$, (phase) =>
            this.updateContextMenuWithMovePhase(phase)
        );
    }

    private updateContextMenuWithMovePhase(phase: MovePhase) {
        if (phase === MovePhase.start) {
            this.ngZone.run(() => this.graphicalContextMenu.hide());
        } else if (phase === MovePhase.end || phase === MovePhase.cancel) {
            this.ngZone.run(() => this.showGraphicalContextMenu());
        }
    }

    private showGraphicalContextMenu() {
        const selectedNodes = this.graphSurface.selection.selectedNodes;

        if (selectedNodes?.length) {
            const options = this.config?.nodeMenuBuilder?.(selectedNodes);
            const els = selectedNodes.map((node) => node.data.el);
            this.graphicalContextMenu.show(options, els, this.graphSurface.el);
            return;
        }

        const selectedEdges = this.graphSurface.selection.selectedEdges;

        if (selectedEdges?.length) {
            const options = this.config?.edgeMenuBuilder?.(selectedEdges);
            const els = selectedEdges.map((edge) => edge.connector.el);
            this.graphicalContextMenu.show(options, els, this.graphSurface.el);
            return;
        }

        this.graphicalContextMenu.hide();
    }
}

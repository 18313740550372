import { BaseWidgetComponent } from './widget-interfaces';
import { Widget } from './domain/widget';

export const widgetTypeMappings = new Map<string, Widget>();

/**
 * Register an widget implementing IWidget to the widget registry
 * @param widgetConstructor widget component constructor
 * @param widget widget definition.
 * Needed because in production classes are minified, so we can't use "type.name"
 * @returns
 */
export function registerWidgetType(
    widgetConstructor: new () => BaseWidgetComponent,
    widget: Partial<Widget>
) {
    widget.componentConstructor = widgetConstructor;
    if (!widgetConstructor || !widget || widgetTypeMappings.has(widget.name)) {
        return;
    }
    widgetTypeMappings.set(widget.name, widget as Widget);
}

import { Injectable } from '@angular/core';
import { RestApiService } from '@datagalaxy/data-access';
import { GetSpaceHomePageQueryResult } from './queries/get-space-home-page-query-result';
import { SpaceHomePageDto } from './dto';

@Injectable({ providedIn: 'root' })
export class SpaceHomePageApiService {
    constructor(private restApiService: RestApiService) {}

    public async getSpaceHomePage(
        spaceGuid: string
    ): Promise<GetSpaceHomePageQueryResult> {
        return await this.restApiService.getPromise(
            `space/${spaceGuid}/home-page`
        );
    }

    public async createSpaceHomePage(
        homePage: SpaceHomePageDto
    ): Promise<void> {
        return await this.restApiService.postPromise(
            `space/${homePage.SpaceUid}/home-page`,
            homePage
        );
    }

    public async updateSpaceHomePage(homePage: SpaceHomePageDto) {
        return await this.restApiService.putPromise(
            `space/${homePage.SpaceUid}/home-page`,
            homePage
        );
    }
}

<header class="header">
    <dxy-space-icon [workspace]="currentSpace"></dxy-space-icon>
    <h3 class="header-title" dxyEllipsisTooltip>
        {{ currentSpace?.DisplayName }}
    </h3>
    <div *ngIf="hasEditRights" class="header-settings">
        <button
            *ngIf="!editing"
            [disabled]="dashboardEditionEnabled"
            type="button"
            mat-icon-button
            (click)="onEditModeClick()"
            [matTooltip]="
                'UI.SpaceHome.Widgets.SpaceDescription.UpdateDescription'
                    | translate
            "
            [matTooltipDisabled]="dashboardEditionEnabled"
            dxyDataTestId="edit-workspace-button"
        >
            <i class="glyph-edit"></i>
        </button>
        <button
            *ngIf="editing"
            type="button"
            class="accept-btn active"
            mat-icon-button
            (click)="onValidateEdit()"
            [matTooltip]="'UI.Global.filter.save' | translate"
            dxyDataTestId="save-edit-workspace-button"
        >
            <i class="glyph-check"></i>
        </button>
        <button
            *ngIf="editing"
            type="button"
            mat-icon-button
            (click)="onCancelEdit()"
            [matTooltip]="'UI.Global.btnCancel' | translate"
            dxyDataTestId="cancel-edit-workspace-button"
        >
            <i class="glyph-cancelsearch"></i>
        </button>
    </div>
</header>
<section class="body" [class.disabled]="dashboardEditionEnabled">
    <div class="form-horizontal attribute-form-container">
        <dxy-rich-text-field
            *ngIf="!showPlaceholder"
            class="no-readonly-separator no-min-width"
            toolbarDisplayMode="always"
            [class.editing]="editing"
            [labelKey]="descriptionLabelKey"
            [formControl]="descriptionControl"
            [mentionResolvers]="mentionResolvers"
            [noTabCapture]="true"
            [readonly]="!editing"
            [takeFocus]="true"
            [errorMessageText]="errorMessageKey"
            dxyDataTestId="workspace-description-text-field"
        ></dxy-rich-text-field>
    </div>

    <div *ngIf="showPlaceholder" class="dxy-placeholder">
        <img
            class="dxy-placeholder-img"
            src="/images/space-widgets/space-description-placeholder.svg"
        />
        <span class="dxy-placeholder-text" translate
            >UI.SpaceHome.Widgets.SpaceDescription.Placeholder</span
        >
    </div>
</section>

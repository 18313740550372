import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    OnInit,
    Output,
} from '@angular/core';
import { CoreUtil, StringUtil } from '@datagalaxy/core-util';
import { TranslateService } from '@ngx-translate/core';
import {
    SearchFacetFilterSettings,
    SearchFacetFilterSettingsGroup,
    SearchFacetFilterSettingsItem,
} from '../models/SearchFacetFilterSettings';
import { SearchFacetSettingsService } from '../search-facet-settings.service';
import { AttributeDataService } from '../../../shared/attribute/attribute-data.service';
import { DxyBaseComponent } from '@datagalaxy/ui/core';

@Component({
    selector: 'dxy-search-facet-settings',
    templateUrl: './dxy-search-facet-settings.component.html',
    styleUrls: ['./dxy-search-facet-settings.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DxySearchFacetSettingsComponent
    extends DxyBaseComponent
    implements OnInit
{
    @Output() onChangeSettings = new EventEmitter();

    public filteredSettings: SearchFacetFilterSettings;
    public searchTerm = '';

    private settings: SearchFacetFilterSettings;

    constructor(
        private translate: TranslateService,
        private searchFacetSettingsService: SearchFacetSettingsService,
        private attributeDataService: AttributeDataService
    ) {
        super();
    }

    ngOnInit() {
        this.filteredSettings = this.settings =
            this.searchFacetSettingsService.settings;
        this.translateTypes();
    }

    private translateTypes() {
        this.settings.groups.forEach((group) => {
            group.filters.forEach((filter) => {
                filter.displayName = this.translate.instant(
                    `UI.Search.Filters.group${filter.type}`
                );
                filter.highlightText = filter.displayName;
            });
        });
    }

    public onSearch(searchTerm: string) {
        this.filteredSettings = CoreUtil.cloneDeep(this.settings);
        this.filteredSettings.groups.forEach((group) => {
            group.filters = StringUtil.filterSearched(
                searchTerm,
                group.filters,
                (f) => f.displayName
            );
            group.filters.forEach(
                (f) =>
                    (f.highlightText = StringUtil.boldifyFoundText(
                        f.displayName,
                        searchTerm,
                        true
                    ))
            );
        });
    }

    public onChangeItem(
        group: SearchFacetFilterSettingsGroup,
        item: SearchFacetFilterSettingsItem
    ) {
        this.sortGroupFilters(group);
        const settingGroup = this.settings.groups.find(
            (g) => g.translateTextKey === group.translateTextKey
        );
        const settingItem = settingGroup.filters.find(
            (d) => d.type === item.type
        );
        settingItem.selected = item.selected;
        this.sortGroupFilters(settingGroup);

        this.searchFacetSettingsService.saveSettings(this.settings);
        this.onChangeSettings.emit();
    }

    public getAttributeGlyphClass(item: SearchFacetFilterSettingsItem) {
        return this.attributeDataService.getGlyphClassName(
            item.type,
            item.attributeMetaType
        );
    }

    private sortGroupFilters(group: SearchFacetFilterSettingsGroup) {
        group.filters = group.filters.sort((a, b) =>
            a.selected && b.selected ? 0 : a.selected ? -1 : 1
        );
    }
}

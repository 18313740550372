import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { IOptionAdapter, withLoading } from '@datagalaxy/core-ui';
import { IDxyPreviewPaneContent } from '../../../shared/shared-ui/dxy-preview-panel-slider/dxy-preview-panel-slider.types';
import { PreviewPanelService } from '../../../shared/shared-ui/preview-panel.service';
import { CollectionsHelper } from '@datagalaxy/core-util';
import {
    DateFilterOperator,
    IDateFilterData,
    IValueListFilterData,
    DateFilterUtils,
    ValueListFilterOperator,
    ValueListFilterUtils,
} from '@datagalaxy/core-ui/filters';
import { ActivityLogService } from '../../../activityLog/activityLog.service';
import { ActivityLogEntryElement } from '../../../activityLog/activityLog.types';
import { ServerType } from '@datagalaxy/dg-object-model';
import { UserService } from '../../../services/user.service';
import { UserFieldSelectAdapter } from '@datagalaxy/webclient/user/ui';
import { Space } from '@datagalaxy/webclient/workspace/data-access';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { UserPublicData } from '@datagalaxy/webclient/user/domain';
import { ActivityLogEntry } from '@datagalaxy/webclient/activity-log/domain';

/**
 * ## Role
 * Display recent public activity logs for a space
 *
 * ## Features
 * - Allows to filter by activity log types (commentary, task, update...)
 */
@Component({
    selector: 'app-space-activity-logs-widget-preview',
    templateUrl: './space-activity-logs-widget-preview.component.html',
    styleUrls: ['./space-activity-logs-widget-preview.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpaceActivityLogsWidgetPreviewComponent
    extends DxyBaseComponent
    implements IDxyPreviewPaneContent<void>, OnInit
{
    @Input() space: Space;
    @Input() logEntries: ActivityLogEntry[];

    @Output() readonly onReady = new EventEmitter();

    protected typeFilter: IValueListFilterData<ActivityLogType>;
    protected typeFilterOptions =
        CollectionsHelper.getEnumValues<ActivityLogType>(ActivityLogType);
    protected typeFilterAdapter: IOptionAdapter<ActivityLogType> = {
        getTextKey: (alt) =>
            `UI.SpaceHome.Widgets.SpaceActivityLogs.Preview.FilterType.${ActivityLogType[alt]}`,
    };
    protected dateFilter: IDateFilterData = {
        operator: DateFilterOperator.RangeContains,
    };
    protected userFilter: IValueListFilterData<UserPublicData> = {
        operator: ValueListFilterOperator.Equals,
    };
    protected userFilterOptions: UserPublicData[];
    protected userFilterAdapter: IOptionAdapter<UserPublicData> =
        new UserFieldSelectAdapter();

    protected logEntryElements: ActivityLogEntryElement[];
    protected filteredLogEntryElements: ActivityLogEntryElement[];

    protected get hasDateFilter() {
        return DateFilterUtils.isResolved(this.dateFilter);
    }
    protected get hasTypeFilter() {
        return ValueListFilterUtils.isResolved(this.typeFilter);
    }
    protected get hasUserFilter() {
        return ValueListFilterUtils.isResolved(this.userFilter);
    }
    protected get showLogs() {
        return !this.loadingValue && this.filteredLogEntryElements?.length;
    }
    protected get userId() {
        return this.userFilter?.values?.[0]?.UserId;
    }

    constructor(
        private previewPanelService: PreviewPanelService,
        private activityLogService: ActivityLogService,
        private userService: UserService
    ) {
        super();
    }

    ngOnInit() {
        this.initAsync();
    }

    protected onCloseClick() {
        this.previewPanelService.hidePanel();
    }

    protected onLogFilterChange(filter: IValueListFilterData<ActivityLogType>) {
        this.typeFilter = filter;
        this.applyFilters();
    }

    protected async clearTypeFilter() {
        // Shallow copy to force multiselect to reinit
        this.typeFilter = {
            ...this.typeFilter,
            values: null,
        };
        await this.loadActivityLogs();
    }

    protected async clearUserFilter() {
        this.userFilter = {
            ...this.userFilter,
            values: [],
        };
        await this.loadActivityLogs();
    }

    protected async clearDateFilter() {
        this.dateFilter.endDate = null;
        this.dateFilter.startDate = null;
        await this.loadActivityLogs();
    }

    protected async onDateFilterChange(filter: IDateFilterData) {
        this.dateFilter = filter;

        await this.loadActivityLogs();
    }

    protected async onUserFilterChange(
        filter: IValueListFilterData<UserPublicData>
    ) {
        this.userFilter = filter;
        await this.loadActivityLogs();
    }

    private applyFilters() {
        this.filteredLogEntryElements = this.logEntryElements.filter(
            (logEntries) => this.filterEntryLogByType(logEntries)
        );
    }

    private filterEntryLogByType(logEntryElement: ActivityLogEntryElement) {
        const filter = this.typeFilter;
        const logEntry = logEntryElement.entry;
        const isCommentary = [
            logEntry.ChildData?.ServerType,
            logEntry.Data?.ServerType,
        ].includes(ServerType.ObjectCommentary);
        const isTask = [
            logEntry.ChildData?.ServerType,
            logEntry.Data?.ServerType,
        ].includes(ServerType.ObjectTask);
        return (
            !filter?.values?.length ||
            (isCommentary &&
                filter.values.includes(ActivityLogType.Commentary)) ||
            (isTask && filter.values.includes(ActivityLogType.Task)) ||
            (!isTask &&
                !isCommentary &&
                filter.values.includes(ActivityLogType.Update))
        );
    }

    @withLoading()
    private async loadActivityLogs() {
        this.logEntries = await this.activityLogService.getSpaceActivityLogs(
            this.space,
            {
                fromTime: this.dateFilter?.startDate?.toISOString(),
                toTime: this.dateFilter?.endDate?.toISOString(),
                userId: this.userId,
            }
        );
        this.setLogEntryElements();
        this.applyFilters();
    }

    private async initAsync() {
        this.setLogEntryElements();
        const users = await this.userService.getNonDeletedUsers();
        this.userFilterOptions = CollectionsHelper.alphaSort(users, 'FullName');
    }

    private setLogEntryElements() {
        this.filteredLogEntryElements = this.logEntryElements =
            this.activityLogService.getActivityLogEntryElements(
                this.logEntries
            );
    }
}

enum ActivityLogType {
    Task,
    Commentary,
    Update,
}

import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CollectionsHelper } from '@datagalaxy/core-util';
import {
    IActionOption,
    ISearchTermEvent,
    withLoading,
} from '@datagalaxy/core-ui';
import { RichTextContent } from '@datagalaxy/core-ui/rich-text';
import { DxyIconCellComponent } from '@datagalaxy/core-ui/omnigrid';
import { SpaceCellComponent } from '../../shared/shared-ui/cells/space-cell/space-cell.component';
import { ServerType } from '@datagalaxy/dg-object-model';
import { NavigationApiService } from '../../navigation/services/navigation-api.service';
import { AppDataService } from '../../services/app-data.service';
import { SpaceUiService } from '../../space/services/space-ui.service';
import { AppSpaceService } from '../../services/AppSpace.service';
import { NavigationService } from '../../services/navigation.service';
import { EntitySecurityService } from '../../shared/entity/services/entity-security.service';
import { SecurityService } from '../../services/security.service';
import { DataIdentifier } from '@datagalaxy/dg-object-model';
import { UserCollectionCellComponent } from '../../shared/shared-ui/cells/user-collection-cell/user-collection-cell.component';
import { GlyphUtil } from '../../shared/util/GlyphUtil';
import {
    NavSpace,
    SpaceSecurityProfileType,
} from '@datagalaxy/webclient/workspace/data-access';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { GridCellType, GridConfig, TColDef } from '@datagalaxy/ui/grid';
import { ClientSpacesListStateService } from './client-spaces-list-state.service';

@Component({
    selector: 'app-client-spaces-list',
    templateUrl: 'client-spaces-list.component.html',
    styleUrls: ['client-spaces-list.component.scss'],
    providers: [ClientSpacesListStateService],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClientSpacesListComponent
    extends DxyBaseComponent
    implements OnInit
{
    protected gridConfig: GridConfig<NavSpace> = {
        getItemId: (it: NavSpace) => it.ReferenceId,
    };

    protected cols: TColDef<NavSpace>[] = [
        {
            id: 'DisplayName',
            headerLabel: this.translate.instant('UI.SpacesList.colTitle'),
            type: GridCellType.custom,
            customCellComponent: SpaceCellComponent,
            getInputs: (space: NavSpace) => ({
                space,
                actions: this.getAvailableActions(),
            }),
            minWidth: 350,
            maxWidth: 350,
            resizable: false,
        },
        {
            id: 'Description',
            headerLabel: this.translate.instant('UI.SpacesList.colDescription'),
            minWidth: 200,
            resizable: false,
            type: GridCellType.text,
            getValue: (space: NavSpace) =>
                RichTextContent.getRawText(space.Description, true),
        },
        {
            id: 'CreationUserId',
            headerLabel: this.translate.instant('UI.SpacesList.colOwner'),
            minWidth: 200,
            maxWidth: 200,
            resizable: false,
            type: GridCellType.custom,
            customCellComponent: UserCollectionCellComponent,
            getInputs: (space: NavSpace) => ({
                userIds: [space.DefaultOwnerUser?.UserId],
            }),
        },
        {
            id: 'AssignedToUserId',
            headerLabel: this.translate.instant('UI.SpacesList.colSteward'),
            minWidth: 200,
            maxWidth: 200,
            resizable: false,
            customCellComponent: UserCollectionCellComponent,
            getInputs: (space: NavSpace) => ({
                userIds: [space.DefaultStewardUser?.UserId],
            }),
        },
        {
            id: 'SecurityProfileType',
            headerLabel: this.translate.instant('UI.SpacesList.colAccessType'),
            minWidth: 150,
            maxWidth: 150,
            resizable: false,
            type: GridCellType.custom,
            customCellComponent: DxyIconCellComponent,
            getInputs: (space: NavSpace) => ({
                text: this.getAccessTypeLbl(space.SecurityProfileType),
                glyphClass: GlyphUtil.getSpaceSecurityProfileGlyphClass(
                    space.SecurityProfileType
                ),
            }),
        },
    ];
    protected filteredNavSpaces$ = this.stateService.selectFilteredSpaces();

    protected get navSpaces() {
        return this.stateService.state.navSpaces;
    }
    protected get spaceCount$() {
        return this.stateService.selectSpaceCount();
    }

    private get currentOrganizations() {
        return this.stateService.getOrganizations();
    }

    constructor(
        private translate: TranslateService,
        private navigationApiService: NavigationApiService,
        private appDataService: AppDataService,
        private spaceUiService: SpaceUiService,
        private appSpaceService: AppSpaceService,
        private navigationService: NavigationService,
        private entitySecurityService: EntitySecurityService,
        private securityService: SecurityService,
        private stateService: ClientSpacesListStateService
    ) {
        super();
    }

    ngOnInit() {
        this.init();
    }

    public onSearchTermChange(event: ISearchTermEvent) {
        this.stateService.updateSearchTerm(event.searchString);
    }

    public onNavSpaceClick(navSpace: NavSpace) {
        this.navigationService.goToSpaceHome(navSpace, {
            keepCurrentVersion: true,
        });
    }

    public async onCreateProject() {
        await this.spaceUiService.openCreateSpaceModal(
            this.currentOrganizations.length
                ? this.currentOrganizations[0].ReferenceId
                : null,
            ServerType.Project
        );
    }

    public isCreateAvailable() {
        return (
            (this.currentOrganizations.length == 1 &&
                this.entitySecurityService.hasAdminAccessSecurity(
                    this.currentOrganizations[0].SecurityData
                )) ||
            (this.currentOrganizations.length == 0 &&
                this.securityService.isCurrentUserClientAdmin() &&
                !this.securityService.isSingleWorkspaceClient())
        );
    }

    private async init() {
        this.registerSubscription(
            this.appSpaceService.subscribe({
                onCreate: (navSpace) => this.addNavSpace(navSpace),
                onUpdate: (navSpace) => this.updateNavSpace(navSpace),
                onDelete: (navspace) => this.deleteNavSpace(navspace),
                onUserFavoriteChanged: (navspace) => this.onFavSpace(navspace),
                onSecurityRightsChange: (notifMsg) =>
                    this.onSecurityRightsChangeEvent(notifMsg),
            })
        );
        await this.loadNavSpaces();
    }

    private deleteNavSpace(_data: DataIdentifier) {
        this.loadNavSpaces();
    }

    private onSecurityRightsChangeEvent(_data: any) {
        this.loadNavSpaces();
    }

    @withLoading()
    private async loadNavSpaces() {
        const result = await this.navigationApiService.getNavSpaces();
        const spaces = result.allSpaces();
        // Set Default Space
        const defaultSpaceReferenceId =
            this.appDataService.getDefaultSpaceReferenceId();

        if (defaultSpaceReferenceId) {
            CollectionsHelper.withFirstFound(
                spaces,
                (s) => s.ReferenceId == defaultSpaceReferenceId,
                (s) => (s.IsDefaultSpace = true)
            );
        }
        this.stateService.updateNavSpaces(spaces);
    }

    private addNavSpace(navSpace: NavSpace) {
        this.stateService.addNavSpace(navSpace);
    }

    private async updateNavSpace(navSpace: NavSpace) {
        this.stateService.updateNavSpace(navSpace);
    }

    private getAvailableActions(): IActionOption[] {
        return [
            {
                glyphClass: 'glyph-edit',
                callback: (data) => this.openUpdateSpaceModal(data as NavSpace),
                hidden: (data: NavSpace) =>
                    !data.SecurityData?.HasAdministratorAccess,
                tooltipTranslateKey: 'UI.Tooltip.Edit',
                alwaysVisible: true,
            },
            {
                glyphClass: (data: NavSpace) =>
                    data.IsDefaultSpace ? 'glyph-star-fav' : 'glyph-star',
                callback: (data) => this.onClickStar(data as NavSpace),
                tooltipTranslateKey: (data: NavSpace) =>
                    data.IsDefaultSpace
                        ? this.translate.instant('UI.Tooltip.DefaultValue')
                        : this.translate.instant(
                              'UI.Tooltip.SetAsDefaultValue'
                          ),
                alwaysVisible: true,
            },
        ];
    }

    private async openUpdateSpaceModal(navSpace: NavSpace) {
        const res = await this.spaceUiService.openUpdateSpaceModal(navSpace);
        if (!res) {
            return;
        }
    }

    private async onClickStar(navSpace: NavSpace) {
        const isDefaultSpace = !navSpace.IsDefaultSpace;
        await this.appSpaceService.updateDefaultSpace(
            navSpace,
            isDefaultSpace,
            this.navSpaces
        );
    }

    private async onFavSpace(updatedNavspace: NavSpace) {
        this.stateService.updateDefaultNavSpace(updatedNavspace);
    }

    private getAccessTypeLbl(securityProfileType: SpaceSecurityProfileType) {
        const securityString = SpaceSecurityProfileType[securityProfileType];
        return this.translate.instant(
            `DgServerTypes.SpaceSecurityProfileTypeLbl.${securityString}`
        );
    }
}

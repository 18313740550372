import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FunnelAction } from '@datagalaxy/ui/funnel';
import { MultilingualFunnelStepComponent } from '../multilingual-funnel-step.component';

@Component({
    selector: 'dxy-start-multilingual-activation',
    templateUrl: './start-multilingual-activation.component.html',
    styleUrls: ['../funnel-step.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StartMultilingualActivationComponent extends MultilingualFunnelStepComponent {
    override actions: FunnelAction[] = [
        {
            actionType: 'cancel',
            label: this.translate.instant('UI.Global.btnCancel'),
            color: 'secondary',
        },
        this.nextAction,
    ];
}

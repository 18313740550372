<button
    *ngFor="let action of actions"
    dxyIconButton
    size="small"
    [color]="getColor(action)"
    [matTooltip]="action.tooltipKey | translate : action.tooltipData"
    (mouseleave)="onMouseLeave()"
    (mouseenter)="onMouseEnter(action)"
    (click)="onClick(action)"
>
    <i class="glyph" [ngClass]="action.glyphClass"></i>
</button>

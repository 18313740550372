import { DisplayedHData } from './app-types/DisplayedHData';
import { IHasSpaceIconData } from '@datagalaxy/webclient/workspace/data-access';
import { FunctionalLogService } from '@datagalaxy/webclient/monitoring/data-access';

/**
 * ## Specs
 * https://datagalaxy.atlassian.net/wiki/spaces/DG3/pages/2834989069/SPEC+-+Comportement+des+ellipses+du+path
 */
export class BreadcrumbUtil {
    static getBreadcrumbItemsSettingsFromIDisplayedHData(
        items: DisplayedHData[],
        dtContext?: string
    ): IBreadcrumbItemSettings[] {
        return BreadcrumbUtil?.getBreadcrumbItemsSettings(
            items?.map((it) => ({
                ancestorData: it,
                dtName: dtContext ? this.getDtName(it, dtContext) : undefined,
            }))
        );
    }
    static getDtName(it: DisplayedHData, dtContext: string): any {
        const entityCode = FunctionalLogService.getHddDataActionFeatureCode(it);
        return `${dtContext} Breadcrumb - ${entityCode}`;
    }
    static getBreadcrumbItemsSettingsFromAvailableWidth(
        availableWidth: number,
        itemsData: IBreadcrumbItemData[],
        dotWidth = 0,
        caretWidth = 0
    ): IBreadcrumbItemSettings[] {
        if (!itemsData?.length) {
            return [];
        }
        const itemsSettings =
            BreadcrumbUtil.getBreadcrumbItemsSettings(itemsData);
        let virtualWidth = BreadcrumbUtil.computeVirtualBreadcrumbWidth(
            itemsSettings,
            dotWidth,
            caretWidth
        );
        let displayedItems = itemsSettings;
        let maxIterations = 0;

        while (
            virtualWidth > availableWidth &&
            displayedItems.length > 2 &&
            maxIterations < 100
        ) {
            const ancestorToBeDisabledIndex = Math.round(
                displayedItems.length / 2 - 1
            );
            const ancestorToBeDisabled =
                displayedItems[ancestorToBeDisabledIndex];
            ancestorToBeDisabled.isDisplayed = false;
            displayedItems = itemsSettings.filter(
                (itemSettings) => itemSettings.isDisplayed
            );
            virtualWidth = BreadcrumbUtil.computeVirtualBreadcrumbWidth(
                itemsSettings,
                dotWidth,
                caretWidth
            );
            maxIterations++;
        }

        if (virtualWidth > availableWidth) {
            const dotSectionWidth =
                displayedItems.length >= 2
                    ? (itemsSettings.length - displayedItems.length) *
                          dotWidth +
                      caretWidth
                    : 0;
            const remainingWidth = availableWidth - dotSectionWidth;
            const firstItem = itemsSettings[0];
            const lastItem = itemsSettings[itemsSettings.length - 1];
            firstItem.maxWidth = 0.4 * remainingWidth;
            lastItem.maxWidth = 0.6 * remainingWidth;

            if (firstItem.maxWidth < 20) {
                firstItem.isDisplayed = false;
            }
            if (lastItem.maxWidth < 20) {
                lastItem.isDisplayed = false;
            }
        }
        return itemsSettings;
    }

    private static getBreadcrumbItemsSettings(
        itemsData: IBreadcrumbItemData[]
    ): IBreadcrumbItemSettings[] {
        return (
            itemsData?.map((itemData) => ({ itemData, isDisplayed: true })) ??
            []
        );
    }

    private static computeVirtualBreadcrumbWidth(
        itemsSettings: IBreadcrumbItemSettings[],
        dotWidth: number,
        caretWidth: number
    ): number {
        let width = 0;
        let isFirstDot = true;

        const firstDotWidth = dotWidth + caretWidth;
        for (let item of itemsSettings) {
            if (item.isDisplayed) {
                width += item.itemData.itemWidth;
            }
            // If it's the first not displayed ancestor : add caret's width
            else {
                width += isFirstDot ? firstDotWidth : dotWidth;
                isFirstDot = false;
            }
        }
        return width;
    }
}

export interface IBreadcrumbItemData {
    ancestorData: DisplayedHData;
    itemWidth?: number;
    dtName?: string;
}

export interface IBreadcrumbItemSettings {
    itemData: IBreadcrumbItemData;
    isDisplayed: boolean;
    maxWidth?: number;
}

export interface ISpaceBreadcrumbInfo extends IHasSpaceIconData {
    DisplayName: string;
}

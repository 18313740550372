<div class="connection-form-target-container">
    <div class="connector-details">
        <img
            [src]="iconUrl"
            class="connector-img"
            alt=""
            [attr.data-tracker-id]="getOnlineImportPluginImgDataTrackerId()"
        />
        <span class="connector-name">{{ pluginDisplayName }}</span>
    </div>
    <div class="glyph-arrow-right"></div>
    <div class="fields-container">
        <dxy-spinner *ngIf="isInitLoading" class="xl"></dxy-spinner>
        <div class="fields">
            <dxy-field-text
                labelKey="UI.Connector.Wizard.Step3.displayName"
                [hint]="displayNameHint"
                [hintBeforeControl]="true"
                [mandatory]="true"
                name="connectionName"
                dxyDataTestId="connection-name-field"
                required
                [(ngModel)]="connectionName"
                [debounceTimeMs]="333"
            ></dxy-field-text>
            <div class="token-selection">
                <dxy-field-select
                    name="token"
                    labelKey="Import.GenericImportWizard.ConnectionForm.Selectors.Token"
                    [disabled]="isSelectTokenDisabled"
                    [infoTooltip]="
                        'Import.GenericImportWizard.ConnectionForm.Selectors.PersonalTokenInfo'
                            | translate
                    "
                    [options]="tokenOptions"
                    [placeholder]="!isTokenEditable ? '********' : ''"
                    [(ngModel)]="!isTokenEditable ? undefined : selectedToken"
                    [adapter]="tokenAdapter"
                    (ngModelChange)="onSelectedToken($event)"
                    (openClose)="onMenuOpenClose.emit($event)"
                    dxyDataTestId="token-select"
                >
                </dxy-field-select>
                <button
                    *ngIf="isEditConnection && !isTokenEditable"
                    mat-icon-button
                    type="button"
                    class="token-btn"
                    (click)="onTokenButtonClick()"
                    dxyDataTestId="token-button"
                >
                    <i class="glyph-edit"></i>
                </button>
            </div>
            <div class="workspace-selection">
                <label class="field-label" translate>
                    Import.GenericImportWizard.ConnectionForm.Selectors.TargetWorkspace
                </label>
                <dxy-space-version-selector
                    [spaceIdr]="spaceIdr"
                    (onSpaceVersionSelected)="
                        onSpaceVersionSelected($event.spaceIdr)
                    "
                    [isSpaceSelectable]="isSpaceVersionSelectable"
                    [isVersionSelectable]="isSpaceVersionSelectable"
                    [hideOfficialVersion]="true"
                    [showSelectedSpaceIcon]="true"
                    [onlyImportableCatalog]="true"
                    (onMenuOpenClose)="onMenuOpenClose.emit($event)"
                ></dxy-space-version-selector>
            </div>
            <dxy-field-boolean
                *ngIf="supportUrn"
                labelKey="Import.GenericImportWizard.ConnectionForm.URN.label"
                [beta]="true"
                [(ngModel)]="dataStructureUrn"
                (ngModelChange)="onEnableUrnUpdated($event)"
                [textValueTrue]="
                    'Import.GenericImportWizard.ConnectionForm.URN.text'
                        | translate
                "
                [textValueFalse]="
                    'Import.GenericImportWizard.ConnectionForm.URN.text'
                        | translate
                "
            >
            </dxy-field-boolean>
            <ng-container *ngIf="dataStructure === DataStructure.Tree">
                <div
                    *ngFor="let entityTarget of importEntityTargets"
                    class="target-selection"
                >
                    <label
                        class="field-label"
                        [translate]="
                            getTranslationKey(entityTarget, 'fieldTitle')
                        "
                    ></label>
                    <mat-hint
                        align="start"
                        [translate]="
                            getTranslationKey(entityTarget, 'fieldHint')
                        "
                    ></mat-hint>
                    <dxy-target-selection
                        [serverType]="entityTarget.serverType"
                        [hasEntityCreationAccess]="true"
                        [entityId]="entityTarget.selectedEntityId"
                        [newEntityName]="entityTarget.newEntityName"
                        [subTypeName]="entityTarget.subTypeName"
                        [disableSourceType]="true"
                        [availableEntities]="entityTarget.availableEntities"
                        [context]="importContext"
                        (newEntityNameChange)="
                            onNewEntityNameChange($event, entityTarget)
                        "
                        (subTypeChange)="
                            onEntitySubTypeChange($event, entityTarget)
                        "
                        (entityChange)="onEntityChange($event, entityTarget)"
                        (isUpdateChange)="
                            onIsUpdateChange($event, entityTarget)
                        "
                        (openChange)="onMenuOpenClose.emit($event)"
                    ></dxy-target-selection>
                </div>
            </ng-container>
            <dxy-field-select
                [labelKey]="getOrphanedObjectsHandlingTranslationKey('label')"
                [hint]="
                    getOrphanedObjectsHandlingTranslationKey('hint') | translate
                "
                [hintBeforeControl]="true"
                [adapter]="orphanedObjectsHandlingAdapter"
            ></dxy-field-select>
        </div>
    </div>
</div>

<button
    mat-icon-button
    class="dg5-dropdown-icon-button"
    [matMenuTriggerFor]="menu"
    [matTooltip]="'UI.NavBar.toggleViewTooltip' | translate"
>
    <i class="glyph" [ngClass]="currentViewGlyph"></i>
</button>

<mat-menu #menu="matMenu" class="dg5-mat-menu">
    <ul>
        <li
            role="menuitem"
            (click)="goToListView()"
            [dxyLogFunctional]="featureCodeDetail"
        >
            <span class="glyph glyph-layout-split"></span>
            <span class="text" translate>UI.Software.detailsView</span>
        </li>
        <li
            role="menuitem"
            (click)="goToGridView()"
            [dxyLogFunctional]="featureCodeTabView"
        >
            <span class="glyph glyph-collection-static"></span>
            <span class="text" translate>UI.Software.listView</span>
        </li>

        <li
            *ngIf="hasDataMapView"
            role="menuitem"
            (click)="goToDataMapView()"
            [dxyLogFunctional]="featureCodeDataMap"
        >
            <span class="glyph glyph-datamap"></span>
            <span class="text" translate>UI.Glossary.dataMapView</span>
        </li>
    </ul>
</mat-menu>

import {
    AfterViewChecked,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    NgZone,
    OnInit,
    Optional,
    Output,
    Self,
    ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgControl } from '@angular/forms';
import { IRichTextContentAdapter } from '../rich-text-editor.types';
import {
    IMentionResolver,
    IRichTextMentionData,
} from '../../core-rich-text-mention.types';
import { DxyRichTextFieldControlComponent } from '../rich-text-field-control/rich-text-field-control.component';
import { DxyBaseFocusableFieldComponent } from '@datagalaxy/ui/fields';

@Component({
    selector: 'dxy-rich-text-field',
    templateUrl: 'rich-text-field.component.html',
    styleUrls: ['rich-text-field.component.scss'],
})
export class DxyRichTextFieldComponent
    extends DxyBaseFocusableFieldComponent<string>
    implements OnInit, AfterViewChecked
{
    /** Placeholder text to be displayed when the text is empty */
    @Input() placeholder: string;
    /**
     * Set display mode for toolbar:
     * - always: show toolbar permanently
     * - focus: show toolbar on input focus / hide on blur
     * - manual: hide toolbar by default and provide toggle method to hide/show toolbar
     * this has to be called by parent's component for example
     */
    @Input() toolbarDisplayMode: 'always' | 'focus' | 'manual' = 'always';
    /** Defaults to richTextDefaults.quillContentAdapter */
    @Input() adapter: IRichTextContentAdapter;
    /** Data providers for inserting rich-text mentions (usually via a modal) when typing special characters.
     * Note that the adapter must support this feature. */
    @Input() mentionResolvers: IMentionResolver[];
    /** When true, hitting the *tab* key will pass focus to next control instead of inserting a tab character */
    @Input() noTabCapture: boolean;

    /** Fires before and after aquiring mention data. the boolean argument is true on before, false on after */
    @Output() onAcquireMentionData = new EventEmitter<boolean>();

    public get label() {
        return this.getLabel(this.translate);
    }
    public get labelTooltip() {
        return this.getLabelTooltip(this.translate);
    }
    public get errorMessage() {
        return this.getErrorMessage(this.translate);
    }

    public get isToolbarVisible() {
        return (
            !this.isToolbarHidden &&
            (this.toolbarDisplayMode == 'always' ||
                this.toolbarDisplayMode == 'manual' ||
                (this.toolbarDisplayMode == 'focus' &&
                    this.fieldControl?.focused))
        );
    }

    @ViewChild('fieldControl')
    protected fieldControl: DxyRichTextFieldControlComponent;

    protected get empty() {
        return this.fieldControl?.empty;
    }

    private isToolbarHidden: boolean;

    constructor(
        private changeDetector: ChangeDetectorRef,
        private translate: TranslateService,
        elementRef: ElementRef<HTMLElement>,
        ngZone: NgZone,
        @Optional() @Self() ngControl: NgControl
    ) {
        super(elementRef, ngZone, ngControl);
    }

    ngOnInit() {
        super.ngOnInit();
        this.isToolbarHidden = this.toolbarDisplayMode == 'manual';
    }
    ngAfterViewChecked() {
        // because of the rich-text-field-control compiling components
        this.preventNg0100Error(this.changeDetector);
    }

    public onAcquireMentionDataInternal(isAcquiring: boolean) {
        this.onAcquireMentionData.emit(isAcquiring);
    }

    //#region API
    public toggleToolbar() {
        this.isToolbarHidden = !this.isToolbarHidden;
    }
    public showToolbar() {
        this.isToolbarHidden = false;
    }
    public hideToolbar() {
        this.isToolbarHidden = true;
    }

    public addMention(mention: IRichTextMentionData) {
        this.fieldControl.addMention(mention);
    }

    public addHtml(html: string) {
        this.fieldControl.addHtml(html);
    }

    protected preventBlur(
        event: FocusEvent,
        isClickingInside: boolean
    ): boolean {
        return (
            this.mentionResolvers?.some(
                (value) => value.isMentionDropdownOpen
            ) || super.preventBlur(event, isClickingInside)
        );
    }

    //#endregion
}

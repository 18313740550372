<dxy-space-version-selector
    *ngIf="isSpaceVersionSelectorVisible"
    [allowAll]="true"
    (onSpaceVersionSelected)="onSpaceVersionSelected($event.spaceIdr)"
    [spaceIdr]="selectedSpaceIdr"
    [isSmallCaretBtn]="true"
    [showSelectedSpaceIcon]="true"
    logId="task-search"
></dxy-space-version-selector>
<header>
    <dxy-search-input
        class="no-border height-50"
        [searchString]="state.searchText"
        [showCancelSearch]="isClearSearchVisible"
        placeholderKey="UI.Search.placeholder"
        (onSearchString)="searchStringChanged($event.searchString)"
    ></dxy-search-input>
</header>
<div *ngIf="hasResults$ | async; else noResults" class="results">
    <dxy-task-facet-filters
        [groups]="filterGroups$ | async"
        (onFilterChange)="onFilterChanged($event.item, $event.group)"
        [isLoading]="isLoading$ | async"
    ></dxy-task-facet-filters>
    <dxy-task-results
        logId="tasks"
        [headerGridTitle]="totalCountMessage$ | async"
        [filterGroups]="filterGroups$ | async"
    ></dxy-task-results>
</div>
<ng-template #noResults>
    <div class="dxy-placeholder">
        <img class="dxy-placeholder-img" src="/images/placeholders/tasks.svg" />
        <div
            *ngIf="userSearched$ | async; else noSearchPlaceholder"
            class="dxy-placeholder-text"
            translate
        >
            UI.Tasks.noResults
        </div>
        <ng-template #noSearchPlaceholder>
            <h3 translate>UI.Tasks.homeFirstLine</h3>
            <div class="dxy-placeholder-text" translate>
                UI.Tasks.noResultsDetail
            </div>
        </ng-template>
        <a (click)="openTaskSearchArticle()" translate
            >UI.Search.homeNeedHelp</a
        >
    </div>
</ng-template>

import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
    ViewChild,
} from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { IListOption } from '@datagalaxy/core-ui';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'dxy-widget-handle',
    templateUrl: './widget-handle.component.html',
    styleUrls: ['./widget-handle.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WidgetHandleComponent {
    @ViewChild('triggerButton') triggerButton: MatMenuTrigger;

    @Input() options: IListOption[];
    @Input() disabled = false;

    @Output() longPress = new EventEmitter<void>();

    protected tooltipHtml: string;

    private mouseDownTime?: Date;
    private threshold = 300;

    constructor(translate: TranslateService) {
        this.tooltipHtml = translate.instant('Dashboard.Handle.tootlip');
    }
    protected onMouseUp() {
        if (!this.mouseDownTime) {
            return;
        }
        const timeElapsed = new Date().getTime() - this.mouseDownTime.getTime();
        if (timeElapsed > this.threshold) {
            this.longPress.emit();
            return;
        }
        this.triggerButton.openMenu();
    }
    protected onMouseDown() {
        this.mouseDownTime = new Date();
    }
}

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
    DxyFieldHyperlinkComponent,
    IHyperlinkModel,
} from '@datagalaxy/core-ui/fields';
import { TranslateService } from '@ngx-translate/core';
import { DxyAttributeBaseInput } from '../../DxyAttributeBaseInput';
import {
    fromSerializedServerHyperlinkModel,
    toSerializedServerHyperlinkModel,
} from '../../../../fields/field-hyperlink.utils';

@Component({
    selector: 'dxy-attribute-hyperlink-input',
    templateUrl: './dxy-attribute-hyperlink-input.component.html',
})
export class DxyAttributeHyperlinkInputComponent
    extends DxyAttributeBaseInput<string>
    implements OnInit
{
    @ViewChild('field') field: DxyFieldHyperlinkComponent;

    public get model() {
        return this._model;
    }
    public set model(value: IHyperlinkModel) {
        this._model = value;
        this.storeModel();
    }

    public get isBulk() {
        return super.isBulkForm;
    }
    public get bulkNameLabel() {
        return super.isBulkForm
            ? this.translate.instant('UI.HtmlLink.name')
            : undefined;
    }
    public get bulkInputsLabelClass() {
        return super.isBulkForm ? 'labels-bulk' : '';
    }

    private _model: IHyperlinkModel;

    constructor(
        translate: TranslateService,
        elementref: ElementRef<HTMLElement>
    ) {
        super(elementref, translate);
    }

    ngOnInit() {
        this.initModel();
    }

    //#region IAttributeBaseInputOverride
    public focusField() {
        this.field.doFocus();
    }
    public blurField() {
        this.field.doBlur();
    }
    public isEmpty() {
        return !this.isActive && !this.field.hasUrl;
    }
    public async onBeforeValidate() {
        await this.storeModel();
        return true;
    }
    public onAfterValidate() {
        this.initModel();
    }
    public onAfterUndo() {
        this.initModel();
    }
    public onBeforeRestorePreviousValueForCompareEntityForm() {
        const ak = this.attributeKey;
        const item = this.entityForm.comparatorItemsValues?.find(
            (civ) => ak == civ.attributeMeta.AttributeKey
        );
        this._model = fromSerializedServerHyperlinkModel(item?.newValue);
    }
    //#region

    private initModel() {
        this._model = fromSerializedServerHyperlinkModel(this.getData());
    }
    private async storeModel() {
        await this.setData(toSerializedServerHyperlinkModel(this._model));
    }
}

import {
    ChangeDetectionStrategy,
    Component,
    Input,
    ViewEncapsulation,
} from '@angular/core';
import { UiUtil } from '@datagalaxy/core-ui';
import { SpaceApiService } from '../../../space/services/space-api.service';
import { IHasSpaceIconData } from '@datagalaxy/webclient/workspace/data-access';
import { DxyBaseComponent } from '@datagalaxy/ui/core';

/**
 * ## Role
 * Display space icon or space trigram
 */
@Component({
    selector: 'dxy-space-icon',
    templateUrl: './dxy-space-icon.component.html',
    styleUrls: ['./dxy-space-icon.component.scss'],
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DxySpaceIconComponent extends DxyBaseComponent {
    @Input() workspace: IHasSpaceIconData;

    public get spaceIconUrl(): string {
        return this.spaceApiService.getSpaceIconUrl(this.workspace);
    }
    public get hasSpaceIcon(): boolean {
        return !!this.workspace?.IconHash;
    }

    public get trigram() {
        return this.workspace?.Trigram;
    }
    public get trigramColorClass() {
        return this.trigram && UiUtil.getColorClassFromString(this.trigram);
    }

    constructor(private spaceApiService: SpaceApiService) {
        super();
    }
}

import { IOmniGridDataInfo } from '@datagalaxy/core-ui/omnigrid';
import { Component, Input } from '@angular/core';
import { DataQualityRule } from '@datagalaxy/webclient/data-quality/data-access';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';

@Component({
    selector: 'app-data-quality',
    templateUrl: 'data-quality-tab.component.html',
    styleUrls: ['data-quality-tab.component.scss'],
})
export class DataQualityTabComponent extends DxyBaseComponent {
    @Input() entityData: EntityItem;

    protected gridData: IOmniGridDataInfo<DataQualityRule>;

    constructor() {
        super();
    }
}

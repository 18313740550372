import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Widget } from '../domain';

@Component({
    selector: 'dxy-widget-preview',
    templateUrl: './widget-preview.component.html',
    styleUrls: ['./widget-preview.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WidgetPreviewComponent {
    @Input() widget!: Widget;
    @Input() displayMode: 'default' | 'placeholder' = 'default';

    protected get previewHeaderKey() {
        return this.widget.previewHeaderKey ?? this.widget.titleKey;
    }
}

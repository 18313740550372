<span *ngIf="hasImportAccess()">
    <button
        mat-button
        [matMenuTriggerFor]="importButtonMenu"
        [matTooltip]="tooltipmsg | translate"
    >
        <span class="glyph-upload"></span>
        <ng-content select="[title]"></ng-content>
        <span class="glyph-arrow-drop-down"></span>
    </button>
    <mat-menu #importButtonMenu="matMenu" class="dg5-mat-menu">
        <ul>
            <li (click)="openImportFromCSVModal()">
                <span class="glyph-csv-file"></span>
                <span translate>UI.Global.import.fromFile</span>
            </li>
        </ul>
        <ng-content select="[actions]"></ng-content>
    </mat-menu>
</span>

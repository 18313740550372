import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { DxyBaseModalComponent } from '@datagalaxy/ui/dialog';
import { IFunctionalEvent } from '@datagalaxy/core-ui';
import { TranslateService } from '@ngx-translate/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
    IGenericImportWizardResolve,
    ImportSteps,
} from './dxy-import-wizard-modal.types';
import { WizardContext } from '../../../shared/wizard-context';
import { ImportContext, ImportMode } from '../../../shared/ImportContext';
import { ToasterService } from '../../../../services/toaster.service';
import { SecurityService } from '../../../../services/security.service';
import { CurrentSpaceService } from '../../../../services/currentSpace.service';
import { ClientErrorService } from '../../../../services/clientError.service';
import { ConnectorService } from '../../../../connector/connector.service';
import { ImportWizardService } from '../../../services/import-wizard.service';
import { CsvImportApiService } from '../../../services/csv-import-api.service';
import { ImportModuleCatalog } from '../../../shared/ImportModule';
import {
    IConnectorPlugin,
    PluginType,
} from '@datagalaxy/webclient/connectivity/data-access';
import {
    CrudActionType,
    CrudOperation,
    FunctionalLogService,
} from '@datagalaxy/webclient/monitoring/data-access';

@Component({
    selector: 'dxy-import-wizard-modal',
    templateUrl: 'dxy-import-wizard-modal.component.html',
})
export class DxyImportWizardModalComponent
    extends DxyBaseModalComponent<IGenericImportWizardResolve, void>
    implements OnInit, AfterViewInit
{
    public readonly wizardContext: WizardContext = {
        title: () => this.getModalTitle(),

        getNextButtonText: () => this.getNextButtonText(),
        getNextButtonDataTrackerId: () => this.getNextButtonDataTrackerId(),
        getNextButtonTooltip: () => this.getNextButtonTooltip(),

        checkboxValue: () => this.getPreferencesCheckboxValue(),
        checkboxMessage: () => this.getSkipStepInfo(),

        isNextDisabled: () => this.isNextDisabled(),
        isNextVisible: () => this.isNextVisible(),

        isCheckBtnVisible: () => this.isCsvPreImportVisible(),
        isCheckBtnDisabled: () => this.isCsvPreImportDisabled(),
        getCheckTooltip: () => this.getCheckBtnTooltip(),
        getCheckIcon: () =>
            this.importWizardService.getCheckIcon(
                this.isCheckLoading,
                this.importContext
            ),
        getTestButtonIcon: () => this.getTestButtonIcon(),
        getTestBtnTooltip: () => this.getTestBtnTooltip(),
        getTestErrorMessage: () => this.getTestErrorMessage(),

        checkMessageData: () =>
            this.importWizardService.getCheckMessageData(
                this.isCheckLoading,
                this.importContext
            ),

        isTestDisabled: () => this.isTestDisabled(),
        isTestVisible: () => this.isTestVisible(),
        isSaveDisabled: () => this.isSaveDisabled(),
        isSaveVisible: () => this.isSaveVisible(),
        isLoadingVisible: () => this.isDownloadPluginLoading,
        isRestartVisible: () => this.isCsvPostImportVisible(),
        isRestartDisabled: () => this.isRestartDisabled(),
        isFooterVisible: () => this.isFooterVisible(),

        isPreviousVisible: () => this.isPreviousVisible(),
        isCheckboxVisible: () => this.isShowDetailsPreferencesCheckboxVisible(),

        getLeftFooterCustomText: () =>
            this.connectorService.onlineConnectionSelectedEntitiesText,
        isLeftFooterCustomTextVisible: () =>
            this.currentStep == ImportSteps.onlineConnectionSelection &&
            !this.connectorService.connectionObjectsLoading,

        next: () => this.onClickNext(),
        test: () => this.onClickTest(),
        save: () => this.saveConnection(),
        previous: () => this.onClickPrevious(),
        close: () => this.closeModal(),
        restart: () => this.onRestartWizard(),
        toggleCheckbox: (value) => this.onTogglePreferences(value),
        checkBtnClick: () => this.onImport(true),
    };
    public importContext: ImportContext;
    public selectedSolution?: IConnectorPlugin;
    public isImportLoading = false;

    public get isCsvImport() {
        return this.importContext?.importMode == ImportMode.Csv;
    }

    public get isDesktopImport() {
        return this.importContext?.importMode == ImportMode.DesktopConnector;
    }

    public get isEmbeddedImport() {
        return this.importContext?.importMode == ImportMode.OnlineConnector;
    }

    private isDownloadPluginLoading = false;
    private isCheckLoading = false;
    private currentStep = ImportSteps.solutionSelection;
    private skipStepEmbedded: boolean;
    private skipStepCsv: boolean;
    private isOnlineTestSuccess: boolean;
    private isCurrentlyTesting = false;
    private isCurrentlyUpdating = false;
    private testErrorMessage: string;

    private get isStartFromOnlineSettings() {
        return this.importContext.startFromOnlineSettings;
    }

    private get hasManagementAccess() {
        return this.securityService.hasManagementAccess(
            this.currentSpaceService.getCurrentOrLastSpace()
        );
    }

    private get isOnlineConnectionEntitySelectionEnabled() {
        return (
            this.selectedSolution?.settings?.disableListObjects === false ||
            this.connectorService.targetPlugin?.settings?.disableListObjects ===
                false
        );
    }

    constructor(
        private translate: TranslateService,
        private functionalLogService: FunctionalLogService,
        private toasterService: ToasterService,
        private clientErrorService: ClientErrorService,
        private connectorService: ConnectorService,
        private importWizardService: ImportWizardService,
        private importApiService: CsvImportApiService,
        private securityService: SecurityService,
        private currentSpaceService: CurrentSpaceService,
        dialogRef: MatDialogRef<DxyImportWizardModalComponent>,
        @Inject(MAT_DIALOG_DATA) data: IGenericImportWizardResolve
    ) {
        super(dialogRef, data);
    }

    ngOnInit() {
        this.log('ngOnInit-start', this.data);

        const ctx = (this.importContext = this.data.importContext);

        this.skipStepCsv = !ctx?.showCsvDetails;
        this.skipStepEmbedded = !ctx?.showEmbeddedDetails;
        if (ctx.startFromOnlineSettings) {
            this.currentStep = ImportSteps.onlineConnectionTarget;
        }
        if (ctx.startFromCsvSettings) {
            this.currentStep = ImportSteps.csvSettings;
        }

        this.log('ngOnInit-end', this.data);
    }

    ngAfterViewInit() {
        this.log('ngAfterViewInit-done');
    }

    public onLogFunctional($event: IFunctionalEvent) {
        this.functionalLogService.parseAndLog($event.text, $event.origin);
    }

    //#region translations
    private getModalTitle() {
        return this.getTranlationStep('ModalTitle');
    }

    private getNextButtonText() {
        switch (this.currentStep) {
            case ImportSteps.onlineConnectionSelection:
            case ImportSteps.csvPreImport:
                return this.translate.instant('UI.Global.btnImport');
            case ImportSteps.csvPostImport:
                return this.translate.instant('UI.Global.btnClose');
            case ImportSteps.solutionDetail:
                return this.getTranslationSolutionDetail(
                    this.importContext.importMode == ImportMode.DesktopConnector
                        ? 'NextBtnDesktop'
                        : 'NextBtn'
                );
            case ImportSteps.onlineConnectionTarget:
                return this.getTranslationConnectionForm('NextBtnTarget');
            case ImportSteps.onlineConnectionSettings:
                return this.isOnlineConnectionEntitySelectionEnabled
                    ? this.getTranslationConnectionForm('NextBtnSettings')
                    : this.translate.instant('UI.Global.btnImport');
            default:
                return this.translate.instant(
                    `Import.GenericImportWizard.Global.BtnNext`
                );
        }
    }

    private getNextButtonDataTrackerId() {
        if (this.isEmbeddedImport) {
            switch (this.currentStep) {
                case ImportSteps.onlineConnectionSelection:
                case ImportSteps.onlineConnectionSettings:
                    return 'import-online-btn';
                case ImportSteps.onlineConnectionTarget:
                    return 'import-modal-set-connection';
                case ImportSteps.solutionDetail:
                    return 'start-import-online-btn';
                default:
                    return '';
            }
        }
    }

    private getTranslationSolutionDetail(suffix: string) {
        return this.translate.instant(
            `Import.GenericImportWizard.SolutionDetails.${suffix}`
        );
    }

    private getTranslationConnectionForm(suffix: string) {
        return this.translate.instant(
            `Import.GenericImportWizard.ConnectionForm.${suffix}`
        );
    }

    private getNextButtonTooltip(): string {
        if (!this.isNextDisabled()) {
            return '';
        }

        if (this.isSolutionDetailsVisible()) {
            return this.translate.instant(
                'Import.GenericImportWizard.SolutionDetails.EmbeddedImport.NextButtonDisabledTooltip'
            );
        }

        // Csv settings
        if (this.isCsvSettingsVisible()) {
            return this.getTranslationCsvSettings(
                !this.importContext.csvFile
                    ? 'NoFileTooltip'
                    : !this.importContext.currentOperation
                    ? 'NoTargetTooltip'
                    : 'SourceWarningTooltip'
            );
        }

        // Csv pre-import
        if (this.isCsvPreImportVisible()) {
            return this.getCheckBtnTooltip();
        }
        return '';
    }

    private getTranslationCsvSettings(suffix: string) {
        return this.translate.instant(
            `Import.GenericImportWizard.CsvSettings.${suffix}`
        );
    }

    private getCheckBtnTooltip(): string {
        // tooltip : mandatory field unmapped
        if (!this.importContext.areAllMandatoryFieldsMatched) {
            return this.getTranlationStep('StatusMissingMandatory');
        }
        // tooltip : mapping changed
        if (!this.isCsvPreImportDisabled()) {
            return this.getTranlationStep('CheckBtnTooltip');
        }
        // tooltip : mapping unchanged and errors
        if (
            !this.importContext.hasChangedMapping &&
            this.importContext.hasImportErrors
        ) {
            return this.getTranlationStep('StatusError');
        }
        return '';
    }

    private getTranlationStep(suffix: string) {
        return this.translate.instant(
            `Import.GenericImportWizard.${this.getStepInfix()}.${suffix}`
        );
    }

    private getStepInfix() {
        switch (this.currentStep) {
            case ImportSteps.solutionSelection:
            case ImportSteps.solutionDetail:
                return this.isCsvImport
                    ? 'SolutionDetails.CsvImport'
                    : this.isDesktopImport
                    ? 'SolutionDetails.DesktopImport'
                    : 'SolutionDetails.EmbeddedImport';
            case ImportSteps.csvSettings:
                return 'CsvSettings';
            case ImportSteps.csvPreImport:
                return 'CsvPreImport';
            case ImportSteps.csvPostImport:
                return 'CsvPostImport';
            case ImportSteps.onlineConnectionTarget:
            case ImportSteps.onlineConnectionSettings:
            case ImportSteps.onlineConnectionSelection:
                return 'SolutionDetails.EmbeddedImport';
            default:
                return 'SolutionSelection';
        }
    }

    private getSkipStepInfo() {
        return this.translate.instant(
            'Import.GenericImportWizard.SolutionDetails.ShowDetailsPreferencesLbl',
            { iconHtml: '<span class="glyph-info"></span>' }
        );
    }

    //#endregion

    //#region html isVisible / isDisabled
    public isSolutionSelectionVisible() {
        return this.currentStep == ImportSteps.solutionSelection;
    }

    public isSolutionDetailsVisible() {
        return this.currentStep == ImportSteps.solutionDetail;
    }

    public isCsvSettingsVisible() {
        return this.currentStep == ImportSteps.csvSettings;
    }

    public isCsvPreImportVisible() {
        return this.currentStep == ImportSteps.csvPreImport;
    }

    public isCsvPostImportVisible() {
        return this.currentStep == ImportSteps.csvPostImport;
    }

    public isOnlineTargetVisible() {
        return this.currentStep == ImportSteps.onlineConnectionTarget;
    }

    public isOnlineSettingsVisible() {
        return this.currentStep == ImportSteps.onlineConnectionSettings;
    }

    public isOnlineSelectionVisible() {
        return this.currentStep == ImportSteps.onlineConnectionSelection;
    }

    private isFooterVisible() {
        return this.currentStep >= ImportSteps.solutionDetail;
    }

    private isShowDetailsPreferencesCheckboxVisible() {
        return (
            this.currentStep == ImportSteps.solutionDetail &&
            this.importContext.importMode != ImportMode.DesktopConnector &&
            this.hasManagementAccess
        );
    }

    private isNextVisible() {
        return !this.isDownloadPluginLoading;
    }

    // When adding/updating a condition, consider adding/updating tooltips in getNextTooltip()
    private isNextDisabled() {
        const ctx = this.importContext;

        const isSolutionDetailsDisabled =
            this.currentStep == ImportSteps.solutionDetail &&
            ctx.importMode == ImportMode.OnlineConnector &&
            !this.hasManagementAccess;

        const isOnlineSettingsDisabled =
            this.currentStep == ImportSteps.onlineConnectionSettings &&
            !this.isOnlineTestSuccess;

        const isConnectionFormTargetInvalid =
            this.currentStep === ImportSteps.onlineConnectionTarget &&
            (!this.connectorService.isConnectorFormValidated ||
                this.isCurrentlyUpdating);

        const isPreImportDisabled =
            this.isCsvPreImportVisible() &&
            (!ctx.areAllMandatoryFieldsMatched ||
                ctx.hasChangedMapping ||
                ctx.hasImportErrors);

        const isCsvSettingsDisabled =
            this.isCsvSettingsVisible() &&
            (!ctx.csvFile ||
                !ctx.currentOperation ||
                (ctx.currentModule instanceof ImportModuleCatalog &&
                    !ctx.currentModule.isOpeEnabled(ctx.currentOperation)));

        const isCsvPostImportDisabled =
            this.isCsvPostImportVisible() && this.isImportLoading;

        const isSelectionImportDisabled =
            this.currentStep === ImportSteps.onlineConnectionSelection &&
            ((!this.connectorService.isAllObjectsSelected &&
                this.connectorService.selectedObjectNames.length === 0) ||
                this.connectorService.connectionObjectsLoading);

        return (
            isSolutionDetailsDisabled ||
            isCsvSettingsDisabled ||
            isPreImportDisabled ||
            isCsvPostImportDisabled ||
            isOnlineSettingsDisabled ||
            isConnectionFormTargetInvalid ||
            isSelectionImportDisabled
        );
    }

    private isPreviousVisible() {
        return (
            !this.isCsvPostImportVisible() &&
            !(
                this.isStartFromOnlineSettings &&
                this.currentStep === ImportSteps.onlineConnectionTarget
            ) &&
            !(
                this.importContext.startFromCsvSettings &&
                this.currentStep == ImportSteps.csvSettings
            )
        );
    }

    private isTestVisible() {
        return this.currentStep == ImportSteps.onlineConnectionSettings;
    }

    private isSaveVisible() {
        return (
            this.currentStep == ImportSteps.onlineConnectionTarget &&
            this.isStartFromOnlineSettings
        );
    }

    private isTestDisabled() {
        return (
            !this.connectorService.isConnectorFormValidated ||
            this.isCurrentlyTesting ||
            this.isCurrentlyUpdating
        );
    }

    private isSaveDisabled() {
        return (
            !this.connectorService.isConnectorFormValidated ||
            this.isCurrentlyUpdating
        );
    }

    private isRestartDisabled() {
        return this.isCsvPostImportVisible() && this.isImportLoading;
    }

    private isCsvPreImportDisabled() {
        return (
            !this.importContext.areAllMandatoryFieldsMatched ||
            !this.importContext.hasChangedMapping ||
            this.isImportLoading
        );
    }

    //#endregion

    //#region actions callbacks

    public async closeModal() {
        if (
            this.isCsvPostImportVisible() &&
            !this.importContext.lastImportResult.isPreImportResult &&
            this.importContext.lastImportResult.IsSuccess
        ) {
            await this.onClickNext();
        } else {
            this.connectorService.resetStoredData();
            this.onCloseCancel();
        }
    }

    public onClickSolution(e: {
        solution: IConnectorPlugin;
        forceDetails: boolean;
    }) {
        this.selectedSolution = e.solution;
        this.importContext.importMode =
            e.solution.pluginType == PluginType.Online
                ? ImportMode.OnlineConnector
                : ImportMode.DesktopConnector;

        if (
            this.isDesktopImport ||
            e.forceDetails ||
            this.importContext.showEmbeddedDetails
        ) {
            return (this.currentStep = ImportSteps.solutionDetail);
        }
        this.currentStep = ImportSteps.onlineConnectionTarget;
    }

    public onClickCsv(e: { files: File[]; forceDetails: boolean }) {
        this.importContext.csvFile = e.files?.[0];
        this.importContext.contentRequestId = null;
        this.importContext.importMode = ImportMode.Csv;
        if (e.forceDetails || this.importContext.showCsvDetails) {
            return (this.currentStep = ImportSteps.solutionDetail);
        }
        this.currentStep = ImportSteps.csvSettings;
    }

    public async onImport(isPreImport: boolean) {
        this.isImportLoading = true;

        const moduleString =
            this.importContext.currentModule?.moduleLogString ?? '';
        if (moduleString) {
            const actionType = isPreImport
                ? CrudActionType.CheckImport
                : CrudActionType.Import;
            this.functionalLogService.logFunctionalAction(
                `IMPORT_${moduleString}_CSV`,
                CrudOperation.A,
                actionType
            );
        }

        const importFunction = async (rawContent: string) => {
            const result =
                await this.importApiService.importFromContextAndRawContent(
                    this.importContext,
                    rawContent,
                    isPreImport
                );
            result.isPreImportResult = isPreImport;
            this.importContext.lastImportResult = result;
            this.importContext.hasChangedMapping = false;
            this.isImportLoading = false;
            this.importContext.notifyRefreshMessages('');
            if (!result.isPreImportResult) {
                this.importContext.isImportDone = true;
            }
            if (result.ContentRequestId) {
                this.importContext.contentRequestId = result.ContentRequestId;
            }
            if (isPreImport) {
                this.isCheckLoading = false;
            }
        };

        if (isPreImport) {
            this.isCheckLoading = true;
        }

        if (this.importContext.contentRequestId) {
            await importFunction(null);
        } else {
            this.makeRawContent(importFunction, (error) => console.log(error));
        }
    }

    private onClickPrevious() {
        const isDetailsStepSkipped =
            (this.isCsvImport && this.skipStepCsv) ||
            (this.isEmbeddedImport && this.skipStepEmbedded);
        if (
            (this.currentStep == ImportSteps.csvSettings ||
                this.currentStep == ImportSteps.onlineConnectionTarget) &&
            isDetailsStepSkipped
        ) {
            this.importContext.isTargetAlreadySet = false;
            this.connectorService.resetStoredData();
            return (this.currentStep = ImportSteps.solutionSelection);
        }
        if (this.currentStep == ImportSteps.onlineConnectionTarget) {
            return (this.currentStep = ImportSteps.solutionDetail);
        }
        this.currentStep--;
    }

    private async onClickNext() {
        // Close after import
        if (this.isCsvPostImportVisible()) {
            this.onCloseSubmit();
        }
        // Desktop connector DL
        if (
            this.currentStep == ImportSteps.solutionDetail &&
            this.isDesktopImport &&
            this.selectedSolution?.pluginType == PluginType.Desktop
        ) {
            return await this.onDownloadPlugin();
        }

        // Online Connector Import
        if (
            this.currentStep == ImportSteps.onlineConnectionSettings &&
            !this.isOnlineConnectionEntitySelectionEnabled
        ) {
            this.onCloseCancel();
            await this.onConnectorImport();
        }

        if (this.isOnlineSelectionVisible()) {
            this.onCloseCancel();
            await this.saveConnection();
            await this.onConnectorImport();
        }

        if (
            this.currentStep == ImportSteps.solutionDetail &&
            this.importContext?.importMode == ImportMode.OnlineConnector
        ) {
            this.connectorService.resetStoredData();
            return (this.currentStep = ImportSteps.onlineConnectionTarget);
        }

        if (this.currentStep === ImportSteps.onlineConnectionTarget) {
            this.importContext.isTargetAlreadySet = true;
            return (this.currentStep = ImportSteps.onlineConnectionSettings);
        }

        this.currentStep++;

        // CSV Import
        if (this.currentStep == ImportSteps.csvPostImport) {
            return this.onImport(false);
        }
    }

    private async saveConnection() {
        this.isCurrentlyUpdating = true;
        const isConnectionUpdate =
            this.isStartFromOnlineSettings || this.isOnlineSelectionVisible();

        try {
            await this.connectorService.preSaveConnection(
                this.importContext.spaceIdr,
                isConnectionUpdate
            );

            if (this.currentStep === ImportSteps.onlineConnectionTarget) {
                this.toasterService.successToast({
                    titleKey: 'UI.Connector.Wizard.Step3.Save.title',
                    messageKey: 'UI.Connector.Wizard.Step3.Save.message',
                });
            }
        } finally {
            this.isCurrentlyUpdating = false;
        }
    }

    private async onClickTest() {
        await this.saveConnection();
        await this.testConnection();
    }

    private async testConnection() {
        this.testErrorMessage = '';
        this.isCurrentlyTesting = true;
        try {
            const resultTestConnection =
                await this.connectorService.testConnection(
                    this.importContext.spaceIdr.spaceId,
                    this.connectorService.connectionId
                );
            const reachable = resultTestConnection.reachable;
            if (reachable === true) {
                this.showSuccessfulConnection();
            } else if (reachable === false) {
                this.showUnsuccessfulConnection();
            } else {
                if (reachable.success === true) {
                    this.showSuccessfulConnection();
                } else {
                    this.testErrorMessage = reachable.message;
                    this.showUnsuccessfulConnection();
                }
            }
        } catch (error) {
            this.isOnlineTestSuccess = false;
            const jsonErrorMessage = error.data?.Message
                ? JSON.parse(error.data.Message)
                : {};
            if (jsonErrorMessage.connectionId) {
                this.connectorService.connectionId =
                    jsonErrorMessage.connectionId;
            }
            this.clientErrorService.notify(
                error.data || error,
                '',
                'EmbeddedConnector'
            );
            this.toasterService.errorToast({
                titleKey: this.connectionTestTitle(false),
                messageKey: this.connectionTestMsg(false),
            });
        } finally {
            this.isCurrentlyTesting = false;
        }
    }

    private showSuccessfulConnection() {
        this.isOnlineTestSuccess = true;
        this.toasterService.successToast({
            titleKey: this.connectionTestTitle(true),
            messageKey: this.connectionTestMsg(true),
        });
    }

    private showUnsuccessfulConnection() {
        this.isOnlineTestSuccess = false;
        this.toasterService.errorToast({
            titleKey: this.connectionTestTitle(false),
            messageKey: this.connectionTestMsg(false),
        });
    }

    private onTogglePreferences(newValue: boolean) {
        this.isEmbeddedImport
            ? (this.skipStepEmbedded = newValue)
            : (this.skipStepCsv = newValue);
        this.importContext.showCsvDetails = !this.skipStepCsv;
        this.importContext.showEmbeddedDetails = !this.skipStepEmbedded;
        this.importWizardService.setImportSettingsShowDetailsStep({
            showCsvDetails: this.importContext.showCsvDetails,
            showEmbeddedDetails: this.importContext.showEmbeddedDetails,
        });
    }

    private connectionTestTitle(success: boolean) {
        const result = success ? 'Success' : 'Failure';
        return `UI.Connector.Wizard.Step3.Test.${result}.Title`;
    }

    private connectionTestMsg(success: boolean) {
        const result = success ? 'Success' : 'Failure';
        return `UI.Connector.Wizard.Step3.Test.${result}.Message`;
    }

    public getTestButtonIcon() {
        if (this.isCurrentlyTesting || this.isCurrentlyUpdating) {
            return 'dg5-spinner';
        }

        if (this.isOnlineTestSuccess === false) {
            return 'glyph-mandatory red-glyph';
        }

        if (this.isOnlineTestSuccess === true) {
            return 'glyph-check green-glyph';
        }

        return '';
    }

    public getTestBtnTooltip(): string {
        if (this.isOnlineTestSuccess === false && !this.isCurrentlyTesting) {
            return this.translate.instant(
                'UI.Connector.Wizard.Step3.Test.tooltipFailure'
            );
        }
        return '';
    }

    public getTestErrorMessage(): string {
        return this.testErrorMessage;
    }

    private async onDownloadPlugin() {
        this.isDownloadPluginLoading = true;
        await this.connectorService.downloadConnector(
            this.selectedSolution.name
        );
        this.isDownloadPluginLoading = false;
    }

    private onRestartWizard() {
        if (!this.isCsvImport) {
            return;
        }
        this.currentStep = ImportSteps.csvSettings;
        const ctx = this.importContext,
            module = ctx.currentModule;
        if (module instanceof ImportModuleCatalog) {
            const newInitialSourceId =
                ctx.lastImportResult?.NewModelId || module.sourceId;
            if (newInitialSourceId) {
                ctx.initialSourceType = module.sourceType;
                ctx.initialEntityId = newInitialSourceId;
                module.isUpdate = true;
                module.sourceId = newInitialSourceId;
            }
        }
        ctx.lastImportResult = null;
        ctx.csvFile = null;
    }

    private async onConnectorImport() {
        await this.connectorService.executeWithCurrentConnectionId(
            this.importContext.spaceIdr.spaceId
        );
        this.isOnlineTestSuccess = false;
    }

    //#endregion

    private getPreferencesCheckboxValue(): boolean {
        return this.isEmbeddedImport ? this.skipStepEmbedded : this.skipStepCsv;
    }

    private makeRawContent(
        onDone: (result: string) => void,
        onError: (error: any) => void
    ) {
        const reader = new FileReader();
        reader.onload = () => onDone(reader.result as string);
        reader.onerror = (error) => onError(error);
        return reader.readAsText(
            this.importContext.csvFile,
            this.importContext.csvParseOptions.encoding
        );
    }
}

<dxy-tabs-header
    *ngIf="showTabs"
    class="stretch-tabs"
    [tabs]="headerTabs"
    (click)="$event.stopPropagation()"
    (tabChange)="onTabChange($event)"
>
    <div
        class="content-wrapper"
        [class.scrollable]="hasScrollBar"
        [class.loading]="loading"
    >
        <ng-container
            *ngIf="showAllOptions"
            [ngTemplateOutlet]="optionList"
        ></ng-container>
        <ng-container
            *ngIf="showSelectedOptions"
            [ngTemplateOutlet]="selectedOptionList"
        ></ng-container>
        <dxy-spinner *ngIf="loading" class="spinner xl"></dxy-spinner>
    </div>
</dxy-tabs-header>
<div
    *ngIf="!showTabs"
    class="content-wrapper"
    [class.scrollable]="hasScrollBar"
    [class.loading]="loading"
>
    <ng-container
        *ngIf="showAllOptions"
        [ngTemplateOutlet]="optionList"
    ></ng-container>
    <dxy-spinner *ngIf="loading" class="spinner xl"></dxy-spinner>
</div>

<ng-template #optionList>
    <div class="header" #header>
        <ng-content select="[headerBeforeSearch]"></ng-content>
        <dxy-search-input
            *ngIf="showSearchBox"
            #searchInput
            class="no-border-top height-50 no-padding"
            [showCancelSearch]="showClearSearchTerm"
            [searchString]="searchTerm"
            (onSearchString)="onSearched($event)"
            placeholderKey="CoreUI.Global.Search"
        ></dxy-search-input>
        <ng-content select="[headerAfterSearch]"></ng-content>
        <div *ngIf="showFoundCount" class="menu-section-found-count">
            <span translate>CoreUI.MultiSelect.FoundCountLabel</span>
            <span class="dg5-badge">{{ filteredOptions?.length }}</span>
        </div>
        <div *ngIf="showNotFound" class="menu-section-not-found-count">
            <ng-content select="[emptyResult]"></ng-content>
        </div>
        <div
            *ngIf="showSelectAll"
            class="option-item select-all"
            (click)="onToggleAllOptions()"
        >
            <mat-pseudo-checkbox [state]="selectAllState"></mat-pseudo-checkbox>
            <span class="text" [translate]="selectAllKey"></span>
            <span class="dg5-badge" *ngIf="searchTerm">{{
                filteredOptions?.length
            }}</span>
        </div>
    </div>
    <ng-container
        [ngTemplateOutlet]="virtualOptionList"
        [ngTemplateOutletContext]="{ $implicit: filteredOptions }"
    ></ng-container>
    <div *ngIf="showNoDataMessage" class="no-data-message">
        {{ noDataMessage }}
    </div>
</ng-template>

<ng-template #selectedOptionList>
    <ng-container *ngIf="hasSelectedValues">
        <div class="header" #header>
            <dxy-search-input
                *ngIf="showSearchBox"
                #searchInput
                class="no-border-top height-50"
                [showCancelSearch]="showClearSearchTerm"
                [searchString]="searchTerm"
                (onSearchString)="onSelectedOptionsSearch($event)"
                placeholderKey="CoreUI.Global.Search"
            ></dxy-search-input>
            <div
                *ngIf="hasMoreThanOneItem"
                class="option-item select-all"
                (click)="onUnselectAll()"
            >
                <mat-pseudo-checkbox [state]="'checked'"></mat-pseudo-checkbox>
                <span class="text" translate>CoreUI.Global.UnselectAll</span>
            </div>
        </div>
        <ng-container
            [ngTemplateOutlet]="virtualOptionList"
            [ngTemplateOutletContext]="{ $implicit: filteredSelectedOptions }"
        ></ng-container>
    </ng-container>
    <div *ngIf="!hasSelectedValues" class="empty-selected">
        <img
            src="/images/placeholders/multiselect.svg"
            class="empty-selected-img"
        />
        <span class="empty-selected-text" translate
            >CoreUI.MultiSelect.EmptySelected</span
        >
    </div>
</ng-template>

<ng-template #virtualOptionList let-options>
    <mat-selection-list
        (selectionChange)="onSelectOption($event)"
        [compareWith]="compareWith"
    >
        <cdk-virtual-scroll-viewport
            [itemSize]="itemSize"
            [minBufferPx]="minBufferPx"
            [maxBufferPx]="maxBufferPx"
            class="menu-section-scroll"
        >
            <mat-list-option
                *cdkVirtualFor="let option of options"
                class="option-item"
                [dxyDataTestId]="'menu-item-' + option.valueId"
                [selected]="isSelected(option)"
                [value]="option"
                checkboxPosition="before"
                (click)="$event.stopPropagation()"
            >
                <div class="option-item-content">
                    <dxy-option-item
                        [option]="option"
                        [dataType]="dataType"
                        [useEmptyText]="true"
                    ></dxy-option-item>
                </div>
            </mat-list-option>
        </cdk-virtual-scroll-viewport>
    </mat-selection-list>
</ng-template>

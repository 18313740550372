import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class DraggingObserver {
    private mutationObserver: MutationObserver;
    private dragging = new BehaviorSubject(false);

    public dragging$ = this.dragging.asObservable();

    constructor() {
        this.mutationObserver = new MutationObserver((mutationList) => {
            for (const mutation of mutationList) {
                const addedElements = Array.from(mutation.addedNodes);
                if (
                    addedElements.some(this.nodeHasDraggableClass) ||
                    this.hasDraggableClassAdded(mutation)
                ) {
                    this.dragging.next(true);
                }

                const removedElements = Array.from(mutation.removedNodes);
                if (
                    removedElements.some(this.nodeHasDraggableClass) ||
                    this.hasDraggableClassRemoved(mutation)
                ) {
                    this.dragging.next(false);
                }
            }
        });
    }

    private hasDraggableClassRemoved(mutation: MutationRecord): boolean {
        return (
            mutation.oldValue?.includes('ui-draggable-dragging') &&
            !this.nodeHasDraggableClass(mutation.target)
        );
    }

    private hasDraggableClassAdded(mutation: MutationRecord): boolean {
        return (
            mutation.type === 'attributes' &&
            this.nodeHasDraggableClass(mutation.target)
        );
    }

    private nodeHasDraggableClass(node: Node) {
        return (
            node instanceof HTMLElement &&
            node.classList.contains('ui-draggable-dragging')
        );
    }

    public observe(element: HTMLElement) {
        this.mutationObserver.observe(document.body, { childList: true });
        this.mutationObserver.observe(element, {
            childList: true,
            subtree: true,
            attributeFilter: ['class'],
            attributeOldValue: true,
        });
    }

    public disconnect() {
        this.mutationObserver.disconnect();
    }
}

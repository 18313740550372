import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BaseCellComponent } from '@datagalaxy/core-ui';
import {
    DgModuleName,
    getModuleDefinition,
} from '@datagalaxy/shared/dg-module/domain';
import {
    FilteredViewDto,
    FilterModuleName,
} from '@datagalaxy/webclient/filter/domain';

/**
 * ## Role
 * Filtered view Display name and module icon.
 * Used inside grids, lists or select.
 */
@Component({
    selector: 'dxy-filtered-view-card-cell',
    templateUrl: './filtered-view-card-cell.component.html',
    styleUrls: ['./filtered-view-card-cell.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilteredViewCardCellComponent extends BaseCellComponent<
    unknown,
    FilteredViewDto
> {
    @Input() filteredView?: FilteredViewDto;

    protected get module() {
        if (this.filteredView?.ModuleName == undefined) {
            return;
        }
        return getModuleDefinition(
            FilterModuleName[this.filteredView.ModuleName] as DgModuleName
        );
    }

    protected get thumbnailVisible() {
        return !this.isMini;
    }

    protected get iconVisible() {
        return this.isMini;
    }

    protected override updateBindings() {
        this.filteredView = this.value;
        this.cd.detectChanges();
    }
}

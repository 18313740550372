import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { executeOnce } from '@datagalaxy/core-ui';
import { TAccessLevel } from '../errorPages.types';
import { NavigationService } from '../../services/navigation.service';
import { AppDataService } from '../../services/app-data.service';
import { ClientPublicData } from '@datagalaxy/webclient/client/data-access';
import { FeatureFlagService } from '@datagalaxy/webclient/feature-flag';
import { AuthenticationService } from '@datagalaxy/webclient/auth/feature';
import { DxyBaseComponent } from '@datagalaxy/ui/core';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'dxy-error-page',
    templateUrl: 'dxy-error-page.component.html',
    styleUrls: ['dxy-error-page.component.scss'],
})
export class DxyErrorPageComponent extends DxyBaseComponent implements OnInit {
    @Input() accessLevel: TAccessLevel;
    @Input() errorMessage: string;

    public availableClients: ClientPublicData[] = [];
    public get canRedirect() {
        return (
            !this.isNoLoginLevel && !this.isPrivateLevel && !this.isLoginLevel
        );
    }
    public get titleKey() {
        const suffix =
            this.isLoginLevel || this.isNoLoginLevel
                ? 'login'
                : this.isPrivateLevel
                ? 'mainPrivate'
                : 'title';
        return `UI.ErrorPage.title.${suffix}`;
    }
    public get contentMessage() {
        if (this.errorMessage) {
            return this.errorMessage;
        }
        const suffix = this.isPrivateLevel ? 'mainPrivate' : 'description';
        return this.translate.instant(`UI.ErrorPage.description.${suffix}`);
    }

    public get isPrivateLevel() {
        return this.accessLevel == 'private';
    }
    public get isNoLoginLevel() {
        return this.accessLevel == 'nologin';
    }
    public get showDefaultRedirect() {
        return !this.canRedirect && !this.isNoLoginLevel;
    }

    private get isLoginLevel() {
        return this.accessLevel == 'login';
    }

    constructor(
        private navigationService: NavigationService,
        private translate: TranslateService,
        private featureFlagService: FeatureFlagService,
        private appDataService: AppDataService,
        private authenticationService: AuthenticationService
    ) {
        super();
    }

    ngOnInit() {
        this.log('ngOnInit', {
            accessLevel: this.accessLevel,
            errorMessage: this.errorMessage,
        });
        if (this.isPrivateLevel) {
            this.availableClients = this.appDataService.getAllClients();
        }
    }

    public async onChangeClient(client: ClientPublicData) {
        await this.navigationService.goToClientMain(client.ClientId);
    }

    public async redirect() {
        this.log('redirect', this.canRedirect);
        if (this.canRedirect) {
            try {
                await this.navigationService.goToClientSpacesList();
                return;
            } catch (e) {
                /* transition errors are already visible in console */
            }
        }
        await this.goToLoginPage();
    }

    @executeOnce()
    public async goToLoginPage() {
        if (this.featureFlagService.isFeatureEnabled('ENABLE_AUTH_V2')) {
            this.authenticationService.logout();
            return;
        }
        await this.navigationService.goLogout();
    }
}

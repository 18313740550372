import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import { withLoading } from '@datagalaxy/core-ui';
import { DomUtil } from '@datagalaxy/core-util';
import { CurrentSearch } from '../../search/models/CurrentSearch';
import { SearchService } from '../../search/search.service';
import { SearchUsage } from '../../shared/util/DgServerTypesApi';
import { AttributeFilterModel } from '../../shared/attribute/attribute-filter/attribute-filter/attributeFilterModel';
import { SpaceHomeService } from './space-home.service';
import { Space } from '@datagalaxy/webclient/workspace/data-access';
import { SpaceHomeStateService } from './state/space-home-state.service';
import { map } from 'rxjs';
import { DxyModalService } from '../../shared/dialogs/DxyModalService';
import { SpaceHomeHeaderEditionModalComponent } from '../space-home-header-edition-modal/space-home-header-edition-modal.component';
import { ModalSize } from '@datagalaxy/ui/dialog';
import { Dashboard } from '@datagalaxy/webclient/dashboard/ui';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { SpaceIdentifier } from '@datagalaxy/webclient/workspace/utils';
import { Filter } from '@datagalaxy/webclient/filter/domain';

/**
 * ## Role
 * Display a home page specific to each workspace
 *
 * ## Features
 * - Display a search bar (+ quick filters) and a search image header
 * - Display a list of widgets specifics to each workspace
 */
@Component({
    selector: 'app-space-home-dashboard',
    templateUrl: 'space-home-dashboard.component.html',
    styleUrls: [
        'space-home-dashboard.component.scss',
        'space-home-dashboard.component.responsive.scss',
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpaceHomeDashboardComponent
    extends DxyBaseComponent
    implements OnInit, OnChanges
{
    @Input() currentSpace: Space;

    protected spaceHomePage$ = this.spaceHomeStateService.selectSpaceHomePage();
    protected spaceHeaderImage$ = this.spaceHomePage$.pipe(
        map((hp) => {
            const fileContent = hp?.SpaceHomePageImageDto?.FileContent;
            if (fileContent) {
                return `url("${fileContent}")`;
            }
            const url = this.spaceHomeService.getImageUrl(hp);
            return `url("${url}")`;
        })
    );

    protected spotlightOpen: boolean;
    protected spotlightSearch: CurrentSearch;
    protected isQuickFiltersLoading = true;
    protected quickFilters: Filter[];

    protected get canEdit() {
        return this.currentSpace.SecurityData.HasManagementAccess;
    }
    protected get spotlightPlaceholderKey() {
        return this.spotlightSearch?.isSearchEmpty
            ? 'UI.Search.placeholder'
            : '';
    }
    protected get showQuickFilters() {
        return !!this.quickFilters?.length && !this.isQuickFiltersLoading;
    }

    protected get dashboard$() {
        return this.spaceHomeStateService.selectDashboard();
    }
    protected get editionEnabled$() {
        return this.spaceHomeStateService.selectEditionEnabled();
    }
    protected get spaceName() {
        return this.currentSpace.DisplayName;
    }

    constructor(
        private elementRef: ElementRef<HTMLElement>,
        private searchService: SearchService,
        private spaceHomeService: SpaceHomeService,
        private spaceHomeStateService: SpaceHomeStateService,
        private modalService: DxyModalService,
        private cd: ChangeDetectorRef
    ) {
        super();
    }

    ngOnChanges(changes: SimpleChanges) {
        super.onChange(changes, 'currentSpace', () => {
            this.initAsync();
        });
    }

    ngOnInit() {
        this.initAsync();
        this.subscribeEvents();
    }

    protected onSelectQuickFilter(filter: AttributeFilterModel) {
        this.log('onSelectQuickFilter', filter);
        this.spotlightOpen = true;

        /**
         * Wait for the dxy-spotlight-input animation (slide to top)
         * This avoids filter item dropdown being opened too soon, since it is
         * appended to body
         */
        setTimeout(() => {
            this.spotlightSearch.addFilterItem(filter);
            this.focusInput();
        }, 200);
    }

    protected async openHeaderModal() {
        await this.modalService.open<SpaceHomeHeaderEditionModalComponent>({
            componentType: SpaceHomeHeaderEditionModalComponent,
            size: ModalSize.Large,
        });
    }

    protected onDashboardChange(dashboard: Dashboard) {
        this.spaceHomeStateService.updateDashboard(dashboard);
    }

    @withLoading()
    private async initAsync() {
        const previousSpaceGuid = this.spaceHomeStateService.getSpaceGuid();
        if (previousSpaceGuid != this.currentSpace.spaceId) {
            const homePage = await this.spaceHomeService.getDefaultHomePage();
            this.spaceHomeStateService.init(homePage);
        }
        const spotlightSearch = (this.spotlightSearch =
            await this.getSpotlightSearch());
        await this.loadQuickFilters(spotlightSearch);

        super.subscribe(spotlightSearch.filterItemsChanged$, () =>
            this.loadQuickFilters(spotlightSearch)
        );
    }

    private subscribeEvents() {
        super.subscribe(this.searchService.isSpotlightPanelOpen$, (open) => {
            this.spotlightOpen = open;
        });
    }

    private async getSpotlightSearch() {
        const spotlightSearch = this.searchService.getNewSearch(
            this.getLogger('spotlightMainSearch')
        );
        const spaceIdr = SpaceIdentifier.from(this.currentSpace);

        if (spaceIdr) {
            spotlightSearch.setSpaceAndVersion(spaceIdr);
        }

        return spotlightSearch;
    }

    private async loadQuickFilters(spotlightSearch: CurrentSearch) {
        this.isQuickFiltersLoading = true;
        const res = await this.searchService.searchPreview(spotlightSearch, {
            searchUsage: SearchUsage.SpaceHome,
            initQuickFilters: true,
        });
        this.isQuickFiltersLoading = false;

        if (res) {
            this.quickFilters = res.quickFilters;
        }
        this.cd.detectChanges();
    }

    private focusInput() {
        DomUtil.getElement(this.elementRef.nativeElement, 'input')?.focus();
    }
}

<div class="dg_card dg_component">
    <div class="dg_component-header">
        <div class="dg_component-title">
            <span translate>UI.DataType.DataTypeModelSettings.ttlTypes</span>
            <div class="dg_component-subtitle" translate>
                UI.DataType.DataTypeModelSettings.subttlTypes
            </div>
        </div>
        <div class="page-actions">
            <button
                *ngIf="hasWriteAccess"
                mat-mini-fab
                (click)="onAddDataTypeClick()"
                [matTooltip]="
                    'UI.DataType.DataTypeModelSettings.btnAddDataType'
                        | translate
                "
                dxyLogFunctional="TYPE_DATA,C"
            >
                <span class="glyph glyph-add"></span>
            </button>
        </div>
    </div>
    <dxy-data-type-grid
        class="dg_component-content"
        [modelSettings]="modelSettings"
    ></dxy-data-type-grid>
</div>

<div *ngIf="isTechnologySettingsAvailable" class="dg_card dg_component">
    <div class="dg_component-header">
        <div class="dg_component-title">
            <span translate
                >UI.DataType.DataTypeModelSettings.ttlTechnology</span
            >
            <div class="dg_component-subtitle" translate>
                UI.DataType.DataTypeModelSettings.subttlTechnology
            </div>
        </div>
        <div class="page-actions">
            <button
                *ngIf="isClientAdmin"
                [uiSref]="StateName.ClientAdminDataDefinitionLanguage"
                mat-button
                dxyLogFunctional="DATA_TYPE_MODEL_SETTINGS,R"
            >
                <span class="glyph-datatypemapping"></span>
                <span translate
                    >UI.DataType.DataTypeModelSettings.btnGoToAdminTechnology</span
                >
            </button>
        </div>
    </div>
    <div class="current-mapping">
        <dxy-field-select
            class="no-full-width"
            labelKey="UI.DataType.DataTypeModelSettings.lblTechnology"
            [adapter]="currentMappingAdapter"
            [readonly]="!hasWriteAccess"
        ></dxy-field-select>
        <button
            *ngIf="hasWriteAccess && hasCurrentMapping"
            (click)="onDeleteCurrentMappingClick()"
            mat-button
        >
            <span translate>UI.Global.btnDelete</span>
        </button>
    </div>
    <dxy-data-type-mapping-grid
        *ngIf="hasCurrentMapping"
        class="dg_component-content"
        [modelSettings]="modelSettings"
    ></dxy-data-type-mapping-grid>
</div>

import { Pipe, PipeTransform } from '@angular/core';
import { ClientService } from '../../client/client.service';

/**
 * ## Role
 * Return an image src url based on an image hash string
 */
@Pipe({
    name: 'imageHashUrl',
})
export class ImageHashUrlPipe implements PipeTransform {
    constructor(private clientService: ClientService) {}

    transform(imageHash: string): string {
        return !imageHash
            ? null
            : `${this.clientService.getImageUrl(imageHash)}`;
    }
}

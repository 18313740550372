import { Injectable } from '@angular/core';
import { BackendApiService } from '@datagalaxy/data-access';
import {
    GetExploratoryAnalyticsDataParameter,
    GetExploratoryAnalyticsDataResult,
} from './exploratory';
import { GetDataLineageParameter, GetDataLineageResult } from './lineage';

@Injectable({ providedIn: 'root' })
export class ExplorerApiService {
    constructor(private backendApiService: BackendApiService) {}

    public async getExploratoryAnalyticsData(
        param: GetExploratoryAnalyticsDataParameter
    ) {
        return await this.backendApiService.postPromise(
            'Analytic/GetExploratoryAnalyticsData',
            param,
            GetExploratoryAnalyticsDataResult
        );
    }

    public async getDataLineage(parameter: GetDataLineageParameter) {
        return await this.backendApiService.postPromise(
            'Analytic/GetDataLineage',
            parameter,
            GetDataLineageResult
        );
    }
}

import { EntityType } from '@datagalaxy/dg-object-model';

export class ExplorationGraphRelationItem {
    public static sort(
        a: ExplorationGraphRelationItem,
        b: ExplorationGraphRelationItem
    ) {
        return a.relationNodeId > b.relationNodeId ? 1 : -1;
    }

    public get relationNodeTypeName() {
        return EntityType[this.relationNodeEntityType];
    }

    constructor(
        public relationNodeId: string,
        public relationNodeEntityType: EntityType,
        public relationLinkTypeName: string,
        public relationIsReverse: boolean
    ) {}
}

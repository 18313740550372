import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
    TaskSearchFilterGroup,
    TaskSearchFilterItem,
} from '../dxy-task-facet-filters/dxy-task-facet-filters.component';
import {
    EntityTaskDTO,
    ObjectTaskStatus,
    ObjectTaskType,
} from '@datagalaxy/dg-object-model';
import { UserService } from '../../services/user.service';
import { EntityEventService } from '../../shared/entity/services/entity-event.service';
import { FreshDeskService } from '../../shared/support/freshdesk/fresh-desk.service';
import { AppSpaceService } from '../../services/AppSpace.service';
import { CollectionsHelper } from '@datagalaxy/core-util';
import { DeleteEntityTasksParameter } from '@datagalaxy/webclient/task/data-access';
import {
    AttributeFilter,
    AttributeFilterValue,
} from '@datagalaxy/webclient/attribute/data-access';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { ISpaceIdentifier } from '@datagalaxy/webclient/workspace/domain';
import { Filter } from '@datagalaxy/webclient/filter/domain';
import { TaskSearchStateService } from '../task-search-state.service';
import { map } from 'rxjs';

@Component({
    selector: 'dxy-task-search',
    templateUrl: 'dxy-task-search.component.html',
    styleUrls: ['dxy-task-search.component.scss'],
    providers: [TaskSearchStateService],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DxyTaskSearchComponent extends DxyBaseComponent implements OnInit {
    public isSpaceVersionSelectorVisible: boolean;

    protected get state() {
        return this.stateService.state;
    }
    protected userSearched$ = this.stateService.selectUserSearched();

    protected get selectedSpaceIdr() {
        return this.stateService.state.spaceIdr;
    }
    protected get isClearSearchVisible() {
        return (
            !this.stateService.state.loading &&
            !!this.stateService.state.searchText
        );
    }
    protected isLoading$ = this.stateService.selectLoading();
    protected hasResults$ = this.stateService
        .selectFilteredTasks()
        .pipe(map((tasks) => tasks.length > 0));
    protected tasks$ = this.stateService.selectFilteredTasks();

    protected totalCountMessage$ = this.tasks$.pipe(
        map((tasks) => {
            return this.translate.instant('UI.Search.resultElements', {
                count: tasks.length,
            });
        })
    );

    protected filterGroups$ = this.stateService
        .selectAttributeFilters()
        .pipe(
            map((attributeFilters) => this.buildFilterGroups(attributeFilters))
        );

    private filterAttributeKeys = [
        'DueTime',
        'CreationUserId',
        'AssignedToUserId',
        'Status',
        'TaskType',
    ];

    constructor(
        private translate: TranslateService,
        private userService: UserService,
        private entityEventService: EntityEventService,
        private freshDeskService: FreshDeskService,
        private appSpaceService: AppSpaceService,
        private stateService: TaskSearchStateService
    ) {
        super();
    }

    ngOnInit() {
        this.logId = 'DxyTaskSearchComponent';
        super.registerSubscriptions(
            this.entityEventService.subscribeTaskDelete((task) =>
                this.onTasksDeleted(task)
            ),
            this.entityEventService.subscribeTaskUpdate((task) =>
                this.onTaskUpdated(task)
            )
        );
        this.initAsync();
    }

    public searchStringChanged(searchString: string) {
        this.stateService.setSearchString(searchString);
    }

    public async onSpaceVersionSelected(spaceIdr: ISpaceIdentifier) {
        this.stateService.setSpaceIdr(spaceIdr);
    }

    public openTaskSearchArticle() {
        this.freshDeskService.openTaskSearchArticle();
    }

    /** when the user clicks on a filter item */
    public onFilterChanged(
        item: TaskSearchFilterItem,
        group: TaskSearchFilterGroup
    ) {
        this.applyFilters(item, group);
    }

    private async initAsync() {
        if (this.appSpaceService.isSingleWorkspace()) {
            const space = await this.appSpaceService.getSingleWorkSpace();
            this.isSpaceVersionSelectorVisible =
                this.appSpaceService.isSpaceVersioningEnabled() &&
                space?.HasMultipleVersions;
        } else {
            this.isSpaceVersionSelectorVisible = true;
        }
        await this.stateService.initAsync();
    }

    private async onTasksDeleted(deleteParam: DeleteEntityTasksParameter) {
        if (deleteParam?.TaskIds?.length) {
            this.stateService.removeTasks(deleteParam.TaskIds);
        }
    }

    private async onTaskUpdated(updatedTask: EntityTaskDTO) {
        if (updatedTask) {
            this.stateService.updateTask(updatedTask);
        }
    }

    private async applyFilters(
        clickedFilterItem: TaskSearchFilterItem,
        clickedFilterGroup: TaskSearchFilterGroup
    ) {
        this.stateService.applyFilters(clickedFilterItem, clickedFilterGroup);
    }

    private buildFilterGroups(attributeFilters: AttributeFilter[]) {
        const filterItems = CollectionsHelper.flattenGroups(
            attributeFilters,
            (af) => af.Values.map((afv) => this.buildServerFilterItem(af, afv))
        );
        CollectionsHelper.replace(this.state.filtersTrack, (ti) =>
            filterItems?.find((it) => it.id == ti.id)
        );
        this.state.filtersTrack.forEach((it) => (it.isChecked = true));
        return this.filterAttributeKeys.map((fak) => {
            const title = this.translate.instant(
                this.getAttributeTranslateKey(fak)
            );
            const items = filterItems.filter(
                (it) => it.serverFilter.AttributeKey == fak
            );
            return new TaskSearchFilterGroup(fak, title, items);
        });
    }

    private buildServerFilterItem(
        af: AttributeFilter,
        afv: AttributeFilterValue
    ) {
        if (!af || !afv) {
            return;
        }
        const serverFilter = new Filter(af.AttributeKey, af.Operator, [
            afv.ValueKey,
        ]);
        const filterId =
            afv.ValueKey == undefined || !afv.ValueKey.length
                ? afv.ValueName
                : afv.ValueKey;
        const displayName = this.getFilterDisplayName(af.AttributeKey, afv);
        return new TaskSearchFilterItem(
            af.AttributeKey,
            filterId,
            displayName,
            serverFilter,
            afv.Count
        );
    }

    private getAttributeTranslateKey(attributeKey: string) {
        switch (attributeKey) {
            case 'CreationUserId':
                return 'UI.Search.colTaskCreator';
            case 'AssignedToUserId':
                return 'UI.Search.colTaskAssignedTo';
            case 'Status':
                return 'UI.Search.colTaskStatus';
            case 'TaskType':
                return 'UI.Search.colTaskType';
            case 'DueTime':
                return 'UI.Search.colTaskDueTime';
        }
    }

    private getFilterDisplayName(
        attributeKey: string,
        afv: AttributeFilterValue
    ) {
        if (!afv) {
            return;
        }
        switch (attributeKey) {
            case 'CreationUserId':
            case 'LastModificationUserId':
            case 'AssignedToUserId':
                return this.userService.getUserName(afv.ValueKey);
            case 'Status':
                return this.translate.instant(
                    'DgServerTypes.ObjectTaskStatus.' +
                        ObjectTaskStatus[afv.ValueKey]
                );
            case 'TaskType':
                return this.translate.instant(
                    'DgServerTypes.ObjectTaskType.' +
                        (afv.ValueKey == ObjectTaskType.None.toString()
                            ? '_undefined'
                            : ObjectTaskType[afv.ValueKey])
                );
            case 'DueTime':
                return this.translate.instant(
                    'UI.Search.Filters.DueTime.' + afv.ValueName
                );
        }
    }
}

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    Input,
    Output,
    ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
    MatLegacyMenuTrigger as MatMenuTrigger,
    LegacyMenuPositionX as MenuPositionX,
    LegacyMenuPositionY as MenuPositionY,
} from '@angular/material/legacy-menu';
import { DxyBaseComponent } from '@datagalaxy/ui/core';

/**
 * ## Role
 * Display a filter button with a projected mat menu content
 * It has a readonly mode and an unresolved filter state
 * It can show an operator selection list
 *
 * Note: It is internal to Core-ui, use it only to build complex filters
 */
@Component({
    selector: 'dxy-filter-button',
    templateUrl: './filter-button.component.html',
    styleUrls: ['./filter-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DxyFilterButtonComponent<TOperator> extends DxyBaseComponent {
    @Input() xMenuPosition: MenuPositionX = 'after';
    /**
     * If true, since the menu's height can be changed dynamically, we
     * position it above if there is more space than below
     */
    @Input() menuHasDynamicHeight: boolean;
    @Input() labelText?: string;
    @Input() labelKey?: string;
    @Input() isMini?: boolean;
    @Input() readonly?: boolean;
    @Input() noMenu?: boolean;
    /**
     * If false, the filter will be showed in unresolved state,
     * meaning it will show only the label with semicolon
     * A filter is considered resolved when its operator is valueless or
     * when it has an operator selected and value/values filled
     */
    @Input() isResolved?: boolean;
    /**
     * If true, a button with a delete glyph will be showed on the right side
     * On click: clickRemove event will be emitted
     */
    @Input() hasRemove?: boolean;
    @Input() removeDisabled: boolean;
    @Input() removeDisabledTooltipKey: string;
    /**
     * If the filter value display is a text use this property,
     * otherwise use customTextTemplate content projection for custom value display
     */
    @Input() text?: string;
    @Input() hideIcon: boolean;
    @Input() iconClass?: string;
    @Input() iconUrl?: string;
    /**
     * If true the mat menu panel will be smaller in width
     */
    @Input() hasSmallPanel?: boolean;
    /**
     * List of available operators
     */
    @Input() operators: TOperator[];
    @Input() operator?: TOperator;
    @Input() andOperator?: TOperator;
    @Input() orOperator?: TOperator;
    @Input() noOperator?: boolean;
    @Input() getOperatorTranslateKey: (operator: TOperator) => string;

    @Output() operatorChange = new EventEmitter<TOperator>();
    @Output() onOpenClose = new EventEmitter<boolean>();
    @Output() removeClick = new EventEmitter<MouseEvent>();

    @ViewChild('operatorMenuTrigger') operatorMenuTrigger: MatMenuTrigger;
    @ViewChild('matMenuTrigger') menuTrigger: MatMenuTrigger;
    @ViewChild('matMenuTrigger', { read: ElementRef })
    menuTriggerRef: ElementRef<HTMLElement>;

    @HostBinding('class.is-mini') get isMiniClass() {
        return this.isMini;
    }

    public menuOpen: boolean;

    protected yMenuPosition: MenuPositionY = 'below';

    public get showAndOrOperators() {
        return (
            this.andOperator &&
            this.orOperator &&
            (this.operator === this.andOperator ||
                this.operator === this.orOperator) &&
            this.operators.includes(this.orOperator) &&
            this.operators.includes(this.andOperator)
        );
    }
    public get isOrOperator() {
        return this.orOperator === this.operator;
    }
    public get label() {
        return (
            this.labelText ||
            (this.labelKey && this.translate.instant(this.labelKey))
        );
    }
    public get hasOperators() {
        return !!this.operators?.length && !this.noOperator;
    }
    public get selectedOperatorLabel() {
        return this.getOperatorLabel(this.operator);
    }
    public get showIconClass() {
        return (
            this.iconClass && this.isResolved && !this.hideIcon && !this.iconUrl
        );
    }
    public get showIconUrl() {
        return this.iconUrl && this.isResolved && !this.hideIcon;
    }
    public get isMenuOpen() {
        return this.menuOpen && !this.readonly;
    }
    public get removeDisabledTooltip() {
        return (
            this.removeDisabledTooltipKey &&
            this.translate.instant(this.removeDisabledTooltipKey)
        );
    }

    constructor(
        private translate: TranslateService,
        private cd: ChangeDetectorRef
    ) {
        super();
    }

    //#region API
    public openMenu() {
        if (this.readonly || this.menuOpen) {
            return;
        }
        this.adjustYMenuPositionIfNeeded();
        this.cd.detectChanges();
        this.menuTrigger?.openMenu();
    }
    //#endregion API

    public onMenuTriggerMouseDown() {
        if (this.readonly || this.menuOpen) {
            return;
        }

        this.adjustYMenuPositionIfNeeded();
    }

    public onMenuOpen() {
        if (!this.readonly) {
            this.menuOpen = true;
            this.onOpenClose.emit(true);
            this.cd.detectChanges();
        } else {
            this.menuTrigger.closeMenu();
        }
    }

    public onMenuClose() {
        this.menuOpen = false;
        this.onOpenClose.emit(false);
        this.cd.detectChanges();
    }

    public onSelectOperator(operator: TOperator, event: MouseEvent) {
        this.log('onSelectOperator', operator);
        event.stopPropagation();
        this.operator = operator;
        this.operatorChange.emit(operator);
        this.operatorMenuTrigger.closeMenu();
    }

    public getOperatorLabel(operator: TOperator) {
        const key = this.getOperatorTranslateKey?.(operator);
        return key ? this.translate.instant(key) : null;
    }

    public isSelectedOperator(operator: TOperator) {
        return this.operator === operator;
    }

    public onAndOrOperatorChange(isOrOperator: boolean) {
        const operator = (this.operator = isOrOperator
            ? this.orOperator
            : this.andOperator);
        this.operatorChange.emit(operator);
    }

    public isAndOperator(operator: TOperator) {
        return operator === this.andOperator;
    }

    private adjustYMenuPositionIfNeeded() {
        if (!this.menuHasDynamicHeight) {
            return;
        }
        const menuTriggerEl = this.menuTriggerRef.nativeElement;
        const menuTriggerMiddleOffsetY =
            menuTriggerEl.getBoundingClientRect().top +
            menuTriggerEl.offsetHeight / 2;

        this.yMenuPosition =
            menuTriggerMiddleOffsetY > window.innerHeight / 2
                ? 'above'
                : 'below';
    }
}

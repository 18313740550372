import { NgModule } from '@angular/core';
import { DxySharedDialogsModule } from '../shared/dialogs/DxySharedDialogsModule';
import { SharedModule } from '../shared/shared.module';
import { DxySharedUiModule } from '../shared/shared-ui/DxySharedUiModule';
import { FieldsModule } from '../shared/fields/fields.module';
import { DataQualityContainerComponent } from './data-quality-container/data-quality-container.component';
import { DxyOmnigridModule } from '@datagalaxy/core-ui/omnigrid';
import { DxyModelerModule } from '../modeler/DxyModelerModule';
import { DataQualityTabComponent } from './data-quality-tab-component/data-quality-tab.component';
import { DataQualityRulesGridComponent } from './data-quality-rules-grid/data-quality-rules-grid.component';
import { LastChecksCellComponent } from './last-checks-cell-component/last-checks-cell.component';
import { RuleCheckListModalComponent } from './rule-check-modal/rule-check-list-modal.component';
import { CoreUiModule } from '@datagalaxy/core-ui';
import { RuleCreateModalComponent } from './rule-create-modal/rule-create-modal.component';
import { RuleEditModalComponent } from './rule-edit-modal/rule-edit-modal.component';
import {
    DxyGridColumnSelectorComponent,
    GridComponent,
} from '@datagalaxy/ui/grid';
import { GridUserSettingsDirective } from '../user/grid-user-settings/grid-user-settings.directive';
import { DxyButtonsModule } from '@datagalaxy/ui/buttons';

@NgModule({
    imports: [
        SharedModule,
        DxySharedDialogsModule,
        FieldsModule,
        DxySharedUiModule,
        DxyOmnigridModule,
        DxyModelerModule,
        CoreUiModule,
        GridComponent,
        GridUserSettingsDirective,
        GridUserSettingsDirective,
        DxyGridColumnSelectorComponent,
        DxyButtonsModule,
    ],
    declarations: [
        DataQualityContainerComponent,
        DataQualityTabComponent,
        DataQualityRulesGridComponent,
        LastChecksCellComponent,
        RuleCheckListModalComponent,
        RuleCreateModalComponent,
        RuleEditModalComponent,
    ],
    exports: [
        DataQualityContainerComponent,
        DataQualityTabComponent,
        DataQualityRulesGridComponent,
        LastChecksCellComponent,
        RuleCheckListModalComponent,
        RuleCreateModalComponent,
        RuleEditModalComponent,
    ],
})
export class DataQualityModule {}

import {
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import { ImpactAnalysisTool } from '../../shared/util/app-types/impact-analysis.types';
import { ExplorationGraphData } from '../data/ExplorationGraphData';
import { LineageGraphParams } from '../lineage-graph/data/LineageGraphParams';
import { ImpactAnalysisService } from '../impact-analysis.service';
import { NavigationService } from '../../services/navigation.service';
import { Constants } from '../../shared/util/Constants';
import {
    CrudOperation,
    FunctionalLogService,
} from '@datagalaxy/webclient/monitoring/data-access';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';

@Component({
    selector: 'app-entity-impact-analysis',
    templateUrl: 'entity-impact-analysis.component.html',
    styleUrls: ['entity-impact-analysis.component.scss'],
})
export class EntityImpactAnalysisComponent
    extends DxyBaseComponent
    implements OnChanges, OnInit
{
    @Input() entityData: EntityItem;
    @Input() selectedTool: ImpactAnalysisTool;
    @Input() defaultTool: ImpactAnalysisTool;

    public explorationGraphData: ExplorationGraphData;
    public lineageGraphParams: LineageGraphParams;
    public get isActiveToolLineage() {
        return ImpactAnalysisService.isLineage(this.selectedTool);
    }
    public get isActiveToolLineageLegacy() {
        return ImpactAnalysisService.isLineageLegacy(this.selectedTool);
    }
    public get isActiveToolLineageLegacyReady() {
        return this.isActiveToolLineageLegacy && !!this.lineageGraphParams;
    }
    public get isActiveToolExplorer() {
        return (
            !!this.explorationGraphData &&
            ImpactAnalysisService.isExplorer(this.selectedTool)
        );
    }

    constructor(
        private impactAnalysisService: ImpactAnalysisService,
        private functionalLogService: FunctionalLogService,
        private navigationService: NavigationService
    ) {
        super();
    }

    ngOnChanges(changes: SimpleChanges) {
        super.onChange(changes, 'entityData', () =>
            this.updateLineageGraphParams()
        );
    }

    ngOnInit() {
        this.log(
            'ngOnInit',
            this.entityData,
            this.selectedTool,
            this.explorationGraphData
        );
        void this.init();
    }

    private async init() {
        this.selectedTool = this.impactAnalysisService.getInitialSelectedTool(
            this.selectedTool,
            this.defaultTool
        );
        await this.initWithtool(this.selectedTool);
        super.subscribe(
            this.impactAnalysisService.onSelectedToolChange$,
            (tool) => this.initWithtool(tool)
        );
    }

    private async initWithtool(selectedTool?: ImpactAnalysisTool) {
        this.log(
            'initWithtool',
            selectedTool,
            ImpactAnalysisTool[selectedTool],
            this.entityData
        );

        const isDefaulted =
            selectedTool == undefined ||
            selectedTool == ImpactAnalysisTool.None;
        if (isDefaulted) {
            selectedTool = this.defaultTool;
        }

        this.navigationService.updateUrlWithoutChangingState({
            [Constants.Nav.ParamKey.ImpactAnalysisTool]:
                selectedTool?.toString(),
        });

        if (!this.entityData) {
            return;
        }

        const isLineage = ImpactAnalysisService.isLineageLegacy(selectedTool),
            isExplorer = ImpactAnalysisService.isExplorer(selectedTool);

        if (!isDefaulted && (isLineage || isExplorer)) {
            const entityCode =
                FunctionalLogService.getEntityItemActionFeatureCode(
                    this.entityData
                );
            const toolString = isExplorer ? 'ANALYSIS_EXPLORE' : 'ANALYSIS';
            this.functionalLogService.logFunctionalAction(
                `${entityCode}_${toolString}`,
                CrudOperation.R
            );
        }

        const wasExplorer =
                ImpactAnalysisService.isExplorer(this.selectedTool) &&
                !!this.explorationGraphData,
            toExplorer = isExplorer && !wasExplorer;

        this.selectedTool = selectedTool;

        if (toExplorer) {
            this.lineageGraphParams = this.explorationGraphData = undefined;
            const result =
                await this.impactAnalysisService.initExploratoryFromEntity(
                    this.entityData,
                    true
                );
            this.log('initWithtool-result');
            this.explorationGraphData = result;
        } else if (isLineage) {
            this.lineageGraphParams = this.explorationGraphData = undefined;
            this.updateLineageGraphParams();
        }
    }

    private updateLineageGraphParams() {
        if (!this.isActiveToolLineageLegacy) {
            return;
        }

        this.log('updateLineageGraphParams');
        this.lineageGraphParams =
            this.impactAnalysisService.getLineageGraphParams(
                this.entityData,
                this.selectedTool
            );
    }
}

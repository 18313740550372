import {
    CellMouseOutEvent,
    CellMouseOverEvent,
    ColDef,
    Column,
    ColumnApi,
    ColumnMovedEvent,
    ColumnResizedEvent,
    ColumnVisibleEvent,
    GetContextMenuItemsParams,
    GetMainMenuItemsParams,
    GridApi,
    GridOptions,
    GridReadyEvent,
    GridSizeChangedEvent,
    IServerSideDatasource,
    IServerSideGetRowsParams,
    IServerSideGetRowsRequest,
    MenuItemDef,
    RowClickedEvent,
    RowGroupOpenedEvent,
    RowNode,
    SelectionChangedEvent,
    SortChangedEvent,
} from 'ag-grid-community';
import { LicenseManager } from 'ag-grid-enterprise';
import {
    CollectionsHelper,
    CoreUtil,
    ICancellableTimeout,
    IExecutor,
    wait,
} from '@datagalaxy/core-util';
import { DomUtil } from '@datagalaxy/core-util';
import {
    IOmniGridApi,
    IOmniGridColumnDef,
    IOmniGridDataInfo,
    IUpdateCellsLayoutParams,
    OmniGridSortModel,
} from './OmniGridTypes';
import {
    OmniGridExportFormat,
    IOmniGridExportOptions,
    ILoadMultiResult,
    IOmniGridColumnState,
    IOmniGridState,
} from './OmniGridTypes';
import { OmniGridActionTool } from './OmniGridActionTool';
import { ITranslate } from '../services';
import {
    DxyLoadingCellComponent,
    DxyGroupCellComponent,
    IGroupCellParams,
} from './cell-components';
import { BaseCellComponent } from '../cell-components';
import { INgZone } from '@datagalaxy/utils';

LicenseManager.setLicenseKey(
    'Evaluation_License-_Not_For_Production_Valid_Until_22_June_2019__MTU2MTE1ODAwMDAwMA==f3aec40b5c0abbfd6e9d922f3c96e0a4'
);

export class OmniGridCore<TEntity> implements IOmniGridApi<TEntity> {
    private debugDetailed = false;

    //#region getters
    get data() {
        return this._data;
    }
    private _data: IOmniGridDataInfo<TEntity>;

    public get gridOptions() {
        return this._gridOptions;
    }
    private _gridOptions: GridOptions;

    public get isSingleColumn() {
        return this._isSingleColumn;
    }
    private _isSingleColumn: boolean;

    public get isReady() {
        return !!(this.data && this._gridOptions);
    }
    public get isTree() {
        return !!this.data?.tree;
    }
    public get isRowSelectSingle() {
        return this.rowSelection == 'single';
    }
    public get isAutoHeight() {
        return this.autoHeight;
    }
    //#endregion

    //#region locals

    private debug?: boolean;
    private element: HTMLElement;

    private _isInitDone = false;
    private _isReadyDone = false;

    private currentRowIndex: number;
    private isLeavingRowOver: boolean;
    private actionTool: OmniGridActionTool<TEntity>;
    private updatingAllCellsLayout: ICancellableTimeout<void>;
    private updatingColumnCellsLayout: ICancellableTimeout<void>;
    private readonly groupFieldName = '_group';

    private rootLoaded: IExecutor<void>;
    private readonly childrenLoading = new Map<RowNode, IExecutor<void>>();

    private useChildrenCache: boolean;
    private childrenCache: Map<RowNode, RowNode[]>;

    private overlayNoRowsTemplate: string;

    private isDraggingColumn: boolean;
    private draggingColumn: Column;
    private columnStatesOnDragStart: IOmniGridColumnState[];

    private columnStates: IOmniGridColumnState[];

    private cbSelectAllForceVisible: boolean;
    private cbSelectAllForcingVisible: ICancellableTimeout<void>;
    private cbSelectAllColumnId: string;
    private cbSelectAllListener: (e: MouseEvent) => void;
    private cbSelectAllSelected: boolean;

    private readonly autoSpanColumns = true;

    private rowSelection?: 'single' | 'multiple';
    private disableRowSelection?: boolean;
    private rowHeight: number;
    private groupRowHeight: number;
    private groupHeaderClass: string;
    private headerHeight: number;
    private autoHeight: boolean;
    private loadingText: string;
    private animateRows: boolean;
    private showNoResults: boolean;
    private noResultText: string;
    private useGroupRowDefaultIcon?: boolean;
    private groupCaretPosition: 'before' | 'after' = 'before';
    private canRemoveColumnByDragging: boolean;
    private agGridDebug: boolean;

    private cancellableTimeouts: (() => void)[] = [];

    private headerTooltips: HTMLElement[];

    private get isInfiniteLoad() {
        return !!this.data?.infiniteLoad;
    }
    private get isSelectMultiple() {
        return this.rowSelection == 'multiple';
    }

    private get noApi() {
        if (this._gridOptions?.api) {
            return;
        }
        this.log('no api');
        return true;
    }
    private get noColumnApi() {
        if (this._gridOptions?.columnApi) {
            return;
        }
        this.log('no columnApi');
        return true;
    }
    private get gridApi() {
        return this._gridOptions.api;
    }
    private get columnApi() {
        return this._gridOptions.columnApi;
    }

    //#endregion

    constructor(
        private translate: ITranslate,
        private ngZone?: INgZone,
        private onReady?: (gridApi: IOmniGridApi<TEntity>) => void,
        private onRowClick?: (
            data: TEntity,
            refreshRow?: (newData: TEntity) => void
        ) => void,
        private onSortChange?: (sortModel: OmniGridSortModel) => void,
        private onSelectionChange?: () => void,
        private onColumnsChange?: () => void,
        private setTooltip?: (el: Element, message: string) => void,
        private removeTooltip?: (el: Element) => void,
        private removeTooltips?: (els: Element[]) => void,
        private logFn?: (...args: any[]) => void
    ) {}

    public async init(element: HTMLElement, data: IOmniGridDataInfo<TEntity>) {
        this.element = element;
        this._data = data;

        this.initOptions();

        this.debug &&
            this.log('core.init', {
                data: this.data,
                element: element,
                autoHeight: this.autoHeight,
                rowSelection: this.rowSelection,
            });

        const noResultText =
            this.noResultText ||
            this.translate.instant('UI.SourceList.NoResults');
        this.overlayNoRowsTemplate =
            this.getOverlayNoRowsTemplate(noResultText);

        this.childrenLoading.clear();
        this.rootLoaded = undefined;

        if (this.useChildrenCache) {
            this.childrenCache = new Map<RowNode, RowNode[]>();
        }

        this._isInitDone = true;
        await this.setupGrid(true);
    }

    public updateData(data: IOmniGridDataInfo<TEntity>) {
        this._data = data;
        this.setupGrid();
    }

    public destroy() {
        this.log('core.destroy', this.cancellableTimeouts.length);
        this.cancellableTimeouts.forEach((cancel) => cancel());
        this.destroyAllTooltips();
    }

    //#region IOmniGridApi

    /** refresh displayed cells content and layout */
    async refreshView() {
        if (this.noApi) {
            return;
        }
        let columns = this.getDisplayedFields();
        if (!columns.length) {
            columns = undefined;
        }
        this.log('refreshView', columns);
        this.gridApi.refreshCells({ force: true, columns });
        // when called from entitygrid, ensures miniobject breadcrumb tooltip updating
        await this.updateAllCellsLayout('refreshView');
    }

    async expandCollapseAll(isExpanded: boolean) {
        this.log('expandCollapseAll', isExpanded);
        await this.withApi((api) =>
            isExpanded ? api.expandAll() : api.collapseAll()
        );
        await this.updateAllCellsLayout('expandCollapseAll');
    }
    async setRowSelected(
        rowId: string,
        isSelected = true,
        clearSelection = false
    ) {
        this.log('setRowSelected', rowId, isSelected, clearSelection);
        return this.withApi((api) => {
            const node =
                api.getRowNode(rowId) ??
                api.getSelectedNodes()?.find((n) => n.id === rowId);
            if (!node) {
                return false;
            }
            node.setSelected(isSelected, clearSelection);
            return true;
        });
    }
    async setRowsSelected(
        rowIds: string[],
        isSelected = true,
        clearSelection = false
    ) {
        this.log('setRowsSelected', rowIds, isSelected, clearSelection);
        await this.withApi((api) => {
            const lastIndex = rowIds.length - 1;
            rowIds.forEach((rowId, index) => {
                const node = api.getRowNode(rowId);
                if (!node) {
                    return;
                }
                node.setSelected(
                    isSelected,
                    clearSelection,
                    index != lastIndex
                );
            });
        });
    }
    async clearSelection() {
        this.log('clearSelection');
        const selectionChanged = this.setAllNodesSelected(false);
        if (selectionChanged) {
            this.onSelectionChange?.();
        }
    }

    clearFocusedCell() {
        this.gridApi.clearFocusedCell();
    }

    async forceNodeRefresh(
        rowId: string,
        hasContextualChildren?: (entity: TEntity) => boolean,
        getContextualAllLevelChildrenCount?: (entity: TEntity) => number
    ) {
        this.log('getRowNode', rowId);
        if (this.noApi) {
            return;
        }
        const node = this.gridApi.getRowNode(rowId);
        if (!node) {
            return;
        }
        node.setExpanded(node.expanded);
        node.group = hasContextualChildren?.(node.data);
        node.setAllChildrenCount(
            getContextualAllLevelChildrenCount?.(node.data) ?? 0
        );
    }
    async getRowData(rowId: string) {
        this.log('getRowData', rowId);
        return this.withApi((api) => {
            const node = api.getRowNode(rowId);
            return node?.data;
        });
    }
    async setRowData(rowId: string, newData: TEntity) {
        this.log('setRowData', rowId, newData);
        return this.withApi((api) => {
            const node = api.getRowNode(rowId);
            if (!node) {
                return false;
            }
            node.setData(newData);
            if (this.isTree) {
                //force the displayName to update
                api.redrawRows({ rowNodes: [node] });
            }
            this.updateActionTool(node.rowIndex, newData);
            return true;
        });
    }
    async setRowsData(rowsIdAndData: Map<string, TEntity>) {
        this.log('setRowsData', rowsIdAndData?.size);
        return this.withApi((api) => {
            const result = new Array<string>();
            const nodes = new Array<RowNode>();

            rowsIdAndData.forEach((newData, rowId) => {
                const node = api.getRowNode(rowId);
                if (!node) {
                    return;
                }
                node.setData(newData);
                nodes.push(node);
                result.push(rowId);
            });

            if (this.isTree && nodes.length) {
                api.redrawRows({ rowNodes: nodes });
            }

            return result;
        });
    }

    async removeRows(predicate: (rowData: any) => boolean) {
        return this.withApi((api) => {
            const nodesData = new Array<any>();
            api.forEachNode((node) => {
                if (predicate(node.data)) {
                    nodesData.push(node.data);
                }
            });
            if (nodesData.length) {
                if (this.isTree || this.isInfiniteLoad) {
                    this.warn(
                        'removeRows is not valid for Tree and InfiniteLoad modes'
                    );
                }
                api.updateRowData({ remove: nodesData });
            }
            return nodesData;
        });
    }
    async updateRows(predicate: (rowData: any) => boolean) {
        return this.withApi((api) => {
            const nodesData = new Array<any>();
            api.forEachNode((node) => {
                if (predicate(node.data)) {
                    nodesData.push(node.data);
                }
            });
            if (nodesData.length) {
                if (this.isTree || this.isInfiniteLoad) {
                    this.warn(
                        'updateRows is not valid for Tree and InfiniteLoad modes'
                    );
                }
                api.updateRowData({ update: nodesData });
            }
            return nodesData;
        });
    }

    async refreshAllRows(preserveExpandedRows = false) {
        this.log('refreshAllRows', preserveExpandedRows);
        if (this.noApi) {
            return;
        }
        const api = this.gridApi;

        let promise = new Promise<void>((resolve, reject) => {
            this.rootLoaded = { resolve, reject };
        });
        if (this.isTree && preserveExpandedRows) {
            const expandedRowIds = this.getOrderedExpandedRowIds(api);
            if (expandedRowIds?.length) {
                promise = promise.then(() => {
                    this.log(
                        'refreshAllRows-preserveExpandedRows-setRowsExpanded',
                        expandedRowIds
                    );
                    return this.setRowsExpanded(expandedRowIds, true, true);
                });
            }
        }
        this.log('refreshAllRows-purgeServerSideCache');
        api.purgeServerSideCache();
        return promise;
    }

    async refreshAllCells(columns: string[]) {
        if (this.noApi) {
            return;
        }
        this.gridApi.refreshCells({ force: true, columns: columns });
    }

    async refreshNodeOnAction(parentId: string, preserveExpandedRows = false) {
        await this.refreshTopAncestors([parentId], preserveExpandedRows);
    }

    async updateNodeData(nodeId: string, newNodeData: TEntity) {
        await this.withApi((api) => {
            const updatedNode = api.getRowNode(nodeId);
            if (!updatedNode) {
                return;
            }
            updatedNode.updateData(newNodeData);
            api.refreshCells({ rowNodes: [updatedNode], force: true });
        });
    }

    async scrollToNode(rowId: string) {
        if (this.noApi) {
            return;
        }
        const api = this.gridApi;
        const currentNode = api.getRowNode(rowId);
        if (!currentNode) {
            return;
        }
        api.ensureNodeVisible(currentNode, 'middle');
    }

    async sizeColumnsToFit() {
        if (this.noApi) {
            return;
        }
        const api = this.gridApi;
        const hasWidth = this.element?.offsetWidth > 0;
        this.log('sizeColumnsToFit', hasWidth);
        if (!hasWidth) {
            return;
        }
        api.sizeColumnsToFit();
        this.log('sizeColumnsToFit-end');
        await this.timeout(() => this.getColumnStates());
    }
    async setRowsExpanded(
        rowIds: string | string[],
        isExpanded = true,
        chained = false
    ) {
        this.log('setRowsExpanded', rowIds, isExpanded, chained);
        if (this.noApi) {
            return;
        }
        const api = this.gridApi;
        const ids = Array.isArray(rowIds) ? rowIds : [rowIds];
        const getPromise = async (id: string) => {
            const node = api.getRowNode(id);
            await this.setNodeExpanded(node, isExpanded);
        };
        if (chained) {
            await ids.reduce(
                (p, id) => p.then(() => getPromise(id)),
                Promise.resolve()
            );
        } else {
            await Promise.all(ids.map(getPromise));
        }
    }
    async refreshForParentChanged(
        rowId: string,
        newParentId: string,
        preserveExpandedRows = false
    ) {
        this.log('refreshForParentChanged', rowId, newParentId);
        if (this.noApi) {
            return;
        }
        const api = this.gridApi;
        if (!this.isTree) {
            return;
        }
        const node = api.getRowNode(rowId);
        if (!node && newParentId) {
            return;
        }
        const oldAncestorId = this.getParentOrGreatParentId(node.parent, -1);
        const newAncestorId = this.getParentOrGreatParentId(
            api.getRowNode(newParentId),
            1
        );
        await this.refreshTopAncestors(
            [oldAncestorId, newAncestorId],
            preserveExpandedRows
        );
    }
    async refreshForParentsChanged(
        rowsIdAndNewParentId: Map<string, string>,
        preserveExpandedRows?: boolean
    ) {
        this.log(
            'refreshForParentsChanged',
            rowsIdAndNewParentId && rowsIdAndNewParentId.size
        );
        if (this.noApi) {
            return;
        }
        const api = this.gridApi;
        if (!this.isTree) {
            return;
        }
        const ancestorIds = new Array<string>();
        rowsIdAndNewParentId.forEach((newParentId, rowId) => {
            const node = api.getRowNode(rowId);
            if (!node) {
                return;
            }
            const oldAncestorId = this.getParentOrGreatParentId(
                node.parent,
                -1
            );
            const newAncestorId = this.getParentOrGreatParentId(
                api.getRowNode(newParentId),
                1
            );
            ancestorIds.push(oldAncestorId, newAncestorId);
        });
        await this.refreshTopAncestors(
            CollectionsHelper.distinct(ancestorIds),
            preserveExpandedRows
        );
    }

    async refreshForRemoved(
        rowIds: string[],
        areChildrenReParentedToRoot = false,
        preserveExpandedRows = false
    ) {
        this.log('refreshForRemoved', rowIds);
        if (this.noApi) {
            return;
        }
        const api = this.gridApi;

        if (!this.isTree) {
            await this.refreshAllRows(preserveExpandedRows);
            return;
        }

        let refreshRoot = false;
        const options = this.data.tree;
        const parentChildrenToRemove = new Map<string, number>(); // parentId -> nbChildrenToRemove
        rowIds.forEach((rowId) => {
            if (refreshRoot) {
                return;
            }

            const node = api.getRowNode(rowId);
            if (!node) {
                return;
            }

            if (areChildrenReParentedToRoot && node.hasChildren()) {
                //if at least one deleted entity has children, its children will we rooted, so we refresh the root
                refreshRoot = true;
            } else {
                const parentId =
                    node.parent &&
                    node.parent.data &&
                    options.getEntityId(node.parent.data as TEntity);
                parentChildrenToRemove.set(
                    parentId,
                    1 + (parentChildrenToRemove.get(parentId) || 0)
                );
            }
        });

        const idsToRefresh = new Array<string>();
        if (refreshRoot) {
            idsToRefresh.push(null);
        } else {
            parentChildrenToRemove.forEach((nbChildrenToRemove, parentId) =>
                idsToRefresh.push(
                    this.getParentOrGreatParentId(
                        api.getRowNode(parentId),
                        -nbChildrenToRemove
                    )
                )
            );
        }
        await this.refreshTopAncestors(idsToRefresh, preserveExpandedRows);
    }
    async getSelectedRows() {
        return await this.withApi((api) => api.getSelectedRows() as TEntity[]);
    }
    async setColumnVisible(colIds: string | string[], visible: boolean) {
        this.log('setColumnVisible', colIds, visible);
        if (this.noApi) {
            return;
        }
        const api = this.columnApi;
        if (Array.isArray(colIds)) {
            api.setColumnsVisible(colIds as string[], visible);
        } else {
            api.setColumnVisible(colIds, visible);
        }
        this.updateCbSelectAllVisible();
        await this.updateAllCellsLayout('setColumnVisible');
    }

    async setColumnDefs(
        colDefs: (IOmniGridColumnDef | ColDef)[],
        deltaColumnMode?: boolean
    ) {
        this.log(
            'setColumnDefs',
            deltaColumnMode /*, colDefs.filter(c=> !c.hide).map(c=> c.colId)*/,
            colDefs
        );
        await this.withApi((api) => {
            const currentDeltaColumnMode = this._gridOptions.deltaColumnMode;
            if (deltaColumnMode != undefined) {
                this._gridOptions.deltaColumnMode = deltaColumnMode;
            }
            api.setColumnDefs(colDefs as ColDef[]);
            if (deltaColumnMode != undefined) {
                this._gridOptions.deltaColumnMode = currentDeltaColumnMode;
            }
        });
        if (this.autoSpanColumns) {
            await this.sizeColumnsToFit();
        }
        this.updateCbSelectAllVisible();
        await this.setHeaderTooltips();
        await this.updateAllCellsLayout('setColumnDefs');
    }

    async getState() {
        this.log('getState');
        return await this.withColumnApi((api) => {
            const fields = ['colId', 'hide', 'width'];
            const getStateLight = (ic: IOmniGridColumnState) => {
                const oc = {} as IOmniGridColumnState;
                fields.forEach((k) => {
                    if (ic[k] != undefined) {
                        oc[k] = ic[k];
                    }
                });
                return oc;
            };
            const columns = api.getColumnState().map(getStateLight);
            return {
                version: 'omni-grid-1',
                columns: columns,
            } as IOmniGridState;
        });
    }
    async setState(gridState: IOmniGridState) {
        this.log('setState', gridState);
        return await this.withColumnApi(async (api) => {
            const css = api.getColumnState();
            const columnStates = gridState?.columns?.filter((ca) =>
                css.some((cb) => cb.colId == ca.colId)
            );
            if (!columnStates?.length || !api.setColumnState(columnStates)) {
                return false;
            }

            if (this.autoSpanColumns) {
                await this.sizeColumnsToFit();
            }
            this.updateCbSelectAllVisible();
            this.setHeaderTooltips();
            await this.updateAllCellsLayout('setState');
            return true;
        });
    }
    async resetState() {
        this.log('resetState');
        await this.withColumnApi((api) => api.resetColumnState());
        if (this.autoSpanColumns) {
            await this.sizeColumnsToFit();
        }
        this.updateCbSelectAllVisible();
        this.setHeaderTooltips();
        await this.updateAllCellsLayout('resetState');
    }

    async refreshCellsLayout() {
        await this.updateAllCellsLayout('refreshCellsLayout');
    }

    async getFirstData() {
        return await this.withApi((api) => {
            const row = api.getDisplayedRowAtIndex(0);
            return row?.data as TEntity;
        });
    }

    async getExpandedRowIds() {
        return await this.withApi((api) => this.getOrderedExpandedRowIds(api));
    }

    async updateGridData() {
        this.log(
            'updateGridData',
            'isTree: ',
            this.isTree,
            'isInfiniteLoad:',
            this.isInfiniteLoad
        );
        await this.withApi((api) => {
            if (this.isTree || this.isInfiniteLoad) {
                api.setServerSideDatasource(this.makeServerDataSource(true));
            } else {
                api.setRowData(this.getRowsData());
            }
        });
    }

    async export(options: IOmniGridExportOptions) {
        await this.withApi((api) => {
            this.log('export', OmniGridExportFormat[options?.format], options);
            switch (options.format) {
                case OmniGridExportFormat.excel:
                    api.exportDataAsExcel(options.excelParams);
                    break;
                default:
                    api.exportDataAsCsv(options.csvParams);
                    break;
            }
        });
    }

    getRowPosition(rowId: string): DOMRect {
        const element = this.element?.querySelector(
            `div.ag-center-cols-container div[row-id="${rowId}"]`
        );

        return element?.getBoundingClientRect();
    }

    //#endregion IOmniGridApi

    //#region event handlers

    private onCellMouseOver(e: CellMouseOverEvent) {
        this.isLeavingRowOver = false;
        if (this.currentRowIndex != e.rowIndex) {
            this.currentRowIndex = e.rowIndex;
            this.showActionTool(e.rowIndex, e.data);
        }
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    private async onCellMouseOut(e: CellMouseOutEvent) {
        if (this.actionTool?.isMouseOver) {
            return;
        }
        this.isLeavingRowOver = true;
        await this.timeout(() => {
            if (this.isLeavingRowOver) {
                this.currentRowIndex = this.isLeavingRowOver = undefined;
                this.hideActionTool();
            }
        });
    }

    private onSortChanged(e: SortChangedEvent) {
        this.onSortChange?.(e.api.getSortModel());
    }

    private onRowClicked(e: RowClickedEvent) {
        this.log('onRowClicked', e.node);

        // Check if source element is children of burger menu  => cancel navigation
        if (
            DomUtil.getParents(e.event.target as HTMLElement, (e) =>
                e.classList.contains('omnigrid-burger-menu')
            ).length
        ) {
            e.event.stopPropagation();
            return;
        }

        if (e.node.group) {
            // group row
            const options = this.data.tree;
            if (typeof options?.onGroupRowClick == 'function') {
                options.onGroupRowClick(e.data as TEntity);
            }
        } else if (typeof this.onRowClick == 'function') {
            // normal row, and onRowClick is defined
            const afterAction = (newData: TEntity, isDeleted?: boolean) => {
                this.log('onRowClicked-refreshRow', newData, isDeleted, e.node);
                if (isDeleted === true) {
                    e.api.updateRowData({ remove: [e.node.data] });
                } else {
                    e.node.updateData(newData);
                    e.api.refreshCells({ rowNodes: [e.node], force: true });
                }
            };
            this.onRowClick(e.data as TEntity, afterAction);
        }
    }

    private onGridReady(e: GridReadyEvent) {
        this.log('onGridReady', e, this._gridOptions);
        this.updateCbSelectAllVisible();
        this.setHeaderTooltips();
    }
    private async onGridSizeChanged(e: GridSizeChangedEvent) {
        this.log('onGridSizeChanged', this.autoSpanColumns, e);
        if (this.autoSpanColumns) {
            await this.sizeColumnsToFit();
        }
        await this.updateAllCellsLayout('onGridSizeChanged');
        this.updateCbSelectAllVisible();
    }

    private async onRowGroupOpened(e: RowGroupOpenedEvent) {
        this.log('onRowGroupOpened', e.node.expanded, e);
        await this.updateAllCellsLayout('onRowGroupOpened');
        if (
            this.isTree &&
            this.data.tree.purgeClosedRowNodesChildrenSelection &&
            !e.node.expanded
        ) {
            this.unselectDescendants(e.node);
        }
    }
    private onSelectionChanged(e: SelectionChangedEvent) {
        this.log('onSelectionChanged', e);
        this.onSelectionChange?.();
    }

    //#region columns changed

    private onColumnResized(e: ColumnResizedEvent) {
        //this.log('onColumnResized', e.column, e.finished)
        if (this.isDraggingColumn) {
            this.draggingColumn = e.column;
        }
        this.updateColumnCellsLayout(e.column, 'onColumnResized');
    }
    private async onColumnVisible(e: ColumnVisibleEvent) {
        if (this.isDraggingColumn) {
            this.log('onColumnVisible-dragged', e.column);
            this.draggingColumn = e.column;
        } else {
            this.log('onColumnVisible-viaApi', e.column, e.visible);
            this.colChanged(e.column);
        }
        this.updateCbSelectAllVisible();
        this.setHeaderTooltips();
        if (this.autoSpanColumns) {
            await this.sizeColumnsToFit();
        }
        await this.updateAllCellsLayout('onColumnVisible');
    }
    private onColumnMoved(e: ColumnMovedEvent) {
        this.log('onColumnMoved', e);
        if (this.isDraggingColumn) {
            this.draggingColumn = e.column;
        }
        this.updateCbSelectAllVisible();
        this.updateAllCellsLayout('onColumnMoved');
    }
    private onColDrag(isStart: boolean) {
        this.log('onColDrag', isStart);
        this.isDraggingColumn = isStart;
        if (isStart) {
            this.draggingColumn = undefined;
            this.columnStatesOnDragStart = CoreUtil.cloneDeep(
                this.columnStates
            );
        } else if (this.draggingColumn) {
            const colId = this.draggingColumn.getColId();
            const newStates = this._gridOptions.columnApi.getColumnState();
            // archi-omnigrid(revi) : this.columnStatesOnDragStart could be undefined.
            // Had some errors in console. To investigate.
            const oldState = this.columnStatesOnDragStart?.find(
                (c) => c.colId == colId
            );
            const newState = newStates.find((c) => c.colId == colId);

            if (!oldState && !newState) {
                return;
            }
            if (oldState && newState) {
                const oldIndex = this.columnStates.indexOf(oldState);
                const newIndex = newStates.indexOf(newState);
                const changed =
                    oldIndex != newIndex ||
                    oldState.hide != newState.hide ||
                    oldState.width != newState.width;
                this.log(
                    'onColDrag-stop-changed',
                    changed,
                    colId,
                    oldIndex,
                    newIndex,
                    oldState.hide,
                    newState.hide,
                    oldState.width,
                    newState.width
                );
                if (!changed) {
                    return;
                }
            } else {
                this.log('onColDrag-stop-new/removed', oldState, newState);
            }

            const changedColumn = this.draggingColumn;
            this.draggingColumn = undefined;
            this.columnStates = newStates;
            this.columnStatesOnDragStart = undefined;
            this.colChanged(changedColumn, true);
        }
    }

    //#endregion

    //#endregion event handlers

    //#region util helpers

    private async withApi<TResult>(
        action: (grid: GridApi) => TResult | Promise<TResult>
    ) {
        const api = this._gridOptions?.api;
        if (api) {
            return Promise.resolve(action(api));
        } else {
            this.log('no api');
            return Promise.reject('no api');
        }
    }
    private withColumnApi<TResult>(
        action: (api: ColumnApi) => TResult | Promise<TResult>
    ) {
        const api = this._gridOptions?.columnApi;
        if (api) {
            return Promise.resolve(action(api));
        } else {
            this.log('no api');
            return Promise.reject('no api');
        }
    }

    //#endregion

    //#region component helpers

    private initOptions() {
        const opt = this.data;
        if (opt?.debug) {
            this.debug = true;
        }
        this.log('initOptions', opt);
        if (!opt) {
            return;
        }

        if (opt.rowSelection == 'single' || opt.rowSelection == 'multiple') {
            this.rowSelection = opt.rowSelection;
        }
        if (opt.disableRowSelection != undefined) {
            this.disableRowSelection = opt.disableRowSelection;
        }
        this.canRemoveColumnByDragging = !!opt.canRemoveColumnByDragging;

        if (opt.rowHeight != undefined) {
            this.rowHeight = opt.rowHeight;
        }
        if (opt.groupRowHeight != undefined) {
            this.groupRowHeight = opt.groupRowHeight;
        }
        if (opt.headerHeight != undefined) {
            this.headerHeight = opt.headerHeight;
        }
        if (opt.animateRows != undefined) {
            this.animateRows = opt.animateRows;
        }

        if (opt.showNoResults != undefined) {
            this.showNoResults = opt.showNoResults;
        }
        if (opt.noResultText != undefined) {
            this.noResultText = opt.noResultText;
        }
        if (opt.loadingText != undefined) {
            this.loadingText = opt.loadingText;
        }

        this.useChildrenCache = opt.tree?.purgeClosedRowNodesChildrenSelection;

        if (this.headerHeight == undefined) {
            this.headerHeight = 50;
        }
        if (this.rowHeight == undefined) {
            this.rowHeight = 50;
        }
        if (this.groupRowHeight == undefined) {
            this.groupRowHeight = this.rowHeight;
        }

        if (opt.useGroupRowDefaultIcon != undefined) {
            this.useGroupRowDefaultIcon = opt.useGroupRowDefaultIcon;
        }

        if (opt.gridDebug) {
            this.agGridDebug = true;
        }
        if (opt.autoHeight) {
            this.autoHeight = true;
        }

        this.autoHeight = !!opt.autoHeight;
        this.groupCaretPosition = opt.groupCaretPosition ?? 'before';
    }

    private showActionTool(rowIndex: number, data: any) {
        if (!this.actionTool) {
            return;
        }
        const eRow = this.element?.querySelector(
            `div.ag-center-cols-container div[row-index="${rowIndex}"]`
        );
        if (eRow) {
            this.actionTool.show(eRow, data);
        } else {
            this.actionTool.clear();
        }
    }
    private hideActionTool() {
        this.actionTool?.clear();
    }
    private updateActionTool(rowIndex: number, data: any) {
        this.hideActionTool();
        this.showActionTool(rowIndex, data);
    }
    private destroyAllTooltips() {
        const els = this.element
            ? Array.from(
                  this.element.querySelectorAll('[data-toggle="tooltip"]')
              )
            : [];
        this.log('destroyAllTooltips', els.length, this.headerTooltips?.length);
        this.removeTooltips(els);
        this.removeTooltips(this.headerTooltips);
    }

    private colChanged(column: Column, dragged = false) {
        this.log('colChanged', column, dragged);
        this.onColumnsChange?.();
    }

    //#endregion

    //#region grid helpers

    private async setupGrid(isInit = false) {
        if (!this._isInitDone) {
            return false;
        }

        if (!this.data) {
            this._gridOptions = null;
            this.log('setupGrid nodata');
            return false;
        }

        this.log('setupGrid', !!isInit, this.data);

        if (!isInit) {
            this.initOptions();
        }

        if (this._gridOptions) {
            // ag-grid is already ready => update its columns and/or data
            this.updateGridData();
            this.updateGridColumns();
        } else {
            // setup ag-grid before it is ready
            this.setupGridLayout();
        }

        if (!this._isReadyDone) {
            this.log('setupGrid-onReady');
            await wait();
            this.onReady?.(this);
            this._isReadyDone = true;
        }
    }

    private getOverlayNoRowsTemplate(noResultText: string) {
        return `<span class="entity-list-filter-no-result"><span class="txt-center">${noResultText}</span></span>`;
    }
    private getGroupRowIconTemplate(glyphClass: string) {
        return `<div class='expand-btn-container'><span class='mat-button-base mat-icon-button glyph ${glyphClass}'></span></div>`;
    }

    private setupGridLayout() {
        this.log('setupGridLayout-IN', this.data);

        this.actionTool =
            this.data?.actions &&
            new OmniGridActionTool(
                this.data.actions,
                undefined,
                this.setTooltip,
                this.removeTooltip,
                this.ngZone,
                true
            );

        const go = <GridOptions>{
            debug: this.agGridDebug,
            domLayout: this.autoHeight ? 'autoHeight' : undefined,

            headerHeight: this.headerHeight,
            getRowHeight: (p: any) =>
                p.node.group
                    ? this.groupRowHeight
                    : this.data.getRowHeight
                    ? this.data.getRowHeight(p)
                    : this.rowHeight,

            getRowClass: this.data.getRowClass,
            rowSelection: this.rowSelection,
            // with multi-selection, selection is explicit: by checkbox or api call
            suppressRowClickSelection:
                this.isSelectMultiple || this.disableRowSelection,
            animateRows: this.animateRows,
            icons: this.useGroupRowDefaultIcon
                ? undefined
                : {
                      groupExpanded: this.getGroupRowIconTemplate(
                          'glyph-arrow-drop-down'
                      ),
                      groupContracted: this.getGroupRowIconTemplate(
                          'glyph-arrow-drop-right'
                      ),
                  },
            /** Display unsorted icon on sortable columns */
            unSortIcon: true,
            /** Enable insensitive case & accent comparator */
            accentedSort: true,
            defaultColDef: {
                sortable: true,
                resizable: true,
                suppressMenu: true, // no column menu
                lockPinned: true, // not pinnable,
            },

            suppressCellSelection: true,
            suppressDragLeaveHidesColumns: !this.canRemoveColumnByDragging,
            suppressContextMenu: true, // no context menu //getContextMenuItems: p => this.getContextMenuItems(p),

            enableCellTextSelection: this.data?.enableCellTextSelection,

            suppressNoRowsOverlay: !this.showNoResults,

            loadingCellRendererFramework: DxyLoadingCellComponent,
            loadingCellRendererParams: {
                loadingMessage: this.loadingText,
            },

            getMainMenuItems: (p) => this.getMainMenuItems(p), // column menu

            onCellMouseOver: (e) => this.onCellMouseOver(e),
            onCellMouseOut: (e) => this.onCellMouseOut(e),
            onRowClicked: (e) => this.onRowClicked(e),
            onSortChanged: (e) => this.onSortChanged(e),
            onRowGroupOpened: (e) => this.onRowGroupOpened(e),
            onGridSizeChanged: (e) => this.onGridSizeChanged(e),
            onGridReady: (e) => this.onGridReady(e),
            onSelectionChanged: (e) => this.onSelectionChanged(e),
            onColumnVisible: (e) => this.onColumnVisible(e),
            onColumnMoved: (e) => this.onColumnMoved(e),
            onColumnResized: (e) => this.onColumnResized(e),
            onDragStarted: () => this.onColDrag(true),
            onDragStopped: () => this.onColDrag(false),
        };

        if (this.isSelectMultiple) {
            // ensure the first column has the selection checkbox
            go.defaultColDef.checkboxSelection = (params) =>
                go.columnApi.getAllDisplayedColumns()[0] == params.column;
            // and the the header has the checkbox for select all
            if (this.isInfiniteLoad || this.isTree) {
                this.cbSelectAllForceVisible = true;
            } else {
                go.defaultColDef.headerCheckboxSelection =
                    go.defaultColDef.checkboxSelection;
            }
        }

        if (this.isTree) {
            this.setupGridForTree(go);
        } else if (this.isInfiniteLoad) {
            this.setupGridForInfiniteLoad(go);
        } else {
            this.setupGridForGroups(go);
        }

        this._isSingleColumn = go.columnDefs.length < 2;

        this._gridOptions = go;
        this.log('setupGridLayout-OUT', this._gridOptions);
    }

    private setupGridForTree(go: GridOptions) {
        this.log('setupGridForTree');

        go.treeData = true;
        go.columnDefs = this.getColumnDefs();
        go.rowModelType = 'serverSide';

        const options = this.data.tree;
        go.defaultColDef.sortable = !!options.canSortByHeaderClick;
        go.autoGroupColumnDef = {
            cellRendererParams: options.controlCellRendererParams,
            headerName: options.controlHeaderName,
            lockPosition: !!options.lockGroupColumnToLeft,
            width: options.controlColumnWidth,
        };
        go.autoGroupColumnDef.cellRendererParams.suppressCount = true; // hide children count
        go.isServerSideGroup = (obj: TEntity) => this.hasChildren(obj);
        go.getServerSideGroupKey = go.getRowNodeId = options.getEntityId;
        go.getChildCount = options.getEntityChildrenCount;
        go.cacheBlockSize = options.maxChildrenCountToLoad;
        go.purgeClosedRowNodes = !!options.purgeClosedRowNodes;
        go.serverSideDatasource = this.makeServerDataSource();
    }
    private setupGridForInfiniteLoad(go: GridOptions) {
        this.log('setupGridForInfiniteLoad');
        go.columnDefs = this.getColumnDefs();
        go.rowModelType = 'serverSide';
        const options = this.data.infiniteLoad;
        go.defaultColDef.sortable = !!options.canSortByHeaderClick;
        if (options.fetchSize != undefined) {
            go.cacheBlockSize = options.fetchSize;
        }
        if (options.getEntityId) {
            go.getRowNodeId = (obj: TEntity) => options.getEntityId(obj);
        }
        go.serverSideDatasource = this.makeServerDataSource();
    }
    private setupGridForGroups(go: GridOptions) {
        this.log('setupGridForGroups');

        const groupDisplayName =
            this.data.groupHeaderName || this.groupFieldName;
        go.autoGroupColumnDef = {
            field: this.groupFieldName,
            rowGroup: true,
            hide: true,
            headerValueGetter: () => groupDisplayName,
            enableRowGroup: true, //so we can regroup if we ungroup
        };
        go.columnDefs = this.getColumnDefs(go.autoGroupColumnDef);

        go.groupRowRendererFramework = DxyGroupCellComponent;
        go.groupRowRendererParams = {
            groupHeaderItemClass: this.groupHeaderClass,
            isCaretBeforeText: this.groupCaretPosition != 'after',
        } as IGroupCellParams;
        go.groupDefaultExpanded = -1; //all expanded
        go.groupUseEntireRow = true;
        go.rowGroupPanelShow = 'never'; //hide group panel/zone

        if (this.data.getObjectId) {
            go.getRowNodeId = (obj: TEntity) => this.data.getObjectId(obj);
        }
        go.rowData = this.getRowsData();
    }

    private makeServerDataSource(clearCache = false): IServerSideDatasource {
        const isTree = this.isTree;
        const isInfiniteLoad = this.isInfiniteLoad;
        let getData: (
            params: IServerSideGetRowsParams
        ) => Promise<ILoadMultiResult<any>>;
        let getLastRow: (
            request: IServerSideGetRowsRequest,
            result: ILoadMultiResult<any>
        ) => number;
        let afterSetRows: (
            params: IServerSideGetRowsParams,
            result: ILoadMultiResult<any>
        ) => void;
        if (isTree) {
            this.log('makeServerDataSource-Tree', clearCache);
            const options = this.data.tree;
            if (clearCache) {
                options.clearSourceCache?.();
            }
            getData = (params) =>
                options.getEntityChildren(
                    options.getEntityId(params.parentNode.data),
                    params.request.sortModel
                );
            getLastRow = (request, result) =>
                request.startRow + (result?.Entities?.length || 0); //we get all rows in a single fetch, so TotalCount isn't needed
            afterSetRows = (params, result) => {
                if (this.childrenLoading.has(params.parentNode)) {
                    this.log(
                        'makeServerDataSource-Tree-afterSetRows-childrenLoading-resolve',
                        params.parentNode.id
                    );
                    const defer = this.childrenLoading.get(params.parentNode);
                    this.childrenLoading.delete(params.parentNode);
                    defer.resolve();
                }
                if (this.useChildrenCache) {
                    this.cacheChildren(
                        params.parentNode,
                        result?.Entities,
                        (e) => options.getEntityId(e)
                    );
                }
                if (
                    result?.IsSuccess &&
                    typeof options.onChildrenRefreshed == 'function'
                ) {
                    this.log(
                        'makeServerDataSource-Tree-afterSetRows-success-onChildrenRefreshed',
                        params,
                        result
                    );
                    options.onChildrenRefreshed(
                        params.parentNode.data,
                        result.Entities
                    );
                }
            };
        } else if (isInfiniteLoad) {
            this.log('makeServerDataSource-InfiniteLoad');
            const options = this.data.infiniteLoad;
            if (clearCache) {
                options.clearSourceCache?.();
            }
            const maxResultWindow = options.maxResultWindow;
            getData = (params: IServerSideGetRowsParams) => {
                let from = params.request.startRow;
                let size = params.request.endRow - params.request.startRow;
                const sortModel = params.request.sortModel;

                if (maxResultWindow > 0 && from + size > maxResultWindow) {
                    size = Math.min(size, Math.max(0, maxResultWindow - from));
                    from = Math.min(from, maxResultWindow - size);
                    this.log(
                        'makeServerDataSource-InfiniteLoad-clamped',
                        maxResultWindow,
                        from,
                        size
                    );
                }
                return options.getRows(from, size, sortModel);
            };
            getLastRow = (request, result) =>
                maxResultWindow == undefined
                    ? result.TotalCount
                    : result.TotalCount < maxResultWindow
                    ? result.TotalCount
                    : maxResultWindow;
            afterSetRows =
                typeof options.onRowsLoaded != 'function'
                    ? undefined
                    : (params, result) => {
                          if (result?.IsSuccess) {
                              this.log(
                                  'makeServerDataSource-InfiniteLoad-afterSetRows-success-onRowsLoaded',
                                  params,
                                  result
                              );
                              options.onRowsLoaded(
                                  params.request.startRow,
                                  result.Entities
                              );
                          }
                      };
        } else {
            throw new Error(
                'no datasource options provided (tree or infiniteLoad)'
            );
        }

        return {
            getRows: (params) =>
                this.getServerRows(params, getData, getLastRow, afterSetRows),
        };
    }

    private async updateGridColumns() {
        this.log('updateGridColumns', this.data?.columns, !!this.data?.groups);
        if (!this.data?.columns) {
            return;
        }
        // if in group mode, include the non-specified auto-group column
        const moreColDefs = this.data.groups
            ? [this._gridOptions.autoGroupColumnDef]
            : [];
        const colDefs = this.getColumnDefs(...moreColDefs);
        await this.setColumnDefs(colDefs, true);
    }

    private getRowsData() {
        if (!this.data) {
            this.log('getRowsData-nodata');
            return;
        }

        const inputGroups = this.data.groups;
        if (inputGroups) {
            this.log('getRowsData-groups', inputGroups.length);

            //set the group key on each object
            const dn = this.groupFieldName;
            inputGroups.forEach((g) =>
                g.objects.forEach((o) => ((o as any)[dn] = g.displayName))
            );

            //flatten data which is given in groups
            return CollectionsHelper.flattenGroups(
                inputGroups,
                (g) => g.objects
            ) as unknown as TEntity[];
        }

        this.log('getRowsData-objects', this.data.objects?.length);
        return this.data.objects || [];
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    private getContextMenuItems(
        _params: GetContextMenuItemsParams
    ): (string | MenuItemDef)[] {
        //console.log('getContextMenuItems', params)
        return [];
    }

    private getMainMenuItems(
        params: GetMainMenuItemsParams
    ): (string | MenuItemDef)[] {
        //console.log('getMainMenuItems', params)
        const items = params.defaultItems;
        return items;
    }

    private getColumnDefs(...moreColumnDefs: ColDef[]) {
        let colDefs = (this.data?.columns as ColDef[]) ?? [];
        if (moreColumnDefs.length) {
            colDefs = [...colDefs, ...moreColumnDefs];
        }
        return colDefs;
    }

    private getDisplayedFields() {
        return ((this._gridOptions?.columnDefs ?? []) as ColDef[])
            .filter((cd) => cd.field != undefined && !cd.hide)
            .map((cd) => cd.field);
    }

    private async updateColumnCellsLayout(column: Column, from?: string) {
        if (this.updatingColumnCellsLayout) {
            this.updatingColumnCellsLayout.cancel();
            CollectionsHelper.removeElement(
                this.cancellableTimeouts,
                this.updatingColumnCellsLayout.cancel
            );
            this.log('updateColumnCellsLayout-cancelled');
        }
        this.log('updateColumnCellsLayout', from);
        this.updatingColumnCellsLayout = this.startCancellableTimeout(() => {
            this.updateCellsLayout({ column });
        }, 200);
        await this.updatingColumnCellsLayout.promise;
    }

    private async updateAllCellsLayout(from?: string) {
        if (this.updatingAllCellsLayout) {
            this.updatingAllCellsLayout.cancel();
            CollectionsHelper.removeElement(
                this.cancellableTimeouts,
                this.updatingAllCellsLayout.cancel
            );
            this.log('updateAllCellsLayout-cancelled');
        }
        this.log('updateAllCellsLayout', from);
        this.updatingAllCellsLayout = this.startCancellableTimeout(() => {
            this.updateCellsLayout();
        }, 200);
        await this.updatingAllCellsLayout.promise;
    }

    private updateCellsLayout(params?: IUpdateCellsLayoutParams) {
        const { rowNode, column } = params ?? {};
        if (this.noApi) {
            return;
        }
        const api = this.gridApi;
        const rowNodes = rowNode ? [rowNode] : api.getRenderedNodes();
        if (!rowNodes.length) {
            return;
        }
        const allRenderers = api.getCellRendererInstances({
            rowNodes,
            columns: column ? [column] : undefined,
        });
        // archi-renderers(revi) remove any type (needs AG Grid update)
        const componentRenderers = allRenderers
            .filter((r: any) => r.getFrameworkComponentInstance)
            .map(
                (r: any) =>
                    r.getFrameworkComponentInstance() as BaseCellComponent
            );
        this.debug &&
            this.log(
                'updateCellsLayout-updateLayout-start',
                rowNodes.length,
                allRenderers.length,
                componentRenderers.length
            );
        componentRenderers.forEach((r) => r.updateLayout());
        this.log('updateCellsLayout-updateLayout-end');
    }

    private async getServerRows(
        params: IServerSideGetRowsParams,
        getData: (
            params: IServerSideGetRowsParams
        ) => Promise<ILoadMultiResult<any>>,
        getLastRow: (
            request: IServerSideGetRowsRequest,
            result: ILoadMultiResult<any>
        ) => number,
        afterSetRows?: (
            params: IServerSideGetRowsParams,
            result: ILoadMultiResult<any>
        ) => void
    ) {
        const request = params.request;
        this.log('getServerRows', request);

        // for debug
        //await this.timeout(null, 1000)

        let result: ILoadMultiResult<any>;
        try {
            result = await getData(params);
        } catch (e) {
            this.log('getServerRows-ERROR', e);
            params.failCallback?.();
        }
        if (result?.IsSuccess) {
            const lastRow = getLastRow(params.request, result);
            const entities = result.Entities,
                loadedCount = entities?.length || 0;
            const isPreviousData = this.anyDataLoaded();
            this.log(
                'getServerRows-success',
                loadedCount,
                result,
                lastRow,
                isPreviousData
            );
            params.successCallback(entities, lastRow);
            if (!isPreviousData && loadedCount > 0) {
                this.log('getServerRows-success-isFirstData');
                if (this.autoSpanColumns) {
                    await this.sizeColumnsToFit();
                }
                await this.updateAllCellsLayout(
                    'getServerRows-success-isFirstData'
                );
            }
        } else if (result) {
            this.log('getServerRows-FAIL');
            params.failCallback?.();
        }

        if (
            this.rootLoaded &&
            (!params.parentNode || params.parentNode.level == -1)
        ) {
            const defer = this.rootLoaded;
            this.rootLoaded = undefined;
            this.log('makeServerDataSource-rootLoaded');
            defer.resolve();
        }
        if (typeof afterSetRows == 'function') {
            this.log('getServerRows-afterSetRows', params, result);
            afterSetRows(params, result);
        }
    }

    private getNodeRoute(node: RowNode) {
        const route = node && new Array<string>();
        while (node?.data) {
            route.unshift(this.data.tree.getEntityId(node.data));
            node = node.parent;
        }
        return route;
    }

    private async setNodeExpanded(node: RowNode, isExpanded: boolean) {
        if (!node || !node.isExpandable() || node.expanded == isExpanded) {
            this.log('setNodeExpanded-notExpanding', node?.data);
            return;
        }

        //todo(fbo) the returned promise is not resolved in some cases when purgeClosedRowNodes if false

        if (this.childrenLoading?.has(node)) {
            const cl = this.childrenLoading.get(node);
            this.childrenLoading.delete(node);
            cl.reject('canceled');
        }

        this.log('setNodeExpanded-setExpanded', isExpanded, node.data);
        if (isExpanded) {
            return new Promise<void>((resolve, reject) => {
                this.childrenLoading.set(node, { resolve, reject });
                node.setExpanded(true);
            });
        }

        node.setExpanded(false);
    }

    private getOrderedExpandedRowIds(api: GridApi) {
        const expandedRowIds = new Array<string>();
        api.forEachNode((node) => {
            if (node?.expanded) {
                expandedRowIds.push(node.id);
            }
        });
        return expandedRowIds;
    }
    private hasChildren(obj: TEntity) {
        const options = this.data.tree;
        const hasChildren = options.getEntityChildrenCount(obj) > 0;
        this.log('hasChildren', hasChildren, obj);
        if (!hasChildren) {
            // resolves the promise because getRows will never be called
            this.childrenLoading.forEach((defer, node) => {
                if (node.data === obj) {
                    this.log('hasChildren-isLeaf-resolve', obj);
                    this.childrenLoading.delete(node);
                    defer.resolve();
                }
            });
        }
        return hasChildren;
    }

    private unselectDescendants(ancestor: RowNode) {
        const selectedDescendants = this.getDescendants(ancestor, true).filter(
            (c) => c.isSelected()
        );
        this.log('unselectDescendants', ancestor, selectedDescendants.length);
        const last = selectedDescendants.length - 1;
        selectedDescendants.forEach((c, i) =>
            c.setSelected(false, false, i < last)
        );
    }

    private cacheChildren(
        parentNode: RowNode,
        loadedEntities: TEntity[],
        getEntityId: (e: TEntity) => string
    ) {
        if (!this.childrenCache) {
            this.log('cacheChildren-noCache');
        } else if (!parentNode || parentNode.level == -1) {
            this.log('cacheChildren-clear');
            this.childrenCache.clear();
        } else {
            if (!loadedEntities || this.noApi) {
                return;
            }
            const children =
                loadedEntities.map((e) =>
                    this.gridApi.getRowNode(getEntityId(e))
                ) ?? [];
            this.log('cacheChildren-set', parentNode, children.length);
            this.childrenCache.set(parentNode, children);
        }
    }

    private getDescendants(ancestor: RowNode, clearCache = false) {
        const result = new Array<RowNode>();
        if (ancestor && this.childrenCache) {
            const depthFirstSearch = (node: RowNode) =>
                this.childrenCache.get(node)?.forEach((c) => {
                    result.push(c);
                    depthFirstSearch(c);
                });
            depthFirstSearch(ancestor);
            if (clearCache) {
                this.childrenCache.delete(ancestor);
            }
        }
        return result;
    }

    private getColumnStates() {
        if (this.noApi) {
            this.log('getColumnStates-no-api');
            return;
        }
        this.columnStates = this.columnApi.getColumnState();
        this.log('getColumnStates-result', this.columnStates);
    }

    private updateCbSelectAllVisible() {
        if (!this.cbSelectAllForceVisible || this.noApi) {
            return;
        }
        this.cbSelectAllForcingVisible?.cancel();
        this.cbSelectAllForcingVisible = this.startCancellableTimeout(
            () =>
                this.withColumnApi((api) => {
                    this.log('updateCbSelectAllVisible');
                    const colId = this.getFirstColumnId(api);
                    if (!colId) {
                        return;
                    }
                    const allColId = this.cbSelectAllColumnId;
                    if (allColId && allColId != colId) {
                        this.setCbSelectAllVisible(allColId, false);
                    }
                    this.setCbSelectAllVisible(
                        (this.cbSelectAllColumnId = colId),
                        true
                    );
                }),
            50
        );
    }
    private setCbSelectAllVisible(columnId: string, visible: boolean) {
        const el = this.getCbSelectAllElement(columnId);
        this.log('toggleCbSelectAllVisible', columnId, visible, el);
        if (!el) {
            return;
        }
        el.classList.toggle('ag-hidden', !visible);
        if (this.cbSelectAllListener) {
            el.removeEventListener('click', this.cbSelectAllListener);
        }
        if (visible) {
            DomUtil.addListener(
                el,
                'click',
                (this.cbSelectAllListener = () =>
                    this.setAllNodesSelected(!this.cbSelectAllSelected)),
                this.ngZone,
                false
            );
        }
    }
    private setAllNodesSelected(isAllSelected: boolean) {
        this.log('setAllNodesSelected', isAllSelected);
        if (this.noApi || this.noColumnApi) {
            return;
        }
        const api = this.gridApi,
            columnApi = this.columnApi;
        isAllSelected = !!isAllSelected;
        let result = false;
        const iLast = api.getLastDisplayedRow();
        api.forEachNode((n, i) => {
            if (!n.selectable) {
                return;
            }
            if (n.isSelected() != isAllSelected) {
                result = true;
            }
            n.setSelected(isAllSelected, false, i != iLast);
        });
        this.cbSelectAllSelected = isAllSelected;
        const el = this.getCbSelectAllElement(this.getFirstColumnId(columnApi));
        if (el) {
            el.querySelector('.ag-checkbox-checked')?.classList.toggle(
                'ag-hidden',
                !isAllSelected
            );
            el.querySelector('.ag-checkbox-unchecked')?.classList.toggle(
                'ag-hidden',
                isAllSelected
            );
        }
        return result;
    }
    private getFirstColumnId(api: ColumnApi) {
        return api.getAllDisplayedColumns()[0]?.getColId();
    }
    private getCbSelectAllElement(columnId: string): HTMLElement {
        return (
            columnId &&
            this.element?.querySelector(
                ".ag-header-viewport [col-id='" +
                    columnId +
                    "'].ag-header-cell [ref=cbSelectAll].ag-header-select-all"
            )
        );
    }

    private anyDataLoaded() {
        if (this.noApi) {
            return false;
        }
        const api = this.gridApi;
        switch (
            api.getDisplayedRowCount() // returns 1 when no data !
        ) {
            case 0:
                return false;
            case 1: {
                const dr0 = api.getDisplayedRowAtIndex(0);
                return !!dr0?.data;
            }
            default:
                return true;
        }
    }

    /** give me the parent node and the number of children to add or remove, i'll tell you if this node becomes a leaf or a group, or stays unchanged */
    private getParentOrGreatParentId(
        parentNode: RowNode,
        nbChildrenToAddOrRemove: number
    ) {
        const options = this.data.tree;
        const parentEntity = parentNode && (parentNode.data as TEntity);
        let resultEntity = parentEntity;
        if (parentEntity) {
            const currentNbChildren =
                options.getEntityChildrenCount(parentEntity);
            const isLeafGroupChange =
                currentNbChildren != undefined &&
                ((nbChildrenToAddOrRemove < 0 &&
                    currentNbChildren <= -nbChildrenToAddOrRemove) ||
                    (nbChildrenToAddOrRemove > 0 && currentNbChildren == 0));
            if (isLeafGroupChange) {
                resultEntity = parentNode.parent.data as TEntity;
            }
        }
        this.log('getParentOrGreatParentId', parentNode, '->', resultEntity);
        return resultEntity && options.getEntityId(resultEntity);
    }
    private async refreshTopAncestors(
        idsToRefresh: string[],
        preserveExpandedRows: boolean
    ) {
        if (idsToRefresh.some((id) => !id)) {
            this.log('refreshTopAncestors-root');
            await this.refreshAllRows(preserveExpandedRows);
        } else {
            const ancestorIds = await this.getTopAncestorIds(idsToRefresh);
            this.log(
                'refreshTopAncestors-topAncestors',
                idsToRefresh,
                ancestorIds
            );
            if (ancestorIds?.length) {
                await Promise.all(
                    ancestorIds.map((id) =>
                        this.refreshChildren(id, preserveExpandedRows)
                    )
                );
            }
        }
    }
    private async getTopAncestorIds(ids: string[]) {
        this.log('getTopAncestorIds', ids);
        return this.withApi((api) => {
            return ids.filter((childId) => {
                const childRoute =
                    childId && this.getNodeRoute(api.getRowNode(childId));
                if (!childRoute) {
                    return false;
                }
                return !ids.some(
                    (ancestorId) =>
                        ancestorId &&
                        ancestorId != childId &&
                        childRoute.some((id) => id == ancestorId)
                );
            });
        });
    }
    private async refreshChildren(rowId: string, preserveExpandedRows = false) {
        this.log('refreshChildren', rowId, preserveExpandedRows);
        if (this.noApi) {
            return;
        }
        const api = this.gridApi;

        const node = api.getRowNode(rowId);
        if (!node) {
            // the node to refresh is the root
            this.log('refreshChildren-refreshAllRows');
            await this.refreshAllRows(preserveExpandedRows);
            return;
        }

        const expandedRowIds =
            (preserveExpandedRows && this.getOrderedExpandedRowIds(api)) || [];

        this.log('refreshChildren-purgeServerSideCache', expandedRowIds);

        // clear the ag-grid cache for the node to re-expanded
        api.purgeServerSideCache(this.getNodeRoute(node));

        if (expandedRowIds.length) {
            this.log('refreshChildren-setRowsExpanded');
            await this.setRowsExpanded(expandedRowIds);
        }
    }

    private clearCache() {
        const clearCache = (this.data?.tree ?? this.data?.infiniteLoad)
            ?.clearSourceCache;
        this.log('clearCache', !!clearCache);
        clearCache?.();
    }

    private async setHeaderTooltips() {
        this.removeTooltips(this.headerTooltips);
        if (!this.data.headerTooltips) {
            return;
        }
        await wait();
        const container = DomUtil.getElement(this.element);
        const els = (this.headerTooltips = DomUtil.getElements(
            container,
            '.ag-header-cell-text'
        ));
        els.forEach((el) => this.setTooltip(el, el.innerText));
    }

    //#endregion grid helpers

    private log(...args: any) {
        if (!this.debug) {
            return;
        }
        this.logFn?.('(OmniGridCore)', ...args);
    }

    private timeout(fn: () => void, ms?: number) {
        return this.startCancellableTimeout(fn, ms).promise;
    }
    private startCancellableTimeout<T>(
        fn: () => T | Promise<T>,
        delayMs?: number,
        onCancel?: () => void
    ) {
        const ct = CoreUtil.startCancellableTimeout(
            () => {
                CollectionsHelper.removeElement(
                    this.cancellableTimeouts,
                    ct.cancel
                );
                fn();
            },
            delayMs,
            onCancel,
            this.ngZone,
            true
        );
        this.cancellableTimeouts.push(ct.cancel);
        return ct;
    }
    private warn(...args: any[]) {
        if (!CoreUtil.isProduction) {
            console.warn(...args);
        }
    }
}

import * as moment from 'moment';
import { wait } from '@datagalaxy/core-util';
import { Component, ElementRef, NgZone, OnInit } from '@angular/core';
import {
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
} from '@angular/forms';
import { NavigationService } from '../../services/navigation.service';
import { LegacyLoginService } from '../../services/login.service';
import { AppDataService } from '../../services/app-data.service';
import { DxyModalService } from '../../shared/dialogs/DxyModalService';
import { ErrorLogin } from '../../shared/util/app-types/errors.types';
import { AppConfigService } from '@datagalaxy/webclient/config';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { StateName } from '@datagalaxy/webclient/routing';
import { TranslateService } from '@ngx-translate/core';
import { BottomBannerService } from '@datagalaxy/ui/banner-notification';

@Component({
    selector: 'app-account-login',
    templateUrl: 'account-login.component.html',
    styleUrls: [
        'account-login.component.scss',
        'account-login.component.responsive.scss',
    ],
})
export class AccountLoginComponent extends DxyBaseComponent implements OnInit {
    public loginForm: FormGroup<ILoginForm>;
    public loginErrorMessage: string;
    public out = false;

    public get loginControl() {
        return this.loginForm.controls.login;
    }
    public get passwordControl() {
        return this.loginForm.controls.password;
    }
    public get useAutoLoginControl() {
        return this.loginForm.controls.useAutoLogin;
    }

    public get isLoginValid() {
        return this.loginControl.valid;
    }
    public get isLoginError() {
        return this.loginControl.invalid && this.loginControl.touched;
    }
    public get isPasswordValid() {
        return this.passwordControl.valid;
    }
    public get isPasswordError() {
        return this.passwordControl.invalid && this.passwordControl.touched;
    }

    public get showForgotPassword() {
        return !this.appConfigService.DISABLE_EMAILING;
    }
    public get isLoginInProgress() {
        return this.appDataService.isLoginInProgress;
    }
    public get isLoginFailed() {
        return this.appDataService.isLoginFailed;
    }

    public get showAutologin() {
        return this.canAutoLogin;
    }

    protected readonly StateName = StateName;

    private canAutoLogin = false;
    private lastLoginAttempt: moment.Moment;
    private readonly loginAttemptDiffDelayMs = 500;

    constructor(
        private elementRef: ElementRef<HTMLElement>,
        private navigationService: NavigationService,
        private loginService: LegacyLoginService,
        private appDataService: AppDataService,
        private appConfigService: AppConfigService,
        private bottomBannerService: BottomBannerService,
        private dxyModalService: DxyModalService,
        private formBuilder: FormBuilder,
        private translate: TranslateService,
        private ngZone: NgZone
    ) {
        super();
        this.createForm();
    }

    ngOnInit() {
        this.init();
    }

    public setDirty() {
        this.loginService.setLoginFailed(false);
    }

    public async doLogin() {
        const currentLoginAttempt = moment();

        this.logTrace('doLogin: Last Login Attempt', this.lastLoginAttempt);
        this.logTrace('doLogin: Current Login Attempt', currentLoginAttempt);

        const doubleLoginDetected =
            this.lastLoginAttempt &&
            currentLoginAttempt.diff(this.lastLoginAttempt, 'milliseconds') <
                this.loginAttemptDiffDelayMs;

        if (doubleLoginDetected || this.isLoginInProgress) {
            if (doubleLoginDetected) {
                this.log('Double Login: Blocked!');
            }
            return;
        }

        this.lastLoginAttempt = moment();

        this.log('doLogin!');

        this.loginService.setCurrentUserLogin(this.loginControl.value);
        this.loginService.setUseAutoLogin(
            this.useAutoLoginControl.value && this.canAutoLogin
        );
        this.loginService.setLoginFailed(false);

        if (this.navigationService.isUserSessionTimeout) {
            this.navigationService.clearIsUserSessionTimeout();
            this.bottomBannerService.hideBanner();
        }

        try {
            await this.slide(true);
            await this.loginService.systemLogin(
                false,
                this.passwordControl.value,
                'account-login'
            );
            await this.navigationService.goToMainPrivateIndex();
        } catch (error) {
            if (error instanceof ErrorLogin && error.data) {
                this.loginErrorMessage = error.loginErrorMessage;
            } else {
                this.loginErrorMessage = 'UI.Login.lblServerError';
            }

            this.loginService.setLoginFailed(true, true);
            this.lastLoginAttempt = null;

            await this.slide(false);
        }
    }

    private createForm() {
        this.loginForm = this.formBuilder.group<ILoginForm>({
            login: new FormControl(this.appDataService.currentUserLogin, {
                validators: [Validators.required],
            }),
            password: new FormControl('', {
                validators: [Validators.required],
            }),
            useAutoLogin: new FormControl(false),
        });
    }

    private async init() {
        this.canAutoLogin = this.loginService.canAutoLogin;
        if (!this.canAutoLogin) {
            this.loginService.setUseAutoLogin(false);
        }
        if (this.navigationService.isUserSessionTimeout) {
            await this.loginService.logoutSessionTimeout();
            this.bottomBannerService.show(
                'UI.Banner.userSessionTimeout.title',
                this.translate.instant('UI.Banner.userSessionTimeout.text', {
                    timeoutMinutes:
                        this.appDataService.userSessionTimeoutMinutes,
                }),
                {
                    imageSrc: '/images/banner-image-usersessiontimeout.svg',
                    bannerClass: 'right-half',
                    rowClass: 'nowrap',
                }
            );
            this.dxyModalService.dismissAll();
        }
    }

    private async slide(out: boolean) {
        const inOut = out ? 'out' : 'in';
        const el = this.elementRef.nativeElement.querySelector('.login-zone');
        const durationMs =
            parseInt(
                getComputedStyle(el).getPropertyValue(`--slide-${inOut}-ms`),
                10
            ) || 222;
        this.log('slide', inOut, durationMs);
        this.out = out;
        return await wait(durationMs, this.ngZone, true);
    }
}

interface ILoginForm {
    login: FormControl<string>;
    password: FormControl<string>;
    useAutoLogin: FormControl<boolean>;
}

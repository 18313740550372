import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    QueryList,
    SimpleChanges,
    ViewChildren,
} from '@angular/core';
import { DxyMultiselectListComponent } from '@datagalaxy/core-ui';
import { IListOption } from '@datagalaxy/core-ui';
import {
    DashboardService,
    IWidgetFilter,
    IWidgetSettingsData,
    WidgetFilterType,
} from '../dashboard.service';
import { WidgetSettingsManager } from '../widgets/widget-settings-manager';
import { IWidgetConfigParam, WidgetFormFieldType } from '../WidgetUtil';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { SpaceIdentifier } from '@datagalaxy/webclient/workspace/utils';
import { LicenseLevel } from '@datagalaxy/webclient/license/domain';
import { ISpaceIdentifier } from '@datagalaxy/webclient/workspace/domain';

/** widget header with inplace configuration capabilities */
@Component({
    selector: 'dxy-widget-header',
    templateUrl: 'dxy-widget-header.component.html',
    styleUrls: ['dxy-widget-header.component.scss'],
})
export class DxyWidgetHeaderComponent
    extends DxyBaseComponent
    implements OnInit, OnChanges
{
    @Input() titleText: string;
    @Input() description: string;
    @Input() spaceIdr: ISpaceIdentifier;
    @Input() licenseTypes: LicenseLevel[];
    @Input() showCount: boolean;
    @Input() countValue: number;
    @Input() noLine: boolean;
    @Input() filters?: IWidgetFilter[];
    @Input() inPlaceConfig?: IWidgetSettingsData;
    @Input() disabledwidgetConfigParams: string[] = [];
    @Output() onFiltersUpdate = new EventEmitter<IWidgetConfigParam[]>();
    @Output() get onTitleClick() {
        return (this._onTitleClick ??= new EventEmitter<void>());
    }

    @ViewChildren(DxyMultiselectListComponent)
    multiSelectLists: QueryList<DxyMultiselectListComponent>;

    public filtersList?: IWidgetFilter[];
    public isEditMode = false;
    public get hideFilters() {
        return this.settings?.hideFilters;
    }
    public set hideFilters(value: boolean) {
        this.settings.hideFilters = value;
    }
    public get hasFilters() {
        return !!this.filtersList?.length;
    }
    public get hasSpaceFilter() {
        return this.filters?.some(
            (f) => f.filterType == WidgetFilterType.spaceVersion
        );
    }
    public get spaceFilterLabel() {
        return this.filters?.filter(
            (f) => f.filterType == WidgetFilterType.spaceVersion
        )[0]?.uiValue;
    }
    public get params() {
        return this.settings?.params.filter(
            (p) => !this.disabledwidgetConfigParams?.includes(p.name)
        );
    }
    public get isTitleClickActive() {
        return !!this._onTitleClick;
    }

    public settingManagerInitialized = false;
    private _onTitleClick: EventEmitter<void>;
    private settings: WidgetSettingsManager;

    constructor(private dashboardService: DashboardService) {
        super();
    }

    ngOnInit() {
        if (this.inPlaceConfig) {
            this.initSettingManager();
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        super.onChange(changes, 'filters', () => this.onChangeFilters(), true);
        super.onChange(changes, 'inPlaceConfig', () => {
            this.initSettingManager();
        });
    }

    //#region events
    public titleClick() {
        this._onTitleClick?.emit();
    }

    public onTitleChange() {
        const param = this.settings.params.find((p) => p.name == 'titleText');
        if (!param) {
            return;
        }
        param.value = this.titleText;
    }

    public onDescriptionChange() {
        const param = this.settings.params.find((p) => p.name == 'description');
        if (!param) {
            return;
        }
        param.value = this.description;
    }

    public onReset() {
        this.settings.reset();
    }

    public onToggleEditMode() {
        this.isEditMode = !this.isEditMode;
    }

    public onShowHideFilters() {
        this.hideFilters = !this.hideFilters;
    }

    public onSave() {
        this.isEditMode = false;
        this.settings.save();
    }

    public onCancelEditMode() {
        this.isEditMode = false;
        this.settings.cancel();
    }

    public onMenuOpened(param: IWidgetConfigParam) {
        const multiSelectParams = this.params.filter((p) =>
            this.isMultiSelect(p)
        );
        const index = multiSelectParams.indexOf(param);

        if (index != -1) {
            this.multiSelectLists.get(index)?.refreshUi();
        }
    }

    public onSpaceVersionSelected(spaceIdr: ISpaceIdentifier) {
        const param = DashboardService.getSpaceVersionParam(
            this.inPlaceConfig.config
        );
        const paramSpaceIdr = param?.value as ISpaceIdentifier;
        if (
            !paramSpaceIdr ||
            SpaceIdentifier.areSame(spaceIdr, paramSpaceIdr)
        ) {
            return;
        }
        param.value = spaceIdr;
        this.settings.onSelectChange(param);
    }

    public onSelectChange(option: IListOption, param: IWidgetConfigParam) {
        param.value = option.data;
        this.settings.onSelectChange(param);
    }
    //#endregion events

    public isMultiSelect(param: IWidgetConfigParam) {
        return this.settings.isMultiSelect(param);
    }
    public isSingleSelect(param: IWidgetConfigParam) {
        return this.settings.isSingleSelect(param);
    }
    public isUserSelect(param: IWidgetConfigParam) {
        return this.settings.isUserSelect(param);
    }
    public getSelectOptions(param: IWidgetConfigParam) {
        const options = this.settings.getSelectOptions(param);
        if (!options) {
            return [];
        }
        options.forEach(
            (option: IListOption) =>
                (option.callback = () => this.onSelectChange(option, param))
        );
        return options;
    }
    public getMultiSelectData(param: IWidgetConfigParam) {
        return this.settings.getMultiSelectData(param);
    }

    public getFilterId(f: IWidgetFilter) {
        return f && `${WidgetFilterType[f.filterType]}-${f.uiValue}}`;
    }

    public getParamDisplayName(param: IWidgetConfigParam) {
        const options = this.settings.getSelectOptions(param);
        return this.dashboardService.getParamDisplayName(param, options);
    }

    public showFilter(param: IWidgetConfigParam) {
        return (
            param.formFieldType !== WidgetFormFieldType.spaceVersion &&
            param.name !== 'titleText' &&
            param.name !== 'description'
        );
    }

    public getUserIds(param: IWidgetConfigParam) {
        return param.value as string[];
    }

    public getShowHideClass() {
        return this.hideFilters ? 'glyph-show' : 'glyph-hide';
    }

    public getShowHideText() {
        return `UI.DashboardGrid.WidgetSettings.InPlace.actions.${
            this.hideFilters ? 'showFilters' : 'hideFilters'
        }`;
    }

    private async initSettingManager() {
        this.settingManagerInitialized = false;
        this.settings = new WidgetSettingsManager(
            this.inPlaceConfig,
            this.dashboardService,
            this.onFiltersUpdate
        );
        await this.settings.init();
        this.settingManagerInitialized = true;
    }

    private onChangeFilters() {
        this.filtersList = this.filters?.filter(
            (f) => f.filterType != WidgetFilterType.spaceVersion
        );
        this.debug &&
            this.log(
                'onChanges-filters',
                this.titleText,
                this.filters,
                this.hasSpaceFilter,
                this.spaceFilterLabel
            );
    }
}

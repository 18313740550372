import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
} from '@angular/core';
import { DataUtil } from '../../util/DataUtil';
import { ServerType } from '@datagalaxy/dg-object-model';
import {
    ModuleView,
    NavigationService,
} from '../../../services/navigation.service';
import { CurrentSpaceService } from '../../../services/currentSpace.service';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { FunctionalLogService } from '@datagalaxy/webclient/monitoring/data-access';
import { DgModule } from '@datagalaxy/shared/dg-module/domain';

@Component({
    //eslint-disable-next-line @angular-eslint/component-selector
    selector: 'dxy-entity-view-toggle-button',
    templateUrl: './dxy-entity-view-toggle-button.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DxyEntityViewToggleButtonComponent extends DxyBaseComponent {
    @Input() dgModule: DgModule;

    public get hasDataMapView() {
        return DataUtil.canHaveDataMapView(this.dgModule);
    }
    public get featureCodeTabView() {
        return this.getFeatureCode('TABVIEW');
    }
    public get featureCodeDetail() {
        return this.getFeatureCode('DETAIL');
    }
    public get featureCodeDataMap() {
        return this.getFeatureCode('DATAMAP_VIEW');
    }
    public get currentViewGlyph() {
        switch (this.navigationService.getActiveModuleView()) {
            case ModuleView.entityList:
                return 'glyph-layout-split';
            case ModuleView.entityGrid:
                return 'glyph-collection-static';
            case ModuleView.datamap:
                return 'glyph-datamap';
            default:
                return '';
        }
    }

    constructor(
        private navigationService: NavigationService,
        private currentSpaceService: CurrentSpaceService,
        private cd: ChangeDetectorRef
    ) {
        super();
    }

    // #region event handlers
    public goToListView() {
        return this.gotoModuleView(ModuleView.entityList);
    }
    public goToGridView() {
        return this.gotoModuleView(ModuleView.entityGrid);
    }
    public goToDataMapView() {
        return this.gotoModuleView(ModuleView.datamap);
    }
    // #endregion event handling

    private async gotoModuleView(moduleView: ModuleView) {
        await this.navigationService.gotoModuleView(
            this.dgModule,
            moduleView,
            this.currentSpaceService.getCurrentSpace()
        );
        this.cd.detectChanges();
    }

    private getFeatureCode(suffix: string) {
        const prefix = this.currentSpaceService.getFuncLogPrefix();
        const serverType = DataUtil.getDefaultServerTypeFromModule(
            this.dgModule
        );
        const etn = ServerType[serverType]?.toUpperCase();
        const code = FunctionalLogService.getTypeNameCode(etn, true);
        return `${prefix}${code}_${suffix},R`;
    }
}

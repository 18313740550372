import {
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import { CollectionsHelper } from '@datagalaxy/core-util';
import { Subject } from 'rxjs';
import { IEntityForm } from '../interfaces/entity-form.interface';
import {
    EntityService,
    IEntityLinksChangeInfo,
} from '../services/entity.service';
import { EntityDockingPaneService } from '../services/entity-docking-pane.service';
import { EntityEventService } from '../services/entity-event.service';
import {
    IScreenData,
    IScreenLayoutComponent,
} from '../../screens-layout/screens-layout.types';
import { RealTimeCommService } from '../../../services/realTimeComm.service';
import { DataUtil } from '../../util/DataUtil';
import { ScreenService } from '../../../screens/screen.service';
import { AttributeDataService } from '../../attribute/attribute-data.service';
import { SuggestionService } from '../../../suggestions/suggestion.service';
import { AttributeFieldInfo } from '../../attribute/attribute.types';
import { EntitySuggestionStoreService } from '../../../suggestions/entity-suggestion-store.service';
import { UpdateEntityAttributeResult } from '@datagalaxy/webclient/entity/data-access';
import { ScreenCategory } from '@datagalaxy/webclient/screen/data-access';
import {
    CrudOperation,
    FunctionalLogService,
} from '@datagalaxy/webclient/monitoring/data-access';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import {
    EntityUtils,
    IUpdatedAttributeData,
} from '@datagalaxy/webclient/entity/utils';
import { SpaceIdentifier } from '@datagalaxy/webclient/workspace/utils';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';
import {
    AttributeMetaInfo,
    TextQualityData,
    TextQualityUserVoteDto,
    TextQualityVoteStatus,
} from '@datagalaxy/webclient/attribute/domain';
import { ServerConstants } from '@datagalaxy/shared/server/domain';
import PropertyName = ServerConstants.PropertyName;

@Component({
    selector: 'dxy-entity-form',
    templateUrl: 'dxy-entity-form.component.html',
    styleUrls: ['./dxy-entity-form.component.scss'],
})
export class DxyEntityFormComponent
    extends DxyBaseComponent
    implements IScreenLayoutComponent, OnInit, OnChanges
{
    @Input() screenData: IScreenData;
    @Input() screenCategory: ScreenCategory;

    public fieldInfos: AttributeFieldInfo[];
    public titleKey: string;

    public get isHeader() {
        return this.screenCategory.IsHeader;
    }

    private entityData: EntityItem;
    private entityAttributes: AttributeMetaInfo[];
    private usedEntityAttributes: AttributeMetaInfo[];
    private modificationNotificationDurationMs = 3000;
    private formEntityUpdated = new Subject<IUpdatedAttributeData>();

    constructor(
        private entityService: EntityService,
        private attributeDataService: AttributeDataService,
        private screenService: ScreenService,
        private entityEventService: EntityEventService,
        private entityDockingPaneService: EntityDockingPaneService,
        private realTimeCommService: RealTimeCommService,
        private suggestionService: SuggestionService,
        private functionalLogService: FunctionalLogService,
        private entitySuggestionStore: EntitySuggestionStoreService
    ) {
        super();
    }

    ngOnInit() {
        this.log('ngOnInit', this.screenData, this.screenCategory);
        this.entityData = this.screenData.entityData;
        this.entityAttributes = this.screenData.entityAttributes;
        this.usedEntityAttributes = this.screenCategory.Properties.map(
            (screenProperty) =>
                this.entityAttributes?.find(
                    (ami) => ami.AttributeKey == screenProperty.Key
                )
        ).filter((o) => o);

        this.setTitleKey();

        const entityForm = this.buildEntityForm();
        this.fieldInfos = this.usedEntityAttributes.map(
            (ami) => new AttributeFieldInfo(ami, entityForm)
        );

        super.registerSubscriptions(
            this.entityEventService.subscribeEntityFieldsUpdate(
                this.entityData.ServerType,
                (updatedEntity: EntityItem, external: boolean) => {
                    this.markUpdatedFields(updatedEntity, external);
                }
            ),

            this.entityEventService.subscribeEntitySecurityUpdate(
                this.entityData.ServerType,
                (data) => {
                    if (
                        data.DataReferenceId == this.entityData.DataReferenceId
                    ) {
                        this.entityData.SecurityData = data.SecurityData;
                    }
                }
            ),
            this.realTimeCommService.subscribeAttributeTextQualityScoreEvent(
                (userId: string, textQualityData: TextQualityData[]) => {
                    this.updateTextQualityScores(textQualityData);
                }
            ),
            this.suggestionService.settings$.subscribe(
                (settings) =>
                    (entityForm.isSuggestionsDisabled = settings.disabled)
            )
        );
    }

    ngOnChanges(changes: SimpleChanges) {
        super.onChange(changes, 'screenCategory', () => this.setTitleKey());
    }

    public getAttributeDisplayName(attributeMeta: AttributeMetaInfo) {
        return this.attributeDataService.getAttributeDisplayName(
            attributeMeta,
            attributeMeta.serverType
        );
    }

    private buildEntityForm(): IEntityForm<unknown> {
        return {
            isEditEnabled: !this.screenData?.readOnly,
            isLabelDisplayed: true,
            isFieldByFieldValidationNeeded: true,
            formEntityUpdated$: this.formEntityUpdated.asObservable(),
            isSuggestionsDisabled:
                this.suggestionService.settingsValue.disabled,
            getEntityData: () => this.entityData,
            getEntityDataList: () => (this.entityData ? [this.entityData] : []),
            getSpaceIdr: () => SpaceIdentifier.fromEntity(this.entityData),
            getModule: () =>
                DataUtil.getModuleFromServerType(this.entityData.ServerType),
            isAttributeEditEnabled: (attributeMeta: AttributeMetaInfo) => {
                if (!this.entityData.SecurityData?.HasWriteAccess) {
                    return false;
                }
                const sd = this.entityData?.SecurityData;
                switch (attributeMeta.AttributeKey) {
                    case ServerConstants.PropertyName.EntityStatus:
                        return !!sd?.HasEntityStatusWriteAccess;
                    case ServerConstants.PropertyName.Technology:
                        return this.entityData?.HasWritableTechnologyCode;
                    default:
                        return AttributeDataService.isOfficialRoleAttribute(
                            attributeMeta
                        )
                            ? !!sd?.HasOfficialRoleAttributesWriteAccess
                            : !!sd?.HasWriteAccess;
                }
            },
            getSuggestionGroup$: (attributeKey: string) =>
                this.entitySuggestionStore.selectAttributeSuggestionGroup(
                    attributeKey
                ),
            getTextQualityUserVote: (attributePath: string) =>
                this.entityData.TextQualityUserVoteDtos?.find(
                    (d) => d.AttributePath === attributePath
                ),

            getAttributeValue: (attributeKey: string) =>
                this.entityData.getAttributeValue(attributeKey),
            setAttributeValue: async (
                attributeKey: string,
                newValue: unknown
            ) => this.entityData.setAttributeValue(attributeKey, newValue),

            setTextQualityUserVote: async (
                attributePath: string,
                vote: TextQualityVoteStatus
            ) => {
                const res =
                    await this.attributeDataService.setAttributeTextQualityUserVote(
                        this.entityData,
                        attributePath,
                        vote
                    );
                CollectionsHelper.replaceOrAppend(
                    this.entityData.TextQualityUserVoteDtos,
                    (d) => d.AttributePath === attributePath,
                    res.TextQualityUserVoteDto
                );
            },

            onChangeAttributeValue: async (
                attributeKey: string,
                linkChangeInfo?: IEntityLinksChangeInfo
            ) => {
                const meta = this.entityAttributes?.find(
                    (c) => c.AttributeKey === attributeKey
                );
                if (this.screenCategory.IsHeader) {
                    this.logHeaderFunctionalAction(attributeKey);
                }
                return (await this.entityService.updateEntity(
                    this.entityData,
                    attributeKey,
                    this.entityData.getAttributeValue(attributeKey),
                    {
                        linkChangeInfo,
                        includeSecurity:
                            AttributeDataService.isOfficialRoleAttribute(
                                meta
                            ) || meta.AttributeKey == PropertyName.EntityStatus,
                        includeQuality:
                            this.entityDockingPaneService.isInsightsPaneVisible(),
                    }
                )) as UpdateEntityAttributeResult;
            },
            loadReferenceOptions: async (attributeMeta: AttributeMetaInfo) =>
                await this.attributeDataService.loadReferenceOptionsEntity(
                    attributeMeta,
                    this.entityData
                ),
        };
    }
    private logHeaderFunctionalAction(attributeKey: string) {
        const attribute = this.entityAttributes.find(
            (a) => a.AttributeKey == attributeKey
        );
        if (!attribute || !this.entityData) {
            return;
        }
        const suffix = `_HEADER_${
            !attribute.IsCdp
                ? attribute.AttributeKey.toUpperCase()
                : 'CUSTOM_PROP'
        }`;
        this.functionalLogService.logEntityItemAction(
            this.entityData,
            CrudOperation.U,
            undefined,
            suffix
        );
    }

    private updateTextQualityScores(textQualityData: TextQualityData[]) {
        textQualityData?.forEach((data) => {
            if (
                data.ReferenceId !== this.entityData.ReferenceId ||
                data.VersionId !== this.entityData.VersionId
            ) {
                return;
            }
            let textQualityUserVote =
                this.entityData.TextQualityUserVoteDtos?.find(
                    (d) => d.AttributePath === data.AttributePath
                );
            if (textQualityUserVote) {
                const userHasVoted =
                    textQualityUserVote.Vote !== TextQualityVoteStatus.None &&
                    textQualityUserVote.Vote != undefined;
                textQualityUserVote.TextQualityStat =
                    data.TextQualityStatistics;
                textQualityUserVote.CurrentTextHashcode = data.TextHashcode;
                textQualityUserVote.IsVoteOutdated =
                    data.TextHashcode !==
                        textQualityUserVote.UserLastInteractionTextHashcode &&
                    userHasVoted;
            } else {
                textQualityUserVote = new TextQualityUserVoteDto();
                textQualityUserVote.AttributePath = data.AttributePath;
                textQualityUserVote.CurrentTextHashcode = data.TextHashcode;
                textQualityUserVote.TextQualityStat =
                    data.TextQualityStatistics;
                this.entityData.TextQualityUserVoteDtos.push(
                    textQualityUserVote
                );
            }
        });
    }

    private markUpdatedFields(updatedEntity: EntityItem, isExternal: boolean) {
        const attributeUpdateData = EntityUtils.getModifiedAttributesAndValue(
            this.usedEntityAttributes,
            this.entityData,
            updatedEntity
        );
        attributeUpdateData.forEach((data) => {
            if (isExternal) {
                data.attribute.hasBeenModifiedExternallyRecently = true;
                this.formEntityUpdated.next(data);
                setTimeout(
                    () =>
                        (data.attribute.hasBeenModifiedExternallyRecently =
                            false),
                    this.modificationNotificationDurationMs
                );
            } else {
                this.formEntityUpdated.next(data);
            }
        });
    }

    private setTitleKey() {
        this.titleKey = this.screenService.getScreenCategoryNameKey(
            this.screenCategory
        );
    }
}

import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { BaseCellComponent } from '@datagalaxy/core-ui/cell-components';
import { IDgEntityLinkShortcutCellParams } from './dg-entity-link-shortcut-cell.types';
import {
    NavigateTo,
    NavigationService,
} from '../../../../services/navigation.service';
import { IHierarchicalData } from '@datagalaxy/dg-object-model';
import { FeatureFlagService } from '@datagalaxy/webclient/feature-flag';
import { EntityTypeUtils } from '@datagalaxy/webclient/entity/utils';

/**
 * ## Role
 * Display entity in a small card with a link to navigate to entity
 */
@Component({
    selector: 'app-dg-entity-link-shortcut-cell',
    templateUrl: 'dg-entity-link-shortcut-cell.component.html',
    styleUrls: ['dg-entity-link-shortcut-cell.component.scss'],
})
export class DgEntityLinkShortcutCellComponent
    extends BaseCellComponent<
        unknown,
        IHierarchicalData,
        IDgEntityLinkShortcutCellParams
    >
    implements OnChanges
{
    @Input() hdd: IHierarchicalData;

    public glyphClass?: string;
    public href?: string;

    constructor(
        private featureFlagService: FeatureFlagService,
        private navigationService: NavigationService
    ) {
        super();
    }

    ngOnChanges(changes: SimpleChanges): void {
        super.ngOnChanges(changes);
        this.onAnyChangesBut(
            changes,
            this,
            ['params'],
            () => this.initLink(),
            true
        );
    }

    public onClick(e: MouseEvent) {
        if (this.featureFlagService.isFeatureEnabled('ENABLE_AUTH_V2')) {
            e.preventDefault();
            e.stopPropagation();
            this.navigationService.goToWithHierarchicalData(this.hdd, {
                destination: NavigateTo.Object,
                withEntityFullPage: true,
            });
            return;
        }
        this.navigationService.setCookieNavNewTab(this.href);
    }

    protected override updateBindings() {
        this.hdd = this.value;
        this.initLink();
    }

    private initLink() {
        if (!this.hdd) {
            return;
        }
        this.glyphClass = EntityTypeUtils.getColoredGlyphClass(
            this.hdd.EntityType
        );
        this.setHref();
    }

    private async setHref() {
        this.href =
            await this.navigationService.getUrlToGoToWithHierarchicalData(
                this.hdd,
                NavigateTo.Object,
                true
            );
    }
}

<dxy-collapsible
    *ngIf="isCollapsible"
    [titleText]="displayName"
    class="collapsible no-header-padding header-primary"
>
    <ng-container *ngTemplateOutlet="listContent"></ng-container>
</dxy-collapsible>

<ng-container *ngIf="!isCollapsible">
    <div class="group-header">
        <span class="no-link">{{ displayName }}</span>
    </div>
    <div class="group-body">
        <ng-container *ngTemplateOutlet="listContent"></ng-container>
    </div>
</ng-container>

<ng-template #listContent>
    <!-- items -->
    <ol>
        <!-- item -->
        <li
            *ngFor="let it of filteredItems"
            [class.active-filter]="it.isChecked"
        >
            <mat-checkbox
                class="checkbox-xs checkbox-overflow-hidden"
                [(ngModel)]="it.isChecked"
                [matTooltip]="it.tooltipText"
                (change)="onFilterChange.emit(it)"
                dxyLogFunctional="MAIN_SEARCH,R"
            >
                <dxy-profile-avatar
                    *ngIf="isUserItem(it)"
                    [userId]="it.valueId"
                    [isMiniIcon]="true"
                    class="profile-avatar"
                ></dxy-profile-avatar>
                <dxy-color-point-cell
                    *ngIf="isTagItem(it)"
                    [color]="it.glyphColor"
                ></dxy-color-point-cell>
                <span
                    *ngIf="it.glyphClassName"
                    class="glyph"
                    [ngClass]="it.glyphClassName"
                ></span>
                <div *ngIf="it.hasIcon" class="img-container">
                    <img
                        *ngIf="hasIconUrl(it)"
                        [src]="it.iconUrl"
                        class="img-wrapper"
                    />
                    <span
                        *ngIf="!hasIconUrl(it)"
                        class="img-wrapper placeholder"
                    ></span>
                </div>
                <span class="text">{{ it.displayName }}</span>
                <span *ngIf="it.dataCount" class="search-count">{{
                    it.dataCount | numberFormat
                }}</span>
            </mat-checkbox>
        </li>
        <!-- show more -->
        <li *ngIf="isShowMoreVisible" class="show-more" (click)="onShowMore()">
            <span translate>UI.Search.facets.showMore</span>
            <button mat-icon-button>
                <i class="glyph-more"></i>
            </button>
        </li>
    </ol>
    <!-- subgroups -->
    <ol>
        <ng-container *ngFor="let sg of subGroups">
            <li *ngIf="!sg.isEmpty()">
                <dxy-search-facet-filter-group
                    [groupData]="sg"
                    (onFilterChange)="onFilterChange.emit($event)"
                    class="sub-group"
                ></dxy-search-facet-filter-group>
            </li>
        </ng-container>
    </ol>
</ng-template>

import { CoreUtil } from '@datagalaxy/core-util';
import { CollectionsHelper } from '@datagalaxy/core-util';
import { environment } from '../../../environments/environment';
import { ExplorationGraphNode } from './ExplorationGraphNode';
import { ExplorationGraphLink } from './ExplorationGraphLink';
import { ExplorationGraphRelationItem } from './ExplorationGraphRelationItem';
import {
    EntityLinkTypeKind,
    IEntityIdentifier,
    ObjectLinkType,
} from '@datagalaxy/dg-object-model';
import { EntityTypeUtil } from '@datagalaxy/dg-object-model';
import { GetExploratoryAnalyticsDataResult } from '@datagalaxy/webclient/explorer/data-access';
import {
    ExploratoryDataLink,
    ExploratoryDataObject,
} from '@datagalaxy/webclient/explorer/data-access';

export class ExplorationGraphData {
    public get rootNode() {
        if (!this._rootNode && this._rootEntityIdr) {
            this._rootNode = this.nodes.get(this._rootEntityIdr.ReferenceId);
            this._rootNode.isRoot = true;
            this._rootNode.fixed = false;
        }
        return this._rootNode;
    }

    public get rootNodeVersionId() {
        return this._rootEntityIdr.VersionId;
    }

    private _rootNode: ExplorationGraphNode;
    private links: Map<string, ExplorationGraphLink>;
    private nodes: Map<string, ExplorationGraphNode>;
    private relations: Map<string, ExplorationGraphRelationItem[]>;
    private objects: Map<string, ExploratoryDataObject>;

    constructor(
        private _rootEntityIdr?: IEntityIdentifier,
        public debug = false
    ) {
        this.objects = new Map<string, ExploratoryDataObject>();
        this.nodes = new Map<string, ExplorationGraphNode>();
        this.links = new Map<string, ExplorationGraphLink>();
        this.relations = new Map<string, ExplorationGraphRelationItem[]>();
    }

    public addData(eadr: GetExploratoryAnalyticsDataResult) {
        const sourceObjects = eadr.Objects,
            sourceLinks = eadr.Links || [];
        this.log('addData', eadr.Links);
        const versionId = this._rootEntityIdr.VersionId;
        sourceObjects.forEach((edo) => {
            const hdd = edo.Data;
            //#Archi: should'nt this be set server side ?
            if (versionId && !hdd.VersionId) {
                hdd.setVersionId(versionId);
            }
            this.objects.set(hdd.DataReferenceId, edo);
        });
        if (!sourceLinks.length) {
            const link = new ExploratoryDataLink(
                ObjectLinkType.Unknown,
                EntityLinkTypeKind.Unknown,
                null,
                false
            );
            link.SourceId = sourceObjects[0].Data.DataReferenceId;
            sourceLinks.push(link);
        }
        sourceLinks.forEach((l) => this.addDataLink(l));
    }
    private addDataLink(edl: ExploratoryDataLink) {
        let parentNode = this.nodes.get(edl.SourceId);
        if (!parentNode) {
            this.addNode((parentNode = new ExplorationGraphNode(edl.SourceId)));
        }

        const targetId = edl.TargetId;
        if (!targetId) {
            return;
        }

        let targetNode = this.nodes.get(targetId);
        const isTargetExisting = !!targetNode;
        if (!isTargetExisting) {
            this.addNode((targetNode = new ExplorationGraphNode(targetId)));
        }
        const isEntityLink = edl.ObjectLinkType == ObjectLinkType.EntityLink;
        const childLink = new ExplorationGraphLink(
            edl,
            parentNode,
            targetNode,
            isEntityLink ? edl.universalLinkTypeString : edl.LinkTypeName,
            edl.LinkObjectId,
            edl.IsReverse,
            isEntityLink,
            edl.LinkTypeName,
            edl.IsGoldenLink
        );

        const existing = this.links.get(childLink.id);
        if (existing) {
            if (this.isInputOutput(existing, childLink)) {
                existing.parallel = childLink;
            }
        } else {
            this.addLink(childLink);
        }

        if (!isTargetExisting) {
            this.clusterNode(targetNode);
        }
    }
    private isInputOutput(a: ExplorationGraphLink, b: ExplorationGraphLink) {
        if (!a || !b) {
            return false;
        }
        const ta = a.linkTypeName,
            tb = b.linkTypeName;
        const tin = 'IsInputOf',
            tout = 'IsOutputOf';
        return (ta == tin && tb == tout) || (ta == tout && tb == tin);
    }
    protected addLink(link: ExplorationGraphLink) {
        this.log('addLink', link?.linkTypeName, link?.source);
        this.links.set(link.id, link);

        const source = link.source;
        const target = link.target;

        const sourceType = source.entityType || this.getNodeType(source);
        const targetType = target.entityType || this.getNodeType(target);

        const sourceRelations = this.relations.get(source.id);
        const targetRelations = this.relations.get(target.id);

        sourceRelations.push(
            new ExplorationGraphRelationItem(
                target.id,
                sourceType,
                link.linkTypeName,
                link.isReverse
            )
        );
        targetRelations.push(
            new ExplorationGraphRelationItem(
                source.id,
                targetType,
                link.linkTypeName,
                !link.isReverse
            )
        );

        sourceRelations.sort(ExplorationGraphRelationItem.sort);
        targetRelations.sort(ExplorationGraphRelationItem.sort);

        if (source.isCluster || source.parentClusterNodeId) {
            this.extractNodeFromCluster(source);
        }
        if (target.isCluster || target.parentClusterNodeId) {
            this.extractNodeFromCluster(target);
        }
    }

    protected addNode(node: ExplorationGraphNode) {
        this.nodes.set(node.id, node);
        if (!node.entityType) {
            node.entityType = this.getNodeType(node);
        }
        this.relations.set(node.id, []);
    }

    public getAllNodes() {
        return Array.from(this.nodes.values());
    }
    public getDisplayableNodes() {
        return CollectionsHelper.filterMap(
            this.nodes,
            (node) => node?.isDisplayed
        );
    }
    public getDisplayableLinks() {
        return CollectionsHelper.filterMap(
            this.links,
            (link) => link?.isDisplayed
        );
    }

    public getObjectHdd(node: ExplorationGraphNode) {
        return this.objects.get(node?.nodeObjectReferenceId)?.Data;
    }

    public clusterNode(parentClusterNode: ExplorationGraphNode) {
        if (!parentClusterNode) {
            return;
        }

        const parentId = parentClusterNode.id;
        const parentRelations = this.relations.get(parentId);

        const childrenIds = new Array<string>();
        this.relations.forEach((nodeRelations, nodeId) => {
            if (nodeId === parentId) {
                return;
            }
            const child = this.nodes.get(nodeId);
            if (!child || child.isRoot) {
                return;
            }
            if (!CoreUtil.areEqual(nodeRelations, parentRelations)) {
                return;
            }
            childrenIds.push(nodeId);
            child.isCluster = false;
            child.parentClusterNodeId = parentId;
        });

        if (!childrenIds.length) {
            return;
        }

        parentClusterNode.isCluster = true;
        parentClusterNode.parentClusterNodeId = null;
        parentClusterNode.childrenClusterNodesId = childrenIds;
    }

    public unclusterNode(parentClusterNode: ExplorationGraphNode) {
        const childrenIds = parentClusterNode?.childrenClusterNodesId;
        if (!childrenIds.length) {
            return;
        }

        childrenIds.forEach((childId) => {
            const child = this.nodes.get(childId);
            if (!child) {
                return;
            }
            child.x = null;
            child.y = null;
            child.isCluster = false;
            child.parentClusterNodeId = null;
        });

        parentClusterNode.isCluster = false;
        childrenIds.length = 0;
    }

    private changeParentClusterNode(
        newParentClusterNode: ExplorationGraphNode,
        oldParentClusterNode: ExplorationGraphNode
    ) {
        const newParentId = newParentClusterNode.id;
        const childrenIds = oldParentClusterNode.childrenClusterNodesId;
        childrenIds.splice(childrenIds.indexOf(newParentId), 1);

        newParentClusterNode.isCluster = true;
        newParentClusterNode.parentClusterNodeId = null;
        newParentClusterNode.childrenClusterNodesId = childrenIds;

        oldParentClusterNode.isCluster = false;
        oldParentClusterNode.parentClusterNodeId = null;
        oldParentClusterNode.childrenClusterNodesId = [];

        childrenIds.forEach((childId) => {
            const child = this.nodes.get(childId);
            if (child) {
                return;
            }
            child.parentClusterNodeId = newParentId;
        });
    }

    private extractNodeFromCluster(nodeToExtract: ExplorationGraphNode) {
        let parentClusterNode: ExplorationGraphNode;

        if (nodeToExtract.isCluster) {
            const newParentClusterNode = this.nodes.get(
                nodeToExtract.childrenClusterNodesId[0]
            );
            newParentClusterNode.x = nodeToExtract.x;
            newParentClusterNode.y = nodeToExtract.y;
            this.changeParentClusterNode(newParentClusterNode, nodeToExtract);
            parentClusterNode = newParentClusterNode;
        } else {
            parentClusterNode = this.nodes.get(
                nodeToExtract.parentClusterNodeId
            );
            const idIndex = parentClusterNode.childrenClusterNodesId.indexOf(
                nodeToExtract.id
            );
            parentClusterNode.childrenClusterNodesId.splice(idIndex, 1);
            nodeToExtract.parentClusterNodeId = null;
        }

        // Init node position
        nodeToExtract.x = null;
        nodeToExtract.y = null;

        if (!parentClusterNode.childrenClusterNodesId.length) {
            parentClusterNode.isCluster = false;
        }

        return parentClusterNode;
    }

    private getNodeType(node: ExplorationGraphNode) {
        return EntityTypeUtil.getEntityType(
            this.getNodeProperty(node, 'DataTypeName') as string,
            this.getNodeProperty(node, 'SubTypeName') as string
        );
    }
    private getNodeProperty(node: ExplorationGraphNode, propertyName: string) {
        return this.objects.get(node?.nodeObjectReferenceId)?.Data[
            propertyName
        ];
    }

    protected withClusterChildren<TNode extends ExplorationGraphNode>(
        clusterItem: TNode,
        action: (child: TNode) => void
    ) {
        clusterItem?.childrenClusterNodesId?.forEach((childId) => {
            const child = this.nodes.get(childId) as TNode;
            if (child) {
                action(child);
            }
        });
    }
    public getClusterChildren<TNode extends ExplorationGraphNode>(
        clusterItem: TNode
    ) {
        return (
            clusterItem?.childrenClusterNodesId?.map(
                (childId) => this.nodes.get(childId) as TNode
            ) || []
        );
    }

    public getLinks<TNode extends ExplorationGraphNode>(item: TNode) {
        return CollectionsHelper.filterMap(
            this.links,
            (l) => l.source == item || l.target == item
        );
    }

    public getLinksOfTarget<TNode extends ExplorationGraphNode>(item: TNode) {
        return CollectionsHelper.filterMap(this.links, (l) => l.target == item);
    }

    public getLinkedNodes<TNode extends ExplorationGraphNode>(item: TNode) {
        const nodes = new Array<ExplorationGraphNode>();
        this.links.forEach((l) => {
            const s = l.source,
                t = l.target;
            if (s == item) {
                if (nodes.indexOf(t) == -1) {
                    nodes.push(t);
                }
            } else if (t == item) {
                if (nodes.indexOf(s) == -1) {
                    nodes.push(s);
                }
            }
        });
        return nodes;
    }

    protected log(...args: any[]) {
        if (this.debug) {
            console.log(this.constructor.name, ...args);
        }
    }
    protected warn(...args: any[]) {
        if (!environment.production) {
            console.warn(this.constructor.name, ...args);
        }
    }
}

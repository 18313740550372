import { NgModule } from '@angular/core';
import { FilteredViewSelectorComponent } from './filtered-view-selector/filtered-view-selector.component';
import { DxyFieldsModule } from '@datagalaxy/core-ui';
import { AsyncPipe, NgForOf, NgIf } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { FilterModule } from '@datagalaxy/webclient/filter/ui';

@NgModule({
    declarations: [FilteredViewSelectorComponent],
    exports: [FilteredViewSelectorComponent],
    imports: [
        DxyFieldsModule,
        NgIf,
        FormsModule,
        FilterModule,
        AsyncPipe,
        NgForOf,
    ],
})
export class FilterFeatureModule {}

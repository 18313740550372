import { NgModule } from '@angular/core';
import { CoreUiModule } from './core-ui.module';
import { DxyFieldsModule } from './fields';
import { DxyRichTextModule } from './rich-text';

/**
 * This module is a convenience module that exports all the modules of the core-ui library included non buildable sub libraries.
 */
@NgModule({
    imports: [CoreUiModule, DxyFieldsModule, DxyRichTextModule],
    exports: [CoreUiModule, DxyFieldsModule, DxyRichTextModule],
})
export class CoreUiFullModule {}

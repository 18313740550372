<div class="dg-entity-grid-container">
    <div *ngIf="showTitleBar" class="title-bar">
        <div class="title-bar-content">
            <div *ngIf="headerActionButton" class="header-action-button">
                <button
                    mat-icon-button
                    class="glyph"
                    [ngClass]="getGlyphClass(headerActionButton)"
                    (click)="onHeaderActionButtonClick()"
                    [matTooltip]="headerActionButtonTooltipKey | translate"
                ></button>
            </div>

            <dxy-search-input
                *ngIf="showSearchBox"
                class="no-border height-50"
                placeholderKey="UI.Global.filter.filterByName"
                [searchString]="searchBoxTerm"
                (onSearchString)="onSearchBoxTermChanged($event.searchString)"
            ></dxy-search-input>

            <div class="grid-header-transclude">
                <ng-content select="[header]"></ng-content>
            </div>

            <div *ngIf="isAnyTitleMessage" class="pull-left grid-messages">
                <div
                    *ngIf="isMultiSelect"
                    class="grid-message selection-message"
                >
                    {{ selectionMessage }}
                </div>
                <div
                    *ngIf="!isMultiSelect && totalCountMessage"
                    class="grid-message total-count-message"
                >
                    {{ totalCountMessage }}
                </div>
                <div
                    *ngIf="noResult && isAnyFilter"
                    class="grid-message filters-no-result"
                    translate
                >
                    UI.Filter.noResultWithFilters
                </div>
                <div
                    *ngIf="noResult && !isAnyFilter"
                    class="grid-message filters-no-result"
                    translate
                >
                    UI.Filter.noResultWithoutFilters
                </div>
                <div
                    *ngIf="tooManyResults"
                    class="grid-message filters-too-many-results"
                    translate
                >
                    UI.Filter.tooManyResults
                </div>
                <div
                    *ngIf="externalTitleMessage"
                    class="grid-message external-title-message"
                >
                    {{ externalTitleMessage }}
                </div>
            </div>
        </div>

        <div class="actions-container">
            <button
                *ngIf="isCreateButtonVisible"
                mat-icon-button
                (click)="onCreateButtonClick()"
                class="create-button"
                [matTooltip]="'UI.Global.btnCreate' | translate"
                matTooltipPosition="above"
            >
                <span class="glyph-add"></span>
            </button>
            <dxy-option-list
                *ngIf="selectedCount && !isListOptionEmpty"
                [dropdownButton]="bulkEditDropdownButton"
                [options]="bulkEditDropdownOptions"
                tooltipPosition="above"
                (functional)="onFunctional($event)"
            ></dxy-option-list>
            <dxy-omni-grid-column-selector
                *ngIf="showColumnsSelector"
                [columnGroups]="filteredColumnListGroups"
                (onColumnToggle)="setColumnVisibility($event)"
                (onReset)="resetColumnStates()"
                (onSearch)="filterColumns($event)"
                [hideCollapse]="hideColumnSelectorCollapse"
                [getColumnGlyphClass]="getColumnGlyphClass"
            ></dxy-omni-grid-column-selector>
            <button
                *ngIf="showHierarchicalToggleButton"
                mat-icon-button
                class="hierachical-view-button"
                (click)="toggleHierarchicalView()"
                [matTooltip]="getHierarchicalTooltip()"
                matTooltipPosition="above"
                [dxyLogFunctional]="getFeatureCodeHierarchy()"
            >
                <span
                    class="glyph"
                    [class.glyph-view-tree]="!isHierarchical"
                    [class.glyph-view-flat]="isHierarchical"
                ></span>
            </button>
            <dxy-option-list
                *ngIf="showGlobalCollapseExpand"
                class="global-collapse-expand"
                [isDropdownMenu]="true"
                [dropdownButton]="globalCollapseExpandDropdownButton"
                [options]="globalCollapseExpandDropdownOptions"
                (functional)="onFunctional($event)"
            ></dxy-option-list>
            <ng-content select="[headerActions]"></ng-content>
        </div>
    </div>

    <div class="dg-entity-grid-inner-container">
        <div *ngIf="showMessagesInGrid" class="list-messages">
            <div *ngIf="showTotalCountMessage" class="list-message">
                <div>{{ totalCountMessage }}</div>
            </div>
            <div
                *ngIf="tooManyResults"
                class="list-message filters-too-many-results"
                translate
            >
                UI.Filter.tooManyResults
            </div>
            <button
                *ngIf="showHierarchicalToggleButtonInGrid"
                mat-icon-button
                class="hierachical-view-button"
                (click)="toggleHierarchicalView()"
                [matTooltip]="getHierarchicalTooltip()"
                matTooltipPosition="above"
                [dxyLogFunctional]="getFeatureCodeHierarchy()"
            >
                <span
                    class="glyph"
                    [class.glyph-view-tree]="!isHierarchical"
                    [class.glyph-view-flat]="isHierarchical"
                ></span>
            </button>
        </div>
        <dxy-omni-grid
            *ngIf="!resettingGrid"
            [class.hidden]="!showGrid"
            class="dg_omnigrid-entities"
            [ngClass]="{
                'dg_omnigrid-grid': isGrid,
                'dg_omnigrid-list': isList,
                'dg_omnigrid-flat': isFlat,
                'dg_omnigrid-tree': isTree,
                'dg_omnigrid-breadcrumbs': isBreadcrumbs,
                'dg_omnigrid-multiselect': isMultiSelect
            }"
            [data]="gridData"
            (onReady)="onGridReady($event)"
            (onRowClick)="onRowClick($event.data, $event.refreshRow)"
            (onSortChange)="onSortChange($event)"
            (onSelectionChange)="onGridSelectionChanged()"
            (onColumnsChange)="onGridColumnsChanged()"
        ></dxy-omni-grid>
        <div
            *ngIf="showMessagesInGrid && noResult"
            class="filters-no-result-in-list"
        >
            <div *ngIf="isAnyFilter" translate>
                UI.Filter.noResultWithFilters
            </div>
            <div *ngIf="!isAnyFilter" translate>
                UI.Filter.noResultWithoutFilters
            </div>
            <div
                *ngFor="let i of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]"
                class="list-no-item"
            ></div>
        </div>
    </div>
</div>

import {
    Component,
    ElementRef,
    Input,
    NgZone,
    OnInit,
    Optional,
    Self,
    ViewChild,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DxyFileUploadFieldControlComponent } from '../controls/file-upload-field-control/file-upload-field-control.component';
import { DxyBaseFocusableFieldComponent } from '@datagalaxy/ui/fields';

/**
 * ## Role
 * File upload field. Could upload many files
 */
@Component({
    selector: 'dxy-field-file-upload',
    templateUrl: 'field-file-upload.component.html',
    styleUrls: ['field-file-upload.component.scss'],
})
export class DxyFieldFileUploadComponent
    extends DxyBaseFocusableFieldComponent<File[]>
    implements OnInit
{
    /** label tooltip for informations */
    @Input() infoTooltip: string;
    /** When provided, must return an empty string if no error, else the error message.
     * When not provided, each file is tested for size only, using the *maxFileSize* input */
    @Input() checkFile: (f: File[]) => string;

    @Input() allowsMultiple: boolean;
    /** Default value is * */
    @Input() acceptedFormat = '*';
    /** Default value is 20 Mo */
    @Input() maxFileSize = 20971520;

    @ViewChild(DxyFileUploadFieldControlComponent)
    fieldControl: DxyFileUploadFieldControlComponent;

    public readonly checkFilesFn = (files: File[]) => this.checkFiles(files);

    public get label() {
        return this.getLabel(this.translate);
    }
    public get labelTooltip() {
        return this.getLabelTooltip(this.translate);
    }
    public get errorMessage() {
        return this.getErrorMessage(this.translate);
    }

    //#region API
    public get hasFiles(): boolean {
        return !!this.value?.length;
    }
    //#endregion

    protected get empty() {
        return this.fieldControl?.empty;
    }

    private clicked: boolean;

    constructor(
        private translate: TranslateService,
        elementRef: ElementRef<HTMLElement>,
        ngZone: NgZone,
        @Optional() @Self() ngControl: NgControl
    ) {
        super(elementRef, ngZone, ngControl);
    }

    //#region API
    public doFocus() {
        this.fieldControl.focus();
    }
    public doBlur() {
        this.fieldControl.blur();
    }
    /** Returns an empty string if no file exceeds the given size, else a translated error message */
    public checkFilesSize(files: File[], maxFileSize: number): string {
        return files?.some((f) => f.size > maxFileSize)
            ? this.translate.instant('UI.Attribute.FileUpload.ErrorMessageSize')
            : '';
    }
    //#endregion

    ngOnInit() {
        super.ngOnInit();
        if (!this.allowsMultiple && this.value?.length > 1) {
            this.value = [this.value[0]];
        }
    }

    public onContainerClick() {
        this.clicked = true;
        this.doFocus();
        this.fieldControl.click();
    }

    protected preventBlur(event: FocusEvent, isClickingInside: boolean) {
        if (this.clicked) {
            this.clicked = false;
            return true;
        }
        return isClickingInside;
    }

    private checkFiles(files: File[]): boolean {
        this.errorMessageText = this.checkFile
            ? this.checkFile(files)
            : this.checkFilesSize(files, this.maxFileSize);
        return !!this.errorMessage;
    }
}

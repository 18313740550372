import { Injectable } from '@angular/core';
import { Dashboard } from '@datagalaxy/webclient/dashboard/ui';
import { TranslateService } from '@ngx-translate/core';
import { generateGuid } from '@datagalaxy/utils';

@Injectable({ providedIn: 'root' })
export class DefaultSpaceHomeDashboardBuilder {
    constructor(private translate: TranslateService) {}
    public build(): Dashboard {
        return {
            sections: [
                {
                    id: generateGuid(),
                    title: this.translate.instant(
                        'UI.SpaceHome.Widgets.Sections.General'
                    ),
                    titleVisible: true,
                    widgetInstances: [
                        {
                            guid: generateGuid(),
                            position: {
                                x: 0,
                                y: 0,
                            },
                            widgetName: 'space-description-widget',
                        },
                        {
                            guid: generateGuid(),
                            position: {
                                x: 1,
                                y: 0,
                            },
                            widgetName: 'space-members-widget',
                        },
                        {
                            guid: generateGuid(),
                            position: {
                                x: 2,
                                y: 0,
                            },
                            widgetName: 'space-news-widget',
                        },
                    ],
                },
                {
                    id: generateGuid(),
                    title: this.translate.instant(
                        'UI.SpaceHome.Widgets.Sections.Catalog'
                    ),
                    titleVisible: true,
                    widgetInstances: [
                        {
                            guid: generateGuid(),
                            position: {
                                x: 0,
                                y: 0,
                            },
                            widgetName: 'space-filtered-views-widget',
                        },
                        {
                            guid: generateGuid(),
                            position: {
                                x: 1,
                                y: 0,
                            },
                            widgetName: 'space-activity-logs-widget',
                        },
                    ],
                },
            ],
        };
    }
}

import { NgModule } from '@angular/core';
import { DxyTaskContainerComponent } from './dxy-task-container/dxy-task-container.component';
import { DxyTaskFacetFiltersComponent } from './dxy-task-facet-filters/dxy-task-facet-filters.component';
import { DxyTaskItemComponent } from './dxy-task-item/dxy-task-item.component';
import { DxyTaskResultsComponent } from './dxy-task-results/dxy-task-results.component';
import { DxyTaskSearchComponent } from './dxy-task-search/dxy-task-search.component';
import { DxyTaskFormModalComponent } from './dxy-task-form-modal/dxy-task-form-modal.component';
import { SharedModule } from '../shared/shared.module';
import { DxySharedDialogsModule } from '../shared/dialogs/DxySharedDialogsModule';
import { FieldsModule } from '../shared/fields/fields.module';
import { DxySharedUiModule } from '../shared/shared-ui/DxySharedUiModule';
import { DxySpaceSelectorModule } from '../space-selector/DxySpaceSelectorModule';
import { DxySupportModule } from '../shared/support/DxySupportModule';
import { GridComponent } from '@datagalaxy/ui/grid';

@NgModule({
    imports: [
        SharedModule,
        DxySharedDialogsModule,
        FieldsModule,
        GridComponent,
        DxySharedUiModule,
        DxySpaceSelectorModule,
        DxySupportModule,
    ],
    declarations: [
        DxyTaskFormModalComponent,
        DxyTaskItemComponent,
        DxyTaskContainerComponent,
        DxyTaskResultsComponent,
        DxyTaskFacetFiltersComponent,
        DxyTaskSearchComponent,
    ],
    exports: [
        DxyTaskFormModalComponent,
        DxyTaskItemComponent,
        DxyTaskContainerComponent,
        DxyTaskSearchComponent,
    ],
})
export class DxyTaskModule {}

import { NgModule } from '@angular/core';
import { DxyScreenLayoutCategoryComponent } from './dxy-screen-layout-category/dxy-screen-layout-category';
import { DxyScreenLayoutComponent } from './dxy-screen-layout/dxy-screen-layout.component';
import { SharedModule } from '../shared.module';

@NgModule({
    imports: [SharedModule],
    declarations: [DxyScreenLayoutCategoryComponent, DxyScreenLayoutComponent],
    exports: [DxyScreenLayoutCategoryComponent, DxyScreenLayoutComponent],
})
export class DxyScreensLayoutModule {}

<div [style.padding-left.px]="paddingLeft" class="item">
    <button
        mat-icon-button
        *ngIf="node?.children?.length"
        class="tree-toggle"
        (click)="onToggle()"
    >
        <i
            class="glyph"
            [class.glyph-arrow-drop-right]="!open"
            [class.glyph-arrow-drop-down]="open"
        ></i>
    </button>
    <app-entity-card-cell
        [hierarchicalData]="getChildHddData(node)"
        [actions]="actions"
        [isRestrictedItem]="!!node.noAccessData"
        [showRestrictedActions]="true"
    ></app-entity-card-cell>
</div>

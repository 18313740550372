import {
    AfterViewInit,
    Component,
    Input,
    OnChanges,
    SimpleChanges,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import { ICustomCellParams } from './edit-grid-custom-cell.types';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
/**
 * ## Role
 * Dynamic custom cell for edit grid.
 * Inject a component inside the edit grid.
 */
@Component({
    selector: 'dxy-edit-grid-custom-cell',
    template: '<ng-container #cellContainer></ng-container>',
})
export class DxyEditGridCustomCellComponent
    extends DxyBaseComponent
    implements OnChanges, AfterViewInit
{
    @Input() contentParams: ICustomCellParams;
    @ViewChild('cellContainer', { read: ViewContainerRef })
    private viewContainerRef: ViewContainerRef;

    constructor() {
        super();
    }

    ngOnChanges(changes: SimpleChanges) {
        super.onChange(
            changes,
            'contentParams',
            () => this.initInnerComponent(),
            false
        );
    }
    ngAfterViewInit(): void {
        this.initInnerComponent();
    }

    private async initInnerComponent() {
        this.log('initCustomCell');

        const componentType = this.contentParams?.component;
        this.viewContainerRef.clear();
        if (!componentType) {
            return;
        }
        const component =
            componentType &&
            this.viewContainerRef.createComponent(componentType);
        component.instance['params'] = this.contentParams.params;
        component.changeDetectorRef.detectChanges();
    }
}

import { IGraphicalToolbarOption } from '@datagalaxy/core-ui/graphical';
import { LineageConstants } from '../data/LineageConstants';
import { LineagePathTracker } from './LineagePathTracker';
import { ImpactAnalysisService } from '../../impact-analysis.service';
import { LineageOrientation } from '@datagalaxy/webclient/explorer/data-access';

/** ## Role:
 * Provide action button contents for the lineage left toolbar */
export class LineageActionButtonProvider {
    private readonly optionExpandLevel: IGraphicalToolbarOption;
    private readonly optionSplitRoot: IGraphicalToolbarOption;
    private readonly optionHierarchicalEllipsis: IGraphicalToolbarOption;
    private readonly optionHorizontalFlip: IGraphicalToolbarOption;
    private readonly optionPathTracker: IGraphicalToolbarOption;
    private readonly optionGoldenLinks: IGraphicalToolbarOption;
    private readonly optionShowSiblingDpis: IGraphicalToolbarOption;
    private readonly optionNewLineageSwitchActionDef: IGraphicalToolbarOption;

    public getOptions() {
        return [
            this.optionNewLineageSwitchActionDef,
            { isSeparator: true },
            this.optionExpandLevel,
            this.optionSplitRoot,
            this.optionHierarchicalEllipsis,
            this.optionHorizontalFlip,
            this.optionShowSiblingDpis,
            this.optionPathTracker,
            this.optionGoldenLinks,
        ].filter((o) => o);
    }

    constructor(private params: ILineageActionButtonProviderParams) {
        const expandGlyphs = [
            'glyph-density-low',
            'glyph-density-medium',
            'glyph-density-high',
        ];
        const expandTranslateKeys = [
            'UI.ImpactAnalysis.lineage.ttToolExpandLevel1',
            'UI.ImpactAnalysis.lineage.ttToolExpandLevel2',
            'UI.ImpactAnalysis.lineage.ttToolExpandLevel3',
        ];
        const getExpandLevelSubOption: (
            lvl: number
        ) => IGraphicalToolbarOption = (lvl) => ({
            glyphClass: expandGlyphs[lvl],
            callback: () => params.applyExpandLevel(lvl),
            tooltipTranslateKey: expandTranslateKeys[lvl],
        });

        this.optionNewLineageSwitchActionDef = {
            glyphClass: () => 'glyph-lineage-static dg5-beta-badge',
            tooltipTranslateKey:
                'UI.EntityImpactAnalysis.toolOptionLineageTooltip',
            options: [
                ...this.params.impactAnalysisService.getToolSelectorOption(
                    ImpactAnalysisService.getToolFromLineageOrientation(
                        this.params.getLineageOrientation()
                    )
                ).options,
            ],
        };

        this.optionExpandLevel = {
            glyphClass: () => expandGlyphs[params.getExpandLevel()],
            tooltipTranslateKey: 'UI.ImpactAnalysis.lineage.ttToolExpand',
            options: [0, 1, 2].map(getExpandLevelSubOption),
        };

        this.optionSplitRoot = LineageConstants.behaviour.splitRoots
            .globalDisabled
            ? undefined
            : {
                  glyphClass: () =>
                      params.isSplitRootsActive()
                          ? 'glyph-lineage-ungroup'
                          : 'glyph-lineage-group',
                  tooltipTranslateKey:
                      'UI.EntityImpactAnalysis.toolOptionSplitRootsTooltip',
                  options: [
                      {
                          glyphClass: 'glyph-lineage-group',
                          tooltipTranslateKey:
                              'UI.EntityImpactAnalysis.toolOptionSplitRootsRegroup',
                          callback: () => params.toggleSplitRoots(),
                          isCurrentActive: () => !params.isSplitRootsActive(),
                          logFunctional: () =>
                              params.getActionFunctionalLog('GROUP'),
                      },
                      {
                          glyphClass: 'glyph-lineage-ungroup',
                          tooltipTranslateKey:
                              'UI.EntityImpactAnalysis.toolOptionSplitRoots',
                          callback: () => params.toggleSplitRoots(),
                          isCurrentActive: () => params.isSplitRootsActive(),
                          logFunctional: () =>
                              params.getActionFunctionalLog('UNGROUP'),
                      },
                  ],
              };

        this.optionHierarchicalEllipsis = LineageConstants.behaviour
            .hierarchicalEllipsis.globalDisabled
            ? undefined
            : {
                  glyphClass: () =>
                      params.isAnyHEllipsis()
                          ? 'glyph-shrink-vertical'
                          : 'glyph-expand-vertical',
                  tooltipTranslateKey:
                      'UI.EntityImpactAnalysis.toolOptionHierarchicalEllipsisTooltip',
                  options: [
                      {
                          glyphClass: 'glyph-shrink-vertical',
                          callback: () => params.toggleHEllipsis(),
                          tooltipTranslateKey:
                              'UI.EntityImpactAnalysis.toolOptionHierarchicalEllipsis',
                          isCurrentActive: () => params.isAnyHEllipsis(),
                          logFunctional:
                              params.getActionFunctionalLog('ELLIPSIS'),
                      },
                      {
                          glyphClass: 'glyph-expand-vertical',
                          callback: () => params.toggleHEllipsis(),
                          tooltipTranslateKey:
                              'UI.EntityImpactAnalysis.toolOptionNoHierarchicalEllipsis',
                          isCurrentActive: () => !params.isAnyHEllipsis(),
                          logFunctional:
                              params.getActionFunctionalLog('NO_ELLIPSIS'),
                      },
                  ],
              };

        this.optionHorizontalFlip = {
            glyphClass: 'glyph-flip-horizontal',
            callback: () => params.applyHorizontalFlip(),
            tooltipTranslateKey:
                'UI.EntityImpactAnalysis.toolOptionHorizontalFlip',
            logFunctional: params.getActionFunctionalLog('HORIZONTAL_FLIP'),
        };

        const isPinned = (trackId: number) =>
            params.pathTracker.isPinned(trackId);
        this.optionPathTracker = {
            glyphClass: () =>
                `glyph icon-path-tracker blue-and-red ${
                    isPinned(1) ? '' : 'blue-unpinned'
                } ${isPinned(2) ? '' : 'red-unpinned'}`,
            tooltipTranslateKey:
                'UI.ImpactAnalysis.lineage.pathTracker.pathTracker',
            hidden: () => params.isNoPathTracker(),
            dataTrackerId: 'lineage-path-tracker',
            options: [1, 2].map((trackId) => ({
                glyphClass: () =>
                    `glyph icon-path-tracker ${trackId == 2 ? 'red' : 'blue'} ${
                        isPinned(trackId) ? 'pinned' : ''
                    }`,
                callback: () => params.pathTracker.unpinTrack(trackId),
                tooltipTranslateKey: () =>
                    params.pathTracker.getUnpinTooltip(trackId),
                disabled: () => !isPinned(trackId),
                //logFunctional: // done via LineagePathTracker.onPinUnpin,
            })),
        };

        this.optionGoldenLinks = {
            glyphClass: () =>
                `glyph-golden-link golden-link-option glyph ${
                    params.areGoldenLinksVisible() ? '' : 'inactive'
                }`,
            tooltipTranslateKey: () =>
                params.isAnyGoldenLink()
                    ? ''
                    : 'UI.ImpactAnalysis.lineage.goldenLinkOptionBtn.disabled',
            disabled: () => !params.isAnyGoldenLink(),
            dataTrackerId: 'lineage-golden-links',
            options: [
                {
                    glyphClass: 'glyph-golden-link golden-link-option glyph',
                    tooltipTranslateKey:
                        'UI.ImpactAnalysis.lineage.goldenLinkOptionBtn.show',
                    callback: () => params.toggleGoldenLinks(),
                    isCurrentActive: () => params.areGoldenLinksVisible(),
                    logFunctional: params.getActionFunctionalLog(
                        'DISPLAY_GOLDEN_LINK'
                    ),
                },
                {
                    glyphClass: `glyph-golden-link golden-link-option glyph inactive`,
                    tooltipTranslateKey:
                        'UI.ImpactAnalysis.lineage.goldenLinkOptionBtn.hide',
                    callback: () => params.toggleGoldenLinks(),
                    isCurrentActive: () => !params.areGoldenLinksVisible(),
                    logFunctional:
                        params.getActionFunctionalLog('HIDE_GOLDEN_LINK'),
                },
            ],
        };

        this.optionShowSiblingDpis = {
            glyphClass: () =>
                params.areSiblingDpisVisible()
                    ? 'glyph-full-links'
                    : 'glyph-light-links',
            tooltipTranslateKey:
                'UI.ImpactAnalysis.lineage.directLinksOptionBtn.tooltip',
            dataTrackerId: 'Lineage_all_simplification',
            options: [
                {
                    glyphClass: 'glyph-full-links',
                    tooltipTranslateKey:
                        'UI.ImpactAnalysis.lineage.directLinksOptionBtn.all',
                    callback: () => params.toggleSiblingDpis(),
                    isCurrentActive: () => params.areSiblingDpisVisible(),
                    logFunctional:
                        params.getActionFunctionalLog('LOAD_DPI_SIBLINGS'),
                    dataTrackerId: 'Lineage_all_simplification_withDPI',
                },
                {
                    glyphClass: 'glyph-light-links',
                    tooltipTranslateKey:
                        'UI.ImpactAnalysis.lineage.directLinksOptionBtn.directOnly',
                    callback: () => params.toggleSiblingDpis(),
                    isCurrentActive: () => !params.areSiblingDpisVisible(),
                    logFunctional:
                        params.getActionFunctionalLog('HIDE_DPI_SIBLINGS'),
                    dataTrackerId: 'Lineage_all_simplification_withoutDPI',
                },
            ],
        };
    }
}

export interface ILineageActionButtonProviderParams {
    impactAnalysisService: ImpactAnalysisService;
    getLineageOrientation: () => LineageOrientation;
    getActionFunctionalLog: (actionType: string) => string;

    getExpandLevel: () => number;
    applyExpandLevel: (level: number) => void;

    isSplitRootsActive: () => boolean;
    toggleSplitRoots: () => void;

    isAnyHEllipsis: () => boolean;
    toggleHEllipsis: () => void;

    applyHorizontalFlip: () => void;

    pathTracker: LineagePathTracker;
    isNoPathTracker: () => boolean;

    isAnyGoldenLink: () => boolean;
    areGoldenLinksVisible: () => boolean;
    toggleGoldenLinks: () => void;

    areSiblingDpisVisible: () => boolean;
    toggleSiblingDpis: () => void;
}

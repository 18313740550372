import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { DxyBaseModalComponent } from '@datagalaxy/ui/dialog';
import {
    GlossaryGenerateFromSourceModalInput,
    GlossaryGenerateFromSourceModalOutput,
    IGlossaryGenerationForm,
    IModuleTypeInfo,
} from './glossary-generate-from-source-modal.types';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IEntitySelectorData } from '../../shared/entitySelector/entity-selector.types';
import {
    AsyncValidatorFn,
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
} from '@angular/forms';
import { EntityType } from '@datagalaxy/dg-object-model';
import { IRadioOption } from '@datagalaxy/core-ui/fields';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../../services/user.service';
import { withLoading } from '@datagalaxy/core-ui';
import { GlossaryService } from '../glossary.service';
import { ToasterService } from '../../services/toaster.service';
import { ISpaceVersionSelectedEvent } from '../../space/space-version-selector.types';
import { EntityService } from '../../shared/entity/services/entity.service';
import { SpaceApiService } from '../../space/services/space-api.service';
import { from, map, of, switchMap, timer } from 'rxjs';
import { Space } from '@datagalaxy/webclient/workspace/data-access';
import { PreCreateEntityStatus } from '@datagalaxy/webclient/attribute/data-access';
import { ISpaceIdentifier } from '@datagalaxy/webclient/workspace/domain';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';
import { DgModule } from '@datagalaxy/shared/dg-module/domain';
import { ModuleStore } from '../../module/module.store';

/**
 * ## Role
 * Modal form to generate glossary items for an universe based
 * on sources (Catalog or Usage)
 */
@Component({
    selector: 'app-glossary-generate-from-source-modal',
    templateUrl: './glossary-generate-from-source-modal.component.html',
    styleUrls: ['./glossary-generate-from-source-modal.component.scss'],
})
export class GlossaryGenerateFromSourceModalComponent
    extends DxyBaseModalComponent<
        GlossaryGenerateFromSourceModalInput,
        GlossaryGenerateFromSourceModalOutput
    >
    implements OnInit
{
    protected sourceCountLimit: number;
    protected showHelp: boolean;
    protected skipThisStep: boolean;
    protected selectedModule: IModuleTypeInfo;
    protected sourceOptions: IEntitySelectorData;
    protected sourceHint: string;
    protected targetExistingOptions: IRadioOption<boolean>[] = [
        {
            value: true,
            labelKey: this.getFormTranslateKey(
                'targetExisting.options.existing'
            ),
        },
        {
            value: false,
            labelKey: this.getFormTranslateKey('targetExisting.options.new'),
        },
    ];
    protected targetOptions: IEntitySelectorData;
    protected formGroup: FormGroup<IGlossaryGenerationForm>;
    protected modules: IModuleTypeInfo[];
    protected spaceIdr: ISpaceIdentifier;
    protected modulesEntitiesCount: Map<DgModule, number>;
    private space: Space;
    private sourceCount: number;

    protected get showSpaceSelector() {
        return !this.data.spaceIdr;
    }
    protected get isSelectedModuleEmpty() {
        return !this.modulesEntitiesCount?.get(this.selectedModule?.moduleType);
    }
    protected get showSourceError() {
        return (
            this.isSelectedModuleEmpty && !this.loadingValue && this.spaceIdr
        );
    }
    protected get showWriteAccessError() {
        return (
            this.showSpaceSelector &&
            !!this.space &&
            !this.space?.GlossarySecurityData.HasCreateAccess
        );
    }
    protected get showSourceLimitWarning() {
        return this.sourceCount >= this.sourceCountLimit;
    }
    protected get showSourceSelector() {
        return (
            this.spaceIdr && !this.showSourceError && !this.showWriteAccessError
        );
    }
    protected get hasSelectedSources() {
        return this.formGroup.value.sources?.length;
    }
    protected get showUniverseSelect() {
        return this.hasSelectedSources && this.useExistingUniverse;
    }
    protected get showUniverseInput() {
        return this.hasSelectedSources && !this.useExistingUniverse;
    }
    protected get helpImage() {
        const suffix =
            this.data.selectedDgModule === DgModule.Glossary
                ? 'choice'
                : this.data.selectedDgModule === DgModule.Catalog
                ? 'catalog'
                : 'usage';
        return `/images/glossary-generation/glossary-generation-placeholder-${suffix}.svg`;
    }
    protected get selectedModuleImage() {
        return `/images/glossary-generation/${this.selectedModule.imageFilename}`;
    }
    protected get sourcesErrorMessage() {
        const isEmptyModule = this.isSelectedModuleEmpty;
        const prefix =
            'UI.Glossary.components.generateFromSourceModal.form.source.errorMessage';
        const moduleStr = DgModule[this.selectedModule.moduleType];
        return isEmptyModule ? `${prefix}.${moduleStr}` : '';
    }
    /** This can be removed when freshdesk online help is implemented */
    protected get showOnlineHelp() {
        return false;
    }

    protected get universeAlreadyExistsErrorMessage(): string {
        const targetUniverse = this.formGroup.controls.targetUniverse;
        if (targetUniverse?.valid) {
            return undefined;
        }

        return targetUniverse.errors?.nameAlreadyExists;
    }

    private get useExistingUniverse() {
        return this.formGroup.value.useExistingUniverse;
    }
    private get hasUniverseEntities() {
        return !!this.modulesEntitiesCount?.get(DgModule.Glossary);
    }

    constructor(
        private fb: FormBuilder,
        private translate: TranslateService,
        private userService: UserService,
        private glossaryService: GlossaryService,
        private toasterService: ToasterService,
        private entityService: EntityService,
        private spaceApiService: SpaceApiService,
        private moduleStore: ModuleStore,
        dialogRef: MatDialogRef<
            GlossaryGenerateFromSourceModalComponent,
            GlossaryGenerateFromSourceModalOutput
        >,
        @Inject(MAT_DIALOG_DATA) data: GlossaryGenerateFromSourceModalInput,
        private cd: ChangeDetectorRef
    ) {
        super(dialogRef, data);
        this.skipThisStep = this.data.skipHelp;
        this.spaceIdr = this.data.spaceIdr;
        this.modulesEntitiesCount = this.data.modulesEntitiesCount;
        this.showHelp = !this.data.skipHelp;
    }

    ngOnInit() {
        this.initAsync();
    }

    protected async goNextStep() {
        this.showHelp = false;
        if (this.skipThisStep === this.data.skipHelp) {
            return;
        }
        await this.userService.setUserSettingValue(
            'glossary-auto-generation',
            'skip-help',
            this.skipThisStep ? 'true' : 'false'
        );
    }
    protected goPreviousStep() {
        this.showHelp = true;
    }

    protected getFormTranslateKey(key: string) {
        return `UI.Glossary.components.generateFromSourceModal.form.${key}`;
    }

    @withLoading()
    protected async onSpaceVersionSelected(event: ISpaceVersionSelectedEvent) {
        const spaceIdr = (this.spaceIdr = event.spaceIdr);
        this.modulesEntitiesCount =
            await this.entityService.getModulesEntitiesCountForGlossaryAutoGeneration(
                spaceIdr
            );
        this.sourceOptions = { ...this.sourceOptions, spaceIdr: spaceIdr };
        this.targetOptions = { ...this.targetOptions, spaceIdr: this.spaceIdr };
        this.targetExistingOptions[0].disabled = !this.hasUniverseEntities;
        this.formGroup.patchValue({
            sources: [],
            useExistingUniverse: this.hasUniverseEntities,
            targetUniverse: null,
        });
        this.space = await this.spaceApiService.getSpace(spaceIdr);
    }

    protected selectModuleType(moduleType: IModuleTypeInfo) {
        if (moduleType == this.selectedModule) {
            return;
        }
        this.formGroup.patchValue({ sources: [] });
        this.setSourceOptions(moduleType);
    }

    protected isModuleDisabled(moduleType: IModuleTypeInfo) {
        return !this.modulesEntitiesCount?.get(moduleType.moduleType);
    }

    protected async onSourcesChange(sources: EntityItem[]) {
        if (!sources?.length) {
            this.sourceCount = 0;
            this.sourceHint = '';
            return;
        }
        const sourcesIds = sources.map((entity) => entity.ReferenceId);
        const res = await this.glossaryService.preGenerateGlossary(sourcesIds);
        const count = (this.sourceCount = res?.TotalObjectCount);
        this.sourceCountLimit = res?.DataScienceMaxSupportedEntriesSize;
        this.sourceHint = count
            ? this.translate.instant(this.getFormTranslateKey('source.hint'), {
                  count,
              })
            : null;
    }

    protected onTargetTypeChange() {
        this.formGroup.patchValue({ targetUniverse: null });
    }

    @withLoading()
    protected async onSubmit() {
        const { sources, targetUniverse } = this.formGroup.value;
        const sourceIds = sources?.map((s) => s.ReferenceId);
        const targetName =
            typeof targetUniverse === 'string' ? targetUniverse : null;
        const targetId =
            typeof targetUniverse != 'string'
                ? targetUniverse.ReferenceId
                : null;
        await this.glossaryService.generateGlossary(
            sourceIds,
            targetId,
            targetName
        );

        this.toasterService.infoToast({
            titleKey:
                'UI.Glossary.components.generateFromSourceModal.successToaster.title',
            messageKey:
                'UI.Glossary.components.generateFromSourceModal.successToaster.message',
        });

        this.onCloseSubmit();
    }

    private async initAsync() {
        this.initSourceModules();

        this.formGroup = this.fb.group<IGlossaryGenerationForm>({
            sources: new FormControl(this.data.sources ?? []),
            useExistingUniverse: new FormControl(this.hasUniverseEntities),
            targetUniverse: new FormControl(null, {
                validators: [Validators.required],
                asyncValidators: this.universeNameValidator(),
            }),
        });

        const selectedOption = this.getDefaultSelectedModule();
        this.targetExistingOptions[0].disabled = !this.hasUniverseEntities;
        this.setSourceOptions(selectedOption);
        this.targetOptions = {
            spaceIdr: this.spaceIdr,
            includedEntityTypes: [EntityType.Universe],
        };
        await this.onSourcesChange(this.data.sources);
    }

    private initSourceModules() {
        const moduleInfos: IModuleTypeInfo[] = [];
        if (this.moduleStore.hasAccess(DgModule.Catalog)) {
            moduleInfos.push({
                moduleType: DgModule.Catalog,
                labelKey: this.getFormTranslateKey('source.catalog'),
                imageFilename: 'glossary-source-catalog.svg',
            });
        }
        if (this.moduleStore.hasAccess(DgModule.Usage)) {
            moduleInfos.push({
                moduleType: DgModule.Usage,
                labelKey: this.getFormTranslateKey('source.usage'),
                imageFilename: 'glossary-source-usage.svg',
            });
        }
        this.modules = moduleInfos;
    }

    private setSourceOptions(moduleType: IModuleTypeInfo) {
        this.selectedModule = moduleType;
        this.sourceOptions = {
            includedEntityTypes:
                moduleType.moduleType === DgModule.Usage
                    ? [EntityType.Application]
                    : [
                          EntityType.RelationalModel,
                          EntityType.NonRelationalModel,
                          EntityType.NoSqlModel,
                          EntityType.TagBase,
                      ],
            spaceIdr: this.spaceIdr,
        };
    }

    private getDefaultSelectedModule() {
        const selectedModule = this.data.selectedDgModule;
        if (selectedModule === DgModule.Glossary) {
            return this.modules.find((option) =>
                this.modulesEntitiesCount?.get(option.moduleType)
            );
        }
        const isModuleEmpty = !this.modulesEntitiesCount?.get(selectedModule);

        return this.modules.find(
            (option) =>
                (option.moduleType == selectedModule && !isModuleEmpty) ||
                (isModuleEmpty && option.moduleType != selectedModule)
        );
    }

    private async isUniverseNameAlreadyUsed(universeName: string) {
        const result = await this.entityService.preCreateEntity(
            this.spaceIdr.spaceId,
            EntityType.Universe,
            universeName,
            false,
            this.spaceIdr.versionId
        );
        return result.Status == PreCreateEntityStatus.Exists;
    }

    private universeNameValidator(): AsyncValidatorFn {
        return (universeNameControl: FormControl<string>) => {
            if (this.showUniverseSelect) {
                return of(null);
            }

            this.cd.detectChanges();

            return timer(500).pipe(
                switchMap(() =>
                    from(
                        this.isUniverseNameAlreadyUsed(
                            universeNameControl.value
                        )
                    )
                ),
                map((nameExists) => {
                    if (!nameExists) {
                        return null;
                    }
                    const errorMessage = `${
                        universeNameControl.value
                    } ${this.translate.instant(
                        'UI.EntityCreateModal.preCreateStatus.Exists'
                    )}`;
                    return { nameAlreadyExists: errorMessage };
                })
            );
        };
    }
}

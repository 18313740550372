<div *ngIf="enabled$ | async" class="language-selector-container">
    <dxy-spinner
        *ngIf="loading$ | async; else content"
        class="xs no-margin"
    ></dxy-spinner>

    <ng-template #content>
        <button
            dxyIconButton
            type="button"
            color="secondary"
            [matMenuTriggerFor]="menu"
            [matTooltip]="'Multilingual.changeMyLanguage' | translate"
        >
            <dxy-language-icon
                [languageCode]="selectedLanguageCode"
            ></dxy-language-icon>
        </button>
        <mat-menu #menu="matMenu" class="dg5-mat-menu">
            <button
                *ngFor="let languageCode of activatedLanguagesCodes$ | async"
                mat-menu-item
                [class.active]="languageCode === selectedLanguageCode"
                (click)="selectLanguage(languageCode)"
            >
                <dxy-language-card
                    [languageCode]="languageCode"
                ></dxy-language-card>
                <span
                    *ngIf="(sourceLanguageCode$ | async) === languageCode"
                    class="dg5-badge"
                    translate
                >
                    Multilingual.Administration.sourceLanguage
                </span>
            </button>
            <a
                *ngIf="administrationLinkVisible"
                mat-menu-item
                [uiSref]="adminRouteStateName"
            >
                <span translate>Multilingual.manageLanguages</span>
            </a>
        </mat-menu>
    </ng-template>
</div>

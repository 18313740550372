import { NgModule } from '@angular/core';
import { DxyDataTypeModelSettingsComponent } from './dxy-data-type-model-settings/dxy-data-type-model-settings.component';
import { DxyDataTypeGridComponent } from './dxy-data-type-grid/dxy-data-type-grid.component';
import { DxyDataTypeMappingGridComponent } from './dxy-data-type-mapping-grid/dxy-data-type-mapping-grid.component';
import { DxyDataTypeAdminSettingsComponent } from './dxy-data-type-admin-settings/dxy-data-type-admin-settings.component';
import { AngularSplitModule } from 'angular-split';
import { DxyNavigationModule } from '../../navigation/DxyNavigationModule';
import { SharedModule } from '../../shared/shared.module';
import { FieldsModule } from '../../shared/fields/fields.module';

@NgModule({
    imports: [
        SharedModule,
        FieldsModule,
        DxyNavigationModule,
        AngularSplitModule,
    ],
    declarations: [
        DxyDataTypeMappingGridComponent,
        DxyDataTypeGridComponent,
        DxyDataTypeModelSettingsComponent,
        DxyDataTypeAdminSettingsComponent,
    ],
    exports: [DxyDataTypeAdminSettingsComponent],
})
export class DxyModelerDataTypesModule {}

import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { IFunctionalEvent } from '@datagalaxy/core-ui';
import {
    CheckMessageData,
    WizardContext,
} from '../../../shared/wizard-context';
import { DxyBaseComponent } from '@datagalaxy/ui/core';

@Component({
    selector: 'dxy-wizard-layout',
    templateUrl: './dxy-wizard-layout.component.html',
    styleUrls: ['dxy-wizard-layout.component.scss'],
})
export class DxyWizardLayoutComponent
    extends DxyBaseComponent
    implements OnInit, AfterViewInit
{
    @Input() wizardContext: WizardContext;
    @Output() readonly onLogFunctional = new EventEmitter<IFunctionalEvent>();

    public get ctx() {
        return this.wizardContext;
    }

    public get checkboxValueLocal() {
        return this.ctx.checkboxValue();
    }
    public set checkboxValueLocal(value) {
        this.ctx.toggleCheckbox(value);
    }

    public get hasCheckIcon() {
        return !!this.ctx.getCheckIcon();
    }

    public get localCheckMessageData(): CheckMessageData {
        const newCheckMessageData = this.ctx.checkMessageData();
        const isEqual = CheckMessageData.equals(
            this.checkMessageData,
            newCheckMessageData
        );
        return isEqual
            ? this.checkMessageData
            : (this.checkMessageData = newCheckMessageData);
    }

    private checkMessageData: CheckMessageData;

    constructor() {
        super();
    }

    public ngOnInit() {
        this.log('ngOnInit-done', this.wizardContext);
    }
    public ngAfterViewInit() {
        this.log('ngAfterViewInit-done');
    }

    public getCheckTooltip() {
        return this.ctx.getCheckTooltip?.() ?? '';
    }
    public getCheckIconClass() {
        return this.ctx.getCheckIcon?.();
    }
    public getTestIconClass() {
        return this.ctx.getTestButtonIcon?.();
    }
    public getTestBtnTooltip() {
        return this.ctx.getTestBtnTooltip?.();
    }
    public getTestErrorMessage() {
        return this.ctx.getTestErrorMessage?.();
    }
    public getNextButtonTooltip() {
        return this.ctx.getNextButtonTooltip?.() ?? '';
    }
    public getCheckboxMessage() {
        return this.ctx.checkboxMessage?.() ?? '';
    }
    public getIsCheckBtnDisabled() {
        return this.ctx.isCheckBtnDisabled?.();
    }
    public getIsTestDisabled() {
        return this.ctx.isTestDisabled?.();
    }
    public getIsNextDisabled() {
        return this.ctx.isNextDisabled?.();
    }
    public getIsRestartDisabled() {
        return this.ctx.isRestartDisabled?.();
    }

    public onCheckBtnClick() {
        this.ctx.checkBtnClick?.();
    }
    public onRestartClick() {
        this.ctx.restart?.();
    }
    public onSaveClick() {
        this.ctx.save();
    }
    public onTestClick() {
        this.ctx.test?.();
    }

    public onNextClick(event: Event) {
        const featureCode = this.ctx.getFeatureCodeNext?.() ?? '';
        if (featureCode) {
            this.onLogFunctional.emit({
                text: featureCode,
                origin: event.target as HTMLElement,
            });
        }
        this.ctx.next();
    }
}

import { NgModule } from '@angular/core';
import { DxyApiDocModalComponent } from './dxy-api-doc-modal/dxy-api-doc-modal.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
    imports: [SharedModule],
    declarations: [DxyApiDocModalComponent],
    exports: [DxyApiDocModalComponent],
})
export class DxyApiDocModule {}

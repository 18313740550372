import { NgModule } from '@angular/core';
import { DxyModuleMainComponent } from './dxy-module-main/dxy-module-main.component';
import { DxyModuleRootComponent } from './dxy-module-root/dxy-module-root.component';
import { AngularSplitModule } from 'angular-split';
import { AttributeFilterModule } from '../shared/attribute/attribute-filter/AttributeFilterModule';
import { DxyFilterModule } from '../shared/filter/DxyFilterModule';
import { SharedModule } from '../shared/shared.module';

@NgModule({
    imports: [
        SharedModule,
        DxyFilterModule,
        AngularSplitModule,
        AttributeFilterModule,
    ],
    declarations: [DxyModuleMainComponent, DxyModuleRootComponent],
})
export class DxyModuleMainModule {}

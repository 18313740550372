import * as moment from 'moment';
import { BaseService } from '@datagalaxy/core-ui';
import { Injectable } from '@angular/core';
import { DataUtil } from '../shared/util/DataUtil';
import { IServerTimeData } from '@datagalaxy/webclient/monitoring/data-access';

/**
    #Archi-service-leaf (Does not reference any other app service)
*/
@Injectable({ providedIn: 'root' })
export class ServerTimeService extends BaseService {
    public constructor() {
        super();
    }

    private serverDeltaTime = 0;

    public initTimeReference(serverTimeData: IServerTimeData) {
        const startTime = Date.now();
        const startTimeUtc = moment.utc(startTime).valueOf();
        const serverUtcTime = moment(
            serverTimeData.ServerCurrentTimeUtc
        ).valueOf();
        const endTime = Date.now();
        const requestTime = endTime - startTime;
        this.serverDeltaTime = serverUtcTime - startTimeUtc - requestTime / 2;
    }

    public getServerNowTimeString(): string {
        const time = Date.now() - this.serverDeltaTime;
        return moment(time).utc().toISOString();
    }

    public getServerTimeFromNowString(
        timeString: string,
        forceFromNow: boolean = false
    ) {
        const localTime =
            <any>moment(timeString).valueOf() - this.serverDeltaTime;
        if (
            forceFromNow ||
            (!forceFromNow && Date.now() - localTime <= 7200000)
        ) {
            return moment(localTime).fromNow();
        } else {
            return DataUtil.getFormattedDateTime(moment(localTime));
        }
    }

    public getServerDateTimeAsMoment(timeString: string) {
        const localTime = moment(timeString).valueOf() - this.serverDeltaTime;
        return localTime > 0 ? moment(localTime) : null;
    }

    public getServerTimeString(timeString: string) {
        const m = this.getServerDateTimeAsMoment(timeString);
        return m ? DataUtil.getFormattedDateTime(m) : null;
    }

    public getServerDateString(timeString: string) {
        const m = this.getServerDateTimeAsMoment(timeString);
        return m ? DataUtil.getFormattedDate(m) : null;
    }
}

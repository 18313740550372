import {
    ChangeDetectionStrategy,
    Component,
    ViewEncapsulation,
} from '@angular/core';
import { AttributeTranslationBaseInput } from '../attribute-translation-base-input';

@Component({
    selector: 'dxy-attribute-rich-text-translation-input',
    templateUrl: './attribute-rich-text-translation-input.component.html',
    styleUrls: ['./attribute-rich-text-translation-input.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttributeRichTextTranslationInputComponent extends AttributeTranslationBaseInput {
    constructor() {
        super();
    }
}

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnInit,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PrimaryKeyGridData } from '../PrimaryKeyGridData';
import { EntitySecurityService } from '../../../shared/entity/services/entity-security.service';
import { ModelerService } from '../../services/modeler.service';
import { Project } from '@datagalaxy/webclient/workspace/data-access';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';
import {
    GridCellType,
    GridComponent,
    GridConfig,
    TColDef,
} from '@datagalaxy/ui/grid';
import { TextAndToolsCellComponent } from '../../../shared/shared-ui/cells/text-and-tools-cell/text-and-tools-cell.component';
import { IActionOption } from '@datagalaxy/core-ui';

@Component({
    selector: 'dxy-primary-key-grid',
    templateUrl: 'dxy-primary-key-grid.component.html',
    styleUrls: ['dxy-primary-key-grid.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [GridComponent],
})
export class DxyPrimaryKeyGridComponent
    extends DxyBaseComponent
    implements OnInit
{
    @Input() currentRoot: Project;
    @Input() entityData: EntityItem;
    @Input() modelId: string;

    protected gridConfig: GridConfig<PrimaryKeyGridData> = {
        getItemId: (item) => item.primaryKeyTechnicalName,
        horizontalScroll: true,
    };

    protected cols: TColDef<PrimaryKeyGridData>[] = [
        {
            id: 'primaryKeyTechnicalName',
            headerLabel: this.getLabel('lblPrimaryKeyTechnicalName'),
            type: GridCellType.custom,
            customCellComponent: TextAndToolsCellComponent,
            getInputs: (item) =>
                ({
                    text: item.primaryKeyTechnicalName,
                    actions: this.getActions(item),
                } as Partial<TextAndToolsCellComponent<PrimaryKeyGridData>>),
            minWidth: 200,
            sortable: true,
            getSortableValue: (item) => item.primaryKeyTechnicalName,
        },
        {
            id: 'primaryKeyStructureType',
            headerLabel: this.getLabel('lblStructureType'),
            type: GridCellType.text,
            getValue: (item) =>
                this.translate.instant(item.primaryKeyStructureType),
            sortable: true,
        },
        {
            id: 'tableTechnicalName',
            headerLabel: this.getLabel('lblTableTechnicalName'),
            type: GridCellType.text,
            getValue: (item) => item.tableTechnicalName,
            sortable: true,
        },
        {
            id: 'columnTechnicalName',
            headerLabel: this.getLabel('lblColumnTechnicalName'),
            type: GridCellType.text,
            getValue: (item) => item.columnTechnicalName,
            sortable: true,
        },
        {
            id: 'primaryKeyColumnOrder',
            headerLabel: this.getLabel('lblPrimaryKeyColumnOrder'),
            type: GridCellType.text,
            getValue: (item) => item.primaryKeyColumnOrder?.toString(),
            sortable: true,
            getSortableValue: (item) => item.primaryKeyColumnOrder,
        },
        {
            id: 'tableColumnOrder',
            headerLabel: this.getLabel('lblTableColumnOrder'),
            type: GridCellType.text,
            getValue: (item) => item.tableColumnOrder?.toString(),
            sortable: true,
            getSortableValue: (item) => item.tableColumnOrder,
        },
    ];

    protected rows: PrimaryKeyGridData[] = [];

    constructor(
        private translate: TranslateService,
        private entitySecurityService: EntitySecurityService,
        private modelerService: ModelerService,
        private cd: ChangeDetectorRef
    ) {
        super();
    }

    ngOnInit() {
        this.refreshGrid();

        super.registerSubscription(
            this.modelerService.subscribeRealTimeModelerUpdate(() =>
                this.refreshGrid()
            )
        );
    }

    private getActions(
        row: PrimaryKeyGridData
    ): IActionOption<PrimaryKeyGridData>[] {
        return [
            {
                glyphClass: 'glyph-edit',
                tooltipText: this.translate.instant(
                    'UI.TechDictionary.ttEditPk'
                ),
                callback: async () => this.onEditPrimaryKey(row),
                disabled: () => !this.isModelEditEnabled(),
            },
        ];
    }

    private getLabel(suffix: string) {
        return this.translate.instant(`UI.Modeler.PrimaryKeyGrid.${suffix}`);
    }

    private isModelEditEnabled() {
        return this.entitySecurityService.hasWriteAccessSecurity(
            this.entityData?.SecurityData
        );
    }

    private refreshGrid() {
        this.rows = [
            ...this.modelerService.getPrimaryKeyGridData(this.modelId),
        ];
        this.cd.detectChanges();
    }

    private async onEditPrimaryKey(primaryKeyGridData: PrimaryKeyGridData) {
        this.log('onEditPrimaryKey', primaryKeyGridData);
        await this.modelerService.openPrimaryKeySettingsModal(
            primaryKeyGridData.tableId
        );
        this.refreshGrid();
    }
}

import { CoreEventsService, IActionOption } from '@datagalaxy/core-ui';
import { DpMappingBurgerMenuActionProvider } from '../helpers/DpMappingBurgerMenuActionProvider';
import {
    AfterViewInit,
    Component,
    ElementRef,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { DpMappingUiService } from '../dpMappingUi.service';
import { DomUtil } from '@datagalaxy/core-util';
import {
    EntityGridRowClickAction,
    IEntityGridOptions,
} from '../../../shared/entity/entity-grid/EntityGridOptions';
import {
    EntityType,
    ObjectLinkType,
    ServerType,
} from '@datagalaxy/dg-object-model';
import { AngularSplitService } from '../../../services/angular-split.service';
import { GraphicalContainer } from '../models/GraphicalContainer';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { userSettingsValues } from '@datagalaxy/webclient/user/domain';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';

/**
 * ## Role
 * Main component for Data-Processing Mapping UI
 */
@Component({
    selector: 'app-dp-mapping',
    templateUrl: 'dp-mapping.component.html',
})
export class DpMappingComponent
    extends DxyBaseComponent
    implements OnInit, AfterViewInit, OnDestroy
{
    protected readonly splitterPersistenceId =
        userSettingsValues.angularSplit.routes.dpMapping;
    protected egOptions: IEntityGridOptions;
    protected get gridServerType(): ServerType {
        return ServerType.Model;
    }
    protected get data() {
        return this.dpMappingUiService.data;
    }
    protected get spaceIdr() {
        return this.dpMappingUiService.spaceIdr;
    }
    protected get isEditEnabled() {
        return this.hasWriteAccess;
    }
    protected get isTooltipEnabled() {
        return this.dpMappingUiService.tooltipsEnabled;
    }
    protected get serviceDebug() {
        return this.dpMappingUiService.debug;
    }

    private get hasWriteAccess() {
        return this.dpMappingUiService.hasWriteAccess;
    }

    constructor(
        private elementRef: ElementRef<HTMLElement>,
        private coreEventsService: CoreEventsService,
        private dpMappingUiService: DpMappingUiService,
        private angularSplitService: AngularSplitService
    ) {
        super();
    }

    ngOnInit() {
        this.dpMappingUiService.preInitUI(
            DomUtil.getElement(this.elementRef, '.graphical-surface')
        );
        this.initGrid();
        super.subscribe(this.coreEventsService.mainViewResize$, () =>
            this.onGraphicalAreaResized()
        );
    }
    ngAfterViewInit() {
        setTimeout(() => this.dpMappingUiService.postInitUI(), 500);
    }
    ngOnDestroy() {
        super.ngOnDestroy();
        this.dpMappingUiService.onSurfaceDestroy();
    }

    //#region html bound

    public getContainerId(index: number, container: GraphicalContainer) {
        return container.uniqueId;
    }
    public getAreaSize(areaIndex: number, defaultValue: number) {
        return this.angularSplitService.getAreaSize(
            this.splitterPersistenceId,
            areaIndex,
            defaultValue
        );
    }

    public onGraphicalAreaResized() {
        this.dpMappingUiService.onGraphicalAreaResized();
    }
    public onLayoutColumnScrolled() {
        this.dpMappingUiService.onLayoutColumnScrolled();
    }
    public async onDPICreateButtonClick() {
        await this.dpMappingUiService.createDPI();
    }

    //#endregion

    private initGrid() {
        const burgerMenuProvider = new DpMappingBurgerMenuActionProvider(
            this.dpMappingUiService,
            (entity, isInput) => this.isUsedInList(entity, isInput)
        );
        const options: IActionOption<EntityItem>[] = [
            {
                glyphClass: 'glyph-direction-before',
                tooltipTranslateKey: (data: EntityItem) =>
                    `UI.DataProcessing.mapping.btnAddToInput${
                        this.isUsedInList(data, true) ? 'Disabled' : ''
                    }`,
                callback: (data: EntityItem) =>
                    this.dpMappingUiService.addItem(
                        data,
                        ObjectLinkType.HasInput
                    ),
                disabled: (data: EntityItem) =>
                    !this.hasWriteAccess || this.isUsedInList(data, true),
                hidden: (data: EntityItem) => this.isItemFilledTable(data),
            },
            {
                burgerMenuGlyphClass: 'glyph-direction-before',
                tooltipTranslateKey: () =>
                    'UI.DataProcessing.mapping.btnAddToInput',
                burgerMenuManagerContext: ObjectLinkType.HasInput,
                burgerMenuProvider,
                hidden: (data: EntityItem) => !this.isItemFilledTable(data),
            },
            {
                glyphClass: 'glyph-direction-after',
                tooltipTranslateKey: (data: EntityItem) =>
                    `UI.DataProcessing.mapping.btnAddToOutput${
                        this.isUsedInList(data) ? 'Disabled' : ''
                    }`,
                callback: (data: EntityItem) =>
                    this.dpMappingUiService.addItem(
                        data,
                        ObjectLinkType.HasOutput
                    ),
                disabled: (data: EntityItem) =>
                    !this.hasWriteAccess || this.isUsedInList(data),
                hidden: (data: EntityItem) => this.isItemFilledTable(data),
            },
            {
                burgerMenuGlyphClass: 'glyph-direction-after',
                tooltipTranslateKey: () =>
                    'UI.DataProcessing.mapping.btnAddToOutput',
                burgerMenuManagerContext: ObjectLinkType.HasOutput,
                burgerMenuProvider,
                hidden: (data: EntityItem) => !this.isItemFilledTable(data),
            },
        ];

        this.egOptions = {
            isSingleColumn: true,
            rowClickAction: EntityGridRowClickAction.none,
            noNavLink: true,
            noTotalCountMessage: true,
            showSearchBox: true,
            showHierarchicalSwitch: true,
            isHierarchical: true,
            isHierarchyToggleLocal: true,
            preserveCurrentSingleSelectedOnEntityCreated: false,
            optionalActions: options,
        };
    }

    private isUsedInList(entity: EntityItem, isInput?: boolean) {
        const listData = isInput
            ? this.data.inputLinksData
            : this.data.outputLinksData;

        return listData.some(
            (link) =>
                (link.LinkedDataReferenceId === entity.ReferenceId &&
                    !link.Columns?.length) ||
                (entity.EntityType === EntityType.Column &&
                    link.Columns.some(
                        (column) => column.ColumnId === entity.ReferenceId
                    ))
        );
    }

    private isItemFilledTable(entityItem: EntityItem) {
        return (
            entityItem.ServerType == ServerType.Table &&
            entityItem.HasContextualAllLevelChildrenCount
        );
    }
}

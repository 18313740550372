import { NgModule } from '@angular/core';
import { DxyNavigationSpaceListComponent } from './dxy-navigation-space-list/dxy-navigation-space-list.component';
import { DxySpaceVersionSelectorComponent } from './dxy-space-version-selector/dxy-space-version-selector.component';
import { DxyVersioningModule } from '../versioning/DxyVersioningModule';
import { SharedModule } from '../shared/shared.module';
import { DxySharedUiModule } from '../shared/shared-ui/DxySharedUiModule';

@NgModule({
    imports: [SharedModule, DxyVersioningModule, DxySharedUiModule],
    declarations: [
        DxyNavigationSpaceListComponent,
        DxySpaceVersionSelectorComponent,
    ],
    exports: [
        DxySpaceVersionSelectorComponent,
        DxyNavigationSpaceListComponent,
    ],
})
export class DxySpaceSelectorModule {}

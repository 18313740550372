import { SpaceHomeState } from '../space-home-state.service';

export function disableEditiondReducer(
    state: SpaceHomeState
): Partial<SpaceHomeState> {
    const homePage = state.spaceHomePage;
    return {
        editionEnabled: false,
        spaceHomePage: {
            ...homePage,
            SpaceHomePageImageDto: homePage.SpaceHomePageImageDto?.IsDelete
                ? null
                : homePage.SpaceHomePageImageDto,
        },
    };
}

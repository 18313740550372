import { CoreUtil, DomUtil } from '@datagalaxy/core-util';
import { IOmniGridActionDef } from './IOmniGridActionDef';
import { INgZone, ZoneUtils } from '@datagalaxy/utils';

export class OmniGridActionTool<TEntity> {
    private element: Element;
    public isMouseOver: boolean;

    constructor(
        private items: IOmniGridActionDef<TEntity>[],
        private className = 'actionTools',
        private setTooltip: (el: Element, message: string) => void,
        private removeTooltip: (el: Element) => void,
        private ngZone?: INgZone,
        private runOutsideAngular = false
    ) {}

    public clear() {
        if (this.element) {
            this.element.remove();
            this.element = undefined;
            this.isMouseOver = false;
        }
    }

    public show(container: Element, data: TEntity) {
        this.clear();
        if (!container) {
            return;
        }
        const el = this.build(data);
        if (el) {
            container.appendChild(el);
            this.element = el;
            return true;
        }
        return false;
    }

    private build(data: TEntity) {
        const filteredItems = this.items?.filter(
            (it) => !it.filter || it.filter(data)
        );
        if (!filteredItems?.length) {
            return;
        }

        const eTools = DomUtil.createElement('div', this.className);
        ZoneUtils.zoneExecute(
            () => {
                eTools.addEventListener(
                    'mouseover',
                    () => (this.isMouseOver = true)
                );
                eTools.addEventListener(
                    'mouseout',
                    () => (this.isMouseOver = false)
                );
            },
            this.ngZone,
            this.runOutsideAngular
        );

        filteredItems.forEach((it) => {
            let classes = 'action-tool-glyph mat-button-base mat-icon-button ';
            classes += it.isBigBtn ? '' : 'dg5-small-icon-button';
            classes += it.customClass ?? '';
            const eLink = DomUtil.createElement('div', classes);
            const tooltip = CoreUtil.fromFnOrValue(it.tooltip, data);

            if (typeof it.isHidden == 'function' && it.isHidden(data)) {
                eLink.classList.add('hidden');
                eLink.setAttribute('hidden', 'hidden');
            } else if (
                typeof it.isDisabled == 'function' &&
                it.isDisabled(data)
            ) {
                eLink.classList.add('disabled');
                eLink.setAttribute('disabled', 'disabled');
            } else if (it.callback) {
                ZoneUtils.zoneExecute(
                    () => {
                        eLink.addEventListener('click', (e) => {
                            e.cancelBubble = true;
                            const eRow = eLink.closest('div[row-id]');
                            const rowId = eRow && eRow.getAttribute('row-id');
                            it.callback(data, rowId);
                            if (tooltip) {
                                this.removeTooltip(eLink);
                            }
                        });
                    },
                    this.ngZone,
                    this.runOutsideAngular
                );
            }
            if (tooltip) {
                this.setTooltip(eLink, tooltip);
            }
            let html = '';
            const glyphClassName = CoreUtil.fromFnOrValue(
                it.glyphClassName,
                data
            );
            if (glyphClassName) {
                html += `<span class="glyph ${glyphClassName}"></span>`;
            }
            if (it.label) {
                html += `<span class="label">${it.label}</span>`;
            }
            eLink.innerHTML = html;
            eTools.appendChild(eLink);
        });

        return eTools;
    }
}

import { Directive, HostBinding, Input } from '@angular/core';
import { ButtonColor } from '../button-color';
import { ButtonSize } from '../button-size';

/**
 * ## Role
 * Add DataGalaxy CSS classes to a button based on inputs values
 */
@Directive({
    selector: '[dxyButton]',
})
export class DxyButtonDirective {
    /**
     * Button's vertical size format
     */
    @Input() size: ButtonSize = 'large';

    /**
     * Button's color
     */
    @Input() color: ButtonColor = 'primary';

    @HostBinding('class') get classes() {
        return `dxy-button dxy-button--${this.color} dxy-button--${this.size}${
            this.color.includes('secondary')
                ? ' dxy-button--secondary-common'
                : ''
        }`;
    }
}

import {
    ChangeDetectionStrategy,
    Component,
    HostBinding,
    Input,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Widget } from '../domain';

@Component({
    selector: 'dxy-widgets-gallery',
    templateUrl: './widgets-gallery.component.html',
    styleUrls: ['./widgets-gallery.component.scss', '../is-drag-over.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WidgetsGalleryComponent {
    protected openedSubject = new BehaviorSubject<boolean>(false);
    protected opened$ = this.openedSubject.asObservable();

    @Input() title = '';
    @Input() description = '';
    @Input() widgets: Widget[] = [];
    @Input()
    get opened() {
        return this.openedSubject.value;
    }
    set opened(o: boolean) {
        if (o == this.openedSubject.value) {
            return;
        }
        this.openedSubject.next(o);
    }

    @HostBinding('class.is-drag-over')
    @Input()
    dragging = false;

    public openPanel() {
        this.openedSubject.next(true);
    }

    protected togglePanel() {
        this.openedSubject.next(!this.openedSubject.value);
    }
}

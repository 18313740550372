import { CollectionsHelper } from '@datagalaxy/core-util';
import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    Input,
    OnChanges,
    SimpleChanges,
    Type,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import { environment } from '../../../../environments/environment';
import { ScreenService } from '../../../screens/screen.service';
import {
    ComparatorItemClient,
    VersionCompareContext,
} from '../../entity/compare-entity.types';
import { IScreenData, IScreenLayoutComponent } from '../screens-layout.types';
import { DxyCompareEntityFormComponent } from '../../entity/dxy-compare-entity-form/dxy-compare-entity-form.component';
import { DxyEntityFormComponent } from '../../entity/dxy-entity-form/dxy-entity-form.component';
import { DxyEntitySynonymsComponent } from '../../entity/dxy-entity-synonyms/dxy-entity-synonyms.component';
import { ScreenCategory } from '@datagalaxy/webclient/screen/data-access';
import { DxyBaseComponent } from '@datagalaxy/ui/core';

@Component({
    selector: 'dxy-screen-layout-category',
    template: '<ng-container #container></ng-container>',
})
export class DxyScreenLayoutCategoryComponent
    extends DxyBaseComponent
    implements OnChanges, AfterViewInit
{
    //#region static
    private static getComponentType(
        componentName: string
    ): Type<IScreenLayoutComponent> {
        switch (componentName) {
            case ScreenService.componentName.entityForm:
                return DxyEntityFormComponent;
            case ScreenService.componentName.compareEntityForm:
                return DxyCompareEntityFormComponent;
            case ScreenService.componentName.entitySynonyms:
                return DxyEntitySynonymsComponent;
            default:
                if (!environment.production) {
                    console.warn('unknown component name', componentName);
                }
                return;
        }
    }
    //#endregion

    @Input() screenData: IScreenData;
    @Input() screenCategory: ScreenCategory;
    @Input() reloadOnSourceChange: boolean;
    @Input() comparatorItems: Map<string, ComparatorItemClient>;
    @Input() versionCompareContext: VersionCompareContext;
    @Input() isVersioningCompare: boolean;

    // Note: we use a ng-container instead of getting the ViewContainerRef from the constructor,
    // so the inner component is created inside this component instead of after it in the html.
    @ViewChild('container', { read: ViewContainerRef })
    private viewContainerRef: ViewContainerRef;

    constructor(private changeDetector: ChangeDetectorRef) {
        super();
    }

    ngOnChanges(changes: SimpleChanges): void {
        super.onChange(changes, 'screenCategory', () => {
            if (this.reloadOnSourceChange) {
                this.updateData();
            }
        });
    }

    ngAfterViewInit() {
        this.updateData();
    }

    private updateData() {
        if (!this.screenCategory) {
            return;
        }
        this.compileComponent();
    }

    private compileComponent() {
        this.viewContainerRef.clear();
        const componentName =
            ScreenService.getScreenLayoutCategoryUsedComponentName(
                this.screenCategory,
                this.isVersioningCompare
            );
        const componentType =
            DxyScreenLayoutCategoryComponent.getComponentType(componentName);
        const compRef = this.viewContainerRef.createComponent(componentType);
        const instance = compRef.instance;
        instance.screenData = this.screenData;
        instance.screenCategory = this.screenCategory;

        if (this.isVersioningCompare) {
            instance.versionCompareContext = this.versionCompareContext;
            instance.comparatorItems = this.getComparatorItems();
        }

        super.preventNg0100Error(this.changeDetector);
    }
    private getComparatorItems() {
        return CollectionsHelper.objArrayToMap(
            this.screenCategory.Properties,
            (p) => p.Key,
            (p) => this.comparatorItems.get(p.Key)
        );
    }
}

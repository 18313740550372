<div *ngIf="spaceHomePage$ | async as spaceHomePage" class="space-home-header">
    <div
        class="header-image-content"
        [ngStyle]="{ 'background-image': (spaceHeaderImage$ | async) }"
    ></div>
    <div *ngIf="!spaceHomePage.IsTitleDisabled" class="header-text">
        <span *ngIf="spaceHomePage.Title as title; else defaultTitle">{{
            title
        }}</span>
        <ng-template #defaultTitle>
            <span translate [translateParams]="{spaceName}"
                >UI.SpaceHome.title</span
            >
        </ng-template>
    </div>
    <button
        *ngIf="editionEnabled$ | async"
        class="header-edition-overlay"
        type="button"
        (click)="openHeaderModal()"
    ></button>

    <dxy-spotlight-input
        *ngIf="spotlightSearch"
        displayMode="dropdown"
        [class.opened]="spotlightOpen"
        [placeholder]="spotlightPlaceholderKey | translate"
        [currentSearch]="spotlightSearch"
        [enableLastSearch]="true"
        [disabled]="editionEnabled$ | async"
    ></dxy-spotlight-input>
    <div
        class="quick-filters-wrapper"
        [class.disabled]="editionEnabled$ | async"
    >
        <app-attribute-quick-filters
            *ngIf="showQuickFilters"
            displayMode="horizontal"
            [currentSearch]="spotlightSearch"
            [quickFilters]="quickFilters"
            (onSelectFilter)="onSelectQuickFilter($event)"
        ></app-attribute-quick-filters>
        <dxy-spinner *ngIf="isQuickFiltersLoading"></dxy-spinner>
    </div>
</div>

<dxy-spinner *ngIf="loading$ | async; else body"></dxy-spinner>

<ng-template #body>
    <div class="space-home-body">
        <dxy-dashboard
            *ngIf="dashboard$ | async as dashboard"
            [dashboard]="dashboard"
            (dashboardChange)="onDashboardChange($event)"
            [enableEdition]="editionEnabled$ | async"
        ></dxy-dashboard>
    </div>
</ng-template>

import { ImpactAnalysisTool } from '../../shared/util/app-types/impact-analysis.types';
import { ImpactAnalysisService } from '../../impactAnalysis/impact-analysis.service';
import { IGraphicalToolbarOption } from '@datagalaxy/core-ui';
import { NavigationService } from '../../services/navigation.service';
import { Injectable } from '@angular/core';
import { BehaviorSubject, map } from 'rxjs';
import { DksLeftBarMenuOption } from '../../data-knowledge-studio/menu/dks-left-bar-menu/dks-left-bar-menu.types';
import { LineageDksApi } from '../lineage.types';
import { DxyModalService } from '../../shared/dialogs/DxyModalService';
import { IEntityIdentifier } from '@datagalaxy/dg-object-model';
import { LineagePathTrackerService } from '../lineage-path-tracker/lineage-path-tracker.service';
import { LineageTrackerColor } from '../lineage-path-tracker/lineage-path-tracker.types';
import { LineageVersionTogglerService } from '../../impactAnalysis/entity-impact-analysis/lineage-version-toggler.service';
import { UserService } from '../../services/user.service';
import {
    LineageLeftToolbarPersistedState,
    LineageLeftToolbarState,
    LineageLeftToolbarStore,
} from './lineage-left-toolbar.store';
import { userSettingsValues } from '@datagalaxy/webclient/user/domain';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';

@Injectable()
export class LineageLeftToolbarService {
    private readonly loading = new BehaviorSubject<boolean>(false);
    private entity: EntityItem;
    private dksApi: LineageDksApi;

    public get menu$() {
        return this.menuStore
            .selectState()
            .pipe(map((state) => this.buildMenu(state)));
    }
    public get loading$() {
        return this.loading.asObservable();
    }

    constructor(
        private navigationService: NavigationService,
        private dxyModalService: DxyModalService,
        private lineagePathTracker: LineagePathTrackerService,
        private lineageVersionTogglerService: LineageVersionTogglerService,
        private userService: UserService,
        private menuStore: LineageLeftToolbarStore
    ) {
        this.lineagePathTracker
            .selectState()
            .subscribe(() => this.setupMenu(this.entity, this.dksApi));
    }

    public setupMenu(entityData: EntityItem, dksApi: LineageDksApi) {
        this.dksApi = dksApi;

        this.menuStore.dispatchLoadEntityMenu(entityData);
    }

    public async loadPersistedMenuState() {
        if (this.menuStore.state.persistedState) {
            return;
        }

        this.loading.next(true);
        try {
            const res = await this.userService.getUserSettingValue(
                userSettingsValues.lineage.category,
                userSettingsValues.lineage.routes.globalState
            );

            const persistedState: LineageLeftToolbarPersistedState = res.Value
                ? JSON.parse(res.Value)
                : { displayDataProcessingItems: false };

            this.menuStore.dispatchUpdatePersistedState(persistedState);
        } finally {
            this.loading.next(false);
        }
    }

    private buildMenu(state: LineageLeftToolbarState) {
        const entity = state.entity;

        return [
            this.getLegacyLineageOption(entity),
            DksLeftBarMenuOption.Separator,
            this.getPathTrackerBurgerMenu(),
            DksLeftBarMenuOption.ExtraData,
            DksLeftBarMenuOption.Separator,
            this.getSaveAsDiagramOption(entity, this.dksApi),
        ];
    }

    private async savePersistedMenuState(
        state: LineageLeftToolbarPersistedState
    ) {
        await this.userService.setUserSettingValue(
            userSettingsValues.lineage.category,
            userSettingsValues.lineage.routes.globalState,
            JSON.stringify(state)
        );
    }

    private getSaveAsDiagramOption(
        entityIdr: EntityItem,
        dksApi: LineageDksApi
    ): IGraphicalToolbarOption {
        return {
            glyphClass: 'glyph-save',
            tooltipTranslateKey:
                'UI.ImpactAnalysis.lineage.leftMenu.saveAsDiagram',
            logFunctional: 'LINEAGE,A,SAVE_AS_DIAGRAM',
            callback: () => this.openSaveAsDiagramModal(entityIdr, dksApi),
        };
    }

    private openSaveAsDiagramModal(
        _entityIdr: IEntityIdentifier,
        _dksApi: LineageDksApi
    ) {
        return this.dxyModalService.confirm({
            titleKey:
                'UI.ImpactAnalysis.lineage.saveAsDiagramDisabledModal.title',
            messageKey:
                'UI.ImpactAnalysis.lineage.saveAsDiagramDisabledModal.message',
        });
        /* Temporary disabled while diagrams don't support hierarchies
        return this.dxyModalService.open<
            LineageSaveAsDiagramModalComponent,
            LineageSaveAsDiagramModalInput
        >({
            componentType: LineageSaveAsDiagramModalComponent,
            data: {
                entityIdr,
                dksApi,
            },
        });
        
         */
    }

    private getSelectorOption(
        tool: ImpactAnalysisTool,
        onSelectedToolChange: (tool: ImpactAnalysisTool) => void,
        isSelected?: (tool: ImpactAnalysisTool) => boolean
    ): IGraphicalToolbarOption {
        return {
            glyphClass: ImpactAnalysisService.getToolGlyphClass(tool),
            callback: () => onSelectedToolChange(tool),
            tooltipTranslateKey: `UI.EntityImpactAnalysis.toolOption${ImpactAnalysisTool[tool]}`,
            data: tool,
            isCurrentActive: () => isSelected?.(tool),
        };
    }

    private getLegacyLineageOption(
        entityIdr: EntityItem
    ): IGraphicalToolbarOption {
        return {
            isCurrentActive: false,
            glyphClass: 'glyph-lineage-explore dg5-beta-badge',
            tooltipTranslateKey:
                'UI.EntityImpactAnalysis.toolOptionLineageTooltip',
            options: [
                {
                    glyphClass: 'glyph-lineage-explore dg5-beta-badge',
                    tooltipTranslateKey:
                        'UI.EntityImpactAnalysis.toolOptionLineage',
                    isCurrentActive: true,
                    callback: () => void 0,
                },
                this.getSelectorOption(ImpactAnalysisTool.LineageBoth, () => {
                    this.lineageVersionTogglerService.setUseLegacyLineage(true);
                    this.navigationService.goToEntityLineage(entityIdr.HddData);
                }),
                { isSeparator: true },
                this.getSelectorOption(ImpactAnalysisTool.Explorer, () => {
                    this.navigationService.goToEntityImpactAnalysis(
                        entityIdr.HddData
                    );
                }),
            ],
        };
    }

    private getPathTrackerBurgerMenu(): IGraphicalToolbarOption {
        const isRedActive = !!this.lineagePathTracker.state.red;
        const isBlueActive = !!this.lineagePathTracker.state.blue;
        return {
            glyphClass: `icon-path-tracker blue-and-red ${
                isRedActive ? '' : 'red-unpinned'
            } ${isBlueActive ? '' : 'blue-unpinned'}`,
            tooltipTranslateKey:
                'UI.ImpactAnalysis.lineage.pathTracker.pathTracker',
            dataTrackerId: 'lineage-path-tracker',
            options: [
                {
                    glyphClass: `icon-path-tracker red ${
                        isRedActive ? 'pinned' : ''
                    }`,
                    callback: () =>
                        this.lineagePathTracker.removePathTracker(
                            LineageTrackerColor.Red
                        ),
                    tooltipTranslateKey: `UI.ImpactAnalysis.lineage.pathTracker.${
                        isRedActive ? 'unpinTrack' : 'disabled'
                    }`,
                    logFunctional: 'LINEAGE,A,UNPIN_TRACK_RED',
                    disabled: !isRedActive,
                },
                {
                    glyphClass: `icon-path-tracker blue ${
                        isBlueActive ? 'pinned' : ''
                    }`,
                    callback: () =>
                        this.lineagePathTracker.removePathTracker(
                            LineageTrackerColor.Blue
                        ),
                    tooltipTranslateKey: `UI.ImpactAnalysis.lineage.pathTracker.${
                        isBlueActive ? 'unpinTrack' : 'disabled'
                    }`,
                    logFunctional: 'LINEAGE,A,UNPIN_TRACK_BLUE',
                    disabled: !isBlueActive,
                },
            ],
        };
    }
}

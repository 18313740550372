import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    HostBinding,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ListOptionUtil } from '../../IListOption';
import { DomUtil, TTooltipPlacement } from '@datagalaxy/core-util';
import { IListOptionItem } from './option-item.types';
import { UiOptionSelectDataType } from '../../UiOptionSelect.types';
import { DxyBaseComponent } from '@datagalaxy/ui/core';

@Component({
    selector: 'dxy-option-item',
    templateUrl: './option-item.component.html',
    styleUrls: ['./option-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DxyOptionItemComponent<T>
    extends DxyBaseComponent
    implements OnInit, OnChanges, AfterViewInit
{
    @Input() option: IListOptionItem<T>;
    @Input() data?: T;
    @Input() dataType: UiOptionSelectDataType;
    /** if true, when there is no displayName, we show 'Empty' text instead of '' */
    @Input() useEmptyText: boolean;
    @Input() isMini: boolean;
    @Input() tooltipsPlacement: TTooltipPlacement;

    @HostBinding('style') get optionStyle() {
        return this.option?.style;
    }

    @HostBinding('class') get class() {
        return this.option?.class;
    }
    @HostBinding('class.is-mini') get isMiniClass() {
        return this.isMini;
    }

    public isTagData: boolean;
    public isIconData: boolean;
    public isEntityReferenceData: boolean;
    public isDefaultData: boolean;
    public isHtmlContent: boolean;

    public showRenderer: boolean;
    public hasRichLayout: boolean;

    public iconUrl: string;

    public get isGlyphVisible() {
        return this.isDefaultData && ListOptionUtil.hasGlyphClass(this.option);
    }
    public get glyphClass() {
        return ListOptionUtil.getGlyphClass(this.option, this.data);
    }

    public get showText() {
        return this.hasText || this.showEmptyText;
    }
    public get hasText() {
        return (
            !this.option?.renderData &&
            ListOptionUtil.hasText(this.option, this.data)
        );
    }
    public get text() {
        return ListOptionUtil.getText(this.option, this.translate, this.data);
    }
    public get showEmptyText() {
        return this.useEmptyText && !this.hasText && !this.showRenderer;
    }

    public get hasDescription() {
        return !this.isMini && ListOptionUtil.hasDescription(this.option);
    }
    public get description() {
        return ListOptionUtil.getDescription(this.option, this.translate);
    }
    public get hintGlyphClass() {
        return this.option.hintGlyphClass;
    }
    public get hintGlyphTooltip() {
        return this.option.hintGlyphTooltip;
    }
    public get matTooltipPosition() {
        return DomUtil.getMatTooltipPosition(this.tooltipsPlacement);
    }
    public get iconClass() {
        return this.option.iconClass;
    }
    public get textTooltip() {
        return ListOptionUtil.getTooltipText(
            this.option,
            this.translate,
            false,
            this.data
        );
    }

    constructor(
        private elementRef: ElementRef<HTMLElement>,
        private translate: TranslateService
    ) {
        super();
    }

    ngOnChanges(changes: SimpleChanges) {
        super.onChanges(changes, ['option', 'dataType', 'useEmptyText'], () => {
            this.init();
            this.initDynamicContent();
        });
    }

    ngOnInit() {
        this.init();
    }

    ngAfterViewInit() {
        this.initDynamicContent();
    }

    private init() {
        const dataType = this.dataType;
        const opt = this.option;

        if (!opt) {
            return;
        }
        this.isTagData =
            dataType === UiOptionSelectDataType.tag || !!opt.tagColor;
        this.isIconData =
            dataType === UiOptionSelectDataType.icon || !!opt.iconUrl;
        this.isEntityReferenceData =
            dataType === UiOptionSelectDataType.entityReference;
        this.isDefaultData =
            dataType === UiOptionSelectDataType.other || !dataType;
        this.isHtmlContent = !!ListOptionUtil.getHtmlContent(
            this.option,
            this.data
        );

        this.iconUrl = this.getIconUrl();
        this.hasRichLayout = ListOptionUtil.hasRichLayout(opt);
        this.showRenderer = !!opt.renderData;
    }

    private initDynamicContent() {
        if (this.isHtmlContent) {
            ListOptionUtil.renderDynamicContent(
                this.option,
                this.elementRef,
                undefined,
                this.data
            );
        }
    }

    private getIconUrl() {
        return this.option?.iconUrl ?? '';
    }
}

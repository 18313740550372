import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminHeaderComponent } from '@datagalaxy/webclient/admin/ui';
import { UIRouterModule } from '@uirouter/angular';
import { multilingualFeatureRoutes } from './multilingual.routes';
import { MultilingualAdministrationComponent } from './administration/multilingual-administration/multilingual-administration.component';
import { TranslateModule } from '@ngx-translate/core';
import { MultilingualUnavailabilityComponent } from './administration/multilingual-unavailability/multilingual-unavailability.component';
import {
    AlertMessageComponent,
    FileInputComponent,
    SlideToggleComponent,
} from '@datagalaxy/ui/forms';
import { DxyButtonsModule } from '@datagalaxy/ui/buttons';
import { FeatureFlagDirective } from '@datagalaxy/webclient/feature-flag';
import { MultilingualActivatorComponent } from './administration/multilingual-activator/multilingual-activator.component';
import { MultilingualConfiguratorComponent } from './administration/multilingual-configurator/multilingual-configurator.component';
import { StartMultilingualActivationComponent } from './administration/funnel/start-multilingual-activation/start-multilingual-activation.component';
import { MultilingualFunnelService } from './administration/funnel/multilingual-funnel.service';
import { FunnelModule } from '@datagalaxy/ui/funnel';
import { SourceLanguageStepComponent } from './administration/funnel/source-language-step/source-language-step.component';
import { CoreUiFullModule } from '@datagalaxy/core-ui';
import { TargetLanguageStepComponent } from './administration/funnel/target-language-step/target-language-step.component';
import { AttributesSelectionStepComponent } from './administration/funnel/attributes-selection-step/attributes-selection-step.component';
import {
    AttributeCellComponent,
    AttributeSelectorComponent,
} from '@datagalaxy/webclient/attribute/feature';
import { MultilingualRecapStepComponent } from './administration/funnel/multilingual-recap-step/multilingual-recap-step.component';
import { MultilingualLanguagesComponent } from './administration/multilingual-languages/multilingual-languages.component';
import { MultilingualAttributesComponent } from './administration/multilingual-attributes/multilingual-attributes.component';
import { LanguageCardComponent } from './languages/language-card/language-card.component';
import {
    LanguageIconComponent,
    LanguageNamePipe,
} from '@datagalaxy/webclient/multilingual/ui';
import { AddLanguageModalComponent } from './administration/add-language-modal/add-language-modal.component';
import { TargetLanguageSelectorComponent } from './administration/target-language-selector/target-language-selector.component';
import { AddAttributesModalComponent } from './administration/add-attributes-modal/add-attributes-modal.component';
import { LanguageSelectorButtonComponent } from './languages/language-selector-button/language-selector-button.component';
import { MultilingualInitializer } from './multilingual-initializer';
import {
    EntityTranslationLoader,
    EntityTranslationStore,
} from './translations';
import { MultilingualReadMoreButtonDirective } from './administration/multilingual-read-more-button.directive';
import { AttributeTextTranslationInputComponent } from './attributes/attribute-text-translation-input/attribute-text-translation-input.component';
import { AttributeRichTextTranslationInputComponent } from './attributes';
import { LexiconUploadFormComponent } from './administration/lexicon-upload-form/lexicon-upload-form.component';
import { MultilingualLexiconComponent } from './administration/multilingual-lexicon/multilingual-lexicon.component';
import { LexiconStepComponent } from './administration/funnel/lexicon-step/lexicon-step.component';
import { TranslationProgressComponent } from './translations/translation-progress/translation-progress.component';
import { AttributeTranslationButtonComponent } from './translations/attribute-translation-button/attribute-translation-button.component';

@NgModule({
    imports: [
        CommonModule,
        UIRouterModule.forChild({ states: multilingualFeatureRoutes }),
        CoreUiFullModule,
        AdminHeaderComponent,
        TranslateModule,
        AlertMessageComponent,
        SlideToggleComponent,
        DxyButtonsModule,
        FeatureFlagDirective,
        FunnelModule,
        AttributeSelectorComponent,
        LanguageIconComponent,
        LanguageNamePipe,
        AttributeCellComponent,
        LanguageCardComponent,
        LanguageSelectorButtonComponent,
        MultilingualReadMoreButtonDirective,
        FileInputComponent,
        TranslationProgressComponent,
        FeatureFlagDirective,
        AttributeTranslationButtonComponent,
    ],
    exports: [
        AttributeTextTranslationInputComponent,
        AttributeRichTextTranslationInputComponent,
    ],
    declarations: [
        MultilingualAdministrationComponent,
        MultilingualUnavailabilityComponent,
        MultilingualActivatorComponent,
        MultilingualConfiguratorComponent,
        StartMultilingualActivationComponent,
        SourceLanguageStepComponent,
        TargetLanguageStepComponent,
        LexiconStepComponent,
        AttributesSelectionStepComponent,
        MultilingualRecapStepComponent,
        MultilingualLanguagesComponent,
        MultilingualAttributesComponent,
        AddLanguageModalComponent,
        TargetLanguageSelectorComponent,
        AddAttributesModalComponent,
        AttributeTextTranslationInputComponent,
        AttributeRichTextTranslationInputComponent,
        LexiconUploadFormComponent,
        MultilingualLexiconComponent,
    ],
})
export class MultilingualFeatureModule {
    static forRoot(): ModuleWithProviders<MultilingualFeatureModule> {
        return {
            ngModule: MultilingualFeatureModule,
            providers: [
                MultilingualInitializer,
                MultilingualFunnelService,
                EntityTranslationStore,
                EntityTranslationLoader,
            ],
        };
    }
    constructor(
        _etLoader: EntityTranslationLoader,
        _initializer: MultilingualInitializer
    ) {}
}

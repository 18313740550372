import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { withLoading } from '@datagalaxy/core-ui';
import {
    IGraphNodePortHoverEvent,
    LayoutMode,
    TDiagEdge,
} from '@datagalaxy/core-d3-util';
import { DksGraphOptions } from '../data-knowledge-studio/config/dks-config.types';
import { TDksLeftBarMenu } from '../data-knowledge-studio/menu/dks-left-bar-menu/dks-left-bar-menu.types';
import { LineageLeftToolbarService } from './menu/lineage-left-toolbar.service';
import { TDksNode } from '../data-knowledge-studio/data-knowledge-studio.types';
import { combineLatest, map, Observable } from 'rxjs';
import { LineageDksApi, LineageEdge, LineageNodeSpec } from './lineage.types';
import { LineageGraphService } from './lineage-graph/lineage-graph.service';
import { ObjectLinkType } from '@datagalaxy/dg-object-model';
import { TranslateService } from '@ngx-translate/core';
import { LineageEntityMenuService } from './menu/lineage-entity-menu.service';
import { LineageAssetsMenuService } from './menu/lineage-assets-menu.service';
import { LineagePathTrackerService } from './lineage-path-tracker/lineage-path-tracker.service';
import { LineageLeftToolbarStore } from './menu/lineage-left-toolbar.store';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { LineageGraphSurfaceBuilder } from './lineage-graph-surface/lineage-graph-surface.builder';
import { LineageEntityStreamService } from './lineage-entity-stream-buttons/lineage-entity-stream.service';
import { LineageTreeNode } from './lineage-tree/lineage-tree-node';
import { EntityNodeTreeToggleEvent } from '../data-knowledge-studio/notifier/dks-notifier';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';
import { ServerConstants } from '@datagalaxy/shared/server/domain';
import PropertyName = ServerConstants.PropertyName;

@Component({
    selector: 'app-lineage',
    templateUrl: './lineage.component.html',
    styleUrls: ['./lineage.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        LineageGraphService,
        LineageEntityMenuService,
        LineageAssetsMenuService,
        LineagePathTrackerService,
        LineageLeftToolbarService,
        LineageLeftToolbarStore,
        LineageEntityStreamService,
    ],
})
export class LineageComponent
    extends DxyBaseComponent
    implements OnInit, OnDestroy
{
    @Input() entityData: EntityItem;

    protected dksConfig: DksGraphOptions<
        TDksNode<LineageNodeSpec>,
        LineageEdge
    > = {
        layout: LayoutMode.Directed,
        tooltips: {
            showEdgeTooltip: (edge) => this.getEdgeTooltip(edge),
        },
        initialZoomToFit: true,
        entityMenu: this.lineageEntityMenu.getMenu(),
        assets: {
            menu: this.lineageAssetsMenu.getMenu(),
            disableSelection: true,
        },
        selectionDisabled: true,
        endpoint: { disabled: true, separateSourceAndTarget: true },
        nodeDrag: { disabled: true },
        extraData: {
            available: [
                PropertyName.EntityStatus,
                PropertyName.Domains,
                PropertyName.DataOwners,
                PropertyName.DataStewards,
                PropertyName.DataQuality,
            ],
        },
    };
    protected dksLeftMenu$: Observable<TDksLeftBarMenu>;
    protected editedNode: LineageTreeNode;
    protected dksApi: LineageDksApi;

    private highlightedEdges: TDiagEdge<TDksNode>[] = [];

    public get loading$() {
        return combineLatest([
            this.loadingSubject,
            this.lineageLeftToolbar.loading$,
            this.lineageGraphService.selectLoading(),
        ]).pipe(map((results) => results.some(Boolean)));
    }

    constructor(
        private lineageGraphService: LineageGraphService,
        private lineageLeftToolbar: LineageLeftToolbarService,
        private lineageEntityMenu: LineageEntityMenuService,
        private lineageAssetsMenu: LineageAssetsMenuService,
        private lineagePathTrackerService: LineagePathTrackerService,
        private lineageEntityStreamService: LineageEntityStreamService,
        private translate: TranslateService
    ) {
        super();
    }

    ngOnInit() {
        this.dksLeftMenu$ = this.lineageLeftToolbar.menu$;
    }

    @withLoading()
    protected async onGraphSurfaceReady(api: LineageDksApi) {
        this.dksApi = api;
        await this.lineageLeftToolbar.loadPersistedMenuState();
        this.lineageGraphService.loadLineage(this.entityData);
        this.lineageLeftToolbar.setupMenu(this.entityData, api);
        this.lineageEntityMenu.setupMenu(api);
        this.lineagePathTrackerService.setApi(api);

        super.subscribe(this.lineageGraphService.added$, (event) =>
            this.onLineageGraphNodeAdded(event.nodes)
        );

        super.subscribe(this.lineageGraphService.updated$, (event) =>
            this.onLineageGraphNodeUpdated(event.nodes)
        );

        super.subscribe(this.lineageGraphService.removed$, (event) =>
            this.onLineageGraphNodeRemoved(event)
        );

        super.subscribe(api.event$.entityNodeTreeToggle$, (event) =>
            this.onLineageNodeToggleExpansion(event)
        );

        super.subscribe(api.event$.nodePortHover$, (event) =>
            this.onNodePortHover(event, api)
        );

        super.subscribe(api.event$.edgeHover$, (event) => {
            this.hoverEdge(event.edge, event.show, api);
        });

        super.subscribe(api.event$.showMore$, (event) =>
            this.onShowMoreItems(event)
        );

        super.subscribe(
            this.lineageEntityStreamService.nodeExpanded$,
            (event) => this.onNodeExpanded(event)
        );
    }

    protected onPanelClose() {
        this.editedNode = null;
    }

    private getEdgeTooltip(
        edge: TDiagEdge<TDksNode<LineageNodeSpec>, LineageEdge>
    ) {
        const linkKey = ObjectLinkType[edge.data.linkType];

        const tooltipText = this.translate.instant(
            `DgServerTypes.ObjectLinkType.${linkKey}`
        );

        return edge.data.golden ? `${tooltipText} (golden)` : tooltipText;
    }

    private hoverEdge(
        edge: TDiagEdge<TDksNode>,
        show: boolean,
        api: LineageDksApi
    ) {
        const sourceId = edge.connector.srcPort.localId;
        const targetId = edge.connector.tgtPort.localId;

        const className = 'hover';

        if (show) {
            api.addClassToEdge(edge.id, className);
            api.addClassToPort(sourceId, className);
            api.addClassToPort(targetId, className);

            this.highlightedEdges.push(edge);
        } else {
            api.removeClassFromEdge(edge.id, className);
            api.removeClassFromPort(sourceId, className);
            api.removeClassFromPort(targetId, className);

            this.highlightedEdges = this.highlightedEdges.filter(
                (e) => e.id !== edge.id
            );
        }
    }

    private clearHighlightedEdges() {
        this.highlightedEdges.forEach((edge) => {
            this.hoverEdge(edge, false, this.dksApi);
        });
    }

    private onLineageGraphNodeAdded(nodes: LineageTreeNode[]) {
        this.updateGraph(nodes);
    }

    private onLineageGraphNodeUpdated(nodes: LineageTreeNode[]) {
        this.updateGraph(nodes);
    }

    private onLineageGraphNodeRemoved(nodes: LineageTreeNode[]) {
        const rootNodes = nodes
            .map((node) => node.getVisibleRoot())
            .filter(Boolean);

        this.dksApi.remove(
            nodes.map((node) => node.id),
            []
        );

        this.updateGraph(rootNodes);
    }

    private onLineageNodeToggleExpansion(
        event: EntityNodeTreeToggleEvent<TDksNode<LineageNodeSpec>>
    ) {
        const id = event.nodeTree?.entity.ReferenceId || event.node.id;
        this.lineageGraphService.toggleNode(id);

        this.clearHighlightedEdges();
    }

    private onNodePortHover(
        event: IGraphNodePortHoverEvent<TDksNode>,
        api: LineageDksApi
    ) {
        const nodeId = event.port.node.id;

        const edges = api.getNodeEdges(nodeId);
        const filteredEdges = edges.filter(
            (edge) =>
                edge.connector.srcPort.id === event.port.id ||
                edge.connector.tgtPort.id === event.port.id
        );

        filteredEdges.forEach((edge) => this.hoverEdge(edge, event.show, api));
    }

    private updateGraph(nodes: LineageTreeNode[]) {
        const api = this.dksApi;
        const builder = new LineageGraphSurfaceBuilder(
            this.lineageGraphService
        );
        const res = builder.makeGraphNodeSpecs(nodes);

        api.remove(
            res.deleted.nodes.nodes.map((n) => n.id),
            []
        );
        api.add(res.added.nodes, res.added.edges);

        this.lineagePathTrackerService.refreshPathTrackers();
        this.clearHighlightedEdges();
    }

    private onShowMoreItems(id: string) {
        if (id === this.editedNode?.id) {
            this.editedNode = null;
            return;
        }
        this.editedNode = this.lineageGraphService.getNode(id);
    }

    private onNodeExpanded(node: LineageTreeNode) {
        const visibleRoot = node.getVisibleRoot();
        if (!visibleRoot) {
            return;
        }
        this.dksApi.centerNode(visibleRoot.id);
    }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CoreUiModule } from '@datagalaxy/core-ui';
import { CardFromGridDirective } from './card-from-grid.directive';
import { DashboardCardComponent } from './dashboard-card/dashboard-card.component';
import { DashboardGridComponent } from './dashboard-grid/dashboard-grid.component';
import { DashboardSectionComponent } from './dashboard-section/dashboard-section.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { EmptyGridPlaceholderComponent } from './empty-grid-placeholder/empty-grid-placeholder.component';
import { WidgetHandleComponent } from './widget-handle/widget-handle.component';
import { WidgetPreviewComponent } from './widget-preview/widget-preview.component';
import { WidgetsGalleryComponent } from './widgets-gallery/widgets-gallery.component';
import { DraggableWidgetDirective } from './draggable-widget.directive';
import { DxyButtonsModule } from '@datagalaxy/ui/buttons';
import { AlertMessageComponent } from '@datagalaxy/ui/forms';
import { TitleInputComponent } from '@datagalaxy/ui/forms';
import { WidgetTitleEditorComponent } from './widget-title-editor/widget-title-editor.component';
import { DxyRichTooltipDirective } from '@datagalaxy/ui/tooltip';

@NgModule({
    imports: [
        CommonModule,
        CoreUiModule.forRoot(),
        DxyButtonsModule,
        AlertMessageComponent,
        TitleInputComponent,
        DxyRichTooltipDirective,
    ],
    declarations: [
        WidgetsGalleryComponent,
        WidgetPreviewComponent,
        WidgetHandleComponent,
        WidgetTitleEditorComponent,
        DashboardCardComponent,
        DashboardGridComponent,
        CardFromGridDirective,
        DraggableWidgetDirective,
        EmptyGridPlaceholderComponent,
        DashboardSectionComponent,
        DashboardComponent,
    ],
    exports: [
        WidgetsGalleryComponent,
        DashboardComponent,
        WidgetTitleEditorComponent,
        WidgetPreviewComponent,
    ],
})
export class DashboardModule {}

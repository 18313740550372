import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MultilingualStateService } from '../../multilingual-state.service';

@Component({
    selector: 'dxy-multilingual-administration',
    templateUrl: './multilingual-administration.component.html',
    styleUrls: ['./multilingual-administration.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultilingualAdministrationComponent {
    protected multilingualEnabled$ =
        this.multilingualStateService.selectEnabled();

    protected loading$ = this.multilingualStateService.selectLoading();

    constructor(private multilingualStateService: MultilingualStateService) {}
}

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnInit,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DxyBooleanCellComponent } from '@datagalaxy/core-ui/omnigrid';
import { ForeignKeyGridData } from '../ForeignKeyGridData';
import { EntitySecurityService } from '../../../shared/entity/services/entity-security.service';
import { ModelerService } from '../../services/modeler.service';
import { Project } from '@datagalaxy/webclient/workspace/data-access';
import { Model } from '@datagalaxy/webclient/modeler/data-access';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';
import {
    GridCellType,
    GridComponent,
    GridConfig,
    TColDef,
} from '@datagalaxy/ui/grid';
import { IActionOption } from '@datagalaxy/core-ui';
import { TextAndToolsCellComponent } from '../../../shared/shared-ui/cells/text-and-tools-cell/text-and-tools-cell.component';

@Component({
    selector: 'dxy-foreign-key-grid',
    templateUrl: 'dxy-foreign-key-grid.component.html',
    styleUrls: ['dxy-foreign-key-grid.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [GridComponent],
})
export class DxyForeignKeyGridComponent
    extends DxyBaseComponent
    implements OnInit
{
    @Input() currentRoot: Project;
    @Input() modelData: Model;
    @Input() entityData: EntityItem;

    protected gridConfig: GridConfig<ForeignKeyGridData> = {
        getItemId: (item) => item.primaryKeyTechnicalName,
        horizontalScroll: true,
    };
    protected cols: TColDef<ForeignKeyGridData>[] = [
        {
            id: 'foreignKeyDisplayName',
            headerLabel: this.getLabel('lblForeignKeyDisplayName'),
            type: GridCellType.custom,
            customCellComponent: TextAndToolsCellComponent,
            getInputs: (item) =>
                ({
                    actions: this.getActions(item),
                    text: item.foreignKeyDisplayName,
                } as Partial<TextAndToolsCellComponent<ForeignKeyGridData>>),
            minWidth: 200,
            sortable: true,
            getSortableValue: (item) => item.foreignKeyDisplayName,
        },
        {
            id: 'foreignKeyTechnicalName',
            headerLabel: this.getLabel('lblForeignKeyTechnicalName'),
            type: GridCellType.text,
            getValue: (item) => item.foreignKeyTechnicalName,
            sortable: true,
        },
        {
            id: 'primaryKeyTechnicalName',
            headerLabel: this.getLabel('lblPrimaryKeyTechnicalName'),
            type: GridCellType.text,
            getValue: (item) => item.primaryKeyTechnicalName,
            sortable: true,
        },
        {
            id: 'parentTableTechnicalName',
            headerLabel: this.getLabel('lblParentTableTechnicalName'),
            type: GridCellType.text,
            getValue: (item) => item.parentTableTechnicalName,
            sortable: true,
        },
        {
            id: 'parentTableType',
            headerLabel: this.getLabel('lblParentStructureType'),
            type: GridCellType.text,
            getValue: (item) => this.translate.instant(item.parentTableType),
            sortable: true,
        },
        {
            id: 'parentColumnTechnicalName',
            headerLabel: this.getLabel('lblParentColumnTechnicalName'),
            type: GridCellType.text,
            getValue: (item) => item.parentColumnTechnicalName,
            sortable: true,
        },
        {
            id: 'childTableTechnicalName',
            headerLabel: this.getLabel('lblChildTableTechnicalName'),
            type: GridCellType.text,
            getValue: (item) => item.childTableTechnicalName,
            sortable: true,
        },
        {
            id: 'childTableType',
            headerLabel: this.getLabel('lblChildStructureType'),
            type: GridCellType.text,
            getValue: (row) => this.translate.instant(row.childTableType),
            sortable: true,
        },
        {
            id: 'childColumnTechnicalName',
            headerLabel: this.getLabel('lblChildColumnTechnicalName'),
            type: GridCellType.text,
            getValue: (item) => item.childColumnTechnicalName,
            sortable: true,
        },
        {
            id: 'isForeignKeyMandatory',
            headerLabel: this.getLabel('lblForeignKeyMandatory'),
            type: GridCellType.custom,
            customCellComponent: DxyBooleanCellComponent,
            getInputs: (item) => ({ checked: item.isForeignKeyMandatory }),
            getSortableValue: (item) => (item.isForeignKeyMandatory ? 1 : 0),
            sortable: true,
        },
        {
            id: 'isTechnicalForeignKey',
            headerLabel: this.getLabel('lblForeignKeyTechnical'),
            type: GridCellType.custom,
            customCellComponent: DxyBooleanCellComponent,
            getInputs: (item) => ({ checked: item.isTechnicalForeignKey }),
            getSortableValue: (item) => (item.isTechnicalForeignKey ? 1 : 0),
            sortable: true,
        },
    ];
    protected rows: ForeignKeyGridData[] = [];

    constructor(
        private translate: TranslateService,
        private entitySecurityService: EntitySecurityService,
        private modelerService: ModelerService,
        private cd: ChangeDetectorRef
    ) {
        super();
    }

    ngOnInit() {
        this.log('ngOnInit', this.currentRoot, this.modelData, this.entityData);
        this.refreshGrid();

        this.registerSubscription(
            this.modelerService.subscribeRealTimeModelerUpdate(
                () => this.refreshGrid
            )
        );
    }

    private getActions(
        row: ForeignKeyGridData
    ): IActionOption<ForeignKeyGridData>[] {
        return [
            {
                glyphClass: 'glyph-edit',
                tooltipText: this.translate.instant(
                    'UI.TechDictionary.ttEditFk'
                ),
                callback: async () => this.onEditForeignKey(row),
                disabled: () => !this.isModelEditEnabled(),
            },
            {
                glyphClass: 'glyph-cancelsearch',
                tooltipText: this.translate.instant(
                    'UI.TechDictionary.ttDeleteFk'
                ),
                callback: async () => this.onDeleteForeignKey(row),
                disabled: () => !this.isModelEditEnabled(),
            },
        ];
    }

    private getLabel(suffix: string) {
        return this.translate.instant(`UI.Modeler.ForeignKeyGrid.${suffix}`);
    }

    private refreshGrid() {
        this.rows = [
            ...this.modelerService.getForeignKeyGridData(this.modelData),
        ];
        this.cd.detectChanges();
    }

    private isModelEditEnabled() {
        return this.entitySecurityService.hasWriteAccessSecurity(
            this.entityData.SecurityData
        );
    }

    private async onDeleteForeignKey(foreignKeyGridData: ForeignKeyGridData) {
        this.log('onDeleteForeignKey', foreignKeyGridData);
        await this.modelerService.deleteForeignKey(
            foreignKeyGridData.foreignKey
        );
        this.refreshGrid();
    }

    private async onEditForeignKey(foreignKeyGridData: ForeignKeyGridData) {
        this.log('onEditForeignKey', foreignKeyGridData);
        await this.modelerService.updateForeignKey(
            this.modelData,
            foreignKeyGridData.foreignKeyId,
            this.isModelEditEnabled(),
            foreignKeyGridData.isTechnicalForeignKey
        );
        this.refreshGrid();
    }
}

import { LineageEdge, LineageNodeSpec } from '../lineage.types';
import { ObjectLinkType } from '@datagalaxy/dg-object-model';
import { CollectionsHelper } from '@datagalaxy/core-util';
import { EdgeSpec, PathCurve, ShapeId } from '@datagalaxy/core-d3-util';
import { DksNode } from '../../data-knowledge-studio/data-knowledge-studio.types';
import { EntityLinkUtils } from '../../entity-links/entity-link.utils';
import { LineageTreeNode } from '../lineage-tree/lineage-tree-node';
import { getLocalId } from '@datagalaxy/webclient/utils';
import { GraphicalColor } from '@datagalaxy/shared/graphical/domain';

interface VirtualEdgeGroup {
    key: string;
    source: LineageTreeNode;
    target: LineageTreeNode;
    linkType: ObjectLinkType;
    virtual: boolean;
    primary: boolean;
    golden: boolean;
    edges: LineageEdge[];
}

/**
 * Transforms lineage edges to graph surface edges
 * Also it transforms real hidden edges to virtual edges
 */
export class LineageEdgeGraphBuilder {
    public static getNodeEdgeTarget(treeNode: LineageTreeNode) {
        const fn = (node: LineageTreeNode) => {
            if (!node.parent) {
                return null;
            }
            const isPartOfShowMore =
                node.hasVisibleSibling() &&
                !node.visible &&
                !node.parent.closed &&
                node.parent.visible;

            if (node.parent.opened && !isPartOfShowMore) {
                return null;
            }

            return isPartOfShowMore ? node : fn(node.parent);
        };
        const res = fn(treeNode);
        return res ?? treeNode.getClosestOpened();
    }

    public static getNodeEdgeTargetId(treeNode: LineageTreeNode) {
        const res = this.getNodeEdgeTarget(treeNode);

        return res.visible ? res.id : `show-more-${res.parent.id}`;
    }

    public makeGraphEdges(
        edges: LineageEdge[]
    ): EdgeSpec<DksNode<LineageNodeSpec>, LineageEdge>[] {
        const virtualEdgeGroups = this.makeVirtualEdgesGroup(edges);

        return virtualEdgeGroups.map((virtualEdgeGroup) =>
            this.makeGraphEdgeSpec(virtualEdgeGroup)
        );
    }

    private makeVirtualEdgesGroup(edges: LineageEdge[]): VirtualEdgeGroup[] {
        return CollectionsHelper.groupBy(
            edges.filter(
                (edge) =>
                    edge.source.getVisibleRoot() && edge.target.getVisibleRoot()
            ),
            (edge) => {
                const virtualSourceId =
                    LineageEdgeGraphBuilder.getNodeEdgeTargetId(edge.source);
                const virtualTargetId =
                    LineageEdgeGraphBuilder.getNodeEdgeTargetId(edge.target);

                return `${virtualSourceId}:${virtualTargetId}`;
            },
            (key, items) => this.makeVirtualEdgeGroup(key, items)
        );
    }

    private makeGraphEdgeSpec(
        group: VirtualEdgeGroup
    ): EdgeSpec<DksNode<LineageNodeSpec>, LineageEdge> {
        const source = group.source.getVisibleRoot() ?? group.source;
        const target = group.target.getVisibleRoot() ?? group.target;

        const isGlossary = EntityLinkUtils.isGlossaryLinkType(group.linkType);

        return {
            id: group.key,
            source: source.id,
            sourcePort: LineageEdgeGraphBuilder.getNodeEdgeTargetId(
                group.source
            ),
            target: target.id,
            targetPort: LineageEdgeGraphBuilder.getNodeEdgeTargetId(
                group.target
            ),
            pathBuilderOptions: {
                routing: 'straight',
                curve: PathCurve.Bezier,
            },
            data: {
                id: group.key,
                source,
                target,
                linkType: group.linkType,
                golden: group.golden,
            },
            cssClass: this.getClassName(group),
            shapeId: isGlossary ? null : ShapeId.arrowLine6x6,
            thickness: 1,
            color: GraphicalColor.Gray,
        };
    }

    private getClassName(group: VirtualEdgeGroup): string {
        const isGlossary = EntityLinkUtils.isGlossaryLinkType(group.linkType);
        const isPrimary = group.primary;
        const isGolden = group.golden;

        const classNames = [
            isGlossary ? 'glossary' : '',
            isPrimary ? 'primary' : '',
            isGolden ? 'golden' : '',
            group.virtual ? 'virtual' : '',
        ];

        return classNames.join(' ').trim();
    }

    private makeVirtualEdgeGroup(
        key: string,
        edges: LineageEdge[]
    ): VirtualEdgeGroup {
        const group = edges[0];
        const source = LineageEdgeGraphBuilder.getNodeEdgeTarget(group.source);
        const target = LineageEdgeGraphBuilder.getNodeEdgeTarget(group.target);

        const linkType =
            edges.length === 1 ||
            CollectionsHelper.distinct(edges.map((item) => item.linkType))
                .length === 1
                ? edges[0].linkType
                : ObjectLinkType.Virtual;
        const virtual =
            !source.visible ||
            !target.visible ||
            edges.some(
                (item) => item.target !== target || item.source !== source
            );
        const primary = edges.some(
            (item) => item.source.primary && item.target.primary
        );

        const golden = edges.some((item) =>
            item.source.links?.some((group) =>
                group.goldenIds?.includes(getLocalId(item.target.id))
            )
        );

        return {
            key,
            source,
            target,
            linkType,
            virtual,
            primary,
            golden,
            edges,
        };
    }
}

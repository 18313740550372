import { BaseService, UiSpinnerService } from '@datagalaxy/core-ui';
import { LineageGraphParams } from './data/LineageGraphParams';
import { LineageItem } from './data/LineageItem';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EntityType, IEntityIdentifier } from '@datagalaxy/dg-object-model';
import { ViewTypeService } from '../../services/viewType.service';
import { DxyModalService } from '../../shared/dialogs/DxyModalService';
import { DevPreviewService } from '../../dev-preview-options/dev-preview.service';
import { LineageConstants } from './data/LineageConstants';
import {
    ExplorerApiService,
    GetDataLineageParameter,
    GetDataLineageResult,
    LineageOrientation,
} from '@datagalaxy/webclient/explorer/data-access';
import { EntityIdentifier } from '@datagalaxy/webclient/entity/utils';

@Injectable({ providedIn: 'root' })
export class LineageGraphService extends BaseService {
    //#region static

    public static isEntity(it: LineageItem) {
        return it?.entityType && it.entityType != EntityType.DataProcessingItem;
    }

    //#endregion

    public get activeViewType() {
        return this.viewTypeService.viewType;
    }

    private cache: {
        originIdr: EntityIdentifier;
        result: GetDataLineageResult;
    };

    constructor(
        private translate: TranslateService,
        private uiSpinnerService: UiSpinnerService,
        private viewTypeService: ViewTypeService,
        private dxyModalService: DxyModalService,
        private devPreviewService: DevPreviewService,
        private explorerApiService: ExplorerApiService
    ) {
        super();
    }

    public clearCache() {
        this.cache = null;
    }
    public getCachedOriginIdr() {
        return EntityIdentifier.from(this.cache?.originIdr);
    }

    public getGraphParams(
        originEntityIdr: IEntityIdentifier,
        orientation: LineageOrientation
    ) {
        const getData = async (
            forceReload: boolean,
            forceLoadAllItems: boolean,
            directLinksOnly: boolean
        ) =>
            this.getData(
                originEntityIdr,
                forceReload,
                orientation,
                this.getMaxItemsCount(forceLoadAllItems),
                directLinksOnly
            );
        const loadChildren = async (item: LineageItem) =>
            this.loadChildren(
                EntityIdentifier.fromLocalId(
                    item.dataId,
                    item.entityType,
                    originEntityIdr
                ),
                orientation
            );
        return new LineageGraphParams(
            originEntityIdr,
            orientation,
            getData,
            false,
            loadChildren
        );
    }

    /** Display a modal for the user to confirm the number of more items to load */
    public async confirmLoadWithLazyItems(lazyItemsCount: number) {
        return this.dxyModalService.confirm({
            titleKey: 'UI.ImpactAnalysis.LineageGraphLoadWarningModal.title',
            message: this.translate.instant(
                'UI.ImpactAnalysis.LineageGraphLoadWarningModal.message',
                { count: lazyItemsCount }
            ),
            confirmButtonKey:
                'UI.ImpactAnalysis.LineageGraphLoadWarningModal.ConfirmBtnLbl',
            cancelButtonKey:
                'UI.ImpactAnalysis.LineageGraphLoadWarningModal.CancelBtnLbl',
        });
    }

    private getMaxItemsCount(forceLoadAllItems: boolean) {
        if (forceLoadAllItems) {
            return 0;
        }
        // get custom maxItems value from devPreviewOptions, or default
        const poMaxItemsCount = this.devPreviewService.getInputValue(
            'lineageLazyLoadItemsCount'
        ) as any;
        const maxItemsCount =
            (poMaxItemsCount &&
                !isNaN(poMaxItemsCount ?? NaN) &&
                parseInt(poMaxItemsCount, 10)) ||
            LineageConstants.behaviour.lazyItems.maxItemsCount;
        this.log('getMaxItemsCount', poMaxItemsCount, maxItemsCount);
        return maxItemsCount;
    }

    private async getData(
        entity: IEntityIdentifier,
        forceReload: boolean,
        orientation: LineageOrientation,
        maxItemsCount: number,
        directLinksOnly: boolean
    ) {
        if (forceReload) {
            this.clearCache();
        }

        if (!EntityIdentifier.isValid(entity) && !this.cache?.result) {
            return null;
        }
        if (this.cache?.result) {
            return this.cache.result;
        }

        const parameter = new GetDataLineageParameter(
            entity,
            orientation,
            directLinksOnly
        );
        parameter.MaxItemsCount = maxItemsCount;
        const result = await this.getServerData(parameter);
        this.cache = { result, originIdr: EntityIdentifier.from(entity) };
        return result;
    }

    private async loadChildren(
        parentEntity: IEntityIdentifier,
        orientation: LineageOrientation
    ) {
        const parameter = new GetDataLineageParameter(
            parentEntity,
            orientation
        );
        parameter.IsLazyLoad = true;
        return this.getServerData(parameter);
    }

    private async getServerData(parameter: GetDataLineageParameter) {
        return this.uiSpinnerService.executeWithSpinner(() =>
            this.explorerApiService.getDataLineage(parameter)
        );
    }
}

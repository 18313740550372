import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RichTextContent } from '@datagalaxy/core-ui/rich-text';
import { AttributeMatch } from '../../../shared/AttributeMatch';
import { ImportWizardService } from '../../../services/import-wizard.service';
import { DxyBaseComponent } from '@datagalaxy/ui/core';

@Component({
    selector: 'dxy-attribute-match',
    templateUrl: 'dxy-attribute-match.component.html',
})
export class DxyAttributeMatchComponent extends DxyBaseComponent {
    @Input() item: AttributeMatch;
    @Input() disabledMatch: boolean;
    @Output() onMatched = new EventEmitter<AttributeMatch>();
    @Output() onUnmatched = new EventEmitter<{
        matchedAttribute: AttributeMatch;
        unmatchedFieldName: string;
    }>();

    public get draggable() {
        return this.item?.isMatched && !this.disabledMatch ? 'true' : 'false';
    }
    public get mandatory() {
        return this.item?.isMandatory;
    }
    public get matched() {
        return this.item?.isMatched;
    }
    public get csvFieldName() {
        return this.item?.csvFieldName;
    }
    public get expectedTitle() {
        return this.item?.expectedTitle;
    }
    public get attributeTitle() {
        return this.item?.attributeTitle;
    }
    public get glyphClass() {
        return this.item?.glyphClass || 'glyph-note';
    }
    public get mandatoryVisible() {
        return this.importWizardService.isMissingMandatory(this.item);
    }
    public get description() {
        return this.item?.description;
    }
    public onDroppedFunction = (field: string) => this.onDropped(field);

    constructor(private importWizardService: ImportWizardService) {
        super();
    }

    public onDropped(fieldName: string) {
        const prefix = 'csvField:';
        if (fieldName.indexOf(prefix) == 0) {
            if (this.item) {
                this.onUnmatched.emit({
                    matchedAttribute: this.item,
                    unmatchedFieldName: this.item.csvFieldName,
                });
            }
            this.item.csvFieldName = fieldName.substring(prefix.length);
            this.onMatched.emit(this.item);
        }
    }

    public onDragEnd(e: { droppedOnSelf: boolean }) {
        // TODO vbo bug: droppedOnSelf is always undefined
        if (e.droppedOnSelf) {
            return;
        }
        this.onUnmatched.emit({
            matchedAttribute: this.item,
            unmatchedFieldName: this.item?.csvFieldName,
        });
    }

    public getDescriptionTooltip() {
        return RichTextContent.getRawText(this.description);
    }
}

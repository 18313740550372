import { Component, Inject, OnInit } from '@angular/core';
import { DxyBaseModalComponent } from '@datagalaxy/ui/dialog';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CollectionsHelper, CoreUtil, DomUtil } from '@datagalaxy/core-util';
import { TranslateService } from '@ngx-translate/core';
import { ConnectionImportExecutionGridCellComponent } from '../connection-import-execution-grid-cell/connection-import-execution-grid-cell.component';
import {
    IImportHistoryModalResolve,
    IImportHistoryRow,
} from './connector-import-history-modal.types';
import { ConnectorService } from '../connector.service';
import { ConnectorUtil } from '../ConnectorUtil';
import { UserCellComponent } from '@datagalaxy/webclient/user/ui';
import { IUserGridCellData } from '@datagalaxy/webclient/user/ui';
import { TextAndToolsCellComponent } from '../../shared/shared-ui/cells/text-and-tools-cell/text-and-tools-cell.component';
import { ImportHistory } from '@datagalaxy/webclient/connectivity/data-access';
import { GridCellType, GridConfig, TColDef } from '@datagalaxy/ui/grid';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'dxy-connector-import-history-modal',
    templateUrl: './dxy-connector-import-history-modal.component.html',
})
export class DxyConnectorImportHistoryModalComponent
    extends DxyBaseModalComponent<IImportHistoryModalResolve, void>
    implements OnInit
{
    public isLoading = true;

    protected gridConfig: GridConfig<IImportHistoryRow> = {
        getItemId: (item) => item.id,
        autoHeight: true,
    };

    protected cols: TColDef<IImportHistoryRow>[] = [
        {
            id: 'startTime',
            headerLabel: this.translate.instant(
                'UI.Connector.ImportHistory.Grid.date'
            ),
            type: GridCellType.text,
            sortable: true,
        },
        {
            id: 'user',
            headerLabel: this.translate.instant(
                'UI.Connector.ImportHistory.Grid.user'
            ),
            type: GridCellType.custom,
            customCellComponent: UserCellComponent,
            getInputs: (item) =>
                ({ userId: item.user.userId } as Partial<
                    UserCellComponent<IImportHistoryRow>
                >),
        },
        {
            id: 'importExecution',
            headerLabel: this.translate.instant(
                'UI.Connector.ImportHistory.Grid.state'
            ),
            type: GridCellType.custom,
            customCellComponent: ConnectionImportExecutionGridCellComponent,
            getInputs: (item) =>
                ({
                    execution: item.importExecution,
                } as Partial<ConnectionImportExecutionGridCellComponent>),
            width: 70,
            sortable: true,
            getSortableValue: (item) => item.importExecution?.status,
        },
        {
            id: 'message',
            headerLabel: this.translate.instant(
                'UI.Connector.ImportHistory.Grid.message'
            ),
            type: GridCellType.custom,
            customCellComponent: TextAndToolsCellComponent,
            getInputs: (item) =>
                ({
                    actions: [
                        {
                            glyphClass: 'glyph-file-copy',
                            callback: (value) =>
                                DomUtil.copyToClipboard(value.message),
                        },
                    ],
                    actionsData: item,
                    text: item.message,
                } as Partial<TextAndToolsCellComponent<IImportHistoryRow>>),
            sortable: true,
            getSortableValue: (item) => item.message,
        },
        {
            id: 'id',
            headerLabel: this.translate.instant(
                'UI.Connector.ImportHistory.Grid.id'
            ),
            type: GridCellType.custom,
            customCellComponent: TextAndToolsCellComponent,
            getInputs: (item) =>
                ({
                    actions: [
                        {
                            glyphClass: 'glyph-file-copy',
                            callback: (value) =>
                                DomUtil.copyToClipboard(value.id),
                        },
                    ],
                    actionsData: item,
                    text: item.id,
                } as Partial<TextAndToolsCellComponent<IImportHistoryRow>>),
            sortable: true,
            getSortableValue: (item) => item.message,
        },
    ];

    protected rows: IImportHistoryRow[] = [];

    constructor(
        dialogRef: MatDialogRef<DxyConnectorImportHistoryModalComponent>,
        @Inject(MAT_DIALOG_DATA) data: IImportHistoryModalResolve,
        private translate: TranslateService,
        private connectorService: ConnectorService
    ) {
        super(dialogRef, data);
        super.subscribe(
            this.connectorService.importUpdateCalled$,
            (importHistory) => this.updateImportHistory(importHistory)
        );
        super.subscribe(
            this.connectorService.savedConnectionsRefreshCalled$,
            () => this.loadImportHistory()
        );
    }

    ngOnInit() {
        this.loadImportHistory().then();
    }

    private async loadImportHistory() {
        const importsHistory = await this.connectorService.getImportHistory(
            this.data.connectionId
        );
        const importHistoryRows: IImportHistoryRow[] = importsHistory.map(
            (item) => this.buildImportHistory(item)
        );
        this.updateGrid(importHistoryRows);
        this.isLoading = false;
    }

    private updateImportHistory(importUpdate: ImportHistory) {
        const predicate = (el: IImportHistoryRow) => el.id == importUpdate.id;
        const replaceBy = this.buildImportHistory(importUpdate);
        CollectionsHelper.replaceOrInsertFirst<IImportHistoryRow>(
            this.rows,
            predicate,
            replaceBy
        );
        this.updateGrid(this.rows);
    }

    private buildImportHistory(data: ImportHistory): IImportHistoryRow {
        return {
            id: data.id,
            startTime: ConnectorUtil.parseDate(data.startTime),
            user: { userId: data.userId } as IUserGridCellData,
            importExecution: { status: data.status },
            message: this.parseImportResult(
                JSON.stringify(data.result) ?? data.cause
            ),
        };
    }

    private parseImportResult(result: string): string {
        try {
            const parsedResult = JSON.parse(result);
            const trimmedResult = this.trimUnwantedFields(parsedResult);

            return trimmedResult ? JSON.stringify(trimmedResult) : '';
        } catch (e) {
            CoreUtil.warn(e);
            return result;
        }
    }

    private trimUnwantedFields(obj: object) {
        for (const key in obj) {
            if (
                key == '$id' ||
                key == '$type' ||
                obj[key] == null ||
                obj[key] == 0
            ) {
                delete obj[key];
            }
            if (typeof obj[key] == 'object') {
                this.trimUnwantedFields(obj[key]);
            }
        }
        return obj;
    }

    private updateGrid(rows: IImportHistoryRow[]) {
        this.rows = [...rows];
    }
}

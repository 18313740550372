import * as moment from 'moment';
import { Moment } from 'moment';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IFieldSelectAdapter } from '@datagalaxy/core-ui';
import {
    EntityTaskDTO,
    ObjectTaskStatus,
    ObjectTaskType,
} from '@datagalaxy/dg-object-model';
import { UserFieldSelectAdapter } from '@datagalaxy/webclient/user/ui';
import { TaskService } from '../task.service';
import { RichTextMentionService } from '../../shared/richText/mentions/rich-text-mention.service';
import { UserService } from '../../services/user.service';
import { ObjectSecurityService } from '../../services/object-security.service';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { FunctionalLogService } from '@datagalaxy/webclient/monitoring/data-access';
import { IMiniEntityContent } from '@datagalaxy/webclient/entity/domain';

@Component({
    selector: 'dxy-task-item',
    templateUrl: 'dxy-task-item.component.html',
    styleUrls: ['dxy-task-item.component.scss'],
})
export class DxyTaskItemComponent extends DxyBaseComponent implements OnInit {
    @Input() task: EntityTaskDTO;
    @Input() isEditActive: boolean;
    @Input() entityData: IMiniEntityContent;
    @Input() readOnly: boolean;

    @Output() readonly onDeleteItem = new EventEmitter<EntityTaskDTO>();
    @Output() readonly onEditItem = new EventEmitter<EntityTaskDTO>();
    @Output() readonly onAfterFormAction = new EventEmitter<void>();
    @Output() readonly onStatusChange = new EventEmitter<EntityTaskDTO>();

    public readonly taskTypeSelectAdapter: IFieldSelectAdapter<ObjectTaskType> =
        {
            isModel: true,
            getTextKey: (v) =>
                `DgServerTypes.ObjectTaskType.${ObjectTaskType[v]}`,
            onSelectionChange: (v) => (this.task.Type = v),
        };
    public readonly taskStatusSelectAdapter: IFieldSelectAdapter<ObjectTaskStatus> =
        {
            isModel: true,
            getTextKey: (v) =>
                `DgServerTypes.ObjectTaskStatus.${ObjectTaskStatus[v]}`,
            onSelectionChange: (v) => (this.task.Status = v),
        };
    public readonly taskAssigneeSelectAdapter = new UserFieldSelectAdapter(
        (v) => (this.task.AssignedToUserId = v.UserId)
    );
    public dueTime: Moment;

    public get userName() {
        return this.userService.getUserName(this.task.CreationUserId);
    }
    public get modificationDate() {
        return this.taskService.getCreationDate(this.task);
    }
    public get featureCode() {
        return FunctionalLogService.getFeatureCode(
            this.entityData,
            'SOCIAL_TASK',
            'U'
        );
    }
    public get isEditEnabled() {
        return (
            !this.readOnly &&
            this.taskService.isEditEnabled(this.task, this.entityData)
        );
    }
    public get isDeleteEnabled() {
        return (
            !this.readOnly &&
            this.taskService.isDeleteEnabled(this.task, this.entityData)
        );
    }
    public get mentionResolvers() {
        return this.richTextMentionService.getDefaultMentionResolvers(
            this.entityData.ReferenceId
        );
    }
    public get isResponseVisible() {
        return this.taskService.isResponseVisible(this.task.Status);
    }

    constructor(
        private taskService: TaskService,
        private richTextMentionService: RichTextMentionService,
        private userService: UserService,
        private objectSecurityService: ObjectSecurityService
    ) {
        super();
    }

    ngOnInit() {
        this.initAsync();
    }

    public async editTask() {
        this.log('editTask', { readOnly: this.readOnly });
        if (this.readOnly) {
            return;
        }

        const updated = await this.taskService.openTaskFormModal(
            this.entityData,
            null,
            this.task
        );

        if (updated) {
            this.task = updated;
            await this.initAsync();
        }
    }

    public deleteTask() {
        if (this.readOnly) {
            return;
        }
        this.onDeleteItem.emit(this.task);
    }

    private async initAsync() {
        this.taskTypeSelectAdapter.options =
            TaskService.getOrderedTaskTypesIncludingObsoleteCurrent(
                this.task.Type
            );
        this.taskTypeSelectAdapter.current = this.task.Type;

        this.updateAvailableStatus(this.task.Status);

        const users = await this.getTaskAssigneeList();
        this.taskAssigneeSelectAdapter.init(users, this.task.AssignedToUserId);

        this.dueTime = this.task.DueTime && moment.utc(this.task.DueTime);
        this.log('init', { dueTime: this.task.DueTime });
    }

    private async getTaskAssigneeList() {
        return await this.objectSecurityService.getObjectUsersAccess(
            this.entityData.ReferenceId
        );
    }

    private updateAvailableStatus(currentStatus: ObjectTaskStatus) {
        this.taskStatusSelectAdapter.current = currentStatus;
        this.taskStatusSelectAdapter.options =
            this.taskService.getAvailableStatusValues(currentStatus);
    }
}

import {
    EntityDksNode,
    FrameDksNode,
    NodeWithCaption,
    NodeWithColor,
    NodeWithSizeMode,
    NodeWithText,
    NoteDksNode,
    TDksNode,
} from '../data-knowledge-studio.types';
import { DiagramNodeKind } from '@datagalaxy/webclient/diagram/data-access';
import { CardinalUtil, ISizeRO } from '@datagalaxy/core-2d-util';
import { EntityNodeUtils } from './entity/entity-node.utils';

export class NodeUtils {
    public static hasColor<NodeData>(
        node: TDksNode<NodeData>
    ): node is NodeWithColor<NodeData> {
        return 'color' in node;
    }

    public static hasCaption<NodeData>(
        node: TDksNode<NodeData>
    ): node is NodeWithCaption<NodeData> {
        return 'caption' in node;
    }

    public static hasSizeMode<NodeData>(
        node: TDksNode<NodeData>
    ): node is NodeWithSizeMode<NodeData> {
        return 'sizeMode' in node;
    }

    public static hasText<NodeData>(
        node: TDksNode<NodeData>
    ): node is NodeWithText<NodeData> {
        return 'text' in node;
    }

    public static isLocked<NodeData>(node: TDksNode<NodeData>) {
        return node?.locked;
    }

    public static isUnlocked<NodeData>(node: TDksNode<NodeData>) {
        return !node?.locked;
    }

    public static isEntityNode<NodeData>(
        node: TDksNode<NodeData>
    ): node is EntityDksNode<NodeData> {
        return node.type === DiagramNodeKind.Entity;
    }

    public static isNoteNode<NodeData>(
        node: TDksNode<NodeData>
    ): node is NoteDksNode<NodeData> {
        return node.type === DiagramNodeKind.Note;
    }

    public static isFrameNode<NodeData>(
        node: TDksNode<NodeData>
    ): node is FrameDksNode<NodeData> {
        return node.type === DiagramNodeKind.Frame;
    }

    public static getMinSize<NodeData>(
        node: TDksNode<NodeData>,
        extraDataVisible?: boolean
    ): ISizeRO {
        switch (node.type) {
            case DiagramNodeKind.Entity:
                return EntityNodeUtils.getSizeModeMinSize(node.sizeMode, {
                    hasColor: !!node.color,
                    extraDataVisible,
                    childrenCount: EntityNodeUtils.countChildren(node, true),
                    showMoreItems: EntityNodeUtils.countShowMoreChildren(
                        node,
                        true
                    ),
                });
            //case DiagramNodeKind.Note:    return DiagramConstants.noteMinSize
            //case DiagramNodeKind.Frame:   return DiagramConstants.frameMinSize
            default:
                return { width: 0, height: 0 };
        }
    }

    public static getMaxSize<NodeData>(
        node: TDksNode<NodeData>,
        extraDataVisible?: boolean
    ): ISizeRO {
        switch (node.type) {
            case DiagramNodeKind.Entity:
                return EntityNodeUtils.getSizeModeMaxSize(node.sizeMode, {
                    hasColor: !!node.color,
                    extraDataVisible,
                    childrenCount: EntityNodeUtils.countChildren(node),
                    showMoreItems: EntityNodeUtils.countShowMoreChildren(node),
                });
            default:
                return null;
        }
    }

    public static getCardinals<NodeData>(node: TDksNode<NodeData>) {
        switch (node.type) {
            case DiagramNodeKind.Entity:
                switch (node.sizeMode) {
                    case 'maxi':
                        return CardinalUtil.allPositions;
                    default:
                        return CardinalUtil.horizontalSides;
                }
            default:
                return null;
        }
    }
}

import {
    BaseServiceParameter,
    BaseServiceResult,
} from '@datagalaxy/data-access';
import { deserializeAs, inheritSerialization, serialize } from 'cerialize';
import { ExploratoryDataLink, ExploratoryDataObject } from './exploratory';

@inheritSerialization(BaseServiceParameter)
export class GetExploratoryAnalyticsDataParameter extends BaseServiceParameter {
    @serialize public RootDataReferenceId?: string;
    @serialize public RootDataTypeName?: string;

    constructor(rootDataReferenceId?: string, rootDataTypeName?: string) {
        super();
        this.RootDataReferenceId = rootDataReferenceId;
        this.RootDataTypeName = rootDataTypeName;
    }
}

@inheritSerialization(BaseServiceResult)
export class GetExploratoryAnalyticsDataResult extends BaseServiceResult {
    @deserializeAs(ExploratoryDataObject)
    public Objects: ExploratoryDataObject[] = [];
    @deserializeAs(ExploratoryDataLink) public Links: ExploratoryDataLink[] =
        [];
}

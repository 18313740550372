import { Component } from '@angular/core';
import { TrendPillColorRule } from '@datagalaxy/core-ui';
import {
    BaseCellComponent,
    IGridCellParams,
} from '@datagalaxy/core-ui/cell-components';
import { StringUtil } from '@datagalaxy/core-util';
import {
    TimeSeriesColorRule,
    TimeSeriesObject,
} from '@datagalaxy/webclient/attribute/domain';

/**
 * ## Role
 * Display last value of a time series and series trend in an omnigrid
 */
@Component({
    selector: 'app-dg-time-series-cell',
    templateUrl: 'dg-time-series-cell.component.html',
    styleUrls: ['dg-time-series-cell.component.scss'],
})
export class DgTimeSeriesCellComponent<
    TEntity = unknown
> extends BaseCellComponent<TEntity, TimeSeriesObject, ITimeSeriesCellParams> {
    public colorRule?: TrendPillColorRule;
    public lastEntryValue?: string;
    public get trend() {
        return this.value?.Trend;
    }

    constructor() {
        super();
    }

    protected override updateBindings() {
        if (this.value?.LastEntry?.Value == undefined) {
            this.lastEntryValue = undefined;
            return;
        }
        this.colorRule = this.getColorRuleFromTimeSeriesColorRule();
        this.lastEntryValue = StringUtil.formatNumber(
            this.value?.LastEntry?.Value
        );
    }

    private getColorRuleFromTimeSeriesColorRule(): TrendPillColorRule {
        const timeSeriesColorRule: TimeSeriesColorRule = this.params.colorRule;
        switch (timeSeriesColorRule) {
            case TimeSeriesColorRule.None:
                return TrendPillColorRule.none;
            case TimeSeriesColorRule.ShouldIncrease:
                return TrendPillColorRule.shouldIncrease;
            case TimeSeriesColorRule.ShouldDecrease:
                return TrendPillColorRule.shouldDecrease;
        }
    }
}

export interface ITimeSeriesCellParams extends IGridCellParams {
    colorRule: TimeSeriesColorRule;
}

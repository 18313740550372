<ng-container *ngIf="showDropdown">
    <button
        mat-icon-button
        class="dg5-dropdown-icon-button"
        [matMenuTriggerFor]="menu"
        [matTooltip]="'UI.GridTemplate.Settings.manageColumns' | translate"
        matTooltipPosition="above"
        (menuOpened)="toggleMenu(true)"
        (menuClosed)="toggleMenu(false)"
    >
        <span class="glyph glyph-grid-columns"></span>
    </button>
    <mat-menu #menu="matMenu" class="omni-grid-column-selector--mat-menu">
        <div *ngIf="menuOpened" class="columns-selector-groups">
            <div class="header">
                <dxy-search-input
                    *ngIf="!hideSearch"
                    class="no-border-top"
                    [showCancelSearch]="true"
                    [searchString]="searchString"
                    (onSearchString)="onSearched($event)"
                ></dxy-search-input>
                <div class="header-reset" (click)="onResetClick($event)">
                    <span class="glyph-reload glyph"></span>
                    <span class="text" translate
                        >UI.GridTemplate.Settings.resetState</span
                    >
                </div>
            </div>
            <div class="body" (click)="$event.stopPropagation()">
                <ng-container
                    *ngFor="let section of columnGroups; let last = last"
                >
                    <div
                        *ngIf="isSectionOrSubSectionVisible(section)"
                        class="group"
                        [class.group-border]="!last"
                    >
                        <dxy-collapsible
                            *ngIf="menuOpened"
                            [titleKey]="section.textTranslateKey"
                            [noHeader]="hideCollapse"
                        >
                            <ul
                                *ngIf="isSectionVisible(section)"
                                class="dg5-list-unstyled"
                            >
                                <ng-container
                                    [ngTemplateOutlet]="sectionLine"
                                    [ngTemplateOutletContext]="{section}"
                                ></ng-container>
                            </ul>
                            <div
                                *ngIf="isSubSectionVisible(section)"
                                class="sub-sections"
                            >
                                <div
                                    *ngFor="
                                        let subSection of section.subSections
                                    "
                                >
                                    <ul
                                        *ngIf="subSection.options.length"
                                        class="dg5-list-unstyled"
                                    >
                                        <ng-container
                                            [ngTemplateOutlet]="sectionLine"
                                            [ngTemplateOutletContext]="{
                                                section: subSection
                                            }"
                                        ></ng-container>
                                    </ul>
                                </div>
                            </div>
                        </dxy-collapsible>
                    </div>
                </ng-container>
            </div>
        </div>
    </mat-menu>
</ng-container>

<ng-template let-section="section" #sectionLine>
    <li
        *ngFor="let column of getSectionColumns(section)"
        class="group-item"
        role="menuitem"
    >
        <mat-checkbox
            class="checkbox-small"
            [(ngModel)]="column.visible"
            (change)="onColumnToggled(column)"
        >
            <i
                class="attribute-meta-type-icon"
                [ngClass]="getColumnGlyphClassInternal(column)"
            ></i>
            <span
                [innerHTML]="getFoundColumnText(column)"
                [dxyEllipsisTooltip]="column.displayName"
                class="group-item-displayname"
            ></span>
            <i
                *ngIf="column.isSystem"
                class="glyph-logo-dg system-field-icon"
            ></i>
        </mat-checkbox>
    </li>
</ng-template>

import { NgModule } from '@angular/core';
import { AttributeFilterModule } from '../shared/attribute/attribute-filter/AttributeFilterModule';
import { DxyFilterModule } from '../shared/filter/DxyFilterModule';
import { SharedModule } from '../shared/shared.module';
import { EntityCardModule } from '../shared/entityCard/entity-card.module';
import { DxySpotlightResultContainerComponent } from './dxy-spotlight-result-container/dxy-spotlight-result-container.component';
import { DxySpotlightInputComponent } from './dxy-spotlight-input/dxy-spotlight-input.component';
import { DxySpaceSelectorModule } from '../space-selector/DxySpaceSelectorModule';
import { DxySharedUiModule } from '../shared/shared-ui/DxySharedUiModule';
import { DxySpotlightResultSectionComponent } from './dxy-spotlight-result-section/dxy-spotlight-result-section.component';
import { DxySpotlightResultItemComponent } from './dxy-spotlight-result-item/dxy-spotlight-result-item.component';

@NgModule({
    imports: [
        EntityCardModule,
        DxySharedUiModule,
        DxyFilterModule,
        SharedModule,
        DxySpaceSelectorModule,
        AttributeFilterModule,
    ],
    declarations: [
        DxySpotlightInputComponent,
        DxySpotlightResultItemComponent,
        DxySpotlightResultSectionComponent,
        DxySpotlightResultContainerComponent,
    ],
    exports: [DxySpotlightInputComponent, DxySpotlightResultSectionComponent],
})
export class DxySpotlightModule {}

import {
    distanceToOrigin,
    widgetsByDistance,
} from '../../../common/dashboard-grid-utils';
import { DashboardSection, WidgetInstance } from '../../../domain';
import { DashboardState } from '../dashboard-state';
import { getPreviousPosition } from '../get-previous-position';
import { sectionReducer } from './section-reducer';

export function removeWidgetInstanceReducer(
    state: DashboardState,
    section: DashboardSection,
    wi: WidgetInstance
) {
    const removedInstanceDistance = distanceToOrigin(wi.position);
    return sectionReducer(state, section, {
        widgetInstances: [
            ...section.widgetInstances
                .filter((w) => w.guid != wi.guid)
                .map((w) => {
                    const d = distanceToOrigin(w.position);
                    if (d > removedInstanceDistance) {
                        return {
                            ...w,
                            position: getPreviousPosition(w.position),
                        };
                    }
                    return w;
                })
                .sort(widgetsByDistance),
        ],
    });
}

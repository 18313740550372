import { Injectable } from '@angular/core';
import { Dashboard } from '@datagalaxy/webclient/dashboard/ui';
import { SpaceHomePageDto } from '@datagalaxy/webclient/workspace/data-access';
import { deserializeDashboard } from './dashboard-deserializer';
import { DefaultSpaceHomeDashboardBuilder } from './default-dashboard';
import { updateSpaceHomeHeaderReducer } from './update-header/update-space-home-header-reducer';
import { UpdateSpaceHomeHeaderAction } from './update-header/update-space-home-header-action';
import { updateSpaceHomeDashboardReducer } from './update-dashboard/update-space-home-dashboard-reducer';
import { disableEditiondReducer } from './disable-edition/disable-edition-reducer';
import { BaseStateService } from '@datagalaxy/utils';

export interface SpaceHomeState {
    dashboard?: Dashboard;
    spaceHomePage?: SpaceHomePageDto;
    editionEnabled: boolean;
}

@Injectable({ providedIn: 'root' })
export class SpaceHomeStateService extends BaseStateService<SpaceHomeState> {
    constructor(
        private defaultDashboardBuilder: DefaultSpaceHomeDashboardBuilder
    ) {
        super({
            editionEnabled: false,
        });
    }

    public init(spaceHomePage: SpaceHomePageDto) {
        this.setState({
            spaceHomePage,
            dashboard:
                deserializeDashboard(spaceHomePage?.Config) ??
                this.defaultDashboardBuilder.build(),
            editionEnabled: false,
        });
    }
    public reinitState() {
        this.state$.next({ editionEnabled: false });
    }

    public getSpaceGuid() {
        return this.state.spaceHomePage?.SpaceUid;
    }
    public getSpaceHomePage(): SpaceHomePageDto {
        return this.state.spaceHomePage;
    }

    public selectDashboard() {
        return this.select((s) => s.dashboard);
    }
    public selectSpaceHomePage() {
        return this.select((s) => s.spaceHomePage);
    }

    public selectEditionEnabled() {
        return this.select((s) => s.editionEnabled);
    }

    public enableEdition() {
        this.setState({
            editionEnabled: true,
        });
    }

    public disableEdition() {
        this.setState(disableEditiondReducer(this.state));
    }

    public updateHeader(action: UpdateSpaceHomeHeaderAction) {
        this.setState(updateSpaceHomeHeaderReducer(this.state, action));
    }

    public updateDashboard(dashboard: Dashboard) {
        this.setState(updateSpaceHomeDashboardReducer(this.state, dashboard));
    }
}

import { Component, Input } from '@angular/core';
import { DxyTestErrorModalComponent } from '../dxy-test-error-modal/dxy-test-error-modal.component';
import { DxyModalService } from '../../../../shared/dialogs/DxyModalService';
import { DxyBaseComponent } from '@datagalaxy/ui/core';

@Component({
    selector: 'dxy-test-error-message',
    templateUrl: './dxy-test-error-message.component.html',
    styleUrls: ['dxy-test-error-message.component.scss'],
})
export class DxyTestErrorMessageComponent extends DxyBaseComponent {
    @Input() message: string;

    constructor(private dxyModalService: DxyModalService) {
        super();
    }

    public openModal() {
        this.dxyModalService.open<DxyTestErrorModalComponent, string, void>({
            componentType: DxyTestErrorModalComponent,
            data: this.message,
        });
    }
}

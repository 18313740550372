import {
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import { IImportExecution } from '../saved-connections.types';
import { DateTimeUtil } from '@datagalaxy/core-util';
import { ImportStatus } from '@datagalaxy/webclient/connectivity/data-access';
import { DxyBaseComponent } from '@datagalaxy/ui/core';

/**
 * ## Role
 * Import execution grid cell containing import status icon and execution date
 */
@Component({
    selector: 'dxy-connection-import-execution-grid-cell',
    templateUrl: './connection-import-execution-grid-cell.component.html',
    styleUrls: ['./connection-import-execution-grid-cell.component.scss'],
})
export class ConnectionImportExecutionGridCellComponent
    extends DxyBaseComponent
    implements OnChanges, OnInit
{
    @Input() execution?: IImportExecution;

    public status?: ImportStatus;
    public importDate?: string;

    public readonly ImportStatus = ImportStatus;

    public constructor() {
        super();
    }

    ngOnInit() {
        this.init();
    }

    ngOnChanges(changes: SimpleChanges) {
        this.onChange(changes, 'execution', () => {
            this.init();
        });
    }

    private init() {
        if (!this.execution) {
            return;
        }
        const key = this.execution.status;
        this.status = key ? ImportStatus[key] : ImportStatus.unknown;
        if (!this.execution.importDate) {
            return;
        }
        this.importDate = DateTimeUtil.formatForDisplay(
            this.execution.importDate,
            true
        );
    }
}

<app-lineage
    *ngIf="isActiveToolLineage"
    [entityData]="entityData"
></app-lineage>
<app-lineage-graph
    *ngIf="isActiveToolLineageLegacyReady"
    [graphParams]="lineageGraphParams"
></app-lineage-graph>
<app-exploratory-graph
    *ngIf="isActiveToolExplorer"
    [graphData]="explorationGraphData"
></app-exploratory-graph>

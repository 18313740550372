import { deserialize, deserializeAs, inheritSerialization } from 'cerialize';
import {
    BaseLinkDataInfo,
    HierarchyDataDescriptor,
    ServerType,
} from '@datagalaxy/dg-object-model';

export class ExploratoryDataObject {
    @deserializeAs(HierarchyDataDescriptor)
    public Data: HierarchyDataDescriptor = new HierarchyDataDescriptor();
    @deserializeAs(HierarchyDataDescriptor)
    public Parents: HierarchyDataDescriptor[] = [];
}

@inheritSerialization(BaseLinkDataInfo)
export class ExploratoryDataLink extends BaseLinkDataInfo {
    @deserialize public SourceId!: string;
    @deserialize public TargetId!: string;
    @deserialize public TargetTypeName!: string;
    @deserialize public LinkTypeName!: string;
    @deserialize public LinkObjectId!: string;
    @deserialize public IsReverse!: boolean;
    public readonly ServerType = ServerType.ExploratoryDataLink;
}

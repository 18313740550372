<form *ngIf="editionEnabled; else titleTemplate" [formGroup]="customTitleForm">
    <dxy-title-input
        class="small"
        #titleInput
        [maxLength]="50"
        formControlName="customTitle"
        [placeholder]="'Dashboard.Section.emptyTitle' | translate"
        (blurInput)="onBlur()"
    >
    </dxy-title-input>
</form>
<ng-template #titleTemplate>
    <h3 dxyEllipsisTooltip>{{ customTitle || defaultTitleKey | translate }}</h3>
</ng-template>

import { AttributeMetaType } from '@datagalaxy/webclient/attribute/domain';

export function getAttributeGlyphClass(
    attributeType?: AttributeMetaType
): string {
    switch (attributeType) {
        case AttributeMetaType.Text:
        case AttributeMetaType.MultiLineText:
            return 'glyph-text';
        case AttributeMetaType.Number:
            return 'glyph-number';
        case AttributeMetaType.DateTime:
        case AttributeMetaType.Date:
            return 'glyph-date';
        case AttributeMetaType.FormattedText:
            return 'glyph-richtext';
        case AttributeMetaType.ManagedTag:
        case AttributeMetaType.ClientTag:
            return 'glyph-domain1';
        case AttributeMetaType.MultiValueList:
            return 'glyph-multi-value';
        case AttributeMetaType.PersonReference:
            return 'glyph-person';
        case AttributeMetaType.UserGuid:
        case AttributeMetaType.UserReference:
        case AttributeMetaType.StewardUserReference:
            return 'glyph-user-unlock';
        case AttributeMetaType.Boolean:
            return 'glyph-checkbox';
        case AttributeMetaType.ValueList:
        case AttributeMetaType.Technology:
        case AttributeMetaType.DataTypeAndSize:
        case AttributeMetaType.SystemEntityType:
        case AttributeMetaType.Reference:
            return 'glyph-valuelist';
        case AttributeMetaType.HtmlLink:
            return 'glyph-hyperlink';
        case AttributeMetaType.Hierarchy:
        case AttributeMetaType.EntityReference:
            return 'glyph-hierarchy';
        case AttributeMetaType.EntityLinkShortcut:
        case AttributeMetaType.EntityLogicalParent:
            return 'glyph-link';
        case AttributeMetaType.TimeSeriesObject:
            return 'glyph-time-series';
        case AttributeMetaType.AllLinkedData:
        case AttributeMetaType.ObjectLink:
            return 'glyph-diagram';
        default:
            return '';
    }
}

import { NgModule } from '@angular/core';
import { DxyInsightActivityComponent } from './dxy-insight-activity/dxy-insight-activity.component';
import { DxyInsightDataComponent } from './dxy-insight-data/dxy-insight-data.component';
import { DxyInsightTopContributorsComponent } from './dxy-insight-top-contributors/dxy-insight-top-contributors.component';
import { SharedModule } from '../shared/shared.module';
import { DxySharedUiModule } from '../shared/shared-ui/DxySharedUiModule';

@NgModule({
    imports: [SharedModule, DxySharedUiModule],
    declarations: [
        DxyInsightTopContributorsComponent,
        DxyInsightActivityComponent,
        DxyInsightDataComponent,
    ],
    exports: [DxyInsightDataComponent],
})
export class DxyInsightsModule {}

import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { EntityUiService } from '../../../shared/entity/services/entity-ui.service';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';

/**
 * ## Role
 * Display a list of EntityItems
 */
@Component({
    selector: 'app-space-filtered-view-entities',
    templateUrl: './space-filtered-view-entities.component.html',
    styleUrls: ['./space-filtered-view-entities.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpaceFilteredViewEntitiesComponent {
    @Input() entities?: EntityItem[];
    @Output() showEverythingClick = new EventEmitter();

    protected entityActions = [
        this.entityUiService.getEntityCellNavigationMenu(
            'FILTERED_VIEW_OBJECTS_WIDGET'
        ),
    ];

    protected get hasEntities() {
        return !!this.entities?.length;
    }
    protected get diplayedEntities() {
        return this.entities?.slice(0, this.lastIndex) ?? [];
    }
    protected get showMoreVisible() {
        if (this.currentPage >= 2) {
            return false;
        }
        return this.lastIndex < this.entities.length;
    }
    protected get showEverythingVisible() {
        return !this.showMoreVisible && this.lastIndex === this.entities.length;
    }

    private currentPage = 0;
    private pageSize = 15;
    private get lastIndex() {
        return (this.currentPage + 1) * this.pageSize;
    }

    constructor(private entityUiService: EntityUiService) {}

    protected showMore() {
        if (this.currentPage >= 2) {
            return;
        }
        this.currentPage++;
    }
    protected showEverything() {
        this.showEverythingClick.emit();
    }

    protected async onRowClick(entity: EntityItem) {
        await this.entityUiService.openPreviewOrDetailsOrDefault(entity);
    }
}

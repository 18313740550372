import { Component, HostListener, Inject } from '@angular/core';
import { DxyBaseModalComponent } from '@datagalaxy/ui/dialog';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
    IPrimaryKeySettingsInput,
    IPrimaryKeySettingsOutput,
} from '../pkfk.types';
import { KeyboardUtil } from '@datagalaxy/utils';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'dxy-primary-key-settings-modal',
    templateUrl: './dxy-primary-key-settings-modal.component.html',
    styleUrls: ['./dxy-primary-key-settings-modal.component.scss'],
})
export class DxyPrimaryKeySettingsModalComponent extends DxyBaseModalComponent<
    IPrimaryKeySettingsInput,
    IPrimaryKeySettingsOutput
> {
    public get isValidPkSettings() {
        return this.result?.isValidSettings;
    }

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: IPrimaryKeySettingsInput,
        public dialogRef: MatDialogRef<
            DxyPrimaryKeySettingsModalComponent,
            IPrimaryKeySettingsOutput
        >
    ) {
        super(dialogRef, data);
    }

    public onOutputChange(output: IPrimaryKeySettingsOutput) {
        this.log('onOutputChange', output);
        this.result = output;
    }

    @HostListener('keydown', ['$event'])
    private keyDownModal({ event }: { event: KeyboardEvent }) {
        if (KeyboardUtil.isEnterKey(event) && this.isValidPkSettings) {
            this.onCloseSubmit();
        }
    }
}

import {
    IInjectable,
    ResolveTypes,
    StateParams,
    TargetState,
    Transition,
    TransitionService,
} from '@uirouter/core';
import { DataUtil } from '../DataUtil';
import { HddUtil } from '../HddUtil';
import { StateName } from '@datagalaxy/webclient/routing';
import { Ng2StateDeclaration } from '@uirouter/angular';
import { ViewIdentifier } from '../ViewIdentifier';
import { environment } from '../../../../environments/environment';
import { DxyModuleRootComponent } from '../../../moduleMain/dxy-module-root/dxy-module-root.component';
import { AttributeDataService } from '../../attribute/attribute-data.service';
import {
    EntityType,
    FirstClassType,
    FirstClassTypeUtil,
    IEntityIdentifier,
    ServerType,
} from '@datagalaxy/dg-object-model';
import { EntityService } from '../../entity/services/entity.service';
import { EntitySecurityService } from '../../entity/services/entity-security.service';
import { DxyModuleMainComponent } from '../../../moduleMain/dxy-module-main/dxy-module-main.component';
import { ImpactAnalysisMainComponent } from '../../../impactAnalysis/impact-analysis-main/impact-analysis-main.component';
import { DxyNavbarTitleComponent } from '../../../navbar/dxy-navbar-title/dxy-navbar-title.component';
import {
    EntityGridRowClickAction,
    IEntityGridOptions,
} from '../../entity/entity-grid/EntityGridOptions';
import { DatamapGraphComponent } from '../../../impactAnalysis/datamap-graph/datamap-graph.component';
import { FilteredViewService } from '../../filter/services/filteredView.service';
import { DxyEntityDetailsComponent } from '../../entity/dxy-entity-details/dxy-entity-details.component';
import { EntityDashboardComponent } from '../../../entity-dashboard/entity-dashboard/entity-dashboard.component';
import { NavigationService } from '../../../services/navigation.service';
import { ImpactAnalysisService } from '../../../impactAnalysis/impact-analysis.service';
import { EntityDockingPaneService } from '../../entity/services/entity-docking-pane.service';
import { DiagramsListComponent } from '../../../diagrams/diagrams-list/diagrams-list.component';
import { DxyEntityLinksComponent } from '../../entity/dxy-entity-links/dxy-entity-links.component';
import { DxyEntityGridComponent } from '../../entity/entity-grid/dxy-entity-grid/dxy-entity-grid.component';
import { Constants } from '../Constants';
import { ImpactAnalysisTool } from '../app-types/impact-analysis.types';
import ParamKey = Constants.Nav.ParamKey;
import UrlWord = Constants.Nav.UrlWord;
import {
    ModuleSecurityData,
    Space,
} from '@datagalaxy/webclient/workspace/data-access';
import { LineageVersionTogglerService } from '../../../impactAnalysis/entity-impact-analysis/lineage-version-toggler.service';
import { EntityImpactAnalysisComponent } from '../../../impactAnalysis/entity-impact-analysis/entity-impact-analysis.component';
import { EntityIdentifier } from '@datagalaxy/webclient/entity/utils';
import { SpaceIdentifier } from '@datagalaxy/webclient/workspace/utils';
import { DgZone } from '@datagalaxy/webclient/domain';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';
import { DgModule } from '@datagalaxy/shared/dg-module/domain';
import { ABaseSecurityData } from '@datagalaxy/webclient/security/domain';

/** Definitions of the navigation states common to the 4 entity modules (Glossary, Catalog, Processing, Usage) */
export class CommonStateDefinitions {
    private static debug = false;

    public static dgModuleRouterInit(
        enteringStateName: string,
        transitionService: TransitionService
    ) {
        transitionService.onError(
            { entering: enteringStateName },
            CommonStateDefinitions.toClientErrorTransitionHookFn
        );
    }
    public static readonly toClientErrorTransitionHookFn = (
        transition: Transition
    ): TargetState =>
        transition.router.stateService.target(StateName.ClientError);

    //#region Modules states

    public static ModuleStates(dgModule: DgModule): Ng2StateDeclaration[] {
        const names = CommonStateDefinitions.getStateNames(dgModule);
        const isCatalog = dgModule == DgModule.Catalog;
        CommonStateDefinitions.log(
            'ModuleStates',
            DgModule[dgModule],
            names,
            isCatalog
        );
        return [
            CommonStateDefinitions.ModuleRoot(names.root, dgModule),
            CommonStateDefinitions.ModuleData(names.data, isCatalog),
            CommonStateDefinitions.ModuleMain(names.main),
            CommonStateDefinitions.ModuleEntityGrid(names.grid),
            CommonStateDefinitions.ModuleEntityList(names.list, isCatalog),
            CommonStateDefinitions.ModuleEntityDashboard(
                names.dashboard,
                dgModule
            ),
            CommonStateDefinitions.EntityDetails(names.details),
        ];
    }
    private static getStateNames(dgModule: DgModule) {
        switch (dgModule) {
            case DgModule.Glossary:
                return {
                    root: StateName.Glossary,
                    data: StateName.GlossaryData,
                    main: StateName.GlossaryMain,
                    grid: StateName.GlossaryGrid,
                    list: StateName.GlossaryList,
                    dashboard: StateName.GlossaryDashboard,
                    details: StateName.GlossaryDetails,
                };
            case DgModule.Catalog:
                return {
                    root: StateName.Modeler,
                    data: StateName.ModelerData,
                    main: StateName.ModelerMain,
                    grid: StateName.ModelerGrid,
                    list: StateName.ModelerList,
                    dashboard: StateName.ModelerDashboard,
                    details: StateName.ModelerDetailsTab,
                };
            case DgModule.Usage:
                return {
                    root: StateName.Software,
                    data: StateName.SoftwareData,
                    main: StateName.SoftwareMain,
                    grid: StateName.SoftwareGrid,
                    list: StateName.SoftwareList,
                    dashboard: StateName.SoftwareDashboard,
                    details: StateName.SoftwareDetails,
                };
            case DgModule.Processing:
                return {
                    root: StateName.DataProcessing,
                    data: StateName.DataProcessingData,
                    main: StateName.DataProcessingMain,
                    grid: StateName.DataProcessingGrid,
                    list: StateName.DataProcessingList,
                    dashboard: StateName.DataProcessingDashboard,
                    details: StateName.DataProcessingDetails,
                };
            case DgModule.Diagram:
                return {
                    root: StateName.Diagrams,
                    data: StateName.DiagramsData,
                    main: StateName.DiagramsMain,
                    grid: StateName.DiagramsGrid,
                    list: StateName.DiagramsList,
                    dashboard: StateName.DiagramsDashboard,
                    details: StateName.DiagramsDetails,
                };
            default:
                throw 'Not implemented';
        }
    }

    private static ModuleRoot(
        name: string,
        dgModule: DgModule
    ): Ng2StateDeclaration {
        const urlWord = (() => {
            switch (dgModule) {
                case DgModule.Glossary:
                    return UrlWord.glossary;
                case DgModule.Catalog:
                    return UrlWord.catalog;
                case DgModule.Processing:
                    return UrlWord.processing;
                case DgModule.Usage:
                    return UrlWord.usage;
                case DgModule.Diagram:
                    return UrlWord.diagrams;
            }
        })();
        return {
            name,
            abstract: true,
            url: '/' + urlWord,
            params: {
                [ParamKey.AutoEntityTypeFilter]: null,
                [ParamKey.FilteredViewId]: -1,
                [ParamKey.PreFilterWord]: null,
            },
            component: DxyModuleRootComponent,
            resolve: [
                {
                    token: 'dgModule',
                    resolveFn: () => dgModule,
                },
                {
                    //#Archi-cleanup (fbo 20210115) could be moved down in the hierarchy
                    token: 'serverType',
                    resolveFn: () =>
                        DataUtil.getDefaultServerTypeFromModule(dgModule),
                },
                {
                    //#Archi-cleanup (fbo 20210115) seems not used anymore, or is for caching purpose ?
                    token: 'typeAttributes',
                    deps: ['serverType', AttributeDataService],
                    resolveFn: (
                        serverType: ServerType,
                        attributeDataService: AttributeDataService
                    ) =>
                        attributeDataService.loadAttributes(
                            [ServerType[serverType]],
                            false
                        ),
                },
                {
                    token: 'moduleSecurityData',
                    deps: ['spaceData', EntitySecurityService],
                    resolveFn: (
                        spaceData: Space,
                        entitySecurityService: EntitySecurityService
                    ) =>
                        entitySecurityService.getModuleRootSecurityData(
                            dgModule,
                            spaceData
                        ),
                },
                {
                    // By Default, the Security Data is the module security data.
                    // Further down the tree, the securityData is then precised to a specific entity
                    token: 'securityData',
                    deps: ['moduleSecurityData'],
                    resolveFn: (moduleSecurityData: ABaseSecurityData) =>
                        moduleSecurityData,
                },
                {
                    token: '_resetDockingPane',
                    deps: [EntityDockingPaneService],
                    resolveFn: (
                        entityDockingPaneService: EntityDockingPaneService
                    ) => entityDockingPaneService.resetPanel(),
                },
                CommonStateDefinitions.logResolve('ModuleRoot'),
                CommonStateDefinitions.getResolveNavbarCurrentFilteredView(
                    DgZone.Module,
                    { dgModule, loadFavorite: true }
                ),
            ],
        };
    }

    private static ModuleData(name: string, isCatalog = false) {
        const result: Ng2StateDeclaration = {
            name,
            abstract: true,
            url: '/:' + ParamKey.PreFilterWord,
            params: {
                [ParamKey.FilteredViewId]: -1,
                [ParamKey.PreFilterWord]: {
                    value: null,
                    squash: NavigationService.NoDefaultParamValueInUrl,
                },
            },
            resolve: [
                {
                    token: 'currentEntityId',
                    resolveFn: () => null,
                },
                {
                    token: 'dgZone',
                    resolveFn: () => DgZone.Module,
                },
                CommonStateDefinitions.logResolve('ModuleData'),
            ],
        };

        if (isCatalog) {
            CommonStateDefinitions.addMoreResolve(result, [
                {
                    token: 'modelId',
                    deps: ['$transition$'],
                    resolveFn: ($transition$: Transition) =>
                        $transition$.params()[ParamKey.ModelId],
                },
                {
                    token: 'parentDataId',
                    deps: ['spaceData', 'modelId'],
                    resolveFn: (spaceData: Space, modelId: string) =>
                        modelId || spaceData.ReferenceId,
                },
                {
                    token: 'securityData',
                    deps: ['moduleSecurityData'],
                    resolveFn: (moduleSecurityData: ModuleSecurityData) =>
                        moduleSecurityData,
                },
            ]);
        }

        return result;
    }

    private static ModuleMain(name: string): Ng2StateDeclaration {
        return {
            name,
            views: {
                'content@^.^': { component: DxyModuleMainComponent },
                'navbarTitle@^.^.^.^': { component: DxyNavbarTitleComponent },
            },
            resolve: [
                {
                    token: 'dgModule',
                    deps: ['dgModule'],
                    resolveFn: (dgModule: DgModule) => dgModule,
                },
                {
                    token: 'spaceIdr',
                    deps: ['spaceData'],
                    resolveFn: (spaceData: Space) =>
                        SpaceIdentifier.from(spaceData),
                },
                {
                    token: 'moduleSecurityData',
                    deps: ['moduleSecurityData'],
                    resolveFn: (moduleSecurityData: ModuleSecurityData) =>
                        moduleSecurityData,
                },
                {
                    token: 'currentViewIdentifier',
                    deps: ['dgModule'],
                    resolveFn: (dgModule: DgModule) => {
                        switch (dgModule) {
                            case DgModule.Glossary:
                                return ViewIdentifier.Glossary;
                            case DgModule.Catalog:
                                return ViewIdentifier.Modeler;
                            case DgModule.Processing:
                                return ViewIdentifier.DataProcessing;
                            case DgModule.Usage:
                                return ViewIdentifier.Software;
                            case DgModule.Diagram:
                                return ViewIdentifier.Diagrams;
                        }
                    },
                },
                CommonStateDefinitions.logResolve('ModuleMain'),
            ],
        };
    }

    // this *dgModule* argument will be replaced by a isCatalog boolean, once getEntityIdParamName has been removed
    /** content view holding (details, etc) tabs for an entity */
    private static ModuleEntityDashboard(name: string, dgModule: DgModule) {
        const entityIdParameterKey =
            NavigationService.getEntityIdParamName(dgModule);
        const result: Ng2StateDeclaration = {
            name,
            abstract: true,
            url: '/:' + entityIdParameterKey,
            params: {
                [entityIdParameterKey]: { value: null, squash: true },
                [ParamKey.EntityType]: null,
            },
            views: { 'content@^.^': { component: EntityDashboardComponent } },
            resolve: [
                {
                    token: 'dgModule',
                    deps: ['dgModule'],
                    resolveFn: (dgModule: DgModule) => dgModule,
                },
                {
                    token: 'spaceIdr',
                    deps: ['spaceIdr'],
                    resolveFn: (spaceData: Space) =>
                        SpaceIdentifier.from(spaceData),
                },
                {
                    token: 'moduleSecurityData',
                    deps: ['moduleSecurityData'],
                    resolveFn: (moduleSecurityData: ModuleSecurityData) =>
                        moduleSecurityData,
                },
                CommonStateDefinitions.logResolve('ModuleEntityDashboard'),
            ],
        };

        if (dgModule == DgModule.Catalog) {
            CommonStateDefinitions.addMoreResolve(result, [
                {
                    token: 'parentDataId',
                    deps: ['entityData'],
                    resolveFn: (entityData: EntityItem) =>
                        !entityData || entityData.ServerType == ServerType.Model
                            ? null
                            : HddUtil.getModelId(entityData.HddData),
                },
                {
                    token: 'securityData',
                    deps: ['entityData'],
                    resolveFn: (entityData: EntityItem) =>
                        entityData?.SecurityData,
                },
            ]);
        }

        if (
            Array.isArray(result.resolve) &&
            !result.resolve.some((r) => r['token'] == 'entityData')
        ) {
            CommonStateDefinitions.addMoreResolve(result, [
                {
                    token: 'entityData',
                    deps: [
                        'dgModule',
                        '$transition$',
                        NavigationService,
                        EntityService,
                    ],
                    resolveFn: async (
                        dgModule: DgModule,
                        $transition$: Transition,
                        navigationService: NavigationService,
                        entityService: EntityService
                    ) => {
                        // The IEntityIdentifier forged here may have a wrong Servertype (i.e., a table will have a Model serverType)
                        // But it's not a problem for getting the entity from the server
                        const defaultServerType =
                            DataUtil.getDefaultServerTypeFromModule(dgModule);

                        // EntityIdr may not have entityType
                        let entityIdr: IEntityIdentifier =
                            NavigationService.getEntityIdrFromParams(
                                defaultServerType,
                                $transition$.params()
                            );
                        /*
                         * To load the entity with only necessaries attributes we need the entityType
                         * To avoid an unnecessary call to getEntityForDetails to get the entityType
                         * we check if the entityType is set in params
                         * All calls to this route via navigationService should set entityType param
                         * The only exception should be a direct access via URL
                         */
                        if (entityIdr) {
                            if (!entityIdr.entityType) {
                                this.debug &&
                                    !environment.production &&
                                    console.warn(
                                        'ModuleEntityDashboard: entityType param is not set'
                                    );
                                const entity =
                                    await entityService.getEntityForDetails(
                                        entityIdr,
                                        ['EntityType'],
                                        false
                                    );
                                entityIdr = EntityIdentifier.from(entity);
                            }
                            const entity =
                                await entityService.getMinimalEntityForDetails(
                                    entityIdr,
                                    true
                                );
                            if (entity) {
                                const modelHdd = HddUtil.getModelHdd(
                                    entity.HddData
                                );
                                if (modelHdd) {
                                    navigationService.setCurrentModelData(
                                        modelHdd
                                    );
                                }
                            }
                            return entity;
                        } else {
                            this.debug &&
                                !environment.production &&
                                console.warn(
                                    'ModuleEntityDashboard: no entityIdr'
                                );
                        }
                    },
                },
            ]);
        }

        return result;
    }

    private static ModuleEntityList(
        name: string,
        isCatalog = false
    ): Ng2StateDeclaration {
        const result: Ng2StateDeclaration = {
            name,
            url: `/${UrlWord.list}/:${ParamKey.IsHierarchicalWord}/:${ParamKey.IsEntityFullPageWord}`,
            params: {
                [ParamKey.IsHierarchicalWord]: {
                    value: null,
                    squash: NavigationService.NoDefaultParamValueInUrl,
                },
                [ParamKey.IsEntityFullPageWord]: {
                    value: null,
                    squash: NavigationService.NoDefaultParamValueInUrl,
                },
            },
            views: { list: { component: DxyEntityGridComponent } },
            resolve: [
                {
                    token: 'securityData',
                    deps: ['securityData'],
                    resolveFn: (securityData: ABaseSecurityData) =>
                        securityData,
                },
                {
                    token: 'serverType',
                    deps: ['serverType'],
                    resolveFn: (serverType: ServerType) => serverType,
                },
                {
                    token: 'spaceIdr',
                    deps: ['spaceData'],
                    resolveFn: (spaceData: Space) => spaceData,
                },
                {
                    token: 'egOptions',
                    resolveFn: () =>
                        ({
                            isSingleColumn: true,
                            rowClickAction:
                                EntityGridRowClickAction.showEntityDetail,
                            burgerMenuConfiguration: {
                                actionButton: true,
                            },
                            noNavLink: true,
                            noLabelNavLink: true,
                            showHierarchicalSwitch: true,
                            preserveCurrentSingleSelectedOnEntityCreated: true,
                            actionButtonOpenEntityPane: true,
                            dgZone: DgZone.Module,
                        } as IEntityGridOptions),
                },
                {
                    token: 'parentDataId',
                    deps: ['parentDataId', 'spaceData'],
                    resolveFn: (parentDataId: string, spaceData: Space) =>
                        isCatalog ? spaceData.ReferenceId : parentDataId,
                },
                {
                    token: 'currentEntityId',
                    deps: ['dgModule', '$transition$'],
                    resolveFn: (dgModule: DgModule, $transition$: Transition) =>
                        NavigationService.getEntityIdFromParams(
                            dgModule,
                            $transition$.params()
                        ),
                },
                {
                    token: 'logId',
                    deps: ['dgModule'],
                    resolveFn: (dgModule: DgModule) =>
                        `list-${DgModule[dgModule]}`,
                },
                {
                    token: 'dtContext',
                    resolveFn: () => 'Entity List',
                },
                {
                    token: '_initIsHierarchical',
                    deps: ['$transition$', NavigationService],
                    resolveFn: (
                        $transition$: Transition,
                        navigationService: NavigationService
                    ) =>
                        navigationService.initIsHierarchical(
                            $transition$.params()[ParamKey.IsHierarchicalWord]
                        ),
                },
                {
                    token: '_isEntityFullPage',
                    deps: ['$transition$', NavigationService],
                    resolveFn: (
                        $transition$: Transition,
                        navigationService: NavigationService
                    ) =>
                        navigationService.initIsEntityFullPage(
                            $transition$.params()[ParamKey.IsEntityFullPageWord]
                        ),
                },
                CommonStateDefinitions.logResolve('ModuleEntityList'),
            ],
        };
        return CommonStateDefinitions.addMoreResolve(
            result,
            undefined,
            isCatalog
        );
    }

    private static ModuleEntityGrid(name: string): Ng2StateDeclaration {
        return {
            name,
            url: `/${UrlWord.grid}/:${ParamKey.IsHierarchicalWord}`,
            params: {
                [ParamKey.IsHierarchicalWord]: {
                    value: null,
                    squash: NavigationService.NoDefaultParamValueInUrl,
                },
            },
            component: DxyEntityGridComponent,
            resolve: [
                {
                    token: 'securityData',
                    deps: ['securityData'],
                    resolveFn: (securityData: ABaseSecurityData) =>
                        securityData,
                },
                {
                    token: 'serverType',
                    deps: ['serverType'],
                    resolveFn: (serverType: ServerType) => serverType,
                },
                {
                    token: 'spaceIdr',
                    deps: ['spaceData'],
                    resolveFn: (spaceData: Space) => spaceData,
                },
                {
                    token: 'egOptions',
                    resolveFn: () =>
                        ({
                            rowClickAction:
                                EntityGridRowClickAction.openEntityPreviewPanel,
                            burgerMenuConfiguration: {
                                actionButton: true,
                                navigationButton: true,
                            },
                            preserveCurrentSingleSelectedOnEntityCreated: true,
                            isMultiSelect: true,
                            bulkEditConfiguration: { showActions: true },
                            noNavLink: true,
                            showExportEntitiesAction: true,
                            showColumnsSelector: true,
                            canMoveColumns: true,
                            canSaveGridState: true,
                            showHierarchicalSwitch: true,
                            dgZone: DgZone.Module,
                        } as IEntityGridOptions),
                },
                {
                    token: 'entityAttributes',
                    deps: ['serverType', EntityService],
                    //#Archi-ServerType (fbo) this serverType can only be first class type, right ?
                    resolveFn: (
                        serverType: ServerType,
                        entityService: EntityService
                    ) =>
                        entityService.getEntityAttributesForEntityGrid(
                            serverType
                        ),
                },
                {
                    token: 'gridStatePersistenceId',
                    deps: ['dgModule'],
                    resolveFn: (dgModule: DgModule) =>
                        `EntityGridView.${DgModule[dgModule]}.undefined`, // Should be .all instead, but is stored as is in db
                },
                {
                    token: 'logId',
                    deps: ['dgModule'],
                    resolveFn: (dgModule: DgModule) =>
                        `entityGridview-${DgModule[dgModule]}`,
                },
                {
                    token: 'dtContext',
                    resolveFn: () => 'Entity Grid',
                },
                {
                    token: '_initIsHierarchical',
                    deps: ['$transition$', NavigationService],
                    resolveFn: (
                        $transition$: Transition,
                        navigationService: NavigationService
                    ) =>
                        navigationService.initIsHierarchical(
                            $transition$.params()[ParamKey.IsHierarchicalWord]
                        ),
                },
                {
                    // ensures *not* full page mode
                    token: '_setIsEntityFullPage',
                    deps: [NavigationService],
                    resolveFn: (navigationService: NavigationService) =>
                        navigationService.setIsEntityFullPage(false),
                },
                CommonStateDefinitions.logResolve('ModuleEntityGrid'),
            ],
        };
    }

    private static EntityDetails(
        name: string,
        actualServerType: ServerType = null
    ): Ng2StateDeclaration {
        return {
            name,
            url: '',
            component: DxyEntityDetailsComponent,
            resolve: [
                {
                    token: 'spaceId',
                    deps: ['spaceId'],
                    resolveFn: (spaceId: string) => spaceId,
                },
                {
                    token: 'entityData',
                    deps: ['entityData'],
                    resolveFn: (entityData: EntityItem) => entityData,
                },
                {
                    token: 'entityAttributes',
                    deps: ['entityData', 'serverType', EntityService],
                    resolveFn: (
                        entityData: EntityItem,
                        serverType: ServerType,
                        entityService: EntityService
                    ) =>
                        entityService.getEntityAttributesForDetails(
                            entityData?.ServerType ||
                                actualServerType ||
                                serverType
                        ),
                },
                {
                    token: 'isEntityOwner',
                    resolveFn: () => false,
                },
                CommonStateDefinitions.logResolve('EntityDetails'),
            ],
        };
    }

    //#endregion - Modules states

    public static ModuleEntityTabGrid(
        name: string,
        url: string,
        /** main type of entities to display in the grid */
        entityServerType: ServerType,
        wantedColumns: string[],
        /** type of the entity for which the view containing the tab is displayed */
        tabContainerEntityServerType: ServerType,
        /** mandatory, not to use the global view hierarchical state */
        isHierarchical: boolean,
        /** (for Catalog types only)
         * - true: filter using only the entityServerType,
         * - false: filter using all Catalog types
         */
        isSingleServerType?: boolean,
        canSaveGridState?: boolean,
        filterEntityTypes?: EntityType[],
        applyFilteredView = false
    ): Ng2StateDeclaration {
        const egOptions: IEntityGridOptions = {
            isHierarchical,
            useParentDataIdIfNullEntityRefId: isHierarchical,
            isSingleServerType,
            wantedColumns,
            rowClickAction: EntityGridRowClickAction.openEntityPreviewPanel,
            useOrderColumn: true,
            canMoveColumns: true,
            canCreate: true,
            noNavLink: true,
            burgerMenuConfiguration: { navigationButton: true },
            isMultiSelect: true,
            bulkEditConfiguration: { showActions: true },
            showColumnsSelector: true,
            canSaveGridState,
            filterEntityTypes,
            dgZone: applyFilteredView ? DgZone.Module : DgZone.none,
        };
        return {
            name,
            url,
            component: DxyEntityGridComponent,
            resolve: [
                {
                    token: 'spaceIdr',
                    deps: ['spaceData'],
                    resolveFn: (spaceData: Space) => spaceData,
                },
                {
                    token: 'egOptions',
                    deps: ['entityData'],
                    resolveFn: (entityData: EntityItem) => {
                        const hasHierarchicalSwitch =
                            HddUtil.hasHierarchicalChildren(entityData.HddData);
                        egOptions.showHierarchicalSwitch =
                            hasHierarchicalSwitch;
                        egOptions.isHierarchical = hasHierarchicalSwitch;
                        egOptions.isHierarchyToggleLocal =
                            hasHierarchicalSwitch;
                        egOptions.useParentDataIdIfNullEntityRefId = true;
                        return egOptions;
                    },
                },
                {
                    token: 'serverType',
                    resolveFn: () => entityServerType,
                },
                {
                    token: 'entityAttributes',
                    deps: [EntityService],
                    resolveFn: (entityService: EntityService) =>
                        entityService.getEntityAttributesForEntityGrid(
                            entityServerType
                        ),
                },
                {
                    token: 'parentDataId',
                    deps: ['spaceData', 'entityData'],
                    resolveFn: (spaceData: Space, entityData: EntityItem) =>
                        entityData
                            ? entityData.ReferenceId
                            : spaceData.ReferenceId,
                },
                {
                    token: 'parentData',
                    deps: ['entityData'],
                    resolveFn: (entityData: EntityItem) => entityData,
                },
                {
                    token: 'gridStatePersistenceId',
                    resolveFn: () =>
                        [
                            'EntityTabGrid',
                            FirstClassType[
                                FirstClassTypeUtil.firstClassTypeFromServerType(
                                    tabContainerEntityServerType
                                )
                            ],
                            FirstClassType[
                                FirstClassTypeUtil.firstClassTypeFromServerType(
                                    entityServerType
                                )
                            ],
                            filterEntityTypes?.join('-'),
                        ]
                            .filter((o) => o)
                            .join('.'),
                },
                {
                    token: 'logId',
                    resolveFn: () =>
                        `entityTabGrid-${ServerType[entityServerType]}`,
                },
                {
                    token: 'dtContext',
                    resolveFn: () => 'Entity Grid',
                },
                CommonStateDefinitions.logResolve('ModuleEntityTabGrid'),
            ],
        };
    }

    /**
     * get view identifier resolver for navbarTitle
     * @param viewIdentifier current route view identifier
     * @returns ResolveTypes for navbarTitle currentViewIdentifier Input attribute
     */
    public static getResolveNavbarTitleViewIdentifier(
        viewIdentifier: ViewIdentifier
    ): ResolveTypes {
        return {
            token: 'currentViewIdentifier',
            resolveFn: () => viewIdentifier,
        };
    }

    /**
     * Get current FilteredView resolver for navbarTitle
     */
    public static getResolveNavbarCurrentFilteredView(
        dgZone: DgZone,
        opt: {
            loadFavorite?: boolean;
            dgModule?: DgModule;
            filteredViewCategory?: string;
        }
    ): ResolveTypes {
        return {
            token: 'currentFilteredView',
            deps: ['spaceData', FilteredViewService],
            resolveFn: async (
                spaceData: Space,
                filteredViewService: FilteredViewService
            ) =>
                filteredViewService.getCurrentViewOrFavoriteOrNewOne(
                    dgZone,
                    opt?.dgModule,
                    spaceData
                ),
        };
    }
    //#region ImpactAnalysis

    public static GlobalImpactAnalysis(
        name: string,
        isExploratory?: boolean,
        isLineage?: boolean
    ): Ng2StateDeclaration {
        return {
            name,
            url: isExploratory
                ? `/${UrlWord.exploratory}?${ParamKey.PropId}?${ParamKey.PropType}?${ParamKey.EntityType}`
                : isLineage
                ? `/${UrlWord.lineage}?${ParamKey.PropId}?${ParamKey.PropType}?${ParamKey.EntityType}?${ParamKey.ImpactAnalysisTool}`
                : '/',
            views: {
                '': { component: ImpactAnalysisMainComponent },
                'navbarTitle@^.^.^': { component: DxyNavbarTitleComponent },
            },
            params: {
                [ParamKey.PropId]: null,
                [ParamKey.PropType]: null,
                [ParamKey.ImpactAnalysisTool]: null,
                [ParamKey.EntityType]: null,
            },
            resolve: [
                {
                    token: 'currentSpace',
                    deps: ['currentRoot'],
                    resolveFn: (currentRoot: Space) => currentRoot,
                },
                {
                    token: 'entityIdr',
                    deps: ['$stateParams', ImpactAnalysisService],
                    resolveFn: (
                        $stateParams: StateParams,
                        impactAnalysisService: ImpactAnalysisService
                    ) => {
                        const entityId: string = $stateParams[ParamKey.PropId];
                        const serverTypeName: string =
                            $stateParams[ParamKey.PropType];
                        const entityType = $stateParams[ParamKey.EntityType];
                        if (entityId && serverTypeName && entityType) {
                            const versionId = $stateParams[ParamKey.VersionId];
                            return new EntityIdentifier(
                                entityId,
                                versionId,
                                entityType
                            );
                        } else {
                            return isLineage || isExploratory
                                ? impactAnalysisService.getCachedLineageOriginIdr()
                                : undefined;
                        }
                    },
                },
                {
                    token: 'selectedTool',
                    deps: ['$stateParams'],
                    resolveFn: ($stateParams: StateParams) =>
                        CommonStateDefinitions.getImpactAnalysisSelectedTool(
                            $stateParams,
                            isExploratory
                        ),
                },
                CommonStateDefinitions.logResolve('GlobalImpactAnalysis'),
                CommonStateDefinitions.getResolveNavbarTitleViewIdentifier(
                    ViewIdentifier.ImpactAnalysis
                ),
            ],
        };
    }

    public static EntityImpactAnalysis(name: string): Ng2StateDeclaration {
        return {
            name,
            url: `/${UrlWord.analysis}?${ParamKey.ImpactAnalysisTool}`,
            component: EntityImpactAnalysisComponent,
            resolve: [
                {
                    token: 'entityData',
                    deps: ['entityData'],
                    resolveFn: (entityData: EntityItem) => entityData,
                },
                {
                    token: 'defaultTool',
                    deps: [LineageVersionTogglerService],
                    resolveFn: async (lineageVersionTogglerService) => {
                        const userPrefersLegacyLineage =
                            await lineageVersionTogglerService.shouldUseLegacyLineage();
                        return userPrefersLegacyLineage
                            ? ImpactAnalysisTool.LineageBoth
                            : ImpactAnalysisTool.Lineage;
                    },
                },
                {
                    token: 'selectedTool',
                    deps: ['$stateParams', 'defaultTool'],
                    resolveFn: ($stateParams: StateParams, defaultTool) => {
                        return CommonStateDefinitions.getImpactAnalysisSelectedTool(
                            $stateParams,
                            false,
                            defaultTool
                        );
                    },
                },
                CommonStateDefinitions.logResolve('EntityImpactAnalysis'),
            ],
        };
    }

    private static getImpactAnalysisSelectedTool(
        $stateParams: StateParams,
        forceExploratory = false,
        defaultTool = ImpactAnalysisTool.LineageBoth
    ) {
        return forceExploratory
            ? ImpactAnalysisTool.Explorer
            : +$stateParams[ParamKey.ImpactAnalysisTool] || defaultTool;
    }

    //#endregion - ImpactAnalysis

    public static EntityDiagrams(
        name: string,
        opt: { persistenceGridId?: string; canCreateDiagram?: boolean }
    ): Ng2StateDeclaration {
        return {
            name,
            url: `/${UrlWord.diagramsList}`,
            component: DiagramsListComponent,
            resolve: [
                {
                    token: 'entityData',
                    deps: ['entityData'],
                    resolveFn: (entityData: EntityItem) => entityData,
                },
                {
                    token: 'persistenceGridId',
                    resolveFn: () => opt.persistenceGridId,
                },
                {
                    token: 'canCreate',
                    resolveFn: () => opt.canCreateDiagram,
                },
            ],
        };
    }

    public static LinkedObjects(name: string): Ng2StateDeclaration {
        return {
            name,
            url: `/${UrlWord.links}`,
            component: DxyEntityLinksComponent,
            resolve: [
                {
                    token: 'entityData',
                    deps: ['entityData'],
                    resolveFn: (entityData: EntityItem) => entityData,
                },
                {
                    token: 'serverType',
                    deps: ['serverType'],
                    resolveFn: (serverType: ServerType) => serverType,
                },
            ],
        };
    }

    public static DataMap(name: string): Ng2StateDeclaration {
        return {
            name,
            url: `/${UrlWord.datamap}`,
            component: DatamapGraphComponent,
            resolve: [
                {
                    token: 'graphData',
                    deps: ['spaceData', 'dgModule', ImpactAnalysisService],
                    resolveFn: (
                        spaceData: Space,
                        dgModule: DgModule,
                        impactAnalysisService: ImpactAnalysisService
                    ) =>
                        impactAnalysisService.initDatamapGraphData(
                            SpaceIdentifier.from(spaceData),
                            dgModule
                        ),
                },
                {
                    token: '_hideDockingPane',
                    deps: [EntityDockingPaneService],
                    resolveFn: (
                        entityDockingPaneService: EntityDockingPaneService
                    ) => entityDockingPaneService.hidePanel(),
                },
                CommonStateDefinitions.logResolve('DataMap'),
            ],
        };
    }

    private static addMoreResolve<
        T1 extends Ng2StateDeclaration,
        T2 extends { [key: string]: IInjectable } | ResolveTypes[]
    >(result: T1, moreResolve?: T2, withParamsFilterDefault = false) {
        if (withParamsFilterDefault) {
            if (!result.params) {
                result.params = {};
            }
            result.params.filters = { default: [], array: true };
        }

        if (Array.isArray(result.resolve)) {
            if (Array.isArray(moreResolve)) {
                result.resolve.push(...moreResolve);
            }
        } else if (moreResolve && !Array.isArray(moreResolve)) {
            const resolve = result.resolve;
            Object.keys(moreResolve).forEach(
                (k) => (resolve[k] = moreResolve[k])
            );
        }

        return result;
    }

    public static logResolve(...args: any[]) {
        return {
            token: '_log',
            resolveFn: () => CommonStateDefinitions.log('(resolve)', ...args),
        };
    }
    public static log(...args: any[]) {
        if (CommonStateDefinitions.debug) {
            console.log(CommonStateDefinitions.name, ...args);
        }
    }
}

import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseCellComponent } from '../BaseCellComponent';

/**
 * ## Role
 * Cell component for a boolean value
 */
@Component({
    selector: 'dxy-boolean-cell',
    templateUrl: 'boolean-cell.component.html',
    styleUrls: ['boolean-cell.component.scss'],
})
export class DxyBooleanCellComponent<TEntity> extends BaseCellComponent<
    TEntity,
    boolean
> {
    @Input() checked? = false;

    public get label() {
        if (this.checked == undefined) return '';
        return this.translate.instant(
            `DgServerTypes.BooleanValue.${this.checked ? 'True' : 'False'}`
        );
    }

    constructor(private translate: TranslateService) {
        super();
    }

    public override updateBindings() {
        this.checked = this.value;
    }
}

import { NgModule } from '@angular/core';
import { DxySharedDialogsModule } from '../dialogs/DxySharedDialogsModule';
import { FieldsModule } from '../fields/fields.module';
import { DxySupportTicketModalComponent } from './feedback/dxy-support-ticket-modal/dxy-support-ticket-modal.component';
import { SharedModule } from '../shared.module';
import { DxyFreshdeskHelpLinkDirective } from './freshdesk/freshdesk-help-link.directive';

@NgModule({
    imports: [SharedModule, FieldsModule, DxySharedDialogsModule],
    declarations: [
        DxySupportTicketModalComponent,
        DxyFreshdeskHelpLinkDirective,
    ],
    exports: [DxySupportTicketModalComponent, DxyFreshdeskHelpLinkDirective],
})
export class DxySupportModule {}

import { UIRouter } from '@uirouter/core';
import { StateName } from '@datagalaxy/webclient/routing';
import { TranslateService } from '@ngx-translate/core';
import {
    CurrentUserInitializer,
    CurrentUserService,
} from '@datagalaxy/webclient/user/feature/current-user';
import { ClientServiceManagerService } from './client/client-service-manager.service';
import { InactivityLogoutService } from '@datagalaxy/webclient/auth/feature';
import { Constants } from './shared/util/Constants';
import UrlWord = Constants.Nav.UrlWord;
import { OpenFeatureInitializer } from '@datagalaxy/webclient/feature-flag';
const noAuthenticationRoutes = ['/is-up', '/#/is-up', `/${UrlWord.seeYouSoon}`];

export function initializeAppForCurrentUser(
    currentUserInitializer: CurrentUserInitializer,
    currentUserService: CurrentUserService,
    inactivityLogoutService: InactivityLogoutService,
    openFeatureInitializer: OpenFeatureInitializer,
    translate: TranslateService,
    clientServiceManager: ClientServiceManagerService,
    router: UIRouter
) {
    const relativeUrl = getCurrentValidRelativeUrl();
    if (noAuthenticationRoutes.includes(relativeUrl)) {
        return () => {
            router.locationService.url(relativeUrl, true);
            router.urlService.listen();
            router.urlService.sync();
        };
    }
    return async () => {
        let relativeCallbackUrl = '';
        try {
            relativeCallbackUrl = await currentUserInitializer.init();
            const languageCode = currentUserService.userInfo?.LanguageCode;
            if (languageCode) {
                translate.use(languageCode);
            }
            await clientServiceManager.initializeServices();
        } catch (error) {
            handleInitError(error, router, translate);
            return;
        }
        const clientInfo = currentUserService.clientInfo;

        const sessionTimeoutInMinutes = clientInfo.UserSessionTimeoutMinutes;
        if (sessionTimeoutInMinutes) {
            inactivityLogoutService.init(sessionTimeoutInMinutes);
        }

        const path = relativeCallbackUrl
            ? transformInvalidPathToValid(relativeCallbackUrl)
            : getCurrentValidRelativeUrl();

        try {
            await openFeatureInitializer.init(clientInfo.ClientId);
        } catch (error) {
            console.warn('Error initializing feature flags', error);
        }

        if (path) {
            router.locationService.url(path, true);
        }

        /**
         * Prevent logged-in user from accessing legacy login page
         * To avoid doing this check here, we have to move auth steps into the
         * routing hooks, this way we could use routes metadata to determine if
         * they should be ignored in auth v2
         */
        if (path?.includes('/login')) {
            router.locationService.url('/', true);
        }

        router.urlService.listen();
        router.urlService.sync();
    };
}

/**
 * get the current valid url by remove # and client url parts
 * Useful because of slack and teams notification links
 * Should be removed when # urls are removed from slack/teams after feature flag removal and cleanups
 */
function getCurrentValidRelativeUrl(): string {
    const url = window.location;
    const relativeUrl = url.pathname + url.search + url.hash;
    return transformInvalidPathToValid(relativeUrl);
}

export function transformInvalidPathToValid(relativeUrl: string): string {
    if (relativeUrl === '/error') {
        return '/';
    }
    return relativeUrl.replace(/^\/#(\/client\/[\w-]+)?/, '');
}

function handleInitError(
    error: any,
    router: UIRouter,
    translate: TranslateService
) {
    if (error.tokenError) {
        return;
    }
    router.urlService.listen();
    router.stateService.go(StateName.MainError, {
        errorMessage: translate.instant('UI.Login.lblServerError'),
        accessLevel: 'login',
    });
}

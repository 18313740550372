import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { CoreUtil, DomUtil } from '@datagalaxy/core-util';
import { DxyBaseModalComponent } from '@datagalaxy/ui/dialog';
import { ToasterService } from '../../services/toaster.service';
import { saveAs } from 'file-saver';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from '@datagalaxy/webclient/config';

@Component({
    selector: 'dxy-api-doc-modal',
    templateUrl: 'dxy-api-doc-modal.component.html',
    styleUrls: ['dxy-api-doc-modal.component.scss'],
})
export class DxyApiDocModalComponent
    extends DxyBaseModalComponent<void, void>
    implements OnInit
{
    public apiUrl: string;
    public publicApiHost: string;
    public quickStartUrl: string;

    constructor(
        private toasterService: ToasterService,
        private appConfigService: AppConfigService,
        private translate: TranslateService,
        private httpClient: HttpClient,
        dialogRef: MatDialogRef<DxyApiDocModalComponent>
    ) {
        super(dialogRef);
    }

    ngOnInit() {
        this.quickStartUrl = this.translate.instant(
            'UI.apiDocumentation.links.quickStartExternalLink'
        );
        this.publicApiHost = this.appConfigService.publicApiHost;
        this.apiUrl = this.publicApiHost.replace('/documentation', '');
    }

    public onClickQuickStart() {
        this.openInNewWindow(this.quickStartUrl);
    }

    public onClickPublicApi() {
        this.openInNewWindow(this.publicApiHost);
    }

    public onClickChangelog() {
        this.openInNewWindow(`${this.publicApiHost}changelog`);
    }

    public async onDownloadDocumentationClick() {
        await this.getDocumentation(`${this.publicApiHost}specification`);
    }

    public copyUrl() {
        DomUtil.copyToClipboard(this.apiUrl);
        this.toasterService.successToast({
            messageKey: 'UI.apiDocumentation.copyDone',
        });
    }

    private openInNewWindow(url: string) {
        window.open(url, '_blank');
    }

    private async getDocumentation(url: string) {
        const responseBody = await CoreUtil.toPromise(this.httpClient.get(url));
        const content = JSON.stringify(responseBody);
        saveAs(new Blob([content], { type: 'text/plain' }), 'swagger.json');
    }
}

<button
    dxyIconButton
    color="secondary"
    [matMenuTriggerFor]="menu"
    [matTooltip]="'UI.GridTemplate.Settings.manageColumns' | translate"
    matTooltipPosition="above"
>
    <span class="glyph glyph-grid-columns"></span>
</button>
<mat-menu #menu="matMenu" class="dg5-mat-menu">
    <button type="button" mat-menu-item (click)="onResetClick()">
        <span class="glyph-reload glyph"></span>
        <span class="text">
            {{ 'UI.GridTemplate.Settings.resetState' | translate }}
        </span>
    </button>
    <div class="menu-body not-an-item" (click)="$event.stopPropagation()">
        <div *ngFor="let column of columns" class="column">
            <dxy-checkbox
                [checked]="isColumnVisible(column)"
                (change)="onColumnToggleVisibility(column)"
                [label]="column.headerLabel ?? ''"
            >
            </dxy-checkbox>
        </div>
    </div>
</mat-menu>

import { Component } from '@angular/core';
import { RowNode } from 'ag-grid-community';
import { BaseCellComponent } from '../../../cell-components/BaseCellComponent';
import { IGroupCellParams } from './group-cell.types';

/**
 * ## Role
 * Group cell component collapsing many lines
 */
@Component({
    selector: 'dxy-group-cell',
    templateUrl: 'group-cell.component.html',
    styleUrls: ['group-cell.component.scss'],
})
export class DxyGroupCellComponent extends BaseCellComponent<
    any,
    any,
    IGroupCellParams
> {
    public isCaretBeforeText = false;
    public groupHeaderItemClass?: string;
    public text?: string;
    public get expanded() {
        return this.node?.expanded ?? false;
    }

    private node?: RowNode;

    constructor() {
        super();
    }

    public toggleExpanded() {
        this.node.setExpanded(!this.expanded);
    }

    protected override updateBindings() {
        this.isCaretBeforeText = this.params?.isCaretBeforeText;
        this.groupHeaderItemClass = this.params?.groupHeaderItemClass;
        this.node = this.params?.node;
        this.text = this.value?.toString() ?? '';
    }
}

export interface LineageNodeStream {
    type: LineageNodeStreamDirection;
    status?: LineageNodeStreamStatus;
    isPrincipal: boolean;
    isChild?: boolean;
    virtual?: boolean;
    isRoot?: boolean;
}

export enum LineageNodeStreamStatus {
    Empty = 0,
    Expanded,
    /**
     * A node stream is considered collapsed when at least one link is not
     * displayed
     */
    Collapsed,
}

export enum LineageNodeStreamDirection {
    Upstream,
    Downstream,
    TopStream,
    BottomStream,
}

import { Component, Input } from '@angular/core';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { IMiniEntityContent } from '@datagalaxy/webclient/entity/domain';

@Component({
    selector: 'app-data-quality-container',
    templateUrl: 'data-quality-container.component.html',
    styleUrls: ['data-quality-container.component.scss'],
})
export class DataQualityContainerComponent extends DxyBaseComponent {
    @Input() entityData: IMiniEntityContent;
    @Input() showHeader: boolean;

    constructor() {
        super();
    }
}

import { Component, Input, OnInit } from '@angular/core';
import { ServerType } from '@datagalaxy/dg-object-model';
import { NavigationService } from '../../services/navigation.service';
import { SpaceApiService } from '../../space/services/space-api.service';
import { EntityUiService } from '../../shared/entity/services/entity-ui.service';
import { SpaceUiService } from '../../space/services/space-ui.service';
import { AppSpaceService } from '../../services/AppSpace.service';
import { EntitySecurityService } from '../../shared/entity/services/entity-security.service';
import {
    NavOrganization,
    NavProject,
    NavSpace,
    Organization,
    Space,
} from '@datagalaxy/webclient/workspace/data-access';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { DgModule } from '@datagalaxy/shared/dg-module/domain';
import { EntitySecurityData } from '@datagalaxy/webclient/security/domain';

@Component({
    //eslint-disable-next-line @angular-eslint/component-selector
    selector: 'dxy-organization-dashboard',
    templateUrl: 'dxy-organization-dashboard.component.html',
    styleUrls: ['dxy-organization-dashboard.component.scss'],
})
export class DxyOrganizationDashboardComponent
    extends DxyBaseComponent
    implements OnInit
{
    @Input() currentSpace: Space;

    public currentOrganization: Organization;
    public dgModule = DgModule.Glossary;

    public get isOrganization() {
        return this.currentSpace instanceof Organization;
    }

    constructor(
        private navigationService: NavigationService,
        private spaceApiService: SpaceApiService,
        private entityUiService: EntityUiService,
        private spaceUiService: SpaceUiService,
        private appSpaceService: AppSpaceService,
        private entitySecurityService: EntitySecurityService
    ) {
        super();
    }

    ngOnInit() {
        this.currentOrganization = this.currentSpace as Organization;
        super.registerSubscriptions(
            this.appSpaceService.subscribe({
                onCreate: (navSpace) => this.onCreateSpaceEvent(navSpace),
                onUpdate: (navSpace) => this.onUpdateSpaceEvent(navSpace),
            })
        );
    }

    public get isSubOrgaEnabled() {
        return this.spaceUiService.canAccessSubOrga;
    }

    public hasReadAccess(securityData: EntitySecurityData) {
        return this.entitySecurityService.hasReadAccessSecurity(securityData);
    }

    public hasAdminAccess(securityData: EntitySecurityData) {
        return this.entitySecurityService.hasAdminAccessSecurity(securityData);
    }

    public async goToOrganizationDashboard(navOrganization: NavOrganization) {
        await this.navigationService.goToSpaceHome(navOrganization);
    }

    public async onCreateOrganization() {
        await this.spaceUiService.openCreateSpaceModal(
            this.currentOrganization.ReferenceId,
            ServerType.Organization
        );
    }

    public async onEditSubOrganization(subOrganization: NavOrganization) {
        await this.spaceUiService.openUpdateSpaceModal(subOrganization);
    }

    public async onDeleteSubOrganization(subOrganization: NavOrganization) {
        const confirmed = await this.entityUiService.confirmDelete(
            subOrganization.Type,
            { featureCode: 'ORGA,D', spaceName: subOrganization.DisplayName }
        );
        if (!confirmed) {
            return;
        }
        await this.spaceApiService.deleteOrganization(
            subOrganization.ParentReferenceId,
            subOrganization.ReferenceId
        );
    }

    public async onDeleteProject(project: NavProject) {
        const confirmed = await this.entityUiService.confirmDelete(
            project.Type,
            { featureCode: 'PROJECT,D', spaceName: project.DisplayName }
        );
        if (!confirmed) {
            return;
        }
        await this.spaceApiService.deleteProject(
            project.ParentReferenceId,
            project.ReferenceId
        );
    }

    public async goToProjectDashboard(navProject: NavProject) {
        await this.navigationService.goToSpaceHome(navProject);
    }

    public async onEditProject(project: NavProject) {
        await this.spaceUiService.openUpdateSpaceModal(project);
    }

    public async onCreateProject() {
        await this.spaceUiService.openCreateSpaceModal(
            this.currentOrganization.ReferenceId,
            ServerType.Project
        );
    }

    private onCreateSpaceEvent(navSpace: NavSpace) {
        if (
            navSpace.ParentReferenceId == this.currentOrganization.ReferenceId
        ) {
            if (navSpace instanceof NavOrganization) {
                this.currentOrganization.ChildOrganizations.push(navSpace);
            } else {
                this.currentOrganization.Projects.push(navSpace);
            }
        }
    }

    private onUpdateSpaceEvent(navSpace: NavSpace) {
        if (navSpace.ReferenceId == this.currentOrganization.ReferenceId) {
            this.currentOrganization.DisplayName = navSpace.DisplayName;
            this.currentOrganization.Description = navSpace.Description;
            // TODO : Update current orga description
        } else {
            let childSpace = null;
            if (navSpace instanceof NavOrganization) {
                childSpace = this.currentOrganization.ChildOrganizations.find(
                    (childSpace) =>
                        childSpace.ReferenceId == navSpace.ReferenceId
                );
            } else {
                childSpace = this.currentOrganization.Projects.find(
                    (childSpace) =>
                        childSpace.ReferenceId == navSpace.ReferenceId
                );
            }

            if (childSpace) {
                childSpace.DisplayName = navSpace.DisplayName;
                childSpace.Description = navSpace.Description;
                // TODO : Update child space description
            }
        }
    }
}

import { GridPosition } from '../domain/grid-position';
import { distanceToOrigin } from '../common/dashboard-grid-utils';

export type GridAction = AddWidget | RemoveWidget | MoveWidget;

abstract class BaseAction {
    constructor(public el: HTMLElement) {}
}

abstract class PositionAction extends BaseAction {
    public distanceToOrigin: number;
    constructor(el: HTMLElement, public position: GridPosition) {
        super(el);
        this.distanceToOrigin = distanceToOrigin(position);
    }
}

export class AddWidget extends PositionAction {
    constructor(el: HTMLElement, position: GridPosition) {
        super(el, position);
    }
}

export class MoveWidget extends PositionAction {
    constructor(el: HTMLElement, position: GridPosition) {
        super(el, position);
    }
}

export class RemoveWidget extends BaseAction {
    constructor(el: HTMLElement) {
        super(el);
    }
}

import { Component } from '@angular/core';
import { DevPreviewService, IDevPreviewOption } from '../dev-preview.service';
import { DxyBaseComponent } from '@datagalaxy/ui/core';

@Component({
    selector: 'app-dev-preview-options-list',
    templateUrl: 'dev-preview-options-list.component.html',
    styleUrls: ['dev-preview-options-list.component.scss'],
})
export class DevPreviewOptionListComponent extends DxyBaseComponent {
    public get showDevPreviewOptions() {
        return this.devPreviewService.showOptions;
    }
    public get devPreviewOptions() {
        return this.devPreviewService.options;
    }
    public get canClearDevPreviewOptionsCache() {
        return this.devPreviewService.canClearCache;
    }

    constructor(private devPreviewService: DevPreviewService) {
        super();
    }

    public toggleOption(opt: IDevPreviewOption, event: Event) {
        event.stopPropagation();
        this.devPreviewService.toggle(opt.key);
    }
    public clearCache(event: Event) {
        event.stopPropagation();
        this.devPreviewService.clearOptionsCache();
    }

    public inputChange(opt: IDevPreviewOption, value: string, event: Event) {
        event.stopPropagation();
        this.devPreviewService.inputChange(opt.key, value);
    }
    public resetInput(opt: IDevPreviewOption, event: Event) {
        event.stopPropagation();
        this.devPreviewService.resetInput(opt.key);
    }
}

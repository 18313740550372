<!-- searched text -->
<div class="dg5-filter-item">
    <div class="filter-group">
        <span class="filter-label" translate
            >UI.Global.filter.filterByNameLbl</span
        >
        <input
            type="text"
            class="filter-value"
            autocomplete="off"
            [(ngModel)]="searchTerm"
            [placeholder]="
                'UI.Global.filter.filterByNamePlaceholder' | translate
            "
            (ngModelChange)="onSearchTermChange()"
        />
    </div>
</div>

<app-attribute-filter
    *ngFor="let filterItem of filterItems$ | async"
    [filterItemData]="filterItem"
    (onAction)="onFilterItemAction(filterItem, $event)"
>
</app-attribute-filter>

<!-- add filter -->
<app-attribute-filter-add-dropdown
    [attributes]="availableAttributes"
    (onAddAttribute)="onAddAttribute($event)"
></app-attribute-filter-add-dropdown>

import { StringUtil } from '@datagalaxy/core-util';
import {
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import { EntityTypeUtil } from '@datagalaxy/dg-object-model';
import { EntityService } from '../../shared/entity/services/entity.service';
import { EntityDockingPaneService } from '../../shared/entity/services/entity-docking-pane.service';
import { EntityPreviewPanelService } from '../../shared/entity/services/entity-preview-panel.service';
import { SupportTicketService } from '../../shared/support/feedback/support-ticket.service';
import { EntityPanelTool } from '../../entity-panels/entity-panels.types';
import { UserInsightItem } from '@datagalaxy/webclient/user/data-access';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';
import { ServerConstants } from '@datagalaxy/shared/server/domain';
import PropertyName = ServerConstants.PropertyName;

@Component({
    selector: 'dxy-insight-data',
    templateUrl: 'dxy-insight-data.component.html',
    styleUrls: ['dxy-insight-data.component.scss'],
})
export class DxyInsightDataComponent
    extends DxyBaseComponent
    implements OnChanges, OnInit
{
    @Input() currentEntity: EntityItem;
    @Input() showHeader: boolean;

    public topUsers: UserInsightItem[];
    public get canHaveEntityTree() {
        return EntityTypeUtil.canHaveChildren(this.currentEntity.EntityType);
    }
    public get showFeedback() {
        return this.supportTicketService.isFeedbackEnabled;
    }
    public get displayedDescendantsCount() {
        return StringUtil.formatNumber(
            this.currentEntity.getAttributeValue(
                PropertyName.LogicalAllLevelChildrenCount
            ) as number
        );
    }
    public get diksplayedLinkedObjectCount() {
        return StringUtil.formatNumber(
            this.currentEntity.getAttributeValue(
                PropertyName.EntityLinkCount
            ) as number
        );
    }
    public get fillingValue() {
        return (
            this.currentEntity?.HddData &&
            (this.currentEntity.getAttributeValue(
                PropertyName.DataFillPercentage
            ) as number) * 100
        );
    }
    public get completionValue() {
        return (
            this.currentEntity.HddData &&
            (this.currentEntity.getAttributeValue(
                PropertyName.RecommendedDataFillPercentage
            ) as number) * 100
        );
    }
    public get isTopContributorsVisible() {
        return !!this.topUsers?.length;
    }

    constructor(
        private entityService: EntityService,
        private entityDockingPaneService: EntityDockingPaneService,
        private entityPreviewPanelService: EntityPreviewPanelService,
        private supportTicketService: SupportTicketService
    ) {
        super();
    }

    ngOnChanges(changes: SimpleChanges): void {
        super.onChange(changes, 'currentEntity', () => this.init().then());
    }

    ngOnInit() {
        this.init().then();
    }

    public onDescendantsClick() {
        this.setActiveTool(EntityPanelTool.EntityTree);
    }

    public onLinkedObjectsClick() {
        this.setActiveTool(EntityPanelTool.LinkedObject);
    }

    public onFeedbackClick() {
        this.supportTicketService.openFeedbackGuide();
    }

    private async init() {
        if (!this.currentEntity) {
            return;
        }
        const result = await this.entityService.getEntityInsights(
            this.currentEntity.ReferenceId
        );
        this.topUsers = result.UserItems;
    }
    private setActiveTool(tool: EntityPanelTool) {
        if (this.entityPreviewPanelService.isPanelVisible) {
            this.entityPreviewPanelService.setActiveTool(tool);
        } else {
            this.entityDockingPaneService.setActiveTool(tool);
        }
    }
}

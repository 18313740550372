<ng-container *ngIf="!isEmptyModule">
    <div
        [class.animate-hidden]="!isFiltersBarVisible"
        class="filters-container"
    >
        <app-attribute-filter-form
            [filteredView]="currentFilteredView"
        ></app-attribute-filter-form>
        <button mat-icon-button (click)="onFiltersBarCloseClick()">
            <i class="glyph-cancelsearch"></i>
        </button>
    </div>

    <as-split
        *ngIf="showListContainer"
        class="split-container"
        direction="horizontal"
        unit="pixel"
        (dragEnd)="onSplitDragEnd()"
        [appAsSplitPersistenceId]="splitterPersistenceId"
    >
        <as-split-area
            class="list-area dg5-master-list"
            [order]="1"
            [size]="getAreaSize(0, 450)"
            [minSize]="260"
            [maxSize]="700"
            [visible]="!isListViewCollapsed"
        >
            <div *ngIf="!isListViewCollapsed" ui-view="list"></div>
        </as-split-area>
        <as-split-area class="content-area" [order]="2">
            <div ui-view="content" class="mainView"></div>
        </as-split-area>
    </as-split>
    <div *ngIf="showNonListContainer" ui-view class="view-container"></div>
</ng-container>

<div *ngIf="isEmptyModule" class="module-empty">
    <div class="module-empty-content">
        <img
            class="empty-image"
            [src]="getModuleDetails().imageSource"
            alt="image-empty"
        />
        <span class="title" [translate]="getModuleDetails().title"></span>
        <div class="description">
            <span [translate]="getModuleDetails().description"></span>
            <a
                (click)="onClickDocumentationLink()"
                [translate]="getModuleDetails().documentationLbl"
            ></a>
        </div>
        <div class="actions-wrapper">
            <button
                *ngIf="hasEntityCreationAccess()"
                mat-flat-button
                color="primary"
                (click)="openEntityCreationModal()"
            >
                <span class="glyph-add"></span>
                <span translate>UI.ModuleEmpty.createBtn</span>
            </button>
            <button
                *ngIf="hasImportAccess()"
                (click)="openImportModal()"
                mat-flat-button
                [attr.data-tracker-id]="getImportDataTrackerId()"
            >
                <span class="glyph-upload"></span>
                <span translate>UI.ModuleEmpty.importBtn</span>
            </button>
            <button
                *ngIf="hasGlossaryAutoGenerationImport()"
                (click)="openGlossaryAutoGenerationImportModal()"
                mat-flat-button
                data-tracker-id="open-import-glossary-from-model"
                [dxyLogFunctional]="glossaryAutoGenerationFuncLog"
            >
                <i class="glyph-metabot-glossary"></i>
                <span translate>UI.Glossary.import.generateFromSource</span>
            </button>
        </div>
    </div>
</div>

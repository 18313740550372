import { NgModule, Optional, SkipSelf } from '@angular/core';
import {
    BACKEND_API_CONFIG,
    IApiConfig,
    RestApiService,
    RestHttpClient,
} from '@datagalaxy/data-access';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
    providers: [
        RestHttpClient,
        {
            provide: 'RestConnectivityApiService',
            useFactory: (
                restHttpClient: RestHttpClient,
                config: IApiConfig
            ) => {
                return new RestApiService(
                    restHttpClient,
                    config.publicApiUrl,
                    'connectivity'
                );
            },
            deps: [RestHttpClient, BACKEND_API_CONFIG],
        },
    ],
    imports: [HttpClientModule],
})
export class ConnectivityApiModule {
    constructor(@Optional() @SkipSelf() parentModule?: ConnectivityApiModule) {
        if (parentModule) {
            throw new Error(
                'ConnectivityApiModule is already loaded. Import it in the AppModule only'
            );
        }
    }
}

import { Injectable } from '@angular/core';
import { LineageVersionState } from './lineage-version-toggler.type';
import { UserService } from '../../services/user.service';
import { BaseStateService } from '@datagalaxy/utils';
import { userSettingsValues } from '@datagalaxy/webclient/user/domain';

@Injectable({ providedIn: 'root' })
export class LineageVersionTogglerService extends BaseStateService<LineageVersionState> {
    private static blueprint: LineageVersionState = { useLegacyLineage: null };
    private _initPromise: Promise<void>;

    constructor(private userService: UserService) {
        super({ ...LineageVersionTogglerService.blueprint });
    }

    public async shouldUseLegacyLineage() {
        if (!this._initPromise) {
            this._initPromise = this.init();
        }

        await this._initPromise;

        return !!this.state.useLegacyLineage;
    }

    public async setUseLegacyLineage(shouldUse: boolean) {
        this.setState({ useLegacyLineage: shouldUse });
        await this.userService.setUserSettingValue(
            userSettingsValues.dks.category,
            userSettingsValues.dks.routes.linagev2,
            JSON.stringify({ useLegacyLineage: shouldUse })
        );
    }

    private async init() {
        const res = await this.userService.getUserSettingValue(
            userSettingsValues.dks.category,
            userSettingsValues.dks.routes.linagev2
        );

        this.setState(
            res?.Value
                ? JSON.parse(res.Value)
                : { ...LineageVersionTogglerService.blueprint }
        );
    }
}

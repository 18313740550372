import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
} from '@angular/core';
import { withLoading } from '@datagalaxy/core-ui';
import { ActivityLogService } from '../../../activityLog/activityLog.service';
import { SpaceUiService } from '../../services/space-ui.service';
import {
    BaseCustomTitleWidgetComponent,
    ICustomTitleWidgetComponent,
    registerWidget,
} from '@datagalaxy/webclient/dashboard/ui';
import { CurrentSpaceService } from '../../../services/currentSpace.service';
import { ActivityLogEntry } from '@datagalaxy/webclient/activity-log/domain';

/**
 * ## Role
 * Display recent public activity logs for a space
 */
@registerWidget({
    name: 'space-activity-logs-widget',
    titleKey: 'UI.SpaceHome.Widgets.SpaceActivityLogs.Title',
    previewImgUrl: 'images/space-widgets/space-widget-activity-logs.svg',
})
@Component({
    selector: 'app-space-activity-logs-widget',
    templateUrl: './space-activity-logs-widget.component.html',
    styleUrls: [
        '../space-widget.component.scss',
        './space-activity-logs-widget.component.scss',
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpaceActivityLogsWidgetComponent
    extends BaseCustomTitleWidgetComponent
    implements AfterViewInit, ICustomTitleWidgetComponent
{
    protected get space() {
        return this.currentSpaceService.currentSpace;
    }

    protected logEntries: ActivityLogEntry[];

    protected get showEntries() {
        return !this.loadingValue && !!this.logEntries?.length;
    }
    protected get showPlaceholder() {
        return !this.loadingValue && !this.logEntries?.length;
    }

    constructor(
        private activityLogService: ActivityLogService,
        private spaceUiService: SpaceUiService,
        private currentSpaceService: CurrentSpaceService
    ) {
        super();
    }

    ngAfterViewInit() {
        this.initAsync();
    }

    protected async onOpenSpaceActivityLogsPreview() {
        await this.spaceUiService.openActivityLogWidgetPreview(
            this.space,
            this.logEntries
        );
    }

    @withLoading()
    private async initAsync() {
        this.logEntries = await this.activityLogService.getSpaceActivityLogs(
            this.space
        );
    }
}

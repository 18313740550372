import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { DxySharedUiModule } from '../shared/shared-ui/DxySharedUiModule';
import { DxyFilterModule } from '../shared/filter/DxyFilterModule';
import { DxySpaceSelectorModule } from '../space-selector/DxySpaceSelectorModule';
import { SpaceModule } from '../space/space.module';
import { DxySharedEntityModule } from '../shared/entity/DxySharedEntityModule';
import { DevPreviewOptionsModule } from '../dev-preview-options/DevPreviewOptionsModule';
import { DxyNavbarTitleComponent } from './dxy-navbar-title/dxy-navbar-title.component';
import { NavbarTitleMoreActionsComponent } from './navbar-title-more-actions/navbar-title-more-actions.component';
import { DxyNavbarBreadcrumbComponent } from './dxy-navbar-breadcrumb/dxy-navbar-breadcrumb.component';
import { DxyNavbarProfileDropdownComponent } from './dxy-navbar-profile-dropdown/dxy-navbar-profile-dropdown.component';
import { DxyNavbarComponent } from './dxy-nav-bar/dxy-navbar.component';
import { DxyNavSidebarItemComponent } from './dxy-nav-sidebar-item/dxy-nav-sidebar-item.component';
import { DxyNavSidebarComponent } from './dxy-nav-sidebar/dxy-nav-sidebar.component';
import { NavSidebarModuleComponent } from './nav-sidebar-module/nav-sidebar-module.component';

@NgModule({
    imports: [
        SharedModule,
        DxySharedUiModule,
        DxySpaceSelectorModule,
        DxyFilterModule,
        DxySharedEntityModule,
        SpaceModule,
        DevPreviewOptionsModule,
    ],
    declarations: [
        DxyNavbarTitleComponent,
        NavbarTitleMoreActionsComponent,
        DxyNavbarBreadcrumbComponent,
        DxyNavbarProfileDropdownComponent,
        DxyNavSidebarItemComponent,
        DxyNavSidebarComponent,
        DxyNavbarComponent,
        NavSidebarModuleComponent,
    ],
    exports: [DxyNavbarComponent, DxyNavSidebarComponent],
})
export class NavbarModule {}

<div class="modal-header">
    <h3 mat-dialog-title class="modal-title" translate>
        UI.SpaceHome.Widgets.SpaceFilteredViewObjects.Modal.title
    </h3>
    <button
        mat-icon-button
        (click)="onCloseCancel()"
        class="modal-close-btn"
    ></button>
</div>

<form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div mat-dialog-content class="content">
        <dxy-widget-preview
            [widget]="widget"
            displayMode="placeholder"
        ></dxy-widget-preview>

        <dxy-filtered-view-selector
            formControlName="filteredView"
            labelKey="UI.SpaceHome.Widgets.SpaceFilteredViewObjects.Modal.publicFilteredView"
            [spaceId]="spaceId"
        ></dxy-filtered-view-selector>
    </div>
    <dxy-modal-footer
        [actionEnabled]="form.valid"
        [actionBtnLbl]="'UI.Dialog.btnAdd' | translate"
        (onCloseCancel)="onCloseCancel()"
    ></dxy-modal-footer>
</form>

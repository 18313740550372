import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CollectionsHelper, StringUtil } from '@datagalaxy/core-util';
import { ImportContext } from '../../import/shared/ImportContext';
import {
    EntityTypeUtil,
    ModelType,
    ServerType,
} from '@datagalaxy/dg-object-model';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { ViewTypeService } from '../../services/viewType.service';
import { EntityTypeUtils } from '@datagalaxy/webclient/entity/utils';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';

/**
 * ## Role
 * Display an entity selection field to select a target to update or create during an import
 */
@Component({
    selector: 'dxy-target-selection',
    templateUrl: './dxy-target-selection.component.html',
    styleUrls: ['./dxy-target-selection.component.scss'],
})
export class DxyTargetSelectionComponent
    extends DxyBaseComponent
    implements OnInit, OnChanges
{
    @Input() context: ImportContext;
    @Input() newEntityName: string;
    @Input() subTypeName: string;
    @Input() entityId: string;
    @Input() hasEntityCreationAccess: boolean;
    @Input() disableSourceType?: boolean;
    @Input() availableEntities: EntityItem[];
    @Input() serverType: ServerType;

    @Output() entityChange = new EventEmitter<EntityItem>();
    @Output() subTypeChange = new EventEmitter<string>();
    @Output() newEntityNameChange = new EventEmitter<string>();
    @Output() isUpdateChange = new EventEmitter<boolean>();
    @Output() reset = new EventEmitter<void>();
    /** Emitted when a menu is opened or closed. The argument is true on open. */
    @Output() readonly openChange = new EventEmitter<boolean>();

    @ViewChild('sourceMenuTrigger') private sourceMenuTrigger: MatMenuTrigger;
    @ViewChild('menuTrigger') private menuTrigger: MatMenuTrigger;

    protected sourceTypeNames: string[];
    protected searchTerm = '';
    protected temporaryNewEntityName = '';
    protected currentEntity: EntityItem;
    protected filteredEntities: EntityItem[] = [];
    protected isUpdate: boolean;

    protected get sourceTypeDisabledClass() {
        return this.disableSourceType ? 'source-type-disabled' : '';
    }
    protected get hasCurrentEntity() {
        return this.isUpdate ? !!this.currentEntity : !!this.newEntityName;
    }
    protected get currentEntityName() {
        return this.isUpdate
            ? this.viewTypeService.getTechnicalOrDisplayName(this.currentEntity)
            : this.newEntityName;
    }
    protected get showEntityCreationField() {
        return (
            !this.isUpdate &&
            !(this.serverType == ServerType.Model && !this.subTypeName)
        );
    }
    protected get isUpdateAllowed() {
        return !!this.availableEntities?.length;
    }
    protected get dataTypeName() {
        if (this.serverType === ServerType.Model) {
            return 'source';
        }
        if (this.serverType === ServerType.SoftwareElement) {
            return 'usage';
        }
        return this.serverTypeName.toLowerCase();
    }
    protected get serverTypeName() {
        return ServerType[this.serverType];
    }

    constructor(
        private translate: TranslateService,
        private viewTypeService: ViewTypeService
    ) {
        super();
        this.sourceTypeNames = CollectionsHelper.getEnumValueNames(ModelType);
    }

    ngOnInit() {
        this.currentEntity = this.initCurrentEntity();
        this.isUpdate =
            (this.isUpdateAllowed && !!this.currentEntity) ||
            !this.hasEntityCreationAccess;
        if (
            this.context.currentModule &&
            'isUpdate' in this.context.currentModule
        ) {
            this.context.currentModule.isUpdate = this.isUpdate;
        }
        this.temporaryNewEntityName = this.newEntityName;
        this.filterEntities();
    }

    ngOnChanges(changes: SimpleChanges): void {
        super.onChange<EntityItem[]>(changes, 'availableEntities', () =>
            this.filterEntities()
        );
        super.onChange<string>(
            changes,
            'newEntityName',
            (newEntityName) => (this.temporaryNewEntityName = newEntityName)
        );
    }

    protected getTranslationKey(keySuffix: string) {
        const serverTypeName = this.serverTypeName;
        return `Import.GenericImportWizard.ImportTargetSelection.${serverTypeName}.${keySuffix}`;
    }

    protected getEntityName(entity: EntityItem) {
        return this.viewTypeService.getTechnicalOrDisplayName(entity);
    }

    protected getSubTypeTranslationKey(option: string) {
        const serverTypeName = this.serverTypeName;
        return `DgServerTypes.${serverTypeName}Type.${option}`;
    }
    protected onChangeMode(newIsUpdate: boolean) {
        this.isUpdateChange.emit(newIsUpdate);
        this.reset.emit();
        this.temporaryNewEntityName = this.newEntityName;
    }

    protected onClickSourceType($event: MouseEvent, sourceType: string) {
        $event.stopPropagation();
        this.subTypeChange.emit(sourceType);
        this.sourceMenuTrigger.closeMenu();
    }

    protected getEntityGlyphClass(subTypeName: string) {
        const entityType = EntityTypeUtil.getEntityType(
            this.serverTypeName,
            subTypeName
        );
        return EntityTypeUtils.getGlyphClass(entityType);
    }

    protected onSelectedExistingEntity(entityItem: EntityItem) {
        if (this.currentEntity?.DataReferenceId == entityItem.DataReferenceId) {
            return;
        }
        this.currentEntity = entityItem;
        this.entityId = entityItem.DataReferenceId;
        this.entityChange.emit(entityItem);
        this.menuTrigger.closeMenu();
    }

    protected onSearch(searchString: string) {
        this.searchTerm = searchString;
        this.filterEntities();
    }

    protected getUpdateRadioButtonTooltip() {
        return this.isUpdateAllowed
            ? ''
            : this.translate.instant(
                  this.getTranslationKey('NoTargetAvailable')
              );
    }

    protected onValidateNewEntityName() {
        this.newEntityNameChange.emit(this.temporaryNewEntityName);
        this.menuTrigger.closeMenu();
    }

    protected isValidateAvailable() {
        return (
            !!this.temporaryNewEntityName &&
            this.temporaryNewEntityName != this.newEntityName &&
            !this.availableEntities.some(
                (availableEntity) =>
                    availableEntity.DisplayName == this.temporaryNewEntityName
            )
        );
    }

    private initCurrentEntity(): EntityItem {
        const entityId = this.entityId ?? this.context.initialEntityId;
        const currentEntity = entityId
            ? this.availableEntities?.find(
                  (ei) => ei.DataReferenceId === entityId
              )
            : null;
        // Deleted entity case
        if (entityId && !currentEntity) {
            this.isUpdateChange.emit(false);
        }
        return currentEntity;
    }

    private filterEntities() {
        this.filteredEntities = StringUtil.filterSearched(
            this.searchTerm,
            this.availableEntities,
            (entity) => entity.DisplayName
        );
    }
}

import {
    GridMode,
    IGraphEdgeHoverEvent,
    IGraphNodePortHoverEvent,
} from '@datagalaxy/core-d3-util';
import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { EntityDksNode } from '../data-knowledge-studio.types';
import { EntityNodeTree } from '../nodes/entity/entity-node.types';

export interface EntityNodeTreeToggleEvent<NodeData> {
    node: EntityDksNode<NodeData>;
    nodeTree?: EntityNodeTree;
}

@Injectable()
export class DksNotifierService<NodeData = unknown, EdgeData = unknown> {
    private gridChange = new Subject<GridMode>();
    private extraDataChange = new Subject<string[]>();
    private entityNodeTreeToggle = new Subject<
        EntityNodeTreeToggleEvent<NodeData>
    >();
    private nodePortHover = new Subject<IGraphNodePortHoverEvent<NodeData>>();
    private edgeHover = new Subject<IGraphEdgeHoverEvent<NodeData, EdgeData>>();
    private entityShowMore = new Subject<string>();

    public readonly events$ = {
        gridChanged$: this.gridChange.asObservable(),
        extraDataChanged$: this.extraDataChange.asObservable(),
        entityNodeTreeToggle$: this.entityNodeTreeToggle.asObservable(),
        nodePortHover$: this.nodePortHover.asObservable(),
        edgeHover$: this.edgeHover.asObservable(),
        showMore$: this.entityShowMore.asObservable(),
    };

    public notifyGridChange(gridMode: GridMode) {
        this.gridChange.next(gridMode);
    }

    public notifyExtraDataChange(keys: string[]) {
        this.extraDataChange.next(keys);
    }

    public notifyEntityNodeTreeToggle(
        node: EntityDksNode<NodeData>,
        nodeTree?: EntityNodeTree
    ) {
        this.entityNodeTreeToggle.next({ node, nodeTree });
    }

    public notifyEntityShowMore(id: string) {
        this.entityShowMore.next(id);
    }

    public notifyNodePortHover(event: IGraphNodePortHoverEvent<NodeData>) {
        this.nodePortHover.next(event);
    }

    public notifyEdgeHover(event: IGraphEdgeHoverEvent<NodeData, EdgeData>) {
        this.edgeHover.next(event);
    }
}

<div class="container-box">
    <div
        class="container-header"
        [class.is-expanded]="!container.isCollapsed"
        (click)="onContainerHeaderClick()"
    >
        <app-dp-mapping-item
            [linkedItem]="container.collapsedItem"
            [type]="type"
            [virtual]="true"
            class="connection-target"
            [dxyRichTooltip]="dpiTooltipRef"
            [richTooltipIsEnabled]="headerRichTooltipIsEnabledFn"
            [class.item-highlighted]="container.collapsedItem.highlighted"
            (mouseenter)="
                onMouseEnterItem($event, container.collapsedItem, true)
            "
            (mouseleave)="onMouseLeaveItem($event, container.collapsedItem)"
        >
            <div
                class="container-title connection-source"
                [class.container-title--expanded]="!container.isCollapsed"
            >
                <div class="container-entity">
                    <app-entity-card-cell
                        [hierarchicalData]="hierarchicalData"
                        [noLabelNavLink]="true"
                        [noBreadcrumbNavLink]="true"
                    ></app-entity-card-cell>
                </div>
                <div class="container-action connection-nodrag">
                    <button
                        *ngIf="canExpandCollapse"
                        mat-icon-button
                        class="dg5-small-icon-button"
                    >
                        <i
                            class="glyph-arrow-drop-down"
                            [class.glyph-arrow-drop-down--collapsed]="
                                container.isCollapsed
                            "
                        ></i>
                    </button>
                    <dxy-burger-menu-button
                        *ngIf="bugerMenuVisible"
                        [options]="menuOptions"
                        [small]="true"
                    ></dxy-burger-menu-button>
                </div>
            </div>
        </app-dp-mapping-item>
    </div>
    <dxy-collapsible
        class="no-padding"
        [noHeader]="true"
        [isCollapsed]="container.isCollapsed"
    >
        <div *ngFor="let item of visibleMappingItems">
            <app-dp-mapping-item
                [linkedItem]="item"
                [type]="type"
                [virtual]="false"
                class="dg5-select-list-item connection-target"
                [class.item-highlighted]="item.highlighted"
                (mouseenter)="onMouseEnterItem($event, item)"
                (mouseleave)="onMouseLeaveItem($event, item)"
            >
                <div class="mappingItem mappingItem--table connection-source">
                    <span class="item-field item-name">{{
                        getColumnDisplayName(item.data)
                    }}</span>
                    <span class="item-field item-type">{{
                        item.data.ColumnData.ColumnDataTypeName
                    }}</span>
                    <span class="item-field item-size">{{
                        item.data.ColumnData.SizeString
                    }}</span>
                    <span
                        *ngIf="item.data.ColumnData.IsPrimaryKey"
                        class="item-field item-isPk"
                        >PK</span
                    >
                    <span class="item-actions connection-nodrag">
                        <button
                            *ngIf="hasWriteAccess"
                            mat-icon-button
                            class="glyph-delete"
                            (click)="onDeleteMappingItemClick(item)"
                        ></button>
                    </span>
                </div>
            </app-dp-mapping-item>
        </div>
    </dxy-collapsible>
</div>
<ng-template #dpiTooltipRef>
    <div
        class="dp-implem-element-header-rich-tooltip dg5-custom-tooltip"
        [translate]="headerRichTooltipKey"
    ></div>
</ng-template>

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DxyBaseComponent } from '@datagalaxy/ui/core';

/**
 * ## Role
 * Skeleton loader displayed before entity is fully loaded
 */
@Component({
    selector: 'app-entity-preview-panel-skeleton',
    templateUrl: 'entity-preview-panel-skeleton.component.html',
    styleUrls: ['entity-preview-panel-skeleton.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EntityPreviewPanelSkeletonComponent extends DxyBaseComponent {
    constructor() {
        super();
    }
}

import { TransitionService } from '@uirouter/core';
import { Ng2StateDeclaration } from '@uirouter/angular';
import { CommonStateDefinitions as CSD } from '../shared/util/states/CommonStateDefinitions';
import { Constants } from '../shared/util/Constants';
import { StateName } from '@datagalaxy/webclient/routing';
import Nav = Constants.Nav;
import UrlWord = Nav.UrlWord;
import { DgModule } from '@datagalaxy/shared/dg-module/domain';

export function getStatesForGlossary(): Ng2StateDeclaration[] {
    return [
        // common routes
        ...CSD.ModuleStates(DgModule.Glossary),

        // specific routes
        {
            name: StateName.GlossaryMappingLazyLoaded,
            url: `/${UrlWord.mapping}`,
            loadChildren: () =>
                import('../impactAnalysis/DxyImpactAnalysisModule').then(
                    (m) => m.DxyImpactAnalysisModule
                ),
        },

        CSD.LinkedObjects(StateName.GlossaryLinkedObjects),
        CSD.EntityImpactAnalysis(StateName.GlossaryImpactAnalysis),
        CSD.EntityDiagrams(StateName.GlossaryDiagramsTab, {
            persistenceGridId: 'diagram-list-glossary',
        }),
        CSD.DataMap(StateName.GlossaryDataMap),
    ];
}

export function glossaryRouterInit(transitionService: TransitionService) {
    CSD.dgModuleRouterInit(StateName.GlossaryDashboard, transitionService);
}

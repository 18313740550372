import { TransitionService } from '@uirouter/core';
import { Ng2StateDeclaration } from '@uirouter/angular';
import { CommonStateDefinitions as CSD } from '../shared/util/states/CommonStateDefinitions';
import { DpMappingComponent } from './mapping/dp-mapping/dp-mapping.component';
import { DpMappingUiService } from './mapping/dpMappingUi.service';
import { Constants } from '../shared/util/Constants';
import { StateName } from '@datagalaxy/webclient/routing';
import Nav = Constants.Nav;
import UrlWord = Nav.UrlWord;
import { Project } from '@datagalaxy/webclient/workspace/data-access';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';
import { DgModule } from '@datagalaxy/shared/dg-module/domain';

export function getStatesForProcessing(): Ng2StateDeclaration[] {
    return [
        // common routes
        ...CSD.ModuleStates(DgModule.Processing),

        // specific routes
        {
            name: StateName.DataProcessingMapping,
            url: `/${UrlWord.implementation}`,
            component: DpMappingComponent,
            resolve: [
                {
                    token: '_preload',
                    deps: ['spaceData', 'entityData', DpMappingUiService],
                    resolveFn: (
                        spaceData: Project,
                        entityData: EntityItem,
                        dpMappingsUiService: DpMappingUiService
                    ) => dpMappingsUiService.preload(entityData, spaceData),
                },
            ],
        },
        CSD.LinkedObjects(StateName.DataProcessingLinkedObjects),
        CSD.EntityImpactAnalysis(StateName.DataProcessingImpactAnalysis),
        CSD.EntityDiagrams(StateName.DataProcessingDiagramsTab, {
            persistenceGridId: 'diagram-list-data-processing',
        }),
        CSD.DataMap(StateName.DataProcessingDataMap),
    ];
}

export function dataProcessingRouterInit(transitionService: TransitionService) {
    CSD.dgModuleRouterInit(
        StateName.DataProcessingDashboard,
        transitionService
    );
}

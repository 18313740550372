import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DxyFieldSelectComponent } from '@datagalaxy/core-ui/fields';
import { IFieldSelectAdapter } from '@datagalaxy/core-ui';
import { DxyAttributeBaseInput } from '../../DxyAttributeBaseInput';
import { TranslateService } from '@ngx-translate/core';
import { DataUtil } from '../../../../util/DataUtil';
import { TechnologyUiService } from '../../../../../client-admin/services/technology-ui.service';
import { EntityEventService } from '../../../../entity/services/entity-event.service';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';
import { AttributeMetaValue } from '@datagalaxy/webclient/attribute/domain';
import { TechnologyDto } from '@datagalaxy/webclient/technology/domain';
import { DgModule } from '@datagalaxy/shared/dg-module/domain';
import { ServerConstants } from '@datagalaxy/shared/server/domain';

@Component({
    //eslint-disable-next-line @angular-eslint/component-selector
    selector: 'dxy-attribute-technology-input',
    templateUrl: 'dxy-attribute-technology-input.component.html',
})
export class DxyAttributeTechnologyInputComponent
    extends DxyAttributeBaseInput<string>
    implements OnInit
{
    @ViewChild('field') field: DxyFieldSelectComponent<AttributeMetaValue>;

    public readonly adapter: IFieldSelectAdapter<TechnologyDto> = {
        isModel: true,
        getIconUrl: (t) => this.technologyUiService.getTechnologyIconUrl(t),
        getGlyphClass: (t) =>
            !t || t?.ImageHash ? undefined : 'img-wrapper placeholder',
        getText: (t) =>
            t
                ? t.DisplayName
                : this.translate.instant('UI.TechnologyAdmin.undefinedTechno'),
        getSubText: (t) =>
            this.technologyUiService.getTechnologyCurrentLangDescription(t),
        onSelectionChange: (t) => this.onSelectionChange(t),
    };

    public get hint() {
        return super.hint || this.currentDescription;
    }

    private currentDescription: string;

    private get currentModule() {
        return this.fieldInfo?.entityForm?.getModule() ?? DgModule.unknown;
    }

    constructor(
        elementRef: ElementRef<HTMLElement>,
        translate: TranslateService,
        private technologyUiService: TechnologyUiService,
        private entityEventService: EntityEventService
    ) {
        super(elementRef, translate);
    }

    ngOnInit() {
        this.init();
        super.ngOnInit();

        const moduleServerType = DataUtil.getDefaultServerTypeFromModule(
            this.currentModule
        );
        super.registerSubscription(
            this.entityEventService.subscribeEntityTechnologyUpdate(
                moduleServerType,
                (data) => this.onEntityUpdate(data)
            )
        );
        super.subscribe(
            this.fieldInfo?.entityForm.attributeValueLocalChange$,
            (attributeKey) => {
                if (attributeKey != this.attributeKey) {
                    return;
                }
                this.setCurrentOption();
            }
        );
    }

    //#region IAttributeBaseInputOverride
    public focusField() {
        this.field.doFocus();
    }
    public blurField() {
        this.field.doBlur();
    }
    public onAfterUndo() {
        this.setCurrentOption();
    }
    //#endregion

    private init() {
        const technologies =
            this.technologyUiService.getAvailableTechnologiesFromModule(
                this.currentModule
            );
        this.adapter.options = [null, ...technologies];
        this.setCurrentOption();
    }

    private async onSelectionChange(t: TechnologyDto) {
        await this.setData(t?.TechnologyCode);
        this.updateDescription();
    }

    private setCurrentOption() {
        this.updateCurrentOption(this.getData());
    }

    private onEntityUpdate(updatedData: EntityItem) {
        const entityData = this.getEntityData();
        if (
            updatedData.ReferenceId != entityData.ReferenceId &&
            !entityData.HddData.Parents.some(
                (ancestor) =>
                    ancestor.DataReferenceId == updatedData.ReferenceId
            )
        ) {
            return;
        }
        const newTechnoCode = updatedData.getAttributeValue<string>(
            ServerConstants.PropertyName.Technology
        );
        if (newTechnoCode != this.adapter.current.TechnologyCode) {
            this.updateCurrentOption(newTechnoCode);
        }
    }

    private updateCurrentOption(technoCode: string) {
        const techno = this.adapter.options.find(
            (t) => t?.TechnologyCode == technoCode
        );
        this.adapter.current = techno;
        this.updateDescription();
    }

    private updateDescription() {
        this.currentDescription =
            this.technologyUiService.getTechnologyCurrentLangDescription(
                this.adapter.current
            );
    }
}

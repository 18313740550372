import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    HostBinding,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import {
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
} from '@angular/forms';
import { TitleInputComponent } from '@datagalaxy/ui/forms';
import { TranslateService } from '@ngx-translate/core';
import { debounceTime, distinctUntilChanged } from 'rxjs';

@Component({
    selector: 'dxy-widget-title-editor',
    templateUrl: './widget-title-editor.component.html',
    styleUrls: ['./widget-title-editor.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WidgetTitleEditorComponent implements OnChanges {
    @Input() defaultTitleKey: string;
    @Input() customTitle?: string;
    @Input() editionEnabled = false;

    @Output() customTitleChange = new EventEmitter<string>();

    @ViewChild('titleInput') private titleInput?: TitleInputComponent;

    @HostBinding('class.fullWidth') get fullWidth() {
        return this.editionEnabled;
    }

    protected customTitleForm: FormGroup<CustomTitleForm>;
    protected customTitleMaxLength = 50;

    private get defaultTitle() {
        return this.defaultTitleKey
            ? this.translate.instant(this.defaultTitleKey)
            : '';
    }

    constructor(private fb: FormBuilder, private translate: TranslateService) {
        this.createForm();
        this.customTitleForm.controls.customTitle.valueChanges
            .pipe(debounceTime(444), distinctUntilChanged())
            .subscribe((newTitle) => {
                if (this.customTitle == newTitle) {
                    return;
                }
                this.customTitleChange.emit(newTitle);
            });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.customTitle) {
            this.customTitleForm.patchValue(
                {
                    customTitle:
                        changes.customTitle.currentValue ?? this.defaultTitle,
                },
                { emitEvent: false }
            );
        }
        if (changes.defaultTitleKey) {
            this.defaultTitleKey = changes.defaultTitleKey.currentValue;
            this.customTitleForm.patchValue(
                {
                    customTitle:
                        this.customTitle ??
                        this.translate.instant(
                            changes.defaultTitleKey.currentValue
                        ),
                },
                { emitEvent: false }
            );
        }
    }

    public focusTitleInput() {
        this.titleInput?.focusInput();
    }

    public onBlur() {
        this.customTitleForm.patchValue({
            customTitle:
                this.customTitle?.length == 0
                    ? this.defaultTitle
                    : this.customTitle,
        });
    }

    private createForm() {
        this.customTitleForm = this.fb.group<CustomTitleForm>({
            customTitle: new FormControl('', {
                validators: [Validators.maxLength(this.customTitleMaxLength)],
            }),
        });
    }
}

interface CustomTitleForm {
    customTitle: FormControl<string>;
}

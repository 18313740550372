import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnInit,
} from '@angular/core';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { AsyncPipe, NgClass, NgForOf, NgIf } from '@angular/common';
import { IEntityIdentifier } from '@datagalaxy/dg-object-model';
import { Observable } from 'rxjs';
import { DxyButtonsModule } from '@datagalaxy/ui/buttons';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { DirectivesModule } from '../../directives/directives.module';
import {
    CrudActionType,
    CrudOperation,
    FunctionalLogService,
} from '@datagalaxy/webclient/monitoring/data-access';
import { LineageEntityStreamService } from './lineage-entity-stream.service';
import {
    LineageNodeStream,
    LineageNodeStreamDirection,
    LineageNodeStreamStatus,
} from './lineage-entity-stream.types';
import { LineageStreamExplorerButtonComponent } from '../lineage-stream-explorer/lineage-stream-explorer-button/lineage-stream-explorer-button.component';
import { LineageStreamExplorerComponent } from '../lineage-stream-explorer/lineage-stream-explorer.component';
import { LineageStreamAction } from '../lineage-stream-explorer/lineage-stream-explorer.types';

@Component({
    standalone: true,
    selector: 'app-lineage-entity-stream-buttons',
    templateUrl: './lineage-entity-stream-buttons.component.html',
    styleUrls: ['./lineage-entity-stream-buttons.component.scss'],
    imports: [
        MatLegacyButtonModule,
        NgIf,
        AsyncPipe,
        DxyButtonsModule,
        MatLegacyTooltipModule,
        TranslateModule,
        DirectivesModule,
        NgClass,
        NgForOf,
        LineageStreamExplorerButtonComponent,
        LineageStreamExplorerComponent,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LineageEntityStreamButtonsComponent implements OnInit {
    @Input() entityIdr: IEntityIdentifier;

    protected entityStreamInfos$: Observable<LineageNodeStream[]>;
    protected entityHiddenStreamInfos$: Observable<LineageNodeStream[]>;

    constructor(
        private lineageEntityStreamService: LineageEntityStreamService,
        private functionalLogService: FunctionalLogService
    ) {}

    ngOnInit() {
        this.entityStreamInfos$ =
            this.lineageEntityStreamService.selectEntityStreamInfos(
                this.entityIdr
            );

        this.entityHiddenStreamInfos$ =
            this.lineageEntityStreamService.selectEntityHiddenChildrenStreamInfos(
                this.entityIdr
            );
    }

    protected onSelect(
        action: LineageStreamAction,
        stream: LineageNodeStream,
        hidden?: boolean
    ) {
        return this.onStreamClick(stream, action.data.level, hidden);
    }

    protected streamClass(stream: LineageNodeStream) {
        const className = LineageNodeStreamDirection[stream.type].toLowerCase();

        return [
            className,
            stream.type === LineageNodeStreamDirection.TopStream
                ? 'glossary'
                : '',
            stream.virtual ? 'virtual' : '',
            stream.isRoot ? 'root' : '',
            stream.isChild ? 'child' : '',
        ].join(' ');
    }

    protected onStreamEnter(event: MouseEvent) {
        /**
         * Avoid the parent node to be considered as hovered
         */
        event.stopPropagation();
    }

    protected onStreamLeave(event: MouseEvent) {
        /**
         * Avoid the parent node to be considered as leaved
         */
        event.stopPropagation();
    }

    protected onStreamClick(
        stream: LineageNodeStream,
        level: number,
        hidden?: boolean
    ) {
        if (stream.status === LineageNodeStreamStatus.Expanded) {
            this.lineageEntityStreamService.collapseEntityStream(
                this.entityIdr,
                stream.type
            );
            this.functionalLogService.logFunctionalAction(
                this.streamFeatureCode(stream),
                CrudOperation.A,
                this.getStreamCollapseActionType(stream)
            );
            return;
        }

        this.functionalLogService.logFunctionalAction(
            this.streamFeatureCode(stream),
            CrudOperation.A,
            this.getStreamExpandActionType(stream)
        );

        return this.lineageEntityStreamService.expandEntityStream(
            this.entityIdr,
            stream.type,
            level,
            hidden,
            level > 1 ? stream.isPrincipal : undefined
        );
    }

    private streamFeatureCode(stream: LineageNodeStream) {
        const key = stream.isPrincipal ? 'PRIMARY' : 'SECONDARY';
        return `LINEAGE_${key}_EXPLORE`;
    }

    private getStreamCollapseActionType(stream: LineageNodeStream) {
        switch (stream.type) {
            case LineageNodeStreamDirection.Downstream:
                return CrudActionType.UndoDownStream;
            case LineageNodeStreamDirection.Upstream:
                return CrudActionType.UndoUpStream;
            case LineageNodeStreamDirection.TopStream:
                return CrudActionType.UndoBusinessStream;
            case LineageNodeStreamDirection.BottomStream:
                return CrudActionType.UndoTechnicalStream;
        }
    }

    private getStreamExpandActionType(stream: LineageNodeStream) {
        switch (stream.type) {
            case LineageNodeStreamDirection.Downstream:
                return CrudActionType.ExpandDownstream;
            case LineageNodeStreamDirection.Upstream:
                return CrudActionType.ExpandUpstream;
            case LineageNodeStreamDirection.TopStream:
                return CrudActionType.ExpandBusiness;
            case LineageNodeStreamDirection.BottomStream:
                return CrudActionType.ExpandTechnical;
        }
    }
}

import { NgModule } from '@angular/core';
import { DxyEntityGridComponent } from './entity-grid/dxy-entity-grid/dxy-entity-grid.component';
import { DxyEntityCreateModalComponent } from './dxy-entity-create-modal/dxy-entity-create-modal.component';
import { DxyAttributesModule } from '../attribute/DxyAttributesModule';
import { DxySharedUiModule } from '../shared-ui/DxySharedUiModule';
import { DxyEntityMoveModalComponent } from './dxy-entity-move-modal/dxy-entity-move-modal.component';
import { DxyLocalSynonymModalComponent } from './dxy-local-synonym-modal/dxy-local-synonym-modal.component';
import { DxyEntityBulkEditModalComponent } from './dxy-entity-bulk-edit-modal/dxy-entity-bulk-edit-modal.component';
import { DxySharedDialogsModule } from '../dialogs/DxySharedDialogsModule';
import { DxyEntityFormComponent } from './dxy-entity-form/dxy-entity-form.component';
import { DxyCompareEntityFormComponent } from './dxy-compare-entity-form/dxy-compare-entity-form.component';
import { DxyEntitySynonymsComponent } from './dxy-entity-synonyms/dxy-entity-synonyms.component';
import { DxyEntityDetailsComponent } from './dxy-entity-details/dxy-entity-details.component';
import { DxyScreensLayoutModule } from '../screens-layout/DxyScreensLayoutModule';
import { DxyEntityHeaderComponent } from './dxy-entity-header/dxy-entity-header.component';
import { EntityLinkedObjectsComponent } from './entity-linked-objects/entity-linked-objects.component';
import { DxyEntityTreeComponent } from './dxy-entity-tree/dxy-entity-tree.component';
import { DxyEntityImportButtonComponent } from './dxy-entity-import-button/dxy-entity-import-button.component';
import { DxyEntityPopoverTooltipRichComponent } from './dxy-entity-popover-tooltip-rich/dxy-entity-popover-tooltip-rich.component';
import { DxyOmnigridModule } from '@datagalaxy/core-ui/omnigrid';
import { EntityTableColumnsComponent } from './entity-table-columns/entity-table-columns.component';
import { entityCellComponentsList } from './cells/entity-cell-components-list';
import { EntityCardModule } from '../entityCard/entity-card.module';
import { DxyEntityUserListPopoverTooltipComponent } from './dxy-entity-user-list-popover-tooltip/dxy-entity-user-list-popover-tooltip.component';
import { DxyNavigationModule } from '../../navigation/DxyNavigationModule';
import { DxyFilterModule } from '../filter/DxyFilterModule';
import { LinkedObjectModalComponent } from './linked-object-modal/linked-object-modal.component';
import { SharedModule } from '../shared.module';
import { EntityAssetsComponent } from './entity-assets/entity-assets.component';
import { DxyEntityViewToggleButtonComponent } from './dxy-entity-view-toggle-button/dxy-entity-view-toggle-button.component';
import { FieldsModule } from '../fields/fields.module';
import { WatchEntityButtonComponent } from './watch-entity-button/watch-entity-button.component';
import { EntityTypeCardComponent } from '@datagalaxy/webclient/entity/ui';
import { DxyEntityHeaderTitleComponent } from './dxy-entity-header-title/dxy-entity-header-title.component';
import { EntityDisplayNameComponent } from './entity-display-name/entity-display-name.component';
import { DxyButtonsModule } from '@datagalaxy/ui/buttons';
import {
    AttributeTranslationButtonComponent,
    MultilingualFeatureModule,
} from '@datagalaxy/webclient/multilingual/feature';
import { GridComponent } from '@datagalaxy/ui/grid';

@NgModule({
    imports: [
        SharedModule,
        DxyNavigationModule,
        DxyAttributesModule,
        DxySharedDialogsModule,
        DxySharedUiModule,
        FieldsModule,
        DxyScreensLayoutModule,
        DxyOmnigridModule,
        EntityCardModule,
        DxyFilterModule,
        EntityTypeCardComponent,
        DxyButtonsModule,
        MultilingualFeatureModule,
        GridComponent,
        AttributeTranslationButtonComponent,
    ],
    declarations: [
        DxyEntityGridComponent,
        DxyEntityCreateModalComponent,
        LinkedObjectModalComponent,
        DxyEntityMoveModalComponent,
        DxyLocalSynonymModalComponent,
        DxyEntityBulkEditModalComponent,
        DxyEntityFormComponent,
        DxyCompareEntityFormComponent,
        DxyEntitySynonymsComponent,
        DxyEntityDetailsComponent,
        DxyEntityHeaderComponent,
        DxyEntityHeaderTitleComponent,
        EntityDisplayNameComponent,
        EntityLinkedObjectsComponent,
        DxyEntityTreeComponent,
        DxyEntityImportButtonComponent,
        DxyEntityViewToggleButtonComponent,
        DxyEntityUserListPopoverTooltipComponent,
        DxyEntityPopoverTooltipRichComponent,
        EntityTableColumnsComponent,
        EntityAssetsComponent,
        WatchEntityButtonComponent,
        ...entityCellComponentsList,
    ],
    exports: [
        DxyEntityGridComponent,
        DxyEntityCreateModalComponent,
        LinkedObjectModalComponent,
        DxyEntityMoveModalComponent,
        DxyLocalSynonymModalComponent,
        DxyEntityBulkEditModalComponent,
        DxyEntityFormComponent,
        DxyCompareEntityFormComponent,
        DxyEntitySynonymsComponent,
        DxyEntityDetailsComponent,
        DxyEntityHeaderComponent,
        DxyEntityHeaderTitleComponent,
        EntityLinkedObjectsComponent,
        DxyEntityTreeComponent,
        DxyEntityImportButtonComponent,
        DxyEntityViewToggleButtonComponent,
        EntityTableColumnsComponent,
        EntityAssetsComponent,
        WatchEntityButtonComponent,
        ...entityCellComponentsList,
    ],
})
export class DxySharedEntityModule {}

import { Component } from '@angular/core';
import { BaseCellComponent } from '@datagalaxy/core-ui/cell-components';
import { RichTextContent } from '@datagalaxy/core-ui/rich-text';

/**
 * ## Role
 * Display formatted text in an omnigrid
 */
@Component({
    selector: 'app-dg-formatted-text-cell',
    templateUrl: 'dg-formatted-text-cell.component.html',
})
export class DgFormattedTextCellComponent extends BaseCellComponent<
    unknown,
    string
> {
    public text?: string;

    constructor() {
        super();
    }

    protected override updateBindings() {
        this.text = this.getText();
    }

    /** returns raw (flat) text from RichTextContent */
    protected getText() {
        return this.value ? RichTextContent.getRawText(this.value) : null;
    }
}

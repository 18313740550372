import { Directive, ElementRef, Input, NgZone, OnInit } from '@angular/core';
import { DomUtil } from '@datagalaxy/core-util';
import { DxyBaseDirective } from '@datagalaxy/ui/core';
import { FunctionalLogService } from '@datagalaxy/webclient/monitoring/data-access';

/**
 * Role: Logs a functional action code on click and change user events
 */
@Directive({
    selector: '[dxyLogFunctional]',
})
export class DxyLogFunctionalDirective
    extends DxyBaseDirective
    implements OnInit
{
    @Input() dxyLogFunctional: string;

    constructor(
        private ngZone: NgZone,
        private elementRef: ElementRef<HTMLElement>,
        private functionalLogService: FunctionalLogService
    ) {
        super();
    }

    ngOnInit() {
        DomUtil.addListener(
            this.elementRef.nativeElement,
            'change',
            (e) => this.onChangeEvent(e),
            this.ngZone
        );
        DomUtil.addListener(
            this.elementRef.nativeElement,
            'click',
            (e) => this.onClickEvent(e),
            this.ngZone
        );
    }

    private onChangeEvent(e: Event) {
        if (e.cancelBubble) {
            return;
        }
        this.logAction(e?.type);
    }

    private onClickEvent(e: Event) {
        if (e.cancelBubble) {
            return;
        }
        this.logAction(e?.type);
    }

    private logAction(eventType: string) {
        this.log(
            'logAction',
            this.dxyLogFunctional,
            eventType,
            !!this.functionalLogService
        );
        this.functionalLogService?.parseAndLog(
            this.dxyLogFunctional,
            this.elementRef
        );
    }
}

import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { IActionOption } from '@datagalaxy/core-ui';
import { EntityDksNode } from '../../data-knowledge-studio.types';
import { EntityPreviewPanelService } from '../../../shared/entity/services/entity-preview-panel.service';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { IOnStageAsset, IOnStageState } from './asset-onstage.types';
import { CollectionsHelper, StringUtil } from '@datagalaxy/core-util';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { IEntityIdentifier } from '@datagalaxy/dg-object-model';
import { debounceTime } from 'rxjs/operators';

/**
 * ## Role
 * Synchronized list of items present in the diagram
 */
@Component({
    selector: 'app-dks-asset-onstage',
    templateUrl: 'asset-onstage.component.html',
    styleUrls: ['./asset-onstage.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DksAssetOnStageComponent<NodeData>
    extends DxyBaseComponent
    implements OnChanges, OnInit
{
    @Input() assets: IOnStageAsset<NodeData>[];
    @Input() disabledSelection = false;
    @Input() entityActions: IActionOption<Partial<EntityDksNode<NodeData>>>[];

    @Output() select = new EventEmitter<string[]>();
    @Output() unselect = new EventEmitter<string[]>();

    protected searchBoxTerm$ = new BehaviorSubject<string>('');
    protected state$: Observable<IOnStageState<NodeData>>;

    public get showHeader() {
        return !this.disabledSelection;
    }

    constructor(private entityPreviewPanelService: EntityPreviewPanelService) {
        super();
    }

    ngOnInit() {
        this.state$ = this.searchBoxTerm$.pipe(
            map((searchTerm) => this.makeState(this.assets, searchTerm)),
            debounceTime(300)
        );
    }

    ngOnChanges(changes: SimpleChanges) {
        super.onChange(changes, 'assets', () => {
            this.searchBoxTerm$.next(this.searchBoxTerm$.value);
        });
    }

    protected assetTrackBy(_: number, asset: IOnStageAsset<NodeData>) {
        return asset.node.id;
    }

    private makeState(
        assets: IOnStageAsset<NodeData>[],
        searchTerm: string
    ): IOnStageState<NodeData> {
        const orderedAssets = CollectionsHelper.orderByText(
            assets,
            (o) => o.entity?.DisplayName || ''
        );
        const filteredAssets = StringUtil.filterSearched(
            searchTerm,
            orderedAssets,
            (o) => o.entity?.DisplayName || ''
        );
        const allSelected = !filteredAssets.some((n) => !n.selected);
        return {
            allSelected,
            indeterminateSelection:
                !allSelected && filteredAssets.some((n) => n.selected),
            burgerMenu: [],
            showSearchBar: assets?.length > 10,
            assets: filteredAssets,
        };
    }

    protected toggleSelection(
        assets: IOnStageAsset<NodeData>[],
        selected: boolean
    ) {
        const nodeIds = assets.map((asset) => asset.node.id);
        if (selected) {
            this.select.emit(nodeIds);
        } else {
            this.unselect.emit(nodeIds);
        }
    }

    protected async openPreviewPanel(entityIdr: IEntityIdentifier) {
        await this.entityPreviewPanelService.setupPanel({
            entityIdr,
        });
    }
}

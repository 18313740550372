import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import { AttributeFilterModel } from '../attribute-filter/attributeFilterModel';
import { ModuleAttributeFilterFormModel } from './module-attribute-filter-form.model';
import { AttributeFilterService } from '../attribute-filter.service';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AttributeFilterAction } from '../attribute-filter.types';
import { CollectionsHelper, CoreUtil } from '@datagalaxy/core-util';
import { FilteredViewService } from '../../../filter/services/filteredView.service';
import { FilteredViewsUtil } from '@datagalaxy/webclient/filter/data-access';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { Filter, FilteredViewDto } from '@datagalaxy/webclient/filter/domain';
import { DgZone } from '@datagalaxy/webclient/domain';
import { AttributeMetaInfo } from '@datagalaxy/webclient/attribute/domain';
import { DgModule } from '@datagalaxy/shared/dg-module/domain';

/**
 * ## Role
 * Display an attribute filter form for filtered view in modules
 */
@Component({
    selector: 'app-attribute-filter-form',
    templateUrl: './attribute-filter-form.component.html',
    styleUrls: ['attribute-filter-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttributeFilterFormComponent
    extends DxyBaseComponent
    implements OnInit, OnChanges
{
    @Input() filteredView: FilteredViewDto;

    public get availableAttributes() {
        return this.attributeFilterForm?.availableAttributes;
    }
    public get searchTerm() {
        return this.attributeFilterForm?.searchTerm;
    }
    public set searchTerm(value: string) {
        this.attributeFilterForm.searchTerm = value;
    }

    //#region filterItems
    public get filterItems$() {
        return this.attributeFilterForm?.displayedFilters$;
    }
    //#endregion

    //#region locals
    private attributeFilterForm: ModuleAttributeFilterFormModel;
    private searchTermChanged = new Subject<string>();
    private eventsSubscription: Subscription;
    //#endregion

    constructor(
        private attributeFilterService: AttributeFilterService,
        private filteredViewService: FilteredViewService,
        private cd: ChangeDetectorRef
    ) {
        super();
    }

    ngOnInit() {
        this.initAsync();
    }

    ngOnChanges(changes: SimpleChanges) {
        super.onChange(changes, 'filteredView', () => this.initAsync());
    }

    //#region html events
    public onFilterItemAction(
        afm: AttributeFilterModel,
        action: AttributeFilterAction
    ) {
        this.log('onFilterItemAction', afm, action);
        switch (action) {
            case AttributeFilterAction.itemChanged:
                this.onFilterItemChanged(afm);
                break;
            case AttributeFilterAction.itemRemoved:
                this.onFilterItemRemoving(afm);
                break;
            case AttributeFilterAction.formClosed:
                this.onFilterItemFormClosed(afm);
                break;
            default:
                CoreUtil.warn('action not implemented');
                break;
        }
    }
    public onSearchTermChange() {
        this.log('onSearchTermChange', this.searchTerm);
        this.searchTermChanged.next(this.searchTerm);
    }

    public onAddAttribute(attribute: AttributeMetaInfo) {
        this.log('onAddAttribute', attribute);
        this.addFilterFromAttribute(attribute);
    }
    //#endregion html events

    private async initAsync() {
        this.log('init', { filteredView: this.filteredView });
        await this.initAttributeFilterForm(this.filteredView);
    }
    private async initAttributeFilterForm(fv: FilteredViewDto) {
        this.log('initFormModel', fv);
        this.attributeFilterForm =
            await this.attributeFilterService.getModuleAttributeFilterForm(fv);
        this.subscribeEvents();
        this.cd.detectChanges();
    }

    private updateFormFiltersIfNeeded(filters: Filter[]) {
        if (this.areFiltersEqualsToFormFilters(filters)) {
            return;
        }
        this.attributeFilterForm.setupFilterItems(filters);
    }

    private areFiltersEqualsToFormFilters(filters: Filter[]) {
        return CollectionsHelper.contentEquals(
            filters,
            this.attributeFilterForm.computeFilters(),
            true,
            true,
            (a, b) => Filter.equals(a, b, true)
        );
    }

    private subscribeEvents() {
        this.eventsSubscription = super.renewSubscriptionGroup(
            this.eventsSubscription,
            this.searchTermChanged
                .pipe(debounceTime(444), distinctUntilChanged())
                .subscribe(() => this.updateFilteredViewFilters()),
            this.attributeFilterForm.filterItemsChanged$.subscribe(() =>
                this.updateFilteredViewFilters()
            ),
            this.filteredViewService.currentViewChanged$.subscribe((e) =>
                this.onFilteredViewChange(e.dgZone, e.filteredView)
            )
        );
    }

    private onFilteredViewChange(
        dgZone: DgZone,
        filteredView: FilteredViewDto
    ) {
        if (
            dgZone != this.filteredView.DgZone ||
            filteredView !== this.filteredView
        ) {
            return;
        }
        const filters = filteredView.filters;
        this.log('onFilteredViewChange', filteredView.filters);
        this.updateFormFiltersIfNeeded(filters);
    }

    private updateFilteredViewFilters() {
        this.log('updateFilteredViewFilters');
        if (this.areFiltersEqualsToFormFilters(this.filteredView.filters)) {
            return;
        }
        this.filteredView.filters = this.attributeFilterForm.computeFilters();
        this.filteredViewService.notifyCurrentViewChange({
            dgZone: this.filteredView.DgZone,
            filteredView: this.filteredView,
        });
    }

    private onFilterItemRemoving(filterItem: AttributeFilterModel) {
        this.deleteFilterItem(filterItem);
    }

    private onFilterItemChanged(afm: AttributeFilterModel) {
        const dgModule = FilteredViewsUtil.dgModuleFromModuleName(
            this.filteredView.ModuleName
        );
        this.attributeFilterService.logFilter(
            afm,
            DgModule[dgModule].toUpperCase()
        );
        this.updateFilteredViewFilters();
    }

    private onFilterItemFormClosed(afm: AttributeFilterModel) {
        if (
            afm.attributeKey ==
            this.attributeFilterForm.entityTypeAttribute.AttributeKey
        ) {
            return;
        }
        if (!afm.isValid()) {
            this.deleteFilterItem(afm);
        }
    }

    private addFilterFromAttribute(attribute: AttributeMetaInfo) {
        return this.attributeFilterForm.addFilterFromAttribute(attribute);
    }
    private deleteFilterItem(filterItem: AttributeFilterModel) {
        this.attributeFilterForm.removeFilterItem(filterItem);
    }
}

import { NgModule } from '@angular/core';
import { DxyOptionItemComponent } from './option-item.component';
import { CommonModule } from '@angular/common';
import { DxyColorPointCellComponent } from '../../cell-components';
import { DxyCoreDirectivesModule } from '../../directives';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { DxyRendererComponent } from '../../components/renderer/renderer.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    imports: [
        CommonModule,
        MatTooltipModule,
        TranslateModule,
        DxyCoreDirectivesModule,
        DxyColorPointCellComponent,
        DxyRendererComponent,
    ],
    declarations: [DxyOptionItemComponent],
    exports: [DxyOptionItemComponent],
})
export class DxyOptionItemModule {}

import {
    DxyDgDateTimeCellComponent,
    DxyEllipsisTooltipCellComponent,
} from '@datagalaxy/core-ui';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppEventsService } from '../../services/AppEvents.service';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import {
    GridCellType,
    GridComponent,
    GridConfig,
    TColDef,
} from '@datagalaxy/ui/grid';
import {
    EntityTaskDTO,
    HierarchicalData,
    IEntityIdentifier,
} from '@datagalaxy/dg-object-model';
import { TaskSearchFilterGroup } from '../dxy-task-facet-filters/dxy-task-facet-filters.component';
import { EntityCardCellComponent } from '../../shared/entityCard/entity-card/entity-card-cell.component';
import { TaskService } from '../task.service';
import { TaskSearchStateService } from '../task-search-state.service';
import { EntityIdentifier } from '@datagalaxy/webclient/entity/utils';
import { EntityPreviewPanelService } from '../../shared/entity/services/entity-preview-panel.service';
import { EntityPanelTool } from '../../entity-panels/entity-panels.types';
import { DgUserBadgeCellComponent } from '../../shared/shared-ui/cells/dg-user-badge-cell/dg-user-badge-cell.component';
import { UserService } from '../../services/user.service';

@Component({
    selector: 'dxy-task-results',
    templateUrl: 'dxy-task-results.component.html',
    styleUrls: ['dxy-task-results.component.scss'],
})
export class DxyTaskResultsComponent
    extends DxyBaseComponent
    implements OnInit
{
    @Input() headerGridTitle: string;
    @Input() filterGroups: TaskSearchFilterGroup[] = [];

    @ViewChild(GridComponent) grid: GridComponent<EntityTaskDTO>;

    protected gridConfig: GridConfig<EntityTaskDTO> = {
        getItemId: (item) => item.ReferenceId,
    };

    protected cols: TColDef<EntityTaskDTO>[] = [
        {
            id: 'Type',
            headerLabel: this.translate.instant('UI.Search.colTaskType'),
            type: GridCellType.text,
            getValue: (row) =>
                this.translate.instant(
                    this.taskService.getTypeTranslateKey(row.Type)
                ),
            minWidth: 150,
            width: 250,
            maxWidth: 250,
            sortable: true,
        },
        {
            id: 'titleAndRawText',
            headerLabel: this.translate.instant('UI.Search.colTaskText'),
            type: GridCellType.custom,
            customCellComponent: DxyEllipsisTooltipCellComponent,
            getInputs: (row) => ({
                text: [row.Title, row.RawText],
                highlightTitle: true,
            }),
            minWidth: 150,
            width: 300,
            sortable: true,
            getSortableValue: (row) => row.Title,
        },
        {
            id: 'entityDisplayName',
            headerLabel: this.translate.instant('UI.Search.colTaskEntity'),
            type: GridCellType.custom,
            customCellComponent: EntityCardCellComponent,
            getInputs: (row) => {
                const hdd = row.HddData;
                return {
                    hierarchicalData: new HierarchicalData(
                        hdd.Parents[0],
                        hdd.Parents.slice(1)
                    ),
                    dtContext: 'Task Search',
                } as Partial<EntityCardCellComponent>;
            },
            sortable: true,
            getSortableValue: (row) => row.HddData.Parents?.[0].DisplayName,
            minWidth: 150,
            width: 175,
        },
        {
            id: 'Status',
            headerLabel: this.translate.instant('UI.Search.colTaskStatus'),
            type: GridCellType.text,
            getValue: (row) =>
                this.translate.instant(
                    this.taskService.getStatusTranslateKey(row.Status)
                ),
            minWidth: 125,
            width: 125,
            sortable: true,
        },
        {
            id: 'CreationUserId',
            headerLabel: this.translate.instant('UI.Search.colTaskCreator'),
            type: GridCellType.custom,
            customCellComponent: DgUserBadgeCellComponent,
            getInputs: (row) =>
                ({
                    userId: row.CreationUserId,
                    entityData: row,
                } as Partial<DgUserBadgeCellComponent>),
            sortable: true,
            getSortableValue: (row) =>
                this.userService.getUserName(row.CreationUserId),
            minWidth: 70,
            width: 70,
        },
        {
            id: 'AssignedToUserId',
            headerLabel: this.translate.instant('UI.Search.colTaskAssignedTo'),
            type: GridCellType.custom,
            customCellComponent: DgUserBadgeCellComponent,
            getInputs: (row) =>
                ({
                    userId: row.AssignedToUserId,
                    entityData: row,
                } as Partial<DgUserBadgeCellComponent>),
            sortable: true,
            getSortableValue: (row) =>
                this.userService.getUserName(row.AssignedToUserId),
            minWidth: 70,
            width: 70,
        },
        {
            id: 'DueTime',
            headerLabel: this.translate.instant('UI.Search.colTaskDueTime'),
            type: GridCellType.custom,
            customCellComponent: DxyDgDateTimeCellComponent,
            getInputs: (row) =>
                ({
                    dgDate: row.DueTime,
                    noTime: true,
                } as Partial<DxyDgDateTimeCellComponent<EntityTaskDTO>>),
            minWidth: 100,
            width: 100,
            sortable: true,
            getSortableValue: (row) => new Date(row.DueTime).getTime(),
        },
    ];

    protected rows$ = this.stateService.selectFilteredTasks();

    public isLoading$ = this.stateService.selectLoading();

    public readonly dockingPaneActiveTool =
        EntityPanelTool[EntityPanelTool.Tasks];

    constructor(
        private appEventsService: AppEventsService,
        private translate: TranslateService,
        private taskService: TaskService,
        private stateService: TaskSearchStateService,
        private entityPreviewPanelService: EntityPreviewPanelService,
        private userService: UserService
    ) {
        super();
    }

    ngOnInit() {
        super.subscribe(this.appEventsService.viewTypeChange$, () =>
            this.grid.refreshCellsLayout()
        );
    }

    public async onRowClicked(taskDTO: EntityTaskDTO) {
        const taskHdd = taskDTO.HddData;
        const entityHdd = taskHdd.Parents[0];
        const ei = new EntityIdentifier(
            entityHdd.DataReferenceId,
            taskHdd.VersionId,
            entityHdd.EntityType
        );
        await this.openPreviewPanel(ei);
    }

    private async openPreviewPanel(entityIdr: IEntityIdentifier) {
        await this.entityPreviewPanelService.setupPanel({
            entityIdr,
            activeTool: this.dockingPaneActiveTool,
        });
    }
}

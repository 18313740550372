<div class="header">
    <i class="title-icon glyph-activity-widget"></i>
    <h1 class="title" translate>
        UI.SpaceHome.Widgets.SpaceActivityLogs.Title
    </h1>
    <div class="title-action">
        <button
            mat-icon-button
            (click)="onCloseClick()"
            class="entity-pane-close-btn"
        >
            <i class="glyph-cancelsearch"></i>
        </button>
    </div>
</div>
<div class="body">
    <div class="filter-wrapper">
        <dxy-value-list-filter
            labelKey="UI.SpaceHome.Widgets.SpaceActivityLogs.Preview.FilterLabel"
            [items]="typeFilterOptions"
            [filter]="typeFilter"
            [noOperator]="true"
            [adapter]="typeFilterAdapter"
            [hasRemove]="hasTypeFilter"
            (remove)="clearTypeFilter()"
            (filterChange)="onLogFilterChange($event)"
        ></dxy-value-list-filter>
        <dxy-date-filter
            labelKey="UI.SpaceHome.Widgets.SpaceActivityLogs.Preview.DateFilter"
            [filter]="dateFilter"
            [hasRemove]="hasDateFilter"
            [noOperator]="true"
            (filterChange)="onDateFilterChange($event)"
            (remove)="clearDateFilter()"
        ></dxy-date-filter>
        <dxy-value-list-filter
            labelKey="UI.SpaceHome.Widgets.SpaceActivityLogs.Preview.UserFilter"
            [items]="userFilterOptions"
            [filter]="userFilter"
            [noOperator]="true"
            [adapter]="userFilterAdapter"
            [hideIcon]="true"
            [hasRemove]="hasUserFilter"
            (remove)="clearUserFilter()"
            (filterChange)="onUserFilterChange($event)"
        >
            <dxy-user-cell
                *ngIf="userId"
                [userId]="userId"
                [hideUserName]="true"
                [mini]="true"
                customIconTemplate
            ></dxy-user-cell>
        </dxy-value-list-filter>
    </div>
    <cdk-virtual-scroll-viewport *ngIf="showLogs" itemSize="60">
        <dxy-activity-log-element
            *cdkVirtualFor="let element of filteredLogEntryElements"
            [activityLogEntryElement]="element"
        ></dxy-activity-log-element>
    </cdk-virtual-scroll-viewport>
    <dxy-spinner
        *ngIf="loading$ | async"
        class="absolute-centered xl"
    ></dxy-spinner>
</div>

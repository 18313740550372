import { EntityItem } from '@datagalaxy/webclient/entity/domain';

/** NOTE: This object is now only used by the client. */
export class DataProcessingLinkColumnDto {
    public ColumnId: string;
    public DisplayName: string;
    public TechnicalName: string;
    public ColumnDataTypeName: string;
    public SizeString: string;
    public IsPrimaryKey: boolean;
    public SubTypeName: string;
    public VersionId: string;

    constructor(column: EntityItem) {
        this.ColumnId = column.ReferenceId;
        this.DisplayName = column.DisplayName;
        this.TechnicalName = column.TechnicalName;
        this.ColumnDataTypeName = column.getAttributeValue(
            'DataTypeDisplayName'
        ) as string;
        this.SizeString = column.getAttributeValue('SizeString') as string;
        this.IsPrimaryKey = column.getAttributeValue('IsPrimaryKey') as boolean;
        this.SubTypeName = column.SubTypeName;
        this.VersionId = column.VersionId;
    }
}

import { CommonModule } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    ViewChild,
} from '@angular/core';
import {
    ControlValueAccessor,
    FormsModule,
    NG_VALUE_ACCESSOR,
} from '@angular/forms';

@Component({
    selector: 'dxy-title-input',
    standalone: true,
    imports: [CommonModule, FormsModule],
    templateUrl: './title-input.component.html',
    styleUrls: ['./title-input.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: TitleInputComponent,
        },
    ],
})
export class TitleInputComponent implements ControlValueAccessor {
    @Input() readonly = false;
    @Input() placeholder = '';
    @Input() maxLength = 50;

    @Output() blurInput = new EventEmitter<void>();

    @ViewChild('input') input?: ElementRef<HTMLInputElement>;

    protected value = '';
    private onChange?: (value: string) => void;
    private onTouched?: (value: string) => void;
    protected isDisabled = false;

    constructor(private cd: ChangeDetectorRef) {}

    public writeValue(val: string): void {
        if (val !== undefined) {
            this.value = val;
            this.cd.detectChanges();
        }
    }

    public registerOnChange(fn: (value: string) => void): void {
        this.onChange = fn;
    }

    public registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    public onInput(event: Event): void {
        this.value = (event.target as HTMLInputElement).value;
        this.onChange?.(this.value);
    }

    public setDisabledState(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    public focusInput() {
        this.input?.nativeElement.focus();
    }
}

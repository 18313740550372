import { Component } from '@angular/core';
import { SpaceHomeStateService } from '../space-home-dashboard/state/space-home-state.service';
import { DxyModalService } from '../../shared/dialogs/DxyModalService';
import { SpaceHomeService } from '../space-home-dashboard/space-home.service';
import { withLoading } from '@datagalaxy/core-ui';
import { ToasterService } from '../../services/toaster.service';
import { CurrentSpaceService } from '../../services/currentSpace.service';
import { ApiServiceError, ApiServiceErrorType } from '@datagalaxy/data-access';
import { DxyBaseComponent } from '@datagalaxy/ui/core';

@Component({
    selector: 'app-space-home-navbar',
    templateUrl: 'space-home-navbar.component.html',
    styleUrls: ['./space-home-navbar.component.scss'],
})
export class SpaceHomeNavBarComponent extends DxyBaseComponent {
    protected editionEnabled$ =
        this.spaceHomeStateService.selectEditionEnabled();

    protected get canEditHomePage() {
        return this.currentSpaceService.currentSpace?.SecurityData
            ?.HasManagementAccess;
    }

    constructor(
        private spaceHomeStateService: SpaceHomeStateService,
        private spaceHomeService: SpaceHomeService,
        private modalService: DxyModalService,
        private toasterService: ToasterService,
        private currentSpaceService: CurrentSpaceService
    ) {
        super();
    }

    protected enableEdition() {
        this.spaceHomeStateService.enableEdition();
    }

    @withLoading()
    protected async save() {
        const confirm = await this.modalService.confirm({
            titleKey: 'UI.SpaceHome.SaveConfirmationModal.title',
            messageKey: 'UI.SpaceHome.SaveConfirmationModal.message',
            confirmButtonKey: 'CoreUI.Global.save',
        });
        if (!confirm) {
            return;
        }
        try {
            await this.spaceHomeService.saveHomePage(
                this.spaceHomeStateService.getSpaceHomePage()
            );
            this.toasterService.successToast({
                messageKey: 'UI.SpaceHome.SaveConfirmationModal.successMessage',
            });
            this.spaceHomeStateService.disableEdition();
        } catch (error) {
            this.manageApiError(error);
        }
    }

    protected async cancel() {
        const confirm = await this.modalService.confirm({
            titleKey: 'UI.SpaceHome.CancelConfirmationModal.title',
            messageKey: 'UI.SpaceHome.CancelConfirmationModal.message',
            confirmButtonKey: 'UI.DashboardGrid.Buttons.cancel',
            cancelButtonKey: 'UI.SpaceHome.CancelConfirmationModal.continue',
        });
        if (!confirm) {
            return;
        }
        this.spaceHomeStateService.reinitState();
        await this.spaceHomeService.refreshStateHomePage();
    }

    private manageApiError(error: ApiServiceError) {
        const errorType = error?.type as ApiServiceErrorType;
        const messageKey =
            errorType == ApiServiceErrorType.Conflicts
                ? 'UI.SpaceHome.SaveConfirmationModal.errorConflictMessage'
                : 'UI.SpaceHome.SaveConfirmationModal.errorMessage';
        this.toasterService.errorToast({
            messageKey,
        });
    }
}

import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { FieldsModule } from '../shared/fields/fields.module';
import { DxySharedDialogsModule } from '../shared/dialogs/DxySharedDialogsModule';
import { DxyTimeSeriesModalComponent } from './dxy-time-series-modal/dxy-time-series-modal.component';
import { DxyTimeSeriesEditModalComponent } from './dxy-time-series-edit-modal/dxy-time-series-edit-modal.component';
import { GridComponent } from '@datagalaxy/ui/grid';
import { DxyButtonsModule } from '@datagalaxy/ui/buttons';

@NgModule({
    imports: [
        SharedModule,
        FieldsModule,
        GridComponent,
        DxySharedDialogsModule,
        DxyButtonsModule,
    ],
    declarations: [
        DxyTimeSeriesModalComponent,
        DxyTimeSeriesEditModalComponent,
    ],
})
export class DxyTimeSeriesModule {}

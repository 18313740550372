import { Injectable } from '@angular/core';
import {
    CrudOperation,
    FunctionalLogService,
} from '@datagalaxy/webclient/monitoring/data-access';

/**
 * ## Role
 * Detect if any ad blocker is activated
 */
@Injectable({ providedIn: 'root' })
export class AdBlockerTesterService {
    private readonly adBlockerFeatureCode = 'ADBLOCKER_DETECTED';

    constructor(private functionalLogService: FunctionalLogService) {}

    /**
     * Try to detect if an ad blocker is active by injecting a fake ad into document
     *
     * Send a functional log if any ad blocker is detected
     */
    public detectAdBlocker() {
        const fakeAd: HTMLDivElement = document.createElement('div');

        try {
            fakeAd.className =
                'textads banner-ads banner_ads ad-unit ad-zone ad-space adsbox';
            fakeAd.style.height = '1px';
            fakeAd.style.top = '0px';
            fakeAd.style.position = 'fixed';
            document.body.appendChild(fakeAd);

            if (!fakeAd.offsetHeight) {
                this.sendAdBlockerDetected();
            }
        } finally {
            fakeAd?.remove();
        }
    }

    private sendAdBlockerDetected() {
        this.functionalLogService.logFunctionalAction(
            this.adBlockerFeatureCode,
            CrudOperation.R
        );
    }
}

import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ComponentRef,
    Input,
    OnChanges,
    Type,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import { EntityNodeStatusComponent } from './entity-node-status/entity-node-status.component';
import { EntityNodeTagsComponent } from './entity-node-tags/entity-node-tags.component';
import { EntityNodeUsersComponent } from './entity-node-users/entity-node-users.component';
import { EntityNodeDataQualityComponent } from './entity-node-data-quality/entity-node-data-quality.component';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';
import { ServerConstants } from '@datagalaxy/shared/server/domain';
import PropertyName = ServerConstants.PropertyName;

export interface NodeExtraDataComponent {
    entity: EntityItem;
    attributeKey: string;
}

@Component({
    selector: 'app-entity-node-extra-data',
    template: '<ng-template #container></ng-template>',
    styleUrls: ['./entity-node-extra-data.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EntityNodeExtraDataComponent
    extends DxyBaseComponent
    implements AfterViewInit, OnChanges
{
    private static getEntityNodeExtraDataComponentType(
        key: string
    ): Type<NodeExtraDataComponent> {
        switch (key) {
            case PropertyName.EntityStatus:
                return EntityNodeStatusComponent;
            case PropertyName.Domains:
                return EntityNodeTagsComponent;
            case PropertyName.DataOwners:
            case PropertyName.DataStewards:
                return EntityNodeUsersComponent;
            case PropertyName.DataQuality:
                return EntityNodeDataQualityComponent;
        }
    }

    @Input() attributeKey: string;
    @Input() entity: EntityItem;

    @ViewChild('container', { read: ViewContainerRef })
    private viewContainerRef: ViewContainerRef;

    private componentRef: ComponentRef<NodeExtraDataComponent>;

    constructor() {
        super();
    }

    ngAfterViewInit() {
        this.initInnerComponent();
    }

    ngOnChanges() {
        this.updateComponent(this.componentRef);
    }

    private initInnerComponent() {
        const attributeKey = this.attributeKey;
        if (!attributeKey || !this.entity) {
            return;
        }

        const componentType =
            EntityNodeExtraDataComponent.getEntityNodeExtraDataComponentType(
                attributeKey
            );
        if (!componentType) {
            return;
        }
        const component = (this.componentRef =
            this.viewContainerRef.createComponent(componentType));

        this.updateComponent(component);
    }

    private updateComponent(component: ComponentRef<NodeExtraDataComponent>) {
        if (!component) {
            return;
        }

        component.setInput('entity', this.entity);
        component.setInput('attributeKey', this.attributeKey);
        component.changeDetectorRef.detectChanges();
    }
}

import { EntityDockingPaneService } from '../services/entity-docking-pane.service';
import {
    EntityFormKind,
    IEntityForm,
} from '../interfaces/entity-form.interface';
import { Component, Input, OnInit } from '@angular/core';
import {
    ComparatorItemClient,
    VersionCompareContext,
} from '../compare-entity.types';
import {
    IScreenData,
    IScreenLayoutComponent,
} from '../../screens-layout/screens-layout.types';
import { EntityService } from '../services/entity.service';
import { ScreenService } from '../../../screens/screen.service';
import { AttributeDataService } from '../../attribute/attribute-data.service';
import { EntityEventService } from '../services/entity-event.service';
import { AttributeFieldInfo } from '../../attribute/attribute.types';
import { UpdateEntityAttributeResult } from '@datagalaxy/webclient/entity/data-access';
import { ScreenCategory } from '@datagalaxy/webclient/screen/data-access';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { EntityUtils } from '@datagalaxy/webclient/entity/utils';
import { SpaceIdentifier } from '@datagalaxy/webclient/workspace/utils';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';
import { AttributeMetaInfo } from '@datagalaxy/webclient/attribute/domain';
import { ServerConstants } from '@datagalaxy/shared/server/domain';
import PropertyName = ServerConstants.PropertyName;

@Component({
    selector: 'dxy-compare-entity-form',
    templateUrl: 'dxy-compare-entity-form.component.html',
    styleUrls: ['dxy-compare-entity-form.component.scss'],
})
export class DxyCompareEntityFormComponent
    extends DxyBaseComponent
    implements IScreenLayoutComponent, OnInit
{
    @Input() screenData: IScreenData;
    @Input() screenCategory: ScreenCategory;
    @Input() comparatorItems: Map<string, ComparatorItemClient>;
    @Input() versionCompareContext: VersionCompareContext;

    public itemInfos: Array<{
        itemClient: ComparatorItemClient;
        fieldInfo: AttributeFieldInfo;
    }>;

    private entityData: EntityItem;
    private sourceValue: string;

    constructor(
        private entityService: EntityService,
        private attributeDataService: AttributeDataService,
        private entityEventService: EntityEventService,
        private screenService: ScreenService,
        private entityDockingPaneService: EntityDockingPaneService
    ) {
        super();
    }

    ngOnInit() {
        this.entityData = this.screenData.entityData;

        const comparatorItemsValues = Array.from(this.comparatorItems.values());
        const entityForm = this.buildEntityForm(comparatorItemsValues);
        this.itemInfos = comparatorItemsValues.map((itemClient) => ({
            itemClient,
            fieldInfo: new AttributeFieldInfo(
                itemClient.attributeMeta,
                entityForm
            ),
        }));

        super.registerSubscription(
            this.entityEventService.subscribeEntityUpdate(
                this.entityData.ServerType,
                (entity) => this.onEntityUpdate(entity)
            )
        );
    }

    public getFormName() {
        return this.screenService.getScreenCategoryNameKey(this.screenCategory);
    }

    private onEntityUpdate(entity: EntityItem) {
        if (entity.ReferenceId !== this.entityData.ReferenceId) {
            return;
        }
        EntityUtils.mergeEntity(this.entityData, entity);
    }

    private buildEntityForm(
        comparatorItemsValues: ComparatorItemClient[]
    ): IEntityForm<string> {
        return {
            isEditEnabled: this.entityData.SecurityData?.HasWriteAccess,
            isLabelDisplayed: true,
            isFieldByFieldValidationNeeded: true,
            kind: EntityFormKind.compare,

            comparatorItemsValues,
            versionCompareContext: this.versionCompareContext,

            getEntityData: () => this.entityData,
            getEntityDataList: () => (this.entityData ? [this.entityData] : []),
            getSpaceIdr: () =>
                SpaceIdentifier.fromEntity(this.entityData, true),

            isAttributeEditEnabled: (attributeMeta: AttributeMetaInfo) => {
                if (!this.entityData.SecurityData?.HasWriteAccess) {
                    return false;
                }
                const sd = this.entityData?.SecurityData;
                return attributeMeta.AttributeKey == PropertyName.EntityStatus
                    ? !!sd?.HasEntityStatusWriteAccess
                    : AttributeDataService.isOfficialRoleAttribute(
                          attributeMeta
                      )
                    ? !!sd?.HasOfficialRoleAttributesWriteAccess
                    : !!sd?.HasWriteAccess;
            },

            loadReferenceOptions: async (attributeMeta: AttributeMetaInfo) =>
                await this.attributeDataService.loadReferenceOptionsEntity(
                    attributeMeta,
                    this.entityData
                ),

            setAttributeValue: async (
                attributeKey: string,
                newValue: string
            ) => {
                this.comparatorItems.get(attributeKey).sourceValue = newValue;
                this.sourceValue = newValue;
            },
            getAttributeValueForVersionCompare: (
                attributeKey: string,
                role?: string,
                wantsObjectData?: boolean
            ) => {
                const objectvalue = this.comparatorItems.get(attributeKey);
                if (role == 'Source') {
                    if (
                        wantsObjectData &&
                        objectvalue.sourceObjectValues != undefined
                    ) {
                        return objectvalue.sourceObjectValues;
                    } else {
                        this.sourceValue = objectvalue.sourceValue;
                        return this.sourceValue;
                    }
                } else if (role == 'Target') {
                    if (
                        wantsObjectData &&
                        objectvalue.targetObjectValues != undefined
                    ) {
                        return objectvalue.targetObjectValues;
                    } else {
                        return objectvalue.targetValue;
                    }
                }
            },
            onChangeAttributeValue: async (attributeKey: string) => {
                const objectvalue = this.comparatorItems.get(attributeKey);
                const valueToUse = objectvalue.sourceValue;
                return (await this.entityService.updateEntity(
                    this.entityData,
                    attributeKey,
                    valueToUse,
                    {
                        includeQuality:
                            this.entityDockingPaneService.isInsightsPaneVisible(),
                    }
                )) as UpdateEntityAttributeResult;
            },
            restorePreviousValue: (attributeMeta: AttributeMetaInfo) => {
                const sourceItem = this.comparatorItems.get(
                    attributeMeta.AttributeKey
                );
                if (sourceItem.targetObjectValues) {
                    sourceItem.sourceObjectValues =
                        sourceItem.targetObjectValues;
                } else {
                    sourceItem.sourceValue = sourceItem.newValue;
                }
            },
            undo: (attributeMeta: AttributeMetaInfo) => {
                const sourceItem = this.comparatorItems.get(
                    attributeMeta.AttributeKey
                );
                if (sourceItem.sourceObjectValues) {
                    sourceItem.sourceObjectValues = sourceItem.undoObjectValues;
                }
            },
        };
    }
}

import { NgModule } from '@angular/core';
import { DxyConnectorSchedulerModalComponent } from './dxy-connector-scheduler-modal/dxy-connector-scheduler-modal.component';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { DxyTargetSelectionComponent } from './dxy-target-selection/dxy-target-selection.component';
import { DxySavedConnectionsComponent } from './dxy-saved-connections/dxy-saved-connections.component';
import { ConnectionImportExecutionGridCellComponent } from './connection-import-execution-grid-cell/connection-import-execution-grid-cell.component';
import { DxyConnectionFormFieldListComponent } from './connection-form/dxy-connection-form-field-list/dxy-connection-form-field-list.component';
import { DxyConnectionFormTargetComponent } from './connection-form/dxy-connection-form-target/dxy-connection-form-target.component';
import { DxyConnectionFormSettingsComponent } from './connection-form/dxy-connection-form-settings/dxy-connection-form-settings.component';
import { DxyOmnigridModule } from '@datagalaxy/core-ui/omnigrid';
import { SharedModule } from '../shared/shared.module';
import { DxySharedDialogsModule } from '../shared/dialogs/DxySharedDialogsModule';
import { DxySharedUiModule } from '../shared/shared-ui/DxySharedUiModule';
import { FieldsModule } from '../shared/fields/fields.module';
import { DxySpaceSelectorModule } from '../space-selector/DxySpaceSelectorModule';
import { DxyNavigationModule } from '../navigation/DxyNavigationModule';
import { DxyConnectorImportHistoryModalComponent } from './dxy-connector-import-history-modal/dxy-connector-import-history-modal.component';
import { DxyConnectionFormEntitySelectionComponent } from './connection-form/dxy-connection-form-entity-selection/dxy-connection-form-entity-selection.component';
import { CheckboxComponent } from '@datagalaxy/ui/forms';
import { GridComponent } from '@datagalaxy/ui/grid';
import { DxyButtonsModule } from '@datagalaxy/ui/buttons';

@NgModule({
    imports: [
        MatRadioModule,
        SharedModule,
        DxySharedDialogsModule,
        DxySharedUiModule, // for omni-grid used in DxyMaskGridComponent
        FieldsModule, // for dxy-field-* used in DxyOnlineConnectionFormComponent
        DxySpaceSelectorModule,
        DxyNavigationModule,
        DxyOmnigridModule,
        CheckboxComponent,
        GridComponent,
        DxyButtonsModule,
    ],
    declarations: [
        DxySavedConnectionsComponent,
        DxyTargetSelectionComponent,
        DxyConnectorImportHistoryModalComponent,
        DxyConnectorSchedulerModalComponent,
        DxyConnectionFormFieldListComponent,
        ConnectionImportExecutionGridCellComponent,
        DxyConnectionFormTargetComponent,
        DxyConnectionFormSettingsComponent,
        DxyConnectionFormEntitySelectionComponent,
    ],
    exports: [
        DxySavedConnectionsComponent,
        DxyTargetSelectionComponent,
        DxyConnectionFormTargetComponent,
        DxyConnectionFormEntitySelectionComponent,
        DxyConnectionFormFieldListComponent, // for test-import
        DxyConnectionFormSettingsComponent, // for dxy-import-wizard and test-import
        DxyConnectorSchedulerModalComponent,
    ],
})
export class DxyConnectorModule {}

import { DashboardSection, WidgetInstance } from '../../../domain';
import { DashboardState } from '../dashboard-state';
import { sectionReducer } from './section-reducer';

export function insertWidgetInstanceReducer(
    state: DashboardState,
    section: DashboardSection,
    wi: WidgetInstance
) {
    return sectionReducer(state, section, {
        widgetInstances: [wi, ...section.widgetInstances],
    });
}

import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ComponentRef,
    EventEmitter,
    Input,
    Output,
    Type,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import { AttributeFilterModel } from './attributeFilterModel';
import { BaseAttributeFilter } from '../base-attribute-filter.component';
import { AttributeBooleanFilterModel } from '../attribute-boolean-filter/AttributeBooleanFilterModel';
import { AttributeBooleanFilterComponent } from '../attribute-boolean-filter/attribute-boolean-filter.component';
import { AttributeTextFilterModel } from '../attribute-text-filter/AttributeTextFilterModel';
import { AttributeTextFilterComponent } from '../attribute-text-filter/attribute-text-filter.component';
import { AttributeDateFilterModel } from '../attribute-date-filter/AttributeDateFilterModel';
import { AttributeDateFilterComponent } from '../attribute-date-filter/attribute-date-filter.component';
import { AttributeNumberFilterModel } from '../attribute-number-filter/AttributeNumberFilterModel';
import { AttributeNumberFilterComponent } from '../attribute-number-filter/attribute-number-filter.component';
import { AttributeValueListFilterModel } from '../attribute-value-list-filter/AttributeValueListFilterModel';
import { AttributeValueListFilterComponent } from '../attribute-value-list-filter/attribute-value-list-filter.component';
import { AttributeReferenceFilterModel } from '../attribute-value-list-filter/AttributeReferenceFilterModel';
import { AttributeDataService } from '../../attribute-data.service';
import { AttributeEntityFilterComponent } from '../attribute-entity-filter/attribute-entity-filter.component';
import {
    AttributeFilterAction,
    IAttributeFilterSubmitEvent,
} from '../attribute-filter.types';
import { DxyBaseComponent } from '@datagalaxy/ui/core';

/**
 * ## Role
 * Display an attribute filter
 * Depending on the attribute type, a different attribute filter will
 * be displayed
 */
@Component({
    selector: 'app-attribute-filter',
    template: '<ng-container #container></ng-container>',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttributeFilterComponent
    extends DxyBaseComponent
    implements AfterViewInit
{
    private static getAttributeFilterComponentType(
        afm: AttributeFilterModel
    ): Type<BaseAttributeFilter<AttributeFilterModel>> {
        const isBooleanAttribute = afm instanceof AttributeBooleanFilterModel;
        const isTextAttribute = afm instanceof AttributeTextFilterModel;
        const isDateAttribute = afm instanceof AttributeDateFilterModel;
        const isNumberAttribute = afm instanceof AttributeNumberFilterModel;
        const isValueListAttribute =
            afm instanceof AttributeValueListFilterModel ||
            afm instanceof AttributeReferenceFilterModel;
        const isEntityAttribute = AttributeDataService.isEntityRefAttribute(
            afm.attributeType
        );

        if (isEntityAttribute) {
            return AttributeEntityFilterComponent;
        } else if (isBooleanAttribute) {
            return AttributeBooleanFilterComponent;
        } else if (isTextAttribute) {
            return AttributeTextFilterComponent;
        } else if (isDateAttribute) {
            return AttributeDateFilterComponent;
        } else if (isNumberAttribute) {
            return AttributeNumberFilterComponent;
        } else if (isValueListAttribute) {
            return AttributeValueListFilterComponent;
        }
    }

    @Input() filterItemData: AttributeFilterModel;
    @Input() hasRemove: boolean;
    @Input() removeDisabled: boolean;
    @Input() removeDisabledTooltipKey: string;
    @Input() isReadOnly: boolean;
    @Input() isMini: boolean;
    @Input() isQuickFilter: boolean;

    @Output() onAction = new EventEmitter<AttributeFilterAction>();
    @Output() onSubmitFilter = new EventEmitter<IAttributeFilterSubmitEvent>();

    @ViewChild('container', { read: ViewContainerRef })
    private viewContainerRef: ViewContainerRef;

    private component: ComponentRef<BaseAttributeFilter<AttributeFilterModel>>;

    constructor() {
        super();
    }

    ngAfterViewInit() {
        this.initInnerComponent();
    }

    private initInnerComponent() {
        const afm = this.filterItemData;
        if (!afm) {
            return;
        }

        const componentType =
            AttributeFilterComponent.getAttributeFilterComponentType(afm);
        if (!componentType) {
            return;
        }
        const component = this.viewContainerRef.createComponent(componentType);

        component.instance.filterItemData = afm;
        component.instance.hasRemove = this.hasRemove;
        component.instance.removeDisabled = this.removeDisabled;
        component.instance.removeDisabledTooltipKey =
            this.removeDisabledTooltipKey;
        component.instance.isReadOnly = this.isReadOnly;
        component.instance.isMini = this.isMini;
        component.instance.isQuickFilter = this.isQuickFilter;

        /** Needs to be called for material child components to prevents NG0100 error */
        component.changeDetectorRef.detectChanges();

        super.subscribe(component.instance.onAction, (event) =>
            this.onAction.emit(event)
        );

        super.subscribe(component.instance.onSubmitFilter, (event) =>
            this.onSubmitFilter.emit(event)
        );

        this.component = component;
    }
}

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import {
    ISpaceVersionSelectedEvent,
    ISpaceVersionSelector,
} from '../../space/space-version-selector.types';
import {
    INavSpaceSelectedEvent,
    IProjectVersionSelectedEvent,
} from '../../space/space.types';
import { SpaceVersionSelectorCore } from '../../space/spaceVersionSelectorCore';
import { AppSpaceService } from '../../services/AppSpace.service';
import { SecurityService } from '../../services/security.service';
import { ISpaceSelectorTrackerIds } from '../space-selector.types';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { CoreUtil } from '@datagalaxy/core-util';
import { ISpaceIdentifier } from '@datagalaxy/webclient/workspace/domain';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'dxy-space-version-selector',
    templateUrl: 'dxy-space-version-selector.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DxySpaceVersionSelectorComponent
    extends DxyBaseComponent
    implements ISpaceVersionSelector, OnInit, OnChanges
{
    //#region ISpaceVersionSelector
    //#region ISpaceVersionSelectorInputs
    @Input() spaceIdr: ISpaceIdentifier;
    @Input() projectsOnly: boolean;
    @Input() allowAll: boolean;
    @Input() defaultAll: boolean;
    @Input() versionLabelAll: string;
    @Input() hasLabel: boolean;
    @Input() allowLastModuleSpace: boolean;
    @Input() isSmallCaretBtn: boolean;
    @Input() enableFavorite: boolean;
    @Input() showSpaceDropdown: boolean;
    @Input() showVersionDropdown: boolean;
    @Input() useCurrentSpace: boolean;
    @Input() isSpaceSelectable: boolean;
    @Input() isVersionSelectable: boolean;
    @Input() showAllSpaceRedirection: boolean;
    @Input() showSelectedSpaceIcon: boolean;
    @Input() hideSelectedSpaceLabel: boolean;
    @Input() onlyImportable: boolean;
    @Input() hideOfficialVersion: boolean;
    @Input() onlyImportableCatalog: boolean;
    @Input() openOnSpaceOrVersionClick: boolean;
    @Input() trackerIds: ISpaceSelectorTrackerIds;
    //#endregion - ISpaceVersionSelectorInputs
    @Output() readonly onSpaceVersionSelected =
        new EventEmitter<ISpaceVersionSelectedEvent>();
    @Output() readonly onSpaceNameClick = new EventEmitter<void>();
    @Output() readonly onVersionNameClick = new EventEmitter<void>();
    //#endregion - ISpaceVersionSelector

    /** Emitted when a menu is opened or closed. The argument is true on open. */
    @Output() readonly onMenuOpenClose = new EventEmitter<boolean>();

    public get projectIdr() {
        return this.core.projectIdr;
    }
    public get spaceId() {
        return this.core.spaceId;
    }
    public get isSpaceVisible() {
        return this.core.isSpaceVisible;
    }
    public get isAll() {
        return this.core.isAll;
    }
    public get showSeparator() {
        return this.core.showSeparator;
    }
    public get showVersion() {
        return this.core.showVersion;
    }
    public get showVersionLabel() {
        return this.core.showVersionLabel;
    }
    public get showOfficialVersion() {
        return this.core.showOfficialVersion;
    }
    public get showOfficialVersionLabel() {
        return this.core.showOfficialVersionLabel;
    }
    public get isProjectVersioned() {
        return this.core.isProjectVersioned;
    }

    private core: SpaceVersionSelectorCore;

    constructor(
        changeDetector: ChangeDetectorRef,
        appSpaceService: AppSpaceService,
        securityService: SecurityService
    ) {
        super();
        this.core = new SpaceVersionSelectorCore(
            appSpaceService,
            securityService,
            (event) => this.onSpaceVersionSelected.emit(event),
            () => this.onSpaceNameClick.emit(),
            () => this.onVersionNameClick.emit(),
            () => changeDetector.detectChanges(),
            this.debug,
            (...args) => this.log(...args),
            (...args) => CoreUtil.warn(...args)
        );
    }

    ngOnChanges(changes: SimpleChanges) {
        super.onAnyChanges(changes, this, () => this.core.onInputChange(this));
        super.onChange(changes, 'spaceIdr', (spaceIdr: ISpaceIdentifier) =>
            this.core.onChangeSpaceIdr(spaceIdr, this)
        );
    }

    ngOnInit() {
        this.core.init(this).then();
    }

    public onSpaceSelected(event: INavSpaceSelectedEvent) {
        this.core.onSpaceSelected(event);
    }
    public onVersionSelected(event: IProjectVersionSelectedEvent) {
        this.core.onVersionSelected(event);
    }
    public onSpaceNameClickInternal() {
        this.core.onSpaceNameClickInternal();
    }
    public onVersionNameClickInternal() {
        this.core.onVersionNameClickInternal();
    }
    public onMenuOpenCloseInternal(isOpen: boolean) {
        this.onMenuOpenClose.emit(isOpen);
    }
}

import { Injectable } from '@angular/core';
import { BaseService } from '@datagalaxy/core-ui';
import {
    SpaceHomePageApiService,
    SpaceHomePageDto,
} from '@datagalaxy/webclient/workspace/data-access';
import { ImageCategory } from '@datagalaxy/webclient/client/data-access';
import { ClientService } from '../../client/client.service';
import { CurrentSpaceService } from '../../services/currentSpace.service';
import { getContextId } from '@datagalaxy/webclient/utils';
import { SpaceHomeStateService } from './state/space-home-state.service';
import { AppConfigService } from '@datagalaxy/webclient/config';

@Injectable({ providedIn: 'root' })
export class SpaceHomeService extends BaseService {
    private get spaceGuid() {
        return getContextId(this.currentSpaceService.currentSpace?.ReferenceId);
    }

    constructor(
        private currentSpaceService: CurrentSpaceService,
        private clientService: ClientService,
        private spaceHomePageApiService: SpaceHomePageApiService,
        private spaceHomeStateService: SpaceHomeStateService,
        private appConfigService: AppConfigService
    ) {
        super();
    }

    public async getDefaultHomePage(): Promise<SpaceHomePageDto | undefined> {
        if (!this.spaceGuid) {
            return undefined;
        }
        const result = await this.spaceHomePageApiService.getSpaceHomePage(
            this.spaceGuid
        );
        return result.HomePage ?? ({} as SpaceHomePageDto);
    }

    public async createHomePage(
        spaceHomePage: SpaceHomePageDto
    ): Promise<void> {
        spaceHomePage.SpaceUid = this.spaceGuid;
        spaceHomePage.IsDefault = true;
        await this.spaceHomePageApiService.createSpaceHomePage(spaceHomePage);
        await this.refreshStateHomePage();
    }

    public async saveHomePage(spaceHomePage: SpaceHomePageDto): Promise<void> {
        const homePageToSave: SpaceHomePageDto = {
            ...spaceHomePage,
            SpaceHomePageImageDto: spaceHomePage.SpaceHomePageImageDto ?? {},
        };
        if (homePageToSave.SpaceHomePageUid) {
            await this.updateHomePage(homePageToSave);
        } else {
            await this.createHomePage(homePageToSave);
        }
    }

    public async updateHomePage(
        spaceHomePage: SpaceHomePageDto
    ): Promise<void> {
        await this.spaceHomePageApiService.updateSpaceHomePage(spaceHomePage);
        await this.refreshStateHomePage();
    }

    public getImageUrl(homePage?: SpaceHomePageDto): string {
        const pendingFileContent = homePage?.SpaceHomePageImageDto?.FileContent;
        if (pendingFileContent) {
            return pendingFileContent;
        }
        return homePage?.ImageHash
            ? this.clientService.getImageUrl(homePage.ImageHash)
            : '/images/global-search-header-2.svg?k=' +
                  encodeURIComponent(this.appConfigService.BUILD_RANDOM_KEY);
    }

    public getDefaultStaticImageUrl(
        imageCategory = ImageCategory.SpaceHome
    ): string {
        const imageFileName = (() => {
            switch (imageCategory) {
                case ImageCategory.SpaceHome:
                    return 'global-search-header-2.svg';
            }
        })();
        return imageFileName
            ? `/images/${imageFileName}?k=${encodeURIComponent(
                  this.appConfigService.BUILD_RANDOM_KEY
              )}`
            : '';
    }

    public async refreshStateHomePage() {
        const result = await this.spaceHomePageApiService.getSpaceHomePage(
            this.spaceGuid
        );
        this.spaceHomeStateService.init(result.HomePage);
    }
}

import { Directive, ElementRef, Input, NgZone, OnInit } from '@angular/core';
import {
    IDgNavNewTabParams,
    setupRightClickOpenInNewTab,
} from '../navNewTab.util';
import { BreadcrumbService } from '../services/breadcrumb.service';
import { DomUtil } from '@datagalaxy/core-util';
import { EntityUiService } from '../../shared/entity/services/entity-ui.service';
import { IHierarchicalData } from '@datagalaxy/dg-object-model';
import { EntityPanelTool } from '../../entity-panels/entity-panels.types';
import {
    NavigateTo,
    NavigationService,
} from '../../services/navigation.service';
import { EntityDockingPaneService } from '../../shared/entity/services/entity-docking-pane.service';
import { FeatureFlagService } from '@datagalaxy/webclient/feature-flag';
import { DxyBaseDirective } from '@datagalaxy/ui/core';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[dxyNavDataLink]',
})
export class DxyNavDataLinkDirective
    extends DxyBaseDirective
    implements OnInit, IDgNavNewTabParams
{
    @Input() hierarchicalData?: IHierarchicalData;
    @Input() navToLineage?: boolean;
    @Input() navToTab?: string;
    @Input() dockingPanelTool?: EntityPanelTool;
    @Input() navTo?: NavigateTo;
    @Input() wrapContent?: boolean;
    @Input() isFromBreadcrumb?: boolean;
    @Input() isFromHierarchical?: boolean;
    @Input() withEntityFullPage?: boolean;
    @Input() openPreview?: boolean;

    constructor(
        private ngZone: NgZone,
        private elementRef: ElementRef<HTMLElement>,
        private breadcrumbService: BreadcrumbService,
        private navigationService: NavigationService,
        private entityDockingPaneService: EntityDockingPaneService,
        private entityUiService: EntityUiService,
        private featureFlagService: FeatureFlagService
    ) {
        super();
    }

    ngOnInit() {
        this.initSref();
        // better to stay in angular zone, here
        DomUtil.addListener(
            this.elementRef.nativeElement,
            'click',
            (e) => this.onClick(e),
            this.ngZone,
            false
        );
    }

    private async initSref() {
        if (this.featureFlagService.isFeatureEnabled('ENABLE_AUTH_V2')) {
            const sref = this.navigationService.getNavTo(
                this.navToLineage,
                this.navToTab
            );
            const url =
                await this.navigationService.getUrlToGoToWithHierarchicalData(
                    this.hierarchicalData,
                    sref,
                    true
                );
            this.elementRef.nativeElement.setAttribute(
                'href',
                window.location.origin + url
            );
            this.elementRef.nativeElement.classList.add('navto-href');
        } else {
            setupRightClickOpenInNewTab(
                this.elementRef,
                this,
                this.navigationService,
                this.ngZone
            );
        }
    }

    private async onClick(e: Event) {
        e.stopPropagation();
        e.preventDefault();
        const navTo = this.breadcrumbService.getNavTo(
            this.navToLineage,
            this.navToTab
        );

        if (this.dockingPanelTool) {
            this.entityDockingPaneService.forceActiveTool(
                this.dockingPanelTool
            );
            this.entityDockingPaneService.togglePanel();
        }

        if (this.openPreview) {
            await this.entityUiService.openPreviewOrDetailsOrDefault({
                HddData: this.hierarchicalData,
            });
        } else {
            await this.breadcrumbService.navigateByHierarchicalData(
                this.hierarchicalData,
                navTo,
                this.isFromBreadcrumb,
                this.isFromHierarchical,
                this.withEntityFullPage
            );
        }
    }
}

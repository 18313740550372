import {
    AfterViewInit,
    Component,
    Input,
    OnChanges,
    OnDestroy,
    SimpleChange,
    SimpleChanges,
} from '@angular/core';
import { EntityPanelTool } from '../entity-panels.types';
import { IEntityPanelData } from '../../shared/entity/interfaces/entity-panel.interface';
import { EntityDockingPaneService } from '../../shared/entity/services/entity-docking-pane.service';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';
import { DgModule } from '@datagalaxy/shared/dg-module/domain';

/**
 * ## Role
 * Entity dashboard docking pane
 *
 * ## Notes
 * IMPORTANT: This component is controlled by EntityDockingPaneService only.
 * To control it from another component, use EntityDockingPaneService.setupPanel method
 */
@Component({
    selector: 'app-entity-docking-pane',
    templateUrl: 'entity-docking-pane.component.html',
    styleUrls: ['entity-docking-pane.component.scss'],
})
export class EntityDockingPaneComponent
    extends DxyBaseComponent
    implements OnDestroy, OnChanges, AfterViewInit
{
    @Input() preferredTool: string;
    @Input() entityData: EntityItem;
    @Input() dgModule: DgModule;

    public readOnly: boolean;

    public activeState$ = this.entityDockingPaneService.activeState$;
    public activeTool$ = this.entityDockingPaneService.activeToolChanged$;

    public panelBodyData: IEntityPanelData;
    public get isVisible() {
        return (
            !this.entityDockingPaneService.isPanelHidden && !!this.entityData
        );
    }

    constructor(public entityDockingPaneService: EntityDockingPaneService) {
        super();
    }

    ngAfterViewInit(): void {
        this.entityDockingPaneService.onDockingPaneInit();
    }

    ngOnChanges(changes: SimpleChanges): void {
        super.onChange(
            changes,
            'entityData',
            () => {
                this.updatePanelBodyData(changes.entityData);
            },
            true
        );
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }

    public toggleCollapse() {
        this.entityDockingPaneService.togglePanel();
    }

    public onToolClick(tool: EntityPanelTool) {
        this.entityDockingPaneService.setActiveTool(tool);
        if (this.entityDockingPaneService.isPanelCollapsed) {
            this.toggleCollapse();
        }
    }

    private async updatePanelBodyData(change: SimpleChange) {
        const previousValue: EntityItem = change.previousValue;
        const entityData = this.entityData;
        if (entityData == previousValue) {
            return;
        }
        const { orderedTools, activeTool, entityMoreData } =
            await this.loadToolsData();
        const prevActiveTool = this.entityDockingPaneService.activeTool;
        const panelBodyData: IEntityPanelData = {
            entityData,
            entityMoreData,
            orderedTools,
            activeTool,
            readOnly: this.readOnly,
            //DR 2687 - execute keyboard shortcuts from the docking panel, no matter if the preview panel is visible or not
            isKeyboardEventEnabled: true,
            showToggleButton: true,
            isCollapsedOnInit: this.entityDockingPaneService.isPanelCollapsed,
        };
        if (this.debug) {
            if (activeTool != prevActiveTool) {
                this.log(
                    'invalid current tool',
                    EntityPanelTool[prevActiveTool],
                    '->',
                    EntityPanelTool[activeTool]
                );
            }
            this.log('setActive-panelBodyData', {
                sameEntityPanelData:
                    EntityDockingPaneService.sameEntityPanelData(
                        panelBodyData,
                        this.panelBodyData
                    ),
                panelBodyData,
                prevPanelBodyData: this.panelBodyData,
                orderedTools: orderedTools.map((t) => EntityPanelTool[t]),
                activeTool: EntityPanelTool[activeTool],
            });
        }
        this.panelBodyData = panelBodyData;
        this.entityDockingPaneService.showPanel();
    }

    private async loadToolsData() {
        /** all icon buttons possible, in displayed order. May be filtered eventually */
        const orderedPossibleTools = [
            EntityPanelTool.Details,
            EntityPanelTool.Insights,
            EntityPanelTool.DataQuality,
            EntityPanelTool.EntityTree,
            EntityPanelTool.LinkedObject,
            EntityPanelTool.Tasks,
            EntityPanelTool.Commentaries,
            EntityPanelTool.Suggestion,
            EntityPanelTool.VersionComparison,
            EntityPanelTool.Campaign,
            EntityPanelTool.ActivityLog,
        ];

        /** first valid will be taken if preferredTool is not */
        const orderedDefaultTools = [
            EntityPanelTool.Insights,
            EntityPanelTool.EntityTree,
            EntityPanelTool.LinkedObject,
        ];
        const preferredTool = EntityPanelTool[this.preferredTool];

        return await this.entityDockingPaneService.getToolsData(
            this.entityData,
            this.readOnly,
            orderedPossibleTools,
            preferredTool,
            orderedDefaultTools
        );
    }
}

<header>
    <span class="header-title">
        <dxy-widget-title-editor
            [editionEnabled]="editionEnabled"
            [customTitle]="widgetConfiguration?.customTitle"
            defaultTitleKey="UI.SpaceHome.Widgets.SpaceMembers.Title"
            (customTitleChange)="onCustomTitleChange($event)"
            dxyEllipsisTooltip
        ></dxy-widget-title-editor>
        <span *ngIf="!editionEnabled" class="dg5-badge big">{{
            spaceMembersCount
        }}</span>
    </span>
    <button
        mat-button
        [disabled]="editionEnabled"
        (click)="onOpenSpaceMembersPreview()"
    >
        <span translate>UI.Global.filter.seeAll</span>
    </button>
</header>

<section class="body" [class.disabled]="editionEnabled">
    <ng-container *ngIf="showRoles">
        <div
            *ngFor="let group of spaceMemberGroups"
            class="role-item"
            (click)="onSpaceMemberGroupClick(group)"
        >
            <span class="role-item-name" [translate]="group.roleKey"></span>
            <app-user-collection-cell
                [userIds]="group.userCollectionIds"
                [maxItems]="maxUserItems"
                [hideUserName]="getHideCollectionCellUserName(group)"
                [alignRight]="true"
                [disableMoreCountTooltip]="true"
            ></app-user-collection-cell>
        </div>
    </ng-container>

    <dxy-spinner
        *ngIf="loading$ | async"
        class="absolute-centered"
    ></dxy-spinner>
</section>

import { FocusMonitor } from '@angular/cdk/a11y';
import {
    Component,
    ElementRef,
    Input,
    NgZone,
    Optional,
    Self,
    ViewChild,
} from '@angular/core';
import { FormGroupDirective, NgControl, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatLegacyFormFieldControl as MatFormFieldControl } from '@angular/material/legacy-form-field';
import { MatLegacySelect as MatSelect } from '@angular/material/legacy-select';
import { DxyBaseMatFormFieldControl } from '../../../base';
import {
    IDataTypePrecisionSize,
    IDataTypeDisplayAdapter,
} from './IDataTypePrecisionSize';

/** Group of 3 controls:
 * - datatype selector,
 * - size number input,
 * - precision number input.
 *
 * For to display or set the type, precision and size of a column in a database table */
@Component({
    selector: 'dxy-data-type-size-field-control',
    templateUrl: './data-type-size-field-control.component.html',
    styleUrls: ['./data-type-size-field-control.component.scss'],
    providers: [
        {
            provide: MatFormFieldControl,
            useExisting: DxyDataTypeSizeFieldControlComponent,
        },
    ],
})
export class DxyDataTypeSizeFieldControlComponent<
    TDataType
> extends DxyBaseMatFormFieldControl<IDataTypePrecisionSize<TDataType>> {
    @Input() availableDataTypes: TDataType[];
    @Input() adapter: IDataTypeDisplayAdapter<TDataType>;
    @Input() readonly: boolean;
    @Input() noLabels: boolean;
    @Input() noTypeLabel: boolean;

    public get showTypeLabel() {
        return !this.noLabels && !this.noTypeLabel;
    }
    public get showPrecision() {
        return this.value && this.hasPrecision;
    }
    public get showSize() {
        return this.value && this.hasSize;
    }
    private get hasSize() {
        return (
            this.value?.dataType && this.adapter.hasSize?.(this.value.dataType)
        );
    }
    private get hasPrecision() {
        return (
            this.value?.dataType &&
            this.adapter.hasPrecision?.(this.value.dataType)
        );
    }

    @ViewChild('matSelect') private matSelect: MatSelect;

    constructor(
        @Optional() @Self() ngControl: NgControl,
        @Optional() parentForm: NgForm,
        @Optional() parentFormGroup: FormGroupDirective,
        defaultErrorStateMatcher: ErrorStateMatcher,
        focusMonitor: FocusMonitor,
        elementRef: ElementRef<HTMLElement>,
        ngZone: NgZone
    ) {
        super(
            'dxy-data-type-size-field-control',
            ngControl,
            parentForm,
            parentFormGroup,
            defaultErrorStateMatcher,
            focusMonitor,
            elementRef,
            ngZone
        );
    }

    //#region API
    public focus() {
        this.matSelect.focus();
    }
    public blur() {
        (this.matSelect._elementRef.nativeElement as HTMLElement)?.blur();
    }
    //#endreion

    public getOptionText(o: TDataType) {
        return o ? this.adapter?.getText(o) ?? '' : '';
    }

    public onDataTypeChange() {
        this.log(
            'onDataTypeChange',
            this.value,
            this.hasSize,
            this.hasPrecision,
            this.getOptionText(this.value?.dataType)
        );
        if (!this.value) {
            return;
        }
        if (!this.hasSize) {
            this.value.size = undefined;
        }
        if (!this.hasPrecision) {
            this.value.precision = undefined;
        }
    }
}

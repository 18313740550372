import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DxyBaseModalComponent } from '@datagalaxy/ui/dialog';
import { DiagramDataService } from '../diagram/diagram-data.service';
import {
    IDiagramConfig,
    IDiagramCreationModalInput,
} from '../diagram-configurator/diagram-configurator.types';
import { NavigationService } from '../../services/navigation.service';
import { CurrentSpaceService } from '../../services/currentSpace.service';
import { DiagramKind } from '@datagalaxy/webclient/diagram/data-access';
import { ISpaceIdentifier } from '@datagalaxy/webclient/workspace/domain';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';

/**
 * ## Role
 * Diagram creation modal
 */
@Component({
    selector: 'app-diagram-creation-modal',
    templateUrl: './diagram-creation-modal.component.html',
})
export class DiagramCreationModalComponent
    extends DxyBaseModalComponent<IDiagramCreationModalInput, EntityItem>
    implements OnInit
{
    public loading = false;
    public spaceIdr: ISpaceIdentifier;

    public get isActionEnabled() {
        return !!this.diagramConfig;
    }

    private diagramConfig: IDiagramConfig;

    constructor(
        private navigationService: NavigationService,
        private diagramDataService: DiagramDataService,
        private currentSpaceService: CurrentSpaceService,
        dialogRef: MatDialogRef<any, EntityItem>,
        @Inject(MAT_DIALOG_DATA) data: IDiagramCreationModalInput
    ) {
        super(dialogRef, data);
    }

    ngOnInit() {
        this.spaceIdr = this.currentSpaceService.getCurrentOrLastSpace();
    }

    public async onConfigChanged(diagramConfig: IDiagramConfig) {
        this.log('onConfigChanged', diagramConfig);
        this.diagramConfig = diagramConfig;
    }

    public async createDiagram() {
        if (!this.diagramConfig) {
            return;
        }
        this.loading = true;
        try {
            const { sourceIdr, displayName } = this.diagramConfig;
            const diagramName = displayName.trim();
            const diagramKind = sourceIdr
                ? DiagramKind.MonoSourceDatabase
                : DiagramKind.Free;
            const sourceIds = sourceIdr ? [sourceIdr.ReferenceId] : undefined;
            const diagram = await this.diagramDataService.createDiagram(
                diagramName,
                this.spaceIdr,
                diagramKind,
                sourceIds
            );
            if (diagram) {
                setTimeout(() => this.navigationService.goToDiagram(diagram));
            }
            this.dialogRef.close(diagram);
        } finally {
            this.loading = false;
        }
    }
}

import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    HostBinding,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { IActionOption } from '../action-list/action-list.types';
import { IListOption, ListOptionUtil } from '../../IListOption';
import { CoreUtil } from '@datagalaxy/core-util';
import { TranslateService } from '@ngx-translate/core';
import { IFunctionalEvent } from '../../IFunctionalEvent';
import { DxyBaseComponent } from '@datagalaxy/ui/core';

/**
 * ## Role
 * Action button or burger-menu in an action-list component
 */
@Component({
    selector: 'dxy-action-list-item',
    templateUrl: './action-list-item.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DxyActionListItemComponent<T>
    extends DxyBaseComponent
    implements OnInit
{
    @Input() action: IActionOption<T>;
    @Input() data?: T;
    @Input() actionsCaptureMouseDown?: boolean;

    @Output() openClose = new EventEmitter<boolean>();
    @Output() onLogFunctional = new EventEmitter<IFunctionalEvent>();

    @HostBinding('class.visible')
    public get isVisible() {
        return CoreUtil.fromFnOrValue(this.action?.alwaysVisible, this.data);
    }

    public burgerMenuOptions: IListOption<unknown, T>[];

    public get showActionButton() {
        return !this.isHidden && !this.isBurgerMenu;
    }
    public get showBurgerMenuButton() {
        return !this.isHidden && this.isBurgerMenu;
    }

    public get tooltipText() {
        return ListOptionUtil.getTooltipText(
            this.action,
            this.translate,
            false,
            this.data
        );
    }
    public get tooltipClass() {
        return ListOptionUtil.getTooltipClass(this.action);
    }
    public get text() {
        return ListOptionUtil.getText(this.action, this.translate, this.data);
    }
    public get isDisabled() {
        return ListOptionUtil.isDisabled(this.action, this.data);
    }
    public get glyphClass() {
        return ListOptionUtil.getGlyphClass(this.action, this.data);
    }
    public get dataTestId() {
        return ListOptionUtil.getDataTestId(this.action, this.data);
    }
    public get isHoverDisabled() {
        return !this.action.callback;
    }

    private get isHidden() {
        return ListOptionUtil.isHidden(this.action, this.data);
    }
    private get isBurgerMenu() {
        return this.burgerMenuOptions?.length > 0;
    }

    constructor(private translate: TranslateService) {
        super();
    }

    ngOnInit() {
        this.init();
    }

    private init() {
        this.burgerMenuOptions = this.getBurgerMenuOptions(this.action);
    }

    public onOptionClicked(event: MouseEvent) {
        ListOptionUtil.onClick(
            this.action,
            this.onLogFunctional,
            event,
            this.data
        );
        event.preventDefault();
        event.stopPropagation();
    }

    public onOptionMouseDown(event: MouseEvent) {
        if (!this.actionsCaptureMouseDown) {
            return;
        }
        event.preventDefault();
        event.stopPropagation();
    }

    private getBurgerMenuOptions(action: IActionOption) {
        return action.burgerMenuProvider
            ? action.burgerMenuProvider.getOptions(
                  this.data,
                  action.burgerMenuManagerContext
              )
            : action.options;
    }
}

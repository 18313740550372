<div *ngIf="showHeader" class="panel-content-tool">
    <div class="panel-content-toolname" translate>
        UI.EntityDockingPane.Tool.EntityTree
    </div>
    <div class="actions-container">
        <dxy-dropdown-button
            *ngIf="hasWriteAccess && !readOnly"
            type="add"
            tooltipTranslateKey="UI.EntityTree.createChildTooltip"
            [actions]="addButtonActions"
        ></dxy-dropdown-button>
    </div>
</div>

<div class="panel-content-body">
    <div *ngIf="hasData" class="box-content">
        <dxy-entity-grid
            [egOptions]="egOptions"
            [currentEntityId]="entityData.ReferenceId"
            [spaceIdr]="spaceIdr"
            [parentData]="entityData"
            [parentDataId]="entityData.ReferenceId"
            [serverType]="entityData.ServerType"
            [entityAttributes]="entityAttributes"
            logId="entity-tree"
            dtContext="Docking Pane Hierarchy"
        ></dxy-entity-grid>
    </div>
    <div *ngIf="!hasData" class="no-item-container">
        <div class="image-container">
            <img
                src="/images/placeholders/descendants.svg"
                class="dg_no-item-image"
                alt="no children"
            />
        </div>
        <div class="no-item-title" translate="UI.EntityTree.titleLbl"></div>
        <div class="no-item-text" translate="UI.EntityTree.lblNoChildren"></div>
        <button
            *ngIf="
                hasWriteAccess && !readOnly && !(addButtonActions.length > 1)
            "
            mat-flat-button
            color="primary"
            (click)="addButtonActions[0].callback()"
        >
            <i class="glyph-add"></i>
            <span translate>UI.EntityTree.addChildBtn</span>
        </button>
    </div>
</div>

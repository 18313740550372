import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ITabItem } from '@datagalaxy/core-ui';
import { StateService, UIRouterGlobals } from '@uirouter/core';
import { MultilingualStateName } from '../../multilingual.routes';

@Component({
    selector: 'dxy-multilingual-configurator',
    templateUrl: './multilingual-configurator.component.html',
    styleUrls: ['./multilingual-configurator.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultilingualConfiguratorComponent {
    protected tabItems: ITabItem[] = [
        {
            tabId: MultilingualStateName.Languages,
            tabTranslateKey: 'Multilingual.languages',
        },
        {
            tabId: MultilingualStateName.Attributes,
            tabTranslateKey: 'Multilingual.attributes',
        },
        {
            tabId: MultilingualStateName.Lexicon,
            tabTranslateKey: 'Multilingual.lexicon',
        },
    ];

    protected get activeTab() {
        return (
            this.tabItems.find(
                (tab) => this.globals.current.name === tab.tabId
            ) ?? this.tabItems[0]
        );
    }

    constructor(
        private stateService: StateService,
        private globals: UIRouterGlobals
    ) {
        if (
            !this.tabItems.some(
                (tab) => this.globals.current.name === tab.tabId
            )
        ) {
            this.stateService.go(MultilingualStateName.Languages);
        }
    }

    protected onTabChange(tab: ITabItem) {
        this.stateService.go(tab.tabId);
    }
}

<header>
    <dxy-widget-title-editor
        class="header-title"
        [editionEnabled]="editionEnabled"
        [customTitle]="widgetConfiguration?.customTitle"
        defaultTitleKey="UI.SpaceHome.Widgets.News.Title"
        (customTitleChange)="onCustomTitleChange($event)"
        dxyEllipsisTooltip
    >
    </dxy-widget-title-editor>
    <button
        *ngIf="showCreateButton"
        [disabled]="editionEnabled"
        mat-mini-fab
        [matTooltip]="'UI.Components.IntegrationAdmin.btnCreate' | translate"
        [matTooltipDisabled]="editionEnabled"
        (click)="onCreateArticleClick()"
    >
        <i class="glyph-add"></i>
    </button>
</header>
<section class="body" [class.disabled]="editionEnabled">
    <div class="news-container">
        <div
            class="news-item"
            *ngFor="let article of articles"
            (click)="onArticleClick(article)"
        >
            <div class="news-image-wrapper">
                <img
                    *ngIf="article.ImageHash"
                    class="news-image"
                    [src]="article.ImageHash | imageHashUrl"
                />
                <div class="image-placeholder" *ngIf="!article.ImageHash"></div>
            </div>
            <div class="news-content">
                <div class="news-title" dxyEllipsisTooltip>
                    {{ article.Title }}
                </div>
                <div class="news-description multiline-ellipsis-content">
                    {{ article.Description | richText }}
                </div>
            </div>
        </div>
    </div>
    <dxy-spinner
        *ngIf="loading$ | async"
        class="absolute-centered"
    ></dxy-spinner>
    <div *ngIf="showPlaceholder" class="dxy-placeholder">
        <img
            class="dxy-placeholder-img"
            src="/images/space-widgets/space-news-placeholder.svg"
        />
        <span class="dxy-placeholder-text" translate
            >UI.SpaceHome.Widgets.News.Placeholder</span
        >
    </div>
</section>

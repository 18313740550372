import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { StringUtil } from '@datagalaxy/core-util';
import { TranslateService } from '@ngx-translate/core';
import { ImportContext } from '../../../import/shared/ImportContext';
import { ConnectorService } from '../../connector.service';
import { IOnlineImportEntity } from '@datagalaxy/webclient/connectivity/data-access';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import {
    GridCellType,
    GridComponent,
    GridConfig,
    TColDef,
} from '@datagalaxy/ui/grid';

@Component({
    selector: 'dxy-connection-form-entity-selection',
    templateUrl: './dxy-connection-form-entity-selection.component.html',
    styleUrls: ['./dxy-connection-form-entity-selection.component.scss'],
})
export class DxyConnectionFormEntitySelectionComponent
    extends DxyBaseComponent
    implements AfterViewInit
{
    @Input() importContext: ImportContext;
    @ViewChild(GridComponent, { static: true })
    grid: GridComponent<IOnlineImportEntity>;

    public get isLoading() {
        return this.connectorService.connectionObjectsLoading;
    }
    public set isLoading(value: boolean) {
        this.connectorService.connectionObjectsLoading = value;
    }
    public objects: IOnlineImportEntity[];
    public selectedObjects: IOnlineImportEntity[] = [];

    protected gridConfig: GridConfig<IOnlineImportEntity> = {
        getItemId: (item) => item.name,
        multiSelect: true,
    };
    protected cols: TColDef<IOnlineImportEntity>[] = [
        {
            id: 'name',
            headerLabel: this.translate.instant(
                'Import.GenericImportWizard.ConnectionForm.ObjectsSelection.colLbl'
            ),
            type: GridCellType.text,
            getValue: (item) => item.name,
        },
    ];
    protected rows: IOnlineImportEntity[] = [];

    public searchString = '';
    public isSelectAll = true;

    public get titleText() {
        const message = this.translate.instant(
            'Import.GenericImportWizard.ConnectionForm.ObjectsSelection.title'
        );
        return `${message} ${this.connectorService.targetPlugin.title.fr}`;
    }

    constructor(
        private connectorService: ConnectorService,
        private translate: TranslateService
    ) {
        super();
    }

    ngAfterViewInit() {
        this.isLoading = true;
        this.isSelectAll =
            this.connectorService.selectedObjectNames.length === 0;
        this.connectorService.isAllObjectsSelected = this.isSelectAll;
        this.initConnectionObjects();
    }

    public async onSelectionChange(selectedRows: IOnlineImportEntity[]) {
        this.selectedObjects = selectedRows;
        this.connectorService.selectedObjectNames = this.selectedObjects.map(
            (row) => row.name
        );
    }

    public async onSearchTermChange() {
        this.rows = StringUtil.filterSearched(
            this.searchString,
            this.objects,
            (object) => object.name
        );
    }

    public onChangeSelectAll() {
        this.connectorService.isAllObjectsSelected = this.isSelectAll;
    }

    private async initConnectionObjects() {
        this.rows = this.objects =
            await this.connectorService.getCurrentConnectionFoundObjects(
                this.importContext.spaceIdr.spaceId
            );

        if (!this.isSelectAll) {
            this.selectedObjects =
                this.connectorService.selectedObjectNames.map((n) =>
                    this.objects.find((o) => o.name === n)
                );
            setTimeout(() => {
                this.grid.selection = this.selectedObjects;
            }, 100);
        }
        this.isLoading = false;
    }
}

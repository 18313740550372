import { NgModule } from '@angular/core';
import { CoreUiModule } from '../core-ui.module';
import { DxyDialogModule } from '../dialog';
import { DxyImageInputComponent } from './image-input/image-input.component';

@NgModule({
    imports: [CoreUiModule, DxyDialogModule],
    exports: [DxyImageInputComponent],
    declarations: [DxyImageInputComponent],
})
export class DxyImageInputModule {}

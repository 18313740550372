import { Selection } from 'd3-selection';
import { LineageItem } from './data/LineageItem';
import { LineageLink } from './data/LineageLink';

export enum LineageUiElementType {
    unknown,
    HEllipsis,
    BurgerIcon,
    PreviewIcon,
}

export type SD3Items = Selection<SVGGElement, LineageItem, any, any>;
export type SD3Links = Selection<SVGGElement, LineageLink, any, any>;
export type SD3Texts = Selection<SVGTextElement, LineageItem, any, any>;

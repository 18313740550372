import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { DxyExtensionModalComponent } from './dxy-extension-modal.component';
import { FieldsModule } from '../shared/fields/fields.module';

@NgModule({
    imports: [SharedModule, FieldsModule],
    declarations: [DxyExtensionModalComponent],
    exports: [DxyExtensionModalComponent],
})
export class DxyExtensionModule {}

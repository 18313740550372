import { ChangeDetectionStrategy, Component } from '@angular/core';
import { IMentionResolver } from '@datagalaxy/core-ui/rich-text';
import { RichTextMentionService } from '../../../shared/richText/mentions/rich-text-mention.service';
import { SpaceApiService } from '../../services/space-api.service';
import { TranslateService } from '@ngx-translate/core';
import { FormControl } from '@angular/forms';
import {
    CrudOperation,
    FunctionalLogService,
} from '@datagalaxy/webclient/monitoring/data-access';
import {
    BaseEditionWidgetComponent,
    IEditionWidgetComponnent,
    registerWidget,
} from '@datagalaxy/webclient/dashboard/ui';
import { CurrentSpaceService } from '../../../services/currentSpace.service';

/**
 * ## Role
 * Display a widget which show a space icon + displayName and
 * its description
 *
 * ## Features
 * - If no description, display a placholder
 * - If user is admin, he can edit the description in place
 */
@registerWidget({
    name: 'space-description-widget',
    titleKey: 'UI.SpaceHome.Widgets.SpaceDescription.previewTitle',
    previewHeaderKey: 'UI.SpaceHome.Widgets.SpaceDescription.previewHeaderText',
    previewImgUrl: '/images/space-widgets/space-widget-description.svg',
})
@Component({
    selector: 'app-space-description-widget',
    templateUrl: './space-description-widget.component.html',
    styleUrls: [
        '../space-widget.component.scss',
        './space-description-widget.component.scss',
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpaceDescriptionWidgetComponent
    extends BaseEditionWidgetComponent
    implements IEditionWidgetComponnent
{
    protected mentionResolvers: IMentionResolver[];
    protected editing = false;
    protected errorMessageKey: string;
    protected descriptionControl: FormControl<string>;
    protected get dashboardEditionEnabled() {
        return this.editionEnabled;
    }

    protected get descriptionLabelKey() {
        return this.editing
            ? 'UI.SpaceHome.Widgets.SpaceDescription.DescriptionLabel'
            : '';
    }
    protected get showPlaceholder() {
        return !this.currentSpace?.Description && !this.editing;
    }
    protected get hasEditRights() {
        return this.currentSpace?.SecurityData?.HasManagementAccess;
    }

    private get spaceFeatureCode() {
        return this.currentSpace?.isOrga
            ? 'ORGA'
            : this.currentSpace?.isProject
            ? 'PROJECT'
            : undefined;
    }
    protected get currentSpace() {
        return this.currentSpaceService.currentSpace;
    }

    constructor(
        richTextMentionService: RichTextMentionService,
        private functionalLogService: FunctionalLogService,
        private spaceApiService: SpaceApiService,
        private translate: TranslateService,
        private currentSpaceService: CurrentSpaceService
    ) {
        super();
        this.mentionResolvers =
            richTextMentionService.getDefaultMentionResolvers();
        this.descriptionControl = new FormControl<string>(
            this.currentSpace.Description
        );
    }

    public toggleEditionMode(enabled: boolean) {
        if (enabled && this.editing) {
            this.onCancelEdit();
        }
        super.toggleEditionMode(enabled);
    }

    protected onEditModeClick() {
        this.editing = true;
    }
    protected async onValidateEdit() {
        await this.updateSpace();
        this.editing = false;
    }
    protected onCancelEdit() {
        this.resetDescription();
        this.editing = false;
    }

    protected async updateSpace() {
        const spaceId = this.currentSpace?.ReferenceId;
        const description = this.descriptionControl.value;

        this.functionalLogService.logFunctionalAction(
            this.spaceFeatureCode,
            CrudOperation.U
        );

        try {
            return await this.spaceApiService.updateProject(
                spaceId,
                this.currentSpace.DisplayName,
                this.currentSpace.Trigram,
                description,
                false
            );
        } catch (e) {
            this.errorMessageKey = this.translate.instant(
                'UI.SpaceHome.Widgets.SpaceDescription.DescriptionServerError'
            );
            this.resetDescription();
        }
    }

    private resetDescription() {
        this.descriptionControl?.setValue(this.currentSpace.Description);
    }
}

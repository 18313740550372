import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    Output,
    TemplateRef,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import { IBoundingBox, IGraphicalToolbarOption } from '../graphical.types';
import { DomUtil, THTMLElement } from '@datagalaxy/core-util';
import { Rect, TDomElement } from '@datagalaxy/core-2d-util';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { IFunctionalEvent } from '../../IFunctionalEvent';
import { DxyBaseComponent } from '@datagalaxy/ui/core';

/**
 * ## Role
 * Horizontal contextual menu for one or several graphical elements
 * ## Features
 * - above/under auto-positioning
 */
@Component({
    selector: 'dxy-graphical-context-menu',
    templateUrl: 'graphical-context-menu.component.html',
})
export class DxyGraphicalContextMenuComponent
    extends DxyBaseComponent
    implements AfterViewInit
{
    @Input() menuMargin = 20;
    @Input() menuHeight = 42;
    /** Allows for css scope limitation when the toolbar is displayed in a global overlay */
    @Input() toolbarClass: string;

    @Output() readonly functional = new EventEmitter<IFunctionalEvent>();

    @ViewChild(TemplateRef) private templateRef: TemplateRef<any>;

    protected options: IGraphicalToolbarOption[];

    private portal: TemplatePortal;
    private overlayRef: OverlayRef;
    private visible = false;

    constructor(
        private cd: ChangeDetectorRef,
        private overlay: Overlay,
        private viewContainerRef: ViewContainerRef
    ) {
        super();
    }

    ngAfterViewInit() {
        this.portal = new TemplatePortal(
            this.templateRef,
            this.viewContainerRef
        );
    }

    //#region API
    public show(
        options: IGraphicalToolbarOption[],
        elements: IBoundingBox | TDomElement[],
        container: THTMLElement
    ) {
        this.doShow(options, elements, container);
    }
    public hide() {
        if (!this.visible) {
            return;
        }
        this.doHide();
    }
    //#endregion

    protected onFunctional(event: IFunctionalEvent) {
        this.functional.emit(event);
    }

    private doShow(
        options: IGraphicalToolbarOption[],
        elements: IBoundingBox | TDomElement[],
        container: THTMLElement
    ) {
        const positionStrategy = this.getPositionStrategy(elements, container);

        if (this.overlayRef) {
            this.overlayRef.updatePositionStrategy(positionStrategy);
        } else {
            this.overlayRef = this.overlay.create({ positionStrategy });
        }

        if (!this.overlayRef.hasAttached()) {
            this.overlayRef.attach(this.portal);
        }

        this.options = options;
        this.visible = true;

        this.cd.detectChanges();
    }

    private doHide() {
        this.overlayRef.detach();
        this.visible = false;
        this.options = [];
    }

    private getPositionStrategy(
        elements: IBoundingBox | TDomElement[],
        container: THTMLElement
    ) {
        const bb = Array.isArray(elements)
            ? Rect.boundingBox(elements.map((el) => el.getBoundingClientRect()))
            : elements;

        // menu is displayed above if enough space, else below
        const containerBB =
            DomUtil.getElement(container)?.getBoundingClientRect();
        const spaceAbove = containerBB
            ? containerBB.top < bb.top
                ? bb.top - containerBB.top
                : 0
            : Infinity;
        const isBelow = spaceAbove < this.menuMargin + this.menuHeight;

        return this.overlay
            .position()
            .flexibleConnectedTo({
                x: bb.left - this.menuMargin,
                y: bb.top - this.menuMargin,
                width: bb.width + 2 * this.menuMargin,
                height: bb.height + 2 * this.menuMargin,
            })
            .withPositions([
                {
                    originX: 'center',
                    overlayX: 'center',
                    originY: isBelow ? 'bottom' : 'top',
                    overlayY: isBelow ? 'top' : 'bottom',
                },
            ])
            .withLockedPosition(true)
            .withPush(true);
    }
}

<div class="modal-header">
    <h3 mat-dialog-title class="modal-title" [translate]="modalTitleKey"></h3>
    <button
        mat-icon-button
        (click)="onCloseCancel()"
        class="modal-close-btn"
    ></button>
</div>
<form [formGroup]="formGroup" (ngSubmit)="onCloseSubmit()">
    <div mat-dialog-content>
        <dxy-field-text
            formControlName="displayName"
            labelKey="UI.Dialog.NewItem.Project.lblInstructions"
            [mandatory]="true"
        ></dxy-field-text>

        <dxy-field-text
            formControlName="trigram"
            labelKey="UI.Dialog.NewItem.Project.lblTrigram"
            [mandatory]="true"
            [errorMessageKey]="trigramErrorMessageKey"
        ></dxy-field-text>

        <dxy-rich-text-field
            formControlName="description"
            labelKey="UI.Dialog.NewItem.Project.phDescription"
            [noTabCapture]="true"
            toolbarDisplayMode="focus"
            [mentionResolvers]="mentionResolvers"
            (onAcquireMentionData)="onPanelOpenClose($event)"
            dxyDataTestId="description-field-text"
        ></dxy-rich-text-field>

        <dxy-field-select
            formControlName="defaultOwner"
            labelKey="UI.Dialog.NewItem.Project.lblDefaultDataOwner"
            [adapter]="defaultOwnerAdapter"
            [options]="defaultOwnerAdapter.options"
            [mandatory]="true"
            [readonly]="!isCreation"
            (openClose)="onPanelOpenClose($event)"
            [search]="true"
            dxyDataTestId="owner-field-text"
        ></dxy-field-select>

        <dxy-field-select
            formControlName="defaultSteward"
            labelKey="UI.Dialog.NewItem.Project.lblDefaultDataSteward"
            [adapter]="defaultStewardAdapter"
            [options]="defaultStewardAdapter.options"
            [mandatory]="true"
            [readonly]="!isCreation"
            (openClose)="onPanelOpenClose($event)"
            [search]="true"
            dxyDataTestId="steward-field-text"
        ></dxy-field-select>

        <dxy-field-select
            formControlName="securityProfile"
            labelKey="UI.Dialog.NewItem.Project.phSecurityProfile"
            [adapter]="securityProfileAdapter"
            [mandatory]="true"
            [useRichLayout]="true"
            (openClose)="onPanelOpenClose($event)"
            [hint]="securityProfileTypeTextKey | translate"
            dxyDataTestId="security-field-text"
        ></dxy-field-select>
    </div>
    <dxy-modal-footer
        [actionEnabled]="formGroup.valid"
        [actionBtnLbl]="actionBtnLblKey | translate"
        [actionLoading]="loading"
        (onCloseCancel)="onCloseCancel()"
        [featureCode]="featureCode"
    ></dxy-modal-footer>
</form>

import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnChanges,
} from '@angular/core';
import { GlyphUtil } from '../../../../../shared/util/GlyphUtil';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';
import { EntityLifecycleStatus } from '@datagalaxy/webclient/attribute/domain';

@Component({
    selector: 'app-entity-node-users',
    templateUrl: './entity-node-users.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EntityNodeUsersComponent
    extends DxyBaseComponent
    implements OnChanges
{
    @Input() entity: EntityItem;
    @Input() attributeKey: string;

    protected status: EntityLifecycleStatus;
    protected userIds: string[];

    protected get glyph() {
        return GlyphUtil.getStatusGlyphClass(this.status);
    }
    protected get attributeTranslateKey() {
        return `DgServerTypes.BaseData.fields.${this.attributeKey}`;
    }

    constructor() {
        super();
    }

    ngOnChanges() {
        this.userIds = this.entity.getAttributeValue<string[]>(
            this.attributeKey
        );
    }
}

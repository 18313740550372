import {
    EntityType,
    EntityTypeUtil,
    ServerType,
} from '@datagalaxy/dg-object-model';
import { AttributeFilterModel } from './attribute-filter/attributeFilterModel';
import { AttributeDataService } from '../attribute-data.service';
import { AttributeTextFilterModel } from './attribute-text-filter/AttributeTextFilterModel';
import { AttributeDateFilterModel } from './attribute-date-filter/AttributeDateFilterModel';
import { AttributeValueListFilterModel } from './attribute-value-list-filter/AttributeValueListFilterModel';
import { AttributeReferenceFilterModel } from './attribute-value-list-filter/AttributeReferenceFilterModel';
import { AttributeEntityFilterModel } from './attribute-entity-filter/AttributeEntityFilterModel';
import { CollectionsHelper, CoreUtil, StringUtil } from '@datagalaxy/core-util';
import { AttributeBooleanFilterModel } from './attribute-boolean-filter/AttributeBooleanFilterModel';
import { IFilterItemModelSourceData } from './models/IFilterItemModelSourceData';
import { FilterItemModelSourceData } from './models/FilterItemModelSourceData';
import { FilterUtil } from '../../util/FilterUtil';
import { AttributeNumberFilterModel } from './attribute-number-filter/AttributeNumberFilterModel';
import { BaseService } from '@datagalaxy/core-ui';
import { DataUtil } from '../../util/DataUtil';
import {
    IFilterFormAttributes,
    IFilterFormModel,
    IHasAttributeFilterModels,
} from './attribute-filter-form/IFilterFormModel';
import { BaseFilterListItemModel } from './models/BaseFilterListItemModel';
import { GlyphService } from '../../../services/glyph.service';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ViewTypeService } from '../../../services/viewType.service';
import { ModuleAttributeFilterFormModel } from './attribute-filter-form/module-attribute-filter-form.model';
import { BaseAttributeFilterFormModel } from './attribute-filter-form/BaseAttributeFilterFormModel';
import { ModelerDataUtil } from '../../util/ModelerDataUtil';
import { ModelerService } from '../../../modeler/services/modeler.service';
import { FilteredViewsUtil } from '@datagalaxy/webclient/filter/data-access';
import { getLocalId, getReferenceId } from '@datagalaxy/webclient/utils';
import {
    CrudActionType,
    CrudOperation,
    FunctionalLogService,
} from '@datagalaxy/webclient/monitoring/data-access';
import {
    EntityIdentifier,
    EntityTypeUtils,
} from '@datagalaxy/webclient/entity/utils';
import { SpaceIdentifier } from '@datagalaxy/webclient/workspace/utils';
import { ISpaceIdentifier } from '@datagalaxy/webclient/workspace/domain';
import {
    Filter,
    FilteredViewDto,
    FilterOperator,
} from '@datagalaxy/webclient/filter/domain';
import {
    AttributeMetaInfo,
    AttributeMetaType,
    AttributeMetaValue,
} from '@datagalaxy/webclient/attribute/domain';
import { DgZone } from '@datagalaxy/webclient/domain';
import { DgModule } from '@datagalaxy/shared/dg-module/domain';
import { ServerConstants } from '@datagalaxy/shared/server/domain';
import PropertyName = ServerConstants.PropertyName;

@Injectable({ providedIn: 'root' })
export class AttributeFilterService extends BaseService {
    //#region static

    public static newFilterItemFromAttribute(
        ffm: IFilterFormModel,
        ami: AttributeMetaInfo,
        isQuickFilter?: boolean,
        operators?: FilterOperator[]
    ): AttributeFilterModel {
        operators =
            operators ??
            AttributeFilterService.getAvailableFilterOperators(
                ami,
                isQuickFilter
            );
        switch (ami?.AttributeType) {
            case AttributeMetaType.Text:
            case AttributeMetaType.MultiLineText:
            case AttributeMetaType.FormattedText:
            case AttributeMetaType.HtmlLink:
                return new AttributeTextFilterModel(ffm, ami, operators);

            case AttributeMetaType.TimeSeriesLastEntry:
            case AttributeMetaType.Number:
                return new AttributeNumberFilterModel(ffm, ami, operators);

            case AttributeMetaType.Boolean:
                return new AttributeBooleanFilterModel(ffm, ami, operators);

            case AttributeMetaType.Date:
            case AttributeMetaType.DateTime:
                return new AttributeDateFilterModel(ffm, ami, operators);

            case AttributeMetaType.UserGuid:
            case AttributeMetaType.ReferenceList:
            case AttributeMetaType.PersonReference:
            case AttributeMetaType.UserReference:
            case AttributeMetaType.MultiValueList:
            case AttributeMetaType.ManagedTag:
            case AttributeMetaType.ClientTag:
            case AttributeMetaType.Hierarchy:
            case AttributeMetaType.StewardUserReference:
                return new AttributeReferenceFilterModel(ffm, ami, operators);

            case AttributeMetaType.EntityReference:
            case AttributeMetaType.ObjectLink:
            case AttributeMetaType.AllLinkedData:
                return new AttributeEntityFilterModel(ffm, ami, operators);

            case AttributeMetaType.ValueList:
            case AttributeMetaType.ReferenceId:
            case AttributeMetaType.Reference:
            case AttributeMetaType.Module:
            case AttributeMetaType.Technology:
                return new AttributeValueListFilterModel(ffm, ami, operators);
        }
    }
    private static getAvailableFilterOperators(
        attributeMeta: AttributeMetaInfo,
        isQuickFilter?: boolean
    ) {
        const operators =
            AttributeFilterService.getFilterOperatorsDependingOnAttributeType(
                attributeMeta,
                isQuickFilter
            );
        return AttributeDataService.isMandatoryOrNonNullableAttribute(
            attributeMeta
        )
            ? operators.filter((fo) => !FilterUtil.isNullableOperator(fo))
            : operators.slice();
    }
    private static getFilterOperatorsDependingOnAttributeType(
        attributeMeta: AttributeMetaInfo,
        isQuickFilter?: boolean
    ) {
        switch (attributeMeta.AttributeType) {
            case AttributeMetaType.Text:
            case AttributeMetaType.FormattedText:
            case AttributeMetaType.MultiLineText:
            case AttributeMetaType.HtmlLink:
                return AttributeTextFilterModel.availableOperators(
                    isQuickFilter
                );

            case AttributeMetaType.Boolean:
                return AttributeBooleanFilterModel.availableOperators(
                    isQuickFilter
                );

            case AttributeMetaType.Date:
            case AttributeMetaType.DateTime:
                return AttributeDateFilterModel.availableOperators(
                    isQuickFilter
                );

            case AttributeMetaType.Number:
            case AttributeMetaType.TimeSeriesLastEntry:
                return AttributeNumberFilterModel.availableOperators(
                    isQuickFilter
                );

            case AttributeMetaType.ValueList:
                if (isQuickFilter) {
                    return [FilterOperator.ListContains];
                }
                return [
                    FilterOperator.ListContains,
                    FilterOperator.Equals,
                    FilterOperator.ListExcludes,
                ];

            case AttributeMetaType.Module:
                if (isQuickFilter) {
                    return [FilterOperator.ListContains];
                }
                return [FilterOperator.ListContains];
            /**  This Type is used to map server-side ReferenceObject<T> attributes which are
             *  manually included for filtering. The current only example is the Column.DataTypeRef
             *  which is used for the Column's DataTypes which can be system or user ReferenceId (Integer, VariableString, etc.)
             */
            case AttributeMetaType.Reference:
                if (isQuickFilter) {
                    return [FilterOperator.ListContains];
                }
                return [
                    FilterOperator.ListContains,
                    FilterOperator.Equals,
                    FilterOperator.FieldIsEmpty,
                    FilterOperator.FieldHasValue,
                ];

            case AttributeMetaType.UserGuid:
            case AttributeMetaType.Technology:
                if (isQuickFilter) {
                    return [FilterOperator.ListContains];
                }
                return [
                    FilterOperator.ListContains,
                    FilterOperator.ListExcludes,
                ];

            /**  In the Context of Filtering, there is only one attribute that matches the ReferenceList type:
             *  Parents, which corresponds to the Parent (Ascending) attribute */
            case AttributeMetaType.ReferenceList:
            /**  The Following value Block is Multi-Value based and all maps to Data from Database:
             *  Either from
             *      dbo.ClientTag (ClientTag)
             *      dbo.ClientAttributeTag (Hierarchy, ManagedTag, MultiValueList)
             *      dbo.Person (PersonReference)
             *      dbo.User (UserReference)
             *      dbo.SpaceGovernanceUser (StewardUserReference)
             * */

            case AttributeMetaType.ClientTag:
            case AttributeMetaType.Hierarchy:
            case AttributeMetaType.ManagedTag:
            case AttributeMetaType.MultiValueList:
            case AttributeMetaType.PersonReference:
            case AttributeMetaType.UserReference:
            case AttributeMetaType.StewardUserReference:
            /** Used for specific case: FilterParentId, which allows filtering on the Direct Parent
             * It is a computed attribute which maps on the real Parent Entity's ReferenceId */
            case AttributeMetaType.EntityReference:
            /**  Used for all Computed attributes ObjectLinks_xxxxxx, which maps to Legacy or Entity Links */
            case AttributeMetaType.ObjectLink:
            case AttributeMetaType.AllLinkedData:
                if (isQuickFilter) {
                    return [FilterOperator.ListContains];
                }
                return [
                    FilterOperator.ListContains,
                    FilterOperator.ListMatchAll,
                    FilterOperator.ListExcludes,
                    FilterOperator.FieldIsEmpty,
                    FilterOperator.FieldHasValue,
                ];

            /** CBO: Need to verify if still used when Filtering, not sure... Was used for ModelId/ContainerId
             *  which disappeared some versions ago, with the Catalog module uniformization */
            case AttributeMetaType.ReferenceId:
                if (isQuickFilter) {
                    return [FilterOperator.Equals];
                }
                return [
                    FilterOperator.Equals,
                    FilterOperator.FieldIsEmpty,
                    FilterOperator.FieldHasValue,
                ];

            default:
                return [] as FilterOperator[];
        }
    }

    public static getQuickFilterItemsFromFilters(
        ffm: IFilterFormModel & IFilterFormAttributes,
        filters: Filter[]
    ) {
        filters.forEach((filter) => (filter.isQuickFilter = true));
        return AttributeFilterService.getFilterItemsFromFilters(
            ffm,
            filters,
            false
        );
    }

    public static getFilterItemsFromFilters(
        ffm: IFilterFormModel,
        filters: Filter[],
        onlyCompleted: boolean
    ) {
        return filters
            ?.filter(
                (filter) =>
                    AttributeFilterService.isFilterComplete(filter) ||
                    !onlyCompleted
            )
            .map((filter) =>
                AttributeFilterService.setupFilterItemFromDbFilter(
                    ffm,
                    filter,
                    ffm.sourceAttributes
                )
            )
            .filter((afm) => !!afm);
    }

    private static setupFilterItemFromDbFilter(
        ffm: IFilterFormModel,
        filter: Filter,
        allAttributes: AttributeMetaInfo[]
    ) {
        const ami = allAttributes?.find(
            (att) => att.AttributePath === filter.AttributePath
        );
        if (!ami) {
            return;
        }
        const newFilterItem = AttributeFilterService.newFilterItemFromAttribute(
            ffm,
            ami,
            filter.isQuickFilter
        );
        if (!newFilterItem) {
            return;
        }

        newFilterItem.operator = FilterOperator[filter.FilterOperator];
        newFilterItem.isFormOpen = false;
        newFilterItem.isQuickFilter = filter.isQuickFilter;
        newFilterItem.setValuesFromDb(
            filter.SearchValues,
            filter.SearchHddValues
        );
        return newFilterItem;
    }

    public static isToBeComparedAsLocalId(attributeKey: string) {
        return (
            attributeKey ==
                ServerConstants.AttributeConstants.SystemTagsAttributeKey ||
            AttributeDataService.isSpaceGovUserAttributeKey(attributeKey)
        );
    }

    //#region for attribute-filter-form, filter-item

    //#region for FilterFormModel or CurrentSearch, as IHasFilterItemModels
    public static exportFilters(filterItems: AttributeFilterModel[]) {
        return filterItems.map((filterItem) =>
            AttributeFilterService.exportFilter(filterItem)
        );
    }
    public static isFilterComplete(
        filter: Filter,
        allowEmptySearchTerm?: boolean
    ) {
        const fo = FilterOperator[filter.FilterOperator];

        return !!(
            filter.AttributeKey &&
            filter.FilterOperator &&
            (FilterUtil.isValuelessOperator(fo) ||
                filter.SearchValues?.every((v) => v?.length) ||
                (FilterUtil.canHaveOneOrManyValuesOperator(fo) &&
                    filter.SearchValues?.some((v) => v?.length)) ||
                (allowEmptySearchTerm &&
                    filter.AttributeKey ==
                        ServerConstants.Search.SearchTermFilterKey) ||
                (filter.AttributeKey ==
                    ServerConstants.PropertyName.Technology &&
                    filter.SearchValues.length))
        );
    }
    public static getFilterItem(
        filterItems: AttributeFilterModel[],
        attributeKey: string
    ) {
        return filterItems.find((afm) => afm.attributeKey === attributeKey);
    }
    public static getFilterItems(
        ffm: IHasAttributeFilterModels,
        predicate?: (afm: AttributeFilterModel) => boolean
    ): AttributeFilterModel[] {
        return ffm
            ? predicate
                ? ffm.filterItems.filter(predicate)
                : ffm.filterItems.slice()
            : undefined;
    }
    public static getNotEmptyFilterItems(
        ffm: IHasAttributeFilterModels,
        allowEmptySearchTerm?: boolean
    ) {
        return AttributeFilterService.getFilterItems(
            ffm,
            (afm) =>
                afm.isNotEmpty() ||
                FilterUtil.isValuelessOperator(afm.operator) ||
                (allowEmptySearchTerm &&
                    afm.attributeKey ==
                        ServerConstants.Search.SearchTermFilterKey)
        );
    }
    private static exportFilter(filterItem: AttributeFilterModel) {
        const searchHddValues =
            filterItem instanceof BaseFilterListItemModel
                ? filterItem.getHddValues()
                : null;

        return (
            filterItem &&
            new Filter(
                filterItem.attributePath,
                filterItem.operator,
                filterItem.getValuesAsArray(),
                filterItem.isFromQuickFilter,
                searchHddValues
            )
        );
    }

    //#endregion

    //#endregion - for attribute-filter-form, filter-item

    public static getAttributeMeta(
        attributeKey: string,
        ffm: IFilterFormModel
    ) {
        return (
            attributeKey &&
            ffm?.sourceAttributes?.find((a) => a.AttributeKey == attributeKey)
        );
    }

    /** Creates a FilterItemModel if no matching found in the given IFilterFormModel,
     * sets the given value as a selected value of this FilterItemModel */
    public static addValueToFilterItems(
        amv: AttributeMetaValue,
        compareAsLocalId: boolean,
        ffm: BaseAttributeFilterFormModel,
        operator?: FilterOperator
    ) {
        if (!amv || !ffm) {
            return;
        }

        const ami = amv.attributeInfo;
        let afm = ffm.filterItems.find(
            (fi) => fi.attributeKey == ami.AttributeKey
        );

        const result: IChangeFilterItemResult = {
            value: amv,
            previousOperator: afm?.operator,
        };

        if (!afm) {
            afm = AttributeFilterService.newFilterItemFromAttribute(ffm, ami);
            afm.isFormOpen = false;
            result.itemAdded = true;
        }
        result.filterItem = afm;

        if (afm instanceof AttributeDateFilterModel) {
            afm.operator =
                FilterOperator[amv.Key] ?? FilterOperator.RangeContains;
        } else if (afm instanceof AttributeBooleanFilterModel) {
            afm.operator = FilterOperator.Equals;
            afm.value =
                amv.Key === '0' ? false : amv.Key === '1' ? true : undefined;
        } else {
            let valueAdded = false;
            if (afm instanceof BaseFilterListItemModel) {
                valueAdded = afm.addValueIfKeyNotFound(amv, compareAsLocalId);
                if (valueAdded) {
                    afm.operator = afm.defaultOperator;
                }
            }
            result.valueAdded = valueAdded;
        }

        if (operator != undefined) {
            afm.operator = operator;
        }
        ffm.addFilterItem(afm);
        AttributeFilterService.notifyIfNeeded(result);

        return result;
    }
    /** Removes the given value from the matching FilterItemModel,
     * removes the filterItemModel if empty */
    public static removeValueFromFilterItems(
        amv: AttributeMetaValue,
        compareAsLocalId: boolean,
        ffm: BaseAttributeFilterFormModel
    ) {
        if (!amv || !ffm) {
            return;
        }

        const ak = amv.attributeInfo.AttributeKey;
        const afm = ffm.filterItems.find((f) => f.attributeKey == ak);

        const result: IChangeFilterItemResult = {
            value: amv,
            previousOperator: afm?.operator,
            filterItem: afm,
        };

        if (afm instanceof BaseFilterListItemModel) {
            let isMatch: (v: AttributeMetaValue) => boolean, key: string;
            if (compareAsLocalId) {
                key = getLocalId(amv.Key);
                isMatch = (v) => key == getLocalId(v.Key);
            } else {
                key = amv.Key;
                isMatch = (v) => key == v.Key;
            }
            const removed = CollectionsHelper.remove(afm.values, isMatch);
            result.valueRemoved = !!removed.length;

            if (!afm.hasValues) {
                ffm.removeFilterItem(afm);
                result.itemRemoved = true;
            }
        } else if (afm) {
            ffm.removeFilterItem(afm);
            result.itemRemoved = true;
        }

        AttributeFilterService.notifyIfNeeded(result);

        return result;
    }
    private static notifyIfNeeded(result: IChangeFilterItemResult) {
        if (!result?.filterItem || result.itemAdded || result.itemRemoved) {
            return;
        }
        if (
            result.previousOperator != result.filterItem.operator ||
            result.valueAdded ||
            result.valueRemoved
        ) {
            result.filterItem.notifyItemChanged();
        }
    }

    //#endregion - static

    constructor(
        private translate: TranslateService,
        private attributeDataService: AttributeDataService,
        private modelerService: ModelerService,
        private glyphService: GlyphService,
        private viewTypeService: ViewTypeService,
        private functionalLogService: FunctionalLogService
    ) {
        super();
    }

    public logFilter(afm: AttributeFilterModel, additionalLogId?: string) {
        switch (afm.attributeKey) {
            case PropertyName.IsWatchedByCurrentUser: {
                const watched = afm.getValuesAsArray()?.[0];
                if (!watched) {
                    return;
                }
                this.functionalLogService.logFunctionalAction(
                    `FILTER_WATCHED_${additionalLogId}`,
                    CrudOperation.A,
                    watched === 'true' ? CrudActionType.On : CrudActionType.Off
                );
            }
        }
    }

    public async getAvailableValues(
        filterItemModel: IFilterItemModelSourceData
    ) {
        const ami = filterItemModel.attributeMeta,
            spaceIdr = filterItemModel?.spaceIdr;
        this.log(
            'getAvailableValues',
            AttributeMetaType[ami?.AttributeType],
            spaceIdr
        );
        switch (ami.AttributeType) {
            case AttributeMetaType.UserGuid:
            case AttributeMetaType.ManagedTag:
            case AttributeMetaType.MultiValueList:
            case AttributeMetaType.PersonReference:
            case AttributeMetaType.UserReference:
            case AttributeMetaType.ClientTag:
            case AttributeMetaType.StewardUserReference:
            case AttributeMetaType.Hierarchy:
                return this.attributeDataService.loadReferenceOptionsSpace(
                    ami,
                    spaceIdr
                );

            case AttributeMetaType.Module:
                return filterItemModel.attributeMeta.ListValues.filter(
                    (amv) => DgModule[amv.Value as string] !== DgModule.unknown
                );

            case AttributeMetaType.Technology: {
                const undefinedTechno = new AttributeMetaValue(ami, '', null, {
                    translatedDisplayName: this.translate.instant(
                        'UI.Components.MultiSelect.empty'
                    ),
                });
                const amvs =
                    await this.attributeDataService.loadReferenceOptionsSpace(
                        ami,
                        spaceIdr
                    );
                amvs.unshift(undefinedTechno);
                return amvs;
            }

            case AttributeMetaType.Reference:
                if (
                    ami instanceof AttributeMetaInfo &&
                    ami.ReferenceServerType == ServerType.DataType
                ) {
                    const spaceId = spaceIdr?.spaceId;
                    if (!spaceId) {
                        this.warn('no spaceId');
                    }
                    let versionId = spaceIdr?.versionId;
                    if (!versionId) {
                        this.warn('no versionId');
                        versionId = null;
                    }
                    const modelIdr = new EntityIdentifier(
                        filterItemModel.currentModelId,
                        versionId,
                        EntityType.Model
                    );
                    const dataTypes =
                        await this.modelerService.getModelDataTypes(modelIdr);
                    return dataTypes.map(
                        (dataType) =>
                            new AttributeMetaValue(
                                ami,
                                dataType.ReferenceId,
                                dataType.fullDisplayName
                            )
                    );
                } else {
                    this.warn(
                        'ReferenceDataTypeName not implemented',
                        ami.ReferenceDataTypeName,
                        filterItemModel
                    );
                }
                break;
            default:
                //this.log('AttributeType not implemented', AttributeMetaType[ami.AttributeType], filterItemModel)
                break;
        }
    }

    //#region for spotlight input search preview
    public async getClientTagFilterValues(
        ffm: IFilterFormModel & IFilterFormAttributes
    ) {
        const { sourceAttributes, filterItems, spaceIdr } = ffm;
        const afm = this.getFilterItemSourceData(
            sourceAttributes,
            filterItems,
            spaceIdr,
            ServerConstants.AttributeConstants.SystemTagsAttributeKey
        );
        if (!afm) {
            return;
        }
        return this.getAvailableValues(afm);
    }
    public async getSpaceGovUserFilterValues(
        ffm: IFilterFormModel,
        query: string,
        attributeKey?: string
    ) {
        const result = await this.attributeDataService.getSpaceGovernanceUsers(
            ffm.spaceIdr
        );
        return CollectionsHelper.flatten(
            AttributeDataService.spaceGovUserAttributeKeys.map((ak) => {
                if (attributeKey && ak != attributeKey) {
                    return;
                }

                const group = result.SpaceGovernanceUserList.get(ak);
                const found = StringUtil.filterSearched(
                    query,
                    group,
                    (u) => u.filterString
                );
                if (!found?.length) {
                    return;
                }
                const ami = AttributeFilterService.getAttributeMeta(ak, ffm);
                return found.map((dto) =>
                    AttributeMetaValue.fromSpaceGovUser(dto, ami)
                );
            })
        );
    }
    //#endregion

    //#region for attribute-filter-form, filter-item

    public getFilterItemDisplayName(filterItem: AttributeFilterModel) {
        return filterItem &&
            filterItem.attributeKey ===
                ServerConstants.Search.SearchTermFilterKey
            ? this.translate.instant('UI.Spotlight.filters.searchTerm')
            : this.getAttributeDisplayName(
                  filterItem.attributeMeta,
                  filterItem.sourceAttributes,
                  filterItem.dgModule
              );
    }

    public getAttributeDisplayName(
        attribute: AttributeMetaInfo,
        sourceAttributes: AttributeMetaInfo[],
        dgModule?: DgModule
    ) {
        const displayName = attribute.translatedDisplayName;

        if (
            dgModule == DgModule.Catalog &&
            attribute.IsCdp &&
            !attribute.IsAllTypes
        ) {
            return `${displayName} (${this.tradGetServerTypeName(
                attribute.serverType
            )})`;
        }

        if (
            sourceAttributes.some(
                (c) =>
                    c.translatedDisplayName == displayName &&
                    c.AttributePath != attribute.AttributePath
            )
        ) {
            const attributeTypeTranslateKey =
                AttributeDataService.getAttributeTypeTranslateKey(
                    attribute.AttributeType
                );
            return `${displayName} (${this.translate.instant(
                attributeTypeTranslateKey
            )})`;
        }

        return displayName;
    }
    private tradGetServerTypeName(serverType: ServerType): string {
        return this.translate.instant(
            `DgServerTypes.ServerTypeName.${ServerType[serverType]}`
        );
    }

    public async getModuleAttributeFilterForm(fv: FilteredViewDto) {
        const dgModule = FilteredViewsUtil.dgModuleFromModuleName(
            fv.ModuleName
        );
        const formAttributes =
            await this.getAvailableAttributesForModuleFiltering(dgModule);
        const spaceId = fv.SpaceUid;
        const spaceReferenceId = spaceId
            ? getReferenceId(spaceId, spaceId)
            : null;
        const spaceIdr = new SpaceIdentifier(
            spaceReferenceId,
            fv.DefaultVersionId
        );
        const filters = fv.filters;
        const formModel = new ModuleAttributeFilterFormModel(
            DgZone.Module,
            dgModule,
            spaceIdr,
            this.getLogger('ModuleFilter'),
            formAttributes
        );
        await this.loadServerDataForFilters(formModel, filters);
        formModel.setupFilterItems(filters);
        return formModel;
    }

    public async loadServerDataForFilters(
        ffm: IFilterFormModel,
        filters: Filter[]
    ) {
        const { sourceAttributes, filterItems, spaceIdr } = ffm;
        if (!filters?.length) {
            return;
        }
        await Promise.all(
            filters.map(async (filter) => {
                const afm = this.getFilterItemSourceData(
                    sourceAttributes,
                    filterItems,
                    spaceIdr,
                    filter.AttributePath
                );
                if (!afm) {
                    return;
                }
                const attribute = afm.attributeMeta;

                if (attribute.hasLoadedValues) {
                    return;
                }
                const serverDataPromise = this.getAvailableValues(afm);

                attribute.loadingValuesPromise = serverDataPromise;
                attribute.isLoadingValues = true;

                const data = await serverDataPromise;
                attribute.isLoadingValues = false;
                attribute.loadingValuesPromise = null;
                attribute.hasLoadedValues = true;
                if (data) {
                    attribute.ListValues = data;
                }
            })
        );
    }

    private getFilterItemSourceData(
        sourceAttributes: AttributeMetaInfo[],
        filterItems: AttributeFilterModel[],
        spaceIdr: ISpaceIdentifier,
        attributePath: string
    ): IFilterItemModelSourceData {
        const ami = sourceAttributes?.find(
            (att) => att.AttributePath === attributePath
        );
        if (!ami) {
            return;
        }

        return (
            AttributeFilterService.getFilterItem(
                filterItems,
                ami.AttributeKey
            ) ?? new FilterItemModelSourceData(ami, spaceIdr)
        );
    }

    public async getAvailableAttributesForModuleFiltering(
        dgModule: DgModule
    ): Promise<IFilterFormAttributes> {
        if (!dgModule) {
            return {
                allAttributes: [],
                sourceAttributes: [],
                availableAttributes: [],
            };
        }
        const allAttributes =
            await this.attributeDataService.getAttributesForFiltering(dgModule);
        const serverType = DataUtil.getDefaultServerTypeFromModule(dgModule);
        const searchTermAttributeKey =
            this.viewTypeService.getPrimaryNameAttribute(serverType);
        const subTypeAmi = CollectionsHelper.getFirstRemoved(
            allAttributes,
            (ami) =>
                ami.AttributeKey ==
                ServerConstants.PropertyName.LegacySubTypeAttributeKey
        );
        const entityTypeAttribute =
            subTypeAmi &&
            this.getEntityTypeAttributeForModuleFiltering(
                serverType,
                subTypeAmi,
                allAttributes
            );
        const sourceAttributes = [...allAttributes, entityTypeAttribute];
        const availableAttributes = sourceAttributes.slice();
        return {
            allAttributes,
            sourceAttributes,
            entityTypeAttribute,
            searchTermAttributeKey,
            availableAttributes,
        };
    }
    /**
     * Two purposes:
     *  - Using EntityType values instead of SubType Values (and using the EntityType AttributeKey)
     *  - Handling special case for Modeler: We combine all the values from the 4 different modeler classes:
     *  - Sources, Containers, Structures and Fields
     */
    private getEntityTypeAttributeForModuleFiltering(
        serverType: ServerType,
        ami: AttributeMetaInfo,
        allAttributes: AttributeMetaInfo[]
    ) {
        const clone = CoreUtil.clone(ami);

        clone.AttributeKey = ServerConstants.Search.EntityTypeFilterKey;
        clone.AttributePath = ServerConstants.Search.EntityTypeFilterKey;

        allAttributes.push(clone);

        if (serverType != ServerType.Model) {
            clone.ListValues = this.getOrderedEntityTypeValues(ami, serverType);
            return clone;
        }

        clone.translatedDisplayName =
            this.attributeDataService.getAttributeDisplayNameInternal(
                ServerConstants.PropertyName.LegacySubTypeAttributeKey,
                false,
                null,
                ServerType.Property
            );
        clone.ListValues = [];

        ModelerDataUtil.getOrderedModelerServerTypes().forEach((st) => {
            const sourceTypeAttribute = this.attributeDataService.getAttribute(
                st,
                ServerConstants.PropertyName.LegacySubTypeAttributeKey
            );
            clone.ListValues.push(
                ...this.getOrderedEntityTypeValues(sourceTypeAttribute, st)
            );
        });

        return clone;
    }
    private getOrderedEntityTypeValues(
        sourceTypeAttribute: AttributeMetaInfo,
        st: ServerType
    ) {
        const serverTypeName = ServerType[st];
        const orderedSubTypeNames =
            AttributeDataService.getOrderedAdminSubTypes(st).map(
                (et) => EntityType[et]
            );
        const amvs = sourceTypeAttribute.ListValues.map((amv) => {
            const entityType = EntityTypeUtil.getEntityType(
                serverTypeName,
                amv.Key
            );
            return new AttributeMetaValue(
                sourceTypeAttribute,
                EntityType[entityType],
                EntityType[entityType],
                {
                    translatedDisplayName: amv.translatedDisplayName,
                    translatedDescription: amv.translatedDescription,
                    glyphClass:
                        EntityTypeUtils.getColoredGlyphClass(entityType),
                }
            );
        });
        return CollectionsHelper.orderBy(amvs, (amv) =>
            orderedSubTypeNames.indexOf(amv.Key)
        );
    }

    //#endregion - for attribute-filter-form, filter-item

    public getFilterItemOperatorDisplayName(
        filterItemModel: AttributeFilterModel
    ) {
        return this.getFilterOperatorDisplayName(
            filterItemModel.operator,
            filterItemModel
        );
    }
    public getFilterOperatorDisplayName(
        operator: FilterOperator,
        filterItemModel: AttributeFilterModel
    ) {
        const operatorName = FilterOperator[operator];
        const attributeMetaTypeName =
            AttributeMetaType[filterItemModel.attributeType];
        return this.translate.instant(
            `UI.Filter.operator.${attributeMetaTypeName}.${operatorName}`
        );
    }
}

export interface IChangeFilterItemResult {
    filterItem?: AttributeFilterModel;
    value?: AttributeMetaValue;
    previousOperator?: FilterOperator;
    itemAdded?: boolean;
    valueAdded?: boolean;
    itemRemoved?: boolean;
    valueRemoved?: boolean;
}

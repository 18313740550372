import { Transition, TransitionService } from '@uirouter/core';
import { CommonStateDefinitions as CSD } from '../shared/util/states/CommonStateDefinitions';
import { Ng2StateDeclaration } from '@uirouter/angular';
import { DxySpaceMainComponent } from './dxy-space-main/dxy-space-main.component';
import { VersioningService } from '../versioning/services/versioning.service';
import { AppSpaceService } from '../services/AppSpace.service';
import { DxyNavbarTitleComponent } from '../navbar/dxy-navbar-title/dxy-navbar-title.component';
import { StateName } from '@datagalaxy/webclient/routing';
import { DxyOrganizationDashboardComponent } from '../organization-dashboard/dxy-organization-dashboard/dxy-organization-dashboard.component';
import { ViewIdentifier } from '../shared/util/ViewIdentifier';
import { NavigationService } from '../services/navigation.service';
import { ScreenService } from '../screens/screen.service';
import { Constants } from '../shared/util/Constants';
import {
    Organization,
    Project,
    Space,
} from '@datagalaxy/webclient/workspace/data-access';
import { ProjectVersion } from '@datagalaxy/webclient/versioning/data-access';
import { SpaceHomeNavBarComponent } from './space-home-navbar/space-home-navbar.component';
import { SpaceHomeDashboardComponent } from './space-home-dashboard/space-home-dashboard.component';
import Nav = Constants.Nav;
import UrlWord = Nav.UrlWord;
import ParamKey = Nav.ParamKey;

export function getStatesForSpace(): Ng2StateDeclaration[] {
    return [
        // a space can be an organization or a project.
        // Here, the space is considered a project only if we have a versionId in the current state

        {
            name: StateName.Space,
            abstract: true,
            url: `/${UrlWord.space}/:${ParamKey.NavId}/:${ParamKey.VersionId}`,
            params: {
                versionId: { value: null, squash: true },
            },
            views: { content: { component: DxySpaceMainComponent } },
            resolve: [
                {
                    token: 'currentRoot',
                    deps: ['$transition$', AppSpaceService, NavigationService],
                    resolveFn: async (
                        $transition$: Transition,
                        appSpaceService: AppSpaceService,
                        navigationService: NavigationService
                    ) => {
                        const spaceIdr =
                            NavigationService.getSpaceIdrFromParams(
                                $transition$.params()
                            );
                        try {
                            const result =
                                await appSpaceService.setCurrentSpace(
                                    spaceIdr,
                                    false
                                );
                            if (!result) {
                                throw `No space found for spaceIdr : ${spaceIdr}`;
                            }
                            return result;
                        } catch (error) {
                            console.warn(error);
                            const clientId =
                                $transition$.params()[
                                    Constants.Nav.ParamKey.ClientId
                                ];
                            return navigationService.goToSpecificClientSpacesList(
                                clientId
                            );
                        }
                    },
                },
                {
                    token: '_loadSpaceScreens',
                    deps: ['currentRoot', ScreenService],
                    resolveFn: async (
                        currentRoot: Space,
                        screenService: ScreenService
                    ) => {
                        if (!currentRoot) {
                            return;
                        }
                        screenService.initSpaceScreens();
                        await screenService.loadSpaceScreens(
                            currentRoot.ReferenceId,
                            currentRoot?.versionId
                        );
                    },
                },
                {
                    token: 'securityData',
                    deps: ['currentRoot'],
                    resolveFn: (currentRoot: Space) =>
                        currentRoot instanceof Organization
                            ? currentRoot.OrganizationSecurityData
                            : currentRoot instanceof Project
                            ? currentRoot.ProjectSecurityData
                            : undefined,
                },
                {
                    token: 'projectVersions',
                    deps: ['currentRoot', VersioningService],
                    resolveFn: (
                        currentRoot: Space,
                        versioningService: VersioningService
                    ) =>
                        currentRoot instanceof Project &&
                        currentRoot.IsVersioningEnabled
                            ? versioningService.getProjectVersions(
                                  currentRoot.ReferenceId
                              )
                            : [],
                },
                {
                    token: 'spaceData',
                    deps: ['currentRoot'],
                    resolveFn: (currentRoot: Space) => currentRoot,
                },
                {
                    token: 'spaceId',
                    deps: ['spaceData'],
                    resolveFn: (spaceData: Space) => spaceData?.ReferenceId,
                },
                {
                    token: 'parentDataId',
                    deps: ['spaceData'],
                    resolveFn: (spaceData: Space) => spaceData?.ReferenceId,
                },
                {
                    token: '_updateProjectVersions',
                    deps: ['currentRoot', 'projectVersions', VersioningService],
                    resolveFn: (
                        currentRoot: Space,
                        projectVersions: ProjectVersion[],
                        versioningService: VersioningService
                    ) => {
                        if (currentRoot instanceof Project) {
                            versioningService
                                .getVersioningNavigationData()
                                .updateVersions(projectVersions, true);
                        }
                    },
                },
            ],
        },
        // #Archi-cleanup
        // Still used, only for legacy multi-orga clients,
        // to allow creation of project inside an orga.
        // To be replaced by a modal or something, and to be removed.
        // See https://datagalaxy.atlassian.net/browse/DG-586
        {
            name: StateName.SpaceDashboard,
            url: '',
            views: {
                '': { component: DxyOrganizationDashboardComponent },
                'navbarTitle@^.^': { component: DxyNavbarTitleComponent },
            },
            resolve: [
                {
                    token: 'currentSpace',
                    deps: ['currentRoot'],
                    resolveFn: (currentRoot: Space) => currentRoot,
                },
                CSD.getResolveNavbarTitleViewIdentifier(
                    ViewIdentifier.SpaceDashboard
                ),
            ],
        },
        {
            name: StateName.SpaceHome,
            url: `/${UrlWord.home}`,
            views: {
                '': { component: SpaceHomeDashboardComponent },
                'navbarTitle@^.^': { component: DxyNavbarTitleComponent },
                'navbarPanel@^.^': { component: SpaceHomeNavBarComponent },
            },
            resolve: [
                {
                    token: 'currentSpace',
                    deps: ['currentRoot'],
                    resolveFn: (currentRoot: Space) => currentRoot,
                },
                CSD.getResolveNavbarTitleViewIdentifier(
                    ViewIdentifier.SpaceHome
                ),
            ],
        },
        {
            name: StateName.Diagrams + '.**',
            url: `/${UrlWord.diagrams}`,
            loadChildren: () =>
                import('../diagrams/diagrams.module').then(
                    (m) => m.DiagramsModule
                ),
        },
        {
            name: StateName.CampaignsLazyLoaded,
            url: `/${UrlWord.campaigns}`,
            loadChildren: () =>
                import('../campaign/campaign.module').then(
                    (m) => m.CampaignModule
                ),
        },
        {
            name: StateName.SpaceSettings + '.**',
            url: `/${UrlWord.settings}`,
            loadChildren: () =>
                import('../space-settings/space-settings.module').then(
                    (m) => m.SpaceSettingsModule
                ),
        },

        //This spaceParameters route is disabled temporary to forbid organizational consolidation
        /*.state({
            name: StateName.SpaceParameters,
            url: `/${Navigation.UrlWord.parameters}`,
            component: 'spaceParameters',
        },*/
    ];
}

export function spaceRouterInit(transitionService: TransitionService) {
    CSD.dgModuleRouterInit(StateName.Space, transitionService);
}

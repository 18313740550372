import { NgModule } from '@angular/core';
import { SuggestionLinkFilterComponent } from './suggestion-link-filter/suggestion-link-filter.component';
import { CoreUiModule, DxyFiltersModule } from '@datagalaxy/core-ui';

@NgModule({
    imports: [CoreUiModule, DxyFiltersModule],
    declarations: [SuggestionLinkFilterComponent],
    exports: [SuggestionLinkFilterComponent],
})
export class DxySuggestionModule {}

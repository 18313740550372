import { DataProcessingLinkColumnDto } from './DataProcessingLinkColumnDto';
import { DataProcessingLinkDto } from './DataProcessingLinkDto';
import { GraphicalEntityData } from './GraphicalEntityData';
import { ServerType } from '@datagalaxy/dg-object-model';

export class GraphicalEntityDataDpLinkColumn extends GraphicalEntityData {
    public static fromDataProcessingLinkColumnDto(
        column: DataProcessingLinkColumnDto,
        link: DataProcessingLinkDto
    ) {
        const graphicalEntity = new GraphicalEntityDataDpLinkColumn(
            column,
            link
        );
        graphicalEntity.DataReferenceId = column.ColumnId;
        graphicalEntity.TechnicalName = column.TechnicalName;
        graphicalEntity.DisplayName = column.DisplayName;
        graphicalEntity.DataTypeName = ServerType[ServerType.Column];
        graphicalEntity.ParentReferenceId =
            link.LinkHierarchyData.DataReferenceId;
        graphicalEntity.SubTypeName = column.SubTypeName;
        graphicalEntity.versionId = column.VersionId;
        return graphicalEntity;
    }

    public ColumnData: DataProcessingLinkColumnDto;
    public LinkData: DataProcessingLinkDto;

    constructor(
        column: DataProcessingLinkColumnDto,
        link: DataProcessingLinkDto
    ) {
        super();
        this.ColumnData = column;
        this.LinkData = link;
    }
}

import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import {
    ConfirmationFunnelAction,
    FunnelAction,
    ValidatedFunnelStepComponent,
} from '@datagalaxy/ui/funnel';
import { BehaviorSubject } from 'rxjs';
import { MultilingualFunnelStepComponent } from '../multilingual-funnel-step.component';
import { MultilingualFunnelStateService } from '../multilingual-funnel-state.service';
import { DIALOG_SERVICE_TOKEN, IDialogService } from '@datagalaxy/ui/dialog';

@Component({
    selector: 'dxy-lexicon-step',
    templateUrl: './lexicon-step.component.html',
    styleUrls: ['../funnel-step.scss', './lexicon-step.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LexiconStepComponent
    extends MultilingualFunnelStepComponent
    implements ValidatedFunnelStepComponent
{
    override actions: FunnelAction[] = [
        this.previousAction,
        {
            ...this.nextAction,
            actionType: 'confirmation',
            callback: async () => {
                return await this.tryGoNext();
            },
        } as ConfirmationFunnelAction,
    ];

    public valid$ = new BehaviorSubject<boolean>(true);

    protected languageCodes = [
        this.stateService.getSourceLanguageCode() ?? '',
        this.stateService.getSelectedTargetLanguage()?.languageCode ?? '',
    ];

    private hasLexicon = false;

    constructor(
        private stateService: MultilingualFunnelStateService,
        @Inject(DIALOG_SERVICE_TOKEN) private dialogService: IDialogService
    ) {
        super();
    }

    protected onUploaded() {
        this.hasLexicon = true;
    }

    private async tryGoNext() {
        if (this.hasLexicon) {
            return true;
        }
        return await this.openConfirmationDialog();
    }

    private async openConfirmationDialog() {
        return await this.dialogService.confirm({
            noHeader: true,
            messageKey:
                'Multilingual.Administration.LexiconStep.noLexiconConfirmationMessage',
            confirmButtonKey:
                'Multilingual.Administration.LexiconStep.continueWithoutLexicon',
        });
    }
}

import { NgModule } from '@angular/core';
import { EntityDashboardComponent } from './entity-dashboard/entity-dashboard.component';
import { SharedModule } from '../shared/shared.module';
import { DxyEntityPanelsModule } from '../entity-panels/DxyEntityPanelsModule';
import { DxySharedUiModule } from '../shared/shared-ui/DxySharedUiModule';
import { DxySharedEntityModule } from '../shared/entity/DxySharedEntityModule';
import { SharedDiagramModule } from '../diagrams/shared-diagram.module';
import { MultilingualFeatureModule } from '@datagalaxy/webclient/multilingual/feature';

@NgModule({
    imports: [
        SharedModule,
        DxyEntityPanelsModule,
        DxySharedEntityModule,
        DxySharedUiModule,
        SharedDiagramModule,
        MultilingualFeatureModule,
    ],
    declarations: [EntityDashboardComponent],
})
export class DxyEntityDashboardModule {}

import {
    deserialize,
    deserializeAs,
    inheritSerialization,
    serialize,
    serializeAs,
} from 'cerialize';
import {
    BaseServiceParameter,
    BaseServiceResult,
} from '@datagalaxy/data-access';
import {
    BaseUserServiceParameter,
    UserSpaceAccessType,
} from '@datagalaxy/webclient/user/data-access';
import { SecurityRole } from './security';
import { ObjectAccessType } from './ObjectAccessType.enum';
import { UserAccess } from './user-access';
import { ItemAccess } from './item-access';
import { SpaceAccess } from './space-access';
import { DataGalaxyModule } from '@datagalaxy/shared/dg-module/domain';

export class GetObjectSecurityParameterItem {
    @serialize public Module?: DataGalaxyModule;
    @serialize public DataReferenceId?: string;
    @serialize public SecurityPrincipalId?: string;
    @serialize public WithChildrenObjects?: boolean;
    @serialize public IncludeNoAccessData?: boolean;
}

@inheritSerialization(BaseServiceParameter)
export class GetObjectSecurityParameter extends BaseServiceParameter {
    @serializeAs(GetObjectSecurityParameterItem)
    public Items: GetObjectSecurityParameterItem[] = [];
}

export class GetObjectSecurityResultObjectPrincipalItem {
    @deserialize public LocalRoleId!: string;
    @deserialize public PrincipalId!: string;
}

export class GetObjectSecurityResultObjectItem {
    @deserialize public DataReferenceId!: string;
    @deserialize public DataTypeName!: string;
    @deserialize public DisplayName!: string;
    @deserializeAs(DataGalaxyModule) public Module?: DataGalaxyModule;
    @deserializeAs(GetObjectSecurityResultObjectPrincipalItem)
    public PrincipalItems: GetObjectSecurityResultObjectPrincipalItem[] = [];
}

export class GetObjectSecurityResultItem {
    @deserializeAs(GetObjectSecurityResultObjectItem)
    public ObjectItem: GetObjectSecurityResultObjectItem =
        new GetObjectSecurityResultObjectItem();
    @deserializeAs(GetObjectSecurityResultObjectItem)
    public ChildrenItems: GetObjectSecurityResultObjectItem[] = [];
    @deserialize public IsObjectAccessDenied!: boolean;
}

@inheritSerialization(BaseServiceResult)
export class GetObjectSecurityResult extends BaseServiceResult {
    @deserializeAs(GetObjectSecurityResultItem)
    public Items: GetObjectSecurityResultItem[] = [];
}

@inheritSerialization(BaseServiceParameter)
export class SetObjectSecurityParameter extends BaseServiceParameter {
    @serializeAs(SetObjectSecurityParameter)
    public Items: SetObjectSecurityParameterItem[] = [];
}

export class SetObjectSecurityParameterItem {
    @serialize public SecurityPrincipalId?: string;
    @serialize public DataReferenceId?: string;
    @serialize public Module?: DataGalaxyModule;
    @serialize public SecurityRoleId?: string;
}

@inheritSerialization(BaseServiceResult)
export class SetObjectSecurityResult extends BaseServiceResult {}

@inheritSerialization(BaseServiceParameter)
export class GetUserSecurityResult extends BaseServiceResult {
    @deserializeAs(SecurityRole) public Roles!: SecurityRole[];
}

@inheritSerialization(BaseUserServiceParameter)
export class SetUserSecurityParameter extends BaseUserServiceParameter {
    @serialize public IsClientAdmin: boolean;
    @serialize public IsTechnicalAdmin?: boolean;

    constructor(userId: string, isClientAdmin: boolean) {
        super(userId);
        this.IsClientAdmin = isClientAdmin;
    }
}

@inheritSerialization(BaseServiceResult)
export class SetUserSecurityResult extends BaseServiceResult {}

export interface SetUserSpaceSecurityCommand {
    AccessType: UserSpaceAccessType;
}

export interface SetUserModuleSecurityCommand {
    ReferenceId: string;
    AccessType: ObjectAccessType;
}

export interface GetObjectUsersAccessResult {
    UsersAccess: UserAccess[];
}

export interface GetUserModulesAccessResult {
    Items: ItemAccess[];
}

export interface GetUserSpaceAccessResult {
    SpaceAccess: SpaceAccess[];
}

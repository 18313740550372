import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { DxySharedUiModule } from '../shared/shared-ui/DxySharedUiModule';
import { DgEventLogItemComponent } from './dg-event-log-item/dg-event-log-item.component';
import { TeamNavLinkComponent } from './nav-links/team-nav-link/team-nav-link.component';
import { AttributesUpdatesComponent } from './nav-links/attributes-updates/attributes-updates.component';
import { EntityNavLinkComponent } from './nav-links/entity-nav-link/entity-nav-link.component';
import { NotificationButtonComponent } from './nav-links/notification-button/notification-button.component';
import { TaskNavLinkComponent } from './nav-links/task-nav-link/task-nav-link.component';
import { CoreUiModule } from '@datagalaxy/core-ui';
import { CampaignNavLinkComponent } from './nav-links/campaign-nav-link/campaign-nav-link.component';
import { MsTeamsConnectMeButtonComponent } from './nav-links/ms-teams-connect-me-button/ms-teams-connect-me-button.component';
import { MsTeamsActivateIntegrationButtonComponent } from './nav-links/ms-teams-activate-integration-button/ms-teams-activate-integration-button.component';
import { SlackConnectMeButtonComponent } from './nav-links/slack-connect-me-button/slack-connect-me-button.component';
import { SlackActivateIntegrationButtonComponent } from './nav-links/slack-activate-integration-button/slack-activate-integration-button.component';
import { NavLinkModuleComponent } from './nav-links/module/nav-link-module.component';

@NgModule({
    imports: [SharedModule, DxySharedUiModule, CoreUiModule],
    declarations: [
        DgEventLogItemComponent,
        TeamNavLinkComponent,
        EntityNavLinkComponent,
        TaskNavLinkComponent,
        NotificationButtonComponent,
        AttributesUpdatesComponent,
        CampaignNavLinkComponent,
        MsTeamsConnectMeButtonComponent,
        MsTeamsActivateIntegrationButtonComponent,
        SlackConnectMeButtonComponent,
        SlackActivateIntegrationButtonComponent,
        NavLinkModuleComponent,
    ],
    exports: [
        DgEventLogItemComponent,
        TeamNavLinkComponent,
        EntityNavLinkComponent,
        TaskNavLinkComponent,
        NotificationButtonComponent,
        AttributesUpdatesComponent,
        CampaignNavLinkComponent,
        MsTeamsConnectMeButtonComponent,
        MsTeamsActivateIntegrationButtonComponent,
        SlackConnectMeButtonComponent,
        SlackActivateIntegrationButtonComponent,
        NavLinkModuleComponent,
    ],
})
export class DgEventTranslationModule {}

import { NgModule } from '@angular/core';
import { CoreUiModule } from '../../core-ui.module';
import { DxyCaptionPositionSelectorComponent } from './caption-position-selector/caption-position-selector.component';

@NgModule({
    imports: [CoreUiModule],
    declarations: [DxyCaptionPositionSelectorComponent],
    exports: [DxyCaptionPositionSelectorComponent],
})
export class DxyCaptionPositionModule {}

import { NgModule } from '@angular/core';
import { UIRouterModule } from '@uirouter/angular';
import { CoreUiModule } from '@datagalaxy/core-ui';
import { DirectivesModule } from '../directives/directives.module';
import { SharedPipesModule } from './pipes/shared-pipes.module';

/**
 * This module is meant to be included in every app feature module.
 */
@NgModule({
    providers: [],
    imports: [
        CoreUiModule,
        DirectivesModule,
        SharedPipesModule,
        UIRouterModule,
    ],
    exports: [
        CoreUiModule,
        DirectivesModule,
        SharedPipesModule,
        UIRouterModule,
    ],
})
export class SharedModule {}

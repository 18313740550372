import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { GraphicalItem } from '../models/GraphicalItem';
import { BaseDpMappingItemComponent } from '../helpers/BaseDpMappingItemComponent';
import { DpMappingUiService } from '../dpMappingUi.service';
import { GraphicalEntityData } from '../models/GraphicalEntityData';
import { TNodeType } from '../dp-mapping.types';

/**
 * ## Role
 * Sub-item of a Mapped DPI in the Data-Processing Mapping UI
 * (i.e., sub-item of an app-dp-implem-element)
 */
@Component({
    selector: 'app-dp-mapping-item',
    templateUrl: 'dp-mapping-item.component.html',
})
export class DpMappingItemComponent
    extends BaseDpMappingItemComponent
    implements OnInit
{
    @Input() linkedItem: GraphicalItem;
    @Input() type: TNodeType;
    @Input() override virtual: boolean;

    protected itemId: string;
    protected data: GraphicalEntityData;

    constructor(
        elementRef: ElementRef<HTMLElement>,
        dpMappingUiService: DpMappingUiService
    ) {
        super(elementRef, dpMappingUiService);
    }

    ngOnInit() {
        this.itemId = this.linkedItem.id;
        this.data = this.linkedItem.data;
    }
}

import {
    AfterViewChecked,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    NgZone,
    Optional,
    Output,
    Self,
    ViewChild,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { IMultiSelectData } from '../../components';
import { DxyMultiSelectFieldControlComponent } from '../controls/multi-select-field-control/multi-select-field-control.component';
import { IMultiSelectFieldSelectedItemClickEvent } from '../controls/multi-select-field-control/multi-select-field-control.types';
import {
    DxyBaseFocusableFieldComponent,
    IBaseField,
} from '@datagalaxy/ui/fields';

@Component({
    selector: 'dxy-field-multi-select',
    templateUrl: 'field-multi-select.component.html',
    styleUrls: ['field-multi-select.component.scss'],
})
export class DxyFieldMultiSelectComponent<T>
    extends DxyBaseFocusableFieldComponent<T[]>
    implements IBaseField, AfterViewChecked
{
    @Input() data: IMultiSelectData<T>;

    @Input() maxDisplayedOptions?: number;
    @Input() openMenuOnFocus: boolean;
    /** If true, the mat-menu will take the same width as the field control */
    @Input() menuTakeFullWidth: boolean;

    //#region multiSelectData inputs binding
    /** Specifies if the option is a defaultValue */
    @Input() getDefaultValue?: (option: T) => boolean;
    /** Method to toggle an option's defaultValue property.
     * This must return a boolean to specify if the action is allowed */
    @Input() setDefaultValue?: (option: T, isDefault: boolean) => boolean;
    @Input() menuHasDynamicHeight?: boolean;
    //#endregion

    @Output() selectOption = new EventEmitter<T>();
    @Output() selectAll = new EventEmitter();
    /** Emits when the menu panel is opened or closed, a value of *true* meaning opened */
    @Output() openClose = new EventEmitter<boolean>();
    @Output() selectedItemClick = new EventEmitter<
        IMultiSelectFieldSelectedItemClickEvent<T>
    >();

    @ViewChild(DxyMultiSelectFieldControlComponent)
    fieldControl: DxyMultiSelectFieldControlComponent<T>;

    //#region API
    public get hasOpenPanel() {
        return this._isOpen;
    }
    public refreshUi() {
        this.fieldControl?.refreshUi();
    }
    public triggerSearch() {
        return this.fieldControl?.triggerSearch();
    }
    //#endregion

    public get label() {
        return this.getLabel(this.translate);
    }
    public get labelTooltip() {
        return this.getLabelTooltip(this.translate);
    }
    public get errorMessage() {
        return this.getErrorMessage(this.translate);
    }

    private _isOpen: boolean;

    constructor(
        private changeDetector: ChangeDetectorRef,
        private translate: TranslateService,
        elementRef: ElementRef<HTMLElement>,
        ngZone: NgZone,
        @Optional() @Self() ngControl: NgControl
    ) {
        super(elementRef, ngZone, ngControl);
    }

    ngAfterViewChecked() {
        // because of the dxy-multi-select
        this.preventNg0100Error(this.changeDetector);
    }

    public onMenuToggle(isOpen: boolean) {
        this.openClose.emit((this._isOpen = isOpen));
        if (isOpen) {
            // prevent possible infinite loop at menu opening when used in an attribute multi select input
            // archi-attribute-inputs(revi) To be removed when unitary field validator is refactored
            this.focus.emit();
            setTimeout(() => {
                this.fieldControl?.focusSearch(), 333;
            });
        }
    }

    public onSelectedItemClick(
        event: IMultiSelectFieldSelectedItemClickEvent<T>
    ) {
        this.selectedItemClick.emit(event);
    }

    public override writeValue(value: T[]): void {
        super.writeValue(value);
        if (!this.data) {
            return;
        }
        this.data.selectedItems = value;
    }
}

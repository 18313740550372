import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { LineageTreeNode } from '../lineage-tree/lineage-tree-node';
import { DxySharedEntityModule } from '../../shared/entity/DxySharedEntityModule';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { IActionOption, ITabItem } from '@datagalaxy/core-ui';
import { EntityType, IHasHddData } from '@datagalaxy/dg-object-model';
import { EntityIdentifier } from '@datagalaxy/webclient/entity/utils';
import { DataProcessingUiService } from '../../data-processing/services/data-processing-ui.service';
import { EntityPreviewPanelService } from '../../shared/entity/services/entity-preview-panel.service';
import { EntityCardModule } from '../../shared/entityCard/entity-card.module';
import { NgForOf, NgIf } from '@angular/common';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { LineageItemPickerItemComponent } from './lineage-item-picker-item/lineage-item-picker-item.component';
import {
    CdkFixedSizeVirtualScroll,
    CdkVirtualForOf,
    ScrollingModule,
} from '@angular/cdk/scrolling';
import { LineageNodeStreamDirection } from '../lineage-entity-stream-buttons/lineage-entity-stream.types';
import { TranslateModule } from '@ngx-translate/core';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { SharedModule } from '../../shared/shared.module';
import { StringUtil } from '@datagalaxy/core-util';
import { ViewTypeService } from '../../services/viewType.service';
import { LineageEntityStreamUtils } from '../lineage-entity-stream-buttons/lineage-entity-stream.utils';
import { LineageGraphService } from '../lineage-graph/lineage-graph.service';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';

enum LineageStream {
    None = 0,
    Both,
    Upstream,
    Downstream,
}

@Component({
    standalone: true,
    selector: 'app-lineage-item-picker',
    templateUrl: './lineage-item-picker.component.html',
    styleUrls: ['./lineage-item-picker.component.scss'],
    imports: [
        DxySharedEntityModule,
        EntityCardModule,
        NgForOf,
        MatLegacyButtonModule,
        NgIf,
        LineageItemPickerItemComponent,
        CdkFixedSizeVirtualScroll,
        CdkVirtualForOf,
        ScrollingModule,
        MatSlideToggleModule,
        TranslateModule,
        SharedModule,
    ],
})
export class LineageItemPickerComponent
    extends DxyBaseComponent
    implements OnChanges
{
    @Input() node: LineageTreeNode;

    protected hideNoLinkItems = true;
    protected headerTabs: ITabItem[] = [
        {
            tabId: 'itemPicker',
            tabTranslateKey: 'UI.ImpactAnalysis.lineage.itemPicker.title',
        },
    ];
    protected actions: IActionOption<Partial<IHasHddData>>[] = [
        {
            callback: (entity: IHasHddData) => this.openPreviewPane(entity),
            glyphClass: 'glyph-object-preview',
            tooltipTranslateKey: 'UI.Search.resultActionTooltip.openPane',
            hidden: (entity: IHasHddData) => !entity.HddData.Data.HasReadAccess,
        },
        {
            glyphClass: 'glyph-show',
            tooltipTranslateKey:
                'UI.ImpactAnalysis.lineage.itemPicker.button.show',
            hidden: (entity: IHasHddData) => {
                const found = this.node.find(entity.HddData.ReferenceId);

                return !found?.visible;
            },
            callback: (entity) => {
                this.lineageGraphService.toggleNodeVisibility(
                    entity.HddData.ReferenceId,
                    true
                );
            },
        },
        {
            glyphClass: 'glyph-hide',
            tooltipTranslateKey:
                'UI.ImpactAnalysis.lineage.itemPicker.button.hide',
            alwaysVisible: true,
            hidden: (entity: IHasHddData) => {
                const found = this.node.find(entity.HddData.ReferenceId);

                return found?.visible;
            },
            callback: (entity) =>
                this.lineageGraphService.toggleNodeVisibility(
                    entity.HddData.ReferenceId,
                    true
                ),
        },
        {
            glyphClass: (entity: IHasHddData) => {
                const stream = this.hasLinks(entity);

                switch (stream) {
                    case LineageStream.Both:
                        return 'glyph-direction-both';
                    case LineageStream.Upstream:
                        return 'glyph-direction-before';
                    case LineageStream.Downstream:
                        return 'glyph-direction-after';
                    default:
                        return '';
                }
            },
            tooltipTranslateKey: (entity: IHasHddData) => {
                const stream = this.hasLinks(entity);
                let suffix = '';

                switch (stream) {
                    case LineageStream.Both:
                        suffix = 'both';
                        break;
                    case LineageStream.Upstream:
                        suffix = 'upstream';
                        break;
                    case LineageStream.Downstream:
                        suffix = 'downstream';
                        break;
                    default:
                        return '';
                }
                return `UI.ImpactAnalysis.lineage.itemPicker.button.${suffix}`;
            },
            alwaysVisible: true,
            hidden: (entity: IHasHddData) => {
                const stream = this.hasLinks(entity);

                return stream === LineageStream.None;
            },
        },
    ];

    protected nodes: LineageTreeNode[] = [];
    protected expandMap: Map<string, boolean>;

    protected get baseDepth() {
        return this.node.getDepth();
    }

    constructor(
        private dataProcessingUiService: DataProcessingUiService,
        private previewPanel: EntityPreviewPanelService,
        private viewTypeService: ViewTypeService,
        private lineageGraphService: LineageGraphService
    ) {
        super();
    }

    ngOnChanges(changes: SimpleChanges) {
        super.onChange(
            changes,
            'node',
            (currentValue: LineageTreeNode) => this.onNodeChange(currentValue),
            true
        );
    }

    protected onHideNoLinkItemsChange(hide: boolean) {
        this.hideNoLinkItems = hide;

        const nodes = this.node.getAllChildren();
        this.nodes = nodes.filter((node) => this.displayNode(node));
    }

    protected onSearchTermChange(searchTerm: string) {
        const nodes = StringUtil.filterSearched(
            searchTerm,
            this.node.getAllChildren(),
            (node) => {
                const fn = (node: LineageTreeNode) => {
                    if (!node) {
                        return '';
                    }
                    const entity = node.entityIdentifier as EntityItem;
                    const childrenNames = node.children.map((child) =>
                        fn(child)
                    );

                    const name = this.viewTypeService.isFunctionalView
                        ? entity.HddData.DisplayName
                        : entity.HddData.TechnicalName;

                    return name.concat(childrenNames.join(''));
                };

                return fn(node);
            }
        );
        this.nodes = nodes.filter((node) => this.displayNode(node));
    }

    protected isOpen(node: LineageTreeNode) {
        return this.expandMap.get(node.id);
    }

    protected onToggle(node: LineageTreeNode) {
        this.expandMap.set(node.id, !this.expandMap.get(node.id));

        const nodes = this.node.getAllChildren();
        this.nodes = nodes.filter((node) => this.displayNode(node));
    }

    protected displayNode(node: LineageTreeNode) {
        const fn = (node: LineageTreeNode) => {
            return (
                node.links.length > 0 ||
                node.children.some((child) => fn(child))
            );
        };
        const display = fn(node) || !this.hideNoLinkItems;
        if (!this.expandMap.has(node.parent.id)) {
            return display;
        }
        return display && this.expandMap.get(node.parent.id);
    }

    private onNodeChange(node: LineageTreeNode) {
        const nodes = node.getAllChildren();
        const hasLinkedNodes = nodes.some((node) => node.links.length > 0);

        this.hideNoLinkItems = hasLinkedNodes;
        this.expandMap = new Map(nodes.map((node) => [node.id, false]));

        this.nodes = nodes.filter((node) => this.displayNode(node));
    }

    private async openPreviewPane(entity: IHasHddData) {
        const entityIdr = EntityIdentifier.fromIHierarchicalData(
            entity.HddData
        );
        if (entityIdr.entityType === EntityType.DataProcessingItem) {
            return this.dataProcessingUiService.showDpiEditModalFromEntityIdr(
                entityIdr
            );
        } else {
            return this.previewPanel.setupPanel({ entityIdr });
        }
    }

    private hasLinks(entity: IHasHddData) {
        const found = this.node.find(entity.HddData.ReferenceId);

        const upstreamGroup = LineageEntityStreamUtils.makeStreamLinkGroup(
            found.links,
            LineageNodeStreamDirection.Upstream
        );

        const downstreamGroup = LineageEntityStreamUtils.makeStreamLinkGroup(
            found.links,
            LineageNodeStreamDirection.Downstream
        );

        if (upstreamGroup?.length && downstreamGroup?.length) {
            return LineageStream.Both;
        } else if (upstreamGroup?.length) {
            return LineageStream.Upstream;
        } else if (downstreamGroup?.length) {
            return LineageStream.Downstream;
        }

        return LineageStream.None;
    }
}

import { Injectable } from '@angular/core';
import { BaseStateService } from '@datagalaxy/utils';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';

export interface LineageLeftToolbarPersistedState {
    displayDataProcessingItems: boolean;
}

export interface LineageLeftToolbarState {
    persistedState: LineageLeftToolbarPersistedState;
    entity: EntityItem;
}

@Injectable()
export class LineageLeftToolbarStore extends BaseStateService<LineageLeftToolbarState> {
    constructor() {
        super({
            persistedState: null,
            entity: null,
        });
    }

    public dispatchUpdatePersistedState(
        state: LineageLeftToolbarPersistedState
    ) {
        this.setState({
            persistedState: {
                ...this.state.persistedState,
                ...state,
            },
        });
    }

    public dispatchLoadEntityMenu(entity: EntityItem) {
        this.setState({ entity });
    }

    public selectState() {
        return this.select((state) => state);
    }
}

import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnInit,
} from '@angular/core';
import { IListOption, IOptionCategory } from '@datagalaxy/core-ui';
import { NavigationService } from '../../services/navigation.service';
import { AppSpaceService } from '../../services/AppSpace.service';
import { AppDataService } from '../../services/app-data.service';
import { CurrentSpaceService } from '../../services/currentSpace.service';
import { FilteredViewService } from '../../shared/filter/services/filteredView.service';
import { ISpaceSelectorTrackerIds } from '../../space-selector/space-selector.types';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { ModuleStore } from '../../module/module.store';
import { map, Observable } from 'rxjs';
import {
    DgModule,
    DgModuleDefinition,
    DiagramDgModule,
} from '@datagalaxy/shared/dg-module/domain';
import { tap } from 'rxjs/operators';
import { ISpaceIdentifier } from '@datagalaxy/webclient/workspace/domain';
import { FilteredViewDto } from '@datagalaxy/webclient/filter/domain';
import { DgZone } from '@datagalaxy/webclient/domain';

/**
 * ## Role
 * Top Navbar Breadcrumb
 *
 * ## Features
 * - Display current client, workspace & module
 * - Navigate to any workspace
 * - Navigate to any module
 */
@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'dxy-navbar-breadcrumb',
    templateUrl: './dxy-navbar-breadcrumb.component.html',
    styleUrls: ['./dxy-navbar-breadcrumb.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DxyNavbarBreadcrumbComponent
    extends DxyBaseComponent
    implements OnInit
{
    @Input() dgZone: DgZone;
    @Input() currentViewTitle: string;
    @Input() showSpaceDropdown: boolean;
    @Input() showVersionDropdown: boolean;
    @Input() isSpaceSelectable: boolean;
    @Input() isVersionSelectable: boolean;
    @Input() showCurrentClientName: boolean;

    protected moduleOptions$: Observable<IListOption<DgModuleDefinition>[]>;

    public readonly moduleCategories: IOptionCategory[] = [
        'dataKnowledgeCatalog',
        'dataKnowledgeStudio',
    ].map((key) => ({
        key,
        translateKey: `UI.NavBreadcrumb.moduleCategories.${key}`,
    }));

    public selectedModuleOption: IListOption<DgModuleDefinition>;
    public readonly filteredViewCategories: IOptionCategory[] = [
        { key: 'Public', translateKey: 'UI.Filter.sharedFilterViews' },
        { key: 'Private', translateKey: 'UI.Filter.myFilterViews' },
    ];
    public filteredViewOptions: IListOption<FilteredViewDto>[] = [];
    public selectedFilteredViewOption: IListOption<FilteredViewDto>;
    public spaceSelectorTrackerIds: ISpaceSelectorTrackerIds = {
        spaceSelectedText: 'Breadcrumb-Workspace-Landing',
        spaceDropdownCaret: 'Breadcrumb-Workspace-DropDrown',
        versionSelectedText: 'Breadcrumb-Version-Landing',
        versionDropdownCaret: 'Breadcrumb-Version-DropDrown',
    };

    public get clientName() {
        return this.appDataService.clientName;
    }

    public get isClientNameVisible() {
        return (
            this.showCurrentClientName &&
            this.clientName &&
            !this.appSpaceService.isSingleWorkspace()
        );
    }

    public get isSpaceSelectorVisible() {
        return this.showSpaceDropdown || this.showVersionDropdown;
    }

    public get isModuleVisible() {
        const currentModule = this.navigationService.getCurrentModule();
        return (
            !this.currentSpaceService.getCurrentSpace()?.isOrga &&
            !!currentModule
        );
    }

    public get isFilteredViewSelectorVisible() {
        return !!this.selectedFilteredViewOption;
    }

    public get showSpaceSelectorSeparator() {
        return this.isSpaceSelectorVisible && this.isClientNameVisible;
    }

    public get showModuleSeparator() {
        return (
            this.isModuleVisible &&
            (this.isClientNameVisible || this.isSpaceSelectorVisible)
        );
    }

    public get showFilteredViewSelectorSeparator() {
        return (
            this.isFilteredViewSelectorVisible &&
            (this.isClientNameVisible ||
                this.isModuleVisible ||
                this.isSpaceSelectorVisible)
        );
    }

    public get showTitleSeparator() {
        return (
            !!this.currentViewTitle &&
            (this.isClientNameVisible ||
                this.isModuleVisible ||
                this.isSpaceSelectorVisible ||
                this.isFilteredViewSelectorVisible)
        );
    }

    private isSpaceVersionInitialized: boolean;

    constructor(
        private currentSpaceService: CurrentSpaceService,
        private navigationService: NavigationService,
        private appSpaceService: AppSpaceService,
        private filteredViewService: FilteredViewService,
        private appDataService: AppDataService,
        private moduleStore: ModuleStore
    ) {
        super();
    }

    ngOnInit() {
        this.init().then();
        super.subscribe(this.filteredViewService.currentViewChanged$, (e) =>
            this.setSelectedFilteredViewOption(e.dgZone)
        );
        super.subscribe(this.filteredViewService.viewCRUD$, (e) =>
            this.onFilteredViewCRUD(e.dgZone).then()
        );

        this.moduleOptions$ = this.moduleStore.selectModules().pipe(
            map((modules) => this.loadModuleOptions(modules)),
            tap((options) => {
                this.selectCurrentModule(options);
            })
        );
    }

    public async onSpaceVersionSelected(spaceIdr: ISpaceIdentifier) {
        this.log(
            'onSpaceVersionSelected',
            this.isSpaceVersionInitialized,
            spaceIdr?.spaceId,
            spaceIdr?.versionId
        );

        if (!this.isSpaceVersionInitialized) {
            this.isSpaceVersionInitialized = true;
            return;
        }

        if (this.currentSpaceService.isCurrentSpaceAndVersion(spaceIdr)) {
            return;
        }
        await this.navigationService.goToSpaceHome(spaceIdr);
    }

    public async onSpaceVersionClick() {
        this.log('onSpaceVersionClick');
        await this.navigationService.goToSpaceHome(
            this.currentSpaceService.getCurrentSpace()
        );
    }

    public async onModuleChange(option: IListOption) {
        const space =
            await this.appSpaceService.getCurrentSpaceSetCurrentIfSingle();
        this.log('onModuleChange', option, space);
        const dgModuleDefinition = option.data as DgModuleDefinition;
        const dgModule = DgModule[dgModuleDefinition.name];
        return await this.navigationService.goToModule(dgModule, space);
    }

    public async onSelectedModuleOptionClick() {
        const dgModuleDefinition = this.selectedModuleOption.data;
        const dgModule = DgModule[dgModuleDefinition.name];

        this.log('onSelectedModuleOptionClick', dgModule);
        const space =
            await this.appSpaceService.getCurrentSpaceSetCurrentIfSingle();
        return await this.navigationService.reloadModuleActiveView(
            dgModule,
            space,
            true
        );
    }

    public onFilteredViewSelected(option: IListOption) {
        this.log('onFilteredViewSelected', option);
        this.filteredViewService.setCurrentFilteredView(
            option.data as FilteredViewDto
        );
    }

    //#endregion

    private async init() {
        const currentModule = this.navigationService.getCurrentModule();
        const currentSpace = this.currentSpaceService.getCurrentSpace();

        if (currentModule && currentSpace) {
            await this.setupFilteredViews(currentSpace, currentModule);
        }
        this.log(
            'init',
            DgZone[this.dgZone],
            DgModule[currentModule],
            currentSpace
        );
    }

    private selectCurrentModule(options: IListOption<DgModuleDefinition>[]) {
        const currentModule = this.navigationService.getCurrentModule();
        if (currentModule) {
            this.selectedModuleOption = options.find(
                (option) => DgModule[option.data.name] === currentModule
            );
        }
    }

    private loadModuleOptions(
        modules: DgModuleDefinition[]
    ): IListOption<DgModuleDefinition>[] {
        const moduleOptions = modules.map((module) => ({
            categoryKey: module.categoryKey,
            glyphClass: module.coloredGlyphClass,
            labelKey: module.translateKey,
            data: module,
        }));
        const diagramDgModule = new DiagramDgModule();
        moduleOptions.push({
            categoryKey: diagramDgModule.categoryKey,
            glyphClass: diagramDgModule.coloredGlyphClass,
            labelKey: diagramDgModule.translateKey,
            data: diagramDgModule,
        });

        return moduleOptions;
    }

    private async setupFilteredViews(
        spaceIdr: ISpaceIdentifier,
        module: DgModule
    ) {
        const fvs = await this.filteredViewService.getFilteredViews(
            this.dgZone,
            spaceIdr,
            module
        );
        this.log('setupFilteredViews-getFilteredViews-result', fvs);
        this.filteredViewOptions = fvs.map((fv) =>
            this.setupFilteredViewOption(fv)
        );
        this.setSelectedFilteredViewOption(this.dgZone);
    }

    private setupFilteredViewOption(
        fv: FilteredViewDto,
        option: IListOption<FilteredViewDto> = {}
    ) {
        option.data = fv;
        option.labelText = fv.DisplayName;
        option.categoryKey = fv.IsPrivate ? 'Private' : 'Public';
        return option;
    }

    private setSelectedFilteredViewOption(dgZone: DgZone) {
        const currentViewId =
            dgZone != this.dgZone
                ? null
                : this.filteredViewService.getCurrentViewId(this.dgZone);
        this.log(
            'setSelectedFilteredViewOption',
            DgZone[dgZone],
            currentViewId
        );
        this.selectedFilteredViewOption =
            currentViewId &&
            this.filteredViewOptions.find(
                (view) => view.data.FilteredViewId == currentViewId
            );
    }

    private async onFilteredViewCRUD(dgZone: DgZone) {
        if (dgZone != this.dgZone) {
            return;
        }
        this.log('onFilteredViewCRUD');
        await this.init();
    }
}

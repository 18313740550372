import { LineageTreeGraph } from './lineage-tree-graph';
import { LineageTreeNode } from '../lineage-tree/lineage-tree-node';
import { CollectionsHelper } from '@datagalaxy/core-util';

export class LineageGraphRootTracker {
    private rootDescendantPredecessors: Set<string>;
    private rootDescendantSuccessors: Set<string>;

    constructor(private treeGraph: LineageTreeGraph, private rootId: string) {}

    public update() {
        this.getRootDescendantRecursiveSuccessors();
        this.getRootDescendantRecursivePredecessors();

        const primaryIds = [
            ...this.rootDescendantPredecessors,
            ...this.rootDescendantSuccessors,
            this.rootId,
            ...this.getRootTreeNode()
                .getAllChildren()
                .map((child) => child.id),
        ];

        primaryIds.forEach((id) => {
            const node = this.treeGraph.getNode(id);

            if (!node) {
                return;
            }

            node.setPrimary();
        });
    }

    public getRootTreeNode(): LineageTreeNode | null {
        return this.treeGraph.getNode(this.rootId);
    }

    public areNodePredecessors(nodeId: string): boolean {
        return this.rootDescendantPredecessors.has(nodeId);
    }

    public areNodeSuccessors(nodeId: string): boolean {
        return this.rootDescendantSuccessors.has(nodeId);
    }

    public isPrimaryNode(nodeId: string) {
        return (
            this.rootDescendantPredecessors.has(nodeId) ||
            this.rootDescendantSuccessors.has(nodeId) ||
            this.rootId === nodeId ||
            this.getRootTreeNode()
                .getAllChildren()
                .some((child) => child.id === nodeId)
        );
    }

    private getRootDescendantRecursiveSuccessors() {
        const root = this.getRootTreeNode();

        if (!root) {
            return false;
        }

        const rootNodes = [root, ...root.getAllChildren()];

        const res = CollectionsHelper.distinctByProperty(
            rootNodes.flatMap((treeNode) =>
                this.treeGraph.getRecursiveSuccessors(treeNode.id)
            ),
            (node) => node.id
        ).map((node) => node.id);

        this.rootDescendantSuccessors = new Set(res);
    }

    private getRootDescendantRecursivePredecessors() {
        const root = this.getRootTreeNode();

        if (!root) {
            return false;
        }

        const rootNodes = [root, ...root.getAllChildren()];

        const res = CollectionsHelper.distinctByProperty(
            rootNodes.flatMap((treeNode) =>
                this.treeGraph.getRecursivePredecessors(treeNode.id)
            ),
            (node) => node.id
        ).map((node) => node.id);

        this.rootDescendantPredecessors = new Set(res);
    }
}

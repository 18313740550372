import { Injectable } from '@angular/core';
import { IOutputData, SplitComponent } from 'angular-split';
import { BaseService } from '@datagalaxy/core-ui';
import { UserService } from './user.service';
import { GetUserSettingCategoryValuesResult } from '@datagalaxy/webclient/user/data-access';
import { userSettingsValues } from '@datagalaxy/webclient/user/domain';

/** ## Role
 * Provide logic to synchronize *angular-split* UI component's area sizes and persisted user settings
 */
@Injectable({ providedIn: 'root' })
export class AngularSplitService extends BaseService {
    private readonly cache = new Map<string, IStoredData>();
    private gettingUserSettingData: Promise<GetUserSettingCategoryValuesResult>;

    constructor(private userService: UserService) {
        super();
        this.initAsync();
    }

    /** Returns the size for the split-area matching the given *persistenceId* and zero-based *areaIndex* */
    public getAreaSize(
        persistenceId: string,
        areaIndex: number,
        defaultValue: number
    ) {
        const data = this.cache.get(persistenceId);
        const size = data?.sizes[areaIndex] ?? defaultValue;
        return size as number; // as number to match split-area[size] wrong typing
    }

    /** For *DxyAngularSplitPersistDirective*.
     * - Applies area sizes previously stored in the user settings to the given angular-split component
     * - Tracks user action (split gutter dragged) and persists area sizes to the user settings.
     * */
    public register(angularSplitRef: SplitComponent, persistenceId: string) {
        const subscription = this.trackChanges(angularSplitRef, persistenceId);
        // unawaited on purpose: to return the subscription, to be compatible with DxyBaseDirective.registerSubscription
        this.setup(angularSplitRef, persistenceId);
        return subscription;
    }
    private trackChanges(
        angularSplitRef: SplitComponent,
        persistenceId: string
    ) {
        return angularSplitRef.dragEnd
            .asObservable()
            .subscribe((e) => this.store(angularSplitRef, persistenceId, e));
    }

    private async setup(
        angularSplitRef: SplitComponent,
        persistenceId: string
    ) {
        this.log('setup', !!angularSplitRef, persistenceId);

        const warn = (...args: any[]) => this.warn(...args, persistenceId);

        if (!angularSplitRef) {
            warn('angularSplitRef not provided');
            return;
        }
        if (!persistenceId) {
            warn('persistenceId not provided');
            return;
        }

        let data = this.cache.get(persistenceId);
        if (!data) {
            data = await this.getStoredData(persistenceId);
            this.cache.set(persistenceId, data);
        }
        if (!data) {
            return;
        }

        // prevent data mismatch if host component layout has been modified
        if (data.unit != angularSplitRef.unit) {
            warn('unit mismatch');
            return;
        }
        if (data.direction != angularSplitRef.direction) {
            warn('direction mismatch');
            return;
        }

        // not necessary if the host component uses getAreaSize(),
        // but we don't kwnow that here, and it does not harm.
        try {
            angularSplitRef.setVisibleAreaSizes(data.sizes);
        } catch (e) {
            warn(e);
        }
    }

    private async store(
        angularSplitRef: SplitComponent,
        persistenceId: string,
        e: IOutputData
    ) {
        const data: IStoredData = {
            sizes: e.sizes,
            unit: angularSplitRef.unit,
            direction: angularSplitRef.direction,
        };
        this.log('store', data);
        this.cache.set(persistenceId, data);
        return await this.userService.setUserSettingValue(
            userSettingsValues.angularSplit.category,
            persistenceId,
            JSON.stringify(data)
        );
    }

    private async getStoredData(persistenceId: string) {
        let data: IStoredData;
        try {
            const result = await this.userService.getUserSettingValue(
                userSettingsValues.angularSplit.category,
                persistenceId
            );
            data = JSON.parse(result?.Value ?? null);
        } catch (e) {
            this.warn(e);
        }
        this.log('getStoredData-result', data);
        return data;
    }

    private async initAsync() {
        // usefull for debugging
        // await this.clearAllPersisted()

        this.userService.onInit$.subscribe(() => this.initCache());
        await this.initCache();
    }
    private async clearAllPersisted(keepCache = false) {
        const data = await this.userService.getUserSettingCategoryValues(
            userSettingsValues.angularSplit.category
        );
        await Promise.all(
            data.Values.map((v) => this.clearPersisted(v.Id, keepCache))
        );
    }
    private async clearPersisted(persistenceId: string, keepCache = false) {
        if (!keepCache) {
            this.cache.delete(persistenceId);
        }
        await this.userService.deleteUserSettingValue(
            userSettingsValues.angularSplit.category,
            persistenceId
        );
    }

    private async initCache() {
        const result = await this.getUserSettingData();
        this.log('initCache', result);
        this.cache.clear();
        result?.Values?.forEach((v, i) => {
            try {
                this.cache.set(v.Id, JSON.parse(v.Value));
            } catch (e) {
                this.warn(e, v, i);
            }
        });
    }
    private async getUserSettingData() {
        if (this.gettingUserSettingData) {
            return this.gettingUserSettingData;
        }
        this.gettingUserSettingData =
            this.userService.getUserSettingCategoryValues(
                userSettingsValues.angularSplit.category
            );
        const data = await this.gettingUserSettingData;
        this.gettingUserSettingData = null;
        this.log('getUserSettingData-result', data);
        return data;
    }
}

interface IStoredData {
    unit: 'percent' | 'pixel';
    direction: 'horizontal' | 'vertical';
    sizes: (number | '*')[];
}

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnInit,
} from '@angular/core';
import { withLoading } from '@datagalaxy/core-ui';
import { WatchEntityApiService } from '../services/watch-entity-api.service';
import { DataUtil } from '../../util/DataUtil';
import { EntityEventService } from '../services/entity-event.service';
import {
    CrudActionType,
    CrudOperation,
    FunctionalLogService,
} from '@datagalaxy/webclient/monitoring/data-access';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';
import { DgModule } from '@datagalaxy/shared/dg-module/domain';

/**
 * ## Role
 * Button to watch/unwatch an entity
 * Watching an entity means get a notification every time a modification is made on entity
 */
@Component({
    selector: 'app-watch-entity-button',
    templateUrl: './watch-entity-button.component.html',
    styleUrls: ['./watch-entity-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WatchEntityButtonComponent
    extends DxyBaseComponent
    implements OnInit
{
    @Input() entityItem: EntityItem;

    protected get watching() {
        return this.entityItem.IsWatchedByCurrentUser;
    }
    private get entityReferenceId() {
        return this.entityItem.ReferenceId;
    }

    constructor(
        private watchEntityApiService: WatchEntityApiService,
        private entityEventService: EntityEventService,
        private functionalLogService: FunctionalLogService,
        private cd: ChangeDetectorRef
    ) {
        super();
    }
    ngOnInit() {
        super.subscribe(
            this.watchEntityApiService.getWatchUpdateObservable(
                this.entityReferenceId
            ),
            (watching) => this.onWatchUpdate(watching)
        );
    }

    @withLoading()
    protected async watch() {
        await this.watchEntityApiService.watchEntity(this.entityReferenceId);
        this.notifyEntityUpdate(true);
        this.addFunctionalLogEntry(CrudActionType.On);
    }

    @withLoading()
    protected async unwatch() {
        await this.watchEntityApiService.unwatchEntity(this.entityReferenceId);
        this.notifyEntityUpdate(false);
        this.addFunctionalLogEntry(CrudActionType.Off);
    }

    private addFunctionalLogEntry(actionType: CrudActionType) {
        const dgModule = DataUtil.getModuleFromEntityType(
            this.entityItem.EntityType
        );
        this.functionalLogService.logFunctionalAction(
            `WATCHED_${DgModule[dgModule].toUpperCase()}`,
            CrudOperation.U,
            actionType
        );
    }

    private onWatchUpdate(watching: boolean) {
        this.entityItem.IsWatchedByCurrentUser = watching;
        this.cd.detectChanges();
    }

    private notifyEntityUpdate(watched: boolean) {
        this.entityItem.IsWatchedByCurrentUser = watched;
        if (!this.entityItem.Attributes) {
            return;
        }
        this.entityItem.Attributes['IsWatchedByCurrentUser'] = watched;
        this.entityEventService.notifyEntityUpdate(this.entityItem);
    }
}

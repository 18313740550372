import { NgModule } from '@angular/core';
import { NumberFormatPipe } from './number-format.pipe';
import { OrderByPipe } from './order-by.pipe';
import { DateFormatPipe } from './date-format.pipe';

@NgModule({
    declarations: [NumberFormatPipe, OrderByPipe, DateFormatPipe],
    exports: [NumberFormatPipe, OrderByPipe, DateFormatPipe],
})
export class CorePipesModule {}

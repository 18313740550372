import { NgModule } from '@angular/core';
import { DxyRichTextModule } from '@datagalaxy/core-ui/rich-text';
import { DxyMentionComponent } from './mentions/dxy-mention/dxy-mention.component';
import { DxyFieldsModule } from '@datagalaxy/core-ui/fields';
import { SharedModule } from '../shared.module';
import { DxySharedUiModule } from '../shared-ui/DxySharedUiModule';

@NgModule({
    imports: [
        SharedModule,
        DxyFieldsModule, // for field-text in DxyMentionUserModalComponent
        DxyRichTextModule,
        DxySharedUiModule,
    ],
    declarations: [
        //#region Only used by MentionService, no need to export
        DxyMentionComponent,
        //#endregion
    ],
    exports: [DxyRichTextModule],
})
export class RichTextModule {}

import { Type } from '@angular/core';
import {
    Column,
    CsvExportParams,
    ExcelExportParams,
    GridApi,
    ICellRendererComp,
    RowNode,
} from 'ag-grid-community';
import { ICellComponent } from '../cell-components';
import { BaseCellComponent } from '../cell-components/BaseCellComponent';
import { IOmniGridActionDef } from './IOmniGridActionDef';
import { IDragDropConfig } from '../DragDropConfig';

//#region datagroups

export interface IDisplayedDataGroup extends IDataGroup {
    displayName?: string;
    glyphClassName?: string;
}
export interface IDisplayedDataGroupOf<T extends object>
    extends IDataGroupOf<T> {
    displayName?: string;
    glyphClassName?: string;
}
export interface IDataGroupOf<T extends object> extends IDataGroup {
    objects: T[];
}
export interface IDataGroup {
    key: string;
    objects: object[];
    count: number;
}
export class DataGroup<T extends object>
    implements IDataGroupOf<T>, IDisplayedDataGroupOf<T>
{
    get count(): number {
        return this._objects?.length ?? 0;
    }
    get objects(): T[] {
        return this._objects ?? [];
    }

    constructor(
        public key: string,
        private _objects: T[] = [],
        public displayName?: string,
        public glyphClassName?: string
    ) {
        this.displayName = displayName;
    }
}

//#endregion

/** same as in DgServerTypes */
export interface ILoadMultiResult<TEntity> {
    IsSuccess: boolean;
    Entities: TEntity[];
    TotalCount?: number;
    StartIndex?: number;
    Size?: number;
}

export interface IOmniGridColumnDef<
    TEntity = any,
    TValue = any,
    TCellParams = any
> {
    field: string;
    headerName?: string;
    width?: number;
    minWidth?: number;
    maxWidth?: number;
    lockPosition?: boolean;
    resizable?: boolean;
    sortable?: boolean;
    enableRowGroup?: boolean;
    suppressMovable?: boolean;
    hide?: boolean;
    cellClass?: string;
    cellStyle?: {};
    colId?: string;
    valueGetter?: (params: any) => any;
    tooltipValueGetter?: string | ((params: any) => string);

    cellRendererFramework?: Type<BaseCellComponent<TEntity, TValue>>;
    /** Note: to specify an angular component, use the *cellRendererFramework* property instead */
    cellRenderer?: IOmniGridCellRenderer<TEntity, TValue>;
    cellRendererParams?: TCellParams; // With cell components cellRendererParams could be any type
    comparator?: TRowNodeComparator;
}

export interface IOmniGridOptions {
    /** If true then the grid will be full height, making all rows visible
     * If false then the grid fills it's parent element (may depend on flex layout) */
    autoHeight?: boolean;

    rowSelection?: 'single' | 'multiple';
    /**
     * If true, the css style on row focus will not be applied and the mouse cursor
     * will be the default one
     */
    disableRowSelection?: boolean;

    /**
     * rows height. applied css (height, line-height) will have to conform to this
     * Note this may be overridden by getRowHeight
     */
    rowHeight?: number;

    /** function to get row height dynamically */
    getRowHeight?: (params: IOmniGridRowHeightParams) => number;
    /** columns headers height. applied css (height, line-height) will have to conform to this */
    headerHeight?: number;
    /** group row height. applied css (height, line-height) will have to conform to this */
    groupRowHeight?: number;

    groupHeaderClass?: string;
    showNoResults?: boolean;
    animateRows?: boolean;
    noResultText?: string;
    loadingText?: string;

    canRemoveColumnByDragging?: boolean;

    useGroupRowDefaultIcon?: boolean;
    groupCaretPosition?: 'before' | 'after';

    enableCellTextSelection?: boolean;

    /** when true, tooltips are added to header cells */
    headerTooltips?: boolean;

    debug?: boolean;
    gridDebug?: boolean;
    logId?: string;
}

/** grid configuration options for infinity load mode */
export interface IOmniGridInfinityLoadOptions<TEntity = any> {
    getEntityId: (obj: TEntity) => string;
    getRows: (
        from: number,
        size: number,
        sortModel: OmniGridSortModel
    ) => Promise<ILoadMultiResult<TEntity>>;
    /** max number of results to fetch on a single call to getRows */
    fetchSize?: number;
    /** maximum value for (from + size). If reached then size in decreased (down to 0) */
    maxResultWindow?: number;
    /** called when data has been loaded from the server */
    onRowsLoaded?: (from: number, objects: TEntity[]) => Promise<void>;
    canSortByHeaderClick?: boolean;
    clearSourceCache?: () => void;
}

/** grid configuration options for tree mode */
export interface IOmniGridTreeOptions<TEntity = any> {
    getEntityId: (obj: TEntity) => string;
    getEntityChildren: (
        rootEntityId: string,
        sortModel: OmniGridSortModel,
        notFromCache?: boolean
    ) => Promise<ILoadMultiResult<TEntity>>;
    getEntityChildrenCount: (obj: TEntity) => number;
    maxChildrenCountToLoad: number;
    /** renderer for the cell which controls the opening/closing of group nodes */
    controlCellRendererParams: IOmniGridTreeOptionsRendererParams<TEntity>;
    /** header name for the columns of cells which control the opening/closing of group nodes */
    controlHeaderName?: string;
    controlColumnWidth?: number;
    onChildrenRefreshed?: (
        parent: TEntity,
        children: TEntity[]
    ) => Promise<void>;
    purgeClosedRowNodes?: boolean;
    purgeClosedRowNodesChildrenSelection?: boolean;
    canSortByHeaderClick?: boolean;
    lockGroupColumnToLeft?: boolean;
    onGroupRowClick?: (obj: TEntity) => void;
    clearSourceCache?: () => void;
}

/** cell renderer */
export type IOmniGridCellRenderer<TEntity = any, TValue = any> =
    | IOmniGridCellRendererFunc<TEntity, TValue>
    | { new (): ICellRendererComp };

/** cell renderer as a function */
export interface IOmniGridCellRendererFunc<TEntity = any, TValue = any> {
    (params: IOmniGridCellRendererData<TEntity, TValue>): HTMLElement | string;
}
/** cell rendering function argument */
export interface IOmniGridCellRendererData<TEntity = any, TValue = any> {
    data: TEntity;
    value: TValue;
}

/** cell renderer parameter for a group node in tree mode */
export interface IOmniGridTreeOptionsRendererParams<TEntity = any> {
    innerRenderer?: IInnerRenderer<TEntity>;
    innerRendererFramework?: Type<ICellComponent<TEntity>>;
    onGroupItemClick: (obj: TEntity) => void;
    isFromHierarchical?: boolean;
    showBreadCrumbs?: boolean;
    noNavLink?: boolean;
}
export type IInnerRenderer<TEntity, TValue = any> = IOmniGridCellRendererFunc<
    TEntity,
    TValue
>;

export interface IOmniGridColumnState {
    colId: string;
    hide?: boolean;
    width?: number;
}

export type TRowNode<TEntity = any> = { data: TEntity };
export type TRowNodeComparator<TEntity = any> = (
    valueA: any,
    valueB: any,
    nodeA: TRowNode<TEntity>,
    nodeB: TRowNode<TEntity>,
    isInverted: boolean
) => number;

/** Grid configuration and data.
 *  Note: One of those fields must be populated: objects, groups, infiniteLoad, tree. */
export interface IOmniGridDataInfo<TEntity = any> extends IOmniGridOptions {
    /** columns definition */
    columns?: IOmniGridColumnDef<TEntity>[];

    /** optional row actions definition */
    actions?: IOmniGridActionDef<TEntity>[];

    //#region modes

    /** for non grouped data mode */
    objects?: TEntity[];

    /** for grouped data mode */
    groups?: IDisplayedDataGroup[];
    groupHeaderName?: string;
    getObjectId?: (obj: TEntity) => string;

    /** for custom row class */
    getRowClass?: (
        params: IOmniGridRowClassParams<TEntity>
    ) => string | string[] | undefined;

    /** for non grouped with infinite load mode */
    infiniteLoad?: IOmniGridInfinityLoadOptions<TEntity>;

    /** for tree data mode */
    tree?: IOmniGridTreeOptions<TEntity>;

    //#endregion modes

    /** configuration for the *cdkDropList* directive applied to the grid's container, to allow drag&drop of entity-card-cell components */
    dragDrop?: IDragDropConfig;
}

/** the api to control the grid once it is ready */
export interface IOmniGridApi<TEntity = any> {
    /** refresh displayed cells content and layout */
    refreshView: () => Promise<void>;
    sizeColumnsToFit: () => Promise<void>;
    expandCollapseAll: (isExpanded: boolean) => void;
    setRowSelected: (
        rowId: string,
        isSelected?: boolean,
        clearSelection?: boolean
    ) => Promise<boolean>;
    setRowsSelected: (
        selection: string[],
        isSelected?: boolean,
        clearSelection?: boolean
    ) => Promise<void>;
    clearSelection: () => Promise<void>;
    clearFocusedCell: () => void;
    refreshAllCells: (columns: string[]) => Promise<void>;
    refreshAllRows: (preserveExpandedRows?: boolean) => Promise<void>;
    refreshNodeOnAction: (
        nodeId: string,
        preserveExpandedRows?: boolean
    ) => Promise<void>;
    updateNodeData: (rowId: string, rowData: TEntity) => Promise<void>;
    refreshForRemoved: (
        rowIds: string[],
        areChildrenReParentedToRoot?: boolean,
        preserveExpandedRows?: boolean
    ) => Promise<void>;
    refreshForParentChanged: (
        rowId: string,
        newParentId: string,
        preserveExpandedRows?: boolean
    ) => Promise<void>;
    refreshForParentsChanged: (
        rowsIdAndNewParentId: Map<string, string>,
        preserveExpandedRows?: boolean
    ) => Promise<void>;
    forceNodeRefresh: (
        rowId: string,
        hasContextualChildren?: (entity: TEntity) => boolean,
        getContextualAllLevelChildrenCount?: (entity: TEntity) => number
    ) => Promise<void>;
    getRowData: (rowId: string) => Promise<TEntity>;
    setRowData: (rowId: string, newData: TEntity) => Promise<boolean>;
    setRowsData: (rowsIdAndData: Map<string, TEntity>) => Promise<string[]>;
    setRowsExpanded: (
        rowIds: string | string[],
        isExpanded?: boolean,
        sequential?: boolean
    ) => Promise<void>;
    scrollToNode: (rowId) => Promise<void>;
    updateGridData: (clearCache?: boolean) => Promise<void>;
    getSelectedRows: () => Promise<TEntity[]>;
    setColumnVisible: (
        colIds: string | string[],
        visible: boolean
    ) => Promise<void>;
    setColumnDefs: (
        colDefs: IOmniGridColumnDef[],
        deltaColumnMode?: boolean
    ) => Promise<void>;
    getState: () => Promise<IOmniGridState>;
    setState: (gridState: IOmniGridState) => Promise<boolean>;
    resetState: () => Promise<void>;
    refreshCellsLayout: () => Promise<void>;
    getFirstData: () => Promise<TEntity>;
    removeRows: (predicate: (rowData: any) => boolean) => Promise<any[]>;
    updateRows: (predicate: (rowData: any) => boolean) => Promise<any[]>;
    getExpandedRowIds: () => Promise<string[]>;
    export: (options: IOmniGridExportOptions) => Promise<void>;
    getRowPosition: (rowId: string) => DOMRect;
}

export interface IOmniGridState {
    version: string;
    columns: IOmniGridColumnState[];
}

export interface IOmniGridRowHeightParams {
    data: any;
    node: RowNode;
    api: GridApi;
}

export interface IOmniGridRowClassParams<TEntity> {
    data?: TEntity;
    node: RowNode;
    api: GridApi;
    rowIndex: number;
}

export interface IOmniGridExportOptions {
    format: OmniGridExportFormat;
    excelParams?: IOmniGridExcelExportParams;
    csvParams?: IOmniGridCsvExportParams;
}
export enum OmniGridExportFormat {
    csv = 0,
    excel,
}
export interface IOmniGridExcelExportParams extends ExcelExportParams {}
export interface IOmniGridCsvExportParams extends CsvExportParams {}
export interface IUpdateCellsLayoutParams {
    from?: string;
    column?: Column;
    rowNode?: RowNode;
}

export type OmniGridSortModel = IOmniGridSortModelItem[];
export interface IOmniGridSortModelItem {
    colId: string;
    sort: string;
}

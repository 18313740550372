import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnChanges,
    SimpleChanges,
} from '@angular/core';
import { IUserBadgeCellData } from '@datagalaxy/core-ui/cell-components';
import { UserService } from '../../../services/user.service';
import { DxyBaseComponent } from '@datagalaxy/ui/core';

@Component({
    selector: 'dxy-profile-avatar',
    templateUrl: './dxy-profile-avatar.component.html',
    styleUrls: ['dxy-profile-avatar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DxyProfileAvatarComponent
    extends DxyBaseComponent
    implements OnChanges
{
    @Input() userId: string;
    @Input() isMiniIcon: boolean;

    public userTag: IUserBadgeCellData;

    constructor(private userService: UserService) {
        super();
    }

    ngOnChanges(changes: SimpleChanges) {
        super.onChange(
            changes,
            'userId',
            () =>
                (this.userTag = this.userService.getUserBadgeDataFromId(
                    this.userId
                )),
            true
        );
    }
}

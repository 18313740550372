<div
    *ngIf="!isFileUploaded()"
    class="left-section"
    [class.no-file]="!isFileUploaded()"
>
    <dxy-csv-file-deposit-card
        (onUploadFile)="onUploadFiles($event)"
        titleKey="Import.GenericImportWizard.CsvSettings.AddFileLbl"
    ></dxy-csv-file-deposit-card>
</div>
<div *ngIf="isFileUploaded()" class="right-section">
    <div *ngIf="isWorkspaceVisible" class="workspace-selection">
        <span class="settings-title" translate
            >Import.GenericImportWizard.CsvSettings.TargetWorkspace</span
        >
        <dxy-space-version-selector
            [spaceIdr]="selectedSpaceIdr"
            (onSpaceVersionSelected)="onSpaceVersionSelected($event.spaceIdr)"
            [isSpaceSelectable]="isSpaceVersionSelectable"
            [isVersionSelectable]="isSpaceVersionSelectable"
            [hideOfficialVersion]="true"
            [onlyImportable]="true"
            [showSelectedSpaceIcon]="true"
            (onMenuOpenClose)="onMenuOpenClose.emit($event)"
        ></dxy-space-version-selector>
    </div>
    <!-- File data section -->
    <span class="settings-title" translate
        >Import.GenericImportWizard.CsvSettings.CsvFileLbl</span
    >
    <div class="file-section-card" [class.is-expanded]="showSettingsAndPreview">
        <!-- Target section -->
        <div class="file-data-line">
            <span class="glyph-csv-file"></span>
            <span>{{ getFileName() }}</span>
            <button
                mat-icon-button
                (click)="onClickShowSettings()"
                [class.active]="showSettingsAndPreview"
                [matTooltip]="
                    'Import.GenericImportWizard.CsvSettings.CsvSettingsTooltip'
                        | translate
                "
            >
                <i class="glyph-settings"></i>
            </button>
            <div class="align-right">
                <div *ngIf="isOperationEditable">
                    <span
                        *ngIf="isTargetErrorVisible()"
                        class="glyph-mandatory"
                    ></span>
                    <dxy-dropdown-section
                        [sections]="targetSections"
                        [btnTitle]="getCurrentOperationTitle()"
                        [showDropdownCaret]="true"
                        (openClose)="onMenuOpenClose.emit($event)"
                    ></dxy-dropdown-section>
                </div>
                <div *ngIf="!isOperationEditable">
                    {{ getCurrentOperationTitle() }}
                </div>
                <div
                    *ngIf="isSourceConfigVisible()"
                    id="import-select-source"
                    data-tracker-id="import-select-source"
                    class="source-selection-wrapper"
                >
                    <span
                        *ngIf="isSourceWarningVisible()"
                        class="glyph-mandatory"
                        [matTooltip]="
                            'Import.GenericImportWizard.CsvSettings.SourceWarningTooltip'
                                | translate
                        "
                    ></span>
                    <dxy-target-selection
                        [hasEntityCreationAccess]="hasSourceCreationAccess"
                        [serverType]="sourceServerType"
                        [entityId]="sourceId"
                        [newEntityName]="newSourceName"
                        [subTypeName]="sourceTypeName"
                        [context]="importContext"
                        [availableEntities]="availableSources"
                        (reset)="onReset()"
                        (subTypeChange)="onSourceTypeChange($event)"
                        (newEntityNameChange)="onNewSourceNameChange($event)"
                        (entityChange)="onSourceChange($event)"
                        (isUpdateChange)="onIsUpdateChange($event)"
                        (openChange)="onMenuOpenClose.emit($event)"
                    ></dxy-target-selection>
                </div>
                <button
                    mat-icon-button
                    (click)="onDeleteFile()"
                    [matTooltip]="
                        'Import.GenericImportWizard.CsvSettings.CsvDeleteTooltip'
                            | translate
                    "
                >
                    <i class="glyph-delete"></i>
                </button>
            </div>
        </div>

        <div
            class="hidden-section"
            [class.expanded-section]="showSettingsAndPreview"
        >
            <!-- Encoding section -->
            <div class="file-data-line encoding-section">
                <mat-checkbox [(ngModel)]="autoDetectEncoding">
                    <span class="checkbox-text" translate
                        >Import.GenericImportWizard.CsvSettings.AutoEncoding</span
                    >
                </mat-checkbox>
                <button
                    mat-button
                    (click)="onClickReload()"
                    [disabled]="loading$ | async"
                >
                    <i class="glyph-redo2"></i>
                    <span translate
                        >Import.GenericImportWizard.CsvSettings.RefreshLbl</span
                    >
                </button>
            </div>
            <dxy-file-options-form
                [delimiter]="delimiter"
                [allowedEncodings]="allowedEncodings"
                (onConfigChange)="onFileOptionsChange($event)"
                (onMenuOpenClose)="onMenuOpenClose.emit($event)"
            ></dxy-file-options-form>

            <div *ngIf="parseWarning" class="parse-warning">
                <span class="glyph-warning"></span>
                <span translate>Import.Wizard.FileDeposit.ParseWarning</span>
            </div>
            <dxy-grid
                *ngIf="isPreviewVisible"
                [config]="gridConfig"
                [columns]="cols"
                [items]="rows"
            ></dxy-grid>
            <div *ngIf="loading$ | async" class="dg5-spinner xl-spinner"></div>
        </div>
    </div>
</div>

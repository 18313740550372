<div class="modal-header" [class.readonly]="isReading">
    <h3
        *ngIf="!isReading"
        mat-dialog-title
        class="modal-title"
        [translate]="title"
    ></h3>
    <button
        mat-icon-button
        (click)="onCloseCancel()"
        class="modal-close-btn"
    ></button>
</div>
<form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
    <div mat-dialog-content class="modal-content">
        <div class="news-header">
            <ng-container *ngIf="!isReading">
                <dxy-image-input
                    [params]="searchImageInputParams"
                    [isInModalDialog]="true"
                ></dxy-image-input>
                <dxy-field-text
                    *ngIf="!isReading"
                    labelKey="UI.SpaceHome.Widgets.News.Modal.Form.Title"
                    formControlName="title"
                    [mandatory]="true"
                    [takeFocus]="true"
                    [maxLength]="255"
                ></dxy-field-text>
            </ng-container>
            <ng-container *ngIf="isReading">
                <div class="news-image-wrapper">
                    <img
                        *ngIf="article.ImageHash"
                        class="news-image"
                        [src]="article.ImageHash | imageHashUrl"
                    />
                    <div
                        class="image-placeholder"
                        *ngIf="!article.ImageHash"
                    ></div>
                </div>
                <div class="news-title-wrapper">
                    <div *ngIf="article" class="news-infos">
                        <dxy-user-cell
                            *ngIf="article.CreationUserId"
                            [userId]="article.CreationUserId"
                        ></dxy-user-cell>
                        <div class="separator"></div>
                        {{ article.CreationTime | dateFormat }}
                    </div>
                    <div
                        class="news-title"
                        dxyEllipsisTooltip
                        [dxyEllipsisTooltipLineClamp]="4"
                    >
                        {{ article.Title }}
                    </div>
                </div>
            </ng-container>
        </div>
        <dxy-rich-text-field
            [labelKey]="descriptionLabelKey"
            formControlName="description"
            toolbarDisplayMode="always"
            [mentionResolvers]="mentionResolvers"
            [noTabCapture]="true"
            [mandatory]="true"
            [readonly]="isReading"
            [class.no-readonly-separator]="isReading"
        ></dxy-rich-text-field>
    </div>

    <div *ngIf="!isReading" class="modal-footer">
        <button mat-flat-button type="button" (click)="onCancelClick()">
            <span [translate]="cancelButtonKey"></span>
        </button>
        <button
            *ngIf="isEditing"
            mat-flat-button
            type="button"
            (click)="onDeleteClick()"
        >
            <i class="glyph-delete"></i>
            <span translate>UI.Global.btnDelete</span>
            <span *ngIf="loading" class="dg5-spinner"></span>
        </button>
        <button [disabled]="!formGroup.valid" mat-flat-button color="primary">
            <span [translate]="submitButtonKey"></span>
            <span *ngIf="loading" class="dg5-spinner"></span>
        </button>
    </div>
    <div *ngIf="showEditButton" class="modal-footer">
        <button
            mat-flat-button
            color="primary"
            type="button"
            (click)="onEditClick()"
        >
            <i class="glyph-edit"></i>
            <span translate>UI.Components.IntegrationAdmin.btnUpdate</span>
        </button>
    </div>
</form>

import { Component, OnInit, ViewChild } from '@angular/core';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { DpMappingUiService } from '../dpMappingUi.service';
import { IDpItemLinkMenuInfo } from '../dp-mapping.types';
import { DxyBaseComponent } from '@datagalaxy/ui/core';

/**
 * ## Role
 * Contextual menu of a graphical link in the Data-Processing Mapping UI
 */
@Component({
    selector: 'app-dp-item-link-menu',
    templateUrl: 'dp-item-link-menu.component.html',
})
export class DpItemLinkMenuComponent
    extends DxyBaseComponent
    implements OnInit
{
    public readonly menuPosition = { x: 0, y: 0 };

    @ViewChild(MatMenuTrigger, { static: true })
    private menuTrigger: MatMenuTrigger;

    private currentInfo: IDpItemLinkMenuInfo;

    constructor(private dpMappingUiService: DpMappingUiService) {
        super();
    }

    ngOnInit() {
        this.log('ngOnInit');
        super.subscribe(this.dpMappingUiService.onShowLinkMenu$, (info) =>
            this.showContextMenu(info)
        );
    }

    public onMenuOpenClose(isOpen: boolean) {
        this.dpMappingUiService.onLinkMenuOpenClose(isOpen);
    }

    public async deleteConnection() {
        this.log('deleteConnection', this.currentInfo);
        await this.dpMappingUiService.deleteItemLink(this.currentInfo.info);
    }

    private showContextMenu(info: IDpItemLinkMenuInfo) {
        this.log('showContextMenu', info);
        this.currentInfo = info;
        this.menuPosition.x = info.event.clientX;
        this.menuPosition.y = info.event.clientY;
        this.menuTrigger.openMenu();
    }
}

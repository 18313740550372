import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DxyBaseModalComponent } from '@datagalaxy/ui/dialog';
import { FilteredViewSelectorForm } from './filtered-view-selector-form';
import {
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
} from '@angular/forms';
import { CurrentSpaceService } from '../../../services/currentSpace.service';
import { MatDialogRef } from '@angular/material/dialog';
import { getContextId } from '@datagalaxy/webclient/utils';
import { widgetTypeMappings } from '@datagalaxy/webclient/dashboard/ui';
import { FilteredViewDto } from '@datagalaxy/webclient/filter/domain';

/**
 * ## Role
 * Configure the SpaceFilteredViewObjectWidget by selecting a filtered view
 */
@Component({
    selector: 'app-space-filtered-view-objects-modal',
    templateUrl: './space-filtered-view-objects-modal.component.html',
    styleUrls: ['./space-filtered-view-objects-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpaceFilteredViewObjectsModalComponent extends DxyBaseModalComponent<
    void,
    FilteredViewDto
> {
    protected form: FormGroup<FilteredViewSelectorForm>;
    protected spaceId = getContextId(
        this.currentSpaceService.currentSpace.ReferenceId
    );
    protected widget = widgetTypeMappings.get(
        'space-filtered-view-objects-widget'
    );

    constructor(
        dialogRef: MatDialogRef<
            SpaceFilteredViewObjectsModalComponent,
            FilteredViewDto
        >,
        private currentSpaceService: CurrentSpaceService,
        fb: FormBuilder
    ) {
        super(dialogRef, undefined);
        this.form = fb.group<FilteredViewSelectorForm>({
            filteredView: new FormControl<FilteredViewDto>(undefined, [
                Validators.required,
            ]),
        });
    }

    protected onSubmit() {
        if (this.form.valid) {
            this.result = this.form.controls.filteredView.value;
            this.onCloseSubmit();
        }
    }
}

import { Injectable } from '@angular/core';
import { DashboardState } from './dashboard-state';
import { DraggingObserver } from '../dragging-observer';
import { DashboardStateService } from './dashboard-state.service';

/**
 * ## Role
 * Save dahboard state for each undoable action on the dashboard
 */
@Injectable()
export class DashboardHistoryService {
    private history: DashboardState[] = [];
    private maxHistorySize = 1;
    private draggingSub = this.draggingObs.dragging$.subscribe((dragging) =>
        dragging ? this.pushCurrentState() : undefined
    );

    constructor(
        private draggingObs: DraggingObserver,
        private stateService: DashboardStateService
    ) {}

    public revertToLastHistoryState() {
        const lastState = this.history.pop();
        this.stateService.revertToState(lastState);
    }

    public dispose() {
        this.draggingSub.unsubscribe();
    }

    private pushCurrentState() {
        this.push(this.stateService.state);
    }

    private push(state: DashboardState) {
        if (this.history.length >= this.maxHistorySize) {
            this.history.shift();
        }
        this.history.push(state);
    }
}

import { RootSplitUtil } from './RootSplitUtil';
import { environment } from '../../../../environments/environment';
import { ViewType } from '../../../shared/util/app-types/ViewType';
import { LineageConstants } from './LineageConstants';
import { LineageItem } from './LineageItem';
import { CollectionsHelper } from '@datagalaxy/core-util';
import { ModelerDataUtil } from '../../../shared/util/ModelerDataUtil';
import { EntityTypeUtil } from '@datagalaxy/dg-object-model';
import { LineageLink, VirtualType } from './LineageLink';
import { LineageColumn } from './LineageColumn';
import { DataUtil } from '../../../shared/util/DataUtil';
import { EntityType } from '@datagalaxy/dg-object-model';
import { GetDataLineageResult } from '@datagalaxy/webclient/explorer/data-access';
import {
    DataLineageDataLink,
    DataLineageGenerationData,
    DataLineageItem,
    LineageLinkOrientationType,
} from '@datagalaxy/webclient/explorer/data-access';
import { DgModule } from '@datagalaxy/shared/dg-module/domain';

/** Dataset of items and links to be displayed, converted from server's data.
 * Features:
 * - Add virtual links
 * - Compute layout columns
 * - Append lazy-loaded children items with their links and linked items */
export class LineageData {
    /** to log durations */
    private static times = false;
    /** to log to console */
    private static debug = false;
    /** to log more to console */
    private static verbose = false;

    //#region static

    /** initialize columns, items and links.
     * Ordered operations are: buildItems, splitRoots, initItems, buildLinks, removeEmptySplitRoots, markSearchedItems, computeColumns, addVirtualLinks */
    public static build(
        serverData: GetDataLineageResult,
        viewType: ViewType,
        itemSpecs: LineageConstants,
        options?: ILineageDataBuildOptions
    ) {
        LineageData.debug &&
            LineageData.log('build', { serverData, itemSpecs, options });

        if (!serverData?.SourceDataLocalId) {
            LineageData.warn('no SourceDataLocalId', serverData);
            return null;
        }

        if (options?.debug) {
            LineageData.times = LineageData.debug = true;
        }

        LineageData.time('global');
        const serverLinks = serverData.DataLinks;

        // build items
        const itemsByDataId = new Map<string, LineageItem>();
        const { roots } = LineageData.buildItems(
            serverData.Items,
            serverLinks,
            itemsByDataId
        );

        // optionally split root items
        if (options?.splitRoots) {
            RootSplitUtil.splitRoots(roots, serverLinks);
        }

        // initialize every item with its unique *id*.
        // Note: in case of split roots, several items can have the same *dataId*. But (dataId,clonedFor?.DataId) is unique.
        const { allItems, nextId } = LineageData.initAndCollect(
            roots,
            itemSpecs
        );

        // build real links
        const allLinks = LineageData.buildRealLinks(serverLinks, itemsByDataId);
        const realLinks = Array.from(allLinks.values());

        // remove empty non-linked split roots
        const removedRoots = LineageData.removeEmptyNonLinkedSplitRootsIfNeeded(
            roots,
            realLinks,
            options,
            serverData
        );

        // mark searched item/clones and their parents
        const { searchedItems, searchedItemLevel } =
            LineageData.markSearchedItems(
                serverData.SourceDataLocalId,
                allItems,
                removedRoots
            );

        // compute columns
        const columns = serverData.generations
            ? LineageData.computeColumnsFromGenerations(
                  serverData.generations,
                  itemsByDataId
              )
            : LineageData.computeColumns(
                  searchedItems,
                  realLinks,
                  roots,
                  viewType
              );

        columns.forEach((c) => LineageData.sortDescendants(c.items, viewType));

        // add virtual links
        if (options?.addVirtualLinks) {
            LineageData.addVirtualLinks(allLinks);
        }

        // pack all
        LineageData.time('result');
        const result = new LineageData(
            columns,
            allItems,
            Array.from(allLinks.values()),
            searchedItemLevel,
            serverData.LazyItemsCount,
            nextId,
            itemSpecs,
            options
        );
        LineageData.timeEnd('result');

        LineageData.timeEnd('global');

        // some figures
        if (LineageData.debug) {
            LineageData.logStats(result, options);
            LineageData.log(
                'links-without-src',
                result.links.filter(
                    (l) => !l.src || !result.items.includes(l.src)
                )
            );
            LineageData.log(
                'links-without-tgt',
                result.links.filter(
                    (l) => !l.tgt || !result.items.includes(l.tgt)
                )
            );
            LineageData.log(
                'items-without-link',
                result.items.filter(
                    (it) =>
                        !result.links.some((l) => l.tgt == it || l.src == it)
                )
            );
            LineageData.log(
                'roots-without-column',
                result.items.filter(
                    (it) =>
                        it.isRoot &&
                        !result.columns.some((c) => c.items.includes(it))
                )
            );
            LineageData.log(
                'links-by-item',
                result.items.map((it) => [
                    it.toDebugString(true),
                    {
                        it,
                        up: result.links.filter((l) => l.src == it),
                        dn: result.links.filter((l) => l.tgt == it),
                    },
                ])
            );
        }
        LineageData.log('build-result', result);

        return result;
    }

    private static buildItems(
        serverItems: DataLineageItem[],
        serverLinks: DataLineageDataLink[],
        itemsByDataId: Map<string, LineageItem>,
        forUpdate = false
    ) {
        LineageData.time('buildItems');
        const dataItemsByDataId = CollectionsHelper.arrayToMap(
            serverItems,
            (dli) => dli.DataLocalId
        );
        const roots: LineageItem[] = [],
            newItems: LineageItem[] = forUpdate ? [] : undefined;
        const makeItem = (dli: DataLineageItem) => {
            LineageData.verbose && LineageData.log('makeItem-pre', dli);
            if (!dli || itemsByDataId.has(dli.DataLocalId)) {
                return;
            }

            // the parent, with its ascendants
            let parent: LineageItem = undefined;
            const parentDataId = dli.ParentLocalId;
            if (parentDataId) {
                const pdli = dataItemsByDataId.get(parentDataId);
                if (!pdli && forUpdate) {
                    parent = itemsByDataId.get(parentDataId);
                }
                parent ??= !pdli
                    ? undefined
                    : itemsByDataId.get(parentDataId) || makeItem(pdli);
            }

            // the item
            const localId = dli.DataLocalId;
            const isCatalogItem = ModelerDataUtil.isModelerServerType(
                EntityTypeUtil.getServerType(dli.EntityType)
            );
            const isGoldenItem =
                isCatalogItem &&
                serverLinks.some(
                    (l) =>
                        l.IsGoldenLink &&
                        (l.TargetId == localId || l.SourceId == localId)
                );
            const it: LineageItem = new LineageItem(
                dli.EntityType,
                dli.DisplayName,
                dli.TechnicalName,
                localId,
                parent,
                isGoldenItem,
                dli.LazyChildrenCount
            );
            LineageData.verbose && LineageData.log('makeItem-post', it);

            itemsByDataId.set(localId, it);
            if (newItems) {
                newItems.push(it);
            }
            if (it.isRoot) {
                roots.push(it);
            }

            return it;
        };
        dataItemsByDataId.forEach(makeItem);
        LineageData.timeEnd('buildItems');
        newItems
            ? LineageData.log('roots', roots, 'newItems', newItems)
            : LineageData.log('roots', roots);
        return { roots, newItems };
    }

    private static initAndCollect(
        roots: LineageItem[],
        itemSpecs: LineageConstants
    ) {
        LineageData.time('initAndCollect');
        const allItems = new Array<LineageItem>();
        let nextId = 1;
        const initAndCollect = (items: LineageItem[]) => {
            items.forEach((it) => {
                it.init(nextId++, itemSpecs);
                if (this.debug)
                    this.log(
                        `initAndCollect[${nextId - 1}/${items.length}]`,
                        it.toDebugString(true),
                        it.root?.toDebugString(true)
                    );
                allItems.push(it);
                if (it.children) {
                    initAndCollect(it.children);
                }
            });
        };
        initAndCollect(roots);
        LineageData.timeEnd('initAndCollect');
        return { allItems, nextId };
    }
    private static initNewItems(
        items: LineageItem[],
        itemSpecs: LineageConstants,
        nextId: number,
        maxLevel: number
    ) {
        const roots = new Set<LineageItem>();
        items.forEach((it) => {
            if (it.id) {
                return;
            }
            it.init(nextId++, itemSpecs);
            roots.add(it.root);
        });
        roots.forEach(
            (it) => (maxLevel = Math.max(maxLevel, it.computeMaxLevel()))
        );
        return { nextId, maxLevel };
    }

    //#region split

    //#endregion

    /** remove nulls; remove doubles if any (take last).
     * Note: items must have their root and id here, for classes to be computed.
     * If *existingLinks* is provided, only non-virtual links of those are added to the result */
    private static buildRealLinks(
        dataLinks: DataLineageDataLink[],
        itemsByDataId: Map<string, LineageItem>,
        existingLinks?: LineageLink[]
    ) {
        LineageData.time('buildRealLinks');
        const links = new Map<string, LineageLink>();
        const addLink = (l: LineageLink) => {
            const dir =
                l.orient == LineageLinkOrientationType.Unoriented ? '-' : '>';
            links.set(`${l.src.id}${dir}${l.tgt.id}`, l);
        };
        existingLinks?.forEach((l) => !l.virtual && addLink(l));
        dataLinks.forEach((dl) => {
            if (!dl) {
                return;
            }
            let src = itemsByDataId.get(dl.SourceId);
            if (!src) {
                return;
            }
            let tgt = itemsByDataId.get(dl.TargetId);
            if (!tgt) {
                return;
            }
            const l = new LineageLink(
                src,
                tgt,
                dl.OrientationType,
                VirtualType.No,
                dl.UniversalObjectLinkType,
                dl.IsGoldenLink,
                dl.EntityLinkReferenceId,
                dl.IsLazy
            );
            addLink(l);
            LineageData.verbose &&
                LineageData.log('buildRealLinks', l.toDebugString());
        });
        LineageData.timeEnd('buildRealLinks');
        return links;
    }

    private static removeEmptyNonLinkedSplitRootsIfNeeded(
        roots: LineageItem[],
        realLinks: LineageLink[],
        options: ILineageDataBuildOptions,
        serverData: GetDataLineageResult
    ) {
        if (
            options?.splitRoots &&
            LineageConstants.behaviour.splitRoots
                .removeEmptyRootsHavingNoLinks &&
            serverData.Items.length > 1
        ) {
            const isLinked = (it: LineageItem) =>
                realLinks.some((l) => l.src == it || l.tgt == it);
            const removedRoots: LineageItem[] = [];
            removedRoots.push(
                ...CollectionsHelper.remove(
                    roots,
                    (it) => !it.hasChildren && !isLinked(it)
                )
            );
            LineageData.log('removedRoots', removedRoots);
            return removedRoots;
        }
        return [];
    }

    private static markSearchedItems(
        dataId: string,
        allItems: LineageItem[],
        removedRoots: LineageItem[]
    ) {
        const markSearchedItem = (it: LineageItem) => {
            if (!it) {
                return;
            }
            it.isSearchedItem = true;
            let p = it.parent;
            while (p) {
                p.isSearchedItemParent = true;
                p = p.parent;
            }
        };
        let searchedItemLevel: number;
        const searchedItems = allItems.filter(
            (it) => it.dataId == dataId && !removedRoots.includes(it)
        );
        if (searchedItems.length) {
            searchedItems.forEach(markSearchedItem);
            searchedItemLevel =
                CollectionsHelper.minValue(searchedItems, (it) => it.level) ??
                -1;
        } else {
            searchedItemLevel = -1;
            LineageData.warn(
                'no searched item',
                'SourceDataLocalId:',
                dataId,
                'found in allItems:',
                dataId && allItems?.some((it) => it.dataId == dataId)
            );
        }
        LineageData.log('searchedItems', searchedItems, searchedItemLevel);
        return { searchedItems, searchedItemLevel };
    }

    //#region compute columns

    private static computeColumnsFromGenerations(
        generations: DataLineageGenerationData[],
        itemsByDataId: Map<string, LineageItem>
    ) {
        return generations.map(
            (g, i) =>
                new LineageColumn(
                    i,
                    g.DataLocalIds.map((dataId) =>
                        itemsByDataId.get(dataId)
                    ).filter((it) => it)
                )
        );
    }

    private static computeColumns(
        origins: LineageItem[],
        links: LineageLink[],
        allRoots: LineageItem[],
        viewType: ViewType
    ) {
        LineageData.log('computeColumns', origins, links);
        LineageData.time('computeColumns');
        const { items, columnsByIndex, setColumn } =
            LineageData.initSetColumns();

        // columns layout is made by walking the graph from origin items, first upstream, then downstream
        visitItems(
            origins,
            links,
            (upstream, it, nextLinked, fromLinked, stack, type) => {
                const known = items.has(it),
                    i = known
                        ? items.get(it)
                        : setColumn(upstream, it, fromLinked);
                LineageData.verbose &&
                    LineageData.log(
                        'computeColumns-process',
                        upstream,
                        i,
                        known,
                        VisitType[type],
                        it.toDebugString(true),
                        fromLinked?.toDebugString(true),
                        [...stack, it].map((o) => o.id).join()
                    );
            }
        );
        const processedRoots = CollectionsHelper.flattenGroups(
            Array.from(columnsByIndex.values()),
            (c) => c.items
        );

        // Because the graph is walked all upstream first, then all downstream - which is needed for the graph to be as flat as possible,
        // some roots may not have been processed.
        // For instance, considering this graph, with DB (or Model or Table) being the origin:
        // DB/Model/Table -> App1
        // App3 -> App2 -> App1
        // In that case, App2 and App3 would not be processed.

        // process unprocessed roots
        const secondaryRoots = CollectionsHelper.difference(
            allRoots,
            processedRoots
        );
        LineageData.computeSecondaryRootsColumn(
            secondaryRoots,
            columnsByIndex,
            links,
            setColumn,
            processedRoots
        );

        const columns = LineageData.reIndexColumns(columnsByIndex);
        LineageData.sortColumnItems(columns, viewType);
        LineageData.timeEnd('computeColumns');
        LineageData.log('columns', columns);
        return columns;
    }
    private static initSetColumns(columns?: LineageColumn[]) {
        const columnsByIndex = new Map<number, LineageColumn>(),
            items = new Map<LineageItem, number>();
        columns?.forEach((c) => {
            columnsByIndex.set(c.index, c);
            c.items.forEach((it) => items.set(it, c.index));
        });
        function setColumn(
            upstream: boolean,
            it: LineageItem,
            from: LineageItem
        ) {
            const i = from ? items.get(from) + (upstream ? -1 : 1) : 0;
            items.set(it, i);
            if (it.isRoot) {
                if (columnsByIndex.has(i)) {
                    columnsByIndex.get(i).items.push(it);
                } else {
                    columnsByIndex.set(i, new LineageColumn(i, [it]));
                }
            }
            LineageData.verbose &&
                LineageData.log(
                    'setColumn',
                    it.toDebugString(true),
                    i,
                    upstream,
                    from?.toDebugString(true)
                );
            return i;
        }
        return { items, columnsByIndex, setColumn };
    }
    /** Note: items for which a column is determined are removed from *secondaryRoots* */
    private static computeSecondaryRootsColumn(
        secondaryRoots: LineageItem[],
        columnsByIndex: Map<number, LineageColumn>,
        links: LineageLink[],
        setColumn: (
            upstream: boolean,
            it: LineageItem,
            from: LineageItem
        ) => number,
        processedRoots?: LineageItem[]
    ) {
        LineageData.log('computeSecondaryRootsColumn', secondaryRoots);
        if (!secondaryRoots.length) {
            return;
        }

        const toString = (o: any) =>
            Array.isArray(o)
                ? o.map(toString)
                : o instanceof LineageItem
                ? o.toDebugString(true)
                : o;
        const log = (...args: any[]) =>
            LineageData.verbose &&
            LineageData.log(
                'computeColumns-secondaryRoots',
                ...args.map(toString)
            );

        /** returns true when at least one item was removed from secondaryRoots */
        function process(
            root: LineageItem,
            stack: LineageItem[] = []
        ): boolean {
            log('process', root, stack);
            const exit = (res: boolean, ...args: any[]) => {
                log('process-exit', root, res, ...args);
                return res;
            };
            const hasColumn = (it: LineageItem) =>
                !!CollectionsHelper.findFirstInMap(columnsByIndex, (c) =>
                    c.items.includes(it)
                );

            if (hasColumn(root)) {
                return exit(false, 'column already set');
            }

            if (stack.includes(root)) {
                return exit(false, 'cycle');
            }

            const preventCycle = (linked: LineageItem) =>
                linked.root != root && !stack.includes(linked.root);

            // find a directly linked processed root
            const foundProcessed =
                processedRoots &&
                LineageItem.findLinked(
                    root,
                    links,
                    (linked) =>
                        linked.isRoot &&
                        processedRoots.includes(linked.root) &&
                        preventCycle(linked)
                );
            if (foundProcessed) {
                log('process-found-processed', foundProcessed.linked);
            }

            const found =
                foundProcessed ??
                LineageItem.getFirstLinkedDescendant(
                    root,
                    links,
                    true,
                    preventCycle
                );
            if (!found) {
                return exit(false, 'linked not found');
            }

            const linkedRoot = found.linked.root;
            if (!foundProcessed) {
                log('process-found-descendant', linkedRoot);
            }
            const processFound = (...args: any[]) => {
                setColumn(found.upstream, root, linkedRoot);
                CollectionsHelper.removeElement(secondaryRoots, root);
                if (foundProcessed) {
                    processedRoots.push(root);
                }
                return exit(true, 'processFound', ...args, linkedRoot);
            };

            if (hasColumn(linkedRoot)) {
                return processFound('linkedRoot found');
            }

            stack.push(root);
            const linkedRootColumnSet = process(linkedRoot, stack);
            stack.pop();
            if (linkedRootColumnSet) {
                return processFound('linkedRoot processed');
            }

            return exit(false, 'default');
        }

        LineageData.time('computeSecondaryRootsColumn');
        secondaryRoots.slice().forEach((root) => process(root));
        LineageData.timeEnd('computeSecondaryRootsColumn');
    }
    private static reIndexColumns(columnsByIndex: Map<number, LineageColumn>) {
        const columns = CollectionsHelper.orderBy(
            Array.from(columnsByIndex.values()),
            (c) => c.index
        );
        columns.forEach((c, i) => (c.index = i));
        return columns;
    }
    /** Sort items in a column by module, then by displayName, then by first children's displayName */
    private static sortColumnItems(
        columns: LineageColumn[],
        viewType: ViewType
    ) {
        const modulesOrder = [
            DgModule.Glossary,
            DgModule.Catalog,
            DgModule.Processing,
            DgModule.Usage,
            DgModule.unknown,
        ];
        columns.forEach((c) => {
            c.items = CollectionsHelper.orderBy(c.items, [
                // by module, in the column
                (it) =>
                    modulesOrder.indexOf(
                        DataUtil.getModuleFromEntityType(it.entityType)
                    ),
                // by displayName in a module
                (it) => it.getDisplayedName(viewType),
                // by first child's displayName (for split roots having the same name)
                (it) => it.children?.[0]?.getDisplayedName(viewType) ?? '',
            ]);
        });
    }
    private static updateColumns(
        newRoots: LineageItem[],
        columns: LineageColumn[],
        links: LineageLink[]
    ) {
        const { columnsByIndex, setColumn } =
            LineageData.initSetColumns(columns);
        LineageData.computeSecondaryRootsColumn(
            newRoots.slice(),
            columnsByIndex,
            links,
            setColumn
        );
        return LineageData.reIndexColumns(columnsByIndex);
    }

    //#endregion

    private static addVirtualLinks(links: Map<string, LineageLink>) {
        LineageData.time('addVirtualLinks');

        const addVirtualLink = (l: LineageLink, forSrc: boolean) => {
            let src = l.src,
                tgt = l.tgt,
                parent = (forSrc ? src : tgt).parent;

            const dir =
                    l.orient == LineageLinkOrientationType.Unoriented
                        ? '-'
                        : '>',
                vts = VirtualType.Src,
                vtt = VirtualType.Tgt,
                vt = forSrc ? vts : vtt;

            if (LineageData.verbose)
                LineageData.log(
                    'addVirtualLink-for',
                    `${l.src.id}${dir}${l.tgt.id}`,
                    forSrc ? l.src.id : l.tgt.id
                );

            while (parent) {
                if (forSrc) {
                    src = parent;
                } else {
                    tgt = parent;
                }
                parent = parent.parent;

                const lid = `${src.id}${dir}${tgt.id}`,
                    el = links.get(lid);
                if (el) {
                    const elv = el.virtual;
                    el.isVirtualGoldenLink =
                        l.isVirtualGoldenLink || l.isGoldenLink;
                    if ((forSrc && elv == vtt) || (!forSrc && elv == vts)) {
                        el.virtual = VirtualType.Both;
                        if (LineageData.verbose)
                            LineageData.log('addVirtualLink-mutate-vv', lid);
                    } else {
                        if (LineageData.verbose)
                            LineageData.log('addVirtualLink-exists', lid);
                    }
                } else {
                    const vl = new LineageLink(
                        src,
                        tgt,
                        l.orient,
                        vt,
                        l.linkType
                    );
                    vl.isVirtualGoldenLink =
                        l.isVirtualGoldenLink || l.isGoldenLink;
                    links.set(lid, vl);
                    if (LineageData.verbose)
                        LineageData.log(
                            'addVirtualLink-new',
                            vl.toDebugString()
                        );
                }
            }
        };

        links.forEach((l) => {
            if (l.src.parent) {
                addVirtualLink(l, true);
            }
            if (l.tgt.parent) {
                addVirtualLink(l, false);
            }
        });

        LineageData.timeEnd('addVirtualLinks');
    }

    private static logStats(
        result: LineageData,
        options: ILineageDataBuildOptions
    ) {
        LineageData.log('items-total', result.items.length);

        CollectionsHelper.getEnumValues<EntityType>(EntityType).forEach(
            (et) => {
                const count = CollectionsHelper.count(
                    result.items,
                    (d) => d.entityType == et
                );
                if (count)
                    LineageData.log('items-EntityType', EntityType[et], count);
            }
        );

        LineageData.log('links-total', result.links.length);

        CollectionsHelper.getEnumValues<LineageLinkOrientationType>(
            LineageLinkOrientationType
        ).forEach((ot) =>
            LineageData.log(
                'links-Orient',
                LineageLinkOrientationType[ot],
                CollectionsHelper.count(result.links, (l) => l.orient == ot)
            )
        );

        if (options?.addVirtualLinks) {
            CollectionsHelper.getEnumValues<VirtualType>(VirtualType).forEach(
                (vt) =>
                    LineageData.log(
                        'links-Virtual',
                        VirtualType[vt],
                        CollectionsHelper.count(
                            result.links,
                            (l) => l.virtual == vt
                        )
                    )
            );

            CollectionsHelper.getEnumValues<LineageLinkOrientationType>(
                LineageLinkOrientationType
            ).forEach((ot) =>
                CollectionsHelper.getEnumValues<VirtualType>(
                    VirtualType
                ).forEach((vt) => {
                    const count = CollectionsHelper.count(
                        result.links,
                        (l) => l.orient == ot && l.virtual == vt
                    );
                    if (count)
                        LineageData.log(
                            'links-Orient-virtual',
                            LineageLinkOrientationType[ot],
                            VirtualType[vt],
                            count
                        );
                })
            );
        }
    }

    //#region log
    private static log(...args: any[]) {
        if (!LineageData.debug) {
            return;
        }
        console.log('LineageData', ...args);
    }
    private static warn(...args: any[]) {
        if (environment.production) {
            return;
        }
        console.warn(...args);
    }
    private static time(label: string) {
        if (!LineageData.times) {
            return;
        }
        console.time('LineageData-' + label);
    }
    private static timeEnd(label: string) {
        if (!LineageData.times) {
            return;
        }
        console.timeEnd('LineageData-' + label);
    }
    //#endregion

    //#endregion

    /** hierarchical level of the deepest item */
    public maxLevel: number;

    private readonly allRoots: Array<LineageItem>;
    private debug: boolean;

    private constructor(
        public readonly columns: Array<LineageColumn>,
        public readonly items: Array<LineageItem>,
        public readonly links: Array<LineageLink>,
        public readonly searchedItemLevel: number,
        public lazyItemsCount: number,
        private nextId: number,
        private itemSpecs: LineageConstants,
        private options?: ILineageDataBuildOptions
    ) {
        this.allRoots = CollectionsHelper.flattenGroups(
            columns,
            (c) => c.items
        );
        this.maxLevel =
            CollectionsHelper.maxValue(this.allRoots, (r) => r.maxLevel) || 0;
        this.debug = options.debug;
    }

    public getAllRoots(predicate?: (r: LineageItem) => boolean) {
        return predicate ? this.allRoots.filter(predicate) : this.allRoots;
    }
    public withAllRoots(withRoot: (root: LineageItem) => void) {
        this.allRoots.forEach(withRoot);
    }
    public getItemsByRoot() {
        return CollectionsHelper.objArrayToMap(
            this.allRoots,
            (r) => r,
            (r) => LineageItem.getDescendants(r, null, true)
        );
    }

    /** Add lazy-loaded chilren items with ther links and linked items */
    public mergeLazyLoad(
        parentItem: LineageItem,
        serverData: GetDataLineageResult,
        viewType: ViewType
    ) {
        this.log('mergeLazyLoad', parentItem, serverData);
        LineageData.time('mergeLazyLoad');
        const serverLinks = serverData.DataLinks;

        // buildItems
        const itemsByDataId = CollectionsHelper.arrayToMap(
            this.items.filter((it) => !it.clonedFor),
            (it) => it.dataId
        );
        const newServerItems = serverData.Items.filter(
            (dli) => !itemsByDataId.has(dli.DataLocalId)
        );
        const { roots: newRoots, newItems } = LineageData.buildItems(
            newServerItems,
            serverLinks,
            itemsByDataId,
            true
        );

        // optionally split roots
        if (this.options?.splitRoots) {
            RootSplitUtil.splitRoots(newRoots, serverLinks, {
                newItems,
                parentItem,
            });
        }

        // init items
        const { nextId, maxLevel } = LineageData.initNewItems(
            newItems,
            this.itemSpecs,
            this.nextId,
            this.maxLevel
        );

        // remove lazy links of the parent item
        CollectionsHelper.remove(
            this.links,
            (l) => l.isLazyLink && (l.src == parentItem || l.tgt == parentItem)
        );
        // update lazy items count
        //#Lineage- todo: better computation, or remove counting
        this.lazyItemsCount +=
            serverData.LazyItemsCount ?? 0 - parentItem.lazyChildrenCount;
        parentItem.lazyChildrenCount = 0;

        // build real links
        const allLinks = LineageData.buildRealLinks(
            serverLinks,
            itemsByDataId,
            this.links
        );
        const realLinks = Array.from(allLinks.values());
        const newLinks = this.debug && realLinks.slice();

        // remove empty non-linked split roots
        LineageData.removeEmptyNonLinkedSplitRootsIfNeeded(
            newRoots,
            realLinks,
            this.options,
            serverData
        );

        // sort new items' children
        LineageData.sortDescendants(newRoots, viewType);

        // compute new columns
        const columns = LineageData.updateColumns(
            newRoots,
            this.columns,
            realLinks
        );
        const newColumns =
            this.debug && CollectionsHelper.difference(columns, this.columns);

        // add virtual links
        if (this.options?.addVirtualLinks) {
            LineageData.addVirtualLinks(allLinks);
        }

        // update data
        this.items.push(...newItems);
        this.allRoots.push(...newRoots);
        this.links.length = 0;
        this.links.push(...allLinks.values());
        this.columns.length = 0;
        this.columns.push(...columns);
        const oldNextId = this.nextId;
        this.nextId = nextId;
        this.maxLevel = Math.max(this.maxLevel, maxLevel);

        LineageData.timeEnd('mergeLazyLoad');
        this.debug &&
            this.log(
                'mergeLazyLoad',
                { newItems, newLinks, newRoots, newColumns, oldNextId },
                this
            );

        return { newItems, newRoots };
    }

    /** Sort every item's children by displayName according to the given viewType */
    private static sortDescendants(roots: LineageItem[], viewType: ViewType) {
        const sortChildrenRecursive = (it: LineageItem) => {
            if (!it.children) {
                return;
            }
            if (it.children.length > 1) {
                it.children = CollectionsHelper.orderByText(it.children, (it) =>
                    it.getDisplayedName(viewType)
                );
            }
            it.children.forEach(sortChildrenRecursive);
        };
        roots.forEach(sortChildrenRecursive);
    }

    private log(...args: any) {
        this.debug && console.log(this.constructor.name, ...args);
    }
}

export interface ILineageDataBuildOptions {
    debug?: boolean;
    addVirtualLinks?: boolean;
    splitRoots?: boolean;
}

/** From each given *startItem*,
 * visit each linked, parent and children items,
 * by depth first,
 * upstream first then downstream.
 * Cycles are avoided. */
function visitItems<T extends { parent: T; children: T[] }>(
    startItems: T[],
    links: { src: T; tgt: T }[],
    process: (
        upstream: boolean,
        it: T,
        nextLinked: T[],
        fromLinked: T,
        stack: T[],
        type: VisitType
    ) => void
) {
    function getSources(it: T) {
        return links.filter((l) => l.tgt == it).map((l) => l.src);
    }
    function getTargets(it: T) {
        return links.filter((l) => l.src == it).map((l) => l.tgt);
    }

    const stack: T[] = [];
    const processed = new Set<T>();

    function visit(it: T, upstream: boolean, type: VisitType, fromLinked: T) {
        if (processed.has(it) || stack.includes(it)) {
            return;
        }

        const nextLinked = upstream ? getSources(it) : getTargets(it);
        process(upstream, it, nextLinked, fromLinked, stack, type);
        if (nextLinked.length) {
            processed.add(it);
        }

        if (nextLinked.length || it.parent || it.children) {
            stack.push(it);
            nextLinked.forEach((n) => visit(n, upstream, VisitType.link, it));
            it.parent &&
                visit(it.parent, upstream, VisitType.parent, fromLinked);
            it.children?.forEach((c) =>
                visit(c, upstream, VisitType.child, fromLinked)
            );
            stack.pop();
        }
    }

    function startVisit(it: T, upstream: boolean) {
        stack.length = 0;
        processed.clear();
        visit(it, upstream, VisitType.start, null);
    }

    startItems.forEach((it) => startVisit(it, true));
    startItems.forEach((it) => startVisit(it, false));
}
enum VisitType {
    start,
    link,
    parent,
    child,
}

<div class="spacemodule-content-wrapper">
    <dxy-widget-header
        [titleText]="titleText"
        [description]="description"
        [showCount]="hasCount && totalCount > 0"
        [countValue]="totalCount"
        [noLine]="!hasCount"
        (onTitleClick)="onClickTitle()"
        [dxyLogFunctional]="getFeatureCode()"
        [filters]="filters"
    >
    </dxy-widget-header>

    <div class="widget-content-body body">
        <div *ngIf="canImport">
            <dxy-entity-import-button
                *ngIf="hasCount && totalCount === 0"
                class="centered-import"
                (onImport)="onImportData()"
                [spaceIdr]="spaceIdr"
                [dgModule]="dgModule"
                [securityData]="entitySecurityData"
            >
                <span title [translate]="importTranslateKey"></span>
            </dxy-entity-import-button>
            <div
                *ngIf="hasCount && totalCount === 0"
                class="centered-import-label"
                [translate]="firstImportTranslateKey"
            ></div>
        </div>

        <div class="elementCount-wrapper">
            <div
                *ngFor="let element of elementList"
                class="elementCountContainer"
            >
                <div
                    *ngIf="getCount(element.value)"
                    class="elementTypePart"
                    (click)="onClickEntityTypeContent(element.id)"
                >
                    <span
                        class="elementTypeIcon glyph"
                        [ngClass]="getElementTypeClass(element.id)"
                    ></span>
                    <span class="elementCount">{{
                        getCount(element.value)
                    }}</span>
                    <span class="elementName">
                        {{
                            getTranslateEntitySubTypeKey(element.value)
                                | translate
                                    : getEntitySubTypeKeyPluralizationParameters(
                                          element.value
                                      )
                        }}
                    </span>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="placeholderVisible" class="empty-module-placeholder">
        <img [src]="getEmptyModulePlaceholderUrl()" />
    </div>
</div>

import { CollectionsHelper } from '@datagalaxy/core-util';
import { DatamapGraphNode } from './DatamapGraphNode';
import { ExplorationGraphLink } from './ExplorationGraphLink';
import { ExplorationGraphData } from './ExplorationGraphData';
import { EntityType } from '@datagalaxy/dg-object-model';
import { ISpaceIdentifier } from '@datagalaxy/webclient/workspace/domain';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';
import { DgModule } from '@datagalaxy/shared/dg-module/domain';

declare type Item = DatamapGraphNode;
declare type Link = ExplorationGraphLink;

export class DatamapGraphData extends ExplorationGraphData {
    public topLevelEntities: EntityItem[];

    public get topLevelEntitiesCount() {
        return this.topLevelEntities?.length || 0;
    }

    public get allEntitiesCount() {
        return this.allEntities?.length || 0;
    }

    constructor(
        public spaceIdr: ISpaceIdentifier,
        public dgModule: DgModule,
        isChildVisible: boolean,
        private allEntities: EntityItem[]
    ) {
        super();
        const spaceId = this.spaceIdr.spaceId;
        this.topLevelEntities =
            allEntities?.filter(
                (ei) => ei?.HddData?.Parents[0]?.DataReferenceId == spaceId
            ) || [];
        const topLevelNodes = this.topLevelEntities.map(
            (it) => new DatamapGraphNode(it)
        );
        topLevelNodes.forEach((n) => {
            this.addNode(n);
            n.isRoot = true;
            n.fixed = false;
            this.appendDescendants(n, isChildVisible);
        });
    }

    public getAllEntitiesTypes() {
        return CollectionsHelper.distinctValues(
            this.allEntities,
            (it) => EntityType[it.SubTypeName]
        );
    }

    //#region overrides ExplorationGraphData
    public getDisplayableNodes() {
        return super.getDisplayableNodes() as Item[];
    }

    public getAllNodes() {
        return super.getAllNodes() as Item[];
    }

    public getDisplayableLinks() {
        return super.getDisplayableLinks() as Link[];
    }

    //#endregion

    public getParent(item: Item) {
        return this.getLinksOfTarget(item)[0]?.source;
    }

    public withDisplayableItems(action: (item: Item) => void) {
        this.getDisplayableNodes().forEach((it) => action(it));
    }

    public withAllItems(action: (item: Item) => void) {
        this.getAllNodes().forEach((it) => action(it));
    }

    public getMaxContextualAllLevelChildrenCount() {
        return (
            1 +
            CollectionsHelper.maxValue(
                this.getAllNodes(),
                (it) => it.contextualAllLevelChildrenCount
            )
        );
    }

    public getDescendants(item: Item) {
        if (!item.childrenClusterNodesId?.length) {
            return;
        }
        const descendants = new Array<Item>();
        this.withDescendants(item, (d) => descendants.push(d));
        return descendants;
    }

    public withDescendants(item: Item, action: (descendant: Item) => void) {
        if (!item.childrenClusterNodesId?.length) {
            return;
        }
        this.withItemOrDescendants(item, undefined, action);
    }

    public withItemAndDescendants(
        item: Item,
        action: (itemOrDescendant: Item) => void
    ) {
        this.withItemOrDescendants(item, action, action);
    }

    public withItemOrDescendants(
        item: Item,
        withItem?: (item: Item) => void,
        withDescendant?: (descendant: Item) => void
    ) {
        if (withItem) {
            withItem(item);
        }
        if (withDescendant) {
            const visit = (it: Item) => {
                withDescendant(it);
                this.withChildren(it, visit);
            };
            this.withChildren(item, visit);
        }
    }

    private appendDescendants(item: Item, isChildVisible: boolean) {
        item.clicked = false;
        const parentId = item.nodeObjectReferenceId;
        const childEntities = this.allEntities.filter(
            (ei) => ei.LogicalParentId == parentId
        );
        childEntities.forEach((ei) => {
            const child = this.appendChildEntity(item, ei, isChildVisible);
            if (ei.ContextualAllLevelChildrenCount) {
                this.appendDescendants(child, isChildVisible);
            }
        });
    }

    private appendChildEntity(
        item: Item,
        childEntity: EntityItem,
        isVisible: boolean
    ) {
        const child = new DatamapGraphNode(childEntity);
        child.level = item.level + 1;
        child.isVisible = isVisible;
        item.childrenClusterNodesId.push(childEntity.ReferenceId);
        this.addNode(child);
        this.addLink(
            new ExplorationGraphLink(
                null,
                item,
                child,
                'EntityLink',
                null,
                false,
                true,
                'EntityLink',
                false
            )
        );
        return child;
    }

    public getChildren(item: Item) {
        return super.getClusterChildren(item);
    }

    public withChildren(item: Item, action: (item: Item) => void) {
        return super.withClusterChildren(item, action);
    }

    public setExpandedCollapsed(item: Item, expanded: boolean) {
        item.clicked = expanded;
        if (!item.childrenClusterNodesId?.length) {
            return;
        }
        this.withDescendants(item, (descendant) => {
            descendant.clicked = expanded;
            descendant.isVisible = expanded;
        });
    }
}

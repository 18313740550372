import { TranslateService } from '@ngx-translate/core';
import {
    ChangeDetectorRef,
    Component,
    Input,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import {
    IDropdownSection,
    ISearchTermEvent,
    ITabItem,
    ITabsHeaderData,
} from '@datagalaxy/core-ui';
import { StringUtil } from '@datagalaxy/core-util';
import { DiagramListUiService } from '../diagram-list-ui.service';
import { DiagramStore } from '../DiagramStore';
import { IEntityIdentifier } from '@datagalaxy/dg-object-model';
import { DxyEntityGridComponent } from '../../shared/entity/entity-grid/dxy-entity-grid/dxy-entity-grid.component';
import { IEntityGridOptions } from '../../shared/entity/entity-grid/EntityGridOptions';
import { EntityEventService } from '../../shared/entity/services/entity-event.service';
import { EntityService } from '../../shared/entity/services/entity.service';
import { UserService } from '../../services/user.service';
import { ViewTypeService } from '../../services/viewType.service';
import { ModelerDataUtil } from '../../shared/util/ModelerDataUtil';
import {
    IOmniGridState,
    OmniGridColumnInfo,
} from '@datagalaxy/core-ui/omnigrid';
import { EntityGridColumnInfo } from '../../shared/entity/entity-grid/EntityGridTypes';
import { SearchApiService } from '@datagalaxy/webclient/search/data-access';
import { PublishingStatus } from '@datagalaxy/webclient/diagram/data-access';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { ISpaceIdentifier } from '@datagalaxy/webclient/workspace/domain';
import { userSettingsValues } from '@datagalaxy/webclient/user/domain';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';
import { ServerConstants } from '@datagalaxy/shared/server/domain';
import PropertyName = ServerConstants.PropertyName;

/**
 * ## Role
 * List of all diagrams for a specified workspace or a specified entity
 */
@Component({
    selector: 'app-diagrams-list',
    templateUrl: './diagrams-list.component.html',
    styleUrls: ['./diagrams-list.component.scss'],
})
export class DiagramsListComponent
    extends DxyBaseComponent
    implements OnInit, OnDestroy
{
    private static readonly defaultDisplayedColumns = [
        PropertyName.EntityType,
        PropertyName.Description,
        PropertyName.CreationUserId,
        PropertyName.CreationTime,
    ];

    @Input() persistenceGridId = userSettingsValues.omniGrid.routes.diagramList;
    /** If true, show a button to create a diagram */
    @Input() canCreate: boolean;
    @Input() space: ISpaceIdentifier;

    @Input() entityData: EntityItem;

    /** If true, private & public diagrams will be split in separate header tabs */
    @Input() usePublishingStatusTabs: boolean;
    /** If true, show a large placeholder with a title and a text if diagrams list is empty */
    @Input() useLargePlaceholder = false;

    /** Preview-panel context header */
    @Input() showHeader: boolean;

    @Input() excludedDefaultColumns: string[] = null;

    @ViewChild(DxyEntityGridComponent) entityGrid: DxyEntityGridComponent;

    public egOptions: IEntityGridOptions;
    public searchTerm: string;
    public filteredColumnListGroups: IDropdownSection[];
    public get loading() {
        return this.diagramStore.isLoading;
    }
    public get showSearch() {
        return this.diagrams.length >= 10;
    }
    public get gridVisible() {
        return this.diagrams.length >= 1;
    }
    public get placeholderVisible() {
        return !this.loading && !this.gridVisible;
    }
    public get diagramCount() {
        return this.diagrams.length;
    }
    public get showDiagramCount() {
        return !this.usePublishingStatusTabs && this.diagramCount;
    }
    public get placeholderSrc() {
        return `images/placeholders/diagrams-${
            this.activeTab.tabId === 'private' ? 'private' : 'public'
        }.svg`;
    }

    // #region tabs
    public tabsHeaderData: ITabsHeaderData<PublishingStatus> = {
        tabItems: [
            {
                tabId: 'public',
                tabTranslateKey: 'UI.Diagrams.Public',
                showEmptyDataCount: true,
                data: PublishingStatus.Public,
            },
            {
                tabId: 'private',
                tabTranslateKey: 'UI.Diagrams.Private',
                showEmptyDataCount: true,
                data: PublishingStatus.Private,
            },
            {
                tabId: 'other',
                tabTranslateKey: 'UI.Diagrams.unknownStatus',
                showEmptyDataCount: true,
                data: undefined,
                hiddenIfNoDataCount: true,
            },
        ],
    };
    protected activeTab = this.tabsHeaderData.tabItems[0];
    // #endregion

    private diagramStore: DiagramStore;
    private get diagrams() {
        if (this.usePublishingStatusTabs) {
            return this.activeTab.data === PublishingStatus.Public
                ? this.diagramStore.getPublicDiagrams()
                : this.diagramStore.getPrivateDiagrams();
        }
        return this.diagramStore.getAllDiagrams();
    }

    constructor(
        private translate: TranslateService,
        private diagramListUiService: DiagramListUiService,
        private searchApiService: SearchApiService,
        private entityEventService: EntityEventService,
        private entityService: EntityService,
        private userService: UserService,
        private cd: ChangeDetectorRef,
        private viewTypeService: ViewTypeService
    ) {
        super();
    }

    ngOnInit() {
        this.initAsync();
    }
    ngOnDestroy() {
        super.ngOnDestroy();
        this.diagramStore.dispose();
    }

    //#region events
    public async onTabChange(tab: ITabItem<PublishingStatus>) {
        this.activeTab = tab;
        this.searchTerm = '';
        this.updateGridItems(this.diagrams);
    }

    public onGridReady() {
        this.setFilteredColumns();
        this.preventNg0100Error(this.cd);
    }

    public async onCreateDiagram() {
        const model = this.entityData;
        const modelIdr: IEntityIdentifier =
            model &&
            ModelerDataUtil.isModelRelational(
                model.ServerType,
                model.HddData.Data
            )
                ? model
                : null;
        await this.diagramListUiService.createDiagram(modelIdr);
    }

    public onSearchTermChange(event: ISearchTermEvent) {
        const items = StringUtil.filterSearched(
            event.searchString,
            this.diagrams,
            (d) => this.viewTypeService.getTechnicalOrDisplayName(d)
        );
        this.updateGridItems(items);
    }
    //#endregion events

    //#region entity grid api
    public async setColumnVisibility(column: OmniGridColumnInfo) {
        await this.entityGrid?.setColumnVisibility(
            column as EntityGridColumnInfo
        );
        const attributeKeys = this.entityGrid?.columnsList
            ?.filter((d) => d.visible)
            .map((d) => d.attributeKey);
        this.diagramStore.setAdditionalAttributeKeys(attributeKeys);
        await this.diagramStore.init(this.entityData || this.space);
    }
    public async resetColumnStates() {
        await this.entityGrid?.resetColumnStates();
        this.setFilteredColumns();
    }
    public filterColumns(searchTerm: string) {
        this.entityGrid?.filterColumns(searchTerm);
        this.setFilteredColumns();
    }

    private setFilteredColumns() {
        this.filteredColumnListGroups =
            this.entityGrid?.filteredColumnListGroups;
    }
    //#endregion entity grid api

    private updateGridItems(items: EntityItem[]) {
        this.egOptions = {
            ...this.egOptions,
            directData: {
                items,
            },
        };
    }

    private async initAsync() {
        await this.initStore();
    }

    private async initStore() {
        this.log('initStore', this.entityData, this.space);
        this.diagramStore = new DiagramStore(
            this.searchApiService,
            this.entityEventService,
            this.entityService,
            {
                onChange: () => this.onStoreUpdate(),
                debug: this.debug,
                logId: this.logId ?? 'diagrams-list',
            }
        );
        const displayedColumns = await this.getPersistenceGridColumns();
        this.diagramStore.setAdditionalAttributeKeys(displayedColumns);
        await this.diagramStore.init(this.entityData || this.space);
        await this.onStoreUpdate();
    }
    private async onStoreUpdate() {
        const items = this.diagrams;
        const entityAttributes =
            await this.entityService.getEntityAttributesForDiagramGrid();
        const wantedColumns = this.computeDefaultcolumns();

        if (!this.usePublishingStatusTabs) {
            wantedColumns.push(ServerConstants.Diagram.PublishingStatus);
        }

        const primaryDisplayNameKey = `DgServerTypes.BaseData.fields.${
            this.viewTypeService.isTechnicalView
                ? 'TechnicalName'
                : 'DisplayName'
        }`;

        this.egOptions = {
            primaryName: PropertyName.DisplayName,
            primaryDisplayName: this.translate.instant(primaryDisplayNameKey),
            isMultiSelect: false,
            noMessagesAndTitleBar: true,
            gridStatePersistenceId: this.persistenceGridId,
            canSaveGridState: true,
            wantedColumns,
            entityAttributes,
            isSingleServerType: true,
            optionalActions:
                this.diagramListUiService.getAvailableEntityActions({
                    navigateOnClone: false,
                }),
            directData: { items, groupColumnMinWidth: 200 },
            canMoveColumns: true,
            excludedAttributePaths: [
                ServerConstants.Diagram.VisualData,
                ServerConstants.PropertyName.IsWatchedByCurrentUser,
            ],
        };
        this.setTabCounts();
    }

    private setTabCounts() {
        this.tabsHeaderData.tabItems.forEach((ti) => {
            switch (ti.data) {
                case PublishingStatus.Private:
                    ti.contentDataCount =
                        this.diagramStore.getPrivateDiagrams()?.length;
                    break;
                case PublishingStatus.Public:
                    ti.contentDataCount =
                        this.diagramStore.getPublicDiagrams()?.length;
                    break;
            }
        });
    }

    private async getPersistenceGridColumns() {
        const settingsValue = await this.userService.getUserSettingValue(
            userSettingsValues.omniGrid.category,
            this.persistenceGridId
        );
        const gridState =
            settingsValue?.Value &&
            (JSON.parse(settingsValue.Value) as IOmniGridState);
        const userSelectedColumns = gridState?.columns
            ?.filter((c) => !c.hide)
            .map((c) => c.colId);

        return userSelectedColumns?.length
            ? userSelectedColumns
            : this.computeDefaultcolumns();
    }

    private computeDefaultcolumns(): string[] {
        // Consumer has the opportunity to drop some of the defaults columns
        return DiagramsListComponent.defaultDisplayedColumns.filter(
            (column) => !this.excludedDefaultColumns?.includes(column)
        );
    }
}

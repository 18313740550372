import { NgModule } from '@angular/core';
import { DxySharedEntityModule } from '../shared/entity/DxySharedEntityModule';
import { DxyEntityPanelBodyComponent } from './dxy-entity-panel-body/dxy-entity-panel-body.component';
import { DxyEntityPanelHeaderComponent } from './dxy-entity-panel-header/dxy-entity-panel-header.component';
import { DxyEntityPreviewPanelContentComponent } from './dxy-entity-preview-panel-content/dxy-entity-preview-panel-content.component';
import { EntityPreviewPanelSkeletonComponent } from './entity-preview-panel-skeleton/entity-preview-panel-skeleton.component';
import { SharedModule } from '../shared/shared.module';
import { DxyInsightsModule } from '../insights/DxyInsightsModule';
import { DxyTaskModule } from '../tasks/DxyTaskModule';
import { DxyCommentaryModule } from '../commentary/DxyCommentaryModule';
import { SuggestionModule } from '../suggestions/suggestion.module';
import { DxyVersioningModule } from '../versioning/DxyVersioningModule';
import { DxyActivityLogModule } from '../activityLog/DxyActivityLogModule';
import { DxyModelerModule } from '../modeler/DxyModelerModule';
import { EntityDockingPaneComponent } from './entity-docking-pane/entity-docking-pane.component';
import { DataQualityModule } from '../data-quality/data-quality.module';
import { CampaignContainerComponent } from './entity-panel-containers/campaign-container/campaign-container.component';
import { DxyOmnigridModule } from '@datagalaxy/core-ui/omnigrid';
import { SharedDiagramModule } from '../diagrams/shared-diagram.module';
import { MultilingualFeatureModule } from '@datagalaxy/webclient/multilingual/feature';

@NgModule({
    imports: [
        SharedModule,
        DxySharedEntityModule,
        DxyInsightsModule,
        DxyTaskModule,
        DxyCommentaryModule,
        SuggestionModule,
        DxyVersioningModule,
        DxyActivityLogModule,
        DxyModelerModule, // for PrimaryKeyPreviewPaneComponent in entity-panel-body
        SharedDiagramModule, // for DiagramsListComponent in entity-panel-body
        DataQualityModule,
        DxyOmnigridModule,
        MultilingualFeatureModule,
        CampaignContainerComponent,
    ],
    declarations: [
        DxyEntityPreviewPanelContentComponent,
        DxyEntityPanelHeaderComponent,
        DxyEntityPanelBodyComponent,
        EntityDockingPaneComponent,
        EntityPreviewPanelSkeletonComponent,
    ],
    exports: [EntityDockingPaneComponent],
})
export class DxyEntityPanelsModule {}

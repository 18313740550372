import { Subject } from 'rxjs';
import { Injectable, NgZone } from '@angular/core';
import {
    GraphSurface,
    IConnectingSpec,
    IDiagEdge,
    IDiagNode,
    PortKind,
    PortUsage,
    ShapeId,
    SurfaceLayer,
} from '@datagalaxy/core-d3-util';
import { BaseUiService } from '@datagalaxy/core-ui';
import { CollectionsHelper, DomUtil } from '@datagalaxy/core-util';
import { DataProcessingUiService } from '../services/data-processing-ui.service';
import { GraphicalContainer } from './models/GraphicalContainer';
import { GraphicalEntityData } from './models/GraphicalEntityData';
import { GraphicalItem } from './models/GraphicalItem';
import {
    DataProcessingLinkDirection,
    DataProcessingLinkEntityType,
} from '../data-processing.types';
import {
    IConnectorInfo,
    IDpItemInfo,
    IDpItemLinkMenuInfo,
    IMappingItemInfo,
    TDir,
    TDpiTargetDatum,
    TNodeType,
} from './dp-mapping.types';
import {
    HierarchicalData,
    ObjectLinkType,
    ServerType,
} from '@datagalaxy/dg-object-model';
import { EntityUiService } from '../../shared/entity/services/entity-ui.service';
import { ViewTypeService } from '../../services/viewType.service';
import { IXYRectRO, Rect } from '@datagalaxy/core-2d-util';
import { Project } from '@datagalaxy/webclient/workspace/data-access';
import {
    DataProcessingItemDto,
    DataProcessingItemType,
} from '@datagalaxy/webclient/data-processing/data-access';
import { SpaceIdentifier } from '@datagalaxy/webclient/workspace/utils';
import { ZoneUtils } from '@datagalaxy/utils';
import { ISpaceIdentifier } from '@datagalaxy/webclient/workspace/domain';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';
import { GraphicalColor } from '@datagalaxy/shared/graphical/domain';

/** ## Role
 * Manage the Data-Processing Mapping UI
 *
 * - provide data and respond to UI components
 * - draws links & allow for connecting by mouse
 */
@Injectable({ providedIn: 'root' })
export class DpMappingUiService extends BaseUiService {
    private readonly debugNoPopover = true;
    private readonly debugDiagramSurface = false;

    public get onShowLinkMenu$() {
        return this.onShowLinkMenu.asObservable();
    }
    public get spaceIdr() {
        return this._spaceIdr;
    }
    public get data() {
        return this.dataProcessingUiService.implementationData;
    }
    public get dpEntityData() {
        return this.data.entityData;
    }
    public get hasWriteAccess() {
        return this.dataProcessingUiService.hasWriteAccess;
    }
    public get tooltipsEnabled() {
        return this._tooltipsEnabled;
    }
    public get noPopover() {
        return (
            this.isConnectionBeingDragged || (this.debug && this.debugNoPopover)
        );
    }
    public get isConnectionBeingDragged() {
        return this.diagramSurface?.isConnectionBeingDragged;
    }

    private readonly onShowLinkMenu = new Subject<IDpItemLinkMenuInfo>();

    private readonly dpiInfos = new Map<DataProcessingItemDto, IDpItemInfo>();
    /** key is IConnectorInfo.objectId aka "${dir}|${targetId}|${dpiId}" */
    private readonly connectorInfos = new Map<string, IConnectorInfo>();
    private readonly itemInfos = new Map<Element, IMappingItemInfo>();
    /** key is itemId:
     * - from dp-item-element: linkedItem.id: GraphicalItem&lt;GraphicalEntityData&gt;.id
     * - from dp-mapping-item: linkedItem.ReferenceId: DataProcessingItemDto.ReferenceId */
    private readonly itemElements = new Map<string, HTMLElement>();
    private container: HTMLElement;
    private diagramSurface: GraphSurface;
    private isCreatingDpi = false;
    private _spaceIdr: ISpaceIdentifier;
    private _tooltipsEnabled = true;
    private repaintEverythingTimer: number;
    private createMissingConnectionsTimer: number;
    private initDone = false;

    constructor(
        private ngZone: NgZone,
        private dataProcessingUiService: DataProcessingUiService,
        private entityUiService: EntityUiService,
        private viewTypeService: ViewTypeService
    ) {
        super();
    }

    //#region for routing (called before loading the main component)

    public async preload(dataProcessing: EntityItem, spaceData: Project) {
        this.log('preload-in', dataProcessing);
        this._spaceIdr = SpaceIdentifier.from(spaceData);
        await this.dataProcessingUiService.loadImplementation(dataProcessing);
        this.log('preload-out');
    }

    //#endregion

    //#region for dp-mapping (the main component)

    public preInitUI(container: HTMLElement) {
        this.log('preInitUI', container);
        this.initDone = false;
        this.container = container;
        this.dataProcessingUiService.refreshImplementation(true);
    }
    public async postInitUI() {
        await this.initSurface();
        this.initDone = true;
        this.manageNodes(...this.itemElements.values());
        this.requestCreateMissingConnections();
        setTimeout(() => this.diagramSurface.viewport.updateViewport(), 333);
    }
    public onSurfaceDestroy() {
        this.diagramSurface?.dispose();
        this.unsubscribeUI();
    }
    public async createDPI() {
        if (this.isCreatingDpi) {
            return false;
        }
        this.isCreatingDpi = true;
        try {
            await this.dataProcessingUiService.createDataProcessingItem(
                this.dpEntityData,
                DataProcessingItemType.Copy,
                null,
                null
            );
        } finally {
            this.isCreatingDpi = false;
        }
        return true;
    }
    public async addItem(
        item: EntityItem,
        objectLink: ObjectLinkType,
        includeChildren = false
    ) {
        const linkedEntityType =
            includeChildren && item.ServerType == ServerType.Table
                ? DataProcessingLinkEntityType.Column
                : (DataProcessingLinkEntityType[
                      item.DataTypeName
                  ] as DataProcessingLinkEntityType);
        await this.dataProcessingUiService.addDataProcessingEntityLink(
            this.dpEntityData,
            objectLink,
            [item],
            linkedEntityType
        );
    }
    public onLayoutColumnScrolled() {
        this.requestRepaint();
    }
    public onGraphicalAreaResized() {
        this.diagramSurface?.viewport.updateViewport({ debounce: true });
    }

    private async initSurface() {
        this.diagramSurface = new GraphSurface(this.container, {
            disablePanAndZoom: true,
            debug: this.debug && this.debugDiagramSurface,
            hover: {
                hoverClass: 'highlighted',
            },
            linking: {
                getNewEdgeInfo: (source, target) =>
                    this.connectionOnNew(source, target),
                getConnectingSpec: (source: IDiagNode) => {
                    if (!this.hasWriteAccess) {
                        return;
                    }
                    const info = this.itemInfos.get(source.data.el);
                    if (this.isInOrOutWithSubItems(info)) {
                        this.log(
                            'getConnectingSpec',
                            'prevented: item has sub-items',
                            info
                        );
                        return;
                    }
                    const spec: IConnectingSpec = {
                        thickness: 1,
                        color: GraphicalColor.Gray,
                        srcEp:
                            info.type == 'Out'
                                ? { shapeId: ShapeId[ShapeId.arrowLine6x6] }
                                : undefined,
                        inverted: info.type == 'Out',
                        tgtEp:
                            info.type == 'In'
                                ? { shapeId: ShapeId[ShapeId.arrowLine6x6] }
                                : undefined,
                    };
                    return spec;
                },
                allowConnect: (source: IDiagNode, target: IDiagNode) => {
                    const src = this.itemInfos.get(source.data.el);
                    const tgt = this.itemInfos.get(target.data.el);

                    // forbid In<->In, Process<->Process and Out<->Out
                    if (src.type == tgt.type) {
                        this.log(
                            'allowConnect',
                            'prevented: same layout column'
                        );
                        return false;
                    }

                    // prevent drop on item if it has sub-items (displayed or not)
                    if (this.isInOrOutWithSubItems(tgt)) {
                        this.log(
                            'allowConnect',
                            'prevented: In or Out target item has sub-items',
                            tgt
                        );
                        return false;
                    }

                    // prevent recreating an existing connection
                    const srcId = source.id,
                        tgtId = target.id;
                    for (const ci of this.connectorInfos.values()) {
                        const c = ci.connection as IDiagEdge;
                        if (
                            c &&
                            ((c.source.id == srcId && c.target.id == tgtId) ||
                                (c.source.id == tgtId && c.target.id == srcId))
                        ) {
                            this.log('allowConnect', 'already connected');
                            return false;
                        }
                    }
                    return true;
                },
            },
            selection: {
                disabled: true,
            },
            endpoint: {
                disabled: true,
            },
            graph: {
                noDecimals: true,
                edges: {
                    noEditOnClick: true,
                    noDisconnect: true,
                    editor: false,
                    connectors: {
                        routing: 'orthogonal',
                        radius: 10,
                        thickness: 1,
                        color: GraphicalColor.Gray,
                    },
                },
            },
        });
        this.diagramSurface.events.edgeHovered$.subscribe((e) => {
            const source = this.itemElements.get(
                e.edge.source.id.replace('item-', '')
            );
            const target = this.itemElements.get(
                e.edge.target.id.replace('item-', '')
            );

            if (e.show) {
                source.classList.add('item-highlighted');
                target.classList.add('item-highlighted');
            } else {
                source.classList.remove('item-highlighted');
                target.classList.remove('item-highlighted');
            }
        });
    }

    private isInOrOutWithSubItems(info: IMappingItemInfo) {
        if (!info || (info.type != 'In' && info.type != 'Out')) {
            return;
        }
        if (info.data.DataServerType == ServerType.Column) {
            return false;
        }
        const dir = DataProcessingLinkDirection[info.type];
        const container =
            this.dataProcessingUiService.findContainerByParentDataReferenceId(
                info.data.DataReferenceId,
                dir
            );
        return !!container?.itemsCount;
    }
    //#endregion

    //#region for baseMappingItem: dp-item-element, dp-mapping-item
    public onBaseMappingItemPostInit(
        element: HTMLElement,
        itemId: string,
        data: GraphicalEntityData,
        type: TNodeType,
        isVirtual: boolean
    ) {
        this.log('onBaseMappingItemPostInit');
        this.itemInfos.set(element, { itemId, data, type, isVirtual });
        this.itemElements.set(itemId, element);
        element.id = this.getNodeId(itemId);
        this.manageNodes(element);
        this.requestCreateMissingConnections();
    }
    public onBaseMappingItemDestroy(element: Element) {
        const info = this.itemInfos.get(element);
        this.itemInfos.delete(element);
        info && this.itemElements.delete(info.itemId);
        this.requestRepaint();
    }
    //#endregion

    //#region for dp-item-element (elements in 'Process' layout column)
    public showDpiEditModal(linkedItem: DataProcessingItemDto) {
        this.dataProcessingUiService.showDpiEditModal(
            linkedItem,
            this.dpEntityData
        );
    }
    public toggleDpItemElementHover(
        item: DataProcessingItemDto,
        hover: boolean
    ) {
        this.log('toggleDpItemElementHover', item, hover);
        item.highlighted = hover;
        this.highlightDpiLinks(item, hover, true);
        this.highlightDpiLinks(item, hover, false);
        this.hilightConnectors(
            hover,
            ...CollectionsHelper.flattenGroups(item.InputLinks, (hd) =>
                this.getItemHdConnectorKeys(item, hd, 'In')
            ),
            ...CollectionsHelper.flattenGroups(item.OutputLinks, (hd) =>
                this.getItemHdConnectorKeys(item, hd, 'Out')
            )
        );
    }
    public async deleteDpItem(linkedItem: DataProcessingItemDto) {
        const confirmed = await this.entityUiService.confirmDelete(
            ServerType.DataProcessingItem,
            { featureCode: 'DATA_PROESSING_ITEM,D' }
        );
        if (!confirmed) {
            return;
        }
        await this.dataProcessingUiService.deleteDataProcessingItem(
            this.data.entityData,
            linkedItem
        );
    }
    public toggleDpItemElementConnection(dpi: DataProcessingItemDto) {
        this.getConnectorInfos((_, k) => k.endsWith(dpi.ReferenceId)).forEach(
            (ci) => this.showHideConnection(ci.connection)
        );
    }
    private showHideConnection(c: IDiagEdge) {
        this.diagramSurface.graph.updateEdge(c.id, {
            hidden: !(c as IDiagEdge).connector.hidden,
        });
    }
    public onDpItemElementPreInit(linkedItem: DataProcessingItemDto) {
        const dpiInfo: IDpItemInfo = { linkedItem, connectorInfos: [] };
        this.initDpItemLinkInfos(dpiInfo);
        this.dpiInfos.set(linkedItem, dpiInfo);
        return GraphicalEntityData.fromDataProcessingItemDto(linkedItem);
    }
    public async onDpItemElementPostInit(linkedItem: DataProcessingItemDto) {
        this.log('onDpItemElementPostInit', linkedItem);
        const conInfos = this.dpiInfos.get(linkedItem).connectorInfos;
        conInfos.forEach((ci) => this.connectorInfos.set(ci.objectId, ci));
        this.requestCreateMissingConnections(conInfos);
    }
    public onItemElementDestroy(linkedItem: DataProcessingItemDto) {
        const dpiInfo = this.dpiInfos.get(linkedItem);
        this.removeItemElementLinks(dpiInfo);
        this.dpiInfos.delete(linkedItem);
        this.requestRepaint();
    }

    private initDpItemLinkInfos(itemInfo: IDpItemInfo) {
        const initPartial = (isVirtual: boolean, dir: TDir) => {
            const targetData = this.getDpiTargetData(
                itemInfo.linkedItem,
                isVirtual,
                dir
            );
            targetData.forEach((td) =>
                this.initDpItemLinkInfo(itemInfo, isVirtual, dir, td)
            );
            return targetData;
        };
        const realIn = initPartial(false, 'In');
        const realOut = initPartial(false, 'Out');
        const virtualIn = initPartial(true, 'In');
        const virtualOut = initPartial(true, 'Out');

        this.log('updateDpItemLinkInfo-result', itemInfo, {
            realIn,
            realOut,
            virtualIn,
            virtualOut,
        });
        return itemInfo;
    }
    private getDpiTargetData(
        linkedItem: DataProcessingItemDto,
        isVirtual: boolean,
        dir: TDir
    ): TDpiTargetDatum[] {
        if (isVirtual) {
            const gcs =
                dir == 'In' ? this.data.inputLinks : this.data.outputLinks;
            return gcs.filter((gc) =>
                this.isLinkWithContainerItem(linkedItem, gc, dir)
            );
        } else {
            const hds =
                dir == 'In' ? linkedItem.InputLinks : linkedItem.OutputLinks;
            return hds.filter((hd) => !this.isContainerCollapsed(hd, dir));
        }
    }
    private initDpItemLinkInfo(
        dpiInfo: IDpItemInfo,
        isVirtual: boolean,
        dir: TDir,
        targetDatum: TDpiTargetDatum
    ) {
        const conInfo = this.makeConnectorInfo(
            dpiInfo.linkedItem,
            isVirtual,
            dir,
            targetDatum
        );
        dpiInfo.connectorInfos.push(conInfo);
        this.connectorInfos.set(conInfo.objectId, conInfo);
        return conInfo;
    }
    private makeConnectorInfo(
        linkedItem: DataProcessingItemDto,
        isVirtual: boolean,
        dir: TDir,
        targetDatum: TDpiTargetDatum
    ): IConnectorInfo {
        const dpiId = linkedItem.ReferenceId;
        const targetId = this.getDpItemLinkTargetId(targetDatum, dir);
        const isIn = dir == 'In';
        return {
            isVirtual,
            dir,
            dpiId,
            objectId: `${dir}|${targetId}|${dpiId}`,
            targetItemId: isIn ? dpiId : `OutputLinks_${targetId}`,
            sourceItemId: isIn ? `InputLinks_${targetId}` : dpiId,
            targetDatum,
        };
    }
    private getDpItemLinkTargetId(targetDatum: TDpiTargetDatum, dir: TDir) {
        return targetDatum instanceof GraphicalContainer
            ? this.getCollapsedTargetId(targetDatum)
            : this.getGraphicalItemTargetId(targetDatum, dir);
    }
    private getCollapsedTargetId(container: GraphicalContainer) {
        return container.parentData.DataReferenceId;
    }
    private getGraphicalItemTargetId(hd: HierarchicalData, dir: TDir) {
        return this.dataProcessingUiService.getGraphicalItemTargetId(
            hd,
            DataProcessingLinkDirection[dir]
        );
    }
    private isContainerCollapsed(hd: HierarchicalData, dir: TDir) {
        if (hd.Data.DataServerType != ServerType.Column) {
            return false;
        }
        const container = this.dataProcessingUiService.findContainerForColumn(
            hd.DataReferenceId,
            DataProcessingLinkDirection[dir]
        );
        return !!container?.isCollapsed;
    }
    private isLinkWithContainerItem(
        linkedItem: DataProcessingItemDto,
        container: GraphicalContainer,
        dir: TDir
    ) {
        const direction = DataProcessingLinkDirection[dir];
        return (
            container.isCollapsed &&
            this.hasConnection(linkedItem, container, direction)
        );
    }
    private hasConnection(
        linkedItem: DataProcessingItemDto,
        container: GraphicalContainer,
        direction: DataProcessingLinkDirection
    ) {
        const dataList =
            direction === DataProcessingLinkDirection.In
                ? linkedItem.InputLinks
                : linkedItem.OutputLinks;
        const dataListName = DataProcessingUiService.getDataListName(direction);
        return dataList.some((d) =>
            container.hasItem(`${dataListName}_${d.Data.DataReferenceId}`)
        );
    }
    private requestCreateMissingConnections(conInfos?: IConnectorInfo[]) {
        if (!this.initDone) {
            return;
        }
        this.log('requestCreateMissingConnections');
        window.clearTimeout(this.createMissingConnectionsTimer);
        const wrapped = () => {
            const action = () => {
                this.requestRepaint();
                const connectionsToCreate = (
                    conInfos ?? Array.from(this.connectorInfos.values())
                ).filter((ci) => !ci.connection);
                connectionsToCreate.forEach((info) =>
                    this.createConnection(info)
                );
                this.log(
                    'requestCreateMissingConnections-done',
                    conInfos?.length,
                    connectionsToCreate.length
                );
            };
            action();
        };
        this.createMissingConnectionsTimer = ZoneUtils.zoneTimeout(
            wrapped,
            250,
            this.ngZone,
            true
        );
    }
    private createConnection(conInfo: IConnectorInfo) {
        this.diagramSurface.graph.addEdges({
            source: this.getNodeId(conInfo.sourceItemId),
            target: this.getNodeId(conInfo.targetItemId),
            cssClass: conInfo.isVirtual ? 'virtual' : undefined,
            shapeId: ShapeId.arrowPlain45fb,
            data: conInfo,
            id: conInfo.objectId,
        });
        const edge = this.diagramSurface.graph.getEdgeById(conInfo.objectId);
        conInfo.connection = edge;
        DomUtil.addListener(
            edge.connector.el,
            ['contextmenu', 'mouseenter', 'mouseleave'],
            (e) => {
                if (!conInfo || conInfo.isVirtual) {
                    return;
                }
                if (e.type == 'contextmenu') {
                    e.preventDefault();
                    this.onShowLinkMenu.next({ info: conInfo, event: e });
                } else {
                    this.hilightConnection(
                        conInfo.connection,
                        e.type == 'mouseenter'
                    );
                }
            },
            this.ngZone,
            true
        );
    }

    private removeItemElementLinks(dpiInfo: IDpItemInfo) {
        const linkInfos = dpiInfo?.connectorInfos;
        this.log('removeItemElementLinks', dpiInfo, linkInfos);
        if (!linkInfos) {
            return;
        }
        linkInfos.forEach((linkInfo) => {
            this.detachConnector(linkInfo);
            this.connectorInfos.delete(linkInfo.objectId);
        });
        linkInfos.length = 0;
    }
    //#endregion

    //#region for dp-implem-element (elements in 'In' and 'Out' layout columns)
    public onBeforeExpandCollapse(container: GraphicalContainer) {
        container.isCollapsed = !container.isCollapsed;
    }
    public onAfterExpandCollapse() {
        this.rebuildAllConnectors();
    }
    public getColumnDisplayName(data: GraphicalEntityData) {
        return this.viewTypeService.getTechnicalOrDisplayName(data);
    }
    public async deleteContainerLinks(
        container: GraphicalContainer,
        isIn: boolean
    ) {
        this.log('deleteContainerLinks', container, isIn);
        await this.dataProcessingUiService.deleteLinkedData(
            this.dpEntityData,
            container.parentData,
            isIn
                ? DataProcessingLinkDirection.In
                : DataProcessingLinkDirection.Out
        );
        this.removeImplemConnections(
            isIn,
            container.parentData,
            ...container.getItems().map((it) => it.data)
        );
    }
    public async deleteColumn(item: GraphicalItem, isIn: boolean) {
        this.log('deleteColumn', item, isIn);
        await this.dataProcessingUiService.deleteLinkedData(
            this.dpEntityData,
            item.data,
            isIn
                ? DataProcessingLinkDirection.In
                : DataProcessingLinkDirection.Out
        );
        this.removeImplemConnections(isIn, item.data);
    }
    private removeImplemConnections(
        isIn: boolean,
        ...data: GraphicalEntityData[]
    ) {
        this.log('removeImplemConnections', isIn, data);
        CollectionsHelper.withMap(
            this.itemInfos,
            (itemInfo) => data.includes(itemInfo.data),
            (itemInfo) => {
                CollectionsHelper.withMap(
                    this.connectorInfos,
                    (conInfo) =>
                        conInfo.connection &&
                        (isIn
                            ? conInfo.sourceItemId == itemInfo.itemId
                            : conInfo.targetItemId == itemInfo.itemId),
                    (conInfo, objectId) => {
                        this.detachConnector(conInfo);
                        this.connectorInfos.delete(objectId);
                        CollectionsHelper.withMap(
                            this.dpiInfos,
                            (dpiInfo) =>
                                dpiInfo.connectorInfos?.includes(conInfo),
                            (dpiInfo) => {
                                CollectionsHelper.removeElement(
                                    dpiInfo.connectorInfos,
                                    conInfo
                                );
                            }
                        );
                    }
                );
            }
        );
    }
    public toggleImplemItemHover(
        item: GraphicalItem,
        isIn: boolean,
        hover: boolean,
        withItems = true
    ) {
        const otherDir: TDir = isIn ? 'Out' : 'In';
        const conInfos = this.getConnectorInfosFromImplemItem(item, isIn);
        this.log('toggleImplemItemHover', item, isIn, hover, conInfos);
        item.highlighted = hover;
        conInfos.forEach((ci) => {
            this.hilightConnection(ci?.connection, hover);
            if (!withItems || !ci.dpiId) {
                return;
            }
            const otherConnectorKeys: string[] = [];
            this.data.items
                .filter((item) => item.ReferenceId == ci.dpiId)
                .forEach((item) => {
                    const links = isIn ? item.OutputLinks : item.InputLinks;
                    item.highlighted = hover;

                    if (isIn) {
                        this.highlightDpiLinks(item, hover, false);
                    }

                    if (!isIn) {
                        this.highlightDpiLinks(item, hover, true);
                    }

                    links.forEach((hd) => {
                        otherConnectorKeys.push(
                            ...this.getItemHdConnectorKeys(item, hd, otherDir)
                        );
                    });
                });
            this.hilightConnectors(hover, ...otherConnectorKeys);
        });
    }

    private highlightDpiLinks(
        item: DataProcessingItemDto,
        highlight,
        isIn: boolean
    ) {
        const hdList = isIn ? item.InputLinks : item.OutputLinks;
        const outputIds = hdList.map((il) => il.DataReferenceId);
        const graphicalContainers = isIn
            ? this.data.inputLinks
            : this.data.outputLinks;

        graphicalContainers.forEach((graphicalContainer) => {
            if (
                !graphicalContainer.itemsCount ||
                graphicalContainer.isCollapsed
            ) {
                if (
                    outputIds.includes(
                        graphicalContainer.collapsedItem.entityIdentifier
                            .ReferenceId
                    ) ||
                    graphicalContainer
                        .getItems()
                        .some((item) =>
                            outputIds.includes(
                                item.entityIdentifier.ReferenceId
                            )
                        )
                ) {
                    graphicalContainer.collapsedItem.highlighted = highlight;
                }
            } else {
                graphicalContainer.getItems().forEach((item) => {
                    if (outputIds.includes(item.entityIdentifier.ReferenceId)) {
                        item.highlighted = highlight;
                    }
                });
            }
        });
    }

    private getItemHdConnectorKeys(
        item: DataProcessingItemDto,
        hd: HierarchicalData,
        dir: TDir
    ) {
        const container = this.dataProcessingUiService.findContainingDpLink(
            hd,
            DataProcessingLinkDirection[dir]
        );
        return [
            `${dir}|${container.parentData.DataReferenceId}|${item.ReferenceId}`,
            `${dir}|${hd.DataReferenceId}|${item.ReferenceId}`,
        ];
    }
    private getConnectorInfosFromImplemItem(
        item: GraphicalItem,
        isIn: boolean
    ) {
        const dir = isIn ? 'In' : 'Out';
        const id = item.data.DataReferenceId;
        const prefix = `${dir}|${id}`;
        return this.getConnectorInfos((_, k) => k.startsWith(prefix));
    }

    private rebuildAllConnectors() {
        Array.from(this.dpiInfos.values()).forEach((itemInfo) => {
            this.removeItemElementLinks(itemInfo);
            this.initDpItemLinkInfos(itemInfo);
        });
        this.requestCreateMissingConnections();
    }

    //#endregion

    //#region for dp-item-link-menu
    public onLinkMenuOpenClose(isOpen: boolean) {
        this._tooltipsEnabled = !isOpen;
    }
    public async deleteItemLink(info: IConnectorInfo) {
        await this.dataProcessingUiService.deleteItemLinkData(
            this.dpEntityData,
            info.dpiId,
            info.targetDatum,
            DataProcessingLinkDirection[info.dir]
        );
        this.onLinkMenuOpenClose(false);
        this.detachConnector(info);
        this.connectorInfos.delete(info.objectId);
        info.connection = undefined;
    }
    //#endregion

    private manageNodes(...elements: HTMLElement[]) {
        if (!this.initDone) {
            return;
        }
        this.diagramSurface.graph.addNodesFromElements(elements, {
            layer: SurfaceLayer.none,
            undraggable: true,
            container: this.container,
            ports: () => {
                return [{ kind: PortKind.node, usage: PortUsage.both }];
            },
        });
    }

    private requestRepaint() {
        this.log('requestRepaint');
        window.clearTimeout(this.repaintEverythingTimer);
        this.repaintEverythingTimer = ZoneUtils.zoneTimeout(
            () => {
                this.redrawSurfaceEdges();
                this.log('requestRepaint-done');
            },
            50,
            this.ngZone,
            true
        );
    }

    private redrawSurfaceEdges() {
        const containerRect = this.container.getBoundingClientRect();
        this.diagramSurface.graph
            .getNodes()
            .forEach((n) => this.updateSurfaceNodeBounds(n, containerRect));
    }
    private updateSurfaceNodeBounds(n: IDiagNode, containerRect: IXYRectRO) {
        const rect = Rect.from(n.data.el.getBoundingClientRect())
            .makeRelativeTo(containerRect)
            // for when nodes are wider than their column:
            // limit node width to column width minus padding
            .clampSize(
                undefined,
                Rect.augmentBy(
                    n.data.el.closest('.mapping-zone')?.getBoundingClientRect(),
                    -10,
                    0
                )
            );
        this.diagramSurface.graph.updateNode(n.id, {
            rect,
        });
    }

    private async connectionOnNew(src: string, tgt: string) {
        this.log('connectionOnNew', src, tgt);
        const values = Array.from(this.itemInfos.values());
        const source = values.find(
            (value) => value.itemId === src.replace('item-', '')
        );
        const target = values.find(
            (value) => value.itemId === tgt.replace('item-', '')
        );
        await this.dataProcessingUiService.addItemLinkData(
            this.dpEntityData,
            source.data,
            target.data
        );
        this.dataProcessingUiService.refreshImplementation(false);
        this.rebuildAllConnectors();
        return null;
    }

    private detachConnector(ci: IConnectorInfo) {
        this.diagramSurface.graph.removeEdges(ci.objectId);
    }

    private getConnectorInfos(
        filter: (info: IConnectorInfo, connectorKey: string) => boolean
    ) {
        return CollectionsHelper.filterMap(this.connectorInfos, filter);
    }
    private hilightConnectors(hi: boolean, ...keys: string[]) {
        const cons = keys.map((k) => this.connectorInfos.get(k)?.connection);
        if (this.debug) {
            this.log(
                'hilightConnectors',
                keys.length,
                cons.filter((o) => o).length
            );
        }
        cons.forEach((con) => this.hilightConnection(con, hi));
    }
    private hilightConnection(connection: IDiagEdge, hi: boolean) {
        if (!connection) {
            return;
        }
        this.diagramSurface.graph.updateEdge(connection.id, {
            highlighted: hi,
        });
    }

    private getNodeId(itemId: string) {
        return `item-${itemId}`;
    }
}

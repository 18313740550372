import { NgModule } from '@angular/core';
import { DxyDenodoTableSampleComponent } from './denodo/dxy-denodo-table-sample/dxy-denodo-table-sample.component';
import { getStatesForCatalog, modelerRouterInit } from './modeler.routes';
import { UIRouterModule } from '@uirouter/angular';
import { DxyOmnigridModule } from '@datagalaxy/core-ui/omnigrid';
import { DxyModelerDdlModule } from './ddl/DxyModelerDdlModule';
import { DxyModelerDataTypesModule } from './data-types/DxyModelerDataTypesModule';
import { DxyModelerPkFkModule } from './pkfk/DxyModelerPkFkModule';
import { SharedModule } from '../shared/shared.module';
import { FieldsModule } from '../shared/fields/fields.module';
import { DxySharedUiModule } from '../shared/shared-ui/DxySharedUiModule';
import { DxySharedDialogsModule } from '../shared/dialogs/DxySharedDialogsModule';

@NgModule({
    imports: [
        UIRouterModule.forChild({
            states: getStatesForCatalog(),
            config: (uiRouter) => modelerRouterInit(uiRouter.transitionService),
        }),
        SharedModule,
        FieldsModule,
        DxySharedUiModule,
        DxyOmnigridModule,

        DxyModelerPkFkModule,
        DxyModelerDataTypesModule,
        DxyModelerDdlModule,
        DxySharedDialogsModule,
        DxyDenodoTableSampleComponent,
    ],
    exports: [
        DxyModelerPkFkModule,
        DxyModelerDataTypesModule,
        DxyModelerDdlModule,
    ],
})
export class DxyModelerModule {}

<div class="header">
    <dxy-search-input
        class="no-border"
        [showCancelSearch]="true"
        [searchString]="searchTerm"
        (onSearchString)="onSearch($event.searchString)"
    ></dxy-search-input>
</div>

<div class="body">
    <ng-container
        *ngFor="let group of filteredSettings.groups; let last = last"
    >
        <div
            *ngIf="group.filters.length"
            class="group"
            [class.separator]="!last"
        >
            <p class="group-title" [translate]="group.translateTextKey"></p>
            <ul>
                <li class="group-item" *ngFor="let item of group.filters">
                    <mat-checkbox
                        class="checkbox-small"
                        [(ngModel)]="item.selected"
                        (change)="onChangeItem(group, item)"
                    >
                        <i
                            class="attribute-meta-type-icon"
                            [ngClass]="getAttributeGlyphClass(item)"
                        ></i>
                        <span
                            [innerHTML]="item.highlightText"
                            class="text"
                        ></span>
                        <i class="glyph-logo-dg system-glyph"></i>
                    </mat-checkbox>
                </li>
            </ul>
        </div>
    </ng-container>
</div>

import { Component } from '@angular/core';
import { DxyBaseComponent } from '@datagalaxy/ui/core';

@Component({
    selector: 'dxy-module-root',
    templateUrl: 'dxy-module-root.component.html',
    styleUrls: ['dxy-module-root.component.scss'],
})
export class DxyModuleRootComponent extends DxyBaseComponent {
    constructor() {
        super();
    }
}

import { NgModule } from '@angular/core';
import { UIRouterModule } from '@uirouter/angular';
import { getStatesForUsages, softwareRouterInit } from './software.routes';

@NgModule({
    imports: [
        UIRouterModule.forChild({
            states: getStatesForUsages(),
            config: (uiRouter) =>
                softwareRouterInit(uiRouter.transitionService),
        }),
    ],
})
export class DxySoftwareModule {}

import {
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import { EditCellType, IEditGridData, IValueChange } from '@datagalaxy/core-ui';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { DataTypeGridItem } from '../../grid-items/DataTypeGridItem';
import { ModelerService } from '../../services/modeler.service';
import { ModelSettings } from '@datagalaxy/webclient/modeler/data-access';
import {
    CrudOperation,
    FunctionalLogService,
} from '@datagalaxy/webclient/monitoring/data-access';
import { DxyBaseComponent } from '@datagalaxy/ui/core';

@Component({
    selector: 'dxy-data-type-grid',
    templateUrl: 'dxy-data-type-grid.component.html',
})
export class DxyDataTypeGridComponent
    extends DxyBaseComponent
    implements OnChanges, OnInit
{
    @Input() modelSettings: ModelSettings;

    public gridData: IEditGridData<DataTypeGridItem>;

    private readonly dataSource = new MatTableDataSource<DataTypeGridItem>();

    constructor(
        private modelerService: ModelerService,
        private functionalLogService: FunctionalLogService
    ) {
        super();
    }

    ngOnChanges(changes: SimpleChanges) {
        super.onChange(changes, 'modelSettings', () =>
            this.updateRows('onChange-modelSettings')
        );
    }

    ngOnInit() {
        this.updateRows('ngOnInit');
        this.gridData = {
            dataSource: this.dataSource,
            options: {
                rowHeight: 27,
                headerHeight: 50,
            },
            colDefs: [
                {
                    field: 'dataTypeName',
                    headerKey: 'UI.DataType.DataTypeGrid.lblDataTypeName',
                    type: EditCellType.text,
                    isReadOnly: (it) => it.isDataTypeNameReadOnly,
                    debounceTimeMs: 444,
                },
                {
                    field: 'isDataTypeSystem',
                    headerKey: 'UI.DataType.DataTypeGrid.lblIsSystemDataType',
                    type: EditCellType.booleanYesNo,
                    readonly: true,
                },
                {
                    field: 'isDataTypeSizeRequired',
                    headerKey: 'UI.DataType.DataTypeGrid.lblIsSizeRequired',
                    type: EditCellType.booleanYesNo,
                    isReadOnly: (it) => it.isDataTypeSizeRequiredReadOnly,
                },
                {
                    field: 'isDataTypePrecisionRequired',
                    headerKey:
                        'UI.DataType.DataTypeGrid.lblIsPrecisionRequired',
                    type: EditCellType.booleanYesNo,
                    isReadOnly: (it) => it.isDataTypePrecisionRequiredReadOnly,
                },
                {
                    field: 'isDataTypeExcluded',
                    headerKey: 'UI.DataType.DataTypeGrid.lblIsExclude',
                    type: EditCellType.booleanYesNo,
                    readonly: false,
                },
                {
                    id: 'deleteAction',
                    headerText: '',
                    type: EditCellType.action,
                    actionGlyphClass: 'glyph-cancelsearch',
                    actionCallback: (it) => this.onDeleteDataType(it),
                    actionDisabled: (it) =>
                        it.isDeleteDisabled ||
                        !this.modelerService.getModelSettingsMappingItemRefByDataType(
                            this.modelSettings,
                            it.dataType
                        ),
                    actionTooltipKey:
                        'UI.DataType.DataTypeGrid.tltDeleteDataType',
                },
            ],
        };

        this.registerSubscription(
            this.modelerService.subscribeDataTypeEvents({
                onAdd: () => this.updateRows('onAdd-dataType'),
            })
        );
    }

    public onGridValueChanged(event: IValueChange<DataTypeGridItem>) {
        this.onUpdateDataType(
            event.item,
            event.colDef.field,
            event.currentValue
        );
    }

    private async onUpdateDataType(
        dataTypeGridModel: DataTypeGridItem,
        field: string,
        newValue: any
    ) {
        this.log('onUpdateDataType', field, newValue);
        const dataType = dataTypeGridModel.dataType;
        switch (field) {
            case 'isDataTypeExcluded':
                await this.modelerService.updateDataTypeAvailability(
                    dataType,
                    this.modelSettings,
                    !newValue
                );
                break;
            default: {
                const propertyName = this.getUpdatedPropertyName(field);
                await this.modelerService.updateDataType(
                    dataType,
                    propertyName,
                    newValue
                );
                break;
            }
        }
    }
    private getUpdatedPropertyName(field: string) {
        switch (field) {
            case 'dataTypeName':
                return 'DisplayName';
            case 'isDataTypeSizeRequired':
                return 'IsSizeRequired';
            case 'isDataTypePrecisionRequired':
                return 'IsPrecisionRequired';
        }
    }

    private async onDeleteDataType(dataTypeGridModel: DataTypeGridItem) {
        const dataType = dataTypeGridModel.dataType;
        this.functionalLogService.logFunctionalAction(
            'TYPE_DATA',
            CrudOperation.D
        );
        await this.modelerService.deleteDataTypeForModel(
            this.modelSettings,
            dataType
        );
        this.updateRows('onDeleteDataType');
    }

    private updateRows(origin?: string) {
        this.log('updateRows', origin);
        this.dataSource.data = this.modelerService.getDataTypeGridData(
            this.modelSettings
        );
    }
}

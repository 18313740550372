import { CoreUtil, DomUtil } from '@datagalaxy/core-util';
import { LegacyTooltipPosition as TooltipPosition } from '@angular/material/legacy-tooltip';
import { LineageConstants } from '../data/LineageConstants';
import { LineageTrack } from '../data/LineageTrack';
import { LineageTrackStore } from './LineageTrackStore';
import { SD3Items, SD3Links } from '../lineage.utils';
import { LineageRenderer } from './LineageRenderer';
import { LineageItem } from '../data/LineageItem';
import { LineageLink } from '../data/LineageLink';
import { INgZone } from '@datagalaxy/utils';

/** lineage graph sub-component
 * for highlighting tracks of links and items,
 * downstream and upstream of an item */
export class LineagePathTracker {
    private static readonly debug = null && { publicOnly: false };

    private static behaviour = LineageConstants.behaviour.pathTracker;

    /** track pinning buttons container displayed in the burger-menu */
    public readonly pinOptionElement: HTMLElement;

    // unused since v3.3
    /** track unpinning buttons container displayed in the common-controls top bar */
    public readonly unpinOptionElement: HTMLElement;

    /** the hovered item track */
    private current: LineageTrack;
    /** the pinned tracks */
    private readonly store: LineageTrackStore;
    /** timer to reduce blinking when hovering between items */
    private clearTimer: any;

    constructor(
        /** get the nodes and data of all (or some) items */
        private d3Items: (filter?: string, selector?: string) => SD3Items,
        /** get the nodes and data of all (or some) links */
        private d3Links: (filter?: string) => SD3Links,
        private renderer: LineageRenderer,
        private setTooltip: (
            el: Element,
            message: string,
            position: TooltipPosition
        ) => void,
        private removeTooltips: (els: Element[]) => void,
        private onPinUnpin?: (trackId: number, isUnpin: boolean) => void,
        public debug: any = false,
        private ngZone?: INgZone
    ) {
        if (LineagePathTracker.debug) {
            this.debug = LineagePathTracker.debug;
        }
        this.store = new LineageTrackStore(() =>
            this.updateUnpinOptionElement()
        );
        this.pinOptionElement = document.createElement('span');
        this.unpinOptionElement = document.createElement('span');
        this.updateUnpinOptionElement();
    }

    //#region public methods

    /** returns the HTML element for the burger-menu (for to pin the track of the given item) */
    public getPinOptionElement(item: LineageItem) {
        this.updatePinOptionElement(item);
        this.logPublic('getPinOptionElement', item, this.pinOptionElement);
        return this.pinOptionElement;
    }

    /** builds and shows, or hides the track from the given item, and shows the already pinned tracks */
    public highLight(show: boolean, item: LineageItem, maxDepth?: number) {
        this.logPublic('highLight', show, item);
        const itemNode = this.getItemNode(item);
        if (!itemNode) {
            CoreUtil.warn('highLight', item);
            return;
        }
        itemNode.classList.toggle('over', show);
        this.showHide(0, show, item, itemNode, maxDepth);
    }

    /** returns true if the track from the given item can be pinned */
    public canBePinned(item: LineageItem) {
        const hasLinks = item && this.isTrackEmpty(item) === false;
        const isAllTracksSource =
            item && this.store.all((lt) => lt.item == item);
        const result = hasLinks && !isAllTracksSource;
        this.logPublic(
            'canBePinned',
            item,
            hasLinks,
            isAllTracksSource,
            result
        );
        return !!result;
    }

    /** stores the track for the given item.
     * If a track is already stored for the given item then it is replaced.
     * Unless onlyOneActive is given false, if a track is already stored for the given trackId and another item then it is removed */
    public pinTrack(trackId: number, item: LineageItem, onlyOneActive = true) {
        this.logPublic('pinTrack', trackId, item);
        if (!item || !trackId) {
            return;
        }

        this.onPinUnpin?.(trackId, false);

        const behaviour = LineagePathTracker.behaviour.clearTracks?.onPin;
        if (behaviour?.collapsedItemChildren) {
            this.clearDescendantStoredTracks(
                item,
                true,
                !behaviour.descendantsIsPartOf
            );
        }

        const stored = this.store.getById(trackId);

        if (onlyOneActive) {
            // clear stored tracks for same item or track id
            this.store.withAll(undefined, (lt, tid) => {
                if (lt.item == item || tid == trackId) {
                    this.clearStoredTrack(tid, true);
                }
            });
        } else if (stored) {
            this.clearStoredTrack(trackId, true);
        }

        // show track (makes it current)
        this.showHide(trackId, true, item, this.getItemNode(item));

        if (this.current && (!stored || item != stored.item)) {
            this.store.set(trackId, this.current);
        }
    }

    /* clears and removes the pinned track for the given trackId */
    public unpinTrack(trackId: number) {
        this.logPublic('unpinTrack', trackId);
        if (!trackId || !this.isPinned(trackId)) {
            return;
        }
        this.clearStoredTrack(trackId, true);
        this.onPinUnpin?.(trackId, true);
    }

    public isPinned(trackId: number) {
        return this.store.getById(trackId);
    }

    public getUnpinTooltip(trackId: number) {
        const disabled = !this.isPinned(trackId);
        return `UI.ImpactAnalysis.lineage.pathTracker.${
            disabled ? 'disabled' : 'unpinTrack'
        }`;
    }

    /* rebuilds the track for the given item */
    public updateFor(item: LineageItem) {
        const trackId = this.store.getTrackId(item);
        const canUpdate = !!trackId;
        this.logPublic('updateFor', item, canUpdate, trackId);
        if (canUpdate) {
            this.updateStored(trackId);
        }
    }

    /* show the currently pinned tracks */
    public showPinnedTracks() {
        this.logPublic('showPinnedTracks');
        this.updateStored();
    }

    public clearAllTracks() {
        this.logPublic('clearAllTracks');
        this.store.withAll(undefined, (lt, tid) =>
            this.clearStoredTrack(tid, true)
        );
    }

    public clearDescendantTracks(
        item: LineageItem,
        andSelf = false,
        onlyTrackSources = false
    ) {
        this.logPublic('clearDescendantTracks', item, andSelf);
        if (andSelf) {
            this.store.withAll(undefined, (lt, tid) => {
                if (lt.item == item) {
                    this.clearStoredTrack(tid);
                }
            });
        }
        this.clearDescendantStoredTracks(item, false, onlyTrackSources);
    }

    //#endregion

    //#region private methods

    private updatePinOptionElement(item: LineageItem) {
        this.logPrivate('updatePinOptionElement', item);
        const isPinned = (trackId: number) => this.store.hasItem(item, trackId);
        this.buildListOptionElement(
            this.pinOptionElement,
            (trackId) =>
                isPinned(trackId)
                    ? this.unpinTrack(trackId)
                    : this.pinTrack(trackId, item),
            (trackId) => isPinned(trackId),
            (disabled) =>
                disabled
                    ? 'UI.ImpactAnalysis.lineage.pathTracker.disabled'
                    : 'UI.ImpactAnalysis.lineage.pathTracker.pinTrack'
        );
    }
    private updateUnpinOptionElement() {
        this.logPrivate('updateUnpinOptionElement');
        this.buildListOptionElement(
            this.unpinOptionElement,
            (trackId) => this.unpinTrack(trackId),
            (trackId) => !this.store.getById(trackId),
            (disabled) =>
                disabled
                    ? 'UI.ImpactAnalysis.lineage.pathTracker.disabled'
                    : 'UI.ImpactAnalysis.lineage.pathTracker.unpinTrack'
        );
    }
    private buildListOptionElement(
        container: HTMLElement,
        onClick: (trackId: number) => void,
        isHidden: (trackId: number) => boolean,
        getTooltip: (disabled: boolean) => string
    ) {
        this.logPrivate('buildListOptionElement', onClick, isHidden);
        this.removeTooltips(
            Array.from(
                container.querySelectorAll('.path-tracker-option-track')
            ) as HTMLElement[]
        );
        DomUtil.empty(container);
        for (
            let i = 0, il = LineagePathTracker.behaviour.nbTracks;
            i < il;
            i++
        ) {
            const trackId = i + 1,
                el = document.createElement('span'),
                hidden = isHidden(trackId);
            /*!hidden && */ DomUtil.addListener(
                el,
                'click',
                () => onClick(trackId),
                this.ngZone
            );
            el.className = `path-tracker-option-track path-tracker-option-track${trackId} ${
                hidden ? 'hidden' : ''
            }`;
            container.appendChild(el);
            this.setTooltip(el, getTooltip(hidden), 'below');
        }
    }

    /** returns true if the track for the given item has no links */
    private isTrackEmpty(item: LineageItem) {
        const stored = this.store.find(item);
        const result = stored
            ? stored.isEmpty
            : item && this.current?.item == item
            ? this.current.isEmpty
            : undefined;
        this.logPrivate('isTrackEmpty', item, this.current, result);
        return result;
    }

    private isPartOfTrack(item: LineageItem, trackId: number) {
        let node: SVGGElement;
        const result = this.store.any((lt) => {
            if (lt.item == item) {
                return true;
            }
            if (!node) {
                node = this.getItemNode(item);
            }
            return lt.itemNodes.indexOf(node) != -1;
        });
        this.logPrivate('isPartOfTrack', trackId, item, result);
        return result;
    }

    /** rebuild one or all stored tracks,
     * and redraw all stored tracks */
    private updateStored(trackId?: number) {
        this.logPrivate('updateStored');
        this.store.withAll(trackId, (lt, tid) => {
            this.logPrivate('updateStored-store-each', tid, lt);
            this.clearStoredTrack(tid, true);
            const trackNotEmpty = this.build(lt.item, lt.node, true);
            if (trackNotEmpty) {
                this.store.set(tid, trackNotEmpty);
            }
        });
        this.store.withAll(undefined, (lt, tid) => {
            this.logPrivate('updateStored-update-each');
            this.updateTrackNodesClass(true, lt, tid);
        });
    }

    /** show (build it if not stored) or hide the track for the given item,
     * show the already stored tracks,
     * returns true if show and the track has links */
    private showHide(
        trackId: number,
        show: boolean,
        item?: LineageItem,
        node?: SVGGElement,
        maxDepth?: number
    ) {
        this.logPrivate('showHide', trackId, show, item, node);

        this.cancelClearTimer();

        const action = () => {
            this.clearStoredTrack();
            this.removeCurrent();
            if (show) {
                this.logPrivate('showHide-action-show', trackId, item);
                this.current = this.build(item, node, undefined, maxDepth);
                this.updateTrackNodesClass(true, this.current, trackId);
            }
            this.showStored();
        };
        if (show) {
            action();
            this.logPrivate('showHide-shown', this.current);
            return !this.current.isEmpty;
        } else {
            // wait for clear, to reduce blinking when hovering between items
            this.clearTimer = setTimeout(action, 222);
            return false;
        }
    }

    private build(
        item: LineageItem,
        node: SVGGElement,
        noEmpty = false,
        maxDepth?: number
    ) {
        this.logPrivate('build', item);

        if (!item) {
            return;
        }

        const track = new LineageTrack(item, node, this.debug);

        node.classList.add(LineageConstants.trackClass);

        this.logPrivate('build-downstream');
        this.followLinks(this.getLinks(item, false), track, false, maxDepth);

        this.logPrivate('build-upstream');
        this.followLinks(this.getLinks(item, true), track, true, maxDepth);

        if (this.debug) {
            this.logPrivate(
                'build-shown-links',
                track
                    .getD3Links()
                    .data()
                    .map((l) => l.toDebugString())
            );
            this.logPrivate(
                'build-shown-items',
                track
                    .getD3Items()
                    .data()
                    .map((d) => d.toDebugString(true))
            );
        }

        return track.isEmpty && noEmpty ? undefined : track;
    }
    private followLinks(
        d3links: SD3Links,
        track: LineageTrack,
        isUp: boolean,
        maxDepth?: number
    ) {
        if (maxDepth != undefined) {
            if (maxDepth < 1) {
                return;
            }
            maxDepth--;
        }

        // configure track to get anti-cycle and direction css filter and classes
        track.setDir(isUp);

        // filter received links
        d3links = d3links.filter(track.dirFilter);

        if (this.debug) {
            this.logPrivate('followLinks', isUp, d3links.size());
        }

        if (d3links.empty()) {
            return;
        }

        // mark and show visited links
        d3links.classed(track.dirClass, true);

        // update link markers so they are styled like their link
        this.renderer.redrawLinkMarkers(d3links, true);

        // store visited links
        track.addLinks(d3links);

        // get the item at one end of each link
        const d3items = d3links.selectAll<SVGGElement, LineageItem>((l) =>
            this.d3Items(
                track.dirFilter,
                isUp ? l.src.selector : l.tgt.selector
            ).nodes()
        );

        if (d3items.empty()) {
            return;
        }

        // mark and show items
        d3items.classed(track.dirClass, true);

        // store items
        track.addItems(d3items);

        // get the links to or from each item
        d3links = d3items.selectAll<SVGGElement, LineageLink>((d) =>
            this.getLinks(d, isUp).nodes()
        );

        if (d3links.empty()) {
            return;
        }

        // repeat
        this.followLinks(d3links, track, isUp, maxDepth);
    }

    private updateTrackNodesClass(
        show: boolean,
        track: LineageTrack,
        trackId: number
    ) {
        const trackClass = LineageConstants.trackClass + trackId;
        const filter = '.' + trackClass;
        this.logPrivate(
            'updateTrackNodesClass',
            show,
            trackId,
            track,
            trackClass,
            filter
        );
        this.d3Items(filter).classed(trackClass, false);
        this.d3Links(filter).classed(trackClass, false);
        if (show && track) {
            //this.logPrivate('updateTrackNodesClass-show', track.getD3Items().nodes(), track.getD3Links().nodes())
            track.getD3Items().classed(trackClass, show);
            track.getD3Links().classed(trackClass, show);
        }
    }

    private cancelClearTimer() {
        const canceled = this.clearTimer != undefined;
        clearTimeout(this.clearTimer);
        this.clearTimer = undefined;
        return canceled;
    }
    private clearStoredTrack(trackId?: number, remove = false) {
        this.logPrivate('clearStoredTrack', trackId, remove);
        this.store.withAll(trackId, (lt, tid) => {
            this.logPrivate('clearStoredTrack-each', tid, lt, remove);
            this.updateTrackNodesClass(false, lt, tid);
            this.showHideTrack(false, lt);
            if (remove) {
                this.store.clear(tid);
            }
        });
    }
    private clearDescendantStoredTracks(
        item: LineageItem,
        onlyIfItemCollapsed: boolean,
        onlyTrackSources: boolean
    ) {
        this.logPrivate(
            'clearDescendantStoredTracks',
            onlyIfItemCollapsed,
            item
        );
        if (
            !item ||
            !item.children ||
            (onlyIfItemCollapsed && item.isExpanded) ||
            this.store.isEmpty()
        ) {
            return;
        }
        const descendants = this.getDescendants(item);
        this.store.withAll(undefined, (lt, tid) => {
            let found: boolean;
            const trackItem = lt.item;
            if (onlyTrackSources) {
                found = descendants.some((it) => it == trackItem);
            } else {
                found =
                    this.isPartOfTrack(item, tid) ||
                    descendants.some(
                        (it) => it == trackItem || this.isPartOfTrack(it, tid)
                    );
            }
            if (found) {
                this.clearStoredTrack(tid, true);
            }
        });
    }
    private getDescendants(item: LineageItem) {
        const descendants = new Array<LineageItem>();
        const collectDescendants = (it: LineageItem) => {
            const children = it?.children;
            if (children) {
                descendants.push(...children);
                children.forEach(collectDescendants);
            }
        };
        collectDescendants(item);
        this.logPrivate('getDescendants', item, descendants);
        return descendants;
    }

    private removeCurrent() {
        this.logPrivate('removeCurrent', this.current);
        if (!this.current) {
            return;
        }
        this.updateTrackNodesClass(false, this.current, 0);
        this.showHideTrack(false, this.current);
        this.current = undefined;
    }

    private showStored(trackId?: number) {
        this.logPrivate('showStored', trackId);
        this.store.withAll(trackId, (lt, tid) => {
            this.logPrivate('showStored-each', tid, lt);
            this.showHideTrack(true, lt);
            this.updateTrackNodesClass(true, lt, tid);
        });
    }

    private showHideTrack(show: boolean, track: LineageTrack) {
        this.logPrivate('showHideTrack', show, track);

        if (!track) {
            return;
        }

        track
            .getD3Items()
            .classed(track.classed, show)
            .classed(LineageConstants.trackClass, show);

        const d3links = track.getD3Links();
        d3links
            .classed(track.classed, show)
            .classed(LineageConstants.trackClass, show);
        this.renderer.redrawLinkMarkers(d3links, show);
    }

    private getLinks(item: LineageItem, upstream: boolean) {
        return this.d3Links(`${upstream ? '.t' : '.s'}${item.id}`);
    }

    private getItemNode(item: LineageItem) {
        return this.d3Items('', item.selector).node() as SVGGElement;
    }

    private logPublic(...args: any[]) {
        this.log(...args);
    }
    private logPrivate(...args: any[]) {
        if (!this.debug?.publicOnly) {
            this.log(...args);
        }
    }
    private log(...args: any[]) {
        if (this.debug) {
            console.log((this.constructor as any).name, ...args);
        }
    }

    //#endregion
}

import { Component } from '@angular/core';
import { DxyBaseComponent } from '@datagalaxy/ui/core';

@Component({
    selector: 'dxy-client-error',
    templateUrl: 'dxy-client-error.component.html',
    styleUrls: ['dxy-client-error.component.scss'],
})
export class DxyClientErrorComponent extends DxyBaseComponent {
    constructor() {
        super();
    }
}

import { WidgetInstanceMovedEvent } from '../../../common/dashboard-grid-events';
import { DashboardSection } from '../../../domain';
import { DashboardState } from '../dashboard-state';
import { sectionReducer } from './section-reducer';

export function moveWidgetInstanceInsideSectionReducer(
    state: DashboardState,
    section: DashboardSection,
    events: WidgetInstanceMovedEvent[]
) {
    let newState = { ...state };
    const widgetInstances = [
        ...section.widgetInstances.map((wi) => {
            const e = events.find((e) => e.widgetInstanceId == wi.guid);
            if (!e) {
                return wi;
            }
            return {
                ...wi,
                position: e.position,
            };
        }),
    ];
    return sectionReducer(newState, section, { widgetInstances });
}

import { FocusMonitor, FocusOrigin } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
    Directive,
    DoCheck,
    ElementRef,
    HostBinding,
    Input,
    NgZone,
    OnDestroy,
    OnInit,
} from '@angular/core';
import {
    ControlValueAccessor,
    FormGroupDirective,
    NgControl,
    NgForm,
} from '@angular/forms';
import { ErrorStateMatcher, mixinErrorState } from '@angular/material/core';
import { MatLegacyFormFieldControl as MatFormFieldControl } from '@angular/material/legacy-form-field';
import { DomUtil } from '@datagalaxy/core-util';
import { Subject } from 'rxjs';
import { DxyBaseValueAccessorComponent } from '@datagalaxy/ui/core';
import { ZoneUtils } from '@datagalaxy/utils';

//inspired from: https://stackblitz.com/edit/custom-mat-form-field-control?file=app%2Fcustom-input%2Fcustom-input.component.ts

class _AbstractMatFormField extends DxyBaseValueAccessorComponent<any> {
    public readonly stateChanges = new Subject<void>();
    constructor(
        public _defaultErrorStateMatcher: ErrorStateMatcher,
        public _parentForm: NgForm,
        public _parentFormGroup: FormGroupDirective,
        public ngControl: NgControl,
        elementRef: ElementRef<HTMLElement>,
        ngZone: NgZone
    ) {
        super(ngControl, ngZone, elementRef);
    }
}

/** Base class for a custom MatFormFieldControl, to be used in a MatFormField.
 *
 * Inherits DxyBaseValueAccessorComponent which provides the ControlValueAccessor interface (ngModel/ngModelChange),
 * and can also:
 * - trigger a native *change* event,
 * - emit a debouncedModelChange ng event.
 *
 * Inherits DxyBaseComponent which provides:
 * - logging methods,
 * - events subscription and unsubscription.
 */
@Directive()
export abstract class DxyBaseMatFormFieldControl<T>
    extends mixinErrorState(_AbstractMatFormField)
    implements
        OnInit,
        DoCheck,
        OnDestroy,
        ControlValueAccessor,
        MatFormFieldControl<T>
{
    private static nextId = 0;

    @Input()
    public set placeholder(placeholder: string) {
        this._placeholder = placeholder;
        this.stateChanges.next();
    }
    public get placeholder() {
        return this._placeholder;
    }

    @Input()
    public set required(required: any) {
        this._required = coerceBooleanProperty(required);
        this.stateChanges.next();
    }
    public get required() {
        return this._required;
    }

    @Input()
    public set disabled(disabled: any) {
        this._disabled = coerceBooleanProperty(disabled);
        this.stateChanges.next();
    }
    public get disabled() {
        return this._disabled;
    }

    @Input()
    public errorStateMatcher: ErrorStateMatcher;

    @HostBinding()
    public id = `${this.controlType}-${DxyBaseMatFormFieldControl.nextId++}`;

    @HostBinding('attr.aria-describedBy')
    public describedBy = '';

    public touched: boolean;

    public get empty(): boolean {
        return this.isEmpty();
    }
    protected isEmpty() {
        return !this._value;
    }

    public get shouldLabelFloat(): boolean {
        return this.focused || !this.empty;
    }

    public get focused() {
        return this._focused;
    }
    public set focused(value: boolean) {
        this.setFocused(value);
    }
    protected setFocused(value: boolean) {
        this.log('set-focused', value);
        this._focused = value;
    }

    public set value(value: T) {
        super.setValue(value);
    }
    public get value() {
        return this._value;
    }

    protected _focused = false;
    protected _disabled = false;
    protected _placeholder = '';
    protected _required = false;

    protected get isClickingInside() {
        return this._isClickingInside;
    }
    private _isClickingInside: boolean;

    /** true if focus was acquired from a keyboard event */
    protected get isFocusOriginKeyboard() {
        return this.focusOrigin == 'keyboard';
    }
    private focusOrigin: FocusOrigin;

    constructor(
        public readonly controlType: string,
        // ErrorStateMixin
        public readonly ngControl: NgControl,
        public readonly _parentForm: NgForm,
        public readonly _parentFormGroup: FormGroupDirective,
        public readonly _defaultErrorStateMatcher: ErrorStateMatcher,
        // FocusMonitor
        protected readonly focusMonitor: FocusMonitor,
        elementRef: ElementRef<HTMLElement>,
        ngZone: NgZone
    ) {
        super(
            _defaultErrorStateMatcher,
            _parentForm,
            _parentFormGroup,
            ngControl,
            elementRef,
            ngZone
        );

        focusMonitor
            .monitor(this.elementRef.nativeElement, true)
            .subscribe((origin) => {
                this.log('focusMonitor', origin);
                this.focusOrigin = origin;
            });
    }

    ngOnInit() {
        this.addListenerOutside(
            'pointerdown',
            () => (this._isClickingInside = true)
        );
        this.addListenerOutside(
            'pointerup',
            () => (this._isClickingInside = false)
        );
        this.addListenerOutside('focusin', (e: FocusEvent) =>
            this.onFocusIn(e)
        );
        this.addListenerOutside('focusout', (e: FocusEvent) =>
            this.onFocusOut(e)
        );
    }

    ngDoCheck() {
        this.updateErrorState();
    }

    ngOnDestroy() {
        super.ngOnDestroy();
        this.stateChanges.complete();
        this.focusMonitor.stopMonitoring(this.elementRef.nativeElement);
    }

    /** Handles a click on the control's container.
     * By default, focus() is called if the control is not already focused
     * and the event's target is outside the control. */
    onContainerClick(event: PointerEvent): void {
        if (!this.focused && !this.isTargetContained(event)) {
            this.log('onContainerClick-focus', event);
            this.focus();
        }
    }

    setDescribedByIds(ids: string[]) {
        this.describedBy = ids.join(' ');
    }

    /** called when the inner element must be focused */
    abstract focus(): void;

    /** To be overriden to prevent the control from losing focus,
     * for example when a matMenu panel managed by the control is clicked.
     * By default, returns true if the event is from an element inside the control */
    protected preventBlurOnFocusOut(event: FocusEvent): boolean {
        return this.isRelatedTargetContained(event);
    }

    //#region helpers

    /** Adds an event listener on the native element, that does not trigger change detection */
    protected addListenerOutside<T extends Event>(
        eventName: string,
        listener: (e: T) => void
    ) {
        ZoneUtils.zoneExecute(
            () =>
                this.elementRef.nativeElement.addEventListener(
                    eventName,
                    listener
                ),
            this.ngZone,
            true
        );
    }

    /** returns true if the given event's target is an inner element of this fieldcontrol */
    protected isTargetContained(event: Event) {
        return this.elementRef.nativeElement.contains(event.target as Element);
    }

    /** returns true if the given event's relatedTarget is an inner element of this fieldcontrol */
    protected isRelatedTargetContained(event: FocusEvent) {
        return this.elementRef.nativeElement.contains(
            event.relatedTarget as Element
        );
    }

    /** returns true if the given event's relatedTarget is a mat-option */
    protected isRelatedTargetMatOption(event: FocusEvent) {
        return (event.relatedTarget as Element)?.classList?.contains(
            'mat-option'
        );
    }

    /** returns true if the given event's relatedTarget element has any of the given class names */
    protected relatedTargetHasClassName(
        event: FocusEvent,
        ...classNames: string[]
    ) {
        return DomUtil.relatedTargetHasClassName(event, ...classNames);
    }

    /** returns true if the given event's target element has any of the given class names */
    protected targetHasClassName(event: Event, ...classNames: string[]) {
        return DomUtil.targetHasClassName(event, ...classNames);
    }

    //#endregion - helpers

    protected onFocusIn(event: FocusEvent) {
        if (this.focused || this.disabled) {
            this.log(
                this.focused
                    ? 'onFocusIn-already-focused'
                    : 'onFocusIn-disabled-focus',
                this.focusOrigin,
                event
            );
            return;
        }

        this.log('onFocusIn-focus', this.focusOrigin, event);
        this.focused = true;
        this.stateChanges.next();
    }
    protected onFocusOut(event: FocusEvent) {
        if (!this.focused) {
            this.log('onFocusOut-already-!focused', event);
            return;
        }

        if (this.preventBlurOnFocusOut(event)) {
            this.log('onFocusOut-prevent', event);
            event.stopImmediatePropagation();
            return;
        }

        this.log('onFocusOut-blur', event);
        this.touched = true;
        this.focused = false;
        this.propagateTouched?.();
        this.stateChanges.next();
    }
}

import { CollectionsHelper } from '@datagalaxy/core-util';
import { Component, Input, OnInit } from '@angular/core';
import { GraphicalItem } from '../models/GraphicalItem';
import { IActionOption } from '@datagalaxy/core-ui';
import { DpMappingUiService } from '../dpMappingUi.service';
import { GraphicalEntityDataDpLink } from '../models/GraphicalEntityDataDpLink';
import { GraphicalEntityDataDpLinkColumn } from '../models/GraphicalEntityDataDpLinkColumn';
import { TNodeType } from '../dp-mapping.types';
import { EntityUiService } from '../../../shared/entity/services/entity-ui.service';
import { GraphicalContainer } from '../models/GraphicalContainer';
import { HierarchicalData } from '@datagalaxy/dg-object-model';
import { NavigationEventService } from '../../../navigation/services/navigation-event.service';
import { DataProcessingUiService } from '../../services/data-processing-ui.service';
import { EntityEventService } from '../../../shared/entity/services/entity-event.service';
import { ServerType } from '@datagalaxy/dg-object-model';
import { GraphicalEntityData } from '../models/GraphicalEntityData';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';

/**
 * ## Role
 * UI representation of a mapped Data-Processing Item in the Data-Processing Mapping UI
 *
 * (i.e., the boxes in the 'In' and 'Out' layout columns)
 */
@Component({
    selector: 'app-dp-implem-element',
    templateUrl: 'dp-implem-element.component.html',
})
export class DpImplemElementComponent
    extends DxyBaseComponent
    implements OnInit
{
    @Input() container: GraphicalContainer;
    @Input() type: TNodeType;

    public readonly menuOptions: IActionOption[] = [
        {
            glyphClass: 'glyph-delete',
            labelKey: 'UI.Global.btnDelete',
            callback: () => this.deleteContainer(),
            hidden: () => !this.showActionDeleteContainer,
        },
        {
            glyphClass: 'glyph-hide',
            labelKey: 'UI.DataProcessingItem.ttToggleHideMappingColumns',
            callback: () => this.showHideUnmappedColumns(false),
            hidden: () =>
                !this.showActionToggleUnmappedColumns ||
                !this.showUnmappedChildren,
        },
        {
            glyphClass: 'glyph-show',
            labelKey: 'UI.DataProcessingItem.ttToggleShowMappingColumns',
            callback: () => this.showHideUnmappedColumns(true),
            hidden: () =>
                !this.showActionToggleUnmappedColumns ||
                this.showUnmappedChildren,
        },
    ];
    public readonly headerRichTooltipIsEnabledFn = () => {
        if (
            this.container.itemsCount &&
            this.dpMappingUiService.isConnectionBeingDragged
        ) {
            this.headerRichTooltipKey =
                'UI.DataProcessing.mapping.tableMappingForbiddenTooltip';
            return true;
        }
        return false;
    };
    public headerRichTooltipKey: string;
    public hierarchicalData: HierarchicalData;
    public visibleMappingItems: ColumnGraphicalItem[] = [];
    public get hasWriteAccess() {
        return this.dpMappingUiService.hasWriteAccess;
    }
    public get canExpandCollapse() {
        return this.graphicalEntityData.isColumn;
    }
    public get bugerMenuVisible() {
        return (
            this.showActionDeleteContainer ||
            this.showActionToggleUnmappedColumns
        );
    }

    private graphicalEntityData: GraphicalEntityDataDpLink;
    private isDeleteInProgress = false;
    private showUnmappedChildren = true;
    private get showActionDeleteContainer() {
        return this.hasWriteAccess;
    }
    private get showActionToggleUnmappedColumns() {
        return this.graphicalEntityData.isColumn;
    }
    private get isIn() {
        return this.type == 'In';
    }

    constructor(
        private entityEventService: EntityEventService,
        private navigationEventService: NavigationEventService,
        private dataProcessingUiService: DataProcessingUiService,
        private entityUiService: EntityUiService,
        private dpMappingUiService: DpMappingUiService
    ) {
        super();
    }

    ngOnInit() {
        this.graphicalEntityData = this.container
            .parentData as GraphicalEntityDataDpLink;
        this.container.updateContainerContent = () =>
            this.showHideUnmappedColumns(true);
        if (this.graphicalEntityData.isColumn) {
            this.registerSubscription(
                this.entityEventService.subscribeEntityUpdate(
                    ServerType.Column,
                    (entity) => this.updateLocalColumn(entity)
                )
            );
        }

        this.updateVisibleItems();
        const parentData = this.container.parentData;
        this.hierarchicalData = new HierarchicalData(
            parentData.hddData,
            parentData.Parents,
            parentData.TechnologyCode
        );
        super.subscribe(
            this.dataProcessingUiService
                .mappingToggleShowUnmappedGlobalColumns$,
            () =>
                setTimeout(() => {
                    const show =
                        this.dataProcessingUiService.getShowGlobalUnmappedChildren();
                    this.showHideUnmappedColumns(show);
                })
        );
    }

    public onContainerHeaderClick() {
        if (!this.canExpandCollapse) {
            return;
        }
        this.toggleCollapse();
    }
    private toggleCollapse() {
        if (this.isDeleteInProgress) {
            return;
        }
        this.log('toggleCollapse');
        this.dpMappingUiService.onBeforeExpandCollapse(this.container);
        if (!this.container.isCollapsed) {
            // opening => re-add items in dom
            this.updateVisibleItems();
        }
        // for quicker redraw of links (before afterExpandCollapse)
        setTimeout(() => this.dpMappingUiService.onAfterExpandCollapse());
    }

    public getColumnDisplayName(data: GraphicalEntityData) {
        return this.dpMappingUiService.getColumnDisplayName(data);
    }

    public onDeleteMappingItemClick(item: GraphicalItem) {
        this.navigationEventService.notifyDeleteModalOpen();
        this.dpMappingUiService.deleteColumn(item, this.isIn);
    }

    public async onMouseEnterItem(
        event: MouseEvent,
        item: GraphicalItem,
        noPopover = false
    ) {
        if (
            !noPopover &&
            !this.dpMappingUiService.noPopover &&
            event.target instanceof HTMLElement
        ) {
            await this.entityUiService.openEntityPopover(
                event.target,
                item.entityIdentifier
            );
        }
        this.toggleItemHover(item, true);
    }
    public onMouseLeaveItem($event: MouseEvent, item: GraphicalItem) {
        this.toggleItemHover(item, false);
    }

    private toggleItemHover(item: GraphicalItem, hover: boolean) {
        this.dpMappingUiService.toggleImplemItemHover(item, this.isIn, hover);
    }

    private async deleteContainer() {
        this.isDeleteInProgress = true;
        try {
            this.navigationEventService.notifyDeleteModalOpen();
            await this.dpMappingUiService.deleteContainerLinks(
                this.container,
                this.isIn
            );
        } finally {
            this.isDeleteInProgress = false;
        }
    }

    private showHideUnmappedColumns(show: boolean) {
        this.showUnmappedChildren = show;
        this.log('showHideUnmappedColumns', this.showUnmappedChildren);
        this.updateVisibleItems();
    }

    private updateVisibleItems() {
        if (this.container.isCollapsed) {
            this.visibleMappingItems = [];
        } else {
            let listColumnToShow =
                this.container.getItems() as ColumnGraphicalItem[];
            if (!this.showUnmappedChildren) {
                const isInput = this.isIn;
                listColumnToShow = listColumnToShow.filter((item) =>
                    this.dataProcessingUiService.isColumnMapped(
                        item.data.DataReferenceId,
                        isInput
                    )
                );
            }
            this.visibleMappingItems = CollectionsHelper.orderBy(
                listColumnToShow,
                (it) => it.data.TechnicalName
            );
        }
    }

    private updateLocalColumn(entity: EntityItem) {
        const entityId = `${DataProcessingUiService.getDataListName(
            this.graphicalEntityData.LinkData.Direction
        )}_${entity.ReferenceId}`;
        if (!this.container.hasItem(entityId)) {
            return;
        }

        const data = this.container.getItem(entityId)
            .data as GraphicalEntityDataDpLinkColumn;
        data.ColumnData.ColumnDataTypeName = entity.getAttributeValue(
            'DataTypeDisplayName'
        ) as string;
        data.ColumnData.SizeString = entity.getAttributeValue(
            'SizeString'
        ) as string;
        data.ColumnData.DisplayName = entity.DisplayName;
        data.ColumnData.TechnicalName = entity.TechnicalName;
    }
}

type ColumnGraphicalItem = GraphicalItem<GraphicalEntityDataDpLinkColumn>;

<div *ngIf="!inPlaceConfig">
    <div class="widget-head" [class.no-bottom-line]="noLine && !hasFilters">
        <div class="title-zone">
            <div *ngIf="hasSpaceFilter" class="title-context">
                {{ spaceFilterLabel }}
            </div>
            <div class="title">
                <span
                    (click)="titleClick()"
                    class="title-text"
                    [class.title-link]="isTitleClickActive"
                >
                    {{ titleText }}
                </span>
            </div>
            <div *ngIf="description" class="description">{{ description }}</div>
        </div>
        <div *ngIf="showCount" class="side-counter green">
            {{ countValue }}
        </div>
    </div>
    <div
        *ngIf="hasFilters"
        class="widget-filters"
        [class.no-bottom-line]="noLine"
    >
        <div *ngFor="let f of filtersList" class="dg5-filter-item disabled">
            <div class="filter-group">
                <div class="filter-label">{{ f.uiLabel }}</div>
                <div class="filter-value">{{ f.uiValue }}</div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="inPlaceConfig">
    <div class="widget-head">
        <div class="title-zone">
            <div class="settings-zone">
                <dxy-space-version-selector
                    (onSpaceVersionSelected)="
                        onSpaceVersionSelected($event.spaceIdr)
                    "
                    [spaceIdr]="spaceIdr"
                    [isSpaceSelectable]="isEditMode"
                    [isVersionSelectable]="isEditMode"
                    [showSelectedSpaceIcon]="true"
                    [allowAll]="true"
                    [isSmallCaretBtn]="true"
                    [hideOfficialVersion]="true"
                    logId="widget-header"
                ></dxy-space-version-selector>

                <div class="widget-actions">
                    <button
                        *ngIf="!isEditMode"
                        (click)="onToggleEditMode()"
                        mat-icon-button
                        [matTooltip]="
                            'UI.DashboardGrid.WidgetSettings.tooltips.configure'
                                | translate
                        "
                    >
                        <i class="glyph-settings"></i>
                    </button>

                    <ng-container *ngIf="isEditMode">
                        <button
                            [matMenuTriggerFor]="configureMenu"
                            mat-icon-button
                            [matTooltip]="
                                'UI.DashboardGrid.WidgetSettings.tooltips.edit'
                                    | translate
                            "
                        >
                            <i class="glyph-slider-2"></i>
                        </button>
                        <mat-menu #configureMenu="matMenu" class="dg5-mat-menu">
                            <ul>
                                <li
                                    role="menuitem"
                                    (click)="onShowHideFilters()"
                                >
                                    <span [ngClass]="getShowHideClass()"></span>
                                    <span
                                        class="text"
                                        [translate]="getShowHideText()"
                                    ></span>
                                </li>
                                <li role="menuitem" (click)="onReset()">
                                    <span class="glyph-undo2"></span>
                                    <span class="text" translate>
                                        UI.DashboardGrid.WidgetSettings.InPlace.actions.resetFilters
                                    </span>
                                </li>
                            </ul>
                        </mat-menu>
                        <button
                            *ngIf="isEditMode"
                            (click)="onSave()"
                            mat-icon-button
                            [matTooltip]="
                                'UI.DashboardGrid.WidgetSettings.InPlace.actions.save.tooltip'
                                    | translate
                            "
                        >
                            <i class="glyph-save1"></i>
                        </button>
                        <button
                            *ngIf="isEditMode"
                            (click)="onCancelEditMode()"
                            mat-icon-button
                            [matTooltip]="
                                'UI.DashboardGrid.WidgetSettings.tooltips.closeWithoutSaving'
                                    | translate
                            "
                        >
                            <i class="glyph-cancelsearch"></i>
                        </button>
                    </ng-container>
                </div>
            </div>

            <div class="title">
                <span
                    *ngIf="!isEditMode"
                    (click)="titleClick()"
                    class="title-text"
                    [class.title-link]="isTitleClickActive"
                >
                    {{ titleText }}
                </span>
                <dxy-field-text
                    *ngIf="isEditMode"
                    class="no-padding bold"
                    [(ngModel)]="titleText"
                    (change)="onTitleChange()"
                ></dxy-field-text>
            </div>

            <div *ngIf="!isEditMode" class="description">
                {{ description }}
            </div>
            <dxy-field-text
                *ngIf="isEditMode"
                class="no-padding"
                [(ngModel)]="description"
                (change)="onDescriptionChange()"
            ></dxy-field-text>

            <div
                *ngIf="params"
                class="widget-filters"
                [class.no-bottom-line]="noLine"
                [class.hidden]="hideFilters"
            >
                <ng-container *ngFor="let param of params">
                    <div
                        *ngIf="settingManagerInitialized && showFilter(param)"
                        class="filter-item-wrapper"
                    >
                        <button
                            class="dg5-filter-item"
                            [matMenuTriggerFor]="paramMenu"
                            [disabled]="!isEditMode"
                            [matTooltip]="
                                'UI.DashboardGrid.WidgetSettings.tooltips.edit'
                                    | translate
                            "
                            (menuOpened)="onMenuOpened(param)"
                        >
                            <app-user-collection-cell
                                *ngIf="!!param.value && isUserSelect(param)"
                                [userIds]="getUserIds(param)"
                                [hideUserName]="true"
                                [containerWidth]="70"
                                [fitContentWidth]="true"
                                [mini]="true"
                            ></app-user-collection-cell>

                            <span class="filter-group">
                                <span
                                    class="filter-label"
                                    [translate]="param.translateKey"
                                ></span>
                                <span class="filter-value-wrapper">
                                    <div class="filter-value">
                                        {{ getParamDisplayName(param) }}
                                    </div>
                                </span>
                            </span>
                            <span
                                *ngIf="isEditMode"
                                class="glyph-arrow-drop-down"
                            ></span>
                        </button>
                        <mat-menu
                            #paramMenu="matMenu"
                            class="dg5-mat-menu no-padding fixed-width-400"
                        >
                            <div *ngIf="isEditMode" class="not-an-item">
                                <dxy-multiselect-list
                                    *ngIf="isMultiSelect(param)"
                                    class="inside-dropdown"
                                    [data]="getMultiSelectData(param)"
                                ></dxy-multiselect-list>
                                <dxy-option-list
                                    *ngIf="isSingleSelect(param)"
                                    [options]="getSelectOptions(param)"
                                    [dropdownMenuSelected]="param.value"
                                    (onDropdownMenuSelected)="
                                        onSelectChange($event, param)
                                    "
                                    [isDropdownMenu]="false"
                                ></dxy-option-list>
                            </div>
                        </mat-menu>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>

import { Injectable } from '@angular/core';
import {
    GetUserModulesAccessResult,
    ModuleSecurityApiService,
    ObjectAccessType,
} from '@datagalaxy/webclient/security/data-access';
import { getLocalId } from '@datagalaxy/webclient/utils';
import { LicenseLevel } from '@datagalaxy/webclient/license/domain';
import { DataGalaxyModule } from '@datagalaxy/shared/dg-module/domain';

@Injectable({ providedIn: 'root' })
export class ModuleSecurityService {
    constructor(private securityApiService: ModuleSecurityApiService) {}

    public async setUserObjectSecurity(
        userGuid: string,
        spaceGuid: string,
        module: DataGalaxyModule,
        referenceId: string,
        accessType: ObjectAccessType
    ) {
        return this.securityApiService.setUserModuleSecurity(
            userGuid,
            getLocalId(spaceGuid),
            module,
            referenceId,
            accessType
        );
    }

    public async getUserModulesSecurityData(
        userGuid: string,
        spaceGuid: string,
        versionId: string
    ): Promise<GetUserModulesAccessResult> {
        return await this.securityApiService.getUserModulesSecurityData(
            userGuid,
            getLocalId(spaceGuid),
            versionId
        );
    }

    public getUserObjectAccessTypeByLicenceLevel(
        licenceLevel: LicenseLevel
    ): ObjectAccessType[] {
        switch (licenceLevel) {
            case LicenseLevel.Steward:
                return [
                    ObjectAccessType.Admin,
                    ObjectAccessType.Edit,
                    ObjectAccessType.Viewer,
                    ObjectAccessType.NoAccess,
                ];
            case LicenseLevel.Reader:
            case LicenseLevel.Explorer:
                return [ObjectAccessType.Viewer, ObjectAccessType.NoAccess];
            default:
                return [ObjectAccessType.NoAccess];
        }
    }

    public getObjectAccessTypeTranslateKey(accessType: ObjectAccessType) {
        return `UI.AuthGrid.Column.${ObjectAccessType[accessType]}`;
    }
}

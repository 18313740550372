import { NgClass, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DxyBaseComponent } from '@datagalaxy/ui/core';

/**
 * ## Role
 * Horizontal progress bar
 */
@Component({
    selector: 'dxy-progress-bar',
    templateUrl: 'progress-bar.component.html',
    styleUrls: ['progress-bar.component.scss'],
    standalone: true,
    imports: [NgIf, NgClass],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProgressBarComponent extends DxyBaseComponent {
    @Input() size = 1;
    @Input() value = 0;
    @Input() mode: 'determinate' | 'indeterminate' = 'determinate';

    public get barWidth() {
        const percentage = Math.min(
            100,
            ((this.value ?? 0) / (this.size ?? 1)) * 100
        );
        return `${percentage}%`;
    }

    constructor() {
        super();
    }
}

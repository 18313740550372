<div class="modal-header">
    <h3 mat-dialog-title class="modal-title" translate>
        UI.SpaceHome.settingsModal.title
    </h3>
    <button
        mat-icon-button
        (click)="onCloseCancel()"
        class="modal-close-btn"
    ></button>
</div>
<form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div mat-dialog-content>
        <dxy-image-input
            [params]="searchImageInputParams"
            [isInModalDialog]="true"
            class="search-image-input"
        ></dxy-image-input>
        <p class="search-image-hint" translate>
            UI.SpaceHome.settingsModal.form.imageHint
        </p>

        <dxy-field-text
            labelKey="UI.SpaceHome.settingsModal.form.title"
            [placeholder]="titlePlaceholder"
            formControlName="title"
        ></dxy-field-text>

        <dxy-field-boolean
            labelKey="UI.SpaceHome.settingsModal.form.hideTitle"
            formControlName="hideTitle"
        ></dxy-field-boolean>
    </div>
    <dxy-modal-footer
        [actionEnabled]="true"
        [actionBtnLbl]="'UI.SpaceHome.settingsModal.form.save' | translate"
        [actionLoading]="loading"
        [noMandatory]="true"
        (onCloseCancel)="onCloseCancel()"
    ></dxy-modal-footer>
</form>

import { NgModule } from '@angular/core';
import { DxyScriptGenerationResultModalComponent } from './dxy-script-generation-result-modal/dxy-script-generation-result-modal.component';
import { DxyScriptGenerationSettingsModalComponent } from './dxy-script-generation-settings-modal/dxy-script-generation-settings-modal.component';
import { DxySharedDialogsModule } from '../../shared/dialogs/DxySharedDialogsModule';
import { SharedModule } from '../../shared/shared.module';
import { FieldsModule } from '../../shared/fields/fields.module';

@NgModule({
    imports: [SharedModule, FieldsModule, DxySharedDialogsModule],
    declarations: [
        DxyScriptGenerationSettingsModalComponent,
        DxyScriptGenerationResultModalComponent,
    ],
    exports: [
        DxyScriptGenerationSettingsModalComponent,
        DxyScriptGenerationResultModalComponent,
    ],
})
export class DxyModelerDdlModule {}

import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { executeOnce } from '@datagalaxy/core-ui';
import { NotificationService } from '../notification.service';
import { ITranslatedNotification } from '../notification.types';
import { DxyBaseComponent } from '@datagalaxy/ui/core';

/**
 * ## Role
 * Display a notification content informations
 */
@Component({
    selector: 'app-notification-item',
    templateUrl: 'notification-item.component.html',
    styleUrls: ['notification-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationItemComponent extends DxyBaseComponent {
    @Input() readonly notification: ITranslatedNotification;
    @Input() readonly hideAcknowledgeAction: boolean;

    @Output() readonly isAcknowledgedChanged = new EventEmitter<boolean>();

    protected get acknowledged() {
        return this.source.IsAcknowledged;
    }

    private get source() {
        return this.notification.source;
    }

    constructor(private notificationService: NotificationService) {
        super();
    }

    @executeOnce()
    public async onClickAcknowledge(e: MouseEvent) {
        e.stopPropagation();
        this.isAcknowledgedChanged.emit(true);
        await this.acknowledge();
    }

    @executeOnce()
    public async onClickUnAcknowledge(e: MouseEvent) {
        e.stopPropagation();
        this.isAcknowledgedChanged.emit(false);
        await this.unacknowledge();
    }

    private async acknowledge() {
        if (this.acknowledged) {
            return;
        }
        await this.notificationService.acknowledgeNotification(
            this.notification,
            true
        );
    }

    private async unacknowledge() {
        if (!this.acknowledged) {
            return;
        }
        await this.notificationService.acknowledgeNotification(
            this.notification,
            false
        );
    }
}

import { NgModule } from '@angular/core';
import { DxyOmniGridComponent } from './omni-grid/omni-grid.component';
import { AgGridModule } from 'ag-grid-angular';
import {
    DxyGroupCellComponent,
    DxyLoadingCellComponent,
} from './cell-components';
import { CoreUiModule } from '../core-ui.module';
import { DxyOmniGridColumnSelectorComponent } from './omni-grid-column-selector/omni-grid-column-selector.component';

@NgModule({
    imports: [CoreUiModule, AgGridModule.forRoot()],
    declarations: [
        DxyOmniGridComponent,
        DxyGroupCellComponent,
        DxyOmniGridColumnSelectorComponent,
        DxyLoadingCellComponent,
    ],
    exports: [
        AgGridModule,
        DxyOmniGridComponent,
        DxyOmniGridColumnSelectorComponent,
    ],
})
export class DxyOmnigridModule {}

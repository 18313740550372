import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { ITabsHeaderData } from '@datagalaxy/core-ui';
import { ITabViewItem, ITabViewData } from './dxy-tab-view.types';
import { NavigationService } from '../../../services/navigation.service';
import { CurrentSpaceService } from '../../../services/currentSpace.service';
import {
    CrudOperation,
    FunctionalLogService,
} from '@datagalaxy/webclient/monitoring/data-access';
import { AppEventsService } from '../../../services/AppEvents.service';
import { DxyBaseComponent } from '@datagalaxy/ui/core';

/** Tab view with native material style
 * @example <dxy-tab-view [tabsData]="tabsData"></dxy-tab-view>
 *
 * // In order to use alternative custom style you can add "alternative" class:
 * @example <dxy-tab-view [tabsData]="tabsData" class="alternative"></dxy-tab-view>
 */
@Component({
    selector: 'dxy-tab-view',
    templateUrl: 'dxy-tab-view.component.html',
    styleUrls: ['dxy-tab-view.component.scss'],
})
export class DxyTabViewComponent
    extends DxyBaseComponent
    implements OnChanges, OnInit
{
    @Input() tabsData: ITabViewData;
    @Input() customClass?: string;

    /** Emitted when selected tab changes (before *onActiveTabItem* is called) */
    @Output() readonly onTabChanging = new EventEmitter<ITabViewItem>();

    /** Emitted when selected tab has changed (after *onActiveTabItem*, or navigation) */
    @Output() readonly onTabChanged = new EventEmitter<ITabViewItem>();

    public tabsHeaderData: ITabsHeaderData;
    public noUiView = false;

    protected selectedTabItem: ITabViewItem;

    private customNavigation = false;

    constructor(
        private functionalLogService: FunctionalLogService,
        private navigationService: NavigationService,
        private currentSpaceService: CurrentSpaceService,
        private appEventsService: AppEventsService
    ) {
        super();
    }

    ngOnChanges(changes: SimpleChanges) {
        super.onChange(changes, 'tabsData', () => this.init());
    }

    ngOnInit() {
        this.log('ngOnInit', this.tabsData);
        this.init();

        super.subscribe(
            this.appEventsService.stateChanged$,
            (currentStateName) => {
                if (!this.noUiView) {
                    const currentTab = this.tabsData.tabItems.find((tab) =>
                        currentStateName.includes(tab.tabStateName)
                    );
                    this.selectedTabItem = currentTab;
                }
            }
        );
    }

    public onTabChangeInternal(tabItem: ITabViewItem) {
        this.activeSelectedTabItem(tabItem).then();
    }

    private init() {
        this.noUiView = this.tabsData.noUiView;
        this.customNavigation = this.tabsData.customNavigation;
        this.tabsHeaderData = {
            ...this.tabsData,
            isTabItemActive: (ti) => this.isCurrentTabItem(ti),
        };
        const tabItems = this.tabsData?.tabItems;
        if (!tabItems?.some((ti) => this.isCurrentTabItem(ti))) {
            this.activeSelectedTabItem(tabItems?.[0]).then();
        }
    }

    private isCurrentTabItem(tabItem: ITabViewItem) {
        if (this.noUiView || this.customNavigation) {
            return this.tabsData.isTabItemActive(tabItem);
        }
        return this.navigationService.isUiTabActive(tabItem);
    }

    private logAction(tabItem: ITabViewItem) {
        const code =
            tabItem.customFeatureCode ||
            (this.tabsData.tabFeatureCodeCustomPrefix ||
                this.currentSpaceService.getFuncLogPrefix()) +
                tabItem.tabId.toUpperCase();
        this.functionalLogService.logFunctionalAction(code, CrudOperation.R);
    }

    private async activeSelectedTabItem(tabItem: ITabViewItem) {
        this.selectedTabItem = tabItem;
        this.log('activeSelectedTabItem', tabItem);
        if (!tabItem) {
            return;
        }
        this.logAction(tabItem);
        this.onTabChanging.emit(tabItem);
        if (
            tabItem.isStateNameRelative ||
            this.noUiView ||
            this.customNavigation
        ) {
            this.tabsData.onActiveTabItem(tabItem);
        } else {
            await this.navigationService.gotoUiTabState(tabItem);
        }
        this.onTabChanged.emit(tabItem);
    }
}

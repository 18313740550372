import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BaseComponent } from '@datagalaxy/utils';
import { MultilingualStateService } from '../../multilingual-state.service';

@Component({
    selector: 'dxy-multilingual-lexicon',
    templateUrl: './multilingual-lexicon.component.html',
    styleUrls: ['./multilingual-lexicon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultilingualLexiconComponent extends BaseComponent {
    protected languageCodes$ =
        this.multilingualStateService.selectAllLanguageCodes();
    constructor(private multilingualStateService: MultilingualStateService) {
        super();
    }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { Filter } from '@datagalaxy/webclient/filter/domain';

@Component({
    selector: 'dxy-task-facet-filters',
    templateUrl: 'dxy-task-facet-filters.component.html',
    styleUrls: ['dxy-task-facet-filters.component.scss'],
})
export class DxyTaskFacetFiltersComponent
    extends DxyBaseComponent
    implements OnInit
{
    @Input() groups: TaskSearchFilterGroup[];
    @Input() isLoading: boolean;
    @Output() onFilterChange = new EventEmitter<{
        item: TaskSearchFilterItem;
        group: TaskSearchFilterGroup;
    }>();

    constructor() {
        super();
    }

    ngOnInit() {
        this.log('ngOnInit', this.groups);
    }

    public toggleGroupCollapsed(g: TaskSearchFilterGroup) {
        g.collapsed = !g.collapsed;
    }

    public groupClicked(g: TaskSearchFilterGroup) {
        if (!g.hasAnyItemChecked) {
            return;
        }
        this.itemCheckChanged(null, g);
    }

    public itemCheckChanged(
        it: TaskSearchFilterItem,
        g: TaskSearchFilterGroup
    ) {
        this.onFilterChange.emit({ item: it, group: g });
    }
}

export interface ISubGroupInfo {
    key: string;
    displayName: string;
    glyphClassName: string;
}
export class TaskSearchFilterGroup {
    public items: TaskSearchFilterItem[];
    public collapsed: boolean;
    public allChecked: boolean;
    public subGroups: TaskSearchFilterGroup[];
    public get count() {
        return (this.items && this.items.length) || 0;
    }
    public get totalCount() {
        return this.items && this.items.reduce((p, c) => p + c.dataCount, 0);
    }
    public get id() {
        return 'filterGroup' + this.filterType;
    }
    public get hasAnyItemChecked() {
        return this.items && this.items.some((it) => it.isChecked);
    }
    public get isSubGroupHeaderVisible() {
        return this.displayName && this.count > 0;
    }
    public get isSubGroupCountVisible() {
        return this.count > 1;
    }
    public get subGroupCount() {
        return this.totalCount;
    }

    constructor(
        public filterType: string,
        public displayName: string,
        items = new Array<TaskSearchFilterItem>(),
        isFromSubGroup?: boolean
    ) {
        this.items = items.sort((a, b) =>
            ('' + a.displayName).localeCompare(b.displayName)
        );
        this.allChecked = !this.items.some((it) => !it.isChecked);
        this.subGroups = isFromSubGroup
            ? undefined
            : [
                  new TaskSearchFilterGroup(
                      this.filterType,
                      undefined,
                      this.items,
                      true
                  ),
              ];
    }
}

export class TaskSearchFilterItem {
    public isChecked: boolean;
    public get id() {
        return 'filterItem' + this.filterType + this.filterId;
    }
    public isHidden: boolean;
    constructor(
        public filterType: string,
        public filterId: string,
        public displayName: string,
        public serverFilter: Filter,
        public dataCount = 0
    ) {}
}

import {
    ChangeDetectionStrategy,
    Component,
    OnInit,
    ViewChild,
} from '@angular/core';
import { from, map, merge, Observable } from 'rxjs';
import { ModalSize } from '@datagalaxy/ui/dialog';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { ViewType } from '../../shared/util/app-types/ViewType';
import { LegacyLoginService } from '../../services/login.service';
import { SecurityService } from '../../services/security.service';
import { SupportTicketService } from '../../shared/support/feedback/support-ticket.service';
import { NavigationService } from '../../services/navigation.service';
import { CurrentSpaceService } from '../../services/currentSpace.service';
import { DxyModalService } from '../../shared/dialogs/DxyModalService';
import { FreshDeskService } from '../../shared/support/freshdesk/fresh-desk.service';
import { AppEventsService } from '../../services/AppEvents.service';
import { ViewTypeService } from '../../services/viewType.service';
import { DevPreviewService } from '../../dev-preview-options/dev-preview.service';
import { AppDataService } from '../../services/app-data.service';
import { DxyAboutInfoModalComponent } from '../../about-info/dxy-about-info-modal/dxy-about-info-modal.component';
import { DxyApiDocModalComponent } from '../../api-doc/dxy-api-doc-modal/dxy-api-doc-modal.component';
import { DxyExtensionModalComponent } from '../../dxy-extension-modal/dxy-extension-modal.component';
import { ClientPublicData } from '@datagalaxy/webclient/client/data-access';
import { ClientService } from '../../client/client.service';
import { ClientServiceManagerService } from '../../client/client-service-manager.service';
import { ImportWizardService } from '../../import/services/import-wizard.service';
import { FeatureFlagService } from '@datagalaxy/webclient/feature-flag';
import { AuthenticationService } from '@datagalaxy/webclient/auth/feature';
import { CurrentUserService } from '@datagalaxy/webclient/user/feature/current-user';
import { AppConfigService } from '@datagalaxy/webclient/config';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { StateName } from '@datagalaxy/webclient/routing';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'dxy-navbar-profile-menu',
    templateUrl: './dxy-navbar-profile-dropdown.component.html',
    styleUrls: ['./dxy-navbar-profile-dropdown.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DxyNavbarProfileDropdownComponent
    extends DxyBaseComponent
    implements OnInit
{
    @ViewChild('menuTrigger') menuTrigger: MatMenuTrigger;

    protected readonly StateName = StateName;

    public get currentViewType() {
        return this.viewTypeService.viewType;
    }
    public get showFeedback() {
        return this.supportTicketService.isFeedbackEnabled;
    }
    public get showOnlineHelp() {
        return !this.appConfigService.DISABLE_ONLINE_HELP;
    }
    public get showConnectors() {
        return this.securityService.isDesktopConnectorEnabled();
    }
    public get showBlog() {
        return !this.appConfigService.DISABLE_BLOG;
    }
    public get showApiDocumentation() {
        return !this.appConfigService.DISABLE_PUBLIC_API;
    }
    public get profileModeClass() {
        return this.currentViewType == ViewType.Functional ? 'green' : 'blue';
    }
    public get blogHref() {
        return this.appConfigService.blogUrl;
    }
    public get showSeparator() {
        return this.showConnectors || this.showApiDocumentation;
    }
    public get currentUserId() {
        return this.appDataService.currentUserId;
    }
    public get showDevPreviewOptions() {
        return this.devPreviewService.showOptions;
    }

    public get hasManyClients() {
        return this.authorizedClients.length > 1;
    }

    public viewTypeToggler$: Observable<{
        functional: 'visible' | '';
        technical: 'visible' | '';
    }>;

    protected readonly authorizedClients = this.appDataService.getAllClients();

    constructor(
        private loginService: LegacyLoginService,
        private securityService: SecurityService,
        private supportTicketService: SupportTicketService,
        private navigationService: NavigationService,
        private appConfigService: AppConfigService,
        private currentSpaceService: CurrentSpaceService,
        private dxyModalService: DxyModalService,
        private freshDeskService: FreshDeskService,
        private appEventsService: AppEventsService,
        private viewTypeService: ViewTypeService,
        private devPreviewService: DevPreviewService,
        private appDataService: AppDataService,
        private clientService: ClientService,
        private clientServiceManager: ClientServiceManagerService,
        private importWizardService: ImportWizardService,
        private featureFlagService: FeatureFlagService,
        private authenticationService: AuthenticationService,
        private currentUserService: CurrentUserService
    ) {
        super();
    }

    ngOnInit() {
        this.viewTypeToggler$ = merge(
            from([this.currentViewType]),
            this.appEventsService.viewTypeChange$
        ).pipe(
            map((viewType) => ({
                functional: viewType === 1 ? 'visible' : '',
                technical: viewType === 2 ? 'visible' : '',
            }))
        );
    }

    public openMenu() {
        this.menuTrigger.openMenu();
    }

    public toggleViewTypeSwitch($event: Event) {
        $event.stopPropagation();
        const viewType = this.currentViewType == 1 ? 2 : 1;
        // Some consumers of appEVentsService.viewTypeChange$ do not
        // rely on the emited value but on the ViewTypeService public
        // getter instead, assuming the property is up-to-date.
        // So we make sure the dedicated service is updated first
        void this.viewTypeService.setViewType(viewType);
        this.appEventsService.emitViewTypeChange(viewType);
    }

    public isCurrentClient(client: ClientPublicData): boolean {
        if (this.featureFlagService.isFeatureEnabled('ENABLE_AUTH_V2')) {
            return this.currentUserService.clientId === client.ClientId;
        }
        return (
            this.navigationService.getCurrentStateClientId() === client.ClientId
        );
    }

    public async changeCurrentClient(client: ClientPublicData) {
        if (this.currentUserService.clientId === client.ClientId) {
            return;
        }

        if (this.featureFlagService.isFeatureEnabled('ENABLE_AUTH_V2')) {
            const userClient =
                this.currentUserService.userInfo.AuthorizedClients?.find(
                    (c) => c.ClientId == client.ClientId
                );
            const url = userClient?.DedicatedUrl;
            if (url) {
                window.location.href = url;
            }
            return;
        }
        this.navigationService.clearMemoState();
        this.currentSpaceService._clearCurrentAndLastSpace();
        await this.navigationService.goToClientMain(client.ClientId);
    }

    public isClientAdmin() {
        return this.securityService.isCurrentUserClientAdmin();
    }

    public async onClickLogoutLink() {
        if (this.featureFlagService.isFeatureEnabled('ENABLE_AUTH_V2')) {
            this.authenticationService.logout();
            return;
        }
        await this.clientService.logout();
        await this.clientServiceManager.cleanupServices();
        await this.loginService.logout();
    }

    public async onClickUserProfileLink() {
        await this.navigationService.goToUserProfile();
    }

    public async onClickExtension() {
        await this.openExtensionModal();
    }

    public async onClickConnectors() {
        await this.importWizardService.openImportModal();
    }

    public async onClickFeedback() {
        await this.supportTicketService.openSupportTicketModal();
    }

    public async onClickAbout() {
        await this.openAboutInfoModal();
    }

    public async onClickApiDocumentation() {
        await this.openDocumentationModal();
    }

    public openFreshworksWidget() {
        this.freshDeskService.openFreshworksWidget();
    }

    private async openAboutInfoModal() {
        await this.dxyModalService.open<DxyAboutInfoModalComponent, void, void>(
            {
                componentType: DxyAboutInfoModalComponent,
            }
        );
    }

    private async openExtensionModal() {
        await this.dxyModalService.open<DxyExtensionModalComponent, void, void>(
            {
                componentType: DxyExtensionModalComponent,
            }
        );
    }

    private async openDocumentationModal() {
        await this.dxyModalService.open<DxyApiDocModalComponent, void, void>({
            componentType: DxyApiDocModalComponent,
            size: ModalSize.Large,
        });
    }
}

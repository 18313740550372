import { DxyBaseModalComponent } from '@datagalaxy/ui/dialog';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MsTeamsIntegrationService } from '../ms-teams-integration.service';
import { DomainName } from '../domain-name';
import {
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
} from '@angular/forms';
import {
    IMsGraphDomainForm,
    IMsTeamsIntegrationForm,
} from './ms-teams-integration-activation.type';
import { firstValueFrom } from 'rxjs';
import { withLoading } from '@datagalaxy/core-ui';
import { createDomainNameValidator } from '../domain-name-validator';
import { UserService } from '../../../../services/user.service';
import { ToasterService } from '../../../../services/toaster.service';

/**
 * ## Role
 * Automatic MsTeams tenantId retrival
 *
 * ## Features
 * - Get the tenantId autimatically
 * - Let the ability to put another tenant id
 */
@Component({
    selector: 'app-ms-teams-integration-activation-modal',
    templateUrl: './ms-teams-integration-activation-modal.component.html',
    styleUrls: ['ms-teams-integration-activation-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MsTeamsIntegrationActivationModalComponent extends DxyBaseModalComponent<
    void,
    boolean
> {
    protected domainFormGroup: FormGroup<IMsGraphDomainForm>;
    protected tenantFormGroup: FormGroup<IMsTeamsIntegrationForm>;

    constructor(
        dialogRef: MatDialogRef<
            MsTeamsIntegrationActivationModalComponent,
            boolean
        >,
        private msTeamsIntegrationService: MsTeamsIntegrationService,
        private toasterService: ToasterService,
        private userService: UserService,
        private fb: FormBuilder
    ) {
        super(dialogRef, undefined);
        this.initAsync();
    }

    @withLoading()
    protected async getTenantIdFromDomainName() {
        const domainName = this.domainFormGroup.value.domainName;
        try {
            const tenantId = await firstValueFrom(
                this.msTeamsIntegrationService.getMsTeamsTenantId(
                    new DomainName(domainName)
                )
            );
            this.tenantFormGroup.patchValue({ tenantId });
        } catch {
            this.toasterService.warningToast({
                messageKey:
                    'UI.NotificationChannels.MsTeams.IntegrationModal.unknownDomainName',
            });
        }
    }

    @withLoading()
    protected async activateTeamsIntegration() {
        const fg = this.tenantFormGroup;
        if (!fg.valid) {
            return;
        }
        await this.msTeamsIntegrationService.activate(fg.value.tenantId);
        this.result = true;
        this.toasterService.successToast({
            titleKey: 'UI.Global.success',
            messageKey:
                'UI.NotificationChannels.MsTeams.IntegrationModal.activationSuccess',
        });
        this.onCloseSubmit();
    }

    private async initAsync() {
        const userEmail = this.userService.getCurrentUser()?.Email;
        if (!userEmail) {
            return;
        }
        const domainName = DomainName.fromEmail(userEmail);
        this.domainFormGroup = this.fb.group<IMsGraphDomainForm>({
            domainName: new FormControl(
                domainName.toString(),
                createDomainNameValidator()
            ),
        });
        this.tenantFormGroup = this.fb.group<IMsTeamsIntegrationForm>({
            tenantId: new FormControl(undefined, Validators.required),
        });

        await this.getTenantIdFromDomainName();
    }
}

<ng-container *ngIf="entityStreamInfos$ | async as streamInfos">
    <ng-container *ngFor="let stream of streamInfos">
        <app-lineage-stream-explorer
            [stream]="stream"
            [ngClass]="streamClass(stream)"
            (select)="onSelect($event, stream)"
            (mousedown)="$event.stopPropagation()"
            (mouseenter)="onStreamEnter($event)"
            (mouseleave)="onStreamLeave($event)"
        ></app-lineage-stream-explorer>
    </ng-container>
</ng-container>

<ng-container *ngIf="entityHiddenStreamInfos$ | async as streamInfos">
    <ng-container *ngFor="let stream of streamInfos">
        <app-lineage-stream-explorer
            class="hidden-stream"
            [stream]="stream"
            [ngClass]="streamClass(stream)"
            (select)="onSelect($event, stream, true)"
            (mousedown)="$event.stopPropagation()"
            (mouseenter)="onStreamEnter($event)"
            (mouseleave)="onStreamLeave($event)"
        ></app-lineage-stream-explorer>
    </ng-container>
</ng-container>

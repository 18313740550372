import { IHasTechnicalName } from '@datagalaxy/data-access';
import {
    deserialize,
    deserializeAs,
    GenericDeserialize,
    SerializableEnumeration,
} from 'cerialize';
import { HierarchicalData } from '@datagalaxy/dg-object-model';
import {
    EntityItem,
    EntityTypeMeta,
} from '@datagalaxy/webclient/entity/domain';

export enum DataProcessingItemType {
    /** NOTE: for the DataProcessingItemType Undefined is a real valid value */
    Undefined = 0,
    Copy = 1,
    Concatenate = 2,
    Substring = 3,
    Formula = 4,
    Agregate = 5,
    Convert = 6,
    Search = 7,
    InnerJoin = 8,
    ConstantVariable = 9,
    Custom = 10,
    OuterJoin = 11,
    Filter = 12,
    Calculation = 13,
}
SerializableEnumeration(DataProcessingItemType);

export class DataProcessingItemDto implements IHasTechnicalName {
    @deserialize public ReferenceId?: string;
    @deserialize public DisplayName: string;
    @deserialize public TechnicalName: string;
    @deserialize public Description?: string;
    @deserialize public LongDescription?: string;
    @deserialize public WithLinkedData: boolean;
    @deserializeAs(DataProcessingItemType)
    public ItemType: DataProcessingItemType;
    @deserializeAs(HierarchicalData) public InputLinks: HierarchicalData[];
    @deserializeAs(HierarchicalData) public OutputLinks: HierarchicalData[];

    // Not deserialized, fetched from GetDataProcessingLinks result
    public Meta?: EntityTypeMeta;
    public highlighted?: boolean;

    //#region static
    //#archi-enum-translate
    public static getTypeTranslateKey(type: DataProcessingItemType) {
        return `DgServerTypes.DataProcessingItemType.${DataProcessingItemType[type]}`;
    }

    //#endregion

    public get dpiTypeTranslateKey() {
        return DataProcessingItemDto.getTypeTranslateKey(this.ItemType);
    }

    constructor(dpiEntityItem?: EntityItem) {
        this.ReferenceId = dpiEntityItem?.ReferenceId;
        this.DisplayName = dpiEntityItem?.DisplayName || '';
        this.TechnicalName = dpiEntityItem?.TechnicalName || '';
        this.Description = dpiEntityItem?.Description;
        this.LongDescription =
            dpiEntityItem?.getAttributeValue<string>('LongDescription');

        this.ItemType =
            DataProcessingItemType[
                dpiEntityItem?.getAttributeValue<string>(
                    'ItemType'
                ) as keyof typeof DataProcessingItemType
            ] ||
            DataProcessingItemType[
                dpiEntityItem?.getAttributeValue<string>(
                    'Type'
                ) as keyof typeof DataProcessingItemType
            ];

        const inputLinks =
            dpiEntityItem?.getAttributeValue<HierarchicalData[]>(
                'ObjectLinks_HasInput_HData'
            ) ?? [];
        this.InputLinks = inputLinks.map((c) =>
            GenericDeserialize(c, HierarchicalData)
        );
        const outputLinks =
            dpiEntityItem?.getAttributeValue<HierarchicalData[]>(
                'ObjectLinks_HasOutput_HData'
            ) ?? [];
        this.OutputLinks = outputLinks.map((c) =>
            GenericDeserialize(c, HierarchicalData)
        );

        this.WithLinkedData = !!(
            this.InputLinks.length || this.OutputLinks.length
        );
    }
}

import { Observable, OperatorFunction } from 'rxjs';
import { INgZone } from '@datagalaxy/utils';

export class RxjsUtils {
    public static runInZone<T>(zone: INgZone): OperatorFunction<T, T> {
        return (source) =>
            new Observable<T>((observer) => {
                const onNext = (value: T) =>
                    zone.run(() => observer.next(value));
                const onError = (error: any) =>
                    zone.run(() => observer.error(error));
                const onComplete = () => zone.run(() => observer.complete());

                return source.subscribe({
                    next: onNext,
                    error: onError,
                    complete: onComplete,
                });
            });
    }
}

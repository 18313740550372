import { D3DragEvent } from 'd3-drag';
import { Subscription } from 'rxjs';
import { CoreUtil, DomUtil } from '@datagalaxy/core-util';
import {
    D3Helper,
    GraphSurface,
    GraphSurfaceOptions,
    SD3,
    SurfaceLayer,
} from '@datagalaxy/core-d3-util';
import {
    BurgerMenuManager,
    CoreEventsService,
    IFunctionalEvent,
} from '@datagalaxy/core-ui';
import { LineageUiElementType, SD3Items } from './lineage.utils';
import { LineageLayout } from './managers/LineageLayout';
import { LineageGraphService } from './lineage-graph.service';
import {
    AfterViewInit,
    Component,
    ElementRef,
    Input,
    NgZone,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
    DxyGraphicalControlsComponent,
    IGraphicalControlEvents,
    IGraphicalToolbarOption,
} from '@datagalaxy/core-ui/graphical';
import { ImpactAnalysisService } from '../impact-analysis.service';
import { LineageActionButtonProvider } from './managers/LineageActionButtonProvider';
import { LineageGraphParams } from './data/LineageGraphParams';
import {
    LineageConstants,
    svgLineageItemLayout,
    ZoomType,
} from './data/LineageConstants';
import { LineageItem } from './data/LineageItem';
import {
    IDataIdentifier,
    IEntityIdentifier,
    ObjectLinkType,
    ServerType,
} from '@datagalaxy/dg-object-model';
import { LineagePathTracker } from './managers/LineagePathTracker';
import { LineageRenderer } from './managers/LineageRenderer';
import {
    ILineageBurgerMenuContext,
    LineageBurgerMenuActionProvider,
} from './managers/LineageBurgerMenuActionProvider';
import { ILineageDataBuildOptions, LineageData } from './data/LineageData';
import {
    EntityEventService,
    IDataIdentifierEventHandler,
} from '../../shared/entity/services/entity-event.service';
import { NavigationService } from '../../services/navigation.service';
import { EntityPreviewPanelService } from '../../shared/entity/services/entity-preview-panel.service';
import { TaskService } from '../../tasks/task.service';
import { CommentaryService } from '../../commentary/commentary.service';
import { EntityService } from '../../shared/entity/services/entity.service';
import { AppEventsService } from '../../services/AppEvents.service';
import { LineageLink } from './data/LineageLink';
import { ViewType } from '../../shared/util/app-types/ViewType';
import {
    DataLineageDataLink,
    GetDataLineageResult,
    LineageOrientation,
} from '@datagalaxy/webclient/explorer/data-access';
import { getLocalId } from '@datagalaxy/webclient/utils';
import {
    DeleteLinkedEntitiesParameter,
    UpdateEntityLinkResult,
    UpdateLinkAction,
} from '@datagalaxy/webclient/entity/data-access';
import {
    CrudOperation,
    FunctionalLogService,
} from '@datagalaxy/webclient/monitoring/data-access';
import { DataProcessingUiService } from '../../data-processing/services/data-processing-ui.service';
import {
    EntityIdentifier,
    EntityTypeUtils,
} from '@datagalaxy/webclient/entity/utils';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { DxyTooltipService } from '@datagalaxy/ui/tooltip';
import { ZoneUtils } from '@datagalaxy/utils';
import { IMiniEntityContent } from '@datagalaxy/webclient/entity/domain';
import { MoveBuffer } from './lineage.buffer';

@Component({
    selector: 'app-lineage-graph',
    templateUrl: 'lineage-graph.component.html',
})
export class LineageGraphComponent
    extends DxyBaseComponent
    implements OnChanges, OnInit, AfterViewInit, OnDestroy
{
    private readonly debugForceLazyBehaviour: {
        warningThreshold: number;
        maxItemsCount: number;
    };
    //= { warningThreshold: 6, maxItemsCount: 3 }

    /*
     * TODO: take links into account on h.ellipsis.
     * => forbid ellipsis of a linked node ?
     */

    public get debug() {
        return this._debug ? (this.__debug as any) : null;
    }
    protected set debug(value: any) {
        this._debug = !!value;
    }

    private readonly __debug = {
        //#region logging
        detailed: false,
        verbose: false,
        toolbar: false,
        surface: false,
        surfaceElements: false,
        renderer: false,
        data: true,
        layout: false,
        pathTracker: false,
        boundingBox: false,
        ellipsisText: false,
        burgerMenu: false,
        onHoverItemHead: false,
        onElementCreated: false,
        computeToggleExpandCollapse: false,
        //#endregion
        noZoomReset: false,
        noHideBurgerMenu: false,
    };

    //#region constants and properties

    @Input() graphParams: LineageGraphParams;
    @Input() actionButtons: IGraphicalToolbarOption[];
    @Input() initExpanded?: boolean;
    @Input() noPathTracker?: boolean;
    @Input() allowGoldenLinkCreation?: boolean;

    public toolBarEvents: IGraphicalControlEvents;
    public isLoadingSourceData: boolean;
    public get hasLineageData() {
        return !!this.lineageData;
    }
    public get burgerMenuOptions() {
        return this.burgerMenuManager?.burgerMenuOptions;
    }
    public get optionData() {
        return this.burgerMenuManager?.optionData;
    }
    public get isFullScreen() {
        return !!this.graphicalControls?.isFullScreen;
    }
    public get zoom() {
        return this.d3ZoomSurface?.zoom.scale;
    }

    @ViewChild(DxyGraphicalControlsComponent)
    private graphicalControls: DxyGraphicalControlsComponent;
    private readonly itemSpecs: LineageConstants;
    private readonly layout: LineageLayout;
    private readonly hierarchicalEllipsisClickDisabled =
        LineageConstants.behaviour.hierarchicalEllipsis.globalDisabled ||
        LineageConstants.behaviour.hierarchicalEllipsis.clickDisabled;
    private readonly maxGlobalUiExpandLevel =
        LineageConstants.behaviour.expandLevel.max;
    private readonly entitiesByItem = new Map<
        LineageItem,
        IMiniEntityContent
    >();
    private readonly dynamicSubscriptions = new Map<string, Subscription>();
    private d3ZoomSurface: GraphSurface<SVGGElement, LineageItem>;
    private readonly surfaceCfg: GraphSurfaceOptions<SVGGElement, LineageItem> =
        {
            debug: this.debug?.surface,
            nodeDrag: {
                callbacks: {
                    onDragStart: (d) => this.onDragStart(d),
                    onDragMove: (d, event) => this.onDragMove(event, d),
                    onDragEnd: (d) => this.onDragEnd(d),
                },
            },
        };
    private globalUiExpandLevel =
        LineageConstants.behaviour.expandLevel.initial;
    private autoExpandSearchedItemAncestors: boolean;
    private pathTracker: LineagePathTracker;
    private renderer: LineageRenderer;
    private moveBuffer: MoveBuffer<LineageItem>;
    private burgerMenuManager: BurgerMenuManager<
        LineageItem,
        ILineageBurgerMenuContext
    >;
    private actionButtonsProvider: LineageActionButtonProvider;
    private graphicalSurface: SD3<SVGGElement>;
    private d3ItemsContainer: SD3<SVGGElement>;
    private d3LinksContainer: SD3<SVGGElement>;
    private serverSourceData: GetDataLineageResult;
    private lineageData: LineageData;
    private isDragging: boolean;
    private isAnyHEllipsis: boolean;
    private isSplitRootsActive: boolean;
    private hasGoldenLinks: boolean;
    private areGoldenLinksVisible = true;
    private forceLoadAllItems = false;
    private areSiblingDpisVisible = false;
    private debounceApplyZoom: number;
    private isLoadingChildren = false;
    private isDisposing = false;
    private isUdatingData = false;

    private get container() {
        return DomUtil.getElement(this.elementRef, '.d3div');
    }

    //#endregion

    //#region initialization

    constructor(
        public elementRef: ElementRef<HTMLElement>,
        private translate: TranslateService,
        private entityPreviewPanelService: EntityPreviewPanelService,
        private entityEventService: EntityEventService,
        private navigationService: NavigationService,
        private taskService: TaskService,
        private commentaryService: CommentaryService,
        private entityService: EntityService,
        private coreEventsService: CoreEventsService,
        private appEventsService: AppEventsService,
        private functionalLogService: FunctionalLogService,
        private lineageGraphService: LineageGraphService,
        private impactAnalysisService: ImpactAnalysisService,
        private ngZone: NgZone,
        private tooltipService: DxyTooltipService,
        private dataProcessingUiService: DataProcessingUiService
    ) {
        super();
        this.itemSpecs = new LineageConstants(svgLineageItemLayout);
        this.layout = new LineageLayout(this.itemSpecs, this.debug?.layout);

        if (this.debug && this.debugForceLazyBehaviour) {
            LineageConstants.behaviour.lazyItems.maxItemsCount =
                this.debugForceLazyBehaviour.maxItemsCount;
            LineageConstants.behaviour.lazyItems.warningThreshold =
                this.debugForceLazyBehaviour.warningThreshold;
        }
        this.log('behaviour.lazyItems', LineageConstants.behaviour.lazyItems);
    }

    ngOnChanges(changes: SimpleChanges) {
        super.onChange(
            changes,
            'graphParams',
            (cur: LineageGraphParams, prv: LineageGraphParams) => {
                if (
                    cur &&
                    prv &&
                    LineageGraphParams.isOrientationChange(cur, prv)
                ) {
                    this.onOrientationChanged(cur.orientation, prv.orientation);
                } else {
                    this.onSourceChanged();
                }
            }
        );
    }

    ngOnInit() {
        this.log('ngOnInit', this.graphParams);

        //#region html bindings

        this.toolBarEvents = {
            onScreenshot: () => this.takeScreenShot(),
            onZoomIn: () => this.d3ZoomSurface.zoom.stepIn(),
            onZoomOut: () => this.d3ZoomSurface.zoom.stepOut(),
            onZoomReset: () => this.applyZoomBestFit(true),
            onFullScreenChanged: () => this.onSurfaceSizeChange(true),
            debug: this.debug?.toolbar,
        };

        if (this.initExpanded) {
            this.globalUiExpandLevel = this.maxGlobalUiExpandLevel;
        }

        //#endregion

        //#region sub-components

        this.moveBuffer = new MoveBuffer<LineageItem>();

        this.renderer = new LineageRenderer(
            this.itemSpecs,
            () => this.d3HGroups(),
            (filter, selector) => this.d3Items(filter, selector),
            (filter) => this.d3Links(filter),
            (d3items, elementType) =>
                this.onElementCreated(d3items, elementType),
            () => this.isAnyHEllipsis,
            (item) => this.getHEllipsisText(item),
            this.hierarchicalEllipsisClickDisabled,
            (d, w, n, t) => this.onItemTextDrawn(d, w, n, t),
            (this.debug?.renderer && {
                showBlueprints: this.debug.showBlueprints,
            }) ||
                null,
            (d) => EntityTypeUtils.getGlyphColorClass(d.entityType),
            (el, messageTranslateKey) =>
                this.tooltipService.setTooltip(
                    el,
                    this.translate.instant(messageTranslateKey)
                ),
            (el) => this.tooltipService.removeTooltip(el)
        );

        this.pathTracker = new LineagePathTracker(
            (filter, selector) => this.d3Items(filter, selector),
            (filter) => this.d3Links(filter),
            this.renderer,
            (el, messageTranslateKey, position) =>
                this.tooltipService.setTooltip(
                    el,
                    this.translate.instant(messageTranslateKey),
                    position
                ),
            (els) => this.tooltipService.removeTooltips(els),
            (trackId, isUnpin) =>
                this.functionalLogService.parseAndLog(
                    this.getActionFunctionalLog(
                        `${isUnpin ? 'UNPIN' : 'PIN'}_TRACK_${
                            trackId == 2
                                ? 'RED'
                                : trackId == 1
                                ? 'BLUE'
                                : 'OTHER'
                        }`
                    )
                ),
            this.debug?.pathTracker
        );

        this.actionButtonsProvider = new LineageActionButtonProvider({
            impactAnalysisService: this.impactAnalysisService,
            getLineageOrientation: () => this.graphParams.orientation,
            getActionFunctionalLog: (actionType) =>
                this.getActionFunctionalLog(actionType),

            getExpandLevel: () => this.globalUiExpandLevel,
            applyExpandLevel: (level) => this.applyGlobalExpandCollapse(level),

            isSplitRootsActive: () => this.isSplitRootsActive,
            toggleSplitRoots: async () => {
                this.isSplitRootsActive = !this.isSplitRootsActive;
                await this.updateData(false);
            },

            isAnyHEllipsis: () => this.isAnyHEllipsis,
            toggleHEllipsis: () => this.applyGlobalToggleHEllipsis(),

            applyHorizontalFlip: () => this.applyHorizontalFlip(),

            pathTracker: this.pathTracker,
            isNoPathTracker: () => this.noPathTracker,

            isAnyGoldenLink: () => this.hasGoldenLinks,
            areGoldenLinksVisible: () => this.areGoldenLinksVisible,
            toggleGoldenLinks: () => {
                this.areGoldenLinksVisible = !this.areGoldenLinksVisible;
                this.renderer.showHideGoldenLinksAndItems(
                    this.areGoldenLinksVisible
                );
            },

            areSiblingDpisVisible: () => this.areSiblingDpisVisible,
            toggleSiblingDpis: async () => {
                this.areSiblingDpisVisible = !this.areSiblingDpisVisible;
                await this.updateData(true);
            },
        });

        //#endregion

        super.subscribe(this.impactAnalysisService.onSelectedToolChange$, () =>
            setTimeout(() => this.initActionButtons(true))
        );

        this.log('ngOnInit-end');
    }

    ngAfterViewInit() {
        /**
         * Give enough time to the parent component to give the full height/width
         * otherwise it could be instantiated with half of the space with ui-view
         * transitioning the last component from the current one
         */
        setTimeout(() => this.init2(), 500);
    }

    ngOnDestroy() {
        this.isDisposing = true;
        super.ngOnDestroy();
    }

    public onLogFunctional(event: IFunctionalEvent) {
        this.functionalLogService.parseAndLog(event.text, event.origin);
    }

    private async init2() {
        this.log('init2');
        this.renderer.viewType = this.lineageGraphService.activeViewType;
        const success = await this.initGraphics();
        this.subscribeEvents();
        if (!success) {
            return;
        }
        this.initBurgerMenuManager();
        await this.updateData(true, false);
    }

    private initActionButtons(force = false) {
        if (force) {
            this.actionButtons = undefined;
        }
        if (this.hasLineageData) {
            this.actionButtons ??= this.actionButtonsProvider.getOptions();
        }
    }

    private getActionFunctionalLog(actionType: string) {
        return actionType ? `LINEAGE,A,${actionType}` : null;
    }

    private initBurgerMenuManager() {
        this.burgerMenuManager = new BurgerMenuManager<
            LineageItem,
            ILineageBurgerMenuContext
        >(
            DomUtil.getElement(this.elementRef, 'dxy-graphical-controls'),
            new LineageBurgerMenuActionProvider(
                this.navigationService,
                this.taskService,
                this.commentaryService,
                this.entityService,
                this.pathTracker,
                (item, entityData) => this.cacheEntity(item, entityData),
                this.graphParams.originIdr,
                this.graphParams.canUnlink
                    ? (item) => this.onActionUnlink(item)
                    : undefined,
                (actionType, lineageLink) =>
                    this.onActionSetGoldenLink(actionType, lineageLink),
                this.debug?.burgerMenu
            ),
            {
                showHideBurgerIcon: (show, item) =>
                    this.renderer.showHideBurgerIcon(show, item),
                getMenuOffset: (icon) =>
                    this.getBurgerMenuOffset(icon as HTMLElement),
                getContext: (item) => this.getBurgerMenuContext(item),
                onItemInOut: (show, item) =>
                    this.pathTracker.highLight(
                        show,
                        item,
                        LineageConstants.behaviour.pathTracker.highlightMaxDepth
                    ),
                debug: this.debug?.burgerMenu,
            }
        );
    }

    private async initGraphics() {
        this.log('initGraphics');
        const success = (this.d3ZoomSurface = new GraphSurface<
            SVGGElement,
            LineageItem
        >(this.container, this.surfaceCfg));
        this.log('initGraphics-container', success);
        if (!success) {
            return false;
        }

        this.renderer.createDefs(this.d3ZoomSurface.svgL.defs.d3);
        // drop-shadow filter for outer items
        this.defineShadowFilter();

        // container for every svg element
        this.graphicalSurface = this.d3ZoomSurface.svgL.d3;
        // container for items
        this.d3ItemsContainer = this.graphicalSurface
            .append('g')
            .attr('class', 'lineage-items');
        // container for links
        this.d3LinksContainer = this.graphicalSurface
            .append('g')
            .attr('class', 'lineage-links');
        // activate the debug stylesheet
        this.elementRef.nativeElement.classList.toggle(
            'debug',
            !!this.debug?.surfaceElements
        );

        return true;
    }

    /**
     * Appends a drop-shadow definition the svg document.
     * To be used on element with: `.style('filter', 'url(#drop-shadow)')`
     */
    private defineShadowFilter(
        filterId = 'drop-shadow',
        dx = 0,
        dy = 2,
        stdDeviation = 2,
        floodColor = 'rgba(2,22,142,0.2)',
        heightRatio = 1.3
    ) {
        const svg = this.d3ZoomSurface.svgL;
        if (!svg) {
            return;
        }
        D3Helper.createDropShadowFilter(
            svg.defs.d3,
            filterId,
            dx,
            dy,
            stdDeviation,
            floodColor,
            heightRatio
        );
    }

    private subscribeEvents() {
        this.log('subscribeEvents');

        super.subscribe(this.d3ZoomSurface.events.zoomed$, () =>
            this.onZoomed()
        );

        // update surface size on view size change
        super.subscribe(this.coreEventsService.mainViewResize$, () =>
            this.onSurfaceSizeChange()
        );

        // apply zoom best fit on full page change
        super.subscribe(this.appEventsService.entityFullPageChanged$, () =>
            this.onSurfaceSizeChange(true)
        );

        // respond to technical/functional view change
        super.subscribe(this.appEventsService.viewTypeChange$, () =>
            this.onViewTypeChanged()
        );

        // respond to data changes
        this.subscribeEntityEvent(
            this.entityEventService.subscribeEntityLinkAdd
        );

        // respond to golden link update
        this.entityEventService.subscribeEntityLinkUpdateGoldenLink(
            (data: UpdateEntityLinkResult) => this.onGoldenLinkUpdate(data)
        );
    }

    //#endregion initialization

    //#region event handlers

    private onSurfaceSizeChange(isFullScreenChange = false) {
        if (this.isDisposing || this.isUdatingData) {
            return;
        }
        this.log('onSurfaceSizeChange', isFullScreenChange);
        if (isFullScreenChange) {
            this.d3ZoomSurface?.viewport
                .updateViewportAsync(false)
                .then(() => this.applyZoomBestFit(false));
        } else {
            const zoomType =
                LineageConstants.behaviour.zoom.onSurfaceSizeChange;
            this.applyZoom(zoomType, false, true);
            this.d3ZoomSurface?.viewport.updateViewport({ debounce: true });
        }
    }

    private async onSourceChanged() {
        this.log('onSourceChanged', this.graphParams?.originIdr);

        this.subscribeEntityEvent(
            this.entityEventService.subscribeEntityLinkIdentifierAdd
        );
        this.subscribeEntityEvent(
            this.entityEventService.subscribeEntityLinkIdentifierDelete
        );

        await this.updateData(false, false);
    }
    private async onEntityEvent(data: IDataIdentifier) {
        const matchesCurrent =
            this.graphParams?.originIdr &&
            this.graphParams.originIdr.ReferenceId == data.DataReferenceId;
        this.log('onEntityEvent', matchesCurrent);
        if (matchesCurrent) {
            await this.updateData(true, false);
        }
    }
    private onViewTypeChanged() {
        this.log('onViewTypeChanged');
        this.renderer.onViewTypeChanged(
            this.lineageGraphService.activeViewType
        );
    }
    private async onOrientationChanged(
        newOrient: LineageOrientation,
        oldOrient: LineageOrientation
    ) {
        if (newOrient == oldOrient) {
            return;
        }
        this.log(
            'onOrientationChanged',
            oldOrient,
            newOrient,
            LineageOrientation[this.graphParams?.orientation]
        );
        await this.updateData(true, false);
    }

    private onHoverItemHead(isIn: boolean, item: LineageItem) {
        this.debug?.onHoverItemHead && this.log('onHoverItemHead', isIn, item);
        if (isIn && this.isDragging) {
            return;
        }
        this.renderer.showHidePreviewIcon(isIn, item);
        if (isIn || !this.debug?.noHideBurgerMenu) {
            this.burgerMenuManager.onHoverItem(isIn, item);
        }
    }
    private onClickItemHead(
        event: MouseEvent,
        item: LineageItem,
        node: SVGGElement
    ) {
        this.log('onClickItemHead', this.isDragging, event, item, node);
        if (this.isDragging) {
            return;
        }
        event.stopPropagation();
        if (event.ctrlKey || event.metaKey) {
            this.onClickForSelectAddRemove(item, node);
        } else if (
            item.lazyChildrenCount &&
            LineageConstants.behaviour.lazyItems.enableLoadChildren
        ) {
            this.onClickForLoadChildren(item);
        } else if (item.hasChildren) {
            this.onClickForExpandCollapse(item);
        }
        this.burgerMenuManager.updateIcon(item);
    }
    private onClickHEllipsis(
        event: MouseEvent,
        item: LineageItem,
        node: SVGGElement
    ) {
        this.log('onClickHEllipsis', event, item, node);
        this.applyToggleHEllipsis(item);
    }
    private onClickForExpandCollapse(item: LineageItem) {
        this.log('onClickForExpandCollapse', item);
        this.renderer.bringToFront(item.root);
        this.applyExpandCollapse(item);
    }
    private async onClickForLoadChildren(item: LineageItem) {
        if (this.isLoadingChildren) {
            return;
        }
        this.isLoadingChildren = true;
        try {
            this.log('onClickForLoadChildren', item);
            this.renderer.bringToFront(item.root);
            await this.loadChildrenAndLinked(item);
        } finally {
            this.isLoadingChildren = false;
        }
    }

    private onDragStart(item: LineageItem) {
        this.log('onDragStart', item);
        this.moveBuffer.start((dx, dy) => this.moveItem(item, dx, dy));
    }
    private onDragMove(event: D3DragEvent<any, any, any>, item: LineageItem) {
        if (!this.isDragging) {
            this.renderer.bringToFront(item.root);
        }
        this.isDragging = true;
        if (this.debug?.verbose) {
            this.log('onDragMove', item);
        }
        this.moveBuffer.update(event);
    }
    private onDragEnd(item: LineageItem) {
        this.log('onDragEnd', item);
        this.moveBuffer.end();
        ZoneUtils.zoneTimeout(
            () => (this.isDragging = false),
            111,
            this.ngZone,
            true
        );
    }
    private onZoomed() {
        this.log('zoomed');
        this.burgerMenuManager.updateMenuPosition();
    }

    private onElementCreated(
        d3item: SD3Items,
        elementType: LineageUiElementType
    ) {
        this.debug?.onElementCreated &&
            this.log(
                'onElementCreated',
                LineageUiElementType[elementType],
                d3item
            );
        switch (elementType) {
            case LineageUiElementType.HEllipsis:
                if (this.hierarchicalEllipsisClickDisabled) {
                    return;
                }
                D3Helper.bindClick(d3item, (it, el, event) =>
                    this.onClickHEllipsis(event, it, el)
                );
                break;

            case LineageUiElementType.BurgerIcon:
                this.burgerMenuManager.onBurgerIconCreated(d3item.node());
                break;

            case LineageUiElementType.PreviewIcon:
                D3Helper.bindClick(
                    d3item,
                    (it) => {
                        if (LineageGraphService.isEntity(it))
                            this.openPreviewPanel(it);
                        else
                            this.dataProcessingUiService.showDpiEditModalFromEntityIdr(
                                this.getEntityIdentifier(it)
                            );
                    },
                    true
                );
                break;
        }
    }

    private openPreviewPanel(item: LineageItem) {
        this.log('openPreviewPanel', item);
        const entityIdr = this.getEntityIdentifier(item);
        this.entityPreviewPanelService.setupPanel({ entityIdr });
    }

    private onClickForSelectAddRemove(item: LineageItem, node: SVGGElement) {
        this.log('onClickForSelectAddRemove', 'TODO', item, node);
    }

    private async onActionUnlink(item: LineageItem) {
        const source = this.graphParams.originIdr;
        const target = this.getEntityIdentifier(item);

        const unlink = async (linkType: ObjectLinkType) => {
            this.log?.('onActionUnlink', source, target, linkType);
            const parameter = DeleteLinkedEntitiesParameter.createModern(
                [source.ReferenceId],
                linkType,
                [target.ReferenceId]
            );
            parameter.setVersionId(source.VersionId);
            return this.entityService.deleteEntityReferencesGeneric(parameter);
        };

        const link = this.getLink(item);
        await unlink(link.linkType);

        // Need to check for possible second link on same item
        const secondLink = this.getSecondLink(link);
        if (secondLink) {
            await unlink(secondLink.UniversalObjectLinkType);
        }

        await this.updateData(true, false);
    }

    // called on burger menu action click
    private async onActionSetGoldenLink(
        actionType: UpdateLinkAction,
        lineageLink: LineageLink
    ) {
        const logOperation =
            actionType == UpdateLinkAction.SetGoldenLink
                ? CrudOperation.C
                : CrudOperation.D;
        this.functionalLogService.logFunctionalAction(
            'GOLDEN_LINK',
            logOperation
        );
        await this.entityService.updateEntityLink(
            lineageLink?.entityLinkReferenceId,
            actionType
        );
    }

    private async onGoldenLinkUpdate(
        updateEntityLinkResult: UpdateEntityLinkResult
    ) {
        const matchesCurrent = updateEntityLinkResult.UpdatedEntityIds.some(
            (updatedEntityId) =>
                updatedEntityId === this.graphParams?.originIdr.ReferenceId
        );
        this.log('onGoldenLinkUpdate', matchesCurrent);
        if (matchesCurrent) {
            await this.updateData(true);
        }
    }

    //#endregion

    //#region coarse grain actions (compute + draw)

    private async updateData(
        forceReload: boolean,
        forceLoadAllItems?: boolean
    ) {
        try {
            this.isUdatingData = true;
            if (forceLoadAllItems != undefined) {
                this.forceLoadAllItems = forceLoadAllItems;
            }
            this.log('updateData', forceReload, this.forceLoadAllItems);
            await this.loadData(forceReload, this.forceLoadAllItems);
            this.autoExpandSearchedItemAncestors = true;
            this.pathTracker?.clearAllTracks();
            // compute + draw
            this.build();
        } catch (e) {
            CoreUtil.warn(e);
        } finally {
            this.isUdatingData = false;
        }

        const zoomReset = this.debug?.noZoomReset
            ? ZoomType.none
            : LineageConstants.behaviour.zoom.onLoadData;
        const he = LineageConstants.behaviour.hierarchicalEllipsis,
            applyHEllipsis = !he.globalDisabled && he.applyOnStart;
        // compute + draw
        this.resetLayout(zoomReset, applyHEllipsis, false);

        this.initActionButtons();
    }

    private async loadData(forceReload: boolean, forceLoadAllItems = false) {
        this.log(
            'loadData',
            forceReload,
            forceLoadAllItems,
            !!this.graphParams
        );
        if (!this.graphParams) {
            return false;
        }
        this.isLoadingSourceData = true;
        const result = await this.graphParams.getData(
            forceReload,
            forceLoadAllItems,
            !this.areSiblingDpisVisible
        );
        this.isLoadingSourceData = false;
        this.serverSourceData = result;
        this.hasGoldenLinks = !!result.DataLinks?.some((d) => d.IsGoldenLink);
    }

    private build() {
        this.log('build', this.serverSourceData);
        // draw
        this.d3HGroups().remove();
        this.d3Links().remove();

        this.isSplitRootsActive ??=
            LineageConstants.behaviour.splitRoots.activatedAtInit &&
            !this.serverSourceData?.generations?.length;

        // compute
        const options: ILineageDataBuildOptions = {
            splitRoots: this.isSplitRootsActive,
            addVirtualLinks: true,
            debug: this.debug?.data,
        };
        const viewType = this.lineageGraphService.activeViewType;
        this.lineageData = LineageData.build(
            this.serverSourceData,
            viewType,
            this.itemSpecs,
            options
        );
        this.layout.compute(
            this.lineageData,
            this.d3ZoomSurface.viewport.size.width
        );

        // draw
        this.createElements();
    }

    private resetLayout(
        zoomType = ZoomType.bestFit,
        applyHEllipsis = false,
        smoothZoom = true
    ) {
        // compute
        this.computeGlobalExpandCollapse(false);
        this.isAnyHEllipsis = this.computeGlobalHierarchicalEllipsis(
            applyHEllipsis,
            false
        ).isAnyEllipsis;
        this.computeInitialPositions();
        // draw
        this.renderer.onLayoutReset(applyHEllipsis);
        this.applyZoom(zoomType, smoothZoom);
    }

    private onItemTextDrawn(
        item: LineageItem,
        wholeTextWidth: number,
        node?: SVGTextElement,
        wholeText?: string
    ) {
        item.wholeTextWidth = wholeTextWidth;
        // add a tooltip with the whole text when the displayed text is ellipsed
        if (node.textContent != wholeText) {
            this.tooltipService.setTooltip(node, wholeText);
        }
    }

    private moveItem(item: LineageItem, dx: number, dy: number) {
        const root = item.root;
        // compute
        root.translateSelfAndDescendants(dx, dy);
        // draw
        this.renderer.onMoved(root);
    }

    private applyExpandCollapse(item: LineageItem) {
        this.log('applyExpandCollapse', item);
        // compute
        const { clearTracks, updatedHGroups } =
            this.computeExpandCollapse(item);
        // draw
        this.renderer.onExpandedOrCollapsed(item, updatedHGroups);
        if (!this.noPathTracker) {
            if (clearTracks?.descendants) {
                this.pathTracker.clearDescendantTracks(
                    item,
                    clearTracks.self,
                    !clearTracks.descendantsIsPartOf
                );
            }
            this.pathTracker.updateFor(item);
        }
    }
    private computeExpandCollapse(item: LineageItem) {
        const preventOverlapping =
            LineageConstants.behaviour.preventOverlapping;
        const clearTracks =
            LineageConstants.behaviour.pathTracker.clearTracks.onCollapseExpand;
        const updatedHGroups = this.computeToggleExpandCollapse(
            item,
            preventOverlapping
        );
        return { clearTracks, updatedHGroups };
    }

    private async applyGlobalExpandCollapse(uiExpandLevel: number) {
        this.log('applyGlobalExpandCollapse', uiExpandLevel);

        const { globalUiExpandLevel, autoExpandSearchedItemAncestors } = this;
        this.globalUiExpandLevel = uiExpandLevel;
        this.autoExpandSearchedItemAncestors = false;
        const logAction =
            uiExpandLevel == 0
                ? 'SIMPLE_VIEW'
                : uiExpandLevel == 1
                ? 'INTERMEDIARY_VIEW'
                : uiExpandLevel >= 2
                ? 'DETAILED_VIEW'
                : undefined;
        this.functionalLogService.parseAndLog(
            this.getActionFunctionalLog(logAction)
        );

        const lcb = LineageConstants.behaviour;

        const lazyItemsCount = this.lineageData?.lazyItemsCount;
        if (
            lazyItemsCount > 0 &&
            uiExpandLevel == this.maxGlobalUiExpandLevel
        ) {
            if (lazyItemsCount > lcb.lazyItems.warningThreshold) {
                const confirmed =
                    await this.lineageGraphService.confirmLoadWithLazyItems(
                        lazyItemsCount
                    );
                this.log('confirmLoadWithLazyItems', confirmed, lazyItemsCount);
                if (!confirmed) {
                    this.globalUiExpandLevel = globalUiExpandLevel;
                    this.autoExpandSearchedItemAncestors =
                        autoExpandSearchedItemAncestors;
                    return;
                }
            }
            await this.updateData(true, true);
            return;
        }

        const clearTracks =
            lcb.pathTracker.clearTracks.onCollapseExpand.descendants;

        if (lcb.resetLayout.onGlobalExpandLevelChange) {
            // compute + draw
            this.resetLayout(lcb.zoom.onGlobalExpandLevelChange);
        } else {
            // compute
            const dirty = this.computeGlobalExpandCollapse(
                lcb.preventOverlapping
            );
            dirty.updatedHGroups.forEach((r) =>
                r.updatePositionSelfAndDescendants()
            );
            // draw
            this.renderer.onGlobalExpandedOrCollapsed(dirty);
        }
        if (!this.noPathTracker) {
            if (clearTracks) {
                this.pathTracker.clearAllTracks();
            } else {
                this.pathTracker.showPinnedTracks();
            }
        }
        this.applyZoom(lcb.zoom.onGlobalExpandLevelChange, true);
    }

    private applyToggleHEllipsis(hEllipsisRoot: LineageItem) {
        this.log('applyToggleHEllipsis', hEllipsisRoot);

        // compute
        const preventOverlapping =
            LineageConstants.behaviour.preventOverlapping;
        const { updatedHGroups } = this.computeToggleHEllipsis(
            hEllipsisRoot,
            preventOverlapping
        );

        // draw
        this.renderer.onHEllipsisToggled(hEllipsisRoot.root, updatedHGroups);
        this.setIsAnyHEllipsis();
    }

    private applyGlobalToggleHEllipsis() {
        this.log('applyGlobalToggleHEllipsis');
        // compute
        const wasEllipsed = !!this.isAnyHEllipsis;
        const preventOverlapping =
            LineageConstants.behaviour.preventOverlapping;
        const { isAnyEllipsis, updatedHGroups } =
            this.computeGlobalHierarchicalEllipsis(
                !wasEllipsed,
                preventOverlapping
            );
        if (isAnyEllipsis != wasEllipsed) {
            this.setIsAnyHEllipsis();
            // draw
            this.renderer.onGlobalHierarchicalEllipsisToggled(updatedHGroups);
        }
    }

    private applyHorizontalFlip() {
        this.log('applyHorizontalFlip');
        // compute
        const bb = this.computeBoundingBox(),
            cx = bb.x + bb.width / 2;
        this.lineageData?.withAllRoots((r) => {
            const dx = 2 * (cx - r.x) - r.width;
            r.translateSelfAndDescendants(dx, 0);
        });
        // draw
        this.renderer.onHorizontallyFlipped();
    }

    private async loadChildrenAndLinked(parentItem: LineageItem) {
        const serverData = await this.graphParams.loadChildrenAndLinked(
            parentItem
        );
        const viewType = this.lineageGraphService.activeViewType;
        this.log(
            'loadChildrenAndLinked',
            parentItem,
            serverData,
            ViewType[viewType]
        );
        // compute
        const { newItems, newRoots } = this.lineageData.mergeLazyLoad(
            parentItem,
            serverData,
            viewType
        );
        this.computeNewRootsInitialPositions(newRoots);
        new Set(newItems.map((it) => it.parent)).forEach((p) => {
            if (!p) {
                return;
            }
            p.updateWidthHeightSelfAndDescendants();
            p.updatePositionSelfAndDescendants();
        });
        // draw
        this.d3Links().remove();
        this.createElements();
        this.computeExpandCollapse(parentItem);
        const d3parentItem = this.d3Items('', parentItem.selector);
        if (
            !parentItem.hasChildren &&
            LineageConstants.behaviour.lazyItems
                .removeExpanderCaretWhenNoChildren
        ) {
            this.renderer.removeExpanderCaret(d3parentItem);
        } else {
            this.renderer.setLoadedExpanderCaret(d3parentItem);
        }
        this.renderer.onLayoutReset(true);
    }

    //#endregion

    //#region fine grain actions
    /** create new dom elements and bind their events */
    private createElements() {
        const { d3heads } = this.renderer.createElements(
            this.lineageData.getItemsByRoot(),
            this.lineageData.links
        );

        this.d3ZoomSurface.graph.addNodesFromElements(
            this.d3HGroups().nodes(),
            {
                data: this.d3HGroups().data(),
                layer: SurfaceLayer.none,
            }
        );

        // item head click
        D3Helper.bindClick(d3heads, (it, el, event) =>
            this.onClickItemHead(event, it, el)
        );

        // item head hover
        D3Helper.bindHover(d3heads, (isOn, it) =>
            this.onHoverItemHead(isOn, it)
        );
    }

    private setIsAnyHEllipsis() {
        this.log('setIsAnyHEllipsis', this.isAnyHEllipsis);
        this.isAnyHEllipsis = this.lineageData
            ?.getAllRoots()
            .some((r) => r.containsAnyHEllipsis());
        this.log('isAnyHEllipsis', this.isAnyHEllipsis);
    }

    private applyZoom(
        zoomType: ZoomType,
        smooth: boolean,
        debounceDelayMs?: number | true
    ) {
        clearTimeout(this.debounceApplyZoom);
        if (!this.d3ZoomSurface) {
            return;
        }

        const action = () => {
            switch (zoomType) {
                case ZoomType.bestFit:
                    this.applyZoomBestFit(smooth);
                    break;
            }
        };

        if (debounceDelayMs) {
            const delayMs = debounceDelayMs === true ? 111 : debounceDelayMs;
            this.debounceApplyZoom = ZoneUtils.zoneTimeout(
                action,
                delayMs,
                this.ngZone,
                true
            );
        } else {
            action();
        }
    }

    private applyZoomBestFit(smooth = false) {
        if (this.isDisposing || this.isUdatingData) {
            return;
        }
        this.log('applyZoomBestFit');
        const bb = this.computeBoundingBox();
        const params = LineageConstants.behaviour.zoom.bestFit;
        this.d3ZoomSurface.zoom.zoomToRect(bb, {
            margin: params.margin,
            durationMs: smooth ? 333 : 0,
            zoomMax: params.zoomMax,
        });
    }

    private computeInitialPositions() {
        this.layout.computeInitialPositions(
            this.d3ZoomSurface.viewport.size.height
        );
    }
    private computeNewRootsInitialPositions(newRoots: LineageItem[]) {
        return this.layout.computeNewRootsInitialPositions(newRoots);
    }

    private computeGlobalExpandCollapse(preventOverlapping: boolean) {
        // compute level to expand or collapse to
        const newLevel = Math.floor(
            this.lineageData.maxLevel *
                (this.globalUiExpandLevel / this.maxGlobalUiExpandLevel)
        );
        const searchedItemLevel = this.autoExpandSearchedItemAncestors
            ? this.lineageData.searchedItemLevel
            : -2;
        this.log(
            'computeGlobalExpandCollapse',
            preventOverlapping,
            this.globalUiExpandLevel,
            newLevel,
            this.autoExpandSearchedItemAncestors,
            searchedItemLevel
        );

        // collect items to toggle expansion
        const toggledItems = new Set<LineageItem>(),
            toggledRoots = new Array<LineageItem>();
        this.lineageData.withAllRoots((root) => {
            const til = toggledItems.size;
            root.collectToggleExpandedSelfAndDescendants(
                newLevel,
                searchedItemLevel,
                toggledItems
            );
            if (toggledItems.size != til) {
                toggledRoots.push(root);
            }
        });

        // toggle and update items position and size, and collect updated hgroups
        const updatedHGroups = new Array<LineageItem>();
        toggledItems.forEach((it) =>
            this.computeToggleExpandCollapse(
                it,
                preventOverlapping,
                updatedHGroups
            )
        );

        // collapse descendants of collapsed items and collect them
        toggledItems.forEach((it) => {
            if (!it.isExpanded) {
                it.collapseDescendants(toggledItems);
            }
        });

        return { toggledItems, toggledRoots, updatedHGroups };
    }

    private computeToggleExpandCollapse(
        item: LineageItem,
        preventOverlapping: boolean,
        updatedHGroups = new Array<LineageItem>()
    ) {
        this.debug?.computeToggleExpandCollapse &&
            this.log('computeToggleExpandCollapse', item, preventOverlapping);

        item.isExpanded = !item.isExpanded;

        const alreadyOverlapped =
            preventOverlapping && this.getIntersectedHGroups(item);

        const dy = item.updateHGroupAfterExpandedOrCollapsed(true);

        if (!updatedHGroups.includes(item.root)) {
            updatedHGroups.push(item.root);
        }

        if (preventOverlapping) {
            const roots = this.translateOverlappedHGroups(
                item,
                dy,
                alreadyOverlapped
            );
            updatedHGroups.push(
                ...roots.filter((r) => !updatedHGroups.includes(r))
            );
        }

        return updatedHGroups;
    }

    private computeGlobalHierarchicalEllipsis(
        toBeEllipsed: boolean,
        preventOverlapping: boolean
    ) {
        this.log('computeGlobalHierarchicalEllipsis', toBeEllipsed);
        let isAnyEllipsis = false;
        const updatedHGroups = new Array<LineageItem>();
        this.lineageData.withAllRoots((r) => {
            const alreadyOverlapped =
                preventOverlapping && this.getIntersectedHGroups(r);

            const wasAnyHEllipsisInBranch = r.isAnyHEllipsisInBranch();

            const removeAll = toBeEllipsed ? undefined : true;
            const isAnyHEllipsisInBranch = r.updateHEllipsisSelfAndDescendants(
                toBeEllipsed,
                removeAll
            );
            if (isAnyHEllipsisInBranch) {
                updatedHGroups.push(r);
                isAnyEllipsis = true;
            }

            if (isAnyHEllipsisInBranch != wasAnyHEllipsisInBranch) {
                const dy = r.updateWidthHeightSelfAndDescendants();
                r.updatePositionSelfAndDescendants();

                if (preventOverlapping) {
                    const roots = this.translateOverlappedHGroups(
                        r,
                        dy,
                        alreadyOverlapped
                    );
                    updatedHGroups.push(
                        ...roots.filter((r) => !updatedHGroups.includes(r))
                    );
                }
            }
        });

        return { isAnyEllipsis, updatedHGroups };
    }

    private computeToggleHEllipsis(
        hEllipsisRoot: LineageItem,
        preventOverlapping: boolean,
        updatedHGroups = new Array<LineageItem>()
    ) {
        this.log('computeToggleHEllipsis', hEllipsisRoot);

        const alreadyOverlapped =
            preventOverlapping && this.getIntersectedHGroups(hEllipsisRoot);

        const wasEllipsed = hEllipsisRoot.isHEllipsed;
        const anyEllipsed = hEllipsisRoot.updateHEllipsisSelfAndDescendants(
            !wasEllipsed
        );

        const root = hEllipsisRoot.root;

        const dy = hEllipsisRoot.updateHGroupAfterHEllipsisToggled(true);

        if (preventOverlapping) {
            const roots = this.translateOverlappedHGroups(
                hEllipsisRoot,
                dy,
                alreadyOverlapped
            );
            updatedHGroups.push(
                ...roots.filter((r) => !updatedHGroups.includes(r))
            );
        }

        if (!updatedHGroups.includes(root)) {
            updatedHGroups.push(root);
        }

        return { anyEllipsed, updatedHGroups };
    }

    private translateOverlappedHGroups(
        item: LineageItem,
        dy: number,
        alreadyIntersectedHGroups?: LineageItem[]
    ): LineageItem[] {
        this.log('translateOverlappedHGroups', item);
        const root = item.root;
        const overlapingRoots = this.lineageData.getAllRoots(
            (r) =>
                r != root &&
                !alreadyIntersectedHGroups?.includes(r) &&
                root.isOverLapping(r)
        );
        overlapingRoots.forEach((it) => it.translateSelfAndDescendants(0, dy));
        return overlapingRoots;
    }

    //#endregion

    //#region getters

    private getBurgerMenuOffset(icon: HTMLElement) {
        const offset = DomUtil.getGlobalOffset(icon);
        // compute position of the menu: under the icon, taking the zoom into account
        offset.top +=
            this.d3ZoomSurface.zoom.scale * (this.itemSpecs.minHeight(0) - 2);
        return offset;
    }

    private getBurgerMenuContext(item: LineageItem) {
        this.debug?.burgerMenu && this.log('getBurgerMenuContext', item);
        const canBePinned =
            !this.noPathTracker && this.pathTracker.canBePinned(item);
        const entityIdentifier = this.getEntityIdentifier(item);
        const canBeUnlinked = this.canBeUnlinked(item, entityIdentifier);
        const goldenLinkCandidate = this.getLink(
            item,
            (l) => l.linkType == ObjectLinkType.IsImplementedBy
        );
        const canBeGolden = !!goldenLinkCandidate;
        const isMultiLink =
            canBeGolden && !!this.getSecondLink(goldenLinkCandidate);
        const result: ILineageBurgerMenuContext = {
            canBePinned,
            canBeUnlinked,
            entityIdentifier,
            canBeGolden,
            dataLink: goldenLinkCandidate,
            isMultiLink,
        };

        if (!LineageGraphService.isEntity(item)) {
            return result;
        }

        result.entityData = this.entitiesByItem.get(item);

        return result;
    }

    private canBeUnlinked(item: LineageItem, idr?: IEntityIdentifier) {
        if (!this.graphParams?.canUnlink) {
            return false;
        }

        const entityIdentifier = idr ?? this.getEntityIdentifier(item);
        if (
            EntityIdentifier.areSame(
                entityIdentifier,
                this.graphParams.originIdr
            )
        ) {
            return false;
        }

        return !!this.getLink(item);
    }

    /** returns the first non-virtual link from the graph source item to the given target */
    private getLink(
        target: LineageItem,
        linkMatch: (l: LineageLink) => boolean = () => true
    ): LineageLink {
        // could be cached
        const sourceItemId = getLocalId(this.graphParams.originIdr.ReferenceId);
        const sourceItem = this.lineageData?.items.find(
            (it) => it.dataId == sourceItemId
        );

        return this.lineageData.links.find(
            (l) =>
                !l.virtual &&
                l.tgt == target &&
                l.src == sourceItem &&
                linkMatch(l)
        );
    }

    // In the context of Implementation Lineage, we can have two links on the same catalog object (HasRecordingSystem + IsImplementedBy)
    // This method will return the second one if it exists
    private getSecondLink(firstLink: LineageLink): DataLineageDataLink {
        return this.serverSourceData.DataLinks.find(
            (dl) =>
                dl.EntityLinkReferenceId != firstLink.entityLinkReferenceId &&
                dl.SourceId == firstLink.src.dataId &&
                dl.TargetId == firstLink.tgt.dataId
        );
    }

    private cacheEntity(item: LineageItem, entity: IMiniEntityContent) {
        this.log('cacheEntity', entity);
        this.entitiesByItem.set(item, entity);
    }

    private getEntityIdentifier(item: LineageItem) {
        return EntityIdentifier.fromLocalId(
            item.dataId,
            item.entityType,
            this.graphParams.originIdr
        );
    }

    private getIntersectedHGroups(item: LineageItem) {
        const root = item.root;
        return (
            this.lineageData?.getAllRoots(
                (r) => r != root && root.isIntersecting(r)
            ) ?? []
        );
    }

    private computeBoundingBox() {
        const roots = this.lineageData?.getAllRoots();
        const bb = D3Helper.getBoundingBox(roots);

        // in debug mode, show the bounding box
        if (this.debug?.boundingBox) {
            D3Helper.createRectOrUpdate(
                this.graphicalSurface,
                'debug-bounding-box',
                bb
            );
        }

        return bb;
    }

    private getHEllipsisText(item: LineageItem) {
        return (
            (this.debug?.ellipsisText ? `(${item.id}) ` : '') +
            this.translate.instant('UI.ImpactAnalysis.lineage.ellipsisLevel', {
                n: item.getHEllipsisDepth(),
            })
        );
    }

    private d3HGroups() {
        return this.d3ItemsContainer.selectAll<SVGGElement, LineageItem>(
            'g.hgroup'
        );
    }
    private d3Items(filter = '', selector: string = 'g[data-id]') {
        return this.d3ItemsContainer.selectAll<SVGGElement, LineageItem>(
            selector + filter
        );
    }
    private d3Links(filter = '') {
        return this.d3LinksContainer.selectAll<SVGGElement, LineageLink>(
            'g.link' + filter
        );
    }

    //#endregion

    //#region helpers

    private subscribeEntityEvent(
        entityEventServiceMethod: (
            serverType: ServerType,
            handler: IDataIdentifierEventHandler<IDataIdentifier>
        ) => void
    ) {
        const subscriptionName = entityEventServiceMethod.name;
        const ready = !!this.graphParams?.originIdr?.ServerType;
        this.log('subscribeEntityEvent', ready, subscriptionName);
        if (!ready) {
            return;
        }

        super.unsubscribe(this.dynamicSubscriptions.get(subscriptionName));

        const subscription = entityEventServiceMethod.call(
            this.entityEventService,
            this.graphParams.originIdr.ServerType,
            (data: IDataIdentifier) => this.onEntityEvent(data)
        );

        this.dynamicSubscriptions.set(
            subscriptionName,
            super.registerSubscription(subscription)
        );
    }

    //#endregion

    private takeScreenShot() {
        const selectedItem = this.lineageData?.items.find(
            (elem) => elem.isSearchedItem == true
        )?.displayName;
        const fileName = `Lineage_${selectedItem}`;
        DomUtil.screenshot(this.container, fileName).then();
    }
}

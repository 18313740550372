import { Component, Input } from '@angular/core';
import { IActionOption } from '@datagalaxy/core-ui';
import {
    SynonymDto,
    SynonymType,
} from '@datagalaxy/webclient/entity/data-access';

/**
 * ## Role
 * Display local synonym or linked entity card with action buttons to edit or remove synonym
 */
@Component({
    selector: 'app-synonym-cell',
    templateUrl: './synonym-cell.component.html',
    styleUrls: ['./synonym-cell.component.scss'],
})
export class SynonymCellComponent {
    @Input() synonym?: SynonymDto;
    @Input() localSynonym?: string;
    @Input() readOnly?: boolean;
    @Input() actions: IActionOption<SynonymDto>[] = [];

    public get isLocalSynonymType() {
        return this.synonym.HddData?.Data?.DataTypeName == SynonymType[0];
    }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
    ImportContext,
    ImportTarget,
} from '../../../import/shared/ImportContext';
import { EntitySecurityService } from '../services/entity-security.service';
import { ImportModuleCatalog } from '../../../import/shared/ImportModule';
import { SecurityService } from '../../../services/security.service';
import { ImportWizardService } from '../../../import/services/import-wizard.service';
import { ModelType } from '@datagalaxy/dg-object-model';
import {
    CrudOperation,
    FunctionalLogService,
} from '@datagalaxy/webclient/monitoring/data-access';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { ISpaceIdentifier } from '@datagalaxy/webclient/workspace/domain';
import { DgModule } from '@datagalaxy/shared/dg-module/domain';
import { EntitySecurityData } from '@datagalaxy/webclient/security/domain';

/**
 * Entity import dropdown button
 * Component only used in dashboard module
 * Archi RVI : Move in dashboard module ?
 */
@Component({
    //eslint-disable-next-line @angular-eslint/component-selector
    selector: 'dxy-entity-import-button',
    templateUrl: 'dxy-entity-import-button.component.html',
})
export class DxyEntityImportButtonComponent extends DxyBaseComponent {
    @Input() spaceIdr: ISpaceIdentifier;
    @Input() dgModule: DgModule;
    @Input() securityData: EntitySecurityData;
    @Input() importSourceIdContext?: string;
    @Input() importSourceTypeContext?: ModelType;
    @Input() importEntityOnly: boolean;
    @Input() tooltipmsg: string;
    @Output() onImport = new EventEmitter();

    public get hasAdministratorAccess() {
        return this.securityService.isCurrentUserClientAdmin();
    }

    constructor(
        private entitySecurityService: EntitySecurityService,
        private importWizardService: ImportWizardService,
        private securityService: SecurityService,
        private functionalLogService: FunctionalLogService
    ) {
        super();
    }

    public hasImportAccess() {
        return this.entitySecurityService.hasImportAccess(this.securityData);
    }

    public getSourceIdContext() {
        return this.importSourceIdContext || null;
    }

    public getSourceTypeContext() {
        return this.importSourceTypeContext;
    }

    public async openImportFromCSVModal() {
        this.functionalLogService.logFunctionalAction(
            this.getModuleFeatureCode(),
            CrudOperation.R
        );
        const importContext = new ImportContext(
            this.spaceIdr,
            ImportTarget.Entities,
            this.dgModule,
            this.getSourceIdContext()
        );
        importContext.initialSourceType = this.getSourceTypeContext();
        importContext.onModalClosed = (isImportDone: boolean) => {
            if (isImportDone) {
                this.onImport.emit();
            }
        };
        await this.importWizardService.openImportWizardModal(importContext);
    }

    public setImportContextOnUpdate(importContext: ImportContext) {
        const moduleModel = new ImportModuleCatalog(importContext);
        moduleModel.isUpdate = true;
        moduleModel.sourceId = this.getSourceIdContext();
        moduleModel.sourceType = this.getSourceTypeContext();
        moduleModel.isEntityOnly = this.importEntityOnly;
        moduleModel.hasSourceCreationAccess = this.securityData.HasCreateAccess;
        importContext.currentModule = moduleModel;
        return importContext;
    }

    private getModuleFeatureCode(): string {
        switch (this.dgModule) {
            case DgModule.Glossary:
                return 'IMPORT_FROM_GLOSSARY';
            case DgModule.Processing:
                return 'IMPORT_FROM_DATA_PROCESSING';
            case DgModule.Usage:
                return 'IMPORT_FROM_USES';
            case DgModule.Catalog:
                return 'IMPORT_FROM_DICTIONARY';
        }
    }
}

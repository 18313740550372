import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    HostBinding,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { DashboardSection } from '../domain/dashboard-section';
import {
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
} from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs';
import { DashboardStateService } from '../dashboard/state/dashboard-state.service';
import { TitleInputComponent } from '@datagalaxy/ui/forms';

@Component({
    selector: 'dxy-dashboard-section',
    templateUrl: './dashboard-section.component.html',
    styleUrls: ['./dashboard-section.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class DashboardSectionComponent implements OnChanges {
    @Input() section: DashboardSection;
    @Input() enableEdition = false;
    @Input() dragging = false;
    @Input() widgetGalleryOpened = false;

    @Output() openWidgetGallery = new EventEmitter<void>();

    @HostBinding('class.hidden') get hidden() {
        return !this.section?.widgetInstances?.length && !this.enableEdition;
    }

    @ViewChild('titleInput') private titleInput?: TitleInputComponent;

    protected titleForm: FormGroup<TitleForm>;

    protected get title() {
        return this.section?.title ?? '';
    }
    protected get titleVisible() {
        return this.section?.titleVisible && !!this.section.title;
    }
    protected get titleTooltipDisabled() {
        return this.dragging || this.titleVisible;
    }
    protected get editableTitle() {
        return this.enableEdition;
    }

    protected titleMaxLength = 50;

    constructor(
        private fb: FormBuilder,
        dashboardStateService: DashboardStateService
    ) {
        this.createForm();
        this.titleForm.controls.title.valueChanges
            .pipe(debounceTime(444), distinctUntilChanged())
            .subscribe((newTitle) => {
                if (this.title == newTitle) {
                    return;
                }
                dashboardStateService.renameSection(this.section.id, newTitle);
            });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.section) {
            const section = changes.section.currentValue as DashboardSection;
            this.titleForm.patchValue({
                title: section.title,
            });
        }
    }

    public focusTitleInput() {
        this.titleInput?.focusInput();
    }

    private createForm() {
        this.titleForm = this.fb.group<TitleForm>({
            title: new FormControl(this.title, {
                validators: [Validators.maxLength(this.titleMaxLength)],
            }),
        });
    }
}

interface TitleForm {
    title: FormControl<string>;
}

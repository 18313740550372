import { NgModule } from '@angular/core';
import { CoreUiModule } from '../core-ui.module';
import { DxyFieldsModule } from '../fields';
import { DxyModalDialogComponent } from './modal-dialog/modal-dialog.component';
import { DxyDialogService } from './DxyDialogService';
import { DIALOG_SERVICE_TOKEN } from '@datagalaxy/ui/dialog';
import { DxyButtonsModule } from '@datagalaxy/ui/buttons';

@NgModule({
    imports: [CoreUiModule, DxyFieldsModule, DxyButtonsModule],
    providers: [
        {
            provide: DIALOG_SERVICE_TOKEN,
            useClass: DxyDialogService,
        },
    ],
    declarations: [DxyModalDialogComponent],
    exports: [DxyModalDialogComponent],
})
export class DxyDialogModule {}

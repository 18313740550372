import {
    IEntityIdentifier,
    IHasHddData,
    ObjectLinkType,
} from '@datagalaxy/dg-object-model';
import { IHasTechnicalName } from '@datagalaxy/data-access';
import { DgModule } from '@datagalaxy/shared/dg-module/domain';

/** Identifier for a link between 2 entities */
export interface IObjectLinkIdentifier {
    /** id of the link */
    linkReferenceId: string;
    /** type of link (universal ObjectLinkType) */
    linkType: ObjectLinkType;
}

/** Link between 2 entities */
export interface IEntityLinkIdentifier extends IObjectLinkIdentifier {
    source: IEntityIdentifier;
    target: IEntityIdentifier;
}

export interface ILinkedEntityInfo {
    entity: IEntityIdentifier;
    /** existing links in the workspace where *entity* is the target */
    asTarget: IEntityLinkIdentifier[];
    /** existing links in the workspace where *entity* is the source */
    asSource: IEntityLinkIdentifier[];
}

/** Type of flow: upstream, downstream, all (default) */
export enum FlowType {
    all = 0,
    downstream,
    upstream,
}
/** A *localEntityIdr*, a linked entity (*linkedObjectIdr*) and the *linkIdr* */
export interface ILinkedObject
    extends IHasHddData,
        IHasTechnicalName,
        IObjectLinkIdentifier {
    /** entity for which the request was made */
    localEntityIdr: IEntityIdentifier;

    /** linked entity identifier */
    linkedObjectIdr: IEntityIdentifier;
    /** module of the linked entity - used for sorting */
    dgModule: DgModule;

    isGoldenLink: boolean;

    /** direction of the link, seen from the local entity */
    flow: FlowType;

    linkIdr: IEntityLinkIdentifier;
}

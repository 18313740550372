import { CoreUtil } from '@datagalaxy/core-util';
import {
    CoreEventsService,
    DXY_TRANSLATE_CONFIG,
    IFieldSelectAdapter,
    ITranslationConfig,
    UiUtil,
} from '@datagalaxy/core-ui';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Inject,
    Input,
    OnInit,
} from '@angular/core';
import {
    animate,
    AnimationEvent,
    state,
    style,
    transition,
    trigger,
} from '@angular/animations';
import {
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
} from '@angular/forms';
import { NavigationService } from '../../services/navigation.service';
import {
    IUiImageInputParams,
    UiImageInputMode,
} from '@datagalaxy/core-ui/image-input';
import { CropMode } from '@datagalaxy/core-doka-util';
import { UserService } from '../../services/user.service';
import { UserUiService } from '../../user/user-ui.service';
import { DEFAULT_LANGUAGE } from '@ngx-translate/core';
import { ViewType } from '../../shared/util/app-types/ViewType';
import { AppDataService } from '../../services/app-data.service';
import { FirstAccessService } from './first-access.service';
import { IFirstAccessForm } from './first-access.types';
import { ToasterService } from '../../services/toaster.service';
import { ApiServiceError } from '@datagalaxy/data-access';
import {
    AppConfigService,
    crowdinInContextLang,
} from '@datagalaxy/webclient/config';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { UserPublicData } from '@datagalaxy/webclient/user/domain';

@Component({
    selector: 'app-client-first-access',
    templateUrl: 'client-first-access.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: [
        'client-first-access.component.scss',
        'client-first-access.component.responsive.scss',
    ],
    animations: [
        trigger('hideShow', [
            state('show', style({ width: '50%', opacity: 1 })),
            state('hide', style({ width: '0', opacity: 0 })),
            transition('show => hide', [animate('0.3s ease-in-out')]),
            transition('hide => show', [animate('0.3s ease-in-out')]),
        ]),
    ],
})
export class ClientFirstAccessComponent
    extends DxyBaseComponent
    implements OnInit
{
    @Input() user: UserPublicData;
    public firstAccessForm: FormGroup<IFirstAccessForm>;
    protected avatarInputParams: IUiImageInputParams;
    protected hideControls = true;

    protected selectedLanguage: IFieldSelectAdapter<string> = {
        isModel: true,
        getTextKey: (lc) => {
            switch (lc) {
                case 'fr':
                    return 'UI.User.UserSettings.Language.french';
                case 'en':
                    return 'UI.User.UserSettings.Language.english';
                case 'nl':
                    return 'UI.User.UserSettings.Language.dutch';
                case 'es':
                    return 'UI.User.UserSettings.Language.spanish';
                case 'ga':
                    return 'UI.User.UserSettings.Language.crowdin';
            }
        },
        // Propagate application wide change (and eventually trigger translation files loading)
        onSelectionChange: (lc) => {
            this.coreEventsService.uiLanguage = lc;
        },
    };

    protected selectedProfile: IFieldSelectAdapter<ViewType> = {
        isModel: true,
        options: [ViewType.Functional, ViewType.Technical],
        getTextKey: (profileCode) => {
            switch (+profileCode) {
                case ViewType.Functional:
                    return 'UI.ViewType.Functional';
                case ViewType.Technical:
                    return 'UI.ViewType.Technical';
            }
        },
    };

    private get initials() {
        return this.user?.Initials;
    }

    constructor(
        @Inject(DXY_TRANSLATE_CONFIG)
        private translationConfig: ITranslationConfig,
        @Inject(DEFAULT_LANGUAGE)
        private defaultAppLanguage: string,
        private navigationService: NavigationService,
        private formBuilder: FormBuilder,
        private userService: UserService,
        private userUiService: UserUiService,
        private cd: ChangeDetectorRef,
        private appDataService: AppDataService,
        private firstAccessService: FirstAccessService,
        private appConfigService: AppConfigService,
        private coreEventsService: CoreEventsService,
        private toasterService: ToasterService
    ) {
        super();

        // Available language sources
        this.selectedLanguage.options = this.translationConfig.locales?.filter(
            (lang) => !CoreUtil.isProduction || lang !== crowdinInContextLang
        );

        // Use browser detected language by default
        let initialLanguageCode = this.appConfigService.startupLanguage;

        // If for some reason it is falsy, fallback to client language (from CMS)
        initialLanguageCode =
            initialLanguageCode || this.appDataService.currentLanguageCode;

        // The language has to be supported
        if (
            !this.selectedLanguage.options.some(
                (l) => l === initialLanguageCode
            )
        ) {
            initialLanguageCode = this.defaultAppLanguage;
        }

        this.firstAccessForm = this.formBuilder.group<IFirstAccessForm>({
            language: new FormControl<string>(
                (this.selectedLanguage.current = initialLanguageCode),
                Validators.required
            ),
            profile: new FormControl<ViewType>(
                (this.selectedProfile.current = ViewType.Functional),
                Validators.required
            ),
            acceptedTermsOfUse: new FormControl<boolean>(
                false,
                Validators.requiredTrue
            ),
            acceptedPrivacyPolicy: new FormControl<boolean>(
                false,
                Validators.requiredTrue
            ),
        });

        this.registerSubscriptions(
            this.coreEventsService.uiLanguageChanged$.subscribe(() => {
                // Update profile adapter ref for translation sync
                this.selectedProfile = { ...this.selectedProfile };
                this.selectedLanguage = { ...this.selectedLanguage };
                this.cd.detectChanges();
            })
        );

        // Make sure the right language is set application wide
        this.coreEventsService.uiLanguage =
            initialLanguageCode as unknown as string;
    }

    public ngOnInit() {
        this.avatarInputParams = {
            mode: UiImageInputMode.modalEdit,
            editSize: 90,
            finalSize: 150,
            crop: CropMode.round,
            upscale: true,
            placeHolderText: () => this.initials,
            placeHolderClass: () =>
                'initials dg5-palette bg ' +
                UiUtil.getColorClassFromString(this.initials),
            getImageUrl: () =>
                this.userService.getUserImageUrl(this.user.UserId, true),
            storeImage: (main, mini) =>
                this.userUiService.setUserProfileImage(
                    this.user.UserId,
                    main,
                    mini
                ),
            deleteImage: () =>
                this.userUiService.deleteUserProfileImage(this.user.UserId),
        };

        // Delay form controls slide-in animation
        this.toggleForm(false);
    }

    public async submitForm() {
        try {
            // At this point the form should be valid
            const data = this.firstAccessForm.getRawValue();
            await this.firstAccessService.publishCompletion(this.user, data);
            // Trigger page leave animation
            this.toggleForm(true);
        } catch (error) {
            if (error instanceof ApiServiceError) {
                this.toasterService.showApiErrorGeneric(error);
                return;
            }

            throw error;
        }
    }

    public onSubmissionTransitionEnd(evt: AnimationEvent) {
        if (evt.fromState === 'show' && evt.toState === 'hide') {
            this.navigationService.gotoMemoStateOrClientMain();
        }
    }

    private toggleForm(show: boolean) {
        setTimeout(() => {
            this.cd.markForCheck();
            this.hideControls = show;
        }, 200);
    }
}

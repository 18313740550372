import { CoreEventsService, IMultiSelectData } from '@datagalaxy/core-ui';
import { IGraphicalToolbarOption } from '@datagalaxy/core-ui/graphical';
import { DomUtil } from '@datagalaxy/core-util';
import { Component, ElementRef, Input, NgZone, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DatamapGraphNode } from '../data/DatamapGraphNode';
import { ExplorationGraphLink } from '../data/ExplorationGraphLink';
import {
    DxyBaseForceGraphComponent,
    IForceGraphConfig,
    SD3,
} from '../BaseForceGraphComponent';
import { DatamapGraphData } from '../data/DatamapGraphData';
import { EntityType } from '@datagalaxy/dg-object-model';
import { AppSpaceService } from '../../services/AppSpace.service';
import { ImpactAnalysisService } from '../impact-analysis.service';
import { AttributeDataService } from '../../shared/attribute/attribute-data.service';
import { FilterBarService } from '../../shared/filter/services/filterBar.service';
import {
    FilteredViewService,
    ICurrentFilteredViewChangeEvent,
} from '../../shared/filter/services/filteredView.service';
import { AppEventsService } from '../../services/AppEvents.service';
import { GlyphUtil } from '../../shared/util/GlyphUtil';
import { DataUtil } from '../../shared/util/DataUtil';
import { EntityTypeUtils } from '@datagalaxy/webclient/entity/utils';
import { DgZone } from '@datagalaxy/webclient/domain';

declare type Item = DatamapGraphNode;
declare type Link = ExplorationGraphLink;
declare type SD3Items = SD3<Item>;
declare type SD3Links = SD3<Link>;

@Component({
    selector: 'app-datamap-graph',
    templateUrl: 'datamap-graph.component.html',
    styleUrls: ['datamap-graph.component.scss'],
})
export class DatamapGraphComponent
    extends DxyBaseForceGraphComponent<Item, Link>
    implements OnInit
{
    //#region debug
    protected verbose = false;
    //#endregion

    //#region static

    // disabled for now because navigation state change caused by filtering makes fullScreen to exit
    private static readonly showFilterBarOnFullScreen = false;

    private static readonly expandAllDescendantsOnClick = false;
    private static readonly bigGraphSize = 200;
    private static readonly childrenSpacingRadius = 150;
    private static readonly initialSpacingRadius = 85;
    private static readonly config: IForceGraphConfig = {
        forces: {
            gravity: 0.02,
            link: { distance: 150, strength: 1.0 },
            charge: { distance: 150, strength: -1500 },
            friction: 0.8,
        },
        noAnimationOnStart: true,
        draggedItem: {
            setFixed: true,
            setSelected: true,
        },
    };

    private static readonly itemTemplateCustomContent =
        '<circle class="dg_dataVizObjectGraph-disque-font" cx="100" cy="47" r="30" style="fill: #fff;"/>' +
        '<circle class="dg_dataVizObjectGraph-disque-select" cx="100" cy="47" r="30" style="fill: #555;stroke: #000;stroke-miterlimit: 10;stroke-width: 2px"/>' +
        '<circle class="dg_dataVizObjectGraph-cercle" cx="100" cy="47" r="4" style="fill: #555;stroke-miterlimit: 10;stroke-width: 2px"/>' +
        '<text class="dg_dataVizObjectGraph-number" x="100" y="55" font-family="Montserrat" font-size="20px" text-anchor="middle" fill="white"/>';
    //#endregion

    @Input() graphData: DatamapGraphData;

    public actionButtons: IGraphicalToolbarOption[];
    public multiSelectData: IMultiSelectData<EntityType>;

    private isBigGraphAcknowledged = false;
    private wasFilterBarVisible: boolean;
    private readonly dgZone = DgZone.Module;

    private isAllExpanded = false;
    private readonly optionDensity: IGraphicalToolbarOption = {
        glyphClass: () =>
            this.isAllExpanded ? 'glyph-density-high' : 'glyph-density-low',
        tooltipTranslateKey: 'UI.ImpactAnalysis.lineage.ttToolExpand',
        options: [
            {
                glyphClass: 'glyph-density-low',
                tooltipTranslateKey: 'UI.OmniGrid.tooltipCollapseAll',
                callback: () => this.applyCollapseAll(),
            },
            {
                glyphClass: 'glyph-density-high',
                tooltipTranslateKey: 'UI.OmniGrid.tooltipExpandAll',
                callback: () => this.applyExpandAll(),
            },
        ],
    };
    private readonly optionSubTypeFilter: IGraphicalToolbarOption = {
        glyphClass: 'glyph-filter-filled',
        getMultiSelectData: () => this.multiSelectData,
    };

    constructor(
        private appSpaceService: AppSpaceService,
        private translate: TranslateService,
        private impactAnalysisService: ImpactAnalysisService,
        private attributeDataService: AttributeDataService,
        private filterBarService: FilterBarService,
        private filteredViewService: FilteredViewService,
        ngZone: NgZone,
        elementRef: ElementRef,
        coreEventsService: CoreEventsService,
        appEventsService: AppEventsService
    ) {
        super(
            ngZone,
            elementRef,
            coreEventsService,
            appEventsService,
            DatamapGraphComponent.config,
            DatamapGraphComponent.itemTemplateCustomContent
        );
    }

    ngOnInit() {
        this.log('ngOnInit', this.graphData);
        this.updateSubTypeFilter();
        this.actionButtons = [this.optionDensity, this.optionSubTypeFilter];
        this.wasFilterBarVisible = this.filterBarService.isFilterBarVisible(
            this.dgZone
        );
        super.subscribe(this.filteredViewService.currentViewChanged$, (data) =>
            this.onFilteredViewChanged(data)
        );
    }

    protected async takeScreenShot() {
        let spaceName = '';
        if (!this.appSpaceService.isSingleWorkspace()) {
            const space = await this.appSpaceService.getSpace(
                this.graphData.spaceIdr
            );
            spaceName = space.DisplayName;
        }
        const moduleName = this.translate.instant(
            DataUtil.getModuleTranslateKey(this.graphData.dgModule)
        );
        const fileName = ['Datamap', spaceName, moduleName]
            .filter((o) => o)
            .join('_');
        const element = DomUtil.getElement(this.elementRef, '.d3div');
        await DomUtil.screenshot(element, fileName);
    }

    protected isDataSourceReady() {
        return !!this.graphData;
    }

    protected getItemsData() {
        const items = this.graphData?.getDisplayableNodes() || [];
        if (this.isFirstSimulation) {
            this.positionItemsAsPhyllotaxis(
                items,
                this.center,
                DatamapGraphComponent.initialSpacingRadius
            );
        }
        return items;
    }

    protected getLinksData() {
        return this.graphData?.getDisplayableLinks();
    }

    protected confirmStart(): Promise<boolean> {
        return this.withBigGraphAcknowledged(
            this.graphData.topLevelEntitiesCount,
            () => true
        );
    }

    protected onSimulationEnd() {
        this.log('onSimulationEnd', this.isFirstSimulation);
        if (this.isFirstSimulation) {
            this.zoomBestFitDelayed();
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    protected onItemClick(item: Item, event: MouseEvent) {
        this.log('onItemClick', item.clicked, item);
        if (item.clicked) {
            this.showHideChildren(item, false, true);
        } else {
            const recursive = DatamapGraphComponent.expandAllDescendantsOnClick;
            const nbItemsToAdd = recursive
                ? item.contextualAllLevelChildrenCount
                : item.childrenClusterNodesId.length;
            const nbVisibleItems = this.d3Items()
                .filter((it) => it.isVisible)
                .size();
            const nbItems = nbVisibleItems + nbItemsToAdd;
            this.withBigGraphAcknowledged(nbItems, () =>
                this.showHideChildren(item, true, recursive)
            );
        }
    }

    protected onFullScreenChanging(toFullScreen: boolean) {
        if (!DatamapGraphComponent.showFilterBarOnFullScreen) {
            return;
        }
        const filterBarVisible = toFullScreen || this.wasFilterBarVisible;
        this.filterBarService.showHideFilterBar(this.dgZone, filterBarVisible);
    }

    //#region drawing (dom objects modification)

    protected setupNewDrawnItems(d3items: SD3Items) {
        const d3ItemSvgs = d3items.append(
            () => this.itemTemplateNode.cloneNode(true) as SVGSVGElement
        );

        d3ItemSvgs
            .select('.dg_dataVizObjectGraph-element')
            .attr('graphic', (d) => d.isRoot);

        d3ItemSvgs
            .select('.dg_dataVizObjectGraph-name')
            .text((d) => d.displayName)
            .style('font-weight', 900);

        d3ItemSvgs
            .select('.object-graph-name')
            .attr('x', 0)
            .attr('y', (d) => 50 + this.getDimensionFromChildCount(d));

        d3ItemSvgs
            .select('.object-graph-type')
            .attr('x', 50)
            .attr('y', (d) => 70 + this.getDimensionFromChildCount(d));

        d3ItemSvgs
            .select('.dg_dataVizObjectGraph-number')
            .text((d) => d.contextualAllLevelChildrenCount || '');

        d3ItemSvgs
            .select('.dg_dataVizObjectGraph-type')
            .text((d) =>
                this.attributeDataService.getEntityTypeTranslation(d.entityType)
            )
            .style('fill', '#aaa')
            .style('font-weight', 500);

        d3ItemSvgs
            .select('.dg_dataVizObjectGraph-cercle')
            .style('fill', (d) => this.getColorFromEntityType(d))
            .style('fill-opacity', (d) => this.getOpacity(d))
            .attr('r', (d) => this.getDimensionFromChildCount(d));

        d3ItemSvgs
            .select('.dg_dataVizObjectGraph-disque-font')
            .attr('r', (d) => this.getDimensionFromChildCount(d));

        d3ItemSvgs
            .select('.dg_dataVizObjectGraph-disque-select')
            .attr('r', (d) => this.getDimensionFromChildCount(d));
    }

    protected updateDrawnItems(d3items: SD3Items) {
        const d3ItemSvgs = this.redrawItemsPosition(d3items)
            .attr('visibility', (d) => (d.isDisplayed ? '' : 'hidden'))
            .attr('id', (d) => d.id);

        d3ItemSvgs
            .select('.dg_dataVizObjectGraph-name')
            .text((d) => d.displayName);

        d3ItemSvgs
            .select('.dg_dataVizObjectGraph-spinner')
            .attr('graphic', (d) => (d.isLoading ? '' : 'hidden'));
    }

    protected setupNewDrawnLinks(d3links: SD3Links) {
        d3links
            .append('line')
            .attr(
                'class',
                (link) =>
                    `dg_dataVizGraphObject-graphicalLink--${link.objectLinkTypeName}`
            );
    }

    protected updateDrawnLinks(d3links: SD3Links) {
        this.redrawLinks(d3links);
        const linkTexts = d3links.select<SVGTextElement>('text');
        DxyBaseForceGraphComponent.redrawLinksTextPosition(linkTexts);
        DxyBaseForceGraphComponent.redrawLinksTextVisibility(linkTexts);
    }

    protected redrawItemsPosition(d3items: SD3Items) {
        return DxyBaseForceGraphComponent.redrawItemsPosition(d3items);
    }
    protected redrawLinks(d3links: SD3Links) {
        return DxyBaseForceGraphComponent.redrawLinks(d3links);
    }

    private setCircleColorFromEntityTypes(
        d3items: SD3Items,
        filteredEntityTypes: EntityType[]
    ) {
        d3items
            .select('.dg_dataVizObjectGraph-cercle')
            .style('fill', (node) =>
                this.getColorFromEntityType(node, ...filteredEntityTypes)
            );
    }

    //#endregion

    private applyColorsBySelectedEntityTypes(
        selectedEntityTypes: EntityType[]
    ) {
        this.setCircleColorFromEntityTypes(this.d3Items(), selectedEntityTypes);
    }

    private showHideChildren(parent: Item, show: boolean, recursive: boolean) {
        this.log('showHideChildren', show, parent);
        if (show) {
            this.setSelectedItem(parent);
        }
        parent.clicked = show;

        if (parent.childrenClusterNodesId?.length) {
            if (!show) {
                this.graphData.clusterNode(parent);
            }

            const action = (it: Item) => {
                it.isVisible = show;
                it.clicked = show && recursive;
            };
            this.graphData.withChildren(parent, (it) => {
                action(it);
                if (!recursive) {
                    return;
                }
                this.graphData.withDescendants(it, action);
            });
        }

        if (show) {
            const children = recursive
                ? this.graphData.getDescendants(parent)
                : this.graphData.getChildren(parent);
            const greatParent = this.graphData.getParent(parent);
            if (greatParent) {
                this.positionChildrenOnCircle(parent, children, greatParent);
            } else {
                this.positionItemsOnCircle(
                    children,
                    DatamapGraphComponent.childrenSpacingRadius,
                    parent
                );
            }
        }

        this.updateAll(true);
    }

    private applyExpandAll() {
        this.isAllExpanded = true;
        this.withBigGraphAcknowledged(this.graphData.allEntitiesCount, () => {
            this.graphData.withAllItems((it) =>
                this.graphData.setExpandedCollapsed(it, true)
            );
            this.updateAll(false);
            this.zoomBestFitDelayed();
        });
    }
    private applyCollapseAll() {
        this.isAllExpanded = false;
        this.graphData.withDisplayableItems((it) =>
            this.graphData.setExpandedCollapsed(it, false)
        );
        this.updateAll(false);
        this.zoomBestFitDelayed();
    }

    private zoomBestFitDelayed() {
        this.log('zoomBestFitDelayed');
        setTimeout(() => this.zoomBestFit(true));
    }

    private getColorFromEntityType(item: Item, ...onlyIfSubType: EntityType[]) {
        const itemEntityType = item.entityType;
        const color = GlyphUtil.getEntityTypeColor(itemEntityType);
        return !onlyIfSubType.length ||
            onlyIfSubType.indexOf(itemEntityType) != -1
            ? color
            : '#D3D3D3';
    }
    private getOpacity(item: Item) {
        return item.level == 1 || item.contextualAllLevelChildrenCount
            ? 1 / (item.level - 0.7)
            : 1;
    }
    private getDimensionFromChildCount(item: Item) {
        const val = item.contextualAllLevelChildrenCount || 0;
        const max = this.graphData.getMaxContextualAllLevelChildrenCount();
        return 15 + (28 * Math.log(1 + (28 * val) / max)) / Math.log(31);
    }

    /** if the graph has many nodes, and has not been acknowledged by the user yet, a warning confirmation modal is displayed.
     *  In case of the modal being answered no or canceled, the action is not executed */
    private async withBigGraphAcknowledged<TResult>(
        nbTotalItems: number,
        action: () => TResult
    ) {
        if (
            nbTotalItems > DatamapGraphComponent.bigGraphSize &&
            !this.isBigGraphAcknowledged
        ) {
            const confirmed =
                await this.impactAnalysisService.confirmWarningModal();
            if (!confirmed) {
                return;
            }
            this.isBigGraphAcknowledged = true;
        }
        return action();
    }

    private async onFilteredViewChanged(
        event: ICurrentFilteredViewChangeEvent
    ) {
        if (event.dgZone != this.dgZone || event.context?.isReloading) {
            return;
        }

        this.log('onFilteredViewChanged');
        this.graphData = await this.impactAnalysisService.initDatamapGraphData(
            this.graphData.spaceIdr,
            this.graphData.dgModule
        );
        this.updateAll(this.config.noAnimationOnStart);
        this.zoomBestFitDelayed();
    }

    private updateSubTypeFilter() {
        const entityTypes = this.graphData.getAllEntitiesTypes();
        this.multiSelectData = {
            items: entityTypes,
            selectedItems: [],
            searchParams: { enabled: true },
            adapter: {
                getId: (et) => et.toString(),
                getText: (et) =>
                    this.attributeDataService.getEntityTypeTranslation(et),
                getGlyphClass: (et) => EntityTypeUtils.getColoredGlyphClass(et),
            },
            onSelectionChange: (selectedItems) =>
                this.applyColorsBySelectedEntityTypes(selectedItems),
        };
    }
}

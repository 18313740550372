import { Dashboard, DashboardSection, WidgetInstance } from '../../domain';
import { Injectable } from '@angular/core';
import {
    WidgetDroppedEvent,
    WidgetInstanceDroppedEvent,
    WidgetInstanceMovedEvent,
} from '../../common/dashboard-grid-events';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { DashboardState } from './dashboard-state';
import { sectionReducer } from './reducers/section-reducer';
import { insertWidgetInstanceReducer } from './reducers/insert-widget-instance-reducer';
import { removeWidgetInstanceReducer } from './reducers/remove-widget-instance-reducer';
import { moveWidgetInstanceReducer } from './reducers/move-widget-instance-reducer';
import { duplicateWidgetInstanceReducer } from './reducers/duplicate-widget-instance-reducer';
import { moveWidgetInstanceInsideSectionReducer } from './reducers/move-widget-instance-inside-section-reducer';
import { updateWidgetConfigurationReducer } from './reducers/update-widget-configuration-reducer';
import { BaseStateService, generateGuid } from '@datagalaxy/utils';

/**
 * ## Role
 * Create new dahboard state for each action on the dashboard
 */
@Injectable()
export class DashboardStateService extends BaseStateService<DashboardState> {
    constructor() {
        super({
            dashboard: {
                sections: [],
            },
        });
    }

    public init(dashboard: Dashboard) {
        if (dashboard?.sections == undefined) {
            return;
        }
        this.setState({ dashboard });
    }

    public getDashboard() {
        return this.state.dashboard;
    }
    public getSectionsLength() {
        return this.getDashboard()?.sections?.length ?? 0;
    }

    public selectSections() {
        return this.select((s) => s.dashboard.sections);
    }

    public addSection(section: DashboardSection) {
        this.setState({
            dashboard: {
                sections: [...this.state.dashboard.sections, section],
            },
        });
    }

    public addWidgetToSection(
        section: DashboardSection,
        event: WidgetDroppedEvent
    ) {
        const newWidgetInstance: WidgetInstance = {
            ...event,
            guid: generateGuid(),
        };
        let newState = insertWidgetInstanceReducer(
            this.state,
            section,
            newWidgetInstance
        );
        this.setState(newState);
    }

    public addWidgetInstanceToSection(
        section: DashboardSection,
        event: WidgetInstanceDroppedEvent
    ) {
        this.setState(moveWidgetInstanceReducer(this.state, section, event));
    }

    public duplicateWidgetInstance(
        section: DashboardSection,
        wi: WidgetInstance
    ) {
        this.setState(duplicateWidgetInstanceReducer(this.state, section, wi));
    }
    public removeWidgetInstance(section: DashboardSection, wi: WidgetInstance) {
        this.setState(removeWidgetInstanceReducer(this.state, section, wi));
    }

    public moveWidgetsInsideSection(
        section: DashboardSection,
        events: WidgetInstanceMovedEvent[]
    ) {
        this.setState(
            moveWidgetInstanceInsideSectionReducer(this.state, section, events)
        );
    }

    public removeSectionAtIndex(index: number) {
        const newState = { ...this.state };
        newState.dashboard = { ...newState.dashboard };
        newState.dashboard.sections = [...newState.dashboard.sections];
        newState.dashboard.sections.splice(index, 1);
        this.setState(newState);
    }
    public moveSectionAtIndex(section: DashboardSection, currentIndex: number) {
        const previousIndex = this.getDashboard().sections.findIndex(
            (s) => s.id == section.id
        );
        const newState = { ...this.state };
        newState.dashboard = { ...newState.dashboard };
        newState.dashboard.sections = [...newState.dashboard.sections];
        moveItemInArray(
            newState.dashboard.sections,
            previousIndex,
            currentIndex
        );
        this.setState(newState);
    }

    public renameSection(sectionId: string, newTitle: string) {
        const section = this.state.dashboard.sections.find(
            (s) => s.id == sectionId
        );
        if (!section) {
            return;
        }
        this.setState(
            sectionReducer(this.state, section, {
                title: newTitle,
            })
        );
    }

    public changeSectionTitleVisibility(sectionId: string, visible: boolean) {
        const section = this.state.dashboard.sections.find(
            (s) => s.id == sectionId
        );
        if (!section) {
            return;
        }
        this.setState(
            sectionReducer(this.state, section, {
                titleVisible: visible,
            })
        );
    }

    public updateWidgetConfiguration(
        section: DashboardSection,
        wi: WidgetInstance,
        configuration: unknown
    ) {
        this.setState(
            updateWidgetConfigurationReducer(
                this.state,
                section,
                wi,
                configuration
            )
        );
    }

    public revertToState(newState: DashboardState) {
        this.setState(newState);
    }
}

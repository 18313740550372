import { Component, Inject } from '@angular/core';
import { DxyBaseModalComponent } from '@datagalaxy/ui/dialog';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ToasterService } from '../../services/toaster.service';
import { ClientAdminService } from '../../services/clientAdmin.service';
import { IUserProfileModalResolve } from '../models/IUserProfileModalResolve';
import { UserAdminData } from '@datagalaxy/webclient/user/data-access';
import {
    ChangePasswordParameter,
    ChangePasswordResult,
} from '@datagalaxy/webclient/auth/data-access';
import { catchUnsuccessfulApiErrorOrThrow } from '@datagalaxy/data-access';

@Component({
    selector: 'dxy-user-profile-change-password-modal',
    templateUrl: './dxy-user-profile-change-password-modal.component.html',
})
export class DxyUserProfileChangePasswordModalComponent extends DxyBaseModalComponent<
    IUserProfileModalResolve,
    void
> {
    public oldPassword: string;
    public newPassword: string;
    public copyPassword: string;
    public oldPasswordMessage: string;
    public comparePasswordsMessage: string;
    public isOldPasswordServerError = false;
    public get canCreate(): boolean {
        return !!(
            !this.isOldPasswordServerError &&
            this.oldPassword &&
            this.newPassword &&
            this.copyPassword &&
            this.newPassword === this.copyPassword
        );
    }
    public get comparePasswordsErrorMessage() {
        return !this.newPassword ||
            !this.copyPassword ||
            this.newPassword !== this.copyPassword
            ? this.translate.instant(
                  'UI.User.changePasswordModal.error.noMatch'
              )
            : '';
    }
    public get oldPasswordErrorMessage() {
        return this.oldPassword == ''
            ? this.translate.instant(
                  'UI.User.changePasswordModal.error.OldPassRequired'
              )
            : '';
    }

    private get currentUser(): UserAdminData {
        return this.data.adminUserData;
    }

    constructor(
        dialogRef: MatDialogRef<DxyUserProfileChangePasswordModalComponent>,
        @Inject(MAT_DIALOG_DATA) data: IUserProfileModalResolve,
        private translate: TranslateService,
        private toasterService: ToasterService,
        private clientAdminService: ClientAdminService
    ) {
        super(dialogRef, data);
    }

    public async onSubmit(): Promise<void> {
        const cupp = new ChangePasswordParameter();
        cupp.Old = this.oldPassword;
        cupp.New = this.newPassword;
        cupp.Email = this.currentUser.Email;

        try {
            await this.clientAdminService.changeUserPassword(cupp);
            super.onCloseSubmit();
            this.toasterService.successToast({
                messageKey: 'UI.User.changePasswordModal.changeSuccess',
            });
        } catch (e) {
            catchUnsuccessfulApiErrorOrThrow<ChangePasswordResult>(
                e,
                (error) => {
                    if (error.result.IsErrorAccessDenied) {
                        this.isOldPasswordServerError = true;
                        this.oldPasswordMessage = this.translate.instant(
                            'UI.User.changePasswordModal.error.OldPasswordIsBad'
                        );
                    } else {
                        this.toasterService.errorToast({
                            messageKey:
                                'UI.User.changePasswordModal.changeError',
                        });
                    }
                }
            );
        }
    }

    public onOldPasswordChange() {
        this.isOldPasswordServerError = false;
    }
}

<div class="connection-form-settings-container">
    <div *ngIf="isAdvancedModeVisible" class="advanced-toggle">
        <div class="toggle-label" translate>
            UI.Connector.Wizard.Step3.advancedModeText
        </div>
        <mat-slide-toggle
            [checked]="isAdvancedMode"
            (change)="onToggleAdvancedMode()"
            [disabled]="!hasCustomForm"
            class="toggle"
            color="primary"
        ></mat-slide-toggle>
    </div>
    <div *ngIf="!isAdvancedMode">
        <ng-container *ngFor="let def of fieldDefs">
            <ng-container *ngIf="isBaseField(def)">
                <ng-container
                    [ngTemplateOutlet]="fieldsTemplate"
                    [ngTemplateOutletContext]="{def}"
                ></ng-container>
            </ng-container>
        </ng-container>

        <div *ngIf="hasMaskList" class="section-title">
            <span class="sub-title" translate
                >UI.Connector.Wizard.Step3.options</span
            >
        </div>
        <ng-container *ngFor="let def of fieldDefs">
            <ng-container *ngIf="isOptionSectionField(def)">
                <ng-container
                    [ngTemplateOutlet]="fieldsTemplate"
                    [ngTemplateOutletContext]="{def}"
                ></ng-container>
            </ng-container>
        </ng-container>

        <div *ngIf="hasFilterSection" class="section-title">
            <span class="sub-title" translate
                >UI.Connector.Wizard.Step3.filterSection</span
            >
        </div>
        <ng-container *ngFor="let def of fieldDefs">
            <ng-container *ngIf="isFilterSectionField(def)">
                <ng-container
                    [ngTemplateOutlet]="fieldsTemplate"
                    [ngTemplateOutletContext]="{def}"
                ></ng-container>
            </ng-container>
        </ng-container>
    </div>
    <div *ngIf="isAdvancedMode">
        <dxy-field-text
            [takeFocus]="true"
            labelKey="UI.Connector.Wizard.Step3.advancedModeInputLbl"
            [(ngModel)]="advancedModeText"
            [updateOnBlur]="true"
            (ngModelChange)="checkAdvancedModeTextFormat()"
            [errorMessageText]="advancedFieldError"
            [multiLine]="true"
            class="advanced-mode-field"
        ></dxy-field-text>
    </div>
</div>

<ng-template #fieldsTemplate let-def="def">
    <dxy-field-text
        *ngIf="isText(def)"
        [labelKey]="def.labelKey"
        [required]="def.conf.formMandatory"
        [infoTooltip]="def.description"
        [hint]="def.hint"
        [hintBeforeControl]="true"
        [(ngModel)]="def.data"
        (ngModelChange)="onValueChange(def.fieldKey)"
        [updateOnBlur]="true"
        [disabled]="def.conf.isDisabled?.(fields)"
        [dxyDataTestId]="def.conf.formField + '-field'"
    ></dxy-field-text>
    <dxy-field-boolean
        *ngIf="isBool(def)"
        [labelKey]="def.labelKey"
        [required]="def.conf.formMandatory"
        [infoTooltip]="def.description"
        [hint]="def.hint"
        [hintBeforeControl]="true"
        [(ngModel)]="def.data"
        (ngModelChange)="onValueChange(def.fieldKey)"
        [updateOnBlur]="true"
        [disabled]="def.conf.isDisabled?.(fields)"
        [dxyDataTestId]="def.conf.formField + '-field'"
    ></dxy-field-boolean>
    <dxy-field-select
        *ngIf="isSelect(def)"
        [labelKey]="def.labelKey"
        [required]="def.conf.formMandatory"
        [infoTooltip]="def.description"
        [hint]="def.hint"
        [hintBeforeControl]="true"
        [(ngModel)]="def.data"
        [updateOnBlur]="true"
        [adapter]="def.adapter"
        (openClose)="onMenuOpenClose.emit($event)"
        [disabled]="def.conf.isDisabled?.(fields)"
        [dxyDataTestId]="def.conf.formField + '-field'"
    ></dxy-field-select>
    <dxy-connection-form-field-list
        *ngIf="isList(def)"
        [values]="def.data"
        [fieldKey]="def.fieldKey"
        (onClickOpen)="openListFieldModal(def)"
        [titleTradKey]="def.conf.listFieldTradKeys.gridTitle"
        [addBtnTranslateKey]="def.conf.listFieldTradKeys.gridAddBtn"
    ></dxy-connection-form-field-list>
    <div *ngIf="isToggle(def)" class="toggle">
        <mat-slide-toggle
            color="primary"
            [(ngModel)]="def.data"
            (ngModelChange)="onValueChange(def.fieldKey)"
        >
        </mat-slide-toggle>
        <span class="toggle-label">
            <span translate>{{ def.labelKey }}</span>
            <span
                *ngIf="def.description"
                class="glyph-info"
                [matTooltip]="def.description"
            ></span>
        </span>
    </div>
    <div *ngIf="def.conf.isPassword" class="password-wrapper">
        <dxy-field-password
            *ngIf="!isPasswordFile && def.labelKey"
            [labelKey]="def.labelKey"
            [placeholder]="getPasswordFieldPlaceholder(def)"
            [(ngModel)]="def.data"
            (ngModelChange)="setPassword(def)"
            [infoTooltip]="def.description"
            [hint]="def.hint"
            [hintBeforeControl]="true"
            [updateOnBlur]="true"
            [mandatory]="def.conf.formMandatory"
            [disabled]="!def.conf.isUpdatingPassword"
            [hideMaxLengthHint]="true"
            [passwordMaxLength]="def.conf.passwordMaxLength"
            [dxyDataTestId]="def.conf.formField + '-field'"
        ></dxy-field-password>

        <dxy-field-file-upload
            *ngIf="isPasswordFile && def.labelKey"
            [labelKey]="def.labelKey"
            [(ngModel)]="passwordFiles"
            [infoTooltip]="def.description"
            [hint]="def.hint"
            [hintBeforeControl]="true"
            [maxFileSize]="15728640"
            acceptedFormat="application/JSON"
            [mandatory]="def.conf.formMandatory"
            [disabled]="!def.conf.isUpdatingPassword"
            [dxyDataTestId]="def.conf.formField + '-upload'"
        ></dxy-field-file-upload>

        <button
            *ngIf="!def.conf.isUpdatingPassword && def.labelKey"
            mat-icon-button
            type="button"
            class="password-change-btn"
            (click)="onChangePasswordClick(def)"
            dxyDataTestId="change-password-button"
        >
            <i class="glyph-edit"></i>
        </button>
    </div>
</ng-template>

import { Ng2StateDeclaration } from '@uirouter/angular/interface';
import { ClientSpacesListComponent } from './client-spaces-list/client-spaces-list.component';
import { ClientMainComponent } from './client-main/client-main.component';
import { Constants } from '../shared/util/Constants';
import { StateName } from '@datagalaxy/webclient/routing';
import { DxyNavbarTitleComponent } from '../navbar/dxy-navbar-title/dxy-navbar-title.component';
import { AppSpaceService } from '../services/AppSpace.service';
import { ViewIdentifier } from '../shared/util/ViewIdentifier';
import { CommonStateDefinitions } from '../shared/util/states/CommonStateDefinitions';
import { DxyMainSearchComponent } from '../search/dxy-main-search/dxy-main-search.component';
import { DxyTaskSearchComponent } from '../tasks/dxy-task-search/dxy-task-search.component';
import { DxyClientErrorComponent } from '../errorPages/dxy-client-error/dxy-client-error.component';
import { SlackRedirectionQueryParams } from '../notification-channels/slack-redirection.types';
import Nav = Constants.Nav;
import UrlWord = Nav.UrlWord;
import ParamKey = Nav.ParamKey;
import { ClientFirstAccessComponent } from './first-access/client-first-access.component';
import { UserService } from '../services/user.service';
import { Transition } from '@uirouter/core';
import { handleQueryParamsOnClientTransitionSuccess } from './client-query-param-handler';
import { DgZone } from '@datagalaxy/webclient/domain';

export const clientRoute: Ng2StateDeclaration = {
    name: StateName.Client,
    url: `?from&${SlackRedirectionQueryParams.code}&${SlackRedirectionQueryParams.callbackIdentifier}`,
    views: { '@main': { component: ClientMainComponent } },
    onEnter: async (transition: Transition) => {
        if (transition.options()?.custom?.queryParamRemoval) {
            return;
        }
        transition.onSuccess({}, (transition) => {
            handleQueryParamsOnClientTransitionSuccess(transition);
        });
    },
};

export const legacyClientRoute: Ng2StateDeclaration = {
    name: StateName.Client,
    url: `/${UrlWord.client}/:${ParamKey.ClientId}?from&${SlackRedirectionQueryParams.code}&${SlackRedirectionQueryParams.callbackIdentifier}`,
    views: { '@main': { component: ClientMainComponent } },
};

const clientWorkspacesRoute: Ng2StateDeclaration = {
    name: StateName.ClientSpacesList,
    url: `/${UrlWord.spaces}`,
    views: {
        content: { component: ClientSpacesListComponent },
        'navbarTitle@^': { component: DxyNavbarTitleComponent },
    },
    resolve: [
        {
            token: '_clearCurrentSpace',
            deps: [AppSpaceService],
            resolveFn: (appSpaceService: AppSpaceService) =>
                appSpaceService.clearCurrentSpace(),
        },
        CommonStateDefinitions.getResolveNavbarTitleViewIdentifier(
            ViewIdentifier.ClientSpacesList
        ),
    ],
};

const clientSearchResultsRoute: Ng2StateDeclaration = {
    name: StateName.ClientSearchResults,
    url: `/${UrlWord.searchResults}`,
    views: {
        content: { component: DxyMainSearchComponent },
        navbarTitle: { component: DxyNavbarTitleComponent },
    },
    resolve: [
        {
            token: 'spaceData',
            resolveFn: () => null,
        },
        CommonStateDefinitions.getResolveNavbarTitleViewIdentifier(
            ViewIdentifier.ClientSearchResults
        ),
        CommonStateDefinitions.getResolveNavbarCurrentFilteredView(
            DgZone.Search,
            { filteredViewCategory: 'MainSearch' }
        ),
    ],
};

const clientTasksRoute: Ng2StateDeclaration = {
    name: StateName.ClientTasks,
    url: `/${UrlWord.tasks}`,
    views: {
        content: { component: DxyTaskSearchComponent },
        navbarTitle: { component: DxyNavbarTitleComponent },
    },
    resolve: [
        {
            token: '_clearCurrentSpace',
            deps: [AppSpaceService],
            resolveFn: (appSpaceService: AppSpaceService) =>
                appSpaceService.clearCurrentSpace(),
        },
        CommonStateDefinitions.getResolveNavbarTitleViewIdentifier(
            ViewIdentifier.ClientTasks
        ),
    ],
};

const clientErrorRoute: Ng2StateDeclaration = {
    name: StateName.ClientError,
    url: `/${UrlWord.error}`,
    views: {
        content: { component: DxyClientErrorComponent },
    },
};

const clientProfileLazyRoute: Ng2StateDeclaration = {
    name: StateName.UserLazyLoaded,
    url: `/${UrlWord.user}`,
    loadChildren: () => import('../user/user.module').then((m) => m.UserModule),
};

const clientAdminLazyRoute: Ng2StateDeclaration = {
    name: StateName.ClientAdminLazyLoaded,
    url: `/${UrlWord.administration}`,
    loadChildren: () =>
        import('../client-admin/client-admin.module').then(
            (m) => m.ClientAdminModule
        ),
};

const clientDashboardLazyRoute: Ng2StateDeclaration = {
    name: StateName.ClientDashboardLazyLoaded,
    url: `/${UrlWord.dashboard}`,
    loadChildren: () =>
        import('../widgets-dashboard/widgets-dashboard.module').then(
            (m) => m.WidgetsDashboardModule
        ),
};

/**
 * @deprecated to replace with clientFirstAccessRouteAuthV2
 */
export const clientFirstAccessRoute: Ng2StateDeclaration = {
    name: StateName.ClientFirstAccess,
    url: `/${UrlWord.login}/${UrlWord.firstAccess}`,
    views: { '@main': { component: ClientFirstAccessComponent } },
    resolve: [
        {
            token: 'user',
            deps: [UserService],
            resolveFn: (userService: UserService) =>
                userService.getCurrentUser(),
        },
    ],
};

export const clientFirstAccessRouteAuthV2: Ng2StateDeclaration = {
    name: StateName.ClientFirstAccessAuthV2,
    url: `/${UrlWord.firstAccess}`,
    views: { '@main': { component: ClientFirstAccessComponent } },
    resolve: [
        {
            token: 'user',
            deps: [UserService],
            resolveFn: (userService: UserService) =>
                userService.getCurrentUser(),
        },
    ],
};

export const clientRoutes: Ng2StateDeclaration[] = [
    clientWorkspacesRoute,
    clientSearchResultsRoute,
    clientTasksRoute,
    clientErrorRoute,
    clientProfileLazyRoute,
    clientAdminLazyRoute,
    clientDashboardLazyRoute,
];

import { Injectable } from '@angular/core';
import { EntityService } from '../../shared/entity/services/entity.service';
import { EntityEventService } from '../../shared/entity/services/entity-event.service';
import { CollectionsHelper, CoreUtil } from '@datagalaxy/core-util';
import { CurrentSpaceService } from '../../services/currentSpace.service';
import { IEntityIdentifier, ServerType } from '@datagalaxy/dg-object-model';
import { DksNotifierService } from '../notifier/dks-notifier';
import {
    EntityIdentifier,
    EntityServerTypeUtils,
    EntityUtils,
} from '@datagalaxy/webclient/entity/utils';
import { BaseStateService } from '@datagalaxy/utils';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';
import { ServerConstants } from '@datagalaxy/shared/server/domain';
import PropertyName = ServerConstants.PropertyName;

export interface DksEntityState {
    entities: EntityItem[];
    extraDataAttributesKeys: string[];
}

@Injectable()
export class DksEntitiesService extends BaseStateService<DksEntityState> {
    private static extraDataKeysOrdered: string[] = [
        PropertyName.EntityStatus,
        PropertyName.Domains,
        PropertyName.DataOwners,
        PropertyName.DataStewards,
        PropertyName.DataQuality,
    ];

    constructor(
        private dksNotifier: DksNotifierService,
        private entityService: EntityService,
        private entityEvents: EntityEventService,
        private currentSpace: CurrentSpaceService
    ) {
        super({
            entities: [],
            extraDataAttributesKeys: [],
        });

        this.entityEvents.subscribeEntityUpdate(null, (event) =>
            this.onEntityChange(event)
        );
        this.dksNotifier.events$.extraDataChanged$.subscribe((keys) =>
            this.onExtraDataChange(keys)
        );
    }

    public async addEntities(ids: string[]) {
        const attributeKeys = this.state.extraDataAttributesKeys;
        await this.loadEntities(ids, attributeKeys);
    }

    public getEntityById(entityIdr: IEntityIdentifier) {
        return this.state.entities.find(
            (entity) => entity.ReferenceId === entityIdr.ReferenceId
        );
    }

    public selectEntity(entityIdr: IEntityIdentifier) {
        return this.select((state) =>
            state.entities.find(
                (et) => et.ReferenceId === entityIdr.ReferenceId
            )
        );
    }

    public selectExtraDataKeys() {
        return this.select((state) => state.extraDataAttributesKeys);
    }

    public getExtraDataKeys() {
        return this.state.extraDataAttributesKeys;
    }

    public selectEntityExtraDataKeys(entityIdr: IEntityIdentifier) {
        return this.select((state) => {
            const entity = state.entities.find(
                (entity) => entity.ReferenceId === entityIdr.ReferenceId
            );
            return (
                entity &&
                state.extraDataAttributesKeys?.filter((key) =>
                    this.hasExtraData(entity, key)
                )
            );
        });
    }

    public selectEntities() {
        return this.select((state) => state.entities);
    }

    private async loadEntities(ids: string[], attributeKeys: string[] = []) {
        const spaceIdr = this.currentSpace.currentSpace;
        const res = await this.entityService.loadMultiEntity({
            parentReferenceId: spaceIdr.spaceId,
            versionId: spaceIdr.versionId,
            dataReferenceIdList: ids,
            includeHdd: true,
            dataTypes: [
                ...EntityServerTypeUtils.firstClassEntityServerTypes,
                ServerType.DataProcessingItem,
            ],
            includedAttributesFilter: [
                PropertyName.DisplayName,
                ...attributeKeys,
            ],
        });

        const entities = CollectionsHelper.distinctByProperty(
            [...this.state.entities, ...res.Entities],
            (o) => o.ReferenceId
        );

        this.setState({
            entities,
            extraDataAttributesKeys: attributeKeys,
        });
    }

    private onEntityChange(updatedEntity: EntityItem) {
        const entity = this.state.entities.find((et) =>
            EntityIdentifier.areSame(et, updatedEntity)
        );

        if (!entity) {
            return;
        }

        EntityUtils.mergeEntity(entity, updatedEntity, true);

        this.setState({
            ...this.state,
            entities: [
                ...CollectionsHelper.replaceOne(
                    this.state.entities,
                    (entity) =>
                        entity.ReferenceId === updatedEntity.ReferenceId,
                    CoreUtil.cloneDeep(entity)
                ),
            ],
        });
    }

    private async onExtraDataChange(keys: string[]) {
        const ids = this.state.entities.map((et) => et.ReferenceId);
        void this.loadEntities(
            ids,
            keys?.sort(
                CollectionsHelper.sortByIndexOf<string>(
                    DksEntitiesService.extraDataKeysOrdered
                )
            )
        );
    }

    private hasExtraData(entity: EntityItem, key: string) {
        const attributeValue = entity.getAttributeValue(key);
        return (
            (Array.isArray(attributeValue) && !!attributeValue?.length) ||
            (!Array.isArray(attributeValue) && !!attributeValue)
        );
    }
}

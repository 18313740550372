import { GRID_COLUMNS } from '../../domain/dashboard-constants';
import { GridPosition } from '../../domain/grid-position';

export function getPreviousPosition(position: GridPosition): GridPosition {
    const newX = position.x === 0 ? GRID_COLUMNS - 1 : position.x - 1;
    const newY = position.x === 0 ? position.y - 1 : position.y;

    if (newY < 0) {
        return { x: 0, y: 0 };
    }

    return {
        x: newX,
        y: newY,
    };
}

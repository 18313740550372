import { Component, Input } from '@angular/core';
import { TAccessLevel } from '../errorPages.types';
import { DxyBaseComponent } from '@datagalaxy/ui/core';

@Component({
    selector: 'dxy-error-main',
    templateUrl: 'dxy-error-main.component.html',
    styleUrls: ['dxy-error-main.component.scss'],
})
export class DxyErrorMainComponent extends DxyBaseComponent {
    @Input() errorMessage: string;
    @Input() accessLevel: TAccessLevel;

    constructor() {
        super();
    }
}

import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { EntityCardModule } from '../../../shared/entityCard/entity-card.module';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { NgForOf, NgIf } from '@angular/common';
import {
    IOnStageAsset,
    IOnStageAssetChild,
} from '../asset-onstage/asset-onstage.types';
import { IEntityIdentifier } from '@datagalaxy/dg-object-model';
import { EntityPreviewPanelService } from '../../../shared/entity/services/entity-preview-panel.service';
import { MatLegacyCheckboxModule } from '@angular/material/legacy-checkbox';
import { FormsModule } from '@angular/forms';
import { DxyButtonsModule } from '@datagalaxy/ui/buttons';

@Component({
    standalone: true,
    selector: 'app-asset-onstage-item',
    templateUrl: './asset-onstage-item.component.html',
    styleUrls: ['./asset-onstage-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        EntityCardModule,
        MatLegacyButtonModule,
        NgForOf,
        NgIf,
        MatLegacyCheckboxModule,
        FormsModule,
        DxyButtonsModule,
    ],
})
export class AssetOnstageItemComponent<NodeData> {
    @Input() asset: IOnStageAsset<NodeData> | IOnStageAssetChild;
    @Input() depth = 0;
    @Input() disabledSelection = false;

    @Output() select = new EventEmitter<string[]>();
    @Output() unselect = new EventEmitter<string[]>();

    protected get hasNoAccess() {
        return !this.asset.params.data.HddData.Data.HasReadAccess;
    }

    constructor(private entityPreviewPanelService: EntityPreviewPanelService) {}

    protected onToggleClick(event: MouseEvent) {
        event.stopPropagation();
        this.asset.opened = !this.asset.opened;
    }

    protected async openPreviewPanel(entityIdr: IEntityIdentifier) {
        await this.entityPreviewPanelService.setupPanel({
            entityIdr,
        });
    }

    protected toggleSelection(
        assets: (IOnStageAsset<NodeData> | IOnStageAssetChild)[],
        selected: boolean
    ) {
        const nodeIds = assets.map((asset) => asset.entity.ReferenceId);
        if (selected) {
            this.select.emit(nodeIds);
        } else {
            this.unselect.emit(nodeIds);
        }
    }
}

import { NgModule } from '@angular/core';
import { DxyMatBurgerMenuComponent } from './mat-burger-menu.component';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { DxyBurgerMenuComponent } from '../burger-menu/burger-menu.component';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { DxyCoreDirectivesModule } from '../../directives';
import { DxyOptionItemModule } from '../option-item/option-item.module';

@NgModule({
    imports: [
        CommonModule,
        DxyCoreDirectivesModule,
        TranslateModule,
        MatMenuModule,
        DxyOptionItemModule,
    ],
    declarations: [DxyBurgerMenuComponent, DxyMatBurgerMenuComponent],
    exports: [DxyMatBurgerMenuComponent, DxyBurgerMenuComponent],
})
export class DxyMatBurgerMenuModule {}

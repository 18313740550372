import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import {
    ITabsHeaderData,
    ITabItem,
    DxyTabsHeaderComponent,
    IActionOption,
    IListOption,
} from '@datagalaxy/core-ui';
import { EntityDksNode } from '../../data-knowledge-studio.types';
import { IOnStageAsset } from '../asset-onstage/asset-onstage.types';
import { DxyBaseComponent } from '@datagalaxy/ui/core';

/**
 * ## Role
 * Lists of diagram-related items, displayed in tabs
 */
@Component({
    selector: 'app-dks-asset-panel',
    templateUrl: 'asset-panel.component.html',
    styleUrls: ['./asset-panel.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DksAssetPanelComponent<NodeData>
    extends DxyBaseComponent
    implements OnInit, OnChanges
{
    @Input() onStageAssets: IOnStageAsset<NodeData>[];
    @Input() entityActions: IActionOption<Partial<EntityDksNode>>[];
    @Input() burgerMenu: IListOption[];
    @Input() canUpdateDiagramContent: boolean;
    @Input() disabledSelection = false;

    @Output() select = new EventEmitter<string[]>();
    @Output() unselect = new EventEmitter<string[]>();

    protected headerTabs: ITabsHeaderData;
    protected activeTab: ITabItem;
    protected get isTabOnStage() {
        return this.activeTab == this.onstageTab;
    }
    protected get isTabCatalog() {
        return this.activeTab == this.catalogTab;
    }

    private onstageTab: ITabItem;
    private catalogTab: ITabItem;

    @ViewChild(DxyTabsHeaderComponent)
    private readonly tabsHeader: DxyTabsHeaderComponent;

    constructor(private cd: ChangeDetectorRef) {
        super();
    }

    ngOnInit() {
        this.initTabs();
    }

    ngOnChanges(changes: SimpleChanges) {
        super.onChange(
            changes,
            'onStageAssets',
            () => this.onEntitiesChange(),
            true
        );
    }

    //#region API
    public refresh() {
        this.cd.detectChanges();
        this.tabsHeader.refresh();
    }
    //#endregion

    protected onTabChange(tabItem: ITabItem) {
        this.activeTab = tabItem;
        this.tabsHeader.realignInkBar();
    }

    private initTabs() {
        this.headerTabs = {
            tabItems: [
                (this.onstageTab = {
                    tabId: 'onstage',
                    tabTranslateKey: 'UI.Diagrams.Assets.onStage',
                }),
            ],
            isTabItemActive: (tabItem) =>
                tabItem.tabId == this.activeTab?.tabId,
        };

        if (this.canUpdateDiagramContent) {
            this.headerTabs.tabItems.push(
                (this.catalogTab = {
                    tabId: 'catalog',
                    tabTranslateKey: 'UI.Diagrams.Assets.catalog',
                })
            );
        }

        this.activeTab =
            !this.onStageAssets?.length && this.catalogTab
                ? this.catalogTab
                : this.onstageTab;
    }

    private onEntitiesChange() {
        if (this.onstageTab) {
            this.onstageTab.contentDataCount = this.onStageAssets?.length;
        }
        this.tabsHeader?.refresh();
    }
}

import { Component, Input, OnInit } from '@angular/core';
import { CoreEventsService } from '@datagalaxy/core-ui';
import { ImportWizardService } from '../../import/services/import-wizard.service';
import { AppEventsService } from '../../services/AppEvents.service';
import {
    ModuleView,
    NavigationService,
} from '../../services/navigation.service';
import { EntityService } from '../../shared/entity/services/entity.service';
import { EntityEventService } from '../../shared/entity/services/entity-event.service';
import { EntityUiService } from '../../shared/entity/services/entity-ui.service';
import { FilterBarService } from '../../shared/filter/services/filterBar.service';
import { FilteredViewService } from '../../shared/filter/services/filteredView.service';
import { FreshDeskService } from '../../shared/support/freshdesk/fresh-desk.service';
import { GlossaryService } from '../../glossary/glossary.service';
import { AngularSplitService } from '../../services/angular-split.service';
import { ImportContext, ImportTarget } from '../../import/shared/ImportContext';
import { DataUtil } from '../../shared/util/DataUtil';
import { ModuleSecurityData } from '@datagalaxy/webclient/workspace/data-access';
import {
    CrudOperation,
    FunctionalLogService,
} from '@datagalaxy/webclient/monitoring/data-access';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { ISpaceIdentifier } from '@datagalaxy/webclient/workspace/domain';
import { FilteredViewDto } from '@datagalaxy/webclient/filter/domain';
import { userSettingsValues } from '@datagalaxy/webclient/user/domain';
import { DgZone } from '@datagalaxy/webclient/domain';
import { DgModule } from '@datagalaxy/shared/dg-module/domain';
import { EntityCreationOrigin } from '@datagalaxy/webclient/entity/feature';

/** Controller for the 4 modules:
 * - Glossary,
 * - Catalog (the 'Modeler'),
 * - Usage (ex 'Softwares'),
 * - Processing (DataProcessings) */
@Component({
    //eslint-disable-next-line @angular-eslint/component-selector
    selector: 'dxy-module-main',
    templateUrl: 'dxy-module-main.component.html',
    styleUrls: ['./dxy-module-main.component.scss'],
})
export class DxyModuleMainComponent extends DxyBaseComponent implements OnInit {
    @Input() dgModule: DgModule;
    @Input() spaceIdr: ISpaceIdentifier;
    @Input() moduleSecurityData: ModuleSecurityData;
    @Input() currentFilteredView: FilteredViewDto;

    public readonly dgZone = DgZone.Module;
    public isEmptyModule = false;

    public get isHierarchical() {
        return this.navigationService.isHierarchical;
    }
    public get isFullScreenDetailsMode() {
        return this.navigationService.isEntityFullPage;
    }

    public get isListViewCollapsed() {
        return this.isEntityFullPage;
    }
    public set isListViewCollapsed(isCollapsed: boolean) {
        /* NOTE: We have to keep this noop method for proper behavior in full screen mode */
    }

    public get isGlossary() {
        return this.dgModule == DgModule.Glossary;
    }

    public get showListContainer() {
        return (
            this.navigationService.getActiveModuleView() ==
            ModuleView.entityList
        );
    }
    public get showNonListContainer() {
        const activeView = this.navigationService.getActiveModuleView();
        return (
            activeView == ModuleView.entityGrid ||
            (!this.isModeler && activeView == ModuleView.datamap)
        );
    }

    public get currentModelId() {
        return this.isModeler
            ? this.navigationService.currentModelId
            : undefined;
    }
    public get currentModelType() {
        return this.isModeler
            ? this.navigationService.currentModelType
            : undefined;
    }
    public get isFiltersBarVisible() {
        return (
            !this.isFullScreenDetailsMode &&
            this.filterBarService.isFilterBarVisible(this.dgZone)
        );
    }
    public get splitterPersistenceId() {
        return userSettingsValues.angularSplit.routes.moduleMain;
    }
    public get glossaryAutoGenerationFuncLog() {
        return this.glossaryService.glossaryAutoGenerationFuncLog(
            this.dgModule
        );
    }

    private isEntityFullPage: boolean;
    private modulesEntitiesCount: Map<DgModule, number>;
    private get isModeler() {
        return this.dgModule == DgModule.Catalog;
    }

    constructor(
        private navigationService: NavigationService,
        private filterBarService: FilterBarService,
        private appEventsService: AppEventsService,
        private coreEventsService: CoreEventsService,
        private importWizardService: ImportWizardService,
        private entityUiService: EntityUiService,
        private entityEventService: EntityEventService,
        private entityService: EntityService,
        private functionalLogService: FunctionalLogService,
        private freshDeskService: FreshDeskService,
        private glossaryService: GlossaryService,
        private angularSplitService: AngularSplitService,
        private filteredViewService: FilteredViewService
    ) {
        super();
    }

    ngOnInit() {
        this.initAsync();
        this.subscribeEvents();

        this.log(
            'ngOnInit',
            DgModule[this.dgModule],
            this.spaceIdr,
            this.isEntityFullPage
        );
    }

    public onSplitDragEnd() {
        this.coreEventsService.emitMainViewResize();
    }

    public onFiltersBarCloseClick() {
        this.filterBarService.hideFilterBar(this.dgZone);
    }

    public getModuleDetails(): IEmptyModuleDetails {
        switch (this.dgModule) {
            case DgModule.Glossary:
                return {
                    imageSource:
                        '/images/placeholders/modules/glossary-main.svg',
                    description: 'UI.ModuleEmpty.glossaryDescriptionContent',
                    title: 'UI.ModuleEmpty.glossaryTitle',
                    documentationLbl:
                        'UI.ModuleEmpty.glossaryDocumentationLink',
                };
            case DgModule.Catalog:
                return {
                    imageSource:
                        '/images/placeholders/modules/catalog-main.svg',
                    description: 'UI.ModuleEmpty.dictionnaryDescriptionContent',
                    title: 'UI.ModuleEmpty.catalogTitle',
                    documentationLbl:
                        'UI.ModuleEmpty.dictionnaryDocumentationLink',
                };
            case DgModule.Processing:
                return {
                    imageSource:
                        '/images/placeholders/modules/processing-main.svg',
                    description: 'UI.ModuleEmpty.processingDescriptionContent',
                    title: 'UI.ModuleEmpty.processingTitle',
                    documentationLbl:
                        'UI.ModuleEmpty.processingDocumentationLink',
                };
            case DgModule.Usage:
                return {
                    imageSource: '/images/placeholders/modules/usage-main.svg',
                    description: 'UI.ModuleEmpty.usageDescriptionContent',
                    title: 'UI.ModuleEmpty.usageTitle',
                    documentationLbl: 'UI.ModuleEmpty.usageDocumentationLink',
                };
            default:
                return {
                    imageSource: '/images/glossary-main.png',
                    description: '',
                    title: '',
                };
        }
    }

    public async openImportModal() {
        this.functionalLogService.logFunctionalAction(
            this.getModuleFeatureCode(),
            CrudOperation.R
        );
        const importContext = new ImportContext(
            this.spaceIdr,
            ImportTarget.Entities,
            this.dgModule
        );
        importContext.onModalClosed = (isImportDone: boolean) => {
            if (isImportDone) {
                this.isEmptyModule = false;
            }
        };
        await this.importWizardService.openImportWizardModal(
            importContext,
            !this.isEmptyModule
        );
    }

    public hasImportAccess() {
        return this.moduleSecurityData.HasImportAccess;
    }

    public hasEntityCreationAccess() {
        return this.moduleSecurityData.HasCreateAccess;
    }

    public async openEntityCreationModal() {
        await this.entityUiService.openCreationModal(
            EntityCreationOrigin.globalAddButton
        );
    }

    public onClickDocumentationLink() {
        this.freshDeskService.openModuleArticle(this.dgModule);
    }

    public hasGlossaryAutoGenerationImport() {
        const hideGenerationBtn =
            this.dgModule === DgModule.Catalog ||
            this.dgModule === DgModule.Usage;
        return (
            !hideGenerationBtn &&
            this.hasEntityCreationAccess() &&
            [DgModule.Glossary, DgModule.Catalog, DgModule.Usage].includes(
                this.dgModule
            )
        );
    }

    public async openGlossaryAutoGenerationImportModal() {
        const result = await this.glossaryService.openGenerateFromSourceModal(
            this.spaceIdr,
            this.dgModule
        );
        if (result) {
            this.isEmptyModule = false;
        }
    }

    public getImportDataTrackerId() {
        const module = DgModule[this.dgModule]?.toLowerCase();
        return `open-import-modal-${module}`;
    }

    public getAreaSize(areaIndex: number, defaultValue: number) {
        return this.angularSplitService.getAreaSize(
            this.splitterPersistenceId,
            areaIndex,
            defaultValue
        );
    }

    private async initAsync() {
        this.isEntityFullPage = this.navigationService.isEntityFullPage;

        const showFilterBar =
            !this.isEntityFullPage && this.currentFilteredView?.hasSavedFilters;
        this.filterBarService.showHideFilterBar(this.dgZone, showFilterBar);

        await this.initCheckModuleEmptiness();
        this.modulesEntitiesCount =
            await this.entityService.getModulesEntitiesCountForGlossaryAutoGeneration(
                this.spaceIdr
            );
    }

    private getModuleFeatureCode(): string {
        switch (this.dgModule) {
            case DgModule.Glossary:
                return 'IMPORT_FROM_GLOSSARY_EMPTY';
            case DgModule.Processing:
                return 'IMPORT_FROM_DATA_PROCESSING_EMPTY';
            case DgModule.Usage:
                return 'IMPORT_FROM_USES_EMPTY';
            case DgModule.Catalog:
                return 'IMPORT_FROM_DICTIONARY_EMPTY';
        }
    }

    private subscribeEvents() {
        super.registerSubscriptions(
            this.appEventsService.entityFullPageChanged$.subscribe(
                (isNowFullPage) => (this.isEntityFullPage = isNowFullPage)
            ),
            this.filteredViewService.currentViewChanged$.subscribe(
                (e) => (this.currentFilteredView = e.filteredView)
            )
        );
    }

    private async initCheckModuleEmptiness() {
        const serverTypes = DataUtil.getServerTypesFromModule(this.dgModule);
        const res = await this.entityService.getEntitiesCount(
            this.spaceIdr,
            serverTypes
        );
        this.isEmptyModule = res.TotalCount === 0;
        if (this.isEmptyModule) {
            serverTypes.forEach((serverType) => {
                this.entityEventService.subscribeEntityCreation(
                    serverType,
                    () => (this.isEmptyModule = false)
                );
            });
        }
    }
}
interface IEmptyModuleDetails {
    imageSource: string;
    description: string;
    title: string;
    documentationLbl?: string;
}

import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DxyBaseModalComponent } from '@datagalaxy/ui/dialog';
import {
    IVersioningCreateModalResolve,
    IVersioningCreateModalResult,
} from '../versioning-create-modal.types';
import { ProjectVersionFieldSelectAdapter } from '../../shared/fields/field-select-adapters/ProjectVersionFieldSelectAdapter';

@Component({
    selector: 'dxy-versioning-create-modal',
    templateUrl: './dxy-versioning-create-modal.component.html',
})
export class DxyVersioningCreateModalComponent
    extends DxyBaseModalComponent<
        IVersioningCreateModalResolve,
        IVersioningCreateModalResult
    >
    implements OnInit
{
    public isActivateVersion: boolean;
    public readonly projectVersions = new ProjectVersionFieldSelectAdapter(() =>
        this.updateCanCreate()
    );
    public isSourceVersionFixed: boolean;
    public canCreate = false;
    public featureCode: string;

    public versionName: string;
    public versionDescription: string;

    private isMulti: boolean;

    constructor(
        dialogRef: MatDialogRef<
            DxyVersioningCreateModalComponent,
            IVersioningCreateModalResult
        >,
        @Inject(MAT_DIALOG_DATA) data: IVersioningCreateModalResolve
    ) {
        super(dialogRef, data);
    }

    ngOnInit() {
        const { sourceVersionId, projectVersions, isActivateVersion } =
            this.data;
        this.isActivateVersion = isActivateVersion;
        this.featureCode = isActivateVersion
            ? 'VERSION_ACTIVATE,C'
            : 'VERSION,C';
        this.isMulti = projectVersions.length > 1;
        this.projectVersions.init(projectVersions).selectById(sourceVersionId);
        this.isSourceVersionFixed = !!sourceVersionId;
        this.updateCanCreate();
    }

    public updateCanCreate() {
        this.canCreate = !!(
            this.versionName &&
            (!this.isMulti ||
                (this.isMulti &&
                    this.projectVersions.current?.ProjectVersionId))
        );
    }

    public onCloseSubmit() {
        this.result = {
            versionName: this.versionName,
            versionDescription: this.versionDescription,
            sourceProjectVersionId:
                this.projectVersions.current?.ProjectVersionId ?? null,
        };
        super.onCloseSubmit();
    }
}

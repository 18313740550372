import { NgModule } from '@angular/core';
import { NotificationPanelComponent } from './notification-panel/notification-panel.component';
import { NotificationItemComponent } from './notification-item/notification-item.component';
import { NotificationTriggerComponent } from './notification-trigger/notification-trigger.component';
import { NotificationToasterComponent } from './notification-toaster/notification-toaster.component';
import { SharedModule } from '../shared/shared.module';
import { DxySharedUiModule } from '../shared/shared-ui/DxySharedUiModule';
import { DxyNavigationModule } from '../navigation/DxyNavigationModule';
import { DxyActivityLogModule } from '../activityLog/DxyActivityLogModule';
import { DgEventTranslationModule } from '../dg-event-translation/dg-event-translation.module';

@NgModule({
    imports: [
        SharedModule,
        DxySharedUiModule,
        DxyNavigationModule,
        DxyActivityLogModule,
        DgEventTranslationModule,
    ],
    declarations: [
        NotificationPanelComponent,
        NotificationItemComponent,
        NotificationTriggerComponent,
        NotificationToasterComponent,
    ],
    exports: [NotificationTriggerComponent],
})
export class NotificationModule {}

import { selectAll as d3selectAll } from 'd3-selection';
import { LineageItem } from './LineageItem';
import { LineageLink } from './LineageLink';
import { SD3Items, SD3Links } from '../lineage.utils';

export class LineageTrack {
    public linkNodes = new Array<SVGGElement>();
    public itemNodes = new Array<SVGGElement>();
    public classed: string;
    public dirClass: string;
    public dirFilter: string;

    public get isEmpty() {
        return this.linkNodes.length == 0;
    }

    private readonly trackClass = ''; //LineageConstants.trackClass;

    constructor(
        public item: LineageItem,
        public node: SVGGElement,
        private debug = false
    ) {
        this.classed = `${this.trackClass} tu${this.item.id} td${this.item.id}`;
        this.itemNodes.push(node);
    }

    /** configures the track for adding links and items, with an upstream or downstream direction */
    public setDir(upstream: boolean) {
        const dir = `${upstream ? 'tu' : 'td'}${this.item.id}`;
        this.dirFilter = `:not(.${dir}):not(.hidden)`;
        this.dirClass = `${this.trackClass} ${dir}`;
    }

    public getD3Links() {
        return d3selectAll<SVGGElement, LineageLink>(this.linkNodes);
    }
    public getD3Items() {
        return d3selectAll<SVGGElement, LineageItem>(this.itemNodes);
    }

    public addItems(d3items: SD3Items) {
        const nodes = d3items.nodes();
        this.itemNodes.push(...nodes);
        if (this.debug) {
            this.log('addItems', nodes.length, this.itemNodes.length);
        }
    }
    public addLinks(d3links: SD3Links) {
        const nodes = d3links.nodes();
        this.linkNodes.push(...nodes);
        if (this.debug) {
            this.log('addLinks', nodes.length, this.linkNodes.length);
        }
    }

    private log(...args: any[]) {
        if (this.debug) {
            console.log((this.constructor as any).name, ...args);
        }
    }
}

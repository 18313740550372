import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
    CoreDialogUtil,
    DialogResult,
    DxyBaseModalComponent,
    IDialogButton,
    IModalDialogData,
    IModalDialogResult,
} from '@datagalaxy/ui/dialog';

@Component({
    selector: 'dxy-modal-dialog',
    templateUrl: './modal-dialog.component.html',
})
export class DxyModalDialogComponent
    extends DxyBaseModalComponent<IModalDialogData, IModalDialogResult>
    implements OnInit
{
    // Important: Do not rename any property (public or private) that exists in CoreDialogUtil.resolveFields

    //#region html bindings
    public buttons: IDialogButton[];
    public userInputValue: string;
    //#endregion

    constructor(
        dialogRef: MatDialogRef<DxyModalDialogComponent, IModalDialogResult>,
        @Inject(MAT_DIALOG_DATA) public data: IModalDialogData
    ) {
        super(dialogRef, data);
    }

    ngOnInit(): void {
        const d = this.data;
        this.log('ngOnInit', d);
        this.userInputValue = d?.userInputValue;
        this.buttons = CoreDialogUtil.getButtons(d);
    }

    public onCloseCancel() {
        this.closeDialog(DialogResult.Close);
    }

    public onKeyDownEnter(event: Event) {
        const dialogType = this.data?.type;
        const result = [DialogResult.Ok, DialogResult.Yes].find((dr) =>
            CoreDialogUtil.isButtonVisible(dr, dialogType)
        );
        const featureCode = this.buttons?.find(
            (b) => b.type == result
        )?.featureCode;
        this.log('onKeyDown-enter', DialogResult[result]);
        if (result == undefined) {
            return;
        }
        event.preventDefault();
        event.stopPropagation();
        this.closeDialog(result, featureCode, event);
    }

    public isBtnDisabled(btn: IDialogButton) {
        return this.isButtonDisabled(btn.type);
    }
    public onBtnClick(btn: IDialogButton, event: MouseEvent) {
        this.closeDialog(btn.type, btn.featureCode, event);
    }

    public getBtnDataTestId(btn: IDialogButton) {
        return `modal-type-${DialogResult[btn.type]?.toLowerCase()}-button`;
    }

    private isButtonDisabled(buttonType: DialogResult) {
        if (!this.data?.hasUserInput) {
            return false;
        }
        if (CoreDialogUtil.isCancelOrCloseOrNo(buttonType)) {
            return false;
        }
        const fnValidate = this.data?.userInputValidationMethod;
        if (!fnValidate || fnValidate(this.userInputValue)) {
            return false;
        }
        return true;
    }

    private closeDialog(
        dialogResult: DialogResult,
        featureCode?: string,
        event?: Event
    ) {
        this.log('closeDialog', DialogResult[dialogResult], featureCode, event);
        if (this.isButtonDisabled(dialogResult)) {
            return;
        }

        if (CoreDialogUtil.isCancelOrCloseOrNo(dialogResult)) {
            this.log('closeDialog-cancel');
            super.onCloseCancel();
        } else {
            this.result = {
                dialogResult,
                featureCode,
                origin: event.target as HTMLElement,
            };
            this.log('closeDialog-submit');
            super.onCloseSubmit();
        }
    }
}

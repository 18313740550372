import { Component, OnInit } from '@angular/core';
import { DxyBaseModalComponent } from '@datagalaxy/ui/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import {
    IUiImageInputParams,
    UiImageInputMode,
} from '@datagalaxy/core-ui/image-input';
import { SpaceHomeService } from '../space-home-dashboard/space-home.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { SpaceHomeHeaderForm } from './space-home-header-form';
import { SpaceHomeStateService } from '../space-home-dashboard/state/space-home-state.service';
import { CurrentSpaceService } from '../../services/currentSpace.service';
import { CoreUtil } from '@datagalaxy/core-util';

/**
 * ## Role
 * Display a space home header settings modal
 *
 * ## Features
 * - Allow to specify a welcome message
 * - Allow to hide the welcome message
 * - Allow to upload an image to be displayed on the home page header
 */
@Component({
    selector: 'app-space-home-header-edition-modal',
    templateUrl: './space-home-header-edition-modal.component.html',
    styleUrls: ['./space-home-header-edition-modal.component.scss'],
})
export class SpaceHomeHeaderEditionModalComponent
    extends DxyBaseModalComponent<void, void>
    implements OnInit
{
    protected form: FormGroup<SpaceHomeHeaderForm>;
    protected loading: boolean;
    protected searchImageInputParams: IUiImageInputParams = {
        mode: UiImageInputMode.modalEdit,
        editSize: 500,
        imageSize: 960,
        finalSize: 1920,
        upscale: true,
        forcedRatio: 1860 / 200,
        defaultImageUrl: this.spaceHomeService.getDefaultStaticImageUrl(),
        getImageUrl: () => this.spaceHomeService.getImageUrl(this.spaceHome),
        storeImage: (file) => this.onStoreImage(file),
        deleteImage: () => this.onDeleteImage(),
    };

    private imageFile: File;
    private imageDeleted = false;

    protected get titlePlaceholder() {
        return this.translate.instant('UI.SpaceHome.title', {
            spaceName: this.currentSpaceService.currentSpace?.DisplayName,
        });
    }
    protected get spaceHome() {
        return this.spaceHomeStateService.getSpaceHomePage();
    }

    constructor(
        dialogRef: MatDialogRef<SpaceHomeHeaderEditionModalComponent, void>,
        private translate: TranslateService,
        private spaceHomeService: SpaceHomeService,
        private spaceHomeStateService: SpaceHomeStateService,
        private currentSpaceService: CurrentSpaceService,
        private fb: FormBuilder
    ) {
        super(dialogRef, undefined);
    }

    ngOnInit() {
        this.init();
    }

    protected async onSubmit() {
        if (this.form.valid) {
            const fileContent = this.imageFile
                ? await CoreUtil.readAsDataUrl(this.imageFile)
                : null;
            this.spaceHomeStateService.updateHeader({
                ...this.form.value,
                image: {
                    FileContent: fileContent,
                    FileName: this.imageFile?.name,
                    FileType: this.imageFile?.type,
                    IsDelete: this.imageDeleted,
                },
            });
            this.onCloseCancel();
        }
    }

    private init() {
        this.form = this.fb.group<SpaceHomeHeaderForm>({
            title: new FormControl(this.spaceHome.Title),
            hideTitle: new FormControl(this.spaceHome.IsTitleDisabled ?? false),
        });
    }

    private async onStoreImage(file: File) {
        this.imageFile = file;
        return true;
    }

    private async onDeleteImage() {
        this.imageFile = null;
        if (this.spaceHome.ImageHash) {
            this.imageDeleted = true;
        }
        return true;
    }
}

import { ServerType } from '@datagalaxy/dg-object-model';
import { DataIdentifier } from '@datagalaxy/dg-object-model';
import { HierarchyDataDescriptor } from '@datagalaxy/dg-object-model';
import { EntityTypeUtil } from '@datagalaxy/dg-object-model';
import { DataProcessingItemDto } from '@datagalaxy/webclient/data-processing/data-access';
import { EntityIdentifier } from '@datagalaxy/webclient/entity/utils';
import { generateGuid } from '@datagalaxy/utils';

export class GraphicalEntityData extends DataIdentifier {
    public Parents: HierarchyDataDescriptor[];
    public GraphicalUniqueId: string;
    public DisplayName: string;
    public TechnicalName: string;
    public SubTypeName?: string;
    public ParentReferenceId?: string;
    public clientUid: string;
    public versionId?: string;
    public hddData?: HierarchyDataDescriptor;
    public TechnologyCode?: string;

    public get EntityReferenceId() {
        return this.DataReferenceId;
    }

    public get entityType() {
        return EntityTypeUtil.getEntityType(
            this.DataTypeName,
            this.SubTypeName
        );
    }

    public static fromDataProcessingItemDto(item: DataProcessingItemDto) {
        const ged = new GraphicalEntityData();
        ged.DataReferenceId = item.ReferenceId;
        ged.DisplayName = item.DisplayName;
        ged.TechnicalName = item.DisplayName;
        ged.DataTypeName = ServerType[ServerType.DataProcessingItem];
        return ged;
    }

    public constructor(
        hddData?: HierarchyDataDescriptor,
        parents?: HierarchyDataDescriptor[],
        technologyCode?: string
    ) {
        super(hddData?.DataReferenceId ?? null, hddData?.DataTypeName ?? null);
        this.clientUid = generateGuid();

        if (!hddData) {
            return;
        }

        this.Parents = parents;
        this.DataReferenceId = hddData.DataReferenceId;
        this.DataTypeName = hddData.DataTypeName;
        this.DisplayName = hddData.DisplayName;
        this.TechnicalName = hddData.TechnicalName;
        this.SubTypeName = hddData.SubTypeName;
        this.GraphicalUniqueId = this.DataReferenceId;
        this.versionId = hddData.VersionId;
        this.hddData = hddData;
        this.TechnologyCode = technologyCode;

        if (!parents?.length) {
            return;
        }

        const parentTypes =
            hddData.DataServerType == ServerType.Table
                ? [ServerType.Model]
                : [ServerType.Table, ServerType.Model];

        const firstParent = this.getDisplayableContainerParent(parentTypes);
        if (firstParent) {
            this.ParentReferenceId = firstParent.DataReferenceId;
        }
    }

    public getDisplayableContainerParent(parentTypes: ServerType[]) {
        return this.Parents.find((p) =>
            parentTypes.some((pt) => p.DataServerType == pt)
        );
    }

    public toEntityIdentifier() {
        const entityType = EntityTypeUtil.getEntityType(
            this.DataTypeName,
            this.SubTypeName
        );
        return (
            this &&
            new EntityIdentifier(
                this.EntityReferenceId,
                this.versionId,
                entityType
            )
        );
    }
}

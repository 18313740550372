import { Component, Input, Output, EventEmitter } from '@angular/core';
import { DxyBaseComponent } from '@datagalaxy/ui/core';

@Component({
    selector: 'dxy-modal-footer',
    templateUrl: './dxy-modal-footer.component.html',
    styleUrls: ['dxy-modal-footer.component.scss'],
})
export class DxyModalFooterComponent extends DxyBaseComponent {
    @Input() actionBtnLbl: string;
    @Input() actionEnabled: boolean;
    @Input() actionLoading: boolean;
    @Input() featureCode: string;
    /** if true, the '* mandatory' text is not displayed */
    @Input() noMandatory: boolean;
    @Input() featureCodeSecond: string;

    @Input() showCreateAnother: boolean;
    @Input() isCheckedCreateAnother: boolean;
    @Output() onChangeCreateAnother = new EventEmitter<void>();

    @Output() onCloseCancel = new EventEmitter();
    @Output() onActionPointerDown = new EventEmitter<PointerEvent>();

    public get disabled() {
        return !this.actionEnabled || this.actionLoading;
    }

    constructor() {
        super();
    }

    public getDtName() {
        if (!this.featureCode) {
            return;
        }
        const actionName = this.featureCode.endsWith('C')
            ? 'Creation'
            : 'Update';
        const featureCode = this.featureCode.split(',')[0];
        return `${actionName} Modal - ${featureCode}`;
    }

    public actionPointerDown($event: PointerEvent) {
        if (this.disabled) {
            return;
        }
        this.onActionPointerDown.emit($event);
    }
}

import * as _ from 'lodash';
import { CoreUtil } from './core-util';

export class CollectionsHelper {
    /** getValue can be: Function(s) returning number or text, or property name(s). Default order is ascending */
    static orderBy<T>(
        data: T[],
        getValue?: OneOrMany<string | ((o: T) => number | string | boolean)>,
        order?: OneOrMany<'asc' | 'desc'>
    ) {
        return _.orderBy(data, getValue, order);
    }

    static orderByText<T>(
        data: T[],
        getText: (o: T) => string,
        order: 'asc' | 'desc' = 'asc',
        caseSensitive = false,
        accentSensitive = false
    ) {
        return _.orderBy(
            data,
            (o: T) => {
                let text = getText(o).trimLeft();
                if (!accentSensitive) {
                    text = _.deburr(text);
                }
                if (!caseSensitive) {
                    text = text.toUpperCase();
                }
                return text;
            },
            order
        );
    }

    /** returns a new array containing the given data, sorted using the given property's value as a string, case and accent insensitive */
    static alphaSort<T>(data: T[], propertyName: keyof T) {
        return CollectionsHelper.orderBy(data, (o) =>
            o[propertyName] == undefined
                ? ''
                : _.deburr(
                      String(o[propertyName])
                          .trimLeft()
                          .replace(/_+/g, ' ')
                          .toUpperCase()
                  )
        );
    }

    /** returns true if *data* includes any of the given values */
    static includesAny<T>(data: T[], ...values: T[]) {
        return data?.length && values.some((v) => data.includes(v));
    }

    /** returns true if *data* includes all of the given values, and no other */
    static includesAllAndOnly<T>(data: T[], ...values: T[]) {
        return data?.length && data.every((v) => values.includes(v));
    }

    static hasAny<T>(data: T[], predicate: (o: T) => boolean) {
        return data?.length && data.some(predicate);
    }

    static count<T>(data: T[], predicate: (o: T) => boolean) {
        return (
            (data?.length &&
                data.reduce((p, c) => (predicate(c) ? p + 1 : p), 0)) ||
            0
        );
    }

    static sum<T>(data: T[], getValue: (o: T) => number) {
        return (
            (data?.length &&
                data.reduce((p, c) => p + (getValue(c) || 0), 0)) ||
            0
        );
    }

    /** returns the smallest value returned by the given *getValue* on each element of the given *data* */
    static minValue<T>(data: T[], getValue: (o: T) => number) {
        return !data || !data.length
            ? undefined
            : data.length == 1
            ? getValue(data[0])
            : data.reduce((p, c, i) => {
                  if (i == 0) {
                      return p;
                  }
                  const v = getValue(c);
                  return v < p ? v : p;
              }, getValue(data[0]));
    }

    /** returns the biggest value returned by the given *getValue* on each element of the given *data* */
    static maxValue<T>(data: T[], getValue: (o: T) => number) {
        return !data || !data.length
            ? undefined
            : data.length == 1
            ? getValue(data[0])
            : data.reduce((p, c, i) => {
                  if (i == 0) {
                      return p;
                  }
                  const v = getValue(c);
                  return v > p ? v : p;
              }, getValue(data[0]));
    }
    /** @deprecated Use `Math.min(...data)` */
    static min(data: number[]) {
        return _.min(data);
    }
    /** @deprecated Use `Math.max(...data)` */
    static max(data: number[]) {
        return _.max(data);
    }

    /** difference([1,2], [1,3]) = [2] */
    static difference<T>(data: T[], collection2: T[]) {
        return _.difference(data, collection2);
    }

    static getAddedAndRemoved<T>(before: T[], after: T[]) {
        const added = CollectionsHelper.difference(after, before);
        const removed = CollectionsHelper.difference(before, after);
        return {
            added,
            removed,
            any: added?.length > 0 || removed?.length > 0,
        };
    }

    /** returns true if elements in a group are not the other */
    static anyDifference<T>(data1: T[], data2: T[]) {
        return (
            data2?.length != data1?.length ||
            CollectionsHelper.getAddedAndRemoved(data1, data2).any
        );
    }

    static getFromFirst<D extends object, V>(
        data: D[],
        predicate: (o: D) => boolean,
        getValue: (o: D) => V
    ) {
        const found = data && data.find(predicate);
        return found && getValue(found);
    }
    /** executes the given function on the first of the given data that matches the given predicate, and returns the result.
     * If no data matches the predicate, the function is not executed and undefined is returned */
    static withFirstFound<TData, TResult>(
        data: TData[],
        predicate: (o: TData) => boolean,
        withFound: (o: TData) => TResult
    ) {
        const found = data?.find(predicate);
        if (found != undefined) {
            return withFound(found);
        }
    }

    /** returns null if more than one or no item matches the predicate, otherwise returns the matching item */
    static findUnique<T>(data: T[], predicate: (o: T) => boolean) {
        const items = data && data.filter(predicate);
        return items && items.length == 1 ? items[0] : null;
    }

    static all<T>(data: T[], predicate: (o: T) => boolean) {
        return data?.every(predicate);
    }

    static none<T>(data: T[], predicate: (o: T) => boolean) {
        return !data || !data.some(predicate);
    }

    /** returns true if both given arrays have the same values,
     * optionally in the same order */
    static contentEquals<T>(
        a1: T[],
        a2: T[],
        sameOrder = false,
        bothEmptyIsEquals = true,
        equals = (v1: T, v2: T, _i1?: number, _i2?: number) =>
            CoreUtil.areEqual(v1, v2)
    ) {
        if (!a1?.length && !a2?.length) {
            return !!bothEmptyIsEquals;
        }
        if (!a1 || !a2 || a1.length != a2.length) {
            return false;
        }
        return sameOrder
            ? a1.every((v1, i) => equals(v1, a2[i], i))
            : a1.every((v1, i1) =>
                  a2.some((v2, i2) => equals(v1, v2, i1, i2))
              ) &&
                  a2.every((v2, i2) =>
                      a1.some((v1, i1) => equals(v2, v1, i2, i1))
                  );
    }

    static asArray<T>(o: T | T[]) {
        return Array.isArray(o) ? o : [o];
    }

    /** returns a new array of items having a value in *values*, or all items if no values */
    static filterByIncludedValue<TItem, TValue>(
        items: TItem[],
        getValue: (it: TItem) => TValue,
        values: TValue[],
        allIfNoValues = true
    ) {
        if (!items) {
            return;
        }
        if (!values?.length) {
            return allIfNoValues ? items.slice() : [];
        }
        return items.filter((d) => values.includes(getValue(d)));
    }

    /** return the list of values that are the intersection of all the arrays. Each value in the result is present in each of the arrays. */
    static getArraysCommonValues<TItem>(...items: TItem[][]): TItem[] {
        return _.intersection(...items);
    }

    /** Returns a non empty array, stripped of undefined and null values, or undefined. The given array is not modified */
    static strip<TIn, TOut = TIn>(
        input: ArrayLike<TIn> | Iterable<TIn>,
        map?: (o: TIn) => TOut,
        filter?: (o: TOut) => boolean
    ) {
        const a = input
            ? Array.isArray(input)
                ? input
                : Array.from(input)
            : undefined;
        if (!a?.length) {
            return;
        }
        const ma = map ? a.map(map) : (a as TOut[]);
        const fa = ma.filter((o) => o != undefined);
        if (!fa.length) {
            return;
        }
        const r = filter ? fa.filter(filter) : fa;
        return r.length ? r : undefined;
    }

    //#region Map

    static arrayToMap<TData, TMapKey>(
        data: TCollection<TData>,
        getMapKey: (o: TData, i: number) => TMapKey
    ) {
        const map = new Map<TMapKey, TData>();
        const array = CollectionsHelper.toArray(data);
        if (!array?.length) {
            return map;
        }
        array.forEach((o, i) => map.set(getMapKey(o, i), o));
        return map;
    }
    static objArrayToMap<TData, TMapKey, TMapValue>(
        data: TCollection<TData>,
        getMapKey: (o: TData, i: number) => TMapKey,
        getMapValue: (o: TData, i: number) => TMapValue
    ) {
        const map = new Map<TMapKey, TMapValue>();
        const array = CollectionsHelper.toArray(data);
        if (!array?.length) {
            return map;
        }
        array.forEach((o, i) => map.set(getMapKey(o, i), getMapValue(o, i)));
        return map;
    }

    /** returns an array of { key, val } objects */
    static mapToKeyValArray<K, V>(
        map: Map<K, V>,
        adaptVal: (val: V) => unknown = (val: V) => val,
        adaptKey: (key: K) => unknown = (key: K) => key
    ) {
        return (
            map &&
            Array.from(map.entries()).map((e) => ({
                key: adaptKey(e[0]),
                val: adaptVal(e[1]),
            }))
        );
    }

    /** returns an array containing the values of the entries that match the given predicate */
    static filterMap<TKey, TData>(
        map: Map<TKey, TData>,
        predicate: (v: TData, k: TKey) => boolean
    ) {
        const res = new Array<TData>();
        map.forEach((v, k) => {
            if (predicate(v, k)) {
                res.push(v);
            }
        });
        return res;
    }

    /** returns the value of the first entry that matches the given predicate */
    static findFirstInMap<TKey, TData>(
        map: Map<TKey, TData>,
        predicate: (v: TData, k: TKey) => boolean
    ) {
        for (let kv of map.entries()) {
            if (predicate(kv[1], kv[0])) {
                return kv[1];
            }
        }
    }

    /** executes the given action on each element of the given map that matches the given predicate.
     * If action_stop returns a truthy value then the iteration is stopped */
    static withMap<TKey, TData>(
        map: Map<TKey, TData>,
        predicate: (v: TData, k: TKey) => boolean,
        action_stop: (v: TData, k: TKey) => void | boolean
    ) {
        for (let kv of map.entries()) {
            const k = kv[0],
                v = kv[1];
            if (predicate(v, k)) {
                if (action_stop(v, k)) {
                    return;
                }
            }
        }
    }
    /** returns the value of the first entry of the given map */
    static getFirstValueInMap<TKey, TData>(map: Map<TKey, TData>) {
        return map.values().next().value;
    }

    static mapToMap<TKeyIn, TValueIn, TKeyOut, TValueOut>(
        map: Map<TKeyIn, TValueIn>,
        predicate: (v: TValueIn, k: TKeyIn) => boolean,
        getKey: (v: TValueIn, k: TKeyIn) => TKeyOut,
        getValue: (v: TValueIn, k: TKeyIn) => TValueOut
    ) {
        const res = new Map<TKeyOut, TValueOut>();
        map.forEach((v, k) => {
            if (predicate(v, k)) {
                res.set(getKey(v, k), getValue(v, k));
            }
        });
        return res;
    }

    static objArrayToMapOfArrays<TArrayData, TMapKey, TMapValue>(
        data: TArrayData[],
        getMapKey: (o: TArrayData, i: number) => TMapKey,
        getMapValue: (o: TArrayData, i: number) => TMapValue,
        skipUndefinedKeys = false,
        distinctValues = false
    ) {
        const map = new Map<TMapKey, TMapValue[]>();
        data?.forEach((o, i) => {
            if (o == undefined) {
                return;
            }
            const k = getMapKey(o, i);
            if (k == undefined && skipUndefinedKeys) {
                return;
            }
            const v = getMapValue(o, i);
            if (map.has(k)) {
                const list = map.get(k);
                if (distinctValues && list.includes(v)) {
                    return;
                }
                list.push(v);
            } else {
                map.set(k, [v]);
            }
        });
        return map;
    }

    //#endregion

    /** returns true if data is empty or falsy */
    static isEmpty<T>(data: Array<T> | Set<T> | Map<unknown, T>) {
        return !data
            ? true
            : Array.isArray(data)
            ? data.length == 0
            : data.size == 0;
    }

    /** returns the source array filtered from *except* elements.
     * - *forceArrayCopy*: Always return a copy of the array, even if *except* is null or undefined */
    static except<T>(
        source: Array<T>,
        except: Array<T>,
        forceArrayCopy = false
    ) {
        return source
            ? except
                ? source.filter((s) => !except.includes(s))
                : forceArrayCopy
                ? source.slice()
                : source
            : source;
    }

    public static filterDistinct<T>(value: T, index: number, array: T[]) {
        return array.indexOf(value) === index;
    }

    private static compareByIndex<T>(a: T, b: T, orderedArray: T[]) {
        const indexA = orderedArray.indexOf(a);
        const indexB = orderedArray.indexOf(b);

        if (indexA === -1 && indexB === -1) {
            return 0;
        } else if (indexB === -1) {
            return -1;
        } else if (indexA === -1) {
            return 1;
        }
        return indexA - indexB;
    }

    public static sortByIndexOf<TData, TValue>(
        orderedArray: TValue[],
        getValue: (o: TData) => TValue
    ): (a: TData, b: TData) => number;
    public static sortByIndexOf<T>(orderedArray: T[]): (a: T, b: T) => number;
    public static sortByIndexOf<TData, TValue>(
        orderedArray: TData[] | TValue[],
        getValue?: (o: TData) => TValue
    ) {
        return getValue
            ? (a: TData, b: TData) =>
                  CollectionsHelper.compareByIndex(
                      getValue(a),
                      getValue(b),
                      orderedArray as TValue[]
                  )
            : (a: TData, b: TData) =>
                  CollectionsHelper.compareByIndex(
                      a,
                      b,
                      orderedArray as TData[]
                  );
    }

    /** returns the given array,
     * or an array made from the given iterable,
     * or the result of the given object's *toArray* method */
    static toArray<T>(data: TToArray<T>) {
        return data
            ? Array.isArray(data)
                ? (data as T[])
                : (data as IHasToArray<T>).toArray?.() ??
                  Array.from(data as TCollection<T>)
            : (data as T[]);
    }

    static distinct<T>(data: TCollection<T>) {
        const array = CollectionsHelper.toArray(data);
        return [...new Set(array)];
    }
    static distinctValues<TDatum, TValue>(
        data: TCollection<TDatum>,
        getValue: (o: TDatum) => TValue
    ) {
        return CollectionsHelper.toArray(data)
            ?.map(getValue)
            .filter(CollectionsHelper.filterDistinct);
    }

    static distinctByProperty<TDatum, TKey>(
        data: TCollection<TDatum>,
        getKey: (o: TDatum) => TKey
    ) {
        const map = new Map<TKey, TDatum>();
        CollectionsHelper.toArray(data)?.forEach((o) => map.set(getKey(o), o));
        return Array.from(map.values());
    }

    /** returns the concatenated values obtained by getItem on each datum */
    static flattenGroups<TGroup, TItem>(
        data: TCollection<TGroup>,
        getItems: (o: TGroup, groupIndex: number) => TItem[],
        distinct = false,
        removeFalsyValues = false
    ) {
        return CollectionsHelper.flatten(
            CollectionsHelper.toArray(data)?.map(getItems),
            distinct,
            removeFalsyValues
        );
    }
    /** returns one array made of the concatenated given arrays */
    static flatten<T>(
        data: TCollection<T[]>,
        distinct = false,
        removeFalsyValues = false
    ) {
        let result = CollectionsHelper.toArray(data)?.reduce(
            CollectionsHelper.concatInternal,
            []
        );
        if (!result) {
            return result;
        }
        if (removeFalsyValues) {
            result = result.filter((o) => o);
        }
        if (distinct) {
            result = CollectionsHelper.distinct(result);
        }
        return result;
    }
    private static concatInternal<T>(prev: T[], curr: T[]) {
        if (curr?.length) {
            prev.push(...curr);
        }
        return prev;
    }

    /** returns concatenated arrays removing null values */
    static concat<T>(...arrays: (T[] | null)[]) {
        return [].concat(...arrays.filter(Array.isArray));
    }

    static groupBy<TDatum, TGroupKey, TGroup>(
        data: TCollection<TDatum>,
        getGroupKey: (o: TDatum) => TGroupKey,
        makeGroup: (k: TGroupKey, items: TDatum[]) => TGroup,
        resultGroupKeys?: unknown[]
    ) {
        const array = CollectionsHelper.toArray(data);
        const groups = new Map<TGroupKey, TDatum[]>();

        array?.forEach((item) => {
            const key = getGroupKey(item);
            const group = groups.get(key);

            if (!group) {
                groups.set(key, [item]);
            } else {
                group.push(item);
            }
        });

        resultGroupKeys?.push(...groups.keys());

        return Array.from(groups, ([key, items]) => makeGroup(key, items));
    }

    static groupToMap<TDatum, TGroupKey>(
        data: TCollection<TDatum>,
        getGroupKey: (o: TDatum) => TGroupKey
    ) {
        const array = CollectionsHelper.toArray(data);
        const groupKeys = array
            ?.map(getGroupKey)
            .filter(CollectionsHelper.filterDistinct);
        const map = new Map<TGroupKey, TDatum[]>();
        groupKeys?.forEach((k) =>
            map.set(
                k,
                array.filter((o) => getGroupKey(o) == k)
            )
        );
        return map;
    }

    /** Replaces elements in the specified array when getReplacement does not return null nor undefined.
     * Returns the elements that have been replaced. */
    static replace<T>(data: T[], getReplacement: (old: T, index: number) => T) {
        const replaced = new Array<T>();
        data?.forEach((o, i) => {
            const n = getReplacement(o, i);
            if (n == undefined) {
                return;
            }
            replaced.push(data[i]);
            data[i] = n;
        });
        return replaced;
    }

    /** Replace the first element in the given array that matches the given predicate, by the given element.
     * Returns the given array. */
    static replaceOne<T>(
        data: T[],
        predicate: (o: T, i: number) => boolean,
        replaceBy: T
    ) {
        if (!data?.length) {
            return data;
        }
        const i = data.findIndex(predicate);
        if (i != -1) {
            data[i] = replaceBy;
        }
        return data;
    }

    /** Updates the first occurrence. If none was found, add a new element to the given array at position 0.
     * Returns the given array. */
    static replaceOrInsertFirst<T>(
        data: T[],
        predicate: (el: T) => boolean,
        replaceBy: T
    ) {
        if (!data) {
            return data;
        }
        const targetIndex = data.findIndex(predicate);
        if (targetIndex != -1) {
            data[targetIndex] = replaceBy;
        } else {
            data.unshift(replaceBy);
        }
        return data;
    }

    /** Updates the first occurrence. If none was found, add a new element to the given array.
     * Returns the given array. */
    static replaceOrAppend<T>(
        data: T[],
        predicate: (el: T) => boolean,
        replaceBy: T
    ) {
        if (!data) {
            return data;
        }
        const targetIndex = data.findIndex(predicate);
        if (targetIndex != -1) {
            data[targetIndex] = replaceBy;
        } else {
            data.push(replaceBy);
        }
        return data;
    }

    /** Removes elements in the specified array.
     * Returns the removed elements.
     * If predicate is a number, removes the first n elements */
    static remove<T>(
        data: T[],
        predicate: ((old: T, index: number) => boolean) | number
    ) {
        if (!data) {
            return [];
        }
        if (typeof predicate != 'function') {
            return data.splice(0, predicate);
        }

        const reversedIds = new Array<number>(),
            removed = new Array<T>();
        data.forEach((o, i) => {
            if (predicate(o, i)) {
                reversedIds.unshift(i);
            }
        });
        reversedIds.forEach((i) => removed.unshift(...data.splice(i, 1)));
        return removed;
    }
    /** Returns the modified given array, after removing the given element, if found */
    static removeElement<T>(data: T[], element: T) {
        if (!data) {
            return data;
        }
        const i = data.indexOf(element);
        if (i != -1) {
            data.splice(i, 1);
        }
        return data;
    }
    /** Returns the modified given array, after removing the given elements, when found */
    static removeElements<T>(data: T[], elements: T[]) {
        if (!data || !elements) {
            return data;
        }
        elements.forEach((e) => {
            const i = data.indexOf(e);
            if (i != -1) {
                data.splice(i, 1);
            }
        });
        return data;
    }

    /**
     * Creates an array with elements from data that does not already exist in candidates.
     * Does not modify the given array
     */
    static filterOutOccurences<T>(
        data: T[],
        predicate: (a: T, b: T) => boolean,
        ...candidates: T[]
    ): T[] {
        return data?.filter(
            (el1) => !candidates.some((el2) => predicate(el1, el2))
        );
    }

    /** Creates a slice of array with n elements dropped from the beginning.
     *  Does not modify the given array */
    static drop<T>(data: T[], n: number): T[] {
        return _.drop(data, n);
    }

    /** removes the specified object if it is in the specified array, else adds it. returns the modified array */
    static toggle<T>(
        data: T[],
        obj: T,
        equals: (a: T, b: T) => boolean = (a, b) => a === b
    ) {
        const i = data ? data.findIndex((o) => equals(o, obj)) : -1;
        if (i == -1) {
            data.push(obj);
        } else data.splice(i, 1);
        return data;
    }

    /** removes the first element matching the given predicate and, if found, calls the given function.
     * Returns the index or -1 */
    static withRemoved<T>(
        data: T[],
        find: (o: T) => boolean,
        withRemoved: (o: T, index?: number) => void
    ) {
        const index = data ? data.findIndex(find) : -1;
        if (index > -1) {
            const found = data[index];
            data.splice(index, 1);
            withRemoved(found, index);
        }
        return index;
    }
    /** removes the first element matching the given predicate and returns it */
    static getFirstRemoved<T>(data: T[], find: (o: T) => boolean) {
        let removed: T;
        CollectionsHelper.withRemoved(data, find, (found) => (removed = found));
        return removed;
    }

    /** Remove the first element matching the given predicate */
    static removeOne<T>(data: T[], find: (o: T) => boolean) {
        const index = data?.findIndex(find);
        if (index > -1) {
            data.splice(index, 1);
        }
    }

    /** append the 'more' elements matching the given predicate to the 'data' array. Returns the data array */
    static appendIf<T>(
        data: T[],
        more: T[],
        predicate: (m: T, d: T) => boolean
    ) {
        if (data && more?.length) {
            data.push(
                ...more.filter(
                    (m) => data.findIndex((d) => predicate(m, d)) == -1
                )
            );
        }
        return data;
    }
    /** append the 'more' elements to the 'data' array if they are not already in it. Returns the data array */
    static appendIfNotExist<T>(
        data: T[],
        more: T[],
        equals?: (m: T, d: T) => boolean
    ) {
        if (equals) {
            return CollectionsHelper.appendIf(data, more, equals);
        }
        if (data && more?.length) {
            data.push(...more.filter((m) => !data.includes(m)));
        }
        return data;
    }

    /** returns the *last* index, or -1.
     *  For first index, use data.find(predicate) */
    static findLastIndex<T>(data: T[], predicate: (o: T) => boolean) {
        if (!data?.length) {
            return -1;
        }
        for (let i = data.length - 1; i >= 0; i--) {
            if (predicate(data[i])) {
                return i;
            }
        }
        return -1;
    }

    /** returns { matches: T[], others: T[] } */
    static split<T>(data: T[], predicate: (o: T) => boolean) {
        if (!data) {
            return;
        }

        const matches = data.filter(predicate);
        return { matches, others: data.filter((o) => !matches.includes(o)) };
    }

    static moveItemInArray<T>(
        data: T[],
        indexBefore: number,
        newIndex: number
    ) {
        if (indexBefore < 0 || newIndex < 0) {
            return;
        }
        data.splice(newIndex, 0, data.splice(indexBefore, 1)[0]);
    }

    /** filters the given array
     * - *noNullOrUndefined*: Remove values which are null or undefined
     * - *forceArrayCopy*: Always return a copy of the array, even if no predicate is given */
    static filter<T>(
        data: TCollection<T>,
        predicate?: (o: T) => boolean,
        noNullOrUndefined = false,
        forceArrayCopy = false
    ) {
        const array = CollectionsHelper.toArray(data);
        return array
            ? predicate
                ? noNullOrUndefined
                    ? array.filter((o) => o != undefined && predicate(o))
                    : array.filter(predicate)
                : forceArrayCopy
                ? noNullOrUndefined
                    ? array.filter((o) => o != undefined)
                    : array.slice()
                : array
            : undefined;
    }

    //#region enums

    /** To be used with *number* enum only.
     * Returns an array containing the values of the specified enumeration */
    static getEnumValues<TEnum>(E: unknown, ...exceptValues: TEnum[]) {
        const keys = Object.keys(E).filter(
            (k) => typeof E[k as string | number] === 'number'
        );
        const values = keys.map((k) => E[k]) as TEnum[];
        if (!exceptValues.length) {
            return values;
        }
        return values.filter((v) => !exceptValues.some((ev) => v === ev));
    }

    /**
     * Converts an enum type to an object with enum values as keys
     * and their corresponding string values as values.
     * @param enumType - The enum type to convert.
     * @returns An object with enum values as keys and their string values as values.
     * @example
     * ```typescript
     * enum MyEnum {
     *   Value1 = 'FirstValue',
     *   Value2 = 'SecondValue',
     *   Value3 = 'ThirdValue',
     * }
     *
     * const myEnumObject = enumToObject(MyEnum);
     * console.log(myEnumObject);
     * // Output: { Value1: 'FirstValue', Value2: 'SecondValue', Value3: 'ThirdValue' }
     * ```
     */
    static getEnumValuesMapping(enumType: any): { [key: string]: string } {
        const result: { [key: string]: string } = {};

        for (const key in enumType) {
            if (typeof enumType[key] === 'string') {
                result[key] = enumType[key];
            }
        }

        return result;
    }

    /** To be used with *number* enum only.
     * Returns an array containing the names of the values of the specified enumeration */
    static getEnumValueNames<TEnum>(E: unknown, ...exceptValues: TEnum[]) {
        return CollectionsHelper.getEnumValues(E, ...exceptValues).map(
            (v) => E[v as string | number]
        ) as string[];
    }

    //#endregion

    //#region strings
    //TODO: use Intl.Collator
    static containSameStrings(
        a1: string[],
        a2: string[],
        sameOrder?: boolean,
        caseSensitive?: boolean,
        bothEmptyIsEquals?: boolean,
        nullEqualsEmpty?: boolean
    ) {
        a1 = caseSensitive ? a1 : a1?.map((v) => v?.toUpperCase());
        a2 = caseSensitive ? a2 : a2?.map((v) => v?.toUpperCase());
        a1 = nullEqualsEmpty ? a1?.map((v) => v ?? '') : a1;
        a2 = nullEqualsEmpty ? a2?.map((v) => v ?? '') : a2;
        return CollectionsHelper.contentEquals(
            a1,
            a2,
            sameOrder,
            bothEmptyIsEquals
        );
    }
    //#endregion
}
type OneOrMany<T> = T | T[];
export type TToArray<T> = ArrayLike<T> | Iterable<T> | IHasToArray<T>;
export type TCollection<T> = ArrayLike<T> | Iterable<T>;
export interface IHasToArray<T> {
    toArray(): T[];
}

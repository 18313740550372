import { CoreUtil, DomUtil } from '@datagalaxy/core-util';
import { GUI } from 'dat.gui';
import {
    IGUI,
    IOptionsToggle,
    IOptionsEnumValues,
    IGUIController,
    IOptions,
} from './dat-gui-util.types';

/** dat.gui helpers for function, toggle, enums */
export class DatGuiUtil {
    private static _initDone: boolean;

    /** patches dat.GUI to extend IGUI */
    public static init() {
        if (DatGuiUtil._initDone) {
            return;
        }
        DatGuiUtil._initDone = true;

        const p = GUI.prototype as IGUI;

        p.addFunction = function (name: string, func: () => void) {
            return DatGuiUtil.function(func, this, name);
        };

        p.addToggle = function (
            name: string,
            toggle: (request: boolean) => Promise<boolean>,
            options?: IOptionsToggle
        ) {
            return DatGuiUtil.toggle(name, this, toggle, options);
        };

        p.addEnumSelect = function <TEnum>(
            name: string,
            select: (request: TEnum) => Promise<TEnum>,
            options?: IOptionsEnumValues<TEnum>
        ) {
            return DatGuiUtil.enumSelect(name, this, select, options);
        };
    }

    public static async create(
        className?: string,
        container?: Element,
        positionTopRight = false,
        open = false,
        load?: object
    ) {
        const gui = new GUI({
            autoPlace: container == undefined,
            closed: !open,
            load,
        }) as IGUI;

        let guiContainer: Element;
        if (positionTopRight) {
            guiContainer = DomUtil.createElement(
                'div',
                'dat-gui-container-top-right'
            );
            container?.appendChild(guiContainer);
        } else {
            guiContainer = container;
        }

        guiContainer?.appendChild(gui.domElement);

        if (className) {
            await CoreUtil.startTimeout(() => {
                const main =
                    (container === guiContainer ? null : guiContainer) ??
                    gui.domElement.closest('.dg.ac') ??
                    gui.domElement;
                main.classList.add(className);
            });
        }

        return gui;
    }

    public static openCloseAll = (gui: IGUI, open: boolean) => {
        const rootGui = gui.getRoot();
        const openClose = (g: IGUI) => {
            Object.keys(g.__folders).forEach((k) => openClose(g.__folders[k]));
            open ? g.open() : g != rootGui && g.close();
        };
        openClose(gui);
    };

    public static function(f: () => void, parent: IGUI, name: string) {
        return parent.add({ [name]: f }, name) as IGUIController;
    }

    public static toggle(
        name: string,
        parent: IGUI,
        toggle: (request: boolean) => Promise<boolean>,
        opt: IOptionsToggle
    ) {
        const o = { [name]: opt?.initialValue ?? false };
        const gui = parent.add(o, name);
        gui.onChange((request: boolean) =>
            toggle(request).then((newValue) => {
                if (newValue == undefined || newValue == request) {
                    return;
                }
                o[name] = newValue;
                opt?.onChange?.(newValue);
                opt?.update?.(name, newValue);
            })
        );
        return gui as IGUIController;
    }

    public static enumSelect<TEnum>(
        name: string,
        parent: IGUI,
        select: (request: TEnum) => Promise<TEnum>,
        opt: IOptionsEnumValues<TEnum>
    ) {
        const enumStringValues: string[] = opt.values.map(
            (v) => opt.enumType[v]
        );
        const initialValue =
            opt?.initial == undefined
                ? enumStringValues[0]
                : (opt.enumType[opt.initial] as string);
        const o = { [name]: initialValue };
        const gui = parent.add(o, name, enumStringValues);
        gui.onChange((enumStringValue: string) => {
            const enumValue: TEnum = opt.enumType[enumStringValue];
            select(enumValue).then((newValue) => {
                if (newValue == undefined || newValue == enumValue) {
                    return;
                }
                const newStringValue = opt.enumType[
                    newValue as unknown as number
                ] as string;
                o[name] = newStringValue;
                opt?.onChange?.(newValue);
                opt?.update?.(name, newValue);
            });
        });
        return gui as IGUIController;
    }

    protected static makeOptions<T>(obj: T, opt: IOptions<any>, name: string) {
        return !opt
            ? undefined
            : {
                  listen: opt.listen,
                  reset: opt.reset,
                  onChange: opt.onChange ? () => opt.onChange(obj) : undefined,
                  update: opt.update ? () => opt.update(name, obj) : undefined,
              };
    }

    protected static makeOnChange<T>(c: T, opt: IOptions<any>) {
        return opt?.onChange || opt?.update
            ? (name: string) => {
                  if (opt.onChange) {
                      opt.onChange(c);
                  }
                  if (opt.update) {
                      opt.update(name, c);
                  }
              }
            : undefined;
    }

    public static setValue(obj: Object, path: string, value: any) {
        if (!obj || !path) {
            return;
        }
        const parts = path.split('.'),
            k = parts.pop();
        let o = obj;
        parts.forEach((p) => (o = o[p] ?? (o[p] = {})));
        o[k] = value;
    }
    public static getValue(obj: Object, path: string) {
        if (!obj || !path) {
            return;
        }
        const parts = path.split('.'),
            k = parts.pop();
        let o = obj;
        for (const p of parts) {
            o = o[p];
            if (o == undefined) {
                return;
            }
        }
        return o[k];
    }
}
DatGuiUtil.init();

import { Directive, HostBinding, Input } from '@angular/core';
import { ButtonColor } from '../button-color';

/**
 * ## Role
 * Add DataGalaxy CSS classes to a fab button based on inputs values
 */
@Directive({
    selector: '[dxyFabButton]',
})
export class DxyFabButtonDirective {
    /**
     * Color of the button
     */
    @Input() color: ButtonColor = 'primary';

    @HostBinding('class') get classes() {
        return `dxy-fab-button dxy-fab-button--${this.color}`;
    }
}

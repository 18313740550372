import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import { IActionOption, INavigationPanelParams } from '@datagalaxy/core-ui';
import { TranslateService } from '@ngx-translate/core';
import { CollectionsHelper } from '@datagalaxy/core-util';
import { ServerType } from '@datagalaxy/dg-object-model';
import { AppSpaceService } from '../../../services/AppSpace.service';
import { EntityUiService } from '../../entity/services/entity-ui.service';
import { FilteredViewService } from '../services/filteredView.service';
import { DataUtil } from '../../util/DataUtil';
import { NavigationService } from '../../../services/navigation.service';
import { AppDataService } from '../../../services/app-data.service';
import { SecurityService } from '../../../services/security.service';
import { SearchService } from '../../../search/search.service';
import { ModuleSecurityData } from '@datagalaxy/webclient/workspace/data-access';
import { FilteredViewsUtil } from '@datagalaxy/webclient/filter/data-access';
import { FilteredViewUiService } from '../services/filtered-view-ui.service';
import {
    DgModule,
    getModuleDefinition,
} from '@datagalaxy/shared/dg-module/domain';
import { CurrentSpaceService } from '../../../services/currentSpace.service';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { SpaceIdentifier } from '@datagalaxy/webclient/workspace/utils';
import { LicenseLevel } from '@datagalaxy/webclient/license/domain';
import { ISpaceIdentifier } from '@datagalaxy/webclient/workspace/domain';
import { FilteredViewDto } from '@datagalaxy/webclient/filter/domain';
import { DgZone } from '@datagalaxy/webclient/domain';

/**
 * ## Role
 * Filtered Views Navigation header
 *
 *  ## Features
 * - Open a left panel to choose a filtered view to vizualize, edit, delete or fav
 * - Display Search result total count for each filtered view
 */
@Component({
    selector: 'app-filtered-views-nav-header',
    templateUrl: 'filtered-views-nav-header.component.html',
    styleUrls: ['filtered-views-nav-header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilteredViewsNavHeaderComponent
    extends DxyBaseComponent
    implements OnInit, OnChanges
{
    @Input() spaceIdr: ISpaceIdentifier;
    @Input() dgZone: DgZone;
    @Input() dgModule: DgModule;
    @Input() moduleSecurityData: ModuleSecurityData;
    @Input() currentFilteredView: FilteredViewDto;

    public navPanelParams?: INavigationPanelParams<IPanelFilteredView, number>;

    public panelOpened: boolean;
    public privateFilteredViews: FilteredViewDto[];
    public publicFilteredView: FilteredViewDto[];

    private translationBaseKey: string;
    private filterViewsList: FilteredViewDto[] = [];

    /** contains default "All" view (not a FilteredViewDto)*/
    private defaultView?: IPanelFilteredView;

    constructor(
        private translate: TranslateService,
        private searchService: SearchService,
        private navigationService: NavigationService,
        private entityUiService: EntityUiService,
        private securityService: SecurityService,
        private appDataService: AppDataService,
        private filteredViewService: FilteredViewService,
        private filteredViewUiService: FilteredViewUiService,
        private appSpaceService: AppSpaceService,
        private currentSpaceService: CurrentSpaceService,
        private cd: ChangeDetectorRef
    ) {
        super();
    }

    ngOnInit() {
        this.init();
        this.subscribeEvents();
    }

    ngOnChanges(changes: SimpleChanges) {
        super.onChanges(changes, ['dgZone', 'dgModule', 'spaceIdr'], () =>
            this.init()
        );
        super.onChange(changes, 'currentFilteredView', () =>
            this.updateViewInList(this.currentFilteredView)
        );
    }

    public async onPanelToggle() {
        if (!this.panelOpened) {
            return;
        }
        await this.updateListCounts();
    }

    public async onViewClick(filteredView: IPanelFilteredView) {
        if (filteredView.IsDefault) {
            return await this.onClickFilterAll();
        }
        if (!(filteredView instanceof FilteredViewDto)) {
            return;
        }

        await this.gotoFilteredView(filteredView);

        this.closePanel();
    }

    /** Get typed template data */
    public asIPanelFilteredView(templateData: any) {
        return templateData as IPanelFilteredView;
    }

    //#region getters

    private getTranslateKey(key: string) {
        return `${this.translationBaseKey}.${key}`;
    }

    private getFilteredViewName(fv: IPanelFilteredView) {
        return !fv.hasSavedFilters && fv.autoEntityTypeFilter
            ? this.translate.instant('UI.Global.filter.untitledFilterName')
            : fv.DisplayName;
    }

    private getViewGlyphClass(filteredView?: IPanelFilteredView) {
        const dgModule = filteredView.IsDefault
            ? this.dgModule
            : FilteredViewsUtil.dgModuleFromModuleName(filteredView.ModuleName);
        return getModuleDefinition(DgModule[dgModule]).glyphClass;
    }

    private getViewStarClass(filteredView: IPanelFilteredView) {
        return filteredView.IsFavorite ? 'glyph-star-fav' : 'glyph-star';
    }

    private canEditView(filteredView: IPanelFilteredView) {
        return (
            this.securityService.isSteward() ||
            filteredView.CreationUserId == this.appDataService.currentUserId
        );
    }

    //#endregion

    private subscribeEvents() {
        this.registerSubscriptions(
            this.filteredViewService.currentViewChanged$.subscribe((event) => {
                this.currentFilteredView = event.filteredView;
                this.updateViewInList(event.filteredView);
            })
        );
    }

    private createSpaceIdentifier(filteredView: FilteredViewDto) {
        return new SpaceIdentifier(
            filteredView?.SpaceUid,
            filteredView?.DefaultVersionId
        );
    }

    private async init() {
        this.log(
            'init',
            DgZone[this.dgZone],
            DgModule[this.dgModule],
            this.spaceIdr
        );
        this.initTranslationBaseKey();
        this.initDefaultView();
        this.initNavigationPanel();

        // unawaited promises
        this.initAllCount();
        this.initList();
    }

    private initTranslationBaseKey() {
        switch (this.dgZone) {
            case DgZone.Module: {
                const prefix = DataUtil.getModuleTranslateKeyPrefix(
                    this.dgModule
                );
                return (this.translationBaseKey = prefix
                    ? prefix + 'home'
                    : '_');
            }
            case DgZone.Search:
                return (this.translationBaseKey = 'UI.Search.filteredViews');
            default:
                break;
        }
    }

    private initDefaultView() {
        this.defaultView = {
            DisplayName: this.translate.instant(
                this.getTranslateKey('filterAll')
            ),
            IsDefault: true,
        };
    }

    private initNavigationPanel() {
        const publicFilteredViews: IPanelFilteredView[] = [
            this.defaultView,
            ...(this.publicFilteredView ?? []),
        ];
        const privateFilteredViews = this.privateFilteredViews ?? [];
        this.navPanelParams = {
            getId: (fv) => fv.FilteredViewId,
            getName: (fv) => this.getFilteredViewName(fv),
            getGlyphClass: (fv) => this.getViewGlyphClass(fv),
            getTooltipText: (fv) => fv.Description,
            titleButtonTooltipKey:
                'UI.NavBar.filteredViews.filteredViewsTooltip',
            titleButtonText: this.getCurrentViewName(),
            currentItem: this.currentFilteredView,
            itemActions: this.getFilteredViewActions(),
            hideAddButton: true,
            tabs: [
                {
                    tabId: 'shared',
                    tabTranslateKey: this.getTranslateKey('publicFilterViews'),
                    tabTooltipKey: this.getTranslateKey('subTitle'),
                    contentDataCount: publicFilteredViews.length,
                    data: publicFilteredViews,
                },
                {
                    tabId: 'private',
                    tabTranslateKey: this.getTranslateKey(
                        'privateFiltersTitle'
                    ),
                    tabTooltipKey: this.getTranslateKey(
                        'privateFiltersDescription'
                    ),
                    contentDataCount: privateFilteredViews.length,
                    data: privateFilteredViews,
                },
            ],
        };
        this.cd.detectChanges();
    }

    private getFilteredViewActions(): IActionOption<IPanelFilteredView>[] {
        return [
            {
                glyphClass: (fv) => this.getViewStarClass(fv),
                tooltipTranslateKey: 'UI.NavBar.filteredViews.defaultView',
                alwaysVisible: (fv) => fv.IsFavorite,
                hidden: (fv) => fv.IsDefault,
                callback: (fv) => this.toggleIsFavorite(fv),
            },
            {
                glyphClass: 'glyph-edit',
                tooltipTranslateKey: 'UI.NavBar.filteredViews.editTooltip',
                callback: (fv) => this.editView(fv),
                hidden: (fv) => fv.IsDefault || !this.canEditView(fv),
            },
            {
                glyphClass: 'glyph-delete',
                tooltipTranslateKey: 'UI.Tooltip.Delete',
                callback: (fv) => this.deleteView(fv),
                hidden: (fv) => fv.IsDefault || !this.canEditView(fv),
            },
            {
                glyphClass: 'glyph-tools-publish',
                tooltipTranslateKey: 'UI.NavBar.filteredViews.publishTooltip',
                callback: (fv) => {
                    this.openPublishModal(fv);
                },
                hidden: (fv) => this.publishButtonHidden(fv),
            },
        ];
    }

    private publishButtonHidden(fv: IPanelFilteredView): boolean {
        return (
            fv.IsDefault ||
            !fv.IsPrivate ||
            this.appDataService.currentUserLicenseLevel == LicenseLevel.Reader
        );
    }

    private async openPublishModal(fv: IPanelFilteredView) {
        const published = await this.filteredViewUiService.openPublishModal(
            fv as FilteredViewDto
        );
        if (published) {
            fv.IsPrivate = false;
            this.updatePrivateAndPublicFilteredViews();
        }
    }

    private initAllCount() {
        return this.updateEntitiesCount(null, true);
    }
    private async initList() {
        // The received instance of array is also used by navigation-breadcrumb.
        // So when we add or remove a view, changes are propagated
        this.filterViewsList = await this.filteredViewService.getFilteredViews(
            this.dgZone,
            this.spaceIdr,
            this.dgModule
        );
        this.updatePrivateAndPublicFilteredViews();
    }

    private updatePrivateAndPublicFilteredViews() {
        this.privateFilteredViews = CollectionsHelper.orderBy(
            this.filterViewsList.filter((fv) => fv.IsPrivate),
            [(d) => (d.IsFavorite ? -1 : 1), (d) => d.DisplayName]
        );
        this.publicFilteredView = CollectionsHelper.orderBy(
            this.filterViewsList.filter((fv) => !fv.IsPrivate),
            [(d) => (d.IsFavorite ? -1 : 1), (d) => d.DisplayName]
        );
        this.initNavigationPanel();
    }

    private async updateListCounts() {
        await Promise.all(
            this.filterViewsList.map((fv) => this.updateEntitiesCount(fv))
        );
        this.updatePrivateAndPublicFilteredViews();
    }

    private async updateViewInList(filteredView: FilteredViewDto) {
        const viewId = filteredView?.FilteredViewId;

        await this.updateEntitiesCount(filteredView);
        const fv = this.filterViewsList.find(
            (fv) => fv.FilteredViewId == viewId
        );
        if (viewId > 0) {
            if (fv) {
                filteredView.copyTo(fv, {
                    includeListFilter: true,
                    includeSavedListFilter: true,
                });
                this.initNavigationPanel();
            } else {
                this.filterViewsList.push(filteredView);
                this.updatePrivateAndPublicFilteredViews();
            }
        } else {
            this.initNavigationPanel();
        }
    }

    private async updateEntitiesCount(fv: FilteredViewDto, isCountAll = false) {
        if (!fv && !isCountAll) {
            return;
        }

        if (
            fv &&
            fv.FilteredViewId !== this.currentFilteredView?.FilteredViewId
        ) {
            fv.reset();
        }

        let count: number;
        switch (this.dgZone) {
            case DgZone.Module:
                count = await this.entityUiService.getModuleEntitiesCount(
                    this.dgModule,
                    this.spaceIdr,
                    fv
                );
                break;

            case DgZone.Search:
                count =
                    await this.searchService.getFilteredViewSearchEntitiesCount(
                        fv
                    );
                break;

            default:
                count = null;
                break;
        }
        if (fv) {
            fv.entitiesCount = count;
        } else if (isCountAll) {
            this.defaultView.entitiesCount = count;
        }
        this.log('updateEntitiesCount', count, fv, isCountAll);
    }

    private async resetCurrentView() {
        const fv = this.filteredViewService.getNewFilteredView(this.dgZone, {
            dgModule: this.dgModule,
            spaceId: this.currentFilteredView.SpaceUid,
            defaultVersion: this.currentFilteredView.DefaultVersionId,
        });
        await this.gotoFilteredView(fv);
    }

    private async gotoFilteredView(filteredView: FilteredViewDto) {
        const previousFilteredView = this.currentFilteredView;
        let spaceIdr;
        if (filteredView.DgZone == DgZone.Search) {
            spaceIdr = this.createSpaceIdentifier(previousFilteredView);
        } else {
            spaceIdr = this.currentSpaceService.getCurrentSpace();
        }

        await this.navigationService.goToFilteredView(filteredView, spaceIdr);
    }

    private closePanel() {
        this.panelOpened = false;
    }

    private getDefaultViewName(dgZone: DgZone, dgModule?: DgModule) {
        let translateKey: string;
        switch (dgZone) {
            case DgZone.Module: {
                const suffix = (() => {
                    switch (dgModule) {
                        case DgModule.Catalog:
                            return 'allCatalog';
                        case DgModule.Usage:
                            return 'allSoftwares';
                        case DgModule.Glossary:
                            return 'allProperties';
                        case DgModule.Processing:
                            return 'allDataProcessings';
                    }
                })();
                if (suffix) {
                    const translateKeyPrefix =
                        DataUtil.getModuleTranslateKeyPrefix(dgModule) +
                        'home.';
                    translateKey = translateKeyPrefix + suffix;
                }
                break;
            }
            case DgZone.Search:
                translateKey = this.translationBaseKey + '.filterAll';
        }
        return translateKey ? this.translate.instant(translateKey) : '_';
    }

    private getCurrentViewName() {
        const fv = this.currentFilteredView;
        return fv?.hasSavedFilters
            ? fv.DisplayName
            : fv?.autoEntityTypeFilter
            ? this.translate.instant('UI.Global.filter.untitledFilterName')
            : this.getDefaultViewName(this.dgZone, this.dgModule);
    }

    private async onClickFilterAll() {
        if (this.dgZone == DgZone.Search) {
            const defaultSpaceIdr =
                await this.appSpaceService.getASpaceIdentifier({
                    includeCurrent: true,
                });
            this.searchService.goToMainSearchResultsFromAllFilteredView(
                defaultSpaceIdr
            );
        } else {
            await this.resetCurrentView();
        }

        return this.closePanel();
    }

    private async toggleIsFavorite(filteredView: IPanelFilteredView) {
        this.log('toggleIsFavorite', filteredView);
        filteredView.IsFavorite = !filteredView.IsFavorite;
        await this.filteredViewService.setFilteredViewFavorite(
            filteredView.IsFavorite,
            filteredView.FilteredViewUid
        );
        await Promise.all(
            this.filterViewsList
                .filter((fv) => fv != filteredView && fv.IsFavorite)
                .map((fv) =>
                    this.filteredViewService.setFilteredViewFavorite(
                        (fv.IsFavorite = false),
                        fv.FilteredViewUid
                    )
                )
        );
        this.initNavigationPanel();
    }

    private async deleteView(filteredView: IPanelFilteredView) {
        if (!(filteredView instanceof FilteredViewDto)) {
            return;
        }
        const confirmed = await this.entityUiService.confirmDelete(
            ServerType.FilteredView,
            { featureCode: 'SAVED_FILTER,D' }
        );
        if (!confirmed) {
            return;
        }
        await this.filteredViewService.deleteFilteredView(filteredView);
        const deletedId = filteredView.FilteredViewId;
        CollectionsHelper.remove(
            this.filterViewsList,
            (f) => f.FilteredViewId == deletedId
        );
        this.updatePrivateAndPublicFilteredViews();
        if (this.currentFilteredView?.FilteredViewId == deletedId) {
            await this.resetCurrentView();
        }
    }

    private async editView(filteredView: IPanelFilteredView) {
        if (!(filteredView instanceof FilteredViewDto)) {
            return;
        }
        this.closePanel();
        await this.filteredViewService.openSaveModal(filteredView, true);
        await this.filteredViewService.updateFilteredViewProperties(
            this.spaceIdr,
            filteredView
        );
        if (
            this.currentFilteredView?.FilteredViewId ==
            filteredView.FilteredViewId
        ) {
            filteredView.copyTo(this.currentFilteredView);
        }
        this.initNavigationPanel();
    }
}

interface IPanelFilteredView extends Partial<FilteredViewDto> {
    IsDefault?: boolean;
}

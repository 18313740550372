import { ErrorHandler, NgModule } from '@angular/core';
import { APP_BASE_HREF } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';
import {
    DXY_TRANSLATE_CONFIG,
    DxyTranslateModule,
    RICH_TEXT_DEFAULTS,
} from '@datagalaxy/core-ui';
import { translateConfigFactory } from './core/provider-factories/translation.factory';
import { ClientErrorService } from './services/clientError.service';
import { GlobalErrorHandler } from './core/services/global-error-handler';
import { AppComponent } from './app.component';
import { DxyIsUpComponent } from './app-main/dxy-is-up/dxy-is-up.component';
import { DxyErrorPagesModule } from './errorPages/DxyErrorPagesModule';
import { AccountModule } from './account/account.module';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
    BACKEND_API_CONFIG,
    BackendApiModule,
    RestApiModule,
} from '@datagalaxy/data-access';
import { AppDataService } from './services/app-data.service';
import { ServerTimeService } from './services/serverTime.service';
import { backendHttpInterceptors } from './core/backend-http-interceptors';
import { backendApiFactory } from './core/provider-factories/backend-api.factory';
import {
    FullscreenOverlayContainer,
    OverlayContainer,
} from '@angular/cdk/overlay';
import { MonitoringApiService } from '@datagalaxy/webclient/monitoring/data-access';
import { ConnectivityApiModule } from '@datagalaxy/webclient/connectivity/data-access';
import { DataQualityApiModule } from '@datagalaxy/webclient/data-quality/data-access';
import { UserService } from './services/user.service';
import { UserPublicService } from '@datagalaxy/webclient/user/ui';
import { FeatureFlagService } from '@datagalaxy/webclient/feature-flag';
import {
    AuthenticationModule,
    AuthenticationService,
} from '@datagalaxy/webclient/auth/feature';
import { UIRouterModule, UIView } from '@uirouter/angular';
import { API_BASE_URL } from './api-config';
import { APP_CONFIG, AppConfigService } from '@datagalaxy/webclient/config';
import { DxySharedDialogsModule } from './shared/dialogs/DxySharedDialogsModule';
import { MultilingualFeatureModule } from '@datagalaxy/webclient/multilingual/feature';
import { ToasterModule } from '@datagalaxy/ui/toaster';
import { BottomBannerComponent } from '@datagalaxy/ui/banner-notification';
import { DATA_TEST_ID_CONFIG } from '@datagalaxy/ui/testing';
import { QuillContentAdapter } from './shared/richText/QuillContentAdapter';
import { DxyEntityLinksComponent } from './shared/entity/dxy-entity-links/dxy-entity-links.component';

@NgModule({
    providers: [
        { provide: APP_BASE_HREF, useValue: '/' },
        { provide: Window, useValue: window },
        // Enable a modal (mat-dialog) to be visible when an element is displayed full-screen.
        { provide: OverlayContainer, useClass: FullscreenOverlayContainer },
        { provide: ErrorHandler, useClass: GlobalErrorHandler },
        {
            provide: DXY_TRANSLATE_CONFIG,
            deps: [APP_CONFIG, ClientErrorService],
            useFactory: translateConfigFactory,
        },
        {
            provide: BACKEND_API_CONFIG,
            deps: [
                AppConfigService,
                AppDataService,
                ServerTimeService,
                FeatureFlagService,
                AuthenticationService,
                API_BASE_URL,
            ],
            useFactory: backendApiFactory,
        },
        { provide: UserPublicService, useExisting: UserService },
        {
            provide: DATA_TEST_ID_CONFIG,
            deps: [AppConfigService],
            useFactory: (appConfigService: AppConfigService) => ({
                disabled: appConfigService.DISABLE_DATA_TEST_IDS,
            }),
        },
        {
            provide: RICH_TEXT_DEFAULTS,
            useValue: {
                quillContentAdapter: new QuillContentAdapter(),
            },
        },
        ...backendHttpInterceptors,
        CookieService, // To delete after AuthV2 feature flag cleanup
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        BackendApiModule,
        RestApiModule,
        ConnectivityApiModule,
        DataQualityApiModule,
        UIRouterModule,
        AuthenticationModule,
        DxySharedDialogsModule,
        ToasterModule,
        //#region feature modules
        AccountModule,
        DxyErrorPagesModule,
        MultilingualFeatureModule.forRoot(),
        //#region

        //#region tier modules
        DxyTranslateModule.forRoot({ extend: true }),
        //#endregion

        //#region standalone components,
        BottomBannerComponent,
        DxyEntityLinksComponent,
        //#endregion
    ],
    declarations: [AppComponent, DxyIsUpComponent],
    exports: [
        BrowserModule,
        BrowserAnimationsModule,
        BackendApiModule,
        RestApiModule,
        ConnectivityApiModule,
        DataQualityApiModule,
        AccountModule,
        DxyErrorPagesModule,
        DxyTranslateModule,
        BottomBannerComponent,
        AppComponent,
        DxyIsUpComponent,
        AuthenticationModule,
        ToasterModule,
    ],
    bootstrap: [UIView],
})
export class AppSharedModule {
    constructor(
        private monitoringApiService: MonitoringApiService,
        private serverTimeService: ServerTimeService
    ) {
        this.initServerTime();
    }

    private async initServerTime() {
        const res = await this.monitoringApiService.getServerTime();
        this.serverTimeService.initTimeReference(res);
    }
}

import { CollectionsHelper } from '@datagalaxy/core-util';
import { BaseService } from '@datagalaxy/core-ui';
import { IGraphicalToolbarOption } from '@datagalaxy/core-ui/graphical';
import { LineageGraphService } from './lineage-graph/lineage-graph.service';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { ImpactAnalysisTool } from '../shared/util/app-types/impact-analysis.types';
import {
    IEntityIdentifier,
    ObjectLinkType,
    ServerType,
} from '@datagalaxy/dg-object-model';
import { ExplorationGraphData } from './data/ExplorationGraphData';
import { EntityService } from '../shared/entity/services/entity.service';
import { ViewTypeService } from '../services/viewType.service';
import { FilteredViewService } from '../shared/filter/services/filteredView.service';
import { AppEventsService } from '../services/AppEvents.service';
import { DxyModalService } from '../shared/dialogs/DxyModalService';
import { ExplorationGraphNode } from './data/ExplorationGraphNode';
import { ExplorationGraphLink } from './data/ExplorationGraphLink';
import { DatamapGraphData } from './data/DatamapGraphData';
import {
    ExplorerApiService,
    GetExploratoryAnalyticsDataParameter,
} from '@datagalaxy/webclient/explorer/data-access';
import { LineageOrientation } from '@datagalaxy/webclient/explorer/data-access';
import { LineageVersionTogglerService } from './entity-impact-analysis/lineage-version-toggler.service';
import { ISpaceIdentifier } from '@datagalaxy/webclient/workspace/domain';
import { DgZone } from '@datagalaxy/webclient/domain';
import { DgModule } from '@datagalaxy/shared/dg-module/domain';

@Injectable({ providedIn: 'root' })
export class ImpactAnalysisService extends BaseService {
    //#region static

    public static isLineage(tool: ImpactAnalysisTool) {
        return tool == ImpactAnalysisTool.Lineage;
    }

    public static isLineageLegacy(tool: ImpactAnalysisTool) {
        switch (tool) {
            case ImpactAnalysisTool.LineageBoth:
            case ImpactAnalysisTool.LineageRight:
            case ImpactAnalysisTool.LineageLeft:
                return true;
            default:
                return false;
        }
    }

    public static isExplorer(tool: ImpactAnalysisTool) {
        return tool == ImpactAnalysisTool.Explorer;
    }
    public static getToolGlyphClass(item?: ImpactAnalysisTool) {
        switch (item) {
            case ImpactAnalysisTool.Explorer:
                return 'glyph-analysis';
            case ImpactAnalysisTool.LineageBoth:
                return 'glyph-lineage-static';
            case ImpactAnalysisTool.LineageRight:
                return 'glyph-direction-before';
            case ImpactAnalysisTool.LineageLeft:
                return 'glyph-direction-after';
            case ImpactAnalysisTool.None:
                return 'glyph-visual-analysis';
            case ImpactAnalysisTool.Lineage:
                return 'glyph-lineage-explore';
        }
    }

    public static getLineageOrientation(tool: ImpactAnalysisTool) {
        switch (tool) {
            case ImpactAnalysisTool.LineageRight:
                return LineageOrientation.Left;
            case ImpactAnalysisTool.LineageLeft:
                return LineageOrientation.Right;
            case ImpactAnalysisTool.LineageBoth:
            default:
                return LineageOrientation.Both;
        }
    }
    public static getToolFromLineageOrientation(
        orientation: LineageOrientation
    ) {
        switch (orientation) {
            case LineageOrientation.Right:
                return ImpactAnalysisTool.LineageLeft;
            case LineageOrientation.Left:
                return ImpactAnalysisTool.LineageRight;
            case LineageOrientation.Both:
                return ImpactAnalysisTool.LineageBoth;
            default:
                return ImpactAnalysisTool.None;
        }
    }

    //#endregion

    //#region events

    public get onSelectedToolChange$() {
        return this.onSelectedToolChange.asObservable();
    }
    private readonly onSelectedToolChange = new Subject<ImpactAnalysisTool>();
    public notifySelectedToolChange(selectedTool: ImpactAnalysisTool) {
        this.onSelectedToolChange.next(selectedTool);
    }

    //#endregion

    private lastExploratoryData = new ExplorationGraphData(
        undefined,
        this.debug
    );

    constructor(
        private translateService: TranslateService,
        private entityService: EntityService,
        private viewTypeService: ViewTypeService,
        private filteredViewService: FilteredViewService,
        private appEventsService: AppEventsService,
        private lineageGraphService: LineageGraphService,
        private dxyModalService: DxyModalService,
        private explorerApiService: ExplorerApiService,
        private lineageVersionTogglerService: LineageVersionTogglerService
    ) {
        super();

        this.appEventsService.impactAnalysisClearCacheNeeded$.subscribe(() => {
            this.clearLineageCache();
            this.clearExploratoryCache();
        });
    }

    //#region common

    public getInitialSelectedTool(
        selectedTool: ImpactAnalysisTool,
        defaultTool: ImpactAnalysisTool
    ) {
        const excluded = [
            ImpactAnalysisTool.None,
            ImpactAnalysisTool.LineageNaturalFlow,
        ];
        const tools = CollectionsHelper.getEnumValues(
            ImpactAnalysisTool,
            ...excluded
        );
        if (defaultTool == undefined || excluded.includes(defaultTool)) {
            defaultTool = tools[0];
        }
        if (selectedTool == undefined || excluded.includes(selectedTool)) {
            selectedTool = defaultTool;
        }
        return selectedTool;
    }

    public async confirmWarningModal() {
        return this.dxyModalService.confirm({
            titleKey: 'UI.ImpactAnalysis.UnclusterWarningModal.title',
            messageKey: 'UI.ImpactAnalysis.UnclusterWarningModal.message',
            confirmButtonKey:
                'UI.ImpactAnalysis.UnclusterWarningModal.UnclusterBtnLbl',
            cancelButtonKey:
                'UI.ImpactAnalysis.UnclusterWarningModal.CancelBtnLbl',
        });
    }

    public getToolSelectorOption(
        selectedTool: ImpactAnalysisTool,
        onSelectedToolChange = (selectedTool: ImpactAnalysisTool) =>
            this.notifySelectedToolChange(selectedTool)
    ): IGraphicalToolbarOption {
        const isSelected = (tool: ImpactAnalysisTool) => tool == selectedTool;
        return {
            glyphClass: ImpactAnalysisService.getToolGlyphClass(selectedTool),
            tooltipTranslateKey:
                'UI.EntityImpactAnalysis.toolOptionLineageTooltip',
            options: [
                {
                    glyphClass: 'glyph-lineage-explore dg5-beta-badge',
                    tooltipTranslateKey:
                        'UI.EntityImpactAnalysis.toolOptionLineage',
                    isCurrentActive: isSelected(ImpactAnalysisTool.Lineage),
                    callback: async () => {
                        await this.lineageVersionTogglerService.setUseLegacyLineage(
                            false
                        );
                        onSelectedToolChange(ImpactAnalysisTool.Lineage);
                    },
                },
                this.getSelectorOption(
                    ImpactAnalysisTool.LineageBoth,
                    onSelectedToolChange,
                    isSelected
                ),
                this.getSelectorOption(
                    ImpactAnalysisTool.LineageLeft,
                    onSelectedToolChange,
                    isSelected
                ),
                this.getSelectorOption(
                    ImpactAnalysisTool.LineageRight,
                    onSelectedToolChange,
                    isSelected
                ),
                { isSeparator: true },
                this.getSelectorOption(
                    ImpactAnalysisTool.Explorer,
                    onSelectedToolChange,
                    isSelected
                ),
            ],
        };
    }

    private getSelectorOption(
        tool: ImpactAnalysisTool,
        onSelectedToolChange: (tool: ImpactAnalysisTool) => void,
        isSelected?: (tool: ImpactAnalysisTool) => boolean
    ): IGraphicalToolbarOption {
        return {
            glyphClass: ImpactAnalysisService.getToolGlyphClass(tool),
            callback: () => void onSelectedToolChange(tool),
            tooltipTranslateKey: `UI.EntityImpactAnalysis.toolOption${ImpactAnalysisTool[tool]}`,
            data: tool,
            isCurrentActive: () => isSelected?.(tool),
        };
    }

    //#endregion

    //#region exploratory

    public initExploratoryFromEntity(
        item: IEntityIdentifier,
        clearExploratoryCache = false
    ) {
        if (clearExploratoryCache) {
            this.clearExploratoryCache();
        }
        return this.initExploratoryData(item);
    }

    public async initExploratoryData(rootItem: IEntityIdentifier) {
        if (
            rootItem &&
            this.lastExploratoryData?.rootNode?.nodeObjectReferenceId !==
                rootItem.ReferenceId
        ) {
            const newGraphData = new ExplorationGraphData(rootItem, this.debug);
            await this.loadExploratoryAnalyticsData(rootItem, newGraphData);
            this.lastExploratoryData = newGraphData;
        }

        return this.lastExploratoryData;
    }

    public async loadExploratoryAnalyticsData(
        rootItem: IEntityIdentifier,
        graphData: ExplorationGraphData
    ) {
        const geadp = new GetExploratoryAnalyticsDataParameter(
            rootItem.ReferenceId,
            ServerType[rootItem.ServerType]
        );
        geadp.setVersionId(rootItem?.VersionId);

        const result =
            await this.explorerApiService.getExploratoryAnalyticsData(geadp);
        graphData.addData(result);
    }

    public getNodeName(
        graphData: ExplorationGraphData,
        item: ExplorationGraphNode,
        useClusterState = true
    ) {
        if (useClusterState && item.isCluster) {
            const objectCount = 1 + item.childrenClusterNodesId.length;
            return this.translateService.instant(
                'UI.ImpactAnalysis.exploratory.objects',
                { objectCount }
            );
        } else {
            const hdd = graphData?.getObjectHdd(item);
            return this.viewTypeService.getTechnicalOrDisplayName(hdd);
        }
    }

    public getLinkName(link: ExplorationGraphLink) {
        const uolt = link.sourceLinkInfo?.UniversalObjectLinkType;
        const tradKey =
            !uolt || uolt == ObjectLinkType.Unknown
                ? `DgServerTypes.ExploratoryLinkName.${link.linkTypeName}`
                : `DgServerTypes.ObjectLinkType.${link.sourceLinkInfo.universalLinkTypeString}`;
        const tradSync = tradKey ? this.translateService.instant(tradKey) : '';
        return tradSync == tradKey ? null : tradSync;
    }

    private clearExploratoryCache() {
        this.lastExploratoryData = new ExplorationGraphData(
            undefined,
            this.debug
        );
    }

    //#endregion

    //#region datamap

    public async initDatamapGraphData(
        spaceIdr: ISpaceIdentifier,
        dgModule: DgModule
    ) {
        const filters = this.filteredViewService.getCurrentFilters(
            DgZone.Module
        );
        this.clearExploratoryCache();
        const data = await this.entityService.getEntitiesForDataMap(
            spaceIdr,
            dgModule,
            filters,
            false
        );
        return new DatamapGraphData(
            spaceIdr,
            dgModule,
            !!filters?.length,
            data.Entities
        );
    }

    //#endregion

    //#region lineage
    public getCachedLineageOriginIdr() {
        return this.lineageGraphService.getCachedOriginIdr();
    }
    public getLineageGraphParams(
        entity: IEntityIdentifier,
        tool: ImpactAnalysisTool
    ) {
        const orientation = ImpactAnalysisService.getLineageOrientation(tool);
        return this.lineageGraphService.getGraphParams(entity, orientation);
    }
    private clearLineageCache() {
        return this.lineageGraphService.clearCache();
    }
    //#endregion
}

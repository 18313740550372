<header>
    <dxy-widget-title-editor
        class="header-title"
        [editionEnabled]="editionEnabled"
        [customTitle]="widgetConfiguration?.customTitle"
        defaultTitleKey="UI.SpaceHome.Widgets.SpaceActivityLogs.Title"
        (customTitleChange)="onCustomTitleChange($event)"
        dxyEllipsisTooltip
    >
    </dxy-widget-title-editor>
    <button
        *ngIf="showEntries"
        [disabled]="editionEnabled"
        mat-button
        (click)="onOpenSpaceActivityLogsPreview()"
    >
        <span translate>UI.Global.filter.seeAll</span>
    </button>
</header>
<section class="body" [class.disabled]="editionEnabled">
    <cdk-virtual-scroll-viewport *ngIf="showEntries" itemSize="70">
        <div *cdkVirtualFor="let logEntry of logEntries" class="log-entry">
            <dxy-profile-avatar
                *ngIf="logEntry.UserId"
                [userId]="logEntry.UserId"
            ></dxy-profile-avatar>

            <div class="log-entry-content">
                <dxy-activity-log-item
                    [activityLogEntry]="logEntry"
                ></dxy-activity-log-item>
                <span class="log-entry-time">{{
                    logEntry.CreationTimeFromNow
                }}</span>
            </div>
        </div>
    </cdk-virtual-scroll-viewport>
    <dxy-spinner
        *ngIf="loading$ | async"
        class="absolute-centered"
    ></dxy-spinner>
    <div *ngIf="showPlaceholder" class="dxy-placeholder">
        <img
            class="dxy-placeholder-img"
            alt="placeholder"
            src="/images/space-widgets/space-activity-log-placeholder.svg"
        />
        <span class="dxy-placeholder-text" translate
            >UI.SpaceHome.Widgets.SpaceActivityLogs.Placeholder</span
        >
    </div>
</section>

import { NgModule } from '@angular/core';
import { DiagramsListComponent } from './diagrams-list/diagrams-list.component';
import { DxyOmnigridModule } from '@datagalaxy/core-ui/omnigrid';
import { CoreUiModule } from '@datagalaxy/core-ui';
import { DxySharedEntityModule } from '../shared/entity/DxySharedEntityModule';

@NgModule({
    imports: [DxyOmnigridModule, CoreUiModule, DxySharedEntityModule],
    declarations: [DiagramsListComponent],
    exports: [DiagramsListComponent],
})
export class SharedDiagramModule {}

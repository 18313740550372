//#region exported types
import {
    IOmniGridApi,
    IOmniGridCellRendererData,
    IOmniGridTreeOptionsRendererParams,
    OmniGridColumnInfo,
} from '@datagalaxy/core-ui/omnigrid';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';
import { AttributeMetaInfo } from '@datagalaxy/webclient/attribute/domain';

export class EntityGridColumnInfo extends OmniGridColumnInfo {
    constructor(
        private attributeMeta: AttributeMetaInfo,
        displayName: string,
        visible = true,
        movable = true
    ) {
        super({
            field: `Attributes.${attributeMeta.AttributeKey}`,
            colId: attributeMeta.AttributePath,
            valueGetter: (params: any) => {
                const entityItem = params.data as EntityItem;
                return entityItem?.getTypedAttributeValue(attributeMeta);
            },
            headerName: displayName,
            hide: !visible,
        });

        if (!movable) {
            this.colDef.suppressMovable = true;
        }
    }

    get attributeKey() {
        return this.attributeMeta.AttributeKey;
    }
    get attributeType() {
        return this.attributeMeta.AttributeType;
    }
    get sourceAttributeType() {
        return this.attributeMeta.SourceAttributeType;
    }
    get isSystem() {
        return this.attributeMeta.IsSystem;
    }

    get attributePath() {
        return this.attributeMeta.AttributePath;
    }
}

export const EntityGridDefaultRowHeight = 55;
//#endregion - exported types

//#region local types  (ie, used by entitygrid only)
export declare type EntityItemOmniGridApi = IOmniGridApi<EntityItem>;
export declare type ICellRendererParams<TValue = any> =
    IOmniGridCellRendererData<EntityItem, TValue>;
export declare interface ITreeNodeRendererParams
    extends IOmniGridTreeOptionsRendererParams<EntityItem> {}
//#endregion - local types

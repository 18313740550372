import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DxyButtonDirective } from './button/button-directive';
import { DxyIconButtonDirective } from './icon-button/icon-button-directive';
import { DxyFabButtonDirective } from './fab-button/fab-button-directive';

@NgModule({
    imports: [CommonModule],
    declarations: [
        DxyButtonDirective,
        DxyIconButtonDirective,
        DxyFabButtonDirective,
    ],
    exports: [
        DxyButtonDirective,
        DxyIconButtonDirective,
        DxyFabButtonDirective,
    ],
})
export class DxyButtonsModule {}

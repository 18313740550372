<header>
    <!-- header message -->
    <div class="more-results-message">
        <div class="inner">
            <span class="text">{{ headerGridTitle }}</span>
        </div>
    </div>
</header>

<dxy-grid
    *ngIf="(isLoading$ | async) === false"
    [config]="gridConfig"
    [columns]="cols"
    [items]="rows$ | async"
    (rowClick)="onRowClicked($event)"
></dxy-grid>
<dxy-spinner *ngIf="isLoading$ | async"></dxy-spinner>

import { NgModule } from '@angular/core';
import { FieldsModule } from '../fields/fields.module';
import { DxyFileOptionsFormComponent } from './dxy-file-options-form/dxy-file-options-form.component';
import { SharedModule } from '../shared.module';

@NgModule({
    imports: [SharedModule, FieldsModule],
    declarations: [DxyFileOptionsFormComponent],
    exports: [DxyFileOptionsFormComponent],
})
export class DxyFileOptionsModule {}

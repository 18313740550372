import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    HostBinding,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    ViewChild,
    ViewContainerRef,
    ViewEncapsulation,
} from '@angular/core';
import { IListOption } from '@datagalaxy/core-ui';
import { Widget } from '../domain';
import { widgetTypeMappings } from '../widgets-registry';
import {
    BaseWidgetComponent,
    IConfigurableWidgetComponent,
    IEditionWidgetComponnent,
    ILoadingWidgetComponent,
    IUndoWidgetComponent,
} from '../widget-interfaces';
import { Observable, of } from 'rxjs';
import { DraggingObserver } from '../dashboard/dragging-observer';
import { BaseCustomTitleWidgetComponent } from '../base-widget-component';
import { DashboardHistoryService } from '../dashboard/state/dashboard-history-state.service';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { CoreUtil } from '@datagalaxy/core-util';

@Component({
    selector: 'dxy-dashboard-card',
    templateUrl: './dashboard-card.component.html',
    styleUrls: ['./dashboard-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class DashboardCardComponent
    extends DxyBaseComponent
    implements AfterViewInit, OnChanges
{
    @Input() widgetName!: string;
    @Input() @HostBinding('class.editing') enableEdition = false;
    @Input() disableDuplication = false;
    @Input() configuration?: unknown;

    @Output() ready = new EventEmitter();
    @Output() widgetNotFound = new EventEmitter();
    @Output() remove = new EventEmitter();
    @Output() duplicate = new EventEmitter();
    @Output() configurationChange = new EventEmitter<unknown>();

    @ViewChild('container', { read: ViewContainerRef })
    private container: ViewContainerRef;

    widgetComponentInstance: BaseWidgetComponent;

    protected widgetLoading$: Observable<boolean>;
    protected dragging$ = this.draggingObserver.dragging$;

    protected dropDownOptions: IListOption[] = [
        {
            glyphClass: 'glyph-file-copy',
            labelKey: 'Dashboard.Handle.DropDown.Widget.duplicate',
            hidden: () => this.disableDuplication,
            callback: () => this.duplicate.emit(),
        },
        {
            glyphClass: 'glyph-delete',
            labelKey: 'Dashboard.Handle.DropDown.Widget.remove',
            callback: () => this.remove.emit(),
        },
    ];

    private widget: Widget;

    constructor(
        private cd: ChangeDetectorRef,
        private draggingObserver: DraggingObserver,
        private dashboardHistoryService: DashboardHistoryService
    ) {
        super();
    }

    ngOnChanges(changes: SimpleChanges) {
        super.onChange(
            changes,
            'enableEdition',
            () => {
                this.updateWidgetComponentInstanceEditionMode();
                this.applyConfiguration();
            },
            false
        );
    }

    ngAfterViewInit(): void {
        this.injectWidgetComponent();
    }

    private injectWidgetComponent() {
        try {
            this.widget = this.getWidget();
            this.compileWidgetComponent();
            this.setLoadingObservableValue();
            this.updateWidgetComponentInstanceEditionMode();
            this.applyConfiguration();
            this.registerToConfigurationChange();
            this.addCustomTitleButton();
            this.registerToUndo();
            this.ready.emit();
            this.cd.detectChanges();
        } catch (error) {
            CoreUtil.warn(error);
            this.widgetNotFound.emit();
        }
    }
    private setLoadingObservableValue() {
        const obs = (
            this.widgetComponentInstance as unknown as ILoadingWidgetComponent
        )?.loading$;
        this.widgetLoading$ = obs ?? of(false);
    }

    private compileWidgetComponent() {
        const componentType = this.widget.componentConstructor;
        this.widgetComponentInstance =
            this.container.createComponent(componentType).instance;
    }

    private getWidget(): Widget {
        const widget = widgetTypeMappings.get(this.widgetName);
        if (!widget) {
            throw {
                error: `Widget component doesn't exist for Widget Type: ${this.widgetName}`,
            };
        }
        return widget;
    }

    private updateWidgetComponentInstanceEditionMode() {
        const wci = this.widgetComponentInstance as IEditionWidgetComponnent;
        if (wci.toggleEditionMode) {
            wci.toggleEditionMode(this.enableEdition);
        }
    }

    private registerToConfigurationChange() {
        const wci = this
            .widgetComponentInstance as IConfigurableWidgetComponent;
        if (wci.configuration$) {
            this.subscribe(wci.configuration$, (configuration) => {
                if (configuration === this.configuration) {
                    return;
                }
                this.configurationChange.emit(configuration);
            });
        }
    }

    private addCustomTitleButton() {
        const wci = this.widgetComponentInstance;
        if (wci instanceof BaseCustomTitleWidgetComponent) {
            this.dropDownOptions.unshift({
                glyphClass: 'glyph-edit',
                labelKey: 'Dashboard.Handle.DropDown.Widget.rename',
                callback: () =>
                    setTimeout(() => wci.focusWidgetTitleEditor(), 100),
            });
        }
    }

    private registerToUndo() {
        const wci = this.widgetComponentInstance as IUndoWidgetComponent;
        if (wci.undo instanceof EventEmitter) {
            this.subscribe(wci.undo, () => {
                this.dashboardHistoryService.revertToLastHistoryState();
            });
        }
    }

    private applyConfiguration() {
        const wci = this
            .widgetComponentInstance as IConfigurableWidgetComponent;
        wci.applyConfiguration?.(this.configuration);
    }
}

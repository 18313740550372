import { NgModule } from '@angular/core';
import { DxySpaceEditModalComponent } from './dxy-space-edit-modal/dxy-space-edit-modal.component';
import { DxySpaceMainComponent } from './dxy-space-main/dxy-space-main.component';
import { UIRouterModule } from '@uirouter/angular';
import { getStatesForSpace, spaceRouterInit } from './space.routes';
import { DxyImageInputModule } from '@datagalaxy/core-ui/image-input';
import { DxyOmnigridModule } from '@datagalaxy/core-ui/omnigrid';
import { DxyFiltersModule } from '@datagalaxy/core-ui/filters';
import { SharedModule } from '../shared/shared.module';
import { FieldsModule } from '../shared/fields/fields.module';
import { DxySharedDialogsModule } from '../shared/dialogs/DxySharedDialogsModule';
import { DxyNavigationModule } from '../navigation/DxyNavigationModule';
import { DxySharedUiModule } from '../shared/shared-ui/DxySharedUiModule';
import { DxySpotlightModule } from '../spotlight/DxySpotlightModule';
import { AttributeFilterModule } from '../shared/attribute/attribute-filter/AttributeFilterModule';
import { SpaceDescriptionWidgetComponent } from './space-home-widgets/space-description-widget/space-description-widget.component';
import { SpaceFilteredViewsWidgetComponent } from './space-home-widgets/space-filtered-views-widget/space-filtered-views-widget.component';
import { SpaceMembersWidgetComponent } from './space-home-widgets/space-members-widget/space-members-widget.component';
import { SpaceMembersWidgetPreviewComponent } from './space-home-widgets/space-members-widget-preview/space-members-widget-preview.component';
import { SpaceActivityLogsWidgetComponent } from './space-home-widgets/space-activity-logs-widget/space-activity-logs-widget.component';
import { DxyActivityLogModule } from '../activityLog/DxyActivityLogModule';
import { SpaceActivityLogsWidgetPreviewComponent } from './space-home-widgets/space-activity-logs-widget-preview/space-activity-logs-widget-preview.component';
import { SpaceNewsWidgetComponent } from './space-home-widgets/space-news-widget/space-news-widget.component';
import { SpaceNewsWidgetModalComponent } from './space-home-widgets/space-news-widget-modal/space-news-widget-modal.component';
import { DxyRichTextModule } from '@datagalaxy/core-ui/rich-text';
import { SharedWidgetModule } from '../widgets-dashboard/shared-widget.module';
import { SpaceHomeDashboardComponent } from './space-home-dashboard/space-home-dashboard.component';
import { DashboardModule } from '@datagalaxy/webclient/dashboard/ui';
import { SpaceHomeNavBarComponent } from './space-home-navbar/space-home-navbar.component';
import { SpaceHomeHeaderEditionModalComponent } from './space-home-header-edition-modal/space-home-header-edition-modal.component';
import { SpaceFilteredViewObjectsWidgetComponent } from './space-home-widgets/space-filtered-view-objects-widget/space-filtered-view-objects-widget.component';
import { SpaceFilteredViewObjectsModalComponent } from './space-home-widgets/space-filtered-view-objects-modal/space-filtered-view-objects-modal.component';
import { FilterModule } from '@datagalaxy/webclient/filter/ui';
import { EntityCardModule } from '../shared/entityCard/entity-card.module';
import { SpaceFilteredViewEntitiesComponent } from './space-home-widgets/space-filtered-view-entities/space-filtered-view-entities.component';
import { EntityTypeCardComponent } from '@datagalaxy/webclient/entity/ui';
import { IconCardComponent } from '@datagalaxy/ui/icon';
import { FilterFeatureModule } from '@datagalaxy/webclient/filter/feature';
import { GridComponent } from '@datagalaxy/ui/grid';

const widgetComponents = [
    SpaceDescriptionWidgetComponent,
    SpaceFilteredViewsWidgetComponent,
    SpaceMembersWidgetComponent,
    SpaceMembersWidgetPreviewComponent,
    SpaceActivityLogsWidgetComponent,
    SpaceActivityLogsWidgetPreviewComponent,
    SpaceNewsWidgetComponent,
    SpaceNewsWidgetModalComponent,
    SpaceFilteredViewObjectsWidgetComponent,
    SpaceFilteredViewObjectsModalComponent,
    SpaceFilteredViewEntitiesComponent,
];

@NgModule({
    imports: [
        SharedModule,
        UIRouterModule.forChild({
            states: getStatesForSpace(),
            config: (uiRouter) => spaceRouterInit(uiRouter.transitionService),
        }),
        DxySharedDialogsModule,
        DxyNavigationModule,
        DxySharedUiModule,
        SharedWidgetModule,
        DxySpotlightModule,
        AttributeFilterModule,
        DxyFiltersModule,
        FilterModule,
        DxyActivityLogModule,
        DxyRichTextModule,
        FieldsModule,
        DxyImageInputModule,
        DxyOmnigridModule,
        DashboardModule,
        EntityCardModule,
        EntityTypeCardComponent,
        IconCardComponent,
        FilterFeatureModule,
        GridComponent,
    ],
    declarations: [
        DxySpaceEditModalComponent,
        SpaceHomeDashboardComponent,
        SpaceHomeNavBarComponent,
        DxySpaceMainComponent,
        SpaceHomeHeaderEditionModalComponent,
        ...widgetComponents,
    ],
    exports: [DxySpaceEditModalComponent, ...widgetComponents],
})
export class SpaceModule {}

import { LineageItem } from './LineageItem';
import { EntityType } from '@datagalaxy/dg-object-model';
import { DataLineageDataLink } from '@datagalaxy/webclient/explorer/data-access';

export class RootSplitUtil {
    /** Splits each splittable root into new roots made of each extractable descendant item and its cloned ancestors.
     * Isolated remaining parents (ie with no children and not linked), except the roots, are removed.
     * New roots are inserted after the one they are extracted from. */
    public static splitRoots(
        roots: LineageItem[],
        dataLinks: DataLineageDataLink[],
        forLazyLoad?: {
            /** the item for which the lazy loading is done */
            parentItem: LineageItem;
            /** all items built from the lazy-loading */
            newItems: LineageItem[];
        }
    ) {
        if (!roots?.length && !forLazyLoad?.newItems?.length) {
            return;
        }

        const linkedNodeDataIds = new Set([
            ...dataLinks.map((l) => l.SourceId),
            ...dataLinks.map((l) => l.TargetId),
        ]);
        const isLinked = (it: LineageItem) => linkedNodeDataIds.has(it.dataId);

        roots.filter(RootSplitUtil.canBeSplit).forEach((root) => {
            const newRoots = RootSplitUtil.splitRoot(root, isLinked);
            if (!newRoots?.length) {
                return;
            }
            const rootIndex = roots.indexOf(root);
            roots.splice(1 + rootIndex, 0, ...newRoots);
        });

        if (forLazyLoad?.newItems?.length) {
            const { parentItem, newItems } = forLazyLoad,
                parentItemRoot = parentItem.root;
            const lazyDescendantsDataIds = LineageItem.getDescendants(
                parentItem,
                (d) => newItems.includes(d)
            ).map((it) => it.dataId);
            const linkedToDescendants = newItems.filter(
                (it) =>
                    it.root != parentItemRoot &&
                    dataLinks.some(
                        (l) =>
                            lazyDescendantsDataIds.includes(l.SourceId) ||
                            lazyDescendantsDataIds.includes(l.TargetId)
                    )
            );
            const newRoots = linkedToDescendants
                .filter(RootSplitUtil.canBeExtracted)
                .map((it) => RootSplitUtil.extractBranch(it, isLinked));
            if (newRoots.length) {
                roots.splice(1 + roots.indexOf(parentItemRoot), 0, ...newRoots);
                newItems.push(...newRoots);
            }
        }
    }

    private static splitRoot(
        root: LineageItem,
        isLinked: (it: LineageItem) => boolean
    ) {
        const itemsToExtract = LineageItem.getDescendants(
            root,
            RootSplitUtil.canBeExtracted
        );
        //console.log('itemsToExtract', c, root.toDebugString(true), itemsToExtract)
        return itemsToExtract?.length > 1
            ? itemsToExtract.map((it) =>
                  RootSplitUtil.extractBranch(it, isLinked)
              )
            : [];
    }

    /** re-parent an item to a new branch made of its parents' clones.
     * Empty parents are removed, if they have no links */
    private static extractBranch(
        item: LineageItem,
        isLinked: (it: LineageItem) => boolean
    ) {
        let newParent: LineageItem = undefined;
        LineageItem.getParents(item)
            .reverse()
            .forEach(
                (it) =>
                    (newParent = new LineageItem(
                        it.entityType,
                        it.displayName,
                        it.technicalName,
                        it.dataId,
                        newParent,
                        it.isGoldenItem,
                        it.lazyChildrenCount,
                        item
                    ))
            );
        item.reParent(newParent, (emptyParent) => !isLinked(emptyParent));
        return item.root;
    }

    private static canBeSplit(item: LineageItem) {
        switch (item?.entityType) {
            //#region ServerType.Model
            case EntityType.RelationalModel:
            case EntityType.NonRelationalModel:
            case EntityType.NoSqlModel:
            case EntityType.TagBase:
            //#endregion

            //#region ServerType.DataProcessing
            case EntityType.DataFlow:
                //#endregion

                return true;

            default:
                return false;
        }
    }

    private static canBeExtracted(item: LineageItem) {
        switch (item?.entityType) {
            //#region ServerType.Table
            case EntityType.Table:
            case EntityType.View:
            case EntityType.File:
            case EntityType.Document:
            case EntityType.Tag:
            case EntityType.Model:
            //#endregion

            //#region ServerType.DataProcessing
            case EntityType.DataProcessing:
                //#endregion

                return true;

            default:
                return false;
        }
    }
}

export interface IConnectionToken {
    id?: string;
    displayName: string;
    value: string;
}

export interface IPersonalAccessTokenDecoded {
    uid: string;
    given_name: string;
    family_name: string;
}

export enum OrphanedObjectsHandling {
    DO_NOTHING = 'DO_NOTHING',
    OBSOLETE_STATE = 'OBSOLETE_STATE',
    DELETE = 'DELETE',
}

export interface IOrphanedObjectsHandlingDropdownOption {
    action: OrphanedObjectsHandling;
    text: string;
    subText?: string;
}

export const DEFAULT_ORPHANED_OBJECTS_HANDLING =
    OrphanedObjectsHandling.DO_NOTHING;

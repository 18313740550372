import { InjectionToken } from '@angular/core';
import { BaseServiceParameter, BaseServiceResult } from '../backend';
import { ObservableInput } from 'rxjs';

export const BACKEND_API_CONFIG = new InjectionToken<IApiConfig>(
    'backend.api.config'
);

export interface IApiConfig {
    /**
     * Backend URL.
     * Eg: https://app.datagalaxy.com/api
     */
    baseUrl: string;
    /**
     * Public API.
     * Eg: Eg: https://api.datagalaxy.com/v2
     */
    publicApiUrl: string;
    /**
     * Client version to be added to http request headers (x-client-app)
     */
    clientVersion?: string;
    /**
     * JSON Web token added to http request headers (Authorization: Bearer <jwt>)
     */
    getJWT?: () => Promise<string>;
    /**
     * User session added to request parameters, required for authorized routes
     */
    getUserSessionId?: () => string;
    /**
     * Server Time added to request parameters to track requests starting time
     * and last request received time
     */
    getServerTimeString?: () => string;
    /**
     * Realtime session id added to request parameters to be able to get realtime
     * notifications for the current logged-in user
     */
    getRealTimeSessionId?: () => string;
}

export interface IApiCallOptions {
    responseType?: any;
    cancelNotifier?: ObservableInput<any>;
    skipSerialization?: boolean;
}

export class ApiServiceError<
    TParam extends BaseServiceParameter = BaseServiceParameter,
    TResult extends BaseServiceResult = BaseServiceResult
> extends Error {
    constructor(
        message: string,
        public route: string,
        public type: ApiServiceErrorType,
        public parameter?: TParam,
        public result?: TResult
    ) {
        super(message);
    }
}

export enum ApiServiceErrorType {
    Unknown = 0,
    MissingRequiredFields,
    BadParameterValues,
    DataCorruption,
    SessionNotFound,
    Conflicts,
    AccessDenied,
    ClientAdminAccessDenied,
    ObjectNotFound,
    InternalServerError,
    Timeout,
    ServerUnreachable,
    UnhandledServerError,
    Unauthorized,
    UnmodifiedContent,
    /**
     * Server returned a 200 response with body containing IsSuccess to false
     */
    UnsuccessfulRequest,
}

import { NgModule } from '@angular/core';
import { UIRouterModule } from '@uirouter/angular';
import { getStatesForGlossary, glossaryRouterInit } from './glossary.routes';
import { FieldsModule } from '../shared/fields/fields.module';
import { SharedModule } from '../shared/shared.module';
import { DxyGlossaryFusionModalComponent } from './dxy-glossary-fusion-modal/dxy-glossary-fusion-modal.component';
import { GlossaryGenerateFromSourceModalComponent } from './glossary-generate-from-source-modal/glossary-generate-from-source-modal.component';
import { DxySharedDialogsModule } from '../shared/dialogs/DxySharedDialogsModule';
import { DxySpaceSelectorModule } from '../space-selector/DxySpaceSelectorModule';
import { GlossaryLinksGenerationModalComponent } from './glossary-links-generation-modal/glossary-links-generation-modal.component';

@NgModule({
    imports: [
        UIRouterModule.forChild({
            states: getStatesForGlossary(),
            config: (uiRouter) =>
                glossaryRouterInit(uiRouter.transitionService),
        }),
        SharedModule,
        FieldsModule,
        DxySharedDialogsModule,
        DxySpaceSelectorModule,

        //#region standalone components
        GlossaryLinksGenerationModalComponent,
        //#endregion standalone components
    ],
    declarations: [
        DxyGlossaryFusionModalComponent,
        GlossaryGenerateFromSourceModalComponent,
    ],
    exports: [GlossaryGenerateFromSourceModalComponent],
})
export class GlossaryModule {}

import {
    DksLeftBarMenuOption,
    TDksLeftBarMenu,
    TDksLeftBarMenuOption,
} from './dks-left-bar-menu.types';
import { IGraphicalToolbarOption, IMultiSelectData } from '@datagalaxy/core-ui';
import { GridMode } from '@datagalaxy/core-d3-util';
import { DksGraphSurface } from '../../data-knowledge-studio.types';
import { DksNotifierService } from '../../notifier/dks-notifier';
import { CollectionsHelper } from '@datagalaxy/core-util';
import { DksGraphOptions } from '../../config/dks-config.types';
import { BehaviorSubject } from 'rxjs';

export class DksLeftBarMenu<NodeData, EdgeData> {
    private multiSelectExtraData: IMultiSelectData<string>;
    private readonly menu = new BehaviorSubject<IGraphicalToolbarOption[]>([]);

    public get menu$() {
        return this.menu.asObservable();
    }

    constructor(
        private graphSurface: DksGraphSurface<NodeData, EdgeData>,
        private config: DksGraphOptions<NodeData, EdgeData>,
        private notifier: DksNotifierService<NodeData>
    ) {}

    setupMenu(options?: TDksLeftBarMenu): void {
        const menu = CollectionsHelper.flatten(
            options?.map((opt) => {
                const res = this.getOption(opt);
                return Array.isArray(res) ? res : [res];
            })
        );
        this.menu.next(menu);
    }

    private getOption(
        option: TDksLeftBarMenuOption
    ): IGraphicalToolbarOption | IGraphicalToolbarOption[] {
        if (typeof option === 'object') {
            return option;
        } else {
            switch (option) {
                case DksLeftBarMenuOption.Separator:
                    return { isSeparator: true };
                case DksLeftBarMenuOption.SelectMode:
                    return this.getSelectModeOptions();
                case DksLeftBarMenuOption.ExtraData:
                    return this.getExtraDataOption();
                case DksLeftBarMenuOption.Grid:
                    return this.getGridOption();
            }
        }
    }

    private getSelectModeOptions(): IGraphicalToolbarOption[] {
        return [
            {
                glyphClass: 'glyph-hand',
                callback: () =>
                    this.graphSurface.selection.setSelectMode(false),
                tooltipTranslateKey: 'Graphical.Plumb.panMode',
                isCurrentActive: () =>
                    !this.graphSurface.selection.isSelectMode,
            },
            {
                glyphClass: 'glyph-selection',
                callback: () => this.graphSurface.selection.setSelectMode(true),
                tooltipTranslateKey: 'Graphical.Plumb.selectMode',
                isCurrentActive: () => this.graphSurface.selection.isSelectMode,
            },
        ];
    }

    private getExtraDataOption() {
        this.multiSelectExtraData ??= {
            items: this.config?.extraData?.available,
            selectedItems: this.config?.extraData?.selected,
            hasSelectAll: true,
            onSelectionChange: (selection: string[]) => {
                this.notifier.notifyExtraDataChange(selection);
            },
            adapter: {
                getTextKey: (v) => `DgServerTypes.BaseData.fields.${v}`,
            },
        };
        return {
            glyphClass: 'glyph-insight',
            tooltipTranslateKey: 'UI.Diagrams.ExtraData.btnManage',
            logFunctional: 'DIAGRAM_EXTRADATA,R',
            getMultiSelectData: () => this.multiSelectExtraData,
        };
    }

    private getGridOption(): IGraphicalToolbarOption {
        return {
            glyphClass: () => this.getGridGlyph(this.graphSurface.grid.mode),
            tooltipTranslateKey: 'UI.Diagrams.magneticGrid.tooltip',
            options: [GridMode.on, GridMode.hidden, GridMode.off].map(
                (gridMode) => ({
                    glyphClass: this.getGridGlyph(gridMode),
                    tooltipTranslateKey: this.getGridTooltip(gridMode),
                    isCurrentActive: () =>
                        this.graphSurface.grid.mode === gridMode,
                    callback: () => {
                        this.graphSurface.grid.mode = gridMode;
                        this.notifier.notifyGridChange(gridMode);
                    },
                })
            ),
        };
    }

    private getGridGlyph(state: GridMode) {
        switch (state) {
            case GridMode.on:
                return 'glyph-grid';
            case GridMode.hidden:
                return 'glyph-grid-magnetism-on';
            case GridMode.off:
                return 'glyph-grid-magnetism-off';
        }
    }
    private getGridTooltip(state: GridMode) {
        switch (state) {
            case GridMode.on:
                return 'UI.Diagrams.magneticGrid.show';
            case GridMode.hidden:
                return 'UI.Diagrams.magneticGrid.activate';
            case GridMode.off:
                return 'UI.Diagrams.magneticGrid.hide';
        }
    }
}

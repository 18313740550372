import { IGraphicalToolbarOption } from '@datagalaxy/core-ui';

export enum DksLeftBarMenuOption {
    Separator,
    SelectMode,
    ExtraData,
    Grid,
}

export type TDksLeftBarMenuOption =
    | IGraphicalToolbarOption
    | DksLeftBarMenuOption;
export type TDksLeftBarMenu = TDksLeftBarMenuOption[];

import { Component, Inject, OnInit } from '@angular/core';
import { DxyBaseModalComponent } from '@datagalaxy/ui/dialog';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as moment from 'moment';
import { CoreUtil } from '@datagalaxy/core-util';
import {
    ITimeSeriesEditModalInput,
    ITimeSeriesEditModalOutput,
} from '../time-series.types';
import { EntityService } from '../../shared/entity/services/entity.service';
import StartOf = moment.unitOfTime.StartOf;
import {
    UpdateTimeSeriesAttributeEntryAction,
    UpdateTimeSeriesEntryDto,
} from '@datagalaxy/webclient/attribute/data-access';
import { UpdateAttributeAction } from '@datagalaxy/webclient/entity/data-access';
import {
    TimeSeriesEntry,
    TimeSeriesFrequency,
    TimeSeriesObject,
} from '@datagalaxy/webclient/attribute/domain';
import { executeOnce, withLoading } from '@datagalaxy/core-ui';

@Component({
    selector: 'dxy-time-series-edit-modal',
    templateUrl: './dxy-time-series-edit-modal.component.html',
    styleUrls: ['./dxy-time-series-edit-modal.component.scss'],
})
export class DxyTimeSeriesEditModalComponent
    extends DxyBaseModalComponent<
        ITimeSeriesEditModalInput,
        ITimeSeriesEditModalOutput
    >
    implements OnInit
{
    public get errorMessage(): string {
        return this.dateErrorMessage || this.valueErrorMessage;
    }
    public timeSeriesEntry: TimeSeriesEntry;
    public get isDisabled() {
        return this.errorMessage || this.timeSeriesEntry.Value == null;
    }

    private oldTimeSeriesEntry: TimeSeriesEntry;
    private dateErrorMessage: string;
    private valueErrorMessage: string;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: ITimeSeriesEditModalInput,
        public dialogRef: MatDialogRef<
            DxyTimeSeriesEditModalComponent,
            ITimeSeriesEditModalOutput
        >,
        private entityService: EntityService
    ) {
        super(dialogRef, data);
    }

    ngOnInit() {
        this.oldTimeSeriesEntry = this.data.isCreate
            ? { Date: moment().toDate(), Value: undefined }
            : this.data.entry;
        this.timeSeriesEntry = CoreUtil.clone(this.oldTimeSeriesEntry);
        this.initValidate();
    }

    @executeOnce()
    @withLoading()
    public async onSubmit() {
        let params: UpdateTimeSeriesEntryDto;

        if (this.data.isCreate) {
            params = {
                Date: this.timeSeriesEntry.Date,
                Value: this.timeSeriesEntry.Value,
                Action: UpdateTimeSeriesAttributeEntryAction.AddEntry,
            };
        } else {
            params = {
                Date: this.timeSeriesEntry.Date,
                Value: this.timeSeriesEntry.Value,
                OldDate: this.oldTimeSeriesEntry.Date,
                Action: UpdateTimeSeriesAttributeEntryAction.UpdateEntry,
            };
        }

        const result = await this.entityService.updateEntity(
            this.data.entity,
            this.data.attributeMeta.AttributeKey,
            params,
            {
                action: UpdateAttributeAction.SetValue,
                includeQuality: this.data.mustIncludeQualityOnUpdate,
            }
        );

        if (result?.UpdatedEntities?.length) {
            const timeSeriesObject =
                result.UpdatedEntities[0].getTypedAttributeValue(
                    this.data.attributeMeta
                ) as TimeSeriesObject;
            this.result = {
                updatedEntry: this.timeSeriesEntry,
                updatedTimeSeriesObject: timeSeriesObject,
            };
            this.onCloseSubmit();
        }
    }

    private initValidate() {
        this.onDateChange(moment(this.timeSeriesEntry.Date));
    }

    public onDateChange(date: moment.Moment) {
        this.timeSeriesEntry.Date = date.toDate();
        const oldDate = moment(this.oldTimeSeriesEntry.Date);
        const frequencyMap = new Map<TimeSeriesFrequency, string>([
            [TimeSeriesFrequency.Monthly, 'month'],
            [TimeSeriesFrequency.Weekly, 'week'],
            [TimeSeriesFrequency.Daily, 'day'],
        ]);
        const frequency = this.data.attributeMeta.TimeSeriesFrequency;
        if (date.isAfter()) {
            this.dateErrorMessage =
                'UI.EntityTimeSeriesHistoryModal.edit.errors.ulteriorDate';
            return false;
        }
        if (frequencyMap.has(frequency)) {
            const frequencyStr: string = frequencyMap.get(frequency);
            const isBadFrequency = this.data.entries
                ?.filter((ts) => !oldDate.isSame(ts.Date))
                .some((ts) => date.isSame(ts.Date, frequencyStr as StartOf));
            if (isBadFrequency) {
                this.dateErrorMessage = `UI.EntityTimeSeriesHistoryModal.edit.errors.${frequencyStr}Frequency`;
                return false;
            }
        }
        this.dateErrorMessage = '';
        return true;
    }

    public onValueChange() {
        const entry = this.timeSeriesEntry;

        if (entry.Value == undefined) {
            this.valueErrorMessage =
                'UI.EntityTimeSeriesHistoryModal.edit.errors.valueUndefined';
            return false;
        }
        this.valueErrorMessage = '';
        return true;
    }
}

import {
    Component,
    ElementRef,
    Input,
    NgZone,
    Optional,
    Self,
    ViewChild,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { MatLegacyInput as MatInput } from '@angular/material/legacy-input';
import { MatLegacyAutocomplete as MatAutocomplete } from '@angular/material/legacy-autocomplete';
import { DxyBaseFocusableFieldComponent } from '@datagalaxy/ui/fields';

@Component({
    selector: 'dxy-field-email',
    templateUrl: 'field-email.component.html',
})
export class DxyFieldEmailComponent extends DxyBaseFocusableFieldComponent<string> {
    @Input() placeholder: string;
    @Input() maxLength = 125;
    @Input() hideMaxLengthHint: boolean;
    @Input() matAutocomplete: MatAutocomplete;
    /** returns an empty string if the email matches the criteria, else the error message */
    @Input() checkEmailSyntax: (email: string) => string;

    public get label() {
        return this.getLabel(this.translate);
    }
    public get labelTooltip() {
        return this.getLabelTooltip(this.translate);
    }
    public get errorMessage() {
        return this.getErrorMessage(this.translate);
    }
    public get showMaxLengthHint() {
        return !!(
            this.maxLength &&
            this.fieldControl?.focused &&
            !this.hideMaxLengthHint
        );
    }

    @ViewChild('fieldControl') protected fieldControl: MatInput;

    constructor(
        private translate: TranslateService,
        elementRef: ElementRef<HTMLElement>,
        ngZone: NgZone,
        @Optional() @Self() ngControl: NgControl
    ) {
        super(elementRef, ngZone, ngControl);
    }

    public onInputChange(email: string) {
        if (!this.checkEmailSyntax) {
            return;
        }
        this.errorMessageKey = this.checkEmailSyntax(email);
    }
}

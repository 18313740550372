import {
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import { IFieldSelectAdapter } from '@datagalaxy/core-ui';
import { DataUtil } from '../../../shared/util/DataUtil';
import { NavigationService } from '../../../services/navigation.service';
import { EntityService } from '../../../shared/entity/services/entity.service';
import { ModelerService } from '../../services/modeler.service';
import { SecurityService } from '../../../services/security.service';
import {
    DataTypeMapping,
    Model,
    ModelSettings,
} from '@datagalaxy/webclient/modeler/data-access';
import {
    CrudOperation,
    FunctionalLogService,
} from '@datagalaxy/webclient/monitoring/data-access';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { EntityUtils } from '@datagalaxy/webclient/entity/utils';
import { StateName } from '@datagalaxy/webclient/routing';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'dxy-data-type-model-settings',
    templateUrl: 'dxy-data-type-model-settings.component.html',
})
export class DxyDataTypeModelSettingsComponent
    extends DxyBaseComponent
    implements OnChanges, OnInit
{
    @Input() modelData: Model;
    @Input() entityData: EntityItem;

    public modelSettings: ModelSettings;
    public readonly currentMappingAdapter: IFieldSelectAdapter<DataTypeMapping> =
        {
            isModel: true,
            getText: (v) => v.DisplayName,
            onSelectionChange: (v) => this.setCurrentmapping(v).then(),
        };
    protected readonly StateName = StateName;

    public get hasWriteAccess() {
        return this.entityData.SecurityData.HasWriteAccess;
    }
    public get isTechnologySettingsAvailable() {
        return DataUtil.isModelRelationalHddData(this.entityData.HddData);
    }
    public get isClientAdmin() {
        return this.securityService.isCurrentUserClientAdmin();
    }
    public get hasCurrentMapping() {
        return this.modelerService.hasCurrentMapping(this.modelSettings);
    }

    constructor(
        private navigationService: NavigationService,
        private entityService: EntityService,
        private modelerService: ModelerService,
        private securityService: SecurityService,
        private functionalLogService: FunctionalLogService
    ) {
        super();
    }

    ngOnChanges(changes: SimpleChanges) {
        super.onChange(changes, 'modelData', () => this.init());
    }

    ngOnInit() {
        this.init();
    }

    public async onAddDataTypeClick() {
        this.log('onAddDataTypeClick');
        await this.modelerService.addDataTypeForModel(this.modelSettings);
    }

    public async onDeleteCurrentMappingClick() {
        this.log('onDeleteCurrentMappingClick');
        this.functionalLogService.logFunctionalAction(
            'DATA_DEFINITION_LANGUAGE',
            CrudOperation.D
        );
        const currentMapping = this.currentMappingAdapter.current;
        await this.modelerService.deleteDataTypeMappingForModel(
            this.modelSettings,
            currentMapping
        );
        await this.updateEntity();
        this.init();
    }

    private async setCurrentmapping(newMapping: DataTypeMapping) {
        this.log('setCurrentmapping', newMapping);
        this.functionalLogService.logFunctionalAction(
            'DATA_DEFINITION_LANGUAGE',
            CrudOperation.U
        );
        await this.modelerService.changeDataTypeMappingForModel(
            this.modelSettings,
            newMapping
        );
        await this.updateEntity();
        this.init();
    }

    private async updateEntity() {
        const entity = await this.entityService.getEntityForDetails(
            this.entityData,
            ['TechnologyName', 'TechnologyId', 'IsScriptGenerationEnabled'],
            false
        );
        EntityUtils.mergeEntity(this.entityData, entity);
    }

    private init() {
        this.modelSettings = this.modelerService.getSettings(
            this.modelData,
            true
        );
        this.currentMappingAdapter.options =
            this.modelerService.getAllDataTypeMappings();
        this.currentMappingAdapter.current =
            this.modelerService.hasCurrentMapping(this.modelSettings)
                ? this.modelerService.getCurrentMapping(this.modelSettings)
                : undefined;
    }
}

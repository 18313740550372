import { NgModule } from '@angular/core';
import { AngularSplitModule } from 'angular-split';
import { AttributeFilterModule } from '../shared/attribute/attribute-filter/AttributeFilterModule';
import { DxyFilterModule } from '../shared/filter/DxyFilterModule';
import { SharedModule } from '../shared/shared.module';
import { DxySharedUiModule } from '../shared/shared-ui/DxySharedUiModule';
import { SpaceModule } from '../space/space.module';
import { DxySpotlightModule } from '../spotlight/DxySpotlightModule';
import { DxySharedEntityModule } from '../shared/entity/DxySharedEntityModule';
import { DxySpaceSelectorModule } from '../space-selector/DxySpaceSelectorModule';
import { DxyAttributesModule } from '../shared/attribute/DxyAttributesModule';
import { DxySearchSettingsComponent } from './dxy-search-settings/dxy-search-settings.component';
import { DxySearchFiltersListComponent } from './dxy-search-filters-list/dxy-search-filters-list.component';
import { DxySearchFacetFilterGroupComponent } from './facets/dxy-search-facet-filter-group/dxy-search-facet-filter-group.component';
import { DxySearchFacetSettingsComponent } from './facets/dxy-search-facet-settings/dxy-search-facet-settings.component';
import { DxySearchFacetFiltersComponent } from './facets/dxy-search-facet-filters/dxy-search-facet-filters.component';
import { DxyMainSearchComponent } from './dxy-main-search/dxy-main-search.component';

@NgModule({
    imports: [
        SharedModule,
        DxySharedUiModule,
        DxyFilterModule,
        SpaceModule,
        DxySpotlightModule,
        DxySharedEntityModule,
        DxySpaceSelectorModule,
        AngularSplitModule,
        DxyAttributesModule,
        AttributeFilterModule,
    ],
    declarations: [
        DxySearchSettingsComponent,
        DxySearchFiltersListComponent,
        DxySearchFacetFilterGroupComponent,
        DxySearchFacetSettingsComponent,
        DxySearchFacetFiltersComponent,
        DxyMainSearchComponent,
    ],
    exports: [DxySearchSettingsComponent],
})
export class DxySearchModule {}

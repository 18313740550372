import { Injectable } from '@angular/core';
import { AppConfig, AppConfigService } from '@datagalaxy/webclient/config';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AppVersionUpdateService {
    private readonly appVersionChange = new Subject<{
        latest: string;
        current: string;
    }>();

    public get appVersionChange$() {
        return this.appVersionChange.asObservable();
    }

    constructor(private appConfigService: AppConfigService) {}

    public startAppVersionChangeDetection() {
        const defaultDelayMinutes = 0.2;
        const delayMinutes =
            this.appConfigService.versionCheckMinutes || defaultDelayMinutes;

        const normalize = (s: string | number) =>
            s?.toString().trim().toLowerCase() ?? '';
        const repeat = () => setTimeout(check, 1000 * 60 * delayMinutes);
        const check = async () => {
            const current = this.appConfigService.APP_VERSION_NUMBER;
            const latest = await AppConfig.getLatestVersionNumber();

            const change = normalize(latest) != normalize(current);
            if (change) {
                const data = { latest, current };
                this.appVersionChange.next(data);
            }

            repeat();
        };

        repeat();
    }
}

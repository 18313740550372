<dxy-graphical-controls
    toolbarsClass="diagram-designer"
    [events]="toolBarEvents"
    [actionButtons]="actionButtons$ | async"
    [zoom]="scale$ | async"
    [fullScreenElement]="hostRef"
    [sidePanelSpec]="sidePanelSpec"
    [sidePanelExpanded]="panelOpen"
    (functional)="onFunctionalLog($event)"
    (sidePanelExpandedChange)="onAssetsPanelExpandedChange($event)"
>
    <div class="graph-surface" #graphSurfaceEl></div>
    <div mini-map-content class="full-size"></div>
    <app-dks-asset-panel
        *ngIf="!externalPanel && panelOpen"
        side-panel-content
        [onStageAssets]="onStageAssets$ | async"
        [disabledSelection]="config?.assets?.disableSelection"
        (select)="onSelectAssets($event)"
        (unselect)="onUnselectAssets($event)"
    >
        <ng-content second-tab select="[second-tab]"></ng-content>
    </app-dks-asset-panel>
    <ng-container *ngIf="externalPanel" side-panel-content>
        <ng-content select="[side-panel-content]"></ng-content>
    </ng-container>
</dxy-graphical-controls>
<app-dks-contextual-menu
    *ngIf="graphSurface"
    [graphSurface]="graphSurface"
    [config]="config?.contextualMenu"
    (functional)="onFunctionalLog($event)"
></app-dks-contextual-menu>
<div class="hidden">
    <ng-container #compsContainer></ng-container>
</div>
<dxy-rich-tooltip-content
    contentClass="dg5-custom-tooltip diagram-edge-tooltip"
></dxy-rich-tooltip-content>

<div
    *ngIf="batches$ | async as batches"
    class="progress"
    [dxyRichTooltip]="batchesTooltip"
    [richTooltipPosition]="'left'"
    [richTooltipPositionOptions]="{ offsetX: -10 }"
>
    <dxy-progress-bar mode="indeterminate"></dxy-progress-bar>
</div>

<ng-template #batchesTooltip>
    <div class="batches-tooltip dg5-custom-tooltip">
        <h3 translate>
            Multilingual.Administration.LanguagesTab.TranslationProgressTooltip.title
        </h3>
        <ul>
            <li *ngFor="let batch of batches$ | async">
                {{ batch }}
            </li>
        </ul>
    </div>
</ng-template>

import {
    ChangeDetectionStrategy,
    Component,
    HostBinding,
    Input,
} from '@angular/core';
import { EntityTypeIconComponent } from '../entity-type-icon/entity-type-icon.component';
import { NgIf } from '@angular/common';
import { EntityCardDisplayMode } from './entity-type-card.types';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';
import { EntityType } from '@datagalaxy/dg-object-model';
import { DxyCoreDirectivesModule } from '@datagalaxy/core-ui';
import { EntityTypeUtils } from '@datagalaxy/webclient/entity/utils';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {
    IconCardComponent,
    IconCardSize,
    IconComponent,
} from '@datagalaxy/ui/icon';

@Component({
    standalone: true,
    selector: 'dxy-entity-type-card',
    templateUrl: './entity-type-card.component.html',
    imports: [
        EntityTypeIconComponent,
        NgIf,
        MatLegacyTooltipModule,
        TranslateModule,
        DxyCoreDirectivesModule,
        IconCardComponent,
        IconComponent,
    ],
    styleUrls: ['./entity-type-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EntityTypeCardComponent {
    @Input() size: IconCardSize = 'medium';
    @Input() displayMode = EntityCardDisplayMode.TechnoWithChip;
    @Input() entityType?: EntityType;
    @Input() iconUrl = '';
    @Input() iconTooltip = '';

    @HostBinding('class')
    private get sizeClass() {
        return this.size;
    }

    protected tooltipDisabled = false;

    constructor(private translate: TranslateService) {}

    protected get chipSize() {
        return this.size === 'xl' ? 'xl' : 'medium';
    }

    protected get tooltip() {
        return this.showEntityGlyph
            ? EntityTypeUtils.getTranslateKey(this.entityType)
            : this.iconTooltip;
    }

    protected get icon() {
        return this.showEntityGlyph
            ? EntityTypeUtils.getColoredGlyphClass(this.entityType)
            : this.iconUrl;
    }

    protected get iconType() {
        return this.showEntityGlyph ? 'glyph' : 'url';
    }

    protected get showEntityGlyph() {
        return (
            [
                EntityCardDisplayMode.GlyphOnly,
                EntityCardDisplayMode.GlyphWithChip,
            ].includes(this.displayMode) || !this.iconUrl
        );
    }

    protected get showIconUrl() {
        return (
            !!this.iconUrl &&
            [
                EntityCardDisplayMode.TechnoOnly,
                EntityCardDisplayMode.TechnoWithChip,
            ].includes(this.displayMode)
        );
    }

    protected get showEntityChip() {
        return (
            (this.displayMode === EntityCardDisplayMode.GlyphWithChip &&
                this.iconUrl) ||
            (this.displayMode === EntityCardDisplayMode.TechnoWithChip &&
                this.iconUrl)
        );
    }

    protected get entityTypeNameKey() {
        if (this.size !== 'xl') {
            return EntityTypeUtils.getShortTranslateKey(this.entityType);
        }
        const translateKey = EntityTypeUtils.getTranslateKey(this.entityType);
        const maxLength = 10;
        const translatedName = this.translate.instant(translateKey);
        const isNameShort = translatedName?.length < maxLength;

        this.tooltipDisabled = isNameShort && this.showEntityGlyph;

        return isNameShort
            ? translateKey
            : EntityTypeUtils.getShortTranslateKey(this.entityType);
    }
}

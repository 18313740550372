import { CollectionsHelper, CoreUtil } from '@datagalaxy/core-util';
import * as moment from 'moment/moment';
import { VersionStatus } from './connector.types';
import { ConnectorVersionDto } from '@datagalaxy/webclient/connectivity/data-access';
import { IConfigFieldDef } from './connection-form/form-configs/types/interfaces/config-field-def.interface';
import { ConnectionFields } from './connection-form/form-configs/types/models/connection-fields.model';

export class ConnectorUtil {
    public static parseDate(date?: moment.Moment | string): string | null {
        if (date) {
            const momentDate = typeof date === 'string' ? moment(date) : date;
            const year = momentDate.year();
            return year > 1 ? momentDate.format('L, LTS') : null;
        } else {
            return null;
        }
    }

    public static getVersionDtosForSelection(
        dtos: ConnectorVersionDto[],
        clone = false,
        maxReleases = 8
    ) {
        const sortedDtos = CollectionsHelper.distinctByProperty(
            dtos,
            (v) => v.Version
        )
            .sort((a, b) => ConnectorUtil.compareVersion(a.Version, b.Version))
            .reverse();
        const localDtos = clone ? sortedDtos.map(CoreUtil.clone) : sortedDtos;
        const recommended = localDtos.find(
            (dto) => dto.Status == VersionStatus.RECOMMENDED
        );
        const beta = localDtos.find((dto) => dto.Status == VersionStatus.BETA);
        const releases = localDtos
            .filter((dto) => dto.Status == VersionStatus.RELEASE)
            .slice(0, maxReleases);
        return {
            available: [recommended, beta, ...releases].filter((o) => o),
            selected: recommended ?? beta ?? releases[0],
        };
    }

    public static buildPayload(
        fields: ConnectionFields,
        conf: IConfigFieldDef[]
    ) {
        return conf
            .filter((c) => c.payloadField)
            .reduce((p, c) => {
                p[c.payloadField] = ConnectorUtil.getFieldValue(fields, c);
                if (c.dependencies) {
                    c.dependencies.forEach((dep) => {
                        p[dep.field.payloadField] = ConnectorUtil.getFieldValue(
                            fields,
                            dep.field
                        );
                    });
                }
                return p;
            }, {});
    }

    public static getFieldValue(fields: ConnectionFields, cf: IConfigFieldDef) {
        if (cf.formField) {
            if (cf.format) {
                return cf.format(fields[cf.formField]);
            } else {
                return fields[cf.formField];
            }
        } else {
            return cf.payloadValue;
        }
    }

    public static buildMaskList(savedCredentials: {
        [credentialKey: string]: string;
    }) {
        const credentialKeys = Object.keys(savedCredentials).filter((k) =>
            k.includes('structure-masks')
        );
        return credentialKeys?.map((k) => savedCredentials[k]) ?? [];
    }

    public static getDefaultPort(pluginName: string) {
        switch (pluginName) {
            case 'sqlserver':
            case 'azuresql':
                return 1433;
            case 'snowflake':
                return 443;
            default:
                return null;
        }
    }

    private static compareVersion(versionA: string, versionB: string) {
        const split = (v: string) => v.split('.').map((d) => parseInt(d, 10));
        const [majorA, minorA, patchA] = split(versionA);
        const [majorB, minorB, patchB] = split(versionB);
        if (majorA !== majorB) {
            return majorA - majorB;
        }
        if (minorA !== minorB) {
            return minorA - minorB;
        }
        if (patchA !== patchB) {
            return patchA - patchB;
        }
        return 0;
    }
}

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { AttributeFilterModel } from '../../shared/attribute/attribute-filter/attribute-filter/attributeFilterModel';
import { Subject, Subscription } from 'rxjs';
import { AttributeFilterService } from '../../shared/attribute/attribute-filter/attribute-filter.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AttributeFilterAction } from '../../shared/attribute/attribute-filter/attribute-filter.types';
import { CurrentSearch } from '../models/CurrentSearch';
import { AppSpaceService } from '../../services/AppSpace.service';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { CoreUtil } from '@datagalaxy/core-util';
import { ISpaceIdentifier } from '@datagalaxy/webclient/workspace/domain';
import { FilterOperator } from '@datagalaxy/webclient/filter/domain';
import { AttributeMetaInfo } from '@datagalaxy/webclient/attribute/domain';
import { ServerConstants } from '@datagalaxy/shared/server/domain';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'dxy-search-filters-list',
    templateUrl: './dxy-search-filters-list.component.html',
    styleUrls: ['./dxy-search-filters-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DxySearchFiltersListComponent
    extends DxyBaseComponent
    implements OnInit, OnChanges
{
    @Input() currentSearch: CurrentSearch;
    @Output() onSpaceVersionSelected = new EventEmitter<ISpaceIdentifier>();
    @Output() onChangeSearch = new EventEmitter();

    /**
     * Temporarily disable searchTerm Operator
     * Wait until StartWith/EndWith to be fixed
     * (should be ready for 3.0)
     */
    public searchTermOpEnabled = false;
    public get filterItems$() {
        return this.currentSearch.displayedFilters$;
    }
    public get spaceIdr() {
        return this.currentSearch.spaceIdr;
    }
    public get searchTerm() {
        return this.currentSearch.searchTerm;
    }
    public set searchTerm(value: string) {
        this.currentSearch.searchTerm = value;
    }
    public get searchTermFilter() {
        return this.currentSearch?.filterItems.find(
            (filter) =>
                filter.attributeKey ==
                ServerConstants.Search.SearchTermFilterKey
        );
    }
    public get searchTermOps() {
        return this.searchTermFilter.operators;
    }
    public get searchTermFilterOperator() {
        return this.searchTermFilter?.operator === FilterOperator.TextEquals
            ? '='
            : this.attributeFilterService.getFilterItemOperatorDisplayName(
                  this.searchTermFilter
              );
    }
    public get showSearchTermOperator() {
        return this.searchTermFilter?.operator !== FilterOperator.TextContains;
    }
    public get availableAttributes() {
        return this.currentSearch.availableAttributes;
    }
    public get showSpaceSelector() {
        return !this.appSpaceService.isSingleWorkspace();
    }

    private filterItemsChangeSubscription: Subscription;
    private searchTermChanged = new Subject();

    constructor(
        private appSpaceService: AppSpaceService,
        private attributeFilterService: AttributeFilterService,
        private cd: ChangeDetectorRef
    ) {
        super();
    }

    ngOnInit() {
        this.subscribeEvents();

        this.searchTermChanged
            .pipe(debounceTime(500), distinctUntilChanged())
            .subscribe(() => {
                this.onChangeSearch.emit();
            });
    }

    ngOnChanges(changes: SimpleChanges) {
        super.onChange(changes, 'currentSearch', () => {
            this.subscribeEvents();
        });
    }

    public onSpaceVersionSelectedInternal(spaceIdr: ISpaceIdentifier) {
        this.log(
            'onSpaceVersionSelectedInternal',
            spaceIdr,
            !!this.onSpaceVersionSelected
        );
        this.onSpaceVersionSelected.emit(spaceIdr);
    }

    public onFilterItemAction(
        afm: AttributeFilterModel,
        action: AttributeFilterAction
    ) {
        this.log('onFilterItemAction', afm, action);
        switch (action) {
            case AttributeFilterAction.itemChanged:
                this.onFilterItemChanged(afm);
                break;
            case AttributeFilterAction.itemRemoved:
                this.onFilterItemRemoving(afm);
                break;
            case AttributeFilterAction.formClosed:
                this.onFilterItemFormClosed(afm);
                break;
            default:
                CoreUtil.warn('action not implemented');
                break;
        }
    }

    public onAddAttribute(attribute: AttributeMetaInfo) {
        this.log('onAddAttribute', attribute);
        this.addFilterItem(attribute);
    }

    public onSearchTermChange() {
        this.log(
            'onSearchTermChange',
            this.searchTerm,
            this.currentSearch.filterItems
        );
        this.searchTermChanged.next(this.searchTerm);
    }

    public onClearSearchTerm() {
        this.currentSearch.clearSearchTerm();
        this.searchTermChanged.next(this.searchTerm);
    }

    public getSearchOpDisplayName(op: FilterOperator) {
        return this.attributeFilterService.getFilterOperatorDisplayName(
            op,
            this.searchTermFilter
        );
    }

    public onSearchTermOperatorChange(op: FilterOperator) {
        this.searchTermFilter.operator = op;
        this.onChangeSearch.emit();
    }

    private subscribeEvents() {
        this.filterItemsChangeSubscription?.unsubscribe();
        this.filterItemsChangeSubscription = super.subscribe(
            this.currentSearch.filterItemsChanged$,
            () => this.onFilterItemsChange()
        );
    }
    private deleteFilterItem(filterItem: AttributeFilterModel) {
        this.currentSearch.removeFilterItem(filterItem);
    }
    private addFilterItem(attribute: AttributeMetaInfo) {
        this.currentSearch.addFilterFromAttribute(attribute);
    }

    private onFilterItemRemoving(afm: AttributeFilterModel): void {
        this.log('onFilterItemRemoving', afm);
        this.deleteFilterItem(afm);
        this.onChangeSearch.emit();
    }
    private onFilterItemChanged(afm: AttributeFilterModel): void {
        this.log('onFilterItemChanged', afm);
        this.attributeFilterService.logFilter(afm, 'SEARCH');
        this.onChangeSearch.emit();
    }
    private onFilterItemFormClosed(afm: AttributeFilterModel): void {
        this.log('onFilterItemFormClosed', afm);
        if (!afm.isValid()) {
            this.deleteFilterItem(afm);
        }
    }

    private onFilterItemsChange() {
        this.log('onFilterItemsChange');
        this.cd.detectChanges();
    }
}

import { NgModule } from '@angular/core';
import { DxyEntitySelectorModule } from '../entitySelector/DxyEntitySelectorModule';
import { DxyUnitaryFieldActionsComponent } from './unitary/dxy-unitary-field-actions/dxy-unitary-field-actions.component';
import { DxyFieldsModule } from '@datagalaxy/core-ui/fields';
import { SharedModule } from '../shared.module';
import { RichTextModule } from '../richText/RichTextModule';
import { DxySharedUiModule } from '../shared-ui/DxySharedUiModule';

@NgModule({
    imports: [
        SharedModule,
        DxySharedUiModule,
        RichTextModule,
        DxyEntitySelectorModule,
        DxyFieldsModule,
    ],
    declarations: [DxyUnitaryFieldActionsComponent],
    exports: [
        DxyUnitaryFieldActionsComponent,
        DxyFieldsModule,
        RichTextModule, // exports RichTextModule for dxy-rich-text-field
        DxyEntitySelectorModule, // contains dxy-entity-selector-field
    ],
})
export class FieldsModule {}

import { INgZone, ZoneUtils } from '@datagalaxy/utils';

/** provides buffering for a drag/move action */
export class MoveBuffer<T> {
    private dx = 0;
    private dy = 0;
    private timer: number;
    private onDo: (dx: number, dy: number, data?: T) => void;

    constructor(public timeoutDurationMs?: number, public ngZone?: INgZone) {}

    public start(onDo: (dx: number, dy: number, data?: T) => void) {
        this.dx = 0;
        this.dy = 0;
        this.onDo = onDo;
    }

    public update(delta: { dx: number; dy: number }, data?: T) {
        window.clearTimeout(this.timer);

        this.dx += delta.dx;
        this.dy += delta.dy;

        this.timer = ZoneUtils.zoneTimeout(
            () => {
                const dx = this.dx;
                this.dx = 0;
                const dy = this.dy;
                this.dy = 0;

                if (this.onDo) {
                    this.onDo(dx, dy, data);
                }
            },
            this.timeoutDurationMs,
            this.ngZone,
            true
        );
    }

    public end() {
        window.clearTimeout(this.timer);
        this.onDo = undefined;
    }
}

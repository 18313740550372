<dxy-grid
    *ngIf="isGridReadyToDisplay"
    [config]="gridConfig"
    [columns]="cols"
    [items]="sampledData"
></dxy-grid>
<dxy-spinner *ngIf="loading$ | async" class="xl"></dxy-spinner>
<div *ngIf="hasSamplingFailed" class="message-container">
    <span translate>UI.Modeler.denodoSampling.samplingFailed</span>
</div>
